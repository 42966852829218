const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c3" dir="rtl">
      <span class="c1 bold">דבר 27.7.1930</span><span> – </span
      ><span class="c0"> תחת הכותרת</span
      ><span> מסיבה למורי-רומניה בראשון לציון </span
      ><span class="c0 c4"
        >מתואר מפגש של מורי דרום יהודה ובאי כח המוסדות עם מורי עברית מרומניה,
        מפגש שאורגן ע"י סניף מורי יהודה. במפגש דובר על חשיבות בתי הספר העברים
        בגולה לחיזוק הקשר בין הקהילות ולאפשרות למיזוג הגלויות בארץ ע"י השפה.
        לאורחים סופר על הנעשה במושבות. במאמר מוזכרים אנשים שונים שדברו שם ובתוכם
        "הגב&#39; נחמה פוחצ&#39;בסקי מספרת ע"ד השתתפותה של האכרה בבנין הארץ
        ומאשימה את הגולה במיעוט פעולה למען א"י. גם כיום ישנן אפשרויות גדולות.
        זאת יש להביא לגולה."
      </span>
    </p>
  </body>
</html>`;

export default modal;
