const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <p class="c0 title italic space-bottom" dir="rtl">
      <span class="c1"
        >בנערותה היתה נחמה פוחצ&#39;בסקי (אז פיינשטיין) תלמידה מצטיינת בגימנסיה
        רוסית. כשעזבה את הגימנסיה למדה עברית עם מורה פרטי. את המאמר הזה כתבה
        בעברית, בהיותה כבת 20, עדין ברוסיה (לפני נישואיה). המאמר פורסם בעתון </span
      ><span class="c6 bold">המליץ</span
      ><span class="c1 c2"
        > ביום ו&#39; ז&#39; אדר א תרמ"ט, 8 בפברואר 1889.</span
      >
    </p>
    <p class="c0 c5 title" dir="rtl"><span class="c2 c4"></span></p>
    <p class="c0 title" dir="rtl">
      <span class="c2 c8 bold">עוד על שאלת הבנות</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c4"
        >בין השאלות העומדות ברום עולמנו הקטן תתנוסס שאלה אחת, ושאלת החנוך שמה,
        והיא תֵראה פעם בפעם על פני עלי מכ"ע העברים מפי סופרים, מורים ופדגוגים
        אומנים, אשר כל אחד ואחד מהם יחווה דעתו, להורות את הדרך אשר יוליכו בה את
        הנערים ואת המעשים אשר יעשו, להיות בנים נאמנים לעמם ונאמנים לכל סגולותינו
        היקרות. אך חסרון אחד יראה בכל המאמרים ע"ד שאלת החינוך, חסרון חנוך הבנות.
        עד הנה עוד לא נסו הסופרים והפדגוגים (זולת אחד או שנים) לעבוד בשדה הזה,
        כאלו לא נבראו הנשים לקחת חלק בכל דבר טוב ומועיל, וכאלו לא נחשבו למאום
        בחיי האומה ולא תוכלנה להביא שום תועלת וגם אין כל שאר רוח להן; אבל דברי
        ימינו יוכיחו לנו כי לא כן הדבר. מרים, דבורה ויהודית עדות הן, כי בעת אשר
        יכבדו את ערך האשה ויתנוה להתערב בהענינים אשר חיי האומה תלויים בהם,
        יכולות הן לצאת חוצץ להצלת האומה, יכולות להציל את ארצן מידי האויב גם
        בגבורה ועוז – דברים אשר מימי עולם לקחו להם הגברים למורשה, ובנקל להן
        להקריב את חייהן על מזבח אהבת עמן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c4"
        >האמת ניתנה להאמר, כי בימי התלמוד הורידו מעט את כבוד הנשים, בהלקח מהן
        סגולת למוד התורה וידיעת הדת, כאשר יעידו איזו מאמרים המפוזרים במרחבי ים
        התלמוד וביותר המאמר הידוע: "כל המלמד את בתו תורה כאלו מלמדה תפלות", אשר
        הוא לפח ולמוקש עד היום על דרך השכלת הבנות בחכמת ישראל. אמנם התנאים
        הגדולים כרבי עקיבא ור&#39; מאיר ידעו להוקיר את ערך האשה, אף כי לא יכלו
        עוד להרימה משפלות מצבה, כי כבר הסכינו בימים ההם לחשבה לעגלה ביתית, אשר
        רק מטרה אחת לה בחיים ולא יותר. ומאז לא ילמדו את הבת תורה, אף כי שוקדים
        העם לפעמים ללמדה תפלות, ועד עתה אין שם לב לתקן את הטעות הזה, רק בחנוך
        הבנים כאשר אמרנו, ידובר הרבה מאד, לתת אהבת עמנו ודעת דתו וחכמתו ושפתו
        בלבם; אבל נראה נא עד כמה הצליח החפץ הזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c4"
        >כמעט כל המון צעירינו יחלק לשתי כתות: אחת מהן נושאת את נר השכלת אירופא
        בידה, והשנית עוד "בחשך הולכים", ר"ל לא נעשו "משכילים" בביאור המצוי של
        המילה הזאת. ושתי הכתות האלה מתאחדות בדבר אחד: בחסרון אהבה לעם ישראל ולכל
        חמודותיו. הראשונים – הם הניבו בתי הספר הנמוכים והגבוהים – אומרים אין
        ספרות לישראל, כי על מסורותינו אבד כלח, כי שפת עבר מתה לנצח ולא תקום
        לתחייה עוד, כי כאחד חנוטי מצרים היא וכי סכלות היא לנו לשחד מכחנו בעדה.
        האחרונים – המה הצעירים מעולם המעשה, בנעוריהם למדו בהחדר הצר בתחום מושב
        היהודים מפי מורים ומלמדים ללא הועיל, ובהיותם לאנשים, כמעט הריחו את רוח
        החיים, התיימרו למשכילים וישליכו אחרי גום את כל חמדת ישראל, אם כי מפי
        אחרים למדו רק לפזר כסף כאפר ומלוא חופניים חניפות להמין היפה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c4"
        >שורש כל הרעה הזאת היא בחסרון החינוך בין הבנות, כי לא תדענה האִמות לתת
        מנוער בלב הבן את האהבה לעמו ולשפת קדשו, כי בעצמן לא חונכו לזה. אלה הנשים
        מזה, בנות "צאנה וראינה" הן, המצפצפות והמהגות את התחינות אשר חיברו בעדן;
        הנשים האלה אמנם בנות עמן הן, אבל לא תדענה את האהבה לעם מה היא, ועל אחת
        כמה וכמה לא תדענה לטעת אותה בלב בניהן ובנותיהן, ומעבר השני בנות מן
        "הישוב" נראה, בנות המאה "התשע עשרה", בנות ההשכלה; אבל, אוי לי אם אומר
        ואוי לי אם לא אומר אוי לי אם אחפה על עון בנות עמנו ואוי לי אם אגלה את
        פשען, לאמר הנשים האלה תביאנה  רעה רבה לרוח ישראל, הבנים המגודלים על
        ברכיהן מעודם לא ידעו להוקיר את היהדות, כי בכל ימי ילדותם ישמעו שפה זרה,
        יראו מנהגים נכרים, ישמעו מדברים בשאט נפש בעם ישראל, ומה יעשו אלה הבנים
        בצאתם לשוק החיים כי לא יחטאו לרוח עמם ולדתו? לכן לא הרבה יועילו הדואגים
        לטובת חינוך הבנים, כל עוד לא ישימו לב לתקן את חינוך הבנות ללמד אותן את
        שפתנו הקדושה, תולדות ימינו וספרותנו, לטעת בלבן את האהבה לעמן ואחיהן,
        למען תוכלנה להאציל מרוח קדשן זה על הדור אשר יולד על ברכיהן ולעשותו בנים
        נאמנים לעמו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c4"
        >האומרים כי האשה איננה מסוגלה ללמוד שפת קודש ישגו הרבה מאד. הלא כבר
        העידו אלה הבנות אשר בחפץ לב החלו ללמוד שפת-עבר, כי נקל להן לדעת במשך עת
        קצרה את השפה הזאת על בוריה. יש גם בנות כאלה אשר בבקרן את בתי הספר ובלמדן
        חכמות חיצוניות, מצאו חפץ בשפת עבר וחכמת ישראל יקרה עתה להם מכל; אות הוא
        כי נוכל לאחוז בזה לבלי הניח ידינו גם מזה. רוב ההורים יתנו עתה את בנותיהם
        לבתי הספר, ללמוד שם חכמה ודעת, ואם יכניסו אותן גם להפרדס הנחמד השתול על
        שדמות ישראל, אז רק אז תוכלנה בנות ישראל להביא את התועלת הנחוצה ולמלאות
        את משלחתן בחיים לטובת עמנו ולאשרו.</span
      >
    </p>    
    <p class="c9 text-left" dir="rtl">
      <span class="c2 c4">.נחמה פיינשטיין</span>
    </p>    
  </body>
</html>`;

export default page;
