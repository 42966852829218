const page = `<body class="c10 doc-content">
  <h3 class="c14" dir="rtl" id="h.poc87rc93x6u">
    <span class="c0">יהודה ליב גורדון</span>
  </h3>
  <p class="c2" dir="rtl">
    <span class="c1 c3">המכתבים נמצאים בארכיון יל"ג תיק נחמה פיינשטיין</span>
  </p>
  <a href="#lilg1885">
    <p class="c2" dir="rtl">
      <span class="c12">ליל"ג  29.12.1885</span>
    </p>
  </a>
  <a href="#milg1886">
    <p class="c2" dir="rtl">
      <span class="c12">מיל"ג  3.1.1886</span>
    </p>
  </a>
  <a href="#lilg1886">
    <p class="c2" dir="rtl">
      <span class="c12">ליל"ג  12.1.1886</span>
    </p>
  </a>
  <a href="#lilg1889">
    <p class="c2" dir="rtl">
      <span class="c12">ליל"ג  10.4.1889</span>
    </p>
  </a>
  <br />

  <p class="c2" dir="rtl" id="lilg1885">
    <span class="c9">ליל"ג  29.12.1885</span>
  </p>
  <p class="c2 text-center" dir="rtl">
    <span class="c4"
      >        אל כבוד אהוב עמנו תפארת לאומנו המשורר הנפלא הרב יהודה ליב
      גארדאן!</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span>אמנם ידעתי כי לגבר </span><span class="c1 italic">[?]</span
    ><span> מאוד נעלה </span><span class="c1 italic">[?]</span
    ><span> השוקד על מלאכה רבה ונשגבה  כמוך, יש לך צדקה לדחות בקש </span
    ><span class="c1 italic">[?]</span
    ><span
      >  אנשים זרים המריצים אגרותיהם אליו, ובכל זאת ערבתי לבי לדבר אליך אדוני!
      הורי הורוני </span
    ><span class="c1 italic">[?]</span><span>  ושננו וחרתו </span
    ><span class="c1 italic">[?]</span
    ><span
      > בילדותי על לוח לבי מאמר חז"ל: "אסור להינות בעולם הזה בלא ברכה". ועל כל
      מוצא פי ד" יחיה האדם מברך אל בכונה. כן הרגלתי והסכנתי עד היום ומדוע לא
      אציל גם ברכה לראש משוררנו אשר מפרי תבונתו נהינתי עד מאוד ועתידה אני להינות
      בכל עת ובכל שעה? אם לא למשא אהיה עליך אדוני אספר לפניך תולדות עלמה עבריה
      המגודלת כשרש בארץ ציה, בכל זאת הושיעה לה ידה ובעזרת ד" באתי עד כה. אבי
      לרגל מסחרו העתיק פה משכנו לפני שש שנים ואנוכי אז עלמה כבת-עשרה שנה, יחידה
      להורי. ואבי אשר ראה בקרבי  יתרון הכשר ללימודים הביאני אל בית הגימנזיות שם
      מצאתי את אשר בקשה לה נפשי ושבעתי ענג ונחת רב. בשקידה גדולה שקדתי על דלתות
      הבית הזה וכנחל נובע מים חיים היה בעיני... אף כי צעירה מאוד לימים היתי בכל
      זאת הרביתי להעמיק במצולות הספרות הרוססית בכל עת מצוא עשיתי כל מיעיני </span
    ><span class="c1 italic">[? מועצי?]</span
    ><span
      > רק בה. סופריה היו בעיני כמלאכי מרום וכל עם רוססיה בכלל עם חכם ונבון מאין
      כמוהו. אז עוד לא שערתי ולא דמיתי ולא עלתה על לבי כי לאחינו ב"י המפוזרים
      והנדחים לכל ארבע רוחות השמים יש גם כן ספרות מיוחדת וסופרים מצוינים
      המקריבים כל ימי חייהם רק לטובת עמם ושפתם. אך שם אתנו מורה עברי צעיר לימים
      מודע ומכיר לנו מכבר הוא החל לפקוח מעט את עיני ויספר לי כי גם בקרב ב"י יש
      ספרות נשגבה אשר בכוחה לברוא חדשות לעשות נפלאות ולראיה הביא לו את כתבי
      קודש, אך אנכי שחקתי לעגתי לו... </span
    ><span class="c1 italic">["]</span
    ><span
      >אמנם יודעת נפשי – אמרתי לו – כי התנ"ך הוא אוצר כל חמדה, מקור כל חכמה, דעת
      ותושיה, ובכל זאת לא אוכל עוד להגיד כי יש לנו ליטעראטור במלוא מובן המילה,
      הבו נא לי אותות ומופתים אז אתן אומן בדבריכם" והוא בהתגלות לבו ענה ואמר לי:
      "הנה יש לנו בדורנו משורר אחד נפלא אשר רוח אלהים בו ושמו ריל"ג, אם יהיו כל
      חכמי – משוררי הגוים בכף מאזנים וגארדאן בכף השניה מכריע את כולם; מאז עיני
      ראשונה חזו את שריו הנשגבים אהבתים בכל לבי ונפשי נקשרה אליו באהבה נצחת ורק
      בקסם אשר על שפתו יש בידו לקרב לבות ב"י לשפתינו הקדושה. לוא הבינות ש"ע </span
    ><span class="c1 italic">[שפת עבר]</span
    ><span
      > וקראת את שיריו אז מהר אהבת אותו בכל לבך החם אשר חננך ד&#39; "... אז החל
      רוח חדש לפעמני והחילותי בחשק עז ובתשוקה גדולה ללמוד ש"ע ומיד אהבתי את
      שפתי, עמי ולאומי באהבה עזה כמות. והאח, אבי, לא עברו עוד שתי שנים מעת ההיא
      והנה </span
    ><span class="c1 italic">[? הנני?]</span
    ><span
      > אכרע ברך לפניך בלב מלא אהבה תודה ויקר! ברוך אתה אדוני שהסיבות לי ללמוד
      תורה ולהדבק בכל נפשי ומאודי לעמי וללאומי, ברוך שמך הנותן רוח חיים למחזיקים
      בך, יאריך לך ד" ימים ותראה השקט ובטחה בכל גבול ישראל סלה!!! אבי, מה מאוד
      שמחתי לקראת  צלוחתך שנתן ריח ניחוח לאפי, ושחוק עשית לי בדרוש השוטר תעודה
      מעצר </span
    ><span class="c6 underline">המלך</span><span> אשר הנעו </span
    ><span class="c1 italic">[?]</span
    ><span
      > לבו ידיו _ובו בהלחמו בעד עמו ולאומו... אמנם רבים הדאיבו את רוחך העדינה,
      אך אתה כגור אריה יהודה תשחק להמונם ושאונם וכולם יכירו וידעו כי לך רק לך
      נאוה תהילה. אל תחשוב אדוני כי קולך כקול קורא במדבר! לא ולא! הנה המונים
      נהרו אליך לשמוע ניבך </span
    ><span class="c1 italic">[?]</span
    ><span class="c4"
      > כי טוב ומתוק להם מדבש ומנופת צופים. ממני הצמאה לדבריו נפ"ש (נחמה
      פיינשטיין)</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      >.S.P  בשירך "האיש ושלש פלגשיו" – המלה "חומה" לא אדע פרשנה ושאלתי מרבים גם
      מהם נעלם. הנל</span
    >
  </p>
  <br />

  <p class="c2" dir="rtl" id="milg1886">
    <span class="c9">מיל"ג  3.1.1886</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4">שלום לך בתי תמתי נחמה, באשר את שמה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      >לבבתני במכתבך הנחמד אשר נוראות נפלאתי עליו. האמת אגיד, כי כמעט לא אאמין
      לדברים כי נערה בת שש עשרה שנה את וכי בשנתיים ימים למדת את שפתנו הקשה עד
      כדי לכתוב בה מכתב ארוך ערוך בטוב טעם ודעת כזה. אשרי הוריך כי נתן להם
      ה&#39; בת משכלת כזאת ובכל שמה, ואשרי האיש אשר תפולי לו חבל בנעימים; כי
      נכון לבי בטוח כי ככשרונותיך כן מדותיך וכשם שהיית תלמידה ותיקה כן הנך בת
      טובה וכך תהיי, בהגיע תורך, גם אשה טובה ואם טובה, ואז תהיינה חכמתך ודעתך
      כענקים לגרגרותיך והיית תפארת הבנות ובך יתפאר ישראל.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      > באמרך כי קראת את שירי ויטבו בעיניך נתת שמחה רבה בלבי, כי הנך רואה את ניד
      שפתי על גורל נשי עמי העלובות בשירי "קוצו של יו"ד" ושומעת את קול קריאתי אל
      אחותי בנות ציון בשירי "למי אני עמל" ואיכה לא אשמח בראותי אחת מבנות ציון
      יודעת שפתי ומבינה הגיגי? עלי והצליחי בתי תמתי, כי יתן ה&#39; לך כלבבך
      תשגשגי ותפרחי ובבוא עתך עת דודים אל נא תשכחי לקרוא אותי לראש שמחתך אז
      מרחוק אתערב בשמחתך ומשירי יהודך.</span
    >
  </p>
  <p class="c8 text-end" dir="rtl">
    <span class="c4">המוקירך מבלי להכירך,</span>
  </p>
  <p class="c8 text-end" dir="rtl">
    <span class="c4">יל"ג</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      >פ.ס. ביאור מלת "חומה" תמצאי במקומה בבאור (שה"ש ח&#39;) ולהרחיב באורה לא
      יתכן באזני עלמה צעירה לימים אשר גם היא עודנה חומה.</span
    >
  </p>
  <br />

  <p class="c2" dir="rtl" id="lilg1886">
    <span class="c9">ליל"ג  12.1.1886</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4">לכבוד הרב החכם ה_לל מו"ר  יהודה ליב גארדאן!</span>
  </p>
  <p class="c2" dir="rtl">
    <span
      >שמן נחומים הזלת על ראשי אשי יקירי! אין די תודות אשר רחש לך נפשי על כי שמת
      לבך לדברי ולא חסת על עתותך היקר להשיב לי על מכתבי. כבוד גדול ונחת רוח אשר
      מעודי לא פללתי עשית לי. באמת אגיד לך אדוני כי כל המתנות ומכתבי-תהילה אשר
      רכשתי לי שנה שנה מהפדגוגים בבית הגימנזיות איננה שוה בעיני לעומת דבריך
      היקרים והנעימים אשר הרהיבו </span
    ><span class="c1 italic">[?]</span><span> בנפשי עוד יתר און ואצמה </span
    ><span class="c1 italic">[? אם כך – הטעות במקור]</span
    ><span
      > ללמוד שפתינו הקדושה ולהגות בה תמיד. נוראות נפלאתי אבי: במה זכיתי כי אתה
      תגיד תהילתי בפני? אכן מאוד מאוד מאושרה אנוכי כי זכיתי לכך, ואשרי חלקי כי
      טוב! דבריך אדוני חרותים לנצח על לוח לבי הרגש לכל יופי; הם יהיו נר לרגלי
      ואור לנתיבתי </span
    ><span class="c1 italic">[?]</span
    ><span class="c4"> תמיד וזכרונו לא יסור ממני לעד סלה!!!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      >קח נא אדוני את דברי המעטים היוצאים מקרב לב עמוק ואמורים בתומת
    </span>
  </p>
  <p class="c8 c15 text-end" dir="rtl">
    <span class="c4">נפ"ש</span>
  </p>
  <br />

  <p class="c2" dir="rtl" id="lilg1889">
    <span class="c9">ליל"ג  10.4.1889</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4">לכבוד אדוני הנכבד שלום שלום!</span>
  </p>
  <p class="c2" dir="rtl">
    <span
      >בחיי האדם יקראו לפעמים רגעים אשר פתאום יתלהב חשק נמרץ בנפשו לדבר ולשוח עם
      האיש הנערץ והמקדש </span
    ><span class="c1 italic">[?]</span
    ><span
      > בעיניו והיושב עמוק בקרב לבו. התאוה הזאת תגדל באנשים אחדים כל כך עד כי
      מבקשים הם כל מיני עצות ומזימות איך למלאות תאותם זאת וכל יד עמל </span
    ><span class="c1 italic">[?]</span
    ><span
      > לא תוכל לעצרם מזה. כתשוקה הזאת התעוררה עתה בקרבי. נפשי תלהט גחלים לשוח
      ע"פ הגיליון עם האיש אשר זה כארבע שנים לקח את לבבי בשירתו הנעימה וברוח
      חכמתו המתנוססת </span
    ><span class="c1 italic">[?]</span
    ><span
      > מתוך כל הגה וכל דיבור היוצאים מפיו, ולבשר לו את שמחת לבי. אבל היש לי
      זכות על זה? היש לי זכות לבוא בפטפוטי מלים לאיש הדגול בכל אלפי ישראל? לענות
      על השאלה הזאת תוכל רק אהבתי העזה לרוח האיש הנעלה הזה. ואולי גם הוא בעצמו
      ובכבודו. כי מי כמוך משוררנו הנכבד והאהוב יודע את רגשות הנפש הנצמדה בחבלי
      אהבה לבת שירתך הנעימה? מי כמוך יודע לכלכל במשפט את חושי לב חם של איש צעיר
      לימים? ואם תדונני לכף זכות אז ארהיב עוד בנפשי לבוא לפניך היום כאיש בשורה.
      כי דע לך אדוני כי לפני שבֻעות אחדות נקשרתי בעבותות התנאים עם איש עובד
      אדמתינו הקדושה, והוא בן עיר מולדתי בריסק ואחד מששה הבחורים אשר לפני ארבעה
      שנים נבחרו מטעם הנדיב הידוע לשלחם לארצנו הקדושה ללמדם שם מלאכת גנן ושדה.
      כי חפץ הנדיב לדעת אם מסֻגלים אחינו יושבי ארץ הרוסה </span
    ><span class="c1 italic">[?]</span
    ><span class="c4"
      > למלאכה הזאת. בקיץ העבר כלו הצעירים את חוק לימודם והנה בחירי – מיכל זלמן
      פאחוטשאווסקי שמו – הֻשם לגנן בתחילה בהמושבה החדשה "באר טוביה" אשר קנה
      הנדיב בעד הבסרבים. אך אחרי אשר דבר המושבה נתבטל נמנה לגנן ב"ראשון לציון".
      בקיץ הוא יבוא הנה להעלני ציונה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      >וכן אדוני עוד ירחים מספר ותחז בציון עיני, ועיני תראנה את ארץ מחמדינו את
      ערש ילדותינו, את הארץ אשר אליה נשאתי את נפשי מיום אשר רוח הלאומי החל לפעם
      בקרבי. עוד מעט ואנכי אחבק את עפר ציון, אשק את אבניו ואמסם בדמעות שליש. האח
      מה מאושרה אנוכי כי כך עלתה לי!</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c4"
      >ואת השיר אשר אשיר בעלותי ציונה ברינה הידעת אדוני? הוא השיר אשר הבטחת לי
      להגיש לפני ביום חתונתי וביום שמחת לבי.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span
      >        אך סלח נא אדוני, הנה הלאתיך בדברי! סלח לנפ"ש המוקרת ומכבדת אותך
      כערכך הרב </span
    ><span class="c1">[? רם?]</span><span class="c4">.</span>
  </p>
  <p class="c8 text-end" dir="rtl">
    <span class="c4">נחמה פיינשטיין</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c4">צאריצין. יום ד&#39;, ט&#39; לירח ניסן תרמ"ט</span>
  </p>
</body>`;

export default page;
