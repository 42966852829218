const page = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <!-- <h3 class="c10" dir="rtl" id="h.kjspum4t997a">
      <span class="c5">על אופייה בהקשר לתולדותיה</span>
    </h3> -->
    <p class="c1 c3" dir="rtl"><span class="c6"></span></p>
    <p class="c1" dir="rtl">
      <span class="c8 small-text">אפרת בן כהן</span>
    </p>
    <p class="c1 c3" dir="rtl"><span class="c8"></span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >היא התחתנה ובאה לארץ בגיל צעיר, היא היתה בת שמונה עשרה, באה לראשון
        לציון של אז, 18 משפחות. לא כולם כמובן היו ברמה המתאימה לה אבל היא מצאה
        לה משפחה, יותר מאחת, שהיו מתאימות.
      </span>
    </p>
    <p class="c2 c3" dir="rtl"><span class="c0"></span></p>
    <p class="c4" dir="rtl">
      <span class="c0"
        >אני אינני חושבת שהיא סבלה מהחיים &#39;בסביבה פרובינציאלית&#39; ...
        להפך, זה המריץ אותה לתת יותר ויותר, במובן תרבותי. היתה חברה בכל מיני
        חוגים, ותמיד למדו וקראו והתפתחו. אני אינני חושבת שהיא סבלה מזה.</span
      >
    </p>
    <p class="c4 c3" dir="rtl"><span class="c0"></span></p>
    <p class="c4 c3" dir="rtl"><span class="c0"></span></p>
    <p class="c4" dir="rtl">
      <span class="c0"
        >לאמא היו הרבה מאד ידידים. למה? זה לא מפליא, משהיא היתה אשה חביבה, מארחת
        בחסד, תרבותית מאד, עקרת בית טובה.
      </span>
    </p>
    <p class="c3 c4" dir="rtl"><span class="c0"></span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >חייה לא היו קלים, כנראה. גם בסביבה הרוחנית, גם בסביבה החומרית. המצב היה
        די קשה, עבדו הרבה מאד. מחסור בהרבה דברים. חלק, מאסונות פרטיים השפיעו
        עליה: שני ילדיה הראשונים נפטרו ממלריה בגיל צעיר מאד. זה כנראה השאיר חותם
        די קשה עליה.
      </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >פעם, סיפרו לי, היתה שרה יפה. אבל בזמני אמא לא שרה. דווקא אז באוירה היה
        לשיר הרבה. אמרו לי שבצעירותה היא גם היתה מרבה לרקוד. היא סבלה ממלריה.
        החיים היו קשים. וכנראה לא היה מצב רוח לזה. בראשון ניגנו הרבה, אבל זה
        נחשב לבורגני.
      </span>
    </p>
    <p class="c4 c3" dir="rtl"><span class="c0"></span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >אמא ניהלה בית, עזרה במשק, חלבה פרות, גידלה ירקות, גידלה פרחים, ובאותה
        שעה כתבה ספרים. הפעלתנות התבטאה במישורים שונים, גם כעקרת בית וכפי שהיא
        בעצמה היתה חותמת בשם איכרה. היא היתה איכרה. לא עבדה בשדה או בכרם, אבל
        בשטח הביתי, במשק הביתי היא היתה מאד פעילה: היתה חולבת את הפרות, מטפלת
        בתרנגולות, מגדלת פרחים, מגדלת ירקות, ועקרת בית, אני חושבת, היתה טובה.
        וגם המצב החומרי הקשה אצל האיכרים באותו זמן הכריח אותה לחפש מקורות פרנסה
        נוספים. ואחד המקורות היה שאמא החזיקה פנסיונרים, בחורים או בחורות בעיקר
        מגרמניה, נוער ציוני שהתכוון לעלות לישראל. וחוץ מעבודה במשק שלה, גם בבית,
        היא גם היתה עסקנית ציבורית.</span
      >
    </p>
    <p class="c2 c3" dir="rtl"><span class="c0"></span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >היא, חוץ מהבית והכתיבה היא גם עסקה הרבה מאד בענייני ציבור, רשמיים ובלתי
        רשמיים. עזרה לחולים, לנדכאים, לנשים שסבלו מבעליהן וכולי וכולי. היתה גם
        חברת המועצה העירונית, חברת בית המשפט שהיה קיים שם. איפה היא מצאה את הכח
        – היתה אשה חלשה, חולת לב – איפה היא מצאה את הכח ואת הזמן לעשות את כל מה
        שהיא עשתה אני תמהה עד היום.  וכתוצאה מכל זה היא נפטרה מהתקף לב בגיל לא
        מבוגר ביותר – היתה בת 63.
      </span>
    </p>
  </body>
</html>
`;
export default page;
