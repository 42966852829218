const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c14 doc-content">
    <p class="c5" dir="rtl" id="h.gjdgxs">
      <a href="#fromZionistArch">
        <span class="c11">מתוך הארכיון הציוני</span>
      </a>
    </p>
    <p class="c5" dir="rtl">
      <a href="#AMKoler">
        <span class="c11">א.מ. קולר</span>
      </a>
    </p>
    <p class="c5" dir="rtl">
      <a href="#shlomoTzemach">
        <span class="c11">שלמה צמח</span>
      </a>
    </p>
    <br/>

    <h3 class="c5 c9" dir="rtl" id="fromZionistArch">
      <span class="c15">מתוך הארכיון הציוני</span>
    </h3>
    <p class="c5" dir="rtl">
      <span class="c7 italic"
        >זהו קטע מתוך עמוד כתוב בכתב יד, הנמצא בארכיון הציוני. איננו יודעים מי כתב אותו אך נראה שזו עדות של מישהו/י שהכיר/ה את נחמה פוחצ'בסקי:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"
        >נחמה פוחצ&#39;בסקי היתה הלוחמת האמיצה שנלחמה בעד זכות האשה בחיים
        ובחברה. ראשיתה של המלחמה היה &#39;צער&#39;. בכדי להגיע למטרתה התחילה
        ביסוד חברת "לינת הצדק" במושבה, כמתכֹונת חברות לינה הידועות והמפרסמות בכל
        רחבי הגולה: לסעד את החולה על ערש דוי בלילות בכדי לתת מנוחה לקרובי החולה
        העיפים מעמל היום. פעולות החברה הזאת התפתחו לאט לאט. החברה עזרה לחולים
        חסרי אמצעים ברופא ובתרופות חנם, ואח"כ גם בדברים הנחוצים לחזק את גופו
        ולאמץ את כחו של החולה המבריא. חברת "לינת הצדק" במושבה לא היתה רק חברת
        נשים, אליה נספחו גברים רבים מבני המושבה, אבל היא נוסדה ע"י נחמה
        פוחצ&#39;בסקי וחברות אחדות שעזרו לה. ביסודה הונחה מדת הרחמים והעדינות
        המיוחדה ללב האשה.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c6">...</span></p>
    <p class="c0" dir="rtl">
      <span class="c6"
        >ואלו הן פעולות סניף ראש"לצ של הסתדרות נשים ציוניות: א) תחנת טפת חלב,
        נוסדה בשנת התרפ"ח ע"י הסתדרות מדיצינית "הדסה" והסתדרות נשים עבריות.
        בתחנה מבקרים יותר משלש מאות ילד וכששים נשים הרות. ב) ארוחות חמות לילדי
        גני הילדים במושבה ובשכונת התימנים. ג). תמיכה כספית לנצרכים, לזקנים,
        לחולים וכו&#39;. ד) עזרה לתלמדים ברכישת ספרי למוד וכדומה. ה) מתן הלואות
        (בלי רבית) לנזקקים בתשלומים לשעורין (המקור: 1. קרן גיטל לובמן 2. קרן
        זילברמן 3. קרן עלפיה [?]. הון הקרנות 100 לא"י) ו) מלביש ערומים, בגד לשנה
        (בגדים חדשים מתקבלים מאמריקה  מ"הדסה" – ישנים מבני המקום. ז) איסוף מעות
        חטים וחלוקת מצה לעניים לחג הפסח ח) שעורי ערב לעברית לעולות חדשות
        ולתימניות. ט) שעורים בתפירה, גזרה ובשול י) תמיכה להחזקת מגרש המשחקים
        לילדי המושבה.</span
      >
    </p>
    <br/>

    <h3 class="c5 c9" dir="rtl" id="AMKoler">
      <span class="c15">א.מ. קולר</span>
    </h3>
    <p class="c5" dir="rtl">
      <span class="c7 italic">קטעים ממה שכתב </span
      ><span class="c2">א.מ. קולר</span><span class="c7 italic"> לספר</span
      ><span class="c12 bold"
        > מימים עברו (פרק חיים) – קובץ במלאת 40 שנה להופעת הפועל הצעיר</span
      ><span class="c7">:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"
        >        באותם חדשים ראשונים בארץ, במושבה ראשון-לציון, לא היתה כל פעולה
        תרבותית וציבורית בתוך הציבור הקטן של פועלי המושבה. לא ידענו ריב מפלגות,
        אם כי ידענו שבתוכנו נמצאים כמה חברי "הפועל הצעיר", וחברים-מספר
        מ"פועלי-ציון". הרוב הגדול היה בלתי-מפלגתי, ואף חברי המפלגות לא ניכרה
        בחייהם ובשיחם ובשיגם השתייכותם המפלגתית...</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c6">...</span></p>
    <p class="c0 c19" dir="rtl">
      <span
        >באותו זמן לא היינו מעורבים ביותר בין האכרים במושבה, אם כי לא היו בינינו
        דברי ריב ויחסים מתוחים. פשוט, לא היה שדה-פגישה בינינו. מלבד מעטים
        שהעסיקו בעבודתם פועלים יהודים – לא נפגשנו אתם גם בעבודה. אף בית-עם טרם
        היה במושבה. אך ידעתי פגישה מופלאה עם שנים מחוג האכרים: האכרה נחמה
        פוחצ&#39;בסקי ואחיה מרגולין. </span
      ><span class="c17 italic"
        >[דוד מרגולין, אחיו העוור של הקולונל מרגולין, היה בן בית בבית
        פוחצ&#39;בסקי]</span
      ><span class="c6"
        > מהימים הראשונים לבואי למושבה הכרתי את נחמה פוחצ&#39;בסקי, אשר בביתה
        מצא מקום אז מטבח הפועלים. היתה זאת אשה אצילת-נפש, אשר התקרבה לעדת
        החלוצים והתהלכה ביניהם כאם וכאחות. נפגשנו כמה וכמה פעמים בשיחה מתוך יחסי
        אמון וקרבת נפש. והרבה לבקר אותנו בבית מלוננו מרגולין, האיש אשר הגורל המר
        לו באין אור עיניו אתו. הוא שיתף עצמו בהתרחשויות שעברו עלינו והאריך שיחה
        על ענינים שהיינו חיים בהם.</span
      >
    </p>
    <br/>
    
    <h3 class="c5 c9" dir="rtl" id="shlomoTzemach">
      <span class="c15">שלמה צמח</span>
    </h3>
    <p class="c5 italic" dir="rtl">
      <span class="c7">זהו קטע מתוך כתבה של שלמה צמח שהופיעה תחילה בעיתון </span
      ><span class="c1 bold">דבר</span
      ><span class="c7"
        > כחלק מסדרה שנקראה "שנה ראשונה".  יותר מאוחר נכללה בספר בשם זה. הכתבה
        מספרת על המלחמה בין &#39;אוגנדיסטים&#39; ל&#39;ציוני-ציון&#39; בראשון
        לציון של תחילת המאה העשרים (1905-1903), יש בה התייחסות למאבק על העברית
        ולבסוף תאור של ביקור בבית פוחצ&#39;בסקי ודיון שנערך שם בענין הקמת אגודה
        שתפעל נגד רעיון אוגנדה.</span
      >
    </p>
    <p class="c5 italic" dir="rtl">
      <span class="c7"
        >נמליץ לקורא לשמור בזכרונו את עובדת היותו של שלמה צמח איש העליה השניה
        ובתור שכזה בעל התנגדות אפריורית לכל דבר הקשור לעליה הראשונה, וכמו כן את
        נטייתו של הכותב לתת דרור לדמיונו הפורה. ובכל זאת:</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c18">דבר</span><span class="c2"> מרץ 29 1951</span>
    </p>
    <h3 class="c20 text-center" dir="rtl">
      <span class="c18">ראשית מדון</span>
    </h3>
    <p class="c16 text-end" dir="rtl">
      <span class="c2">(מן הסדרה "שנה ראשונה")</span>
    </p>
    <br/>
    
    <p class="c4" dir="rtl">
      <span class="c2"
        >... מורה אחד שבבית־הספר נתן לי חוברת "השלוח" בה ראה אור המאמר "משה", 
        וכיון שהסכים הוועד שאקראהו במוצאי שבת, הריני יושב לאור המנורה ומתרגמו
        לאידיש. למה אכחד תחת לשוני. היסס לבי הרבה ומלאכתי מאד בזויה בעיני. בארץ
        ישראל, במושבה יהודית יושב אני ומתרגם דברי אחד העם ללשון הקרויה בפי
        "ז&#39;רגון" </span
      ><span class="c7">[יידיש] </span
      ><span class="c2"
        >ושאני רותח כנגדה ונלחם בה. ודוקא בימים אלה, בתוך אש הויכוחים המתלקחת
        לאחר הכרוזים שיצאו מטשרנוביץ. ובשעה קשה זו,משנסתמו כל מעיינותיה של
        השרידה־היחידה ואין לה שיור אלא פינה נידחת זו בהייטשיפל, שם הקים ברנר
        בשארית כוחותיו מבטחה האחרון ב"המעורר" שלו. ואף על פי כן אי אפשר לי לנהוג
        אלא כפי שאני נוהג. לא ייתכן שאשב בחיבוק ידים בשעה שהללו, יאפו והדומים
        לו, חורשים מזימות ואוספים כוחותיהם להפגין בקונגרס כחשם ומעלם.</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >כל שעות מנחה בשבת הייתי משמיע לעצמי הדברים שתירגמתי, שיהיו שגורים בפי
        וארוץ בהם בזמן קריאתם, ומכוון קולי להטעים מקומות כדאים להטעמה. שכן קצת
        ערמה בבחירה שבחרתי להביא את "משה" של אחד־העם לפני הקהל לאחר שאסרו עלי את
        הויכוח הגלוי. וכל כך נתפסתי לשינוני שלא השגחתי בשעות החולפות. ואחרתי
        הרבה ולא היה סיפק בידי אלא לטעום משהו בחטיפה וללבוש חליפתי הכחולה ולצאת
        בפסיעה גסה לבית־העם. מלא האולם תושבי המקום וארבע מנורות דולקות ויושב מר
        יאפו, עם פני הבי־מינן שלו, על הבמה וממתין לבואי. משהבחין בי היסה את הקהל
        וביקש ממני לעלות ולפתוח. מלמעלה מקיף מבטי את הנאספים שלפני. הנה הציץ
        ראשו של מר דרובין גבוה מן השאר. מיטיב עמי מרחוק ומחייך. בתוך משפחתו הוא
        יושב. אשתו מימינו ובתו משמאלו. על ידו שמואל אוסטאשיסקי ועיניו העגולות
        שלוחות אלי ואף הן מחייכות. ובצדו אשתו הגוצה לוחשת משהו על אזנו. אפילו
        בן־זאב כאן וחבריו למשחקים בשכנותו. על כל הספסלים מאוושות בנות המושבה
        המזדמנות לי בטיוליהן בערבים. והרי הגביה הכורם הרומיני והטיל בי עיניו
        הדומעות דם. אף מר פריימאן הננס, מייסד ראשון לציון, עם עיניו הקאלמיקיות
        יושב בשורה הראשונה ומסתכל בי. ונפלה עלי אימתו של ציבור זה. לא הייתי
        כבד־פה וגם איני חסר-כשרון דיבור ובכל זאת פותח אני ברתת ובגמגום ובלחש  -
        ‬"ווען איר טו זיך צו־תערען צו וואס די פארשער פילאזאפירן (כשאני שומע את
        החוקרים מתפלספים."(&#39;‬ פתאום פולחת מחשבה במוחי ומזהירה בי ומזרזת
        אותי: אסור לך להיכשל! מיד חוזרת הבטיחות לקולי ואני קורא כמחצית השעה ללא
        תקלה את המשפטים הסדורים, וחש שקהל שומעי מטים אוזן לדברים, תופסים הרמזים
        הגנוזים בהם ונגררים אחריהם. עד שהגעתי אל הסוף, שאני אומרו במקורו ומחזק
        קולי - והעולה על רוחכם היה לא תהיה. אשר אתם אומרים נהיה כגויים. חי אני,
        אם לא ביד חזקה אמלוך עליכם ."!‬אשה אחת מחאה כף. אבל איני יורד מן הבמה
        אלא מפנה גופי אל מר יאפו ושולח דברי אליו: "רעיונות אלו של אחד העם
        מתכוונים אל בני אדם שכמותך. לפני שמונה ימים עמדת כאן ואף נפשך עיפה, גם
        אתה משתוקק להווה של חיי שעה ואינך חס על העתיד הגדול ועוקרו מלבך. במקום
        זה ומעל במה זו - - - ."‬פתאום נפסקו דברי באמצע. הניח מר יאפו ידו על כתפי
        ואומר לי בנחת: "אין אנו מרשים לפתוח בויכוחים."‬ עומד אני על דעתי, שאיני
        מתווכח אלא מפרש הרעיונות שהשמעתי. מר יאפו בשלו. והנה קופץ מר דרובין,
        השקט והמכונם בתוך עצמו והייגע, ובלשונו המגורמנת הקורלאנדית קורא בקול:
        "מדוע אינך נותן לו לדבר."?‬ מן הקצה האחר של האולם משיב לו קול: "די !
        איננו רוצים לשמוע"!‬  מישהו עולה על ספסל ומכבה מנורה אחת. קהל הנאספים קם
        על רגליו. מר יאפו חוטף כובעו ומסתלק מן הבמה. שוב מישהו עולה על ספסל
        ומכבה מנורה שניה. אפלה למחצה ואנדרלמוסיה והתרגשות גדולה באולם. אבל אינו
        דומה מעמדי עתה למעמדי אז לפני חדשיים, בערבי הראשון, בביתו של בן־זאב. אל
        אזני עולה קולו הצלול והדק של אוסטאשינסקי מתעצם ומוחה ומאיים וצווח: "מי
        אתם?! אין לכם רשות לדבר בשם הישוב! סאמוזוואנצים   ."‬ על ידו מחרים
        מחזיקים דרובין ובני משפחתו ועוד כמה כורמים שאני מכירם בפניהם ואיני יודע
        שמותיהם. ואפילו כמה צעירות מבנות המושבה נצטרפו אליהם. וכשירדתי מן הבמה
        וקרבתי לחבורה זו הכל לוחצים ידי ומאמצים רוחי, ואנו יוצאים בציבור אל תוך
        המושבה, ואשה זו שמחאה לי כף, מרת פוחצ&#39;בסקי, מזמינה הכל לביתה. אחת
        הבנות פותחת בשיר קולה: "חושו, אחים, חושו,&#39;&#39;‬ והכורמים זוקפים
        קומתם כאילו חוזרים אליהם ימים ראשונים ופוסעים בפסיעה מאוששת במעלה הרחוב,
        וסופם שהם מצטרפים לשירה וחבורתנו הופכת
        הפגנה.‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >אצל בית בנוי על תל קטן מאחורי בית־הכנסת הקביל פנינו מר פוחצ&#39;בסקי
        שאינו איש רעים, ופתח לנו דלתו. לא ידע מה שאירע ותמה ואין סבר יפה בפניו.
        בן אדם כחוש הוא ומחמת רזונו נראית קומתו גבוהה משיעורה, וגביניו אפורות
        וסבוכות וכבושות ודומה על כל העולם הוא קובל. ראשו קרח ועור קרחתו המסגילה
        עולה ויורד ברתיחה בשעת דיבורו, ואפילו כשהוא מסכים עמך ומתרצה עליך דומה
        גוער הוא בך ורוטן עליך. אבל נצנצו מתחת הגבינים הכבושות שתי עיני תכלת
        ביישניות ובהולות ושלוח מבטן באלכסון, ויש בהן יותר מורא ופחד מקפידה ורוע
        לב. משנכנסנו  למרפסת מיד ונדחק לצדדים והתכנס לתוך עצמו מפני ידיה
        העסקניות של בת־זוגו. מרת פוחציבסקי היתה כל ימיה, משחר ילדותה עד היום
        הזה, יפת תואר ובעלת מזג לוהט ועל שתי מידותיה אלו גאוותה בדעת ובהכרה. אף
        משכילה היא. בטס הנחושת הדמשקאי הגדול מונחות חוברות "רוסקוייא בוגאטסטבו"
        ו"השִלוֹח" זו בצד זו. וכותבת היא סיפורים מחיי הארץ ודיבורה העברי רוהט
        ומצלצל בין שפתיה: ואולי בעמידה צנועה זו בצל זוגתו עצירה ותרעומת גנוזה
        שבהליכות בעלה. אלא משהגיעה מרת פוחצ&#39;בסקי לשנות בגרות מלאה והתחילו
        נראים רמזי בלייה ראשונים בגופה, הריהי מחפה עליהם בסאה גדושה של משובת
        נעורים ותנועה רבה וגנדרנות יתירה. עכשיו שנמתח פרצופה יש משהו של צפור
        בפניה, צפור נאה ומקושטת בנוצות פאר  ומנתרת מענף אל ענף ללא מנוחה ומרגוע,
        מחמת ברק עיגול זה ובליטתו שבעיניה החומות, ומחמת חוטם ישר ודק ואפור זה,
        שבשעת דיבור דומה אין מתחתיו פה וסנטר אלא נפתח הוא כמקור ומצייץ
        והוגה.</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >ישבנו כמניין אנשים על כסאות סמוך לשולחן וערביה מביאה לנו כוסות תה. מר
        דרובין אומר לי, שחברו הכורם לובמאן רוצה בי שאזמור לו כרמיו ויש אצלו
        עבודה לשבועיים ועצתו שאלך אליו, ואחר הוא מלגלג ופונה אל מר
        פוחצ&#39;בסקי:</span
      >
    </p>
    <span class="dashed_bullets">
    <ul class="c3 lst-kix_list_1-0 start">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">הוי זהיר, צעיר זה עוד מעט וידחה אותנו, הקשישים.</span>
      </li>
    </ul>
    <p class="c5" dir="rtl">
      <span class="c2"
        >מר פוחצ&#39;בסקי שגנן חשוב היה, ומן המחדשים ומרבה בשיטות ואמצאות, הטיל
        בי גביניו הכבושות והסתכל במתחרה שלו ולא אמר כלום. מאד נקלה הייתי בעיניו
        שיחשוש למעמדו מפני. אבל מיד לאחר שתיית חמים מבחין אני במסובים שמצפים
        למשהו חשוב שיבוא ולא נזדמנה החבורה באקראי למקום זה. בעלת־הבית ישבה בראש
        השולחן, החליקה בשתי ידיה על תסרוקתה המוגבהת וזיעזעה מעט צווארון חולצתה
        הלבנה. הצטחקה מתוך מבוכה, ואותה בליטה שבעיגול עיניה מצטמצמת והיא פותחת
        מקורה ואומרת:
      </span>
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">יש תשובה מרחובות, הם מסכימים ואף משה מסכים. </span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2"
        >מה כתבו לרחובות ומי משה זה שהסכים ולמה הסכים לא ידעתי ואף דברי
        אוסטשינסקי סתומים ואינם מחוירים לי כלום:</span
      >
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">נתחיל איפוא מן המושבה שלנו. </span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2">דרובין נכנס באמצע:</span>
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">דיברתם עם מאירוביץ? </span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2">כורם אחר משיב לו: </span>
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">שוחחתי עמו ארוכות, וכדרכו, לא הן ולא לאו. </span>
      </li>
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">בושה! - נתכעס דרובין. ‬‬‬</span>
      </li>
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >אמרתי לכם, חתך אוסטשינסקי וחייכה בת צחוק של ערמה קלה בעיני התכלת
          המגודלות שלו.
        </span>
      </li>
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >- ודאי. כדרכו. לא הן ולא לאו. הריהו אגרונום, מומחה גדול. וכשאתה
          שואלהו משהו, לעולם תשובתו לא הן ולא לא. - נהם מר פוחצ&#39;בסקי תחת
          שפמו העבות והאפור.
        </span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2"
        >זוגתו נענעה ראשה ושוב החליקה בשתי ידיה על תסרוקתה וזיעזעה את הצווארון
        שבחולצתה:
      </span>
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">אתה תמיד עם האגרונומיה שלך. </span>
      </li>
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >אף על פי כן, הוסיף אותו כורם שבא בדברים עפ מאירוביץ, דומני, סוף סוף
          יתן ידו לפריימאן וליאפו.
        </span>
      </li>
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >איו שום דבר מוחלט, הכל במה שתאמר פאריס, לגלג אוסטאשינסקי.
        </span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2"
        >עכשיו נכנס לשיחה אדם קטן צהבהב כבן ששים, שחטמו ארוך וזקנו מדובלל וחציו
        שיבה וחציו ברזל שהחליד ומשקפים מתחת למצחו המקומט, ומדבר הוא בנחת וכל
        הפנים פונות אליו:
      </span>
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >שמועות שמעתי. פאריס אינה רואה בעין יפה עסקנות זו של פקידיה.
        </span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2"
        >נהרו פניהם של כל המסובים. בעלת־הבית הרגישה בי, בנכר שבישיבתי היתומה
        והתחילה מסבירה לי את הענין. הללו שנתאספו בביתה ציוני־ציון שבמקום הם.
        בכוונה תהילה הלכו לבית־העם לשמוע הרצאתי, כי שיערו שלא תיגמר ללא תקלה.
        עכשיו נזדמנו יחד לפתוח במעשים. אומרים לשלוח את משה סמילאנסקי לקונגרס,
        והוא נתן הסכמתו לכך, ואין עוד לדחות מכירת השקלים הציוני־ציוניים. ודאי
        אצטרף אליהם.</span
      >
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >כלום אין שם זה, ציוני־ציון, פוגם ? - היססתי ושאלתי.</span
        >
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2"
        >הכרתי בעקימת שפתיה של מרת פוחצ&#39;בסקי, ששטופה היתה בחום עשייתה, שאין
        היא נהנית הרבה מפקפוקי. ואולי חלף הרהור בלבה כי אמנם משהו מן האמת ברינון
        שמרננים אחרי בחורים הללו שהתחילו לבוא לארץ, שחטטנים הם, מרי נפש הם, ואין
        דבר משביע  רצונם, והכל פסול בעיניהם וכאילו כל הקיים תקנה אין לו. משכה
        בקצות השרוולים הנפוחים שעל גבי כתיפיה ואמרה:</span
      >
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2"
          >וכיצד תעשה, אם רצונך לבחור במשה סמילאנסקי ? – והיתה תרעומת
          בקולה.</span
        >
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2"
        >חלשה דעתי. הרי הדין עמה. באמת, כיצד אעשה? ולבי נוקפי על ההיסוס שנפלט
        מפי. מיד והוציאה מתוך מעטפה כמה חוברות שקלים. נתנה אחת לכל אחד מהמסובים,
        הסבירה מה לרשום והיאך לרשום, ואחר ביקשה שימנו מעין ועד של שלושה שיתעסק
        בדברים ויכוונם. לאחר כמה סירובים נתמנו בהסכמת הכל - מרת פוחצ&#39;בסקי
        בראש הועד ואותו כורם שנשא ונתן עם מאירוביץ ואני - שני סגניה. ומר דרובין
        משמש גזבר לנו. מישהו הביע רעתו:</span
      >
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">אולי נקרא לאסיפה ?</span>
      </li>
    </ul>
    <p class="c4" dir="rtl">
      <span class="c2">אבל מיד והכל מיחו:</span>
    </p>
    <ul class="c3 lst-kix_list_1-0">
      <li class="c5 c8 li-bullet-0" dir="rtl">
        <span class="c2">בשום פנים לא אסיפות.</span>
      </li>
    </ul>
    </span>
    <p class="c4" dir="rtl">
      <span class="c2"
        >ונתפזרנו לבתינו. לא נחה דעתי מאגודה זו שנתאגדה הערב ואני בועד שלה. הרבה
        שעות הייתי מהלך בחשכת המושבה באותו לילה, והיו עצבות ויגון בלב, כבלבו של
        אדם שסר מן הדרך אשר בחר בה. חיי כלל כחיי פרט. פעמים אחד עושה מעשה
        רב־הכרע בחייו ורואה שטעה, ואף על פי שמתקן הוא כל ימיו את המעוות תקנה אין
        לו, ואפילו כוונותיו הטובות ומעשיו הטובים על כרחם שהם טבועים בחותמו של
        אותו חטא קדמון שהוצנע ביסוד הדברים. חטאת הישוב יש בה מקדמוניות זו ואין
        לה תקנה אלא בשינוי ...</span
      >
    </p>
    <p class="c16" dir="rtl">
      <span class="c2">ש. צמח</span>
    </p>
    <p class="c0 c10" dir="rtl"><span class="c13"></span></p>
  </body>
</html>`;

export default page;
