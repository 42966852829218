const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c0 italic" dir="rtl">
      <span class="c7 bold">גליונות</span
      ><span class="c3"
        >  תרצ"ט 1939 (כרך ט&#39; עמ&#39;  78– 79. גליונות היה ירחון ספרותי עברי
        שיצא לאור בשנים 1954-1934)</span
      >
    </p>
    <br />

    <h3 class="c6" dir="rtl" id="h.q8py7fk1c9b2">
      <span class="c4">נחמה פוחצ&#39;בסקי</span>
    </h3>
    <p class="c0" dir="rtl">
      <span class="c8">למלאת חמש שנים למותה – ז&#39; בסיון תרצ"ד</span>
    </p>
    <br />

    <p class="c0" dir="rtl">
      <span class="c2"
        >בשקט ובצנעה הלכה לעולמה לפני חמש שנים בראשון-לציון. לא הוספדה כראוי. גם
        יצירתה נשארה חבויה בצל. אמנם לא הרבה פרסמה.  בסך הכל שני קובצי ציורים
        וספורים: "ביהודה החדשה" (יפו, תרע"א) ו"בכפר ובעבודה" (תל-אביב, תר"ץ),
        וקצת סיפורים ורשימות בכתבי-עת שלא הגיעו לידי כינוס, ואולם כל מה שיצא
        מתחת עטה טבוע בחותם האמת הנפשית ושופע אהבה בלי גבול לארץ המתחדשת
        ולבוניה. סבלם ויסוריהם של היחידים הבודדים הבונים את חייהם החדשים על אדמת
        המולדת; מחאה נגד העוֶל והאכזריות שהחיים גדושים בהם; חייהם של אחים
        תימנים, עבודתם וחויותיהם; הכמיהה והגעגועים לאהבה – אלה הם הנושאים של
        סיפוריה ורשימותיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        הציורים שלה בקובץ "ביהודה החדשה" – חוט של יסורים מתוח עליהם,
        יסורי חיים אכזריים, הנושאים בכל זאת בחובם את גרעיני החדש והיפה, את
        ארץ-ישראל החדשה. גבורי הציורים האלה – אנשי דור המעבר: בת הישוב הישן
        היוצאת מבין החומות (דינה בציור "העבדות"); אשה שעברה את כל מדורי התנועות
        המהפכניות בחוץ-לארץ עד שספינת חייה הטרופים הגיעה ארצה (אלברשטט
        ב"גוססת"); העולה מתימן, שקשה לו להסתגל לארץ והוא עדיין חולם על סיר-הבשר
        אשר בתימן ורואה את המולדת כגלות חדשה (מוסה ב"ג&#39;לות"). הסבל של גבוריה
        – האנושי והטרגי ביותר, שנפש האשה ביחוד מסוגלה לחדור לתוכו: מות ילד אהוב
        ויקר, שרק נשמת אם עלולה כל כך להזדעזע מפניו ("האבדות", "סימה רסקין");
        בדידות של אשה, שחייה עוברים עליה מתוך צפיה וערגון לאושר משפחתי שאיננו
        (צפורה ב"בדידות", בקובץ "בכפר ובעבודה"). מזעזעת הרשימה "פעמים" על טוביה
        התימניה המוכרחה להפרד ממימון אוהבה, כי גרושה אסורה לכהן, ומימון כהן היה
        (מעין "קוצו של יוד" בזעיר אנפין).</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        ברשימה "בלעדיה" (ב"השלוח") מתארת פוחצ&#39;בסקי את כוחה של האהבה
        לאשה, שאין אהבת העבודה והקשר למשק יכולים להתגבר עליה. בנפשו של ווינהולץ,
        הכמֵה לאהבת אשה ולאושר אב, ישנה מלחמה פנימית, הוא חושב לפעמים, כי "יש לו
        גם מולדת, גם קרקע. בית וגן ושדה ומושבה חביבה ועבודה ואידיאל נהדר, כלום
        כל אלה אינם יכולים להכריע אהבת אשה ותשוקת בנים?" ואולם כל האשליות האלו
        אינן אלא עד ארגיעה. קטן הוא האדם, ובלי אשרו האישי, הפרטי – מאבדים את
        חינם את חשיבותם כל שאר הערכים כמו מולדת, עבודה, משק. אור האהבה לאשה
        ולבנים מאציל עליהם מזיוו, וכשאור זה נגנז – הרי גם ערכם מתנדף. ואין פלא
        אם אחרי מותה של יהודית, אשתו האהובה של האכר ווינהולץ, שוב אין הוא יכול
        להשאר על נחלתו ולעבד אותה. השאלה שהתקשה בה לפני כן "מה עדיף ממה, האדם או
        עץ השדה" – נפתרה. בפרובלימה זו נוגעת פוחצ&#39;בסקי גם בסיפורה "בצל
        הקבוצה" (בקובץ "בכפר ובעבודה"). תמרה, למרות מסירותה הרבה לקיבוץ ולמשק
        שלו, אינה יכולה לוַתר על אשרה הפרטי, האושר להיות אהובה על-ידי הגבר שהיא
        נמשכת אחריו. תמרה יודעת "כי גורלה שזור לעולם בגורל האנשים הטובים
        והטהורים האלה, ההולכים שחוח תחת נטל הדאגה, המתחדשת עליהם בכל יום תמיד"
        (עמ&#39; נ"ח), ואולם אין היא יכולה להשלים עם העובדה שהגבר, שהיא אוהבת
        אותו, התקשר עם חברתה. היא מתביישת בפני עצמה ברגשות קנאה אלה, שהיא רואה
        בהם בגידה בעיקרוני הקבוצה, ומוסר כליותיה מיַסר אותה: "מדוע היא כה רעה,
        כה רעה שקשה לה לראות באשרם של אחרים" (עמ&#39; ל"ד).</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        הסיפור "בבדידות" (בקובץ "בכפר ובעבודה"), הוא מצבה להתלבטויותיה
        ולסבלותיה של בת-ישראל חלוצית ונאמנה לתקוות העם, בתוך האוירה הגלותית של
        המושבה בארץ, המתנכרת לעבודה העברית. מבין השורות של הסיפור פורצת האימה
        מפני קשיחות הלב של אחים לאחים ועולה המחאה נגד החטא של עבודה זרה, הסופרת
        מציינת אותו במלים פשוטות אלו: "משק יהודי אשר כולו נוצר בידים זרות, נושא
        עליו כתם עולמי".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        את האהבה הבריאה והטבעית לאדמה מתארת פוחצ&#39;בסקי ברשימתה
        "בימים ההם" (התקופה י"ח). עומד לו כורם באחת ממושבות יהודה בימי המלחמה
        העולמית ומטפל בגינתו, אף-על-פי שברור לו, כי מחר יגרשו את כל האכרים מן
        המושבה. הוא קם בחצות הלילה להשקות את השיחים הצמאים, חופר תעלות המוליכות
        את המים לתוך הגומות. ומתוך המרחק נשמעות יריות תותחים, "למַטַה משק-מים
        קל, למעלה הלמות תותחים כבדים וחרדים הכוכבים, חרדים למראה" (שם עמ&#39;
        99).</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        אמצעי הסיפור והציור של פוחצ&#39;בסקי פשוטים ומוגבלים ביותר. אך
        יש בהם חן, חן פשטות. כל משפט, חותם של חויה עצמית טבוע עליו. הקורא
        בחוץ-לארץ היה מרגיש בכל ציור ורשימה שלה מריחות השדה והכרם של המולדת, שכל
        כך נכסף אליה. ברשימתה "בימים ההם", היא מתארת במלים אלו את שתיל
        עגבניות-הסתו: "שיח קטן... שיח נמוך, שאינו בר-קימא, אלא שפריו נאה ונהנים
        על טעמו הטוב במשך חדשים אחדים עד כלות צמיחתו". שתיל כזה, שיח נמוך שנהנים
        מפריו, היתה גם נחמה פוחצ&#39;בסקי בספרותנו. לא בין האילנות מקומה. היא
        היתה מן השיחים הצנועים העושים פרי מפירות הארץ. אלה שקראו את סיפוריה
        ורשימותיה, החדורים אהבה ורחמים של לב אשה, זכור יזכרוה לברכה.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c2">ג.א.</span></p>
    <p class="c0" dir="rtl">
      <span class="c2">[אלקושי]</span>
    </p>
  </body>
</html>`;

export default page;
