import { createBrowserRouter } from "react-router-dom";

import Item from "../components/Item";
import Page from "../components/Page";
import pages from "./pagesContent.js";

// set pages childrens
(() => {
  pages.map((page) => {
    page.items.map((item) =>
      page.children.push({
        path: item.itemUrl,
        element: <Item isOpen={true} {...item} />,
      })
    );
  });
})();

// console.log("after setPagesChildrens pages", pages);

const router = createBrowserRouter(
  pages.map((page) => {
    //console.log("router page =", page);
    return {
      path: page.path,
      element: <Page {...page} />,
      children: page.children,
    };
  })
);

export default router;
