const page = `<body class="c3 doc-content">
  <p class="c0" dir="rtl">
    <span class="c1"
      >האתר נבנה בעיקר על בסיס חומרים הנמצאים ברשות המשפחה וחומרים שהועתקו
      מעיתונות התקופה.
    </span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1"
      >יש גם חומרים שנמצאו בארכיונים שונים ובספרים שונים – בכל המקרים האלה צויין
      המקור.</span
    >
  </p>
  <p class="c0" dir="rtl" id="h.gjdgxs">
    <span class="c1"
      >אנחנו רוצים בזאת להביע את הערכתינו העמוקה ותודותינו לספריה הלאומית על
      איסוף, שימור והנגשת העתונות בת זמנה של נחמה פוחצ'בסקי לציבור הרחב.
    </span>
  </p>
  <br/>
  
  <p class="c0" dir="rtl">
    <span class="c1">איסוף, שימור והנגשת החומר המשפחתי: טלי אבישי</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">איסוף החומרים הנוספים: טלי אבישי ואסנת ארבל</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">עריכת האתר: אסנת ארבל</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">בנית האתר: עילי מאור, 2024</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">אפשר ליצור איתנו קשר במייל: osnatarbel@012.net.il</span>
  </p>
</body>`;

export default page;
