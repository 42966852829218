const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c9 doc-content">
    <p class="c3" dir="rtl">
      <span class="c1"
        >תמהון ודבת-רבים העירה העלמה דרורי, בעת שעזבה פתאום את ביתה ואת נחלתה
        וקבלה משרת מבשלת במטבח-פועלים באחת החוות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באמצע החורף חלתה בקדחת קשה והובלה לבית-החולים העירוני.  לשם קראתני לבוא
        אליה.  רופא בית-החולים אמר לי, שאין שום סכנה במצבה, אבל צפורה עצמה חשבה,
        שהיא הולכת למות, ותמסור לי מפתח קטן, באמרה בפשטות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אינני יודעת, איך ישתלשל מהלך המחלה.  אפשר שאמות, אז תואילי נא לדאוג
        שימציאו לך את ארגזי מן החוה.  את הלבנים והבגדים תחלקי למי שתמצאי לנחוץ;
        את התמונות והספרים תשיבי לאחי ואת כתב-היד הנמצא שם, הוא היומן שלי, קחי
        לך, ואם ראוי הנהו לדפוס, השתדלי לפרסמו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >צפורה דרורי עובדת עתה באחת הקבוצות בגליל.  את יומנה "בבדידות" שלחה אלי
        לעשות בו כטוב בעיני.  ואני, אחרי אי-אלה שנויים קלים, מוסרת אותו כאן
        לדפוס.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <h1 class="c6" dir="rtl"><span class="c14">א</span></h1>
    <p class="c3" dir="rtl">
      <span class="c2">ר"ח שבט.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החולת-רוח אני?...  וכי למה התחיל טורדני הרעיון על-דבר פטורים מן החיים?
        לילות שלמים אני מתלבטת בשאלה, אם להיות או לחדול, וכשמכריע רעיון החדלון,
        עומדות עלי עיני ההורים מתמונותיהם הגדולות, התלויות נגד מטתי, ומביטות בי
        בתוכחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והאויר סביבי מחניק, מחניק.  – הנה אסף אחי לעבודת-החורף חבורה גדולה
        ממשפחה מיוחסת שבכפר הקרוב והנהיג, שאסור לנגוע אף נגיעה קלה בכבוד
        מי-שהוא, אף בכבודו של זה הרשלן הגדול, חג&#39; מחמוד, שעצלותו עוברת כל
        גבול. מתרגזת אני ביחוד בבוקר, כשכל החבריה עומדת שעה ארוכה בבטלה ומחכה עד
        שמוסטפה ירתום את הסוסים, ישים את המחרשה ויתר כלי-העבודה בעגלה והם יעלו
        לנסוע.  ללכת ברגל אל הכרם הקרוב לא נאה למשפחת אבו-חסן!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחצר נשאר בנו של מוסטפה לחטוב עצים.  ואני יודעת ברור את המעשר, שהוא ישא
        אל הכפר מכל טוב המשק שלנו, החל מן הביצים, שהתרנגולות מטילות במדה זעומה
        בתחלת החורף, ומירקות-הגן הראשונים, וכלה בשעורה וסובין, שקונים בכסף
        מלא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לעתים אני מנסה בזהירות לנגוע בשאלת העבודה העברית ובחובתנו להקליטה לפחות
        במקצת לתוך עבודת-המשק שלנו, שבה עסוקות כל כך הרבה ידים זרות, ואחי לועג
        ומלגלג לדברי. פולמוס על השאלה קם בעתונות, אומרים לחיב את הישוב להתחשב
        בה, אבל הקהל שלנו לא יכנע, ולא יכנע ביחוד צעיר כעמרם דרורי, החזק, התקיף!
        "לך אין מושג – אומר הוא לי – כמה פועל עברי יכול לקלקל!"... שנאה כבושה
        מתעוררת בי לפעמים לשמע דברים כאלה, אבל אחר-כך אני מתחרטת על הרגשתי הרעה
        לאחי יחידי, אשר כל חיי מוקדשים לו, חוזרת אני בתשובה ומבקשת את
        אלהי-הרוחות להשיב את האהבה ללבי. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואמנם זו שבה לאט לאט אל כנה, אבל מנוחת נפשי אינה רוצה לחזור, והמשק
        שלנו, העבודה וכל ההויה של עלמה בת-ארבעים נמאסים עלי.  אני מחליטה, שמשק
        יהודי, אשר כולו נוצר בידים זרות, נושא עליו כתם עולמי, ואני, שהשתתפתי
        בבנינו בצורה זו, - אני נושאת עלי את החטא ואין לי זכות-קיום
        בעולם...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חולשה נופלת עלי ואני רואה את עצמי עומדת על גבול החיים בלי אור, בלי
        אמונה בנחיצות קיומי ובלי הכח הדרוש להפסיקו. לא נשאר לי בלתי אם להניח את
        המשוט ולתת לסירתי להנשא על-פני הגלים כרצונם, – היא תשוט לה לאשר ישאנה
        הרוח, עד שתגיע אל סלע ותנופץ. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכאב כבוש אני עומדת על-יד חלוני. הנה שם על-פני הפרדס מעופפת עדת-צפרים
        רבה, התנשאה מזרחה וכרגע שבה מערבה, נוטה פעם ימינה, פעם שמאלה ומעגולה לא
        תזוז. – הכי גם עדה שלמה זו אינה יודעת, לאן דרכה נטויה?... ופרחו הצפרים
        ונעלמו,  ובי נרדם הכל.  אין לפני כלום, וכלום אין אחרי.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">ב&#39; שבט.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יום גשם; גשם ברכה, גשם זהב! לשוני חוזרת על השורה הקטנה שבשמונה-עשרה:
        "משיב הרוח ומוריד הגשם", אחר-כך: "מכלכל חיים בחסד"... ב"מחיה מתים" הלשון
        נעצרת.  ויום זה, אחרי שכל  הכרמים שלנו נחרשו יפה-יפה, יכול היה להיות לי
        לחג, לולא המקרה הרע, שנכשלתי בלשוני ודברתי באזני אחי סרה על מוסטפה, שגם
        הוא, במחילת כבודו, עצלן גדול.  אמנם זו היתה עזות מצדי ועל כך קבלתי עונש
        קשה! –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עמרם לא אכל את ארוחת-הבוקר שלו; צער גדול הצטערתי.  אבל מצאתי נחומים
        בזה, שהרי מכינה אני ארוחת-צהרים שמנה וטובה מהמאכלים שעמרם אוהב אותם
        ואכול יאכלם בתאבון כפול.  והנה שגיתי: גם בצהרים לא טעם דבר! כל בקשותי
        ותחנוני לא הועילו, הוא באחת: "איני רוצה!" וחסל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מדוע זה, עמרם, מדוע אתה מעניש אותי ואותך יחד? – שואלת אני בלב
        מורתח.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- את מקלקלת את עצבי ומרעלת את דמי! – היתה תשובתו ולא הוסיף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשנסיתי לבקש באורים לדבריו, ולשוני פלטה בלי-משים הברה שלא לפי רוחו –
        ברח בלי כובע אל השדה באמצע המטר הסוחף.  בלכתו התעבּבו השמים עוד יותר,
        רעם אחרי רעם התגלגל והחריד את האויר, ולבי אף הוא חרד ודפק: יתקרר הנער
        ויחלה.  מה לעשות, מה לעשות? ובקושי כבשתי את יצרי מלרוץ אחריו אל השדה
        ולהחזירהו הביתה.  נשארתי על יד החלון מתענה ומיסרת את עצמי, שאני, רק אני
        אשמה בסבלו של הנער, – אני הרעה והרשעה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עברה שעה קלה והנה נשמעו צעדי עמרם בגן.  הוא עלה על מדרגות, נכנס ועבר
        ישר אל חדרו.  כשבאתי אליו כבר מצאתיו שוכב מכוסה מעל לראשו בשמיכתו
        העבה;  בחדר היו פזורים בגדיו הלחים, שהמים נגרו עוד מהם על הרצפה רצועות
        רצועות.  בלי-אומר אספתי את הבגדים, נגבתי את הרצפה והבאתי לפניו את
        ארוחתו.  עמרם עדיין מסרב, נושך בשניו את השמיכה וזורק עלי מבטי-זעם מתוך
        עינים אדומות.  על הפצרותי, לגמור את הצום של היום, נשמעה אך תשובה חנוקה
        אחת: "לא!" ואולם עוד נמצא אתי אוצר של מלים מבדחות לפיסו ולשדלו, עד
        שהתרכך. לא אכל אמנם בפני, אבל את דבר התפיסותו בשר לי אחר-כך קשקוש המזלג
        בצלחת – ועקת הלב הוקלה. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מנסה אני לבקש זכות על אחי: אין העלם אשם בזרות הנהגתו ושגעונותיו.  כי
        אלה באו לו בירושה... ואפשר – אפשר שהרבה אשם רעבונו לאהבה... וגם זה לו
        בירושה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אמנם אין להאשים את עמרם על שגעונותיו – וגם לא על יחס הבטול שלו לגבי
        האשה.  את זה רכש לו מהסביבה ואינו יכול בשום-אופן להשתחרר מן הדעה השולטת
        בה, כי אין האשה מוכשרת לשום משק, חוץ מן המטבח.  כשגנתי עולה כפורחת, הוא
        מבקר אותה חריפות; ובכל-זאת, כשבאים אלינו אורחים, הוא מוליך אותם אל הגן,
        למען הראות להם, איזה ירקות יפים יש לו! לאחי יש אותה מדה, המשותפת לכל
        הנוער הארץ-ישראלי, לדבר תמיד בשמו: "הכרם שלי", "הבית שלי", "הפרדס שלי"
        וכו&#39;, בעת שהרכוש נוצר על-ידי האב ודמים וטפול מכניס עד-היום האב! אמנם
        לאחי יש הזכות יותר לדבר בעדו, ואף על פי כן,  כשהוא מסיח לפי-תומו: "הכרם
        החדש </span
      ><span class="c4">שלי</span
      ><span class="c1"> עלה לי בסכום פרנקים כזה וכזה, על תקון </span
      ><span class="c4">גג-ביתי </span><span class="c1">הוצאתי </span
      ><span class="c4">מכיסי</span
      ><span class="c1"> ככה וככה נפוליונים, הפרות</span
      ><span class="c4"> שלי</span
      ><span class="c1"
        > מכניסות סכום כזה וכזה בכל חודש", – אז מתעורר בי העלבון הגדול של אדם
        הנרמס ברגל, ונפשי תובעת ושואלת: היכן נעורי, מיטב-שנותי ותמצית-חיי,
        שהכנסתי במשק הזה, עד שהגיע למדרגתו הנוכחית? עמלי אינו כלום, דמי אינו
        סמיק, והאדם שבאשה אינו אלא גולם, בול-עץ, שיכולים ליַדות בו אבן והוא לא
        ירגיש! – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ערב. פסק הגשם, ואני יוצאת לקראת פרותינו.  כולן כבר נוהרות בסימטה.  רק
        ה"אדומה" חסרה לי – הלכה הזוללה לבקש לה עשב רענן בצדי הדרכים
        הרחוקים.  אני הולכת לחפש אחריה ומוצאת אותה בפנת אחד הכרמים שקועה
        בלעיסה;  שמעה את קולי וזזה לה הכרסנית.  יחד אתה נבהלה עדת-עורבים, שכסתה
        ככתם שחור חלקת גפנים נמוכות, אשר בחרה לה למקום לינת-לילה, ואני, הרשעה,
        הפרעתי את מנוחתם.  אך הרגישו בי מרחוק, מיד התרוממו כולם יחד ופרחו, שבו,
        רעדו ושוב פרחו, כאילו אויב מסוכן עומד לפניהם. עורבים פתאים, למה תפחדו,
        תנוסו מפני יצור אפסי כמוני?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוצאתי את ה"אדומה" מן הכרים ועמדתי רגע להסתכל בענני-האש הצפים על הרי
        יהודה המכוסים לבוש כהה.  פתאום אני רואה בתוך הסבך יפהפיה קטנה בעלת צואר
        ירקרק, בטן צהובה וזנב כצבעי-הקשת; היא מנתרת בבטחה מענף לענף ואינה יודעת,
        שמאחורי עץ עבות אורב לה זוג ילדים שובבים, אשר פרשו פח לרגליה לתפסה
        חיה.  אני הצלתי את הפעוטה מידי רודפיה, בקחתי לאט את הפח, והרגשתי את עצמי
        ברגע זה כאילו עשיתי איזה מעשה רב באמת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לילה. השמים מעולפים עננים כבדים; בצפון נוצצים ברקים בלי הרף ורעם מחריד
        לעתים את האויר;  אני מזדרזת לבקר את הבהמות באורוה, בטרם ירד
        הגשם.  נכנסתי לשם עטופה שכמית-גשם של אחי;  עששית קטנה דולקת;  העגלון
        שוכב מכוסה שתי עביות עבות ונוחר;  הסוסים עומדים אצל האבוסים המלאים ואינם
        אוכלים.  התעצל מוסטפה לקום ולהשקותם;  הם מפנים ראשם לברז שבצד האורוה,
        ובפניהם האלמים כאילו רומזים לי שאשקם.  מלאתי רצונם והם התחילו לאכול
        בתאבון.  נכנסתי ל"רכבן", העומד בדיר מיוחד;  ראה אותי וקם ממשכבו הלח,
        כשבטנו כולה מלוכלכה בזבל.  נקיתיו יפה במסרק-ברזל, פרשתי תחתיו מצע יבש
        והוספתי מעט אוכל לאבוסו.  בדקתי את רגלו הנפוחה;  נראה לי, שהיא חמה קצת;
        שלשום הוכה המסכן על שעזב את בעליו בשדה ושב לבדו, - וברגלו זו הכואבה אני
        צריכה לטפל עתה ולרפאותה, כי בעליו עדיין כועס עליו: נוקם ונוטר הוא
        אחי!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סגרתי את האורוה במנעול ופתחתי את הרפת.  שם מצאתי עזובה: ה"מבכירה" סחבה
        אליה את הארגז של ה"אדומה", שפכה את כל האוכל שלה ארצה, ושתיהן עמדו
        כאבלות.  הדוּד, שמכינים בו מים ללילה בשביל הפרות, עומד יבש, מלא אבק
        ושירי אוכל של אתמול, - ככה מכין להן מוסטפה שתיה! עגלה צעירה לא הוכנסה אל
        הדיר שלה, אלא שכבה אצל אמה על ערמת זבל, שהתעצלו להשליך אל המזבלה.  קר
        ולח היה בכל פנות הרפת, ועלי היה להכניס כאן קצת סדר: את הזבל הלח סחבתי
        במָגוב אל אחת הפנות ועל הקרקע הרטוב זרקתי תבן ישן, שנשאר במחבוא
        מאשתקד.  אוכל לפרות עשיתי מחדש והעמדתי את ארגזיהן באופן כזה שלא תוכלנה
        למשוך אחת מרעותה, לשפוך ולהיות צפויות לרעב כל הלילה.  את העֶגלה סגרתי;
        את הדוד רחצתי ומלאתי מים, סתמתי סדק, שהיה בקיר הדרומי ורוח קרה, חזקה
        נשבה משם על ה"מבכירה" המפונקת שלנו.  כסגרי את הרפת שָׁהיתי עוד בחצר שעה
        קלה, בעשותי תכנית לתקון אחד, שאחי מתרשל בו, ואני החלטתי לגשת לזה מחר בלי
        עזרתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כוכב מזהיר הציץ אלי מבין העננים, הבהב ושחק, ובאותו שעה כבר ירדו טפות
        גשם, הרטיבו שערותי והחליקו על לחיי.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">ד&#39; שבט.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בפרדסן סַלקין, מי שישב אלמן שנים אחדות, נשא אשתקד עלמה, שבאה בתור פועלת
        לארץ-ישראל.  מההכֵּרוּת הראשונה אתה הרגשתי, שנהיה ידידות טובות. מתחלה
        נדמה לי, שדרכה סוגה שושנים: מוקפה היא אהבה של אחים, אחֵי בעלה, ידידים
        וידידות לרוב לה, ויחס בעלה אליה נראה לי חם וטוב.  והנה בקורי אמש בביתה
        הראני את המטבע מצדה השני ואני נוכחתי לדעת, כי בנשואיה מרובים הקוצים על
        השושנים וכל אשרה אינו אלא בדמיונם של אחרים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא דימוקרטית, עדיין במפלגה. בהשתדלותה קבל בעלה אל פרדסו יהודים שלשה
        בין המון הערביים.  את אלה השלשה הוא קורא פועלים "שלה", וכל אי-הצלחה
        בעבודה הוא זוקף על חשבון "פועליה".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">שב סלקין אמש מהפרדס מלא-זעם וזרק בפניה:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- עסק מצוין עשו לי היום הפועלים שלך! עבדו כל היום, ומחר אני צריך לשלוח
        את הערביים לתקן מה שקלקלו הם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">היא שתקה, והוא הוסיף בחמתו:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- רק שדים יעבדו עם יהודים ולא בני-אדם!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- ואתה אינך יהודי? – ענתה הגברת סלקין, כשקולה רעד קצת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- לא, לא!...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- אם כך – באיזו רשות נשאת אשה יהודיה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על זה לא ענה סלקין ישר, אלא הוסיף לשפוך רותחים על העבודה העברית, לחללה,
        ויחד עם זה להלל את עבודת הזרים, כגברים כנשים וגם כילדים! ובגמרו את ההלל
        הזה, הביט אל אשתו בנצחון מחלט. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נכרת היתה בה התאמצות גדולה לכבוש כעסה, ובהתול קל אמרה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- למה אין יהודים מתחתנים עם ערביות? – הלא הרבה מעלות טובות להן: עובדות
        הן בחמר ובלבנים, נושאות כל משא כבד על ראשיהן ומחמרות אחרי בעליהן;
        והילדים, הילדים שהערביות מביאות לעולם, הלא מבטן אמם מוכשרים הם לכל עבודה
        קשה – תענוג ממש!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כה הלכה והתחדדה השיחה בין הזוג ונגמרה בדמעות מצד אחד ובהתרגזות מגוחכה
        מצד שני.  לא קל היה לי להיות עדה למחזה זה.  האזנתי ומוחי היה עסוק בשאלה:
        שני אנשים זרים איש לרעהו ברוחם, איך זה קשרו את חייהם יחד? ודאי יולידו
        בנים וינחילו להם את כל הסלף והעקמומיות שבלבותיהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובאותו הערב שמעתי בבית הסלקינים עוד דבר אחד יפה. – נכנס מכר שלהם
        מירושלים והציגוני לפניו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >דרורי? אחות עמרם דרורי? – אמר הלז בהתפעלות. – אני מכיר את הצעיר הנחמד
        הזה ויודע את חריצותו ויחסו היפה לאחותו – הלא אידיליה ממש שוררת
        בביתם!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אידיליה!... פני בערו מבושה על השקר הזה, שאני צריכה לעבור עליו
        בשתיקה.  בבית חכה לי גמר ספור מעניין. זה היה "יומנה של יוליה" לפטר
        ננסן. התחלתי לפני ימים אחדים לדפדף את הספר הזה רק מתוך סקרנות גרידא, כדי
        לראות, כיצד סופר מתנהג בעריכת יומן: אולי ישמש לי פעם דוגמה? – התגנב
        רעיון בלבבי וצחקתי לו.  בינתים תפש הספר את לבי במדה שלא פללתי לה.  ותמוה
        בעיני הדבר: מה ביני ובין הקטנה ההיא, שמשַחק על הבימה צד את לבבה? מה לי
        ולחיים הרחוקים והזרים ההם? ועוד פליאה היא, שמקומות לא חשובים כלל עשו עלי
        את הרושם הכי-חזק: meine liebe, süsse Kleine! מצלצלות מלי-החבה באזני ואני
        חוזרת עליהן ורואה את "הקטנה המתוקה" מפרפרת בזרועותיו של האמן-המשחק,
        שמשתכר בתאותו, לוחט ובוער...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עתה גמרתי את הספר ואני עדיין יושבת שקועה בהרהורים. לפני מונח גליון-ניר
        ואני משרטטת עליו במהירות קדחתנית: "שמעון, שמעון, החביב, היקר!" חוזרת
        וכותבת את ארבע המלים האלה עד שמתמלא הגליון.  זה כחמש-עשרה שנה עברו מאז
        נתק כל קשר ביני ובין מי שהיה חתני, ובהסכמה גמורה נפרדנו איש מעל רעהו,
        ומאז ועד היום לא אחדל  מחזור בפה או בכתב על המלים ההן.  חולבת אני פרה,
        עובדת במעדר או עושה במטבח, איני חושבת כלום – ופתאום והמלים מתגלגלות על
        השפתים: "שמעון, שמעון, החביב, היקר!" ויש שנוספת קריאת-צער: "הוי, לא
        אוכל, לא אוכל!" מה לא אוכל? איני נותנת לי חשבון מפורט, אבל הלב נלחץ, עד
        לכאב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תקפו אותי געגועים על משהו אי-ברור, בעצלות אני מציעה לי את מטתי, ולפני
        שכבי אני נגשת אל שִדָתי ומוציאה חבילה קשורה של מכתבים; מעטפה עם חותם
        Paris, Rue de St… מתגלגלת לידי; אני מוציאה משם שני מכתבים מעוכים מרוב
        משמוש וקוראת מתוך דפיקות-לב:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"צפורה בלתי-נשכחה!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"לדאבוני הגדול נגמרו בינינו החשבונות הרשמיים, אבל עובדה מעציבה זו אינה
        מחייבת אותנו, שתנתק ידידות נעורינו.  זכרי את הילדות שלנו, ימי הלמודים,
        לכתנו יחד בוקר בוקר במשך שנים רבות אל הגימנסיות שלנו; זכרי ערבי הקריאות,
        השיחות, הטיולים; זכרי את אהבתנו הנקיה, הטהורה – ולכל אשר יפרדו הדרכים
        נשא-נא זכרון קדוש בלב".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">המכתב השני היה כתוב ברוח אחרת:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"חברה!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הפריד בינינו הים ואת חדלת להבין ולהרגיש אותי.  מוכיחה את אותי,
        שרגש-לאומיותי הולך וכבה – והדבר לא כן הוא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"לא, ידידתי; – הרי רוחי המרחפת על גלי תקומה ותחיה לאומית, לבי המרגיש
        בצרת העם, חוט משזר זה של תקוות ואנחות, הקשור בנפשי, – יחד הם האותות
        המראים בעליל, כי עברי לאומי כמוך הנני. אלא מה? לא פתרון רוחני אני מבקש
        בעד עמי, כי-אם פתרון מעשי – ואותו בארץ ישראל שלך לא נמצא! ההגיון הבריא
        שלי מביאני תדיר לידי בקורת. עתה נגשתי אל הערפל, קרעתי את המסכה הנסוכה
        על-פני ההזיות והוברר לי: מטרת החיים הם החיים עצמם ועל הקרקע היבש של
        המזרח לא ימצא הנודד חיים; אל תונו אותו בדמיונות-הבל!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עיפות גדולה תקפתני ואני לא חפשתי יותר בין המכתבים. נתקע מבטי בנקודה אחת
        ולא הסירותי עין ממנה, כאילו בה התרכזה כל הטרגדיה של אושר אבוד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נדמה לי, שאך זה-עתה שברתי את כוס אשרי, זה-עתה סתמתי את הגולל על
        עלומי.</span
      >
    </p>
    <p class="c7" dir="rtl"><span class="c5"> </span></p>
    <p class="c7" dir="rtl">
      <span class="c11">כ&#39; שבט.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עדת-יוֹנים פרחה למטה, קשקשה בכנפיה ודרשה את סעודת-הבוקר שלה.  אך זרקתי
        לה מנת זרעוניה והנה המון אנקורים זעירים הופיעו, אורחים לא-קרואים, להשתתף
        בסעודה.  התכופפתי להרים מקל כדי לגרשם, וירגישו מיד במזמתי ויפרחו למעלה
        אל הגדר.  אך לא התאפקו וחיש-מהר שבו על עקבם: יצרם גרה אותם לתפוש כמה
        שאפשר מהזרעונים הטעימים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מעשה זה של האנקורים הזכירני ימים רחוקים.  שבת-שירה.  שלג צח כסה את פני
        חצרנו.  בבית נדף חום נעים מהתנור שהסיקה הגויה המשרתת.  ההורים כבר שבו
        מבית-התפלה, אבא קדש על כוס יין אדום, טעם מן העוגות המתוקות, ואחרי נטלו
        את הידים, התחילה אמא להגיש אל השלחן ממטעמי-שבת הערֵבים. כשהגיע תור
        החמין, הפרישה צלחת אחת עם דיסה ותעמידהּ הצדה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשביל מי זה, אמא? – שואלת אני בתמיהה רבה. – בשביל הצפרים, ילדתי הקטנה!
        – עונה האם: – הלא שבת-שירה היום, השבת שלהן!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אנכי לא שאלתי ולא חקרתי, מדוע שבת זו דוקא שלהן היא, אלא רצתי אחרי אמי
        החצרה לראות, איך היא זורקת להן את הדיסה המבושלת.  כמדומני, שזו היתה הפעם
        הראשונה בחיי שהתעורר בי הרצון להסתכל מקרוב אל בריות קטנות אלה, שעפות
        באויר, ומאז נכרתה ברית בינינו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל יחד עם הזכרון ההוא נחרת בלבי גם זכרון אסוני, כי ימים מועטים אחרי
        שבת-שירה ההיא חלתה אמי במחלה ממושכה ותמת בה – יתמות ארוכה וקשה חתם לי אז
        גורלי!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שקועה בזכרונות גמרתי את החליבה ועברתי עם כד החלב את החצר; על-יד הגדר של
        השכנים עמדה אם צעירה ופעוט על ידיה ומפטפטת אליו:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- חביבי, חביביניו, האוהב אתה את אמא שלך?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- יֵין,יֵין!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- הטובה אמא שלך?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- אוֹבָה! – וידים פעטות מחבקות את האם המאושרה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אני שלחתי את הפרות עם העגלים הגדולים אל העדר, ושני הקטנים, שנשארו בחצר,
        עורכים לי מחזה-שעשועים של רקודים וקפיצות; אני תופסת את היפה שבהם,
        מלטפתו, והוא בקפיצה אחת מתחמק אל הרפת ומחביא את ראשו בשיָרי העשב
        הריחני.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >את העשב הזה דואג אחי להמציא לרפת ערב ערב עם בוא העגלה מעבודה, ואני
        מלאת-תודה לו על זה.  מחליטה אני, כי הוא באמת בעל-בית טוב, ורק לחנם אני
        טופלת עליו אשמות.  הרי יש לי להתגאות בו: חסון הוא, יפה וגם חרוץ יותר
        מחבריו. כמה הייתי מאושרה, לו נשא את תמר לאשה! כמדומני, שהוא אוהב אותה
        בלב תמים... כדאי היה לדבר אתו גלוי על אודות זה, לפנות גם להוריה ולגמור
        את הענין בכי-טוב... אחרי חתונתם אשגיח על שניהם, שלא יחסר להם כלום; מעליה
        אקֵל את עבודות-הבית, ומעמרם – את דאגות המשק הרבות. ילדה זו נחמדה היא,
        אבל חלשה קצת; גדול-בנים יעלה לה בקושי, וגם החיים עם עמרם לא קלים יהיו,
        אבל אני אהיה לה למגן, אהיה לה לאם אוהבת ומסורה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בינתים צף ועולה בזכרוני איזה זוג צעיר אצל בְּיֶרְנְסְטְיֶרְנָה
        בְּיֶרְנְסוֹן, שדומה מאד לאחי ולאהובתו – ותוהה אני על עצמי: למה הספר
        והחיים תמיד אחוזים וקשורים אצלי? הנה למשל: "פרוסים" שחורים התחילו להופיע
        במטבחי, מיד נזכרת אני בספר "קטינא" של דוֹדֶה וב"פרוסים" המרובים שבדירת
        הוריו של גבור הספר. ו"קטינא" עצמו עולה בזכרוני כל פעם שאני נפגשת עם מורה
        קטן-קומה, דל-מראה ועלוב.  בעלמה מכירה אחת, לא מן הצעירות ביותר, אני רואה
        את גֶ&#39;נֵבְיֶב בספורו של לַמרטין; ובעלמה אחרת, ארוסה מאושרה, אני רואה
        בעתיד חייה את אֵם איוֹלף של איבסֶן, ידען גדול זה של החיים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מר דומברובסקי, אדם בא-בימים, שמבקש טובתי, יש לו שם ספרותי
        בספר-זכרונותי: "סיר הוּגוֹ מַלינגר". ולעתים מגוחכה אני מאד בעיני: נכנסת
        אלי אשה פשוטה בשעת קריאתי בספר מענין, ועולה על לבי להכנס אתה בשיחה על
        דבר הספר ולבאר לה, כי יש בני-אדם אשר אצלם הלחם והספר עומדים במדרגה אחת,
        כלומר, במדה שדרוש הלחם לקיום-גופם דרוש הספר לרוחם.  היא מביטה עלי בתמהון
        ואי-הבנה, ואני נזכרת באותה שעה בנָכלודוֹב, שטולסטוי מספר עליו, כי פעם
        אחרי התוַדותו לפני הכהן על חטאיו, החליט, שמהיום ההוא והלאה יתחיל לחיות
        חיי טהרה וצניעות, וסח לעגלונו את כל דבר התורה החדשה שבלבו.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">כ"ה שבט.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לא יועיל לי כל קוצר-רוח וכל אי-סבלנות. הדבר ברור, שאין בכוחי לשַנות
        כלום במשק שלנו!  הנה יום שאחרי הגשם. לעבוד בכרמים אין אפשרות,
        ואף-על-פי-כן באה כל החבורה הקדושה – היא יודעת, שלחוג&#39;ה עמרם לא נאה
        יהיה להשיבם הביתה. יבַטלו אצלו יום כאַות-נפשם, ושכרם מוכן לפניהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתעסקים בהעברת עצים וסדוּרם ואני מתבקעת למראה עצלותם. בן מוסטַפה, חליל,
        גם הוא בין החבריה, את הנער המָּשחת והמפונק הזה לא אוכל לסבול, וכל שבוע
        ושבוע, ביום תשלום-הפועלים, מבקשת אני מעמרם לתת לו את המגיע ולפטרו, אבל
        אחי שותק ואינו עושה את בקשתי. וחליל אפילו לרַמות אותנו אינו משתדל
        והולך-לו בטל לעין כל, כי סומך הנהו על זכות-אבות, שתעמוד לו בכל עת. הוא
        מביא אלינו את סיָחו הקטן, מכניסהו ישר אל האורוָה לאכול מתוך אבוסי
        הסוסים. מוסיף לו אביו בהסתר גם קנוח-סעודה – סובין או שעורה טחונה שהחביא
        לסיח – וחליל לוקח שעור במלאכת הגנבה. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פועלינו עומדים להם בכנופיה אצל ערמת העצים ומשוחחים בהתלהבות על בנות
        הכפר ופרוצותיו ונהנים הנאה מרובה. אני יוצאת לזרזם בעבודה ומוכיחה אותם על
        עצלותם, והם מביטים איש אל פני רעהו, מצליפים מבט-לעג בחשאי, ובתרעומת
        שואלים: מה רוצה הגברת? האם אפשר לעבוד עוד יותר מזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ויש בוקר ממין אחר: הצפירה לעבודה כבר היתה והפועלים יושבים עוד ומפטפטים,
        כאילו לא באו כלל לשֵם עבודה. כשאני מתחילה לזרם: "יַללה, יַללה!" הם אמנם
        קמים, אבל מסתובבים בחצר, עד שמוסטפה גומר לרתום את העגלה: תקוה לנסיעה יש
        להם. אבל אני גוערת בהם והנם יוצאים ברגל מן החצר, – בדרך ודאי יחכו
        לעגלה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נעשו אצלנו הפועלים יחסנים, ביחוד כשהעבודה דוחקת; וכדי שלא תתפזר הכנופיה
        לכורמים אחרים, מבקש אחי למשוך את לבבם אליו בהכנסו אתם לעתים בשיחה ארוכה
        ובהבטיחו להם מתנות לחג הקרוב. ויודעת אני, שאמנם יעניק להם, אפילו לעצלנים
        ביותר, סוכר ואורז ביום חג. עמרם מוציא הכל ביד רחבה, כי לכסף אין ערך
        אצלו. לקשוטים לסוסים ולכל מני מותרות הוא מוציא בלי שום פקפוק מן הקופה,
        לפעמים עד הפרוטה האחרונה, ואחר-כך אני צריכה לחפש בכל מיני דרכים להשיג
        כסף לתשלום הפועלים. השנים רעות ותשלום-העבודה עולה לי תמיד בקושי. ושלא
        לשלם בעתו אי-אפשר לי, וכפלַים אז לסבלי: גם עמרם כועס ומתמרמר, וגם לבי לא
        ישקוט: "אַל תלין שְֹכַר שכיר!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מפלט יחיד מעָקת לבי משמשת לי עבודת-הגן. הנה משגשג ועולה העשב בין
        העגבניות, שעלי-צבר מגנים עליהן; מסובב את הצמח מכל עבריו, מסתבך בתוך
        שרשיו ובין עליו, עד שלא נודע, מי העיקר ומי הטפל. שורות הבצל, השום, הסלק
        והאפון גם הן שוב דורשות עדירה, ואין דעתי מתקררת עד שינוכש הגן ויבריק
        באדמתו התחוחה ונראית כפסי קטיפה שחומה בין שורות ירוקות. נשארה שאלה: איזו
        פנת גן לעבד לירקות האביב? במה להתחיל קודם ובמה לגמור? ואיך להוציא את
        התועלת הכי-מרובה מהשטח הקטן הזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אלא שאחרי עבודה רצופה במשך ימים אחדים אני מעמידה לי שאלה: אם באמת יש
        למצוא בעבודה תנחומים לצער ומשהו משכחת עלבון-החיים? הרוחות המתלחשות בין
        העצים עונות דבר-מה, אבל את שפתן לא אבין ונפשי עיפה ואת הדה לא
        אשמע.</span
      >
    </p>
    <p class="c7" dir="rtl"><span class="c5"> </span></p>
    <p class="c13" dir="rtl"><span class="c10">ב.</span></p>
    <p class="c7" dir="rtl">
      <span class="c11">יום ב&#39; דחול-המועד פסח.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בוקר-זיו. בבית מוכן לחג מכל טוב. נקיון וטהרה בכל הפנות. בחוץ העצים
        מלבלבים; שמש-אביב מזהירה ומלטפת, רננת-צפרים עליזה בוקעת את האויר;
        טיק-טַק דופק השעון, כלויה מוסיקלית חשאית לכל אותם הקולות והמראות הנפלאים
        שמסביב, ובלבי שד משחת מוצץ את דמי בלי-הרף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אני מתחמקת לזוית רחוקה בגן ומחפשת פנת-צל. האורות הרבים מדכאים את רוחי,
        הזוהר והיופי שמסביב אך מוסיפים על מכאובי. אני עוצמת עיני וסותמת אזני,
        לבל ידקרני האור ולבל תחרישני שירת-הרננים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">מה היה ללבי, ששב אליו העצב?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בערב פורים עלה פתאום על דעתו של עמרם להכניס אל חצרנו את כל משפחת העגלון
        עם בנים ובני-בנים ולהקצות להם מדור מיוחד בין בניני האורוָה. התנגדותי
        הגמורה לזה הכעיסה את אחי מאד ויתחל בשביתת-אכילה כדרכו, ולבסוף הענישני
        בשכיבה בליל קר על ספסל בגן. אז התקרר ונפל למשכב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פְּלֶוִרִיט, דלקת קרום-הריאה! ואני שלשה שבועות רצופים הייתי מלאה חרדה
        לחייו. שלשה שבועות רצופים לא פשטתי בגדי מעלי. בלב דופק ורגלים כושלות
        הייתי מתקרבת אליו בעת תנומתו, מקשיבה אל נשימתו הכבדה, לוחצת ראשי אל מוט
        הברזל הקר, ומהרהרת בזה, שאם לא יקום מחליו, ארד גם אני אחריו שאוֹלה, כי
        לא יהיה לי עוד בשביל מי לחיות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בעת המחלה היו עיניו לפעמים זורקות עלי תוכחה: "הטוב עשית לי? השמחה את
        כעת?" והענשים שלפני המחלה נמשכו: אינו אוכל את המרק ואינו שותה חלב, וגם
        את הרפואה הוא מסרב לקחת: כל טפה עולה לי בדמעה ודם; עומדת אני על ברכי
        ומתחננת – הוא נרתע אל הקיר בתנועה משונה. זורק מבט זועם ומסתיר
        פניו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כעבור המשבר, כשאמר הרופא, שעתה תלוי הכל רק בשמירה מעולה ובמזון מבריא,
        קרה, שבאחד הימים לא טעם עמרם אף טפת מרק או חלב, גם תה לא לקח אל פיו. לכל
        תחנוני אך תשובה אלמת אחת: נשיכת-שפתים ומבט-זועם! בערב אותו היום התעלפתי
        בפעם הראשונה בימי חיי; ושושנה סלקין, שעל פי רוב היתה עוזרת לי
        חצאי-לילות, נשארה עתה כל הלילה ובבוקר השכם שלחה את עגלתנו להביא את
        תמר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא כועס בודאי, שעד היום לא הודענו לה כלום ולא הבאנו אותה אליו... –
        לחשה לי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עם בוא תמר בא תיכך שנוי במצב-רוחו, והוא אכל ושתה את כל מה שהגישו לפניו.
        בערב ישבנו שתינו על יד מטתו. תמר התרפקה עליו בגעועים, הניחה את ראשה על
        כתפו ברכות, ופניה החורים כוסו אדמומית כשפגעה עינה בחזהו הפתוח. לחִי
        ורודה נגעה נגיעה קלה בחלוקו הלבן – ותָּשב ותָּרֶם מהר את ראשה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עזבתי אותם לבדם ויצאתי אל הגן לנוח על הספסל. צפצוף של זמר קל מפי אחד
        העוברים בסימטה הזכירני ימים רחוקים ושפתי פלטו את הקריאה הרגילה: "שמעון,
        שמעון, היקר, החביב". הוא היה תמיד משמיע את קולו בצפצוף כזה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רוחות של שלהי-חורף רשרשו בין העצים. ריחות חריפים של ציצי תפוחי-הזהב כבר
        נדפו מהפרדסים ובשמו את האויר, פרחי הגן גם הם נתנו ריחם וגם פרחי-הלב
        הנובלים כאילו התחילו להתנער מאבק השָנים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כן, יש שאני שוגה באהבה הישנה, שאין להחזירה, אהבה שאני בעצם ידי קברתיה
        לנצח. הלב דואב וכואב, אבל יודעת אני, שאין להאשים איש במכאובי. אין לי
        כשרון-הסתגלות אל החיים, אין ההבנה האמתית לסול לי דרך נכונה, לרכוש
        מעמד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גל רודף גל ומכניע אותי. עשרים ואחד הלילות על-יד מטת החולה התישו את
        כחותי לגמרי. הימים עוברים בעבודה מתוך עקת-לב והלילות בלי שֵנה, עם רגש
        קשה בלב, כאילו הייתי נדונה למות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהשכיבה בלי שנה נמאסת עלי, אני קמה ממטתי, יושבת, מביטה ארוכות לנקודה
        אחת; בבות-העינים מתרחבות והדבר, ששמתי עליו עיני תחילה, מתחלק, מתרחב,
        מִטשטש, עד שהצורה אובדת לגמרי והמחשבה תועה על גבי נקודות בלתי ברורות.
        מצפוני החולה לוחש ומונה חובות מוסריים, שלא שלמתי כדבעי, ואין עוד כל תקוה
        לתקן המעוָת. על מהות החיים מתלבטת הנפש ומוצאת, שכיון שמהות זו היא רק
        בסכום האושר, שאנחנו יכולים להמציא לאחרים, ואני אף קורטוב אחד של אושר לא
        נתתי עוד לשום אדם בעולם, לכן אין לי זכות קיום והויה בין יושבי חלד, ואך
        אבר מדולדל אני, נפש ערטילאית תועה בעולם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מקיצה אני בחולשה רבה. אותה החולשה תוקפת אותי לעתים גם באמצע היום, אבל
        איש אינו מרגיש בכך. יש שאני שוכבת שעות ארוכות מבלי יכולת לקום. אחי אינו
        שואל עלי כלל; רק לפעמים, כשאנחותי מגיעות לאזניו, הוא פונה אל התימניה
        הקטנה שלנו, שתלך ותשאל, מה לי. יש גם שהוא נכנס בעצמו, עומד רגע אצל מטתי
        ומבקש אמתלה להתחמק מהר, מבלי עזור לי אף בחִלוף תחבושת אחת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האוכל להאשים את הנער בזה? הלא אני בעצמי הרגלתיו, כי לדאוג צריכים רק לו,
        להתענין רק בו, למלא כל הדרישות שלו, ושהוא הוא הנקודה המרכזית, שעצמיותי
        אובדת בה. זהו גורלם ומנת-חלקם של אנשים כמוני, אשר כל חייהם נפתלו עם הטבע
        ונוצחו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חג – ואני לבדי עם בדידותי. עמרם הוזמן אל כלתו לכל ימי הפסח. אני נחה
        וקוראת. ל"סדר" הייתי מוזמנה אל הסלקינים ושמחתי, כמובן, שליל פסח לא היה
        אצלי חולין, - עוד המסורת תופסת מקום חשוב אצלי! אל הסלקינים היו מוזמנים
        גם הזקנים, הוריו של בעל-הבית, והם הם שערכו את הסדר לכל פרטי-פרטיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >השולחן היה ערוך בהרבה יופי וברק. נרות גדולים דלקו בפמוטי-כסף; כוסות
        מזהבות עמדו על השולחן מסביב, וכוס-כסף גבוהה לאליהו הנביא באמצע השולחן.
        הסבא, בגלימה לבנה עם שרוולים רחבים, ישב בהסבה על כרים מוצעים על ספה,
        שהגיעו עד השולחן. היו שני האחים של סלקין וחבר אחד שלהם, מורה רוָק. לכל
        זוג היתה מוכנת "הגדה לפסח" ואנחנו קראנו אחרי הזקן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קדוש ויפה הוא ליל חג הפסח, אבל ה"הגדה" מקלקלת את השורה... – לחשתי לגברת
        סלקין.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >דברים כאלה אסור לאמור! – ענתה לי שושנה בבת-צחוק, וכשהגישה אחר-כך את
        הכופתות הרכות משומן והכל הִללו את אומנותה הגדולה, פנתה ותאמר
        בצחוק:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרי רואה את, צפורה, שלא ההגדה העיקר, אלא הכופתות! אני הסתכלתי בה, - כמה
        נחמדה היא בתלבשתה הלבנה – כ"בת-מלכה"! – חשבתי בלבי. לא לחנם יהודים
        נקראים בני-מלכים בליל זה. חותם של גאוה לאומית היה טבוע בה, יחד עם עוז
        אשה ונצחון אשה על החיים. בעיני-אהבה בוערות הביט עליה סלקין, ואני חשבתי
        בלבי שוב: קשים שבעתים היו חיי האשה עם הגבר, לולא תשוקה זו אליה, הלוהטת
        בלבו... בעיקרו של דבר, אין הגבר סובל את האשה, אלא שהתאוה הגדולה אליה
        מכפרת על ההתנגדות וחוסר-הכבוד שהוא מרגיש ביחס אליה.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c7" dir="rtl">
      <span class="c11">כ"ז ניסן.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נאספו לישיבה כללית הרבה מעסקני הישוב וָאתענין ללכת לבית-הועד. שלוחי עם
        ישראל התקבצו לָדון על שאלה בוערת אחת ולהוציא משפט. הענין היה זה:
        בָּאת-כח מושבה אחת הגישה קובלנה על פועליה ותפטרם מעבודה; התחוללה התרגשות
        בצבור, ומעסקני הצבור, מאותו החלק שעמד על צד הפועלים, יצא דבר האספה. חפצם
        היה להשתמש במקרה זה לברר את השאלה מכל צדדיה ולשמוע חות-דעת הישוב
        כולו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרבה התלבטו הנאספים בשאלה: אם יש חשבון לתת עבודה לפועל עברי? נמצאו
        כאלה, שענו על השאלה בשלילה גמורה. אחרים השיבו בחיוב והשתדלו בכל מאמצי
        כחם להוכיח, שאין העבודה העברית עולה יותר מעשרה או שנים-עשר אחוזים על
        העבודה הערבית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחוזים!... על זה הסתובבה השאלה זמן רב, כאילו באחוזים אלה נכלל כל חשבון
        הלאום. מה שבעבודה זרה אנו מרחיקים ת"ק פרסה את הגאולה, מה שהעבודה הזלה של
        הפרט עולה ביוקר לגבי הכלל – עם זה אין כל צורך לנו להתחשב, כי עיקר כל
        העיקרים הלא הם ה"דיוידנדים".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד הברה אחת צרמה מאד את אזני באספה זו: "הם", "הם"... ואיש לא שאל מי
        "הם" ומי "אנחנו"? לא נתן דין וחשבון לעצמו על זה, כי הלא ברור הוא; אלה
        אשר האיר להם הכוכב </span
      ><span class="c4">בימים הטובים</span
      ><span class="c1"
        > לישוב להשיג אדמה בתשלומים לזמן ארוך ובית מוכן, או הלואה לבית, - יהיו
        אלה אנשים מוכשרים או לא מוכשרים לחקלאות ולעבודה, – הם עתה בבחינת מלכי
        הארץ, יחסני הישוב ועמודי-התוך שלו וקוראים לעצמם "אנחנו". "הם" – אלה
        דגי-הרקק, שעל מעדרם לחמם ובזעת-אפם יאכלוהו – ובמה נחשבים הם?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אפיָנית היתה תשובת אחד האכרים, כשמי-שהוא הוכיח אותו על שנתן לבניו לעזוב
        את הארץ:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במה שבנינו הולכים מכאן אין שום רעה, רבותי; אלא דא עקא כי איזה ארחי-פרחי
        באים אל הארץ ושוטפים אותה ברוח הריבולוצינרי שלהם. על צרה זו עלינו לפקוח
        היטב את עינינו! –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סערה התחוללה בי ונרשמתי לענות. אבל כשבא תורי לא נתנו לי לדבר, מטעם זה,
        שרק אורחת אני באספה ולא מן הקרואים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מלאת-עלבון שאלתי את עצמי: כלום אני תופסת איזה מקום בתוך הצבור הזה, כי
        נדחקת אני אליו ודורשת זכות לָדון בשאלותיו? כלום נפש עלובה ונדחת כמוני
        יכולה להכניס דבר-מה לתוך המכונה המורכבת הנקראת ישוב-הארץ?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל מאידך-גיסא: אדם פועל, עובד, זורע, הלא הוא משתתף ביצירה. ונסיון של
        עשרות בשנים, וההסתכלות והלמוד, כל זה לכאורה נותן איזו זכות להחשב כחלק כל
        הגוף, ולו היה איש אורח כזה רוצה לדבר, לא היו שוללים ממנו את הזכות, - אבל
        אשה הרי היא משוללת זכות-אדם בכלל, ובאיזו רשות היא נדחקת לתוך הצבור לחוות
        דעה? הלא זוהי רק חוצפה מצדה! מקומה של האשה במטבח, מאחורי הכירַים, ולא
        בין נבחרי-העם!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואלה מדַינים ומתוַכחים – רבונו-של-עולם, באיזו צורה! – חבורה שלמה של
        פועלים נדונה כמעט לחֵרם, אלא שעסקני העיר מנַתקים באופן פלאי את הקשר
        הגרדי – והפועלים יוצאים בשלום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואני יושבת לי בפנה אצל ארון-ספרים, ומשם פתאום מנצנץ לעיני ספרו של משה
        הֶס "רומא וירושלים". נזכרת אני בחזון-הנבואה של הס – כמה יפה הוא! אבל הנה
        התנגש הרעיון בחיים ובמעשים – ונהפך הכל עכור וכהה... האומנם רק אברים
        מדולדלים אנו, שלא יוכלו להתאחד, וחומר מתאים לבנין האומנם לא
        יִמָצא?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ככה שאלתי את עצמי בשבתי באולם-האספה ועצבי מרוגזים. ואולם כשיצאתי לחוץ,
        אל בין הכרמים המרהיבים עין, אל הרחובות היפים הנטועים עצים, כשהכל הלבין
        והבריק לעומת הצללים, באה רוח אחרת בקרבי ואמרתי בגאוה: כאן יש יצירה!
        ויסוד לבנין העם הונח, הונח בלי-ספק!</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">כ"ז ניסן.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אמש, בליל השבת, הפרעה סעודתנו מפני שלש סיבות חשובות מאד: כפתור חסר
        לכותנתו של עמרם, נמלה זחלה על חלת-השבת וריח חומץ חריף נדף מהַסַלַטה.
        שלשה חטאים בפעם אחת לאו מלתא זוטרתא היא, ועמרם בחל באכילה ויצא הגנה
        להשתטח על הספסל הרחב. בודדה ועלובה ישבתי על יד השולחן. נרות שבת לא הבהבו
        בפמוטי כסף כמו אצל אמי, אבל זכרונה עלה בלבי: הנה היא עומדת חורת לפני
        הנרות, מאהילה על עיניה בשתי ידים צחות ושפתיה רוחשות תפלה. יש שדמעות
        נושרות מעיניה עם ברכת-הנרות. על מה הזילו דמעות העינים הטהורות ההן?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מן הגן הגיעה לאזני אנחה. יצאתי. עמרם שכב על הספסל פקדן וזרועותיו
        משולבות על עיניו. נגשתי ושאלתי, אם לא כואב לו דבר? הוא לא ענה, כי אם זרק
        את מעילו ארצה והעמיד עלי עיני-זעמו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משדלת אני אותו בדברים רכים: מה הוא רוצה? מוטב להגיד דבר ברור ולא למרר
        את חיי שנינו! אולי החליט להכנס לחופה עם תמר, הריהו יודע שאני אוהבת את
        הילדה ואהיה מאושרה לחיות אתה תחת קורת ביתנו! מה שנוגע לנחלתנו, אם הוריה
        אינם מרוצים מזה, שהיא כתובה על שמי, סדר אסדר את הדבר, לבל יהיה להם
        למכשול... הלא כשאני לעצמי, לא דרוש לי בלתי-אם קורטוב של מנוחה ושלום,
        ואני אעביר הכל, אם הוא רוצה, על שמו ושם תמר; אבל אם הוא חושב, שמציאותי
        כאן בביתנו תוכל להפריע במשהו את חייו, הריני מוכנה לפנות את מקומי וללכת
        לי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אף מלה לא יצאה מפיו, ואני יושבת לי במרחק-מקום קצת ומהרהרת במחשבות העלם:
        - הנשים הללו בריות חלשות הן, שלאמתו של דבר אין ערכן חשוב. ואף-על-פי-כן
        אדם מוכרח להתחשב אתן! הנה אדם בעל זרוע וכתפים רחבות עם שרירים חזקים
        ואמיצים ואינו יכול בשום אופן להשליך בתנועת-יד אחת את עולן מעל שכמו...
        האחות שלי אינה רעה בעיקר ויש שאומרת דברים טובים ונכונים, כמו עכשיו למשל,
        היא באמת רוצה שיבוסס אָשרי בחיים ועשֹה תעשה כל מה שדרוש לכך,
        ובכל-זאת...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועוד חושב לו העלם: אפשר שכל הנשים דרכן בכך – למשול בגברים ולהיות לפוקה
        ולמכשול על דרך חופשתם... אפשר שגם תמר, זה היצור הרך, גם היא תפרוש ממשלתה
        עלי, - אנה אני בא אז?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחי קם פתאום ובתנועה מהירה אמר ללכת לצד הרחוב, אבל נמלך בלבו ונכנס
        הביתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבוקר קם פחות זועם, אבל תלונות לא חסרו על חשבוני: ה"אדומה" נפתח החבל
        שלה, נכנסה למתבן ולכלכה שם. זוג יונים נשארו ללון באורוה תחת הקורה וטנפו
        את גבו של "בָּרוֹד". נוסף על זה, הנה קפצו התרנגולות והחלו מנקרות
        באבוסים, - פשוט אין לו מנוס מפני השדים, שמגדלים בחצר!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הובלתי את הפרות אל העדר. האנשים, שפגשתי ושאני מכירה אותם מתמול-שלשום,
        נראו לי רחוקים וזרים, רק הטבע מסביב קרוב ללבבי. – הנה שם למטה משתטחים
        יערות שקדים, זיתים ואקליפטוסים; מבהיקים חולות-הזהב ואחריהם נראית כאגם
        חלקת-שדה כחולה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על גבעה פרשה עננה אפורה את רחבי כנפיה ותבלט את לובן הקירות שלבית-התפלה,
        אשר בצדו האחד עומדת משמרת אקליפטוסים ענקיים ומבין גזעיהם העבים מבהיק
        מרחק בהיר עם עמק ירוק והרי יהודה בקצהו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">אחד המכירים מצאני עומדת ומסתכלת במרחק.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- הגברת צפורה הולכת לשמוע את החזן, שבא מוִינה? – שאלני בעברו
        לבית-התפלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >צחקתי לו ופניתי לסימטתנו; אבל אחר-כך כשקולות ערֵבים בקעו מבית-התפלה
        והגיעו לאזני, הסיתני יצרי להתלבש ולעלות לבית-אלהים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מדפדפת אני בסדור אמא שלי ונוכחת לדאבוני, שסדר-התפלות נשכח ממני. מפי
        החזן מגיעים לאזני פסוקים ידועים ומֻכּרים, שהיטבתי פעם לדעתם בעל-פה, ועתה
        מעלעלת אני בסדור ואיני מוצאת אותם בשום אופן.        </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נגונו של החזן מקרוב לא ערב לאזני, והתפלה, אחרי שלא מצאתיה והמלים שבטא
        החזן לא הבחינה אזני היטב, הוסחה דעתי ממנה ושקעתי במחשבה על ערך בית-התפלה
        לקיום הלאום. הנה, כשאני מסתכלת למטה מעזרת-הנשים, רואה אני רק זקנים
        ואנשים באים בימים, - הדור הצעיר כמעט חסר כאן לגמרי, כאילו אבד לו הקשר עם
        בית-התפלה ועם המסורת, שנותנת לנפש פיוט וחמימות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עולות משום-מה בזכרוני אי-אלה יצירות של המספר בעל-המוסר הגדול
        בֶּיֶרנסטיֶרנה בּיֶרנסון, המקצה לבית-התפלה מקום גדול בספוריו. אמנם כל זה
        שיָך לאותה התקופה, שיסודות החיים התבססו עוד בעיקר על מנהגי הדת, ולא כן
        עכשיו. אבל הפיוט והיופי שבדת הלא צריכים היו להשתמר באיזו צורה שהיא, –
        ומדוע אין מוצאים את הדרך?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קול נגונו של החזן בקע ביתר שאת. מה זה, האם לא צלילי אופֵּירה אני שומעת,
        – והיכן הנגון העברי, המקורי? – שואלת אני את נפשי, וצער גדול מתעורר בי על
        שאין השכינה שורה במקום הזה ורוח-הקודש הסתלקה מאתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הפטרה. נגון זה קרוב אל הלב. אני מקשיבה למלים. יודעת, שזהו פרק באחד
        הנביאים, שבשקידה רבה למדתי וחזרתי שבעים פעם עליו, וכעת ההרצאה כבר מוזרה
        ואי-מובנה לי. והלב דואג על גירסא דינקותא שנשתכחה מפי, ועל זה שאחרי אבדה
        יקרה זו, אפשר שלנפש יאבד לאט לאט כל רכושה והיא תלך ערטילאית מן
        החיים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשובי מבית-התפלה מצאתי את שלשת סוסינו עומדים ליד החצר ולועסים בלי תאבון
        את העשב, שהתחיל להתיבש. עלה על דעתי לעשות להם תענוג של שבת ולהובילם אל
        השדה לרעות. מאושרים כאן סוסי, – העשב הירוק נבלע בין שפתיהם כבכּורה בטרם
        קיץ בפי הלקקן. ברביעה מאוחרת זו עוד העשב מלא לֵח השדות, ושלשת הגבורים
        שלנו עומדים ולועסים בחפזון. שמחים הם בגורלם, אבל אינה שמחה בעלת-הבית
        שלהם, כשהיא רואה את העתיד הקרוב, בעת שהעשבים הלחים הללו יעברו מן העולם,
        ואבלים יעמדו להם הסוסים כל יום השבת באורוה; גג-הרעפים ילהט ויחמם את
        האויר, והם את מאכלם הקשה ילעסו בלי כל תאבון.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשובי עם סוסַי לעת הצהרים פגשתי את "סיר הוגו מַלינגר" – האדם, שאני
        מכבדת ומחבבת כל-כך. ראני עם הסוסים, תהה ואמר:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אתּ בעצמך, צפורה, הובלת את הסוסים למרעה, בחום הזה? - איזו משוגעת
        אתּ:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משוגעת!... ואני שמחה למלה זו, שהובעה ברוך ובמסירות.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">כ"ט ניסן.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נראה, שאחי מצא חפץ בדברי, שפניתי אליו שלשום בדבר חתונתו, כי הציע היום
        לפני את סוסו לרכיבה, בשובו לפנות-ערב מן הכרמים. אני לא סרבתי הרבה,
        התעטפתי בעביה שלי, עליתי על "רכבן", המקושט לכבודי בחגורת-צמר עשויה
        ציציות ארוכות רבות-גונים, הכיתי על גבו בשוט-עור קלוע דק, והוא נשאני
        בצעדי מרוץ פזיזי אל גבעת האיקליפטוסים שעל הגבול. משם רואים את כל הסביבה
        עד למרחוק. כאן כרמים משתרעים בנחת, אחריהם שדות-זרע, ושם רחוק – מגדלים
        עתיקים וכפרים חדשים ונושנים על צלעות ההרים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האופק היה מכוסה צבעים כהים, רק זעיר שם זעיר שם פרוש אור וענני-שלג שטים
        בתוך האור. "רכבן" נושאני בשביל צר בין גדר של צבר ושורת שקדים. עננה אחת
        נצבה מול השביל ובה אני רואה פתאום את תמונת נורדוי עם זקנו הלבן והיפה;
        עומד הסב נשען בענן אל גב כסא רם. עצרתי בסוסי, כדי להסתכל יפה בצורה
        מרהבת-עין זו, אבל לדאבוני התחילה להטשטש ולהמוג, עד שהופיעה לאט לאט תמונה
        אחרת, – סוקרטס עם פניו הרחבים, הרציניים הופיע ובעוד רגע נעלם, ועל-פני
        הענן, שפשט צורה ולבש צורה, הצטירה תמונת זקן חולה, השוכב על ערש-דוי.  אני
        מכירה היטב את פני אבי, רק הגוף אינו שלו; גוף זר, ענקי הולך ומתפשט
        בכפת-השמים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סוסי נתקל פתאום באבן, ולולא נהדקו רגלי בכוח אל הרכובות, הייתי נופלת
        בודאי. וצר נעשה לי כאן על מעשה-זהירות שלי: מה-טוב המות מתוך רכיבה כמו
        בשדות וגנים! כאן תעלות עמוקות ואבנים הרבה – מה קל היה למות בן-רגע! בשלמה
        אני יושבת איתן על הסוס?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זכרון עגום עולה: מות אבי! – מטה שחורה אצל גל עפר תחוח; אני צועקת: אבא,
        אבא! ומורטת שערות ראשי. בני-אדם אוחזים בי ומדברים דברים, שאיני שומעת...
        הנה מטפלים אנשים בסתימת-הגולל, המעדרים מרימים ומשליכים עפר, ודרך גל העפר
        אני רואה את פני אבי ומדברת אליו. הלא הוא כפף על שכמי את ראשו כתינוק עוד
        בבוקר הזה, ועתה כבר הוא מונח באדמה קרה! קובלת אני בקול ושוב צועקת צעקה
        פראית... אחי גומר את ה"קדיש" ואני נדבקת אל האדמה הקרה שעל הקבר...
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עבר! הכל עובר והחיים אינם נדבקים במות מתוך רצון טוב בלבד...</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c13" dir="rtl"><span class="c10">ג.</span></p>
    <p class="c3" dir="rtl">
      <span class="c2">כ"ח איר.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מה-זה – האם מתאהבת אני לעת זקנה? ומאין פזור-נפש משונה זה, שהתחלתי לתפוס
        עליו את עצמי? – הנה יש שבלבשי חולצה אינני יכולה לפרוף אותה, ובחפשי את
        הסבה אני מוצאת, שאת החולצה לבשתי מלבר. פעמים באמצע העבודה אני חובקת ידי
        בעצלות ומתחילה להסתכל בנקודה אחת, שבה אני רואה בחזון-רוחי את שמואלי לבוש
        בגדי-השמירה שלו. – חסון הוא ומוצק; כתפים רחבות בולטות מתחת חולצה כחולה,
        ההולמת אותו כל-כך; מצחו הגבוה – חלקו התחתון, שאֶל הפנים, שזוף הוא, אבל
        החלק העליון לבן וצח; הצואר, להפך, שזוף למעלה ולבן סמוך לצוארונו הרחב;
        הכנף הימנית של עביתו מונחת כפולה על כתפו השמאלית! נוגע הוא בנַבוטו בקוצי
        השִטה ושר לו בחשאי איזה נגון לבבי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זה כשלשה שבועות עברו מעת שחדשנו את האגודה "בקור-חולים" ונתמניתי למפקחת
        בה, ורק זה שבועים מעת שהוזמנתי אל שמואלי הפצוע בידו מירית בדוי, שהתגנב
        למלא שקו שקדים ירוקים. לילה אחד של השגחה עליו קרב אותנו זה לזה ויתהוה
        היחס הידידותי שבינינו. הוא, שלא רצה לקחת מיד חבריו שום דבר, ציֵת לי,
        "לאחות הרחמניה", בכל. רק לי מותר היה להחליף את התחבשות ולטפל בפצעו
        האנוש, רק מידי שתה את הרפואה ואת החלב ועל-פי פקודתי לבד השתדל להֵרגע
        ולנוח.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ביום השלישי ירד החום וכל עקבות סכנה חלפו. אז ישב בבואי אליו ודבר אתי על
        משפחתו, אב ואחות – אחות חורגת, אבל טובת-לב... הוא בקשני להוציא מארגזו
        מעטפה אחת, שבה נמצאת תמונתה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– המוצאת היא חן בעיניך, גברת צפורה? – שאלני ויסתכל בחבה רבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >העיפותי עוד פעם עין בוחנת בתמונה. נערה צעירה, מוצקה, עם ראש עטור תלתלים
        ופנים מלאי-חן. מלאכת-הצלום כשהיא לעצמה משכה את לבי, כי היתה בה אמנות
        בפוזה, בנקוד ובהארה. זו היתה אחת מאלה התמונות המצוינות, העשויות באהבה
        בידי צַלם אמן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- לא ארוסתו זו? – שאלתיו והבטתי בו בעין בוחנת.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">הוא ענה בשלילה וצחק צחוק גדול.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבקורי הבא, אחרי יומים, כבר התהלך שמואלי בחדר. ידו עוד חבושה היתה ומקצה
        התחבושת בצבצו אצבעות נפוחות, אבל שום כאב לא הרגיש עוד. באמצע וכוח עם חבר
        מצאתיו, ובקשתים שלא להפסיק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אתה לעגת לפצעי, – אמר שמואלי לחברו. – אמרת: "אז, כשהיתה לכם ארץ, אולי
        כדאי היה לשפוך דם, אבל לא עתה". שוטה שבעולם! וכי אימתי נהיתה הארץ </span
      ><span class="c4">שלנו</span
      ><span class="c1"
        >, האם לא אחרי שכבשנו אותה בדם? והדם הוא הוא שיחזיר לנו את הזכות
        עליה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אבל בעל-הבית הנוכחי לא יוַתר עליה בשום אופן, - אמר חברו בבטחה, -
        והיהודים עם נקודות הישוב שלהם ישארו תמיד המעוט בתוך רוב גדול, זר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- ידידי, כלום אינך יודע, שהארץ הכילה לפנים כשלשה מליונים נפש? עלינו עתה
        רק למלא את המקומות הפנויים ונהיה יותר משני שלישֵי יושבי הארץ.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">לעג שנון התפרץ מפי חברו:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אבל נחוץ קודם, שעם ישראל ירצה בישוב הארץ ובבנינה, נחוץ, - את הוכוח
        הפסיק הרופא, שבא לבקר את שמואלי. אחרי בדיקה מעולה החליט הרופא, שבעוד
        ימים אחדים יצא האסיר לחפשי. שמואלי שמח לבשורה זו. וכשהלכתי מעמו, לחץ את
        ידי בחמימות וידידות והבטיח לזַכות בבקורו הראשון אחרי המאסר – את "אחותו
        הרחמניה".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עתה כבר שב שמואלי לשמירה. בחלקו נפל פעם הרחוב שלנו והככר הגדולה
        שמאחריו. ערב ערב הוא נגש אל אכסדרת ביתנו, עומד שעה קלה, מתרוצץ או מתוכח
        על רגל אחת והולך לתפקידו. בקורי-ערב אלה נעשו אצלי הרגל, מזדרזת אני לגמור
        את העבודה שלאחרי הארוחה ומצפה לבואו. בשעה קבועה קולטת אזני את קול-צפצופו
        מרחוק! בעוד רגעי מספר אני שומעת את צעדיו בעברו אל האורוה לבדוק את
        המנעול; אחר-כך הוא מסובב את גדר-הגן ומופיע על מדרגות האכסדרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משוחחים אנו בעמידה, כי לשבת אין לו פנאי. אני מתחילה להרגיש זעזועים קלים
        בלבבי בעת ששולי עביתו נוגעים באיזה אופן בשרוול חולצתי, ולפעמים, כשהוא
        שולח מבט רך ועמוק, עובר זרם חשמלי בצדעי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אתמול, לכבוד שבת, בקרני באמצע היום ויבא אתו את פשבישבסקי להקריאו לפני.
        התישבתי על דרגשי כמו ברונית, כר-הקטיפה השחום למראשותי והדום רך לרגלי.
        כשהקריב שמואלי את כסאו אל הדרגש עד שברכיו נגעו כמעט בשולי שמלתי, דפק לבי
        דפיקה קלה ונעימה... הנהו מסב ראשו ומעיף עין ברגלי היחפות, הנעוצות בסנדלי
        עור רך, פני מתאדמים וזרם חם עובר בכל גופי. אז הוא ממשיך את הקריאה
        בפזור-נפש ורשלנות, עד שקם מכסאו, סוגר את הספר ופוסע בחדר אילך ואילך. רטט
        בגוף וראשי סובב, אבל אני משתדלת להראות שויון-נפש גמור ומסתכלת בו מן הצד.
        הוא נראה לי בהתהלכו משונה מאד: חלק הלחי שמעל לשפמו עבה יותר מדי,
        גבות-העינים שחורות ושעירות, ומשום-כך דומים כמעט פניו לפני קוף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שמואלי נגש, הרתיע את כסאו ויתחל שנית בקריאה. קולו נראה לי עתה יותר יפה
        מקודם והפנים עם המצח הצח והגבוה נראים לי שוב נחמדים. שרירי מתחילים
        להתמתח ויש לי רצון כביר, שיתקרב שנית, ישב קצת כפוף אלי, עד שארגיש את הבל
        נשימתו. מרוץ-דמים חם מסית את היצר לעשות איזה תנועה, שירגיש במצבי, אבל
        קול מבפנים עוצר בי ולוחש את דברי מַרתּה טילינג, שהיתה מזהרת בהם את
        סילויה בתה: "Geh in Reinheit durchs Leben!". וקשה לי הטהרה ורוצה אני
        לבעוט בה ברגעים אלה, שהאהבה מושכת אותי יותר מכל תמימות וצניעות, ובכבשי
        את יצרי בוכה הלב במסתרים, ודמעה אחת חשאית נופלת ומסתתרת בשרוולי
        חלוקי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">שמואלי לא הרגיש בדמעה, גמר וקם מהר ללכת.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">ר"ח סיון.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שלשה חולים היו לי בשבוע אחד, ואני מתפלאה על עצמי, כמה אני יכולה להיות
        קשורה אליהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חלתה הזקנה בבית הסלקינים, - שם איני שבעה נחת אף פעם. שושנה היא היחידה
        שמטפלת בחולה. סלקין עצמו אינו מוכשר לכך או שאינו מסור לאם, ואפיני הוא
        דרכו המשונה בבית גם בעת שיש חולה שם. הנה אחד המחזות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי ארוחת-הערב הגישה לו שושנה כוס תה. הוא הסתכל בכוס, מצא שהתה אינו די
        זך, הרחיקו ולא שתה. נדמה לי, ששפתיו מלמלו בכעס: "לעזאזל!"...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שושנה נשכה שפתיה ברגזה; חזרה לחדר אמו ודברה אליה רכות, כאילו לא קרה
        כלום. אבל אלי פנתה אחר כך בלחש נוגה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד מעט והייתי נדונה לגט בלי כתובה!... אשרַי, שאף-על-פי שהאשה היא מקור
        כל פגע וכל צרה, אי-אפשר בכל זאת לעקור אותה על-נקלה מן הבית
        ולגרשה!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נרגזה הייתי גם-אני. לא ידעתי את מי לחייב ואת מי להצדיק בחיי הזוג הזה.
        איה הכח המושך ואיה הדוחה? מה אוחז אותה בבית הזה ומה קושר אותה אליו? אין
        זאת כי אם צחוק עושה לו רבונו דעלמא בזוגו זווגים, שחורקים שִניהם
        ומתאחדים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובכל זאת נשארתי ללון שם. חסתי על שושנה, שמטפלת לבדה בחולה זה כמה וכמה
        לילות. והזקנה הלא אינה דומה לבנה כלל, - היא מפליאה אותי בשכלה הבריא. דלה
        ורזה היא, כולה גל של עצמות – והפנים עדיין מלאי-חן. בנדוד שנתה שפכה את
        רוחה לפני. היא עזבה את הזקן שלה לבדו ובאה הנה, - למה עשתה עול זה? כשהיא
        בבית, הלא גם בחליה משגיחה היא עליו, שיאכל וישתה בעתו. חלש הוא ובלי
        השגחתה נפול יפול הזקן המסכן!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרגעתי את רוחה: הלא שושנה שולחת לו הכל, ולמה תרבה לדאוג לו? רצתה שושנה
        לקחתו אל ביתה, אבל לחיות עם הבן בשלום לא יוכל הזקן – ומוטב ככה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הזקנה עברה לענין אחר: שלשה בנים נתן לה ה&#39; ובת אחת, בת נאמנה ומסורה,
        אבל אינה אתם, - אין בעלה רוצה בארץ ישראל! צחק ולעג כשנסעו הם... לו היתה
        הבת כאן, אז לא הורגשה הבדידות הקשה! הנה שלשת הבנים – נכנסים הם במהירות
        לרגע קל ויוצאים. לו ישבו אִתה שעה קלה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">ובכל-זאת </span><span class="c4">בנים</span
      ><span class="c1">! – אמרתי לזקנה. היא הבינה לרעי ותאמר באנחה:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כן, לאמר "קדיש" לא זכתה הבת – ומשום-זה אינה זוכה גם בדבר אחר: אין היא
        יורשת עם הבנים. והבת הזאת, האהובה והמסורה, אף אם היתה פה, האִם היתה
        נהנית מעשרנו? הבנים, הבנים – להם העולם ומלואו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החולה השני שלי הוא חבקוק, תימני זקן, ידידי מכבר. הוא עבד אצל בנאי אחד,
        עלה על כותל עם קופה מלאה חמר, נפל ובינתו הסתתרה. כשקראוני, מצאתיו
        בבית-לולו, שוכב כבול-עץ על הארץ, עיניו הפוכות, נסגרות ונפתחות מאליהן,
        מבלי ראות כלום; הידים והרגלים נעות תנועה משונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבואי מצאתי את הרופא אצלו. על שאלתי, אם יש סכנה, ענה לי, שאם היה שטף-דם
        במוח, לא יחיה הזקן. ברחמים כרעתי על ברכי, לטפתי את ידיו הצהובות, החלקתי
        את לחיו הרזה ודברתי אליו דברי-נחמה, כאילו יכול המסכן לשמוע אותי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלב נשבר עזבתיו אחרי חצות על יד שני חברים מאגודתנו. מסרתי להם דבר השמוש
        בקרח, שהובא מן העיר, ודבר הרפואות, והלכתי הביתה. אבל כאן בשכבי לא מצאתי
        מנוחה. אמנם נרדמתי לשעה קלה, אבל קול חד-גוני, כמו דפיקות במכתש גדול,
        העירני בפחד. נדמה לי, כי משכונת התימנים עולות הדפיקות ולבי התחיל רותת:
        שמא מנהג הוא זה אצל התימנים לדפוק ככה בעת יציאת נשמתו של אדם?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קפצתי מן המטה, התעטפתי בשכמִיה ויצאתי אל החצר. כאן הוברר לי, שהדפיקות
        עולות ממכונת הפרדס הקרוב, שבלילה זה התחילה השקאתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נכנסתי, התעטפתי יפה יפה בשמיכה דקה ונרדמתי. לפָני עומד חבקוק חי וזריז
        כמו שהיה. פדיה, אשתו היפה, מלאת-המרץ, שאני קוראת לה לפעמים בדמיוני
        "נוֹרה האִבְּסֶנית", יושבת אצלו על הארץ ומגישה לאפו שושנה ורודה
        להריחה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >דפיקת צפור נודדת בתריס העירתני ואמהר להתלבש, כדי לרוץ אל חבקוק. התחילו
        חודרות דרך התריס קרני-בוקר הראשונות, נשמע צפצוף חשאי של צפור מתעוררת
        משנתה, על מזוזות החלון מגששים קוים רפים, ואני עוזבת את החדר והולכת
        כשהמית-צפרים רבה כבר בוקעת מבין כל העצים מסביב. באתי כמעט במרוצה אל
        השכונה והנני מוצאת שם את חבקוק שלי נצל משִני המות. שב אליו רוחו והוא
        מכירני כבר. רוצה להגיד לי דבר-מה, אבל הקול כאוב מארץ עולה, ואני בקושי
        מבחינה את רחש שפתיו: "מהולל שמו יתברך!" – ופדיה היפה ממלמלת מתוך שמחה:
        "ברוכה תהיי, ברוכה תהיי, גברת צפורה!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החולה השלישית הובאה ממושבה אחרת להוציא את ימיה בבית זקֶנתה. אשה למעלה
        מחמשים היא החולה. חיים מלאי-תלאות עברו על ראשה והוכתה בשחפת. צל המות כבר
        פרוש על פניה, אבל עקבות יופי של נפש עדינה ומלאת-שירה עוד יש להכיר
        בה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שעות תמימות אני יושבת על-יד מטתה, אוחזת בידה הקרה הצהובה ושומעת את לחשה
        כשהיא מתאמצת להפיץ אור על העבר. כמה עגום העבר הזה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אזני קולטת מעשה בצעירה חלוצה, שנשאה לאכר, ילדה לו בן יחיד, והאב נקטף
        בעודו באבו. במסירות גִדלה את הבן ובחריצות נהלה את המשק הגדול ותשבחהו
        ותשפרהו ויהי לתהלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עברו ימים. הבן גדל, נשא אשה וינשל את אמו מנחלתה! - - -</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">ד&#39; סיון.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תוקף אותי כעין שגעון בעת שאני מתחילה להכניס סדר חדש בגן-הפרחים שלנו.
        יומם ולילה אני עוסקת בסדור. לילה כיצד? בלילה אני מחברת תכניות על תכניות,
        איזה פרחים לזרוע בפנה זו ואיזה בפנה אחרת, איזה פרחים להעביר מערוגות אל
        עציצים ואיזה ישר אל האדמה. בבוקר, קודם שאני מתחילה להתלבש, עיני כבר
        נטויה אל הגן ומוצאת מיד, שהתכניות, אשר עשיתי בדמיוני בלילה, אינן מתאימות
        למציאות ואני צריכה לשנותן. ימים ולילות, ימים ולילות לקח הגן ולא נחה דעתי
        ממנו, עד שלבש את הצורה הנאותה לפי טעמי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">אחי מסתכל בגן ואומר בגחוך קל:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- מעשה ידי אשה!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- במה הוא רע – מעשה ידי האשה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- בזה שהאדמה אינה מעובדת כדבעי! – והוא מביא מעדר ומראה לי, מה-זה עדור
        טוב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא צדק. בעבודה שלו האדמה מקבלת צורה אחרת לגמרי, ואני בכל הויתי מרגישה
        את יתרונו בזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בא אל גני בשעה שאחר-הצהרים גם שמואלי. יחסו אלי היה הפעם דומה לזה של
        גימנזיסט המתחיל להתאהב. נגש קרוב למקום עבודתי וישב על הספסל הסמוך. מיד
        הרגשתי גרוי בעצבי ודפיקה קלילה בלב. הוא תלה את מבטו בקצה סנרי ויתכופף
        אלי. ראשי התחיל סובב, אבל התחזקתי ומיד עברתי לערוגה השניה והתחלתי לישר
        במתינות את האדמה השפוכה בערמה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זה נעשה כלאחר-יד, אבל הוא הרגיש בכונה להתרחק ממנו ושאל בהתרגשות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- למה ברחה הגברת צפורה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אסור לאיש ואשה להמצא קרובים זה לזו יותר מדי, - עניתי והתאמצתי להראות
        פנים צוחקות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- משום מה, גברתי?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- משום שנותנים לשטן שליטה לרקד ביניהם!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"> - זאת אומרת, שהיא אוסרת את האהבה בין גבר לאשה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- חס וחלילה! אהבה היא הדבר הכי יפה שיצר בורא-עולמים, אלא שאיננו גודרים
        אותה יפה ומחליפים התעוררות של רגע ותשוקת-הרגע – באהבה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התחיל בינינו וכוח גדול על נושא זה; וסוף דבר היה, שהלך ממני שמואלי נרגז
        ונרגש, ואני הוכחתי את-עצמי על שטויות ועל שגיאות כדרכי תמיד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ישבתי על ספסל בעצלות ולהמשיך את העבודה לא יכולתי עוד. ביד מגובי הקטן
        שרטטתי במהירות קדחתנית על-גבי החול: "חביב, חביב, יקר!" עד שמלאה הכתובת
        את השביל שלפני הספסל. וכשחדלתי, שאלתי את עצמי: מי הוא הנעלם הזה, אשר כל
        ימי אני פונה אליו בכתב ובעל-פה? ומדוע עטי או פי פולטים לפעמים גם
        שמות-חבה לאשה: "חביבה, חביבה, יקרה!"... איזו פליאה יושבת במסתרי נפשי? או
        אולי זה הד קולו של גבר רחוק, שפעם היה קרוב אלי?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בא העדר ואני נטלתי את כדי והלכתי לחלוב. וגם בתוך החליבה לא פסקו הרהורי
        על האהבה ועל החיים. ומשונה הדבר בעיני: מצד אחד דוחה העגל הקשור, שרוצה
        ביניקה, מצד השני מכה הפרה בזנבה בטרוד אותה הזבובים והיא מצליפה על פני
        בלי רחמים, לאט מקשקש לו הזרם הלבן לתוך הדלי, הקצף הצח מרהיב את העין,
        ובמוח מקשקשים הרהורים על חובות-החיים, שהיו למשא. נזכרת אני בספרה
        היהודי-הנוצרי של ז&#39;ורז&#39; אֶליוֹט, שבו מוצאת הסופרת, כי החובות הן
        אושר החיים. הנה דירונדה מיעץ לגונדולין ברגעיה הקשים, שתעבוד, תגמול חסד
        עם בני-אדם ואז תדע, שלא לחנם עברו חייה... שטות, מלים בעלמא! הלא דירונדה
        בעצמו לא מצא ספוק גמור בכל חסדיו הרבים עם בני-אדם ובקש לבסוף פתרון אישי
        לחייו, בקשרו אותם עם מירה שנשָאה לאשה....</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והסופרת עצמה איך התיחסה לאהבה? לא הלב הגדול ולא האמנות נתנו לה ספוק
        מלא, כי-אם האהבה, ובהיות אליוט כבר בת ששים ומעלה בקשה עוד חסות לה תחת
        כנפי אלהיה זה, – ומדוע אני, אדם פשוט ורגיל, צריכה להנזר מן החיים?
        צריכה!... וכי מאן-דהוא גוזר עלי את זה? הלא אני בעצמי חורצת משפטי
        לסגופים! - -</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי סנני את החלב וחלקי אותו ללקוחותי, התרחצתי ונכנסתי אל פנתי.
        קרני-שמש האחרונות פזזו בחדר היפה. וילונים צחורים, קירות מבריקים מלובן
        וטרַדיסקַנסיה מטפסת עליהם בשתי פנות; אספרגוס בחבית ירוקה בין שני חלונות
        ופרחי-אלמות מכל המינים תקועים בצנצנות קטנות, מצוירות. תמונות הורַי
        הגדולות מרהיבות-עין בנפול עליהן קרני האור; גם תמונות הסופרים שעל הכוננית
        ספוגות אור. שָם טשרניחובסקי עם תלתליו וצחוקו מתנוסס באמצע כל הכבודה;
        מבטו מלטף ומחמם. "נצאה נגורה שאננים" – מגרה אותי קריאתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על יד החלון אני יושבת ושוגה באהבה. הנה עובר זוג של הורים צעירים. הוא
        נושא את הפעוט על ידיו ומציץ באהבה פעם בעיני רעיתו ופעם בעיני הילד. הנה
        זוג שני הולך, שעוד לא נכנס לחופה, שלובי-זרוע הולכים, הוא כופף אליה את
        ראשו ועיניו מזהירות משמחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על קצה גג מנגד מתעלס זוג יונים, נגיעה בחרטום ונשיקה, נגיעה ונשיקה,
        והיוֹן הומה והומה ומתרפק באהבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בקצה הסימטה יוצא משער הפרדס נער, שאך זה התחיל להתבגר; טל-נוער על שפתיו
        ואודם בלחייו. הנה שם אצל השיטה מחכה לו נערה זקופה; ראתה אותו וכל קו וקו
        שבפניה נתמלא אור וגיל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נשבו עלי רוחות-נוער. ואני משתוקקת ומתאוה ומתענה בגעגועי. קנחתי דמעה
        שנשרה ושפתי מלמלו:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"ובהתעטף הלב ובהיותו למסוס,</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">אל תראני עין זר, זר לא יבין את זאת".</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גבעול-קש עף מן החלון השני ונח על ראשי. נגשתי לפתוח שם את התריס ולהשליך
        בכעס את הקש והגבב, שזוג אנקורים הכין שם, כדי לבנות להם קן; השלכתי רק את
        החצי והפסקתי, כי תקפני באמצע מוסר-כליות: למה אני רודפת את הזוג האהוב
        ואיני נותנת לו לגמור מעשהו? –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ענף עבה של אזדרכת, המכוסה כולה בפרחיה הכחלחלים, נדחק אל הקיר ועל ענף זה
        עומד האנקור עם גבעולי-קש בפיו, מוכן ומזומן לעוף ישר אל חלוני, - אין
        המסכן יודע עוד, שאני כמעט הרסתי את קנו ועמלו, אין הוא יודע, שיד רשעה
        אמרה להתאכזר אליו!
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי שעה אחת נגש שמואלי אל חלוני כדרכו. שוחחנו על אותו הספר הגרמני
        המודרני, שהוא השיגו לקריאה בעמל רב. אחר לכתו זרק אחי מלה, שלא האמנתי
        לשמעה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- "מזמוז"! גם אחותי יודעת "להתמזמז" יפה!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואולם תוכחה גסה זו לא נגעה בי הפעם אף כמלוא-נימה. הפסיכולוגיה שלו אינה
        יכולה להורות אחרת... וחוצפה של נער? זה לא-כלום, לא-כלום!</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">ט"ו סיון.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמר באה לבקרנו. עמרם, מאושר כולו, רתם אחר-הצהרים את מרכבתנו הקטנה
        והוביל אותה אל הכרם להראות לה, מה רב יבול השקדים השנה. הם שבו משם שמחים
        ועליזים. עמרם ערך בעצמו שולחן קטן בגן, מזג תה, הביא מכל טוב שבבית וגם
        שוקולדה, השמורה בקופסה נהדרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ארוחת הערב עברה עלינו בצחוק ועליצות. אחר כך הלכו לטייל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בגמרי את העבודה בבית, יצאתי לשאוף רוח בגן, ומה נבהלתי במצאי כאן את תמר
        יחידה, כשהיא יושבת לה נוגה על הספסל תחת התומר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- היכן עמרם? – שאלתיה בתמהון.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אינני יודעת! – ענתה תמר בתוגה. לבי התחיל דופק בחזקה. החלטתי ללכת לחפש
        את עמרם; אך לבל תכיר הילדה בזאת, נכנסתי קודם הביתה ויצאתי מן העבר השני
        אל הרחוב. אני מצאתיו תיכף, - הוא ישב בפנת הסימטה על אבן גדולה וראשו מוטה
        ארצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- למה זה עזבת את תמר לבדה והלכת לך? האם מן היושר הוא להתנהג עם הילדה
        ככה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא לא ענה, כי אם כבש את פניו עוד יותר בקרקע, ואני משנה את הנעימה הקשה
        בקולי, מניחה את ידי ברכות על כתפו ומתחילה לשדלו כי ישוב. שתיקה גמורה; רק
        אחרי תחנוני הרבים הוא משמיע קול רפה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- הלילה אלין בכרם, והביתה לא אשוב!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">כולי רועדת אני מסתכלת בעיניו הזורקות אימה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- עמרם, מה לך – מה לך?...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שוב הוא שותק; תחנוני אינם מועילים, ואני שבה לבדי הביתה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכאב עצור אני מכניסה את תמר הביתה, עורכת לה את המטה, מחפשת דבר-נחמה
        בשבילה ומבקשת אותה לשכב ולהרגע, – הוא תיכף יבוא!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עברה חצות: תמר עוד יושבת עֵרה על ספרה באולם ביתנו, והנה שומעת אנכי את
        צעדי עמרם. עלה במדרגות, עבר את חדר-האוכל ויתדפק על דלתה. הם התלחשו זמן
        רב, עד שלבי נרגע ואני נרדמתי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קול חנוק בגן העירני משנתי, לבי נדהם והתחיל לדפוק בחזקה. התעטפתי מהר
        בעביה שלי ויצאתי אל האכסדרה. מלמטה, אצל גדר השיטה, ראיתי תמונה לבנה
        עומדת ובוכה בכי היסטרי. ירדתי במרוצה. תמר רועדת מבכי ואחי עומד אצלה,
        מסלסל שפמו הדק ושותק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רבונו-של-עולם, – מה קרה ביניכם הערב? הגידו, הגידו! – התפרצה הצעקה מפי,
        ואני חבקתי את תמר, כסיתי את ראשה בנשיקות ובקשתי תחנונים, שתגלה לי את
        האמת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">שום דבר לא נודע לי, – הם שמרו על סודם בעקשנות.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">כ&#39; תמוז.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מהו העצב, האוכל ומדכא כה את לבי? האם לא הרגלתי באבדות? – הוא נסע!...
        ומה בכך? האם קויתי, שמאן-דהו יוכל להקל על הבדידות, שנמשכת עשרות בשנים
        ותמשך הלאה, עד הסוף?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שלשום קבל פקודה לנסוע העירה ואני לא ידעתי. זמן רב חכיתי לו בערב על יד
        החלון והוא לא בא. יצאתי את השער, עברתי לאטי את הרחוב, אולי אפגשנו או
        אשמע מרחוק את צפצופו; הגעתי עד הככר שתחת פקודתו, ואינו גם כאן. אז התחיל
        לבי מהסס, שמא חולה הוא וזקוק לעזרה, - איך אדע את זה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שקועה בהרהורים שבתי חזרה והנה אצל השער אני פוגשת צעיר זר, שיצא מן החצר.
        הוא היה לבוש קפיה ועגול עבה כדרך השומרים. עלה בדעתי לשאול אותו על דבר
        שמואלי, אבל בטרם הספקתי להחליט את זה, חמק עבר לו. מה אעשה? הארוץ, הארדוף
        אחריו? נשארתי עומדת סמוכה אל אחד העצים מבלי יכולת לזוז.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בעלותי בשעה מאוחרת על משכבי, הקשבתי קשב רב לשריקות השומרים. פתאום נדמה
        לי, שאיזו שירה ידועה לי נשמעת על הגבעה מרחוק, - הקולו הוא זה?... אני
        יורדת מן המטה, נגשת אל החלון ופותחת אותו. שם על הגבעה כבו כל האורות
        והשכונה עטופה עלטה וישֵנה, רק שירת השומר עֵרה; אני נוטה אוזן ומכירה
        היטב, שלא קול שמואלי הוא, אבל מן החלון אינני סרה, עומדת אני ורועדת
        ושואלת את נפשי: מדוע קר לי בלילה רך ופושר זה ואני עטופה שמיכה? אין זאת
        כי-אם חולת-אהבה אני!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >השעה שתים. ראשי כבד עלי ולהרדם לא אוכל; אני מתחילה למנות אחת, שתים, שלש
        עד עשרים וחוזרת על המספר, מה שמשמש לי לפעמים תרופה לשֵנה, אבל הפעם זה לא
        מועיל ואני מנסה תרופה אחרת: להעלות בזכרוני תמונה מימי הילדות. הנה שדה,
        שפת נחל, ערבות. מצד אחד של הנחל טחנת-מים. יש שהסתכלות דמיונית בסבוב גלגל
        התחנה משרה עלי שנה, אבל עתה אינני יכולה בשום אופן להתרכז בגלגל, כי
        מחשבתי שוטפת הלאה: חוף נהר רחב-ידים, ספינות נהדרות על המים ומעבר לנהר
        שדות וכרי ירק עשירים, שעל-פניהם פשטו בני-נוער נכרים ביום חגם ויהודיה
        יחידה ביניהם; הלולא וחנגא – והיא רוקדת על חתונת הזרים... והנה נזכרת אני
        גם בים גדול, גלים רועשים, קוצפים ורותחים, הספינה הענקית נושאת נפש בודדה,
        יחידה בין רבים. יפה, אבל קשה הנסיעה והחוף רחוק הוא...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כואב, כואב הראש, אני מבקשת להמלט מהזכרונות הנוגים, שעטו עלי כעדת עורבים
        צורחים, ולא אוכל; אני מרימה את ראשי הכואב  ולוחצת אותו אל ברזל המטה,
        כאומרת לפוצצו, והנה חרוזים אהובים עולים בזכרוני ואני חוזרת עליהם:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"ובלבי שׂם את קנו צפעוני,</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">המטיל בי ארסו ומוצץ את אוני.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">הוי אנה מחמתו אברחה, אנוסה?"</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי אנה אברח מעצמי, מטפשותי ומסכלותי, מהעבר הנוגה ומהוֶה שאין לו
        תקנה?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התחיל הבוקר מתקרב, מבין מקלות התריס נשבה רוח-ים בחזקה. קול-צפור דק
        השמיע בשורת יום. עורב צרח בחורשה, קול לקול הצטרף, עד שמקהלה שלמה פתחה
        ברנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כנף רננים נעלסה", עולה במוחי פסוק מהתנ"ך. ופתאום מחזה קורע לב לפני
        חלוני: חתול גדול טפס על עץ רם, קפץ וטרף אחת הצפרים. כל העדה עפה, פרחה על
        נפשה; רק שם, בקצה הגן, עדיין מרננות שתים, שכנראה לא ראו את דבר
        הרצח.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כנף רננים נעלסה" חוזרות עוד שפתי כשהידים כבר עסוקות בחליבה והלב עוד
        מלא געגועים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בהכניסי את החלב הביתה מסרו לי פתקה מאתמול, שבה מודיע לי שמואלי, כי הוא
        נוסע לרגלי עניני "השומר" העירה וסובר הנהו, כי תתהוינה חדשות במצבו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואמנם כך היה. בשובו מן העיר סר מיד אלי לבשרני, שההסתדרות שולחת אותו
        הגלילה. בשמחה רבה סח לי שמואלי על עתידות "השומר" בגליל: לא כיהודה
        קשת-העורף, שאינה רוצה עוד לנתק מוסרות העבר בנוגע לשמירה זרה, הוא הגליל!
        גם יהודה תבין סוף-סוף את דרך האמת, אבל הגליל הקדים, וכבר מסר את אחריות
        כל המושבות בידי "השומר", שתכניותיו רחבות הן ועתידו גדול ובטוח...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >למחרת בבוקר, לאחר ששם שמואלי את חפציו בדיליז&#39;נס, עוד נכנס אלי בפעם
        האחרונה לרגע קל. בצחוקו הנעים נפרד מעלי שנית ונעלם... ואני? – מי, זולת
        פדיה הקטנה שלי, ראה את דמעתי?... אומים שדמעות מרככות את קושי הפצעים. לא,
        לא אמת...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עתה ערב. אני יושבת מדוכאה ואיני מסוגלה לעשות כלום. מביטה בלי-נוע בחשכת
        השמשות ובצללים המצטיירים מסביב: הנה צל עגול של המנורה על השולחן וצל ידי
        האוחזת עפרון; הנה ספל וצלו, צלחת וצלה, ובנפשי פנימה צללים צללים, עד אפס
        מקום למחשבה. נטל ממני הרצון לעשות משהו; אפילו לשרטט את ההברות הבודדות,
        הרגילות שלי אין ביכלתי. אני עומדת על ההתחלה: "חביב, חביב", - ואיני
        ממשיכה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ופתאום כאילו התחיל מתפזר הצל ובמוחי נוצצת קרן-אור בודדת של מחשבה
        אי-ברורה: עלי להמסר כולי לעבודת העזרה, לחלק לנצרכים כל מה שמיותר לי, עלי
        לתת חלק מרכושי לאחת מקופות-העם, - אולי יגורש אז הצפעוני וארסו לא יאכל
        עוד את לבי...</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <h2 class="c6 c12" dir="rtl"><span class="c8">ד</span></h2>
    <p class="c3" dir="rtl">
      <span class="c2">ז&#39; אב</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בציר. את כל שבעת מדורי הגיהנום אני עוברת בעונה זו, שכוט הזעם נשפכת עלי
        במלואה. אחי עובד חצי יום עם חבורת עצלנים גדולה ושב לצהרים כועס וזועף.
        הנה השתטח על הספה וסגר את עיניו בעיפות; אני מחכה עד שינוח, ואחר-כך קוראת
        לו אל השולחן לאכול, אבל הוא אינו עונה ואינו זז ממקומו; וכשאני קוראת לו
        שנית, הוא קופץ בתנועה משונה, הופך כסא ועציץ עם פרחים וזה נשבר לרסיסים.
        מאספת אני בזהירות את שברי העציץ, משליכה אותם מעבר לגדר, ואת השיח שנשאר
        שלם אני מעבירה מהר לכלי אחר, ואנחנו הולכים לאכול. עמרם אינו יושב במנוחה,
        מתופף באצבעותיו על גבי השולחן, מניעהו וכאילו אומר להפוך גם אותו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לבי דואב על התרגזות עצביו ואני שואלת אותו בהשתתפות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- מדוע לא עשית לך מקלחת בבואך? הלא זה פועל עליך לטוב!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">- עובדים עבודת פרך, ואת עם המקלחת שלך!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואותו היום ערב שבת היה. לא נמצא בקופתי כל הסכום הדרוש לתשלום
        שכר-הפועלים ואמרתי, שאת המותר נשלם להם בשבוע הבא. בעד זה לבד אפשר שלא
        היה עורך לי מחזה, אלא שאני העזתי להעיר, כי הוא קבע להם מחירים גדולים
        יותר מדי, וכי בעד בציר-ענבים, שזוהי עבודה קלה והבוצר אוכל פרי לשובע, אין
        צורך לקבוע מחירים כמו בעד עבודה קשה בזמן אחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מיד השליך עמרם את רשימת-הפועלים מידו ולא רצה לטפל בה יותר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >למחרת, במוצאי השבת, נודע לי, שלא רק עבודת הבציר, אלא גם ענין אחר היה
        בעוכרי עצביו. עד שלש בלילה לא שב הביתה, כי טייל עם איזו גברת, אשר מפי
        חבריו שמעתי מכבר רמזים עליה, ולא האמנתי. מה נפל בינו ובין תמר – נשאר סוד
        כמוס בשבילי ובחרדת-לב צפיתי לברור הדברים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יום ראשון. עדת פלחים ובדוים עם בנים ובנות נכנסו שוב אל החצר, וסדור
        חבריה זו מרגיז את עצבי עמרם. הוא מכיר, שהחבורה גדולה יותר מדי ושאין לו
        צורך בכולם; אלא כיון שהזמין אותם, לא נאה לחוג&#39;ה שכמותו לפטרם
        בלא-כלום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בערב אני מחפשת שעת-כושר לדבר אתו על הצורך בקמוץ ההוצאה בשנת-בצורת כזו.
        ההוצאה היתרה תכניס אותנו בעול של חובות, שלא נוכל לסלקם אחרי-כן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">אחי עונה על דברי בתרעומת הרגילה:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אם אי-אפשר בארץ-ישראל להתקיים על רכוש חקלאי, למה את עוצרת אותי כאן?
        הלא בכל מקום הייתי מוצא את לחמי ברֶוח, מבלי שאסבול צרות אלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רוצה אני לשתוק, שלא לצער אותו בתשובתי, אבל זה כל-כך קשה בעת שנפשי
        מתפקעת לראות את הנעשה מסביב! יש שאת כל אגודת הקטנים הוא שולח לבצור כרם
        קטן של דונמים אחדים. הם צריכים לגמור את בציר הכרם, לפי חשבונו, ברבע
        יום  - והם מטפלים בו יום תמים. ויש שמתבטלת כל החבריה הגדולה שעות תמימות
        מחוסר סלים. ואני חורקת שנים ואיני אומרת כלום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם קטוף השקדים וקלופם מביאים לי די צער ומכאובים. בכרם חדש, שנותן פריו
        בפעם הראשונה, עבדו שני ערבים גדולים ושתי ערביות על סל שקדים אחד. ארבעה
        פועלים גדולים במקום נער אחד, שהיה מספיק לקטוף את הסל האחד, - זוהי דוגמה
        יפה של משק המתנהל בכשרון!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לקלוף השקדים מהכרם העתיק שלח לי חבורת בדוים שלו הביתה, ואני לא קבלתים,
        כי מסרתי את העבודה לתימניות; נעלבו היחסנים שלו ומהרו לשוב אל הכרם להגיש
        עלי קובלנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >המשפט. שיצא עלי, קשה היה: מארוחת-הצהרים, ששלחתי לו אל הכרם, החזיר לי עם
        השליח את החצי; לארוחת-הערב לא בא כלל; חצות הלילה – והוא איננו! השעון דפק
        שתים, כשנתעוררתי לקול קשקוש מזלגות וכפות. אז שכחתי בן-רגע את כל הצער
        שלי. תנעם לו, למסכן, ארוחתו אחרי עבודה מרובה והתרגשות של יום-קיץ
        תמים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מאיזו פנה חבויה שבלב צפה אהבתי אליו ואני מוחלת לו הכל, את השגעונות, את
        יחסו הרע אלי, את המזמוז עם אחרות, בעת שנפש תמר כלתה אליו!... מצדיקה אני
        עליו את הדין, שלא הוא אשם בתכונתו הקשה, אלא "הירושה הטבעית"! וכאן
        מתעוררת בי כעין גאוה, שלמרות כל החסרונות עלה בכל זאת בידי לגדלהו במקום
        האֵם, והוא כה אמיץ, חזק, יפה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וברגעים אלה, כשמסתלק צער הלב, אני מרגישה את עצמי כברונית ממש בתוך המטה
        הרכה והצחה. דרך מקלות-התריס מנשבת עלי רוח פושרת עדינה; בכל אורך גופי אני
        משתטחת על רכות כרי תחת שמיכה קלה, עטופת צֶפֶת-בד לבנה, דקה ומגוהצה.
        שוכבת אני והוזה: אולי ברונית אני באמת? ובבוקר, בשכבי ככה בחדר שהכל מבריק
        בו, מדיותַת-הנחושת שעל שולחני עד המסגרות המזהבות של תמונות הורי ועד
        ידות-המנעול של הדלת, – תכנס משרתת צעירה ונקיה, לבושה שמלת-בוקר לבנה,
        ותביא לי כוס שוקולדה עם עוגות טריות... אבל ביום הבא לא האיר לי המזל כלל.
        הובלת ענבים אל היקב לא היתה מוצלחה, כי מוסטפה החרוץ הפך עגלה אחת עם
        ענבים בחול וישימו עלינו עונש כסף, ואת השניה לא רצו לקבל כלל. רצתי כל
        היום, התלבטתי מן היקב אל הכרם ומן הכרם אל היקב; בקשתי, התחננתי וגם רבתי
        עם הפקידים, עד שהסתדר הדבר; וכשדברתי בערב על כל הרפתקאותי היום ועמדתי על
        זה, שאת מוסטפה אנחנו צריכים לענוש, בכדי שיזהר להבא בהובלה – התלקח
        הר-הגעש. עמרם לא חרף ולא גדף, כי אם התחיל זורק בגדים בכל פנות החדר.
        וכשראיתי את האש, שהוצתה בעיניו, חשבתי בלבי: עתה רק זה יחסר, כי ירים את
        ידו עלי! – ופרצתי בצחוק משונה. זו היתה, כנראה, התחלה של היסטריה, אבל היא
        נפסקה על-ידי חברו של עמרם, שנכנס באותה שעה. הוא הרגיש בהתרגזותנו והתחיל
        לחקור את הסבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">עמרם היה גלוי-לב וימלמל בהתרגשות:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לסבול שוב איני יכול בשום-אופן! אברח מכאן ואלך למקום שרוב חברינו הלכו
        מכבר, - עכשיו הגיעה השעה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נכויתי ברותחין ומדת צערי עברה את הגבול. מהרתי אל חדרי לבקש פדות בבקבוק
        קטן עם תמצית-קרבול, שעמד על רף-הרפואות שלי. הרגישו שני הבחורים במזמתי
        הרעה. התפרצו לחדרי והוציאו מידי את סם-המות, בטרם הספקתי לגמוע ממנו. -
        -</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועבודתנו מוסיפה להתנהל בדרכה הקודמת, רק רעיון המות אינו מסתלק מן הלב,
        ובכל מקום הוא רודף אותי. אבל איזו החלטה משונה התגנבה אל לבי, לחכות עד
        שבט, שאז תמלאנה לי ארבעים שנה! אם זהו קצב החיים של אדם מיותר בעולם, או
        סתם דחיה, בכדי להרחיק את השעה שאינה נעימה – לא חקרתי, אבל בלי-הפסק היתה
        על שפתי המלה "שבט", וחשבתי את יום-הולדתי הבא לגבולי האחרון ברצינות
        גמורה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יש שאחי אומר לפגוע בכבודי וזה לא מעליבני כלל, - אני מביטה עליו בחיוך
        וחושבת: הוא למלחמה, ואני לשלום – בשבט יבוא השלום הגמור!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עומדת אני על יד חלוני בלילה ומונה את החדשים, שנשארו עד שבט: כמה הם?
        ששה! אין דבר, הם יחלפו כחלום! ואני מסתכלת בינתים בגן. מעבר לזית משמאל
        עולה הירח עם שני מלוים-שמשים ננסים ומפיל סוד אגדי על הגן למטה. התקפלו
        והתחבאו שיחי החצילים והפלפלים והמלפפונים. מרחוק מבין עצים גבוהים בולטים
        גגים שלשה; אש בודדת נראית באחד החלונות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והירח עולה ומתרומם; נעלמה האפלולית מן הגן ושורות הירק מסתמנות
        ונבלטות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >השלך הס בכל. רק רחוק-רחוק כלב נובח וצפרדע מקרקרת בתוך אחת הברכות.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">י&#39; אלול.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קול שופר עולה לבקרים מבתי התפלה. רוחות-סתו מנשבות בעולם ועל שָמי אני
        הגיחו עננים כבדים. הנה דבר האהבה החדשה של עמרם. תחילה התלחשו הרוחות,
        אחר-כך דברו קצת יותר בברור, עד שנגלה לי כל הסוד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כמבשר טוב הופיע הראשון לביתי מר סלקין ונכנס בשיחה אתי. בעקיפין וזהירות
        התחיל: הוא ידיד לנו, ולא יכול להכחיש... ומה נודע לי מפיו? האשה, אשר עמרם
        נגרר אחריה, היא גרושה צעירה עם עָבר חשוד מאד, והוא עומד לשאתה בקרוב...
        כל המושבה חושבת, שהצעד הזה הוא אסון בשביל עמרם ושעלי מוטלת החובה להזהירו
        מפני הסכנה הצפויה לו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רטט אחזני לשֵמע דבריו, אבל התאמצתי להסתיר את מבוכתי ולענות במנוחה
        מדומה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אני מודה לו מאד, אדון סלקין, על אזהרתו, אבל יחד עם זה עלי להגיד את
        האמת, שאני מתפלאה על עצתו. כלום אינו יודע, כי בימינו אין שואלים בענינים
        כאלה בדעת מי-שהוא ואין שומעים בלתי אם בקול הלב? לי אין שום רשות מוסרית
        להתערב בעניניו של אחי!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- אבל להתקשר עם אשה כזו!... עמרם דרורי ואשה כזו – זה אי אפשר, גברתי! –
        מחה סלקין בתם-לב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- והרי יכול להיות, אדון סלקין, שכל המדובר עליה לרעה אך בדות הוא: הלא
        ידוע לנו, שהרבה דעות קדומות שולטות בחברה. בני-אדם אוהבים להתלוצץ איש על
        חשבון רעהו. הרבה בַדאים ישנם, שמפיחים כזבים ומפילים צללים על בני-אדם
        ישרים. אפשר, שגרושה זו אשה הגונה היא ועמרם לא יפול בפח!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סלקין הלך, ובי הורגש אבק של חורבן חדש, אשר החניק את גרוני.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואולי מוטב כך! – דבר אלי אחרי שעה קלה אותו הקול, שמבקש לאדם הקלה במצבים
        קשים. – אולי מוטב כך, מפני שלזו לא אצטרך לחרוד כמו לתמר הרכה והחלשה
        בגופה ותכונתה! נראה, שזו היא בעלת-מרץ וידוע תדע להגן על עצמה, ואני אסבול
        פחות מיחס אי-צודק שלו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">המאושר יהיה עמרם?...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אפשר, אם רק לא יבחל בה אחרי שבעו אהבתה, ואם היא בעלת-הבנה ותדע לשלוט
        בתכונתו!... וכאן אני הוזה כבר על דבר עתידו: עמרם בעל הגון... אשה נאה לו
        וילדים – כשתילי-זיתים סביבו... וכאן, לעיני, הכל צוהל לקראת עתיד החיים:
        השמים הכחולים עם עננים צחים כבדולח. העצים הגבוהים והרעננים עם צפצוף
        הצפרים עליהם, עולם של בריות זעירות פורחות מעץ לעץ וטסות טיסה מהירה זו
        אחרי זו לתוך האויר החפשי – רק זוג צעיר נשאר עומד בחשיבות על אחד הבדים,
        המתנועעים מפני הרוח, והם נדבקו בלטיפות ונשיקות זה לזה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ההוֶה והעתיד נתונים לאהבה!... עוברות פעוטות בסימטה, תמימות ויַלדות על
        פניהן, ואף-על-פי-כן אי-אלה קוים כבר מבשרים אהבה: הנה שתי מחלפות הורדו
        לצד הפנים ומתנועעות על החזה החלק – האם לא למען צודד עיני נער חמוד הושמו
        המחלפות לראוה? הנה צועד צעיר מגולח למשעי ורבב אין על בגדו – האם לא לקראת
        אהובתו הוא מחיש צעדיו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובחדרו עומד עמרם שלי לפני הראי ועושה את שפמו  - גם הוא הולך לקראת
        האהבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אולי דרושה לך החליפה הלבנה, הרי גהצתיה היום, - אומרת אני ומגישה לו את
        החליפה היפה, בכדי שיופיע הדור בתלבשתו לפני אהובתו, ומרגישה באותו רגע את
        עצמי כשושבינה, המלוה את החתן בברכה אל תחת חופתו.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c2">כ"ג אלול.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא בא!... בא אותו הדבר, שמטרידני מן הבית הזה ומביא שנוי-ערכין גמור
        בחיי! אני הולכת מכאן ואי-אפשר לי להתעכב עוד אף יום אחד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אירע הדבר. אני שכבתי על הספסל בגן, ובהסתכלי בכוכבים ובענני-הסתו
        הקלילים, הפזורים בכפה, חשבתי על זה, שחכמה עשתה ה"זעירה" בהמליטה עתה,
        בחודש אלול, בזמן שפסק החלב מהמושבה. עמרם הרי זקוק לחזוק-עצבים; אשקהו
        לרויה מהלבן הלבן של "זעירה" החכמה. גם חמאה וגבינה אכין לו כרצונו והוא
        ייף יותר, יבריא יותר!... מתוך אלה ההרהורים שמעתי קול אחי, המתקרב אל
        הבית, ולו עונה קול-אשה בלתי-ידוע לי. הם נגשו עד השער, עמדו, ואזני קלטה
        בלי-משים את קטעי שיחתם החשאית ותפשה את התוכן...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא טענה, כי אי-אפשר לה להכנס בתור גברת לבית, שאחות גדולה מושלת בו...
        אינה מרגישה בה את האפשרות להכנע, ולהכניע אחרים אין לה הרשות!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחי שדל אותה בדברים: הוא ימצא דרך, כיצד להרחיק את אחותו מן המשק. היא
        בעצמה הביעה את רצונה לוַתר על שלה... קצר כח-הסבל מכבר! הוא אינו יכול
        לשבת אתה יחד, ולא פעם אחת כבר עלה על דעתו לעזוב משום-זה את הארץ ולנוד
        למרחקים!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שררה רגע דומיה ביניהם, ואחר כך התחיל עמרם להתלהב: את שלשלאות-הברזל, אשר
        שמה עליו אחותו, ינתק, בחוזק-יד ינתק, כי חפץ חיים הוא – חיים,
        חיים!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הזרם החשמלי, שעבר בעורקי, עשה את פעולתו; עתה נשכתי את שפתי ולחצתי ידי
        אל חזי להשתיק את דפיקות-לבי החזקות. כמה זמן ארך המצב הזה, אינני יודעת.
        קמתי כשכורה ובקושי עליתי לחדרי. כאן הרגשתי בלבי לחיצה פתאומית ונשימתי
        כבדה עלי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכאב ורעדה צעדתי אט אל רף-הרפואות לקחת לי טפות ללב. בדרך נתקל מבטי בראי
        ונרתעתי מפחד לאחור, כי פני דומים היו לפני אדם ההולך למות. כשעבר הפחד,
        התעוררה בי הסקרנות לראות היטב את דמות המות והקרבתי פני אל הראי, אבל
        להסתכל בו לא יכולתי מאפס נשימה. מהרתי לפתוח את החלון לרוחה ונפלתי על
        המשכב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי קחתי פעמים את הטפות התעודדתי מעט והיתה לי כבר היכולת לָדין על המצב
        ולהחליט דבר. קמתי לערוך מכתב-פרידה לאחי. ביד אחת אחזתי תחבושת קרה על הלב
        ובשניה כתבתי, עורכת שורה ופוסקת מחולשה. בינתים עוברת במוחי כל שלשלת
        החיים עם עמרם מיום מות ההורים עד היום הזה. הובלט ביחוד מצבו בזמן מחלת
        טיפוס קשה, שעברה עליו, וכשאמרו הרופאים נואש לחייו, החלטתי למות, אם יאבד
        לי הנער.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עתה אני הולכת מעמו, ויש לי ביד מי לעזבהו! אבל אני, הבודדה, מה יהיה לי?
        ומדוע כה מפחידה אותי מלה זו: בודדה? האם משום זה, שעתה מצטרפת אליה גם מלה
        אחרת: עזובה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עזובה אהיה מהכל: מהמשק החי, הצומח, הפורה, ומכל מה שגִדלתי וטפחתי במשך
        עשרות שנים והייתי קשורה עמו קשר טבעי וחזק...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">הולכת אני, הולכת בלא-כלום – ולאן?...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חפצי ארוזים; הנה האיר הבוקר, הבה אמהר לצאת בטרם יקום אחי, - מוטב מחזה
        אחד פחות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שלום לכם לנצח, קירות ביתי, כלי, רהיטי! שלום לכם, עצי, פרחי, צפרי! אתם
        מלוים אותי לדרכי בברכה וברנה? – תודה לכם, חביבַי, תודה רבה!  </span
      >
    </p>
  </body>
</html>`;

export default page;
