const page = `<body class="c9 doc-content">
  <p class="c3 italic" dir="rtl">
    <span class="c5"
      >את שלשת המכתבים המובאים כאן כתב יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי לאשתו
      נחמה בזמן ששהה בבית החולים בצפת לצורך ניתוח.</span
    >
  </p>
  <br />

  <a href="#5.3.1923">
    <p class="c3" dir="rtl">
      <span class="c4">יז&#39; אדר תרפ"ג  </span
      ><span class="c5 italic">[5.3.1923]</span>
    </p>
  </a>
  <a href="#14.3.1923">
    <p class="c3" dir="rtl">
      <span class="c4">כו&#39; אדר  </span
      ><span class="c5 italic">[14.3.1923]</span>
    </p>
  </a>
  <a href="#15.3.1923">
    <p class="c3" dir="rtl">
      <span class="c4">כ"ז אדר</span><span>  </span
      ><span class="c5 italic">[15.3.1923]</span>
    </p>
  </a>
  <br />
  <br />

  <p class="c3" dir="rtl" id="5.3.1923">
    <span>צפת עיר הקדושה יום ג&#39; יז&#39; אדר תרפ"ג  </span
    ><span class="c5 italic">[5.3.1923]</span>
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1">נחמה יקרתי.</span>
  </p>
  <p class="c3" dir="rtl">
    <span
      >מה אומר ומה אדבר אני מודה לאל על ההזדמנות הזאת שהביא אותי לידי נסיעה זו.
      מה מאושר אנוכי בזו הרגע מלא וגדוש עם מחזות הטבע אין לך כל מושג על דבר הדרך
      הזאת שעברתי ולרוב אושרי הזדמן לי בצאתינו מירושלים עוד נוסע ערבי פלח שיודע
      כל כפר וכפר בשמו ואני לא נתתי לו מנוחה כל הדרך והעשרני עושר רוחני. בכלל
      היאֶשא היה כל כך נח בלי שום עכוב לרגע. באנו לטבריה בשעה אחת וחצי אחרי
      שעמדנו זמן רב במאה שערים. בטבריה תכף מצאתי אטא לצפת דרך מצוינת באתי לראש
      פינה לפני השקיעה עמדנו איזה רגעים. בדרך פגשתי באטא אחר את אחיו של פרידמן
      שהיה פעם אצלינו הוא תכף פרסם בראש פנה על דבר בואי ועפו מכל הצדדים שלום
      עליכום ובן של בן אריה רץ והביא לי את אבא. הספקתי לראות אותו רגע והאטא ברח.
      עם השקיעה באתי לצפת. התחנה של האטא על יד הדלת של משפחת הד"ר שפירה. על דבר
      קבלת הפנים תוכלי לשער בנפשך. הד"ר בחזקת היד השאיר בשבילי מטה ולוא אחרתי
      יום אחד כי אז היה עלי לחכות הרבה זמן עד שיפנה מקום. כעת אני בא מבית
      החולים. כבר שלמתי ששה פונט שזה גרוש בעד הכול כעת נתנה לי חופשה לטיל בצפת
      מעט עד שעה שתים. בשתים עלי להכנס כבר לבית האסורים בית החולים נהדר. כנראה
      שלא אתחרט. הספקתי עוד בלילה לעשות סיור אצל גולני, את עידה ויהודה אמירתם </span
    ><span class="c6">[אמיתי?]</span
    ><span
      > ראיתי הבוקר ומסרתי שעוד היום יבוא משה חברי לראות אותי. בבית אונגר היתי
      ומסרתי שלום מצבי ונחמה. כעת נשוב אל רשמי הדרך. בעברי את העמק הראו לי הרבה
      נגיעות של ******** ********** היינו את נוריס, מרחביה, בלפוריה, תל-עדשים,
      סג&#39;רה, מצפה ומרחוק גם יבניאל. קרוב מאד לא הייתי בשום מושב ומרחוק קצת
      זה עושה רושם כל כך עגום כל מושב ערום אין עץ אין ירק כמו תחנות של אנשי צבא
      **** להשות את ראשון כאשר רואים אותה מרחוק אם </span
    ><span class="c6">[=עם]</span
    ><span class="c1"
      > המושבות הגליליות זה פשוט מעורר חמלה או… אני עוד לא חותך מסקנות על דבר
      המושבים אך לפי המבט החצון ממעוף האטא אפשר עם (=אם) אהיה פנימה אשנה את
      דעתי. עכשו על דבר הגיוטירה שראיתי אצל שכנינו עברנו הרבה כרמי זתים עתיקות
      גם צעירים. מותר להם לבני דוד שלנו לבוא לקבל ממני שעורים לגדל זיתים. עומדים
      עוד בתקופה לא רחוק מאדם הראשון. שמעתי הרבה שדברו על דבר שכם איזה מקום
      מבורך כלו משקה ובאמת מכל שעל אדמה יוצאים מעינות וטחנות של מים לעשרות פגשנו
      על מי המעינות, ובכל זאת עזובה בכל. אין להם שום דבר טוב הכל מחכה לימות
      המשיח שיבואו בעלי הארץ האמתים ויוציאו יקר מגילו. לדוגמא רואים במגדל מה
      שעשו אחינו שמה איזה גן עדן נסעתי באטא מטבריה יחד עם ערבים הם הראו לי באצבע
      על השנוי בין אדמה מושלמית ועברית והם מלאים התפעלות איך הפכו היהודים אדמת
      בצה מכוסה בוץ "סידרי" שאף פראי חיות לא מצאו שם מקלט וכעת כרמי שקדים זיתים
      כל מיני ירק ותבואה מרהבת עין ובמרחק איזה חצי שעה משם ישבו אטלקים איזה מושב
      אדמה נפלאה ובניותם היכלים. וכמה שמה *** שעריהם המקום עזוב אין נפש חיה בכל
      החוה. ובדוום מוציאים את החלונות והדלתות להסקה. הפסוק הזה אומר דרשיני. היה
      בדעתי לכתוב מכתב מיוחד לאפרת וצבי אך אני מתעצל כעת תמסרי להם תכף את רשומי.
      שלום לכולכם ממשפחת הד"ר.</span
    >
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1">שלכם מיכל.</span>
  </p>
  <br />
  <br />

  <p class="c2" id="14.3.1923">
    <span class="c8">בה"ח הדסה צפת יום רבעי כו&#39; אדר  </span
    ><span class="c6">[14.3.1923]</span>
  </p>
  <br />
  <p class="c3" dir="rtl">
    <span class="c1">יקרתי חביבתי נחמתי</span>
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >איש בשורה הנני היום. תמול לא נתנו לי לקחת עט ביד מפני שהוציאו לי את
      התפרים. כעת בקרני הדר שפירא ואמר לי שמחר נותן לי כבר חופשה לרדת מהמטה, אשר
      הייתי אסור אליה מיום השלשי העבר בתור אבטונומיה מיוחדה בכל כדור הארץ. דבר
      כזה אשר רק לבנות חוה ידוע הטעם הנחמד הזה. בשביל העני הזה שנפל בגורלכן לבד
      אתן צריכות לדרוש יותר *** זכויות. סלחי לי אולי חטאתי לנגדך בפרקים כאלה, אך
      האפשר לקבל מושג מהענויים טרם שמתנסים? לא ולא. מצבי מצוין אני כבר אוכל הכל
      חוץ מלחם. אני מתפלא איך אפשר המצב כזה לדרוש אוכל. אך הטבע עושה את שלה. מה
      שנוגע לשנה עשתי שמיטה, אבל אין דבר פני טובים ומקבל מחמאות אבל האחיות בעד
      התנהגותי עמהן שאיני מכביר עליהן כיתר החולים.</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >יש טפוסים פה שאני מתפלץ לראות את התנהגתם – עם הבריות העדינות והחביבות
      והמסורות – בכל כך גסות למטה מכל ביקורת. דברתי עכשיו עם הד"ר ש בנוגע
      לסיורים במשבות הגליל אחרי שאצא חפש. ואמר שבשום אופן לא ירשה אך יש על האטא
      הביתה. ומה לעשות כך גרם שאראה את ארצנו מרחוק כמו משה רבינו בשעתו. אני
      מצאתי לי עבודה גם בשכבי במטה פרקדן: הווה מורה לאחיות הדסה, טרמינים העברית
      אשר לדאבוני לקויים פה מאד, אף כי הדבור העברי שולט פה בכל תקפו. עד היכן
      הגיע עד כי הערבי המשרת מדבר רק עברית..</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >האם לא ימות המשיח? גם בשכיבה פרקדן רואה לפני את הר כנען בכל יפיו ובכל רגע
      טסים עליט איטאים זה עולה וזה יורד. וכמה מזכירני המקום הזה את עברי בהיותי
      בן עשרים ושלוש, ילד חלומות תנכ"יות ואז בכל שבת טפסתי על ההרים האלו לבקש את
      העבר הרחוק אשר על כל שעל ומצא פה עוד שריד ההסטריה שלנו, ומה נעים לי כעת
      לעשות החזרה. טוב מראה עינים עם הלך נפש מעז יצא לי מתוק. יש כאלה שתמיד
      מתחרטים על ימי הנוער אם הם שבים למקום שבלו ימי הילדות. אז הגעגועים אוכלים
      אותם בזכרם והשותם עם הזקנה. ואני לא כן עמדי. נעים לי מאד בהיותי בן ששים
      לעלעל את ספר זכרונותי. אין לי שום חרטה. שמח אני בחלקי וגורלי שיפרה עלי.
      אין כלל מה להתלונן. חלמתי וגם לחמתי עד היום. מלאתי תפקיד בחיים ומקוה ללכת
      הלאה בדרכי זה. אני מסכם ברגע זו את כל ההתקדמות שבאו במשך הארבעים השנה
      שחלפו ויוצא סך הכל לא רע. מובן שחי יום יום מלאים פגעים ויסורים, אך אל לנו
      לשכוח כי בשדה מערכה אנחנו נמצאים כל הזמן. אני מתאר לי עד כמה את תצחקי
      לפטפוטי זה, אך מה עלי לעשות? אין לי עבודה אחרת יותר נעימה ואתך
      הסליחה.</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >שלום שלום לכלכם לאפרת שלחתי רק מכתב אחד בודאי תתחלקי עמדה לפי שעה.</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1">שלך מיכל.</span>
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >דרישת שלום חמה ולבבית לידידתי היקרה נחמה. כאשר יבוא בעלך הנכבד, כעת חללי
      המקשיב, הביתה, הוא יספר לך מחיינו פה בגליל הנחמד.</span
    >
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1">ידידך ד"ר אריה שפירא.</span>
  </p>
  <p class="c3" dir="rtl"><span class="c1">14/III/23</span></p>
  <br />
  <br />

  <p class="c3" dir="rtl" id="15.3.1923">
    <span>צפת בית&#39;הח הדסה יום ה&#39; כ"ז אדר  </span
    ><span class="c6 italic">[15.3.1923]</span>
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1">יקרתי נחמתי</span>
  </p>
  <p class="c0" dir="rtl"><span class="c1"></span></p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >הרגע הזה הוא רגע הסטורי. בחיי בזה הרגע ירדתי הפעם הראשונה מהמטה. זאת
      אומרת ברשיון קבלתי מהם לפני איזה שעות. אך ההתכוננות לזה מצדי ארך איזה שעות
      כי פשוט לא הרגשתי אומץ בי אך סוף סוף החלטתי, עברתי את החדר בעזרת אחות
      נעימה. הד&#39;ר שמח מאד מעבודתו ונוסף לזה יש לי מזל נגד אחרים. הפעם אני
      יושב על כסא אצל שולחן ולא פרגדן. התביני מה זה?
    </span>
  </p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >לפני הצהרים קבלתי את מכתבך הקצר מכ"ד דנ"א. נעים מאד היה לי לשמוע את סדור
      העבודה שנמשך באנרציה. היום היה שאר רוח עלי כתבתי מכתב מלא רגש לה"ג סולד.
      אני בטוח שהמכתב ישאר אצלה באלבום לזכרון. יצא מוצלח מאד. בלי פטפוט אבל
      חריף. אני התאננתי על אשר אמיתי שלא בא לבקרני עד היום. והנה בקרני עובד בנו
      היום ואמר שביום ראשון זה נסע ישר ירושלימה בדבר ענין נחוץ. חבל. אפשר מאד
      שלא נפגש בודאי יהיה בראשון בזמן שהותי פה.</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >משפחת הד"ר שפירא ממלאים את תפקישם על צד היותר חביב כלם מביטים עליהם כעל
      קרובי ומקנאים בי. ספרתי לך על דבר נתוח מסוכן שעשה ביום ראשון זה ובעצמו אמר
      לי, שנתוח כזה לחיים ולמות אף בראותנו את החיה אחרי הנתוח ספרנו את השעות מתי
      יסגור את עיניו לנצח. אך פלא. היום כבר יום החמשי ולהחולה הולך וטוב. אבל
      איזה מסירות איזה נפש עדינה! כמה פעמים באמצע הלילה התעוררתי והנה הרופא גחן
      עלי להרגיש איך אני נח. וכך לכולם בלי יוצא מהכלל. איזה אושר לבית חולים זה
      שרכש מרגלית כזו. במחלקת הגברים יש מקום בצמצום ל 16 מטות והאם תחשבי שיענה
      ח"ו לחולים אין מקום! לא, זה לא בטבעו כלל. עומדים כבר מטות בפרוזדור ועוד
      ידו נטויה. עפים כעת צעירים מכל הגליל להפטר מצרותיהם העתיקות. הוא מקבל
      פרסום גדול בתור רופא ובתור איש. הלילה הביאו שוטר ערבי שבלי צדיה ירה חברו
      בו. באחת שמעתי את קולו של ד"ר ש&#39; נפחדתי ואמרי לי את הסבה. בחצי שלוש
      הלך לישון. השתיק מעט את החולה והלך לו. בחמש ממש בא כבר לחדרנו מוכן ומזומן
      לנתוח של הערבי. הישנו עוד איש כזה? ואם כל זה אין רגע שיהיה מורגז ולכל
      השואלים הטרחנים עונה באיזה נעימות עד שהם בעצמם עומדים ומתפלאים על טוב לבו.
      ואם נמצאים איזה חולים שמחלתם ממושכה והם עוד ירושה מד"ר גרי וכמובן לבם רע
      עליהם תמצאו את הד"ר אצל מטתיהם, לוחש להם על אזניהם ספורים ומשלים כאב רחמן
      עם בנו יחידו.</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1"
      >וזה מפליא אותי עוד יותר מכל כי מכל הפקידים והאחיות נודף אילו רק חבה
      ורעות. לא כמו שנהוג ביקב שלנו. וככה מצאתי בהתנהגותו בביתו איזה פנה שקטה
      ושלוה איזה רוך איזה יחוס של אהבה עדינה! כעת אני מבין איך השתמר בבביתם
      שלשים ושלש שנה אהבה בלתי מגודרת. צדקת נחמה. אני לא פגשתי כמוהם בכל חיי.
      היום היה יום הביקורים. בקרנו מר גולני עובד אמתי וגם כל משפחת שפירא. הוא לא
      חפץ לעשות בשום אופן שום יוצא מהכלל לבני ביתו. לבקרני חוץ מהזמן הרשמי, ולא
      מועיל כל הפצרותיהם. מאין לאיש בלי מדה עקביות כזו? נשאר לי פה סימן שאלה
      בלתי מפותר. חפצתי מאד לדעת משלום כל המשפחה מה שנוגע לבריאות כי פה פרצה
      מחלת השפעת באפן אפידמי אך בלי תוצאות רעות. כן, קבלתי פריסת שלום מגלדה יאפו
      לא ידעתי כלל שהיא נמצאת שוב פעם בטבריה. לוא ידעתי עמדתי בטבריה די זמן
      שיכולתי לבקרה. ועוד מכרים רבים שמה. גולדברג קרובו של מר גלוסבג גם כן מסר
      שמתעתד לבקרנו, בלל מתענינים בבואי. זה רקלמה נפלאה בשביל הד"ר. את מכתבך של
      היום מסרתי להד"ר לקרוא בכדי לדעת איך מביט אל שאלת הג&#39; מוסנזון. מסרי
      שלום לכולם ובפרט לאמא אמרי לה בקרוב אעשה לה קידיש.</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c1">שלך מיכל</span>
  </p>
</body>`;

export default page;
