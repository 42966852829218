const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <!-- <h3 class="c8" dir="rtl">
      <span class="c6 c9">על נוהג איסור ביאה עד לפיוס</span>
    </h3> -->
    <p class="c8 italic space-bottom" dir="rtl" id="h.gjdgxs">
      <span class="c2">מתוך מכתב מ</span
      ><span class="c1">רות ארבל</span
      ><span class="c2"> לפרופסור יפה ברלוביץ</span
      >
    </p>
    <p>
      <span class="c5"
        >...טלפנתי שוב לחיים יעיש וביקשתי לבקרו. הוא אמנם היה קצת חולה, אך אמר
        שיקבלני ברצון. מצאתי את חיים שרוע על הספה, ושוב שאלתיו על אותו נוהג.
        ואז, כשראשו המורם שעון על יד אחת, הצמיד כמה אצבעות של היד השניה יחד,
        ושֹמן בהמשך לפיו – כעין מקור – ואמר "התרנגול רואה גרגר חטה, הוא מנקר"
        (כשהוא מלוה את המילים בתנועת נקירה בסדין) "ושם אותה בצד. ואז הוא הולך
        הולך הולך (והאצבעות רצות על הסדין כרגלי תרנגול) "ושוב מוצא גרגר, מנקר
        ושם בערימה. וכשאסף מספיק, מגיש לתרנגולת, מפיס אותה ובועלה". שאלתי את
        חיים איפה זה כתוב – אמר לי "במשנה". שאלתיו אם ידע על קיום המנהג, אמר לי
        שבתימן היה נפוץ, בעיקר בין המבוגרים יותר. הוא יודע שהיו שהמשיכו בו שנים
        רבות גם בארץ. שאלתי אם גם אשה יכלה לגשר, והוא אמר "העקר אדם מכובד
        ומקובל". וכאן אולי הוכחה נוספת למעמד המיוחד, להערכה ולכבוד שהיו לסבתא
        בעדה התימנית. כשחזרתי הביתה טלפנתי לידידי דוד, תלמיד חכם, שאליו אני פונה
        כשיש לי שאלה הקשורה למקורות. מיד השיב לי שסיפור התרנגול המפייס נמצא
        בתלמוד, </span
      ><span class="c2"
        >מסכת עירובין פרק י דף ק,ב גמרא: מאי דכתיב (איוב לה) מלפנו </span
      ><span class="c2 c7 italic">[מאלפנו דעת]</span><span class="c10"> </span
      ><span class="c2"
        >מבהמות ארץ ומעוף השמים יחכמנו מלפנו מבהמות זו פרידה שכורעת ומשתנת מים
        ומעוף השמים יחכמנו זה תרנגול שמפייס ואחר כך בועל אמר רבי יוחנן אילמלא לא
        ניתנה תורה היינו למידין צניעות מחתול וגזל מנמלה ועריות מיונה דרך ארץ
        מתרנגול שמפייס ואחר כך בועל ומאי מפייס לה אמר רב יהודה אמר רב הכי קאמר
        לה זביננא ליך זיגא דמטו ליך עד כרעיך לבתר הכי אמר לה לישמטתיה לכרבלתיה
        דההוא תרנגולא אי אית ליה ולא זביננא ליך</span
      ><span class="c2 c7 italic"
        > [בתרגום חפשי: אמר רב יהודה אמר רב כך אומר לה: אקנה לך גלימה שתגיע לך
        עד רגליך. לאחר מעשה אומר לה, תישמט כרבולתו של אותו תרנגול אם יש לי
        ואינני קונה לך]</span
      ><span class="c2">.</span>
    </p>
  </body>
</html>`;

export default page;
