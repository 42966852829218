const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c9 doc-content">
    <p class="c4" dir="rtl">
      <span class="c6 tooltip"
        >על המשפט והגישור<span class="tooltiptext">
          <span>ראו גם מאמר של פרופ&#39; יפה ברלוביץ, </span
          ><span class="c10 bold"
            >בית משפט של אישה אחת: סיפורה של נחמה פוחצ&#39;בסקי כהיסטוריה מקומית
            של נשים (ראשון לציון 1934-1889), חוקה אחת ומשפט אחד לאיש ולאישה,
            נשים, זכויות ומשפט בתקופת המנדט,</span
          ><span
            > עמ&#39; 371-325, עורכים: אייל כתבן, מרגלית שילה, רות הלפרין קדרי,
            אוניברסיטת בר אילן תשע"א (2010)</span
          >
        </span></span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c8 c3 small-text">רות ארבל</span>
    </p>
    <p class="c4" dir="rtl">
      <span
        >משך שנתיים, בדרכי חזרה מבית הספר נהגתי להכנס לביתה. תמיד מצאתיה עסוקה
        בבית או בחצר. והיא כבדה אותי בקוקר מתובל – כיום היינו קוראים לזה גרנולה
        - וישבה אתי כמה רגעים. פעם לא מצאתיה בחצר או במטבח והמשכתי הלאה, לחפש.
        בקצה הבית במרפסת הסגורה ששמשה חדר מגורים ישב הרב סייבי, זקוף, אצילי
        ושותק. ישב זוג שהרים קול זה על זה וישבה סבתא ונסתה להרגיע אותם. אני
        חמקתי חרש כלעומת שבאתי.</span
      >
    </p>
    <p class="c0">
      <span>יונדב</span><span class="c3"> </span
      ><span class="c3 c16 italic"
        >[אחיינו של מיכְל פוחצ&#39;בסקי, גר בזמן מלחמת העולם הראשונה בבית נחמה
        ומיכְל ולמד בבית הספר בראשון לציון]</span
      ><span class="c11"> </span
      ><span class="c2"
        >סיפר לי  שכל תימניה שרבה עם בעלה או חמותה באה להשיח את לבה לפני נחמה:
      </span>
    </p>
    <p class="c0">
      <span
        >יום השישי היה "יום המשפט". נוהג מקובל היה בין התימנים שבעל שרב עם אשתו,
        הרים עליה יד או קול,
      </span>
      <a href="/testimonies/onThePracticeOfBanningBiya">
        <span class="c7 tooltip"
          >אשתו אסורה עליו עד שיפיסה<span class="c1 c14 tooltiptext"
            >ראו  עוד על נוהג איסור ביאה עד לפיוס
          </span></span
        >
      </a>

      <span class="c2">, </span>
      <span class="c2"
        > ואדם מכובד, מקובל על שניהם, ישכין ביניהם שלום. מריבות וקטטות לא חסרו
        בעדה. ובכן כל יום שישי התאספו הזוגות שרבו במשך השבוע בגן לפני ביתה של
        נחמה. בסביבות השעה שתים היתה נחמה יוצאת למרפסת קטנה ליד הדלת ויושבת על
        שרפרף קש. מהמרפסת ירד גרם מדרגות לגינה. התימנים היו נגשים זוגות זוגות.
        אשה יושבת מזה והבעל מזה. נחמה פונה לבעל: "זכריה, ספר מה קרה". וזכריה
        שוטח את טענותיו: "חזרתי עייף, האוכל לא מוכן, הבית לא מסודר..." "טוב,
        מספיק" מפסיקתו נחמה. "סָעֲדָה, ספרי את". "הילד היה חולה, אזל הנפט..."
        "טוב, הבינותי". נחמה פונה לאחד ומוכיחה אותו, פונה לשני ומדריכה אותו.
        "עכשו לחצו ידים וחזרו הביתה בשלום". והאשה מותרת לבעלה, והזוג הבא מתישב
        על המדריגות.
      </span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c3 c8 small-text">אפרת בן כהן</span>
    </p>
    <p class="c4" dir="rtl">
      <span
        >אנשים באו לבית לשפך את לבם בבעיות משפחה. לאט לאט הפך הענין לקבע בבית,
        ומאוחר יותר ארגנה אמא משפט שלום ליד ועד המושבה לדון בסכסוכים בעניני
        משפחה ובין שכנים – לא בעניני כספים –
      </span>
      <a href="/fromHereAndThere#hashalomCourtProtocols">
        <span class="c12 tooltip"
          >בין "השופטים" היו מספר גברים ואמא.
          <span class="c10 tooltiptext"
            >ראו רישום לדוגמה מישיבה של בית משפט השלום בראשון לציון בו ישבה נחמה
            פוחצ&#39;בסקי כשופטת</span
          ></span
        >
      </a>
    </p>
    <p class="c4 c5" dir="rtl"><span class="c8 c11"></span></p>
  </body>
</html>`;

export default page;
