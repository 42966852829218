const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c3" dir="rtl">
      <span class="c1"
        > מתוך שנתו הרגיש העלם התימני כיצד אותו השרץ זוחל מעל ראשו על גבי פאתו
        הארכה. הוא תפשהו בכעס והטילהו לעבר הקיר השני, וכשישב בעינים סגורות על
        משכב השק הבלה, התגרד בפאתו בבחילה, כאלו עודנו מרגיש בה את עקבות השרץ
        הנתעב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >...חלדים, חלדים ממש הם העכברים בכאן! – מקפצים על ראשך ומכרכרים כשדים
        ואינם נותנים לנוח אחרי עמל הרב של יום תמים. – הוי ג&#39;לות, ג&#39;לות!
        – מתרעם מוסה בלבו, ומשפשף בידיו הגסות את עיניו, שנפתחות בקושי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בפנה על רף שחור מעשנת עששית קטנטנה, שהוא שכח לכבותה אמש. האור הקלוש
        נופל על גופו העטוף מחציו ומטה במטלית כהה, מזוהמה; מבין קרעי מעילו
        מבצבצים מערומי גוו השחור. טליתו שהתכסה בה צנחה ומונחת לרגליו על הקרקע
        הטחוב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לצדו נמשכת מחיצת קרשים נמוכה. מעבר למחיצה נשמע קשקוש שִני סוס בלעסו את
        השעורים הבלולות בתבן. בועט שם הסוס ברגלו מרב שובע, ומוסה מקנא בשכנו: –
        הלה מוצא הכל מן המוכן אחרי עבודת היום, והוא, בר נש העלוב, מי דואג לו, מי
        משגיח עליו? – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מוסה חש כאב חזק בלבו, כאלו עלוקות נאספו שם ומוצצות את דמו. דומה שאיזה
        אסון אירע לו, אלא שהדבר לא ברי לו; סובב ראשו סובב והוא צונח על שקו, רוצה
        להרדם ואינו יכול. העלוקות שבלבו נוקרות ועוקצות בלי רחם. מוסה מקמט את
        מצחו ומתאמץ לזכר – מה היה אמש? דומה שישן מתוך בכיה. כן, זהו זוכר! אבל
        למה הוא בכה, למה? –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ופתאם הוא נרתע ממקומו, כאלו עקצו נחש. כל האסון שאירע לו קם נגד עיניו: –
        הרי שם במושבה היפה ההיא עם הפלטרין הגדולים על ההר היו אמש הקדושין של
        הולדה, של אפיה!... –  –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הוי ולדה, יא ולדה! – מתפרצת גניחתו של מוסה – האפשר הדבר שאַת מקֻדשת
        לאחר ולא למוסה הלוי, בן דודך, אשר עשר שנים רצופות נשא אותך בלבו, ואשר
        חזה את כל עולמו רק בך, רק בך אוהבה?!  –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עם יסוריו הגדולים שבלב התחיל כאן מוסה מהרהר באותם החיים המאשרים שעברו
        עליו בקרבת אפיה. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרי קטנה, קטנטנה היתה הילדה בבואו לדודו טביב לחדן-שם! גם הוא לא גדול
        היה כשברח יתום ובדד לחדן מהג&#39;לג&#39;ולים, אלה המריבות התדיריות של
        אחיו ובני משפחתו, לא התחיל עוד אז להניח תפילין. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא התקשר תכף לבת דודו. נדמה לו שמצא בחדן אחות קטנה וטובה, והוא בחבה
        התחיל לטפל בה בכל זמן פנוי מעבודה. הדודה היתה חולנית ושמחה שיש לה עזרה
        בו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומבצבצת לפני מוסה תמונת אפיה, כשהיא גדלה ותהי לנערה זקופה ויפיפיה מאין
        כמותה בכל הסביבה... זוכר הוא שכל מעשיו התחילו אז להיות מכֻוונים למטרה
        אחת: – למצא חן בעיני הילדה!... בלמודו, בעבודתו ובפרקמטיא שלו – בכל היתה
        קשורה היא, רק היא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יוצא הוא, למשל, אל השוק בערב שבת. מוכר סחורתו ומיד חושב ע"ד השבת של
        הדודה; ולמען עשות נחת רוח לאפיה, הוא קונה תרנגלות או ביצים או בשר כבש
        ומביא לדודה לתקן להם שבת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לילדה הוא מביא במטלית צבעונית גרעינים וצמוקים, מניח בלי דבורים את מתנתו
        לפניה, והיא מעיפה עליו מבט של חן ושל רצון, אשר ממלאהו חדוה אין קץ ומכניס
        בו כעין נשמה יתרה, שלא מסתלקת ממנו גם בבית המרחץ גם בבית הכנסת להבדיל.
        וכשהוא שב עם הדוד מבית הכנסת אחרי קבלת שבת מלכתא, נדמה לו שכל העולם רוקד
        לפניו מתוך אותו המבט הנפלא של הילדה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפעמים מזדמן לביתם עובר אורח והוא הראשון שמפנה לו בכבוד את משכבו ומכסהו
        בטלית המשי שלו. הם מתחרים בדברי תורה בשעות הפנאי, וביום שמתכונן הארח
        ללכת, הוא מעניקהו לעיני הילדה חצי רִלְיָה,  ומלוהו בברכה והולך
        לעבודתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלילה כשהוא שב מהחשל עיף, היה מוצא לפניו תדיר ארחת ערב מתקנה, ועד שהוא
        גומר את ברכת המזון ופרק בהלכות שחיטה, הילדה כבר ישנה. הוא שומע את נחרתה
        הקלה מעבר למחיצת מחצלות. "תערב ותמתק שנת הקטנה!" מברך הוא אותה בלבו וגם
        הוא שוכב ומתנמנם עד אשמורה הראשונה, שאז היה הדוד מעיר אותו ללכת לבית
        הכנסת לתקון חצות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הדוד טביב הרי כאב ממש היה לו בימים ההם, וכשהוא, מוסה, היה מרמז לפניו על
        אפיה, היה הדוד לוחש:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– אך כסף, די כסף תצבר, ולדי!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האמנם אך שקר, שקר היה כל זה? – ומכאן ואילך ימשכו חיים חשכים בלי תקוה,
        בלי אור עיני הילדה?.. הוי – מה היה לו, מי עולל לו ככה? –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ונושך מוסה את שקו הנאלח ולוחץ ראשו באבני הקיר המפחמות.</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתוך יסוריו מבקש מוסה את סבת אסונו, ומוצא שרק ארץ ישראל אשמה, רק היא!..
        אלמלי באו לכאן היה רוכש את אפיה בתימן בבוא לה עת דודים, באותו הסכום
        שנצטבר אצלו ושהתבזבז להוצאות הדרך! הרי שם משלמים שני נפוליונים בעד ילדה
        הכי טובה וחסל! וכאן – איזה אסון! – עד עשרה נפוליונים כבר מגיע הדבר, ואין
        להשיג בשום אופן בת טובים בסכום פחות מזה!.. ועוד נוספות עליך הוצאות
        החתונה המרובות עם בגדים ושמלות לכלה, מכסות וכרים וכלים, – מי עשיר יכול
        לכלכל כל אלה? –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לצדוק בין יחיא עלתה חתונתו ברחבות עד חמשה עשר נפוליונים! חמשה עשר
        נפוליונים אשה אחת! – הוי ג&#39;לות ג&#39;לות!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומהרהר מוסה אילך בעוד יסורי גלות שבכאן: – הרי חדש אלול הגיע, ממשמשים
        החגים והוא ימק במארה אפלה זו בלי בשר, בלי מרק, בלי תרנגלת! הכל יקר,
        הכל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי!– נאנח מוסה – היכן הן הכבשות השמנות שבתימן?.. הרי חמשה עשר כבשים
        ושבע פרות ושבעה שורים היתה העדה העשירה שוחטת לכל חג ומועד ומזמינה אליה
        את  העניים מן הסביבות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כן! – בתימן אפילו העני שבעניים נהנה ביום חג מכל טוב, וכאן ממה? – מפתקא
        של פחות משוה פרוטה שנותנים לעני! רק המיחסים שבעניים מקבלים מתליק! הוי
        חרפה: עשירים כאלה – ופתקא נדבה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואנו, העובדים, כלום מצבנו יותר טוב כאן מהחוזרים על הפתחים? לך עבוד להם
        עבודת פרך בשני בשליקים ליום!.. ואם יקרה לך, שקשה עליך העבודה, ואתה רוצה
        לנוח קצת, מיד קולטות אזניך כנוים יפים: סוס, חמור, פרא אדם! – מה אכפת להם
        לאדונים הללו? – הריהם יושבים כבני מלכים בפלטיניהם ומתענגים על כל טוב:
        בשר וביצים, חלב ויין! ישמן ישראל ויבעט!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואלמלי ידע קודם שהוא, מוסה הלוי, יהא עבד נמכר לאחיו – לא היה זז מתימן!
        ויחזרו לו החכמים אלף פעמים שארץ זו קדושה וטהורה וכל המת בה פטור הוא
        משבעה מדורי גהינום, הוא לא יחדל מהתחרט על זה שהלך מתימן! כי מה לו גהינום
        מה לו גן-עדן, אם הוא עבד נמכר לאחיו ואין לו כלום משלו, אין לו כלום כלום,
        כלום!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומוסה מקלל את הגוי המושלמי שחזר מירושלים לתימן ובספוריו הנלהבים העיר את
        העדה הישראלית לזוז ממקומה. על דבריו גרידא סמכה זו ונוע התנועעה... עוד
        זוכר הוא היטב את דברי אותו המושלמי: –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"ארץ טובה, יפה יש לכם סמוך לים רחב וגדול! ההרים והבקעות שבה נוטפים יין
        ושמן טוב, הנשלחים לכל מדינות הים... יושבים שם אחיכם בבתיהם-פלטוניהם
        בטוחים מכל אימת המלכות. וכשיוצאים אל שדותיהם וכרמיהם, רוכבים הם על סוסים
        אבירים, כשפניהם מפיקים עוז ובטחה. – הרי כל טוב אדמתם משתטח לפניהם: החטה
        והשעורה, הגפן והזית, הרמון והתאנה. – ומדוע אתם יושבים בארץ נכריה ואינכם
        נספחים אל אחיכם, האומרים לבנות את הארץ, אשר אללה הבטיח לתת
        לזרעיכם?"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזוכר מוסה את התקוות הטובות, ששעשעו אותם בלכתם לכאן: – בארץ ברך השם הם
        יראו חיים טובים! ישבו כל הימים על התורה ועל עבודת הבורא; ישתתפו ברבוי
        תפלותיהם בבנין המקדש העתיד לקום מהריסותיו, ואחיהם שהתאזרחו בארץ בודי
        שיכלכלו תלמידי חכמים שכמותם בכל טוב, בכל טוב!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כן, הרבה מחשבות טובות הם הגו בלבם, אבל הקב"ה לא רצה לצרף אותם
        למעשים!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וסובב ראשו של מוסה, סובב. מן השק הבלה נודף ריח של טחב והוא מצטמצם עליו,
        כובש בו פניו ומתאמץ לגרש את ההרהורים הרבים שבלבו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פתאם הוא התחלחל, קפץ ועמד על רגליו. – לפני עיניו קמה אפיה בכל יפעתה...
        הריהי צועדת זקופה ורעננה אצל ארוסה החסון!... הלה לבוש שחורים כחוג&#39;ה
        גדול... עיניו נוצצות ופניו מבהיקים מרֹב אֹשר!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתכוץ כאן לבו של מוסה ודופק, דופק. כן, הוא ראה אותם אמש בלכתם מן
        החופה!.. מתחת ההינומה הדקה ראו את עיני אפיה כשהן מורדות ונעוצות בפרחים
        שבידה. הוא רק העיף מבט עליה ועליו וימלט...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רץ הוא בלילה כמטרף דרך שדות וכרמים וכפרים עד בואו הנה, למארה זו, וכאן
        כבה אשו בנחל דמעותיו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ותוקף כעס גדול את מוסה. הוא קופץ אגרופו כאלו אומר להכות את מן דהו ומתוך
        חרוק שנים הוא מסנן עם קצף שיז מפיו:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– עשרה נפוליונים שלם עבורה הנבל, – עשרה, עשרה, עשרה!...</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עם עמוד השחר קם מוסה להתפלל ובלבו גמורה ההחלטה לנסות את מזלו במדינות
        הים. לשֵם זה הוא ילך ליפו, היום או מחר ילך ויחקר אצל התימנים דהתם ע"ד
        אותה השמועה המתהלכת ביניהם, כי ישנה מעבר לים מדינה נפלאה אחת ורוסיה שמה.
        במדינה ההיא יושבים מאה רבוא יהודים טובים ועשירים, אשר אוצרות קֹרח טמונים
        להם, והם נדיבים בני נדיבים, שפורשים צדקה לעני שני נפוליונים, נפוליון אחד
        ולא פחות מחצי נפוליון בפעם אחת!... נמנה וגמור – הוא יפליג בספינה וילך
        לארץ הפלאות ההיא!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל מתוך תפלתו נזכר מוסה שאליה זו קוץ יש בה! דומה לו שאמרו, כי נסיעה
        להתם עולה שמונה נפוליונים! – היתכן, היתכן כדבר הזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושוב אוכל הפקפוק את לבו: – אם חס ושלום אמת הדבר, מה יעשה אז? מה יעשה?
        כיצד ישאר כאן ויראה באבדת עולמו בלי בית, בלי כלום והעיקר – בלי שום ילדה?
        –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והעבודה זו שבכאן, הוי, כמה הוא שונא, כמה שונא אותה! – יום יום רוצה לך
        אל הכרם או אל הפרדס, או בצוק רגליך בחמר ובלבנים, – כלום לא עבודת פרך היא
        זו שהעבידו את אבותינו במצרים? –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומלא תמרורים נטל מוסה את ידיו לסעדת הבקר. ישב הוא לו תחת אחד האילנות,
        המסתעפים קרוב לארוה, ישב ולא הרגיש בתנועה שבחצר, לא הרגיש בשכנו, כשזה
        עומד כבר רתום בעגלה ומחכה לצליפת השוט למען הסתלק מן החצר. מוסה לועס לו
        את פתתו הקרה בלי תאבון, לועס ולוגם לגימות קלות מקֻמקמו השחור.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פרח עוף על האילן, קפץ מענף לענף וצפצף בעליצות. מוסה זוקף ראשו, נועץ
        עיניו בענפים ומחפש את השובב. קרני שמש רכות ולטיפות הצליפו על עיניו, חדרו
        (בחמימותן) אל לבו ורככו את המרירות שבו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשמשות חלונו הפתוח של בית החוג&#39;ה ראה מוסה פתאם שמש זהב שניה המתיזה
        נצוצות מסביב. והרי גם כאן, כאן בפח הדבוק אל חור מארתו, מצטחקת השמש בעגול
        כספי!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נחת רוח גדולה משתפכת לאט לאט לתוך אבריו הכבדים של העלם השחרחר, והוא יחד
        עם ברכת המזון, מברך את המקום ברוך הוא על עולמו היפה שברא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכאן חוזר מוסה מדעתו מבקש עשר בכרכי הים. – מוטב שיעבד כאן ולא ילך ולא
        ינוע! השם ירחם! –  –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– יגיע כפיך כי תאכל, אשריך וטוב לך! – ממלמל מוסה בנטלו את מעדרו ואת
        טליתו, ומסתלק בזריזות מן החצר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובדרך בין הכרמים מבצבצים בלבו הרהורי תקוה: – אפשר שהאדון לא צחק כלל,
        כשאמר לו שלשום בחיוך:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הלבנים הללו, שאנו מטפלים בהם, הם בשבילך מוסה! אני רוצה לתקן לך חדר
        יפה. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האמנם? הנכון הדבר? ואם כך, שמא גם ילדה יזמין לו השם, ובזול? הרי יש לו
        שלשה נפוליונים... עוד אחד ועוד אחד והריהו מתקן לו עזר כנגדו אם ירצה
        השם!..</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכאן מבצבץ עוד רעיון מלבב אחד במחו: – שמא יקח את אותה היבמה הקטנה,
        שלפני ימים מועטים באה עם קרוביה מעדן? בחדש אדר ראשון נשאה הילדה והוא,
        בעלה, מת בניסן! – מסכנת!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל מדוע פוסלים כאן התימנים את התרה להנשא, שנתנו רבני תימן? – מתמרמר
        מוסה, – הרי אחי בעלה מקטנותו מסור בידי הגוים והוא מושלמי אדוק בכל נפשו
        ובכל מאדו, – באיזה רשות אוסרים את הילדה, – באיזה רשות?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ולבו מתחמץ על עוות-הדין של התימנים ביחס ליבמה העלובה. מוסה מהרהר, מהרהר
        בה, ונותן אל לבו לנסע עמה לחג העצרת ירושלימה אצל קרובו אברהים סליח, למען
        שזה ישתדל אצל הרבנים דהתם להכשיר את התרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואז, אז, – מהרהר הוא ופניו מבהיקים – אז יקח את הקטנה לאשה! – האח מה
        טוב, מה נעים! – ומוסה קופץ משמחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא מתקרב מתוך פסיעות מהירות אל מקום עבודתו. מעדרו על שכמו וטליתו
        מופשלת והוא פוסע ומרנן בחדוה: –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"וילך איש מבית לוי, ויקח בת לוי"...  </span>
    </p>
    <p class="c5" dir="rtl"><span class="c4"></span></p>
  </body>
</html>`;

export default page;
