const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c13 doc-content">
    <p class="c6 italic" dir="rtl">
      <span class="c1">
        ההספד הזה שכתבה נחמה פוחצ&#39;בסקי על יוסף ויתקין פורסם בעיתון</span
      ><span class="c1 c7 bold"> האור, </span
      ><span class="c11 c1">ט&#39; שבט תרע"ב  [28.1.1912]. </span>
    </p>
    <p class="c6 italic space-bottom" dir="rtl">
      <span class="c1"
        >על הידידות העמוקה בין בני הזוג פוחצ&#39;בסקי ויוסף ויתקין מעידים גם
      </span>
      <span class="c1 c3 tooltip">
        תאורו של מיכל פוחצ&#39;בסקי את הפגישה הראשונה ביניהם
        <span class="c7 tooltiptext"
          >מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה זלמן פוחצ'בסקי, יד
          בן-צבי 2013, עמ' 383-382</span
        >
      </span>

      <span class="c1"> וגם </span>
      <a href="/letters/yosefVitkin19051904"><span class="c1 c5">מכתבים שכתב אליהם ויתקין ממסחה.</span></a>
      <span class="c1 c12"> </span
      >
    </p>
    <h3 class="c9" dir="rtl">
      <span class="c10">על קבר רענן</span>
    </h3>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        שוב נסתם הגולל על קבר יקר, שוב נפל גבור חלל! – אחרי חדשים רבים
        של יסורים וענויים קשים, נסתלקה נשמה גדולה, נשמת יוסף ותקין ז"ל, גבור
        התחיה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c4"
        >בימי מחלתו הארוכה הביע פעמים אין מספר את רצונו להקבר בראשון-לציון על
        גבעת השקמה העתיקה שהוא כה אהב.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        חברים טובים מלאו את רצונו והביאו את עצמותיו למקום הנרצה,
        ו&#39;ראשון&#39; ידעה לחלק את הכבוד האחרון לגבור האֻמה ובנעוריה וזקניה
        לוְתה אותו למעונו הלאומי. ידעה היא להוקיר את מורַה המת ואת אחד הלוחמים
        הכי טובים, הכי נלהבים והכי מאמינים בתחית העם ובעתידו בארצו.</span
      >
    </p>
    <p class="c9 text-center" dir="rtl"><span class="c4">_  _  _</span></p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        לפני שמנה חדשים, כשותקין התעתד לנתוח גדול וסבור היה, כי הוא
        הולך תיכף למות, כתב לנו כדברים האלה:  "...אפשר אפוא שמכתבי זה לכם הוא
        האחרון. במה אפרד מכם? לאחל לכם בפרזות ידועות איני יכול ולא עכשיו השעה,
        דעו רק שעד הרגע האחרון הנני נשאר אוהב החיים, העולם והאדם. מאמין אני
        שהעולם – הגן-עדן הזה, יהפך באמת לגן-עדן לבני האדם, ואתכם אברך להנות ממנו
        עד כמה שאפשר יותר, ותזכו לראות בהתקדמות גאולתנו..."</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        גאולה וגאולה ! – זה הבהב תדיר בתוך נשמתו הגדולה ובכל ראה הוא
        סגֻלה יקרה זו – באו צעירים מרוסיה לעבוד, או אחד מבני האכרים התפתח ועבד
        בחריצות על אדמת אבותיו, נוצצות בשמחה עיני ויתקין הגדולות והעמֻקות –
        "אתחלתה דגאולה!" באו תימנים מדרום, מיד הוא מתחיל הוזה בתנועה גדולה,
        בקבוץ גליות וכו&#39;.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        וכשויתקין עלה על הבמה לנאום, ראית ניצוצי אורה יוצאים מנשמתו
        הגדולה, והרגשת כי נביא, חוזה עומד לפניך. הוא פתח לפניך אופקים כל-כך
        גדולים, כל כך רחבים עד כי שכחת את חייך האפורים ונִשאת אתו לעולמות כה
        יפים, כה מזהירים!...</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        ובשיחת רעים פשוטה לכאורה, מה גדול ועמוק היה ויתקין! אף פעם לא
        קִוַה כי שיחתו לא תעשה רשם, לא תעיר מחשבה, לא תעורר אמונה באיזה עתיד מלא
        אורה...</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        וויתקין הלך מעולמו צעיר, מלא שאיפות גדולות וכבירות בתקון החנוך,
        תקון הלאֹם ותקון הישוב. הוי, למה לוקחת מאתנו לוחם חביב, יקר?!...</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        כשהיית מונח בחדר הטהרה מכוסה סדין דק ולבן, נדמה לי שעוד דופק
        תחתיו הלב הגדול, ואחרי כך, אחרי כך כשכוסית בעפר הגבעה ותלמידיך
        ותלמידותיך בוכים, בוכים מר סביב לקברך, נתק דבר מה בלבי ותקפני טרוף –
        לעקר את ההר, לעקר את האילן משרשו!...</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >        ונשתתקו הקולות, נשתתקו הדמעות. – השלום לך בעפר גבעתך, רע
        ונביא?</span
      >
    </p>
    <p class="c6 text-left" dir="rtl">
      <span class="c4">נחמה פוחצ&#39;בסקי</span>
    </p>    
  </body>
</html>
`;

export default page;
