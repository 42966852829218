import zionPilgrims_modal from "../pages/herArticles/zionPilgrims_modal";

import {
  testimonies_mainDesc,
  theMutchWeddingAndImmigration,
  theMutchWeddingAndImmigration_modal1,
  theMutchWeddingAndImmigration_modal2,
  onTheFirstYearInIsrael,
  onTheFirstYearInIsrael_modal1,
  onPregnancyFever,
  onHerCharacter,
  aboutWorkinkInTheFarm,
  onTheWaysAtHome,
  nehamaAsAMother,
  onTheLanguage,
  onTheLanguage_modal1,
  onTheLanguage_modal2,
  onTheConnectionWithYaman,
  onTheTrialAndMediation,
  onThePracticeOfBanningBiya,
  onOrganizingPublicActivities,
  onTakingCareOfOtherGirls,
  onLifeAndWriting,
  onWomanProtectionProgression,
  onConnectionWithTheHebrewBattalion,
  onTheCollectionOfTheOralTestimonies,
} from "../pages/testimonies";

import grandmaEfratOnNehama from "../assets/audio/grandmaEfratOnNehama.mp3";

const texts = {
  pageUrl: "/testimonies",
  mainDesc: testimonies_mainDesc,
  mainDescModalsContent: [
    {
      // doc: onTheLanguage_modal1,
      isImage: false,
      isAudio: true,
      audioSrc: grandmaEfratOnNehama,
    },
  ],

  items: [
    {
      name: "theMutchWeddingAndImmigration",
      title: "על השידוך הארוסין החתונה והעליה ארצה",
      subTitle: "",
      description: null,
      doc: theMutchWeddingAndImmigration,
      modalsContent: [
        {
          doc: theMutchWeddingAndImmigration_modal1,
          isImage: false,
        },
        {
          doc: theMutchWeddingAndImmigration_modal2,
          isImage: false,
        },
      ],
    },
    {
      name: "onTheFirstYearInIsrael",
      title: "על השנה הראשונה בארץ",
      subTitle: "",
      description: null,
      doc: onTheFirstYearInIsrael,
      modalsContent: [
        {
          doc: onTheFirstYearInIsrael_modal1,
          isImage: false,
        },
        {
          doc: zionPilgrims_modal,
          isImage: false,
        },
      ],
    },
    {
      name: "onPregnancyFever",
      title: "על הריון, קדחת, פעוטות ושכול",
      subTitle: "",
      description: null,
      doc: onPregnancyFever,
    },
    {
      name: "onHerCharacter",
      title: "על אופייה בהקשר לתולדותיה",
      subTitle: "",
      description: null,
      doc: onHerCharacter,
    },
    {
      name: "aboutWorkinkInTheFarm",
      title: "על העבודה במשק והקשר לטבע",
      subTitle: "",
      description: null,
      doc: aboutWorkinkInTheFarm,
    },
    {
      name: "onTheWaysAtHome",
      title: "על דרכי הבית",
      subTitle: "",
      description: null,
      doc: onTheWaysAtHome,
    },
    {
      name: "nehamaAsAMother",
      title: "על נחמה כאמא",
      subTitle: "",
      description: null,
      doc: nehamaAsAMother,
    },

    {
      name: "onTheLanguage",
      title: "על השפה",
      subTitle: "",
      description: null,
      doc: onTheLanguage,
      modalsContent: [
        {
          doc: onTheLanguage_modal1,
          isImage: false,
        },
        {
          doc: onTheLanguage_modal2,
          isImage: false,
        },
      ],
    },
    {
      name: "onTakingCareOfOtherGirls",
      title: "על הטיפול בילדות שונות",
      subTitle: "",
      description: null,
      doc: onTakingCareOfOtherGirls,
    },
    {
      name: "onTheConnectionWithYaman",
      title: "על הקשר עם העדה התימנית",
      subTitle: "",
      description: null,
      doc: onTheConnectionWithYaman,
    },
    {
      name: "onTheTrialAndMediation",
      title: "על המשפט והגישור",
      subTitle: "",
      description: null,
      doc: onTheTrialAndMediation,
    },
    {
      name: "onThePracticeOfBanningBiya",
      title: "על נוהג איסור ביאה עד לפיוס",
      subTitle: "",
      description: null,
      doc: onThePracticeOfBanningBiya,
      hidden: true,
    },
    {
      name: "onOrganizingPublicActivities",
      title: "על אירגון פעילות ציבורית",
      subTitle: "",
      description: null,
      doc: onOrganizingPublicActivities,
    },
    {
      name: "onLifeAndWriting",
      title: "על החיים והכתיבה",
      subTitle: "",
      description: null,
      doc: onLifeAndWriting,
    },
    {
      name: "onWomanProtectionProgression",
      title: "על הגנה על נשים, קידומן, ומאבק לשיוויון מעמדן",
      subTitle: "",
      description: null,
      doc: onWomanProtectionProgression,
    },
    {
      name: "onConnectionWithTheHebrewBattalion",
      title: "על הקשר עם אנשי הגדוד העברי",
      subTitle: "",
      description: null,
      doc: onConnectionWithTheHebrewBattalion,
    },
    {
      name: "onTheCollectionOfTheOralTestimonies",
      title: "על איסוף העדויות שבעל פה על ידי רות ארבל",
      subTitle: "",
      description: null,
      doc: onTheCollectionOfTheOralTestimonies,
    },
  ],
};

export default texts;
