const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c10 doc-content">
    <p class="c3 italic" dir="rtl">
      <span class="c12 bold">בוסתנאי</span
      ><span class="c2"> תרצ"ד ט&#39; (עמ&#39; 17-15)</span>
    </p>
    <br />

    <h3 class="c13 text-center" dir="rtl" id="h.3kaa1mrja5mv">
      <span class="c11">אסתר מרגולין, נחמה פוחצ&#39;בסקי</span>
    </h3>
    <p class="c3 c6" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        הנאהבות והנאמנת בחייהן – גם במותן לא נפרדו. זו אחרי זו עזבו את
        עולם השקר והלכו אל עולם האמת. ארבעים שנה עברו עליהן מאז נפגשו  בפעם
        הראשונה על אדמת-אבות ותכרותנה ברית-אהבה, ואף פעם לא הופרה הברית, אף פעם
        לא העיב גם ענן קל את שמי אהבתן.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        מה אִחד את שתי הנפשות הללו? בעלות אופי שונה תכלית שנוי היו,
        והבדל שָנים הבדיל ביניהן – שלש-עשרה שנה, כמעט הבדל של דור... האחת,
        הגדולה, היתה בעלת אופי חזק – כמעט זועם, בעלת הגיון קר – כמעט אכזרי, ואת
        פניה כסתה שכבה עבה של קור. אל הארץ באה רחוקה משפת עמה וממסורת עמה, כמעט
        נכריה, ואל הציונות הביא אותה הגיונה, כבר על אדמת-האבות. והשניה – ההפך
        הגמור: בעלת אופי רך – כמעט ילדותי, בעלת רגש עמוק ועדין, הנובע מנבכי
        הנשמה ונבעת ממגע ההגיון, וכל פניה אומרים חן של חמימות. ואל הארץ באה –
        עבריה בכל רמ"ח אבריה. טל הנוער עדין כסה את תלתלי ראשה ונפשה ספוגה טל
        השירה העברית, המסורת העברית והגעגועים הנצחיים את המולדת.</span
      >
    </p>
    <p class="c8" dir="rtl">
      <span class="c1"
        >        מה אִחד אותן? – דבר-מה, שאינו נראה לעין, אחדן: אהבה. האהבה לכל
        מי שנוצר בצלם אלהים ובדמות תבניתו. האהבה לכל הבריות היתה שירת חיי שתיהן
        ותוכן חייהן והיא עשתה אותן, את השונות זו מזו, לנפש אחת. ואולם גם האהבה
        לבשה אצל כל אחת מהן צורה אחרת. הגדולה – אוצר אהבתה היה גנוז עמה
        עמוק-עמוק בנפשה, מתחת לשכבה עבה של קור ומתחת לשריון חזק של הגיון, והיא
        חלקה ממנו רק לאלה הזקוקים לו באמת, דין-קדימה לנגועי החיים. היא אהבה
        ביחוד את האומללים, ואת מתנות אוצרה נותנת היתה בצנעה ובסתר. וחברתה הצעירה
        – אוצר אהבתה הטוב היה שפוך על כולה, על פניה ועל נפשה, על דבורה ועל
        כתבי-ידה, ותחלקו ביד פזרנית, רחבה ונדיבה "לכל דכפין". היא אהבה גם את
        המאושרים.</span
      >
    </p>
    <p class="c9 text-center" dir="rtl"><span class="c1">___</span></p>
    <p class="c8" dir="rtl">
      <span class="c1"
        >תחלה הכרתי את הצעירה. לפני ארבעים וארבע שנים, כשהייתי חוזר ערב-ערב מן
        ה"בחר" שלי לחדרי ובא אל האֻרוה הגדולה של ה"פקידות", למסור שם את מעדרי,
        הייתי רואה על אחת מעליותיה (שתי עליות היו לאורוה משני קצותיה, ובהן גרו
        שנים מהפקידים) עלמה צעירה לימים, שחרחורת ובת-חן, מביטה אלי ברצון. ואני
        הייתי עונה לה במבט של זעם, כי איככה לא ישיב מבט נזעם עלם בן שבע-עשרה,
        החוזר מן העבודה, לרעיתו של הפקיד, הבא יום-יום ל"בחר" רכוב על סוסו, ללמד
        את בחורי ישראל כיצד להחזיק מעדר ביד? הלא "פקיד" הוא ו"משגיח"! ואיככה ישא
        עלם לעלמה "פשעה" –היותה רעיה לפקיד זה? היוכל עלם בגיל זה להבין, כי יש
        אשר תחת עורו של פקיד יהמה לב עברי טוב ועמוק, כבמקרה הזה, והיוכל להעלות
        על דעתו כי פקיד זה חקלאי הוא בחסד עליון ובכל רמ"ח אבריו?... לא הבנתי ולא
        ידעתי ושלמתי זעם תחת רצון. לא ידעתי גם זאת, כי עלמה זו, אשת הפקיד, היא
        ה"נפש", אשר לפעמים קראתי את מכתביה ב"המליץ", שהשיבו עלי חן וחבה וחסד של
        ארץ מולדת. עזבתי את ראשון-לציון ואל "אשת הפקיד", היא נחמה פוחצ&#39;בסקי,
        לא התודעתי.
      </span>
    </p>
    <p class="c3 c6" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את הבכירה הכרתי אחר-כך. אנכי אכר ברחובות. מתגורר בחדר קט של אחד האכרים,
        זוג זקנים. הוא סר וזועף כל הימים ומדבר בלשון של גערה אל אשתו, אל פרותיו
        ואל עופותיו. ורק לעתים רחוקות, כשיצטחק – וידעת כי לב טוב לו. והזקנה נפש
        מדוכאה וסמל הטוב. ולהם בת ובן. הבת גדולה בשנים. היא מילדת מוסמכת,
        הראשונה בסביבה הפראית שלנו. הראשונה שהכניסה קוי אור-מדע במקצועה. היא
        עבדה במושבה הקטנה עקרון שכנתנו, ותעש את עבודתה מתוך שאר-רוח ומסירות-נפש,
        ולא כמלאכה גרידא. היא היתה גם מעורבת בכל עניני המושבה ותשמש לה, לפעמים,
        מליץ-יושר כלפי הפקיד של הברון, כי ידעה לקשור יחסי ידידות גם עם ביתו. וגם
        במושבתנו אהבוה. היא היתה ערה לכל ענינינו הצבוריים, כקטן כגדול. בני הנוער
        אהבוה וכבדוה. היא הגדולה, היא החכמה, ודעתה תמיד שקולה. אחיה בחור-חמד.
        הכל אהבוהו, כעברים וכערבים, על טוב לבו ועל גבורתו. הערבים כנוהו
        "חוג&#39;ה נזר", ויהיו מסורים לו ונשבעים בשמו, וצעירי המושבה התברכו בו
        ועליו גאותם. בימי המשבר שעברו על המושבות יצא לאוסטרליה ומשם בא בימי
        המלחמה בתור מתנדב אל המחנה הבריטי, ויצליח, ויצטין ויתעלה למדרגת קולוניל,
        ויבוא אל הארץ כמפקדו האהוב של הגדוד העברי. עוד אח בא אליהם למושבה מן
        הגולה, עִור מימי ילדותו ומיטיב נגן. ישבתי כשנתים בבית הזה והתקשרתי אליו
        ואהבתיו. בית אכרים יפה. בית עברי יפה. מרכז-מה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">        האחות הגדולה היא אסתר מרגולין.  </span>
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c1">___</span></p>
    <p class="c5 c6" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        אסתר ונחמה נפגשו בשעה חמורה בחייה של האחרונה. ובשעה זו, שעת
        אסון לאחת, חוברו שתיהן להיות כנפש אחת לכל ימי חייהן. בשעה זו התודעתי גם
        אני אל נחמה. גלמודה, בודדה ועזובה מכל חבריה וידידיה, שוחרי ביתה בימי
        אשרה, ישבה בחדר ידידתה במושבתי. ובאחד הערבים, בין השמשות נכנסתי אליה
        ולחצתי בחמימות ובלבביות את ידה הרכה והענוגה. שתי עינים פצועות עמדו עלי
        רגע קל מתוך רגש של תודה עמוקה. ואת מבט העינים הללו לא שכחתי אח"כ במשך
        שנים רבות, ובמשך שנים רבות היה מספר לי על אותה הטרגדיה האנושית הגדולה
        הקרויה אשה עבריה, טרגדיה אשר לא אחת שמעתי את ספורה ללא-מלים ואשר עדיין
        לא קם סופר בישראל אשר יסמלה במלים לנצח. ושנים רבות עקבתי מתוך ענין רב את
        חייה של האשה הזאת, ותמיד עמדתי בהדרת כבוד לפני גבורת נפשה הנפלאה, אשר
        ידעה להפוך אסון פרטי למקור נצחי של אהבה וחסד ורחמים לכל הבריות, ולמקור
        נצחי של מרץ וכח למעשים טובים ומבורכים כל ימי חייה.</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c1">___</span></p>
    <p class="c5 c6" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        נחמה היתה סופרת. ההשגחה לא ברכה אותה בעט יוצר נשגבות, אבל עטה
        ידע לפעמים ליצור חמודות. היא לא ישבה ב"מזרח" הסופרים העברים, אך
        ארון-הקודש של מזרח הספרות העברית עמד תמיד בלבה. עטה היה לה מקדש-מעט. ורק
        ברגעים של עלית-נשמה היתה מושיטה אליו את ידיה. לעניני חול לא השתמשה בו.
        בעודנה נערה צעירה התחילה את נסיונותיה הספרותיים הראשונים – ולא בשפה
        הרוסית, השגורה אז בפיה, כי אם בשפה העברית, בשפה התנ"כית אשר בלבה; כי בת
        ליטא העברית היתה ואת שפת עמה ותורת עמה למדה, ידעה ואהבה מילדותה. כשבאה
        לא"י – והיא בת עשרים – התחילה לכתוב מזמן לזמן מכתבים ל"המליץ" בחתימת
        נ&#39;פ&#39;ש (נחמה פיינשטיין – שם משפחתה לפני כלולותיה), מכתבים ספוגים
        פרפורי נפש וחנו המיוחד של הישוב בימים ההם. אח"כ כתבה ציורים וספורים,
        ולפעמים רחוקות גם מאמרים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        יש שבקשתי בציוריה הד להמית לבה הפרטית, ואותו אף פעם לא מצאתי.
        ציוריה היו תמיד בטוי לאותו מגן, אשר בו נתכנסה ונסתתרה המית לבה, אך לא
        לעצם המית לבבה... האם לא רצתה או לא יכלה?...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        ובטוי נמרץ למגן המסתיר והמכסה הזה היו כל מפעלי חייה. תמיד בקשה
        לה מקדש-מעט, להאחז בקרנות מזבחו... תחלה היה המזבח הזה – השפה העברית. אז,
        בימים ההם, כשהדבור העברי נחשב כמעשה-גבורה, היתה היא אחת הגבורות
        הראשונות. חוץ מביתו של המורה היה ביתה – הבית העברי הראשון במושבה, וכל
        המדברים עם נחמה שברו את לשונם לדבר עברית. ואפילו חברתה הגדולה ממנה, אסתר
        ה"גויה", אשר לא ידעה אף מלה עברית אחת ותהי "מתיחסת בשלילה" אל המנסים
        "להחיות את השפה ולהמית את המחשבה", - הושפעה סוף-סוף מחברתה הצעירה ותלמד
        את לשונה לדבר עברית, ובשנות חייה האחרונות – וגם על מטת חליה שלא ירדה עוד
        מעליה – דברה רק עברית...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        אחר-כך אחזה נחמה בקרנות המזבח של החקלאות... היא אולי היתה
        במושבתה החקלאית הראשונה הראויה לשם זה. ובטרם תהיינה החלוצות בארץ היתה
        היא החלוצה של הרפת, של הלול, של גנת-הירק ושל ערוגת-הפרחים. החצר שלה היתה
        במשך שנים חצר למופת, והיא האכרה עמלה וטרחה בה כל היום כנמלה, ולא היה דבר
        אשר יתן לה ספוק נפש ואשר יאיר את פניה הכחושים באור של שמחה – כמחמאה ללחם
        אשר אפתה במו ידיה, לתוצרת החלב שהכינה בעצמה, לירקות שלה ולפרחים שלה.
        שנים רבות עמדה בנסיון האכרות, עד שהתחילו כחותיה פוחתים וכלים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        בשנים האחרונות היו מזבחותיה – העסקנות הצבורית במדה המצומצמת,
        הדאגה לפרטים, לסובלים האלמים, ובזה נעזרה הרבה, בעיקר, ע"י חברתה אסתר;
        ובמדה הרחבה, הדאגה לכלל, לענינים הצבוריים – המושבתיים והארציים. היתה
        חברה פעילה להסתדרות הנשים, חברה במועצת המושבה, ומסייעת לקרנות. וביחוד
        נתנה את נפשה לבעיה החמורה של העבודה העברית. ודוקא לא בקולי-קולות, כי אם
        במעשה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        את כל מה שעשתה נחמה עשתה בכל נפשה, ובכל אשר עשתה האמינה אמונה
        שלמה. חייה היו תמיד מלאים על כל גדותיהם.</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c1">___</span></p>
    <p class="c5 c6" dir="rtl"><span class="c1"></span></p>
    <p class="c3 c7" dir="rtl">
      <span class="c1">"מלאים"... ובכל זאת לא שלמים.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        בשנים האחרונות הייתי נפגש עם נחמה רק לעתים רחוקות מאד; אבל בכל
        פעם שראיתיה נדמה היה לי, כי למרות חייה מרובי-הגוָנים ומרובי-הפעולות
        בודדה היא, וכי חייה – החיצוניות מרובה בהם מהפנימיות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        שעות-מספר לאחר שנסתם הגולל על קברה סח לי אחד מידידיה המעטים,
        אשר ידע לא רק את נחמה ה"חיצונית" כי אם גם את ה"פנימית":</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        ...לפעמים רחוקות, רחוקות מאד, בשעת בין השמשות, בהיותה לבדה בבית
        ובדַמותה שאין איש עמה ואין איש רואה ושומע אותה, יש שהיתה שרה לנפשה
        בחצי-קול ובשפה הרוסית שיר שהיה חביב עליה עוד בשנות נערותה, בשבתה על ספסל
        הלמודים בגימנסיה בצריצין... והשיר מספר על אחד עלם, בעל לב סוער וסואן,
        בודד לנפשו ומשתעמם דוקא בתוך שאון קריה... ונימי שירתה ספוגים עצב חרישי
        ויגון נפשי עמוק ונוקב...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        נחמה העליזה, נחמה מרובת החיים, שפיה מלא תמיד שיר-אמונה, נחמה
        שרגליה רצות תמיד לדבר מצוה, נחמה המעורבת תמיד עם הצבור – היא ובדידות,
        היא ושעמום... מחידות החיים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        אל בית-העולם בראשון לציון, שהרבה נפשות יפות, סוערות והומיות כבר
        מצאו בו את מנוחתן, הלכה לבקש את מנוחתה גם הנפש היפה, רבת-החן ורבת הסוד
        הגדול והטרגי של החיים, שנקראה בחייה נחמה פוחצ&#39;בסקי. והפעם ודאי מצאה
        את מנוחתה השלמה.</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c1">___</span></p>
    <p class="c5 c6" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        בבית-העולם בראשון-לציון מצאה את מנוחתה גם אסתר מרגולין. בימי
        המשבר הגדול והקשה שעברו על המושבות בתחלת המאה האחרונה נחרב בית מרגולין
        ברחובות. הזקנים מתו. נחלתם נמכרה. האח הצעיר והאחות נדדו לכרכי הים. לאחר
        שנים-מספר חזרה האחות ותהי במשך שנים נודדת מראשון-לציון, המושבה של חברתה,
        לרחובות, המושבה של הוריה, שבה רכשה לה שוב חלקת-קרקע. לבסוף גבר כח-המשיכה
        של החברה – ותשאר בת ראשון-לציון, ובה בנתה לה בית, ובה הקדישה את שנותיה
        האחרונות לסובלים האלמים והנעלמים, ובה התחבבה על כל תושביה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        ובה, בבית-העולם שלה מצאה את קבורתה שבעת שנים ושבעת רוגז החיים.
        ימתקו לה רגבי עפרתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">משה סמילנסקי</span>
    </p>
  </body>
</html>`;

export default page;
