const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c2" dir="rtl">
      <span class="c7 bold">הצפירה</span
      ><span class="c1 c3">  16.11.1889 </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c1"
        >בראשון לציון נוספה אשה אחת יודעת היטב שפה עברית, והיא הגברת נחמה שנשאה
        להגנן פוחאציוסקי. נקוה כי היא תהיה אחת מהעובדות בתחית הלשון
        בארצנו.</span
      >
    </p>
    <p class="c2 c5" dir="rtl"><span class="c4"></span></p>
  </body>
</html>`;

export default modal;
