import fromNewByehudaDesc from "../pages/herStories/descriptions/fromNewByehudaDesc";
import kidsAdviseDesc from "../pages/herStories/descriptions/kidsAdviseDesc";
import underTheEtrogDesc from "../pages/herStories/descriptions/underTheEtrogDesc";
import simaRskinDesc from "../pages/herStories/descriptions/simaRskinDesc";
import theRegretDesc from "../pages/herStories/descriptions/theRegretDesc";
import jlutDesc from "../pages/herStories/descriptions/jlutDesc";
import lightsAndShadowsDesc from "../pages/herStories/descriptions/lightsAndShadowsDesc";
import inThePlowingDesc from "../pages/herStories/descriptions/inThePlowingDesc";
import theLostWigDesc from "../pages/herStories/descriptions/theLostWigDesc";
import withoutHerDesc from "../pages/herStories/descriptions/withoutHerDesc";
import floraSportoDesc from "../pages/herStories/descriptions/floraSportoDesc";
import inSolitudeDesc from "../pages/herStories/descriptions/inSolitudeDesc";
import afiasDisasterDesc from "../pages/herStories/descriptions/afiasDisasterDesc";
import inTheGroupShadeDesc from "../pages/herStories/descriptions/inTheGroupShadeDesc";
import fromInVillageAndWorkDesc from "../pages/herStories/descriptions/fromInVillageAndWorkDesc";
import onlyNoteDesc from "../pages/herStories/descriptions/onlyNoteDesc";
import atThoseDaysDesc from "../pages/herStories/descriptions/atThoseDaysDesc";
import didIWroteDescWithModal from "../pages/herStories/descriptions/didIWroteDescWithModal";
import didIWroteDesc from "../pages/herStories/descriptions/didIWroteDesc";

import {
  kidsAdvisePage1,
  kidsAdvisePage2,
  kidsAdvisePage3,
  theNewJudea1910,
  theNewJudea1929withDedication,
  inVillageAndWork_binding1,
  inVillageAndWork_binding2,
  inVillageAndWork_binding3,
} from "../images/herStories";

import {
  didIWrote,
  theLostsPreTitle,
  kidsAdvice,
  underTheEtrog,
  theLosts1911,
  simaRskin,
  dying,
  theRegret,
  ruma,
  jlot,
  twice,
  saintJoy,
  afterHappiness,
  lightsAndShadows,
  inThePlowing,
  theLostWig,
  withoutHer,
  floraSporto,
  inSolitude,
  afiasDisaster,
  inTheGroupShade,
  inTheGroupShadePreTitle,
  thrOrigin,
  saraZarchi,
  hameshek,
  onlyNote,
  bamidron,
  atThoseDays,
} from "../pages/herStories";

const texts = {
  pageUrl: "/herStories",
  mainDescModalsContent: [
    {
      doc: didIWrote,
      isImage: false,
    },
    {
      isImage: true,
      images: [theNewJudea1910],
    },
    {
      isImage: true,
      images: [theNewJudea1929withDedication],
    },
    {
      isImage: true,
      images: [
        inVillageAndWork_binding1,
        inVillageAndWork_binding2,
        inVillageAndWork_binding3,
      ],
    },
  ],

  items: [
    {
      name: "didIWrote",
      title: "הכתבתי?",
      description: didIWroteDesc,
      doc: didIWrote,
    },
    {
      name: "kidsAdvice",
      title: "עצת ילדים",
      description: kidsAdviseDesc,
      doc: kidsAdvice,
      modalsContent: [
        {
          isImage: true,
          images: [kidsAdvisePage1, kidsAdvisePage2, kidsAdvisePage3],
        },
      ],
    },

    {
      name: "theLosts",
      preTitle: theLostsPreTitle,
      title: "האבדות",
      description: fromNewByehudaDesc,
      doc: theLosts1911,
    },
    {
      name: "underTheEtrog",
      title: "תחת האתרוג",
      description: underTheEtrogDesc,
      doc: underTheEtrog,
    },
    {
      name: "simaRskin",
      title: "סימה רסקין",
      description: simaRskinDesc,
      doc: simaRskin,
    },
    {
      name: "dying",
      title: "גוססת!",
      description: fromNewByehudaDesc,
      doc: dying,
    },
    {
      name: "theRegret",
      title: "החרטה",
      description: theRegretDesc,
      doc: theRegret,
    },
    {
      name: "ruma",
      title: "רֻמָה",
      description: fromNewByehudaDesc,
      doc: ruma,
    },
    {
      name: "jlot",
      title: "ג'לות!",
      description: jlutDesc,
      doc: jlot,
    },
    {
      name: "twice",
      title: "פעמים",
      description: fromNewByehudaDesc,
      doc: twice,
    },
    {
      name: "saintJoy",
      title: "ג'וי קדוש",
      description: fromNewByehudaDesc,
      doc: saintJoy,
    },
    {
      name: "afterHappiness",
      title: "אחרי שמחה",
      description: fromNewByehudaDesc,
      doc: afterHappiness,
    },
    {
      name: "lightsAndShadows",
      title: "אורות וצללים",
      description: lightsAndShadowsDesc,
      doc: lightsAndShadows,
    },
    {
      name: "inThePlowing",
      title: "בחרישה",
      description: inThePlowingDesc,
      doc: inThePlowing,
    },
    {
      name: "theLostWig",
      title: "הפאה האבודה",
      description: theLostWigDesc,
      doc: theLostWig,
    },
    {
      name: "withoutHer",
      title: "בִּלְעָדֶיהָ",
      description: withoutHerDesc,
      doc: withoutHer,
    },
    {
      name: "floraSporto",
      title: "פלורה ספורטו",
      description: floraSportoDesc,
      doc: floraSporto,
    },
    {
      name: "atThoseDays",
      title: "בימים ההם",
      description: atThoseDaysDesc,
      doc: atThoseDays,
    },
    {
      name: "inSolitude",
      title: "בִּבְדִידוּת",
      description: inSolitudeDesc,
      doc: inSolitude,
    },
    {
      name: "afiasDisaster",
      title: "אסונה של אָפְיָה",
      description: afiasDisasterDesc,
      doc: afiasDisaster,
    },
    {
      name: "inTheGroupShade",
      preTitle: inTheGroupShadePreTitle,
      title: "בְּצֵל הַקְּבוּצָה",
      description: inTheGroupShadeDesc,
      doc: inTheGroupShade,
    },
    {
      name: "thrOrigin",
      title: "המוצא",
      description: fromInVillageAndWorkDesc,
      doc: thrOrigin,
    },
    {
      name: "saraZarchi",
      title: "שרה זרחי",
      description: fromInVillageAndWorkDesc,
      doc: saraZarchi,
    },
    {
      name: "hameshek",
      title: "הַמֶּשֶׁק",
      description: fromInVillageAndWorkDesc,
      doc: hameshek,
    },
    {
      name: "onlyNote",
      title: "רַק פִּתְקָה...",
      description: onlyNoteDesc,
      doc: onlyNote,
    },
    {
      name: "bamidron",
      title: "במדרון – רומן ארצישראלי",
      description:
        "הרומן הלא גמור נמצא בעיזבונה של נחמה פוחצ'בסקי, הוא נערך ע״י ניניה אורה עשהאל ועצמון יניב (פוחצ'בסקי) ויצא לאור בשנת 2004, ספרי עיתון77,  קרוב לשבעים שנה אחרי שנכתב.",
      doc: bamidron,
    },
  ],
};

export default texts;
