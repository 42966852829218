const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c4" dir="rtl">
      <span class="c1">פורסם בעיתון </span><span class="c2 bold">האור</span
      ><span class="c1 c3"
        >, כ"ט סיון תרע"ב [14,06,1912]. הוא מועתק כאן מהעיתון מילה במילה כולל
        הטעויות.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1 c3"></span></p>
  </body>
</html>`;

export default desc;
