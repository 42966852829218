import React from "react";
import styled from "styled-components";
import appTexts from "../texts/appTexts";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../state/store.ts";

const BackButton = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const path = location.pathname;

  // let fromPage = useSelector((state: RootState) => state.from.fromPage);

  const goBack = () => {
    // if (fromPage) {
    //   window.history.back();
    // } else if (location.hash.length) {
    //   const pathArr = path.split("/");
    //   // console.log("goback has hash! pathArr[1] = ", pathArr[1]);
    //   navigate("/" + pathArr[1]);
    // } else {
    //   console.log("goback else");
    //   navigate(-1);
    // }
    // if (location.hash.length) {
    //   const pathArr = path.split("/");
    //   // console.log("goback has hash! pathArr[1] = ", pathArr[1]);
    //   navigate("/" + pathArr[1]);
    //   // navigate(-2);
    // } else {
    //   navigate(-1);
    // }
    window.history.go(-1);
  };

  return <BackBtn onClick={goBack}>{appTexts.back}</BackBtn>;
};

export default BackButton;

const BackBtn = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 24px;
  font-weight: bold;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 4px 8px;
`;
