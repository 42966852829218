const page = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c12 doc-content">
    <!-- <h3 class="c5" dir="rtl">
      <span class="c9">על הריון, קדחת, פעוטים ושכול</span>
    </h3> -->
    <p class="c3" dir="rtl"><span class="c2"></span></p>
    <p class="c5" dir="rtl">
      <span class="c2 italic"
        >הקטעים הבאים לקוחים מזכרונותיו של יחיאל מיכְל שלמה זלמן
        פוחצ&#39;בסקי</span
      ><span class="c6"> </span><span class="c2"> </span>
    </p>
    <p class="c3" dir="rtl"><span class="c0"></span></p>
    <p class="c5" dir="rtl">
      <span class="c0"
        >לירח הדבש שלנו בא סופו, ואחרי שקָמלו הפרחים התחיל להתבשל הפרי. הצעירה
        שלי התחילה להתרגש מזה שהיא עוד צעירה יותר מדי ולא מוכנה כלל מלהיות כבר
        לאם, ואין לה שום נסיון בזה, והתחילה להרגיש גלמודות וגעגועים לאם, שבזמנים
        כאלה מוצאים נוחם בחיקה.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כפי שהזכרתי, בהִגמר הבנין הענקי, בנין המשק, קִבלתי דירה. את המעון בנו
        באופן אדמניסטרטיבי בלי שום נוחיות. פה יש לציין "נדיבות לבו" של בלוך
        בדאגו לאחרים: היות שהוא בחור רוָק, סִדר לו בית גדול של שתי קומות עם כל
        הנוחיות שאין הפה יכל לבטא. הדירות שחִלק לפקידים הכילו שני חדרים קטנים
        ופרוזדור צר. אין זכר למטבח או ליתר הנוחיות שבני אדם חיים זקוקים לה,
        ובפרט בעלי משפחה. בכל רגע היו נאלצים לרדת ולעלות במדריגות הרבות. המצב
        פעל לרוע על בריאותה של חברתי, ושלשלת ארוכה של נגעים ופגעים העיבו את שמי
        חַיֵנו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">גם הקדחת התקוננה </span><span class="c2 italic">[קיננה]</span
      ><span class="c0"
        > בביתנו. אני איך שהוא יכלתי ללחום עם הקדחת ע"י חינין, אבל להצעירה שלי
        לא הִרשה הרופא לתת חינין, והקדחת נשתרשה בקרבה באופן מבהיל. ביום אחד,
        זכורני, כאשר היתה כבר במצב קרוב לחשבון, בא לה התקפה כזו שהרופא מזי"א אמר
        נואש לחייה. בינתים, בעמדו על המשמר, קראו לו על ידי רץ מהיר לבאר טוביה.
        כאשר היה נאלץ ללון שם, התבטא: "חבל, ברי לי שבבואי כבר לא אמצא את הצעירה
        הנחמדה שהופיעה ככוכב מזהיר על שמי ארצנו לזמן כה קט".
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אך מן השמים רִחמו עלינו והרופא התבדה, ואחר זמן קצר בערך קִבלנו תארים
        חדשים: אם ואב לבן זכר!
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אך הרבה נחת לא הביא עמדו הבן. בבטן אמו סבל מהקדחת, ובהיותו ילד חלש לא
        האריך ימים. בחודש התשיעי לחייו שבק אותנו לאנחות. </span
      ><span class="c2 italic"
        >[עמינדב נולד בי"ב באב תר"ן ונפטר בניסן תרנ"א.]</span
      >
    </p>
    <p class="c7" dir="rtl"><span class="c11">...</span></p>
    <p class="c1" dir="rtl">
      <span class="c0">בינתים נולד לנו הבן השני, </span
      ><span class="c2 italic">[אביחיל נולד באדר תרנ"ב - 1892.] </span
      ><span class="c0"
        >ילד בריא, ואחרי שהילד הראשון מת, חפצה להתמסר להשני ולמצוא בזה
        נוחם.</span
      >
    </p>
    <p class="c7" dir="rtl"><span class="c11">...</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הילד גדל באמת להפליא והביא קצת סִפוק בחיי המשפחה, וכאשר נמלאו לו שנה
        וחצי נולד לנו השלִשי.</span
      ><span class="c10"> </span
      ><span class="c2 italic">[עשהאל נולד בחשון תרנ"ד - 1893]</span
      ><span class="c0"
        > כמובן שהוא דרש טיפול הרבה, והקודם לו בהכרח היה כבר עומד ברשות עצמו.
        ילד גיבור, תמיד היה משתעשע בחצר המלך וכולם אהבוהו. ופתאום אסון קָרנוּ:
        הילד עלה על המדרגות ואמר "בטני, בטני", שכב ונרדם וגופו התהפך לשחור.
        ואחרי יום מת ילדי השני, כבן שנתים, גִבור חיל, במיתה חטופה.</span
      ><span class="c4"> </span
      ><span class="c2 italic">[אביחיל נפטר בסיון תרנ"ד -  1894]</span>
    </p>
  </body>
</html>
`;
export default page;
