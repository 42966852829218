const modal = `<body class="c6 doc-content">
  <p class="c0 italic" dir="rtl">
    <span class="c2"
      >המלים הבאות נכתבו על גבי גלויה שנמענה לדניאל פרסקי בניו יורק. הספר עליו
      מדובר הוא מן הסתם </span
    ><span class="c2 c5 bold">ביהודה החדשה – קבץ ציורים</span
    ><span class="c2 c7"
      > שיצא לאור באותו זמן ביפו. [הגלויה עצמה נמצאת בארכיון אגודת הסופרים בבית
      אריאלה]</span
    >
  </p>
  <p class="c0 c3" dir="rtl"><span class="c1"></span></p>
  <p class="c0" dir="rtl">
    <span class="c1">ראשון לציון עשרה באב התרע"ב</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">        למר דניאל פרסקי, ניורק.</span>
  </p>
  <p class="c0 c4" dir="rtl">
    <span class="c1">אדוני, </span>
  </p>
  <p class="c0 c4" dir="rtl">
    <span class="c1"
      >קצת תמוה אבל גם נעים היה לי מכתבו. איזה מלאך טוב לחש לו והזכירו באכרה
      עברית ובסופרת כביכל?</span
    >
  </p>
  <p class="c0 c4" dir="rtl">
    <span class="c1"
      >23 ספרים שלי יקבל נא אדוני אצל מר ל.– אפשטין. למה לשלֹח ע"י הדֹאר אם עוד
      מספר גדול של ספרַי מונחים אצל "כרמל"? כותבת אני היום למר ל.-אפשטין למסר
      לכבודו את הספרים שבקש.</span
    >
  </p>
  <p class="c0 c4" dir="rtl">
    <span>את פ"ש </span><span class="c2">[פרישת שלום]</span
    ><span class="c1"
      > למשפחת בן-נון האהודה אמסר. רֻחמה עודנה ב__ ילדה טובה, חמודה, נעימה!  זלת
      זה עבריה מסורה היא וקנאית בעבריותה, מה שמחבבה עוד יותר עלי.</span
    >
  </p>
  <p class="c0 c4" dir="rtl">
    <span class="c1"
      >בליל תשעה באב עבר על מושבתנו אסון נורא, - שומר יהודי נשחט בתוך סכתו!    
       _   _</span
    >
  </p>
  <p class="c0 c4" dir="rtl">
    <span class="c1"
      >אלי, אלי, עד היכן הגענו! לא ידע עמנו במשך שלשים השנה של התעוררות ללכת
      ולהתבסס בארץ. הישוב, התנועה, שמשו משחק לגולה ונשארנו כאן [קו]מץ קטן שהוא
      כעת למשחק בידי אחרים, מה יהא, מה יהא?</span
    >
  </p>
  <p class="c0 c4" dir="rtl">
    <span>                                               נ. פוחצ&#39;בס</span
    ><span class="c2 italic">[קי]</span>
  </p>
</body>`;

export default modal;
