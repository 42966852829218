const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c1 doc-content">
    <p class="c3" dir="rtl">
      <span class="c13 small-text">מיכְל פוחצ&#39;בסקי – מתוך זכרונותיו</span>
    </p>
    <p class="c14" dir="rtl">
      <span class="c0"
        >חברתי שבחרתי לי בחיים היתה צעירה שקִבלה חנוך בגימנסיה, ו</span
      ><span class="c6 italic">[נוסף]</span
      ><span class="c0"
        >לזה למדה על בוריה את השפה העברית. היה לה פְּרטֶנזיה להיות סופרת בחסד
        עליון, ובהיותה ברוסיה כבר נודע שמה בתור מתחילה. אך הברק של ההזדמנות
        לנסוע ולהתיַשב בארץ ישראל שבה את לבה והחליטה להחליף עט באת. המציאות היא
        תמיד אחרת מאשר המחשבה, וכמו אותה שבת קודש, באו ימי חול והעיבו את
        האילוזיה של חיי חופש וחיי עבודה פרודיקטיבית בארצינו. משכורתי הקטנה לא
        הרשתה לנו לחיות חיי רְוָחים </span
      ><span class="c6 italic">[רווחה]</span
      ><span class="c0"
        >, ועליה נפל העול לטפל אך ורק בענינים פעוטים ביתיים.
      </span>
    </p>
    <p class="c9" dir="rtl">
      <span class="c0"
        >מצב כזה של חיים לא השביע אותה לחלוטין, והחליטה לשחד מכֹּחה להוראה. היה
        אז דוקא כר נרחב בשדה ההוראה שהיה לקוי בכל המובנים. כאשר הופיע המפקח
        הראשי מר שייד, בִּקשתי ממר בלוך כי יעיד לי ולחברתי ראיון עם שייד. ביום
        אחד קִבלתי הזמנה על ידי הרכב שלו הידוע בהיסטוריַת הישוב מחמד סלח, שבשעה
        קבועה נוכל לבוא לראותו.
      </span>
    </p>
    <p class="c9" dir="rtl">
      <span class="c0">כאשר נכנסנו אל מר שייד </span
      ><span class="c6 italic"
        >[הנציג של הברון רוטשילד בכל פעילותו בארץ, ואדם שעל פי עדויות שונות נתן
        טובות הנאה – כולל משרות – לנשים שניצל מינית]</span
      ><span class="c0"
        > מצאנו אותו יושב על כורסא ורגליו מתוחות על כִּסא באיזה מין חופש
        וגנדרנות צינית. חברתי הצעירה התבישה אפילו לגשת אליו. בפוזה כזו אתה מרגיש
        את עצמך כמבוטל לגמרי. שיחתנו אמנם ארכה איזה זמן, אך מבוקשה לספחה להיות
        מורה בבית ספרינו לא מצא אֹזן קשבת: מקומות כאלה שמר בשביל הצעירות
        המחונכות על ברכיו... מאז חִפשה חברתי את עוגן ההצלה בספרות, ונדמה לה
        שהמלאך עומד על גבה ומזרזה: "כתבי".</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span class="c0"
        >בינתים נולד לנו הבן השני, ילד בריא, ואחרי שהילד הראשון מת, חפצה להתמסר
        להשני ולמצוא בזה נוחם. לכתוב לא נָתן לה הילד, שדרש טפול רב בעת שאין לה
        שום עזר בבית, ואני הייתי כל כך טרוד בעניָני המשק, שבהִמצאי בבית הייתי
        עיֵף ויגע, ולא היה לה אפילו האפשרות לבלות אתי איזה שעה קלה בשיחה. חיים
        כאלה דִכאו את נפשה.
      </span>
    </p>
    <br />
    <p class="c5" dir="rtl">
      <span class="c13 small-text">אפרת בן כהן</span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c4">אז לא ידעתי שאמא שלי סופרת. </span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c8"
        >אמא התחילה לכתוב עוד בגיל שש-עשרה. היא לא דיברה על הכתיבה שלה. בכלל
        בבית שלנו לא דיברו. לא היה פנאי לדבר. אבא תמיד עבד בחוץ. אמא היתה עסוקה
        בכתיבה, ואם לא – אז ברפת או בגינה. אז לא היה זמן לדבר.  אבל כשאמא כתבה,
        אסור היה להפריע.</span
      ><span class="c8 c11"> </span
      ><span class="c4"
        >אם הפריעו לה לכתוב בבית, אז היתה קמה ונוסעת לכמה ימים לקרובים. שם היתה
        יושבת וכותבת.  
      </span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c4"
        >היא כתבה במשך היום בכל מיני שעות. לא היו לאמא שעות קבועות לכתיבה. בכל
        רגע פנוי.
      </span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c4"
        >אני זוכרת את אמא יושבת על יד שולחן הכתיבה שלה בחדר האוכל, שולחן קטן, לא
        גדול - וכותבת, בכל מיני שעות. אבל מיני השעות האלה היו בשעות היום. בלילה
        אמא לא כתבה. אני לא זוכרת את אמא כותבת בלילה ואפשר להבין, מפני שחיינו
        כולנו סביב מנורת נפט אחת קטנה, או בזמן המלחמה עששית של שמן, ואי אפשר
        לכתוב בתנאים כאלה.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c4"
        >אני חושבת שהכתיבה שלה היתה מאד אוטנטית לתקופה. בהחלט! העצבות מוצדקת,
        החיים היו מאד קשים. נוסף לתנאים שהיו שייכים לעבודה, ליצירה ההתחלתית. אז
        היו לנו מלחמות, אני כל ימי חיי זוכרת מלחמות! מלחמות, עם כל מה שקשור
        אליהן. מלחמת העולם הראשונה התחילה כשהייתי בת 15 אני חושבת, ואני זוכרת
        יפה שלא היה מה לאכול, ולא היה מה ללבוש, ולא היה מאור, לא היה כלום! חוץ
        מזה שאמא היתה... בצעירותה, אמרו לי שהיתה רוקדת הרבה, והיתה עליזה. אני לא
        יכולה להעיד על כך. והבינותי את זה כתוצאה ממה שעבר עליה באופן אישי, ועל
        התנאים הקשים בכלל. שני הילדים הראשונים נפטרו אצלה בגיל צעיר וזה בודאי...
        אנחנו יכולים להבין כהורים מה זה עשה לה, ולכן אני לא מתפלאה שכל זה מתבטא
        בכתיבתה, אבל זה בלי שום ספק השפיע.</span
      >
    </p>
    <p class="c5 c10" dir="rtl"><span class="c8 c12"></span></p>
    <p class="c5 c10" dir="rtl"><span class="c12 c8"></span></p>
  </body>
</html>`;

export default page;
