const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c18 doc-content">
    <p class="c4" dir="rtl">
      <a href="#fightForEquality">
        <span class="c7 c19 c2">מאבק לשוויון זכויות לנשים</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <a href="#educationForGirls">
        <span class="c7 c19 c2">חינוך לבנות</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <a href="#protectionOnWoman">
        <span class="c7 c2 c19">ההגנה על נשים נפגעות אלימות בבית</span>
      </a>
    </p>
    <br />
    <h3 class="c4" dir="rtl" id="fightForEquality">
      <span class="c7 c11 c2">מאבק לשוויון זכויות לנשים</span>
    </h3>
    <p class="c4" dir="rtl">
      <span class="c11 c1 c8 c16 italic"> הקטע הבא לקוח מתוך עמוד כתוב בכתב יד, הנמצא בארכיון הציוני. איננו יודעים מי כתב אותו אך אין ספק שזו עדות של מישהו/י שהכיר/ה את נחמה פוחצ&#39;בסקי:</span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c1"
        >...במלחמתה לזכות האשה פסעה נחמה פסיעות לא גסות, אבל בטוחות פסיעה אחר
        פסיעה, דרישה אחר דרישה, ותזכיר אחרי תזכיר אל ועד המושבה עד אשר עלה בידה
        דבר הבָחר האשה כחברה לועד המושבה. בשנת [1919]
      </span>
      <a href="/fromHereAndThere#womanChoose">
        <span class="c7 c1 c20 tooltip"
          >נבחרה נחמה עצמה לחברת הועד<span class="c11 c13 c1 c25 tooltiptext"
            >ראו פרוטוקול הישיבה בה הוחלט על זכות בחירה לנשים ובסופה אף נבחרה
            נחמה פוחצ&#39;בסקי ברוב קולות</span
          ></span
        ></a>
      <span class="c7 c11 c1"
        >והנצחון הזה גרר אחריו עוד נצחון. ועד המושבה ואח"כ המועצה המקומית הטילה
        על החבֵרה תפקידים אחראיים שונים. וכך אנו עדים לעבודה פוריה ומסֻעפת של
        נשי מושבתנו בכל ענפי חיינו. חברות פעילות בכל המוסדות. לבד הסניף לויצ"ו
        המתקים במושבתנו שמטרתה עזרה סוציאלית, רב חלקה של האשה בועדת התרבות
        במוזקה  בועדת החנוך ובכלל אין מוסד במושבתנו שאין האשה שמשתתפת בו כחברה
        פעילה. והשתתפותה נכרת ורבת תעלת...</span
      >
    </p>
    <br/>
    <p class="c3" dir="rtl">
      <span class="c7 c11 c21">אפרת בן כהן</span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c7 c1 c11"
        >היא היתה אישה עצמאית וראתה ביחס של הגברים לנשים השפלה. היא לא סבלה
        אי-שוויון בין גברים ונשים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c7 c11 c1"
        >המלחמות הגדולות שלה היו לזכות בחירה לנשים. היא חשבה שנעשה עוול לנשים
        וצריך לשנות. ראשון-לציון היתה המקום הראשון שבה זכו הנשים לזכות בחירה, אם
        אינני טועה.  </span
      >
    </p>
    <p class="c3" dir="rtl"><span class="c7 c11 c1">...</span></p>
    <br/>
    <h3 class="c3" dir="rtl" id="educationForGirls">
      <a href="/herArticles/moreOnTheGirlsQ">
        <span class="c2 c20 tooltip"
          >חינוך לבנות<span class="c1 c13 tooltiptext"
            >ראו גם מאמרה של נחמה פוחצ&#39;בסקי בנושא זה</span
          ></span
        >
      </a>
    </h3>
    <p class="c3" dir="rtl">
      <span class="c21 small-text">אפרת בן כהן: </span
      ><span class="c1">כשעברו התימנים לגור בצריפים, ארגנה </span
      ><span class="c13 c1">אמא</span
      ><span class="c7 c11 c1"> כתה לנשים, ואני לימדתי אותם בערבים.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c21 small-text">רות ארבל:</span><span class="c2 c5"> </span
      ><span class="c1"
        >אסתר עבדה אצל סבתא כשהיתה ילדה כבת שבע או שמונה. סבתא הבחינה שאסתר
        מוכשרת מאד, ורצתה שתלמד. כדי שלא תצטרך להתחיל מכתה אלף, לימדו אותה  סבתא
        ואמא בכל רגע פנוי. סבתא השפיעה על אביה (אברהם טביב – לימים חבר כנסת)
        שירשו לה ללמוד, וסבתא שילמה את שכר הלמוד. אסתר נכנסה לבית הספר ישר לכתה
        ב&#39;. בקיץ המשיכה לעבוד בבית סבתא וללמוד, כך שעם התחדש שנת הלמודים
        דילגה כתה והמשיכה בד&#39;.</span
      ><span class="c13 c2"> </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 small-text">דבורה:</span><span class="c5 c2"> </span
      ><span class="c1 c8 c24"
        >הרבה הורים הוציאו את הילדות מבית-הספר שילכו לעבוד בבתים. ... היו גם
        הורים שאמרו שמי שמלמד את בתו תורה מוציא אותה לתרבות רעה</span
      ><span class="c2"> ...</span><span class="c5 c2"> </span
      ><span class="c11 c1 c8 c23"
        >פעם אני זוכרת היא פנתה לאמא שלי ואמרה "עוידה, לדבורה אל תדאגי, תני לה
        ללמוד, אל תוציאי אותה מבית הספר, אני אשלם בשבילה". וכך היה. היא שלמה
        לבית הספר כל מה שמגיע ממני כל השנים. היא פשוט הצילה אותי. ואני לא אשכח
        לה לעולם".</span
      >
    </p>
    <br/>

    <h3 class="c14 title" dir="rtl" id="protectionOnWoman">
      <span class="c2 c8">ההגנה על נשים נפגעות אלימות בבית</span>
    </h3>
    <p class="c14 title" dir="rtl">
      <span class="c1 c8 small-text">נאדרה סֵ&#39;בַהי: </span
      ><span class="c2">דוד רצבי רב עם אשתו לולו </span><span class="c1">(</span
      ><span class="c1 c17">קרי הכה אותה</span><span class="c1">)</span
      ><span class="c2"
        > היא הלכה לסבתא שלך. נתנה לה הכל. היא לבד והוא לבד. מסדרת לה עבודה,
        נתנה לה תנובה </span
      ><span class="c1">(</span><span class="c1 c17">תנובת השדה, אוכל</span
      ><span class="c1">)</span
      ><span class="c2"
        > לא גרו יחד אולי שנה, אולי שנתיים. בסוף עשתה שלום ביניהם. חיו יחד עד
        עכשו.</span
      >
    </p>
    <p class="c14 title" dir="rtl">
      <span class="c2"
        >שלמה לא התנהג יפה לנעמי. אמרה, קחי את הבגדים שלך ובואי...</span
      >
    </p>
  </body>
</html>`;

export default page;
