const preTitle = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type"/>
  </head>
  <body class="c0 doc-content">
    <p class="c2" dir="rtl">
      <span class="c1 bold">עשרת הסיפורים הבאים</span
      ><span class="c3"> כלולים בספרה הראשון של נחמה פוחצ׳בסקי</span
      ><span class="c1"> </span><span class="c5 bold">ביהודה החדשה</span
      ><span class="c1"> </span
      ><span class="c3">אשר יצא לאור ביפו בשנת <span class="modal-button" id="page-modal-button-2">תרע"א</span> – 1911 בהוצאה עצמית.</span>
      <span class="c3">הספר יצא שוב בשנת <span class="modal-button" id="page-modal-button-3">תר"ץ</span> - 1929 בהוצאת הדים, תל אביב.</span>        
    </p>
  </body>
</html>`;

export default preTitle;
