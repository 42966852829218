const page = `
<html>
  <body class="c4 doc-content">
    <p class="c5 c8 italic" dir="rtl">
      <span class="c6">   </span><span class="c12 bold">האור</span
      ><span class="c14"> כ"ט חשון תרע"ב</span
      ><span class="c1">  20.11.1911</span>
    </p>
    <br/>
    <h3 class="c3 c7 text-center" dir="rtl" id="h.2ai4aiev8gb2">
      <span class="c0">ירכתוני-בקֹרת</span>
    </h3>
    <h3 class="c3 c7 text-center" dir="rtl" id="h.o3lmoid83cc9">
      <span class="c0">שכר סופרים</span>
    </h3>
    <p class="c3 text-center" dir="rtl"><span class="c6">א.</span></p>
    <p class="c10 c8 text-left" dir="rtl">
      <span class="c6">ביהודה החדשה</span>
    </p>
    <p class="c10 c8 text-left" dir="rtl">
      <span class="c6">קבץ ציורים מאת</span>
    </p>
    <p class="c10 c8 text-left" dir="rtl">
      <span class="c6">נחמה פוחצ'בסקי</span>
    </p>
    <p class="c8 c10 text-left" dir="rtl">
      <span class="c6">יפו התרע"א</span>
    </p>
    <br/>
    <p class="c5" dir="rtl">
      <span class="c6"
        >"ביהודה החדשה" שם זה מושך אותך, מקסים אותך. קבץ ציורים מחיי הארץ, ועל
        ידי בת הארץ. "יהודה החדשה" לעֻמת "יהודה העתיקה ."‬חיים חדשים מתרקמים
        בארץ, דבר גדול הולך ונוצר לעינינו, בנין-עולמים הולך ונבנה. רוצה אתה לתפס
        חוטים אחדים מרקמת-חיים זו, רוצה אתה להכיר בעמל הבונים, לחוש מצער -
        היצירה, הרי קבץ ציורים לפניך, ציורים מהחיים החדשים אשר בארץ, כי על כן שם
        הקבץ - "ביהודה החדשה"...</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c6"
        >פתח הספר וקרא: "האבדות" - מעשה בתופרת אחת שעליתה הקטנה מול מצודת ציון,
        מת עליה בעלה, מת עליה ילדה יחידה, מתה גם היא" ...‬תחת האתרוג" - מעשה
        באדם ישר שעבד, עבד, חפץ להתעשר ולהמציא חיי־שפע לאשתו, מתה רעיתו, הסתבך
        בחובות, מת גם הוא באבוד עצמו לדעת" ...‬סימה רסקין" - אישה צעירה ויפה, אם
        לילדה קטנה, קלקלה הפעוטה את קבתה בבואה לארץ ישראל, נתקלקלה הקבה עוד
        הפעם, והילדה מתה - וכמעט שמתה גם האם... "גוססת" — שם זה אינו דורש באור.
        סצליה אלברשטם שכבר "שבעה די תענוגים בחיק מעריציה הרבים","‬רצוצת גוף
        ונפש,"‬ מחכה לידידה האחרון, מתגברת על החיים בהזיות משל מורפיום, רואה
        מחזות בהלה והיא גוססת, מתה" ...‬רמה" - תימנית בעלת עינים שחורות וטובות,
        מת עליה סעדיה בעלה, ואחריו מתה – כמובן - גם היא ...‬עד כאן המתים, ומכאן
        ואילך החיים - עקרונית עיפה ויגעה, חלשה ודוויה ...‬ותימנים נאנחים ובוכים,
        שבעי עמל ורגז...‬ הרי סך-הכל של הציורים אשר בקבץ החדש. ומה ? וכי לא
        "ביהודה החדשה" אתה נמצא?...‬</span
      >
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        > מה ראתה הגברת פוחצ'בסקי לקרא שם זה על ציוריה, בשעה שברובם אין כל קשר
        ויחס בין הגבורים וארץ־ישראל ?  התופרת ש"בהאבדות" יכלה לאבד את בעלה ואת
        ילדה, ולמות גם היא - במוסקבה ולאו דוקא בירושלם. הילדה של "סימה רסקין"
        יכלה גם כן למות בכל מקום אחר. השיחה על דבר בר-כוכבא אינה מספקת עד כדי
        להשרות שכינת א"י על ספור זה...‬ אפילו המת "תחת האתרוג,"‬ אין נבלט בציור
        יחוסו אל הישוב, אין נראה הקשר החי בינו ובין הארץ. וסצליה אלברשטם, זו
        שהחליפה את מאהביה עשרת מונים שם בחוץ-לארץ, אותה בודאי לא היה צריך להביא
        אל ארץ־ישראל, רק בכדי שנראה בגסיסתה...‬</span
      >
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        > נושא זה, המות, חביב כנראה על הגברת פוחצ'בסקי. לו היה לה לכל הפחות
        הכשרון לתאר סופו של אדם באמנות מיוחדת, היינו עוד נכנעים ומקבלים. לשם
        אמנות הכל מֻתר. אבל להמית סתם אנשים, לשכל אמות ואבות,‬ ליתם בנים ובנות,
        להרג נפשות בלי כל סבה - אכזריות היא, גברתי פוחצ'בסקי!</span
      >
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        >אפשר מאד שכל מקרי־המות האלה הם מעשים שהיו, וזכר המת אשר על אחד הציורים
        יוכיח. אבל למה זה נקרא הספר בשם "ביהודה החדשה" ולא "בבית העלמין"? ושנית,
        אפילו מקרי־מות אמתים אינם יכולים לשמש עדין חמר לציורים, ועוד לספר בפרט
        פרטי מקרה נורא הידוע לכל, כמו זה "שתחת האתרוג."‬ אסונות-משפחה לא נתנו
        לטפל בהם ברבים... ‬</span
      >
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        >סגנון הגברת פוחצ'בסקי דורש עוד תקון רב. בנין המשפטים לקוי. מתאמצת היא
        לחקות את הסגנון ה"מופתי" המקובל עכשו בספרותנו היפה, ואין הדבר עולה בידה,
        אין היא שולטת דיה בשפה. בוחרת היא דוקא במלים, כגון: לפוש, שלהי, קמעא,
        זאטוטים, רחימא, חשל, הלה - ועוד, שאינן מתאימות למקומן, ואפשר היה להחליפן
        במלים יותר פשוטות ורגילות. המבטאים וחמשפטים המשבשים רבים מאד: "לילות
        הנדודים תששו את כחה" (התישו(. "למה תדיר את בוכה"</span
      ><span class="c2"> –"כשהוא בריא ויפה </span><span class="c14">עם</span
      ><span class="c2"
        > לחיים סמוקות (ולחייו סמוקות). –"אהבה שחרית". –"גורפת את האשר מסביב".
        –זמירה'לי </span
      ><span class="c14">לבד</span
      ><span class="c2"> עם יפיה וחכמתה, עם ורד פניה".</span
      ><span class="c6"> צחוקה </span><span class="c1">התגלגלה והתגלה</span
      ><span class="c6">". —."‬הרוח חודר דרך חתך החלוק־שמלה ומחלק על </span
      ><span class="c1">לבה הרוח</span
      ><span class="c6"
        >" (לב רוח .(?‬- "הירח משדר למטה מבטים."‬ - "מכף רגל ועד ראש לבשו אותם"
        (הלבישו</span
      ><span class="c2">) –"אחרי שנה </span><span class="c14">לבשתהו</span
      ><span class="c2"> אמו טלית קטנה, </span
      ><span class="c6">(הלבישתהו".(‬מסכנת" (מסכנה.(  - "ולא ידעה </span
      ><span class="c1">ממה</span
      ><span class="c6"
        > להתחיל,"  ועוד ועוד כל אלה הן שגיאות שגורות על פי הילדים. והן נמצאות
        לרב בקבץ הציורים אשר לפנינו. נחוץ לשרש אחריהן.
      </span>
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        >עד כאן חסרונות. יש גם מעלות. רשם יפה עושים הציורים מחיי התימנים, אשר
        בחלק השני של הקבץ. באלה ידעה המספרת לתפס רגעי־חיים יפים לתת טפוסים כמעט
        שלמים, המענינים אותנו בחדוש שבהם, במהלך־מחשבותיהם המיֻחד, בהשקפת-עולם
        שלהם, במאוייהם ותביעותיהם. יש קוים </span
      ><span class="c1">נפשיים</span
      ><span class="c6"
        > יפים בציורים "רומה",‬ "גלות", "גוי קדוש"",‬פעמים."‬ ביחוד עלה הציור
        "גלות": מוסה הלוי אוהב וההובתו לקחה מידו ותהי לאחר. קובל התימני על "ארץ
        ישראל שהיא אשמה בכל צרותיו". למה נדד מתימן? שם ידע חיים. וכאן הוא
        "בגלות". "עבד נמכר לאחיו". "עובד עבודת פרך". גם  "צדקה" אשר פה אינה ולא
        כלום: פתקאות... היקרות רבה. ובמה יקנה לו "ולדה"? הוי, גלות! הוי,גלות!
        - מקונן הוא. אבל גם בגלות צריך לחיות. ובלי "עזר כנגדו" אי אפשר... מנחם
        הוא את נפשו בתקוה: "השם ירחם!  גם ילדה יזמין לו."‬  שלשה נפוליונים כבר
        נמצאים בכיסו; והוא הולך אל עבודתו, מעדרו על שכמו, והוא פוסע ומרנן:
      </span>
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6">וילך איש מבית לוי,ויקח בת לוי...</span>
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        >                           חבל מאד על הציורים הקודמים מחיי ה"אשכנזים",
        ‬כביכול, שהם דלים בתכנם ומשונים ב"סוף" הנמהר: מות, מות, מות! יוצא מהם
        הציור "החרטה,"‬ שהוא בעצם לא רע. מתארת בו שרה לאה העקרונית, אכרה טובה,
        בעלת־בית חרוצה, אם כי עיפה ויגעה, נאנחת וגונחת מרב עבודה. אבל טפוס חי
        לפנינו. צר מאד שהמחברת לא הרבתה בכמו אלה: ציורים מחיי־העבודה במושבות,
        חיי-הכרם, חיי השדה - במנעמיהם ובממרוריהם, ובטרדותיהם.</span
      >
    </p>
    <p class="c5 c8" dir="rtl">
      <span class="c6"
        >אולי היתה הבקרת קשה בראשיתה, אבל בקרת נכונה היא רק מתקנת ומועילה,
        מכאיבה ומרפאה. וכבר אמר בואלו הצרפתי:</span
      >
    </p>
    <p class="c3 text-center" dir="rtl">
      <span class="c6">בקשו לכם ידידים המבינים לבקר.</span>
    </p>
    <p class="c3 text-center" dir="rtl">
      <span class="c6">אל תפנו לחונפים האוהבים לשקר,</span>
    </p>
    <p class="c3 text-center" dir="rtl">
      <span class="c6">מרמה בפיהם. לעג – כל מלה...</span>
    </p>
    <p class="c3 text-center" dir="rtl">
      <span class="c6">בחרו בעצה, ולא בתהלה!...</span>
    </p>
    <p class="c10 c8 text-left" dir="rtl">
      <span class="c6">י. אביזהר</span>
    </p>    
    
  </body>
</html>`;

export default page;
