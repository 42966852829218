const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c6" dir="rtl">
      <span class="c1"
        >הסודר השחום שעל ראש טוביה, תימנית צעירה, גוצה ושחרחרה, פתוח לארבע
        קצותיו והוא מתבדר למטה ומתלבט בשולי השמלה הורדה של שבת, שהיא לבשה לכבוד
        לכתה ליפו שלשום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עיפה טוביה ורצוצה עתה בשובה משם ונוגה היא נגררת אחרי מלוה, תימני גבוה
        ורחב עם פנים בריאים ועינים קטנטנות. לבוש הוא חזיה מהֻפכת ופרומה, אשר
        משמשת לו מעיל למכנסיו המטלאים, זוג נעלים קרועים, קשורים במשיחה ומתלבטים
        על כתפו. תרבושו המפֻחם מכסה רק חלק קטן מראשו המתֻלתל ומראהו כלו היה מטיל
        אימה, אלמלי העינים הקטנות הללו, המביעות טוב לב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא פוסע במהירות וטוביה מפגרת בדרכה. – לוחץ לבה, רגליה כואבות והיא
        צועדת בקושי ומתחילה חוששת שתפל כאן לרגלי החולות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יחיא, יא-עמי, חכה מעט! – קוראת לו טוביה בשארית כחה מרחוק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא הפנה שכמו, עמד עד שהיא נגשה אליו ויקבל ברצונו הטוב את בקשתה לנוח
        קמעא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הם ישבו על הר החול, שהבהיק מול פני השמש בגלי זהבו המתפתלים וזוחלים חרש
        למטה, אל הסוּף שעל פני המעין הסתום. הסתכל יחיא במשחק זה ופניו נהירין
        כשיחד עם גרגירי חול יורד רוח לטיף מן הים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וטוביה, – היא כבשה פניה בחול, שתקה, שתקה ופתאם התפרצה בבכי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אל תבכי, יא ולדה, אל תבכי! – משדל אותה יחיא – בודי רצונו של הקב"ה היה
        בכך שתפרדי מבעלך, ואת, ולדה, צריכה לקבל את הדין מבלי הרהר אחריו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לקבל?... אבל כלום יש צדק בדין זה, שיוריד את חיי שאולה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הסי, שטיה, אל תפתחי פיך לשטן! – גוער בה יחיא בנזיפה. צללים קלושים
        יורדים מן ההר וזוחלים ישר אל השדה. חטה צנומה עומדת כאבלה ובחשאי כופפת
        ראשה מול הרוח המתלהלה בשבלותיה העלובות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שמאלה משתטח שדה שמשומין. גם הלה צנום ורזה ואבל, כאלו רוצה להסיח את מר
        לבו: לא הוא אשם במראהו הכחוש, אלא שכנו הרע, החול, שמדלדל את כחות הקרקע
        מסביב! – –</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ההולכים ירדו מן ההר, וימשיכו דרכם הלאה בשביל הצר שבין החולות והשדה.
        יחיא אינו ממהר יותר בהליכתו, אלא פוסע לאטו לצד טוביה וזוקף אזנו
        בהתענינות לספורה, שהיא סחה מתוך התרגשות: –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ילדה קטנה היתה בעת שדודה מכר אותה לבן אחותו, למימון. מתחילה היא לא אהבה
        את הנער ותבך בחשאי על הארוסין שלה, אבל מה היה לה לעשות, ליתומה עזובה בלי
        אב ואם, שהדוד אספה ברחמיו? – כלום יכלה להתנגד לרצונו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי חתונתם התחיל לבה גס בו, במימון, וכשגדלה נקשרה אליו באהבה לאסונה...
        כן, זה היה האסון שלה, הואיל ובו נכנסה כבר רוח השטות לנסע לארץ ישראל! רצה
        הוא אמנם לנטלה אתו, אלא שהיא מאנה בחששה מפני עולם זר, מפני מדינת ים כה
        רחוקה, רחוקה מתימן! וכלום היו אז עולים לא"י כמו האידנא, – עדות, עדות של
        מאות אנשים? הרי אחד היה מימון בעירם שהתעקש ונסע. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נסע הוא לכאורה על מנת לחזר, אבל לא עברו ימים מועטים והוא שלח לה את
        הג&#39;ט ע"י שדר שחזר מירושלים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד הגט בידי הרב והיא בוכה וצועקת כמטרפת על האסון החדש שאירע לה. –
        היאך? מימון גרש אותה, בעת שהיא כה אוהבת אותו, עד מות אוהבת? והניר ההוא
        שהיא תקח מן הרב יפריד אותה ממנו לנצח, לנצח?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סבורה היתה שדעתה נטרפת עליה והדוד שלה התל בה, צחק לדמעותיה ואפשר שבלבו
        שמח על המקרה למכר אותה שוב, אבל לדאבונו נפסל הגט ע"י הרב. הלה עין בו טרם
        מסרו אותו לידה ומצא את פסולו בזה, שמימון חתם עליו ביום השני
        לכתיבתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הדוד פנה אז במכתב לאחד הרבנים התימנים שבירושלים ובקש שישפיעו על מימון
        לשדר גט אחר לאשתו היושבת עגונה. תשובה על מכתב זה הגיעה אחרי חצי שנה
        ממימון עצמו ותשובה זו הפיחה בה רוח חיים חדש.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מימון כתב, שהוא מתחרט בעיקר על הג&#39;ט ואם הלה פסול, הוא רואה בזה אצבע
        אלהים. לא רצה השם כי המזבח יוריד דמעות על מימון כהן, בגרשו את אשתו
        הראשונה... וסים הוא את המכתב בדברי תנחומים: – הוא מקוה שעוד יפגשו וישבו
        יחד בעזרת השם...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושנה רדפה שנה והיא ישבה וצפתה מאין תבא העזרה. קשות היו השנים העגומות
        ההן!  היא התפרנסה בדחק מקליעת סלים. כל הימים גוחנת על עבודתה ובלילות, –
        הוי איזה געגועים נוראים היו תוקפים אותה!... רואה היא בחלום את מימון שלה,
        ודומה שהוא רומז וקורא לה. היא מתפרצת אליו ומתעוררת בצעקה. לנום אחרי זה
        אינה יכולה יותר, תוקפים אותה היסורים והכאב שבלב והיא הולכת ומתמוגגת
        בצערה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ופתאם בקע האור בחייה האפלים: – העדה התכוננה לעליה לארץ ישראל! הדוד נוטל
        גם אותה ולאשרה אין קץ. – הריה נוסעת לאותה המדינה הנפלאה אשר מימון שלה
        נמצא בה, – מי ידמה ומי ישוה לה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי מי חשב אז כי עוד יגרע מזלה ואסון חדש יבא עליה לאבד את
        עולמה?...</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*  *  *</span></p>
    <p class="c4" dir="rtl">
      <span class="c1"
        >שני ההולכים הגיעו עד גבול המושבה ונכנסו בין כרמיה. גדרי הצ&#39;בר
        הגבוהים עכבו מפני הרוח המערבי ולפיכך גדל כאן החום מאד. נוטפת הזעה על פני
        טוביה, נוטפת והיא בקושי פוסעת בתוך העפר העמק. שתקה היא שעה קלה עד בואם
        בצללי אקליפטוסים גבוהים וכאן התחילה שוב סחה: –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– – – מלאה היתה רגשות של שמחה ברדתם על חוף יפו. היא רצתה תכף ומיד לנסע
        למימון ירושלימה, אלא שהדוד עצר בה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– חכי מעט, ולדה! – אמר בחשיבות, – אנו נודיע קדם למימון את דבר בואנו,
        ואם הוא עדין לא הסיח את לבו ממך, בודאי לא יאחר מבוא לכאן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכך היה. בערב שבת הראשונה התיצב מימון לפניה בכל יפיו... היא אמנם לא
        הכירתהו בהיותו לבוש כחוג&#39;ה ממש – בגדים נחמדים עם שעון ושרשרת בחזיתו.
        נבוכה היא עמדה ובכתה משמחה והוא הביט עליה בעינים כה טובות, מלבבות, עד
        שנפשה יצאה באהבתה... הוי, אב שבשמים, מדוע הפריעו אנשים רעים את
        אשרה?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כהן, גרושה!" קטרג השטן. אבל איזו גרושה היתה אם הגט הראשון היה פסול
        מעיקרו והיא לא קבלתהו אפילו? ומדוע אסורה היתה למימון, מדוע?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רוצה יחיא להראות בקיאותו בתורה ולבאר את צדקת הדין, אבל טוביה
        קובלת:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא, לא! – אין צדק בדין ואין בו רחמים!... כלו הרחמים בשביל אדם שנולד
        בלי מזל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וטוביה סחה אחרי כן איך שהשפיע כל הדבר על מימון. – מתחילה הוא צחק –
        היאך? אשתו אסורה לו? וכשראה כי באמת ובתמים שמים מוקשים על דרכו הוא התרגז
        וקצף וכעס עד שנפל חולה בבית הדוד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הם כבר גרו אז במושבה והיא מצאה לה את המקום אצל גברת חוין, זו היפה,
        הנחמדה והטובה לה מאם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכשחלה מימון היא התחילה מסתלקת בכל שעה, בכל שעה מבית גברתה, עוזבת את כל
        העבודות ורצה לבתי התימנים, למימון שלה; עושה לו מים חמים, מניחה תחבשות
        קרות על ראשו כפי מצות הרופא, מגישה את הרפואה שנתן לו... והן, התימניות
        שבבית, מקללות אותה, מחרפות ומגדפות בלי הרף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– חצופה שכמותה, – גרושה מכהן ואין לה בשת הפנים להכנס לד&#39; אמותיו
        ולשמשו! – חורקות הן שִניהן עליה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והדוד גם הוא מתרעם ומתכעס ואלמלי הבושה בפני האשכנזים היה בודי מכה אותה
        ומגרשה ככלב מן הבית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחרי היסורים הללו שהיא סובלת אצל התימנים היא ממהרת לגברתה ולעבודתה;
        אבל הגברת אינה מראה לה את פניה הצוחקות, ויודעת היא טוביה, שחטא חטאה נגדה
        בעזב עליה את עבודות הבית ולבה מתפלץ מבושה ומצער.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא עובדת ובוכה. בוכה עד שהגברת סולחת לה ומתחילה להפיג צערה בדברי
        תנחומים, שאם דבר משפטה יבא לפני הרב דיפו, הלה בודי ובודי ימצא לה התר! –
        ויש שהיא האמינה לדברי תנחומים וקותה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועתה הוי לנפשה, הוי לחייה!... הרי הכל הכל נאבד, נגמר! – את מימון הכריחו
        לגרשה היום שנית, לגרש פעמים את אשתו, שהוא כה אהב!... הוי השם! הרי רק
        למות נשאר לה כעת, שאולה לרדת ותו לא! – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        > הגיעו טוביה ומלוה עד רחוב המושבה. היא מקנחת בקצה סודרה את דמעות עיניה,
        ובלב דופק ורגלים כושלות מתרוממת בהר בין צללי התותים ומתקרבת לבית
        גברתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">נכנסה, צנחה בפרוזדר על כסא והתפרצה בבכי הסתרי.  </span>
    </p>
    <p class="c7" dir="rtl"><span class="c3"></span></p>
  </body>
</html>`;

export default page;
