const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <p class="c9 italic" dir="rtl">
      <span class="c0 bold">השקפה</span
      ><span class="c2">   א אדר ב אתתל"ט, 4.3.1908   - במדור </span
      ><span class="c0 bold">חדשות בספרות אמנות ומדע</span
      ><span class="c2 c5">, מופיעה בין היתר החדשה באה: </span>
    </p>
    <p class="c9" dir="rtl">
      <span class="c3">בענין התנפלו ממש קוראי ארץ ישראל על ה"</span
      ><span class="c3">שאלות הגלויות</span
      ><span class="c3">" של נחמה פוחצבסקי.</span><span class="c11"> </span
      ><span class="c3"
        > זו אחת הנשים המצֻינות שבנשותינו בארץ ישראל: משכלת, נבונה אהובה ונחמדה
        מאד ויודעת היטב עברית וכותבת. לצערנו לא נפל בגורלה לפתח את כשרונותיה
        ולהפך הלכה ונשכחה ונחבאה אל כליה ולא נשמע קולה ולא מלאה פקידתה </span
      ><span class="c2">[תפקידה?]</span
      ><span class="c3"> אשר יכלה למלא בספרותנו העניה, העלובה. </span
      ><span class="c3 c10"> דברי אפשטין בהמחברת הקודמת
       העירוה הפעם לענות עליהם ותבאר במאמר קצר שכתוב בלשונה העשירה וסגנונה
        יפה היטב כי היהודים הביאו אך תועלת להפלחים שכניהם.</span
      >
    </p>
    <p class="c6" dir="rtl"><span class="c5 c8"></span></p>
  </body>
</html>`;

export default modal;
