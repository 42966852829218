const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <p class="c2 italic" dir="rtl">
      <span class="c9 c1"
        >יצחק אפשטיין היה ידוע בין היתר כמורה לעברית, מנהל בתי ספר במושבות הגליל
        ומומחה למבטא הגלילי. משרתו הראשונה כמורה היתה בבית הספר החלוצי לבנות
        צפת, שנוסד אז, ב-1890. הוא ויחיאל מיכל שלמה זלמן פוחצ&#39;בסקי עבדו
        ולמדו יחד בשנותיהם הראשונות בארץ – 1885 עד 1888 ונשארו ידידים כל ימיהם,
        הם ומשפחותיהם.</span
      >
    </p>
    <p class="c2 italic" dir="rtl">
      <span class="c1"
        >מאמר זה של אפשטיין  פורסם בעתון השִלחַ (אותו ערך ביאליק),  במחצית השניה
        של 1907. בעיתון הופיעה ההערה הבאה: </span
      ><span class="c5"
        >הרצאה זו הרצה המחבר באספת "עבריה" עוד בימי הקונגרס השביעי בבאזל</span
      ><span class="c1"> [1905]</span
      ><span class="c5"
        >, אבל חושבים אנו, שהענין, שעליו דנה ההרצאה, לא נתישן גם עתה, ואולי דוקא
        עתה, כשנתרבתה העבודה המעשית בא"י ראוי לשים אליו לב. אין אנו מסכימים לדעת
        המחבר הנכבד מכמה צדדים ובקרוב תבוא ב"השלח" תשובה על הרבה מדבריו
        (העורך).</span
      >
    </p>
    <p class="c2 c6" dir="rtl"><span class="c9 c12 c15"></span></p>
    <h3 class="c13 text-center" dir="rtl">
      <span class="c9 c4 c12">שאלה נעלמה</span>
    </h3>
    <p class="c13 text-center" dir="rtl"><span class="c9 c4 c12">א.</span></p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >בין השאלות הקשות, הקשורות ברעיון תחית עמנו על אדמתו, יש שאלה אחת, שהיא
        שקולה כנגד כולן: שאלת יחוסנו אל הערביים. שאלה זו, שבפתרונה הנכון תלויה
        תקומת תקותנו הלאומית, לא נשכחה, אלא נעלמה כליל מן הציוניים ובצורתה
        האמתית אין לה כמעט זכר בספרות תנועתנו. אמנם, בשנים האחרונות נמלטו מפי
        סופרים אחדים דברים מקוטעים בנידון זה; אבל אלה היו טענות הארציים, שבאו
        להוכיח את אי האפשרות של כל פעולה ממשית בארץ, או בירור התנועה הערבית.
        הציוניים הנאמנים לא נגעו עד עתה בשאלה, מה צריך להיות יחוסנו אל הערביים
        כשאנו באים לקנית קרקעות בארץ-ישראל, ליסד מושבות ובכלל לישב את הארץ.
        בודאי לא נתכוונו הציוניים לעבור בשתיקה על אחד מעיקרי תנאי הישוב; הם לא
        הרגישו במציאותו מחוסר דעתם את המדינה ואת יושביה ועוד יותר – מהעדר חוש
        עממי ומדיני.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        העובדה, שאפשר היה להסיח את הדעת משאלה יסודית כזו ושאחרי שלשים
        שנה של עבודה ישובית צריך לדבר עליה כעל חקירה חדשה, - עובדה מעציבה זו
        מוכיחה למדי את קלות-הדעת השוררת בתנועתנו ומראה, שעדיין אנו מרפרפים על
        פני הענינים ואין אנו יורדים אל תוכם ואל עיקרם.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        מיום שצמחה התנועה הלאומית ועד עתה לא חדלו העסקנים הציוניים
        מלהתענין בסדרי הארץ ובחוקיה, בעוד ששאלת העם היושב בה, עובדיה ואדוניה
        האמתיים, עדיין לא נתעוררה לא במקום המעשים ולא במדרש. כולנו ראינו את
        הקיסם הבולט ולא הרגשנו בקורה הצפונה. סדרי הממשלה, החומרות הנוהגות בקנית
        קרקעות ובבנין בתים, אסור כניסת היהודים וכיוצא בזה פוגעים בכל הבאים לא"י.
        בשעה שמצד הערביים אין, לכאורה, מכשולים מרובים. ואם אחינו בארץ לא הבינו
        את כל חומרת השאלה, כל שכן שהוא לא עלתה על דעת הציוניים הרחוקים ממקום
        המעשה. לכל עניני ארצנו אנו שמים לבנו, על הכל אנו דנים ומתוכחים, את הכל
        אנו מהללים ומחללים, אך דבר אחד של מה בכך שכחנו: כי יש בארץ חמדתנו עם
        שלם, שנאחז בה זה מאות בשנים ומעולם לא היה בדעתו לעזבה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        זה כמה וכמה שנים אנו שומעים, כי מספר יושבי הארץ עולה לשש מאות
        אלף. נניח, שהמספר הזה נכון הוא, ואם נחסר ממנו את שמונים אלף היהודים יצא
        לנו, שבארצנו נמצאים עתה יותר מחצי מיליון ערביים, שכשמונים למאה מהם חיים
        אך על עבודת-האדמה ומחזיקים בכל הקרקעות המוכשרים לאכרות בלי התקנה קודמת.
        כבר הגיעה השעה לשרש את הדעה המשובשת, שנתפשטה בקרב הציוניים, כי בא"י יש
        אדמה בלתי-נעבדת מחוסר ידים עמלות ומעצלות התושבים. אין שדות שוממים, ולהפך
        כל פלח משתדל להוסיף על חלקתו מאדמת הבור הסמוכה לה, אם אינה דורשת עבודה
        יתרה. בקרבת הערים חורשים גם את מורדי ההרים המשופעים ובסביבות המושבה
        מטֻלה זורעים הפלחים העניים, כמו בלבנון, בין הסלעים ואינם מותירים בהר אף
        אמה על אמה. ובכן, כשאנו באים להאחז בארץ, הלא מתעוררת מיד השאלה: מה יעשו
        הפלחים, אשר את שדותיהם נקנה אנחנו?</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        את הקרקעות אנו קונים, על הרוב, מאת בעלי האחוזות הגדולות, שתפשו,
        הם או אלה שקדמו להם, את אדמתם במרמה ובעושק ומחכירים אותה להפלחים,
        ולפעמים מאת הכפרים המוכרים חלק מנחלתם. הפלח החוכר אינו גר על האדמה
        החכורה, אלא הוא תושב קבוע, שאינו משנה את מקומו, ויש חוכרים פלחים,
        שאבות-אבותיהם כבר עבדו את השדות החכורים להם, בני-בניהם. בנוהג שבארץ,
        האחוזה עוברת מרשות לרשות והחוכרים נשארים במקומם. אולם כשאנו קונים נחלה
        כזאת, אנו מרחיקים מעליה את עובדיה הקודמים לגמרי. אמנם לא נשלחם ריקם, שלם
        נשלם להם את החורבות ואת הגנים בעין יפה, ובכלל לא נקמץ בזהובים בימי
        ה"פִטור". מנקודת-המבט של הצדק המקובל והיושר הרשמי צדיקים גמורים אנחנו,
        הנכנסים לפנים משורת הדין. אבל, אם לא נרצה לרמות את עצמנו במוסכם, הלא
        נודה, שהשלכנו אנשים מסכנים מקנם הדל ושברנו את מטה-לחמם. אנה יפנה המודח,
        שאין לו אלא מעט כסף? אמנם, המושבה העברית ממציאה לו לפעמים עבודה ששכרה
        מרובה מהכנסת האכרות הדלה על האדמה החכורה; אבל, ראשית, הלא אין אנו יכולים
        להתחייב, שנספיק לו עבודה בקביעות, ושנית, בזאת נדע לעשות, כי כשאנו נותנים
        לפלח עבודה במושבה המיוסדת על אדמתו, אנו נותנים לו את היכולת לבלי הפרד
        מעל האדמה, שגדלתהו וכלכלתהו מיום הִוַלדו והוא מוסיף להביט עליה גם עתה
        כעל נחלתו, שנעשקה לפי שעה ע"י זרים. העבודה, שאנו נותנים לערבי, לא תחשב
        לעיניו לעולם לכופר על השדה, שהוצא מתחת ידיו; את הטוב יקבל ואת הרע לא
        ישכח.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        בכלל טועים אנו ביחס אל עם גדול, תקיף וקנא טעות גסה בתורת-הנפש.
        בשעה שאנו מרגישים את אהבת-המולדת בכל עזוזה אל ארץ אבותינו, אנו שוכחים,
        שגם לעם החי בה עתה יש לב רגש ונפש אוהבת. הערבי, ככל אדם, קשור הוא
        במולדתו בעבותות חזקים. והרי יותר שתשפל מדרגת התפתחותו של האדם ויותר שיצר
        חוג השקפתו, יותר יתחזק הקשר המחברו אל ארצו ואל מחוזו ויותר יקשה עליו
        להפרד מכפרו ומשדהו. המושֹלם לא יעזוב את ארצו, לא ינוד למרחקים; מחובר הוא
        אל טבע מולדתו במוסרות שונים, שאחד מהם יקר לו ביחוד – קברות אבותיו. כדי
        להבין את כל עומק הרגש הזה צריך לדעת, כמה מעריצים בני-הקדם את מתיהם, כמה
        הם מרבים לבקר את קבריהם של אלו ומשתפים אותם בכל מאורעות חייהם, בשמחתם
        וביגונם. עוד היום תצלצל באזני הקינה, שנשאו ערביות ביום שעזבו משפחותיהן
        את הכפר ג&#39;עוני, הוא ראש פנה, ללכת ולהתישב בחורן אשר מעבר לירדן
        מזרחה. הגברים רכבו על החמורים והנשים הלכו אחריהם הלוך ומרר בבכי, וימלא
        העמק קינתן. לרגעים עמדו ונשקו את האבנים ואת העפר.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        שאלת הקניה עומדת, במדה ידועה, במקומה גם אם הפלחים בעצמם מוכרים
        חלק מאדמת הכפר. אמנם, בצר לאכר, בהציק לו עול החובות, שנצטברו עליו
        כשנצטרך לשלם את המסים הכבדים, הוא מחליט ברגע של יאוש (ולפעמים לא בלי
        השפעת הפתוי של זקני-הכפר, שקבלו מנה יפה) למכור את שדהו; אבל הממכר הזה
        משאיר בלבו פצע שלא ירפא ותמיד יזכור את היום הארור, שבו נפלה נחלתו בידי
        זרים. ידעתי פלחים, שאחרי מכרם את אדמתם עבדו הם ונשיהם אצל היהודים ומשכרם
        המרובה על הוצאותיהם המועטות חסכו כסף. כל זמן שהשתכרו בשפע שמו מחסום
        לפיהם, אולם כאשר אך פסקה העבודה התחילו להֵרגן על היהודים וגם לערער על
        הקניה...</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c5"
        >        האמנם נוכל לבטוח בדרך רכישת-קרקעות אשר כזו? היצלח, הנאות הוא
        למטרתנו? מאה פעמים לא. בני העם, שהשמיע בפעם הראשונה את האומר "והארץ לא
        תמכר לצמיתות" והגביל את זכויות הקונה לטובת העובד, אינם צריכים, אינם
        יכולים להפקיע את אדמתם מידי עובדיה, שהתישבו עליה בתום-לבב. לא נסח </span
      ><span class="c1">[?]</span
      ><span class="c3"
        > מעליה את האנשים, שהם ואבותיהם הקדישו לה את מיטב כחם ואת מבחר עמלם. אם
        יש אכרים, המרטיבים את שדותיהם בזעתם ובחלבם, הם הערביים. מי יעריך את יגיע
        הפלח בחרשו ביום מטרות-עז, בקצרו ביום-קיץ בארצנו, בטענו ובהובילו את
        התבואה? ומה ישא בעמלו? בית רעוע, נמוך ואפל, המשמש למעון כללי למשפחתו,
        לשורו ולחמורו, לחם-עוני, כתונת וטלית בלות – אלה הן שמלותיו יומם ולילה,
        ואשתו ובניו – מה דל חלקם בחיים! מנעוריה ועד ימיה האחרונים לא תחדל הערבית
        לשאת בדומיה את נטל עבודת פרכה; היא שואבת-המים ולפעמים גם חוטבת-העצים,
        אתון נושאת סבל. יונקה על שכמה צרור בשמלתה וכדה המלא מים על ראשה הולכת
        היא אל התָּליש ואל הלקט ומן הבוקר עד הערב היא עובדת שחוח תחת להט קרני
        השמש הבוער. ובשובה הביתה, כאשר השמש קרובה לבוא, עוד תשבע עשן באפותה את
        פתה הדל ובזודה את נזידה הרזה.
      </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3">        האת אלה נוריש, הלהם נרע, הענים נגדיל?</span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        אבל נעזוב נא לרגע את הצדק ואת הרגשנות ונשקיף אל השאלה
        מנקודת-המבט של היכולת בלבד. נניח רגע, כי בארץ אבותינו אין לנו לדאוג
        לאחרים וכי מותר לנו – או אולי גם חובה מוטלת עלינו – לקנות קרקעות מכל הבא
        בידינו. האמנם יכול אופן-הקניה הזה להתמיד? האמנם יחשו המנושלים ויקבלו
        ברוח קרה מה שנעשה להם? הלא סוף סוף יתעוררו להשיב בכח האגרוף מה ששללו מהם
        בתוקף הזהב! הלא משפטים ידברו עם הזרים, שנתשום מעל אדמתם, - ומי יודע, אם
        לא יהיו אז לתובעים ולשופטים גם יחד... והאנשים אמיצי-לב, מזוינים כולם,
        קלעים נפלאים, פרשים מצוינים, קנאים לאומתם וביחוד לדתם. והעם הזה, שההשכלה
        המתשת כחו של אדם עדיין לא נגעה בו להחלישו, הלא הוא אך חלק קטן של הגוי
        הגדול, המחזיק בכל סביבות ארצנו: בסוריה, בארם-נהרים, בערב
        ובמצרים...</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        נקל לבטל את הדברים האלה ולראות בהם בגידה באידיאלנו הלאומי העתיק
        והנצחי; אבל אם נשקול את הענין בדעה מיושבת, הלא נודה, כי אולת תהיה מצדנו,
        אם לא נדע מראש, עם מי יש לנו עסק, ואם לא נביא בחשבון תחלה את כחנו שלנו
        ואת הכחות שכנגדנו. חלילה לנו לעצום את עינינו מראות את הנולד, הקרוב אולי
        יותר ממה שאנו מדמים. אפשר לומר בהחלט, שלכל הפחות בארץ-ישראל אין לעת-עתה
        שום תנועה ערבית במובן הלאומי והמדיני של המושג הזה. אבל באמת אין לעם הזה
        צורך בתנועה: עצום ורב הוא ורינו טעון תחיה, כי מעולם לא מת ולא חדל לחיות
        אף רגע. בהתפתחותו הגופנית הוא עולה על כל עמי אירופה. הוא, שהוא צמחן
        (וויגיטאריני) למחצה ושותה מים, חזק הוא בגופו מאוכלי-הבשר ושותי המשקים
        האלכוהוליים. אל לנו לזלזל בזכֻיותיו, וביחוד חלילה לנו להשתמש לרעתו ברשע
        עושקיו מאחיו. אל לנו להתגרות בלביא נרדם! אל לנו לבטוח באפר המכסה את
        הגחלת: זיק אחד יתמלט – ויהיה לתבערה שלא תכבה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        רחוק ממני הרעיון, שבארצנו צריכים אנו להתרפס ולהכנע לפני
        התושבים. אך באומץ ובעוז נוכל להכבד בתוכם ולשבת לבטח במושבותינו;
        ובארץ-השמש תהיה לנו גם היכולת להחליף כח, לחדש את דמנו ולהתעודד. אבל חטא
        נחטא לעמנו ולעתידו, אם נשליך מידינו בקלות ראש את מבחר נשקנו: צדק-פעלנו
        ותום-דרכנו. כל עוד נחזיק באלה גבורים אנחנו ולא נירא איש, אבל אם נעזבם –
        שוא כחנו וגברתנו מאין.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        כי לא בכל פעם שבעלי-הקרקע הרשמיים רוצים למכור את אדמתם מותר
        לקנותה – הוכיח לנו ברור העבר הקצר של הישוב החדש בארצנו. ואני נוטל רשות
        להביא עובדות אחדות מתולדותיו בגליל העליון.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        בשנת תרנ"ח נגמרה קנית הכפר הדרוזי מֻטַלה אשר בעמק עיון, על גבול
        צפון ארצנו, ונוסדה המושבה הגדולה הידועה בשם זה, שהיא מרגלית-הישוב. היא
        מצטיינת באקלימה, באדמתה, במימיה ובמראותיה ויש לה עתיד גדול. שבט הדרוזים,
        האביר בשבטי הערביים, נודע לשם באומץ-רוחו, בגבורתו, במבנה-גופו, ביפי-תארו
        וגם במעלות רוחניות הרבה. ככתה מושלמית נסתרת שנוא הוא לכל הערביים ולרשות;
        אבל גבורתו עמדה לו וחפשי הוא בארץ-העבדות, כי עד היום לא חדל להלחם על
        חירותו. כל מרד של דרוזים עולה לממשלה בכסף רב ובקרבנות, ועל כן היא משתדלת
        להשלים עם עזי-הנפש האלה. בהר הדרוזים שבחורן יראים לפעמים פקידי הרשות
        לבוא אל הכפרים ולגבות את המסים, כי בנפשם הוא.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c5"
        >        ובכפר מטלה ישבו יותר ממאה משפחות של דרוזים על אדמה חכורה,
        שהחליפה פעמים אחדות את בעליה. אדונה האחרון היה פחה אחד, שנפשו קצה
        בחוכריו, כי לא יכול לא להורישם ולא לגבות מהם את החומש; פעמים אחדות
        הוכרחה הממשלה לצור על הכפר ולערוך עליו מלחמה כדי לקחת את המעשר. הפחה בקש
        למכור את אחוזתו, אבל לא מצא קונים, כי שום איש לא רצה לקבל או להרחיק
        בחוזק-יד חוכרים כאלה, שנושנו על האדמה (הם ישבו עליה כתשעים שנה). והנה
        הוצעה הקניה לפני הפקידות. זכורני, שכשהלכתי עם פקיד המושבות להתבונן בפעם
        הראשונה אל אדמת הכפר, נאספו צעירי הדרוזים בחצר בית-כנסתם, וכשעברנו ברחוב
        קראו אלינו: "אל תעיזו לקנות את מטלה פן נשחטכם!". הפקידות היתה אז בעצם
        תקופת-זהרה, בשעה שהממשלה המקומית וכל הערביים הביטו עליה כעל כח איתן
        ההוגה </span
      ><span class="c1">[?]</span
      ><span class="c3"
        > מדרכו את כל המעצורים. אולם במטלה קשה היה מאד להתגבר על המכשולים;
        אי-אפשר היה להפטר מן הדרוזים. כארבע שנים נמשך המשא-והמתן, ואולי עוד לא
        היה בא לידי גמר אילמלי אירע מאורע יוצא מן הכלל. בשנת תרנ"ו פרץ
        מרד-הדרוזים האחרון, שנמשך כשנה. נשיאי השבט שולחו לקונסטנטינופול –
        והפקידות השתמשה בשעת-חירום לגמור את הקניה. זקני הכפר קבלו שלמונים
        הגונים, והמצב לא היה מסוגל למעשי-אלמות. אף על פי כן מאנו הרבה מבני-הכפר
        לעזוב את מקומם ולא רצו לקבל אף את המחיר היותר גדול בעד בתיהם וגניהם.
        ויהי היום ויבוא פקיד המושבות למטלה ושק מלא זהובים במרכבתו, וכמו במקרה
        הזדמנו שם אז פקיד-צבא וחילים, שבאו לעצור את המשתמטים מעבודת-הצבא – ואלה
        מרובים בין הדרוזים ואין הרשות שוקדת לתפסם – והיו מוכנים לאסור את הממאנים
        לחתום על שטרי המכירה. מובן, שכולם חתמו על השטרות, ואחרי ימים מועטים עזבו
        יותר משש מאות נפש את כפר מולדתן... ובמשך שבוע נאספו שם כששים אכרים
        יהודים, בחירי פועלי המושבות, שהתישבו בבתי הדרוזים. כל זמן שהממשלה רדפה
        את הדרוזים וראשיהם הוכרחו להחבא – שקטה המושבה. אולם מקץ שנה אחת קראה
        הרשות דרור לנשיאיהם ופרסמה סליחה כללית לשבט. לא עברו חדשים מועטים ודרוזי
        מטלה הגישו קובלנא על הפקידות, שהחזיקה בבתיהם ובכרמיהם שלא מכרו לה. בין
        התובעים היו גם זקני-הכפר, שקבלו מחיר עצום בעד כל מה שהיה להם. בשיחותיהם
        עם בני-המושבה היו דורשים, שירשו להם לבנות בתים על חלקת אדמה הסמוכה לכפר,
        והיו מתאוננים על שאין נותנים להם עבודה ואין מפקידים אותם לשומרים. מן הצד
        העידו ביהודים, כי מרה תהיה אחרית המושבה, אם לא יחוסו יושביה על נשיהם ועל
        טפם ולא יעזבוה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c5"
        >        צריך להודות, כי דרוזי מטלה הם עניים מחוסרי-כל וכשהורחקו מן
        הכפר, אף על פי שקבלו איש איש מאות-פראנקים אחדות, נמצאו פתאום במצב רע
        מאוד. ועוד זאת: אחרי שעזבו את מושבתם בעלת האקלים הנחמד והמבריא מצאו להם
        מקלט-ארעי בכפר דרוזי בעמק חילת – מצפון למי-מרום </span
      ><span class="c1">[אגם החולה]</span
      ><span class="c3"
        > – שבו שוררת קדחת הבצות. ובכן חלו הרבה מהם וסבלו מן הקדחת. בתנאים הללו
        לא יכלו בשום אופן להשלים עם הרעיון, שעליהם לשכוח את מטלה. ועל כן הוסיפו
        לארוב להמושבה ולאיֵם עליה וגם ירו אל תוך אחד מן הבתים. פעם התנפלו בלילה
        על יהודי אחד, שישן בגרנו, והרגוהו, ופעם גזלו בעצם היום את צמד-הבקר של
        אכר, שחרש את שדהו. הרבה פעמים פשטו שמועות, שביום פלוני יתנפלו הדרוזים על
        המושבה ויחריבוה, ואימת מות היתה נופלת על כל יושביה. הגברים היו מזדיינים
        ולא היו פושטים את בגדיהם, וכשהיו נשמעות בלילה יריות מסביב למושבה היו
        רצים בבהלה אל המקום, ששם דִמו למצא את עושי הרשעה – ולא היו מוצאים איש.
        מאות לילות כאלה עברו על בני מטלה ואפשר לומר, כי חמש שנים רצופות היתה
        המושבה שרויה בפחד ובבהלה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        הפקידות עשתה כל מה שביכלתה: העמידה עורכי דין מצוינים, הוכיחה את
        צדקת-הקניה, השתדלה להתפשר עם הדרוזים באמצעות נשיאיהם, פזרה כסף רב לכל
        צד, והכל ללא הועיל. פעמים שהאמינו כבר כי בא הקץ לסכסוכים: הממשלה העליונה
        שלחה פקודה נמרצה להגן על המושבה, הרשות המקומית איימה על המערערים ואסרה
        את ראשי המסיתים, נשיא-השבט יעץ את המתאוננים לקבל דמי-פיוס ולהרפות מן
        היהודים, והדרוזים שמעו לעצה – וקבלו סכום הגון. אבל מקץ חדשים אחדים
        הודיעו, שאי-אפשר לרצותם, כי לא כסף אם מבקשים אלא את אדמתם.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        ובין כה וכה הביא ערעור הדרוזים צרה אחרת על המושבה. קנית מטלה
        נגמרה בעזרת נשיאי המוטוַלים, המוצצים את דם אחיהם הפלחים. החומסים האלה
        קבלו שכר טרחה כיאה לכבודם, ואגב מצאו כאן הזדמנות טובה להפטר משכנים
        תקיפים בני-חורין ולהמירם במושבה יהודית, שתהא נוחה להשתעבד ולהֵחָלב.
        ואמנם, הם פרשו את כנפיהם על הישוב היהודי וקבלו על עצמם את שמירת המושבה,
        ויחד עם זה לא חדלו מלבקש תואנות לרדוף שלמונים. הפקידות נלאתה לשאת את
        "חסותם" ונסתה לפרקה מעל צואריה. ואז הלשינו התקיפים הללו על המושבה, גֵרו
        בה את בני הכפרים הסרים למשמעתם והתירו להם לגנוב ולגזול את תבואת היהודים,
        באופן שבמשך קיץ אחד היו שכני מטלה משלחים את בהמותיהם בקָמתה ובקציר היו
        באים עם חמוריהם אל השדות, טוענים עליהם לעיני הבעלים את החטה ואת השעורה,
        את הפול ואת הכרשינה – והולכים בשלום לביתם. וכשגברה סכנת הדרוזים הוכרחה
        הפקידות לבקש עזרה מן המלשינים והמסיתים הללו, ובשכר טוב היו שנית למגיני
        המושבה...</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        סוף סוף, אחרי משפטים מסוכסכים, איומים והשתדלות נמרצה מכל
        הצדדים, עלתה ביד הפקידות להתפשר עם הדרוזים ולפייסם בסכום עצום, ומטלה
        שקטה, אם אפשר לשקוט תחת המחסה ההכרחי של חבר-המרצחים, השולט במחוז
        ההוא.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        את מטלה, זאת אדמת-החמד, צריך ומצוה היה לקנות, אבל לא בדרך זו
        (עוד נשוב אל שאלה זו), שהמיטה עלינו שתי רעות: עוררה עלינו משטמה בקרב שבט
        אדיר, שלא רק להזהר מאיבתו אנו צריכים, אלא גם לרכוש את אמונו ואת ידידותו
        בשעה שאנו באים בקרבתו, והרכיבה עלינו בסביבה ההיא אדונים – את חלאת
        בני-האדם (אין זו מליצה, אלא דבר כהויתו), בכלל גִלה ענין מטלה את רפיוננו
        ונתן אותנו ללעג ולבוז בכל הגליל.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        בעמק עיון פגשנו בבעלי-דין עזים, שאינם מותרים על זכיותיהם אף
        כמלא נימא, בעוד שבמושבות אחרות דבר לנו עם מסכנים, שהם נכנעים לפני
        זקני-הכפר ואימת הרשות ובית האסורים מכרחתם לכבוש את כעסם; אבל שנאתם נטורה
        עמוק בלבם, הם רואים בנו אויבים בנפש ומתנקמים בנו בכל מה שיוכלו. בסביבות
        טבריה ערערו הפלחים על קניות אחדות וטענו, שהמוכרים הסבו על שמם במרמה
        ובזיוף קרקעות לא להם. ימים רבים לא נתנו המערערים את היהודים לחרוש על
        האדמה וגם החלו לזרעה העצמם, עד שגרשה אותם הפקידות בעזרת חילים
        מזוינים.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        אמנם, הישוב היהודי כבר השפיע שפע טובה על יושבי הארץ: מצבם של
        הערים והכפרים הסמוכים למושבות הורם, מאות בעלי מלאכה: סתתים, בנאים,
        סידים, חַמָרים וגַמָּלים, ואלפי פועלים מוצאים עבודה במושבות, המסחר גדל,
        גם הדרישה למעשה-החלב ולתבואת-הגן רבתה. אבל כל אלה לא יכפרו על מה
        שעִוַתנו. על הטובה אין שמנו נקרא, אגל חרות הוא על הרעה, אשר זכרה לא
        יסוף. קשה לקנות אוהבים, אך מה נקל להקים אויבים בין פלחים פשוטים. עזה היא
        קנאת האנשים, שנסחו מעל אדמתם...</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        עת לפקֹח עינינו על דרכנו! אם אין אנו רוצים לחבל את מעשינו,
        חייבים אנו לחשב כל צעד שאנו צועדים בארצנו, ולמהר ולפתור את שאלת יחוסנו
        אל הערביים בטרם תחולל שם שאלה יהודית. רב לנו להסתפק במצב השעה! חלילה לנו
        להסיח דעתנו אף רגע מן הנולד, מן העתיד. כל פעם שהטובה הלאומית המדומה
        פוגעת בצדק האנושי, תהיה טובה זו לחטא לאומי גס, שאין לו כפרה. משאת-נפשנו
        היא כל-כך נעלה, עד שלא לשוא שואפים צעירינו לגשם בה את האידיאלים
        החברותיים, המפעמים את האנושיות בימינו. אבל אם כן, הרי צריכים אנו להתרחק
        מן הכעור והדומה לו, כלומר, מכל מפעל מכוער, מכל פסיעה חשודה ומכל מעשה,
        שיש בו אבק של חמס.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        - אבל – יאמרו לנו – עד שתתפלספו על כל קניה ותבררו, אם היא מותרת
        לנו על פי חוקי היושר, יבואו הגרמנים ויחזיקו בה בלא שום פילוסופיה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        על שאלה זו אשתדל להשיב בפרק הבא. אולם בכלל אין לנו להדמות אל
        הגרמנים. הם לא יבקשו את עתידות אומתם בארצנו ואין להם – ומי יודע, אם אין
        גם להם? – לדאוג לימים הבאים ולהתרומם מעל ליושר המוסכם. ועוד זאת: הרבה
        הנו צריכים ללמוד מן הגרמנים: מדע, עבודה, התמדה, אך לא את חוקי היושר. את
        תורת-הצדק קבלו הם מאתנו ועוד ימים רבים יצטרכו להוראתנו.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        כשנבוא אל ארצנו עלינו לשרש מקרבנו כל מחשבות כבוש ונסיחה. אמרתנו
        צריכה להיות: חיֵה כל נשמה! חלילה לנו לגרום רעה לשום עם, ומה גם לעם גדול,
        ששנאתו מסוכנת לנו ביותר.</span
      >
    </p>
    <br />
    <p class="c13 text-center" dir="rtl"><span class="c9 c4 c12">ב.</span></p>
    <p class="c2 c10" dir="rtl">
      <span class="c5">היוצא מכל הקודם הוא:</span><span class="c4"> </span
      ><span class="c3"
        >שכשאנו באים לקנות קרקעות בא"י עלינו לבדוק היטב, למי הם ומי עובדיהם ומה
        הן הזכֻיות של אלה האחרונים, ושאין אנו צריכים לגמור את הקניה עד שנִוָכח,
        שלא תרע לאיש. באופן זה מוכרחים נהיה למשוך ידינו מרוב אדמת-הזרע; ומה ישאר
        לנו, איפוא, לעשות בארצנו? אנא הגענו אל השאלה רבת-הערך, שכל שאר השאלות
        החשובות טפלות לה: באיזה אופן יכולים אנו להאחז בארץ מבלי שנחטא לצדק ומבלי
        שנרע למי שיהיה?</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >תשובה על שאלת-השאלות הזו יש למצוא בעיקר היסודי הזה, שאנו צריכים לשוות
        לפנינו בָּכל כקו מאשר מפעלינו בארץ-ישראל: אנו באים לארצנו לתפוש שם מה
        שלא תפשו אחרים, להאחז בכל המקומות שעדיין לא נאחזו בהם אחרים, למצוא מה
        שלא מצאו בה אחרים, לגלות לטובתנו ולאושר כל יושביה את העושר הטמון בשכבות
        אדמתה ואת הברכה הצפונה בשמיה ובשמשה. בנוגע להתנחלות נשתדל לרכוש מתְחִלה
        את כל האדמה, שאינה נעבדת מפני שהיא דורשת הכשרה פחות או יותר קשה
        ורבת-הוצאות. חלק-ארצנו זה אולי עולה בשטחו על החלק הנעבד שלה, כי הוא כולל
        את רוב אדמת ההרים והגבעות וגם בקעות ועמקים הרבה. פרוצנט קטן של אדמה זו
        לא יצלח כלל, אבל ברובה הגדול היא ראויה לנטיעות שונות וביחוד לתאנה, לזית
        ולגפן, וחלק חשוב ממנה יוכשר, אחרי העזוק והסקול, גם לזרע, ובמקום שההשקאה
        אפשרית – אף לעִבּוּד אינטנסיבי.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >בספרות הציונית מדברים הרבה על ההשקאה באמצעות הנחלים והנהרות שאינם חרבים
        במשך כל השנה. המים האלה באים מן המעינות או, במלים אחרות הם מי-גשמים,
        שאחרי הִסַפגם באדמה שטפו בתוכה על פני שכבה קשה ובלתי-קולטת ועברו מרחק רב
        עד שפגשו בדרך את פני הארץ ויצאו החוצה ויגַלו. אמנם, רבים הם המים האלה,
        אבל מרובים מהם הם המים הממלאים את מאות הנחלים בחורף. בסתו שוטפת בנחלים
        תערובה של מי-מעינות ומי-גשמים הנגרים ממורדי ההרים, ואת כלם אפשר לאצור
        ולחסוך לתקופת החורב. ומעלות יקרות יש למים אלו: הם נמצאים בכל הארץ,
        וביחוד בהרים, ששם אפשר לעצור חלק מהם קודם שהוא מגיע עד הנחל, וכל מושבה,
        כל כפר וכל אחוזה הם אדוני המים, העוברים בגבולם והנקוים מאדמתם. מדי ידובר
        על המים בא"י מתעורר רגש של תמהון על קוצר-הראות של יושביה, שלא רק אינם
        עושים כלום כדי להרבות את מימיהם, אלא שאף לא יעלה על דעתם, שאפשר לעשות מה
        בנדון זה. ואמנם, אין ארצנו עניה במים כלל. לא נדבר פה על המים הפנימיים,
        המכלכלים את בארות העמקים וההרים, ולא על המעינות המרובים, שנסתתמו או
        שעדיין לא נגלו, אלא על שפעת המים, הנגרים מלמעלה למטה על פני האדמה
        והיוצאים גם הם לבטלה דרך הנחלים אל הימים הפנימיים ואל הים התיכון. הון
        עצום מריקים השמים במשך חדשי-החורף ואין איש שם על לב לצברו, בארץ, שהשמש
        עושה בה נפלאות אם אך יספיקו לאדמה את הרטיבות הדרושה. מניחים למים לרדת
        ללא-הועיל ואחר-כך מתאוננים על חורב ועל ציה. העורים! הלא ירדו מים על ראשי
        ההרים, הלא שטפו במורדים וזרמו בגיאיות? עִצְרום מלכת, אל תתנום לרדת,
        חִסמום ואִצרום, התקינו להם מעצורים באשר תוכלו. הגיאיות הצרות תוכלנה
        להיות לברכות ענקיות, המספיקות מים לעשרות הקטארים. בצלעות ההרים אפשר
        לכונן אגמים מלאכותיים. מובן, שכל זה נקל לתאר במשיכת-העט וקשה לגשם במעשה:
        נצרכים מדע, כסף ועבודה. אבל מה קטנה ההוצאה, שתוצא אך פעם אחת, לעומת
        ההכנסה, שלא תפסוק. וצריך להודות, שבהרבה מקומות מֵקיל הטבע הרבה קוּלוֹת
        בנידון זה. באחת ממושבות-הגליל נושא הנחל אל הירדן במשך ארבעת חדשי-הסתו
        כשלש מאות אלף מטר מעוקב של מים, וכל השפעה הזאת יכולה היתה להשאר על אדמת
        המושבה ולהחיות משפחות רבות. בלא כל הפלגה אפשר להחליט, שעשרות מיליוני
        פראנק יורדות שנה שנה דרך הנחלים הקטנים לאבוד אל הימים.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >הפסד המים גורם עוד נזק רב-ערך מאד: בימות-הגשמים סוחף זרם המים מעל צלעות
        ההרים המשופעות את מיטב העפר התחוח, וביחוד את החלק האורגאני, ההומוס (פרי
        ההשתנות של פרורי-צמחים ובעלי-חיים מורקבים) שבו, הנוח להגרר מפני קלותו.
        באופן זה יוצאת תקנת המים בתקלתם: מצד אחד הם הגורם העיקרי להתהוות העפר,
        להתקנת שכבת-האדמה העליונה, הראויה לגדל צמחים; הם מבקעים ומפרקים את
        הסלעים, חותרים חתירות באבנים, מפוררים את הצרורות ומשלימים את פעולת האויר
        על הגופים האורגניים, שהרי הם פועלים ככח מיכני וכימי גם יחד; אבל מצד שני
        הם לוקחים מה שנותנים, מסַחפים את השכבות שהתהוו על ידיהם ומעָרים את
        הטרשים, שכסו בפירורי הסלעים. כשמתמעט השפוע בעמק ונחלש כח-הזרם משֻכבות
        גרגרי החול הכבדים מעברי הנחל, בעוד שהפרודות הדקות והקלות ביותר נשאות אל
        הים ומרבות שם את שכבת הרקק שעל פני קרקעו. קשה להעריך את כמות החמר הפורה
        והיקר, היורד לתהומות שנה שנה, אבל על כל פנים ההפסד כביר מאד: את משמן
        ההרים וקרום פריתם מנצלים המים ומטלטלים אותם למעמקים. נקל להבין כי יותר
        שנרבה לעצור בעד מי ההרים יותר יתמעט כח סתירתם, ואת העפר, שעוד יסחפו
        בירידתם, ישכיבו בברכות ובאגמים והוא יהיה שם לשכבה עבה, שתהיה כדומן מעולה
        אחרי שיאפסו המים במקוים האלה ככלות האסיף.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c5"
        >בכלל צריכה ההידרולוגיה – תורת התפלגות המים בתוך האדמה ועל פניה – לתפוש
        מקום בראש המדעים, אשר לאורם אנו צריכים ללכת במפעל הישוב. הִתְקוות המים
        בהרים תביא לידי תוצאות חשובות גם ביחס ההיגיאני: מעוט המים הנגרים מן
        הההרים יקטין את הבצות בעמקים; ורבוי המים על פני האדמה ינקה קצת את האויר
        מאבק – זה אויב-הבריאות וצורר-העינים – ויתן להרבות בנטיעות ובגדול יערים
        במקומות, שלא יצלחו לעבודה אחרת. מליצות הנביאים: "והושם מדבר לאגם-מים
        וארץ ציה למוצאי-מים", "והטיפו ההרים עסיס והגבעות תלכנה חלב", יכולות הן
        להתקיים אם נעבוד בדרך מדעי. אם אך נדע לחשך את המים הנגלים ולגלות את
        הצפונים, יוכל שמש-ארצנו המפרה להוציא נחלי יין ושמן מבין לטרשיה ואוצרות
        דגן וירק מעל לאבניה. אנו נכבוש שנית במדע ובזעה מה שכבשו אבותינו בחרב
        ובחנית ונִתן גאולה לארץ לא מן הפלחים הערביים[</span
      ><span class="c1">כאן מופיעה הפניה להערה בתחתית העמוד:</span
      ><span class="c3"
        > פה המקום להעיר על חוסר-הטעם של סופרים שונים הדורשים בעתונים בשאלת
        התחרות הפועלים העברים בערביים. הכל מודים שצריך לברוא במושבות תנאים
        כלכליים שירשו לתת עבודה לפועלים יהודים, אבל אין צורך לדבר על התחרות ועל
        דחיה. בכלל צריכה לנו מעט דיפלומאטיה ביחס אל ההמון הערבי אולי יותר
        מבחצרות המושלים.] אלא מחורב, משממון ומעזובה.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >וכמה מעלות טובות יש להרים שבארצנו! ההרים הם המקום המסוגל לְחָיות עם שתש
        כחו מחוסר אויר ואור, להשביח ולהבריא את דמו, לחזק את שריריו ולאמץ את
        עצביו. די להתבונן אל ההרריים בא"י, לקומתם, למראם, לתֹארם ולחֹסנם,
        ולדמותם אל יושבי העמק והשפלה, כדי להִוַכח בפעולה המבורכה של ההרים על
        יושביהם. בגֹבה של 600-500 מטר מתמעטים האבק והמיקרובים, הזבובים והיתושים,
        ועמהם גם המחלות והמדוִים המצויים בעמק. גם החום מתמעט שם ולעומת זה מתרבה
        כמות-הגשם השנתית. לפעמים יורד המלקוש – זה המטר שבו תלוי גורל תבואות השדה
        – בהר ואל העמק לא יגיע. גם הטללים שכיחים במרום בשעה שבשפלה חורב גמור.
        ישיבת ההרים, המציגים לפני האדם את מראות-הטבע בכל הדרם וחליפותיהם, מפתחת
        בו את רגש היופי ומחנכת את עינו. ובהרחיבה את חוג המרחבים הנשקפים היא
        מגדילה את כח-הראיה, - מה שדרוש כל כך לנו, לעם רפה-העינים וקצר-הראות. אם
        האדם חי על עינו, שהיא שקולה כנגד כל חושיו, אז חי ההררי בשעה יותר ממה
        שיחיה יושב-העמק ביום: ההררי רואה ברגע מה שלא יראה זה בחודש. העמק והשפלה
        הם המרתפים של ארצות ההרים. בקצור אפשר לומר, שהשמש, מקור החיים על פני
        האדמה, משפיע על הרי ארצנו את כל טובו, את כל הברכה האצורה בקרניו, חסר
        מגרעותיו: החמה המתשת והנוגפת, השרב הלוהט והמזיק, - ואת ההרים הללו, - שעל
        הרוב אינם מעובדים, אנו צריכים לרכוש לעצמנו.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >אבל, כשנחזיק באדמה הבלתי-נעבדת, לא נסלק את ידינו מן הקרקעות הנעבדים.
        אנו נקנה גם אותם. אבל לא על מנת לשלח את החוכרים, אלא בתנאי קודם למעשה
        להשאירם באחוזה ולהיטיב את מצבם ע"י הנהגת שיטה אכרותית טובה. גם הם יחליפו
        מעט מעט את האכרות הנושנה, האכסטנסיבית, באכרות החדשה, האינטנסיבית.
        כשהאחוזה תהיה מתוקנת ותונהג בה שיטת-עבודה מדעית תספיק אדמתה למתישבים
        היהודים ולפלחים. כבעלים נאורים נקדיש סכום ידוע לשכלול החוכרים, כי טובתם
        טובתנו. באשר נפנה נהָנֶה קצת את התושבים, אבל לא במתן-בסתר, בשלמונים,
        בזהובים כדי להפטר מהם – אנו נהנָם הנאה קיימת ואמתית, חמרית ורוחנית.
        אגרונומינו ינהלום בעצות ויורום את תורת האכרות, גדול הבהמה והשבחת-המינים,
        וגם יראו להם את האמצעים, שהמדע נותן בידינו לשם המלחמה במגפות הבהמה והעוף
        ובנגעי השדה, הכרם והגן. בשכר מועט ישיגו במושבה את הרפואות למחלות המצויות
        בארץ ובעת צרה ימצא להם הרופא היהודי. טפם יקובל לבית-ספרנו, וכשיעלה בידנו
        להקל את עול המעשר יוקל גם להם. אמנם, בימים הראשונים יביטו עלינו
        בעיני-חשד ולא יאמינו בכל החדשות ועוד פחות מזה – בממציאיהן, אבל מיום ליום
        תרבה צדקתנו להגלות ונוכחו בתום שאיפותינו ובתועלת התקונים שיצלחו בלי ספק
        בידי עובדים חרוצים, נבונים ומסתפקים במועט כערביים; ראוי לדעת, שהפלח עולה
        בפקחותו ובתבונתו המעשית על אכרי כמה וכמה ארצות. ואז יכירו בנו חוכרינו
        הערביים את מיטיביהם ומאשריהם ולא יקללו את יום בוא היהודים להתישב על
        אדמתם, אלא יזכרוהו ביום גאולה וישועה.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >בשיטה זו נשתמש גם כשנקנה חלק מנחלת הפלחים. כל קרן חדשה שנתפוש בארצנו,
        צריכה להיות לקרן אור ושפע לסביבותיה ולמקור-טובה לעובדיה הראשונים. ונעלה
        הוא מעל כל ספק, שאחרי שנעשה נסיונות כאלה במחוזות שונים בארצנו יבואו מאות
        כפרים לבקש מן היהודים שיאחזו על נחלתם, ולא יוכלו כל קוני קרקעות בא"י
        להתחרות עמנו. ואז תראה גם הממשלה את הטובה הרבה, שהבאנו על יושבי הארץ,
        וגם מתנגדינו – והם מרובים יוכרחו להודות, שרק טובה מביאה התישבותנו
        בארץ.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >לא חלום דמיוני היא שיטה זו. קשה היא, אבל קלה, נאמנה ורבת-תוצאות מן
        השיטות שהשתמשנו בהן עד עתה. אילו במקום להוריש את הדרוזים ממטלה היינו
        מחלקים את האדמה עמהם, אז לא היינו מוציאים עליהם אף המחצה ממה שהוצאנו על
        בקשישים למנוולים, על גרוש משפחות עניות, על משפטים ועל עורכי-דין ועל
        פשרות בלתי-קיימות. לא היינו משתעבדים למרצחים והיינו יושבים לבטח עם
        שכנינו ועובדים את נחלתנו בשלוה. הדרוזים אוהבים ומכבדים את ההשכלה והיו
        שולחים את בניהם ובנותיהם לבית-ספרנו, ובדורות הבאים היינו יכולים למצוא
        בהם לא רק שכנים ישרים, אלא גם רעים נאמנים. והוא הדין בשאר המושבות. הון
        רב הפסדנו כדי להקים לנו שונאים נמרצים בשעה שיכולנו להוציא פחות – ויהא
        אפילו יותר – ולרכוש לנו אוהבים, להגדיל את כבודנו, לקדש את שם ישראל
        ולקרבנו אל מטרתנו – לפתוח לנו את שערי הלבבות, מה שחשוב הרבה משערי
        החוף.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >שיטתנו ברכישת קרקעות צריכה להיות אך תוצאה ישרה מיחוסנו אל העם הערבי
        בכלל. העיקרים, שעל פיהם עלינו לפעול את פעולותינו כשאנו באים להתנחל בקרב
        העם הזה ובקרבתו, הם:</span
      >
    </p>
    <ol class="c11 lst-kix_list_2-0 start" start="1">
      <li class="c8 c14 li-bullet-0" dir="rtl">
        <span class="c3"
          >העם העברי, הראש והראשון לכל העמים בתורת הצדק והמשפט, השויון המוחלט
          והאחוה האנושית, מכבד לא רק את הזכֻיות האישיות של כל אדם, אלא גם את
          הזכיות הלאומיות של כל אומה ושבט.</span
        >
      </li>
      <li class="c2 c8 li-bullet-0" dir="rtl">
        <span class="c3"
          >עם ישראל השואף לתחיה הוא אח לדעה ולפעולה לכל העמים המתעוררים לחיים,
          מתיחס באהבה וברצון אל שאיפותיהם ומפתח בקרבם, בבואו עמהם במגע, את ההכרה
          הלאומית שלהם.</span
        >
      </li>
    </ol>
    <p class="c2" dir="rtl">
      <span class="c3"
        >שני העיקרים האלה צריכים להיות ליסוד כל יחוסנו אל הערביים - זה העם
        הכביר, המצוין במעלותיו הגופניות ובכשרון-בינתו. מאותו רגע שאנו באים
        במחיצתו, עלינו לשים לב אל זכֻיותיו. הוא נאחז בארצנו זה יותר מעשרים יובל
        ואילו הצטמצם בה כלו והושיבה עד בלתי השאיר מקום לאחרים, כי עתה אולי לא
        הועילו לנו תביעותינו ההיסטוריות. אבל, לאשרנו, תופש הגוי הזה מרחב כל-כך
        רב על פני הארץ, עד שהוא יכול להניח לנו, לעם העתיק הקרוב אליו כל כך בדמו,
        בשפתו ובנטיות נפשיות הרבה, לתפוש בארץ אבותינו כל אותו החלק, שעדיין לא
        החזיק בו הוא. ולא רק יכול, אלא גם מוכרח הוא לטובתו להכניס את היהודים
        לארצם, כי חסר-אונים הוא להיטיב בידיו את מצבו ולצאת מעניו ומבערותו, ואך
        עמנו לבד יכול למלא את חסרונותיו. שני העמים האלה, העברי והערבי, יכולים
        למלא איש את חסרון רעהו, כי מה שאנו יכולים לתת לערביים לא יוכלו לקבל מכל
        עם אחר. כל עם שיבוא לסוריה תחת מסוֶה של מושיע כלכלי, ישאף לכבוש, לשעבד
        ולהטמיע. מה שאין כן אנו, העם בלי צבא ובלי ספינות-מלחמה: תוכנו כברנו. אין
        לנו כל מחשבה זרה של שעבוד ושל המעטת האופי הלאומי של שכנינו. בלב טהור אנו
        באים להתישב בתוכם על מנת להיטיב להם בכל היחסים. את העיקר של "דעלך סני
        לחברך לא תעביד" נקיים בחיוב: "דעלך חביב תעביד לחברך"; וכשנשתדל להקים את
        אומתנו נפעל גם על תחית התושבים ונגביר את הרגשתם הלאומית במובן הטוב של
        המושג הזה.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c3"
        >עלינו לבוא, איפוא, בברית עם הערביים ולכרות עמהם אמנה, שתהא רבת-תועלת
        לשני הצדדים ולאנושיות כולה. אנחנו בודאי נסכים לברית זו. אבל דרושה עוד
        הסכמת הצד השני; ואותה נשיג לאט לאט ע"י פעולה מעשית, שיש בה תועלת לארץ,
        לנו ולערביים. בדרך הממשי הזה יבינו שכנינו מעט מעט את הטובה הרבה, שיוכלו
        להפיק מן השותפות בין עם ישראל ובין עם ערב. כל פבריקה חדשה וכל מושבה
        שניסד, כל מוסד צבורי שנכונן, אם אך נהַנה בהם גם את יושבי-הארץ, מקרבים
        אותנו אל מטרתנו. השגת הטשארטר הקיים הזה, שצריך לחרות לא על נייר או על
        קלף, אלא בלב ובמח של עם שלם, היא מפעל כביר, שאין דומה לו בתולדות
        התקדמותה והשתחררותה של האנושיות, כי תוצאותיו הן תחית שני עמים שֵמיים
        עתיקים, בעלי-כשרונות ורבי-עתידות, המשלימים זה את זה. צריך להודות, שעד
        עתה "טעינו בכתובת". כדי לרכוש את ארצנו פנינו אל כל הכחות, שיש להם יחס כל
        שהוא אליה, לקחנו דברים עם כל המחותנים, אך את החתן בעצמו שכחנו: העלמנו
        עינינו מאדוני-הארץ האמתיים. מבלי זלזל בכל אלה שיש להם נגיעה בארצנו
        וביחוד בממשלתה, עלינו לפעול ביותר על העם הערבי, ובקרבו עלינו לכוֵן את
        מעשינו ביותר אל מפלגת-הפלחים, הישרה והמרובה משאר המפלגות. המעשה החשוב
        ביותר שאנו יכולים לעשות בנידון זה, הוא הרמת מצב החוכרים והפלחים היושבים
        על הקרקעות שנקנה. יותר שנוסיף לקנות אדמה ולהיטיב לעובדיה, יותר ירבו
        הרוצים למכור את אדמתם לנו, יותר תחזק השפעתנו בארץ ויותר תגדל הכרת
        תועלתנו ונחיצותנו.</span
      >
    </p>
    <p class="c2 c10" dir="rtl">
      <span class="c5"
        >אבל גם בערים יש לפנינו כר נרחב לפעולה. נפתח לרוחה ליושבי הארץ את
        מוסדותינו הצבוריים: בתי-החולים, בתי-הרפואות, בתי-הספרים ואולמי-הקריאה,
        בתי-המאכל הזלים, קופות החסכון והמלוה; נערוך דרשות פופולאריות, מחזות
        ונשפי-זמרה לפי רוח העם ובלשונו; נתן בבתי-ספרנו מקום חשוב ללשון הערבית
        ונקבל אליהם ברצון את בני הערביים; נפתח את גני-הילדים לעולליהם ונביא ###
        ---</span
      >
    </p>
    <p class="c2" dir="rtl" id="h.gjdgxs">
      <span class="c3">ומוסרית, וע"י התינוקות נשפיע השפעה --- ###</span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >שנבין, כי החנוך הוא מניע מדיני גדול, שלו אנו חייבים להקדיש את מיטב
        פעולתנו הצבורית. אמנם, כבר קדמונו אחרים, ובערים הגדולות תפשו הישועיים,
        האנגליים והאמריקניים את מוסדות החנוך וההשכלה הבינונית והעליונה; אבל,
        אילו היינו מבינים את כל חשיבותו של כבוש ההשכלה בארצנו ואת הערך הגדול,
        שיש למוסד מדעי עליון ביחס אל השפעתנו הכללית, יכולים היינו להתחרות עם
        הצרפתים ועם האנגלו-אמריקנים. כי בשעה שלכולם אין המדע וההשכלה אלא אמצעים
        למטרה דתית וכבושית, היינו מורים אנחנו תורה לשמה ומשליטים חופש מוחלט של
        דעות ואמונה בביה"ס. אילו היינו יודעים לכבוש את מוסדות המדע והחנוך
        ולהעלותם למעלה עליונה באופן שיהיו לשם ולתהלה בכל ארצות-הקדם, היו נוהרים
        אל בתי-ספרנו אלפי תלמידים ממצרים, מסוריה, מארם-נהרים ומתורכיה האירופית,
        ובתי-ספרנו היו יכולים להיות גם למקור שפע חמרי גדול לארץ-ישראל. כל מוסד
        הגון של חנוך, שניַסד שם, הוא מלה אחת קטנה, אבל חשובה, של הטשארטר ושָקוּל
        הוא כנגד אלפי זהובים של שלמונים.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        ובבית-ספרנו, כמו בכל מוסדותינו, נתרחק מלאומיות צרת-מבט
        וצרת-עין, הרואה אך את עצמה. אנו נכניס אליו ביד רחבה מדעים, מלאכה, עבודה
        וחנוך גופני. לא ליהד את הערביים מגמתנו, אלא להתקינם לחיים יותר שלמים,
        לנמסם, לפתחם, להוציאם מצרות-מבטם, כדי שיוכלו להיות לנו ברבות הימים
        לבעלי-ברית נאמנים, לרעים ולאחים. נכין את עם-הארץ ונכשירו אל ישובנו, נהיה
        למאור-המדע בארצנו ונפנה את הדרך למשפט ולצדק. נלחם בדעות הקדומות של
        הלאומים השונים, השוטמים זה את זה, נהיה למלאך-השלום, המפשר בין הכתות
        הדתיות הצוררות זו את זו. וכל אלה יכולים לעשות אנחנו בטוהר שאיפותינו
        ודעותינו, אנחנו לבד ולא אחרים.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        וכשנבוא לחנך את בעל-בריתנו ולפעול עליו, אל נא נשכח עיקר אחד.
        כשם שהמורה מחויב לדעת את נפש חניכו ונטיותיו, כך לא די לנו לשוות לנגדנו
        את המטרה הקיצונית, אלא חובה עלינו להכיר כראוי את העם הערבי, תכונותיו,
        נטיותיו, שאיפותיו, לשונו, ספרותו, וביחוד להכיר הכרה עמוקה את חייו,
        מנהגיו, סבלותיו ויסוריו. אל נא נשְנה את השגיאה, שהסבה נזק אין קץ לחנוך
        הילדים. במשך אלפי שנים היו המחנכים רואים בתלמידיהם אנשים קטני-קומה,
        כלומר, את היַלדות היו רואים אך בגוף ולא בנפש שברגע בואה לשכון בחומר היא
        – כך היתה דעתם של המחנכים הישנים – כבר מושלמת די צרכה, מוכנת ומוכשרת
        ומזוינת בכל הכשרונות להבין, להשכיל ולהרגיש. אנחנו נכנסים אל סביבה, שהיא
        חיה עתה במאה השש-עשרה, ועלינו להביא בחשבון בכל מעשינו את מצב-הנפש של העם
        הזה עתה. אם נרצה להוליך איזה אדם אל מקום ידוע, עלינו לקחתו במקום שהוא
        נמצא בו עתה: באופן אחר לא יוכל ללכת אחרינו. אנו צריכים, איפוא, לחקור את
        הפסיכיקה של שכנינו ולבא עד תכונתה. חרפה היא להגיד, שעדיין לא נעשה אף כל
        שהוא בנידון זה, שעדיין לא הקדיש אף יהודי אחד את עצמו לדבר זה, באופן שאנו
        בורים גמורים בכל הנוגע לערביים וכל ידיעותנו עליהם הן ידיעות-השוק. עת
        להשכיל!</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        כל אחד מסעיפי פעולתנו דורש באוּר ושיטה מסוימת, בעוד שהרצאה זו
        באה אך לגעת מגע קל בתכנית הרחבה של העבודה ולהראות את הנחיצות להבין את
        מצב-הענינים האמִתי בעוד מועד.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        אפשר להתנגד לדברי ההרצאה הזאת מטעמים שונים; אבל אחת מעיז המרצה
        להחליט: שהדברים הללו נאמרו ברוח אומתנו, ברוח הצדק העולמי, שטבע את חותמו
        על עמנו מיום שהיה לגוי ועד היום הזה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        נביא-הגולה, כשבא לדבר על חלוקת-הארץ, אמר: "והיה תפילו אותה
        כנחלה לכם וללהגרים הגרים בתוככם, אשר הולידו בנים בתוככם, והיו לכם כאזרח
        בבני ישראל, אתכם יַפִלו בנחלה בתוך שבטי ישראל. והיה בשבט, אשר גר הגר
        אתו, שם תתנו נחלתו" (יחזקאל מ"ז, כ"ב-כ"ג). והנביא הגדול מענתות שקדם
        ליחזקאל, כשבא לנבא דעות על השכנים הרעים הנוגעים בנחלת ישראל, אמר לבסוף:
        "והשיבותים איש לנחלתו ואיש לארצו. והיה אם למד ילמדו את דרכי עמי ...
        ונבנו בתוך עמי" (ירמיהו י"ב, ט"ו-ט"ז).</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >        הבה נלמדם את הדרך הטובה, נבנם – ונבנינו גם אנו.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3">                                        </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >                                                י. אפשטיין.</span
      >
    </p>
  </body>
</html>`;

export default page;
