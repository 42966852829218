const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c25 doc-content">
    <p class="c3" dir="rtl">
      <span class="c2 c4 small-text">אפרת בן כהן</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c0"
        >היא התחילה את כתיבתה בעברית עוד ברוסיה בהיותה צעירה, ושמור בכתובים </span
      ><span class="c19 c0 c24 modal-button" id="modal-button-1"
        >מכתב ששלח לה יל"ג</span
      >
      <span class="c0"
        >המשורר המפורסם, אחרי שהוא קרא משהו מיצירותיה בהתפעלות רבה.</span
      >
    </p>
    <p class="c3" dir="rtl"><span class="c2 c0">...</span></p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >עוד טרם הכירו פנים אל פנים, נוצר הקשר בין הורי בעברית, בחליפת מכתבים
        בין ארץ-ישראל ורוסיה. </span
      ><span class="c0 c19 modal-button" id="modal-button-2"
        >השפה בביתנו היתה תמיד עברית.</span
      >
      <span class="c0 c2">עברית צחה, עשירה, וללא תוספת מילים לועזיות.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0"
        >רק עם אנשים שלא ידעו עברית, נאותה אמא לדבר שפה אחרת. עם אלה שידעו קצת
        עברית, ואפילו התקשו בשפה, התעקשה לדבר עברית.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0"
        >יידיש היה טבו בכלל. אסור היה לדבר יידיש. כולנו ידענו יידיש מפני שסבתא
        חייתה על ידינו, אבל לדבר בבית יידיש זה היה ממש אסור.    </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0"
        >אמא שלטה הכי טוב בעברית, אך ידעה על בורין גם יידיש, רוסית וגרמנית,
        ודברה קצת ערבית. היא קראה הרבה, בעקר עברית.
      </span>
    </p>
    <p class="c1 italic small-text" dir="rtl">
      <span class="c7">בשנת </span><span class="c8 c12">1896, </span
      ><span class="c7"
        >אחרי שנפטרו שני ילדיה הראשונים, וכנראה גם בעקבות מתחים בין בני הזוג -
        ולפי דברי בעלה עקב בעיות בריאות... - לקחה נחמה פוחצ&#39;בסקי את בנה
        השלישי</span
      ><span class="c9">, </span
      ><span class="c7"
        >נסעה לרוסיה, למשפחתה, שהתה שם כשנה וחצי ושבה לארץ בשנת 1897</span
      ><span class="c9">.</span
      ><span class="c7"
        > מתקופה זו נותרו בידי המשפחה מכתבים מלאי כאב, סבל וגעגועים</span
      ><span class="c0 c10"> </span>
      <a href="/letters/fromYechiel1896">
        <span class="c0 c16">שכתב לה בעלה יחיאל מיכְל שלמה זלמן</span></a
        ><span class="c7"
        >.  המכתבים שלה אליו (לפי מכתביו – בעיקר גלויות קצרות) אבדו לצערינו. כל
        המכתבים נכתבו כמובן בעברית.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c8 c4 small-text"
        >מתוך זכרונותיו של מיכל פוחצ&#39;בסקי:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c8 c0"
        >... בשובה מצאה תוכן חדש לפניה, והתמסרה בכל מאודה לעזור ללוחם היחידי מר
        אליעזר בן יהודה להחיות את השפה העברית שלנו ולעשותה לשפה מדוברת. בראשון
        לציון מצאה רק שני תומכים: המורה דוד יודילוביץ, וביתנו.</span
      >
    </p>
    <p class="c1 c5" dir="rtl">
      <span class="c8 c0"
        >הילד, הבן היחיד שלי, ידע היטב את השפה הז&#39;רגונית </span
      ><span class="c21 c0 italic">[יידיש]</span
      ><span class="c8 c0"
        >, הלא אך זה שב מאירופא. קשה היה כקריעת ים סוף לפעול עליו לעזוב ולשכוח
        את שפתו, שזה שפת כל הילדים בני גילו בראשון, ולדבר בשפה אשר כל מִלה ומלה
        צריכים עוד לברוא וליצור בשבילו. כל המונחים שמשתמשים כעת בדִבור עוד טרם
        נולדו אז, וכל רגע העמיד אותנו הילד לפני קיר אטום שלא ידענו לתת לו
        פתרונים.</span
      >
    </p>
    <p class="c1 c5" dir="rtl">
      <span class="c8 c0"
        >נוסף לזה סבלנו מהמכרים הטובים שלנו, באי ביתנו, שחֵרפו וגדפו אותנו על זה
        שקובעים את נפש הילד: "מה אתם חפצים מהילד שלכם, באיזה רשות אתם מעכבים את
        התפתחותו באין לו שום שפה". הילד היה כבן ארבע וחצי והבין כבר את הלעג
        שזִקני הישוב לעגו על אבותיו בנוכחתו, בכוָנה להזיק. כמה יִסורי נפש טעמנו
        בזמן הראשון להחלטתנו, אך את נדרנו לא הפרנו. ברחוב הראו עלינו באצבע: הנה
        ה"שָׁלומניקים"</span
      ><span class="c15 c22"> </span
      ><span class="c8 c0">הולכים, כאילו המירוֹנו חס ושלום את דתנו. </span>
    </p>
    <p class="c1 c5" dir="rtl">
      <span class="c8 c0"
        >אך הזמן עשה את שלו, וגן הילדים העברי הראשון בארץ ישראל מצא את מקומו
        דוקא בראשון לציון, במקום שנמצאו המון מתנגדים לשפה העברית. הבן שלנו היה
        אז מהמאושרים שבבואו הביתה לא צריך היה להחליף את שפתו כיתר ילדי
        הגן.</span
      >
    </p>
    <p class="c6" dir="rtl"><span class="c2 c12">...</span></p>
    <p class="c1 c5" dir="rtl">
      <span class="c8 c0"
        >...ובשביל להיות מרוחק קצת מהעדה המחזיקה בנושנות, קניתי לי מגרש מחוץ
        למושבה ... חפצינו היה לסדר לנו פִּנה אבטונומית, שלא יפריעו לנו בחינוך
        בנינו בשפה של אבות אבותינו.
      </span>
    </p>
    <p class="c1 c5" dir="rtl"><span class="c8 c0">...</span></p>
    <p class="c1 c5" dir="rtl" id="h.gjdgxs">
      <span class="c8 c0"
        >אחרי מלחמה קשה עם הורים ידישיסטים, שהיו כמעט רוב מנין, התחיל גם בית
        הספר הראשוני "להתיַהד", ועברית בעברית מצאה הֵדה בבית ספרנו. אז כבר נולדה
        לנו בת, על טהרת הקודש  </span
      ><span class="c0 c21 italic"
        >[זו אפרת, לימים בן-כהן, שנולדה בב&#39; בחשון תרנ"ט – 1898]</span
      ><span class="c8 c0"
        >  . בִּתי היחידה לא שמעה אף צִלצול שפה אחרת. בזמנה כבר דִברו כל ילדי
        בית הספר אך ורק עברית, אפילו ברחוב. והשפה כל כך התחבבה על הילדים עד
        שרובם, בבואם הביתה, לא חפצו להִכנע להוריהם ולענות להם בשפתם
        הבלולה.</span
      >
    </p>
    <p class="c14" dir="rtl"><span class="c2 c15">...</span></p>
    <p class="c1 c5" dir="rtl">
      <span class="c8 c0">ומי יאמין כעת  </span
      ><span class="c21 c0 italic"
        >[בזמן כתיבת הזכרונות - לקראת סוף שנות העשרים של המאה העשרים]</span
      ><span class="c0 c8"
        >, בזמן שנמצאים בארץ עשרות אלפי איש מדברים בשפתנו ולא מרגישים שום חוסר
        במילים, כי אך לפני שתי עשיריות שנים היו בארץ אך איזה עקשנים מופרזים, שלא
        התחשבו עם הלעג ועם כל הקושיות שפגשו על כל צעד ושעל בחיי יום יום.
      </span>
    </p>    
  </body>
</html>`;

export default page;
