const desc = `<body class='c4 doc-content'>
  <p class='c5' dir='rtl'>
    <span class='c3'>הצעירה (ליום "השלשים" למות נחמה פוחצ&#39;בסקי)</span>
    <span class='c1 bold'> דואר היום</span>
    <span class='c3'>   1934 06 22</span>
  </p>
  <p class='c0' dir='rtl'>
    <span class='c2'></span>
  </p>
  <p class='c0' dir='rtl'>
    <span class='c2'></span>
  </p>
</body>`;

export default desc;
