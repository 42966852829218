const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c6" dir="rtl">
      <span class="c4 bold">השקפה   -  א אדר ב אתתל"ט, 4.3.1908   </span
      ><span class="c3"
        >- במדור חדשות בספרות אמנות ומדע, מופיעה בין היתר החדשה הבאה:</span
      >
    </p>
    <p class="c8" dir="rtl">
      <span class="c0">בענין התנפלו ממש קוראי ארץ ישראל על ה"</span><a href="/herArticles/openQuestions"><span class="c0 c1">שאלות הגלויות</span></a><span class="c0">" של נחמה פוחצבסקי. זו אחת הנשים המצֻינות שבנשותינו בארץ ישראל: משכלת,
        נבונה אהובה ונחמדה מאד ויודעת היטב עברית וכותבת. לצערנו לא נפל בגורלה
        לפתח את כשרונותיה ולהפך הלכה ונשכחה ונחבאה אל כליה ולא נשמע קולה ולא
        מלאה פקידתה </span
      ><span class="c0 c7 italic">[תפקידה?]</span
      ><span class="c0"> אשר יכלה למלא בספרותנו העניה, העלובה. </span>
      <a href="/herArticles/hiddenQuestion"
        ><span class="c0 c1">דברי אפשטין בהמחברת הקודמת</span></a
      >
      <span class="c0"
        > העירוה הפעם לענות עליהם ותבאר במאמר קצר שכתוב בלשונה העשירה וסגנונה
        יפה היטב כי היהודים הביאו אך תועלת להפלחים שכניהם.</span
      >
    </p>
  </body>
</html>`;

export default modal;
