const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c1 c4 italic" dir="rtl">
      <span class="c8 c5"
        >הספד זה שנכתב ע"י א. ז. רבינוביץ פורסם בעתון דבר ב 14 במאי 1934.
      </span>
    </p>
    <p class="c1 c4 italic" dir="rtl">
      <span class="c5 c8"
        >לקראת סוף ההספד מופיע ציטוט ממכתב של נחמה פוחצ&#39;בסקי לכותב.</span
      >
    </p>

    <p class="c1 italic" dir="rtl">
      <span class="c5">אסתר מרגולין ונחמה פוחצ&#39;בסקי היו </span>

      <a href="/aboutHerInThePressOfHerTime/smilansky">
        <span class="c5 c11 tooltip"
          >חברות קרובות במשך שנים רבות<span class="c10 tooltiptext"
            >נחמה נפטרה חודשים ספורים אחרי אסתר ומשה סמילנסקי כתב הספד משותף
            לשתיהן.</span
          ></span
        ></a><span class="c5">.</span>

      <span class="c5"
        > גם שניים מאחיה של אסתר, אליעזר ודוד אשר חיו בארץ בזמנים שונים ומוזכרים
        במאמר, היו בני בית בבית פוחצ&#39;בסקי.</span
      >
    </p>
    <p class="c1 c7" dir="rtl"><span class="c2"></span></p>
    <br/>
    <h3 class="c1" dir="rtl">
      <span class="c12">לזכר אסתר מרגולין</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c0">(ליום  שלשים למותה כ"ט ניסן)</span>
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >את משפחת ר&#39; מרדכי מרגולין ז"ל הכרתי עוד בשנות תרמ"ד – תרמ"ה בשבתי
        בביעלגורוד פלך קורסק.  זו היתה משפחה יהודית אצילה שעליה אפשר לומר: "עם
        לבן גרתי ותרי"ג מצות שמרתי", כי אעפ"י שהיו מחוץ לתחום המושב הקבוע
        ליהודים, בכל זה נשמר בקרב המשפחה הזאת האופי היהודי הטהור והנעלה.</span
      >
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >מבני המשפחה הזאת הצטינו שני האחים ר&#39; משה ואליעזר, הראשון יושב עדיין
        בלנינגראד ועובד שם כחבר במערכת האנציקלופדיה הגדולה הרוסית (עמו בהיותו
        סטודנט למדתי את הספר דור דור ודורשיו של א"ה וייס בהיותו בימי הקיץ בבית
        אביו), והשני, מר אליעזר, הוא הקולוניל מרגולין שעבד בגדוד העברי בכבוש
        ארצנו. וכמוהם הצטיינה בשכלה ובמסירותה לעמה אחותם אסתר ז"ל. היא לא ידעה
        ולא חפצה לדעת מתענוגי העולם הזה, כי כולה היתה מסורה תמיד לענינים
        אידיאליים ולעזרת אחים. היא היתה מילדת ורוקה כל ימיה. אבותיה באו לא"י עוד
        קודם המלחמה וקנו להם חלקת אדמה ברחובות ושם מתו ונקברו. והיא עברה לראשון
        לציון עם אחיה דוד הסומא, שהיה מנגן יפה בכנור ואציל הרוח. היא טפלה בו כאם
        רחמניה עד יום מותו. והוא נקבר בראשל"צ ועל ידו כרו גם לה קבר. היא היתה
        כבת שבעים במותה.</span
      >
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >וזה מה שכתבה לי ידידתה הסופרת החשובה מרת נחמה פוחצ&#39;בסקי תבדל לחיים
        טובים: "אחרי מותה מצאנו מכתבי פרידה אחדים ממנה (לאחיה ועוד), ומכתב כללי
        אחד וזה לשונו: משה ואליעזר האהובים והיקרים שלי, ואתם כולכם אהובי, גם אתם
        הידידים היקרים! אל תצטערו על מותי, אל תאמרו צר לנו, ימיה הבודדים עברו
        כיום סגריר חרפי, נפתוליה ויסוריה בערפל עטפוה.</span
      >
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >הלא יחד עם זה היתה בלבי גנוזה אהבה עמוקה ועזה לכם, אהובי היקרים, לעמנו
        ולארצנו. היא האירה וחממה את הבדידות ונתנה גם אושר.</span
      >
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0">אל תצטערו על מותי, עיפה נשמתי"..</span>
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0">אשריה, שיכלה לכתוב צואה כזו לפני מותה.</span>
    </p>
    <p class="c1 c4" dir="rtl">
      <span>עוד דברים אחדים הוסיפה מרת ח. </span
      ><span class="c5">[הטעות במקור]</span
      ><span class="c0"
        > פוחצ&#39;בסקי לאמר:  אסתר חיתה חיי נזירים ואת כל פרוטותיה שלחה חודש
        חודש לקרוביה ברוסיה ובקשה את היורשים שהם ימשיכו תמיכה זו ולא יפסיקו אבל
        בתנאי לא לקומוניסטים ולא למומרים.</span
      >
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >עד רגעי גסיסתה האחרונים היתה צלולה בדעתה ולבה נתון לזקוקים
        לעזרתה.</span
      >
    </p>
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >המקום ינחם את אחיה, ידידי היקרים, ויהי רצון שאזכה לראותם כאן
        בארצנו.</span
      >
    </p>
    <p class="c4 c9 text-left" dir="rtl">
      <span class="c0">א. ז. רבינוביץ</span>
    </p>
    <p class="c1 c7" dir="rtl"><span class="c0"></span></p>
  </body>
</html>
`;

export default page;
