const mainDesc = `<body class='c13 doc-content'>
    <p class='c1' dir='rtl'>
      <span class='c14'>
        במדור זה אנו מביאים מספר מסמכים שהשגנו מפה ומשם ונראו לנו מתאימים להביאם
      </span>
    </p>
    <br />

    <a href='#womanChoose'>
      <p class='c1' dir='rtl'>
        <span class='c0'>בחירת נשים לועד המושבה</span>
      </p>
    </a>
    <a href='#hashalomCourtProtocols'>
      <p class='c1' dir='rtl'>
        <span class='c0'>פרוטוקולים של בית משפט השלום</span>
      </p>
    </a>
    <a href='#electionRepresentativesNote'>
      <p class='c1' dir='rtl'>
        <span class='c0'>פתק בחירות לאספת הנבחרים</span>
      </p>
    </a>
    <a href='#michelAndNehamasWill'>
      <p class='c1' dir='rtl'>
        <span class='c0'>צוואת מיכְל ונחמה פוחצ'בסקי</span>
      </p>
    </a>
    <a href='#receiptForPickingAlmonds'>
      <p class='c1' dir='rtl'>
        <span class='c0'>קבלה על קטיף שקדים</span>
      </p>
    </a>
    <br />
    <br />

    <h3 class='c9' dir='rtl' id='womanChoose'>
      <span class='c10'>בחירת נשים לועד המושבה</span>
    </h3>
    <p class='c1' dir='rtl'>
      <span class='c8'>
        זהו פרוטוקול של האספה הכללית של אנשי ראשון לציון שנערכה ביוני 12 1919
        לצורך עריכת בחירות לועד המושבה – הבחירות הראשונות מאז תום מלחמת העולם
        הראשונה שעשתה שמות בישוב.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c8'>
        לפני ההצבעה עצמה דנה האספה במתן זכות בחירה למגזרים באוכלוסית המושבה שעד
        אז היו מודרים מזכויות פוליטיות.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c8'>
        באסיפה הוחלט לתת זכות בחירה לבני העדה התימנית ולנשים, ואף לשריין שני
        מקומות לנשים בועד.
      </span>
    </p>
    <p class='c1' dir='rtl' id='h.gjdgxs'>
      <span class='c8'>
        את המאבק על זכות הבחירה לנשים ניהלו נחמה פוחצ'בסקי ועדינה כהנסקי וכפי
        שמראה הפרוטוקול שתיהן נבחרו לועד ברוב קולות ובלי צורך בהפעלת השיריון.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c12'>
        אנו מביאים כאן את <span class='c3 modal-button' id='page-modal-button-1'>הפרוטוקול המקורי</span> כפי שהוא נמצא בארכיון ראשון לציון 
      </span>
       
      
      <span class='c12'> וכן </span>
      <a href='/fromHereAndThere/womanChooseProtocole'>        
        <span class='c3'>הדפסה שלו.</span>
      </a>
    </p>
    <br />

    <h3 class='c1' dir='rtl' id='hashalomCourtProtocols'>
      <span class='c2'>פרוטוקולים של בית משפט השלום</span>
    </h3>
    <p class='c1' dir='rtl'>
      <span class='c12'>
        זהו פרוטוקול אחד מרבים של בית משפט השלום של ראשון לציון בהם מופיעה
      </span>
      <span class='c3 modal-button' id='page-modal-button-2'>
        נחמה פוחצ'בסקי כשופטת.
      </span>
      <span class='c15'> </span>
      <span class='c8'>הפרוטוקולים נמצאים בארכיון ראשון לציון. </span>
    </p>
    <br />

    <h3 class='c1' dir='rtl' id='electionRepresentativesNote'>
      <span class='c5 modal-button' id='page-modal-button-3'>
        פתק בחירות לאספת הנבחרים
      </span>
    </h3>
    <p class='c1' dir='rtl'>
      <span class='c8'>
        נחמה פוחצ'בסקי נבחרה לאסיפת הנבחרים בשנת 1927 (תרפ"ז)
      </span>
    </p>
    <br />

    <h3 class='c1' dir='rtl' id='michelAndNehamasWill'>
      <span class='c5 modal-button' id='page-modal-button-4'>
        צוואת מיכְל ונחמה פוחצ'בסקי
      </span>
    </h3>
    <p class='c1' dir='rtl'>
      <span class='c11'>נכתבה ונחתמה ב- 24/10/1927</span>
    </p>
    <br />

    <h3 class='c1' dir='rtl' id='receiptForPickingAlmonds'>
      <span class='c5 modal-button' id='page-modal-button-5'>
        קבלה על קטיף שקדים
      </span>
    </h3>
    <p class='c1' dir='rtl'>
      <span class='c4'>15.2.1928</span>
    </p>
  </body>`;

export default mainDesc;
