const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c17 doc-content">
    <p class="c7" dir="rtl">
      <span class="c20 c14 italic"
        >הקטעים המובאים כאן נכתבו על ידי יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי
        וקיימים עד היום בכתב ידו. פורסמו בספר זכרונותיו – מהתם להכא (יד בן-צבי
        ירושלים 2013)</span
      >
    </p>
    <p class="c2" dir="rtl" id="h.gjdgxs"><span class="c20 c14"></span></p>
    <h3 class="c7" dir="rtl">
      <span class="c13">שנה ראשונה בארץ:</span>
    </h3>
    <p class="c0" dir="rtl">
      <a href="#home">
        <span class="c22">בית</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#rehovotSelebration">
        <span class="c22">חגיגת ייסוד רחובות</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#visitInJerusalem">
        <span class="c22">ביקור בירושלים</span>
      </a>
      <span class="c13">. </span>
    </p>
    <br/>
    <h3 class="c7" dir="rtl" id="home"><span class="c13">בית</span></h3>
    <p class="c7" dir="rtl">
      <span class="c3"
        >במעלה ההר, קרוב לבית התפילה, עמד הדלז&#39;נס במקומו הקבוע מול המסעדה
        הידועה של מר מוגילבסקי. הנוסעים תופשים בבהלה את חבילותיהם וממהרים הביתה,
        ואני עומד לי וחושב: "ואיה ביתי? לאן אפנה ואנה אכנס?". הלא הדִקטטור מר
        בלוך שלל ממני את זכותי בדירתי הקודמת, ואני באתי הביתה ובית אין לי! מוחי
        פשוט מתהפך: מה עלי לעשות עכשיו?</span
      ><span class="c10 c27"> </span>
    </p>
    <p class="c7 c15" dir="rtl">
      <span class="c3"
        >והנה בעל המסעדה מזמזם על אזני שהוא כבר דאג למקום שינה עבורי: מר צבי
        הכהן-לבונטין</span
      ><span class="c12"> </span
      ><span class="c3"
        >גמר באלו הימים את הקומה השניה על ביתו, והצריף בחצר, שגר בו קודם,
        התרוקן. במדור התחתון באותו הבית היתה המסעדה ששמה אכלתי ארוחת תמיד, ובעל
        המסעדה דאג לי וקִבלתי את מפתח הצריף ורשות ללילה אחד. לילה אחד, גם זו
        לטובה. הלילה כבר מצאנו מנוחה, ובכן מה חסר לנו? ליום המחרת לא רציתי לדאג.
      </span>
    </p>
    <p class="c7" dir="rtl">
      <span class="c3"
        >ואמנם למחרת קִבלנו הצעה מאשה צעירה אחת גלמודה שגרה באיזה פינת גג פשוטו
        כמשמעו, והזמינה אותנו להִספח לפִנתה עד שנמצא לנו דירה קבועה. ההצעה
        נתקבלה בלי פקפוקים, והצעירה שלי – שאך זה יצאה מבית הורים בורגנים אמידים,
        שגרו בחדרים מרווחים ומרוהטים יפה – הסתפקה בזה שמצאה מיטת ברזל אחת שקניתי
        לפני נסיעתי, וסִדרה לה את גנה תחת הרעפים של גב&#39; ד&#39;. גב&#39;
        ד&#39; הפקירה את עצמה, את כֻּלה, וממנה למדה אשתי להתמסר לחולים ולסובלים.
        ופה, תחת כיפת רעפים אדומים, בִּלינו את ירח הדבש בעליצות רבה, </span
      >ופה קִבלנו את כל האורחים שבאו לברך אותנו
      <span class="c3"> בהצעד הנמרץ <span class="modal-button" id="modal-button-1">שמצא הד בלב כלם</span>. </span>
    </p>
    <p class="c7" dir="rtl">
      <span class="c3"
        >לאחר עשרה ימים נזדמן לנו לשכור חדר אצל האכר צ&#39;, שבימים אלו גמר
        לבנות את ביתו. כאן העמידה לי הצעירה שלי שאלה חמורה: במה נרהט את החדר?
        והדאגה נפתרה: מִטת ברזל הלא הזכרתי, קניתי ממשפחה בסרבית שהספיקה כבר
        לחזור, שחן באר טוביה לא מצא הד בלבם. דרגש, או ספסל, קִבלתי בירושה,
        נדוניה מהפקידות. שולחן עץ עגול משומש נזדמן לי לקנות בארבעה פרנקים כסף
        מזומן, וכל השאר נסדר לנו מארגזי נפט וחסל! יהיה לנו היכל מלכים!
      </span>
    </p>
    <p class="c7" dir="rtl">
      <span class="c3">בערב ראש השנה תר"ן </span
      ><span class="c14 c20 italic">[25.9.1889]</span
      ><span class="c3"
        > נכנסנו למזל לדירתנו, שזה היה בכלל חדר אחד בלי רצפה, ודלת ישר החוצה אל
        השמש ואל הרוח, ותו לא. ופה מתחיל כִּשרון האשה להראות פלאות: הרצפה כוסתה
        מחצלות של קנים, והחדר נחלק על ידי פרגודי בד לשלשה "חדרים" זעיר אנפינים:
        חדר מִטות, חדר בִּשולים, וחדר אורחים די הגון, מעין אולם כביכול, ותקופה
        חדשה מתחילה בחיינו.</span
      >
    </p>
    <p class="c7" dir="rtl">
      <span class="c3"
        >בחדר הזה ההיסטורי קיבלנו את כל האורחים הנכבדים שהתחילו לסַיֵר את ארצנו
        בזמן ההוא. בהיכלנו בִּקר "אחד העם", שהביט בחביבות על מחצלותינו הפרושות
        תחת רגליו, ומי יודע אם לא שם התחיל החפוש אחרי האמת מארץ ישראל</span
      ><span class="c12">. </span
      ><span class="c3"
        >אחריו בא הרב יעקב מזא"ה ממוסקבה. מר לוין אפשטיין, מייסד "מנוחה ונחלה",
        הופיע בצל קורתנו לשם התייעצות ממשית. וכן רוב הצירים שבאו לקנות בארץ אדמה
        עבור חברות שונות, וסתם תיירים לעשרות, וחברים מכל אפסי ארץ. כֻּלם התפלאו
        על החיים המסודרים, ועל ההסתפקות בשכר חדשי שקִבלתי מהברון – אך ששים פרנק,
        ומהסכום הזה שִׁלמנו גם שכר דירה בעין יפה.</span
      >
    </p>
    <p class="c7" dir="rtl">
      <span class="c3"
        >המעון הצר שלנו נהפך לעתים קרובות לטרקלין שבו מצא הנוער האינטליגנטי
        הראשוני או הכלל ארצישראלי מקום לבלות איזה שעות בעליַת נשמה. מעין קלוב
        תרבותי המשתמש בחופש יוצא מהכלל.</span
      >
    </p>
    <p class="c4" dir="rtl"><span class="c5">...</span></p>
    <p class="c7 c15" dir="rtl">
      <span class="c3"
        >ירח הדבש שלנו ארך הרבה זמן. נתקיֵם בנו הנס של יהושע שהירח עמד, לא בעמק
        אילון אלא בראשון לציון.</span
      ><span class="c16"> </span
      ><span class="c3"
        >בכל מצאנו עניַן ונחת רוח. גם עבודתי בפרדס ובגן התחבבה עלי שבעתיים,
        בְּידְעי שבאותו הגן גופו, בין העצים שגִדלתי, מטיילת לה הצעירה שלי עם ספר
        בידה, אחרי שכבר הכינה לנו ארוחת צהרים טובה וגמרה את עבודותיה בבית. ובשעה
        הקבועה בחצות היום מצאתיה תמיד באיזה פִּנה בגן, קוראת בספר בצל אילן ומחכה
        לי. ושלובי זרוע שבנו לביתנו.</span
      >
    </p>

    <br/>
    <h3 class="c7 c15" dir="rtl" id="rehovotSelebration">
      <span class="c13">חגיגת ייסוד רחובות</span>
    </h3>
    <p class="c7 c15" dir="rtl">
      <span class="c3"
        >פורים תר"ן הוכרז על חגיגה נוספת למסורתית: זה היום שנולדה מושבה חדשה,
        חפשית, בלי תמיכות, על האדמה שנקנתה על ידי חנקין בדורן, וצרכים לקרוא לה
        שם בישראל. מהבקר התחילו לנהור אנשים, נשים וטף, מי ברכב ומי ברגל, לכִּנוס
        על יד הבאר בדורן. [חוץ] מבאר עתיקה וסביבה קוצים גבוהים כסוסים, לא נמצא
        שמה שום דבר, ומעקרון עד ואדי-איל-חנין היה מדבר שָׁמֵם ריק מישוב. לכִּנוס
        נתנה הזכות לתת שם עברי מתאים למושבה החדשה העתידה להבנות פה.</span
      >
    </p>
    <p class="c7 c15" dir="rtl">
      <span class="c3"
        >התרוממות רוח של אותו היום אין ביכולתי לתאר. התאסף המון עם לפי מושג
        הימים ההם, הצפון והנגב נפגשו גם נשקו. פה לא אמר אדם צר לי המקום קום
        ואשבה. סביב סביב התפזרו על התלים הנשאים, לשאוף אויר צח שאין בו ריח של
        אפוטרופסות ושל תמיכה. חופש ודרור בכל עבר ופנה – המקום הראשון שאך ורק
        יוזמה פרטית עומדת ליצור!
      </span>
    </p>
    <p class="c7 c15" dir="rtl">
      <span class="c3"
        >עד חצות היום חִכִּינו לאורחים, עד התאסף גם הרחוקים והמפגרים. אז תקעו
        בשופר וכולם התלקטו לגוש אחד ורמסו ברגלים את הקוצים המכסים את כל הככר
        מסביב לבאר. התישבנו כלנו על הקרקע כעין גורן עגול, והאספה נפתחה. אספה
        מעורבת! אולי זו היא הפעם הראשונה בארץ ישראל שנִתנה זכות שוויון זו
        לאשה.</span
      >
    </p>
    <p class="c7 c15" dir="rtl"><span class="c3">...</span></p>
    <p class="c4" dir="rtl">
      <span class="c6 italic">פרטים נוספים על החגיגה של ייסוד רחובות:</span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c8 tooltip"
        >בזכרונותיו של יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי
        <span class="c24 tooltiptext"
          > מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה זלמן
          פוחצ&#39;בסקי, יד בן-צבי 2013, עמ&#39; 221-219</span
        ></span
      >
      <br />
      <a href="/herArticles/girlLetterCopy">
        <span class="c1 tooltip"
          >במכתב של נחמה פוחצ&#39;בסקי לאחיה שפורסם ב&#39;המליץ&#39;<span
            class="tooltiptext"
            >העתקה ממכתב אחת מבנות ציון לאחיה על ייסוד רחובות</span
          >
        </span>
      </a>

      
    </p>
    <p class="c4" dir="rtl"><span class="c5">...</span></p>
    <br/>
  
    <h3 class="c4" dir="rtl" id="visitInJerusalem">
      <span class="c19">ביקור בירושלים</span>
    </h3>
    <p class="c4" dir="rtl">
      <span
        >פסח שנת תר"ן הוחלט לחדש את המנהג הקדום לעלות לרגל ירושלימה. לתכלית זו
        שכרנו שתי עגלות גדולות המיוחדות בבנינן להובלת המתפללים הרוסים מיפו
        לירושלים. שלשה זוגות צעירים: דב לובמן, דוד יודילוביץ ומשפחתי וכתריסר
        בחורים. ביום ראשון דחול המועד, טרם שחר, יצאנו לדרכנו בהתרוממות רוח
        חגיגית. כל זמן הנסיעה לא פסקה השירה מפיות הנוסעים, ושתי העגלות מתחרות
        בעליזות ובבדיחות הדעת.</span
      >
    </p>
    <p class="c4" dir="rtl"><span class="c9">...</span></p>
    <p class="c4" dir="rtl">
      <span class="c5"
        >אנו שוב בירושלים, במלון פינקלשטין. משם התפזרנו, מי לסַיֵר במקומות
        הקדושים, עתיקות מימי קדם, ומי שהתחקה על נִצני התחיה המתהוָה בעיר בירתנו.
        הצעירה שלי נזכרה שיש לה חוב לשלם למר אליעזר בן יהודה. לפני איזה זמן ביקר
        ברוסיה, וכשבא לעיר מולדתה התענין להכירה, וזכתה לבִקור פרטי ממנו.</span
      >
    </p>
    <p class="c4" dir="rtl"><span class="c9">...</span></p>
    <p class="c4" dir="rtl">
      <span class="c6 italic">עוד פרטים על אותה עליה לרגל לירושלים </span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c8 tooltip"
        >על פי יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי
        <span class="c24 tooltiptext"
          >מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי,
          יד בן-צבי 2013, עמ&#39; 226-221</span
        ></span
      >
    </p>
    <p class="c4" dir="rtl">
      <a href="/herArticles/zionPilgrims">
        <span class="c1 tooltip"
          >על פי נחמה פוחצ&#39;בסקי<span class="c10 tooltiptext"
            >במאמר לעיתון המליץ.
          </span></span
        >
      </a>      
    </p>
    <p class="c4" dir="rtl">
      <span class="c14 c18 modal-button" id="modal-button-2"
        >בעיתונות התקופה</span
      >      
    </p>    
  </body>
</html>`;

export default page;
