const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c2" dir="rtl">
      <span class="c6">מתוך</span><span class="c3"> </span
      ><span class="c4 bold">ביהודה החדשה</span><span class="c1">.</span>
    </p>
  </body>
</html>`;

export default desc;
