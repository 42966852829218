import { useState, useEffect } from "react";
import useWindowDimensions from "../services/useWinDimentions";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
// import { Link } from "react-router-dom"
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import appTexts from "../texts/appTexts";
import menuImg from "../images/menu.png";

// import { useDispatch } from "react-redux";
// import { setFromPage } from "../state/actions/fromActions";

const MenuComp = (props) => {
  const [isOpen, setIsOpen] = useState(props.inItem ? false : true);
  const [showToggleBtn, setShowToggleBtn] = useState(
    props.inItem ? true : false
  );
  const [buttonFixed, setButtonFixed] = useState(false);
  // const [scrollPosition, setScrollPosition] = useState(0);

  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();

  // console.log("width = ", width);

  useEffect(() => {
    if (width < 720) {
      setIsOpen(false);
      setShowToggleBtn(true);
    } else if (!props.inItem) {
      setIsOpen(true);
      setShowToggleBtn(false);
    }
    // console.log("Menu width ", width);

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [width]);

  const navigateTo = (url) => {
    // console.log("redirectTo url", url);
    // setFromPageRedux(url);
    if (location.pathname === "/") {
      window.location.href = url;
    } else {
      navigate(url);
    }
    if (showToggleBtn) {
      setIsOpen(false);
    }
  };

  const toggleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    // setScrollPosition(position);
    // console.log("handleScroll position", position);
    if (position < 264) {
      setButtonFixed(true);
    } else {
      setButtonFixed(false);
    }
    // console.log("handleScroll buttonFixed ", buttonFixed);
  };

  // const setFromPageRedux = (page) => {
  //   dispatch(setFromPage(page));
  // };

  return (
    <Container
      openedsticktotop={showToggleBtn.toString()}
      inpage={props.inPage && props.inPage.toString()}
      fixed={(buttonFixed && showToggleBtn && !props.inItem).toString()}
    >
      {showToggleBtn && isOpen && <OverLay onClick={() => toggleOpenMenu()} />}
      {showToggleBtn && <MenuToggleBtn onClick={() => toggleOpenMenu()} />}
      {isOpen && (
        <StyledSideBar
          backgroundColor={showToggleBtn ? "#fff" : "rgb(249, 249, 249, 0.2)"}
        >
          <Menu>
            <MenuItem href='/'>
              {/* onClick={() => navigateTo("/")} */}
              {appTexts.pages.homePage}
            </MenuItem>

            <MenuItem onClick={() => navigateTo("/AboutHerInWritings")}>
              {appTexts.pages.AboutHerInWritings}
            </MenuItem>
            <MenuItem onClick={() => navigateTo("/herStories")}>
              {appTexts.pages.herStories}
            </MenuItem>
            <MenuItem onClick={() => navigateTo("/herArticles")}>
              {appTexts.pages.herArticles}
            </MenuItem>
            <MenuItem onClick={() => navigateTo("/testimonies")}>
              {appTexts.pages.testimonies}
            </MenuItem>
            <MenuItem
              onClick={() => navigateTo("/aboutHerInThePressOfHerTime")}
            >
              {appTexts.pages.aboutHerInThePressOfHerTime}
            </MenuItem>
            <MenuItem onClick={() => navigateTo("/letters")}>
              {appTexts.pages.letters}
            </MenuItem>
            <MenuItem onClick={() => navigateTo("/fromHereAndThere")}>
              {appTexts.pages.fromHereAndThere}
            </MenuItem>
            {location.pathname === "/" && (
              <MenuItem onClick={() => navigateTo("/aboutUs")}>
                {appTexts.pages.aboutUs}
              </MenuItem>
            )}
          </Menu>
        </StyledSideBar>
      )}
    </Container>
  );
};

export default MenuComp;
const OverLay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  // background: red;
  z-index: 100;
  left: 0;
  top: 0;
`;

const Container = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.inpage === "true" ? "flex-start" : "flex-end"};

  position: ${(props) =>
    props.openedsticktotop === "true" && props.fixed === "false"
      ? "absolute "
      : "fixed "};

  top: ${(props) =>
    props.fixed === "true" || props.openedsticktotop === "false"
      ? "20px"
      : "285px"};
  left: 20px;
  z-index: 10;
`;
const StyledSideBar = styled(Sidebar)`
  z-index: 200;
  background-color: ${(props) =>
    props.transparentBg === "true" ? "#fff" : "rgb(249, 249, 249, 0.2)"};
  // background-color: red;
`;

const MenuToggleBtn = styled.div`
  background-image: url(${menuImg});
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  // border-radius: 4px;
  // border: solid 1px #000;
`;
