const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c5" dir="rtl">
      <span class="c1"
        >זה עתה כלה חג הפסח.  הכל שמחו, נסעו, טילו, רק שרה היתה חבושה כמו
        בבית-האסורים מפני מחלת בעלה.  לכאורה היתה זו רק קדחת רגילה, אבל לאיש חלש
        ועצבני כמוהו היה די בזה לשלול ממנה את המנוחה.  כי איך תכון את דבוריה ואת
        פעולותיה – והוא לא יתרגז?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וצער גורר צער, ומתגברים געגועיה על זו שהלכה לבלי שוב...  שלש שנים
        לעליתם, ובקרוב תמלאנה שלש שנים לאבדתם.  בבואם אל הארץ התישבו במחנה, בתוך
        אוהל, והימים ימי חורף קשים עם רוחות וגשמים.  באחד הלילות קמה סערה, עקרה
        את מוטות האוהל ותהפוך את הבדים;  אחר-כך נתכו מטרות עוז בלי רחמים ואותם
        הכניסו לאוהל של שכנים. למחרת השתעלה הקטנה והיה לה חום, וטרם הספיקו
        להכניסה לבית-החולים כלו חייה לנגד עיניהם.  – המחלה ארכה רק ארבעה
        ימים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בזכרה זאת, מזדעזעות כתפי שרה; ברקותיה מורגשות כדקירות מחטים,  היא קופצת
        אגרופה, נושכתו מתוך יסורים ולוחשת: לא אוכל לחיות בלעדיך, ילדתי; לא
        אוכל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובאים ימים של טרדות ודאגות ומסיחות את דעתה מן האבדה;  – הנה צפה שאלת
        המים על שטח החיים בשכונת העובדים – שהיא הצליחה לרכוש לה שם משק קטן –
        ומחסור המים מטריד את מוחה.  כמה ירקות בגן מתחילים להתיבש והיא אינה מעיזה
        לחלום על השקאה, אלא מתפללת שיספיקו המים לצרכים ההכרחיים בבית, ומזדרזת,
        בשעה שהמוטור עובד, למלאות את כל הכלים הנמצאים בבית, כי אם לאו, היא
        נידונה לסבל כפול, כי אז יצמאו לא רק הצמחים, כי אם גם בעלי-החיים שעליהם
        היא מתקיימת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושאלת תקון הבאר של השכונה מעסיקה הרבה מוחות.  אמצעים כמובן אין בין
        התושבים העניים.  חברי הועד כותבים, נוסעים ומשתדלים לפני המוסדות;  יש כבר
        הבטחה מזמן, אבל מהבטחה למעשה – רחוקה הדרך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קשים החיים בשכונה בכל המובנים, והיא רק מתפלאה על עצמה, איך הסתגלה אליהם
        במהירות כל כך.  גרם לכך בודאי הרבה היחס החיובי לעבודה שמצאה כאן, במקום
        שכל הידים עסוקות וכל המוחות טרודים בחקלאות הזעירה.  יש לה ממי ללמוד את
        דרכי העבודה, ואם בתוך נפשה עדיין מורגשת לעתים כמיהה לספר ולעבודת-רוח,
        הנה למעשה היא מסתפקת בעבודת-גוף בלבד, ויש שהיא גם רואה בזה חזות הכל: זהו
        עיקר העיקרים בתחית עם על אדמתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה גם המשק הקטן שלה גדל ומתפתח.  – את החצר הספיקה כבר לגדור מסביב ובה
        רוחשים גדוּליה, מעשי ידיה להתפאר: חמש דוגרות מתהלכות עם אפרוחיהן;  מהם
        כבר לבשו נוצות ויודעים בעצמם לנקר באשפה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם הרפת גורמת הנאה.  "ברורה", עגלה קטנה, התחילה להתרועע עם "שחור", עגל
        בן חצי שנה, רובצים בחום השמש יחד ומתנמנמים;  בהתעוררם הם מלקקים זה את
        אזני זו כלוחשים סוד.  באותה שעה באות התרנגולות ומלקטות במקוריהן בשקידה
        את הקרצית מעל אחוריהם של שני האהובים, המקשיבים לנקור ומתענגים במאד
        מאד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זוג יונים, ההוגה אהבה, מתפרץ מן השובך ומתעופף על הגג.  היוֹן רוצה לתפוש
        את אהובתו, רעיתו, תמתו, והיא הגנדרנית, עפה בעגול, מתחמקת מרודפה ואינה
        רוצה להכבש בשעה בלתי רצויה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשזורקת שרה אוכל לתרנגלותיה, נוספת חבריא חדשה בחצר, – עדת צפרים זעירות
        יורדת במעוף מאמירי העצים הסמוכים להשתתף בסעודה.  במחי יד היא מגרשת את
        הבריות החצופות ותיכף היא מוכיחה את עצמה על קמצנותה, – כיצד יכלה להתאכזר
        על עדת נקיים אלה ולמנוע מהם מזון דל, פסולת של חטה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נשמעה געית פרות ושרה ממהרת לצאת לקראת עדרה, אבל אך פסעה פסיעות ועמדה,
        כי פתאום תקף אותה הכאב שבצדה, כאב ישן נושן, ושאין לה פנאי לשים אליו
        לב.  היא עמדה רגע, כוצה את גופה, עִוְתה פניה ומיד הזדקפה ומהרה לאסוף את
        הפרות בלחשה:  לא כלום, לא כלום!  זה יעבור... אסור להתפנק...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גמרה את עבודת הרפת, חלקה את החלב והכינה לארוחת ערב מאכל חביב על ברוך
        שלה, והיה הוא הפעם במצב-רוח טוב, דבר אתה בידידות וספר מכל המתרחש כעת
        בעולם הפוליטי.  הכיר פתאום חיוך על פניה ושאל:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– צחוק זה מהו, שרה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">היא הרהרה רגע ואחר ענתה:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– נוכחתי לדעת, שאם אשה רוצה להפיק רצון מבעלה, – תכין לו מאכל אהוב –
        והכל אתי שפיר!  אמנם צדק ידידנו לוי, האומר, כי אשה היודעת להכין ארוחת
        צהרים טובה לבעלה, מובטח לה שיאהבנה. צר לי מאד שבעניני בשול ידיעותי כל כך
        קלושות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– רק בעניני בשול הן קלושות?  – שאל הוא ברצינות,  מבלי לחשוב על העלבון,
        שהוא מעליבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פניה החורים עם החריצים הכחולים תחת העינים הגדולות התכסו אודם, ולא ענתה
        דבר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >... לא כדאי לענות על העלבון... תתחיל סדרה של וכוחים והתרגזות.  מוטב
        לעבור בשתיקה,  – אלהים עמו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכששכבה במטתה וזמן רב לא יכלה להרדם, עוד חשבה על אודותיו:  – מסכן
        הוא;  חלש ויגע לא מעבודה,  אלא מהרגש הקשה, שאינו מוכשר לעבודה.  – המלחמה
        עשתה אותו לבעל-מום, אִבֵּד עין אחת ועלול הוא לאבד גם את השניה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא מקשיבה לנחרתו ואינה חדלה מהרהר בסבלותיו שם ופה.  שם הרוח הנכאה שהיה
        שרוי בה תמיד, ופה – התקוה להתחדשות הרוח עדיין ממנו והלאה, – מה יהיה
        הסוף?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >את עינה מושך בחשכת החדר החלון היחידי, שבעד מקלות תריסו היא רואה שמים
        כחולים-כהים, הזרועים כוכבים.  הנה אחד מהם מבהיק בתוך השמשה האמצעית, נע
        אט לרחבה, מגיע עד הקצה ונעלם מעבר למזוזה.  היא מטה אוזן;  בחוץ לא נשמע
        כלום, התנמנם כל היקום, רק היא ערה וחשה באזניה רעש עמום, כשראשה מוטל כבר
        על הכר החם והיא כבר במצב נים ולא נים, ופתאום חרדה, – נדמה לה שנפלה
        ממשכבה ארצה.  –  –  –</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשעת בוקר מוקדמה ממהרת שרה זרחי לקום לעבודת הרפת, שבעונה זו דאגה יתרה
        בה מרוב הזבל.  הרי השדות עוד ברוכים בעשבים ירוקים ובמשך הלילה מצטבר הרבה
        זבל תחת הפרות, עד שאי-אפשר לגשת לחליבה, אם אין מנקים תחילה את המקומות
        המלוכלכים ומפזרים עליהם חול או שיירי תבן לספוג את הלחות.  אחרי עבודה זו
        היא עולה בסולם ומורידה מן הגג חבילת חציר לפרות, אבל הבררניות שלה אינן
        מרוצות מארוחת-ירק והן מסתובבות מצד אל צד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נגשה לחליבה.  בראש העגלים קופץ "שחור", טפש זה שלפני גשתו אל האם הוא
        מנסה לינוק מפרה אחרת ומקבל ממנה בעיטות אחדות.  ולא די לו, למסכן, בזה,
        כשמצא את עטיני אמו והתחיל לינוק ברעבתנות, מיד תפסה אותו בסנטרו החולבת
        המרשעת, התיקה אותו משדי אמו וקשרתו בחבל אל עמוד.  הוא לוחם, בועט, קובל
        על העָוֶל שעושים לו ומבקש כל מיני תחבולות להשיב לו את הגזלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חליבת "כרמית", השניה בתור, היתה עוד פחות מוצלחה, – דדיה של זו היו
        מלוכלכים בזבל ו"ברורה", הבת המפונקה שלה, לא רצתה לנגוע בלכלוך.  שרה
        האמינה לה שתעמוד בדבורה, לא קשרה אותה ותרץ להביא מים פושרין לרחוץ את
        כְּחַל הפרה.  בינתים חזרה בה הרמאית הקטנה, ינקה ושבעה, ובשוב החולבת כבר
        עמדה "ברורה" מן הצד והקשיבה בנחת-רוח ללקוקי האם על גבה, כאילו זו נותנת
        הסכמתה למעשה הבת השובבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד נשאר לחלוב את "זלפה", פרה קטנה, מיושבת ושקטה.  אחרי-כן מוּצאות כולן
        מן הרפת ומובלות אל העדר.  רצתה לקרוא לברוך, אבל כיון שמצאה אותו ישן
        עדיין, הלכה בעצמה.  מצליפה היא עליהן במקלה צליפות קלות ופונה אליהן בדברי
        חבה-תוכחה כשהן מנסות לשטות מן הדרך הישרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אצל השוקת עזבה את בהמותיה בתוך כל העדר ושבה דרך המושבה הגדולה. בגנה
        אחת, סמוכה לבית גדול, ראתה צעירה עוסקת בהשקאה וקנאה מפני שפע המים במקום
        הזה.  מהצנור הארוך שבידי הצעירה קלח זרם עליז על ראשי הצמחים והדיח מעליהם
        את האבק.  הפרחים נהנו הנאה מרובה ונשאו ברכה על רחצת בוקר קרה ונעימה
        זו.  חלוץ רחב-כתפים, שעבר סמוך לגן, קבל גם הוא מקלחת ונרתע הצדה, בהעיפו
        על הצעירה מבט מלא חן עם שמץ צניעות.  הנה גמרה שם הילדה, הרימה עיניה
        ועמדה תוהה: אנה נעלמו הצפרים מן הגן?  – האינן אוהבות קלוח קר?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        > שרה עברה את סמטת-הצבר והגיעה עד השדה המפריד בין המושבה הגדולה ושכונת
        העובדים.  בשדה שורר עוד האביב בתקפו: הככר מלאה עשבים, פרחים
        ושבלים.  בעצי השקדים של כרם סמוך כבר נראה פרי זעיר, ירוק ורך;  התאנה
        והתות לבושות מכלול יופי, גם על הגפן רבה התפארת.  מקרוב נראית לה שכונתה
        על גגיה האדומים ולובן קירות בתיה הקטנים. ברק החלונות, המוצפים קרני שמש
        בוקר עליז, מעיר בה חדוה.  זהו כפרם, כפר יהודי, שהיא נחשבת בין בוניו –
        האין זה חלום?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל יש לה גם רגעים של ספקות ואז היא שואלת את עצמה, אם כל אותה יגיעה
        בירקות, פרות ותרנגלות, קשורה באמת ברעיון הגדול, המכונה "תחית עם"? איזה
        קשר ישנו בין רעיון נשגב זה ובין העבודה הפשוטה, הגסה של יום יום?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשברוך מראה איזה רגש של רוֹך, היא מסיחה לפניו את לבה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה מוזר הדבר, – אומרת היא לו – שאת העבודה החקלאית, אשר אנחנו מחפשים
        בה קודם כל את לחמנו ואת קיומנו, רוצים אנו לצרף לאידיאל נשגב וקוראים לה –
        תחית העם בארצו!  הרי כל העמל אינו בא אלא לאפשר את החיים, כל עמלנו אינו
        אלא לפינו, ואחרי כן אנו מרמים את עצמנו ואת האחרים בהתפארוּת, שדבר רב-ערך
        במפעלנו..</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא נכון הוא, שרה, שאת מוצאת רמיה במפעלנו.  הלא זוהי באמת התחיה וזה
        הפלא, שעם נודד אלפים שנה שב אל אדמתו, מרוה אותה בזיעתו, מחיה אותה, ומשיב
        לה את כחה.  חלשים כחות העם, שהגלות אכלה אותו, אבל על-ידי עבודת החקלאות
        ישוב הגוף הכפוף ויזדקף, יתפתחו השרירים ויתחזק חוט השדרה לאמץ כחות
        נרדמים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עיני ברוך אורו בדברו את הדברים האלה.  הוא התלהב והוסיף:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הלא לעינינו מתנער ומזדקף הנוער. קבוצות-קבוצות מתחברות להחיות את
        הנשַמות.  יפה אמרה נואמת אחת, האוהבת את דברי הנביאים: "קו לקו – אמרה –
        צו לצו, זעיר שם, זעיר שם".  וראי: הנה גם לבין הרים שוממים ופראים חדרו
        הקבוצות ליצור נקודות-ישוב.  סלע זקוף על ראשם והם חוצבים בהר, זורעים על
        צלעותיו, נוטעים, בונים ויוצרים חיים באמרם: "ארץ זבת חלב ודבש היתה
        לאבותינו, ועלינו חובה להחזיר לו את כבודה הראשון, להקים חלום
        הדורות".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא הקשיבה לדבריו בהתפעלות והערצה.  הביטה בפניו שטושטשה צורתם הנורמלית
        על-ידי העין החסרה והתעקמות הפה, והרהרה: הנה מקור אהבתי אותו! הכל היא
        שוכחת, הכל סולחת בשעה שנובע מלבו מעין מחשבה טהורה כזו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא יודעת שאין להתחרט.  אמנם התכוננה להוראה, אבל החקלאות עדיפה
        ממנה.  כשבקרה בנֵכָר קורסים פדגוגיים ועסקה בציונות, לא ברורה היתה לפניה
        הדרך, לא ידעה את ערך העבודה, עבודת האדמה.  כעת הכל מובן לה: עם לא יקום
        בלי אדמה ובלי עובדיה.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פעם יצאה שרה לחפש שיירי עשבים בשביל תרנגולותיה והתרחקה מחצרה. לקטה מפה
        ולקטה משם, עד שעלתה על הגבעה ומכאן הסתכלה על פני הסביבה. לימין משתטח בית
        הקברות עם ברושיו הזקופים, משמאל שורה של גבעות מכוסות כרמים, ולפנים, מצד
        מזרח, משתלשלים הרי יהודה עטופי סוד הדורות הרבים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בהטותה צפונה ובהוסיפה לעלות, התגלה לפני מראה חדש: ככר גדולה זרועה
        פרדסים.  מרחוק מתפתל כנחש כביש לבן ואוטומובילים רצים שם כשדים זה אחרי
        זה.  בפרדס הקרוב לגבול היא רואה עצי תמרים גבוהים, הפורשים כפותיהם על
        בנין תפארה, אשר בשמשותיו הצבעוניות מצחקת החמה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה נועצת עיניה במראה-הפלאות הזה ובלבה צפה שאלה: מי גר בתוך מסתרי ההיכל
        ההוא – בני-מלאכים או בנות-השיר?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כששבה עם סל מלא עשב אל חצרה היה לבה טוב עליה, ובראותה את ברוך מרחוק
        חפצה לפנות אליו בהלצה: "הנה עשב, ואתה – הַב בקשיש, יא חוג&#39;ה!" אבל
        הבדיחה קפאה על לשונה, בהתקרבה אליו ובראותה שעל פניו המעוקמים מצטַיר
        הזעף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– מה קרה, ברוך?  האם איזה דבר לא כשורה אצלנו?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מובן שלא כשורה – ענה הוא בזעף קולו – אם אני לא אצא מן הבית ולא אכלא
        את העגלים איש לא ינקוף אצבע.  הנה העגל הנבזה שלך שתה כבר את כל
        החלב. עבודת-הנשים היא תמיד ככה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרגישה באשמתה הפעם ולא יכלה לתפוס כיצד שכחה לכלוא את העגל לפני צאתה את
        החצר.  על-כל-פנים הלא יכלה להזהיר על זה את ברוך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואף-על-פי-כן היטב חרה לה על הבטוי הקשה והבלתי צודק  – כי בלעדיו אין איש
        נוקף כאן אצבע.  הזהו התשלום בעד כל עמלה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא לא אמרה לו כלום, רק עשתה את עבודתה במהירות קדחתנית, כאילו שד עומד
        על גבה, דוחפה ומזרזה.  בשעת ארוחת-הערב עצרה בקושי בעד דמעותיה. נחירי אפה
        התרחבו ואת שפתיה נשכה בעקשנות.  ברוך לא חש כלל בהתרגשותה, היה שקוע
        בקריאת עתון ולא עלה על דעתו, כי במשפט אחד, שבטא בלי דעת-נפש, יעמיק ככה
        את כאב האשה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האויר בקרבתו החניק אותה ממש. היא נכנסה לחדר השנה, נפלה על מטתה וכאן
        הריקה את דמעותיה לתוך הכר הלבן. כשהוקל לה התבהרה מחשבתה ותחל להוכיח את
        עצמה על התרגשות יתרה זו בגלל דבר, שאינו חדש, החוזר תמיד.  הוא מתיחס אליה
        שלא בצדק, מפני שחולה הנהו, מפני שסובל הוא מאסונו ולכן קשה לו לשלוט
        ברוחו.  אבל היא, הבריאה ברוחה ובנפשה, למה תזיק לעצמה ולבריאותה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מה משונה בכלל השפעת הרוח על הגוף!  פגיעה קלה בנפש האדם ופגעי הגוף באים
        לענותו.  הנה הכאב בצד כבר מורגש, כבר נוקב!  או כואבת נקודה קטנה בגוף,
        לכאורה בלי כל תפקיד במערכת האברים – וזה גורם גם לירידה רוחנית;  הנה נחלש
        אצלה כח הזכרון במדה כזו, שהיא חוששת לפעמים לטמטום גמור.  אבל כאן אולי לא
        האפנדיציט אשם, אלא זה שנתקה מן הספר, ומובן מאליו, שאם אדם אינו עולה, בעל
        כרחו הוא יורד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אמנם אין לדרוש מן החיים יותר ממה שהם יכולים לתת.  אבל הלא חייב אדם
        לשמור על עצמיותו, על רכושו הרוחני לבל יתמוטט.  לא לתת לגלי החיים
        שיכבשוהו במסגרת צרה עד כדי מחנק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומחטטת היא הלאה בחייה עם ברוך: – האם יש אף צל של ספק באהבתו
        אותה? ואף-על-פי-כן כמה מן העלבון ומן הצער הוא גורם לה.  פעם כַּעַס סתם,
        פעם קנאה, טינה, ואולי גם שנאה לרגע, כי בלי זו אי-אפשר שיטפטף בלי רחמים
        את הרעל לתוך נפשה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכלל תמוה הוא אפים של הגברים לאהוב ולשנוא כאחד, לחזור אחרי האשה, לבקש
        קרבתה ויחד עם זה – לרמוס את כבודה.  הן מופרך הדבר – לבזות את זו שאוהבים,
        שמתקשרים עמה בחיים, יוצרים עמה את המשפחה, את המעמד, שבונים יחד עמה את
        העתיד.  משפיל אדם את חברת חייו, ובאותה שעה דורש הוא שהיא תחלק לו כבוד,
        מבזה אותה ותובע שכל נפשה ומחשבותיה תהיינה נתונות רק לו לבד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כמו בחיים כן גם בספרות היא מוצאת אותו היחס ללא יושר, ללא צדק.  היא
        רגילה לרשום בשעת קריאה פסקות שלמות, הנוגעות בכאב האשה.  על נורה של איבסן
        רשום בפנקסה ככה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"במחזה האחרון מקסימה נורה ממש בדברי האמת העמוקים והפשוטים שלה. כשהלמר
        אמר לה כי "אין איש מקריב את כבודו בעד האשה שהוא אוהב", עונה היא בפשטות:
        "ואת הדבר הזה עושות רבוא רבבות נשים!" כמה טרגיות וכמה אמת!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אצל מופסן ב"עזה כמות" עמדה על קנאתו של אוליבר ברטין: – "בכל עת שהתבוננה
        אל איזה דבר, שהללה אותו, חבבה אותו, שאפה אליו, – עוררה בקרבו רוח
        קנאה.  הוא קנא אותה כל הימים בחשאי על כל דבר, שעמדה עליו, ששמה בו עיניה,
        שמשך את לבה, ששמח את נפשה... קנא אותה, – כי בשעה שגבר אוהב אשה, קשה לו
        מנשוא, כי תבוא להרהר אף הרהור קל באיש אחר, לבו מלא רצון כביר להיות
        בעיניה יחידי בעולם כולו".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >דברים כנים ונכונים – הוסיפה על דברי מופסן שרה זרחי – אבל האם ישתנה פעם
        הדבר לטובה, והטבע, היוצר מגן לכל בעל חי לעמוד נגד מתנקשיו, – יתן גם לאשה
        בזמן מן הזמנים את אמצעי ההגנה על כבודה, או היא תגשש הלאה בערפל חייה באין
        מוצא?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">פסקות כאלה לרוב רשומות בפנקסה וחרותות על לוח לבה.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה אוהבת את פרחי האזדרכת, כשהם זָעים בחלונה. הנה הענפים העבותים מפילים
        את צלם עליהם והם עוד יותר מפליאים ביפים;  מופיעים עליהם גידים אדומים,
        והנה גם אשכול שלם הצטבע בצבע של דם והיא מסתכלת בעין חודרת ומבקשת את סוד
        חליפת הצבעים בפרחים.</span
      >
    </p>
    <p class="c7" dir="rtl">
      <span class="c1"
        >קול שירה עלה פתאום מקצה הרחוב. היא הציצה בחלון ותרא את ילדי הגן עם
        "הגברת" בצאתם לטיול.  מיד נע איזה דבר בפנים הלב, עלה עד הגרון, לחץ
        וחִנק.  היא התכנסה פנימה, נאחזה בקיר ולחצה אליו את מצחה.  דמעות חמות
        התפרצו מעיניה וכל גופה רתת: – הלא הילדה שלה, היקרה, החביבה שלה, יכלה
        להיות עכשו בגן-הילדים!  אַיֵך, פעוטתי, הן לא אוכל בלעדיך, לא אוכל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובלחשה ככה כשלו רגליה, התנודדה כשכורה.  ובגשתה אל כסא ישבה עליו דוממה
        וראשה מורד, עד שנעלמו קולות הילדים במרחק. אז קמה, נאנחה אנחה עמוקה, זקפה
        קומתה ותלך לרחוץ במים קרים את פניה הלוהטים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >העבודה קוראת לה והיא יוצאת לנקות את הדיר ואת הלולים.  כאן יש מקום
        להתרגזות, כי כל הבריות, למן העז הקטנה עד העגלה הבכירה, כאילו נדברו
        ביניהן לחבל ולהשחית, זה את הקש וזה את התבן. והנה התרנגלות התגנבו בעד
        הפרצה אל חבית הסובין, מנקרות ומלכלכות אותם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פתאום מתפרץ עגל אל הגן לעשות שם שמות, או בורח אל המושבה לפגוש את האם
        ליהנות מחלב שדיה ולקבל ממנה לקיקות-נשיקות.  שרה צריכה אז לרוץ ברחובות
        ובסמטאות כדי להחזיר את השובב.  ולפעמים אחרי החליבה היא עצמה עומדת ולומדת
        את אהבת הפרות לפרי בטנן.  יש שהפרה שוכחת את אכילתה ואת שתיתה ומתמסרת
        כולה ללקוק הולד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחצר רבה העבודה.  אחר נקוי הדיר באים הלולים, ופעם בשבועים – גם השובכים,
        שבהם העבודה אינה קלה כלל, כי עליה להביא סולם, ולעבוד במכשירים. יש שגם סל
        מלא תבן היא מעלה אתה, כדי להציע קנים חדשים, ולטפס עם כל זה למעלה סכנה
        היא, אבל היא עושה זאת במרץ רב, וברדתה מן הסולם בהצלחה, היא מתפלאה על
        עצמה: האמנם נתחדשו עליה נעוריה, שהיא מטפסת כילדה צעירה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומרץ רב היא שופעת גם מחוץ לעבודות משק.  לפעמים היא מתמסרת כולה לחובות
        צבור שונות,  כשרוצים יש מה לעשות: יש קרן-היסוד, קרן-קימת,
        תוצרת-הארץ.  לתנועה זו אין עדיין השפעה נכרת על הצבור, ושרה לקחה עליה
        להפיץ את הרעיון במקומה.  היא מדברת מתוך הכרה עמוקה על זה, שהצלחת הישוב
        דורשת כי האמצעים שלנו יזרמו פנימה ולא חוצה דרך כל הצנורות.  מדגישה היא,
        כי כחוט השני עוברת בשלשלת הישוב הופעה מעציבה זו, שכל הכסף הנכנס אל הארץ,
        רק אחוז מעט ממנו שוקע על קרקענו, ברובו הגדול הוא מתפזר בין שכנינו, או
        חוזר לחוץ-לארץ.  בעשרות המיליונות, שהשקיע, כפי שאומרים, הנדיב הידוע
        בבנין העם, התבצרו הכפרים הערביים מסביב למושבות ונתעשרו עריהם,  אלה
        מעבודה אצל היהודים ואלה ממסחר ומבתים, שהשכירו לבני עמנו במחיר רב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומוזר הדבר ומעציב, שגם בתקופה זו, שהישוב גדל והתרבו הנקודות החקלאיות
        ומשקים רבים מעמידים תוצרת משלהם – עוד הולך ונמשך כמעט באותה הצורה סדר
        החיים הקודם ובני עמנו קונים את רוב צרכיהם מידי אחרים, – האמנם נטול כשרון
        הוא עמנו ליצור לו ערכי חיים שלמים, שלא יהיה זקוק לאחרים?  הזהו העם החכם
        והנבון שאת ארצו הוא הולך לבנות, את עמו להחיות?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומדברת שרה בלעג מר על זה,  שכל ציוני ותיק פנקסו מלא מספרים ובקי הוא
        בהכנסתם של ענפים רבים.  יודע הוא על כמה דונמים אפשר להתקיים בארץ ישראל
        וכמה כסף דרוש לכל משפחה להתישבותה. הכל יודעים אנו למנות ולספור, רק דבר
        אחד נעלם מאתנו: שכל עוד לא על ידינו יוצר קיומנו, אלא על ידי אחרים,
        וכספנו ילך לבנין עולמם של אחרים, – אנו לריק ניגע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה זרחי משתדלת להפיץ את דעותיה בסביבתה הקרובה, אף כי יודעת היא שזוהי
        שטות, – כי מה האנשים האלה מוציאים?  מסתפקים הם במועט ועובדים
        בעצמם.  צריך היה לבוא בדרישותיה לפני הקהל שבמושבה הקרובה.  שם שוק,
        שהערביים מוציאים ממנו יום-יום סכומים גדולים, שם לובשות הנשים שמלות משי
        וגרבי משי.  שם יש דרישה לנשפי רקוד, לראי-נוע, תיאטרון ולמטרה זו רצות
        המכוניות העירה בכל מוצאי שבת וחג, שם רוב הכרמים עוד מעובדים בידי השכנים
        שיש להם אדמה וגנים ובתים, ואינם זקוקים לעבודת יהודים כלל.  הם ממלאים את
        הכרמים ובאותה שעה צריך הפועל היהודי להסתתר בקרן זוית מאפס עבודה, מאפס
        יכולת להשתכר פרוסת לחמו! </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– איך לחדור שמה ולפקוח את עיניהם לראות נכוחה?  – שואלת היא לפעמים את
        החברים וברוך עונה במקום אחרים בלעג:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– בעיקר תעשינה הנשים נפלאות, – עליהן אפשר לסמוך! </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשכונה נערך נשף לטובת הקרן הקימת ושרה קבלה הזמנה להשתתף בו. היא דברה שם
        על ערך הקופסה הכחולה ועל התפקיד שהאשה יכולה למלאות בנוגע לזו.  עוד מלפני
        דורות קשורה האשה העבריה לקופסת הצדקה;  זוהי הטריטוריה הלאומית שלה מקופת
        ר&#39; מאיר בעל הנס והלאה.  לקופסת הקרן-הקימת צריכה כל אשה מישראל לקבוע
        מקום בביתה ולדאוג לתרומה קבועה, בפנה זו יד האשה תגבר תמיד לכשתרצה, ובכן
        היא נעשית שותפת בבנין הארץ.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חובתה הקדושה היא בעיקר להרגיל את ילדיה להתיחס בחבה לתרומות לקרן-הקימת
        ולהאציל לקופסה כל פרוטה הנקבצת על ידם;  היא וילדיה יחד צריכים להתקשר
        בקשר אמיץ אל הקופסה, לותר על מותרות לטובתה ולזרוק שמה כל עודף
        בפרוטה.  הקופסה צריכה לפאר את ביתה, להמצא לעין רואים בראש כל קשוטי
        הקירות, כי ממנה יתד ממנה פנה לבנין העם בארצו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנשף היה בגן הילדים ובסופו בקשה הגננת להגיד מלים מספר על הגן הצעיר ועל
        שיטת החנוך החדשה.  שרה שמעה בהתענינות מרובה את הדברים השקטים, הנאמרים
        בנחת:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"...הטבע נתן לתינוק אם, המחנכת אותו בדרך טבעית;  גם שיטת החנוך בגן
        הילדים צריכה לאחוז דרך זו וליצור לילד סביבה טבעית בלי שום
        מלאכותיות.  חובת הגננת היא להמשיך את תפקיד האם ולתת לילדים חופש התנועה,
        חופש הפעולה. עבודת הגן על-פי השיטה החדשה דומה למִזוג צבעי הקשת השונים
        להרמוניה שלמה;  למוסיקה, המחוברת מצלילים ליצירה אחת".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לשרה נדמה ברגעי הקשבתה, שהיא עצמה מורה, שהיא עצמה מדברת בחבה על החנוך
        ונמצאת שוב בגבולותיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחרי כַּלוֹת הגננת את דבריה עלתה שרה שוב על הבמה לענות והיא דברה על
        גדולי המחנכים בעולם, שכל אחד הלך לשיטתו הוא.  לבסוף בקשה סליחה מן הקהל,
        שהנואמת הקודמת וגם היא הרשו לעצמן לנגוע בשאלה, שלא עמדה על סדר
        היום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כאלה היו ימי הגאות אצל שרה, והנה בא השפל, באה ירידת הכחות, וזה היה
        באשמתה וב"טפשותה", כדברי ברוך. והמעשה רגיל: היתה לה כביסה גדולה ותרץ כל
        היום מן הבית אל החצר ומן החצר אל הרפת וחוזר חלילה.  היא נשאה דליי מים,
        גם הרימה לגינים כבדים מלאים לבנים, והתוצאה היתה, שבלילה תקפוה מכאובים
        חזקים בצדה וצריכים היו להבהיל אליה את הרופא, שראה צורך בנתוח ויעץ לפנות
        למומחה ידוע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לא שמעה שרה בעצת הרופא, ובקומה אחרי איזה ימים ממטתה, הסיחה את ליבה מכל
        הענין, אף כי היתה חלשה מאד והתהלכה ברפיון גמור.  ביחוד קשה היה לה בשעות
        הבוקר, שלא יכלה להתחיל בשום עבודה, צועדת איזה צעדים ועומדת באמצע, הראש
        מתחיל להסתובב והיא צריכה להאחז במה שלא לנפול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עתה יש שלבה מתרכך ומאיזה מקור בלתי ידוע נובע צורך לפייס את מי שהוא,
        לבקש סליחה ומחילה.  היא מתחילה לדאוג יותר לברוך, שהוא לפי דעתה זקוק
        לטפול יותר ממנה.  אבל עצם התמסרותה לבריאותו מרגיזה אותו, כאילו היא עושה
        לו רעה, בהגישה לו כוס חלב, שלא בשעת הסעודה, או בבקשה ממנו לשכב בשעה
        מוקדמת, – חטא היא חוטאת בזה ולא יסָלח לה.  עוד רע מזה, שהיא עצמה אינה
        סולחת לה, שאינה מסוגלה לרדת לסוף דעתו ואינה יודעת לכון את יחסה שלא תרגיז
        אותו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא מונה את עצמה בכל מיני אשמות כלפי בני-אדם, – האם עזרה דַיָהּ?  האם
        יצאה תמיד ידי חובתה?  האם נתנה במדה מספקת מה שנתנה ולא נשארה חייבת
        כלום?  לבה אינו עונה בחיוב על השאלות האלו, להפך – הלב דואב ואומר שהיא
        קמצנית וצרת-עין ואין יחסה טוב לאשרם של האחרים ולעליתם במעלות
        החיים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זכרה את הוריה.  הנה גם מהם עליה לבקש סליחה ומחילה, על שלא עשתה רצונם
        והדאיבה את רוחם.  לא רצו שתנשא לברוך, דרשו שתנתק את הקשר אחרי בוא עליו
        האסון, ולא שמעה בקולם.  ההיתה זו גבורה או חולשה לשמור על אהבת נעורים, על
        קשר שנארג במשך שנים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יש שהיא חולמת עוד לפַיֵס את ההורים, למשכם אל צדה ולהביאם לכאן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הזיה טפשית! – אומר לה ברוך, כשהיא מסיחה לפניו מחשבה זו –  לעולם לא
        יצא הדבר אל הפועל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מדוע ברוך?  הן כך נארגים כל החיים: מתחילה הזיה, אחר מעשה ועוד מעשה
        וסוף-סוף יעמוד האדם בפני התגשמות שאיפותיו. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ברוך לא שמע את דבריה, כי המשיך לקרוא בספרו, וכשהיא, מתוך עלבונה,
        העירתהו על כך, זרק לה בבטול:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– יש מה לשמוע בפטפוטי נשים!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">שוב נוקבת גסותו כמחט בבשר החי, והיא נשארת כאלמת.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בריאותה הוטבה וכחותיה שבו במדה כזו, שהיא כבר יכולה להגיש עזרה לחולים
        אחרים.  נפלה למשכב שכנה טובה וילדה הקטן אינו נותן לה מרגוע לא ביום ולא
        בלילה.  בשעת חומה הכי חזק עליה לקום להאכילו, להשתיקו, לרחצו
        ולישנהו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה התחילה להביא שמה עזרתה והיתה נרגזת מאד מיחסו של הבעל לשתי הנפשות
        הסובלות.  הוא היה הולך לו בכל ערב לאספות או לספריה ולא איכפת לו כלום
        הנעשה בבית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשאשתו היתה מעירתהו על חובות המשפחה, היה זורק תשובתו בכעס:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אחרי עבודה במשך יום תמים, כמדומני שיש לי הרשות לעשות בזמני
        כחפצי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם בבוא יום השבת וחופשי היה – לא נחו רגליו בבית, שוב עזב את המשפחה והלך
        באשר הלך.  וגם כשישב שעה קלה בבית, היה שקוע בקריאה ולא התענין בחולה או
        בילד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">פעם לא התאפקה שרה ואמרה לו בכעס כבוש:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מר דוליצקי, ירשה נא לשאלו שאלה: האם היתה גם אשתו מתיחסת אליו ככה, לו
        נפל הוא למשכב?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– ומה היתה עושה לי?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– היתה עושה את הדרוש להבראתו, ולא היתה מסיחה אף רגע את דעתה ממלוי
        חובותיה לחולה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– ואני?   החושבת היא שלא איכפת לי כלום?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– מעשיו מוכיחים!  ואולי איכפת לו חוסר הנוחיות שלו.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שפתו השתרבבה, ונכר היה שהוא מבליג על עצמו מנזיפה על התערבותה בעניני
        משפחתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וגם היא כבשה מה שתסס בלב: הנה זהו המין החזק, הגבורים, מניעי גלגל העולם
        – אך חסר אצלם העיקר, </span
      ><span class="c2">הלב חסר</span><span class="c1">.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהוטב לחולה דברו שתי הנשים ביניהן בערב אחד על מצבה העגום של האשה
        במשפחה ובחברה.  השכנה הסיחה את לבה על רוע מצבה ותוסף:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לפי דעתי, האשה בעצמה אשמה באסונה.  היא היתה יכולה לתקן את החיים, לו
        ידעה איך לגשת לדבר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– למשל?  – שואלת שרה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– קודם כל אִחוד הכחות ותכנית מסוימה להטבת חייה של האשה.  לא
        האָמַנציפנטיות, העומדות מחוץ לחיי המשפחה, יכולות להצליח.  אלא דוקא הנשים
        הנשואות.  הן הן שצריכות, – ואם תרצינה גם תוכלנה – להכריח את הבעל שיכיר
        באשה את האדם ובזה יוסר מעליה כתם העבדות.  רק לאשה נשואה ישנו כלי הזיין
        הדרוש למלחמתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא נכון.  גברת דוליצקי –  עונה שרה – דוקא האשה הנשואה נטולה כל כשרון
        מלחמה, מפני שאהבתה ומסירותה שוללות ממנה כל התנגדות.  אמנם אמרו חכמינו,
        שאשה כלי זינה עליה.  אבל דברים אלה אמורים ודאי על כבוש אהבה בלבד, ולא על
        חלוקת כבוד לה או הכרת ערכה. בכל הדורות נמצאת האשה בשפל המדרגה ודרך
        לשחרורה עד היום לא נמצא.  את אומרת כי דרוש אחוד,  – הנה נשים מארבעים
        מדינות כבר מאוחדות לשם הגנה על זכויותיהן.  והתוצאות עדיין רפויות מאד,
        ומי יודע איזה דור יזכה לגאולה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הן דברו שתיהן עד שעה מאוחרת, וכששבה שרה הביתה מצאה את ברוך מרוגז קצת –
        הוא רוצה לישון והמטה אינה מוצעת.  אף פעם איננה בבית כשיש צורך לעשות איזה
        דבר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ולך אין ידים, חס ושלום? – העזה לענות והתחרטה: הוא מסכן, מסכן.  היא
        אשמה, או אמו אשמה, שהרגילוהו למצוא הכל מוכן לפניו.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ליל שבת.  שרה וברוך הולכים להרצאה, שנערכה מטעם ועדת התרבות. הם אחרו קצת
        לבוא, מצאו את החדר מלא אנשים מפה לפה ונשארו עומדים על הסף. שרה הטתה אוזן
        להקשיב ולא יכלה לתפוס את החוט המקשר את דברי הנואם.  עיניה תעו מסביב
        ונתקעו בארבע תמונות שעל הקיר, ביניהן הכי גדולה – תמונת הרצל. למטה הימנה,
        לצד ימין – ברנר, לשמאל – טרומפלדור, ובמרחק מקום קצת נשקפה יחידה – תמונת
        א. ד. גורדון.  נשמות גדולות!  את כולם העריצה.  אלה מתו, אלה הומתו, אבל
        ארבעתם נפטרו פטירת קדושים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנואם מדבר בהתלהבות על התחדשות הישוב, על התנועה החלוצית, על עליה
        גדולה.  לא יוסיף עוד העם לתעות במדבר ולגשש באפלה – עכשו הדרך כבר ברורה,
        המסלול ישר.  – – ושרה שומעת ואינה קולטת את התוכן כולו, כי מחשבתה עדיין
        עסוקה בתמונות האנשים שהיא רואה על הקיר ובסוד חייהם ומותם...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשנגמרה ההרצאה היו שרה וברוך זרחי הראשונים ליציאה.  בחוץ נפגשו עם סוני
        גולדמן, חברת ברוך בועדת העזרה לחולים כרוניים וילכו שלשתם יחדיו, כשברוך
        עם סוני מדברים על עניניהם הם ושרה שקועה בהרהוריה.  פתאום עמדה והניחה
        לזוג שילך קדימה;  עינה נמשכה אחרי מראות הקסם שמסביב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היה הירח במלואו, שהשקיף בגאוה ממרומים ושלח את אורותיו הרכים לכל
        הפנות.  דקל יחידי שבפנת הרחוב צִיֵר את כפותיו על גבי האדמה בעגול;  הלאה
        מעט מצויר על החול לרוחב כל הדרך אקליפטוס צעיר, ולאורך הרחוב משתקפים על
        פני החול עצי הגנים, כמו בתוך ברכת מים. דומה היתה התמונה לאיזה נוף שקט על
        חוף ים. ושרה נזכרה בתמונה דומה לזו, שראתה פעם באיטליה על חוף הים בעזבה
        את העיר ברינדיזי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא שולחת מבטיה למעלה, למטה, לצדדין ועינה אינה יכולה לשבוע מהקסם הנפלא
        שמסביב.  בינתים הגיעה עד חצרה; סוני וברוך הרחיקו ללכת והיא נכנסה בשער
        והשתטחה על ערמת החציר הריחני.  גם כאן שלטו קסמי הירח ומבעד לענפי
        האקליפטוסים שעל ראשה התנועעו רבבות זהרורים.  הכוכבים התערבבו בזהרורי
        העלים.  הכל התנועע יחד וקשה היה להבדיל בין עלה נוצץ ובין כוכב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתוך דממת הלילה הגיעו לאזניה פתאום דפיקות נִשנות ורעש עמום: טו, טו! טו,
        טו!  זה נושם בכבדות קטר הרכבת העוברת מקנטרה ללוד.  והנה באחת האורוות
        נוער חמור, ששמע את שריקת הקטר ואומר לעונות לעומתו, – כנראה רגשן הוא יצור
        זה, ובעמדו קשור בשרשרת אל האבוס – ער לבו לכל הנשמע מבעד לקירות האבן
        העבים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נכנס ברוך אל החצר, ראה אותה על הערמה ויגש גם הוא לשם, השתטח, הטה פניו
        אל החציר ושאף אל אפו את ריחו החריף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">מבלי משים פלטה מפיה אנחה עמוקה וברוך שאל:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– אנחה זו למה היא באה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– גם אני שואלת למה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– האם אינך מרגישה את עצמך בטוב?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– עכשו אין כל כאב – ענתה קצרות.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לאור הירח ראה ברוך כלנית מתנועעת בגדר על גבעול ארוך, קטפה והביע את
        תמהונו שבעונה מאוחרת זו עוד נשמרה בשלמותה.  הפרח הזה הזכיר לו את עליתם
        ירושלימה ברגל עם חבורה לחג הפסח. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– הזוכרת את, שרה, את כִּכַּר הכלניות בין ההרים?</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1">– לא.</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הוי נשים, נשים.  ואני לא אשכח לעולם את המראה ההוא.  בכל הארץ שלט
        האביב בהדרו.  רק הרי יהודה עמדו במקומות רבים שוממים ועזובים.  פתאום
        ראינו את הככר ההיא הפורחת, המצובעת בצבע של דם, כאילו דמם של גבורי יהודה
        השתפך במקום הזה.  אכן יפה היה המקום עד להפליא: שרידי יערות עתיקים בצלעי
        ההרים, חורבות, יערות מזבחות ופתאום הופיע לנגד עינינו ישוב יהודי רך
        וצעיר. נדהמנו למראה ואמרנו: גבורי ישראל, מעשי אבותיהם בידיהם, באים הם
        לחצוב להם קן, ליסד ישוב בהרים עזובים, בין סלעי מגור אלה.  האם לא דם
        אבותינו הראשונים, הנשפך במקום הזה, החיה את העצמות היבשות של הדורות
        הרחוקים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה הקשיבה מתוך רטט.  בדברו ככה, הרי היא אוהבת אותו ושוכחת את כל הצער
        והעלבון, שהוא גורם לה.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בעבור שרה את חצרה, היא מסתכלת בכל הפנות ושואלת: מה יפה כאן
        יותר: האפרוחים או הפרחים, עצי הגן או זמרת הצפרים?  בעיקר כולם אהובים
        וחביבים עליה בלי הבדל, כי החיים אחוזים וסבוכים במדה שוה בכל הנמצא בפנה
        זו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפניה עוברות בחשיבות "ברורה", המפליאה אותה בגבהה ובזקיפותה.  יש מזל
        לזו, כי באה לעולם בשעה שהיה שפע בחלב, ונתנו לקטנה לשבוע מעטיני אמה כאות
        נפשה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התרנגולות הזקנות מתהלכות כמלכות ועדיין מרבות בהטלת ביצים.  התפתחו וגדלו
        האפרוחים של כל חמש הדוגרות; הראשונות כבר פרגיות ומראה בוגר להן – אולי
        תטלנה בקרוב?  מה שנוגע לתרנגולים, היתה שרה רוצה לשלוח כמה גברים ללמוד
        מהם דרך-ארץ, כי די לה להופיע בחצר העופות עם כדורי מזון טוב, מיד קופץ כאן
        תרנגול, עומד לשמור וקורא את חברותיו לסעודה.  גם אחר, העומד מרחוק, עוזר
        לכנופיה שלו לחטט בעפר ולמצוא פתותים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כך הוא רק ביחסם למין היפה.  אבל יחסם זה לזה מחריד ממש.  קנאה ושנאה בלתי
        פוסקת שוררת ביניהם – הם רודפים ומכים זה את זה עד זוב דם.  אפילו את
        האפרחים הקטנים הם פוצעים בלי רחמים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל גם בין התרנגולות היא רואה שלא הכל כשורה: יש כעס וצרות עין וקשיות
        לב, ובשביל גרעין מעופש או תולעת זוחלת, ובעיקר בעד מקום של כבוד בלול, קמה
        קטטה ומריבה לנקור בראש ובגב בזעף ובחימה שפוכה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וצער אמתי גרמה לשרה דוגרת אחת, שהתחילה פתאום לנקר באפרוחיה בעודם קטנים
        ותחל לרַדֵף דודים, טרם הספיקה לגדלם כראוי.  אבל הפרוצה נענשה עונש קשה,
        כי בהתחמקה פעם מילדיה ובקפצה מעבר לגדר לבקש אהבה מחוץ ללול – נטרפה בידי
        שועל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לעתים קבועות מנקה שרה את הלולים נקוי מוחלט, – מרחצתם ברותחין, בסבון
        ובנפט ומסידתם מבית ומחוץ עד שכל צורתם מתחדשת;  אבל יחד עם זה מתחדש גם
        הכאב שבצדה.  היא סובלת מאד, מתחילה לחשוב על הנתוח שהוצע לה, אבל היא
        דוחה: אין פנאי, יעבור גם ככה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פעם, בשעת החליבה, הרגישה כאב חזק בידה הימנית, החליבה עלתה לה ביסורים
        קשים, הכאב נראה רציני מאד והלב נבא תוצאה רעה ממנו.  מי יודע אולי לא תוכל
        לעבוד יותר?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכאן נזכרה באחד הציורים של עליזה אורז&#39;שקובה, המתארת את מצב נפשה של
        מזמרת, אשר אבדה פתאום את קולה והוכרחה לרדת לנצח מעל הבמה.  ובעוד ציור
        אחד נזכרה, גם כן של סופר פולני, המתאר את הטרגדיה של כַּנָר מפורסם אשר
        בשעת הקונצרט הגדול, – עברה רוח פרצים על הבמה וגרמה לשִתוק ידו ולשקיעת
        שמשו לעולם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אם שרה זרחי תאבד את ידה ותוכרח לרדת מבמתה הקטנה, לא תשמש כמובן נושא
        לספור, אבל מן הטרגיות יהיה כאן די והותר.  ככה חשבה אז, אך הפחד היה פחד
        שוא.  הכאב עבר מהר ועוד באותו לילה לשה את עיסתה כדי לאפות למחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >השעה מאוחרת.  היא שבורה ורצוצה, נושמת בכבדות במטבחה הצר, אבל לשה בשקידה
        עד שהזיעה מטפטפת מפניה.  יותר מן העבודה מציקה לה פנה קטנה זו, שהקצו
        למטבח בעלי-הבית הראשונים.  אפילו חלון עם שמשה לא קבעו פה, רק תריס בלבד,
        שנפתח בקושי.  כך דרכם של בני אדם בבנותם בית: לדאוג לכל חדר, שיהיה בו אור
        ואויר, רק חדר-הבשול לא איכפת להם אם צר הוא, לח ואפל.  ואם האשה מבלה כאן
        את רוב יומה בעבודה מפרכת, אם היא נחנקת מחוסר אור ואויר – מי דואג וכואב
        על כך?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כאלה וכאלה הן מחשבות שרה בשעת הלישה ונתינת העיסה לתוך הדפוסים.  רגש של
        בדידות תוקף אותה בשעה מאוחרת זו עם האפלה שמסביב.  בתוך הבתים הקטנים
        ישנים בני-אדם עיפים;  בכל השלך הס, רק בלבה פנימה פועמות דפיקות חזקות
        ותוגה חרישית אופפת אותה.  הנה בזמן האחרון היא מרגישה שוב חולשה גדולה
        והכחות התחילו לרדת באופן נִכר.  מי יודע?  אולי עוד שלבים אחדים והכל ישקע
        מאחריה...  בני אדם יוכלו אז להללה או לחללה כאות נפשם, ובה לא יגע הדבר,
        כי יער הברושים יפרש אז את צלו על קבר אחד נוסף בגבעה.  יתנועעו שם הענפים
        לכל רוח ויהגו ביניהם עליה ועל חלומותיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הגיע לאזנה קול תלונה של עוף טורף, הצורח מר במרחקים.  מה רוצה הלז? שואלת
        היא בסגרה כבר את דלת המטבח. היא נכנסת על בהונות רגליה לחדר המטות, שוכבת
        ערה זמן רב ואינה יכולה להרדם. רוח קרירה נושבת מבין מקלות התריס הפתוחים
        ומביאה אתה ריח חריף של פרחים.  היא שואפת את הריח הזה במלוא חזה והוא אינו
        מביא לה הקלה.  דבר-מה מציק בלב ואינה יכולה לגרש את המחשבה המעורפלת, שהיא
        עומדת לפני איזה מעבר מוזר.  הגשר ארוך ותהום רובצת מתחת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באחד הימים אירע לה לשרה זרחי לטעום טעם של סבל, שקשה היה לה להאמין, כי
        כמוהו ימצאנה בארץ ישראל. היא ראתה פתאום כאילו בא הקץ לכל אותה העצמאות,
        החירות, שבה היתה מתגאה כל נקודת ישוב בארץ ישראל;  הגלות בכל צורתה
        המשפילה באה לדכא אותנו גם כאן. מפני הבצורת קשה היה לאכרים לשלם את מסי
        הממשלה בזמנם.  הגישו בקשות ובטוחים היו כי הממשלה תתחשב במצבם. והנה באו
        פתאום אל השכונה מטעם הממשלה שלשה מלאכי-חבלה: אפנדי אחד ושני מוגרבים –
        בני תוניס או מרוקו – ושעליהם אמרו לה השכנים, כי הם מאותם הטפוסים
        הידועים, אשר בימי התורכים היו אורבים לנוסעים, שודדים בדרכים ורוצחים.  את
        אלה מצאה הממשלה הנאורה ראויים לשליחות של הרמת המסים מנקודה קטנה זו,
        המתקימת בנסים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה ראתה בהכנסם לבית אחד ובהחלם להשליך חפצים שונים החוצה. היא רעדה
        כולה, חרקה שניה וחפצה להתנפל עליהם כחיה טורפת, אבל עצרה ברוחה, באה
        במשא-ומתן אתם על ידי תורגמן ובאופן פלא עלה בידה לסדר ערבות בעד תשלום חלק
        מן המס.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הצילה משערוריה בית אחד.  יותר לא היה בכחה לעשות.  חזרה לביתה, נפלה על
        הדרגש ובכתה.  אחרי-כן שאלה את עצמה: הזוהי הממשלה הנאורה, הכבירה והאדירה,
        שעם ישראל תלה בה כל-כך הרבה תקוות, – הזוהי הנהגתה אתנו, לפשוט את עורנו
        מעלינו, לרמוס את כבודנו, להתעלל בנו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בערב אותו יום היתה אספה כללית והיא התרעמה באזני הקהל:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– שנות בצורת – והממשלה שולחת לגבות את מסיה בצורה כזו!  ויען מה?  יען
        אנו מכניסים חיים וכחות וכסף אל הארץ ומקימים אותה מהריסותיה.  ולשם מה
        מוציאים באכזריות כזו את תמצית דמנו – אתם יודעים, רבותי?  לשם בנין
        בית-ספר לצרינו להגדיל כחם וחוצפתם. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הממשלה מפקירה את האוכלוסים, לדעתה אין צורך בהגנה. והארץ מלאה שוד ורצח
        וגנבות והשלטון מחבק ידיו ואומר לחכות עד שהפראים יהפכו מאליהם לאנשי
        תרבות...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אך אל לנו להתיאש.  נצח ישראל לא ישקר!  נהיה מאוחדים, נהיה חזקים ובחיק
        המולדת ינחל העם את נצחונו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הימים ארוכים וחמים.  משרה נִטל תאבון האכילה והמרץ לעבודה.  לפנות ערב,
        כשהעבודה מתחדשת, היא כבר לגמרי במצב של אפיסת הכחות.  הנה באו הפרות –
        והיא נשארת יושבת על הספסל שבחצר מבלי לזוז משם, כמחכה שיופיע לה גואל
        מעבודה.  אבל כיון שהגואל לא בא, היא מתגברת והולכת לעבודתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי כן יושבת היא עיפה בגן עד שעה מאוחרת. אושה קלה בין העצים. צפור-לילה
        משמיעה קול נכאים.  דופקות מכונות הפרדסים בלי-הרף: תיק-תק! תיק-תק! מה הן
        עושות?  מעלות מעמקי האדמה זרמי מים להשקות את שרשי העצים ולהרוות את נפש
        הצמח הצמא.  ואי המעין שישקה את צחיחות נשמתה היא, הנשרפת במדבר
        חייה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חמור נוער בדרך, על גבו עמוס משא לעיפה והוא רץ עם המשא הכבד שלו בתקוה
        לפרקו מהר.  היש תקוה גם לאדם לפרק מעליו משאו, כשהוא מכביד עליו יותר
        מדי?  התועיל לו ריצתו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החיים בביתה אינם כשורה.  ברוך – העצבות וההתרגזות גוברות אצלו עם התגברות
        חום הקיץ.  קשה מצבו בכלל, אבל מי אשם במר גורלו?  אצל קורולנקו אשם בכל –
        מקר, ובביתה אשמה – האשה! תמיד, תמיד היא צריכה להרגיש אותו העוקץ החד,
        העובר בכל שלשלת חייה והדוקר תמיד את לבה.  לא הקושי שבחיי עמל, מכשילה כי
        מסתפקת היא במועט ומקבלת באהבה כל מה שנמצא לה בדרכה. רק על דבר אחד אינה
        יכולה להתגבר: על היחס לאשה.  עבד ואשה – הינו הך.  אך לעבד כבר נמצאו
        גואלים במקצת, ולאשה – טרם נמצא הגואל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"אשה", "אשה"!  – שם–גנאי הוא הנִשא באויר, הצריכים להוסיף עוד מה כדי
        לערער את עצביה עד זועה?  קנה אשה – קנה שפחה.  התמסרותה העורת של האשה,
        בתתה את כחה, רוחה וכל עצמיותה ואשרה הגדול, בקבלה תמורת כל אלה לטיפה,
        נשיקה או חבוק – האם לא עבדות מחלטת היא זו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לשם מה נתנה לה הטבע את הסגולה להכנע לגורל המר כלענה?  למה תרכין ראשה
        ותשא בדומיה את עולה?  רק במסתרים מבכה היא את גורלה, בעמדה כפופה ומיואשה
        לפני המציאות המרה.  – היש צורך בזה, שלא תשתחרר לעולם?</span
      >
    </p>
    <p class="c0 c10" dir="rtl"><span class="c1"></span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה יצאה אל הגן להשקות את הפרחים ועמדה כמוקסמה, בראותה על הגבעה שלפניה
        את חורשת העצים מוארה באור פלאות.  היא התמלאה חרדת קודש: מה טוב עשה רבון
        העולמים שברא את האדם ועולמו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ברוח קלה היא לוקחת את הדליים ומתחילה להשקות, אבל אחרי ששאבה ארבעה-חמשה
        דליים התעיפה ולא יכלה להמשיך יותר,  ותאחז בענף עץ לבל תפול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהתעודדה וחזרה להשקאה, התחילה לועגת לעצמה: הנה מכירותיה חושבות, שהיא
        חזקה, גבורה...  ככה ודאי מביטות הנמלים בתמיהה על חפושית, המעבירה על גבה
        כדור-זבל לכל אורך השדה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בצאתה לרעות את עגליה, היא נהנית ממראה השדות הרחוקים.  שדות–מולדת
        חביבים!  מתפרצת קריאה מן הלב, ומיד נזכרת היא, שהשדות החרושים הללו,
        השדודים למשעי, שדות הכְּרַב המוכנים לזריעת-החורף, לא לנו הם, לא
        לנו.  זריעת שדות, הוצאת לחם מן האדמה – זוהי מנם חלקם </span
      ><span class="c2">שלהם</span
      ><span class="c1">, ואנו קונים מהם הכל במיטב כספנו.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פתאום התעוררה מהרהוריה הנוגים לקול נקישת כלי עבודה.  – מן המחצב הקרוב
        עלתה קבוצת חלוצים ומכושיהם על שכמם וכשראתה אותם זז בלבה דבר מה חם, עלה
        עד גרונה, לחץ וסחט משם דמעה אחרי דמעה.  – אלה מחדש באו אינם חוששים
        למכשולים הרבים.  הנה בעיני-הנוער הבהירות שלהם משתקף רצון כביר לבנות את
        המולדת, ובצעדיהם – פעמי-משיח המבשרים גאולה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שבה שמחה עם העגלים אל חצרה, ופני החבורה נחרתו במחה.  בחצרה נראה לה הכל
        יפה.  בשובכים מהגים ומצפצפים הגוזלות בקול מלא שמחת חיים, ההורים הנאמנים
        הביאו להם אוכל מן השדות ומפטמים אותם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >העגלים גדלו כולם ולמרות העמל והצער, שהם גורמים לה בשעת השתובבותם, היא
        נהנית מבריאותם ומטוב מראיהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בור הזבל עוד מעט ויתמלא על כל גדותיו.  הוא כבוש ומכוסה היטב בשכבת
        עשבים, לבל יתנדף.  הן בזבל ברכת העובד ועושר אדמתו; בו יסוד ההצלחה בצמח,
        שדה וגן, והבור יקר לה ומכל משמר תנצרהו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הפרגיות כולן כבר מתהלכות בגאוה וחשיבות.  יש כבר סימני בגרות גם בדור הכי
        צעיר ועוד מעט תטלנה את הביצים הראשונות גם בנות הדגירה האחרונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והכל כאן דורש את טפולה, – מהצמח והחי הקטן שבקטנים עד החלוץ, בן עירתה,
        השוכב חולה זה שבוע ימים בחדרו הצר. לה עצמה גם כן דרוש טפול, היא מצווה
        לנוח, אבל אין זה יוצא אל הפועל – לעצמה אין לה פנאי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושוב התחילו לילות ירח יפים ושרה יוצאת יחידה בלילה לשאוף אויר.  היא
        מסתכלת בכל פנה מוארה או עטופת צללי עצים, סופגת לתוכה את ריח האדמה וקולטת
        כל מראה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובאחד הלילות האלה נשארה בבית, כי תקפה הכאב בחזקה, וכשנקרא הרופא והוא
        בדקה, מצא שצריך להובילה בלי אחור העירה, לבית-החולים.</span
      >
    </p>
    <p class="c8 text-center" dir="rtl">
      <span class="c6">*   *   *</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבית החולים דרשו מברוך חתימה על ניר ושעת הנתוח נקבעה. למחר בבוקר באה
        האחות להכין לה אמבטי ותלבישנה לבן ככלה ביום חופתה.  כשקשרוה יפה בידיה
        וברגליה בחדר הנתוחים, הניחוה על השלחן הלבן והתחילו בנרקוזה,  היא הספיקה
        לאמר בגיחוך על שפתיה הרועדות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אתם עורכים לי חזיון חדש – כל החיים משחק.  התהיה זו המערכה
        האחרונה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחד הרופאים ענה לה דבר-מה, אבל היא לא שמעה עוד. ראשה התעטף בערפל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והנתוח בא באחור זמן, כי האפנדיציט כבר הפריש מוגלה ולא היתה לה הצלה. היא
        מתה ביום הששי לנתוח ביסורים קשים, אבל בדעה צלולה בקשה מברוך לפני מותה
        שני דברים: לחפור את קברה סמוך לקבר ילדתם ולשמור ככל האפשר על המשק.</span
      >
    </p>
    <p class="c11" dir="rtl">
      <span
        >ברוך הלך אחרי מטתה כפוף תחת סבל יגונו.  שתי ידידות התנהלו בעצבות,
        "כלי-קודש" אחדים ואחדים מעוברי הרחוב לוו את שרה זרחי למנוחת
        עולמים.  בקשתה הראשונה לא נתמלאה, ואשר לשניה – הימים הבאים יגידו.</span
      >
    </p>
  </body>
</html>`;

export default page;
