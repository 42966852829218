const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c4 italic" dir="rtl">
      <span class="c10 bold">דאר היום</span
      ><span class="c3">  15.6.1934  </span>
    </p>  
    <h3 class="c9 text-center" dir="rtl" id="h.w8ys7w74zyhq">
      <span class="c6">נחמה פוחצ&#39;בסקי ז"ל</span>
    </h3>
    <p class="c0 text-center" dir="rtl">
      <span class="c2">(תולדותיה)</span>
    </p>
    <br/>
    <p class="c1" dir="rtl">
      <span class="c2"
        >נחמה פוחצ&#39;בסקי נולדה בבריסק דליטה בשנת 1869 במשפחת-חסידים:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בהיותה בת 9 עברה לגור עם אמה לצריצין שעל גדות הוולגה. ושם נכנסה
        לגימנסיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בשנת ה-15 לחייה גמרה את חק למודיה בגימנסיה, ובאותו הזמן למדה גם תנ"ך
        והתמכרה לעברית בכל חום-נפשה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בשנת ה-16 לחייה התחילה לכתוב מאמרים בעברית. ומאמרה הראשון נדפס ב"המליץ"
        בחתימת "נפש" (נחמה פיינשטיין).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בזמן צמיחתה של "חבת ציון", היא נעשית חובבת-ציון נלהבת ומבקשת אפשרות
        להגשים את האידיאל שלה ולעלות ארצה-ישראל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >והנה נמצא לה הגשר להוציא אל הפועל את ראש מאוייה זה. בהנשאה קרוב לאותו
        זמן לבן-עירה יליד בריסק מר מיכל פוחצ&#39;בסקי, שהיה מן הראשונים לציון
        וכבר התגורר זה 4 שנים בארץ-ישראל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ה"שדכן" והמתווך ביניהם היה מר מרדכי פדואה, המורשה של חובבי ציון בעיר
        בריסק, שהיה ידיד הוריהם של שני ה"צדדים".</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בהיותה בת עשרים (בשנת 1889) עלתה נחמה פיינשטיין-פוחצ&#39;בסקי לארץ
        והתישבה בראשון-לציון. באותו זמן נמצאו רק כעשרים משפחות במושבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בבואה לארץ, נסעה לחג הפסח לירושלים. בקורה הראשון היה בביתו של אליעזר
        בן-יהודה, מכירה ומוקירה מן הגולה (בן-יהודה, בשעת נסיעתו בשנת 1887
        לרוסיה, סר לצריצין ביחוד כדי להפגש עם הסופרת הצעירה "נפש") והתענגה על
        צלצול העברית השוררת בביתו. כאן יש לציין, שרטוט אופייני, כי אשתו של
        בן-יהודה בקשה מאת נחמה פוחצ&#39;בסקי להשפיע עליו, שלא יהיה מחמיר כל-כך
        ונוהג מנהג של "יקוב הדין" במלחמתו בעד השלטת הדבור העברי בבית שלטון גמור
        ומוחלט.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >במהרה נעשה ביתה של נחמה פוחצ&#39;בסקי, בהשפעתו של בן-יהודה הבית הראשון
        של דוברי עברית בין האכרים בראשון לציון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בתקופה הראשונה של חייה במושבה, היה בעלה מר מיכל פוחצ&#39;בסקי פקיד
        הברון; ודבר זה לא נתן לנחמה שום ספוק בחייה. היא שאפה לכך, שבעלה יתאכר
        ויהא עומד ברשות עצמו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ועוד מסכה אחת גרמה לה אי-ספוק נפשי: היותה מחויבת לתת את כל זמנה להנהלת
        הבית, ולגידול בניה. כי על ידי כן נעתקה בחזקת-היד מעטה הנובע. כל נפשה
        היתה נתונה ליצירתה, אלא שהמצב החמרי לא נתן לה להוציא לפועל את משאלת לבה:
        להתמסר לספרות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >רק כעבור שנים רבות נמלא משאלותה זו. לאחר שבעלה התאכר ובניה גדלו, תפסה
        שוב בעט-סופרים אשר לא זז עוד מידה. ובכל רגע של פנאי ואפשרות חזרה
        ליצירתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >באותה תקופה פרסמה את דבריה ספורים מחיי הארץ בירחון "השלח". וכמו כן
        הופיעו ב"התקופה" מאמריה - "בימים ההם" (כרך י"ח) ו"בבדידות" (בכרך כ).
        בחתימת "אכרה". דברי בלטריסטיקה שלה ראו אור גם ב"יהודה החדשה" (קובץ
        שהופיע לפני 10 שנים) ו"בכפר-ובעבודה" (הוצאת "הדים" תר"ץ)</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >חלק מיצירתה הביליטריסטית, בשם "במדרון" – רומן ארץ ישראלי, הנמצא עדיין
        בכתובים, נשאר בעזבונה של המנוחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >שנים אחדות לפני פרוץ המלחמה העולמית, התעוררה בארץ-ישראל שאלת הפועל
        העברי. עד הימים ההם היה הישוב מצער, ושאלה בוערת זו לא עמדה אז על הפרק
        כלל. וכשנתעוררה השאלה בקשר עם עליה חדשה של כוחות עברים רעננים בהמון, קמה
        נחמה פוחצ&#39;בסקי ללחום את מלחמת העבודה העברית. מלחמה אשר עמדה
        במערכותיה עד רגעה האחרון. הסיסמה שלה היתה: "עבודה עברית במאה אחוזים".
        ארבעים וחמש שנים עמדה נחמה פוחצ&#39;בסקי על משמר היצירה העברית בארץ
        והיתה בין הנושאים בגאון את דגלה של יצירתנו זו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >כשנכבשה ארץ-ישראל בידי אנגליה, נתעוררה שאלת זכות הבחירה לנשים, אשר גררה
        אחריה את השאלה בדבר שווי זכויותיהן של הנשים בכלל. המנוחה היתה אחת
        העומדות בראש תנועת הנשים שנתעוררה אז, והיתה אחר-כך ציר באספת הנבחרים
        השניה בירושלים בשנת תרפ"ו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >במושבה ראשון לציון תפסה מקום בראש בכל המוסדות. היא היתה חברת ועד בית
        הספר. חברת המועצה המקומית, השופטת הראשונה בין חברי השופטים של משפט
        השלום. מן המיס[דים] של חברת לינת צדק (אשר קדמה לקופת-חולים), יושבת ראש
        לסניף המקומי של "הסתדרות נשים עבריות", חברת המועצה הארצית ב"התאחדות נשים
        לשווי זכיות", ועוד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >כל קשה-יום במושבה וגם חוצה לה מצא אזן קשובה ומשען בנחמה פוחצ&#39;בסקי.
        היא נשאה וסבלה בסבלנות אין קץ את כל הטורח הרב של צרכי-יחידים וצרכי-צבור
        אשר סביב שתו עליה מכל צד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ביום ח&#39; סיון ש.ז. מצאה מנוחה לאחרונה, בבית הקברות של ראשון לציון
        במעמד קהל רב אשר עוד לא ראתה המושבה כמותה, - הסופרת העברית, אחת הראשונות
        והמועטות בין הנשים, שהיתה גם "ספרא" וגם "סייפא" בספרות-תקופתנו – נחמה
        פוחצ&#39;בסקי</span
      >
    </p>
    <p class="c4 c7" dir="rtl">
      <span class="c2">המערכת</span>
    </p>
  </body>
</html>`;

export default page;
