const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c2" dir="rtl">
      <span class="c3 bold">דבר  18.3.1926</span><span>  - </span
      ><span class="c1"
        > בתוך כתבה על חנוכת בית הפועלים בראשון לציון מוזכר שהמברכים את הפועלים
        בשם המושבה היו ראש העיריה, נשיא הועד החקלאי והגב&#39; פוחצ&#39;בסקי.
      </span>
    </p>
  </body>
</html>`;

export default modal;
