const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c3" dir="rtl">
      <span class="c1 c6 bold">הפועל הצעיר  23.5.1913</span
      ><span class="c1">  -  </span><span class="c5 c1">בתוך</span
      ><span class="c1"> </span
      ><span class="c1 c5">אזכרה למשורר יל"ג מוזכרת </span
      >
      <a href="/letters/yalag18851889"
      ><span class="tooltip">התכתבות שלו<span class="tooltiptext">ראה מכתבים \ידידים ומכרים \ילג</span></span></a>
      <span class="c5 c1"
        > עם מספר נשים כותבות עברית ובהן נחמה פיינשטיין (לימים פוחצ&#39;בסקי)
      </span>
    </p>
  </body>
</html>`;

export default modal;
