const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c0" dir="rtl">
      <span class="c1"
        >במושבה שוררת דממה אף על פי שהשעה אינה מאחרת ביותר, – עשר דפיקות השמיע
        הארלוגין דרך אחד החלונות הפתוחים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הירח טובל ברום שמיו התכולים-כהים ומשדר למטה מבטים מלאי רֹך ולטיפות.
        לכאורה נחה דעתו של מושל לילה, בהסתכלו ממרומיו בפני רחוב יפה זה, המקשט
        בתותים עבותים וגנות פרחים, – ולפיכך כה נהירין פניו, נהירין. – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בולט לובן הבתים מתוך האפלולית הקלושה של צללי העצים; ענפי התותים עם
        טרפיהם הרחבים מצֻירים על פני הקירות כמו על בד צח ולבן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ברחוב עובר זוג מטילים, ששיחתם החשאית מעוררת געגועים בלב העלם התימני
        היושב בדד על ספסל בין שני ברושים זקופים שעל יד אחד השערים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מזל!... איך מזלי? – לוחשות שפתי העלם והוא מבלי משים נועץ את עיניו
        בבית המואר שכנגד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >... זאת לא מזל מתהלכת שם בחדר ומגישה אוכל לחוג&#39;ה, שאִחֵר לבא מבית
        הועד? – חולפת השאלה כברק במחו של זכריה עוג&#39;ן, ומיד הוא צוחק לשטותו.
        – הרי מזל רחוקה כעת ממנו, רחוקה! הריהי בעיר, בבית ההוא הקטן והנמוך שעל
        חוף הים! – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זוקף זכריה את אזנו להמית  גלי הים הבאה ממרחק, מעבר החולות; עוד יותר
        תוקפים הגעגועים את לבו והוא מונה בצער את הימים שלא ראה את מזל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ארבעה ימים וארבעה לילות כבר עברו! הוא אז חכה לה בערב בסימטא הגדורה שטה
        פורחת. הריח החריף של הפרחים הקטנים והסגלגלים, הטיל כמו שכרון עליו. סבב
        ראשו ונפשו כלתה למזל שאחרה לבא. הרבה הוא להרהר אז בשאלה, כיצד להבטיח את
        הקשר עם מזל, שלא ינתק? ונתן אל לבו להציע לפניה לקרא שני עדים ולקדשה לפי
        שעה, כמו שנהוגים לעשות בתימן, בעת שאיש רוצה להיות בטוח, כי אחר לא ישלל
        את ילדתו ממנו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא החליט לשאל את פיה באותו ערב ואם תסכים לזה, הוא, יקדשה לפני עדים
        בעדי של אמו, שני חרוזי האלמוגים המחברים בקשורי כסף וחביונת כסף באמצע;
        ותאר הוא בלבו מה יפה יהא העדי, כשהוא יענד את צוארה הדק והצח של
        מזל...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בהריחו בתאוה את ריח פרחי השטה, שמלל בין אצבעותיו הוא נבהל פתאם למראה
        מזל, שנגשה אליו עצובה וחורת מאד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה אירע לך, יא נשמה? – שאל אותה במבוכה, והיא כבשה את פניה בקרקע
        ושתקה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הגידי, יא אוהבה, הגידי – מה קרה לך? אפשר שהגברת פגעה בכבודך, או שמא
        חשה את בראשך?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא, יא עיני, לא זאת! – ענתה הפעם מזל ותגלה מתוך דמעות את כל מר לבה.
        –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחיה שבא אמש מיפו אים עליה, שאם תכנס לחפה עם תימני, הרג יהרג אותה!
        "ספרדיה מיחסת אל נא תשפיל את כבודה!" – חרק האח את שניו. ונודע לה שבדעתו
        להשיאה לכלדרון, סוחר ארג ביפו! רוצה הוא, האח האכזר, למכרה להזקן העבה
        והמכֻער ההוא!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומזל בכתה, בכתה מר והוא עמד נבוך ולא ידע במה לנחמה. היא מתוך התרגשות
        ודמעות סחה אילך על חיי אחותה היחידה. – גם הלה אמללה וגם אמה שרואה בצרת
        הבת! גיסה שבתאי חלה אשתקד במחלה ממשכה ועד היום אין באפשרותו לעבוד
        ולהרויח מה שהוא. מה לעשות? התחילה פַרידה המסכנת לטפל בחיטות, יומם ולילה
        גוחנת היא על המכונה ופניה היפים הולכים וחורים, הולכים ודלים ומתכמשים,
        ובעודה כה צעירה כבר דומה פַרידה לזקנה בלה! –  –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשנפרדה ממנו מזל מתוך צער, נשבעה לו שבועת אמונים שלא תנשא לכלדרון: מוטב
        לה למות – אמרה – מהנשא אליו! – היא תסע לאמה וזו הטובה, הרחמניה תצילה
        מצרה, תצילה ותתירה לבחיר לבה...</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הסתכל זכריה עג&#39;ן מבין הברושים בככבים הרחוקים ובירח השט מערבה ובלבו
        בצבצו זכרונות נעוריו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרי אמו אשר נושאת אותו על ידיה לבית המדרש בפעם הראשונה כשהוא מתנגד
        ואינו רוצה ללכת... הרבי הגבוה בעל זקן גדול וריסי עינים ארכות ושחורות
        מטיל עליו אימה... המדרש הצר מלא הוא מלא פעוטים לעשרות, המופקדים בידיהם
        של עוזרי הרבי, העושים בילדים כרצונם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא נמסר בחדר ליד אחד העוזרים והלה התחיל מלמדהו ברכות בעזרת רצעות עור
        דקות, שהיה מצליף בהן על גבו בלי רחם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי שנה לבשתהו אמו טלית קטנה עם ציציות ארכות בארבע כנפיה ואז התחיל
        העוזר ללמדהו כסדר – אלף בית, הגיונות וצרוף המלות. כאן הוא נחשב לגדול
        ועבר לכתת הרבי שלמדו בה פסוקים, קבלה והלכה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בפרוש המלות טפל בו אביו, אביו האכזר שהוא כה שנא אותו בעד הנהגתו הרשעית
        עם כל בני הבית, ביחוד עם אמו החולה, החלשה... הרי בין ארבע נשי אבא היתה
        היא היחידה כה צנומה ורזה, ביחוד אחרי לדתה את הילדה המתה!... גוצה, עם
        פנים חורים ונוגים ועינים שחורות וטובות, היתה אמא כה מעוררת רחמים, ואבא
        בועט בועט בה ברגליו בשעת כעסו עליה, בועט ומכה בלי חמלה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זוכר הוא שבעודו קטן מאד התקומם לבו נגד עול זה, ויחליט כי הוא מחיב להציל
        את אמו כשיגדל! את הרעיון הזה נשא שנים רבות בעמקי נפשו עד שיצא לפעולה
        בשעה שלא התכון אליו כלל. –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שבת נחמו!... הוא ואבא וארבעה אחים חורגים קטנים ממנו, שבו מבית התפלה.
        אבא קדש על הכוס ואחרי אכלו לשובע את המדללה השמנה שתה עוד ספל יין ושכב
        לנום. אחרי שעה קלה הקיץ אבא ובראותו שבכורו יושב וחוזר לבדו על פרשת
        השבוע, התמלא חמה והתחיל נוהם:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ריקא, מדוע לא חכית לי עם חזרת הפרשה? לא נאה לנבל שכמותך לחכות לאביו,
        לא נאה, כן?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">נבהל הוא, נעץ עיניו באביו ולא ידע מה לענות.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אינך עונה, שקץ נבזה? – שוב רעם אבא ונרתע ממקומו, תפשהו והתחיל מכה
        אותו באכזריות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">נסתה להתערב האם ולבקש עליו רחמים.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– יחרם אבוכי, יא כלבה! טולי וצאי כרגע מכאן עם הממזר שלך! – התנפל אבא
        עליה כמטרף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אז הוטל גורלם: הוא ואמו ברחו בלילה ההוא מדגלה, עיר מולדתו הגדולה
        והברוכה בשדות פורים וכרמי חמד ומגמת פניהם מג&#39;יזה, מרחק ארבע שעות
        מדגלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זה היה ליל נורא! הם תעו בדרכם, ובבקשם מוצא טפסו הרים, גלשו בקעות
        כשרגליהם נתקלו באבני סלע, נפצעו וזבו דם, עד שנפלו באין אונים לרגלי צוק
        אחד וכאן נשארו מוטלים בפחד ואימה כמה שעות רצופות. סביבם יללו חיתו טרף
        ורעש הד ההרים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הם הפקירו בלילה ההוא את נפשותיהם ביד השם ולא קוו יותר לראות  את אור
        העולם! –  –  –</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מקשיב זכריה רגע להמית הים ולשירת בדוי העולה מהחולות שעל גבול המושבה,
        מקשיב, ושוב נשקע בזכרונותיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תקופת חיים חדשה התחילה לו במג&#39;יז! אמו החלשה לא יכלה להרויח כלום,
        והוא צריך היה לכלכל את שניהם. כמה קשה היה הדבר לנער רך שכמותו, אשר רק
        התחיל להניח תפילין! וכמה הרפתקאות עברו עליו עד שמצא את מחיתם הקבועה
        בעבודה תדירית אצל צדוק החשל! שכר עבודתו הקשה היתה אכילה מצמצמת, בגדים
        ישנים וחצי רליה לשבוע. בכסף זה צריכה היתה אמו המסכנה להסתפק לצרכי חייה.
        אבל היא לא התאוננה. בו מצאה תנחומיה, ביחוד בשבתות, כשהוא היה בא עם ספר
        שאול אצל אחד המכירים, יושב על צדה שעות רצופות ולומד בשקידה. זוכר הוא את
        ברק עיניה אז – האח העינים החביבות, שאור שבעת הימים נִתַז מהן! – למה סגרן
        השם בלא עת? ולמה עזבהו כה בדד בעולם? –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזוכר הוא, את כל אימתו של הדבר אשר פשט אז בארץ תימן והפיל מאות חללים
        בכל יום. יש שעולה אדם לחפר קבר למתו, ובעוד שעה קלה והרי חופרים קבר
        בשבילו הוא!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תוהה ומשתומם הוא הביט אז על אמו. – איזו גבורה נפלאה בצבצה בה בימים
        הנוראים ההם! – כל היום, היתה מכתתה רגליה מחולה לחולה לשמשו, עד שנפלה גם
        היא, הצדקת, בצפרני המות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזוכר הוא שכל ירחי האבל לותה אותו אמו על דרכו האפלה. הוא ראה אותה בלכתו
        שלש פעמים ביום לבית התפלה לאמירת קדיש. ראה אותה בתמונה יותר בולטת
        בלילות. נכנסת היא אליו, תולה בו את עיניה הנוגות, רוחשת תפלה ומסתלקת
        בחשאי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והגיע יום ה"יארציט" הראשון בפרשת יתרו, בעשרה לחדש שבט! הוא לקח אז חפשה
        מהשחל לכל היום ובלה אותו בבית הכנסת בתענית ובלמוד משניות ודיני אבלות. כל
        היום ההוא רחפה תמונת אמו נגד עיניו ודומה היה לו שהיא רומזת ולוחשת: "אבא,
        אבא שלך!" והתחילו אז לבצבץ געגועים כמוסים. אבא!? אב יש לו והוא אינו בדד
        בעולם!.. לכשירצה ישוב לאביו. –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">וכעבור יומים באה אמו אליו בלילה וסחה מתוך עצב:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- יתומי, דע שאין לך כלום יותר בעולם!.. מת אבא שלך ביסורים קשים,
        קשים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחרי שבועים נודע לו מהלך עני אחד, שאמנם מת אביו ממחלה ארכה וקשה, וכי
        את נחלותיו חלק בין יוצאי חלציו, רק אותו, את זכריה, את הבן הנדח הדיר
        מנכסיו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גיחוך נראה על פני העלם. – נכסי אביו? – למה הם לו? הרי מצא כאן, בארץ
        ישראל, הון יותר גדול – את מזל הוא מצא!! כל הון דעלמא שוה הילדה!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מהרהר הוא מהרהר בילדתו ופתאם מתחיל לבו מפרכס. – מי יודע מה שמתהוה כעת
        שם! – שמא באונס יכניסוה לחפה עם אחר?... הוי, טפש הוא, בול עץ, למה שתק,
        למה לא הלך אחריה?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא קפץ, נכנס בשער, ומהר לחדרו הסמוך לאורוה. כאן התחיל מתקן את אקדחו
        ואת יתר כליו והתכונן לצאת עם עמוד השחר ליפו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5">.  .  .  </span
      ><span class="c1"
        >את כל כספו שצבר יתן בפעם אחת, כפלים הוא עוד יבטיח להם ויפדה את ילדתו!
        יפדה, יפדה! – מהרהר זכריה וידיו רועדות ולבו דופק מחשש של סכנה פן אחר את
        המועד.</span
      >
    </p>
    <p class="c2 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובשוב מזל אל המושבה בלוית אחיה וזכריה הודיע למקורביו, שהוא אורס את
        הנערה הספרדית, הוטלה סערה גדולה בין התימנים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ג&#39;וי שכזה, ג&#39;וי קדוש! – התלוננו הזקנים – לא די כי לבש מכנסים
        ומעיל כמוהם, כאשכנזים, עוד למד מדרכיהם המכערים לרדוף אחרי נערה!... הזוהי
        ארץ ישראל, ארץ הקדש?... אכן לא נכנסנו בה אלא להשתמד!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכשהוגד לזקנים כי אחי אותה הנערה קטף אי-אלה שקדים בשבת, התחילו צועקים
        ככרוכיא על החרפה שהעטה עליהם הגוי הקדוש, זכריה אבן יעקב. התכנשו כלם
        לאספה, התיעצו ויחליטו להחרימו מתוך עדתם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ויום החופה של זכריה הגיע, ומכיון שלא רצו הזקנים לסדר קדושין לג&#39;וי
        הקדוש, הוכרח הלה להזמין את מכיריו ממושבה אחרת. ויבאו מהתם גם צעירים
        וצעירות הרבה, ילדות כמעט, אבל נשואות, והבאים הללו הפיחו רוח חיים בחתונת
        זכריה המוחרם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מקרובי מזל היה כאן רק אחיה. יתר בני המשפחה לא רצו להתפיס! וישבה לה מזל
        עגומה בין התימניות הרוקדות את רקודן החד גוני, ותקשיב בעצב לקול התפיפות
        בפחים שמתערב בשירה נוגה השוקקת כמשק גלים חשאים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתחת להינומה נעצה מזל את עיניה בזר הורדים שבידה ולבה נשא אותה רחוק,
        רחוק מחדר החתונה. הרי בית אביה שם בעירה מעבר להרי הלבנון. היא לא יודעת
        שום דאגה ומפזזת כל הימים עם הערביות, בנות גילה. אבל פתאם מת אבא, – וימי
        דלות ועני וצער מסתערים על ביתם! –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמונות מעציבות קמות זו אחרי זו בזכרונה, קמות ופורחות ולוחצות את
        לבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הופיע זכריה בחדר הנשים הצר, נדחק הוא בין הרוקדות ויתקרב אליה. היא תולה
        בו מבט תוהה כשבלבה תוססת שאלה: "האמנם אינה חולמת וזהו יום נשואיה?...
        והיכן אמה, היכן אחותה, – מדוע אינן בין החוגגות?"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >דמעות חמות נוזלות מעיניה הבוערות ונופלות לתוך פתוחי הורדים, שריחם העדין
        מתמר ועולה באף זכריה, העומד נרעש ונדהם מול פני ולדתו המכוסים.</span
      >
    </p>
    <p class="c6" dir="rtl"><span> </span></p>
  </body>
</html>`;

export default page;
