const page = `<html>
  <head>
  <meta content="text/html; charset=UTF-8" http-equiv="content-type" />  
  </head>
  <body class="c6 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c4"
      >מובאים פה, בנוסף להדפסה, צילומי
    </span>
    <span class="c2 modal-button" id="modal-button-1">כתב היד </span>
    <span>(הנמצא בידי המשפחה)</span>
  </p>
  <br/>
       
  <p class="c2" dir="rtl">
      <span class="c0"
        >נחמה פוחאשעווסקי     (כתב יד)    לעתון "עולם קטן"   שנת 1894 תרנ"ד
      </span>
    </p>    
    <p class="c2" dir="rtl">
      <span class="c0"
        >פלטיאל הצובע איש ישר היה מאֹד. ויעבוד את עבודתו כל היום מהבקר עד הערב
        ויכלכל בשכרו את אשתו ואת שני בניו, את גבריאל בכרו ואת בת-שבע בתו הצעירה
        מאחיה בשנתים. ואשת פלטיאל אשה רפת כח היתה תמיד וידועה חולי ולא יכלה
        לעבוד את עבודת ביתה לבדה ותלמד את בת שבע, בהיותה עוד ילדה קטנה מאד, את
        המלאכות אשר בבית. ויהי כאשר מלאו לבת שבע עשרה שנה כבר ידעה היטב את
        המלאכות האלה ותעשה אותן בחריצות נפלאה. כל היום עבדה ולא יגעה ובערב למדה
        מאחיה קרוא וכתוב ותהי תלמידה מקשיבה לו.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c5"
        >        בת שבע אהבה את גבריאל אחיה אהבה עזה מאד. ויהי כאשר ספר לה
        גבריאל כפעם בפעם מהנעשה בבית הספר ומהלימודים אשר הוא לומד, הטתה את אזנה
        בתשוקה רבה לדבריו. לפעמים אחרו שניהם לשבת </span
      ><span class="c4">בלילה</span
      ><span class="c0"
        > גם זמן רב אחרי אשר נמו כל אנשי הבית על משכבם בלילה. ביחוד אהבה בת שבע
        לשמוע את סיפורי דברי הימים של ישראל אשר לומד גבריאל. היא אמנם אך מעט מאד
        הבינה משל ספוריו אלה. שכלה, אשר לא נתפתח בלימודים, לא יכל להבין בכל אופן
        מה זה ומי הוא העם ישראל, אשר אודותו ידובר, מה זה העבר שלו ומה הן המלחמות
        אשר נלחם באחרים ומי הן האחרים? נשגבו ממנה כל הדברים האלה, ובכל זאת באשר
        ראתה את פני אחיה הצוהלים בספרו את הספורים האלה וכאשר ראתה לפעמים שתי
        עגלי דמעה בעיניו, נתחמם גם לבה בקרבה ותבך בלי דעת.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        כן היו מאושרים שני הילדים האלה בחייהם הדלים, ויאהבו איש את
        אחיהו, ויאהבו גם את הוריהם אהבה נצחת.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        ויהי ביום ערב שבת אחד וישב פלטיאל מעבודתו ופניו לא כתמול
        שילשום. ותראה זאת אשתו ויפול לבה בקרבה ותשאל בעלה __: "פלטיאל, אישי,
        מדוע זה נפלו פניך?" ויען פלטיאל ויאמר: "אין מאומה, רק ראשי יכאב לי מעט,
        אל תפחדי, רעיתי, זה כרגע יחלוף". ויתרחץ פלטיאל במים חמים אשר הכינה לו
        אשתו, וילבש את בגדי השבת שלו וילך עם גבריאל ביתה אלוהים להתפלל.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        אבל בשובו לא עמדה בו עוד כֹח לשבת לפני השולחן הערוך ויפול באין
        אונים על הספסל. מי יתאר את עצב המשפחה הקטנה הזאת בערב ההוא אשר נשבת
        שבתם. אך פלטיאל השתדל להרגיע את רוחם ויאמר כי אם מעט רע לו עתה אבל בבֹקר
        בלי ספק יקום בריא וטוב. אך הנה הבקר בא ולפלטיאל לא היה כֹח לקום, כי אש
        נורא יקדה בכל גופו. ותהי אשתו כאובדת עצה ותקרא לעזרה. התקבצו אחדים
        מהשכנים גם השכנות ומהרו לקרוא את הרופא. הרופא נתן בת_ת סמי-מרפא וירגיע
        את רוח אנשי הבית באמרו כי אין המחלה מסוכנה, וכי לעת ערב יעבור החֹם
        והחולה יקום לו. אבל עבר היום ובערב התגבר החום עוד יותר, קראו עוד פעם את
        הרופא. גם ביום המחרת בקר את החולה שתי פעמים, גם ביום השני גם ביום השלישי
        לא נפקד מקומו. השכנים הטובים עשו בעד פלטיאל כל אשר היה ביכֹלתם, שמרו
        לראשו כל הימים וכל הלילות, אבל אם ד&#39; לא חפץ שוא שקדו שומרים. ביום
        הרביעי כבוא השמש יצאה נשמת פלטיאל.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        אלי! מי יתאר את התמונה הקורעת לב אשר ראינו אז בבית. "אישי, בבת
        עיני,"  - התיפחה האשה האומללה, "אבינו, מחמד נפשינו!" קראו הילדים מר
        צורח, במרטם את שערות ראשיהם. אללי לנו קראו הנמצאים בבית ונחלי דמעה שטפו
        מעיניהם. נורא היה המראה אז סביב.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        עברו כשלושה חדשים. ויהי אחד מערבי החורף היותר קשים גבריאל ובת
        שבע יושבים בחדרם הקט. אצל הספסל אשר הם יושבים עומדת מטת עץ בלה ורעועה.
        על המטה שוכבת אמם ופניה לבנים כשיש. הקור חזק מאד הרוח יילל מבין סדקי
        הבית והגשם ידפוק בחזקה על גגו ויפול טפות טפות מהתקרה שם בפינה. בת שבע
        תקשיב לרוח המילל ופניה יחורו כפעם בפעם כמו יראה היא מפניו ותתלחץ קרוב
        קרוב לאחיה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >- ומה יהי? – שאל אותה גבריאל – אמנו כבר נתנה בערבון את כל חפציה, כמעט
        לא נשאר עוד עמנו מאומה בבית. עוד מעט וגם הלחם היבש לא ימצא לפינו ואמנו
        היא חלשה מאד. איננה יכולה לעבוד, ואיך נוכל להביט בצרתה?  אנכי לכן בקשתי
        לי עזרה גם מצאתיה. אנוכי אעזב את בית הספר ואחל ללמוד את מלאכת הצבעים.
        בראשונה אעבוד בשכר הלחם אשר אכל. אך בעוד שנה או שנתים בלמדי היטיב את
        המלאכה הזאת אקבל את שכרי אשר בו אכלכלכן. אך עד העת ההיא מה יעשה לכן?
        מאין יבוא לחמכן?</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        בת שבע הביטה אל אחיה בעיני תמהון ותחשב מחשבות הרבה. פתאום אורו
        עיניה ואותות שמחה נראו על פניה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        > - מצאתי מצאתי גם אנכי עצה לי – אמרה בת שבע. – שמעתי היום אשה אחת מדברת
        עם השכנה שלנו ומספרת כי בתה בת עשתי-עשרה שנה כמוני היום, היא תשרת בבית
        עשירים ותקבל את שכרה מידו אחת לחודש, לבד האוכל אשר היא אוכלת ולבד הבגדים
        אשר ילבישוה. אבקש לי גם אנוכי עבודה כזאת, ולא אכל את לחם אמי, וגם את
        המגיעי בכל חודש וחודש אתן לה ותהי לה לצרכיה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >        גבריאל שמח מאד להעצה הזאת. ויגמרו שניהם כי מחר בבוקר יגלו את
        לבם לאמם ויחלו לעשות את מעשיהם, ויוציאו את מחשבותיהם לאור.</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl"><span class="c0"></span></p>
  </body>
</html>`;

export default page;
