const preTitle = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c4" dir="rtl">
      <span class="c0 bold">ארבעת הסיפורים הבאים</span
      ><span class="c3 c2"> (כמו גם &#39;בבדידות&#39; לעיל) כלולים </span
      ><span class="c3">בספרה השני של נחמה פוחצ&#39;בסקי</span
      ><span class="c9"> </span><span class="c1 bold">בכפר ובעבודה</span
      ><span class="c3"> שיצא לאור בתל אביב בשנת</span><span class="c2"> </span
      ><span class="c3 c7"
        ><span class="modal-button" id="page-modal-button-4">תר"ץ</span> [1930] בהוצאת הדים, דפוס קואופרטיבי "אחדות" בע"מ. בפתח הספר
        מופיעה ההקדשה הבאה:</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >לזכר אחי יחידי היקר באדם \ יעקב ישראל פינשטין ז"ל \ אחד מראשוני
        חובבי-ציון ומיסדי רחובות. \ נפטר ברחובות, כ"ז טבת תרפ"ט \ בן ששים
        ושמונה.</span
      >
    </p>
  </body>
</html>`;

export default preTitle;
