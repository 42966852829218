const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c10 doc-content">
    <p class="c5 italic" dir="rtl">
      <span class="c8 bold">דאר היום </span
      ><span class="c1 c11"> 22.6.1934 </span>
    </p>
    <h3 class="c12 text-center" dir="rtl" id="h.7qzyhal27916">
      <span class="c9">הצעירה </span>
    </h3>
    <p class="c4 c13 text-center" dir="rtl">
      <span class="c0">(ליום "השלשים" למות נחמה פוחצ&#39;בסקי)</span>
    </p>
    <br />

    <p class="c5" dir="rtl">
      <span class="c0"
        >לפני חצי-שנה ישבתי על הספסל בגינתה הקטנה והפורחת – הנשקפת לרחוב המאובק
        והאפור של ראשון לציון. מה רב הניגוד בין אבק הרחוב הלז עם בניניו
        העירוניים כמעט ובין פנתה הכפרית השקטה והנעימה, המלאה ___ של נחמה
        פוחצ&#39;יבסקי. זהו אי קטן פורח ובו בית נוח וצנוע, שמטפלת בו יד עובדת של
        אשה עדינה בתוך רעש-הרחוב אשר מסביב.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >יש פה בגן עצים ושיחים, בגיל של שלשים, עוד מאותו הזמן שבנו את הבית ויש
        כאן ציצי-פרחים רכים ביותר בפחי נפט צבועים ובכל תחוש רחב-השוליי_,
        ובשבילי-הגן המצהיבים זרועי-חול עומדים ספסלי-עץ נוחים לישיבה - דוגמת אותם
        הספסלים הנמוכים והפשוטים, שהם עומדים לפניך בגינות אכרי רוסיה הגדולה
        הכפרית. לכל עץ ופרח בגינה זו שלשלת היוחסין שלו, וכולם קשורים הם לחייה של
        הנוטעת, שמתה עכשיו. וכשבקרתי כאן לפני חצי שנה, ספרה לי בעלת-הבית העדינה
        והצנועה כמה פרטים על צמחי-גינתה; ואגב שיחה צחקה לעצמה, שלעת זקנה היא
        מתענינת עוד בקטנות אלה – פשוט, רגילה היא בכך. היא משקה את הגן, מתוך אותה
        רגילות יום-יומית, כמו שדרכה להאכיל את עשרות התרנגלות שהיא מגדלת פה על יד
        ביתה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >בקשתי אז ממנה להראות לי את תמונתה בשנות בחרותה, נתאויתי לראות בת-ישראל
        אידיאלית זו מהדור הישן בנעוריה אולם היא דחתה את בקשתי להזדמנות אחרת,
        כשאבוא פעם ליום השבת, אז תספר לי דף בזכרונותיה על שנות-הלמודים בגימנסיה
        הרוסית, שבעיר צריצין, מקום שבילתה שם את שנות-ילדותה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >לדאבוני אחרתי בבקורי זה. בבואי שנית, כבר מצאתי את הבית הכפרי והשקט, עם
        גינתו הפורחת, יתומים מבעלת הבית החרוצה וה____. קשה היה לי להגיד
        דבר-תנחומים לידיד-נעוריה השכול שעברה אתו יחד את דרכם בחיוך. בשבילו הן
        דמות חיה היא עודנה, עדיין מוקף הוא בהשגחתה הנשית המסורה על-פי רצונה
        האחרון. נשאר הכל בבית על מקומו כדי שלו, לחברה הנאמן, תשאר אותה הפנה
        הביתית, השקטה והמבודדת, שהיא טפלה בה כל ימי חייה. גינתה היפה
        מושקה-כמקדם.  מקבלות פרנסתן גם העופות, שגידלה על יד ביתה. ברגעי-גסיסתה
        האחרונים הזכירה, שצריך להאכילם בזמנם. הכל, לכאורה, נשאר על מקומו, כמו
        שהיה. הבית מסודר למופת. רק היא, היא לבדה חסרה כאן...  </span
      >
    </p>
    <p class="c5 c7" dir="rtl"><span class="c0"></span></p>
    <p class="c4 text-center" dir="rtl"><span class="c0">* * *</span></p>
    <p class="c4 c7" dir="rtl"><span class="c0"></span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >על השולחן מונחות תמונותיה. אוצר יקר שבתה היחידה שומרת עליו כבבת-עין.
        שונות הן התמונות לפי הגיל ולפי התלבושת, ואחת יש כאן מאותן שנות-הלמודים
        בגימנסיה הרוסית שבצריצין. התפלאו שם על הכשרונות של הנערה היהודית מבריסק.
        אולם הנהלת הגימנסיה לא יכלה בשום אופן להשלים עם שמה היהודי. שם זה לא היה
        הולם את התלמידה של מוסד חנוכי-רוסי במאה אחוז, המצטיינת בין כל חבריה
        הרוסים בשפה ובספרות "של המולדת". משום כך שינו את שמה נחמה לשם "מריה",
        וככה נשארה מריה בכל תעודותיה, שקיבלה מן הגימנסיה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >תמונה זו של בחורה משכילה מאותה התקופה, אופיינית מאד. אלה, שיודעים את
        הספרות הרוסית הקלאסית – רואים לפניהם בתמונה זו גבורה חיה מהרומנים של
        טורגיניב. זאת היא תמונת נערה מחוננת וצנועה, שמתוך נשיותה הענותנית ואהבתה
        לבני-אדם הריהי מוותרת על אשרה ועל חייה הפרטיים; אחת הדמויות הרומנטיות
        והעדינות, שברומני טורגיניב, שהיו אהובות ביותר על המחבר האצילי.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >זאת היא דמות האשה הרוסית משנות השבעים והשמונים של המאה הי"ט, המחונכת על
        הספרות הקלאסית של תקופת השחרור ברוסיה. תוכנה הרוחני של אשה זו, היה טהרה
        ואידיאליות ומלחמה על חרות העם והארץ. הגברים של התקופה ההיא כבדו מאד את
        טפוס-האשה הלז בעבור אישיותה הנעלה; וחפשו בה מקור ידידות ונחמה בקשי
        חייהם. אשה כזו לא התאהבה אף פעם בבן-אדם פשוט. הגבר, שאשה כזו בחרה בו,
        מוכרח היה להיות גבור ואידיאליסטן, המוכן בכל עת ובכל שעה להקריב את חייו
        ואשרו הפרטיים לטובת העובדים המדוכאים, מדלת-העם. איש כזה יכול היה אף
        להיות בעל-מום והיא אהבה אותו כמו שהוא: עוור, גבן, חולה או זקן חלש.
        החסרונות הגופניים דווקא שוו חן והוד טראגי לגורלו בעיני האשה האוהבת. שקטה
        ומסורה הלכה אחריו בכל דרכיו המסוכנות ליוותה אותו לסיביריה הרחוקה והשוממה
        לסבול אתו יחד ענויי גוף ונפש, ובתפילה נלהבה על שפתותיה עלתה יחד אתו
        לתליה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >הסוציאליזם הרומנטי והאידיאליסטי של רוסיה האכרית העמלה, משך את לבה של
        נחמה פוחצ&#39;בסקי. עוד בשנות-נעוריה כשלמדה בגימנסיה הצריצינית והתמכרה
        לספרות הרוסית בתקופה זו. אולם לרגל ענינים משפחתיים נזדמן לה לשוב לסביבה
        יהודית של בריסק עיר מולדתה ושם היא נפגשת את הציונים הרומנטיים –
        "חובבי-ציון" יקרא להם – שמלפני חמישים שנה, השאיפות הנעלות והחלומות
        הנשגבים על תחית העם והארץ של "בילו"ו" השפיעו על הבחורה הרומנטית
        והאידיאליסטית, חניכת-ההשכלה הרוסית של תקופת השחרור. וכשהתחילה להרהר
        בשאיפות ההן, הרגישה, כי בין האידיאלים של ה"גארודניקים" הרוסים, "ההולכים
        בעם" ובין שאיפותיהם של "הבילויים" – יש ויש קשר גדול.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >ורומנטית מאד היתה גם הצעתו - של מיכאל פוחצבסקי, בן-עירה, שהציע לה באותו
        זמן להנשא לו, לאחד החלוצים הראשונים כאן בארץ-ישראל. בחייו, חיי עוני וצער
        פה על אדמת ציה של הימים ההם, הוכיח כי לא נופל הוא בגבורתו הגופנית
        והרוחנית מאלה, שנדונו לעבודת פרך בסיביריה כלוחמי החופש בעד אכרי רוסיה
        המשועבדים. הדבר מובן מאליו שהיא תלך אחריו לאותו המדבר השומם, מתוך סבל
        וענויים יעבדו שניהם עד שיסוללו דרך לתחית העם והארץ!</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">* * *</span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >כזו היתה נחמה פוחצ&#39;בסקי, כשבאה הנה לפני ארבעים וחמש שנים; וכזו
        נשארה עד היום האחרון בחייה, שמור שמרה כל הימים על טהרת נפשה וחום
        לבה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >נחמה פוחצ&#39;בסקי קראה הרבה ספורים מחיי הארץ, יצרה ספרים שלמים והיתה
        עסקנית צבורית ממדרגה גבוהה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >היא נשארה נאמנה לאידיאליזם שלה משנות נעוריה. כששאלתי אותה בשעת ביקורי
        הראשון לפני חצי שנה, אם מוצאת היא סיפוק נפשי בעבודתה הפילאנתרופית לטובת
        הפרט, ענתה על זה ברצינות:</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >--"אם אין לנו שום יכולת ליצור מוסדות ממשלתיים בשביל חולים ומחוסרי לחם
        ובית, על כרחי שלי לעסוק בעניני-צדקה ועבודת חסד בשביל כל פרט ופרט. אני
        עוזרת לכולם לפי כחותי החלשים".</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >אולם היא עזרה יותר מכחותיה. בכל שעה היתה צריכה ללכת להשתדל בענינו של מי
        שהוא, ודבר זה לא מנע אותה מלקרוא ומלכתוב ספרים. כידוע, עזבה רומן מחיי
        הארץ גם בכתב יד.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >אמנם כן, צחק לה הגורל, לנחמה פוחצ&#39;בסקי, ואף על פי שהיתה בת ששים
        וחמש במותה – נשארה צעירה, צעירה ורעננה ברוחה עד יומה האחרון.</span
      >
    </p>
  </body>
</html>`;

export default page;
