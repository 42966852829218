const page = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <!-- <p class="c7" dir="rtl">
      <span class="c12">סימה רסקין</span>
    </p> -->
    <p class="c4" dir="rtl"><span class="c3">I.</span></p>
    <p class="c7 c10" dir="rtl"><span class="c6"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0">…"ישנה!"  </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובנעימה חרישית פוסקת סימה את שיר הערש הגלילי, שהובא לכאן, למושבת יהודה,
        מעל שפועי הר כנען.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עוד רגלה של סימה על אופן העגלה-עריסה, והיא כבר נוטלת מטלית לבנה מעל
        הכסא הסמוך, גוזרת ממנה חלקה מרבעת ומתחילה בטליאת החלוק המוטל
        בחיקה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בינתים היא מעיפה עין על הפעוטה; הלה מחיכת מתוך השנה והאם דעתה זחה עליה:
        – מלאכי החן מלטפים את לחיי זמירה&#39;לי ואוצלים לה מיפים!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסימה נזכרת במראה הפעוטה, כשהיתה חולה לפני שלשה חדשים – הם אך עלו אז על
        חוף יפו...  היה שלהי קיץ, תקופת החום בארץ... זמירה&#39;לי, קיבתה
        התקלקלה, קדחה, התחילה להדמות לשלד זעיר קרום עור דק....</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >...  חביבה שלה! – איך נשתנה מהר מצבה עם בואם אל המושבה, איך התחילו
        חוזרים לה הבריאות והיופי!...  ולדתה! דומה שעתה לא תספיק לה היניקה
        גרידה!..  שמא כדי לשמע בקול הרופא ולהתחיל להשקותה גם חלב פרה? הרי זהו
        המזון היותר מבריא!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסימה חושבת ומסכמת את הוצאותיה ורוצה לדעת, כמה חלב ביום אפשר יהא לה
        לקנות בשביל פעוטתה, כשזו תסתגל למזון החדש. הסכום לא משמח, – ממשכרתו של
        גדליהו לא נשאר כלום לקנית חלב!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מהרהרת היא ומהרהרת בדאגה בשאלה זו ומחליטה שעליה לחפש שעורים גם בשכר.  –
        די עבדה שלא על מנת לקבל פרס! – ואם לא ימצאו לה שעורים כאלה, היא תתחיל
        בתפירה, – הרי למדה מלאכה זו על בריה בבית הספר!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >...  ובבית מבינים כנראה את מצבה הדחוק, – ומשום כך הציעו לפניה סיוע של
        כסף...  אבל שטות! – היאך אינם מכירים אותה, שמוטב לה לסבול מלהשתמש בעזרת
        אחרים? – –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל לא! היא לא תסבל, – כי כלום אפשר הדבר, כי אנשים בריאים שכמותם לא
        ימצאו את די מחיתם בארץ? כלום גדליהו האמיץ, הרענן, המלא מרץ ואמונה
        בכחותיו ובהתקדמות ועתידות הישוב, הוא לא יסלל לו דרך כאן?...  לא! – היה
        לא תהא כזאת! – ופני סימה מבהיקים מתוך אמונה בעתיד.</span
      >
    </p>
    <p class="c8" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ועם תפירתה הקלה על יד העריסה, נשאת סימה רחוק, רחוק מחדרה הצר.  בראשה
        עובר קו אלכסוני לצפון.  – הרי הכרמל, הלבנון, אסיה הקטנה, – ושם אילך –
        הים השחור והדניפר; והרי גם עיר מולדתה, המוקפה יערות!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא נזכרה בנעוריה ובבית ספרה, – עומד לו שם הבית היפה ההוא מול בית התפלה
        עם המון פעמוניו, שאליו היה עורך לפעמים בית הספר תהלוכה ביומי דגנוסיא,
        ואז היתה עוד יותר עלובה בין החברות, הכורעות ברך בתפלתן... החניק אותה
        אויר ביה"ס, היחס המעליב, ותמהר לעזב את ספסל הלמודים שם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מופיעה נגד עיני סימה אהבתה השחרית ליעקב ליברמן...  –  אמלל! – למה נאבדו
        החיים הצעירים?... שליחה עולמית לסביר, שחפת ומות מוקדם בצפון הרחוק, –
        הוי, אלי, למה, למה? –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וחברתם המשתפת טַטְיָנה, זו שהיתה כה משדלת ומפתה גם אותה להכנס למפלגה,
        טטינה הבריאה והעליזה גם עלומיה נקטפו, – על גדות הניבה, נשפטה
        למות!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אהבה היא את טטינה, אהבה עוד אחרים, ומדוע כלם כה זרים ורחוקים לה עכשיו?
        כיצד נתטשטשו הגבולים ונתקו הקשורים ההם, כה מהר, מהר?..  האפשר שרק השפעת
        הקסם של גדליהו היתה בכך? או תכונת האשה בכלל נוחה להכנע?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יש שמוכיחים אותה משם, על יריעת האהבה החדשה שכבר התחילה נארגת בלבה, –
        וימי שבתה בארץ ישראל כה מעטים הם! –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >צדקו!...  באמת תמוה הדבר, היאך שהקשורים החדשים הולכים ומתגברים מיום
        ליום! – הרי אותה החברה הצעירה של פועלים, המבקרת תדיר את ביתה, – כלום לא
        נדמה לה, שהם חבריה משכבר הימים? כלום לא נִתן כל לבה לעניניהם?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וזוכרת סימה בחיוך קל, איך שאחדים מהם עוקצים אותה לפרקים: "בורגנית!" כן!
        מדה זו לכאורה יש בה, אלמלי זאת, הרי לא היתה כה חביבה עליה לפעמים גם
        חברתם של לויתנים אחדים...  מה לעשות לאופי המשונה שלה, – לאהוב אנשים מכל
        הסוגים, מכל המעמדות?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה מהרהרת בשתי חברותיה, – גברת זלצשטין וסוני! – כמה רחוקות הן זו מזו,
        ולשתיהן היא מסורה במדה שוה, אלא שאת אחת מהן היא מרחמת ולשניה תוהה, –
        כיצד זו כה אוהבת את החיים, יודעת להנות מהם, למוץ את מתיקותם ולטבל בנחל
        עדניהם...  יפה היא זלצשטין! – ובזכות יפיה היא גורפת את האֹשר
        מסביב...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסוני, סוניתה המסכנה, כאלו לא נבראה, אלא לשם יסורים וסגופים!..  ותמוה
        הדבר: – כל מה שמתגברות הצרות עליה, לבה מתרכך יותר, מתמוגג ומתמזג עם
        חייהם של אחרים...  ולא את לבה גרידה סוני נותנת, אלא הכל הכל! – את לבושה,
        כספה, כחה ואפילו את האליל שלה לא חסה העלובה מתת לאחרת!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסימה חורזת עוד פניני אהבתה: – חביב עליה אותו העסקן הישובי, עם לבו
        הרחב, האוהב, המסור לכל טוב ומועיל...  וחביב עליה גבריאל הגוץ, המשורר
        שבין הפועלים...  שרטוטי פניו דקים וחורים, וכשפוגשים במבטו הזולף בחמימות
        מעיניו הגדולות והטובות, – הרי עובר זרם חם בתוך העורקים!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ונזכרה כאן סימה באמה.  – גם לה עינים גדולות וטובות! – האח, אמא החביבה,
        היקרה, – כמה אשר היתה נפשה הטובה סופגת בבואה לכאן! – זמירה&#39;לי לבד עם
        יפיה וחכמתה, עם ורד פניה ותכלת עיניה, שזהר שמי הארץ היפה משתקף בהן – מה
        יכלה להיות בשביל אמא!... האח, בואי אמא, בואי!</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c4" dir="rtl"><span class="c3">II.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >על סף חדרה הצר יושבת סימה עם פעוטתה בחיקה וממתינה לבוא גדליהו מעבודת
        הכרם.  הצללים הארכים שזחלו לרוחב החצר, טפסו על גג הארוה שמנגד
        ונעלמו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה עיפה היום.  היא מפהקת, מתמתחת ותוהה על גדליהו – מדוע הוא לא שב
        עדין? הרי השמש כבר באה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והוא צועד בלאט מאחריה, גוחן ומנשקה נשיקה חטופה בראשה וקריאת שמחה מתפרצת
        משני פיות כאחד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התינוקת מיד פשטה את ידיה אליו.  הוא נטלה על זרועותיו, לטף ונשק את ראשה
        הקט המכסה שערות קלושות.  לקק בלשונו את הצואר הרך עד שצחוקה של התינוקת
        התגלגלה והתגלגלה באויר החצר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אבל לכבוד גברת שכמותך, הרי צריכים להחליף הבגדים, לא כן? לפיכך תואילי
        נא לחזר, שובבה, לאמא שלך!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הם נכנסו החדרה.  גדליהו התחיל רוחץ ומתלבש ובינתים סח לסימה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אצל הפועלים שהיתי עד עתה! הוחלט להתאסף מחר בערב כאן בחדרנו, לדון
        בשאלת החוה המשתפת של אופנהימר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– שוב אותה השאלה? הדוב עודנו חבוי במאורתו, ואתם את עורו כבר אומרים
        לחלק!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–לא, לא, חביבה! כנראה שקרוב הוא הדבר לצאת אל הפועל, ואנו מחיבים לטכס
        עצה, כיצד עלינו, הפועלים, להתיחס לנסיון זה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והתחיל כאן הזוג להתוכח בחמימות בשאלה זו.  הם התלבטו בה כמעט כל הערב ולא
        באו לידי דעה שוה.  עם טפולם בילדה לישנה נפסק וכוחם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובשעה מאחרת, כשסימה גמרה לרחוץ את הכלים אחרי ארחת הערב, נתגלגלה שיחתם
        לענין אחר, ללמודה של סימה עם העלמה סלוסקי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– טוב מאד! אני מרוצה מתלמידה כזו! – עונה סימה על שאלתו, – היאך קולטת
        העלמה סלוסקי את ההסתוריה העברית? – הספקנו הרבה בערך.  הרי עברנו כבר את
        שני החרבנות, חרבן בית שני וחרבן ביתר! העלמה סלוסקי התחילה עוד לפני שנתים
        את דברי ימי ישראל ופסקה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– כך, ואיזה רשם עשו הפרקים ההם עליה?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לא שאלתיה; אבל הרשם שלי היה הפעם חזק מאד וחדש לגמרי!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– למשל? – שואל גדליהו בהתעניינות, כשהוא אוחז בחפנו את שתי ידיה, מנשק
        אצבעותיה ומסתכל בה בחבה.  הוא אוהב מאד את חות דעתה על ההסתוריה ושמח
        כשסימה פתחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– תהה תהיתי על ר&#39; יוחנן בן זכאי, – היאך קם בו הרוח לעזב את ירושלים
        ברגעים היותר נוראים, היותר קשים של העם הטובע בים של צרה, – ולהמלט
        ליבנה?...  כיצד יכל לקרא – "שלום, שלום!" בעת שהלהבה אחזה בכל הפנות,
        ואכזריות רצח של חרב הרומאים אכלתנו בכל פה?..  היכן היא גבורתו הרוחנית,
        שהאֻמה מתפארת בה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– גבורתו היא כלולה בזה, יקירתי, – עונה גדליהו במתינות – שמתוך אותה
        המהפכה הנוראה, הוא הצליח להציל באפן פלא זיק קוסם, אשר בו צפון סוד
        שמירתנו מכליון, סוד קיום האמה וחייה עד היום הזה! – –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– </span><span class="c5">חייה</span
      ><span class="c0"
        >, אתה אומר?...  הוי ואוי לחיים כאלה, – חיי עבד שנוא ונרדף, חיי נודד
        שסוי ובזוי! – כלום אינו טוב המות מחיי בוז כאלה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה רוטטת מהתרגשות וגדליהו משתדל להשקיט את רוחה אבל זה לא עולה
        בידו</span
      ><span class="c5">.  </span
      ><span class="c0"
        >היא מורגזה, ממשיכה דבורה על חרבן ירושלים ועוברת גם אל חרבן ביתר:
        –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הרי חמשים ערים נצח סַוֶריוס! – חמשים ערים, ששארית כח העם התכנס לתוכן, –
        ואחד אחד נפלו המבצרים, נפלו צבאות ישראל!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הפליטים נמלטו לביתר!... מהבהב כאן זיק של תקוה – הגבור הנהדר עומד הכן
        להצילם!...  אתה הוגה כבוד לבר כֹכבה, מתמלא אמונה בכח ידו החזקה.  הלב
        דופק בהתרגשות ונדמה לך, שעוד צעד אחד והנצחון בא!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >פתאם נתק דבר מה בלב! אתה רותת, מזדעזע בראותך שהגבור היהיר מרים את ידו
        על ר&#39; אליעזר המודעי!...  בין רגע אתה נופל מאגרא רמא לבירא עמיקתא! –
        הוי תבוסה נוראה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">סימה מכסה בשתי ידים את עיניה וכל גופה רועד.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הרי זוהי רק אגדה סימה&#39;לי! – האמנם כה השפיעה עליך?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אגדה!...  אבל היא מתֻארת אצל פרנקל בצבעים כה חיים ובהירים, עד שהגבור
        מתיצב תחלה לפניך כסמל הישועה ואח"כ בשעת קלקלתו, תוקף אותך כעין טרוף. אתה
        רוצה לצעק מכעס, רוצה לנשך את בשרך מרֹב כאב!..</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c4" dir="rtl"><span class="c3">III</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עונת שבט הגיעה.  הארץ עטופה כלה סות ירֻקה של עשב רך ורענן, מרֻוה לשד
        האדמה.  פרחי ארץ ישראל ברבוי מיניהם והמון צבעיהם מבצבצים על כל שעל ושעל;
        הריהם מציצים בשדרות, במסילות הכרמים, בצדי הדרכים ומבין הגדרות
        הירקות.  העננים הקלושים שנשתירו אחרי הגשם התפזרו ושוב מבריק זהר הרקיע
        וברֻמו התכול צוחק השמש בגבורת נצחונו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האויר צח, שקוף ומלא ריחות עדינים.  ונפתחו האפקים ונגלו גבולי
        יהודה.  המושבות כאלו התקרבו אשה לאחותה, זו לזו מעיפה עין בחדוה ויחד
        תוהות על מראות הפלא של הים מהאי גיסא, ואלה של הרי יהודה מאידך
        גיסא.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בימים כאלה מרגישה סימה, כיצד זולף בעורקיה זרם חם של מאויים חדשים; גופה
        הרענן צמא לאהבה ונפשה מבקשת את הטבע. הטיולים שלה בשדות וכרמים נעשו
        תכופים למדי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הריה קמה עם נץ החמה. מכינה לגדליהו תֵה וארחת הבקר, ובצאתו לעבודה היא
        מזדרזת לסדר את הבית, להכין הכל לצהרים ויוצאת עם פעוטתה לטיל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בעברה סמטא מצלה בין שתי שורות תותים עבותים, סימה פותחת פשפש שחום ונכנסת
        אל הכרם.  היא פוסעת ישר אל הגבעה, המכסה כלה פרחים צהבהבים,
        מזהירים.  יושבת כאן תחת תאנה מצלה ונזהרת לא להעיר את הפעוטה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא נשקעת בקריאה; אבל כעבור שעה קלה מתעוררת ילדתה בבכיה, והיא מניחה את
        ספרה על העשב מן הצד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– רעבה, רעבה נשמתי?.. טוב, אכלי, אכלי! – דובבת סימה בחלצה לפעוטה שַד
        לבנה וצחה.  – –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דעתה של סימה מתישבת עליה.  הלב מפעם באיזו חדוה אי-רגילה.  הריחות
        העדינים של עשב ופרחים כאלו משכרים אותה. היא נוטלת בידה את אצבעות התינוקת
        ומוצצת, מוצצת אותן. אח"כ גוחנת עליה ומתחילה נושקת את מצחה, עיניה,
        אפה.  ולבה מפרכס מתענוג ונובע משם הרצון לחבק את העולם כלו...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה תולה עיניה בסביבה.  – התחדש הטבע מכל אפסים.  מקום בראש תפשו
        השקדים.  עוד אין עליהם אף טרף אחד, – ויזדרזו וילבשו אצטלה לבנה, יפה,
        חמודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האביב הרעבתן מושל בכפה; מפרה ומשגשג את הצמיחה, הקולטת בתאוה עזה את חם
        השמש, לחות האויר ואת כֹח התחיה, המתפרצת מזיוה של העולם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אדמת הכרמים הקרובים והרחוקים חרושה כלה.  השורות מעדרות ומנכשות.  וריח
        של קדושה, של חג נודף מסביב.  – לבה של סימה מזדעזע משפע הרגשות והיא שואלת
        את נפשה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האמנם לא חלום הוא והיא כאן, כאן בארץ האבות?..  האמנם גם היא בין חלוצי
        התחיה?..</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >...רבים המתיאשים כעת... הפקפוק אוכל את לבם...  חדלה האמונה באפשרותה
        ובהצלחתה של שיבת ישראל ושל תחיתו בארצנו...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הבלים! – הגלגל לא ישוב אחורנית!..  ברצונו או שלא ברצונו – והעם ילחם בעד
        קיומו והפרחתו כאן!...  ואם ילחם יהא לו כח ואז יבא גם הנצחון!– –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עיניה נתקלו שוב בפעוטתה הורדה, וההרהורים התרכזו בה! –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        > – – והיא, נשמתה, תגדל כאן, בגן עדן זה!..  רוחה יהא חפשי ואולי גם כביר
        כאותן של גבורות האומה לפנים...  האח! כמה מאשרה האם שבתה לא תדע מיסורי
        הגלות וכבליו לא יאסרוה!..</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c4" dir="rtl"><span class="c3">IV</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסאת אשרה של סימה גדשה, כשאמה הודיעה להם באחד ממכתביה האחרונים, כי היא
        מצאה סוף סוף קונה על נחלותיה ובעוד חדשים תצא לדרכה לארץ ישראל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שם – כותבת האם – היא תקנה נחלת שדה, כרם ובית...  גדליהו ינהל את המשק
        שלה והיא, הסבתא הזקנה, תנוח לה מעמל החיים!.. תצא יום, יום עם נכדתה אל
        הגנים והפרדסים ההם, שסימה מתארת אותם כה יפה במכתביה...  רק אבא
        חסר!..  הוא, עליו השלום, כל ימיו הזה ע"ד עליה לשם, – ועכשיו היא הולכת
        לבדה, לבדה!..</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה מתחילה להתכונן לקראת בוא האם, ופתאם חלתה זמירה&#39;לי
        והפריעה.  הגיעה לפעוטה עונת צמיחת השנים ותתקלקל קיבתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">בדאגה סימה פונה לרופא. והלה מרגיע את רוחה:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– שנים! – אומר הוא – שנים מאחרות! כל הילדים, גברתי, סובלים בתקופה זו של
        צמיחת השנים, וצריכים להתיחס לזה כמו לכל הופעה טבעית!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסימה נוטלת בשביל פעוטתה רפואה אחרי רפואה – ואין שום שנוי לטובה.  היא
        מבקרת כל יום את הרופא, שצוחק לחשש שלה בסכנת המחלה, לועג וקורא לה
        "פחדנית", והיא לשקט לא יכולה בשום אופן בראותה שילדתה הולכת ורזה ומראֶה
        שוב כמו אז בחליה ביפו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היה תחילת אדר ראשון.  כל הלילה געש הים בזעפו.  המו הרוחות והתקצפו ויללו
        בחרשות ובשדרות. קשקשו תריסי החלונות ורעפי הגגים, כאלו אמר הסער להזיזם
        ממקומם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בבקר התכסו השמים במפלשי עבים אפורים אשר רצו בחפזון אל ההרים ופנו מקום
        לחבריהם הבאים מן הים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">סימה לא ישנה כל אותו הלילה וקמה עצובה ונוגה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מה אתך היום? – מדוע פניך כה רעים ועצובים? כלום לא ישנת, יקרה? – שואל
        אותה גדליהו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– בודאי לא ישנתי! – התינוקת לא נחה אף רגע, כיצד לא שמעת?... אבל את
        הסערה שהתחוללה הרי שמעת? – שואלת סימה כמו בתרעומת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לא! כלום לא שמעתי אחרי העבודה הקשה של חפירת בורות!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה מתארת לפניו את ליל הזועה וסחה בקול נוגה על פעוטתם. – הוי, איזה
        הרהורים קשים עלו על לבה, בראותה את זמירה&#39;לי כשלד מונח על ידה!...  אל
        אלהים, מי יודע מה שיהא סוף הדבר!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא בוכה וגדליהו לוחץ את ראשה אל חזהו, קונח את דמעותיה ונושקה בעיניה
        הבוערות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– חדלי, חדלי, יקרה! – משדל הוא אותה – למה את באמת חששנית שכזו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– ואיך לא אפחד, היאך לא אחשוש אם הילדה בסכנה?...  כן, בסכנה! אומרת סימה
        בקול רוטט ומלא יאוש – מה לעשות? – אני משתגעת! – –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">פתאם בצבץ נגד עיניה פרפור משונה בעריסה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סימה נרתעה ממקומה בבהלה, נעצה עיניה בעריסה בפחד וראתה שהתעוו פני ילדתה
        ועיניה קמו בחוריהן...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– גדליהו, מהר, חושה הנה! – והיא הסתלקה כרגע ותרוץ בבהלה אל הרופא.  – –
        –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הוי אלי, מה זה? – לוחשות שפתי גדליהו בטפלו בילדה, שאחזה צירנות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא מנסה ליצוק לתוך פיה כף קטנה עם רפואה והרי הֻדקו השנים זו לזו,
        וכשפתח אותן בחזקת היד, נשפך הנוזל על הכר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא נוטל מן הרף בקבוק קוניק ומתחיל משפשף את ידי התינוקת ואת רגליה,
        כשלבו דופק בחזקה והוא ממלמל:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אלי, אלי, – השיבה את רוחה, השיבה לי את ילדתי! – – –</span
      >
    </p>
    <p class="c8" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ליל זועה.  – רעמים, ברקים ומטר סוחף...  בפנת חדרו, אשר ריח המות נודף
        ממנו, יושב גדליהו על שרפרף לח, כשראשו כבוש בקרקע וידיו אוחזות בצדעיו
        ההולמות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >. . .  נרדמה! סוף סוף נרדמה המסכנה!...  הרי ארבע שעות עברו מעת
        הלויה!...  הוי, רבונו של עולם, כמה קשה אבדה שכזו, ונסיון שכזה מה כבד
        הוא, מה כבד מנשוא!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מחדר הסמוך של השכנים, נשמעה פתאם בכית תינוק וסימה הקיצה בבהלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– בוכה? – למה בוכה זמירה&#39;לי?...  – ותולה היא בגדליהו מבט תוהה,
        נזכרה ותתחלחל...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומתוך יללת הסער וקשקוש הגשם ודפיקות הרעם נשמעה בכיתה של סימה: – "היכן
        זמירה&#39;לי...  – מדוע גזלו את נשמתי ממני – מדוע, מדוע?" – והיא תולשת
        את שערותיה, ודוחפת את ידי גדליהו, המנסות להגיעה מזה.</span
      >
    </p>
    <p class="c10 c11" dir="rtl"><span class="c9"></span></p>
  </body>
</html>`;

export default page;
