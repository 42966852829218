const page = `<html>
  
  <body class="c9 doc-content">
  <p class="c1 c4 text-center" dir="rtl"><span class="c12">(רשימה). מאת נחמה פוחיצֶ'בסקי.</span></p>  
  <p class="c1 c4" dir="rtl"><span class="c12"></span></p>
    <p class="c5 text-center" dir="rtl"><span class="c7">א</span></p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >שכב יחזקאל ווינהולץ עם דמדומי-ערב על ספסל גִנתו, התמתח בכל איבריו
        וחִיֵך בנחת כשעינו ראתה את פרחי הג&#39;יראניום האדומים רחוצים בנטפי
        הגשם, שטפטף היום, בחצי-מרחשון, בפעם הראשונה. כמה חן מסוך על הפרחים
        הפשוטים הללו, שהוא משתוקק תדיר להחליפם באחרים, במינים יותר משובחים, אבל
        אינו יכול לעשות זאת מפני שהמינים הטובים דורשים מים – ואָין. "הוי,
        צנורות, צנורות!" – וויינהולץ, כמה היה מאושר אילו כבר נעשה תִקון זה
        במושבה!  </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        באויר הפושר מורגש ריח-גשם, ויחזקאל נושם אותו בפה פעור. גם בשמים
        נכרו עקבות האורח החביב: הרי שם, בקצה הכפה, נתלו עננים אדומים-זהביים; לצד
        הרי-יהודה, המכוסים טלית-ערב קלושה, שטים גוּשים אפורים, שנערמו
        כרוכסי-הרים זה על גב זה; ועל פני חורשת-הזיתים הרחוקה מתאבקים שני
        ספינקסים ענקיים כשפניהם מופנים בזעם איש מול רעהו. -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        נצנץ ברק והאיר גוש של עננים כהים, הסוככים על ראשי הספינקסים.
        נצנץ – ומיד התחילה יריה תכופה של ברקים, שפזרה את המראות ואת הצבעים.
        ומשנשתתקו יריות-האש גחו לאט צללים וכסו על הסביבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        וויינהולץ קם מפני שהתחיל מרגיש רעבון. צריך הוא להרתיח את החלב
        ולהכין לו ארוחת-ערב. מה לעשות? - יטפל עתה מעט ב"הלכות-הבית", וכשתחזור
        יהודית מווינה, שוב יהא חפשי מעבודה אי-נעימה זו! – סוף-סוף אין החיים
        כל-כך קשים בלעדיה כמו שצייר לעצמו. מעט הטפול בבוקר ובערב הוא דבר קטן.
        העקר היא ארוחת-הצהרים, וארוחה זו הוא אוכל במטבח של הפועלים. אוהב הוא
        חבורה צעירה זו כשהיא באה בתאבונה הבריא ומגיחה אל קרבה מתוך ווכוחים חמים
        את המאכלים המעלים הבל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        אותו, שאינו פועל, אין הפועלים מחבבים ביותר, כמובן. אבל הם נוטים
        אליו קצת חסד מפני שהם יודעים אותו בתור אכר חרוץ, העושה בעצמו גם עבודה
        קשה בשדה ובכרם. אינו בורגני נורא אך-על-פי שיושב הוא בבית נהדר ומתענג על
        רֹוב שפע. הם "סובלים" אותו. על זה מעיד היחס שלהם בשעה שהוא מרצה לפעמים
        ב"בית העם" על נסיונותיו האכריים. הקשבה ושתיקה גמורה שוררים אז בין
        כתלי-האולם! אבל הוא אינו מסתפק ביחס "נוח" זה. לבו כוסף, שיתקרבו אליו
        הצעירים, שיברא יחס של ידידות ביניהם. ואולם זהו דבר שאי אפשר. גאוה
        וקרירות מביעים מבטיהם מדי ינסה לגשת אליהם כידיד... היתרעם? כלום יש לו
        הזכות להתרעם? – הרי אף הוא עצמו היה כך לפנים, אף הוא הביט בבוז על הסביבה
        האכרית עם התמיכה הפאריזית!...</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c7">ב</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"מה עדיף ממה – האדם או עץ השדה?" -  שאלה זרה ומשונה זו אינה מסתלקת
        ממֹחו של וויינהולץ כל הבוקר. עם הנץ-החמה קם, חלב את פרותיו ושלח אותן אל
        העדר, ואת סוסו רתם בעזרת מחמוד רכבו ושלח את מחמוד ואותו לעבודה. אז נכנס
        אל גן-הפּירות הסמוך לבית, סבב וסבב בו ומצא, שהאדמה נתיבשה וצריך לעדרה
        היום, להשקות אחדים מן האילנות ולהעמיד להם סמוכות. והוא החליט להתחיל
        בעבודה זו מיד אחרי שתיָת כוס חלב חם, ולא לדחותה. ופתאם נמסרה לו פתקא
        קטנה, שזלמן פינקל חולה ואם יש פנאי יבוא-נא לשבת שעה קלה על-יד מִטת החולה
        - -
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ידו רעדה. פינקל!... פינקל!... מה גדולה היתה הידידות, ששלטה
        ביניהם לפנים! וכשנתפרדה חבילתם אף ניצוץ מאותה הידידות החמה לא נשאר!...
        וכלום מחויב הוא עתה לעזוב עבודה כה נחוצה בשבילו? אם ילך, הלא לא יוכל
        לעזבו מהר ויצטרך לשהות אצלו כל הבוקר – וזהו דבר שאי-אפשר: חס על האילנות
        העלובים!
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >"אבל העצים לא ימותו ביום אחד!" – מדבר אליו קול מתוך מעמקי-הלב. ואולם
        קול אחר עונה לעומתו:
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >"הטפול בעצים דרוש מיד! השרב של הימים האחרונים יִבּש  את האדמה והעלובים
        הללו טרפיהם מתחילים להתקפל ולהתכמש!... הנח לו לפינקל! במחלתו אין שום
        סכנה ויש די משגיחים עליו בלעדיך!" - -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">        נבוך יחזקאל: מי עדיף ממי – האדם או עץ-הגן?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        והעצים נצחו: כל היום הוא מטפל בהם, ביחוד בחלשים שבהם, הנטועים
        על חלקת-הזִבורית של הגן. כאן חָוְרו העלים מחוסר כלורופיל ונוטעם חש צער
        עמוק למראָָָָם העלוב. הלך לחפש במחסנו שיָרי זבל חימי של אשתקד, זִבל אותם
        ונתן להם השקאה כפולה, אף-על-פי שלבו לא האמין באפשרות טיוּבם. רק עם פנות
        היום, כשנפלו קרני-השמש באלכסון על העלים החִוְרים ונדמה בעיניו, שצבעם הפך
        ירֹוק קצת, התעוררה בו תקוה לתחִיָתם והוא מלמל בשמחה: "חביבי יקומו לתחיה,
        י-קו-מו!" -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        עיף הוא עם גמר העבודה, וכשהוא שוכב פרקדן על המרפסת המכוסה כולה
        ירק מטפס ועולה, מבצבצת ועולה על לבו פתאם אותה השאלה המדאיבה, שהוא כל-כך
        מתאמץ תדיר לגרשה מלבו: למה ובשביל מי הוא עמל ככה? מי יוקיר את פֹּעלו? מי
         ימשיך את עבודתו? מי ישגיח על טפוחיו כשימות? – ולבו נלחץ כמו בצבת. הוא
        עוצם את עיניו ומתוך הַעֲוָיָה משונה הוא נושך את שפתו התחתונה עד זֹב דם.
        צר לו בחזה ונשימתו חטופה. הוא קופץ וקם מעל הספסל, יורד מן המדרגות ומתחיל
        רץ בגן אילך ואילך. ומחשבות שחורות ונוגות שומרות את צעדיו וכעדת עורבים הן
        צורחות מעל לראשו. - - -</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c7">ג.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא גמר מכתב ארוך ליהודית, והשתקע במחשבות. העלובה! עתה היא מתעתדת
        לנתוח... והיא הרי לא רצתה בזה כלל. הוא הכריחה לכך אחרי מלחמה קשה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        היא סבלה הרבה. סבלה ועבדה ושתקה. הוא ידע את אופן-עבודתה, - ידע
        שהיא קמה בבוקר רצוצה ועושה את עבודת הרפת והבית בקושי, בשארית-כֹחותיה. יש
        שלפני גמר-עבודה היא נופלת על הספה באין אונים ופניה מפיקים יסורים וצער, -
        אין עוד בה כח לערוך את הבית ולבשל ארוחת-צהרים. אבל גם לשכב אין לה
        סבלנות, והיא קמה, מאַמצת שארית כחותיה ונִגשת אל העבודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כשהתחיל הרופא לדבר על נחיצות נסיעה לווינה צחקה מתוך דמעות ואמרה
        בהחלט שלא תסע, בשום אופן לא! - ואולם המחלה הלכה וגברה בינתים וחולשתה
        הלכה וגדלה. כשהיה מנסה לגעת בזהירות בשאלת-הנסיעה היתה מתרגשת, בוכה
        ומתחלת לעבוד במהירות קדחתנית.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        - אבל למה את מסרבת ומתעקשת? למה אינך רוצה להציל את חייך?...
        אמרי, יהודית, כלום יש איזה הגיון בדבר?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        - ודאי שאין בזה מן ההגיון! אבל מה לעשות? אי אפשר לי להפרד
        מביתנו היפה, מחורשה זו ומן הגן שלנו. כל נימי-הנפש קשורות בהם ונדמה לי,
        שאם אפרוש מהם אני פורשת מן החיים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        - אך ילדות היא זו! - היה מדבר אליה רכות וקשות כאחת, אבל ללא
        הועיל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ולא נכנעה יהודית אלא אחרי לילה של יסורים קשים מאד. הוא היה אז
        ער כל הלילה, רץ מן הרופא לבית-המרקחת וחזרה, טִפל בחמום-מים,
        בתחבשות...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        למחרת גִלתה לו שקודם שהעירה אותו היה לה כל כך רע, עד שחשבה שהיא
        הולכת למות. זו לה הפעם הראשונה שנבהלה מפני המות! לכי ועזבי פתאם כל מה
        שיָקר לך וחביב עליך, את המושבה הנחמדה, את החורשה ואת הגן, את הבית ואת
        הכרם, את הנפש האהובה עליך והאוהבת אותך, את כל עולם-יה היפה, והִסחבי לתוך
        אדמה לחה, קרה, ששם יהא גופך מאכל לתולעים מסואבים!... הלא זה נורא!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        והוא חוזר ונזכר בנתוח – ומחזה קשה מתחיל לענות את דמיונו: יהודית
        בחזקת סכנה! היא שואפת להמלט מבית-החולים ולחזור לארצה, לחזור ולמות בה –
        ואינה יכולה! הנֵכר כופת אותה ואוחז בה – ואין מנוס, ואין מפלט...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        אחר-כך נדמה לו, שהוא שומע את אנקתה מתחת לאיזמל – והוא לוחץ את
        ראשו ביאוש... למה הטפול במֶשק שלו, באילנות, בחיות-הבית, למה לו כל החיים,
        אם חברתו הולכת ונמקה שם ביסורים ומכאובים קשים?... ולמה הוא אוהב את הטבע
        האכזרי, שחלק ליהודית חבלים באפו?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        רעד עובר בעצמותיו... הוא נופל אל המטה בלי שפשט את בגדיו ועוצם
        את עיניו... אבל עיני-יהודית הגדולות, הילדותיות, נוגות הן ונעוצות בו
        בבקשת-רחמים. - על מה היא מתחננת, על מה מתפללת: על חייו, על נפשה? -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        איזו תמונה משונה זעה בחדר והאפילה על פניה של יהודית... מתקרבה
        התמונה אליו – מה איומה אשה סתומת-עין זו! מה היא רוצה?... הוא רוטט כשהיא
        נגשת בצעדים גנובים אל מטתו... מתחלה היא סוקרת אותו בעינה האחת המלאה זועה
        ואחר-כך מתחילות שפתיה הכחולות והצמוקות לזוע והנה הן רוחשות מלות
        נוראות...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        צעקה התפרצה מלבו – והקיץ. הוא הרים את ראשו, סלק מעליו את המכסה
        בזעם, נעץ עיניו בחשכה ואחר-כך הפנה את ראשו לצד החלון. מבין חרכי-התריס
        זולף אור קלוש של ליל כוכבים. האור משקיט לאט-לאט את עצביו והוא אומר
        להרדם. אבל – עוד הפעם תמונה נוראה לפניו: את יהודית מוליכים שם!... היא
        נכנסה הביתה כשהיא תמוכה בזרועותיהם של שני אנשים זרים, פוסעת פסיעה אחר
        פסיעה ופניה חורים כפני מת... ופתאם היא מתעלפת...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        הוא קופץ בבהלה וזעה קרה מכסה את כל גופו. הוא רוצה להדליק מנורה,
        וכשאינו מוצא את הגפרורים הוא צונח באין אונים על הדרגש. - -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ...לאחר זמן, שאינו יודע, אם ארך או קצר, קם והתחיל להתהלך בחדר
        ולמשש את הקירות ואת הרהיטים. יד נגעה בבריה רכה ושעירה... היא קפצה פתאום
        מעל הספה והוא נרתע לאחוריו בבהלה, צעק צעקה מרוסקת – ונאלם דומיה מרעידת
        בת-קולו...</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c7">ד.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0">וויינהולץ שב מיפו ודעתו נחה קצת.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        בדיליז&#39;אנס שהלך מן העיר אל המושבה נסעה גם שולמית כהן והוא
        ישב אצלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        בת שמונה-עשרה היא והוא זוכר את יום הִולדה. באותה שנה בא בתור
        פועל אל המושבה. ביחד עם פועלים-חברים אחרים התחיל לבקר את בית אבותיה
        הצעירים. האֵם היתה אישה מעניינת, עליזה ומרפרפת על פני שאלות-החיים
        הבוערות. הרבה מצעירי-המושבות נהרו אל ביתם. על ברכיו ועל ברכי-חבריו גדלה
        שולמית, ולבו גס בה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ענין לשיחתם היתה תחילה יהודית. הילדה שאלה לשלומה והוא השיב
        בנחת: - היה זמן קשה. בסכנה היתה יהודית. עתה עבר, הכל עבר! בעוד שני חדשים
        תשוב יהודית. הלא אז תבוא גם שולמית לביתם? בלי יהודית לא תעיז ילדה פחדנית
        שכמותה לבוא! הלא וויינהולץ הוא אדם נורא: בלורית גדולה, זקן שחור ואינו
        מתנהג בנימוס!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">        היא צחקה והוא ספר לה מרשמי-יפו:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        הוא יורד אל הפוסטה האוסטרית ונגש אל ה"גומרוק" </span
      ><span class="c3 c13">[בית-המכס]</span
      ><span class="c0"
        >. אמרו, שתימנים באו. בלב-ים עמדו שתי ספינות והורידו את נוסעיהן. ולא רק
        תימנים הרבה באו. הספינות נשאו עמהן שָבי-גולה מצפון ומדרום. מן הדרום באו
        תימנים – אנשים מסכנים, יחפים, פרועי-שער, בלי מעילים וכמעט בלי כותנות.
        בשרם עטוף מטליות, ועל כתפם טליתות. מראיהם זר ומשונה, אבל הלשון העברית
        בפיהם והם מברכים את אחיהם ואת "השם" על גאולתם ופדות-נפשם, על-שיָצאו מארץ
        טמאה ובאו לארץ טהורה וקדושה... וספינת הצפון אף היא הורידה בנים שבים אל
        שלחן אביהם. צעירים באו – שלֹשים גבורים, בחורים רעננים ומלאי-כח. ומי ימלל
        שמחת-לבו למראה הנהדר, כשצפון ודרום נפגשו! רוח אחת קבצתם והביאתם לכאן,
        הביאתם לשם בנין ויצירה... רגעים יקרים, רגעים קדושים עברו עליו
        היום!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        שולמית הקשיבה והתרגשה. דמעה נוצצה בעינה. הוא נשתתק...
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >לילה. וויינהולץ עומד על-יד החלון הפתוח. רבבות כוכבים מזהירים מהבהבים
        במרומים ומעוררים בו מחשבות... מה חיי-האדם לעומת גודל ויופי זה?...
        בנים... יהודית... כלום אי-אפשר בלעדיהם? כל זמן שעולה היָרֵח ממזרח, כל
        זמן שאלפי רבבות כוכבים מזהירים בכִפה נפלאה זו – מה מקום ליאוש? - והרי יש
        לו גם מולדת, גם קרקע, בית, וגן ושדה, ומושבה חביבה, ועבודה, ואידיאל נהדר.
        כלום כל אלה אינם יכולים להכריע אהבת-אשה ותשוקת-בנים?... ופתאם נוטות
        מחשבותיו לעבר אחר:         </span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >מה משאיר האדם אחריו?... היוצרים – הסופרים והאמנים משאירים להם שם ושארית
        ביצירותיהם. אך מה ישאיר אחריו אדם שכמותו, שלא יברא עולמות חדשים? אך
        "השארות-נפש" אחת יש לאדם פשוט עלי אדמות ואך בו כמוס סוד המשך החיים:
        בנים, בנים!... והוא – ערירי ילך כל ימיו אם לא תשוב אליו יהודית בריאה
        ושלמה...</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >ראשו מורד ולחוץ אל השמשה הקרה של החלון. לעיניו נצבה תמונת בנָיָהו, ילדו
        הנפלא של שכנו. – מפני-מה פעוט זה מתחמק תדיר ממנו? אפילו כשהוא מביא לו
        סוס יפה או חרב מן העיר אין הילד שוהה על ברכיו – בורח! יש שהוא רץ כנער
        לתפוש את הילד – ופתאם הוא נתקל במבטה של יהודית המלא תוכחה.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >ועוד תמונה אחת נצבת לפניו וממלאה את לבו געגועים כמוסים: שולמית כהן. הרי
        כמעט יכלה להיות לו בת כזו... בת כשולמית! אלי, אלי, מה גדול האושר!</span
      >
    </p>
    <p class="c5 c2 text-center" dir="rtl"><span class="c7">ה.</span></p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >יום מעונן. מן הבוקר מטפטף גשם ואי-אפשר לעבוד בשדה ובכרם. וויינהולץ נשאר
        בבית והוא מתקן את רִתמת סוסו, שמתענג היום על חירותו ומנמנם על אבוסו אחרי
        הארוחה השמנה! יודע הוא, שהאדמה ספגה גשמי-יומים ואין לחרוש אותה לא מחר
        ולא מחרתים. רק זמורות של גפנים יש להוביל אל החצר. זוהי עבודה קלה ונעימה:
        מחמוד יושב על ערמת-הזמורות, מזמזם איזה נגון קל ושוכח את השוט. הזמורות
        מרשרשות מתוך שהן נסחבות על הארץ והוא, ה"אפור" – כמו שבעל-הבית קורא לו –
        מתנהל לאטו, מאזין ומקשיב!... האח, ימים טובים הם ימי-הגשמים, ומי יתן
        וירבו כמותם בארץ!...</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >וויינהולץ מאַחה את העורות על-ידי תפירה בחוטים מזופתים. את עבודתו הוא
        עובד בעמידה אצל החלון. ריח-האביב מתפרץ מן האשנב הפתוח אל החדר
        וריח-הירקות מתערב בו. יָפוּ הירקות בגן שממול החלון! - תרמיליהם של הפול
        והחומצה נתמלאו; העלים של תפוחי-האדמה נשתטחו על התללים מרוב כובד; החסא
        גדלה, רחבה והתפשטה בשורותיה. סמקו עלי-הסלק וגבה ונזדקף הבצל. - הכל נושם
        בשמחה את ריח-הגשם.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >השמים נחלקו לחצאין. מצד אחד נצברו עננים בעלי גונים וצורות שונים, ומצד
        שני זכים הם וטהורים ופורשים סדין של תכלת על חולות-הזהב. אנה פנו האורות
        והצללים מן החולות? לאן נעלמו העמקים החבויים בצל? - לגוש ארוך אחד של זהב
        נהפכו החולות. הקשת שבענן מכתרת אותם בטבעת של קסם מלמעלה, ושם, למטה, שדה
        ירוק מחבק את החולות, לוחצם ומהדקם אל חזהו.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >וויינהולץ הסתכל במראות הנהדרים ושב לתפירתו, תוחב את המחט לתוך העורות,
        והרהורים על התפתחות-הישוב, ההתפתחות ההדרגית והטבעית, מבצבצים ועולים
        במחו.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >...כן, לתוך כל פנה אפלה חודרים נצוצי-אורה! קלים הנצוצות, עדיין הם אך
        מרפרפים על פני החיים, אבל בכל מקום שחודר האור שם יש צמיחה ולבלוב.
        המוסדים החלשים של עכשיו ילכו ויתחזקו והנחיצות להרבות בגאולת-הארץ תחדור
        מעט-מעט אל כל הלבבות. ועם-ישראל סוף-סוף ינהר כּולו, מתוך כח-דוחה מבחוץ
        וכח-מושך מבפנים, אל מקור-חיוּתו היחידי, שבו יש לו עוד הצלה מכליון רוחני
        וגשמי... כל הדרכים נסגרו; נשאר רק שביל זה הצר, יחידי בין סלעי-המגור –
        וכלום אפשר שלא ילך בו עם שומר תקות-הגאולה זה שנות-אלפים?...</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >ואמונה מאירה כזו כלום אינה יכולה לעמוד לו במקום בנים ואפילו במקום
        יהודית? אכן, אדם קטן הוא!</span
      >
    </p>
    <p class="c2 c5 text-center" dir="rtl"><span class="c7">ו</span></p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >כל עבודות החורף, ואפילו מעט ההרכבות שהיו לו, נגמרו. תכנית לעבודה אחרת
        עוד אין לו ונפשו פזורה קצת – קשה לו בלי עבודה.
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >בוקר. הוא שותה חלב קר כשהוא עומד על יד החלון, כמנהגו. השמש התרוממה על
        הרי-יהודה והפיצה אורות-זהב על כל הסביבה. ירח חִוֵר נעצר על חוג-שמים והוא
        מסתכל בעדת צפורים שובבות, שמסתובבות למולו בעגול גדול.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >משום מה זכר וויינהולץ עתה את העצה, שהשיא לאחד מידידיו ושלא הוליכה אל
        המטרה, – וידידו סבל וסובל עדיין הרבה באשמתו. מוסר-כליות התעורר בנפשו.
        נדמה לו, שהכל רחוק ממנו והוא בודד בחיים.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >הוא מתלבט מפנה לפנה ואין מפלט לו מלחץ-הלב. אז הוא נכנס אל האורוה, חובש
        את סוסתו ורוכב אל הקמה. מתוך רוגז הוא מצליף על גב הסוסה ונִשא בסערה מן
        החצר.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >בסמטה המֵצֵלָה שעבר בה שרו מקהלות צפרים, שקננו על התותים העבותים. על-יד
        השיטה בצבצו מתוך העשב הירוק שעל פני התעלה פרחים לבנים, סגולים ואדומים.
        כולם רטובי טל-בוקר ולוקחים לב בקסם-יֹפיָם. על הגבול, בצדי-הדרכים, נראו
        בין קבוצות הבצל הפרא פרחים גדולים. ושם, בשדה החטה והשעורה, כשטיח ירוק
        לפניו, שטיח מרהיב-עין. - הוא נגש אל חלקתו, - והיא נראית לו היותר יפה
        שבשדה! לאט-לאט מתחלת האבן הכבדה להגול מעל לבו. איזה נגון עתיק, שהיה מחבב
        בימי-נעוריו, עולה על השפתים מאליו. אורות רבים השתפכו סביבו, חדרו אל לבו
        והניעו מיתרים רכים...</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >יהודית! היכן יהודית? – כמה היה משתוקק אותה שעה לחבק אותה ולהוליכה אל
        מרחב-יה זה!...</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0">בערב ערך לה מכתב מפורט:</span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >הוא תאר לה, כמה מצפה הוא יום-יום אצל סוכת הדאר הצבועה פסים לבנים-כחולים
        לבוא הדיליז&#39;אנס: שמא יביא לו בשורה טובה מילדתו החביבה. אף כתב לה על
        החדשות שבמושבה:</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >סוף-סוף נתקבלה הצעתו הישנה באספת המושבה ושִווי-זכֻיות נִתן לפועלים עם
        התושבים. אמנם, קשה היתה המלחמה, אבל עתה הנצחון הוא נצחון גמור.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >קציעה צדיקוביץ, הבת הצעירה של מיסד המושבה, נארשה לעשיר אחד מאויסטראליה.
        הוא, יחזקאל, הטה את לב החתן לקנות נחלה במחיר חמשים אלף פראנק
        בעמק-שושנים, וכשנגמרה הקניה ונערך נשף לכבוד דבר זה – אילו ראתה יהודית,
        כמה רקד "הזקן שלה" מתוך שמחה!</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >והוא מוסיף וכותב לה, שכל עבודות-החורף שלו נגמרו ועתה הוא סובר לטפל
        בגנה:</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >"...עזובה היא בלעדיך, חביבה! מסביב לפרחים עלו עשבים הרבה, שממתינים
        למעדר הקטן שלך ולידיך הרכות והענוגות! נִכּרת התקדמות רק בהקאזוארינות,
        שזבלתי בתחִלת החורף באותו האופן שנהגתי בשנה שעברה באיקאליפטוסים שאצל
        האורוָה. חפרתי תעלות במרחק ידוע מן השרשים, שפכתי את הזבל וכסיתיו בעפר.
        רבוי-הגשמים הפריד את הזבל המצוין הזה לחלקיו, - והקאזוארינות שגשגו וגדלו.
        אילו שמעת עתה את רשרושן ואת לחשן! רוחות-ים מסיחות בין עליהן-מחטיהן
        ומגלות לי סודות ורזים וחלומות של יהודית שלי..."</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >והוא גמר את מכתבו בהטעמה אחר הטעמה, שהוא מצפה ליהודית בעינים כלות
        ובזרועות פתוחות.</span
      >
    </p>
    <p class="c5 c2 text-center" dir="rtl"><span class="c7">ז</span></p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >איזו חדווה של נוער שורה על וויינהולץ משעה שקבל טלגרמה מיהודית, שהנִתוח
        עבר בשלום, אף על פי שעדיין היא חלשה מאד. הוא התחיל מכין את עצמו לקראת
        בואה. הלא החולשה אחרי נתוח קשה כזה דבר טבעי הוא, וכמובן, תעבור
        בקרוב.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >ידיו מלאות עבודה. הוא מסייד את הבית ושתי ערביות רוחצות, כובסות ומשפשפות
        את הכל: רוצה הוא, שיהודית תמצא את הכל בסדר ובנקיון. ערבי קטן עודר כל
        הימים בגן-הירק ובגנת-הפרחים, והעגלון מוביל חצץ לישר את החצר ואת שדרת
        ה"זאנזילה".</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >עברו ימים אחדים. בבית כבר הכל נקי, נוצץ ומבריק. כלתה כל העבודה. הוא
        מסתכל בחדרים ופניו נוהרים משמחה. נדמה לו, שהעולם כולו מלא יופי, מלא קסם;
        רק יהודית חסרה. למה היא מאחרת לבוא? למה אין שום מכתב ממנה? - אך הלא היה
        כתוב מפורש, שהיא חלשה מאד - ואיך תכתוב? - עוד מעט ותבוא! בינתים הלא ידיו
        מלאות עבודה ולבו מלא רעיונות ותקוות. - ומה לו עוד?</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >באחד מן הלילות, בשעה שנדדה שֵנה מעיניו והוא חשב בדאגה על שיבת יהודית,
        נזכר, שהכין הרבה עשב בשביל הפָּרות, ועשב זה יתקלקל עד מחר אם לא יתן אותו
        לפרות מיד.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >הוא פתח את מנעול השער של האורוה, ששמש גם רפת, ונכנס. ריח-עשבים נדף בכל
        פנה. הוא נטל מלוא-זרועות עשב מן הערמה ונתן אל תוך האבוסים. הפרות זזו
        בעצלות ממשכבן, הִפנו את ראשיהן אל המנורה, הרהרו רגע, השקיעו את סנטריהן
        אל תוך האבוס והתחילו לברר את מבחר העשבים, הוציאו אותם אחד אחד ולעסו
        בעצלות.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >- שבעות אתן! - מלמל וויינהולץ וטפח בחבה על גבה של פרה גדולה ויפה. והוא
        עומד ומנורה בידו ומסתכל בטִפוחיו: - עטיניה של השחומה הולכות ומתמלאות!
        כנראה תלד בערב פסח וכל ימי-הקיץ תהא ברכת-חלב בבית!... "העליזה" – כמה
        יפתה מן העשבים! וגם זו ה"גוצה", הבת הבכירה של "הסרבנית", הוסיפה
        חלב!</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >וכשהוא סוגר שוב את המנעול אינו פוסק מלחשוב על פרותיו:  "שלש נחלבות!
        יהודית תמצא בית זב-חלב. גם גבינה, שמנת וחמאה תהיינה בבית לרוב. יהודית
        תתחזק אחרי יסוריה, ואז... אולי... אך לא! איני דורש כלום, לא כלום! - רק
        בריאה תהיה, החביבה!" –</span
      >
    </p>
    <p class="c8 c11 text-center" dir="rtl">
      <span class="c7">*            *</span>
    </p>
    <p class="c5 c8 text-center" dir="rtl"><span class="c7">*</span></p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >ולאחר שלשה ימים נדפסה מודעה בעתונים היוצאים בירושלים:</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >"לרגלי מקרה של אסון מוֹכר אִכר במושבה N בית יפה עם גן וגִנת-ירק, כרם
        ושדה-תבואה, שמביאים הכנסה הגונה, מפני שהוא עוזב את הארץ" - -</span
      >
    </p>
    <p class="c1 c2 c4" dir="rtl"><span class="c0"></span></p>
    <p class="c1 c2 text-left" dir="rtl">
      <span class="c6">עין-הקורא, סוף הבציר, תרע"ב</span>
    </p>

  </body>
</html>`;

export default page;
