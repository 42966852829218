import styled from "styled-components";
import UpImg from "../images/up-arrow.png";
import { useState, useEffect } from "react";

function UpButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 260) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 260,
    });
  };

  return <UpBtn visible={isVisible.toString()} onClick={scrollToTop} />;
}

export default UpButton;

const UpBtn = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  width: 32px;
  height: 32px;
  background-size: 20px;
  cursor: pointer;
  border: solid 1.4px #000;
  border-radius: 100%;
  background-image: url(${UpImg});
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-color: #f7ecd8;
  display: ${(props) => (props.visible === "true" ? "initial " : "none ")};
`;
