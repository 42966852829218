const page = `<html>
  <body class='c10 doc-content'>
    <p class='c3 italic' dir='rtl'>
      <span class='c2'>
        כל המכתבים כאן נכתבו בסוף 1916 ובחודשים הראשונים של 1917 ע"י אפרת, אז
        כבת 19 לאמה נחמה פוחצ&#39;בסקי. רוב המכתבים נשלחו לראשון לציון מיפו
        (מיעוטם מתל אביב) שם למדה אפרת בסמינר למורים. אנו מביאים כאן רק חלק
        מהמכתבים, בדרך כלל הם אלה שמתוכם ניתן ללמוד משהו על מערכת היחסים בין האם
        לבתה, על תנאי החיים ביפו ובבית פוחצ&#39;בסקי בראשון לציון ועל נחמה עצמה.
        נזכיר שזו תקופת מלחמת העולם הראשונה, אמנם עדין אין קרבות בא"י אך היישוב
        היהודי בארץ כבר סבל מתופעות לואי של המלחמה בצורת גיוס אנשים, גיוס בהמות
        משא לשרות הצבא, החרמת תוצרת חקלאית וכד&#39; והמצב במושבות היה קשה.
        המכתבים מגיעים כמעט עד לזמן הגרושים  מיפו ומתל אביב.
      </span>
    </p>
    <br />

    <a href='#5.12.1916'>
      <p class='c3' dir='rtl'>
        <span class='c8'>י&#39; כסלו התרע"ז    </span>
        <span class='c4 italic'>[5.12.1916]</span>
      </p>
    </a>
    <a href='#23.12.1916'>
      <p class='c1' dir='rtl'>
        <span class='c5'>כ"ח כסלו התרע"ז  </span>
        <span class='c4 italic'>[23.12.1916]</span>
      </p>
    </a>
    <a href='#noDate'>
      <p class='c3' dir='rtl'>
        <span class='c5'>אמא חביבה! </span>
        <span class='c5 italic'>[אין תאריך]</span>
      </p>
    </a>
    <a href='#16.12.1916'>
      <p class='c3' dir='rtl'>
        <span class='c8'>כ"א כסלו התרע"ז  </span>
        <span class='c4 italic'>[16.12.1916]</span>
      </p>
    </a>
    <a href='#thursday'>
      <p class='c3' dir='rtl'>
        <span class='c8'>יום ה&#39; </span>
        <span class='c4 italic'>
          לפי התוכן – טבת תרע"ז, לקראת סוף דצמבר 1916
        </span>
      </p>
    </a>
    <a href='#31.12.16'>
      <p class='c3' dir='rtl'>
        <span class='c5'>31/12/16</span>
      </p>
    </a>
    <a href='#6.1.1917'>
      <p class='c1' dir='rtl'>
        <span class='c8'>י"ב טבת התרע"ז   </span>
        <span class='c4 italic'>[6.1.1917]</span>
      </p>
    </a>
    <a href='#11.1.1917'>
      <p class='c1' dir='rtl'>
        <span class='c8'>י"ז טבת התרע"ז </span>
        <span class='c4 italic'> [11.1.1917]</span>
      </p>
    </a>
    <a href='#alefShvatTaraz'>
      <p class='c3' dir='rtl'>
        <span class='c8'> </span>
        <span class='c4 italic'>[א שבט תרע"ז]</span>
      </p>
    </a>
    <a href='#yomBetNoDate'>
      <p class='c3' dir='rtl'>
        <span class='c8'>יום ב&#39;  </span>
        <span class='c4 italic'>[אין תאריך]</span>
      </p>
    </a>
    <a href='#yomGimelJan1917'>
      <p class='c3' dir='rtl'>
        <span class='c8'>יום ג&#39;  </span>
        <span class='c8 c6 italic'>[סוף ינואר 1917]</span>
      </p>
    </a>
    <a href='#motzshak'>
      <p class='c3' dir='rtl'>
        <span class='c8'>מוצש"ק  </span>
        <span class='c4 italic'>[אין תאריך]</span>
      </p>
    </a>
    <a href='#niceMom2NoDate'>
      <p class='c3' dir='rtl'>
        <span class='c8'>אמא חביבה!  </span>
        <span class='c4 italic'> [ללא תאריך]</span>
      </p>
    </a>
    <a href='#14.3.1917'>
      <p class='c3' dir='rtl'>
        <span class='c6'> </span>
        <span class='c8 c6 italic'>
          [לפי תוכן המכתב, כ&#39; אדר תרנ"ז, 14.3.1917]
        </span>
      </p>
    </a>
    <br />

    <p class='c3' dir='rtl' id='5.12.1916'>
      <span>יום ג&#39; י&#39; כסלו התרע"ז    </span>
      <span class='c2 italic'>[5.12.1916]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא יקירה!</span>
    </p>
    <p class='c3' dir='rtl'>
      <span>באתי ליפו! כן. האמנם אני כבר פה? ההאמנתי זאת לפני שב</span><span class='c6 italic'>[וע]</span>
      <span>
         ימים? איזו כמיהת נפש. כמה מהפכות ושנויים. זעזועי כל מערכת העצבים. מצב
        של תליה בין שמים לארץ, בין מעשה לבטלה, בין למוד – חיים למות שהוא
        הריקניות. האמנם חייתי שנתים בראשון. אני אפרת השלמתי עם החיים נורמליים
        האלה של ראשון הבטלנית, במלא מובנה של מלה זו. הן! אבל כל זה הרי כבר עבר,
        ועבר באפן שאי אפשר להחזירו. זו היתה יד הגורל. הזמן הנורא הזה שחתה בנו את
        זרועו הקשה. אבל יקירה אם אדע לכל הפחות שעזרתי לך במה שהוא בהיותי בבית.
        אם המצפון שלי לא יסבל שהרגתי שתי שנים בימי נעורי אז א
      </span>
      <span class='c6'>ֹ</span>
      <span class='c0'>
        מר גם זו לטובה. ואמנם צריך לאמור גם זו לטובה אם עדיין קימים אנו, וכלנו
        בריאים ושלמים. ויכלתי גם לבסוף להגיע לאיזה נקודת משען הרי בודאי טוב. כן!
        לי טוב אך מה אתך אמא. קשה, קשה אני מרגישה את זה היטב. כשהכל, הכל מוטל
        עליך, ואת כה חלשה צריכה את הכל לשאת. אבל מה לעשות אמא?  היכֹל היה אחרת
        להיות? הלא ידעת שבראשון לא יכלתי להשאר. לו גרנו במקום אחר בקרבת בית ספר
        ואני יכלתי גם ללמד וגם לעזר על ידך כי אז מה מאשרה הייתי. אבל אם לא כך
        הסתדרו החיים אזי כעת בודאי לא יכולים לשנות זאת. אבל השתדלי לקמץ את
        כחותיך המעטים. תכנס נא הדיה כל יום לאיזה זמן ותעזר קצת.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>אני הגעתי בשלום למח</span><span class='c6 italic'>[ו]</span><span class='c0'>ז חפצי. גם את קיר הברזל אפשר לשבר ע"י חץ של זהב, ור&#39; אליהו זה הקשה
        בשביל שלשת הבישליקים הביאני עד הבית. הכל בכי טוב. את הכל יענה הכסף מה
        טוב כנראה בהמצאו בכיס.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        הכל היה שלם ובמצב הכי טוב. כלום לא נשפך, לא נשבר, לא נקרע ולא נתלכלך.
        שלם, מס
      </span>
      <span class='c6'>ֻ</span>
      <span class='c0'>
        דר, נקי וטוב. כמה פחדתי מפני נסיעה זו. ואיני יודעת אמנם מדוע, הלא כבר
        כ"כ הרבה פעמים שנסעתי עם החפצים ומעולם לא חרדתי כ"כ. אולי בא כל זה בתור
        תוצאה מהמלחמה הנפשית ששררה בקרבי במשך הזמן האחרון: לאן?...
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        וכך היה: באנו באחת עשרה. את החפצים, כלומר את הסל שמנו בחדר הילדות ואת
        הארגז על המרפסת. תיכף הלכתי אל עדה ובקשתי ממנה את הארגז. ממנה הלכתי אל
        נעמי ומשם הביתה. אחרי הצהרים הלכתי אל רחל. כמה שמחה היתה על שבאתי סוף
        סוף. והלכנו יחדיו לחפש איזו תלמידה ממחלקתנו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        חפשנו ולא מצאנו. אח"כ הלכה לה רחל. ואני הלכתי לקנות דיו, עטים וכו&#39;.
        והנה פגשתי בדרך בתלמידה אחת. הלכתי אליה ואצלה קבלתי את כל השעורים שצריך
        היה להכין להיום.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>בערב ישבתי כבר להעתיק איזה דברים, ולפי מצו</span><span class='c6 italic'>[ו]</span><span class='c0'>תך שכבתי ב10. אך הקיצותי ועוד היה חושך. ולא יכלתי כבר להרדם, רציתי כבר
        שיגיע בקר ואלך לבית הספר. ממש כמו ילדה קטנה. והשעה המאשרה הגיעה. הקדמתי
        לרוץ ולא ללכת לביה"ס. הבקר היה קר. בקר חרפי ממש, בחוץ עדיין שררה אפלולית
        קצת מפני שהשמש זה רק עלתה. ואני שוב על אותה הדרך עם הילקוט ביד. על אותה
        הדרך שצעדתי בה זה לפני זמן ארך, ארך לפני תקופה של שנתיים ימים. התשאר זו
        כבר הדרך הסלולה? ולא אתעה עוד על שדה בתולה לחפש לי שבילים? אך זמן! זמן!
        רחם נא על בני האדם!!!
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        באתי לבית הספר, נפגשתי עם כל החברות הישנות ונדמה לי שרבות גם שמחו
        לקראתי. בכל אפן הסבירו לי פנים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        השעורים היום היו: גאוגרפיה – אלגברה – משנה – צרפתית – ציור ופדגוגיה.
        שעורים מענינים כלם. איזה תענוג זה ללמד. האפשר להשאר צעירה וללמד תמיד? יש
        די חֹמר המספיק למשך כל החיים. חֹמר יש אבל האם נותנים החיים ללמֹד?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>לי אין פנה מיחדה. חפצי עומדים בחדר הילדות. לעב</span>
      <span class='c6'>ֹ</span>
      <span class='c0'>
        ד אני עובדת שם ביום, ובלילה על המרפסת מפני שאי אפשר להכניס מנורה הביתה
        בסבת היתושים. וע"כ אין לי נחיצות במנורה מיחדה אני משתתפת במנורה הכללית.
        וישנה אני בסלון על הספה. את הצהרים הגב&#39; יאפו מבשלת יחד עם שלה. והרי
        זה באמת איננו חשבון, אם עוד אדם אוכל. הלא הבשר על חשבוננו וגם הלחם הוא
        שלי. הנותר הרי טפל כנגד אלה. אבל בשבילי זה חשוב מאד מפני שבאות אנו בשתים
        מבית הספר והזמן מספיק בצמצום. ובכלל היא אינה נותנת זאת. בקר וערב הנני
        עושה כמו שחשבנו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בבאי מצאתי את הקטנה החמודה שוכבת ואחרי זמן מה בא גם האדון יאפו ושכב. היא
        כבר קמה היום אבל הוא עדיין שוכב. כפי הנראה קדחה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>יש עוד הרבה מה לכתב אבל אין לי אפשרות כעת.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום ונשיקות שלכם תמיד</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>את אשר שלחת קבלתי, ואת המכתבים מסרתי.</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='noDate'>
      <span>אמא חביבה!   </span>
      <span class='c2 italic'>[אין תאריך]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ברגע זה בא הערבי מהיקב ואני ממהרת לכתב לך מלים מספר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אפיתי כבר היום את הלחם והוא כמעט שהצליח. זו אמנם עבודה קצת אבל מה לעשות
        לשלם הרי יותר קשה. הנה אתמול מסרתי מכתב ע"י הפועל שבכרמל ור&#39; אליהו
        לא רצה לקבל והוא היה צריך למסור לאיזו הזדמנות איני יודעת למי. אדם כ"כ
        שפל עוד לא פגשתי. בעין רעה שלמנו לו בשביל הכל? מאשר להצטרך לו מוטב כבר
        לעמל קצת יותר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        עם הד"ר טורוב דברתי ע"ד השכר, והוא אמר שהתשלום השנתי הוא 7 נירות תורקים
        שאין צריך לתתם בבת אחת אלא לשירים
      </span>
      <span class='c6 italic'>[לשעורין?]</span>
      <span class='c0'>
        . וע"כ לפי דעתו יותר נח לנו לשלם. אני לא עניתי לו כלום על זה ואת תעשי
        כפי שתמצאי ליותר נח.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        את המכתב לוילקנסקי לא מסרתי עדיין. היית אצלו והוא איננו. נסע לאיזה מקום
        לא ידעתי לאן.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אצל ברלין הייתי בעצמי והוא ענה שישתדל לעשות כל מה שרק אפשר יהיה ביכולתו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        מה שנוגע ללמודים עדיין יש דברים רבים שעלי להשיג את המחלקה, ואת זה אוכל
        רק עד חנכה. קודם אי אפשר יהיה להספיק גם להעתיק וגם ללמד את כל מה
        שהחסרתי. אבל ביתר השעורים כבר התחילו היום לשאל אותי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אני צריכה ללמד תורכית. אראה אולי אמצא מי שהוא שילמדני. זוהי חובה ולא
        רשות ואהיה מוכרחה להשיג את המחלקה. תורקית עוד איני לומדת מפני שיש למודים
        יותר נחוצים הדוחים את התורקית. הנדסה עוד חסר לי רב. ומה שנוגע לזמנו של
        לדיז&#39;נסקי אני חושבת שאם הבטיח לי אז יש לו זמן. ומתי שאין לו האפשרות
        ללמדני אין אנו לומדים הרי אין כופים עליו הר כגיגית, עומדים על גבו
        ומכריחים אותו. זהו דבר הנעשה מתוך רצון חפשי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ולשלם לו? האם תמיד אמא צריך לשלם מיד. ובזמן שלומדים (במקרה זה) או בזמן
        שעושים איזו טובה אחרת צריכה להיות מטרת השכר. ארכים הם החיים ורבות בהם
        ההרפתקאות. נצרכים לפעמים לאיזה בן אדם, או אותו בן אדם צריך לך. ותמיד,
        תמיד נחוץ לשלם? זה קצת מוזר בעיני. אני חושבת שעם השקפה של שכר, קשה מאד
        לחיות. אנו מתאוננים כ"כ הרבה על שלטון החֹמר, ומי אשם לנו אם מגדילים אנו
        בידינו את ממשלתו. מרגע שחשבתי ללמד עם לדיז&#39;נסקי או מרגע שהתחלתי
        לומדת אתו לא עלתה אף רגע מחשבה לפני: במה אשלם לו?? ועלי להגיד לך שמוזרה
        היתה לי גם שאלתך שהיתה בשבילי חדשה כ"כ.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ומתפלאה אני למה את כותבת זאת ובכלל עולה זה על דעתך. בשעה שאת לכשעצמך כ"כ
        הרבה פעלת בשביל בני אדם אחרים. את כל חייך הקדשת לטובת אלה שהיו נצרכים
        לעזרתך ובטח מעולם לא חשבת לקבל שכר. מדוע חושבת את תמיד לשלם?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        כן! כפויי טובה לא צריך להיות. יש בני אדם כאלה שאוהבים מאד להשיב רעה תחת
        הטובה שעושים להם. או שאינם מרגישים כלל שהם צריכים להודות לאותו אדם.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        לו היה המצב אחר שהייתי יכולה להשתמש בכסף כי אז הייתי לומדת אצל
        לדיז&#39;נסקי בשכר. אבל אם חיים אנו בשעת חרום כזו אשר לקרובים ולזרים
        מקריבים קרבנות הרבה יותר גדולים משלש שעות בשבוע. בשבילי זוהי אמנם טובה
        גדולה, אבל מצדו אני חושבת שאין זה קרבן כ"כ גדול.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        וכעת אענה על שאלותיך כסדרן. המורה לספרות הוא אדון ברש, ואת דעתי עליו
        בתור מורה הרי יודעת את מכבר. איני יודעת מה הוא אותו דבר החסר בשעוריו.
        מות שולט בם. לומדים כעת בספרות את תקופת המאספים. ועל שאלתך איזה שעורים
        מענינים אותו ביותר עוד אי אפשר לי להגביל
      </span>
      <span class='c6 italic'>[?]</span>
      <span class='c0'>
         בדיוק. עוד לא התאקלמתי היטב בכל השעורים. לעת עתה מושכים אותי מאד שעורי
        חימיה והיסטוריה. איזה תענוג השעות האלה. היסטוריה לומדים עם האדון מרשק.
        האם מכירה את אותו?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        עם בנות המחלקה אין לי מגע רב, ביחוד עם אלה שהיו בה קודם אין לי כל יחס.
        אני התחברתי עם שתי הפתח-תקואיות שנכנסו בשנה זו. האחת נוביג
      </span>
      <span class='c6 italic'>[נוביק?]</span>
      <span class='c0'>
         והשניה מילר בת המורה. אבל גם אתן אין לי כל דבר המקשר חוץ מהשעורים,
        הרשימות, הכנת השעורים וכו&#39;, מה שבגבול הלמוד. אבל הנותר נשארה לי רחל
        לחברה. אין הבדל המחלקות משפיע. נפגשות אנו בכל ההפסקות, וכן אם יש לי איזה
        זמן חפשי אני באה אליה. בכל שבת בערב אנו מטילות יחד, וכלום לא השתנה
        ביננו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        היום היתה לנו הרצאה של האדון ברורמן (מורה בבית-ספר-לבנות). הוא הרצה ע"ד
        תקופת התעוררות הציוניות, תאר את השגיאות ששגו אז מחוללי התנועה, החנוך
        בא"י, השגיעות
      </span>
      <span class='c6 italic'>[כך במקור]</span>
      <span class='c0'>
         בחנוך זה וכו&#39;. הרצאותיו תמשכנה כך הלאה בכל שבת ושבת, ומקוה אני
        שתתנה לנו ספוק רב.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בחנכה הוא יפנה קצת מהסדר שהוא קבע לו וירצה על החשמונאים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אני ממהרת מאד הערבי כבר הולך.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>ונשיקות</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אהבתכם אפרת</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='16.12.1916'>
      <span>יפו מצש"ק כ"א כסלו התרע"ז  </span>
      <span class='c2 italic'>[16.12.1916]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא יקרה!</span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        את מכתבך ואת הכסף ע"י אדון וויניק קבלתי. אמנם צדקת בכתבך שבודאי לא הייתי
        שבעת רצון ממנו ביותר. אני באמת אוהבת לקבל ממך מכתבים ארכים, הנותנים לי
        ספוק רוחני, המותחים ממך אלי שביל מזהיר ועליו מתרקמת אריגה של דמיון,
        אריגה ההולכת ומתפשטת, הולכת ומסתבכת. ובכל זאת איני יכולה לדרש ממך לכתוב
        לי תמיד מכתבים ארכים, מפני שאת בעצמך מודה שלא תמיד נחה הרוח, ועל לרוח
      </span>
      <span class='c6 italic'>[הרוח?]</span>
      <span class='c0'> הן אי אפשר למשל, לצוות עליה לנוח בכל זמן שרוצים.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        אמא יקרה! מודה  אני לך על נושאך אותי בלבך תמיד. הרי זהו הקשר היחידי
        כשנמצאים ברחוק מקום. אני נושאת אתכם בלבי. אתם נושאים אותי בלבכם. כ"כ
        נעים כשיש לך דבר רחוק וקרוב. כשיש לך הורים האוהבים אותך והמתגעגעים אליך,
        ואשר אתה קשור אליהם, אוהבם, מקורים
      </span>
      <span class='c6 italic'>[מוקירם?]</span>
      <span class='c0'>...</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        מאד הייתי רוצה כבר לראותכם אבל לדאבוני לא אוכל לנסע לחנכה הביתה. יש לי
        הרבה מאד עבודה. להעתיק הרבה. להשיג בהנדסה (אם רק אספיק). וגם לקרא יהיה
        הרבה ואם אסע הביתה בטח לא אספיק את כל זה, כידוע כשבאים הביתה צריך אל כלם
        ללכת לצאת ידי חובת אנשים מלמדה, לקבל את כל המבקרות כל זה גוזל זמן, שאין
        לי רשות לפזרו. וגם לה<span class='c0 italic'>[ו]</span>ציא כסף מיתר אני חסה לה<span class='c0 italic'>[ו]</span>ציא. אם תהיה איזו
        הזדמנות לנסע ולשוב אעשה זאת. ואם לאו נתאפק עד איזה חפש שני. בלמודי אני
        שמחה, וכמובן לו הייתי משיגה את המחלקה בכל כי עתה שמחתי יותר, וגם הייתי
        מספיקה יותר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        למרים עליך לדעת שלא הבטחתי לכתב. היא אמנם הפצירה בי שאבטיח לה, אך אני לא
        נפתיתי להבטיח. בחנכה כשיהיה לי זמן אולי אכתב לה, אבל גם אז זה יהיה לפעם
        יוצאת מן הכלל. כי אין לי רצון לבא אתה בקשר מכתבים, להתכתב נעשה אצלי דבר
        לא קל כבר. מוטב לי לצמצם את כל מה שאני מרגישה במכתביך מאשר לפזרם על כמה
        מכתבים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        הלחם לא עולה לי בקשי רב, אני לשה בערב. זה אורך כחצי שעה כל הענין. אני
        קונה ב-2/1 עשריה שמרים ולא משאירה שאור בשביל שלא יתחמץ יותר מדי. ובשמרים
        לא מחמיץ כ"כ באפן שבבקר בקמי אני עושה את הלחם לתוך הפחים כמו שהייתי עושה
        בבית תיכף אחרי הלישה ע"י טבילת הידיים במים זה הרי כלל לא אורך זמן. ואז
        בבאי בצהרים הביתה יש לי כבר לחם מוכן. והלחם הוא טוב מאד. ומה גם מעשה ידי
        זה בודאי טוב בעיני.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>על הכל עניתי לך.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
         הייתי הבקר אצל נעמי שם פגשתי גם את עמינדב ואת יעקב. שלומית חשבה להשאר
        פה זמן יותר רב והנה היא מוכרחה לשוב מחר הביתה מפני שהדודה חולה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום לכלם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>ונשיקות חמות שולחת לכם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אוהבתכם אפרת</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ונא <span class='c0 italic'>[?]</span> תשלחי לי כ<span class='c0 italic'>[ו]</span>ס מהדקות ולא מהעבות היא נחוצה לי מאד, מאד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנ"ל</span>
    </p>
    <br />
    <br />

    <p class='c1' dir='rtl' id='23.12.1916'>
      <span>מוצש"ק כ"ח כסלו התרע"ז </span>
      <span class='c2 italic'> [23.12.1916]</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אמא יקרה!</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        את מכתבך קבלתי עוד ביום החמישי אך לא יכלתי לענות לך מפני שלא השגתי
        הזדמנות לשלח לך מכתב. וחשבתי אולי יהיה עשהאל פה בשבת אז אוכל לשלח לך על
        ידו. ואמנם שערתי נכון.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        היום היה אצלי עשהאל עם יונה ואח"כ באה גם עדה. כמובן ששמחתי מאד על זה, זו
        היתה כנראה הצטדקות על הפעם שעברה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        את המכתב מתחילה את כמו גם את הקודם לו, שיש לך הרבה מה לענות אך אין פנאי.
        כן אין לך פנאי אמא יקרה, זה כמו שאני אמרתי לפני עזבי את הבית. את בודאי
        עובדת הרבה, הרבה ולא עשית כפי שבקשתיך לקחת את הדיה לאיזו שעה ביום. את
        אֶת הכל עושה בודאי בעצמך. כשאני חושבת על זה, אוי, לו ידעת, כמה זה
        מצערני. אתמול חשבתי כל היום על זה, לא באה אתמול התימניה העובדת אצלם כל
        השבוע והן היו צריכות בעצמן לעשות הכל. אני כמובן עזרתי כמה שיכלתי ומפני
        שהתעסקתי קצת בעבודת בית זה הזכירני גם את העבודה אצלנו אסוסיצה של מחשבה.
        ובתארי בנפשי שבאותה שעה ממש עובדת את בעצמך בלי כל עזרה, אז.. אבל מה
        לעשות אין ביכלתי לעזר. לו יכלו לא רק לדבר ע"י טלגרף אל-חוטי אלא גם לעזר
        באופן אל-חוטי כי עתה היה טוב מאד.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        ע"ד הדירה של נעמי הלא היה להם משפט ופסקו שפלדמן צריך להשכיר להם את החדר
        ב10 פ"ר לחדש (בזהב). ואת פסק הדין הוציאו כבר אל הפועל ובחדר הסתדר רחביה.
        הוא משלם 7 פ"ר ואת ה3 הנותרים מוסיפה נעמי. החדר הוא נחמד, ובלי המכשפה
        למטה הרי זה תענוג. היה לי האשר להיות נוכחת באיזו קטטה קטנה. אנחנו ישבנו
        ולמדנו והנה נכנסה נעמי וספרה שהקטנה שלה (התימניה) רחצה את הקדרות והנה
        באה הזקנה, השליכה את הקדרות ארצה וכמעט ששברה אותן. אני יצאתי כלי רגזתי,
        נגשתי אליה ואמרתי לה מדוע היא עושה זאת, ומה ראיתי! אני אומרת לך פני זקנה
        זו היו כ"כ מכרכמים, חורים מכעס, ואיזו רשעת התבטאה בשרטוטי פנים איומים
        אלה. מה היה כל הענין. היא צעקת שהתימניה עושה פה אטַאק (נהר), והבטוי
        האוסטרי שלה (בT) כ"כ הצחיק אותי שמרוב כעס כמעט פרצתי בצחוק, באמרי לה שאם
        בחצר אסור לשפך מעט מים ומדוע נשפכים כל היום מים בהצנור שעובר ע"י המטבח
        של נעמי הרי שם נעשה ירקון נורא. ברגע זה חשבתי שהיא תשליך את הקדרות
        בראשי. כמובן שלספר מהם יש הרבה מאד. אנשים כאלה... האם אפשר היה לפלל את
        זה?
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        כעת מכיון שאין להם כל מגע כבר שקט. ואולי זה לעת עתה. מה שעוד יכול להיות
        מי יודע. מאנשים כאלה אפשר הכל לקוות.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        שלום הדודה כבר יותר טוב היא ירדה ממטתה עוד ביום הרביעי או השלישי. הדוד
        היה פה ביום החמישי. ואתמול בא נתנאל לשבת.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        נעמי רצתה לכתב לך בשבוע העבר אך בשלחי את המכתב ע"י רחל עוד לא היה מכתבה
        ערוך וע"כ לא שלחתהו.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        נעמי לפי ראות עיני תבלה את זמנה בנעימים. היא מבקרת את כל הנשפים, החגיגות
        והקונצרטים שהיו במשך כל זמן היותה פה. ובדבר חוג מכריה איני יכולה לאמר לך
        כלום כי לא התענינתי בזה. נדמה לי שאין חוג רחב.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        המצב של שבת העברה עבר כליל ולא השאיר כל סמנים רק עבעבועות שבצבצו לי על
        השפה. ולא נחוץ לי כל חינין.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        מסרתי לנעמי את דבריך בדבר הסופגניות והעוגות. היא אמרה שלחנכה אין שולחים
        דברים טובים. אבל לפורים היא עושה הזמנה. ומה שנוגע אלי אני סולחת בכל לב,
        הרי הדבר היותר טוב בשבילי כבר השגתי. והממתקים יהיו המכתבים שלך אשר בהם
        אשביע את נפשי.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        בהנדסה איני יודעת אם חסר לי הרבה מאד. בכל אפן אם לא עם א&#39;
        לדיג&#39;נסקי אין לי כל אפשרות להשיג. רחל עסוקה מאד ואין היא יכולה
        להקדיש לי זמן. כעת בימי החפש אנחנו לומדות את הפיזיקה שהספיקו ללמד בשנה
        זו לפני באי.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        אני מצטערת מאד שתקותך לראותנו נכזבה. נקוה שנבוא יחדיו באיזה חופש אחר.
        וע"י כך שיארך זמן הצפיה בטח נהיה אורחות עוד יותר יקרות לא כן?? תסלחי לי
        אם חמדתי קצת לצון.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אנא אבקשך לכתב לי מכתב ארוך שעל הכל תעני בו.</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        שלום לחנוכיה שלנו. השנה היא הפעם הראשונה שאינני רואה חנוכיה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>שלום ונשיקות,</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>לכלכם</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>מאוהבתכם</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>נצח</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='thursday'>
      <span>יום ה&#39;   </span>
      <span class='c2 italic'>
        [לפי התוכן – טבת תרע"ז, לקראת סוף דצמבר 1916]
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!  </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        לימי החנכה לא נסעתי הביתה, ולא מפני שלא התגעגעתי אלא שלא יכלתי לנסע.
        ותארי בנפשך כמה רגעים קשים עברו עלי במשך הימים האלה. מפני שלא הלכתי
        לבית-הספר עוד גברו יותר הגעגועים. פעמים רבות כמעט שלא עמדתי בנסיון וכף
        הנסיעה עוד רגע והכריע. אך אני הייתי גבורה והתגברתי על כל זה. והנה אחרי
        כל זה במקום לקבל מכתבים ארכים, מכתבים שלכל הפחות קצת ירגיעו אותי, אני
        מקבלת מכתבים קצרים, המכילים "תקון שגיעות"
      </span>
      <span class='c6 italic'>[כך במקור]</span>
      <span class='c0'>
         ועוד איזה דברים קטנים. ולו היו אלו שגיעות שאיני יודעת אותן היה הדבר
        אחר. אבל כלום איני יודעת איך לכתב להתעדד וכיוצא בו. אבל אמא חביבה, איני
        מתרעמת על זה כלל. אמנם גם בדעתי את המלה וכתבתיה בשגיעה נחוץ להעיר לי על
        זה. אבל זה לא צריך להיות כל תכן המכתב. ואם רֹשם של מכתבי כבר נטשטש ואין
        לך מה לענות לי יותר עליהם כלום, גם זה לא משמחני ביותר. זה נותן לי להבא
        ג"כ תקוה לפתקאות?? קשה לי מאד להתרגל למחשבה שכזו. ותמיד בקשת הסליחה!
        ואיני יודעת עד מתי אוכל עוד לסלח.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בודאי מסר לך אתמול חיים יפה דרישת שלום ממני, אנחנו נפגשנו ברחוב ובקשתיו
        למסר בבית שאת הכל קבלתי בשלום ובשלמות. - - - לקחה כבר את השלחן ומודה לך
        מאד עליו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ע"ד רחביה הענין הוא קצת מסבך. קודם כפי שאת יודעת הבטיחו לו להבחן בחנכה
        וקבעו את הזמן בימים הראשונים אחרי החג. והיום היה היום הראשון שהוא היה
        צריך להבחן, והנה היתה אתמול מועצה פדגוגית והחליטו שלא יתנו להם להבחן
        (הוא ועוד 7 חברים), מפני יראת הצבא. אבל לרחביה נתנו את הרשות לבקר את
        השעורים, כלומר בתור שומע חפשי. אלה הן תוצאות הערב האתמול, ושמעתי אותן
        בהיותי שם בלילה. אם יבאו עוד שנויים בדבר איני יודעת.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בחנכה הספקתי, אבל להגיד הרבה איני יכלה. בכל אפן זה תלוי באיזה קני מידה
        שאמדד, מול אחרות שלא עשו כמוני, זה הרבה. אני קוויתי ליותר, אבל שמחה גם
        בזה. בפיזיקה אני כבר השגתי את המחלקה ואתמול עניתי בשעור. הנדסה ג"כ למדתי
        הרבה, וגם שרטטתי קצת. בכל אפן אולי אשיג בקרוב את המחלקה. בנוגע לדקדוק
        כמובן שאי אפשר היה לי עוד לשים לב כל זמן שעלי להשיג את המחלקה בגאומטריה.
        בדקדוק אני הולכת ישר עם המחלקה, ולו ביחוד אקדיש זמן אחרי גמרי את
        הגאומטריה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אנחנו מסתדרים בקבוצה קטנה לקריאה בערב, מפני שכל אחת בפני עצמה לא יכלה
        לקרא. לי אין מקום מיֻחד ובערב ע"י השלחן עם כלם, ועוד בעזרת השכנים תוכלי
        לתאר בנפשך כמה זמן הולך אצלי לאבוד, בשעה שגם בביתם היה רעש קטן ע"י השלחן
        לא יכלתי עשות כלום. ורחל מצדה אין לה אור שתוכל לקרא ובלי קריאה אי אפשר
        להתקים. אסור להשען על השעורים לבדם. הלמוד בבית הספר זהו רק הדוחף, מורה
        הדרך העוזר לך להשיג את אשר תבקש. אבל אם יש לך רק את מורה הדרך ואת הדרך
        אינך מחפש אז לעולם לא תצעד קדימה. את מקום הקריאה והנושא לקריאה עדיין לא
        קבענו. עד השבת זה כבר יהיה ברור ואז אכתב לך אודות זה ביתר פרוטרות.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        אני לא יכלתי לכתב למרים והנני מבקשת את סליחתה. שלום רב להן, ולחביבה אני
        מודה לחוד על השתוקקותה הרבה לראות אותי. אשמח מאד אם תבא ליפו, ורבות הן
        השואלות עליה. זה שכתבת בדברי חביבה שהיא מאותן שיכולות להשאר תמיד ידידות
        בתם לב, הרגשתי גם את הכונה אלי. אבל חביבה היא כך מפני שהיא תמימה מאד.
        זוהי מתנת הטבע, ולא כל אדם זוכה לה. וביחס אלי את צריכה סוף סוף להצדיק
        קצת את הדין, שאינני כ"כ רעה. אני נפרדת מחברות רק אז כשאי אפשר יותר להיות
        חברה אליהן. כשאני מכירה ומרגישה שאני נסחבת ע"י ידידותן לאיזה עולם שאיננו
        לפי רוחי, לפי תכונתי, שהן משפיעות עלי מהצד הרע. וכי
      </span>
      <span class='c6'>עו<span class='c6 italic'>[ו]</span>ל</span>
      <span class='c0'>
         הוא אז להפרד?? אם נפרדתי מאסתר אני חושבת שזה אשרי. אם נפרדתי משושנה
        ברגמן לזה גרמה מלחמת "עזרא". היא היתה בין הבוגדות ואנחנו נפרדנו. וכן גם
        הלאה. ואל נא תשארי תמיד עלי באותה הדעה הרעה שאני האדם המשחת העומד למטה
        מכל בקרת. אולי יש עוד איזה מלים אחדות להמליץ עלי?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        השעה היא שעת בית הספר בבקר. אני התחלתי את המכתב עוד לאור המנורה - - -
        כעת כביתי אותה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מסרי בשמי מזל טוב לזוג הצעיר שלנו אריה ולאה.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום לכלם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>ונשיקות שולחת לכם אוהבתכם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>לעד</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='31.12.16'>
      <span class='c0'>
        יום א
        בלילה                                                                31/12/16
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אין בפי מלים להביע לך את צערי הגדול והחרטה שתקפתני על מכתבי הקודם. אני
        כתבתי אותו אז בהתרגזות רבה. איני יודעת מה גרם אז להתרגזותי אבל הייתי כ"כ
        נרגזה שבשעת כתיבתי את המכתב זלגו דמעות מעיני. כל אותו הלילה כ"כ נלחץ לבי
        עד כי בקמי בבקר השכם שפכתי את כל המרירות שהיתה עליו על גבי הניר. אבל
        רגעים אחדים אח"כ כ"כ התחרטתי. זה נעשה שלא בכונה. אבל החרטה היתה לאחר זמן
        כי המכתב כבר נמסר. בלכתי לבית הספר חשבתי אודות זה ובבית הספר עצמו לא משה
        המחשבה מלבי. עד כי לא יכלתי להתאפק ואמרתי גם לרחל, שעשיתי שגיאה וכתבתי
        הביתה מכתב ששוה אני בשבילו מלקות.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אף כי התרגזות אינו דבר שכיח אצלי מיום באי הנה, בכל זאת בימי החנכה היו
        שעות רבות, שעצבי היו כ"כ מתוחים עד כי חשבתי להשתגע. אפשר היו אלה תוצאות
        של געגועים. וע"כ אבקשך אמא חביבה למחות את הרשם שעשה עליך מכתבי. אם הוא
        בידך אבקשך להשליכו אל התנור כי אם אמצאנו באחד הימים אז ג"כ לא אסלח
        לעצמי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        וצודקת את מאד אמא יקרה שצריכה אני להודות לך על שמספיקה את לי את כל צרכי,
        בשעה שצריכה את בעצמך לשאת בע </span><span class='c6 italic'>[ו]</span><span class='c0'>ל הבית. והאם באמת איני מכירה בערך כל זה? אבל לא! בכל רגע שלומדת אני דבר
        חדש, ואֹצר ידיעותי הולך ומתרחב, זוכרת אני שבשעה זו לולא הורי הטובים כי
        עתה ישבתי בבית ולא הייתי יודעת את כל זה. והייתי גדלה באותה הריקניות
        שנמצאות בה כל בנות המושבה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ולעמת זה בשעות הבלתי חשובות ביותר, כגון: תפירה, ציור, התעמלות שהן כעין
        בטלה במובן ידוע מול שעות המדעים היותר קשים, ברגעים אלה כשיושבות אנו
        ומשוחחות, משתובבות או משחקות, אז חושבת אני: ומה עושה אמא כעת? אולי עמוסה
        היא עבודה רבה ונחוצה לה מאד עזרה. ואני כאן מתבטלת. ואבא בודאי כפוף על
        גבי הגפן עם המזמרה ביד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        מה עם מצב הכרמים ההיו שם די גשמים. פה היו ביום הששי גשמים חזקים מאד.
        נוה-צדק למשל נעשתה כלה חלולה. רחובותיה הפכו לאגמים ונהרות, ובכל החצרות
        נפתחו בורות עמוקים. גם חצר בית הספר היתה מלאה מים ואי אפשר היה לגשת אל
        המדרגות. היו צריכים לעלות אל הסימינר ומשם דרך הגשר לעבור לבית הספר (אבא
        בודאי יודע איזה גשר אנחנו דברנו אודותיו פעם). התמונה היתה נהדרה, אח"כ
        העמידו ספסלים בתוך ים זה, ועל השביל של ספסלים עברו. רב התלמידות לא באו
        באותו יום.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        בליל שישי היתה רוח חזקה מאד. כל החלונות רעדו כאלו חפצו להעקר ממק</span><span class='c6 italic'>[ו]</span><span>מם. וביחוד סערה הרוח לצד החדרים הפונים אל הים. ובמשך כל יום השבת שלאחרי
        זם היה ק
      </span>
      <span class='c6'>ֹ</span>
      <span>
        ר חזק מאד. גם הרוח לא פסקה. הים סער ויזעף, וגלי קצף גדולים התרוצצו על
        פניו. ומראה נהדר ראיתי בפעם הראשונה בימי סמוך על הים. זה היה דבר נהדר
        שאי אפשר לתארו. פתאם ראינו על הים כעין עשן. חשבנו שאלו שתי אניות כי העשן
        המדמה היה בשני מקומות. אבל אח"כ נוכחנו לדעת שאין אלו אניות. למטה על פני
        הים התאסף במקום אחד כמו גוש עבה של עשן וממנו נמשך כמו עמוד עד הענן. זה
        נמשך כחמישה רגעים וכך הלך השבט
      </span>
      <span class='c6 italic'>[?] </span>
      <span class='c0'>
        הלך וקטן, הגוש התחתון רץ, רץ על פני הים עד שנעלם כליל. זו היתה כפי הנראה
        תוצאת הסערה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        מה שכתבתי לך ע"ד רחביה וכפי שמסר לך גם נתנאל, הוא קבל רשות להיות שומע
        חפשי, אבל לא נתנה לו רשות להבחן בסוף השנה עם כל המחלקה, ובכן כל עמלו
        יעלה לריק. הרי לא דבר קטן הוא להתכונן לבחינות ולבסוף לא לקבל תעודה. וע"כ
        הוא החליט להבחן בשביל 7 מחלקות, ובתעודה זו להפסיק את הלמודים מכיון שאין
        לו ברירה אחרת. ובתעודה כזו הוא יכל אולי גם לנסע ללמד הלאה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        ע"ד הקבוצה של קריאה שלנו כתבתי לך בפעם העברה רק בקצור. הקבוצה שלנו תהיה
        רחל, אני ואחד מאותם התלמידים שגמרו את הגמנסיה במחזור הראשון. הוא צעיר
        מפותח מאד שאפשר ללמד ולקבל ממנו הרבה. הקריאה שאנו נעסוק בה תהיה מענף
        הפובליציסטיקה. אנחנו נקרא את לילנבלום ואח"כ לוינסקי. ללמוד ולהכיר את
        הזרמים הלאומיים שכ"כ זרים לנו עדיין, וצריכים להיות ברורים ביחוד לנו
        העתידות להיות מורות לילדי ישראל. ובנוגע לדעת טורוב על זה, יודעות אנו שזה
        משנים אחדות שהוא דורש מעת
      </span>
      <span class='c6 italic'>[מאת?]</span><span class='c0'> המחלקות הגבוהות לקרא את סוג הספרות הזה. אני חושבת ומקוה שזה לא יפריע לי
        כלל ללמוד, הן במובן הזמן שזה יגזל, כי זמן זה יהיה משעה 7.5-6. זמן בין
        השמשות כמעט שאין אפשרות ללמוד בו, ובתועלת הרוחנית שתתן לי הקריאה אין מה
        לפקפק כלל.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        תהיינה כל שבוע גם הרצאות של ברנר, אבל הרצאות אלה הן פרטיות. קהל השומעים
        הוא מצמצם ומרכב מכל הסוגים. בחרו יחידים מכל שדרה ושדרה. יש גם אחדות
        מהסימינר. רחל ג"כ בין המזמנות. מאד הייתי רוצה להשתתף. ההרצאות הן
        מצוינות, אבל איני יודעת באיזה אפן להגיע לשם, צריך איזו המלצה. אני אשתדל
        אולי עוד יעלה בידי, אבל תקותי רופפת מאד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        חבורים מזמן בֹאי כבר כתבתי שניים. האחד "אל תסתכל בקנקן, אלא במה שיש בו",
        והשני שמסרתי רק היום, "השפעת נדידת העמים על התרבות". זה היה שם החבור,
        אבל הנושא שלי הוא קצת אחר, כי על נדידת העמים למדו במחלקה לפני באי. ומבלי
        להיות במחלקה לא יכלתי לכתב את זה, כי בעקר דברו על זה בשעת הלמוד. וע"כ
        הנושא שלי הוא קצת אחר. בכל אפן איני יודעת איך עלה החבור. המורה לעברית
        הוא אדון ברש.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בערבים האחרונים אני כבר עובדת לבדי בלילה בתוך חדר הילדים. קודם היה אי
        אפשר לעשות זאת מפני היתושים, אבל בימים אחרונים פסקה צרה זו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>השעה כבר מאוחרה מאד ועלי להפסיק.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום ונשיקות</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>קבלו</span>
    </p>
    <p class='c3' dir='rtl'>
      <span>מאוהבת</span><span class='c6'>[כ]</span><span class='c0'>ם אפרת</span>
    </p>
    <br />
    <br />

    <p class='c1' dir='rtl' id='6.1.1917'>
      <span>מוצש"&#39;ק י"ב טבת התרע"ז   </span>
      <span class='c2 italic'>[6.1.1917]</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אמא יקרה!</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        כ"כ צפיתי למכתבך אשר קבלתי רק ביום השישי ע"י האדון וויניק! ע"ד מה שהייתי
        נרגזה באותו ערב אני חושבת שאין כדאי לחטט כ"כ הרבה מפני שאמנם איני יודעת
        בעצמי את הסבה. לא היו יסודות לזה. אלא סתם. דבר שקורה לכל אדם להיות נרגז.
        המעטים הם בחיים דברים שיכולים להרגיז. וע"כ אין המקרה הזה חשוב. וגם
        בהיותי בבית היו פעמים שהייתי נרגזת מה שקורה אצל כל אדם חי, בעל נשמה
        המשנה את גוניה לפי מקרים שונים. ותוכלי להיות בטוחה ושלוה שאני שקטה. עצבי
        גם הם נוחים ועל מקרים אין לדון ולתת להם חשיבות. אל תחשבי שרוצה אני
        להסתיר ממך דבר מה. לו הייתי נמצאת במצב הדרוש עזרה בכדי לצאת מן המבוכה
        בטח הייתי פונה לעזרתך. כותבת את "דאגה בלב איש – ישיחנה". צדק משלי. אבל
        כשאין דאגה הרי אין מה לשיח.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        הקֹר בשבוע זה היה אמנם חזק מאד. הגשמים הטורדים והלחות הרבה היו בצורה קשה
        קצת. היו ימים שבקשי אפשר היה להגיע לבית הספר. אבל בכל זאת לא החסרתי
        ובאתי גם ברגעים היותר קשים. אמנם ברגלים קצת קר ומעיל ארוך ג"כ חסר היה
        לי. אני כתבתי לך (במכתב אחד של עדה והיא לא שלחה אותו) שתשלחי לי את
        המטריה של אדון לוין בתור הגנה נגד הגשמים, הם חדלו כבר היום. אבל אני מקוה
        שהם ישובו עוד ואם את רוצה תשלחי לי אותה. אבל בכלל אני סובלת את הקור יותר
        טוב מרבים, הלבושים יותר חם. הרגלים אינן נפוחות למרות חצאי הנעלים. ואני
        משתדלת עד כמה שאפשר להמנע מלבוש חם. את המעיל הרקום (סויטר) לא לבשתי
        למרות הקור. וזה משפיע עלי לטובה. את הגרון והאף אני שוטפת בכל יום כפי
        מצותך. ומי יתן ויעבור כך כל החרף. זו תקוה יותר מדי מזהירה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        בדבר ה"trio" שלנו תוכלי ג"כ להיות בטוחה ושלוה. אנחנו קוראות עם בוגר זה
        שהד"ר טורוב בידידות רבה אתו. ואת התלמידות הכי טובות שצריך היה להכין
        לסמינר הוא נתן לו. ושבע מאד רצון ממנו. אבל לפנות כעת לד"ר טורוב בדבר זה
        לא נעים לרחל. ומכיון שזה לא כ"כ נחוץ (אחרי הטעמים שמניתי בזה) אפשר לותר
        על בקשת רשות זו. הקריאה שלנו היא מתמידה בכל ערב משעה 6 – 7 1/2  ואנחנו
        שמחות מאד. קראנו עד עתה בחדרי עדה למעלה זה היה נחמד. אבל אחרי שהיא נוסעת
        משכירים את החדרים האלה ואין אנו יודעות היכן נקרא מעתה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        אולי הייתי באיזה אופן משתדלת לדבר עם ד"ר טורוב רק מכיון שזוהי בקשתך אבל
        הוא חולה זה כמה שבועות. שכב זמן רב במטה וגם כעת הוא מבקר את בית הספר אך
        מדבר בקול כ"כ צרוד עד כי אי אפשר כמעט להבין את מה שהוא מביע. חבל מאד
        שאדם כזה כמו ד"ר טורוב צריך להיות כאלם מבלי יכולת להביע את העולה על
        רוחו. וגם אנחנו מאבדות כ"כ זמן רב שאין הוא מוסר שעוריו.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        עדה כבר ארזה היום את חפציה ומחר תבא עגלה לקחתם. עם עשהאל לא דברתי. אני
        נכנסתי אמש למסור את המפתח. הוא ישב בפנה על הספה וגם לא הרים את ראשו
        בהכנסי. כמובן שתיכף הרגשתי באיזה אפן הוא יקבלני. נגשתי אליו ואשאל מה
        נשמע בבית - - -. בבית- - - ומצד שני ענתה הג"ב וילסון: "בבית טוב". בצורה
        כזו לא יכלתי להשאר יותר ומיד הלכתי. היום אחרי הקריאה נכנסתי ורציתי
        לראותו ולדבר אתו אך לא מצאתיו. ואולי זה מזלי. לקמץ קצת כוחות.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        מה היה אצלכם איני יודעת. לפי דבריך אני מתארת שהיה דבר מה נורא. ואת הכל
        נושאים הורים. אני חטאתי חטא במכתב שלפני הקודם וכמה זמן סבלתי מזה.
        ונשבעתי עד כמה שרק יעלה בידי שלא לצער אתכם הורים יקרים. והנה יש לכם מצד
        שני. אלי, אלי.. מתי יבא הסוף לזה. שנינו יחידים ולא יכולים להשביעכם רצון.
        שתתענגו אחרי עמלכם הרב לגדלנו ולהביאנו למדרגה שעליה אנחנו עומדים כעת.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        לגב&#39; יאפו מסרתי את דבריך אבל תשובה עוד לא מסרה לי, ובכלל אני חושבת
        שיהיה קשה מאד להתחשב עמדה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        זיתים ורִבה כבר אפסו אצלי והנני שולחת לך את הצנצנות וכשתהיה לך הזדמנות
        תחזירי לי אותן. זה בודאי יהיה לך קשה. אבל מה לעשות הרי לא אוכל לעשות את
        הנס של חנכה. גם את הכוס של קמח הקודם אני שולחת רצוף בזה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        הנני שולחת לך את הלבנים (אי אפשר לי לכבס לבדי). חולצות, גרבים וכו&#39;
        אלה דברים שאני כובסת לבדי אבל לבנים צריך להרתיח והרי אין לי פחמים או
        עצים, וגם זמן אין לי בשביל לעסוק בזה. אני השארתי חליפת לבנים אשר אכבסם
        כבר לבדי בשביל שיהיה לי מה להחליף עד שתשלחי לי ע"כ לא תדאגי למהר תיכף
        לשלח את זה. אוכל לחכות עוד איזה ימים.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span>הנני שולחת 3 כתנות 3 מכנסים. חזיה 1. סדין 1. בירה </span>
      <span class='c6 italic'>[ביריה?]</span>
      <span class='c0'> 1. כתנת לילה 1. מטפחות קטנות 2.</span>
    </p>
    <p class='c1' dir='rtl'>
      <span>
        אדון בריל אני חושבת בא כל שבוע לראשון הדיה יכולה להודע וע"י תוכלי גם
        לשלח לי את אשר תרצי. אנחנו כ"כ קרוב וכמעט בכל
      </span>
      <span class='c6'>[יום]</span>
      <span class='c0'> אני עוברת שם ע"פני הבית.</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>שלום לכל דורשי שלומי</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>שלום ונשיקות לכם</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>מאוהבתכם לעד</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <br />
    <br />

    <p class='c1' dir='rtl' id='11.1.1917'>
      <span>יום חמישי י"ז טבת התרע"ז </span>
      <span class='c2 italic'> [11.1.1917]</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אמא חביבה!</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        כן! זה שבוע ימים שלא קבלתי ולא שלחתי לך מכתב. אמנם קשה לי מאד להתרגל עם
        הרעיון הזה אבל מה לעשות וכך הדבר. הסתבכו הענינים באפן בלתי רצוי. אני
        רציתי לשלח לך מכתב ע"י עדה והנה באתי אליה רגעים אחדים אחרי לכתה לנסיעה.
        וע"י האדון יאפו אי אפשר לי למסר מכתבים מפני שהוא היה מקבל אותם בעקימת
        פנים כזו אשר מוטב לי לסבל מאי כתיבה מאשר מאי נעימות כזו.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        אבל מה שתיקתך אמא יקרה אומרת? האם נתן לי מקום לחשש פן אין שלום לכם? או
        רק מפני שלא היתה לך הזדמנות לשלח לי מכתב או גם זמן לכתבו לא היה מה שיכֹל
        לקרֹה אצלך. ביום שנשלחו חפצי עדה הייתי פעמים אחדות בביתם. אבל מכל מה
        שקרה לא הזכרנו שמץ דבר. השתדלתי לעשות עצמי כלא יודעת כלום. מה שעשהאל חשב
        איני יודעת. ורק אחרי נסיעת עדה ספרה לי הג"ב ווילסון שמץ דברים. כמובן
        מפיה לא יכלתי לשפט על הנעשה. בכל אפן תאמיני לי שמאד דאב לבי באותו יום,
        והרבה בכיתי באותו ערב, בשבתי בחדרי (יש לי כעין חדר כעת מפני שהם העתיקו
        את חדר-המטות שלהם). ובחדר היה חשך, ודומיה עצובה שררה בו. ואני נשקעתי
        כֻלי בידי מלאך הדמיון. לפני עברה תמונת הבית, איך נשארתם בו רק שניכם (חוץ
        מיונדב) באין ילדיכם על ידכם. ובערב חצי עצב שורר בבית. יושבים מעטים כ"כ
        סביב השלחן הגדול. המנורה הקטנה מאירה חלק קטן מהחדר ובכל הפנות אפל.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        על הספה שוכב אבא לנוח מעמל היום, או יושב ע"י השלחן ומעין בעתון. ובמטבח
        מטפלת את יחידה והרהורים רבים מתרוצצים בדמיונך. ובלב כ"כ הרבה צער וכאב.
        יש מה שילחץ וידאיב אותו.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        ובאותה שעה נמצאות רק במרחק איזה מאות מתרים עוד שלש נפשות הקרובות
        והרחוקות כ"כ. והאם הן מאשרות? לא. חסר גם שם גרעין האשר. רק קטנטנה אחת
        נמצאת שם שאינה מרגישה עדיין כלום. מלאך התמימות מרחף עליה ומגן עליה
        בכנפיו הצחות הלבנות. אבל גם לו יש גבול. ומאותו הרגע הוא מתחיל להתרֹמם
        למעלה, למעלה עד העלמו כליל. ואת מקומו לוקחים זרים. ומי יודע מה טבם של
        אלה? בכל אפן אליל האֹשר איננו ביניהם. הוא עדיין לא נברא. כפי הנראה ששכח
        בו הקב"ה. נתן לנו רק את שמו אך לא את מציאותו.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span>
        תואילי אמא לכתב לי את כל היחסים שביניכם. גֹלי לפני את יסוריך כמו שהיית
        עושה כעת לפני שנה. באותם ערבי החרף בהיותנו יושבות יחד שעות רבות, והיית
        הכל מספרת ל    Groses Kleines madchen
      </span>
      <span class='c6 italic'>[יידיש, הילדה הקטנה הגדולה] </span>
      <span>
        שלך. וכעת אין ביכולתנו רק להחליף מלים אחדות פעם אחת בשבוע. האם אין זה
        מעציב?
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        אפשרות ללמד באחד החדרים בערב אין לי. מפני שאדון יאפו ירא מאד "ממה שיאמרו
        הבריות", איננו חפץ שידברו על האור הרב שבביתו. ואלה דבריו: בשעה שרעבים
        סביבך אל תהיה יותר מדי שבע. אני נסיתי בכ"ז לא להתחשב בזה אך הוא דרש זאת
        ואני הייתי מכרחה להכנע. מה שמכריח אותי לגמר את שעורי ביום. ואחרי הקריאה
        שלנו כשאני באה הביתה, לפעמים די שקט עד כדי כך שאפשר לעבוד. ולפעמים אי
        אפשר. וצריך הכל לקבל באהבה. לו זה היה צריך להיות רק בחוג המשפחה לא היה
        אסון גדול כ"כ אבל במרפסת אחת עם השכנים. תוכלי לתאר בנפשך עד כמה זה נעים.
        השקט פורש פה את כנפיו רק בשעה מאחרה. ואז הרי כבר שוב לא נותנים לי לשבת,
        מפני אחור הזמן.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        תשתדלי חביבה לשלח לי את אשר בקשתי בפעם העברה. גם קמח כבר יהיה נחוץ לי
        בשבוע הבא. אך אבקשך לרדד אותו בבית בכדי שלא תהיה לי עבודה יתרה זו של
        רדוד.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        השליחות תהיה לך אמנם קשה, אבל אני חושבת שתוכלי אולי לסדר ע"י רבקה
        הורוויץ. אנחנו גרים בשכנות לג"ב לוויט. היא יכולה לקחת איזה נושא סבל קטן
        מהעיר הנה. ופה תוכל להשאיר את החבילה בבית הג"ב לוויט ואני כבר אקחנה.
        תלמידה אחת מבית הספר גרה אצלה והיא תמסר לי אם יש בשבילי דבר מה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span>אני כותבת לך את מכתבי זה עתה בלי</span><span class='c6 italic'>[ל]</span><span class='c0'> חמישי. אבל איני יודעת אם תהיה לי הזדמנות לשלח לך מחר את המכתב.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        את הפתקא הרצופה בזה תואילו למסר לחביבה. היא נשלחה לה מאחת החברות, בצרוף
        עם דרישת שלום חמה ממני, לה ולכל הדורשים בשלומי
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>המנשקת אותך מרחוק</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>בתך אוהבתך.</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>ת"א</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        האדון אדלר שאלני בדבר חתימת השם בספור שלך. ואני באמת כמעט לא ידעתי מה
        לענות. בתשובה שאת מסרת לי איננו מסתפק. והוא אומר שבשביל הילדים מוכרחים
        את השם ממש. ולא כל פסודונים יהיה איזה שיהיה. תואילי לענות לי על זה
        במכתבך. הג"ב יאפו אומרת שאת תעשי את החשבון מה שמגיע לה היא אינה יודעת.
        אני חושבת שזה יהיה קצת אי נעים. איני יודעת איך לצאת ממיצר זה.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>יום ראשון</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אמא חביבה!</span>
    </p>
    <p class='c1' dir='rtl'>
      <span>
        את המכתב כתבתי לך ביום החמישי (כפי שכתבתי כבר) אך לא היתה לי הזדמנות
        לשלחו. אני דאגתי מאד לכם יקירי. וביום הששי בערב הייתי פה אצל לאה (ברגע
        זה כותבת אני ג"כ אצלה) ודברתי ע"ד זה שאיני יודעת מה לחשֹב עליכם. והנה
        בשובי הביתה מצאתי את מכת</span><span class='c6 italic'>[ב]</span><span class='c0'>ך ששמחני מאד בראותי אותו. אך הצטערתי על שכותבת את "בצרוף חבילה" וחבילה
        לא קבלתי. אני לא הייתי בבית כשהביאו את המכתב וע"כ לא ידעתי גם מי הביאו.
        כל ימות השבת חכיתי אולי יביאו את החבילה, ואין. והנה כעת בלכתי מבית הספר
        לאה קראה לי ומצאתי את שתי החבילות.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>
        אני ממהרת מאד לכתֹב מפני שהשעה היא כבר שתים, צהרים עוד לא אכלתי ובשלש יש
        לנו טיול טבע. כלומר במקום השעור שצריך להיות במחלקה נלמד מהצמחים שנמצא
        בשדה. אתמול ג"כ טילתי לשרונה.  יחד עם נעמי, לדיג&#39;נסקי, כל מורי
        הסמינר ותלמידות המחלקה העליונה והיה נעים מאד.
      </span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>שלום ונשיקות לכלכם</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>מאוהבתכם תמיד</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <p class='c1' dir='rtl'>
      <span class='c0'>ת"א שלום לעדה לעשהאל, וליונה נשיקה.</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='alefShvatTaraz'>
      <span>יום ד&#39; 24/1/17 </span>
      <span class='c2 italic'>[א שבט תרע"ז]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!  </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        כמה שמחתי תמול בערב בבאי הביתה, זה היה כבר מאחר בערב והנה על השלחן מונח
        מכתב. מכתב מאמא יקירה. כמה זה משקיט, מענג, מפזר על פני הנפש אבקת זהב.
        וכשהוא רצוף במלים כה - - - חמות, אשר העין מאליה מוכרחה להזיל דמעות,
        דמעות גיל.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        שמחה את ומתגאה בבתך וכל זה צריכה את להודות אך ורק לעצמך ולא לאיש זולתך.
        לולא אותן שעות אחדות שלא ישנת באותו בקר של חוה"ס
      </span>
      <span class='c6 italic'> [?]</span>
      <span>
         ובאת לידי אותה החלטה שעלי לנסע לסימינר, כי אז הייתי שם בראשון, באותה
        הסביבה הרקובה. הייתי נשקעת בה יותר ויותר, והכל בי היה נדם ונרדם, כמו
        שהיה במשך שתי השנים. אני מעולם לא יכלתי כלום להביע גם אם התרחש הרבה. וכל
        מה שהתרחש היה כלוא, נחנק וסוף סוף גוע. את היית מיסרת אותי על שאיני
        כותבת, שאני אשכח את הכל, וכל עמלך שעמלת יהיה בתהו. אך את לא ידעת שאני
        סובלת, סובלת משני פנים.  הן מצד המצפון שלי והן מהצער שגורם לך הדבר. אבל,
        לא נכון אם א
      </span>
      <span class='c6'>ֹ</span>
      <span>
        מר שאת לא ידעת. את בודאי הרגשת את הכל, את הכל והראיה ששלחת אותי סוף סוף
        למרות הקשי שבדבר. ואמנם צודקת את שההטבה באה מצד הלמוד. אני מרגישה זאת
        בעצמי. איזה אֹשר כשעסוקים כל היום. לא בהבלים סתם אלא בדברים העומדים ברמו
        של עולם. הנה למשל יום האתמול: השעה הראשונה היתה גיאוגרפיה מטמטית, אחריה
        אלגברה, פסיכולוגיה, משנה. אחר הצהרים היה עלי לכתב חבור. אח"כ הלכנו ללמד
        דקדוק, ואחר קריאת לילנבלום, גמירת החבור והעתקתו. אני שכבתי היתה שעה
        מאחרה כבר לא כרגיל. המח היה עיף, כבד. אבל הר
      </span>
      <span class='c6'>ֹ</span>
      <span class='c0'>
        חב שבנשמה... כן חביבה! את יכולה להרגיש זאת, אני יודעת שירשתי ממך תכונה
        זו, ומה נפלא הדבר. אם קורה רגע שאני מתבטלת מפני איזו סבה שלא הסתדר דבר
        מה - - - אז בשבילי כל העולם נהרס. איזה אֹשר הוא זה להרגיש שאין הורגים אף
        רגע אחד, שמפיקים מן החיים את התועלת הרצויה. השבוע היה באמת שבוע נחמד של
        עבודה פוריה. היו עלי שני חבורים לכתב עברי וצרפתי, וגם התחלנו ללמד
        פסיכולוגיה, מה שצריך להכין עפ"י ג&#39;מס, להרצות במחלקה את הפרק שלומדים
        בבית. עבודה מענינת מאד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        החבור העברי שנתן לנו היה חפשי במדה ידועה, ז"א לכתב עפ"י מה שקראו. זמן רב
        פקפקתי, לא ידעתי במה לבחר, עד שכתבתי פרק אחד מ l&#39;homme qui rit
      </span>
      <span class='c6 italic'>[האדם הצוחק, ויקטור הוגו]</span>
      <span class='c0'>
        , אותו המקום שעוזבים את הילד על הסלע בלב הים הסוער והוא מתחיל לתעות
        ולחפש לו את דרכו בחייו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ע"ד מה שכתבתי לך בנוגע לברש איני נוהגת חס ושלום קלות ראש בשעוריו. ואם
        איני מצליחה לעת עתה בחבורים אצלו אקוה להגיע סוף סוף לאותה נקודה שאותה
        הוא דורש. אם גם לא תמיד אפשר להכנע במובן זה. בכל אפן אין לי עדיין מה
        להתיאש כ"כ מפני שגם כאלה אשר מקבלות כעת ציונים טובים לא נמנעו מקבל גם
        מספיקים, והרי אני לא זכיתי עוד לציון "מספיק".
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>מתגעגים </span>
      <span class='c6 italic'>[כך במקור]</span>
      <span class='c0'>
         אתם אלי חביבי, וחושבים שאני רוצה להרחיק חוק. אך לא, אל תוציאו עלי משפט
        כ"כ קשה. גם לבי הקטן מפרפר לזכרכם, נמשך  - - -, אבל מה אפשר לי לעשות?
        הרי "אין זמן". לבא לאחת השבתות ז"א להתבטל כשני ימים, והן אסור להתפזר
        בדבר שעניים בו. הזמן יותר מדי יקר ואוכל להרשות לי תענוג זה רק באחד החגים
        הממשמשים ובאים. עברו אותם הימים שהייתי באה כל חדש הביתה. כך! היה גם כעת
        חשק לזאת! בכל זאת, אל תתיאשו. אני מקוה וע"כ קוו גם אתם. אשרי המקוה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        מוזרה מאד אהיה בעיניך אמא. במכתב אחד מתבטא צער עמוק, פסימיות נפרזה ובשני
        אופטימיות, תקוה. כך! אלה הם החיים אשר אורות וצללים משמשים בהם בערבוביה.
        אין אור בלי צל וצל בלי אור. אין תקוה שאינה מביאה יאוש בעקביה, ויאוש
        הנרדף בתקוה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        היום באה שלומית. אולי תוכל הפעם לבלות פה זמן יותר רב. מסכנה זו אין לה
        רשות לנוח. עוד רק באה והנה היתה צריכה לשוב לעבודה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>יום ה&#39;</span>
    </p>
    <p class='c3' dir='rtl'>
      <span>אתמול הייתי פה אצל לווה </span>
      <span class='c6 italic'>[?] </span>
      <span class='c0'>
        לשאל לעגלה מפני שפֵּסל רצתה לנסע. והנה אמרו שאין מקום ואולי היום יהיה.
        ולדאבוננו היה דוקא אתמול מקום רב, והיום אין.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אני ממהרת מאד לגמר</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>נשיקות חמות משלכם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אם תהיה לך הזדמנות תואילי נא לשלח לי נפט. נחוצה לי גם חתיכת בד לבן
        לתיקונים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנ"ל</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנני שולחת הביתה</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מכנסים 4</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>כתנות 2</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מגבות 2</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>חזיה 1</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>פסל שולחת גם מעיל אשר קבלה בשביל אחת מבנות אלה.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנ"ל</span>
    </p>
    <br />

    <p class='c3' dir='rtl' id='yomBetNoDate'>
      <span>יום ב&#39;  </span>
      <span class='c2 italic'>[אין תאריך]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        את מכתבי ששלחתי אתמול ע"י, איני יודעת בעצמי מי, מפני שמסרתי אותו לידי
        לאה, בטח קבלת. אני מודה לך על החבילה. באמת כבר היו נחוצים לי מאד כל
        הדברים. רבה וזיתים לא היו לי כבר מאיזה ימים. רק דבר אחד חשבתי שלו שלחת
        היה טוב מאד, לחם. אני כבר מתגעגעת ללחם של הבית וע"י הזדמנות זו לו שלחת
        לי לחם היה באמת נחמד כי גם חסר לי הבקר. במקום עוגות שמבקשים אחרים, אני
        מבקשת רק לחם שיהיה במקום עוגות. בכל אפן אני צוחקת קצת. בשעה שאני אופה
        בעצמי ויש לי לחם, למה לי עוד? אבל הלא את הטעם כבר מניתי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בדבר כסף אני עוד איני כותבת לך כלום. יש עוד בידי מעט כסף אבל עלי לשלם
        מזה לגב&#39; יאפו. אני אמרתי לה שוב מה שאת כותבת והיא באחת, שגם את בעלת
        בית ויודעת חשבון כמוה. אבקשך מאד לכתב לה, או באיזה אפן שהוא להתחשב. את
        חשבון החלב והבשר יש לי ואת הענינים האחרים תחשבי כבר בעצמך.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אני הייתי אתמול אצל הגב&#39; ווילסון, שאלתי אם יש איזה ידיעות מעדה. והיא
        ספרה לי שמסרו לה (איני יודעת מי) שהם היו אצלכם לשבת. ה<span class='c0 italic'>[א]</span>ם נכון הדבר. זה
        משמחני מאד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        הנני שולחת לך בזה את נעלי שנקרעו זה לפני ימים אחדים, ואני נועלת את
        השחורות שג"כ קצת קרועות. אני לא רוצה לתקן אותן פה מפני שזה בודאי יעלה
        ביקר. אם ברצונך אמא תקני לי בד שחור לסנור. זה כ"כ נחוץ לי שאין לך לשער.
        הכל מתלכלך באפן מבהיל. אני אקלקל את כל בגדי. הייתי קונה פה אבל דרוש כסף
        רב לזה. ושם אולי תוכלי לקנות בהקפה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        הספירט כבר אפס אצלי ואיני יודעת מה לעשות. הייתי אולי שואלת אצלם אבל
        הספירט שלהם לא טוב ובוער רע מאד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        גם גפרורים כבר אפסו אצלי. אני לקחתי מהבית רק חפיסה אחת.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>תסלחי אמא חביבה על הטרדות שמטרידה אני אותך.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנני ממהרת לכתב כי העגלה היא ע"י הבית.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום ונשיקות לכלם,</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אוהבתכם נצח,</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='yomGimelJan1917'>
      <span>יום ג&#39;  </span>
      <span class='c6 italic'>[לפי התוכן, שבט תרע"ג, סוף ינואר 1917]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        במכתבך כותבת את: ששני המכתבים האחרונים שלי מלאים רגשות של אֹשר של נֹער.
        מי הוא המלאך הטוב שהביא אותן ללבי? למי עליך להודות?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        להודות צריכה את למלאך הנקרא בשם עבודה, למוד. לו צריכה את להודות. זה מכבר
        ידעתי שתרופה אחת יש בשבילי והיא הלמוד. כשאני לומדת ועסוקה כל היום, כל
        הערב, וחיי מלאים תֹכן, אז טוב לי. גם בהיותי בבית אם היו ימים מלאים, ז"א
        שלא עברו באותו יום אף רגעי בטלה אחדים אז היתי מרגישה שלוה נפשית עמוקה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ואמנם מוזר הדבר. וכי מה הם החיים? לא פעם כמובן מתיצבת לפני השאלה ולא
        השאלה לשמה, שאלה לשם שאלה סתם, אלא בתור פרובלם המעסיקה את המֹח במשך זמן
        רב. לחפש את פתרון שאלת החיים. וחושבת אני: וכי מה לו לאדם אם טובים יהיו
        חייו או רעים. אם פוריים או בלתי פוריים. מענינים או משעממים וכו&#39;
        וכו&#39;. האם לא מובילות כל הדרכים אל אותה המטרה? השמה שויון בין כלם?
        וכי למה לנו כל ההתאמצות הזאת. המלחמה הקשה שנלחמים אנו זהו אנסטינקט
        הקיום: נאמר. אך הרי גם לחיות יש אנסטינקט. אצלם רק אינסטינקט וע"כ אין
        פועל אצלם מבלעדיו אף כח אחר. בממשלת הרוח אין אצלם רצון, בחילה, ספוק, אי
        ספוק, שנאה, אהבה וכו&#39;. עוד ועוד מדות רבות אשר הגיעו אצלנו למדרגה
        גבוהה עפ"י תורת ההשתלשלות. אבל האם נוכל להגיד היכן הוא הטוב? אם בחיי
        החיה או בחיינו המשכללים? שוב שאלות כאלה כפי שהזכרת עליהם באחד ממכתביך,
        הנשאלים מבלי לקוות לקבל עליהם תשובה. אבל זוהי עֻבדה ראשונה וכללית,
        שהחיים נתנו ביד האדם העולה על יתר החיות, בשביל שהוא ישתמש בהם, ילמד
        אותם, יחקֹר אותם, וישתדל ליפותם. זוהי מטרה שצריך כל אדם לשאף אליה היא
        טבועה בדמו ומבלי משים פועלת בו בלי הכרתו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        עשיתם לשבת נקיון גנרלי בתקוה שאני אבא ואהנה מכל זאת, מודה אני לכם על
        הכונה הטובה. אך אני הייתי הילדה הרעה ולא עשיתי לכם את התענוג לבא ולשמח
        אתכם. בכל זאת לא אבדה תקותי להתראות בקרוב.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>                               ------------------</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        הנני לשוב עוד פעם אל נושא שחתנו בפעם העברה – שילוק. דעתך היא שלא עלה
        בידי שקספיר לתאר לנו את המכֹער שביהודי למרות מה שהתכון לזה, והבליט דוקא
        את המכוער והגרוע שבנוצרי. לפי דעתי את צודקת במקצת אך לא לגמרי. שילוק זה
        היהודי הנרדף כל ימי חייו. דורכים עליו ברגלים, מחרפים ומגדפים אותו לא
        רוצה לקבל סכום כסף הגדול פי שלש וארבע מזה שחיב לו הנוצרי ורוצה רק לטרה
        אחת של בשר. להשתיק את רגש הנקמה השואף לדם. אך האם נכון הדבר? גם לא
        שקספיר סופר זר אלא גם טשרניחובסקי שלנו מתאר את הנקמה ב"ברוך ממגנצה".
        כמובן שאין להשוות בין שני היהודים: ברוך ושילוק, אך הצד השוה בהם זוהי
        הנקמה. ואני חושבת ששניהם גם יחד שגו. אותו היהודי אשר נפשו נפש עבד נכנע
        הרגילה כל ימיה אך ללעג וגדופים לא יכולה כבר להתעלות מאותה הבצה, להתרומם
        לפתע ולקרא: נקמה, נקמה לי בכל אויבי. לזה יכֹל לשאֹף נוצרי, בן עם בריא,
        על ארצו הקיָם לא ברצון וחסות אחרים. עם שלאנשיו יש רגש כבוד בריא שלא נרמס
        ונרדף ודורש את עלבונו ומחרפיו. אך לא היהודי. הוא בשום אופן לא יוכל עשות
        זאת. הוא יכול רק להתפלל שאלהים ינקֹם באֹיביו. הוא מבקש נקמה, אך  ע"י כֹח
        נאצל אלוהי הנמצא מחוץ לגבול האפשרות הארצית. ומעולם לא עמדה השאלה לפניו
        ללכת ולנקום בפֹעל. בידיו הוא. מפני שזה היה דבר אי אפשרי, היה, והוא גם
        כעת כך. כל זמן שהעם מפֻזר ומפֹרד. כי אם גם יתעורר אצלו רגש נקמה כמו אצל
        שילוק זה המסכן, מוכרח יהיה רגש זה להחנק. וכמה מגֻחכה היא נקמת שילוק.
        מתחילה יפה היא, נקמה בריאה, עומד הוא על דעתו. אך לאט לאט נחלש הרושם,
        ולבסוף כשהדבר מגיע אל כספו, אשר לפי הדין יצטרך להלקח ממנו בשביל שרצה
        לרצֹח נפש ומעמידים לפניו את הבררה להתנצר, הוא מקבל. כלום זהו אדם, זהו
        אותו יהודי הקדוש אשר מת על קדושת השם? אך מה לדבר הרבה. והן מעיקרא פריק.
        שקספיר לא הכיר את היהודי ולא הצליח בתאורו. אחת היא, אם מן הצד החיובי או
        השלילי. צריך לדעת את השלילה בשביל לתארה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>כאן מתחיל פרק התשובות:</span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        שואלת את מהו המקצוע שמלמד אותנו אדון צפרוני חוץ משנה. לדאבוני עלי
        להודיעך שאין אנו לומדים אתו כלום. גם לא משנה. הוא מורה רק ממחלקות
      </span>
      <span class='c9'>III IV</span>
      <span class='c0'>
        . כמה אני אוהבת את המורה הזה. זהו אדם עמוק, מענין. איזה שעורים חביבים
        היו עלי שעות המשנה אצלו. וכעת, כעת מלמד משנה אדון ברש. אדם שכ"כ רחוק
        מהמקצוע.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        המורה לטבע הוא מר פלדמן. אני מסֻפקה אם מכירה את אותו. הוא מלמד דוקא
        היטב. כל פעם יש בחינה בטבע. מה שמכריח לחזר פעמים רבות, והידיעה נעשית
        ברורה יותר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בהנדסה הייתי צריכה כבר להשיג את המחלקה אך לא למדתי זה זמן רב. לא היה
        ללדיג&#39;נסקי זמן. ואיני יודעת באמת אם לא יהיה גם להבא זמן אז אצטרך
        לחפש איזה אופן אחר לרכישת החסר לי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>נֹעמי אולי תסע בימים אלה. לפני או אחרי השבת.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        לאדון אדלר מסרתי מכבר את תשובתך וגם אז הוא אמר שלא חכה כבר לתשובה ועשה
        זאת על דעת עצמו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        הד"ר טורוב מבקר מכבר את בית הספר. ומתפלאה אני שאת חושבת אחרת וכי כך
        הבינות מדברי? אני רק כתבתי שקשה לו ללמד מפני שקולו צרוד. וע"כ אנחנו
        קוראים את ג&#39;מס במחלקה את כל הקשה הוא מבאר. ואח"כ אנחנו צריכים להרצות
        את תֹכן הפרק בשעה השניה וכו&#39;.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אשמח מאד אם הג"ב פופל תשלח את בתה לבית-הספר לבנות. אני אמנם הרבה התוכחתי
        אתה ע"ד בית-ספר ואולי זוהי השפעת דברי. ומוכנה אני בכל רגע לעזר לה כפי
        האפשר בהכנת ילדתה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        איני יודעת באיזה אֹפן זכיתי לחבה זו הידועה לי משתי הנשים האלה, ג"ב פופל
        ואחותה, הגרה כאן בשכנותנו. האשה הזו כ"כ נעימה וחכמה, ותמיד בראותה אותי
        מרחף שחוק נעים על שפתיה. שואלת לשלומי ובכלל מתע<span class='c0 italic'>[נ]<span>ינת בי הרבה. ויש אשר
        אנחנו מדברות גם זמן רב. כשהיא כבר מתגעגעת אחרי. פעם בקשתני להתחבר אל
        ילדה קרובה אחת שלהם המתאכסנת אצלם ולומדת במחלקה
      </span>
      <span class='c9'> VI</span>
      <span class='c0'>
         בגמנסיה. היא רצתה מאד שאתחבר אליה. אך אני לא עשיתי זאת, מפני שאי אפשר
        ליצור ידידות מלאכותית. אבל בכלל כמה שאשה זו אוהבת אותי זה להפליא. ומקוה
        אני לענות לה במכתב אחד עמדה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מחר אולי אוסיף מלים אחדות.</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='motzshak'>
      <span class='c0'>מוצש"ק  </span>
      <span class='c0 italic'>[אין תאריך]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        כן! בודאי חשבת בלבך כל יום החמישי: "הוי איזו ילדה רעה היא זו". כמה צער
        גרמתי לך באותו בקר, אשר בודאי לא שכחת אותו עדיין. ראית התכזבות של
        תקוותיך לראותי בריאה בגוף וברוח. והנה חבור עצבים שאין בו מתום. לשוא
        תחשבי כדברים האלה. היה יסוד להתרגזותי אם גם הפרזתי קצת על המדה. שני ימים
        בליתי בבית. אמנם באתי לנוח לבלות את החג הקטן, אבל הן עבודה רבה היתה לי
        לעשות וכלום לא פעלתי. את הסבה איני יודעת. מוצאים מה לעשות, לאן ללכת, על
        מה לדבר וכו&#39; וכו&#39;. והזמן חולף וצובר את הבטלה בכנפיו. והן יודעת
        את כמה סובלת אני מרגש אשר כזה. והנה כבר יום חמישי בבקר, ואין לי במה
        לנסע, ז"א להשאר עוד 3 ימים מבלי לעשות כלום. ואח"כ להתרגז שבוע ימים על
        שלא יכלים להסתדר בעבודה שהצטברה. הנה מרגע שבאתי אני כל הזמן לומדת. זהו
        לגמרי עולם אחר. שם אי אפשר ללמֹד. מסביב הכל ירוק, פורח, מושך את הלב
        מרחיק מהמחברת ומהספר. היצר הרע דוחף אותך לחוץ, לטיל בגן, ברחוב המוקף
        משני עבריו בגדרות ירוקים, ואיך אפשר ללמד?? יודעת אני את זה גם קודם! לכן
        בשני ימים אלה התיחסתי אל זה בשלות נפש. אבל יותר לא יכלתי להרשות לעצמי,
        ולמחשבה זו חרדתי. ואולי לא הייתי מתרגזת כ"כ לולא זה שנחלשתי באותו לילה
        כ"כ ע"י הקיבה שבבקר קויתי כבר לשכב כל היום מבלי יכלת לקום. וע"כ אל יפלא
        בעיניך אם הייתי כה נרגזה בראותי שאין עזר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בכל אפן איך שהיה הדבר, אבל אני נסעתי. הדיה בודאי ספרה לך שעליתי על
        העגלה. מקום לא היה כליל ובכל זאת ישבתי, איני יודעת עד עתה איך ואיפא.
        במחשבתי היה להשיג את העגלה של קפלון ולעלות עליה אך המוכתר שלנו לא נתן לי
        לעשות זאת, מפני שהרגיש שהוא אשם בדבר. ויתנצל באמרו שחשב שאבא בעצמו צריך
        לנסע ליפו וע"כ חשב שיכולה נסיעה זו להדחות, אבל לו ידע שאני היא הנוסעת
        כ"א היה רותם את הכרכרה. מה תאמרי על אדיבות אשר כזו?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        הגענו ליפו בשלום. שם חכיתי קצת במושבה הגרמנית לגב&#39; ברטה שנכנסה לרופא
        הגרמני, והעגלה חכתה לה. אח"כ נסענו לתל-אביב ירדנו ע"י המלון של סלנט ונער
        סבל הביא לי את חפצי הביתה. זהו ספור המעשה של הנסיעה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        כמובן שלא כ"כ נעימה היתה הישיבה בתוך העגלה. הרגל עוד נפוחה לי קצת עד
        עתה. אבל אין דבר. זהו סבל שאפשר לעבר עליו בשתיקה, ואמנם טוב מאד עשיתי
        שמהרתי ככה לבא ביום החמישי, מפני שספרו לי שד"ר טורוב כעס על שלא הייתי
        ביום השלישי ומה גם לולא באתי עד יום ראשון. אני הלא לא בקשתי רשות לנסיעה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בחבילה שלי היתה קטסטרופה קטנה. באמת לא היה לי מקודם חשק רב לשים את
        התיבות יחד עם הבגדים. התיבה של הזיתים נזלה. היה שם מיץ קצת אשר נזל על כל
        הדברים. הסדין כלו עם כתמים גם החלצה וכו&#39;. את החבילה שלי בודאי גמרת
        כבר ותשלחיה על ידי איזו הזדמנות. וגם 7 נירות לביה"ס.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        המצב ביפו הוא רע מאד. הרוחות סוערים. המורים כ"כ מיואשים. יושבים בעת
        השעור והמח והלב נמצאים מי יודע היכן. כל מורה ומורה מזכיר ברמז את הרגע
        הנורא.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אדון מרשק שצריך היה לבחן בהסטוריה ישראלית לא בחן, באמרו: מי יודע מה ילד
        יום, מוטב שנספיק כמה שרק אפשר. ע"כ נצעד קדימה. אדון קריצ&#39;בסקי אומר:
        מי יודע אם לא בפעם האחרונה יושבים אנו יחד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אדון אייזנשטיין אומר: אם יחפשוני אני אברח, ועוד ועוד. אדון
        לדיז&#39;ינסקי איננו. הבית סגור ומסגר, מה שעושה רושם כ"כ מדכא. מה יהיה
        הסוף מכל זה לא רוצה איש לחשב. מה יביא בכנפיו יום המחרת לא חוקר אף אחד
        חיים בתוך קלחת. הבלילה חזקה ומתוכה קשה להבחין את הנעשה בפנים. ולעת עתה
        חיים. אוכלים ושותים, הולכים לבית-הספר, לומדים, מכינים שעורים, קוראים.
        לרגעים בוכים, לרגעים צוחקים. הכל מתערב יחד. שכרון החיים צורר אותך, אינך
        יכל לזרם נגד הזרם. נסחפים יחד עמו, יורדים אט אט ולפעמים נופלים בבת אחד
        עם המפל וכן חוזר הגלגל. כאן בוכים, כאן צוחקים, משחקים, עורכים נשפים. אור
        וצל מרקדים יחד. אין אורות בלי צללים ואין צללים מבלי אורות בצדן. יש
        שמתרבים הצללים, מתרבים האורות. היחס הכמותי שלהם משתנה אבל מציאותם זה בצד
        זה קימת לעד. חֹק עולם לא יעבר. אנחנו עושים לא ע"פ רצוננו אלא את אשר מצוה
        עלינו הטבע. החיים דורשים לחיות, המות למות, היסורים לסבל והשמחה לעלז. כל
        כח וכח דורש על קיומו הוא ואינו רוצה לכלות על חשבון השני. אינו רוצה לותר
        על חלקו שלו. והאדם הוא הכבשה השסועה המקריבה לכל העגלים את קרבנותיה – בלי
        מחשבה, בלי כונה אלא כמו אותה מכונה שמסתובבים בה הגלגלים, בלא ידיעה ורצון
        עצמי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>יום ראשון</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        היום היו לנו שעות חפשיות. א&#39; לדיז&#39;ינסקי עוד לא בא, ואדון
        קריצ&#39;בסקי גם לא בא. וע"כ הנה זה באתי מקדם מבית הספר, ומוסיפה לך את
        מלותי האחדות האלה לפני לכתי לגימנסיה למסור את המכתב לידי האדון וויניק.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        בעיר נפוצה השמועה שדחו את הקריאה לצבא לזמן של 3 חדשים. אם נכון הדבר יהיה
        טוב מאד. הכל ישוב לעת עתה למקומו.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        המצב של אלה שרצו לנסע הוא נורא. כבר התכוננו משפחות רבות לנסע מחר וכעת
        אומרים שלא יסעו או מפני שלא תבא האניה, או שלא יתנו לנסע איני יודעת בדיוק
        לא הספקתי לחקר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אני נגשת כעת גם אל בית ווילסון, אולי באה הגב&#39; והביאה לי מה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום ונשיקות לכם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מאוהבתכם תמיד</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אני הולכת אל ווילסון והנה בדרך פגשתי בילדות המובילות לי את החבילה. תודה
        רבה לך.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנ"ל</span>
    </p>
    <br />
    <br />

    <p class='c3' dir='rtl' id='niceMom2NoDate'>
      <span>אמא חביבה!  </span>
      <span class='c2 italic'> [ללא תאריך]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        זה כמה ימים שלא כתבתי לך כלום. אמנם חטא הוא מצדי. ראשית מפני שבכלל זהו
        דבר שלא יעשה, ושנית בדעתי כמה עֹנג מסבות לך שורות אחדות יפות.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        השבוע היה שבוע כ"כ קשה פה עד כי לא יכלתי לכתוב למרות ההזדמניות הרבות.
        מסרתי גם השבוע חבילה לעגלת א&#39; מאירוביץ מג"ב ווילסון לעדה בודאי קבל
        אותה? האינך יודעת זאת?
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        את מכתבך ע"י אדון וויניק קבלתי. חושבת את שבודאי מתרעמת אני על הנזיפה
        שלך. לשוא תחשבי כזאת. אין לי פה מה לכעֹס  אלא אך ורק להצטער. האמיני לי
        כי גם אני הייתי רוצה בכל לבי להיות "שלמה", להיות "אדם" כמו ג"ב לווין
        למשל. אדם חזק שאיננו מתפעל מפגעי החיים ומקבלם בנפש שקטה ושלוה. נשמה
        גרמנית. זוהי כמדומני המלה הכי אחרונה ושיכולה להביע את הרעיון לכל עמקו
        ורחבו. אבל היכולים אנו להדמות אל הגרמנים. היום זה רק הלכתי העירה עם רחל
        בשביל להוביל מכתב של א&#39; לדיג&#39;נסקי לפוסטה (הרי הוא אינו יכול ללכת
        העירה). היום היה יפה. איזו שמש חגיגית זרחה. וריח אביב נִדף. קרני שמש
        חמות לטפו בחִבה את היקום הרטוב וקר, חממוהו בחֹם זעום. והכל אמר שירה,
        שירת חיים ושמחה, כל זה היה בטבע, אך חוץ מהבריות הטבע והם בני-האדם. אלה
        הבריות העלובות שנתכה עליהם קֻבעת כֹס התרעלה. ושותים ומוצים הם אותה בימים
        האחרונים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אנחנו הלכנו אט מפני שבכלל לא היה חשק ללמֹד ביום אשר כזה, ועברנו בדרך ע"י
        בתי הגרמנים. וכי מתי יש זמן לעמוד ולהסתכל בכל? תמיד עוברים או בעגלה או
        מהר לרגלי עסק. והיום בהיות לנו זמן סקרנו את הכל. עמדנו ע"י כל גן וגנה.
        ואז... נתעוררה אותה הנִיה של חֻלשה הידועה לך מכבר, הקנאה לגרמנים, והשאלה
        היוצאת בתור תוצאה ישרה: "מדוע לא נהיה כמוהם"? וכל הדרך התוכחנו בדבר זה.
        מדוע אין אנו כך? ובאיזה אופן אפשר להגיע לזה? האם יכולים אנו לחקות אותם
        ע"י זה שנתבונן למעשיהם ונלמדם? אך לא, לא, לא בלמוד שבהכרה נגיע לזאת.
        צריך נטיה טבעית הנתנת ע"י הטבע ולא נרכשת ע"י אמצעים מלאכותיים. אפשר
        ללמֹד – לדעת, אך לא ללמֹד – לעשות. וגם אצל ג&#39;מס פגשנו לא מכבר,
        האומר: גם האדם הלומד את תורת-המוסר על בוריה, אין הדבר מחיב שיהיה אדם
        מוסרי. תורה זו יכולה לעזֹר לו לשוב מדרך רעה אם תעה בה. יכולה לתת לו את
        האפשרות להערכה עצמית יותר מדֻיקה ונכונה מאדם אחר אך לא יותר מזה. וכן
        מוצאת אני גם ביחס לשאלה זו. מה נלמד את דרכי חייהם זה יכול לעזר לנו לדעת
        איך צריך להיות. אבל זה לא אומר שנוכל כבר להיות ממש כך. ועוד גם זאת, הרי
        הם כ"כ שונאים אותנו לולא זאת אפשר היה להתקרב אליהם ולרכֹש מהם את הנחוץ.
        והנה איזו שנאה כבושה. מביטים עליך היהודי כעל מפלצת הבאה מן השחת. כבר
        מרחוק בראותם אותך יעקמו פניהם בבוז. זהו לעג של צעיר לזקן. הם עדיין
        רעננים בעצם פריחתם, ואנחנו זקַנו. עם שנושא עליו סבל של כ"כ הרבה דורות,
        נטל של כ"כ הרבה צרות ויסורים, האם יכול גבו לא להתכפף, ועיניו להביט ארצה,
        מבלי לזקף שוב את הגב. להביט נוכחו בעינים בריאות שלא כהו עדיין ולראות את
        העולם כמו שהוא אם יפה ואם רע. לראות את העצים שהם עצים והגבעות גבעות ולא
        לשאות במחזות ודמיונות שוא הרחוקים מן המציאות. מי יוכל להגיד לנו אם לא
        טוב היה לא ללכת כפוף אלא לפול לגמרי. אם למות ואם לחיות. אם זקוף ואם שטוח
        ארצה אך לא באמצע הדרך, במצב של גסיסה. לא קיץ ולא חרף אלא סתיו, ממשך,
        סגריר, עצוב.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        וכי מה הבדל הוא בין האדם והעם. בכל דומים הם כך אצל רֹב אומות העולם, אך
        לא אצלנו. האדם נולד, חי ומת, וכך גם העמים. ומתחלת הוסדם צעירים הם באבם.
        מלאים כֹח רענן, כֹח עלומים, הולכים ומתפתחים וכובשים לפניהם בכֹח הנֹער.
         ואח"כ באה תקופת הבינים אשר גם לה יש תוצאות ידועות. בתור מבססת, קובעת,
        מאשרת. ואח"כ באה הירידה ובעקביה מות. גם לעמנו היו כל אותן התקופות אך
        בבואו אל הנקודה האחרונה לקפוץ אל התהום התיצבה חומה אדירה, חסמה את הדרך.
        אין ללכת הלאה וגם לשוב אי אפשר. איך ללכת נגד הטבע. אם נשטפת שוב לא
        תחזור. הזרם לא שב אחורנית וכן גם כל הבא בעקב הגלים. ואם רוצה עמנו שוב
        לחיות, שוב לעלות על ראש הפסגה ז"א לעשות דבר המתנגד למקֻבל. וספק אם יכול
        האדם לברא מחדש דבר שלא היה בטבע. אשרי המקוה. אך מה יהיה עם המיֻאשים? וזה
        הוא הדור, הדור המפקפק. העומד באמצע הדרך אינו מבין את המצב. מה שהיה כבר
        עבר ובטל לגביו. העתיד מעֻרפל ואין הוא יודע מה מחכה לו שם. עמידה כזו
        קפיאה על שמרים לא יכולה לצרור טובה בכנפיה. זהו הדור עם מחלותיו ועצביו.
        ואין תקנה. נעזב בידי הטבע ילך הכל כמו שהוא הולך...  ול Maladie du siècle
      </span>
      <span class='c6 italic'>[צרפתית: מחלת המאה]</span>
      <span class='c0'>
         אל נא נחפש תרופות, כי כלום לא יועיל לו. מחמצן מלאכותי לא יכול האדם
        להמשיך את נשימתו, כי בזה ששואף הוא, באויר צח, בעזרתו הסתגלותיו עצמו בלא
        השתדלות אחרים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ושוב חוזרת אני לשלי. ומה היה לולי שנאונו הגויים כ"כ האם לא אבדנו כבר
        כליל מהעולם? האם לא אותה שנאה עזרה להיותנו עם נפרד ובודד לנפשו? אבל לא
        על לוּ נבראה הסטוריה. אין ליסד בנינים על סמך לוּ.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אבל העבדה היא שהגענו סוף סוף בשנים האחרונות לידי שווי זכויות אין יותר
        יהודי ואזרח. כלם בנים נאמנים בשביל המולדת לנפֹל חלל על שדה קטל. שם אין
        השאלה: "דמו של מי סומקא טפי" כלם נקברים בקבר "אחים". וכך אם תמשך מלחמה
        זו הרבה, אז אפשר לקוות כי יכרת הכל. והעולם החדש שיברא הקב"ה נקוה שיהיה
        יותר יפה. יותר טוב ונֹח, עולם של צדק ויֹשר. כך! נעים סתם לפטפט קצת. כמו
        שעושה אני כעת. _  _  _  _  _  _  _
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אנחנו כבר חושבות על החופש של פסח הממשמש לבֹא. עפי השבועות ועמם החדשים.
        דוחקים את הקץ אבל מימים ימימה ידוע לנו שאסור לדחוק את הקץ. טבע הוא באדם
        לחכות לעתיד. לקוות שיביא הוא לו דבר טוב בכנפיו ואין הוא יודע שזוהי רק
        התכזבות. שעשועי שוא. זה מתאים רק לילדים קטנים, אשר המחר בשבילם זה הכל.
        זהו אותו הילד אך בצורה גדולה. אותו המחר אך יותר ארוך ובלתי מגבל.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        הנני כותבת לך את המכתב בבית זינה. כי זה 4 לילות שאני ישנה אצלה. כי האדון
        לובמן לא היה פה והיא היתה יראה להיות לבדה וגם מפני שאצל הג"ב יאפו נמצאת
        אחותה מחולדה ואין שם מקום. פה נעים כ"כ איזה חדר נחמד. ומטה לבנה (צבועה
        בצבע לבן) סמל ה  Virginito
      </span>
      <span class='c6 italic'>[בתולים]</span>
      <span class='c0'>
        . והן מכבר לא ישנתי בכלל במטה, מה  שמסב לי תענוג רב. ובכלל החדר כלו נעים
        מאד. משֻנה אמנם יהיה בעיניך איך באתי אני לזינה. אך הן חוק הוא: הנגודים
        מתקרבים. ואני כ"כ טוב התרגלתי עם הבית הזה, זוהי תכונה הטבועה בי להתקרב
        מהר אל אנשים. תכונה אשר יש בה גם שלילה וגם חיוב.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מכרכה אני להפסיק מפני שמאחרה היא השעה.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>יום ראשון</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        את החשבון לג"ב יאפו עשיתי והנה הגיע לה הרבה. אני נתתי לה את שתי
        המג&#39;ידיות ועוד נשארנו חייבים ב4 בשליק. כסף קטן אין כלל בידי, וע"כ
        אבקשך אם תשלחי ע"י אדון וויניק כסף תצרפי גם מעט מעות קטנים.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום ונשיקות לכלם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>מאוהבתכם,</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <br/>
    <br/>

    <p class='c3' dir='rtl' id='14.3.1917'>
      <span class='c2 italic'>[לפי תוכן המכתב, כ&#39; אדר תרנ"ז, 14.3.1917]</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אמא חביבה!   </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אמא, אמא, אלו היו המלים הראשונות שנפלטו מפי אחרי קראי את מכתבך. האמנם
        תמיד נשאר עומדות זו מול זו מבלי הבין האחת את רעותה? לא! צריך סוף סוף
        להסיר את אותו הדבר שמפריד בשביל בהירות המחשבה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        אני נדדתי, סבלתי מנדידה זו ואת באה להאשים אותי בזה. האם הייתי עוזבת את
        הבית ללכת למקומות זרים לולא בקשוני לעשות זאת. באותו יום שבאה דבורה אמרה
        לי הגב&#39; יאפו שאני צריכה לחפש לי מקום איפא
      </span>
      <span class='c6 italic'>[כך במקור]</span>
      <span class='c0'>
         ללון. ומה היה לי לעשות? להגיד לא! איני רוצה ללכת מכאן וצריכים אתם
        להמציא לי מקום? לו היה לי חדר מיחד לא הייתי מוסרת את מקומי אבל כשאין זה
        לי מוכרחה הייתי להכנע, ולשתוק. והנה באה תלונה מצדך שזו היתה קלות דעת
        מצדי. גם אם קלת דעת אני במדה ידועה זאת אינני מכחישה אבל כאן דוקא לא כונת
        אל המטרה. אני לגמרי זכאית מחטא זה. לא רק בקלות ראש אלא בכובד ראש הייתי
        כל ערב וערב עולה על משכבי.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אני כותבת וחורדת, פן תרגישי כעס והתמרמרות במכתבי. אך לא אמא אני לא כועסת
        על שאת מוכיחה אותי על שגיאותי תמיד אודה לך על זאת, כי הרי זהו סוד החנוך.
        אני רק משתדלת להביא את הטעמים שיש לי כנגד.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        ולמה את בהתמרמרות כזו עונה לי על התאוננותי בחסר פנה מיוחדה? אמנם צריכה
        אני להודות לכם מאד הורים יקרים שמשתדלים אתם להספיק לי את צרכי באפן שאוכל
        ללמד ולשקוט בשעה שאתם עומלים כ"כ הרבה. יודעת אני ומרגישה את זאת בכל רגע
        ורגע. אבל הרי מה שאני יותר לומדת הנפש אף היא מתרחבת ושאיפותיה עמה יחד.
        יש נטיה לנפש (כמובן ששונות הן הנטיות) למרגֹע, מרגע במובן אסטטי. נעימה
        עליך לפעמים פנה שקטה, אף כי עניות רבה מבצבצת מתוכה. לא דורשת אני מכם
        חו"ש שתתנו לי זה שאי אפשר. אני מסתפקת כי צריך להסתפק. אבל למה נתן דיו
        וניר אם לא לשפך עליהם את הגיגי הלב. בשביל להקל מעל הנפש זה שלא יכלה היא
        לעצר בקרבה. ואני חושבת אמא שצריכה את לשמח שיש בי עדיין רגשות ליופי,
        רגשות אשר אם כעת לא יכולים לתת להם ספוק הרי בודאי יעברו הימים הרעים ואז
        אפשר יהיה להפיק מהם תועלת. והלא בכל משך ימי החיים צריכים ללוות את האדם
        נטיות לנעם וסדר, אשרי אדם שזכה לכמו אלה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        אני כתבתי שאי סדר בחיי הכניסה הנדידה ואת אומרת חסרון פנה. אולי אפשר היה
        להבין זאת ממכתבי ע"י אי דיוק של השפה. בכל אופן לא לזה כונתי וכ"כ התיאשת
        כבר ממני עד שאין הלמוד שוה את כל העמל הגדול הזה. כן חביבה! אני מרגישה
        שיותר מדי קשה עליך העֹל. את משתדלת שלא ארגיש בזה אבל מתוך השורות זה
        מבצבץ. לבי כואב אמא! במה אוכל לעזר. לולא המלחמה כי עתה עמדתי כבר ברשות
        עצמי וגם יכלתי לעזר לכם ומה לי לעשות אם כך נגזר עלינו. הפסיד העולם אבדה
        הרבה יותר גדולה וחשובה מזו שאבדה לי וע"כ אין להתאונן צריך לקבל באהבה.
        אלה גם דבריך תמיד אמא חביבה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        יום רביעי היום ואני יושבת ומתארת בנפשי שבעוד שבוע בזמן הזה אהיה כבר
        בבית. ומאז עד הפסח יש רק שבועיים של למוד ואז כבר נגמרת חצי שנת הלמוד.
        החצי הכי חשוב. אלי, אלי כמה מהר חולפים החיים, אם קלים הם או קשים, אם
        טובים ואם רעים הכל עובר, ומשאיר אחריו רשמים שונים. זכרונות נעימים וגם
        רעים. לפורים תהיה לי חזרה רבה בכל הטבע. כי גמרנו כעת את
      </span>
      <span class='c6 italic'>[המין? המיון?]</span>
      <span class='c0'>
         ותהיה לנו בחינה בטבע ואחרי הפסח אחנו נתחיל ללמד זאולוגיה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        מצב הרוחות פה נסער מאד. ה"מחר" נעשה לדבר רחוק מפשט, בלתי ידוע כ"כ שמעולם
        לא היה כך. מתעוררים בלילה כמה פעמים לראות אם אין אניות על הים, אם לא
        מרעישים עדיין את העיר. ודוקא הלילות כ"כ יפים, הים נחמד בשער שמשתקף בו
        ירח חו<span class='c0 italic'>[ו]</span>ר, לבן. ועל ים זה מסתובבות אניות משחית. היאומן? זהו הרגש והשכל
        שלא ישלימו זה עם זה.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span>
        ע"ד כתיבת מכתב עברי לגב&#39; לוין חקרתי וזה אמנם נכון אפשר לכתב בעברית.
        אני חושבת שאכתב כבר בבית בפורים. הלא אז נערך מכתב - - - אם נחיה אומרת
        אני. זוהי שפת הנשים הזקנות, אבל הרגע למדנו לדבר כך. אם נחיה עד יום
        הרביעי הבא אז נתראה בשמחה, כי צריכים אנו לה</span><span class='c6 italic'>[ו]</span>
      <span>
        קיר כל רגע ורגע בחיינו ההוים. הולך אתה ברחוב לבית הספר ומבית הספר, או
        בבית הספר עצמו והנה בן רגע עפה פצצה, פוגעת בך ואינך... הלא זה דבר מאד
        אפשרי. ובכל זאת אין אתה ח</span><span class='c6 italic'>[ו]</span><span class='c0'>זר אחור. הולך אתה ואינך ירא. מסרת את עצמך ביד הגורל לעשות בך כטוב
        בעיניו. רבות הן התלמידות החרדות כל היום מעת ששמענו את המנגינה היפה. אבל
        אני ופלא הדבר כ"כ שקטה ושלוה. נעשיתי פטלית מאד אמיצה ושקטה. "אפרת שקטה".
        את בודאי לא יכולה להאמין לזה אבל כך הוא הדבר.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>את המנורה כבר מכבים ואני מכרחה להפסיק.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>יום ה&#39;</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>
        זה היום השלישי שאני כותבת את המכתב ואיני יודעת אם ילך גם כעת. יש הזדמנות
        אבל היא בעיר ואיני יודעת באיזה אפן להגיע אליה. למסר לאדון יאפו זה יותר
        קשה מקריעת ים סוף.
      </span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>הנה רצוף בזה מכתב - - -.</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>שלום ונשיקות שלכם</span>
    </p>
    <p class='c3' dir='rtl'>
      <span class='c0'>אפרת</span>
    </p>
    <p class='c3 c7' dir='rtl'>
      <span class='c0'></span>
    </p>
    <hr style='page-break-before: always; display: none' />
    <p class='c3 c7'>
      <span class='c0'></span>
    </p>
  </body>
</html>`;

export default page;
