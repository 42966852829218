const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c3" dir="rtl">
      <span>        </span
      ><span class="c0"
        >כשלותה עדיה בבקר השכם עד שער החצר את סלים שלה לעבודתו, חזרה בפסיעות
        זעירות אל דלת מרתפה וישבה לה בעצלות על הסף. מרֻבה העבודה כאן במרתף אחרי
        שמונת ימי המשתה של צדוק אחיה, אלא שהיא עיפה, רצוצה ושבורה ורוצה לנוח עוד
        רגע קט.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עדיין רעש הנאספים לא הסתלק מאזניה, עדיין הד התוף כאלו הומה באויר וראשה
        סובב עליה. היא תומכת את מרפקה במשקוף הדלת ובסנטר נטוי מרימה פניה כלפי
        המזרח.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >על פני השמים נפוצו עננים אפורים-כחולים, שבקצה אחד הם קלושים ובשני עבים
        ושחורים. בצדם של הרי יהודה נצברו העננים זה על גב זה כמו רֻכסי הרים
        שחורים. פתאם הואר קצהו העליון של גוש עמום זה, אֹדם ארגמן הצניף את ראשו,
        אבל מצנפת אדמה זו התהפכה מהר לפז, והלה לזֹהר נפלא ומיד כרובי מעלה תפשו
        שם מקום והתחילו עפים, מפזזים ומכרכרים בדחילו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עברו רגעים מספר ונעלמו מראות הפלא. נראו זעיר שם, זעיר שם קרעי שמים
        תכולים ועמק הרקיע התגלה לאט לאט. הוארו באור רך גגי הבתים, על הקירות
        הלבנים הצטירו ענפי העצים שבגנות. על הככר הרחב התפשטו אורות וצללים שזחלו
        אט עד הגדר, הגיעו עד עיני עדיה השחורות והצליפו עליהן בפסי אורם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשנכנסה עדיה פנימה היא עמדה כמיֻאשת ולא ידעה ממה להתחיל. – הקדרות
        שחורות ומפֻחמות, הצלחות זוהמה התיבשה עליהן, הכפות נהפכו ירק; הכל דרוש
        להוציא החוצה, לשפשף ולרחץ, – והיא כה עיפה, כה עיפה! רוצה היתה לנוח יום
        אחד אבל אין פנאי! – הריה צריכה לגמר כאן וללכת לגברת, כי זו בודי תתכעס,
        אם גם היום, ככלות כל סדר החתונה לא תבא לעבודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכשנגשת עדיה לתקן קודם כל את המטה היא נאנחת על האי שופרה שנטל חנה ממנה
        ע"י ערמה גדולה של בגדים ומטליות המוטלים עליה. היא משליכה הכל ארצה
        ומתחילה פורשת בחבה את מכסות המוכין האדמות, ובגמרה עומדת רגע ומסתכלת
        במטה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >... יפה, יפתה, – בכמה אנחות ודמעות היא עלתה לה! אבל כלום אינה שוה זאת?
        הרי המוטות הללו שברחב, כזהב טהור הם! והגבוהים? – איזה פרחים מצֻירים
        עליהם!.. רק חבל שהם גבוהים יותר מדי בשביל חדר זה וסלים חפר ארבע בורות
        לכרעי המטה עם הגלגלים היפים, – הוי חבל!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ונזכרת עדיה בענג כיצד תהו והביטו כל האורחים על מטה זו היפה, הנעימה;
        כיצד ישבו עליה ברחבה הרב וכל נכבדי העדה התימנית. האשכנזים מעל הכסאות
        תמהו – מטה כזו מנין לה, מנין?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא באמת התכונה רק כלפי האשכנזים, כשהיא כה נלחמה בשביל רכישת המטה, –
        הריהם, "ראש העיקר!" גם אחיה רק אליהם התכון כשהכין לחתונה מאה שלמה של
        תפוחי זהב, קנה במג&#39;ידי עוגות אצל האופֶה ויצו עליה לשאל מיחם וכוסות
        ולהכין להם טה כדבעי. –  –</span
      >
    </p>
    <p class="c2 text-center" dir="rtl">
      <span class="c0">*  *  *</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עדיה בוחרת ומקפלת את הבגדים מתוך הערמה המוטלת על הארץ, מעיפה כל רגע עין
        על המטה ועל הארון התלוי על הקיר, על השלחן הנמוך עם המפה הצהובה, על
        וילוני החלונות הקטנים ופניה נהירין מנחת. – וכשעולה על רעיונה ההפרש בין
        אשתקד להשתא היא ממלמלת: "ברוך השם, ברוך השם!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אשתקד היא גרה עם סלים שלה במאורה אפלה סמוכה לאורותו
        של חוג&#39;ה יקותיאל. שלשה מתליקים ביום היתה מרויחה אצל בעלת הבית ההיא,
        ועוד התכעסה זו עליה, התכעסה עד שפטרה אותה מעבודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סלים שלה כבר היה אז חולה והמורסא שבצוארו הסבה לו יסורים קשים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התימנים רפאוהו ע"פ אפנם, צרבו באש הרבה פעמים ולסלים לא הוטב. אז הוחלט
        אצלם לפנות אל הרופא.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– חתוך צריך! – אמר הרופא ויתכון לעשות זאת מיד, אבל הם נבהלו:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לחתך בצואר?... הרי ימות! – ויסתלקו מן הרופא בלא כלום.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רעב, יסורים ויאוש שרר בתוך מאורתם ופתאם שולחו משם החוצה. – בעלת הבית
        קבעה את המקום לשפחה אחרת, והיא, עדיה, בוכה ואינה יודעת לאן לפנות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אז אירע להם הנס: – היא נקראה לעבודה לבית חוג&#39;ה מאיר. שם התענינו בה,
        שאלוה ע"ד חייה ומצבה ואחרי שמעם את כל מר לבה, התחילו בבית להתיעץ ויחליטו
        לפנות לה דירה, – את המרתף הזה, ששמש לול לתרנגלות ודיר לעצים, ושנראה לה
        כהיכל מלך מול מאורתה הקודמת. היא טאטאה ונקתה את הקירות, התקרה והרצפה;
        סתמה בחמר את חורי העכברים שבקיר ותביא לכאן את רכושה, – צרור אחד המכיל
        שני חלוקים וחומש עם סדור של סלים. הניחו הם את החלוקים למראשותיהם, התכסו
        בטליתו של סלים וכה חם היה להם בפנתם החדשה, - רק המורסא לא נתנה לסלים
        לישן!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >למחרת בצהרים הוביל אותו חוג&#39;ה מאיר להרופא ומבלי שאול את פיו,
        נעשה הנתוח הדרוש. אחרי כן סחו לה, לעדיה, שבחזקת סכנה  היה סלים שלה,
        רק הנתוח הצילו. והיא התרגשה ותלך להודות לרופא, נשקה את ידיו ורחשה:
        "יאריך נא השם בימיו, יאריך!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשהתרפא סלים לגמרי, התחיל חוג&#39;ה מאיר להרגילהו לעבודת האת. עוד סלים
        מתעצל, מוטל בבקר כבול עץ על הקרקע ואינו זז ממקומו, וחוג&#39;ה מאיר נגש
        אחרי הפעמון אל דלת המרתף ומזרז:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– מהר סלים, מהר, הפעמון צלצל!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">יש שסלים מתחכך ועונה בתחנונים:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– לא אוכל היום, חוג&#39;ה מאירי – מחר אעבד!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לא, לא! – גוער בו החוג&#39;ה ואחרי רגע משדלהו בקול רך:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– ראה סלים! בחוץ שמש, אור, חם, – הרי תענוג הוא לעבד ביום שכזה! ולמה תהא
        מוטל כאן בפנה האפלה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסלים התחיל להרויח כל יום שני בשליקים, שני בשליקים; הצטרפה פרוטה לפרוטה
        עד שנאסף כל הסכום של ששה נפוליונים, שהם חיבים היו מתימן להוצאת
        הספינה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עתה, כשעדיה רואה את כל רהיטי חדרה, ואת הבגדים הרבים התלוים בפנה ומכוסים
        בטלית ואת התבה שבה נצברת קופת החסכון שלהם, היא נותנת הודיה ושבח
        להמקום ברוך הוא ושפתיה רוחשות: "ברוך השם, ברוך השם!"</span
      >
    </p>
    <p class="c2 text-center" dir="rtl">
      <span class="c0">*  *  *</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >תולה עדיה על המסמרים את בגדיה ואת חליפת השבת של סלים ומהרהרת: – כשלבוש
        סלים בחליפה זו עם צוארונו הלבן, ומגהץ את נעליו הצהובים, וסורק תלתליו
        השחורים המתפתלים על לבן צוארונו – הריהו כבן מלך ממש!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והרי גם לה בגדים יפים! שמלה זו האפורית, הלא בידיה תפרה, על מכונת הגברת
        תפרה וכל התימניות מקנאות בה! –  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גם בתרנגלות שגִדְלה הן מקנאות!... חביבות, – שלש הגדולות עם התרנגל בראשן
        ושבעת האפרחים – כלם מחמדי עין הם! גם הגברת הללה אותן בעברה שלשום בסמטא
        וסרה למרתפה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אוהבת היא את הגברת שלה! – מהרהרת אילך עדיה. – אמנם יש שעולים על לבה
        הרהורי חטא לקנא בבית החוג&#39;ה, אבל זה אסור, אסור באמת! הרי השם הוא
        שנתן לחוג&#39;ה מכל טוב,  בית יפה, מאכלים טובים, כלים נאים וגם ילדים
        נחמדים!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוי היא אוהבת כל כך את הילדים, ביחוד את הקטן, את אבשלום! כשהוא קורא לה:
        "עדיה, עדיה, בואי הנה!" – לבה מתמלא איזו חרדה נפלאה, ואם רק הגברת אינה
        רואה, היא תופשת את הפעוט ונושקת, נושקת...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אף את העגלה שלא מכבר נוספה לרפת היא אוהבת! פעם תפשה אותה כשפזזה בחצר,
        לטפה את צוארה ותנשקה. הגברת הציצה פתאם מן החלון ובראותה את המעשה המשונה
        הזה חיכה, והיא, עדיה, התבישה ימים אחדים להביט בפניה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גמרה עדיה את סדור מרתפה והתחילה משפשפת את קדרותיה בחוץ. בזכרונה עובר כל
        סדר חתונת אחיה ופני האורחים וכשהגיעה להנערה ששלמה טביב הביא לפני ימים
        מועטים מתימן, – הועמו פניה פתאם. כבת שנה היתה הילדה בעת
        שאספה טביב לביתו; הם היו אז שכנים בחדן–שם, והיא ראתה איך שהאסופית המסכנה
        בגדלה עבדה עבודת פרך, ואף על פי כן – כמה יפתה כעת! –  –  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והרהורים קשים מתחילים מבצבצים בלב עדיה: – הרי שבע שנים היא יושבת עקרה
        ומי יודע, – אולי מנע השם ממנה פרי בטן לעולם?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וסלים שלה הריהו כה רוצה, כה רוצה בבנים!.. שמא באמת עלתה מחשבה זרה על
        לבו, כשהסתכל אז בליל השדוכין של צדוק וביום החופה, – בילדה זו,
        בסעידה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">הרי התימניות צחקו והזהירוה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– פתחי, עדיה, את עיניך, – הרי סלים שלך נתן עינו בסעידה! והיא לא שמה לב
        לדברי הבל, – כלום היה לה פנאי לכך בעת החתונה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ושמא לא הבל הוא וסלים באמת רוצה בנערה? שמא לשאת אותה נתן אל לבו?... הרי
        אתמול הוא נאבד לכל הערב... אמר שאצל הדוד יחיא היה, אבל שקר זה, שקר! –
        הוא אל טביב בודי הלך, לראות אותה הלך! –  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התכרכמו פני עדיה וכל אבריה הזדעזעו מצער ויסורים. היא השליכה בכעס את
        הקדרות הצדה תפשה את ראשה בידיה, לחצה אותו ביאוש אל הקיר ולחשה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הוי השם, מה לעשות, מה לעשות? – עוצה לי, הושיעה לי!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומתיזות עיניה ניצוצות, כשהיא מרימה אותן כלפי הרקיע ונשבעת, שהיא תחניק
        את הילדה, תחניק אם יכניסה סלים לחופה.</span
      >
    </p>
    <p class="c2 text-center" dir="rtl">
      <span class="c0">*  *  *</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואחרי חתונת סלים עם סעידה, שוב עמלה עדיה לסדר במרתף ולשפשף קדרותיה
        ומקצת נחמה בלבה: – סלים מצא לילדה חדר לחוד וישב שם תשעה ימים, ותשעה ימים
        האחרים עמה כאן, במעונם, ישב כמקדם!</span
      >
    </p>
  </body>
</html>`;

export default page;
