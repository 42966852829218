const page = `
  <body class="c11 doc-content">
    <p class="c0 text-center" dir="rtl">
      <span class="c5 c9">( ס פ ו ר )</span>
    </p>  
    <p class="c0 text-center" dir="rtl">
      <span class="c5 c9">מוקדש לנעמי פ.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בהניקה פלורה את ניסים הקטן היא החזירה אותו לעריסתו ותשוב לגמֹר את טליאת
        בגדי העבודה הנחוצים</span
      ><span class="c6"> </span
      ><span class="c3"
        >לפריסיאדו למחר. היא מצדיקה עליו סוף–סוף את הדין על אחרו לשוב הערב: </span
      ><span class="c2">- </span><span class="c3"> כלום הוא באמת צריך</span
      ><span class="c6"> </span
      ><span class="c3">להיות סגור בבית כמותה? אֵם </span
      ><span class="c2">- </span
      ><span class="c3"
        >מחֻיבת למסֹר את נפשה לילדיה ולהיות כפותה אליהם כל הימים, אבל האב, אחרי
        עבודה קשה של יום תמים בשדה</span
      ><span class="c6"> </span
      ><span class="c3"> כלום אינו רשאי לבלות שעה קלה בנעימים? ובכלל </span
      ><span class="c2">- </span><span class="c3">איך היא יכולה</span
      ><span class="c6"> </span
      ><span class="c3"
        >לכעֹס עליו, אם הוא שוהה קצת יותר בבית פרנסיס, אותו המורה החביב והאדם
        המצוין שמבלה רוב הלילות</span
      ><span class="c6"> </span
      ><span class="c3">על תורתו ועל תפִלותיו הלבביות, המתוקות, </span
      ><span class="c2">- </span
      ><span class="c5 c3"
        > כלום קל להִפרד מהר מחברת אדם שכזה? הלא אין להם עוד ידידים כמוהו במושבה
        אשכנזית זו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >עם האשכנזים אינם באים במגע ומשא רב. נפגשים אמנם בבית התפלה, במשרד
        הפקידות בבית–העם, אבל</span
      ><span class="c6"> </span
      ><span class="c3"
        >יחס הדדי אין ביניהם ופריסיאדו משתעמם ומבקש חברת–אחים, </span
      ><span class="c2">- </span
      ><span class="c3"> כלום מותר לה להתרעם על זה</span
      ><span class="c6"> </span
      ><span class="c3">?... חה, כנראה שהיא בדרך אחותה אחזה </span
      ><span class="c2">- </span
      ><span class="c3"
        >למרר את חיי בעלה,  לבקש עליו תואנות, לקבול על מעשיו ולחטט בדרכיו</span
      ><span class="c6"> </span
      ><span class="c3">!...לא, לא טוב להיות בעלת עצבים כשולטנה</span
      ><span class="c2">, -</span
      ><span class="c5 c3"> צריכים למשוך בעול החיים בסבלנות!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אבל פלורה אינה מצייתת לקול ההגיון הבריא שלה. זע איזה נים בלב ושולל את
        מנוחתה -. הריהו יודע כי</span
      ><span class="c6"> </span
      ><span class="c3"
        >לנסים צומחות השִׁנים; כל היום אינו יורד התִנוק מעל ידיה וגם בלילות אינו
        נותן מנוחה. יודע שאחרי המכתב שקִבלה מיפו ע"ד מחלת אחותה עליה להכין
        לנסיעה לבוקר השכם </span
      ><span class="c2">- , </span
      ><span class="c5 c3">והיאך לא נשאר הערב בבית לעזור לה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >היא נעלבה. אבל בתוך עלבונה מתערב קורטוב של יהירות כשהיא מחליטה כי טובות
        הנשים מבעליהן, אלף פעמים טובות מהם</span
      ><span class="c5 c7 c6">!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >כאן היא נזכרה בגיסהּ סעד; התאדמה והחליטה, כי אמנם יש גברים יוצאים מהכלל </span
      ><span class="c2">- </span
      ><span class="c3"
        >מה טוב הוא סעד לשולטנה! נוהג הוא בה כבוד כאחד האשכנזים, חולק לה חִבה
        ושימת לב ורחם ירחם עליה בסבלה יסורים. אבל מה יועיל כל אלו אם השטיא </span
      ><span class="c1"> </span
      ><span class="c3"
        >אינה יודעת להוקיר את יחסו הטוב וכל הימים ממררת את חייו</span
      ><span class="c2">- ? </span
      ><span class="c3"> היא אינה רוצה בבת יחידה</span><span class="c6">!</span
      ><span class="c3"> בציונה לבד אינה רוצה</span><span class="c2"> - </span
      ><span class="c3"
        >בנים יִתן לה...! למה לפלורה כבר שלושה במספר...? שטיא </span
      ><span class="c2">- </span
      ><span class="c3">האם לא טוב לה סעד מעשרה בנים</span
      ><span class="c5 c7 c6">?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >פלורה גמרה את עבודתה;  ארזה את חפציה; השעה מאוחרת מאוד ופריסיאדו עדיין
        לא. גדול העלבון וגדולה המרירות שבלב -. את פלורה מותר לנצל!  &#39;פלורה
        הטובה&#39;</span
      ><span class="c6"> </span><span class="c3">- ככה מנעוריה קוראים אותה</span
      ><span class="c6"> -</span><span class="c2"> </span
      ><span class="c3"
        >נבראה רק לשמש אחרים, לדאֹג לאחרים, למסֹר להם את נפשה...! פלורה סובלת
        ושותקת - ומדוע לא ינצלו אותה ולא יִטלו מלבה ומכֹּחותיה כל עוד שיש לה מה
        לתת</span
      ><span class="c5 c7 c6">...?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >פתאום תופסת היא שוב את עצמה על התרגזות עצבים. נושכת שִׁניה ורוצה לכבֹּש
        כעסהּ. היא נגִשה אל</span
      ><span class="c6"> </span
      ><span class="c3"
        >הארון, הוציאה אלפס עם חלב חם, מזגה להכוס ושתתה בעמידה כשעיניה מרפרפות
        על פני מִטות הילדים ושפתיה לוחשות</span
      ><span class="c5 c7 c6">-:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >...בן פורת יוסף...! סבלנות דרושה שְׁמִרת הכֹּחות בשבילם, עזרני
        אל!</span
      >
    </p>
    <p class="c0 c8" dir="rtl"><span class="c5 c3"></span></p>
    <p class="c4 text-center" dir="rtl"><span class="c5 c3">***</span></p>
    <p class="c0" dir="rtl">
      <span class="c3">בנסעה מחר עם שני פעוטים </span><span class="c1"> </span
      ><span class="c5 c3"
        >ליפו בעגלתם שלא הספיקו לנקות אחרי הובלת הזבל, דפק לבה לזכר אחותה - מי
        יודע באיזה סגולות חדשות כבר השתמשה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >לא די לה הצועניות, השיכים, הדרוישים, הלא גם אצל התימני התרפאה - השקה
        אותה מים קדושים ממעינו של שלום שבזי, בִּשל לה עשבים מתוך לחישות ועויות
        וצִוה לשתות עם קריאת הגבר הראשונה דוקא... הכל היא עושה וסובלת כל כך!
        תרופות הכושיה לבד בכמה כסף וצער וכחות עלתה לה! שִליא מיולדת בכירה...!
        להחביאה בכלי חרס שחור לשלושה ימים, אחרי כן להשרותה בפושרין ולשתות קימעה
        קימעה... לא יפלא ששלושה חדשים נמשכה הדזנטריה! מי יודע למה עוד מסוגלת
        שטיא זו! מי יודע מה</span
      ><span class="c1"> </span
      ><span class="c3"
        >קרה שם הפעם?! אולי השיך השקה אותה שוב את הסם ההוא...? אבל היא מצאה את
        שולטנה בקדחת פשוטה, קלה ובלי קושי רב עלתה לה ההשגחה. ופלורה מחליפה כֹּח
        בעיר. היא אוהבת את יפו - , לא את העיר גופא אלא את הים ואת הבית הקטן על
        חופו, ששם יושב אביה ושם עברו עליה ימי הנוער והבחרות,  עברו יסוריים וצער
        וגעגועים שונים. בלילות היא אוהבת לעלות על הגג הקטן ותחת רעש הגלים שוטפים
        להם הזכרונות -.</span
      ><span class="c6">  </span
      ><span class="c3"
        >בהיותה כבת עשר ושולטנה בשנתיים פחות ממנה בא אביהן לגור ביפו. מטעם
        הבולשת הירושלמית נמנה  לבוליש בעיר החוף. כאן, רחוק מקרוביו נעשו לו החיים
        עם שתי יתומות</span
      ><span class="c2"> </span
      ><span class="c3"
        >יותר מרים ויותר קשים.  לבו היה מתמלא צער כשהיה אנוס לילה</span
      ><span class="c6"> </span
      ><span class="c5 c3"
        >לילה לצאת למשמרתו ולעזֹב את הפעוטות לבדן בחדר. לובש הוא כלי זינו ויוצא
        בשתיקה ולבו נוקפו, נוקפו.  הוא סוגר את הדלת אחריו, מנסה פעמים את המנעֻל
        והולך לו. בסובבו את השכונה הוא חוזר לדלת חצרו, מנסה שוב את המנעול, נוטה
        אליו אזנו ומקשיב. יש שרעש הים מפריע לו להבחין מה שנעשה פנימה - הישנות
        הילדות או ערות? הוא עומד שעה קלה וחוזר בגעגועים לסִבובו. את ילדותיו לא
        ראה הרבה במשך השבוע. כל הימים הן מבלות בבית הספר ורק בערבים הוא נמצא
        בחברתן. אחרי ארֻחת הערב הן נגִשות לשעוריהן והוא בוחר מתוך ספרי הלִמוד
        שלהן את אותו הספר עם הסִפורים הקלים שהוא קורא בעונג גדול. הילדות מספרות
        לו ביהירות כי ספר זה חִבר המורה שלהן, אותו הגבוה, שאבא פוגשהו לפעמים
        בעברו במהירות לחוף הים. אח"כ שחות הילדות על דבר יתר המורות וע"ד המנהלת,
        המלמדת צרפתית, ואיך שזו ענשה את האשכנזיה הרעה שמכנה אותן תמיד בשם הגנאי
        - פרנקיניה...!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">(המשך יבֹא)</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >-------------------------------------------------------------</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">(המשך מגליון קודם)</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בשעורן הצרפתי האב ג"כ  לוקח חלק. יש שהוא מנסה בסקרנות של ילד לחקות את
        כתיבתן וכשעולה בידו לציֵּר אי–אילו אותיות הוא פונה בצחוק אל הנערות -:
        הבטנה</span
      ><span class="c6"> </span
      ><span class="c3"
        >ילדות וראו - איך שבניסטי הבוליש כותב צרפתית. הן מציצות בכתב יד אביהן
        ומתפרצות בקול צחוק עליז.</span
      ><span class="c1">  </span
      ><span class="c3"
        >כשגדלו וגמרו את בית הספר התחילו להשתכר בתפירה ורקימה ולעזֹר</span
      ><span class="c1"> </span
      ><span class="c3"
        >לאביהן. הוא הביט על בנותיו ושמח -. פלורה צהבהבה עם תלתלים בהירים ועם
        עיניים גדולות, תכולות, שמבטן האלכסוני חודר ונוקב ישר אל הלב. ושולטנה -,
        ראש שחור עם שערות ארוכות, אף דק וחד ועינים עמוקות מני ים -,</span
      ><span class="c2"> </span
      ><span class="c5 c3"
        >האם אינן שתיהן יפהפיות ...? הוא הרגיש איך שספרדים צעירים החלו להביט על
        בנותיו בגעגועים, אבל הוא עצמו נשא עיניו להבחור האשכנזי  שהיה מביא לפעמים
        לביתם ספרים לקריאה. אותו הוא חשב לרכֹּש חתן לפלורה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >ובביתם אז יצא ונכנס גם עלם אחד, פריסיאדו ספורטי, תלמיד בולגרי במקוה
        ישראל. פעם בחֹדש, בערב שבת היה התלמיד ממהר בפסיעות גסות ממקוה ליפו כשהוא
        מלא שכרון מריחות פרחי תפוחי–הזהב ומריח הפרח שלו, פלורתו שהוא נושא בלב.
        אבל דבר אחד היה מעיר פחד בלב העלם -, אחותה שולטנה כל כך רצינית
        ויהירה...!  למה היא מביטה עליו מגבוה? שולטנה בקומתה הזקופה, בפניה היפים
        עם שרטוטים דקים מן הדקים, במבטה הרציני ותכונתה השולטת היתה &#39;מלכת
        הבית&#39;. השלום לא מצא קן בין שתי האחיות אף–על–פי שכל אחת אהבה את השניה
        עפ"י אֹפן שלה, ויש שאהבת פלורה עברה כל גבול. במסירות–נפש גמורה היא מִלאה
        אחרי כל הקפדותיה של שולטנה. חשבה את עצמה  אֵם ליתומה מסכנה, וכל עמל
        בשבילה לא היה קשה לה. רק לעתים רחוקות בצבצו הרהורים ע"ד קושי תכונתה של
        אחותה, על סרבנותה וקפדנותה,  ואז היתה שואלת את נפשה ביגון: למה היא ממררת
        את החיים, למה, למה? ורגש אהבה עמֹק סח לה, כי שולטנה חולה וזקוקה לרחמים
        ולסליחה על כל שגעונותיה. ואת ההשערה הזו, כי מסכנתה חולה, אִשרה חברה
        אשכנזית אחת שקראה אפילו את המחלה בשם</span
      ><span class="c6"> </span><span class="c3">-: מחלת עצבים</span
      ><span class="c2">-! </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >החכוכים בין האחיות נעשו יותר תכופות מעת שהתקשר ספורטו אל פלורה. די היתה
        מִלה אחת, תנועה אחת, שלא מוצאת חן בעיני שולטנה והבית מתמלא קטטות ומריבות
        שסופן בכי, חרטה,  ומוסר-כליות. פלורה תדיר, אחרי מאורע כזה, מלמדת זכות על
        אחותה - חלשה</span
      ><span class="c6"> </span
      ><span class="c3"
        >, חולה שולטנה וצריכים לותַר על הצער, על העלבון, על הכל, הכל...! ואף על
        פי כן מתמרמר הלב, קובל ומוחה במסתרים כששולטנה מרגיזה אותה, מעליבה אותה.
        יש שהיא מתפרצת בבכי, נופלת על מטתה, כובשת ראשה בכר ומהדקת אותו בין
        שִׁניה. ברגעים כאלו מתרוצצים הרהורים קשים בלב -: היא לא יכולה יותר לחיות
        בשביל אחרים! רוצה רגע של שלוה, של מנוחה... היא תחדל לתת</span
      ><span class="c1"> </span
      ><span class="c5 c3"
        >את כל נפשה לשולטנה, תחדל...! וברגע זה יש שהיא נזכרת ששולטנה לא אכלה
        הערב. מה עושה? קופצת מהר ממטתה מציתה את הפרימוס שקנתה</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >לאביה בשביל שיכין לו בלילה קפה במהירות, מחממת לשולטנה חלב ומגישה לה עם
        עוגה שהכינה לבֹּקר. האחות לא שותה ופלורה רותחת מצער ומאשימה את עצמה -:
        רעה היא, מרשעת! היאך לא חסה על אחותה המסכנה ותרגיז את עצביה עד מריטת
        השער, עד הכאות הראש בקיר? לא, לא,</span
      ><span class="c2"> </span
      ><span class="c5 c3"
        >יותר לא תענה כלום כששולטנה תתלונן עליה. היא תשתדל להתאפק מכל דִבור
        ולותר על הכל... קשה? לא, לא קשה בעת שיש לה עולם מלא אהבה, יש לה פריסיאדו
        האהוב, החביב</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >שלה...! וכאן פלורה מרגישה פתאם כאלו היא חטאה בזה שיש לה אושר, יש לה
        אהבה, ולשולטנה המסכנה אין כלום, כלום!
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >אבל בנות בניסטי הבוליש התחתנו שתיהן בשנה אחת. שולטנה בחרה בסעד בן תופיק
        חנוכה חנוני בנוה–שלום, נשארה ביפו, ופלורה היתה לכפרית אחרי שיק"א אִכְּרה
        את ספורטו, המצטיין בחריצותו החקלאית.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c5 c3">***</span></p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >החיים במושבה היו מתחילה קשים וזרים לפלורה;  אבל לאט לאט, שנה אחר שנה,
        הסתגלה אליהם יותר ויותר עד שהיתה כבת כפר מלידה האוהבת את השדה, הגן, את
        הלול ואת הרפת. למרות לידותיה התכופות היתה פלורה עוזרת טובה לפריסיאדו
        במשק החקלאי. כל לידה עוצרת אותה איזה שבועות מעבודות המשק, אבל די לה
        להתחזק מעט, והיא יורדת תכף אל הגן ואל הרפת. הכל נראה לה אז חדש ונעים
        כפליים. העגבניות בגן - איזה צבע נחמד יש להם! והקישות, פרחי הקישות, מה
        ענקיים הם, מה יפים עלי-הקטיפה שלהם אחרי ליל הטל...!
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >הראשונה שפנתה ברפת את ראשה אליה היתה ה&#39;כרסנית&#39;. היא כאילו הכירה
        את בעלת הבית ונתנה לה תודה על שובה. בתה של זו גדלה אבל רזתה</span
      ><span class="c1"> </span
      ><span class="c3"
        >מאוד. פלורה מחפשת בצוארה ומוצאת לדאבון לבה שהקטנה מכוסה כלה קרצות. בלי
        בעלת בית </span
      ><span class="c2">- </span><span class="c3">חושבת פלורה בתלונה </span
      ><span class="c2">- </span
      ><span class="c3"
        >מי ירחץ את המסכנה, מי ישמֹר שהטפילים לא יאכלו את בשרה? פלורה נגשת לחלֹב
        את &#39;האדומה&#39; וספוק–נפשי גדול ממלא אותה, שוב יש לה כח להמציא בידיה
        את החלב למשפחתה - האין זה אושר? למחרת היא כבר כופפת על לִקוט מאכל
        לפרותיה. האדומה - זו הבחלנית מכל פרות רִפתהּ אינה רוצה כבר לטעום מעשבי
        השדה שהתחילו להתיבש וצריכים לבקש לה מטעמים בין הירקות. הגן מלא קִשות,
        שעבר עליהן עונת המחירים הטובים בשוק, ופלורה מחליטה לתקן מהן מאכל לפרות.
        היא ממלאה סלילה גדולה עם קשות, חותכת ושמה אותן בקדרות בתנור חם. כשהתרככו
        הוציאה אותן, קררה ונתנה לפנות ערב לפרות בתקוה שהזוללות תשבענה ותוספנה לה
        חלב כמו שהיא דורשת – תִגש לפרה, תמשש בכחלים והריהן כבדות ומלאות
        חלב...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >אבל הפעם תקותה לא נתקימה. חושמה – בת &#39;הכרסנית&#39; התפרצה מן הדיר
        בלילה וינקה חלב אמה. התנחמה פלורה רק כשגמרה לחלֹב את &#39;האדומה&#39;
        והיא מִלאה מחלבה גדולה. כאן היא מוצאת שבדיעבד מן היושר היה שהעגלה החלשה
        תינק פעם את כל חלב אמה, היניקה לרויה וחזקה מעט! הלא גם זו ילידת החצר -,
        ומדוע לא תהנה מן הברכה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >הובילה את הפרות ואת העגלים לבאר;  בדרך קפצה וכרכרה &#39;חושמה&#39;
        בשובבות גדולה, ממש כמו שהיתה נוהגת בשלהי החֹרף בעת שאכלה לשֹׂבע עשבים
        טריים במשך כל היום. בנחת רוח מתבוננת פלורה בשובבותה, נעצרה רגע נזכרת
        בילדיה וסרה מהר ללכת הביתה. כמעט במרוצה היא עוברת את הסמטא בין תותים
        גבוהים ועבותים, סמטא מליאה ריחות בושם של פרחי שִׁטה קטנים, סגלגלים.
        צִפור צהובה עם זנב ארוך בעל גון יפה מלקטת גרעינים מתוך זבל סוסים יבש
         תופסת אחד אחד בחרטומה הדק. פלורה נעצרה רגע ממרוצתה לבל הפריע מזונה של
        בריה קטנה ויפה זו. אבל פתאום התלחש רוח בין ענפי התותים, הצפור שמעה,
        הזדעזעה ופרחה מעבר לשטה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">)עוד יבא(</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c1"
        >---------------------------------------------------------------</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">)המשך מגליון הקודם(</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בחצר קִבלה את פני פלורה עדת יונים צחורות שירדו במעוף מן הלול והתחילו
        מסתובבות לרגליה בקשקוש תֹבעני; מִהרו ורצו לכאן גם התרנגולות ובראשן הזדקף
        הגבר היהיר, בעל כרבֹּלת אדומה כאש, וגם הוא קשקש בכנפיו הגבוהות. מן
        החלונות נשמעו קולות ילדים הקוראים – אמא, אמא! וכל פעוט בוכה. פלורה עולה
        במהירות על המדרגות וממלמלת במבוכה:&#39;רעבים רעבים כעת כֻּלם! יחכו
        אפרוחי עוד מעט קט!&#39; בערב היא יצאה עם ילדיה לקראת העדר. עמדה בפינת
        הסמטא ונמשכה אחרי מראה השמים היפים. הכִּפה נמוכה, נמוכה מאוד וכֻלה הוצתה
        אש הבוערת בין עננים קלושים, אפורים עם זרים תכולים–כהים. זולפים שם,
        זולפים גלי זהב בחשאי ומסתלקים לאיזו פִּנה רחוקה; רק האדמומית שבמערב עמדה
        והכתה עד שהתחיל המזרח לחשֹׁך. הפרות אֵחרו לשוב מן השדה והיא נכנסה להניק
        את הפעוט. בצאתה פגשה כבר את הפרות בחצר, וכשראתה את עטיניהם מלאים חלב
        קראה - הידד! קשקשה על גבם ותְַּכנם בשמות של חִבה. הציצה בעיניהם ונדמה לה
        כי הן מתפלאות על אהבתה אליהן, בריות עלובות כמותן</span
      ><span class="c2">! </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >עבר פרנסיס בסמטא ונכנס אל החצר. פלורה התאדמה ולא ידעה במה להתחיל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        > - אלה הן פרותיך, פלורה? שואל אותה פרנסיס אחרי דרישת השלום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">היא התעוררה שוב:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >-כן אדני! הוא רואה את &#39;אדומה&#39; זו? רק ארבע שנים נמלאו לה! יסתכל
        נא בגָדלהּ </span
      ><span class="c2">- </span
      ><span class="c5 c3">הלא פרה דמשקאית ממש תהיה! לא כן?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span
      ><span class="c5 c3"
        > אנכי הדיוט גמור בעניינים שכאלה, עונה פרנסיס ומקשיב בסקרנות להמשך
        שיחתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >- אלה העגלות כשיגִמלו אמכֹּר אותם ואכניס את הכסף לבנק! זו תהיה התחלת
        חסכון נדוניא בשביל שמחה תלמידתו! היא מהדקת את פעוטתה אל חיקה, מנֹפפת את
        תלתלי הזהב שלה, גוחנת ומנשקה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"> </span><span class="c2">- </span
      ><span class="c5 c3"
        >כלה נאה וחסודה, בואי נא אלי! קורא אותה פרנסיס ברמזו באצבע.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c3">***</span></p>
    <p class="c0" dir="rtl">
      <span class="c3">קשה לפלורה בעונה זו שאחרי הלידה </span
      ><span class="c2">– </span
      ><span class="c3"
        >הכביסה.  למרות רצונה היא מחֻיבת לקחת ערביה לעזרה. יש שנתקלת
        בתרפג&#39;דה הפטפטנית.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span
      ><span class="c5 c3"> יללה, יללה! - צריכה היא לזרז אותה כל היום. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >ותרפג&#39;דה, הבקיאה בכל מסתרי המושבה ובהויות העולם, ראשה היום
        בפוליטיקה. כי הימים ימי מלחמה במדינה.  חמש מאות מליונים הכה השולטן בבני
        עם נכר! מספרת היא בהתלהבות.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"> </span><span class="c2">- </span
      ><span class="c3">מאין את יודעת זאת?  </span><span class="c2">- </span
      ><span class="c5 c3">שואלת פלורה בגיחוך. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span
      ><span class="c5 c3"
        > הלא היתה שלשום תהלוכה גדולה בכל רחובות העיר! המונים המונים נהרו אחרי
        נושאי הדגלים שהכריזו בכל עבר ופנה: &#39;הכה השולטן בכופרי אללה, הכה
        ונצִח את חמש מאות מליונים שונאיו!&#39; עתונים הקריאו והריעו בחיל:
        &#39;יחי השולטן אל–כביר, יחי מושל המושלמים!&#39;</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span
      ><span class="c3"> מליוני כופרים! מליוני שונאים! </span
      ><span class="c2">- </span
      ><span class="c5 c3">חוזרת תרפ&#39;גדה בהתפעלות. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span><span class="c3"> כמה זה מליון בני אדם </span
      ><span class="c2">- </span><span class="c3 c5">את יודעת?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span
      ><span class="c5 c3"
        > מאין עלי לדעת, מֻעלימתי, כלום מלומדות אנו כמותכן?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"> </span><span class="c2">- </span
      ><span class="c5 c3">ואת ימי שנותיך את יודעת, תרפג&#39;דה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span
      ><span class="c3"
        > לא, גם זה לא למדוני עד היום! והיא סחה שכבר זקנה זקנה היא! עבדה במושבה
        בימי פקיד אחד כרסני וכעסני, ובימי גנן נוצרי, שהיה צועק ומכנה את היהודים,
        פועליו, במיני שמות של גנאי... במשך ימי כביסתה כאן התחלפו חוג&#39;ים
        גדולים וקטנים עד שנתרוקן בית הברון.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >פלורה אינה שומעת את פטפטנות תרפג&#39;דה. הכביסה לא יצאה נקיה והיא כועסת
        ומתרגזת וכמעט שבוכה מצער.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"> </span><span class="c2">-</span
      ><span class="c5 c3"
        >&#39;שלומיאל! לא תוכל בלי עזרת ערביה לעשות את מלאכתה?&#39;</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >גם יום של אפיתַ לחם קשה לפלורה וגורם לה צער - אצל השכנות האשכנזיות
        עולות ככרי הלחם כה גבוהות ויפות, רק אצלה שטחיות, נמוכות...! הלא הכל היא
        עושה בשביל שיעלה הלחם יפה, לא חסה על שום עמל, והרי, הרי לך פתות אלה!
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >היא כועסת וטוענת כלפי עצמה: שלומיאל לא יוצלח! - הלא להלקותה פשוט צריכים
        בשביל לחם שכזה שכל אוכלו יקלקל את קֵבתו! היא מחליטה כמעט לפרֹס לחם זה
        לעניים ולאפות אחר במקומו </span
      ><span class="c2">- </span
      ><span class="c5 c3">אבל כח, הכח מאין ימצא?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >ועוד בחטאים מסתגפת פלורה: לפעמים מרוב עבודה של לפנות ערב, שוכחת היא לתת
        אוכל לעופותיה. בזה נזכרת אך בהקיצה בלילה משנתה, ומוסר כליות תוקף אותה אז
        ואינו נותן להרדם - על חטא שחטאה כלפי פיות עלובות אלה לבה מתענה. או יש
        שלפנות ערב שוכחת היא לסגֹר את העגלים בדיר. באו הפרות, לא</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >נתנו חלבן בלי בניהן, שכל חִפוש לא הועיל למצֹא אותם. געו הפרות, געו כל
        הלילה ושנת פלורה נדדה - מי יודע אם לא חיה רעה אכלה את ילדיהן...! באשמתה
        אירע אסון זה!  בבֹּקר כשמוצאים את השובבים רוקדים וקופצים בין התירס הגבוה
        פלורה בוכה משמחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">)עוד יבא(</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">----------------------------------------</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">)המשך מגליון הקודם(</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >ופעם היתה אבדה באמת, אבל לא באשמתה. מתה אצלם פרדה ששויה היה עשרים
        נפוליונים! גדול היה צערה אבל השתדלה להבליג עליו בשביל להרגיע את רוחו של
        פריסיאדו אשר התאבל מאוד על פרדתו הלבנה החזקה, ששִׁמשה חברה טובה במשך חמש
        שנים רצופות. הוא הולך כמטֹרף:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">-</span
      ><span class="c3"> &#39;מה לעשות, מה לעשות? בלי פרדה </span
      ><span class="c2">- </span><span class="c5 c3">מה נעשה?&#39;</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">- </span><span class="c3"> כפרה בשביל ילדנו! -</span
      ><span class="c2"> </span
      ><span class="c5 c3">נהיה נא בריאים אז תעזֹר לנו ידנו לקנות אחרת! </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c6"> </span><span class="c2">- </span
      ><span class="c5 c3">עונה פלורה ומתאמצת להראות פנים צוחקות בדברה. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >והלב שלה כואב ודואב והיא לא חודלת להרהר בפרטי המקרה: כשהעירה אותה הפרדה
        שלשום בדפיקות משונות בכֹתל האֻרוהָ מיד הגיד לה לבה רעה... שני ימים טִפלו </span
      ><span class="c2">- </span
      ><span class="c3"
        >שני ימים ושני לילות ושום תרופה לא הועילה! מה היה לה? – נחש, עקרב, או
        איזה חיה אחרת נשכתה? מסכנה! - כמה התלבטה ביסוריה, כמה מבקשת-הרחמים
        השתקפו בעיניה הנוגות... להצילה, להצילה הן בִּקשו... עם אור הבקר הזה
        כשדפק עבדללה בתריס הקיטון היא הבינה הכל, </span
      ><span class="c6"> </span
      ><span class="c5 c3"
        >הזדיְנה במרץ ובסבלנות לחזק את לבו של פריסיאדו, אבל אחרי שנתאסף המון
        גדול סביב לנבלת הפרדה היא לא שלטה ברוחה:
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >&#39;מה מסתכלים כאן הסקרנים - הלשמֹח לאידהּ באו?&#39; - במילים אלה
        פִּזרה את ההמון ותִשאר עומדת כאבלה. הלב התרוקן בקרבה וכל היום לא נגִשה
        לעבודה - למה תעמֹל, תעבֹד, תקמץ ותחסֹך אם השטן יכֹל לקפוץ ובין רגע
        להשחית קִמוץ של שנים רבות...?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אחרי שבועיים גלִה לה פריסיאדו שהוא רוצה לפנות אל הפקידות ולבקש הלואה
        לקניית סוס. התרגזה פלורה והתלהבה: טובות, רחמים, השתדלויות...? לא, אינה
        רוצה בזה...! כשם שתדיר עזרה להם ידם, תעזֹר גם עתה! התרפסות לפני הפקידות?
        –</span
      ><span class="c2"> </span><span class="c3">חלילה, חלילה!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >ולא רק במקרה זה, אלא בהרבה מפרטי החיים לא עמדה פלורה עם בעלה בדעה אחת.
        שונים היו הם בתכונותיהם! הוא אדם שמאמין בעצמו הרבה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >כל מה שהוא עושה טוב וישר בעֵיניו. מאמין בהצלחתו, בכשרונו, באפשרותו
        לעשות חַיִיל. והיא </span
      ><span class="c2">- </span
      ><span class="c5 c3">מתענה ומסתגפת על איזה מיני חובות שונים ומשונים</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">שע"פ דעתה לא מִלאה באמונה. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >הוא מאושר ושבע רצון: ההֹוה שלי הריהו הרבה יותר טוב מהעבר </span
      ><span class="c2">– </span><span class="c3">מורגל הוא להתפאר </span
      ><span class="c2">– </span
      ><span class="c5 c3"
        >והעתיד? - כלום אינו מאיר פנים כשאדמה נפלאה כזו יש לו? הריה העידית
        שבעידית שבכל המושבה!
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >פלורה אינה מרגישה באֹשר זה. היא שקועה בעמלה, צער גדול בניה. מחלות שבאות
        עליהם לפרקים מדכאות אותה. לזה צומחות שנים, לזה כאב עינים, אחד קִבל דלקת
        במעים. יש שימים ולילות ושבועות אינה פושטת את בגדיה, עד שמצילה את ילדה
        ברוב טִפוחה והבנתה.  </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">ויש שפלורה נקראת ליפו לעזרה. -</span
      ><span class="c2"> </span><span class="c3">אחותה עם מחלותיה</span
      ><span class="c2"> </span
      ><span class="c5 c3"
        >ואביה זקן וחלש והיא מטפלת בכֻלם, בעת שהיא בעצמה עיפה ויגעה ומלאה חששים:
        לילד זה לא נתנה ארֻחה די מבריאה, לזה לא הספיקה להכין בגדים נקיים, זו
        הסתלקה יחפה לבית הספר ותתקרר...! ופריסיאדו? - לא שלחה לו די אֹכל והוא
        ירעב בשדה כל היום!
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">ויש שהיא מרגישה בדידות נוראה </span
      ><span class="c2">- </span
      ><span class="c5 c3"
        >לבדה היא כאן, לבדה! כלם זרים ומתרחקים ממנה. רק גברת שטיין לבד מכל
        האשכנזיות מתעניינת בה ומבקרת</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אותה. אבל גם בביתה של זו יש לפעמים שבדמיון רודפת אותה המִלה הנוראה </span
      ><span class="c2">– </span
      ><span class="c3"
        >פרנקיניה! ועוד איזו הברה שהיא לא יכולה בשום אופן לעמֹד על אופיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >לאט לאט קפצה עליה הזקנה - קמט עור פניה, נשרו שערות הזהב והיזמה הלבנה עם
        זגוגיות צהבהבות אינה מכסה יותר את יער תלתליה היפים. רק מבט עיניה
        האלכסוני, מבט מיוחד, חמים וטוב </span
      ><span class="c2">- </span
      ><span class="c5 c3">מזכיר לה את פלורה של העבר. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >לפעמים פלורה נחלשת כל כך עד שמאבדת את שִׁווי המשקל בהנהלת הבית. היא
        מתחילה להתרעם על פריסיאדו למה שאינו יודע לדבר עם הילדים בעברית כחפצה,
        למה אינו יודע לחנך אותם, להיות מסור להם ולה...? יש שנדמה לה כי היא שונאת
        אותו ואז מתעוררת שאלה בנפשה </span
      ><span class="c2">- </span
      ><span class="c3"
        >כיצד נקשרה לאדם שהוא זר ומוזר לה? ואחרי כן היא מתחרטת על הרהורי
        החטא</span
      ><span class="c6"> </span
      ><span class="c5 c3"
        >ומתמלאה רחמים לפריסיאדו: - למה נפלה אִשה רעה כמותה בחלקו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">(סוף יבא)</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >------------------------------------------------------</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3">(סוף מגליון...)</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >ובחיי פלורה לא רבי בכל זאת ניצוצי האורה ובאות תקופות של התרוממות הרוח.
        יוצא, למשל, פריסיאדו עם נץ החמה מביתו, תולה עיני</span
      ><span class="c6">ו</span
      ><span class="c5 c3"
        > בקסמי המזרח וקורא את פלורה לבֹא מהר לראות את הרי יהודה ביפָיםָ. היא
        נגשת אל החלון, רואה את שלוליות הזהב על פני שלשלת ההרים ואת מעטה התכלת,
        וקריאה של שמחה מתפרצת מפיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >שקיעה. היא נושאת את נסים הכרסני לקראת אבא. בשמי המערב נערך אז משחק
        הצבעים; מזהיבות החולות ואחריהן התגלה סרט תכול  </span
      ><span class="c2">-</span
      ><span class="c5 c3"
        >פס הים! נפשה מתמלאת עדינות רבה ונעימה. בא ספורטו. היא אוחזת ברסן חמורו,
        מרכיבה את נסים בין ברכיו ומראה לו על חלקת הים שלא ראו מכאן אף פעם.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >הם נכנסים אל הגִנה, גנת ירק הסמוכה לחצר. הכל מלבב כאן. כל תלם ותלם חן
        מיחֻד לו, יופי מיחד. בפנים נוהרים סחה לו פלורה כמה ירק שהוציאה</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >ומכרה היום מן הגן. כלם רוצים בסלק שלה, בפולים וחִמצא  שלה, -</span
      ><span class="c6"> </span><span class="c2"> </span
      ><span class="c3">שוטים שבעולם מדוע אינם זורעים בגנותיהם</span
      ><span class="c5 c2">?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >גדל הילד, נותן לה קצת חֻפשה והיא מתחילה לחפש מזון רוחני. לגברת שטיין
        הרבה ספרים עברים. פלורה שולחת אליה את בתה שמחה. </span
      ><span class="c2">– </span
      ><span class="c5 c3"
        >תבקשי מאת הגברת ספור ארוך ארוך ויפה בשביל אמך! ואל נא תשכחי חביבתי לומר
        לגברת: חן חן!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >עם הרעיון המר שפריסיאדו אינו יודע עברית והיא היא היחידה שמדברת עם ילדיה
        בשפתה האהובה התרגלה פלורה; אבל לא יכלה להתרגל עם העובדה המעציבה שגם את
        הקריאה בספריה הוא אינו יודע! צער זה קשה לה לכבֹּש והיא מבקשת להשכיחו
        בקריאה. אבל כל קריאתה היא בשבת. יום זה, שאף על פי שאינו כֻּלו מנוחה לאם
        של שלושה ילדים, בכל זאת רגעי פנאי נמצאים.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5 c3"
        >יש שהיא מתכוננת לקריאה עוד סמוך לכניסת השבת. הלך פריסיאדו עם מורנו לבית
        התפלה. היא יוצאת עם פעוטה מעבר לחצרה ומתישבת על גבעת הקיר.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >למטה ברחוב שוחקת ילדתה עם ילדי האשכנזים. היא רוצה לפתֹח את ספרה אבל לבה
        נמשך לסביבה היפה עם רוח של שבת השרויה בכל פנותיה.  השמש באה. על האֹפק
        הכחלילי פשטו נהרות של זהב. הרי יהודה נראים כה קרובים קרובים, דומה שסמוך
        לגבול הם משתרעים. פלורה לא יכלה להסיר עיניה מהם </span
      ><span class="c2">- </span
      ><span class="c3"
        >איזה קו בודד על אמיר אחד ההרים משך אותה. קו זה זז, התחיל להתעבות,
        להתקפל, עד שנהפך לאט לאט לחלק של כדור מואר אשר זחל לו בחשאי מעבר להר,
        התרומם והצטיֵר בדמות כדור גדול, יהיר, לוהט, שהסתכל בזחיחות הדעת בעולמו
        של הקב"ה. לרגע הסתתר הכדור בין מפלשי העבים,  התלחש במרומים וזנק בפנים
        מוארים שבעתיים. הואר אז גם גבול המושבה ואחריו גם כרמי זיתים ושקדים והככר
        הרחב המשתרע לפניהם. בקרבת פלורה הצלילה</span
      ><span class="c1"> </span
      ><span class="c3"
        >חֹרשת האקליפטוסים עם השֹמריה  שבתוכה. בפתח התלחשו שניהם ופתאם נשמעו
        צלצולי שירה דקים, מלאים געגועי אהבה </span
      ><span class="c2">- </span><span class="c5 c3">העלמה שרה שירת לבבה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >מראש הגבעה מימין, מתוך החלונות הגבוהים של בית התפלה נשמעה שירה אחרת שגם
        היא מלאה נימי אהבה. "לכו נרננה לד&#39;. בואי כלה, בואי כלה...!" ובתוך
        בית קטן ונמוך בשפלה מתפללים התימנים; קולם עצוב, חד גוני ואף הוא פורט על
        מיתרי לבה של פלורה וממלאהּ רטט קדוש. ספרה מונח על הקיר והיא שכחה אותו,
         כחה גם את תינוקה ואת העולם כלו. נפשה טסה באיזה עולמות יפים, מקסימים
         </span
      ><span class="c5 c2">- -</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בליל שבת זו חלמה פלורה: היא נכנסה ירושלימה, הבנויה בין רוכסי הריה
        ותוהה: ירדו מהר הזיתים הקברים מהאי גיסא וירדו הצלבים מאידך גיסא, וכל ההר
        משתטח כגן אחד לפניה: זיתים ושקדים, זיתים ושקדים! מעל הר הבית נעלם המסגד
        ובמקומו בנוי בנין אחר לתפארה. המונים המונים מבני ישראל נוהרים אל שער
        הזהב גם היא עולה בהר ד&#39;, רוצה לגשת אל חצר המקדש המתנוצץ בזוהר פז
        ואינה יכולה כי ההמון הרב יורט את</span
      ><span class="c1"> </span
      ><span class="c3"
        >הדרך בפניה. פתאום היא רואה כי בשער נכנסו שני אכרים, אשכולות ענקיים,
        אשכולות ענבים הם נושאים על כלונס מוזהב ונדחקים, ונדחקים -</span
      ><span class="c2"> </span><span class="c3">פנו דרך לעובדי האדמה! -</span
      ><span class="c2"> </span
      ><span class="c3"
        >נשמע קול ברמה. והאכרים עם זרי ענפי זית סביב לתרבושיהם פוסעים בקומה
        זקופה קדימה. היא נדחפת אחריהם עד שמגיעה יחד אתם עד למדרגות השיש עם
        העמודים,  המחוטבים כרובים נפלאים. כאן הופיע פתאם על כסא פלאי נורא הוד,
        עטוף בחלוק משי מרֻקם שוהם ויהלום. היא הזדעזעה </span
      ><span class="c2">- </span><span class="c3">פנים מידֻעים פני סבא</span
      ><span class="c6"> </span
      ><span class="c3">עוזיאל...! האמנם, האמנם...? וזהו </span
      ><span class="c2">- </span
      ><span class="c5 c3"
        >משיח...? רוצה להתפרץ בצעקה והנשימה נעצרה ותקיץ מתוך רתת.</span
      >
    </p>
    <p class="c10 text-left" dir="rtl">
      <span class="c5 c3">בת צבי</span>
    </p>
  </body>`;

export default page;
