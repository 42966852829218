const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c14 doc-content">
    <p class="c2 italic" dir="rtl">
      <span class="c4 c3">זהו מאמר שכתבה נחמה פוחצ&#39;בסקי בתגובה ל</span>
      <a href="/herArticles/hiddenQuestion"><span class="c4 c3 c13">מאמרו של יצחק אפשטיין ׳שאלה נעלמה׳</span></a>
      <span class="c4 c3"> שפורסם ב</span
      ><span class="c4 c6 c3 bold">השִלֹחַ</span
      ><span class="c5 c4 c3"
        > ב-1907 (כרך י"ז, חוברת ג&#39;, עמ&#39; 193-206).
      </span>
    </p>
    <p class="c2 italic" dir="rtl">
      <span class="c4 c3"
        >זה היה ללא ספק אחד הויכוחים הפומביים הראשונים בנושא היחס בין הציונות
        לבין ערביי הארץ, והוא עורר </span
      ><span class="c4 c3 c16 modal-button" id="modal-button-1">עניין רב</span><span class="c4 c3">. </span
      >
    </p>
    <p class="c2 italic" dir="rtl">
      <span class="c3 c4">בין שתי המשפחות אפשטיין ופוחצ&#39;בסקי היתה</span>
      <a href="/letters/fromYizhakEpshtein"><span class="c4 c3 c8 tooltip">ידידות רבת שנים<span class="c6 c3 tooltiptext">ראו מכתבו של אפשטיין לנ. פוחצ&#39;בסקי</span></span></a><span class="c3"
      >.</span>
    </p>
    <p class="c2  italic" dir="rtl" id="h.gjdgxs">
      <span class="c4 c3">מאמר התגובה של נ. פוחצ&#39;בסקי פורסם בעתון </span
      ><span class="c4 c6 c3">השִלֹחַ</span
      ><span class="c4 c3 c5"
        >, בתחילת 1908, (כרך י"ח שבט-תמוז תרס"ח, חוברות ק"ג – ק"ח עמודים
        67-69)</span
      >
    </p>
    <p class="c2 c7" dir="rtl"><span class="c5 c4 c10"></span></p>
    <h3 class="c12" dir="rtl">
      <span class="c5 c10 c11">שאלות גלויות</span>
    </h3>
    <p class="c12" dir="rtl">
      <span>(</span><span class="c1">לבעל המאמר "שאלה נעלמה").</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במאמרו של מר י. אפשטיין, "שאלה נעלמה" משמשים אור וחושך בערבוביה. יש בו
        מדה מרובה של אנושיות ושל מוסריות נעלה, אבל אין הוא בנוי על אותן ההשקפות,
        שהן פרי ההגיון המעשי, שאומה שבה לתחיה מצטרכת לו כדי שתוכל לרכוש לה את
        אדמתה ולהתקיים על פני האדמה הזאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מר אפשטיין מזהיר אותנו, שלא נגזול נחלת זרים. אבל הלא זולת קנית מטֻלה,
        שבה אשמה הפקידות בלבד, לא נעשו שום קניות נגד היושר והצדק למרות רוח
        הפלחים. מר אפשטיין מצדד גם בזכֻיות החוכרים; אבל, אם יש לשים לב לזכֻיות,
        שהיו למי שיהיה לפנים, הלא זכֻיותנו קודמות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >הלאה מתאר מר אפשטיין את מצבו האומלל של הערבי, ומדבריו יש להוציא, שאנו
        אשמים בצרתו, - כאילו אנו גזלנו, חמסנו, את המסכן הזה. בעוד שגם מר אפשטיין
        יודע, שהממשלה היא היא הפושטת את עורו של הערבי, דבר זה הרי לא יוכל להכחיש
        שום אדם. הכפרים הערביים נתחזקו, נתגדלו; הפלח מחליף את צריפו בבית-אבנים
        מכוסה רעפים, אחרי שהוא מרויח הרבה במושבה היהודית, שהיא סמוכה לכפרו,
        העירונים תושבי-הארץ נתעשרו הודות לרוח-החיים, שהביא היהודי במסחרם; ובעלי
        המלאכה משלהם נהנו גם הם הנאה מרובה מישוב-היהודים. ואחרי כל אלה עוד יש
        להוכיח אותנו, </span
      ><span class="c6 c3">שאנו</span
      ><span class="c1"
        > מנצלים את הערבי, ולאיים עלינו, שסוף סוף נעורר את אגרופו הקשה נגדנו
        בהנהגתנו הרעה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פחד אגרופו לעת-עתה עדיין הוא רחוק מאתנו, ואם יבא יום, שתתעורר גם בהערבי
        החיה הרעה ביחוסו אלינו, אז לא אופן רכישת-האדמה שלנו תהיה סבת הדבר, אלא
        השנאה הנצחית לעם שגלה מעל אדמתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">מר אפשטיין מזהיר אותנו, שלא נתגרה </span
      ><span class="c6 c3">בלביא נרדם</span
      ><span class="c1"
        > – איה התגרותנו? מי יעיד בנו, שאנו מתגרים ברעה? הלא גם הפלח וגם הממשלה
        אינם מכחישים את התועלת, שהביא לארץ ישוב היהודים. והפלח מכבד בלבו את
        היהודי, העולה עליו במעלות-ההשכלה. וזקני הפלחים מאמינים באיזו מסורת עברית
        עתיקה, האומרת, שהיהודים סוף סוף ישובו לארצם ויהיו אדוניה כקדם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >יפה אמר מר אפשטיין, כי לקנות את אהבת הערביים קשה מאד; אבל אם כן למה
        נעמול חנם? אין אוהבים לישראל, כל מה שאנו מתרפסים יותר, שונאים אותנו
        יותר. כל מה שאנו מוסיפים להכנע, להשתעבד, מכבידים את עולנו, מוסיפים
        ללחצנו, כמה בקש ישראל למצוא חן, למשל, בעיני העם הרוסי ולקנות את אהבתו?
        את מבחר בנינו הוצאנו לטבח למען חרותו של עם-הארץ, וכמה שלמו לנו? ושלמה
        ביד נדיבה לא רק הממשלה; שלם גם האכר, ואפילו הפועל... על כן נעזבה-נא
        דרך-כסל זה ונלך במסלות ישרות אל תחיתנו: נתחיל לדאוג </span
      ><span class="c6 c3">לנו לעצמנו</span
      ><span class="c1"> לקיומנו ולאשרנו!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >את הפרק השני של מאמרו מתחיל מר אפשטיין עוד הפעם באזהרות ע"ד
        רכישת-הקרקעות, ובו הוא מציע תנאי-רכישה, שנוטלות מאתנו כל תקוה להרחיב את
        הישוב באופן מתאים לכחותינו. מר אפשטיין דורש לקניה רק את הקרקעות שעוד לא
        החזיקו בהם הערביים ושעוד לא עבדו אותם; אבל הקרקעות האלה – דבר זה הלא
        ידוע הוא לכל – לא יצלחו לישוב מפני צחיחותם, או מפני אקלימם. הוא מראה על
        עמקי ארץ-ישראל, על שמיה ושמשה; אבל כלום לא הרגיש בעצמו, עד כמה רחוקים
        דברים כאלה מלהעיר בנו אף צל של תקוה? איך נחדור בכחותינו הדלים ל</span
      ><span class="c4 c3">עמקי</span
      ><span class="c1"
        > האדמה, אם אין לנו זכות לרכוש מה שעל פני האדמה? והשמים עם השמש הם
        עוזרים גדולים לאדם, העובד אדמה פחות או-יותר טובה; אבל אם נאמר להפוך
        צחיח-סלע לארץ פוריה, אז יצחקו לנו שני הכחות הכבירים האלה. ואם בעזרת המדע
        אפשר הדבר להפוך צחיח-סלע לשדות-תבואה וכרמי-חמד, היכן הם המיליארדים
        הדרושים לזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >הלאה בונה מר אפשטיין שיטה שלמה של "וגר זאב (או לביא, כמו שקרא קודם
        לעם-הארץ) עם כבש" אבל גם פה הוא מתרומם מעל לחיי-המציאות ומשחק לו
        גבולי-האפשרויות. הוא אומר שאנו צריכים ללמד את הערבי עבודת-אדמה מתוקנת,
        לשכלל את אחוזתו, לפתוח לבניו בתי-ספר, לעשותם לבעלי-תרבות; וקודם שאשאל,
        מאין נקח את האמצעים המרובים הדרושים לזה, אשאל, אם למדנו כבר </span
      ><span class="c6 c3">אנו בעצמנו</span
      ><span class="c3"> לעבוד את האדמה כראוי, אם יודעים </span
      ><span class="c4 c3">אנו</span
      ><span class="c3"
        > כבר לשכלל את האחוזות שלנו? אם יסדנו כבר לבנינו שלנו די בתי-ספר טובים?
        הלא עזובה אצלנו בכל פנה שאנו פונים ואיך יכול אדם מישראל לחייב אותנו עתה,
        שנדאג להתפתחותו החמרית והרוחנית של הערבי? ואם הדברים מכוונים ,לעתיד
        לבא&#39;, הרי צריך היה להראות </span
      ><span class="c6 c3">קודם</span
      ><span class="c3"> את הדרך להתפתחותנו </span
      ><span class="c6 c3">שלנו</span><span class="c1">.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >מר אפשטיין אומר, שעם-ישראל השב לתחיה צריך להיות אח לכל עם, שהוא מתעורר
        לחיים, ועל כן – גם וביחוד להעם הערבי; ושמח מר אפשטיין שאמר קודם לכן, כי
        העם הערבי לא חדל לחיות אף רגע ואינו טעון תחיה. ואם הדבר האחרון אמת, כיצד
        יכולים אנו להתאחד עם הערביים? אנחנו, עם שנמח  מעל אדמתו, מורדף ושנאוי
        בכל מקום, שואפים לשוב את אדמת אבותינו ולכונן בה מקלט בטוח; והם חיים על
        האדמה הזאת באין מפריע. </span
      ><span class="c4 c3">ולמה להם</span
      ><span class="c1"
        > איפוא, האחוה שלנו, ולמה הם הרחמים שמר אפשטיין משתדל לעורר בלבנו אליהם?
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"מה שאנחנו יכולים לתת לערבים, לא יוכלו לקבל מכל עם אחר, אבינו שבשמים!
        מפני-מה צריכים אנו תמיד רק לתת ולתת? לזה – הנפש, לזה – הגוף – ולאלה –
        שאריות התקוה לחיות בתור עם בן-חורין על אדמתו ההיסטורית!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">ומתוך אידיאליות </span><span class="c6 c3">יתרה</span
      ><span class="c1"
        > שכח מר אפשטיין את התורה הברורה שהורנו הנסיון. הוא מחליט, שאם ניטיב את
        מצבו של הערבי, נרים אותו משפל-מדרגתו ונמציא לו תנאי-עבודה טובים, ימכור
        לנו את אדמתו או יתן לנו נחלה בתוכו. אבל כלום אין הוא יודע כמונו, שהערבי
        מוכר את אדמתו רק כשהממשלה מכבידה עליו את עול המסים עד שלא יוכל עוד שאת
        אותם, או כשהאפנדי פשט את כל עורו מעליו? –, ובאמת אין לו עכשיו מה להפסיד
        במכירת-אדמתו, עבודתו היא כל-כך פרימיטיבית, כל כך גרועה, עד שאינה מכניסה
        לו כלום, ולולא המושבות היהודיות, שנותנות לו עבודה ועל-ידיה – אפשרות
        להתקיים, אז בלי ספק היו חרבים הרבה כפרים בחצי יובל השנים האחרון. לא כן
        אם נברא בשביל הערבי תנאי-קיום טובים. אז לא ימכור את אדמתו בעד כל הון.
        אנו צריכים, איפוא להיטיב את מצבו של הערבי כדי שלא תהא לנו אפשרות לרכוש
        את אדמת אבותינו עולמית...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אבל, אפילו אם היינו נעשים "אלטרואיסטים" קיצוניים – כלום יש לנו די
        אמצעים חמריים כדי למלא אחרי תביעותיו של מר אפשטיין? כי הוא דורש, שנתקן
        את הערביים בכל תקוני התרבות החדשה: במוסדות צבוריים, בבתי-ספר, בבתי-מאכל
        בזול, בקופות של חסכון, בתיאטרון, בנשפי-זמרה ועוד ועוד. תכנית יפה! אבל –
        כלום דאגנו כבר להקים </span
      ><span class="c4 c3">לנו</span
      ><span class="c3"> את כל אלה כדי שנוכל לזכות בהם אחרים? עוד אין </span
      ><span class="c4 c3">לנו כלום</span
      ><span class="c1"
        > – וכבר נמצאים בנו חולמים על שפע-ברכה, שנשפיע על אחרים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">ביחוד מעציב הדבר לשמוע כזאת ביחס אל </span
      ><span class="c3 c6">בתי-הספר</span
      ><span class="c3"
        >. מר אפשטיין אומר, שאילו היינו מבינים את ערך ההשכלה בארצנו, היינו
        יכולים להתחרות בהשפעת-השכלה על הערביים עם הצרפתים והאנגלו-אמיריקנים,
        שפתחו בתי-ספר עליונים בשביל עם-הארץ הפלישתיני והסורי. כן, עם, שאינו יכול
        לסדר את בתי-הספר הנמוכים </span
      ><span class="c6 c3">שלו</span><span class="c4 c3"> </span
      ><span class="c1"
        >כראוי, אומרים לו להתחרות ביסוד אוניברסיטאות בשביל אחרים! וכזאת אומר
        מורה מובהק, שהקדיש את מבחר ימיו לילדי ישראל ויודע בצערו של החנוך
        הישראלי!...</span
      >
    </p>
    <p class="c18 text-left" dir="rtl">
      <span class="c5 c11 c17">.ראשון לציון</span>
    </p>
    <p class="c2 c19 text-left" dir="rtl">
      <span class="c1">נחמה פוחצ&#39;בסקי</span>
    </p>    
    <br/>
    <p class="c2 italic" dir="rtl">
      <span>ראו גם</span><span class="c13"> </span
      ><span>מאמרה של דליה גרפי - על הפכחון </span><span class="c13"> </span
      ><span>(הסתדרות המורים גליון מס&#39; 49 פברואר 2010)</span>
    </p>

  </body>
</html>`;

export default page;
