const page = `<body class="c5 doc-content">
  <p class="c2" dir="rtl">
    <span class="c9 c4">מילים אלו נכתבו על גבי גלויה ונשלחו למען:</span>
  </p>
  <p class="c2" dir="rtl"><span class="c0">Mr Lewin Epstein</span></p>
  <p class="c2" dir="rtl"><span class="c0">Carmel Win Comp.</span></p>
  <p class="c2" dir="rtl">
    <span>309-311 E.22</span><span class="c3">nd</span
    ><span class="c0"> Street</span>
  </p>
  <p class="c2" dir="rtl"><span class="c0">New-York</span></p>
  <p class="c2" dir="rtl"><span class="c0">America</span></p>
  <p class="c2" dir="rtl" id="h.gjdgxs">
    <span class="c4 c10 modal-button" id="modal-button-1">הגלויה</span
    ><span class="c4"> </span
    ><span class="c9 c4">נמצאת בארכיון לוין אפשטיין בבית הספרים הלאומי</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">ראשון לציון י&#39; תמוז התרע"ב </span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">למר לוין-אפשטין שלום וברכה מציון. </span>
  </p>
  <p class="c2" dir="rtl">
    <span
      >מכתבו מערב חג השבועות הגיעני. זולת אותה המלה הריקה של ארבע אותיות "תודה"
      – איני יכולה לתת לו כלום בשביל התענינותו במכירת קבצי. האמת נתנה להאמר שאני
      ביחד עם ספרי איני כדאית לשימת לב שכזו </span
    ><span class="c4">[?]</span
    ><span class="c0"> מצדו. כאן רק זכות ארץ האבות שעומדת לי לסיעה. _  _ </span>
  </p>
  <p class="c2 c6" dir="rtl">
    <span
      >מכתבו ב"האור" ותשובתה של הגברת בן-יהודה היו לשיחה אצלנו במשק אי–אלו ימים.
      התעוררה השאלה מי הוא וגנר? כן, ישנם אצלנו טפוסים אי מפתחים ששמות האמנים
      הגדולים טרם ידועים להם ועל כתיב זה שברו את שניהם וקראוהו בוריציות שונות
      וככה היה מכתבו ותשובתה של הגברת חמדה לפתיחה בשעור של מוסיקה ומקצע זה של
      אמנות תופס מקום גדול וחשוב מאד בזמן האחרון בחיינו. בית הספר של מוסיקה ביפו
      הריהו דולה פנינים ממש מתוך אפלת החיים. שם עולים ומבצבצים ככבים עד אין
      מספר. גם מושבתנו, עם כל גדוליה ועשיריה, שחה עם הזרם. לימוד כנור או פסנתר
      נשמע בכל בית, ומספר המכושרות הולכות ומתרבות מיום ליום. באיזה בית אינו נמצא
      כלי נגן זה? ויושבות בריות קטנות, פעֻטות, כל הימים לפרט על הדסתקים </span
    ><span class="c4">[??]</span
    ><span>. חורות הן ורזות וצנומות אבל עיניהן בוערות ותקוה </span
    ><span class="c4">[?]</span
    ><span class="c0"
      > נשקפת מהן להיות כבת פלוני ופלוני, שככבה כבר מהבהב שם בשמי מרום...  מ__
      דאברהם, - איזה עם חולה הננו....
    </span>
  </p>
  <p class="c8 text-end" dir="rtl">
    <span class="c0"
      >שלום לגברת החביבה, הטובה, העליזה. שלום לבניכם ונכדיכם</span
    >
  </p>
  <p class="c8 text-end" dir="rtl">
    <span class="c0">בברכת ציון   נחמה פוחצבסקי</span>
  </p>
  <br />
  <p class="c2 italic" dir="rtl">
    <span class="c4"
      >[הויכוח בין לוין-אפשטין לחמדה בן-יהודה היה בשאלה האם צריך או אסור לנגן את
      וגנר בארץ ישראל לאור שנאתו ליהודים ואישיותו השלילית מחד וגאוניות המוסיקה
      שלו מאידך. תשובתה של חמדה בן-יהודה פורסמה ב"</span
    ><span class="c4 c7 bold">האור</span><span class="c4">" 18.6.1912. ]</span>
  </p>
</body>`;

export default page;
