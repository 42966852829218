const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c4" dir="rtl">
      <span class="c0">חתום: נחמה בת צבי  </span
      ><span class="c7 bold">המבשר</span><span class="c0"> </span
      ><span class="c0 c3">(קושטא)</span
      ><span class="c0"
        > יד ו\או יח סיוון תר"ע [1910 21 או 25 ביוני,  שנה א גל&#39; כד עמ&#39;
        374-375 ]   </span
      ><span class="c1 italic">לא הצלחנו להשיג</span>
    </p>
    <p class="c6" dir="rtl"><span class="c5"></span></p>
  </body>
</html>`;

export default desc;
