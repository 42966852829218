const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c1 italic" dir="rtl">
      <span class="c5 c10 bold">בוסתנאי</span
      ><span class="c3"
        > כ"ז אב תרצ"ד (8.8.1934, שנה 6 גליון 17, עמ&#39; 15-13)</span
      >
    </p>    
    <h3 class="c6 text-center" dir="rtl" id="h.6zbfrpd1kpch">
      <span class="c11">"בכפר ובעבודה"</span>
    </h3>
    <br/>
    
    <p class="c1" dir="rtl">
      <span class="c0"
        >        לא נפניתי עד עכשיו לדבר שרציתי מאד לטפל בו פעם – לספרה של
        המנוחה נחמה פוחצ&#39;בסקה "בכפר ובעבודה". רציתי לעשות זאת בחייה, אך הספר
        הופיע בהוצאת "הדים", שכידוע הייתי שיך לה. לפרקים, מרוב איסטניסות, הננו
        מונעים נחת מבני-אדם. אך מה לעשות? טבענו הוא.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ועתה: הספר יצא מרשות "הדים" והאשה שכתבתו – מרשות החיים, ומותר
        לדבר. חבל: המנוחה רצתה כל-כך לשמוע דעות על ספרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c8"
        >        קראתיו זה לא כבר, בשבועות שלאחר מותה, שנית. וכדרך-קריאה, מתגלה
        לך אחר-זמן מה שנתכסה ממך קודם. לא אגיד שהכל נהיה גלוי לי, לא אגיד שהספר
        שקוף הוא בשבילי. לא. למרות הבהירות והפשטות שבספור ובהרצאת הדברים, בשביל
        הקורא הרחוק, הנה בשביל אלה שהכירו את הכותבת מקרוב פחות או יותר, יש הרבה
        מן המעורפל והסבוך. אך אחת ראיתי בספר: האשה שעבדה בכל-כך הרבה מרץ, שמלאה
        תקוה, שאהבה להבליט את האופטימיות שלה, אשה זו בעצם היתה בתוך-תוכה </span
      ><span class="c5 bold">עצובה</span
      ><span class="c0"> מאד, וקריאת ספרה ממלאה אותך עצבות כבדה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        לכאורה – התלהבות והתפעלות: אהבה לחיים, לבני-אדם, לטבע, לארץ,
        לאדמה ולעובד – אלא שאת כל זה מלוה צל כבד: אכזבות כבדות בכל. החיים
        אכזרים, ושוא עמל אדם והתאמצותו. וצל העצבות מלוה אותך בכל: במשפחה, בבית,
        במושבה ובקבוצה, בחיי-האכר ובעבודת הפועל. אין הבדל. עצוב מאד.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c0">---------</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        הסופרת-האכרה הוציאה פעם קובץ ספורים קטן, לפני שנים. לא עשה
        רושם. היה משהו ארץ-ישראלי של אז, מושבתי, בתוך כל זה, משהו משולהב בידים,
        ממותק, מותפעל. הטבע הארץ ישראלי הבריק בכל שלל צבעי-האקזוטיקה. ומעין זה
        היה השאר. עברנו עליו בשתיקה, וראוי היה לכך.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ופתאום קראתי ב"התקופה" את ספורה "בבדידות". הוא נגע עד לבבי,
        הרעידני. נפתחה לעיני תהום שהיתה מכוסה במסך. לא באמנות שבו נגע בי. האמנות
        לא היתה סגולתה של הכותבת. החרידתני האמת, הפשטות, ישרות הראיה והספור. ואף
        כי היה לך רושם כי המספרת מתהלכת על קרום קרח דק העומד להשבר ולשקוע, ואך
        החזיקה מעמד, נגלה לפני מתחת לצעיף שעל האנשים – לב פצוע. של מי? אל נא
        נשקיף יותר מדי לפנים. נהיה צנועים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        העירותי אז אחדים מידידי ומכרי הקרובים, סופרים וקוראים וגם
        קוראות, על ספור זה. וכשבאה לפנינו הסופרת בהצעה על הדפסת ספרה לא הפתענו
        לראות, כי גם יתר ספוריה, שאמנם חלשים הם מ"בבדידות", יש בהם משהו מאחד עם
        הלך הרוח הקשה והעצוב של ספור זה. הסופרת יצאה מתחום משפחת האכר, הרחיבה
        גבולותיה וקבלה גם את הקבוצה ומושב-העובדים לתוך חוג ראיתה. "בכפר
        ובעבודה". את העיר לא קבלה. זו עוברת מן הצד, דרך אגב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        היא למדה לראות. הטבע חדל מהיות אקזוטי. נעלמו הצבעים המופרזים
        הצעקניים. הארץ נהיתה מולדת, יום-יום, וטבעה – רגיל, פשוט, מובן. בלי
        התפעלות כמעט. הוא השתפך עם החיים והעבודה, התפשט את מחלצותיו ויהי בשבילי,
        הקורא בן-ליטא העניה, כטבעה של ליטה (ליטה במובן היהודי, גם זו שהיא עכשו
        בגבולות פולין). ודוקא תאורים פשוטים אלה, ראית יום-יום ולילה-לילה – קנו
        לבבי. כך הרגלנו אנו לראותה, אלה הרואים את הארץ יותר מחצי יובל שנים.
        המפליא, המיוחד, הלוהט, המשכר שבה חדל לענין. מניחים אנו אותו לתירים,
        לציָרים אקזוטיים ולצעקנים שעל הבמה. אנו רואים פשוט. המישור הקטן, הגבעה,
        הפרדס, הכרם, השדה, גנת-הירק – הנחל הרגיל, החול שעל שפת הים, רצועת ים
        מרחוק, שמים רגילים, בהירים או מועבים. אנו – ככל הגויים וארצנו – ככל
        הארצות. זוהי בעצם מולדת וזהו- עם. לא "אתה בחרתנו" ולא "אשמנו מכל עם", לא
        ארץ "הבחירה" ולא "ארץ אוכלת יושביה", כי אם עם בארץ ולא יותר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כשאנו נופלים לתוך המפליא רגע – ונהי בעינינו כתירים. וגם הסופרת
        האכרה לפרקים נפתעת. היא מוסרת אז את התפעלותה, אך מרגיש הנך כי זהו רגע
        יוצא מן הכלל או שגרת הלשון בעבר. השאר הם חיים, עבודה וראית יום-יום. וטוב
        כך, אף כי עצוב. הן העצבות היא החיים, לכל הפחות חלק גדול, עיקרי
        שלהם.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c0">---------</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        שני ספורים הם מסוג אחד. "בבדידות" – ספור האח ו"המשק" – ספור
        הגיס. ובשניהם הסבל האיום של האשה בעלת הנפש, הדבֵיקה בקרקע, בעבודה, בבית,
        בחצר ובמשק, האשה האוהבת, גזולת-האהבה, האשה המסוגלת ליצור גן-עדן ונפשות
        קרובים יוצרות לה גיהינם: האח, הגיס. כמה נשקפת הסופרת-האכרה עצמה מבעד לכל
        זה, מבעד המסך והאד, איך היא רואה, איך יודעת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ולאותו החוג שיך גם הספור: "המוצא". כל אלה הם ספורי המושבה,
        המשפחה, המשק, הבנין וההרס, העבודה, האהבה והקרבן (הה, כמה הבינה אשה זו את
        ערך הקרבן בבנין בישוב ובחיים!) בונים, והאיגואיזמום, האינסטינקטים הרעים
        והגורל האפל הורסים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כל אחד משלשת ספורים אלה נגמר באופן אחר: ברצון אידיאלי,
        במשאלת-לב שהסופרת-האכרה הפכתה בספר למציאות (בספר ורק בספר, כי בחיים עוד
        לא הפכו דברים כאלה למציאות). בספור "המוצא", בותור מתוך רוחב-לב וגודל-נפש
        ובברכות "שלום" ו"תודה" – ב"בדידות"; ורק ב"המשק" נגמר הענין לא בכי-טוב,
        ב"והתפרצה בבכי". חוששני כי בחיים הנוסחה האחרונה היא האמתית. מי יודע מה
        שיבוא אחרי ה"שלום" וה"תודה"? המחברת אינה מספרת זאת לנו. והסוף האידילי של
        "המוצא", מסירת המשק לקרן הקימת, ועבודת הבודד העזוב עם הקבוצה יחד – אילו
        גם היתה מתגשמת באמת, מי יאמר איך היתה נגמרת? ובחיים, כמו שאמרתי, הן זו
        לעת-עתה רק משאלת-לב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        הסופרת המנוחה נתנה ערך מיוחד ל"שרה זרחי", ספור האשה מן המושב
        הקטן. ל"בבדידות" אין זה מגיע, ואך יחסה הוא מובן. הרבה צער וכאב היה שם
        בסבוך המשפחתי של בני-אדם האוהבים זה את זה, ואך איזו אי-הבנה מפרידה
        וממררת את החיים. ופה שוב הגמר הוא ריאלי. גם שתי משאלות צנועות ומובנות של
        האשה לפני מותה – האחת אינה מתמלאת, והשניה – אומרת המספרת: "ימים יגידו",
        וכאילו מתוך אירוניה כבושה, ובעיקר מתוך הבנת-המציאות. המספרת האכרה אינה
        בעלת-הומור, היא אדם רציני מאד, כבדת-נפש, למרות שבחיים התאמצה להראות פנים
        צוחקות וקלות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        מוצא של ותור והשלמה, מציאותי ואמתי, נמצא לה רק ב"בצל הקבוצה".
        שם החברה מלאת הסבל ומדוכדכת הנפש משלימה עם הגורל, ההכרח והותור. "ותמרה
        ידעה, כי גורלה שזור לעולם בגורל האנשים הטובים והטהורים האלה ההולכים שחוח
        תחת נטל הדאגה, המתחדשת עליהם בכל יום תמיד".</span
      >
    </p>
    <p class="c4 text-center" dir="rtl">
      <span class="c0">---------</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        המספרת-האכרה, האשה, רואה ויודעת יותר מכל את סבל האשה, את ההכרח
        ואי-המוצא שבנפשה. האשה, אם לא באופן יוצא מן הכלל, אם רק אינה שטן הבא מן
        החוץ להרוס את הקן, היא הצודקת כמעט תמיד. ואין זה אומר כי המספרת אינה
        מכירה את הגבר, או כי מפריזה היא בלמדה עליו חובה. לא בכל מקום. במקום שיש
        קונפליקט בין שני גברים – האחד יוצא זכאי וכל האור שפוך עליו. כך הוא
        ב"המוצא". הגברים הם הרשעים רק ב"בבדידות" וב"המשק", האח והגיס, אף כי ביחס
        להאח משתתפים אהבה ורחמים. גם יתר הגברים, אפילו בקונפליקטים שלהם עם האשה
        – אינם רשעים. אפילו ברוך בעלה של שרה זרחי, זה שגורם לה באטומו ובאי-הבנתו
        יסורים קשים – הוא בעצם אדם נחמד. ובחורי הקבוצה הגורמים יסורי-נפש לתמרה
        ודאי שאינם אשמים, וגם לא הבחורות. גורל! מי שרוצה בך אין את רוצה בו, ובמי
        שאת רוצה לא תזכי, לבו נתון לאחרת. את הסבך שבחיי-הקבוצה, בחיי העבודה
        וכו&#39; ואת עומק הסבוכים בחיי-המשפחה שבה – אין המספרת רואה. הקבוצה
        נשארה לה, בחלקה הגדול, למרות כל הפשטות והיום-יומיות שבראיתה ותאורה –
        ענין אקזוטי (אפשר שהיה פה גם טקט נפשי של אורחת). את המושב הקטן תפסה
        יותר. סוף-סוף יותר קרוב למושבה, חיי כל משפחה בביתה ומשק מיוחד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c8">ועוד: המספרת רואה את כל נפשות הנשים </span
      ><span class="c5 bold">בעיניה</span><span class="c8">, והן כולן </span
      ><span class="c5 bold">היא</span
      ><span class="c8"
        >, כמעט. ולא רק הנשים והצעירות שבמושבה, אפילו תמרה בקבוצה כמוה היא.
        מחשבתן על הארץ, הגאולה, הציוניות, האשה, המשפחה והעבודה – הן אותן
        המחשבות. כולן קוראות את הספרים שקראה המספרת, ומתפלאות מאותם הספרים
        ומאותם המקומות; כולן בוכות על אי-אחדות האומה, רובן באות בטענות לשלטון
        הזר. את התפיסה המיוחדת של אי-האחדות באומה אשר לקבוצה ולמושב, וגם את
        ההבדלים שבין אלו – אין היא רואה. סובייקטיבית היא מאד, נתונה בעצמה. היא
        אוהבת ונכונה לעזור, אך קשה לה לצאת מקלפתה שלה. </span
      ><span class="c5 bold">אשה</span><span class="c0">.</span>
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c0">---------</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        המספרת-האכרה נשארה מחוץ לרשויותינו, לא סופרת ותיקה ולא צעירה.
        ואך לקריאה ראויה היא. ללמוד ממנ אפשר תמיד דבר-מה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כי, כפי שאמרתי, רואה היא את הדברים והאנשים ראיה מתוך ישרות-לב
        ואהבה, פעם ראיה עמוקה פחות ופעם יותר. ואם פה ושם היא נופלת לתוך שגרה
        ותפיסה מקובלת – אין אסון. בכל אופן ישנן פנות שהעמיקה ראות לתוכן, ויש
        שכואבת היא בכל עומק כאבה. היא גם כואבת את כאב האב שבנו עוזבו, ולכאב
        שב"בדידות" וב"המשק", ליסורי האשה האוהבת, הדבֵיקה במשפחה ובעבודה, אין
        מוצא וישע. וכדאי שהקורא שלנו יכיר את הכפר והעבודה גם דרך האספקלריא של
        אשה מספרת-עובדת זו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כי אוהבת אשה עובדת זו לא רק את בני-האדם, כי גם את הכפר והעבודה,
        את הבית ורהיטיו וכליו, את החצר, השדה, הכרם והגנה, ואת הרפת והלול; וכמה
        היא אוהבת את הכפר, את ימיו ולילותיו, את דרכיו ושביליו, את בעלי-החיים שלו
        וצמחיו. הכל ענין בשבילה, ואנשיה יצירי-רוחה אוהבים אותם כמוה, ואם אינם
        אוהבים היא כואבת ומצרה עליהם. כאומללים וגזולי חיים אנשים אלה בעיניה. מהו
        אדם שאינו אוהב? עִוֵר הוא וחרש, בעל מום ולקוי בנשמתו. כל הסבוכים
        והטרגדיות באות אצלה בעצם מחוסר אהבה ותשומת-לב. אינך שם לב לחיים ולחי,
        לצומח ואפילו לדומם, ואת נפשך חבלת. אין אהבה בנשמתך. ובזאת נכרת האשה
        הסופרת. הגבר חי יותר מדי לעצמו, שקוע בעצמו. האשה חיה בשביל אחרים, בשביל
        הבעל והילדים, האשה גם משרתת אחרים, ולכן תשומת לבה גדולה ורואה היא
        ושומעת. ואם האשה היא עוד גם פטריארכלית פחות או יותר, ואינה בת הרחוב
        והרעש, ואם זכתה עוד לכפר ולמשק – אז ודאי שרואה היא ויודעת; ומכיון שהיא
        נמצאת הרבה עם עצמה ועם סביבתה הצרה, חודרת היא ורואה וחיה את חיי המקיפים
        אותה ומדובבת גם את הדומם ולא רק את הצומח והחי. מה יודעים אנשי הרעש
        והמהומה הכרכית על כל אלה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בזמננו נהיתה הפרובינציאליות למלת גנאי. לא; אדרבא! הפרובינציה היקרה! מה
        יודע הכרך שלנו מן הבית  (מחוץ לקצת נוחיות), המשפחה, הטבע והיקום? מתי חי
        הוא עם עצמו, מתי מטפל הוא בנשמתו ובנשמת הקרובים אליו? לא כן איש העירה
        והכפר, איש הפנה. הסופרת האכרה אהבה את פנתה ומלאה אותה, כפי יכלתה. אל
        נדרוש ממנה יותר. אל נבוא בתביעות מן החוץ. נקבל את הניתן ונהנה ונתעצב יחד
        עמה באהבה-כל ובעצבות טובה.</span
      >
    </p>
    <p class="c1 c12" dir="rtl">
      <span class="c7 small-text">י"ז אב תרצ"ד.</span>
    </p>
  </body>
</html>`;

export default page;
