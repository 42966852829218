import { createReducer } from "@reduxjs/toolkit";
// import { addBook, deleteBook } from '../actions/booksActions';
import { setFromPage, setFromItem } from "../actions/fromActions";

const initialState = {
  fromPage: "",
  fromItem: "",
};

const fromReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFromPage, (state, action) => {
      console.log("fromReducer action", action);
      state.fromPage = action.payload;
      // console.log("fromReducer action.payload", action.payload);
      console.log("fromReducer state.fromPage", state.fromPage);
    })
    .addCase(setFromItem, (state, action) => {
      state.fromItem = action.payload;
    });
});

export default fromReducer;
