const page = `<body class="c7 doc-content">
  <!-- <h3 class="c6 text-center" dir="rtl" id="h.xbqnueugtin4">
    <span class="c13"
      >[קטע זה משוכתב ממסמך הנמצא בארכיון ראשון לציון. אנו מביאים גם צילום של
      המסמך המקורי]</span
    >
  </h3>
  <br/>
  -->
  <p class="c0 text-center underline" dir="rtl">
    <span class="c8">פרטי-כל    103 No.</span>
  </p>
  <p class="c0 text-center" dir="rtl">
    <span class="c3 c1"
      >אספה הכללית בבית העם במוצ"ש אור לטו' כסלו תר"פ   6/12/19</span
    >
  </p>
  <p class="c0 text-center" dir="rtl">
    <span class="c1 c3">על סדר היום: בחירת ועד המושבה.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >היו"ר מר רפפורט פותח את האספה ואומר שהדין והחשבון של השנה החולפת נמסר כבר
      לפני אספה כללית, ומטרת האספה הנוכחית היא בחירת ועד המושבה כפי שהחלט באספה
      הכללית במוצ"ש ז' כסלו ש"ז. הוא מודה, בשמו ובשם חבריו שעבדו אתו יחד בועד,
      על האמון שהקהל נתן בהם, ומבקש מהקהל שבבאו לבחור בועד חדש ידע לבחור באנשים
      שיוכלו למלא את תפקידם וכי יהיו ראוים ומוכשרים למשרתם. הוא קורא שלשה מכתבים
      שנתקבלו ביחס לבחירות. א-. מכתב מאגֻדת  הנשים מיום י"ב כסלו תר"פ בו היא
      מודיעה שבאספת הנשים נבחרו הגברות כהנסקי ופוחצ'בסקי למועמדות לבחירות הועד
      ומבקשת להכניס אותן ברשימת המועמדים שמהם יבחרו חברי הועד. ב-. מכתב התמנים
      הגרים במושבה – 67 במספר – בו הם מביעים את רצונם להכניס מס גֻלגלת בכדי
      שיוכלו להשתתף בבחירות ושיהיה להם בא-כח בועד. ג-. ועד החקלאים מיום י"ג כסלו
      ש"ז שהוא מעמיד את שבעת חברי הועד שלו למועמדים לבחירות הועד שמהם יבחרו 5
      לועד המושבה, ואם לא יבחרו 5 נשאר הועד החקלאי בתקפו עם שבעת חבריו..</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >ג' דרובין אומרת כי זה שתי שנים שלנשים נתנה זכות דעה אקטיבית ופסיבית, אך
      עד עכשיו לא נבחרה אשה בועד. על הנשים לדרוש את זכותן. היא מציעה ששתי נשים
      שתקבלנה רוב קולות לגבי יתר הנשים תכנסנה בועד בלי התחשבות עם מספר הקולות
      אשר לגברים, ומציעה לבחור באחת מהאכרים ואחת מהתושבים.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1">לרגלי השפה הז'רגונית </span
    ><span class="c1 c11">[יידיש]</span
    ><span class="c1"
      > שהגברת לובין התחילה לדבר בה, בכדי לעמוד על דרישת הנשים ולהגן על
      זכות</span
    ><span class="c1 c11">[ן]</span
    ><span class="c3 c1"
      > קמה מהומה באולם, עד שהגברת לובין היתה מוכרחה לותר על נאומה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >היו"ר מציע לבחור במועמדות שהנשים הציעו ושואל את חות דעת האספה בנוגע
      לדרישת התימנים. הועד לא קבל שום החלטה בנדון זה, כי אמר להציע את דרישת
      התימנים לפני אספה כללית.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >מר דרובין: אין כאן מקום לשאלה, ואין שום ספק שלתימנים זכות הדעה מאחרי
      שהציעו להשתתף בתשלומים.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1">היו"ר מעמיד את השאלה למנין.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >החלטה: האספה מקבלת פה אחד החלטה זו: כל תימני הגר במושבה שנה אחת ומשלם מס
      הערכה</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1">יש לו זכות דעה.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >מר דרובין מציע לבחור בועד של 9 חברים ששנים מהם תהיינה נשים בחיוב.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >היו"ר מעמיד למנין את השאלה אם להעמיד קנדידטים מכל מעמד שמהם יבחרו חברי
      הועד או שהבחירות תהיינה חפשיות.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >החלטה: רוב מחלט מהנאספים מתנגד להעמדת קנדידטים למחלט שחברי הועד יבחרו
      ע"פי רצון כל אחד ואחד.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >היו"ר מעמיד למנין את הצעת הג' דרובין באופן כזה: שאחרי שיבחרו שבעה גברים
      לפי מספר הקולות, תכנסנה 2 הנשים שמספר קולותיהן יהיה יותר גדול מיתר הנשים,
      אך בלי התחשבות עםמספר הקולות שיקבל הגבר השמיני.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >החלטה: האספה מקבלת פה אחד את ההצעה הזו – ונגשים אל הבחירות ע"פי פתקאות
      חשאיות.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1">אלה שמות הנאספים שמסרו את קולותיהם:</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1">האדונים: </span
    ><span class="c1 c11 c14"
      >[כאן מופיעה רשימה ממוספרת של  228 שמות שלא כולם ניתנים לפענוח. לצד חלק
      משמות "האדונים" מופיעה גם "אשתו":]</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1">נבחרו לחברי הועד:</span>
  </p>
  <ol class="c10 lst-kix_list_1-0 start" start="1">
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">הגברת נ. פוחצבסקי    ב   173 קול</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">   "      ע. כהנסקי       "   167   "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">האדון  ת. זמסקי          "   130   "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">  "      ש. לנדוי           "   119  "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">  "      טביב               "   119  "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">  "     י. פרוסקורובסקי  "   103  "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">  "      כ.רפפורט         "    98   "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">  "      ו. דוגר             "    91  "</span>
    </li>
    <li class="c4 li-bullet-0" dir="rtl">
      <span class="c3 c1">  "      מ. מאירוביץ      "   87   "</span>
    </li>
  </ol>
  <p class="c15" dir="rtl">
    <span class="c3 c1">בתור מעמדים:</span>
  </p>
  <p class="c15" dir="rtl">
    <span class="c3 c1">האדון ד. לובמן           "    86   "</span>
  </p>
  <p class="c2 c16" dir="rtl">
    <span class="c3 c1">האדון י. דרובין           "    75   "</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1"
      >היו"ר נועל את האספה בתקוה שהועד שנבחר יעבוד שכם אחד לטובת כל המוסדות
      שבמושבה וכי החכוכים שהיו בין ועד המושבה וועד החקלאי דאשתקד יחדלו ואחד
      יעזור לשני. והאספה נסגרה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3 c1">המזכיר ו. רוזנקרנץ</span>
  </p>
  <p class="c9 text-end" dir="rtl">
    <span class="c1 c12">[וכאן מופיעות חתימות חברי הועד החדש] </span>
  </p>
</body>`;

export default page;
