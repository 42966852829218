const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c8 doc-content">
    <p class="c3 italic" dir="rtl">
      <span class="c0 bold">גלים</span
      ><span class="c4 c10 c14"
        > י"ז בטבת תר"צ  17.1.1930 (שנה ב&#39; גל&#39; 2 עמ&#39; 4. העיתון יצא
        לאור בוילנה)</span
      >
    </p>
    <br/>
    
    <h3 class="c5 text-center" dir="rtl" id="h.w20g24w976f1">
      <span class="c6 c12">אגב קריאה</span>
    </h3>
    <p class="c3" dir="rtl">
      <span class="c13">נחמה פוחצבסקי. "בכפר ובעבודה" </span
      ><span class="c11">(ספורים) הוצאת "הדים" תל-אביב, תר"ץ.</span>
    </p>
    <br />

    <p class="c3" dir="rtl">
      <span class="c1"
        >        על האשה מישראל אשר נתקה את הקשר בינה ובין משפחתה, אשר נטשה את
        הגולה ואת כל היקר וחביב לה בה ותעל ארצה על מנת להשתתף ביצירת החיים
        החדשים, המשוחררים ההולכים ומתהוים שם – על נושא זה נכתב מעט מאד. חתומה
        מאתנו מגלת ההתלבטות שבחיי אותן הנשים, אשר עם כל רוחן המרדנית ועוז-העפלתן
        נושאות הן בעול האשה מבלי כל יכולת להשתחרר מעול זה המדכאן. ונפתולים קשים
        אלה של האשה הבאה לא"י להשתחרר, לגבש בתוך כור העבודה את האנושי שבה מתארים
        לנו ספורי נחמה פוחצ&#39;בסקי בספרה החדש "בכפר ובעבודה".</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >        בספורים אלה מתאמצת נחמה פוחצ&#39;בסקית לגלות לנו את הטמון
        במעמקי לב האשה, את המסכת הטרגית ההולכת ונארגת מתוך עלבונות, דכאון
        ומועקת-נפש. טרגיות אלמת זו נחנקת בהן מתוך </span
      ><span class="c4 c6">הכרה בגורלן</span
      ><span class="c1"
        > ומתוך חדלון לשרות עמו עד שנחרגת ופורצת בשאגת-אלם דוויה של נסיגה לאחור,
        כסופה של תמרה, כבריחתה של העלמה דרורי, כזהבה שטינברג ואפילו כדוגמת מותה
        הטרגי של שרה זרחי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        צפונים מעין-רואים יסוריהן של נשים אלה הרוצות להאבק עם מר גורלן,
        גורל האשה עלי אדמות, ולהיות בנות-חורין מסבל הנשיות המעיקה עליהן. מתוך
        אהבה והשתתפות, שאינה מקהה את החוש ההסתכלותי האוביקטיבי מטפלת פוחצבסקית
        בגבורותיה ומעצבת לעינינו את טפוס האשה הישראלית (ואולי יש בזה דוקא משהו
        ישראלי ספציפי), המוכנת בכל פעם למסור את עצמה קרבן על מזבח המשפחה, הבעל
        והאוהב. היא מבליגה על יסוריה וסבלותיה כדי שלא להחריב את אשרם של אחרים,
        כדי שלא לעורר את חמתו של הגבר ואינה חסה על חורבן עולמה היא.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        בקראנו ספורים אלה המלאים רחמי אשה עלובה ואינה עולבה, התאמצות
        שלא לפי הכחות, ותורים למעלה מן היכולת מציף אותנו רגש של רחמים לכל אותם
        היצורים הרכים והתמימים, הנמקים בצער נפשם השותתת דם וגוועת בשתיקתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >        עלתה האשה לארץ מתוך שרפת הגשרים עם העבר (כמעט הן כלן עזבו את
        בית-הוריהן למרות רצונן), מסביבת הכנעה ושפלות-רוח, להשתחרר כאן מן האזיקים
        שכבשוה – ברם, התפורר רצונה בסלע-המציאות והיא חשה בחולשתה כשבאה להאבק עם
        הגבר שלא הכיר בזכותה ובנצחונה. הנה מתאוננת תמרה (ב"בצל הקבוצה") על
        החברים שאינם מתחשבים בבנין-הבית, בשפור המטבח ושכלולו ולא איכפת להם כלל,
        שמקום עבודתה של האשה צר ועזוב הוא ללא ידים העוסקות בתקונו, ובאותה שעה
        מתאמצת האשה להיטיב את צרכי הגבר. וגם בשדה העבודה הלאומית מחפיר היחס
        אליה. שרה זרחי למרות כל הבנתה ומסירותה לרעיון הלאומי, אינה נחשבת בעיני
        בעלה ברוך אלא כפתיה וקלת-דעת. "כל הטריטוריה שלנו בעבודת הלאום היא מקופת
        ר&#39; מאיר בה"נ עד קופת קק"ל" גומרת בדעתה שרה זרחי. ואין הן קובלות
        ודורשות בפומבי את זכויותיהן, אלא אין לה כוח הסתגלות... אין ההכנה האמתית
        לחולל </span
      ><span class="c4 c10">[?]</span
      ><span class="c1"> לי דרך נכונה" מודה זהבה שטיינברג.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >        בכלל הרי גבורותיה של נ. פוחצ&#39;בסקי שלולות עמדה מוצקה. בנקל
        מותרות הן על שלהן מפני טובת זולתן ואנו משתתפים בצערן. אולם פעמים גם
        מרגיזה אותנו חולשה אלמת ותמימה זו של האשה מישראל השואפת לגאולה...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >        ספורי האהבה היתרה והסנטימנט הפטריוטי אשר בהם ספוגה המספרת (שהיא
        ארצי-ישראלית ותיקה שהוציאה לפני עשרים שנה בערך קובץ ספורים מחיי הארץ בשם
        "ביהודה החדשה") נפגמים פעמים מהצד האמנותי, ואעפ"כ נקראים בכללם הספורים
        מתוך ענין. התאורים והאקציה מצטיינים בעדינות רכה ועגומה במקצת ובלבביות
        מצודדת. הסגנון גמיש, שוטף</span
      ><span class="c4 c10">[?]</span><span class="c1"> ונוח לקריאה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c9 italic small-text"
        > [החתימה חלקית, נראה שנשחקה עם השנים. כפי הנראה חתום:]</span
      >
    </p>
    <p class="c2 text-end" dir="rtl">
      <span class="c7">ב. חיים</span>
    </p>
  </body>
</html>`;

export default page;
