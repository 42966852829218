const page = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c10 doc-content">
    <p class="c1 italic" dir="rtl">
      <span class="c9 bold">האחדות</span
      ><span class="c7"
        > (בטאון מפלגת פועלי ציון) כו חשון תרע"ב  17.11.1911  (שנה ג&#39; גליון
        ה&#39; עמ&#39; 15-14)</span
      >
    </p>
    <h3 class="c0 text-center" dir="rtl">
      <span class="c6">"ביהודה החדשה".</span>
    </h3>
    <p class="c0 text-center" dir="rtl">
      <span class="c3"
        >(נחמה פוחצבסקי, קובץ ציורים. מחיר הקבץ בא"י פרנק אחד).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        השם, כפי שאנו רואים, מצלצל למדי. מי מאתנו אינו חולם על יהודה
        החדשה? מי מאתנו אינו תר בעינים כמהות אחרי כל סימן וקו, אף היותר קטן
        ורפה, של החדש והמקורי, הנוצר בזמננו ולעינינו בארץ הישנה, השבה לימי
        עלומיה? ואולם קוראים אנו את הציורים הללו אחד אחר משנהו, דף אחר דף, עד
        הסוף – ואיזו הרגשה משונה ובלתי נעימה תופסת את הלב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        לכאורה, מה חסר כאן מכל מה שהוא "אופיי" בשביל יהודה החדשה? הרי
        בית-ספר למל, הגמנסיה הירושלמית, הרי וכוחים על חברת-השתוף מיסודו של
        אופנהיימר, הרי אשור ציורי, שהתימנים חדרו לתוך המושבות ולכלם יש עבודה. מן
        הספר הזה אפשר גם ללמוד, שנשי "החוג&#39;ת מתיחסות בטוב-לב גמור לתימניות
        שלהן, נותנות להן במתנה מלבושים ישנים וחפצי-בית אשר אבד עליהם כלח. ולא
        עוד אלא שכאן אתם מוצאים אפילו את ההתעוררות הראשונה של הכרת הפועל התימני
        נגד אחיו האשכנזים הלוחצים אותו. ואף לא פעם תפגשו פה התרגזות עצומה נגד
        הרבנים ודיניהם וסיגיהם, הסבות לטרגדיות מחרידות וקורעות ומשברות לב
        "נאהבים ונעימים" לנצח –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3">        במלה אחת, חדש כמו שנאמר!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        ואחרי כל אלה אין הקורא שבע-רצון משום מה. לא לזה צפה. חסר לו כאן
        דבר-מה חשוב, נחוץ – מה שמסמנים ברגיל במלה: כשרון. יותר מדאי בולטת לפניו
        הגזרה היוצאת-דופן, יותר מדאי מֻרגשת לו התכנית המכוונת, שעל פיה נעשו כל
        הציורים. כל ה"קבץ" נחלק במדה נפלאה כזו בין אשכנזים ותימנים: חמשה ציורים
        על ל"ו עמודים לאלה, וחמשה על ל"ד לאלה – חלק בחלק! והציורים בעצמם כמה
        דומים הם אחד לשני, ממש כאילו אפתה אותם המחברת בדפוסים עשויים לכך! בכל
        אחד מהם יש קצת נחת, מעט תקוה, אבל עוד יותר צער ויסורים. מים במשורה. כל
        הגבורים מכל הציורים עסוקים ומטפלים ב"זכרונות" שלהם. אם ב"אבדות", הציור
        הראשון, נזכרת דינה פינקלשטיין בבעלה המנוח, בא שמעונוביץ בציור השני "תחת
        האתרוג" ונזכר באשתו הראשונה המנוחה. וביהודה החדשה, כפי הנראה, מזכירים
        נשמות לא רק האלמנים והאלמנות. "סימה רסקין" מעלה בזכרונותיה את אהובה
        המנוח ואת חברתה המנוחה, וסיצילה אלברשטם (ה"גוססת") נזכרת באמה המתה
        ובפורעים שנפלו חלל לפי חרבה. ומנהג זה של הזכרת-תמיד אינו רק אצל
        האשכנזים. "רמה" התימנית נזכרת בבעלה המנוח סעיד ובילדה המנוח יחיא; זכריה
        ("ג&#39;וי קדוש") נזכר באמו המתה; מוסה ("ג&#39;לות") נזכר באהובתו אפיה,
        וטוביה ("פעמים") בימים הראשונים, הטובים עם בחירה מימון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        ונפלא הדבר! בספרה זה של יהודה החדשה, במקום שהקורא קוה לפגוש
        חיים הרבה, הרי הוא נתקל על כל צעד בפגרים מתים. ומה שגרוע מזה: כמעט כל
        הגבורים גוססים וגועים לעיניו! צער בעלי חיים! בציור הראשון, שכמו שאפשר
        לשער, הרי הוא מעין תכנית לכל הציורים, אין איש נשאר בחיים: הבעל, האשה,
        ואפילו הילד (אגב, נער משובח ומעולה שהצטין גם בכתיבת חבורים! חבל!); "תחת
        האתרוג" מאבד עצמו לדעת רק הבעל, והאשה נשארה בחיים (מסתמא מפני שהיתה שניה
        לו ומרשעת, לקיים מה שנאמר: אשה רעה מר ממות); בציור השלישי "סימה רסקין"
        מתה רק התינוקת, וההורים נשארים לבכות לה ולהספידה; ב"גוססת" שוב מתים הכל,
        ורק ב"החרטה" – הציור האחרון למחלקת האשכנזים – הכל בריאים אפילו, אלא ששרה
        לאה העקרונית עיפה קצת ועצלות אוכלת אותה. מובן מאליו, שמנהג המיתה אינו רק
        אצל האשכנזים. גם בציור הראשון למחלקת התימנים ("רמה") מתים הכל כנ"ל:
        הבעל, האשה, הילד; ברם, בשאר זוכרים רק על המות, ובינתים פרים ורבים ורואים
        חיים ביהודה החדשה. ומעכשיו, אם יאמר לך אדם, שהמיתה אצל התימנים מצויה
        ביותר – אל תאמין לו! הבא לו את הספר שלפנינו ויראה מתוכו בעליל, שבעת שמתו
        13 אשכנזים, לא מתו מן התימנים אלא ארבע! ואולם, איך שיהיה, 17 מתים אצל
        סופרת אחת ובקבץ קטן אחד – אלהי-אברהם, הכלה אתה עושה בנו "ביהודה
        החדשה"?!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        אין שום ספק, שהקבץ רחוק מן הספרות הגסה מסוג ידוע. כונתה של
        המחברת ודאי רצויה. מרגש יחס חם מצדה אל גבוריה. השתתפות בצערם ויגונם. גם
        התימות הנן פשוטות, לקוחות מחיי יום-יום של הבריות הקטנות עם צרכיהן
        הפעוטים ומאווייהם המגבלים. שמחתם וסבלונם של כל גבורי הציורים הנם כל כך
        גלוים ומובנים, עד שאילו היה למציירת אף כשרון כל שהוא, אפשר היה לקראם בלי
        רגש של מרירות. אבל, בכל אופן, מה ענינו של כל זה ל"יהודה החדשה"? וכי
        בשביל שאותו לא יוצלח, שנזדוג להארורה, המית את עצמו תחת האתרוג ולא תחת
        אילן "גלותי", נעשה בזה קנינה של "יהודה החדשה"? וכי באמת די כשמספרים,
        שהפועלים התוכחו על התכנית של אופינהיימר, בכדי להוכיחנו, שכאן אנו עומדים
        בפועלי א"י ולא ברוסיה או בגליציה? והתימנים – כמה משונים אלי! כאילו
        קנוניא עשו ביניהם לדבר ולהרגיש דוקא ממש כאשכנזים, בלי כל הבדל!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        על מעלותיו של הספר צריך לחשוב, בלי ספק, מה שכל הגבורות בלי יוצא
        מן הכלל ובלי הבדל בין אשכנז האירופית ותימן האסיית) שואפות לעמוד ברשות
        עצמן; מה שבעלות-הבית האשכנזיות מתיחסות בטוב למשרתותיהן התימניות, ובעיקר,
        מה שהכל נדפס באותיות מאירות עינים ועל ניר טוב ומחזיק רק 74 עמודים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span
        >        תקופה חדשה בספרותנו היפה לא יביא הקבץ הלז של מרת
        פוחצבסקי.                        </span
      ><span class="c8">ל. </span><span class="c2 italic">[יעקב זרובבל]</span>
    </p>
  </body>
</html>`;

export default page;
