const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c3" dir="rtl">
      <span class="c8 small-text">רות ארבל</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        > הקבוצה התימנית הראשונה הגיעה לראשון ב-1909. הם שוכנו בבית העם. סבתא –
        עם נשים אחרות – החלו לבקר אותם ולעזור בכל דבר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c8">אפרת בן כהן</span>
    </p>
    <p class="c13" dir="rtl">
      <span class="c2 c1"
        >בערך ב-1913 הגיעה קבוצה נוספת של תימנים. מול ביתנו היה מגרש ריק ובהמשכו
        קיר אבן של חצר תלמוד-התורה. הקימו סוכות שנשענו על קיר האבן, ושם שיכנו את
        הבאים. לא באוהלים, ממש בסוכות של עלי אקליפטוס. והסוכות האלה נשענו על גדר
        אבן של תלמוד תורה, ותלמוד התורה היה בקרבת ביתנו. אז ממש הביאו לה את
        התימנים הביתה, היא לא חיפשה אותם. והיו מוכרחים מיד לטפל בהם, בנשים,
        בילדים, ואני חושבת שזה ממש הכריח את אמא לקבל אותם ופה התחיל הרומן שלה עם
        התימנים.  אמא התחילה מיד לטפל בהם, להאכילם, להלבישם ולדאוג לכל מחסורם.
        כמובן שהיא צרפה גם אותי לעזרה. הייתי עוד די צעירה, אבל אני זוכרת שאמא
        נתנה לי קקאו, חלב, להכניס לילדים. אמא ואני נכנסנו לסוכות כל שעות היום
        והתימנים היו נכנסים לביתנו.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >הקשר עם התימנים נמשך כל ימי חייה, והתקבל לאמא השם "הקונסול של התימנים",
        "אמא של התימנים" או סתם "גברת נחמה". אמרו – "לכל מקום הביאה נחמה".
         </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >כשעברו התימנים לגור בצריפים, ארגנה אמא כתה לנשים, ואני לימדתי אותם
        בערבים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >ילדות תימניות עבדו בבית. אמא ואני לימדנו אותן כל מיני דברים תוך כדי
        עבודה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >הילדה שעבדה אכלה תמיד עם המשפחה, גם כשהיו פנסיונרים ואורחים נכבדים.
        הילדה התימניה ישבה כל זמן הארוחה, ואני או אמא הגשנו לשולחן.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >יותר מאוחר עבדה בבית אשה מבוגרת יותר, עדייה. היא ובעלה יעקוּבּ גרו
        בדירה שסודרה במרתף. בעלה יעקוּבּ היה תלמיד חכם, מבוגר הרבה שנים מעדיה,
        ולא עבד. כל אימת שאמא נתקלה בכתיבתה בשאלה מהמקורות, ירדה להוועץ עם
        יעקוּבּ. עדיה היתה צעירה ממנו. הגיעה גם משפחתה מתימן, וכולם גרו שם יחד.
        אמא, כמובן, טפלה בכולם. עדיה היתה נשואה די הרבה שנים ועדין היתה עקרה. 16
        שנים היתה עקרה, לבסוף הרתה והתקשתה ללדת. אמא הביאה את דר&#39; פוחובסקי
        במיוחד מתל-אביב ונולד בן – יוסף.  אהבתי לרדת לבלות בדירה של עדיה. היא
        ספרה לי שאכלה שם ארבה קלוי בזמן מכת הארבה ב-1915.  </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c8 small-text">רות ארבל:</span
      ><span class="c2 c1"
        > שנים אח"כ היית שרה לי שירים תימניים בהגוי ובתנועות הידים המיוחדות
        שלמדת מעדיה.
      </span>
    </p>
    <p class="c5 title" dir="rtl">
      <span class="c8 c11 small-text">רות ארבל</span>
    </p>
    <h3 class="c5 title" dir="rtl">
      <span class="c6">נאדרה סֵ&#39;בַהי</span>
    </h3>
    <p class="c7">
      <span class="c2 c12"
        >אמי ואני באנו לבקר את נאדרה. ביתה ברחוב רמב"ם מאחורי השוק. חצר רחבת
        ידים בין הרחוב והבית כולה שתולה שיחי גת. נאדרה, באותה תקופה כבר אלמנה,
        היתה אשת הרב סייבי. היא נישאה לבעלה בתימן כאשתו השניה. כשעלו ארצה בסוף
        שנות העשרים נשארה הראשונה בתימן. הילדים הצעירים יותר מאשתו הראשונה עלו
        אתם. נאדרה היתה 15 שנה צעירה מבעלה, אהובה עליו מאד ושלטה בכפה. הרב
        סייבי, איש רזה, גבוה, אצילי, עסק עם סבתא בהשכנת שלום-בית בעדה התימנית.
        לאחר מות סבתא, המשיך בזה עם אמי. הרב לא התפרנס מהרבנות: היתה לו חנות
        לקמרדין (לדר), ממתקים וגרעינים. החנות שכנה בצמוד (משמאל) לשער בית ספר
        "קרון" (בית-הספר היחיד במושבה משך שנים ארוכות). את הגרעינים היה ממלא
        בקונוס שיצר מנייר עיתון. כשלא היו קונים היה יושב בראש זקוף על שרפרף בפתח
        החנות, מנופף במעין שוט לגרש זבובים וקורא בעתון.
      </span>
    </p>
    <p class="c14" dir="rtl">
      <span class="c2 c12"
        >אחרי מותו נקרא רחוב שנפרץ לא רחוק ממקום החנות על שמו. הרחוב נמצא קצת
        דרומה מבית-הכנסת הגדול, ואילו צמוד לבית הכנסת צפונה רחוב נחמה, על-שם
        סבתא.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >נאדרה תימניה נאה, עסיסית, אינה יודעת קרוא וכתוב. אך גאה, מלאת בטחון
        עצמי, חכמת חיים ושמחת חיים. דיבורה ססגוני, מלווה תנועות ידים אופיניות,
        בניב תימני עמוק מתובל ערבית. אני קצת מבינה, קצת מנחשת וקצת הולך לאבוד.
        אני מנסה למסור את דבריה כפי שנאמרו כמיטב יכולתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נאדרה כמובן מקבלת אותנו במטבח. ערימת שום מקולף על השלחן מוכנה לסחוג.
        היא מקדמת אותנו בשמחה, ושואלת מיד</span
      ><span class="c2 c0 hand"> "מה תאכלו, סלט?" </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1">אמא עונה: "שום דבר".</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ונאדרה, כשהיא סופקת כפים ופותחת אותם כלפי מעלה אומרת בצחוק מתגלגל</span
      ><span class="c2 c0 hand"> "שום דבר".</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">הטלפון מצלצל.</span
      ><span class="c0 hand"> "אין דבר יהיו בריאות, אפרת ובנתו </span
      ><span class="c1">(בתה)</span><span class="c0 hand"> בתיג&#39;י </span
      ><span class="c1">(באו)</span><span class="c0 hand"> בקרוני. אפרת? אפרת? </span
      ><span class="c1">(כאילו מה השאלה איזה אפרת)</span
      ><span class="c0 hand"> אפרת חַגַנה. </span><span class="c1">(שלנו).</span
      ><span class="c0 hand"> טוב לי, בטח! מן פה?   מא בתסוני </span
      ><span class="c1">(לא שוכחים אותי).</span
      ><span class="c2 c0 hand"> היא מכירה הבית.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ושוב פונה אלינו:</span
      ><span class="c0 hand"
        > "היינו חדשים חדשים מסכנים אין לנו כלום. אחנא באנו ישר לעוקשי. צריכים
        תימנים. לא יודעים לדבר. היה לו בֵיתאל זמן אל אנגליזי...יטבחו אל יהודים.
        נשארנו בבית". </span
      ><span class="c2 c1">(מאורעות 1929). </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >נכנס תימני זקן, חצ&#39;בי, מביא בקבוק ערק. "לך נתן במאה שלושים".</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 hand"
        >"היתה סבתא שלך כמו אמא של כלם. היתה מסדרת לכלם נעלים, בגדים, אוכל. היה
        ישראל ישעיה כמו שליח – קמח, תפוחי-אדמה, רוז </span
      ><span class="c1">(אורז).</span
      ><span class="c2 c0 hand"
        > מסדרת להם עבודה. כל יום היה ישראל ישעיה אצל סבתא היתה מסדרת חבילה
        חבילה והיתה נותנת לו. מה אין להם - היתה באה לבתים, מסתכלת אל הבית,
        מסתכלת את הילדים, אל הנשים, היתה רואה מה יש להם, מי הרה, מתי חלב יתנו
        לה. תכתוב לילדים נעלים, גן. הכל היתה מסדרת. אין כמוה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 hand">דוד רצבי רב עם אשתו לולו </span
      ><span class="c1">(כנראה הכה אותה)</span
      ><span class="c0 hand"
        > היא הלכה לסבתא שלך. נתנה לה הכל. היא לבד והוא לבד. מסדרת לה עבודה,
        נתנה לה תנובה </span
      ><span class="c1">(תנובת השדה, אוכל)</span
      ><span class="c2 c0 hand"
        > לא גרו יחד אולי שנה, אולי שנתיים. בסוף עשתה שלום ביניהם. חיו יחד עד
        עכשו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 hand"
        >שלמה לא התנהג יפה לנעמי. אמרה, קחי את הבגדים שלך ובואי... הסבתא דאגה
        לנו עד שיסדרו לנו בתים. עד שיסדרו לנו אדמה. היתה עוזרת לכלם. הסבתא שלך
        זו תשכון בגן-עדן." </span
      ><span class="c1">שתי ידים פסוקות נפתחות ועיניה לשמים. </span
      ><span class="c0 hand">"סבתא כזו אין כמוה בעולם. </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מצביעה על אמי</span
      ><span class="c0 hand"
        > לא יעשו לה כלום יש לה זכות אבות. בעלי רצה לגור על-יד אפרת אבל אין כניס </span
      ><span class="c1">(בית כנסת).</span
      ><span class="c0 hand"
        > בנינו כאן – יש כניס. כולם אמרו יש שועלים, יש עקרבים, יש זאבים. כל
        הלילה אוהו... אוהו...! אבל יש זיתים ויש לוז </span
      ><span class="c1">(שקד)</span><span class="c2 c0 hand"> ויש אל כניס. </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0 hand"
        >יום אחד יש עוצר מן השעה שש. ואני נתתי אוכל המטבח בחוץ. באו אנגליז "גֶט
        אִין, גֶט אִין". נכנסתי סגרתי אֵל בֵית.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0 hand"
        >יום אחד היה גשם, היו שועלים, מה אגיד לכם. איזה גשם! וכאן ים. חצי בתים
        מים. בעלי אמר עכשו תוציאי את כל הנשים לטבילה. ואנחנו צחקנו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0 hand"
        >את יודעת הרחוב קוראים על שם סבתא שלך, שם יש הרבה בתי כנסת למה היא היתה
        צדקת."</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c1"
        >דפיקה בדלת. נכנסת שכנה, מגישה שקיק ושני תירסים מגנתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0 hand"
        >"אין כמו תימנים – באוכל שלהם, ביופי שלהם ובאמונה שלהם."</span
      >
    </p>
    <p class="c9" dir="rtl"><span class="c2 c12"></span></p>
  </body>
</html>`;

export default page;
