const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c6 italic" dir="rtl">
      <span class="c1 bold">דבר</span
      ><span class="c3">  11.8.1932 – תחת הכותרת</span><span class="c1"> </span
      ><span class="c2 bold">חמישים שנות ראשון לציון (ממסיבת העתונאים)</span
      ><span class="c3"
        > מתואר מפגש עם "מיסדי וּוַתיקי" המושבה שסיפרו במשך ארבע שעות על הישגי
        המושבה ראשון לציון. </span
      ><span class="c3">בין הנואמים מוזכרת גם נ. פוחצ&#39;בסקי</span
      ><span class="c3">.</span>
    </p>
    <p class="c4" dir="rtl"><span class="c5 c7"></span></p>
  </body>
</html>
`;

export default modal;
