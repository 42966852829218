const page = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c13 doc-content">
    <p class="c3" dir="rtl">
      <a href="#efratBenCohen">
        <span class="c13">
          אפרת בן כהן
        </span>
      </a>
    </p>
    <p class="c3" dir="rtl">
      <a href="#mosheNelson">
        <span class="c13">
        משה נלסון
        </span>
      </a>
    </p>
    <p class="c3" dir="rtl">
      <a href="#liliFridlenderDror">
        <span class="c13">
          לילי פרידלנדר-דרור
        </span>
      </a>
    </p>
    <p class="c3" dir="rtl">
      <a href="#DrRdklifSalman">
        <span class="c13">
          ד"ר רדקליף סלמן
        </span>
      </a>
    </p>
    <br/>
    <h3 class="c3" dir="rtl" id="efratBenCohen">
      <span class="c7 c12">אפרת בן כהן</span>
    </h3>
    <p class="c6" dir="rtl">
      <span class="c0"
        >הרבה אנשים באו לבית, ואני זוכרת את המגוון הגדול של האנשים. חיילים בגדוד
        העברי, הקולונל והרופא של הגדוד...
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >מלחמת העולם הראשונה. הגדוד העברי חנה בסרפנד (צריפין). החלו לבא חיילים
        לבית – יחידים, וקבוצות קבוצות. באו, אכלו, בילו, ישנו. ישנו בכל מקום – על
        השולחן, תחת השולחן, על ספסלים. הבית תמיד המה. אמא הפכה ל"דודה של
        הגדודים".
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >בחנוכה שמרה פלוגה בכרמי הזיתים של רמלה על שבויים תורכים. השומרים,
        ביניהם פרידלנדר ונלסון, באו וביקשו שיכינו לביבות לחנוכה. הגדודאים שלחו
        עגלה עם ארבע פרדות. גב&#39; לובמן ובתה מרים (לימים מרים לוין-קיפניס) ,
        אמא  ואני, נסענו לרמלה עם עיסה תופחת בסיר חרס גדול.  שם – בין הזיתים –
        הכינו החילים עצים בחפירה, עליה הניחו מחבתות גדולות, והכנו את הלביבות.
        החיילים אכלו, שרו, ובשתים אחרי חצות הוחזרנו הביתה לראשון לציון.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >קולונל מרגולין, מפקד הגדוד, היה ידיד משפחה משכבר הימים. תכופות בא לבקר
        בביתנו בלווי רופא הגדוד דר&#39; סלמון. הם באו רכובים על סוסים. לפי התקן
        הבריטי, אסור היה שחיילים ומפקדים ימצאו ויבלו בצוותא. כששמעו החיילים שהיו
        בבית את פרסות הסוסים המתקרבים, מיהרו להסתלק – קופצים מהחלונות או מתחמקים
        מהדלת האחורית.</span
      >
    </p>
    <br/>
    <h3 class="c9" dir="rtl" id="mosheNelson">
      <span class="c1">משה נלסון </span>
    </h3>
    <p class="c3" dir="rtl">
      <span class="c7 c4 c2 italic"
        >[משה נלסון היה חייל בגדוד העברי ואחרי תום המלחמה התיישב באביחיל. רות
        ארבל ראיינה אותו כשהיה בן 93.]</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >"כשבאתי ארצה מארה"ב כחייל ב-1918, הציע לי חבר בשם ישראלי לגשת לבית
        בראשון. שם, אמר, תתקבל יפה. באנו, ונחמה קבלה אותנו בפנים מאירות. "שבו,
        תרגישו טוב". היא דברה עברית. לא הבינותי אף מילה, אך זה נכנס ללב. אחר כך
        נתרצתה ועברה ליידיש. דברנו ארוכות. היא נתנה לי חוברת שכתבה. מאותו יום
        נעשינו ידידים והפכתי לבן בית. בביקור הראשון שלחה נחמה את אפרת בתה להביא
        את דוד </span
      ><span class="c2 c4 italic"
        >[עוור מלידה, אחיו של הקולונל מרגולין, בן בית בבית פוחצ&#39;בסקי]</span
      ><span class="c0"
        >. דוד לחץ ידו של כל אחד, שאל אם אנו בפיקודו של אחיו, ואחר-כך שר לנו
        רומנסות רוסיות וניגן בכינור.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >כששמענו את הקצינים מתקרבים, מהרנו לצאת דרך דלת או חלונות מצד שני. פעם
        ישבתי ולא שמעתי את הסוסים המתקרבים. קולונל מרגולין הופיע עם מספר קצינים
        גבוהים. בקשתי לקום ולצאת, אך הקולונל נתן לי פקודה להשאר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >עשרות חילים באו לבית. פעם פינו את השולחן מהחדר המרכזי (מרפסת סגורה),
        ושבעים-שמונים איש התאספו בחדר, ביניהם פבזנר, אסף גור, מרדכי ברנשטין –
        דנו, התוכחו והחליטו להקים ארגון חקלאי, "מגיני הארץ", מתוך כוונה להתישב
        על הגבולות. להיות חקלאים ולהגן על הגבולות. הרעיון היה של פבזנר ושל
        פוחצ&#39;בסקי. אחרי שתים עשרה שנה הוקמה אבי-חיל.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >בשבתות תכופות באתי לבקר. עברו עלי בביתם הרבה דברים יפים.</span
      >
    </p>
    <br/>
    <h3 class="c9" dir="rtl" id="liliFridlenderDror">
      <span class="c1">לילי פרידלנדר-דרור </span>
    </h3>
    <p class="c3" dir="rtl">
      <span class="c4 c2 c7 italic"
        >[לילי פרידלנדר היתה נשואה לשלמה פרידלנדר המכונה אונקל סם, גדודאי
        ממתישבי אביחיל.]</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >כשסם חזר לארה"ב הוא סיפר במשך לילות שלמים על בית פוחצ&#39;בסקי – על
        הבית הפתוח, על הכנסת האורחים. סם סיפר כי פעם נענשו על-ידי קולונל
        מרגולין, ונאסר עליהם לצאת מהמחנה. אחרי כמה שעות החליטו כמה חילים בכל זאת
        להתחמק ולצאת מהמחנה, והופיעו בשעה מאוחרת בבית פוחצ&#39;בסקי שמחים
        ועליזים. כשמצאו שם את הקולונל מרגולין יושב ומשוחח נדהמו ונבהלו, ובקשו
        מיד להסתלק. הקולונל התעלם מהפרת הפקודה, שאל איך מזג האויר בחוץ, אם הגשם
        פסק, אם יצאה הלבנה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >כשעלינו ארצה, הבית הראשון שביקרנו היה בית פוחצ&#39;בסקי.  </span
      >
    </p>
    <br/>
    <h3 class="c10" dir="rtl" id="DrRdklifSalman">
      <span class="c1">ד"ר רדקליף סלמן</span>
    </h3>
    <p class="c6" dir="rtl">
      <span class="c7 c4 c2 italic"
        >[זהו קטע ממכתב מד"ר רדקליף סלמן לאשתו מה- 28 ביולי 1918. ד"ר סלמן היה
        רופא הגדוד העברי וידידו של קולונל מרגולין שהיה מפקד הגדוד העברי של הצבא
        הבריטי בארץ בזמן מלחמת העולם הראשונה. הקולונל והרופא היו בידידות עם
        משפחת פוחצ&#39;בסקי ובקרו לעיתים בביתם. אחיו העיוור של הקולונל, דוד
        מרגולין בילה בבית פוחצ&#39;בסקי במשך כל המלחמה. אמה של נחמה ("the old
        mother") גם היא חיה שם שנים רבות. האשה ("the wife") הנזכרת במכתב היא
        נחמה פוחצ&#39;בסקי, הבת היא אפרת (לימים) בן כהן שהיתה אז כבת 20, והאב –
        יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי. המכתב מופיע בספר</span
      >
    </p>
    <p class="c5 eng">
      <span class="c4 c2"> </span
      ><span class="c2">Redcliffe N. Salaman, M.D. , </span
      ><span class="c2 c11 bold"
        >Palestine Reclaimed, Letters from a Jewish Officer in Palestine</span
      ><span class="c0"
        >,  London: George Routledge & Sons 1920 \ New York: E. P. Dutton & co.
      </span>
    </p>
    <p class="c5 eng" id="h.gjdgxs">
      <span class="c2"
        >On Monday, Jimmy Rothschild lent Margolin and me his car, and we drove
        to Rishon, near Ludd. Here we stopped, after a delightful drive and a
        picnic lunch, to see friends of his, a family of original colonists. The
        wife and old mother were at home, and Margolin&#39;s blind elder brother
        was sent for. Margolin is, of course, a hero among these people, with
        whom, twenty years ago, he lived and worked, but he is as modest as he
        is simple, and I greatly admired him amongst them. The wife was sweet, a
        rather worn woman of forty-five, with a light in her eyes and the joy of
        freedom and labour in her face. She was the Jewish peasant woman, and
        treated one in that simple, natural, homely way that knew no
        embarrassment, asserted no equality nor recognized any inferiority.
        There we had tea and supper; between the two came in her daughter who
        had been working all day with her father in the almond groves. She was
        the same fine, well-developed, natural girl one sees here, cultivated
        and educated, but contented and full of grace and modesty. She took me
        all over the colony and the vineyards. Later came in the old father,
        gaunt and thin, hard as iron. He works all day and makes a fair living.
        His type was, </span
      ><span class="c4 c2">au fond</span
      ><span class="c0"
        >, the old Jew of the Ghetto, but transformed by a new dignity.</span
      >
    </p>
  </body>
</html>`;

export default page;
