const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c13 doc-content">
    <p class="c2 text-center" dir="rtl"><span class="c11">א</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עבודת הבציר והקטיף באה אל קצה, גם גִפּוּר השקדים נגמר והפרי הובל למחסנה
        של החברה השתופית למכירה.  שמואל גלעדי ובנו יפתח פנויים עתה לעשות את
        החשבון השנתי, כדי לדעת מה הכניסו הכרמים.  היתה שנת בצורת והחשבון הוכיח,
        שכל ההכנסה מספיקה בקושי לכסות את הוצאות החרישה, העדור, מסי הממשלה
        ומס-ההערכה של ועד המושבה.  לכלכלת הבית לא נשאר כלום, חוץ מן ההכנסה הדלה
        של הרפת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אבא, – אמר יפתח בצער – הלא תודה, כי אין עתה שום אפשרות להתקים מהכרמים
        שלנו ועלינו לבקש מוצא מן המצב הזה. </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– ומה המוצא, בני?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– כבר אמרתי לך לא פעם, שעלינו למכור את הנחלה הזאת שלא הצלחנו בה ולעבור
        העירה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– ומה תתן לנו העיר, בני?  הלא אני מעודי שנאתי את המסחר, ואתה אין לך כל
        מושג ממנו, ואיך נהיה פתאום לסוחרים? הרי העיר עם כל תעתועיה זרה לנו ולא
        נמצא בה ידינו ורגלינו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אין דבר, אבא.  ידינו החזקות תהיינה בעזרנו למצוא את מחיתנו ביושר
        ובכבוד.  אם מזלנו גרם, שנפלה בגורלנו אדמה זבורית, שלא נוכל להצליח בה,
        עלינו להחזיר בעוד מועד את הגלגל אחורנית לבל ימולל אותנו כליל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לא, ילד, לא אסכים להצעתך. אידיאל חיי היה – עבודת האדמה.  אמת, היתה
        אי-הצלחה, אבל עלינו להמשיך את הנסיון.  סבלנות, בני!  אם שני אנשים עובדים
        כמונו יקומו יום אחד ויבגדו בעצמם ויברחו מעצמם, מה יעשה המון
        החלשים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אמת, אבא, שאנו מתאימים להיות עובדי-אדמה, אבל אם אדמה זו אינה עונה
        אותנו, אין לדעתי כל עוון אם נחפש לנו דרכי קיום אחרות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– כן, בני, עלינו לחפש דרכי קיום, אבל בכפר ולא בעיר.  בשעה זו עלינו
        להרחיב את המשק הביתי, לרכוש לנו שתי פרות טובות, מדגרה, כַוָרות אחדות,
        ובעבודה חרוצה נוכל להתבסס.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– תסלח לי, אבא, אם אכנה את כל דבריך בשם – "חלום!"</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– מדוע?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– מפני שבלי כסף אין כל אפשרות להרחיב את המשק.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– כסף אפשר היה להשיג באחריות הדדית, לו נוסדה האגודה שחשבנו עליה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לו נוסדה!  והרי ידועים לך כל העכובים העומדים בדרך ההתאגדות. ובאופן
        פרטי אין עכשיו להשיג כסף, כי אין עוד אֵמוּן באכר.  הן לא שכחת כמה
        התלבטנו, כשצריכים היינו להשיג כעשרים לירה לקנית סוס.  הלא בכל המוסדות
        היינו, למלוי ברבית פנינו והכסף לא הושג.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הבן הלך לו בפנים נלהבים, כולו התרגשות, ושמואל גלעדי שקע בהרהוריו: היכן,
        באמת, המוצא מן הסבך?  אנשים מוציאים את כל כחותיהם בעבודה וקיומם מהם
        והלאה!  האם אשמת הארץ היא?  הלא חוסר הנסיון אשם.  ואשם הרבה גם הוא עצמו,
        שחפש תמיד דרכים חדשות ונכשל. אדמתם נראתה להם מתחילה טובה, אחרי-כן ראו את
        טעותם – חלקה חלקה ונִגְעָהּ.  חוץ מזה בטלן הוא בעסקי ממון ונִתָן תמיד
        לנצול ולשגיאות.  אם ימהר למכור את תנובת כרמיו, עולה אחרי כן המחיר, ואם
        יתמהמה במכירה – ירד המחיר.  גם כשיעלה בידו למכור בזמן הנכון, הוא נכשל
        בערמתם של הסוחרים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אשמה גם הצורה החקלאית שבחר; הוא מרגיש בזה מכבר, אבל הכרמים בלעו את מרצו
        ואת שנותיו ועתה אין עוד אפשרות לשנות ולתקן.  בינתים סובל הבן וסובלת כלתו
        הענוגה והרכה, ומה לו לעשות להקל את חייהם?  מה לו לעשות במצב הרוחות של
        ילדיו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ביחוד קשה לו לראות את סבל כלתו המסכנה.  היא מבקשת להסתגל אל הקושי
        שבחייהם אבל אינה יכולה;  ובראותו אשה-ילדה זו בסבלה, לבו זב דם
        וצועק: מדוע אינו יכול לתת אושר לילדיו?  ועיקר הדאגה הוא לה, לכלתו.  יש
        שהוא שומע אותה בוכה בחדר המטות, ואז נפשו מתפלצת, רוצה היה להמציא לה
        הקלה, ספוק, נחת-רוח כל שהיא – ואין בידו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יש שהוא מתבונן נוגות בכלתו ועל לבו עולים זכרונות רחוקים:  רבקה&#39;לי
        שלו גם היא צעירה היתה בשעת נשואיהם, וכשילדה לאחר שנה את יפתח, היו כל
        רואיה משתוממים:  ילדה – וילד יש לה! והנה טרם גמלה את יפתח שבקה את
        החיים...  מי אשם במותה?  אין תשובה. אחר כך שלשלת ארוכה של אלמנוּת, שהיתה
        קשורה בתפקיד משולש:  אב, אם ואכר.  עשרות שנות חיי בדידות.  והלא יש גם
        אצלו תקופות, שרצון החיים מתעורר בו בכל עוז, וקמה בלבו חרטה על הנזירות
        שגזר על עצמו.  היו לו הרבה הזדמנויות לבנות את ביתו מחדש, והוא בסכלותו
        מנע אושר מעצמו.  טעות, טעות!  כך דרכו תמיד. לכאורה שוקל הוא כל צעד, כל
        פעולה, ולבסוף יוצא תמיד החשבון מוטעה, תמיד גרעון והפסד.</span
      >
    </p>
    <p class="c7" dir="rtl"><span class="c4"> </span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לאחר הפסקה של שנתים נבחר גלעדי שוב לוַעַד ושוב הוא סובל בישיבות.  יש
        שעומדות על הפרק שאלות חשובות, ורק חלק קטן מן החברים מתעמקים בשאלות
        בכובד-ראש הראוי.  יתרם פותחים בכד ומסיימים בחבית,  פוסחים על שתי הסעפים
        וחובת הדבור קודמת אצלם לחובת האמת והצדק.  הוא מתוכח, מתרגש, ומתוך
        התלהבות הוא מאבד לפעמים את שווי-המשקל ואז אין עוד כל אפשרות להוכיח את
        צדקת טענותיו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יש שעד אחר חצות הלילה מבלים אספות, מתלבטים ומסתבכים בשאלות שונות ואין
        באים לשום תוצאות ממשיות. את הפתרון דוחים משבוע לשבוע, ובינתים באים
        ענינים אחרים ודוחקים את הראשונים;  פותרים את השאלה הדוחקת על רגל אחת
        וממילא נעשה משגה.  ואז מרגיש הוא מוסר כליות, כאילו רק הוא לבדו
        האשם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יותר קשות מישיבות הועד הן האספות הכלליות של האכרים.  כאן, נדמה לו, הוא
        שב אחורנית עשרות שנים:  השפה הגלותית והדעות הנושנות.  לפעמים מנסה אחד
        החברים לדרוש דבור עברי, אבל אז תקום מהומה:  – הלאה שמנדריקיזם!  גלעדי
        רותת ושולח מבטי זעם מסביב – כמה זרים לו האנשים האלה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יש שגם ברחוב אין לו מנוחה מן הליצנים.  הנה הוא שב עיף מן העבודה, ובעברו
        הוא שומע הלצות או עקיצות על חשבונו, על עבודתו, שהוא עוסק בה כל הימים בלי
        מנוחה.  גם על גן הירקות שלו שלוחים חדודים:  אומר אדם להתעשר מאכילת
        עשבים.  כמה אלפים כבר הניח בבנק?  אזנו קולטת את דברי חבריו האכרים, עינו
        רואה את פרצופיהם והם נראים לו כה זרים ומשונים.  גם עמידתם והליכתם מקבלות
        צורה משונה בעיניו.  רק לעתים רחוקות מאד מגיעה השיחה לשאלות חקלאיות, ואז
        הוא מתעכב אצלם ומשתתף ברצון בשיחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        > יש שבעברו במהירות את הרחוב הוא מתפלא איך האכרים עומדים כנופיות כנופיות
        ופנאי להם לעסוק בפוליטיקה. השעה היא שעת יציאה לעבודה – ולהם יש
        פנאי!  גלעדי הולך, מעדרו על שכמו, ומרגיש הנאה מהליכתו זו, אבל תוך כדי כך
        הוא מוכיח את עצמו על היהירות שבלב. והרי אדם צריך לעשות הכל בפשטות, מבלי
        להתגאות בשום פעולה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בערב הוא שב ולבו טוב עליו – הוא נקה מיבלית חלק מן הכרם ונדמה לו שעסק
        בדבר יצירה.  פוגש הוא חבורת צעירים, שאת כולם הוא זוכר עוד מהיותם
        בעריסותיהם;  עתה הם גדולים, גאים, אמיצים וזקופים, עוברים על ידו ואינם
        אומרים לו "שלום".</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– מתניה!  האינך מכיר אותי?  למה אינך אומר שלום?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מתניה מתבלבל במקצת ומבטא תשובה שאינה מגיעה לאזני השואל.  רגיל כבר גלעדי
        בפגישות עם הצעירים המתנכרים לו, אבל הוא מקדם תמיד את פניהם בברכה ומכריח
        אותם להחזיר שלום.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ברור לו, שהדור הצעיר הזה לועג לו ולברכותיו.  והלא הוא בכלל נותן מקום
        למהתלות:  כובע קש בעל שולים קרועים, סנדלים מטולאים וכתונת-בד כזו של
        פועל, – מי מהאכרים ירשה לעצמו לעבור ככה את הרחוב, ועוד מעדר על
        שכמו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולא רק בעבודתו ותלבשתו הוא ללעג בעיני הבריות, אלא גם במהלך מחשבותיו,
        בנטותו תמיד כלפי שמאל.  יש שהוא מביע דעות, העומדות בנגוד גמור לאינטרסים
        של האכר, ואז אומר הקהל לדחותו מן הצבור – אין לו מקום ביניהם!  והוא בעצמו
        לועג ואומר לנפשו:  באמת איני לא סרטן ולא דג, ואין המים הללו יפים
        לי.  הלא על פי מעמדו בורגני הוא ועל פי דעותיו ופעולותיו – דמוקרט, ובאמת
        אינו לא הא ולא דא.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >באחת האספות, שהוכנס אליה תזכיר מועד הפועלים להעסיק אותם בעבודת העונה
        הבאה, והחברים הביעו את דעתם בשלילה, נאם גלעדי נאום נלהב נגד הדעות
        הריאקציוניות שלהם והקים שערוריה. אחרי-כן הודה בלבו, שעבר את הגבול ובאופן
        ,שְטוֹקְמַנִי" הקים את הקהל נגדו. הוא התחיל אמנם מנקודת השטח העליון, אבל
        זרם הדבור גרף אותו העמק וגרוף עד שירד אל מעמקי המים העומדים, המעלים ריח
        רע...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מי אשם ביחס זה אליו, אם לא הוא עצמו, שאינו יודע להתהלך עמהם ולהביע
        לפניהם את מחשבתו?  אמנם רק את האמת אמר, אבל גם האמת דורשת הבעה
        נוחה. והוא אינו יודע לדבר בנחת ולהתקרב לבני אדם.  האומנם מיזנטרופ
        הוא?  ולמה כל כך קשה לו להפגש עם אנשי סביבתו, אפילו עם אלה, שכל דבר רע
        לא נפל ביניהם? למה בשעה שהוא בא בחברתם תוקף אותו רגש בלתי נעים?  האם יש
        איזה רגש לא טהור בלבם וזה מעורר הרגשה רעה בלבו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל לא, הוא עצמו אינו טהור!  הוא האדם הרע, מר-הנפש, הקפדן, החשדן.  גם
        תקופות הטוּב והרחמים שבלבו אינן מאריכות ימים.  הנה חִלֵק בנדיבות לזקוקים
        כל מה שנמצא ברשותו, אבל אחרי-כן, כשהורגש חסרונם של הדברים האלה, והוא
        נוכח לדעת כי האנשים שנהנו מטובו אינם מכירים לו תודה כלל, - נמלא לבו טינה
        על עצמו, שאיננו אדם מעשי ואיננו יודע חשבון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ותמה שמואל גלעדי, כיצד בא פתאום השנוי ברוחו והוא נעשה קשה-לב במשך
        השנים.  הלא לפנִים היה בו רוך מרובה, והיה משתתף עם כל חלש ונדכא להקל את
        צערו, כעת שוּנה רוחו – זִקנה וחולשה אינן מעוררות בו עוד רחמים.  ובראותו
        אדם זקן הולך למות, נראה לו הדבר טבעי מאד ואינו חש כל עצבות.  אלה לחיים
        אלה למות, - ומה בכך?  גם הוא עצמו כבר הולך ומתקרב אל הגבול, לא רבה עוד
        הדרך...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשהוא עושה את חשבון חייו, אין הסִכום שוה תמיד.  פעם הוא מתביש בַעֲבָרוֹ
        ופעם נדמה לו, שאת תפקידו מלא יפה. אמנם בבדידות ובצער, אבל גם ביושר
        ובנדיבות.  עתה אין עוד המרץ הדרוש לפעולות חשובות, דרכו היא במדרון. אמנם,
        לפעמים מתעורר עוד רצון לחיים, לאהבה כל שהיא.  יש צורך באדם שיהיה קשור בו
        בלב ונפש.  והילדים – הם התוו דרכם בצד, - אהבה צוו לרֵעַ ולאב רק משהו
        כבוד...  הוא מכיר תודה לבנו ולכלתו, שהקצו לו פנה קטנה זו, ד&#39; על
        ד&#39;, בתוך ביתו.  לעתים אומר לו דמיונו:  אולי צר להם על חדר זה, שיכלו
        לקבל בעדו שכר-דירה, אבל לאחר שהוא מתישב בדבר ברור לו, כי אך דמיון חולה
        מטעה אותו לחשוד בילדים.  והוא משתדל להביא תועלת במשק, וידו עושה באמונה
        בכל ענפי העבודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא תמיד קם הראשון בבית, מתלבש מהר ויוצא לפתוח את הרפת.  אויר הבוקר
        קריר ומלא ריחות בושם. רננת צפרים בוקעת ועולה מן החורשה.  מתוך רבוי
        הקולות מושך את לבו צליל דק אחד ונדמה לו, שהוא שומע שיר ארוך במלים.  על
        העץ הקרוב הוא מחפש את הצפור המשוררת, מרים ראשו, פוקח עיניו לרוחה, אבל
        ענפי העץ מוצפים שמש והמשוררת הסתתרה עמוק בסבך הענפים ואין לגלות
        אותה.  אז הוא פונה מהר אל הרפת, פותח אותה, מנקה, חולק אוכל לפרות, חולב
        אותן ומכניס את החלב הביתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא מאושר אם כלתו מקבלת את ברכת עבודתו ברצון.  אוהב הוא אותה כפלים בשעה
        שהיא מדברת דברים נוחים ושקטים.  אבל כשתוקפת אותה העצבות או כשהיא נעשית
        נרגזת ומתחילה בנתוח מצבם ובתלונותיה הנִשנות על חסרון חיים, הוא מבקש
        לברוח מן הבית ולהסתלק אל הכרמים.  כאן הוא שוכח את צערו.  העצים אינם
        מתלוננים, אינם תובעים הכנסות, רק אהבה, מסירות וטפול.  הוי, בני אדם אינם
        מסתפקים באלה!</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בגן כמעט התיבש הכל.  אפילו העגבניות היפות, שהושקו בהתמדה הולכות
        ומתנונות, ועוד מעט לא ישאר להן זכר.  רק החצילים והפלפלים עומדים בפריחתם
        וכל הטפול נתון להם.  צוברים זבל של תרנגלות לתוך דוד גדול, משרים אותו
        במים ושתי פעמים בשבוע משקים בנוזלים אלה את הצמחים הרעננים ועמוסי
        הפרי.  גלעדי קוטף בשמחה מלוא הסל חצילים ופלפלים מתוקים ונושאם אל
        המטבח.  כלתו אינה מרוצה מכל השפע הזה, – היא אינה אוכלת ירקות ואינה אוהבת
        את הכנתם – אבל יש לה די נמוס הפעם להלל את הפרי היפה. היא מוציאה הכל מן
        הסל ומחליטה, שרק חלק קטן תבשל והשאר תכין לתרנגולות ולפרות, כי בשוק אין
        מוצאים קונה לתוצרת עברית!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אמת, בתי.  בכל זאת אנסה להוציא את תוצרתנו אל השוק – אומר גלעדי ומתחיל
        לבחור את הפרי היפה ביותר ולסדרו בסל כדי להוציאו למכירה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא רואה העויה של אי-רצון בפני כלתו.  היא מתבישת שחותנה יעמוד כמוכר
        ירקות בשוק, הרי זה בעיניה סימן של עניות מנוולת והיא מתרגזת על כך.  בכלל
        היא נתונה לשנוים ברוחה – עליזות ועצבות באות אצלה חליפות.  גלעדי רגיל
        לחפש את הסבות למצב-רוחה וכמעט שאין דבר נעלם ממנו.  בשעת התרגזותה הוא
        סובל אי נעימות מרובה.  הנה לקח מן הקומקום מעט מים חמים לרחיצה,
        ולחדר-המרחץ מגיע דבור, שלא צריך היה לשמעו.  בנו דרש תה במטבח, וכלתו עונה
        שם בתלונה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– חכה מעט!  אבא לקח את כל המים החמים לרחיצה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >או יש שאחד מהם הולך לקבל מקלחת ומוצא את הדלת סגורה, אז נשאת באויר
        תלונה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אבא תמיד מקדים ולנו אין אפשרות להתרחץ.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לעתים נדמה לו, שהוא בכלל מיותר כאן ועליו לבקש רשות על כל דבר.  אין עוד
        ירידה אטית במדרון, אלא גלגול ישר מן ההר.  כבוד אב?  שטות, זהו דבר ישן
        נושן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובחטטו בנושא זה הוא מעביר בזכרונו בחיוך על פניו גירסא דינקותא: כשיצא
        הפילוסוף היוני לעולם לחפש את המאושר באדם, בא אל ארצות ואל מלכים ולא מצא
        את המבוקש.  גם קרזוס לא היה מאושר בעיניו, ונבא לו סוף רע.  רק בשובו
        ממסעיו ובבואו בשערי אתונה מצא את המאושרה בחלד, – שני בחורי חמד רתמו את
        עצמם במקום סוסים והובילו את גופת אמם המתה למנוחת-עולמים.  בכִבוד הורים
        כזה ראה הפילוסוף את האושר האמתי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ושמואל גלעדי – האם דורש הוא כבוד מילדיו?  רוצה הוא רק תשלום כל שהוא בעד
        אהבתו ומסירותו וקרבן חייו, שהקריב בשביל בנו יחידו.  כל הזדמנות לבנות את
        חייו מחדש דחה, על כל הצעה השיב בשלילה, ותמיד הכריעה מחשבה אחת:  – לו אין
        רשות לקחת אשה אחרת ולהביא עוד בנים שיקפחו את זכותו של זה.  וככה חתם את
        גורל בדידותו.  האם יש להתחרט עתה על מה שעבר ואין להשיב?  ומה חסר הוא
        עכשיו?  הלא גם בת יש לו בבית, ילדה נחמדה היא, שהוא אוהב אותה וחרד עליה,
        חרד על חולשתה.  לעתים קרובות היא נופלת למשכב, נאנחת ובוכה ולבו מתפלץ
        בקרבו. רוצה היה שלילדה זו, שהיא כבתו, לא יחסר כלום, שלא יכאב לה
        מאומה.  ובימי סבלה הוא מתמלא עצבות ויאוש.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יש שעולה בדעתו, כי חסרון ילדים גורם לה סבל ורוגז, והוא מתלבט
        בשאלה: למה?...  הנה כבר שנתים אחרי חתונתם ועדיין אין הריון...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא עוזר לה בעבודתה בגנת הפרחים, משקה ועודר, הנה מטפס צמח אחד, שהיא
        שתלה בתחלת הקיץ, ועולה באופן נפלא. הוא מסתכל בצבעו היפה, בעושר הכלורופיל
        שבעליו, ובדמיונו רואה הוא מה נהדר יהיה בגדלו, כשיתפשט משני עברי
        המרפסת.  הוא מתאר בהתלהבות של נוער באזני כלתו את חזון עתידו של הצמח הזה,
        אך היא אינה מתפעלת מזה כלל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– האם לא מרוצה תהיי, אלישבע, כשהמרפסת תתכסה צל והשמש לא תלהט בה אחר
        הצהרים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– כן, זה יהיה נוח ויפה, אבל אני רוצה הייתי שיחד עם זה נמצא גם מעט הרוחה
        בחיים.  הנה עובדים אנו עבודת פרך ופרוטה אין.  את כל יגיענו אוכלים
        הכרמים, מסי הממשלה והוצאות המושבה;  איזה חיים הם אלה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מה לעשות, בתי, אם שנות-משבר עוברות עתה בכל העולם?  אצלנו, כמובן,
        מורגש המשבר עוד יותר.  אבל אנחנו צריכים להתאזר בסבלנות, לחכות עד יעבור
        זעם, ואז ישתנה הכל לטובה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– סבלנות, סבלנות!  מיום בואי הנה אני שומעת רק פזמון זה, סלח נא לי, אבא,
        האם בסבלנות אפשר להשביע נפש רעבה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אבל איננו חסרים חלילה לחם, בתי, ורבים מאחינו בגולה, אפילו מהאמידים
        שבהם, היו מסכימים להמצא במצבנו כאן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– מסופקה אני מאד.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא הסתכל בפניה החורים והנרגזים וצר היה לו לראותה בכך.  כל חייו היה
        נותן בשביל מנוחתה של ילדה זו, אך ידו קצרה. במשך שנים ארוכות חלם:  הנה
        יגדל יפתח, ישיאהו לאשה ויהיו להם חיי רוחה על נחלה זו, שכל-כך הרבה יגיעה
        ועמל השקיע בה. חלומו לא נתקיים והילדים סובלים.  היא, הבת היחידה להורים
        אמידים, משתדלת להסתגל לדרך חייהם, אבל קשה לה, וקשה להם שבעתים לראותה
        בסבלה.  מה לעשות?  היכן המוצא מסבך זה?</span
      >
    </p>
    <p class="c2" dir="rtl"><span class="c11"> </span></p>
    <p class="c2 text-center" dir="rtl"><span class="c11">ב.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כל היום כפף גלעדי את גוו על המשתלות שהכין לחורף.  גבו כואב, כולו שבור,
        אבל לעזוב את עבודתו אינו רוצה, – עליו לגמור את מלאכתו בלי דחיה.  וכשגמר
        והעיף עין על כל מה שעשה במשך היום, נמלא לבו יהירות כאילו יצר יצירה
        רבת-ערך.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הופיע בנו השב מן הכרם ועזר על ידו לאסוף את הכלים;  וכשישבו שניהם לנוח
        על מדרגות הבית, פתח בנו בשפה רפה לדבר שוב על המצב, לבו של גלעדי נבא לו
        בשורה רעה, התחיל דופק בחזקה ופניו החוירו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  מה חדשות, יפתח?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  הרבה חשבתי על סוד הצלחתם של אחרים ולא מצאתי לו פתרון,  אבל דבר אחד
        גלוי וידוע לי:  אין הברכה שורה במשק שלנו, ועלינו להשקיע כחות למעלה מן
        הטבע כדי למצוא את צרכינו ההכרחיים ביותר.  מה שאחרים מכניסים בנקל,
        מוכרחים אנו לרכוש בעבודת-פרך ממש. אצלם נעשית העבודה בידי שכירים והם
        נהנים מרוב טובה, ואנו מיגעים את גופנו ואת נשמתנו וברכה אין  –  מהו סוד
        הדבר, אבא?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי פותר לבנו את הקושיות על-פי דרכו, אבל הדברים אינם מתקבלים על לב
        הבן, וסוף-סוף הוא מחליט לגלות את לבו: –  הוא ואלישבע החליטו לנסוע.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  לנסוע?...  לאן?  –  שואל האב בתמהון, לבו מתחיל מתרוצץ בו. </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"> –  אלישבע מחליפה מכתבים עם דודתה מאמריקה...</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">גלעדי השתדל להשתיק את סערת לבו ולדבר במנוחה :</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  אינני חושב, בני, שאתה תהיה מוכשר לחיי "ביזנס". צעיר כמותך, שמילדותו
        הוא קשור אל הקרקע, לא על נקלה ינתק ממנה.  סוחר-מוכר בלי שום הכנה לכך,
        אינו בטוח כלל בהצלחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  ואני חושב, –  עונה יפתח  –  כי במרץ שלי אפשר להספיק שם הרבה.  בכל
        אופן אני מקוה לפרנס שם את משפחתי ברוחה יותר משאני יכול לעשות זאת
        כאן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  ברוחה  –  אולי, אבל לא בבטחון- לב, בני!  אמנם אנו אוכלים פה את לחמנו
        בזעת אפים, אבל לבנו שקט ושלו.  יודע אתה, שהפנה הזאת שלך היא, הקרקע תחת
        רגליך שלך היא ואין מי שיכול לנשלך מעליה,  הנך יושב בביתך, במולדתך, מסייע
        בבנין היסוד לעתידנו, עתיד העם המפוזר והמפורד.  אינך בגולה, – אינך יודע,
        בני, מה היא גולה, מה הוא להיות זר ונרדף, בזוי ושנוא  –  בן בלי
        בית!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  באמריקה, ארץ החופש, אין היהודי מרגיש את עצמו בגולה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  לא, לא נכון, בני. אם גם שנאת היהודים עוד לא התפשטה שם כמגפה כמו
        בארצות אחרות, אך עקבותיה נודעו כבר, היא זרועה בקרקע ומעלה ניצנים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  למה לי להעמיק חקר, אבא?  אם יכול אני "לעשות חיים", ואני עודני צעיר
        ורוצה לחיות  –  למה לא אקבל את העזרה המושטה לי?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">פני גלעדי התעותו מצער, הוא הניע בכתפיו ואמר בהחלט;</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  אינני מסכים לנסיעתך, לא לשם כך הקרבתי את כל חיי בשבילך, שתעזוב את כל
        מה שבניתי בשבילך ותלך לנוד בנכר. האם בני, בני יבגוד באידיאל שלי,
        בשאיפותי, בחלומות חיי?! - וגלעדי הניח ראשו בין ברכיו ובכה כתינוק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומאז לא נשמע עוד בבית שום רמז לנסיעה.  וגלעדי נתעה לחשוב, שרעיון הנסיעה
        הוסח מלבות ילדיו, ועל-כן התחיל להרהר בתקון המשק, כיצד להיטיב את היבול
        ולתקן את כל צורת חייהם של ילדיו. כמה הוא אוהב אותם, כמה התקשר לילדה זו,
        שהיה רוצה לראותה מאושרה בביתו!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אך איכה יתחיל הכל מחדש אחרי עשרים וחמש שנות עבודה בכווּן אחד? אמנם
        הכוון הזה היה מוטעה, לא זה הדרך שצריך היה ללכת בו, –  כרמים בלבד, בלי
        פלחה, בלי אדמת זרע, בלי משק-בית מסודר סדור רציונלי, –  אך משגה היה. אך
        איך לתקן את המעוות, ואיך יתכן להבריא את מצב עובד-האדמה, בזמן שמסי הממשלה
        מוצצים את לשד העובד והמשטר כולו הוא רקוב, גנבות ורציחות מתפשטות בארץ,
        גונבים ומחבלים כל פרי, כל נטע רך שידים נאמנות טפלו בו ועמל האדם הוא
        הפקר?!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מלחמה תמידית לו עם הגנבות בכרם ובבית.  מכרם הזיתים נגנב אשתקד כל היבול,
        טרם התחילו במסיקה.  הוא ובנו התכוננו לצאת לעבודה למחרת חג הסוכות, והנה
        ביום האחרון של החג, כשהשומר נשאר במושבה  –  נגנב כל הפרי עד תומו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כאן בגנה שעל יד הבית צריך לשמור יומם ולילה על מעט עצי הפרי, כי פועליו
        הערביים של שכנו הפרדסן לוטשים תמיד עיניהם לגנה.  הם שבים מן העבודה בשעה
        מוקדמת, מכניסים כליהם למחסן של השכן ומתגנבים אל גנו הוא, קוטפים במהירות
        מלוא כנף חלוקם פירות וירקות ומתחמקים באין רואים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הגנב הוא השליט על כל עמל האדם –  והשלטון נותן לגנבים חופש עד שיתחנך
        מעצמו.  האם אין הצדק את בנו, באמרו כי בתנאים אלה הולך מרץ האדם ופוסק
        לגמרי?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי מעוה פניו וחורק שן מתוך יאוש שתקפו: –  אֵי הבטחון הגדול שהיה בלבו
        לפנים, כי לא רק בית יבנה לו פה העם השב לתחיה.  אלא מדינה יקים לו כאן על
        אדמת אסיה עם גבולות רחבים וסגולות עושר גדולות?  ועוד האמין, כי יבוא העם
        בהמוניו להתישב בארצו, כי דרך אחרת אין לפניו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">ועתה מה עלתה להם לחלומותיו ולתקוותיו?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לפי בקשת כלתו תקן גלעדי  את הלול, אחרי-כן השקה את המשתלות ונכנס אל הגן
        לנוח.  אבל כאן מצא פנה, שלא היתה יפה בעיניו ויאמר להביא בה קצת סדר.
        התחיל להעביר אליה עציצים ממקומות אחרים.  תקע עמודים וקשר בהם ענפים רכים
        שיסתלסלו ויכסו על הפנה הריקה.  הענפים נמשכו למזרח, והוא מתָחָם דוקא
        למערב, מותח וחושב כי מעשה אכזרי הוא עושה והטבע בודאי יענוש אותו על עול
        זה, כי אפשר שהענפים ייבשו וימותו, ובכל זאת הוא ממשיך לעשות את שלו ואינו
        מניח את הענפים מידו, עד שהוא משרה צל ויופי על הפנה כפי רצונו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנה שם המטפסים ששתלה כלתו, כבר הספיקו לכסות חלק מן המרפסת וגלעדי רואה
        את גדולם המהיר ואינו נהנה הרבה, משום שבדימיונו הוא מתאר לו את מראה הצמח
        הזה בעתיד, שדרכו לנבול ולכמוש בימי החורף.  חבל שנטעו מהמין הרע
        הזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכנראה שנכד עתיד לבוא... כלתו חולנית, מקיאה הרבה... יש שהיא בורחת פתאום
        מהשלחן אל חדר הרחצה, אחרי-כן אל המטה, שוכבת שעה קלה ושבה לעבודתה כשפניה
        מלאים סבל, הוא רוצה להקל עליה מעט, מנסה לעזור על ידה, אבל היא נרגזה וקשה
        למצוא דרך לעשות את רצונה.  לבו דוי ואינו יודע במה יהיה לה לעזר. פעמים
        היא מזכירה לו בחולשתה את זו, שאבדה לכל ימי חייו והוא מתמלא חרדה
        לעתידה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשמתגלגלת השיחה על המצב הדחוק ואלישבע בוכה, מכאיב לו הדבר מאד והוא
        מרגיש צער ועלבון.  הלא ברורה הסבה: הוא הנהו רק עובד חרוץ, אבל הבנת-חיים
        אמיתית חסרה לו.  והבנה עולה, כנראה על חריצות, הנה דודו: בא, ראה
        ונצח.  התאכר ומצליח, אף על פי שלא נסה לעבוד אף יום אחד, צורת מעדר אינו
        יודע, ובבואו לשדה אינו מבחין בין עשב לעשב. גם את היבלית אינו מכיר,
        וכשהוא משגיח על פועליו בשעת עקירת היבלית, תופס הוא לפעמים איזה גבעול
        פשוט וגוער בערביים על השאירם יבלית בתוך ה"בַּחְר".  הוא טעה והם,
        הערומים, מחַיכים בחשאי ולועגים לו, אבל מעמידים פנים רציניים ומלמדים אותו
        מהי יבלית ואיך להכירה בסימניה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דודו הספיק כבר להתחיל בנטיעת פרדס ולו אסם מלא תבואה.  אינו קונה לא
        שעורה ולא חטה ולא תבן, גם מחסן מלא בַקיה מוכן לשנה, וכל זה הוכנס על ידי
        ערבייו הרבים.  ולא רק מתבואות השדה יש לו רב, אלא רואה הוא ברכה גם
        בכרמיו, אף על פי שאינו יודע כיצד זומרים גפן או איך גוזמים עץ.  הכל מצליח
        אצלו מבלי שהוא עצמו ינקוף באצבע קטנה.  למה, אפוא, חריצות-כפים, אם אדם
        יכול לעשות חיל רב גם בלעדיה?  מה יועילו הנסיון, החריצות, החקירה, אם
        הבנת-חיים אמתית חסרה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"גבר לא יצלח, שום דבר איני יודע עד תכליתו"  –  מוכיח גלעדי את
        עצמו.  שואף הוא לדעת את הטבע, אבל במדה שהוא קרוב לטבע, הוא גם רחוק ממנו.
        הלא תמיד בא הוא במגע עם הצמחים ועם מחלותיהם השונות,  אבל איך לרפאותם
        אינו יודע. הנה התחיל נובל עץ אחד, שאך זה עמד בהדרו,  וגלעדי אינו יודע מה
        היה לו לעץ. מדוע בחרו הטפילים בעץ זה ולא בחברו, הנמצא במצב גרוע ממנו,
        –  האם אוהבים המזיקים האלה רק את החזק ואת היפה? יש לו הרצון לרפא את העץ,
        אך מפקפק הוא אם לזרוק עליו גפרית, או מי-נחושתן, או להתיז רק את הענפים
        המנוגעים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובעמדו תוהה מבלי דעת במה לבחור, הוא מתמלא תרעומת על עצמו:  למה לא למד
        ולא רכש לו הכנה כדרוש לחיים החקלאיים שבחר?  הרי לא פתאום הלך
        לארץ-ישראל,  ומדוע לא השתדל להכשיר את עצמו כראוי?  עתה לשוא הוא חוקר את
        הטבע, מאום לא יעלה בידו;  על כל תופעה הוא עומד תוהה, מסתכל ומתבונן ואינו
        בא לידי שום מסקנה ברורה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התרשמותו מכל דבר מגיעה לפעמים לידי גחוך. –  הנה שתה מעט מים, ופתאום חש
        בחילה, מפני שנזכר בחידקים הרבים שבמים, –  הרי טפה אחת מהם כוללת בתוכה
        רבבות בריות מיקרוסקופיות, והוא בגמיעה אחת בלע המון בריות חיות אלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יונה הומה בשובך והוא מתעכב ושואל את נפשו: הקוראת היא לבן-זוגה שישוב
        אליה או מתקשה היא בחמוּם ביציה שהטילה?  או אפשר היא מטפלת כבר בגוזליה
        באהבה ומביעה את רגשות האם בהמיתה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בעץ האקליפטוס הענקי שאצל שער אורותו הוא מסתכל:  מה רב הדרו כשהוא פושט
        את ענפיו הענקיים על שטח גדול של החצר.  ומה יפים הפרחים הלבנונים, בעלי
        השפם, העונדים כזרים לבנים את יונקותיו! מדוע עץ בודד זה מכוסה פרחים יותר
        מאחרים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה צד הערוגות השתולות שתילי כרוב ירוק כחלחל, התעטף צל עם השקיעה ומשרה
        סוד על הסביבה.  גלעדי נועץ את עיניו הפקוחות לרוחה במערב המאדים. מרוץ
        דמים חזק עובר מקצה אצבעותיו עד צדעיו ונפשו כמֵהָה לדבר בלתי ברור
        לו. פתאום תוקפת אותו עצבות רבה והוא רואה את עצמו בדד ונבדל מכל העולם,
        שונה מכל אלה שחייהם קלים וטובים. הם אנשים מנוסים, עושים הכל בשביל עצמם
        ודוחים באומץ לב כל מה שעומד להם למפגע בדרך הצלחתם.  אנשים אלה כח ושלטון
        בידם ליצור להם מעמד, ולכן קל להם וטוב להם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והוא?  גבר לא יצלח הנהו, אשר לא יצא ידי חובתו לא כלפי האידיאל שלו ולא
        כלפי עמו.  בכל עבודתו הקשה במשך שנים ארוכות לא הביא תועלת לא לעצמו ולא
        לארץ, כי את דרכו לא מצא.</span
      >
    </p>
    <p class="c9" dir="rtl"><span class="c3"> </span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הגיעו ימי הסתו, גלעדי מוצא קורת רוח בצבעי הבוקר החורים והקרירים ובמראות
        האודם של בין הערבים. באמצע היום בוערת השמש כאש וכל עבודה קלה מיגעת
        אותו.  הוא דוחה על-פי-רוב את העבודות הקשות לשעות הבוקר או הערב, הנותנות
        לו חזוק ומרץ.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בבוקר של סתיו מתעורר הכל בתקפה לחיים.  הנה הצפרים מרננות בחוזק כזה,
        כאילו הן מכריזות חג בעולם, התמימות! אינן יודעות שהן צפויות לחורף
        הקר,  אינן רואות את העננים המצטברים כבר במזרח ומבריקים כשיש לבן,
        המעַורים את העינים בזהרם.  עוד ימים מספר וכל אלה יקדרו ויהפכו לרועץ
        למשוררות-הבוקר העליזות!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ישנם בקרים שבהם נדמה כי הנה החורף כבר בא. –  השמש מסתתרת, השמים נעשים
        אפורים והאופק צר וקרוב. יש שערפל עולה, זוחל ומתקרב ומשוכות הכרמים עוטפות
        מעטה אפור המחתל את כל המרחקים, בולטות רק במקצת השומירות הלבנות במרומי
        הגבעות וענקי העצים שבצידי הדרכים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >את לבו של גלעדי ממלאה זה ימים אחדים הרגשת צפיה נעימה ובדידותו כאילו
        מסתלקת לרגעים ממנו:  כי למושבה באה ידידתו מושה גברילוביץ לבַקר את אחיה,
        והוא נפגש אתה תכופות.  עתה הוא כולו צפיה לקראת הפגישות הללו. הנה עובד
        הוא בכרם, ופתאום מן העמק, בשביל הצר שבין הכרמים, עלה ובקע קול שירה ערבה.
        הוא הכיר את קול שושנה, בת אחיה של מושה גברילוביץ, וידע ברור, שגם היא
        מטילת שם עם הנערה.  הניח את עבודתו ואסף את הכלים אל המלתחה. בדרך קוה
        לפגוש אותה, אך לדאבונו נטו שתי המטילות מן הדרך, שהוא היה צריך לעבור
        בה.  רגש הגעגועים תקף אותו, בעמדו על פרשת הדרכים ובלוותו במבטו את אחת
        משתי הנשים, ההולכות שלובות-זרוע ושמשיה אחת סוככת על ראשן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >פנה ללכת הביתה בדיעבד מרוצה שלא נפגשו עתה. הרי לבושו אינו נקי וכובעו
        אינו הגון ביותר. גם עיף הוא מאוד, ובודאי לא על נקלה היה מוצא נושא
        לשיחה.  רגיל היה לדבר אתה דברים המענינים אותה, גם משתדל להביע את
        רעיונותיו בסדר הגיוני, אבל לא תמיד עולה הדבר בידו, וכשהוא מתחיל להרגיש,
        שדבריו אינם מבוססים יפה על ההגיון, הוא מבקש מתוך מבוכה מבטאים יפים כדי
        לחפות על העדר ההגיון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אינו יודע מה היה לו הפעם. הרי זו לא אהבה, –  בשנותיו מגוחך לחשוב על
        כגון זה, אבל יכול להיות שבאֵפר נשמר איזה גֵץ מימים עברו... שוטה
        שבעולם!  –  סונט הוא בעצמו  –  האם בגיל כזה מחפשים עוד אהבה?  צחוק הוא,
        צחוק מכאיב לב.  חייו אבדו זה כבר את לֵחם ואת זהרם, ועכשיו הוא מתנהג שלא
        כשורה.  אין הוא צריך להפגש עם אשה, שהיה לו פעם זיק של רגש אליה.  אלמנות
        ממושכה, ארורה, אכלה את לשדו. תמק גם נפשו ברקבונה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובאותו רגע החליט לא לבקר עוד את מושה גברילוביץ, לא לראותה כאן. אבל
        אחר-כך לא התאפק וילך לבית אחיה לבלות שם את הערב.  פגש אותה בצאתה וילכו
        יחדו לטייל לאור הירח בסמטה הארוכה, הגדורה משני עבריה שטה גבוהה, שריח
        פרחיה הקטנטנים משכר את העוברים. נושא שיחתם הפעם היה  –  זכרונות משנות
        המלחמה, שאז היתה מושה בחוץ לארץ ולא יכלה לשוב הביתה מפני הסגר
        הדרכים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא ספרה לו בפעם הראשונה פרטים על כל מה שעבר עליה שם בגיהנום, שהתגלגלה
        אליו לפתע פתאום. שם מתה עליה אחותה בשנה הראשונה למלחמה ועזבה על ידה את
        היתומים הקטנים, את הגיס האומלל והחלש, אחרי-כן באו עליה ימים שאין בהם
        חפץ, שהיתה מקנאה באלה אשר כבר מתו. כי באו הפרעות ולעיניה עברו כל זועות
        התופת...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יום אחד, כשהשתתקה סערת הדמים והמתחבאים יצאו מחוריהם ויתאספו לבית-התפילה
        לטכס עצה יחד מה לעשות למתיהם, עברה פתאום שמועה, כי שארית של מחנה פורעים
        הולכת ומתקרבת.  קול יללה איומה נשמע בבית-התפלה בשעה שהדלתות חרגו
        ממסגורותיהן והשודדים התפרצו פנימה. מכל הנעשה אז אינה זוכרת כלום, רק
        תמונה אחת נצבת לנגד עיניה, זוהי היד עם הרובה שירתה בה, סחבה אותה בצמותיה
        והשליכה אותה החוצה... כשהתעוררה ראתה את עצמה בבית-חולים מואר ואחיות
        רחמניות עומדות סביבה. בימים של הפרעות הראשונות בעירה הומת גיסה, הומתו
        שנים מן היתומים ואת הילדות הנותרות הלא הביאה הלום.  הן גמרו שתיהן את
        בית-הספר לאחיות ועובדות בשרון אצל "הדסה".  גם לגלעדי היה מה לספר מהימים
        ההם.  –  היו תקופות שבהן היה לנגד עיניהם רק פחד ומות מכל העברים.  נדמה
        היה, שהנה נופל כל הבנין בארץ ולא ישאר ממנו זכר, אז התפלל הלב לימים
        יבואו, שעולים חדשים ימלאו את המחנה ההולך וחסר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  פסח תרע"ז ותחילת תרע"ח  – היודעת היא מה עלתה אז לישוב?  לפני פסח
        התחיל חורבן הישוב העירוני ולפני חג האסיף  –  חורבן המושבות. על מושבתנו
        נשפכה תחלה כוס הזעם, כי אחדים מתוכה נלכדו ברשת הרגוּל והקצף יצא על כל
        המושבה ועל כל הישוב.  נאסרו טובי העדה והובלו בידי שוטרים תחילה למאסר
        ברמלה, אחרי-כן בירושלים.  אחריהם נאסרו לאט לאט עסקנים צבוריים מכל קצוי
        הארץ, יום יום וקללתו מרובה משל חברו. ביהודה אנחה ובגליל זעקת שבר, אוסרים
        אנשים נקיים מפשע.  מענים ומכים אותם ופוקדים עליהם לגלות את סוד
        הרגול.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  ואני זוכרת  –  אמרה מושה גברילוביץ   –  שלפני המלחמה התיחסו אלינו
        התורכים בערך יחס יפה.  בכל שיטתה הגרועה של הממשלה מורגש היה, שאנו משמשים
        איזה כח חשוב בארץ.  היה אלינו יחס של כבוד, אולי מפני כסף הברון שזרם אל
        הארץ, אבל ביחס זה יכלו לעשות הרבה, לו היה העם שבגולה רוצה בלב תמים בישוב
        הארץ.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  גברתי!  בשנות המלחמה השתנה יחס התורכים אלינו לרעה, בפרט למן העת
        שהתחברו עם הגרמנים.  רק ירד צלה של גרמניה על הארץ ומיד השתרר בה
        החושך. גם כל שיטת המאסרים ההם מידיה באה לנו.  ואת מי אסרו כבוגדים
        במולדת?  את זקני הישוב, את טובי הבונים!  הושם עליהם משמר חזק, ששום איש
        לא יגש אליהם ולא ידבר אתם.  גם לאם, לאשה ולבת אסור היה לגשת לראות את
        פניהם, להחליף עמהם מלים.  ותארי בנפשך, גברתי, את הפחד הנורא, שתקף אותנו
        באחד הימים, כשהגיעה פתאום שמועה, כי הממשלה החליטה לדון לתליה אחד מכל
        עשרה נתפסים, ובָמַת המות תערך לעינינו בתוך המושבה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  אני מתארת לי מה גדולה היתה החרדה במושבה  –  אמרה הגברת מושה מתוך
        רתת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  אין לי די מלים, גברתי, לתאר מה שנעשה אז. נשים חלו, גברים התהלכו באין
        אונים. רק יחידים, שהאמינו בנס, עודדו את האחרים.  גם בלילה נדדה שנת
        הגבורים האלה, ואם נרדמו לשעה קלה, היו מתעוררים בפחד, כי נחלי דם ראו
        בחלום וגופות אסיריהם טובעים בו...  היו אנשים שנתיאשו לגמרי ושאלו את נפשם
        למות. יהודה והגליל קברו חלק מבניהם ואשרי המתים שאינם רואים בחורבן!  היה
        אז הרצון להמוג, להיות לאפס. המח לא תפס עוד כלום, וזכרוני שפעם אחת, במשך
        שלושה ימים רצופים, לא ידעתי מה היה לי, ובאופן מיכני רחשו שפתי קטע
        תפלה: "אתה עוזר ומושיע ואין לנו מלך אלא אתה!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מושה גברילוביץ צחקה מתוך דמעות, וכשהופנתה שיחתם על מצב הישוב בזמן הזה,
        האשימה את אלה היושבים בגולה על סיר הבשר או על הלחם הקלוקל ואינם רוצים
        לזוז משם.  בשעה שהמשטר התורכי היה בארץ, היה תרוץ, שהוא מעכב את
        העליה.  אבל באמת יכלו ליֵשב את ארצנו ולהציל את הרבבות, שכלו אחרי כן
        בפרעות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">הוא הצדיק את עמו:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  כחות אין, הגלות אכלה אותם, ומאין ימצא העוז להקים בנין הרוס זה אלפים
        בשנים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עבר חג האסיף, סוכת בית גלעדי היתה נפלאה, -  צעצוע ירוק בתוך גן חמד. אבל
        לא שמח היה הלב.  כל ימי החג נשמעו תלונות מפי אלישבע על בריאותה, על
        החובות ועל חיי הדוחק, היא אינה יכולה עוד לשאת דחקות זו, –  לחג לא עשתה
        כלום, אפילו כתונת חדשה ליפתח לא תפרה. עכשיו יבוא חורף, נחוצים נעלים
        ובגדים חמים  –  ופרוטה בכיס אין...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סוף כל התלונות היה הנושא הישן: נסיעה לאמריקה!  דודתה מוכנה לשלוח להם
        כרטיסי-נסיעה וכסף להוצאות, ומדוע ישארו כאן ויבולו בלא עת?  שומע גלעדי
        ולבו מתפלץ בקרבו.  מנסה הוא להתוכח ולהראות בעליל, שתקופת המשבר בארץ
        מתקרבת אל קצה, והמצב ישתנה לגמרי, אפשר יהיה להשיג כספים, להגדיל את המשק
        ולמצוא רוחה.  התוכחו גם הם והרוגז גדל משני הצדדים.  הוא מסתלק אל חדרו
        בלב מלא צער נוקב. היה לו הרגש כאילו על מוחו לוחץ מוט ברזל והכתפים כופפות
        את הגוף הרצוץ. הוא יושב ואוחז את ראשו בידיו; מביטות העינים אל נקודה אחת,
        רואות את כלי החדר ואינן מבחינות. מה הוא רוצה מן החיים?  רק מעט שלוה, מעט
        מנוחה  –  האם רק בקבר ימצא את אלו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בכל יום ויום יוצא הוא אל הכרמים ומחפש לו עבודה לשַכֵּך את צערו.  גם
        בשעת הצהרים נשאר על-פי-רוב בכרם, יושב בצל העץ לאכול את ארוחתו  – ביצים,
        זיתים וחלב.  ברוך השם, ביתם אינו חסר אוכל טוב ומבריא. בעת האכילה כשהוא
        מרגיש בעלים הנושרים על פניו, הוא מתחיל להרהר ב"שלכת". הנה העלים הרכים
        אינם יכולים לעמוד בפני הרוח, בעת שהעץ אינו נותן להם את החומר הדרוש
        למחיתם, והם נושרים ומתפזרים ונרקבים –  האם ככה ינשאו ברוח גם הם,
        ילדיו?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בשובו לעת ערב מן הכרם, אין לו פנאי להרהורים  –  עבודה רבה מחכה לו
        ברפת.  הוא מהר בבוקר ולא הספיק לנקותה.  הנה כאן בפנה התאסף גל גדול של
        זבל וצריך להוציאו אל המזבלה, טרם ישוב יפתח עם הסוסים מן השדה. כשגמר
        להוציא את הזבל, עמד והתפלא לכל ה"הון" שהתצטבר כאן במשך הקיץ. הוא קורא
        לזבל "הון" ולועג לעצמו, שהנה אנשי מעשה צוברים זהב גם בארץ קטנה ודלה זו,
        והוא צובר זבל ושומרו, וזה נקרא בפיו "הון"...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התחילו שבות הפרות מן השדה והשמחה רבתה ברפת. בפנים יפות ביותר מקבל גלעדי
        את "זעירה", זו הקטנטונת שנשארה להם לפליטה משנות הרעה ועכשו העמידה כבר
        דור חדש  –  את "בכורה" ו"אהליבה", שתי בנות הנותנות חלב במדה הגונה, אבל
        האֵם עדין עולה עליהן, ואותה הוא מכנה בחבה:  "הזנה והמפרנסת שלנו". היא
        אינה בררנית כצעירות, וכל שיירי אוכל, שהוא מאסף מהסוסים והפרות הצעירות,
        היא בולעת אותם ברצון והכרת תודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >את החלב יחד עם קדרה מלאה ביצים, שאסף מארגזי ההטלה, הכניס הביתה וימסור
        בהנאה לכלתו  –  האם יש ראיה טובה מזו לשובע השורר בבית?  חשב בהגישו לה את
        ברכת המשק הביתי, אבל היא נטלה את כל זה בלי רצון, כדברים שאין להם
        ערך.  מכאיב לו היחס הזה, אבל הוא סולח לה, הכל הוא סולח, מפני שהוא אשם
        בעיניו בזה שלא יכול לתת להם חיים טובים מאלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בשבתו אל השלחן לשתות תה ובעברו על הטלגרמות בעתון, קפץ כנשוך לקריאת אחת
        מהן, המספרת על פרעות ביהודים באחת הערים הגדולות במערב אירופה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >- הנה, יפתח, קרא את הטלגרמה הזאת! גם במרכז התרבות מכים אותנו ושופכים את
        דמינו, והדור הצעיר כאן עוד אומר ללכת אל הגולה, להתענות שם –  אוי לאותה
        בושה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">הבן לקח את העתון, עבר על הטלגרמה ואמר:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  ראשית, כתוב כאן, שהמקור אינו בטוח, ושנית הלא באמריקה אין אנטישמיות,
        אין שם פחד פרעות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ושוב וכוח ישן נושן, שוב הצעות מצד האב ותשובות חמורות מצד הבן:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  חלומות שוא!  אין כסף!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היסעו?  שואל גלעדי את עצמו בחרדה, בפניו הם נזהרים לדבר על תכניותיהם,
        אבל הוא מרגיש ברור כי היריעה נארגת. האומנם ישאר בדד בפנתו? ומה יעשה
        בלעדיהם  –  היקבר חיים בתוך בדידותו?</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כל הארץ בקשה רחמים על הגשם שאחר לבוא. היו ימים שבהם התחילו לעלות עננים,
        אבל בלילה שבו השמים והטהרו. התפלל העולם לגשמים והשמים בבהירותם לעגו
        לו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סוף-סוף באו סערות חזקות, נשיאים ורוח  –  והגשם הראשון ירד לשמחת כל
        יושבי הארץ.  בשדות מסביב התחילה התנועה.  הנה פלח ערבי עובר כבר בשדה עם
        זוג שוריו, וגלעדי מרמת כרמו רואה את החורש, מקנא בעבודתו הזריזה
        וחושב:  מה שמח שם החורש בפתחו את החריצים הראשונים אחרי החורב של ירחי
        הקיץ, אשר לא נראה בהם אף גבעול ירוק אחד בשדהו. הוא מרגיש בחרישה לא רק את
        התענוג של חזיון הצמיחה העתידה, אלא גם את הברכה שבמעשה ידיו:  הנה הוא
        הולך לזרוע ולהבטיח את קיומו וקיום בני ביתו לשנה תמימה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי מלוה בעיניו את הפלח ומחרשתו, ומשער את מהלך מחשבותיו: הנה הכין לו
        במטמורה עשר איפות חטה. הוא יפלח מהר את אדמתו, הנה בשמים עוד פזורים
        עננים, יבוא גשם שנית וירוה את האדמה;  אחרי שבועים יחרוש שנית ויזרע את
        השדה. אללה, אל רחום יתן גשמי ברכה לרוב ויפרה את שדהו. עם אביב תתמלאנה
        השבלים, בתחילת הקיץ תתבשלנה והוא יקצור ויקצור...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וגלעדי הוזה וחוקר עוד בהרהורי לבו של הפלח המאושר, החורש ושר לו את שירת
        לבבו:  ואם תהיה שנת ברכה, שתכניס לו רוָחים רבים וכיסו יתמלא לירות
        מזומנות, יוכל סוף סוף לקחת לו את היפה בבנות, את זהרה, כצרה לאשתו פטמה.
        הרי זו עקבות נעוריה נמחקו זה כבר והזקנה קפצה עליה.  –  איזה טעם לחיים
        בחברת אשה יחידה, שכבר נס לחה?  את זהרה הוא מוכרח לשאת, זהרה עיניה כברק
        נוצצות, זקופה היא כמגדל רַמְלה, וכולה אומרת חמדה ותאוה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יא ליל, יא ליל!  –  משתפכת שירת המאושר על כל הסביבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דין, דין!  –  מצלצל פעמון המושבה לשעת הצהרים וגלעדי גומר את גזום העץ
        ויושב בצל משוכת הכרם לנוח ולאכול את ארוחתו. כלה לאכול, פשט את בגדו על
        הארץ ושכב לנוח.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא הוציא מכיס חזיתו מכתב, שקבל אמש ממושה גברילוביץ, פתח אותו וקרא בו
        שנית. תחילה היא מציעה את בקשתה לסדר שני צעירים בעבודה אצל אכרים, הם באו
        זה לא כבר, למדו בחוץ-לארץ חקלאות, רוצים להתמחות בעבודה, אחרי-כן יקנו להם
        נחלה. היא מקוה שבידו יעלה לסדרם על הצד היותר טוב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"היותר טוב!"  –  לועג גלעדי במחשבתו  –  במושבה זו, שעבודה עברית עודנה
        כמעט בחרם  –  היא מקוה לסדור על הצד היותר טוב!  אינה יודעת כלל את
        התנאים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">והוא קורא הלאה דברים מרים על המצב בארץ:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"אם נתבונן בעין פקוחה אל היחס אלינו בארץ זו מצד הממשלה המנדטורית יש
        לחשוש, שכל חלום התחיה שלנו ישאר אך זכר יפה בלבד, כמו כל זכרונות העבר
        בתקופות הגבורים שלנו.  האם הרגיש מר גלעדי את עלבוננו הנורא בימים
        האחרונים?  עיני שכנינו צרות גם בשרידי האבנים הקרות של הכותל המערבי
        ומרשים לעצמם להתעלל ברגשות הקדושים של הבאים להתפלל שם ולשפוך את שיחם
        לפני בוראם.  מי יודע אם לא ככה יהיה גם כחנו בבנין הבית הלאומי.  ואולי
        יהיה, חלילה, גם סופו רק אבנים לזכרון, אבני תפארה, שהדורות הבאים יבואו
        להתפלל ולשפוך דמעות לפניהן. אלה יהיו שרידי החורבן של המאה
        העשרים!  –  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"ישנה עצה אחת  –  לקנות את הקרקע בכסף, לכסות כל שעל אדמה בזהב, ולסתום
        בהון עצום את פיותיהם של הצוררים. –  הימצא הכסף?  הימצאו הכחות?  ואנחנו
        הלא עם חלש, שתוכנו רקוב, קנאה ושנאה אוכלות לשדנו, מיבשות לחנו. ההתפלגות
        וההתפרדות מביאות כליה על גופנו".</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי לא הניח את המכתב, הוא עין ועין בו מבלי לקרוא.  בזכרונו עלה הערב
        שבו נסעה מושה מבית אחיה, הם נפגשו אצל מכירים והוא הלך ללוותה הביתה. חושך
        היה בחוץ, העננים כסו את כל השמים. היא היתה לבושה סודר רחב, וכשלקח את ידה
        תחת זרועו, הרגיש כעין רעד קל בגופה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  האם קר לה?  –  שאל בעדינות.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  לא, חם לי וטוב לי, –  אמרה מתוך תנועה שובבה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בשעת פרידתם נשק לה על מצחה. זוהי הנשיקה הראשונה במשך חצי יובל
        השנים,  אשר הם מכירים ומכבדים איש את רעותו.  למחר בקש תואנה להמצא ברחוב
        בשעת נסיעתה:  לבו דפק דפיקה קלה בעמדו על ידה ונדמה היה לו, כי היא השתדלה
        לא להביט ישר בפניו.  האם חטא הנשיקה גרם?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דין, דין!  –  שוב קורא הפעמון לעבודה וגלעדי מתנער מהזכרונות ומהחול שדבק
        בו בשעת שכיבה והלך עם מזמרתו אל השורה שעזב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בערב, כשגמר את עבודתו ברפת, נכנס אל חדרו, פרש את המכתב על השלחן וישב
        לענות עליו:  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"לא כדעתה דעתי, גברתי!  לבי מלא אמונה בעתיד ישובנו. הרי אפשר להראות
        בעליל על הקִדְמָה הנפלאה בכל ענפי עבודתנו. העם זז, העם הולך, מיטב הנוער
        עולה אל הארץ, ועבודתם היא כבוש, גאולה.  עוד אלה הולכים ואלה באים,
        בשלש-עשרה נפה יתנַפו העולים וגם ילידי הארץ עד שישארו האמיצים, נכוני היד
        והלב, ואלה יעמדו בשורה, יסיעו את האבנים הכבדות ולא ירתעו אחור...."</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רגע עמד לחשוב והנה הרגיש בעֲלֵי פרחים לבנים הפזורים על שלחן כתיבתו. הוא
        אסף אותם ולבו מלא תודה לילדתו, שהעמידה אצלו את הצרור היפה, אחז את העלים
        הריחנים בחפנו ומחשבתו נשאה לעבר הרחוק.  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עיר הפלך משתטחת על נהר רחב ידים. יום יום עובר הוא שדרת שטות בדרכו אל
        משרד עבודתו. אביב היה בארץ, השטות לבלבו והפיצו ריח עדין על כל הסביבה.
        בימים ההם נפגש עם זו, שקשרה את נפשה בנפשו והחליטה ללכת אחריו
        המדברה...  מסכנה!  כחותיה לא הספיקו  למלחמת החיים הקשה ותפול חלל בראשית
        דרכם, ותעזבהו גלמוד באפלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לאטו צעד הישוב קדימה;  רק עתה התחילה תנועה מהירה.  הוא רואה הכל כעגול
        ההולך ומתרחב, ובו הוא מחפש את נקודתו...  כאן היתה ההתחלה, כאן מקום
        הראשונים לבנין, הם נשארים במרכז תמיד, סביבם נוסף קו אחר קו, העגול הולך
        ומתרחב, ההיקף הולך וגדל והנקודה הראשונה המרכזית מתרחקת יותר ויותר, עוד
        מעט והעין לא תתפסנה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >נקודתו הוא איננה במרכז, והיא כה קטנה ודלה עד שהיא אובדת בתוך העגול והוא
        עצמו מצטמצם ומביט משם אל ההיקף בהתרחבו.  שונאינו אומרים לעכב, אבל הדבר
        לא עלה בידם, אף כי הם הרבים וכח עריצים בידם וגזל דרכם. בכח המוסר ובשאיפת
        הצדק שלנו נעמוד נגדם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל לא תמיד מאירה האמונה את דרכו. הנה התגנב פתאום הצער הגדול אל לבו
        והוא עוצם את עיניו ונושך שפתיו:  רב האור מסביב, אך על חייו הפרטיים עולה
        עננה כבדה...  הילדים אמנם אינם מדברים בפניו על נסיעתם, אבל הם מתלחשים
        תכופות ולחש זה מנבא לו רעה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי נקרא לישיבת בוקר של הועד, אבל הפעם דחה את ההשתתפות בישיבה מפני
        עבודה דוחקת בכרם, –  הוא ובנו החליטו להכין את הגומות סביב לעצים, ונגשו
        לעבודה זו במרץ.  היום היה חם, האויר היה מחניק ממש, השמים צבע משונה היה
        להם, ולא השמש להטה, אלא האויר עצמו כמו הוסק באש. יפתח עבד בזריזות רבה
        וגלעדי הביט בו מדי רגע ורגש של גיל המה בלבו.  זהו פועל!  –  אמר
        לנפשו  – הרי זוהי מנגינת העבודה!  לפנים יכול גם הוא לעבוד ככה, ועכשו
        הסתלקה המהירות בעבודה. כנראה שהוקצבה מדה לכח הזרוע, כי בעבור אדם גבול
        חיים ידוע, מאבדים השרירים את עוזם.  ואפשר מאד שקרוב היום, אשר לא יהיה
        עוד ביכלתו לאחוז באת, היד תרעד מחולשה, וכשיצא לכרם בשעת העבודה, רק יביט,
        יסתכל, ומאום לא יעשה. בזה תשלם הטרגיות של חייו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כמדומה לו, שהוא קרא באיזה מקום, כיצד מתחילה אצל האדם ירידת הכחות
        וכניסתו לשיבה. הקרוב היום?  אינו יכול לתאר לו מה יהיה כשתגיע השעה, והוא
        לא רק לא יוכל לעזור לאחרים, אלא יהיה זקוק לעזרתם  –  הרי זה רעיון
        מבהיל!  ולא המות עצמו מפחיד, אלא הכליון בתוך ההויה, לפני שעוברים את
        הקו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  אבא, מה המחשבות, שאתה שקוע בהן היום?  –  שאל אותו בנו, כשנפגשו בתוך
        השורה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  חושב אני על חיי אדם  –  היכן בהם נקודת-הירידה?  וקשה לי לסמן את
        התחום. ירידות ישנן בכל הזמנים, אבל קשה לאדם אותה הירידה, שבה הוא מוכרח
        לרכז את מחשבתו, כיצד להנשא הלאה מבלי להיות למשא על אחרים, על
        זרים...  הוסיף בלחש ובעיניו עמדה דמעה, שהתבייש בה בפני בנו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הבן כבש פניו במעדר; רצה לפייס את האב עמוס הצער ולא מצא מלים בפיו.  הם
        שתקו שניהם, עד שהגיעה שעת הפסקת העבודה והלכו הביתה. האויר הספיק להצטנן,
        השמש עמדה במערב כבושה ונכלמה, חורת וקרה.  הצבעים הרבים הסתלקו.  הרקיע
        עטוף צבע אחד אפור-כהה כלובש בגדי אבלות. נעלמו הרי יהודה ממזרח, רק רכס
        אחד עוד בולט במעטה כהה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בקרבם לבית יצאה לקראתם אלישבע בשמחה ובידה מעטפה גדולה, –  זה עתה נתקבלו
        כרטיסי המסע!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לבו של גלעדי התחיל לדפוק בחזקה. הוא לא נכנס הביתה, הקיף את חצרו וירד
        בצעדים מהירים אל הכרמים שבמורד המושבה.  הוא לא נתן לעצמו חשבון לאן רגליו
        נושאות אותו. מלבו פג הכל, רק קור וקפאון יצוקים בו.  לאן הוא הולך?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >על כל הסביבה השתרר החושך בשעה שהגיע אל בית הקברות.  כאן שמע תנים מיללים
        בכנופיה. מאחת המערות של ההר משמיע צְבוֹעַ את קולו האיום, –  חיה טורפת זו
        דרה בקרבת בית-הקברות וזוממת בלבה להרגיז את עצמות הנחים כאן
        בשלום...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי לא היה מעודו בבית-הקברות בלילה. רעדה עוברת בגופו, והוא צועד באי
        בטחון, אוזר שארית כחותיו בעלותו ובחפשו את קברה של זו, שרק הופיעה על שמי
        חייו כמחזה תעתועים ונעלמה לעולם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא מצא את הקבר, גחן אליו ולחץ את מצחו אל האבן הקרה, לחצו בחזקה, כאילו
        אמר לנפץ את גולגלתו ולהתאחד אתה לנצח.</span
      >
    </p>
    <p class="c9" dir="rtl"><span class="c3"> </span></p>
    <h1 class="c10" dir="rtl"><span class="c14">ג</span></h1>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הם נסעו. למרות התנגדותו נסעו והשאירוהו יחידי, בדד, אומלל.  מה יעשה עתה
        במשקו בחייו  –  בשביל מי יעבוד ובשביל מה ימשיך קיומו העלוב?  בנו היה לו
        כעמוד אור בדרכו.  עשרים וארבע שנים נשא אליו את עיניו והאמין תמיד, שילך
        בדרך אשר  אביו התוה לו.  אמונה זו לא נכזבה עד שבא הנחשול הגדול וגרף את
        חמודותיו.  מה יעשה עתה, לאן יברח מן היאוש התוקף אותו בכל שעה?  הנה
        מחשבתו התחילה מתבלבלת לעיתים, המוח מתערפל, על ידיו ועל רגליו כאילו הושמו
        כבלי ברזל ולבו אחוז בצבת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בזכרונו עולות שגיאות אשר שגה בחייו, שגיאות אין מספר, –  מי ימנה את
        השלשלת הארוכה?  בלב שורטות ביותר אלו, שהשאירו פצעים לכל ימי החיים.  אין
        משגה בחייו שלא נענש עליו קשה.  הוא מאמץ כחותיו לתקן את המעוות, אבל יש
        שבעצם הרצון לתקן את מה שאינו ניתן עוד לתקון, מונחת השגיאה הגדולה
        ביותר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מפני כל אסון שבחייו הוא מתביש. הוא, גלעדי החזק, למוד הסבל, מתביש כילד
        שסרח ולקה בפרהסיה.  לפעמים מתענין הוא לדעת איך מתיחסים אחרים אל אסונות
        חייו  –  המשתתפים הם בצערו, או לועגים לו?  אף כי בעצם יודע הוא , שבני
        אדם ברובם אוהבים לעג וקלות-ראש, רק יחידים לבם  פתוח לצער זולתם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה מכאיב סכום כל השגיאות, הגדולות והקטנות יחד, שהשתרגו עלו על החיים
        והעיבו את אור הימים, מהי התועלת בניצוצות המועטים החבויים באפר, אם הנשמה
        כולה מתלבטת ביסוריה באין מוצא? </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ממרפסת ביתו נראית המושבה הטבולה כולה בירק.  שקט בה, לובן צח. לו היתה
        ככה לא רק מבחוץ!  הוי, הקהל ההוא שם, –  השונא הוא אותם?...  לא, לא אותם
        הוא שונא, אלא את הגלות, שנשארה טבועה בלבם, ומשם יוצא הבוץ והרפש לכל רחבי
        הישוב.  –  היכן המים הרבים להדיח את כל זה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל אין הוא צודק ביחס אליהם, כי בעיקר הוא הוא האיש הרע מכל.  היו לו
        לפנים יחסים טובים עם בני-אדם, היתה ידידות ומסירות נפש מצדו, עתה התנדף
        הכל.  עומד הוא מרחוק ומקשיח לבו.  אמנם יש לעיתים רצון לשוב ולהיות רך
        וטוב כמו שהיה לפנים, אבל יכולת אין.  הפכים בקרבו:  גודל-רוח וקטנות,
        פרישות וכמיהה לאהבה, פשטות ויהירות, –  הוי, כמה הוא שנוא על עצמו!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >חושב הוא שנדחה מן החיים, אבל האם דחהו מישהו?  הלא הוא עצמו התרחק מחברת
        בני-אדם, מכל אלה שהיה לפעמים מבקר והיה שוכח ביניהם את עצבות
        חייו. הרחיקהו המצב הקשה, רבוי העבודה, הירידה הרוחנית, שהתחיל להרגיש בה
        בשנים האחרונות.  שואף הוא להיטיב עם בני-אדם, אבל כל נסיון של
        התקרבות מרחיקהו מהם ויוצר תהום בינו ובין הסביבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כמה התרוקן לבו אחרי נסיעת ילדיו. עתה יש לו רצון לברוח מן החיים, להתחבא
        במאורתו, לבל יראו אותו ולא ישמעו את קולו.  אל יסתכלו בו בני-אדם, אל
        יביטו בו ובאסונו  –  מוטב שיחשבוהו כמת, כעובר ובטל מן העולם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובכל זאת מוכרח הוא להפגש עם אנשים.  פעם ופעמיים הוא משתמט מאספות
        החקלאים, אך סוף סוף רואה הוא חובה ללכת;  נמאסו עליו הישיבות ובכל זאת
        הולך הוא שמה, בחשבו כי זוהי חובת האזרח.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >באספה אחת נדחו כל יתר השאלות והתחבטו רק בשאלת הבחירות לבד;  זה חמשה
        שבועות מעסיקה שאלה זו את המוחות ואינם יכולים לצאת ממעגל הקסמים.  בכל פעם
        מופיעים עכובים חדשים לפתרון השאלה, באות הצעות חדשות, התנגשויות שונות
        ואין דרך לשלום.   כל כך הרבה עקמומיות הובלטה אצל אחדים, עד שגם ישרי  לב
        מתחילים להתבלבל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >פעם התחזקה סערת הלבבות כשהקריאו תזכיר, שהגיש בא-כח הפועלים בדבר סדור
        צעירים חלוצים בעבודה לעונת החורף. חברי האספה אמרו לדחות שאלה זו לישיבה
        הבאה, אבל גלעדי עמד בכל תוקף על זה שיפתרוה מיד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  הן הנוער הזה מתהלך רעב בחוצות והלב מתפלץ לראותם בכך.  ומה הם
        מבקשים?  רק עבודה.  הם באו ברצון עז לעבוד, כל עמל לא יכבד מהם, ובלבד
        שיתפרנסו מיגיע כפיהם. אמנם לא כולנו קלטנו היטב את המושג "עבודה", אבל זה
        הדור הצעיר, העולה אל הארץ, דור שרצונו ברזל, אומר לבנות בעצם ידיו את
        נשַמות ארצנו, –  אל נא נמנע ממנו את הדבר הגדול הזה.  תנו לשאלה נכבדה זו
        פתרון מיד!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אך דברי גלעדי לא השפיעו על החברים ושאלת העבודה נדחתה בהחלט.
        והוא  –  מכל צד עקצוהו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  גלעדי רגשני כאשה, אבל הרגשנות לא תבנה את הישוב ועלינו להזהר מהנגע
        הזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">וחבר אחר הוסיף:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  ידוע הוא, שגלעדי הולך תמיד נגד האינטרסים שלנו.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לא שלט ברוחו הפעם ועזב מתוך התמרמרות את האספה לפני הגְמָר.  למחרת נודע
        לו, שהוציאוהו מרשימת המועמדים לבחירות העתידות.  על זה לא התפלא, ועל מה
        התפלא?  על שהכניסוהו קודם.  מרגיש הוא בכל רגע שאינו נאה למקומו.  שעומד
        הוא בנגוד גמור לכל מהלך מחשבותיהם, רק מתוך אונס ממלא הוא חובת הצבור, אך
        אינו מוכשר להסתגל אל הרוחות המנשבות.</span
      >
    </p>
    <p class="c1 c12" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גלעדי הוביל את סוסו אל הבאר להשקותו.  לבוש הוא בגדי חול, שאינם בתכלית
        הנקיון, ולפניו עוברים מכירים בבגדי השבת.  הולכים הם לבית הכנסת, טליתיהם
        תחת בית שחים ועל פניהם עונג שבת עם צפיה נעימה לשמוע קול חזן
        נאה. המאושרים!  לו אין התענוג הזה, –  הוא התרחק מבית-הכנסת ובא שמה רק
        בראש השנה ויום הכפורים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >חוזר הוא מן הבאר ורואה על עצי האקליפטוס הגבוהים שבצד בית-הכנסת המון
        עורבים צורחים, והם מפריעים בעד הקולות הבוקעים מגובה החלונות.  מעמקי
        החורשה נשמעת תפלה אחרת, –  שם שר הזמיר והעפרוני עונה לעומתו בבדידות
        קולו.  במרחק מעט מסתדרים האנקורים למקהלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עובר הוא ליד ברכת מים, עומד על ידה שעה ארוכה ומסתכל בבואות
        הז&#39;קוּרַנְדוֹת המשתקפות בה.  מכאן ומכאן מתנועע חלון או דלת, מחפש הוא
        בברכה את תכול השמים ואינו מוצא אותו, שם מתנועע איזה גוש עכור במקום
        היריעה הזכה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בקרבת המושבה משתרע כרם חרוש יפה, יפה.  אין על קרקעו אף עשב, אף גוש-עפר
        אחד  –  הכל פורר ונתחח הדק היטב.  הוא מקנא בעבודה יפה ומוקדמת זו. מי
        יחרוש עתה את אדמתו ככה?  העובד העיקרי איננו.  והוא?  הן למושב זקנים
        עיניו נטויות.  ימיו עתה ללא עבודה. איננו יכול להתגבר על הבטלה;  הריקנות
        והבדידות אוכלות אותו ואבן כבדה מעיקה על לבו.  בני אדם רחוקים או קרובים
        פוגשים אותו, פונים אליו בדברים ומתעוררת בו מחשבה, שהאנשים האלה בזים לו
        בלבם על שלא יכול לסדר את בנו יחידו והיה מוכרח לנוד למדינות הים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עתה הוא עומד על עברי פי תהום. בעָבר אין כלום, רק ערפל, אדים, טעויות אין
        מספר. נהרס מצבו, נהרסו החיים ולא נשאר בלבו שום רגש אליהם. על המות אינו
        חושב, אבל יש לו רצון להתפטר איך שהוא מחובות חיים ומחובות עבודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנה נוצרה בלבו המחשבה למסור את כל רכושו לקרן-הקימת.  רק חלק קטן ימכור
        ובסכום הזה יוכל להכנס לאחד מבתי-המחסה שבארץ.  –  אבל האם יקבלוהו בגיל של
        חמשים ושלש?  ומה יאמרו הבריות, אם ביום בהיר תודע להם החלטתו?  האם לא
        "משוגע" יקראו אחריו?  אבל מה איכפת לו, והוא בין כך רחוק כבר משאונם
        והמונם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כן, זהו הפתרון היחידי:  הרכוש לעם ולו פנת שקט לבלות את שארית ימיו עם
        עצמו;  הוא עוד ימצא גם שם במוסד אפשרות להביא תועלת.  אמנם אינו זקן
        בשנים, אבל זקן הוא ברוח, שבע רוגז וצער. כל החושים הולכים ונחלשים
        בו.  – הנה פגש בחוץ אדם, שלכאורה הוא מכירהו היטב ואת שמו אינו יכול לזכור
        בשום אופן.   מסתכל הוא בעיני הלז, בפניו המוּכרים לו יפה בכל שירטוטיהם,
        יודע הוא גם היכן זה חי, רק את שמו ואת מעשהו שכח. הלז מדבר  והוא עומד
        נבוך ומשתדל לכסות על אי ידיעתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מתבטל הוא לגמרי בהפגשו עם חבר ישן, שיחד אתו בא אל הארץ.  בעת ההוא עלה
        הוא הרבה על חברו גם בחומר וגם ברוח. דרכיהם נפרדו, זה הלך העירה וזה פרש
        הכפרה;  עתה תופס חברו מקום גדול בישוב.  לא ייגע ולא יעמול כמוהו, ובכל
        זאת התעשר, והוא בעל נכסים רבים, שרכישתם עלתה לו בנקל.  הוא אמיץ
        בהליכותיו, מעשיו ודבורו תקיפים, עומד בעצם פריחתו ועדיין עולה בהר...   מה
        הוא לעומתו!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כל הלילות עוברים על גלעדי ללא שינה.  אי המוצא הנכון?  לבו נוקפו לעזוב
        את המשק, כי מי יודע איך תטפלנה בו ידים בלתי מנוסות  –  אולי יוזנח
        ויֵחָרב ובעיניו יראה את הריסות היסודות, שבנה ושכלל במשך שנים ארוכות
        במיטב כוחותיו?  אך קשה לעזוב וקשה להחזיק. אין כוחות.</span
      >
    </p>
    <p class="c9" dir="rtl"><span class="c3"> </span></p>
    <h2 class="c10" dir="rtl"><span class="c14">ד</span></h2>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הזדעזעות העצבים הפילה את גלעדי למשכב, ונמצאו אנשים טובים המטפלים בו
        בחוליו, –  קוראים לרופא, הולכים לבית מרקחת, מביאים לו ארוחה מבית-האוכל
        ומביאים קצת סדר בבית.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בעד חלונו רואה החולה עדת עבים מקסימות, השטות בתוך רקיע כחול, את כנפיהן
        הצחות הן פורשות ונעות אט ובחשיבות מרובה.  עפרונים שרים בגן והאנקורים
        משתובבים ופורחים ברעש מענף לענף, לא רע להיות חולה  –  חושב גלעדי  –  הנה
        יש לי האפשרות  להסתכל בטבע, לנוח ולקרוא. מציק רק כאב הראש הזה שאין ממנו
        מפלט, אפשר להשתגע בשעת התקפתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בפעם הראשונה בחייו משתדל גלעדי למלא בדיוק אחרי פקודות הרופא, ולא בלי
        תמיהה,  –  למה לו לעשות כל זה?  למי נחוצה בריאותו?  הרי באמת שטות הוא כל
        הטפול הזה לאדם שחייו אינם ולא כלום. עליו לחדול מן הרפוי, עליו לקום
        ולהתעודד עד כמה שאפשר.  הנה עדין הכרמים לא חרושים, הגפנים ללא זמורות,
        כלימה היא וחטא להתפנק במטה ולהשאיר את הכרמים בור. הם חפצי חיים, וכל זמן
        שהוא כאן, צריכים החיים להנתן להם.  אבל כח, כח מאין ימצא?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הרופא אומר, שמצב בריאותו הוטב. אבל איזה גיהנום בנפשו!  במה יכבה את האש
        הבוערת שם?  בלילות מתהפך הוא על משכבו בנדודי שינה, לעיתים רואה הוא
        מחזות-בלהות מתוך תנומה קלה וסובל יסורים גדולים.  לפעמים הוא חולם בהקיץ
        ותמונות שונות מתערבבות במחו המעורפל. כמו עננה כבדה מעיקה על כל
        הויתו.  יש שפתאום הוא מתפרץ בצחוק ואינו יודע על שום מה.  הבחור המשמש
        אותו מביט בו בתמיהה, גלעדי תופס את המבט, מתאדם ואומר:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  אל תתפלא עלי, אחי, –  נזכרתי בדבר מה מגוחך!  –  גם השקר שהשמיע באזני
        בחור תמים מעורר בו בושה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מניח הוא את כף ידו על השמיכה, מסתכל בקמטים הרבים שבעורו הצנום וסופר
        ומונה אותם, את הגדולים לחוד ואת הקטנים לחוד, שבילים ישרים, עקומים
        ועגולים, כל גיד דק מן הדק לא נעלם ממנו, מסתכל הוא ביחוד באלה הבולטים
        ביותר, שהתנפחו מתחת לעורו ומסמנים עגולים וחצאי עגולים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בלילות החושך הוא עומד ומביט בעד החלון לתוך החשכה שעות על שעות.  מה הוא
        רואה שם?  מה הוא מחפש?  את כל תמונות חייו, את כל עברו העגום הוא רואה
        בצורות מטושטשות בתוך האפלה הגדולה. אורות נוצצים על הגבעה, הוא מלוה
        בעינים צמאות את נקודות האור, בשעה שבראשו ישכון ערפל.  תר הוא אחרי דרך
        לאיזו נקודה, אך הוא נתעה ומתיגע והזיעה מכסה את בשרו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– דרך, דרך!  –  מתפרץ הוא פתאום בקול צעקה, ומתוך האפלה עונים לו בהד
        עמום קולות מוזרים, יש שהוא נרתע אחור ומזדעזע בפחד ואחרי רגע הוא נועץ שוב
        את עיניו באפלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הרופא המבקר אותו יום יום אומר, כי המצב הולך וטוב וכי הוא עומד להחלים
        במהרה.  על זה הוא משיב בצחוק מוזר ואינו מגלה את החשש שבלבו, כי הוא עתיד
        לצאת מדעתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >באים לבקרו כמה מידידיו והוא רואה בהם בריות מוזרות.  לכאורה הוא מדבר אתם
        אל הענין, אבל בלבו עוברות בינתים מחשבות שאין להן שחר. –  הנה אלה יושבים
        מלובשים ובעצם הדבר ערומים הם, כולם ערומים, ושום דבר אינו מכסה על גופם,
        אינו יכול לכסות, מפני שערומם נשקף מבעד לכל מערכת בגדיהם.  הוא מתביש לשבת
        בחברתם ולראותם בכך.</span
      >
    </p>
    <p class="c1 c12" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשמתבהרת אצלו המחשבה, הוא רואה נגד עיניו:  בודד, עזוב, נדח!  באותיות של
        אש כתובות המלים בחלל ושורפות אותו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא אינו חדל לחשוב על אלה שנסעו –  מי יודע לאן יתגלגלו החביבים?  אפשר
        כי בחדר צר הם גרים, באיזו קומה עליונה בלי אור ובלי אויר.  אלישבע,
        אלישבע, ילדתי?  איך תסדרי שם את חייך?  אילו נשאר יפתח נאמן ומסור לה,
        לפחות!  – אבל מי יודע?  הרי קצת קל-דעת הוא, ובעולם הגדול רבים וקשים
        הנסיונות... חלשה היא אלישבע, עמוסה מחלה כרונית, ואיך יעבור עליה ההריון,
        הלידה?  וגִדול ילד בתנאים קשים  –  מה יכבד עליה המשא!  ויפתח רוצה לחיות,
        להשתחרר מעול מיותר ולהיות חפשי, –   כמה תתחרט שם על נסיעתה,
        המסכנה  –  תבקש תקנה ואין!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ממה שהם כותבים לו לפעמים ומשתדלים להרגיעו, אין ראיה.  הוא קורא בין
        השיטין, ויש לדמיונו כר נרחב לחשדים וחששות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה עולה בלבו שוב שאלת המשק שלו, שאלה הדופקת בו ומזרזתו:  קום עבוד,
        קום עבוד!  אבל הוא יודע כי אין בכחו לעבוד, כי נִטלה ממנו המטרה ונִטל טעם
        החיים.  המשק דורש אדם שלם ולא צל אדם, ולכן אין לו מקום יותר ועליו לצאת
        אל התוהו, ללכת, ללכת עד שיאבד בחשכה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא רואה מנגד גבעה נטועה גפנים. חלקה גדולה ממנה כבר מעובדה וזמוּרה
        ומַרְאֶהָ כמראה עולל יפה, שיד אם אוהבת טפלה בו,  חפפה ראשו והלבישתהו
        בגדי חג.  אך הנה כרם אחר עומד שם עזוב מדאשתקד:  אדמתו כוסתה חרולים כולה
        והתקשתה כאבן:  ועומדות שם הגפנים עצובות, ורבות מהן זוחלות צהובות,
        מפרפרות בין החיים ובין המות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עצב נוגה מתעורר בלב גלעדי למראה העזובה הזאת.  הראשונים לנטיעה הולכים
        במושבה ומסתלקים מן החיים...  גם הראשונים לעליה הולכים אחד אחד לעולמם.
        מתגברת כעת העליה החדשה, דור אמיץ נוהר אל הארץ!  על קברי הראשונים תדרוך
        רגלם בגבורה, אבל עוד רבה הדרך גם לפניהם, ומי יודע איזה דור יזכה לראות
        בגאולה השלמה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובקום הבנין  –  האם יעמוד איתן? אם הנגע הטבוע בתכונתו לא ירפא, אם
        ההתפרדות וההתפלגות תוסיף להתפתח הלאה  –  האם אפשר שהבנין לא יתמוטט? רק
        בבוא יד חזקה, אבירה להבריא את האויר, אפשר יהיה לחזק את יסודות הבנין. על
        יד כזו הוא חולם כל ימיו!  עוד שם, בעיר מולדתו, כשהאגודה הצעירה היתה
        מתאספת על כרי-דשא מחוץ לעיר, והחברים היו הוזים וחולמים על ציון הרחוקה
        ושרים את שיריה הנלהבים, היה הוא הוזה לפעמים על פלא שיארע לפתע פתאום בקום
        גבור ענק, שביד חזקה ובזרוע נטויה יאחז בעם ויובילו ציונה.  והנה בהיותו
        כבר כאן קם הפלא, קם פודה ומציל לאסירי העם  –  מִשְבי הגויים, מחיק הנֵכָר
        בא, והלב נמלא עליצות :  זהו, זהו!  כל תנועה נשענת על כח הרבים, אבל הרבים
        צריכים תמיד  לאחד שישא באחריות, שיכַוֵן את הדרך.  הנה האחד, הפלאי, לא
        ארכו ימיו, והפלא נמוג...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עיניו נתקלו שוב בגבעה שמנגד והכרם העזוב מעורר בו עצב עמוק:  –  הלא גם
        כרמו עזוב השנה, גם כרמו עומד כיתום ואין יד נאמנה שתטפל בו.  פתאום הרגיש
        מוסר-כליות נורא ותקפו הרצון לקום וללכת. הוא קפץ מהר מן המטה, אמר להתלבש,
        אבל רגליו כשלו, ומוכרח היה להאחז בכסא הסמוך לבל יפול.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שכב הוא על המטה והרעיון על העבודה התכופה, שאין מי אשר יעשנה, דקר את
        לבו. כמה כסף ומרץ, כמה שנות חיים הושקעו בכרמים. בכל צמצם, רק לא בהוצאות
        על העבודה.  אמנם השנים הרעות הכזיבו תקוותיו, אבל לראות בשממתם אסור,
        אסור, צריך לחפש מוצא!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא מתבונן בחדרו.  בעד הדלת הוא מעיף עין לחדרים האחרים, –  איזו שממה
        השתררה כאן אחרי לכת הילדים!  הדם קופא בעורקים מפני הבדידות האיומה. ולמה
        נסעו, למה נדו מכפרם, ממולדתם, מביתם ואדמתם?  מי יודע איזו רעה תמצא אותם
        שם בנכר!...  אנחה עמוקה מתפרצת מלבו, הוא כובש את פניו בכר, נושך שפתו
        וקורא מנהמת לבו:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  ילדי, ילדי  – איכם?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשמשתתקת סערת נפשו, הוא רואה בחזון רוחו נכד בהיר תלתלים, בעל עינים
        מאירות כזוהר הרקיע.  נפש יקרה זו הוא נוטל בשתי ידיו ליַשְנָה
        בעריסה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  נומה, נומה, פעוטי!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אל אלהים  –  הלא ככה היה מישן את יפתח, את היתום הקטן שלו אז, אז,
        בבדידות הראשונה.  אבל אז היה קול ילד, קול בכי או צחוק משתפך בחדרים,
        ועתה  - מה עתה?  כליון ומות  –  האם לא ישתגע? והנה הגיעו לו שוב ימים
        ולילות, שהכל מִטַשטש במוחו ואינו יודע לקשר את ההוה עם העבר החתול
        בערפל.  הוא אוהב את החושך, קם באישון לילה, יושב ליד החלון וטובל עיניו
        בעלטה.  אינו רואה שם מאומה, אינו חושב על שום דבר, רק יושב ורועד, עד
        שמתעורר הבחור, רואה אותו במצבו ומכריחו לשכב.  בשכבו עיניו פקוחות לרוָחה
        שעות תמימות עד שהוא נרדם, ובהתעוררו רוצה הוא לזכור היכן הוא נמצא כעת
        ואינו יכול.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לפעמים מתיצבות לפניו תמונות משונות ואינו יודע, אם חולם הוא או הוזה
        בהקיץ.  –  הנה אשה צנומה, כולה גל של עצמות, יושבת בכותנתה לעורה ומיניקה
        ילד...  זקן בעל מבטים איומים לוטש עיניו כלפי נערה, ששעורותיה הארוכות צבע
        זהב להן ואומר להתנפל עליה... שתי ילדות  –  אשתו וכלתו  – הולכות
        שלובות-זרוע ומתקרבות אליו... והנה מושה גברילוביץ עטופה אבל  –  האם
        נתאלמנה?..   פתאום גלים, גלי עשן מכסים את כל אויר החדר והוא נחנק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יש שהוא מביט לתוך חלל החדר ואינו יודע להחליט, אם עֵר הוא או ישן.  או יש
        שאינו מכיר את חדרו כלל, הכל נשתנה ונהפך מלמעלה למטה ומלמטה למעלה. האם
        חדר זה הוא בתוך ביתו?  –  ומדוע אין האורוה בחצר ואין פרדות ואין רתמות,
        וישנה כאן רק מחרשה צרפתית אחת?  מדוע הכניסו את המחרשה והעמידוה סמוך
        למטתו?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >... ומה זה התנערו שם בפחד היונים בשובכים?  ודאי עוף-לילה טורף
        אותן! תיכף יקח את הרובה, ימלאהו ויירה בטורף!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הכדורים!  היכן הכדורים?  –  צועק הוא ומעיר משנתו את הבחור הישן בחדרו
        הסמוך למטתו, והלה קם, נותן לחולה כף רפואה, מחליק לו על כתפו, מרגיע את
        רוחו ומכסא את גופו הקופא.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c0">*    *    *</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רק בסוף  החורף הגיע גלעדי לידי בריאות שלמה בנפשו ובגופו, ואז כבר נהל
        משא-ומתן על התכנית אשר תססה במוחו מזמן, –  למסור לקרן הקיימת החצי מנחלתו
        על מנת ליַשב עליה קבוצה, ואת החצי השני, שהוא משאיר לזכות בנו, הציע
        שתֵעבד ע"י הקבוצה בתנאים נוחים לה עד בוא מועד ובנו ישוב אל הקרקע.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כל ירחי הקיץ עברו בחלופי מכתבים עם הלשכה הראשית בירושלים. ורק לתקופת
        העבודה הבאה, לפני בוא הגשמים, הוצאה התכנית לפועַל וקבוצה של חמשה צעירים
        עם שתי צעירות הסתדרה בביתו ובמשקו. הוא עצמו קבע לו דירתו בחדר העליה
        ובשעות הפנאי התעסק שם ברוחניות:  שב אל התנ"ך ואל ספרי המחקר. הוא חי
        בקבוצה כחבר נכבד והיה לה למורה-דרך בעבודה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >רוחו התחיל להתחדש ולספוק-נפשי גמור הגיע בשעה, שמלשכת ההתישבות באה
        הידיעה על-דבר תוספת אדמה לקבוצה, על קנית שתי פרות גזעיות וצרוף חמשה
        חברים נוספים. אז ערכה ה"חברה" את הנשף הראשון בביתו והוא נסחף בריקודים
        כאחד הצעירים.</span
      >
    </p>
    <p class="c8" dir="rtl"><span class="c5"></span></p>
  </body>
</html>`;

export default page;
