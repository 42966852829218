const modal = `<body class="c5 doc-content">
  <p class="c0" dir="rtl">
    <span class="c3 italic"
      >יצחק אפשטיין עלה לארץ ימים אחדים אחרי יחיאל מיכל שלמה זלמן ("רבי מיכל
      יקירי") בעלה של נחמה פוחצ&#39;בסקי והם למדו עבודה חקלאית יחד בשנותיהם
      הראשונות בארץ, בזכרון יעקב, ראש פינה ויסוד המעלה.
    </span>
  </p>
  <p class="c0 italic" dir="rtl">
    <span class="c4"
      >המאמר של יצחק אפשטיין "על חטא שחטאנו בנוול לשון" פורסם בגליון אדר- סיוון
      תרפ"א של </span
    ><span class="c4 c6 bold">החינוך: עתון פדגוגי להורים ולמורים</span
    ><span class="c3">.</span>
  </p>
  <p class="c0 italic" dir="rtl">
    <span class="c3">המכתב המקורי נמצא בארכיון הציוני</span>
  </p>
  <br />

  <p class="c0" dir="rtl">
    <span class="c1">תל אביב, כ"ב אב התרפא.</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">שלום רב עליך, הגברת נחמה פוכשבסקי.</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1"
      >        זה שבועות אחדים עלה על רעיוני לכתב לך את הטורים האלה, אפס כי לא
      העזתי להפריעך מעבודתך בעצם הבציר, הקטיף והקלוף. ומעשה שהיה כך היה. ויהי
      באחד ימי אספת הסופרים ביפו, בשעת נאום, וכבודך ואני בין השומעים. ובעודני
      מאזין ומקשיב, והנה פתקה שלוחה אלי: כבודֵך הטיפה לי עצת מוסר על אשר אני
      מתנמנם בשעה חמורה, בעת אשר המלחמה מכוֶנת גם אלי. ואֹֹמר יבא יום ואקום את
      נקמתי, אמרתי ואקיֵמה. נקמתי כתובה בחֹברת ג&#39; של "החנוך", בשם "על חטא
      שחטאנו בעוות לשון". ואני שולח לך בצרוף הטורים האלה את החֹברת ומבקש כי
      תואילי להחזירה לי אחרי קראך בה לפי טעמך.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">        ושלומות רבים לרבי מיכל יקירי ולג. אפרת.</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">אם תתפנו – סורו אלינו לימים אחדים</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">                        כמשאלת המזמין בשם החבריא</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c1">                                חברכם הזקן</span>
  </p>
</body>`;

export default modal;
