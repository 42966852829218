const page = `
  <body class="c3 doc-content">
    <p class="c7 italic space-bottom" dir="rtl">
      <span class="c12"
        >המליץ כט תשרי תרנד   9.10.1893. המאמר נכתב בהיות הכותבת כבת 23, שלש
        שנים אחרי עליתה מרוסיה לא"י.</span
      >
    </p>
    <p class="c9" dir="rtl"><span class="c11"></span></p>
    <h3 class="c13" dir="rtl" id="h.93d3q9p9lql">
      <span class="c15">מזכרונות אחת האכרות בא"י</span>
    </h3>
    <p class="c5" dir="rtl"><span class="c8">(מוגש לא—י)</span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >מה שונים הימים ההם מהימים האלה, אנכי _יושבת אז בחברת צעירים רומינים על
        גדות הדונאי, מלמטה _מישתפכים המים הזכים והשקטים, הטבע שקט מסביב.
         -בחברתנו ישמעו מהתלות ושחוק. אנחנו שלוים ומאושרים, לא יחסר לנו כל.
        אנחנו גבוהות נדבר, נשא  עינינו לרעיונות נעלים אשר להם תוצאות גדולות בבוא
        הימים. אנכי כעבריה נרדפת, בכל חבתי לארץ מולדתי לא יתרומם לבי בפטריותי
        לה, ובכל זאת שמחה אנכי כרעי וכמו המה, הרומינים, שמחה ומאושרת אנכי בנשאי
        כמוהם את לבי לאושר הארץ לעתיד לבא, עת יצאו לפועל הרעיונות הנשואים אשר
        נהגה בהם. אינני שוכחת כי עבריה אנכי! ‬לא מפני כי חבתי וחובתי לעמי לא
        יתנני לשכוח זאת, כי עוד טרם אדעהו ועוד טרם אחוש חובתי לו, אך... ‬לחישת
        אחד הצעירים יזכירני מי אנכי;‬ "ראה, קארל, מה יפו עיני היהודיה הזאת "לבי
        יפעם בקרבי, אפי יבער, אבל רק רגע - הזעם עבר. שני הצעירים-הרומינים יחלו
        לדבר רמות ונשגבות ופונים אלי בשאלות נכבדות, ואנכי שוכחת, כי זרה אני להם
        ולעמם ויחד אתם אשא דעי למרחוק בנוגע לאושר ארצם. מתחת מפכים המים הזכים,
        ומלמעלה - לבות צעירים מלאים תכונה - ידפוקו...</span
      ><span class="c1">‬</span>
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c0">*     *</span></p>
    <p class="c4 text-center" dir="rtl"><span class="c0">*</span></p>
    <p class="c2" dir="rtl">
      <span class="c0"
        >רבות בשנים עברו. אלי! כמה מים עברו על ראשי מאז עד עתה, ומה רב השנוי בין
        דעותי אז ודעותי עתה! בעיר יפו הנני, בבית עקד הספרים; מהמעקה אשר עליו,
        נשקפתי ימה, ומה יפה המראה אשר נגד עיני - ככר חול, אניה נשברה בתוך,
        ואחריהם ירעשו גלי הים ובלשונם קצף ילחכו את החול אשר על פני החוף. השמש
        נוטה ימה וקרניה זהב יאירו בצבעים שונים את כל שפת הים עד קצה השמים. עיני
        כמו בקסם נמשך אחרי המראה הנפלא ה זה. ‬כל אשר בתבל שכחתי וכמו אסורה בחבלי
        חלום נצמדתי אל מקומי. פתאום והנה קול נעים העולה מתוך החדר משכני מן
        המראה. "לו לנו היה הככר הזה -  אמר אחד היושבים, אחד מבני ציון היקרים -
        אז גן נחמד שתלנו עליו ונטעי נאמנים נטענו בו". דיה היתה הפרזה הזאת,
        הנאמרת בשחוק קל מושך לב להעיר בקרבי אלפי רעיונות נעימים, אבל - הרעיונות
        האלו נהפכו כרגע לספקות מרים " -‬ לוא לנו היה"!‬ כמה רחוק הדבר מאפשרות!‬
        עוד הפעם נשקפתי על פני הים והנה השמש שקעה בעמקי גליו כמו לנצח. אנכי
        נשאתי את לבי למרחקי הים מערבה גם צפונה, זכרתי את אחי שמה, זכרתי את שבר
        עמנו הגדול כים הזועף הזה, זכרתי... ולחץ לבבי הניע במהירות יתרה את דמי.
        "מה תתיפחי, אחותי נחמה"?‬  - אמרה אלי אחת מרעותי, אנכי לא עניתי דבר,
        עזבתי את המעקה, ובלב מלא רגש באתי אל בית הספרים ורוח עם הספר בהודו והדרו
        נגלה אלי, ובעמקי לבבי שמעתי קול מדבר אלי: האמנם רחוק הדבר כל כך
        מהאפשרות? ... </span
      ><span class="c1">‬‬‬‬</span>
    </p>
    <p class="c10 text-left" dir="rtl"><span class="c8">נחמה פחיטשעווסקי</span></p>
    
  </body>`;

export default page;
