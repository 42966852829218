const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c1" dir="rtl">
      <span class="c2 bold">דאר היום 27.3.1935</span><span> – </span
      ><span class="c0">תחת הכותרת</span
      ><span> הספר העברי בא"י בהתהוותו ההיסטורית </span
      ><span class="c0">וכותרת המשנה</span><span> הדפוס העברי ביפו</span
      ><span class="c0"
        > מסופר על הקמת בית דפוס ראשון ביפו בשנת תרס"ח (1908) וניתנת רשימה של
        סופרים עברים שפירסמו בו לראשונה, ביניהם נחמה פוחצ&#39;בסקי.
      </span>
    </p>
  </body>
</html>`;

export default modal;
