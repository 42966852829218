const page = `<body class="c4 doc-content">
  <p class="c3 italic" dir="rtl">
    <span class="c bold">הדואר</span
    ><span class="c1"> ט"ז תמוז תרצד 29.06.34 (ללא שם הכותב)</span>
  </p>
  <br />

  <h3 class="c0" dir="rtl" id="h.ky2cfxilh93y">
    <span class="c5">נחמה פוחצ&#39;יבסקי ע"ה</span>
  </h3>
  <p class="c3" dir="rtl">
    <span class="c6"
      >בראשון-לציון בארץ-ישראל מתה נחמה פוחצ&#39;יבסקי, אחת מן הסופרות הראשונות
      בספרות העברית החדשה בארץ-ישראל. היא כתבה הרבה ספורים מחיי הישוב החדש
      בארץ-ישראל, ביחוד על גורלה של האשה ועל שאלות חברתיות בבנין ובישוב. בהיותה
      קרובה ברוח למפלגת הפועלים לקחה חבל פעיל בעבודותיה של זו ובמלחמתה לטהרת
      העבודה העברית. ביתה במושבה היה משמש בית ועד לסופרים צעירים ולפועלים, שהיתה
      להם כאם. בת ששים וארבע שנים היתה במותה והניחה אחריה בנים ובנות, נכדים
      ונכדות.</span
    >
  </p>
  <p class="c3 c8" dir="rtl"><span class="c2"></span></p>
</body>`;

export default page;
