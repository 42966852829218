const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c13 doc-content">
    <p class="c10" dir="rtl" id="h.gjdgxs">
      <span class="c3 c4">רות ארבל</span>
    </p>
    <p class="c5" dir="rtl"><span class="c3 c6"></span></p>
    <p class="c1" dir="rtl">
      <span class="c2">בסדר פסח תרצ"ד [</span
      ><span class="c2 c12 italic">אביב 1934</span
      ><span class="c3 c2"
        >] ישבה סבתא כמנהגה משמאלו של סבא ליד שולחן הסדר והמשפחה כלה מסביב. אך
        לפני חג השבועות קבלה התקף לב. אמי, אפרת, טפלה בה יום ולילה. אני הסתובבתי
        כיתומה בחצר. לפתע נפתח תריס חדר השינה, ראשה של אמי יצא החוצה והיא צעקה
        "קראו לד"ר זוסמנוביץ". לאחר שעה קלה סבתא נפטרה, ומאז שבועות חדל להיות חג
        במשפחתנו. אני זוכרת שנכנסתי הביתה וראיתי את סבא עומד במזדרון ומתיפח. זו
        היתה הפעם היחידה שראיתיו בוכה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2 c3"
        >למחרת התקיימה הלויה. שוב הסתובבתי לבדי. לבית לא הצלחתי להגיע, גם לחצר
        רחבת הידים לא יכולתי להתקרב. אפילו הרחוב, לאורך עשרות מטרים לפני החצר,
        היה גדוש אנשים, כל שכונת התימנים היתה שם, כתות של בית הספר. אני הקטנה
        כמעט נדרסתי ברגלי הקהל ונדחקתי לשולי הדרך. כאן חבקתי גזע עץ תות כדי לא
        ליפול ולהשרט בגדר התיל שהיה מתוח בין העצים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 c2"
        >זו היתה לויה ראשונה שראיתי, וחשבתי שכך הן כל הלויות. רק לאט לאט, במשך
        השנים, נתקלתי באנשים שאמרו לי: "אני מוכרח לספר לך על סבתא שלך", או אפילו
        סתם אמרו בגאוה "אנו היינו ידידים של סבתא שלך", כאילו עצם הידידות אתה
        מקנה להם ערך מיוחד. וכך אט אט עלתה והצטיירה דמותה לפני.</span
      >
    </p>
    <p class="c8 text-center" dir="rtl"><span class="c3 c2">***</span></p>
    <p class="c1" dir="rtl">
      <span class="c2 c9 italic"
        >קטעים ממכתב ששלחה רות ארבל לפרופ&#39; יפה ברלוביץ
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 c2"
        >...עבודת האסוף שלי רחוקה היתה מלהיות מדעית. היו דברים ששמעתי, ורשמתי רק
        שנים אח"כ, אם בכלל. לא רשמתי אף פעם תאריך לשיחה, ולא תאריך לאירוע שבו
        דובר, ורק בדיעבד, מתוך רמזים, הצלחתי לשחזר פחות או יותר את הזמנים.
        כששמעתי סיפור, ולו היוצא דופן ביותר, לא ניסיתי לשאול את מי שעדין היה
        בחיים ויכול היה לאמת – ובראש ובראשונה אמי – לעשות זאת. ... בחנתי וגיליתי
        שדברים רבים שמעתי רק ממקור אחד, ולפעמים לגמרי באקראי נודעו לי, אף על פי
        שבודאי היו ידועים גם לאמא וגם לאחרים. למשל, הסיפור שסיפרתי לך על סבתא
        והרב סייבי זוהי למעשה תמונה שנחקקה עמוק בזכרוני בהיותי כבת שבע (ב-1934,
        כ-20 שנה מאוחר יותר מסיפורו של יונדב – יונדב היה בבית סבתא בשנות מלחמת
        העולם הראשונה ) ... מקרה נוסף שנודע לי רק ממקורות חיצוניים למשפחה הוא
        הקשר של סבתא ליתומות שנשלחו ע"י הג&#39;וינט למאיר שפיה (אלו הן הנערות
        שעליהם כתב ביאליק את השיר "בנות שפיה"). מצאתי בין מכתביה של סבתא מכתב
        ששלחה ממאיר שפיה. תמהתי בשעתו מה היו מעשיה של סבתא שם: מראשון לציון
        למאיר שפיה המרחק רב, והקשר מסובך. לא ידעתי על כל ידידים שהיו לה בכפר
        הנוער. והנה הפגישה המקרית עם בת-שבע רזניק ... חשפה לי פרק שלם ובעיני
        מרגש, של הקשר והדאגה של סבתא לקבוצת יתומות אלה, שאילולי פגישה מקרית זו,
        היה נשאר עלום בפני. בביקור השני אצל בת-שבע רק לפני מספר שנים, אמרה לי
        פתאום בת-שבע: "הנכד הבכור של סבתא נהרג במלחמה". לרגע חשבתי שהיא מבולבלת.
        הן שיבי בן-דודי הגדול שבנכדים הבנים עודנו חי. ואז קלטתי שהיא מדברת באחי
        גדעון, שנפל במלחמת העולם השניה. ואז המשיכה בת-שבע, שבבקוריה בבית סבתא
        כנערה היה גדעון תינוק עליז וערני, וילד השעשועים שלה ושל הבנות האחרות.
        אותן שנים, 1924-1926, גרו הורי בבית סבתי, ואחי נולד בחנוכה 1924. כלומר,
        בת-שבע ורעותיה היו בנות בית אצל סבתא ממש בשנים שאמי גרה בבית, ובכל-זאת
        לא מצאה אמי את הסיפור ראוי לאזכור ...</span
      >
    </p>
    <p class="c7 text-center"><span class="c3 c2">***</span></p>
    <p class="c0" dir="rtl"><span class="c3 c11"></span></p>
  </body>
</html>`;

export default page;
