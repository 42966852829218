const page = `<html>
  <body class="c36 doc-content">
    <p class="c11 italic" dir="rtl">
      <span class="c20 c6 c21"
        >בחודש שבט תרפ"ג (ינואר 1923) התחתנה אפרת בת נחמה פוחצ&#39;בסקי, ילידת
        ראשון לציון, עם צבי בן-כהן שהגיע לארץ עם הגדוד העברי ב- 1918. הזוג הצעיר
        עבר לגור בירושלים ונשאר שם כשנה לפני שחזרו לראשון לציון. המכתבים שלהלן
        הם מאותה שנה - 1923. רובם מנחמה, אחדים מבעלה יחיאל מיכל, ארבעה מהבת אפרת
        ואחד מצבי החתן. מהמכתבים אפשר ללמוד רבות על אורח חייה של נחמה
        פוחצ&#39;בסקי: הקונסטלציה המשפחתית, המשק, קשריה, ידידיה, אנשים רבים
        שדאגה להם, ופעילותה בעניין זכויות נשים.  כמו כן ניכר מהמכתבים הקושי של
        נחמה לשחרר את אחיזתה בבתה ואי הנוחות שהדבר יוצר אצל אפרת, וכן אי הנוחות
        של הזוג הצעיר מול הביקורתיות של האם-החותנת (בפרט לגבי העברית של צבי
        שהגיע לארץ מקנדה) - למרות יחסה החם.
      </span>
    </p>
    <p class="c11 italic" dir="rtl">
      <span class="c21 c6">הסימן</span><span class="c44 c6"> [?]</span
      ><span class="c20 c21 c6"> אומר שאיננו בטוחים בפענוח המילה מכתב היד</span>
    </p>
    <p class="c11 italic" dir="rtl">
      <span class="c21 c6">הסימן </span><span class="c6 c44">_</span
      ><span class="c20 c21 c6"> מחליף מילה שכלל לא הצלחנו לפענח </span>
    </p>
    <br />

    <a href="#30.1.192">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >יג שבט תרפג  מנחמה לאפרת וצבי  [30.1.1923]</span
        >
      </p>
    </a>

    <a href="#1.2.1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >ט"ו שבט תרפ"ג  ממיכל ומנחמה לאפרת וצבי [1.2.1923]</span
        >
      </p>
    </a>
    <a href="#4.2.1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >י"ח שבט תרפג  מנחמה לאפרת וצבי  [4.2.1923]</span
        >
      </p>
    </a>
    <a href="#5.2.1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >י"ט שבט תרפ"ג  מנחמה לאפרת וצבי  [5.2.1923]</span
        >
      </p>
    </a>

    <a href="#14.2.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ח שבט תרפ"ג </span><span class="c32 c8"> </span
        ><span class="c19 c8 c10">מנחמה לאפרת וצבי   [14.2.1923]</span>
      </p>
    </a>
    <a href="#15.2.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ט שבט </span><span class="c8 c32"> </span
        ><span class="c8 c10 c19">מנחמה מנחמה לאפרת וצבי  [15.2.1923]</span>
      </p>
    </a>
    <a href="#6.3.1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10">י"ח אדר  מנחמה לאפרת וצבי  [6.3.1923]</span>
      </p>
    </a>
    <a href="#8.3.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ&#39; אדר תרפ"ג  מנחמה לאפרת  </span
        ><span class="c5 italic">[8.3.1923]</span>
      </p>
    </a>
    <a href="#13.3.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ה אדר תרפ"ג  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[13.3.1923]</span>
      </p>
    </a>
    <a href="#16.3.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ח אדר תרפ"ג  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[16.3.1923]</span>
      </p>
    </a>
    <a href="#20.3.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ג&#39; ניסן תרפ"ג  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[20.3.1923]</span>
      </p>
    </a>
    <a href="#25.3.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ח&#39; ניסן תרפ"ג  מנחמה לצבי </span
        ><span class="c5 italic"> [25.3.1923]</span>
      </p>
    </a>
    <a href="#9.4.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ג ניסן תרפ"ג  מנחמה לאפרת וצבי</span
        ><span class="c5 italic">  [9.4.1923]</span>
      </p>
    </a>
    <a href="#11.4.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ה ניסן תרפג מנחמה ומיכל לאפרת וצבי  </span
        ><span class="c5 italic">[11.4.1923]</span>
      </p>
    </a>
    <a href="#15.4.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ט ניסן תרפג  מנחמה ומיכל לאפרת וצבי  </span
        ><span class="c5 italic">[15.4.1923]</span>
      </p>
    </a>
    <a href="#20.4.1923">
      <p class="c3" dir="rtl">
        <span class="c8">מנחמה ומיכל לאפרת וצבי </span
        ><span class="c8 c6 italic">[על פי תוכן המכתב: ערב שבת, </span
        ><span class="c33 c4 c6 italic">יתכן 20.4.1923]</span>
      </p>
    </a>
    <a href="#29.4.1923">
      <p class="c3" dir="rtl">
        <span class="c33 c4 c6"
          >י"ג אייר תרפ"ג   מנחמה לאפרת וצבי  [29.4.1923]</span
        >
      </p>
    </a>
    <a href="#14.5.1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >כ"ח אייר תרפג  מנחמה ומיכל לאפרת וצבי  [14.5.1923]</span
        >
      </p>
    </a>
    <a href="#may1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >ללא תאריך  מנחמה לאפרת וצבי [על פי תוכן המכתב: מאי 1923]</span
        >
      </p>
    </a>
    <a href="#31.5.1923">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >ט"ז סיון תרפ"ג.  מנחמה לאפרת וצבי  [31.5.1923]</span
        >
      </p>
    </a>
    <a href="#june1923">
      <p class="c3" dir="rtl">
        <span class="c8">יום ד    מנחמה לאפרת וצבי    </span
        ><span class="c4 c6 c33">[לפי תוכן המכתב: יוני 1923]</span>
      </p>
    </a>
    <a href="#4.6.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ" סיון תרפג.  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[4.6.1923]</span>
      </p>
    </a>
    <a href="#13.6.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ט סיון תרפ"ג  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[13.6.1923]</span>
      </p>
    </a>
    <a href="#17.6.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ג&#39; תמוז תרפ"ג  מאפרת לנחמה ומיכל   </span
        ><span class="c5">[17.6.1923]</span>
      </p>
    </a>
    <a href="#18.6.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ד&#39; תמוז תרפ"ג  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[18.6.1923]</span>
      </p>
    </a>
    <a href="#20.6.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ו&#39; תמוז  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[20.6.1923]</span>
      </p>
    </a>
    <a href="#30.6.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ט"ז תמוז מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[30.6.1923]</span>
      </p>
    </a>
    <a href="#juneOrJuly1923">
      <p class="c3" dir="rtl">
        <span class="c4 c42">יום א&#39; בערב</span
        ><span class="c8">  מנחמה לאפרת וצבי </span
        ><span class="c5 italic">[על פי התוכן: לקראת אמצע יולי 1923]</span>
      </p>
    </a>
    <a href="#10.7.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ו תמוז תרפ"ג  מנחמה לאפרת  </span
        ><span class="c5 italic">[10.7.1923]</span>
      </p>
    </a>
    <a href="#13.7.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ט תמוז תרפ"ג  מנחמה לאפרת </span
        ><span class="c5 italic">[13.7.1923]</span>
      </p>
    </a>
    <a href="#14.7.1923">
      <p class="c3" dir="rtl">
        <span class="c8">א&#39; אב תרפ"ג  מאפרת לנחמה</span
        ><span class="c8 c6 italic"> [14.7.1923]</span>
      </p>
    </a>
    <a href="#19.7.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ו&#39; אב התרפג  מצבי לנחמה </span
        ><span class="c5 italic">[19.7.1923]</span>
      </p>
    </a>
    <!-- <a href="#startOfElul">
      <p class="c3" dir="rtl">
        <span class="c19 c8 c10"
          >ראשית חודש אלול   מנחמה ומיכל לאפרת וצבי    ??????</span
        >
      </p>
    </a> -->
    <a href="#16.8.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ד&#39; אלול תרפ"ג מנחמה ומיכל  לאפרת וצבי </span
        ><span class="c8 c6 italic"> [16.8.1923]</span>
      </p>
    </a>
    <a href="#19.8.1923fromEfrat">
      <p class="c3" dir="rtl">
        <span class="c8">ז&#39; אלול תרפ"ג  מאפרת וצבי לנחמה ומיכל </span
        ><span class="c5 italic">[19.8.1923]</span>
      </p>
    </a>
    <a href="#19.8.1923fromNehama">
      <p class="c3" dir="rtl">
        <span class="c8">ז&#39; אלול תרפ"ג  מנחמה לאפרת וצבי  </span
        ><span class="c5">[19.8.1923]</span>
      </p>
    </a>
    <a href="#25.8.1923">
      <p class="c3" dir="rtl">
        <span class="c8">י"ד אלול  מנחמה לאפרת  </span
        ><span class="c8 c6 italic">[אין שנה, לפי התוכן כנראה 25.8.1923]</span>
      </p>
    </a>
    <a href="#29.8.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ט"ז אלול תרפ"ג  מנחמה לאפרת </span
        ><span class="c5 italic">[29.8.1923]</span>
      </p>
    </a>
    <a href="#sept1923">
      <p class="c3" dir="rtl">
        <span class="c8">ללא תאריך  ממיכל לאפרת וצבי </span
        ><span class="c5 italic"
          >[לפי התוכן, לקראת סוף אלול תרפ"ג, תחילת ספטמבר 1923]</span
        >
      </p>
    </a>
    <a href="#17.9.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ז תשרי תרפ"ד  מנחמה לאפרת וצבי </span
        ><span class="c5 italic"> [17.9.1923]</span>
      </p>
    </a>
    <a href="#19.9.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ערב יום כפורים  ממיכל  לאפרת וצבי  </span
        ><span class="c5 italic">[19.9.1923]</span>
      </p>
    </a>
    <a href="#beforeSukotSep1923">
      <p class="c3" dir="rtl">
        <span class="c8">ללא תאריך  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic"
          >[לפי התוכן – לקראת סוכות תרפ"ד, ספטמבר 1923]</span
        >
      </p>
    </a>
    <a href="#26.9.1923">
      <p class="c3" dir="rtl">
        <span class="c8">א&#39; דחוהמ"ס תרפ"ד  מנחמה לאפרת, צבי ומיכל </span
        ><span class="c5">[26.9.1923]</span>
      </p>
    </a>
    <a href="#chohamSukotSep1923">
      <p class="c3" dir="rtl">
        <span class="c8">יום ה&#39; בערב    מנחמה לאפרת וצבי</span
        ><span class="c5"
          > [לפי התוכן – חוה"מ סוכות תרפ"ד, סוף ספטמבר 1923]</span
        >
      </p>
    </a>
    <a href="#1.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">הושע"ר תרפ"ד  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[1.10.1923]</span>
      </p>
    </a>
    <a href="#4.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ד תשרי תרפ"ד  מנחמה לאפרת וצבי </span
        ><span class="c5 italic">[4.10.1923]</span>
      </p>
    </a>
    <a href="#10.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ל תשרי תרפ"ד  מנחמה לאפרת וצבי  </span
        ><span class="c8 c6 italic">[10.10.1923]</span>
      </p>
    </a>
    <a href="#13.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ג&#39; חשון תרפ"ד  מאפרת לנחמה  </span
        ><span class="c5 italic">[13.10.1923]</span>
      </p>
    </a>
    <a href="#16.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ו&#39; חשון תרפ"ד מנחמה ומיכל לאפרת וצבי  </span
        ><span class="c8 c6 italic">[16.10.1923]</span>
      </p>
    </a>
    <a href="#18.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">ח&#39; חשון תרפ"ד  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[18.10.1923]</span>
      </p>
    </a>
    <a href="#22.10.1923">
      <p class="c3" dir="rtl">
        <span class="c8">י"ב חשון תרפ"ד ממיכל ונחמה לאפרת וצבי  </span
        ><span class="c5 italic">[22.10.1923]</span>
      </p>
    </a>
    <a href="#6.11.1923">
      <p class="c3" dir="rtl">
        <span class="c8">כ"ז חשון תרפ"ד  מנחמה לאפרת וצבי  </span
        ><span class="c5 italic">[6.11.1923]</span>
      </p>
    </a>
    <br />
    <br />

    <p class="c3 c38 c43" dir="rtl" id="30.1.192">
      <span class="c9 c10">יג שבט תרפג  מנחמה לאפרת וצבי  </span>
      <span class="c9 c10 italic">[30.1.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשלץ י"ג שבט תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">לאפרת ולצבי יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >וד[א]י חושבת ילדתי שעזבה אותי ואני אומרת לכם שזוהי טעות גמורה. היא אתי
        בכל פנה ובכל צעד שאני דורכת, היא אתי בבית, במטבח, בארוה; היא אתי בשעת
        העבודה גם בשכבי ובקומי. על השפתים משתרבבת בלי הרף המלה " - - - " למרות
        זה שאני עשרות בשנים איני מדברת בשפה ההיא. הרגל מאד נעשה טבע.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ואת אפרתי, יקרת לי שלשום שבעתים, כשראיתיך אמיצה ויפה כל כך. לי לא היה
        האומץ הדרוש, אני מן הבקר הכנותי לי מנת ברום, אחזתיו בילקוטי ושתיתיו עד
        תומו. את יותר חזקה ממני ואני שמחה ומתגאה בך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אקוה שתשמעו בקולי לא להעמיס על גבכם מיד כל העבודה הקשה שעליכם עכשו
        לעשות. באמת היתי רוצה להיות אתכם לעזר במהשהו, אבל חוששני שזה לא נוח
        לכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אני אקצר הפעם בתקוה לקבל מכם מכתב ואז אשתדל להאריך בדברים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נשיקות מאליפות ממני, אבא והסבתא. היא מאושרה שזכתה להוביל את נכדתה
        האהובה לחופה</span
      >
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא.</span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נ.ב. השקדים שבכיס בודי התמעכו, יהיו נא אלה לאפיה. הנתנו לסוביל שקדים?
        כתבו אם באו חפציכם בשלימות. אתה, צבי יקירי, אינך מתעצל לכתב. תודיע אתה
        על הכל, אם לאפרת אין פנאי להרבות בדברים.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="1.2.1923">
      <span class="c9 c10"
        >ט"ו שבט תרפ"ג  ממיכל ומנחמה לאפרת וצבי [1.2.1923]</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשון לציון ט"ו שבט תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">לבני החביבים</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >היום הוא היום המקוה, חמישה עשר בשבט, אשר עליו נשאנו עינינו לעשות בו את
        חתונתכם. והנה כמה נחמד הדבר, שכבר עבר הכל ולא חכינו ליום סגריר כזה, -
        הכל תלוי במזל אפילו כלה תחת חופתה! אמא מתכוננת לפיס ביום השבת, זאת אומרת
        מחרתיים, את כל אלה המוכנים להתפיס</span
      ><sup class="c2"><a href="#ftnt1" id="ftnt_ref1">[1]</a></sup
      ><span class="c1"
        >. אתמול אספה כנופיה שלמה של ידידות ואפו דברים טובים ממש כמו ליום החתונה
        ועתה מחכים לכל דכפין.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >נעים מאד לנו הזקנים בקבלנו מכם בכל יום ויום בוליטן. זה מגרש את השעמום
        מהבית; אם תתנהגי ככה אז באמת נוכל לכנות אותך "ילדה טובה". את נמצאת באותו
        מלון מרשבסקי, רק החלפת את יונה ואלישיב בצבי,</span
      ><sup class="c2"><a href="#ftnt2" id="ftnt_ref2">[2]</a></sup
      ><span class="c1"
        > וכנראה החילוף עלה יפה. ומה דעתי? אני מרוצה מאד שלקחת את החדרים האלו
        שזכרת ועוד יותר שהסכמת לדעתי שלא להשתמש בחדר האוכל. כנראה שעוד לא פג
        טעמי. המכתב של היום פרח ממך טרם שהספגתו לגמרי, ולכן אין שום ידיעה על דבר
        הרהיטים ומתי שתכנסי לכך, אבל על זה אנחנו לא מצטערים כלל, אם תתענגי על
        מאור החשמל עוד איזה ימים ותנוחי מעמל הרב של ערב חתונה. ועוד: הלא לפי
        מנהג ישראל צריכים לשבות שבעה ימים אחרי החופה. ומנהג כזה שלא יוכל להזיק –
        שפיר. בכלל, אפרת, אנחנו שמחים מאד על הצעד הזה שכבר עבר בלי שום התעיפות
        יתרה ובלי פוליטיקה מרגזת, שקט ושלוה ומנוחה שלמה. ואני מאחל לכם שחייכם
        תמיד יתנהלו לפי אותו הטעמפו – שקט ושלוה במעונכם תמיד, בלי התרגזות ובלי
        התרגשות</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >                                                                       
               לפי טעם אביכם מיכל.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי! </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >השתנה אצלנו הסדר הפעם – אבא התחיל במכתב ואני אגמר אותו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אלפי תודות לך, ילדתי הטובה, בעד דבריך הטובים והנעימים, שאת מעניקה אותנו
        יום יום. המכתב של אתמול, אשר בא בלתי גמור היום, הוא ישמש לי תעודה, אשר
        בכחה אתבע ממך תמיד אהבה ושלום ויחס של רצון הדדי. אל תצחקי! – המכתב יהיה
        שמור אתי לאות, שאדם מוצא בנפשו את אשרו, אם רוצה בכך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את מכתבי של אתמול עם 2 הטלגרמות בטח קבלת. כאן רצופים מכתבים. אני שולחת
        לך הכל, גם אלה שהם ממדרגה שניה, כך שנוגעים בצעדך לאושר החיים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >האם לא שלחת להודיע לנעמי? לגב&#39; ברגר – סולד אני כתבתי למחרת החתונה.
        הן בודי תחפשנה אותך. האינך חושבת לבקר את תפלות השבת שבביתן?</span
      ><sup class="c2"><a href="#ftnt3" id="ftnt_ref3">[3]</a></sup
      ><span class="c4"
        > הלא זוהי הזדמנות טובה להפגש פעם בשבוע עם אנשים שכדאי ללמדם מפני רחוק </span
      ><span class="c4 c6">[?]</span><span class="c1">.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הקוראת את עתון יום יום? זה צריך להיות חוק אצל כל אדם תרבותי. עד כמה
        שתיי עסוקה, אבל על זה על תעברי!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בשבוע הבא בודי תקני לך כלי-בית, אז תחפשי לך מכונה לטחון שקדים; אתמול
        השתמשתי בכזו של גב&#39; הרשפלד. אין לך מושג איזה תענוג היה לטחון במשך
        איזה רגעים יותר מרוטל שקדים, והטחון דק, דק ולבן והאפיה נחמדה. בודי תכתבו
        לנו על כל סדור מעונכם במשך השבוע ולשבת הבאה הלא בא תבאו אלינו?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >כשהמזכירה של גב&#39; סולד תשלם לך, אפרתי, עבור הדברים (חצי פונט אני
        חושבת הפח) תמסרי את הסכום הקטן הזה לקופת מלוה המרכזית ע"ח הערבות, שאני
        משלמת עכשו לשעורים.</span
      ><sup class="c2"><a href="#ftnt4" id="ftnt_ref4">[4]</a></sup
      ><span class="c1"> </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אצלנו שקט ושלוה. רק הרוחות הומות בחוץ ומתדפקות בתריסים; גשם טורד; הפרות
        משתעממות ברפת וחמדן</span
      ><sup class="c2"><a href="#ftnt5" id="ftnt_ref5">[5]</a></sup
      ><span class="c1"> מתגעגע לעבודה, שבת שבתון הודות לגשם.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">בא תימני לקנות היום. את "יקטן"</span
      ><sup class="c2"><a href="#ftnt6" id="ftnt_ref6">[6]</a></sup
      ><span class="c1"
        > בן "שמחה" לשחט לו לשבת ואני אמרתי שיקטן זה שייך לך. רחל היא הראשונה
        שהזכירה לי שרצונך להעלותו לירושלים הקדושה. החליטי שם במשך השבוע שאלה
        חשובה זו, אם לעשות את "יקטן" ל"חג&#39;" או לאו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו, ילדי, נשיקות רבות</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מאת אמא</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">מרים</span
      ><sup class="c2"><a href="#ftnt7" id="ftnt_ref7">[7]</a></sup
      ><span class="c1"
        > מוסרת לכם שלום וברכות ושואלת – אימתי אתם באים לראשון? היא תשמח מאד
        לראותכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הקבלתם את הכסף מחרמוני? אם לא, צריכים מהתחלה לאחז באמצעים חריפים. אח"כ
        יהיה יותר קשה להוציא.</span
      >
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא</span></p>
    <p class="c3" dir="rtl">
      <span class="c4">בזו הרגע התקבלה טלגרמה מהלוי</span
      ><sup class="c2"><a href="#ftnt8" id="ftnt_ref8">[8]</a></sup
      ><span class="c1"> ואני מצרפת אותה אל כל הכתבים שבכאן.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="4.2.1923">
      <span class="c9 c10">י"ח שבט תרפג  מנחמה לאפרת וצבי  [4.2.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשלץ י"ח שבט תרפג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שבוע, שבועת הזהב עבר חלף ורוצה אני שירח הדבש גם הוא יתמלא מנוחה
        ונעימות. ביום החמישי כתב רק צבי, אפרת כבר היתה עסוקה. אין דבר, רק לא
        להתעיף!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מיום שהתבגרה אפרת קבלתי אתמול בפעם הראשונה אורחים בלעדיה וְחסֵר עמוד
        התוך של הבית, פאר  הבית – במה יכולנו אנחנו, שני זקנים לענין את האורחים?
        יש שאמר: "בכניסה אל הגן נכר כבר שחסרה אפרתי. עזוב הכל!" ומודה אני, שידי
        קצרה להיות בכל. אני אשתדל לחזק את סדר הבית רק במדה כזו, לכשתבא אפרת תהיה
        לה פנה קצת נוחה בכפר. כל הנותר כמעט שלא מענין אותי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מז"ט! – שבתאית [הפרה?] המליטה בן בכור, ו"בכור" שמו. יום הולדתו שבת כמו
        יום הולדת אמו, ואך לשוא התוכחנו קודם מה שם נתן לולד? הלא יולדה ראשון
        ומאליו מובן שמו "בכור" או "בכורה"; - יש שבני אדם שוכחים את הדברים הכי
        פשוטים ועומדים ומתקשים על פתרונם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היום אתם מסדרים כבר בבית ותאמינו לי,- מחשבתי מלוה אתכם על כל פסיעה
        ופסיעה. איני מכירה את החדרים אבל אני מתארת לי שטח ידוע ושם מסדרת אפרת
        תחילה את המטות היפות ומתענגת על מראיהם; הנה פורשת השמיכה הזוגית, אוחזת
        כרים ונמלכת בדעתה: איזה מהם יזכה לשמש שמוש תמידי במדרגה הראשונה ואיזה –
        בשניה?
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הנה עוד שאלות סדוריות, שגם שניכם משתתפים בפתרונם! צבי גם בשבתו בחנות
        חושב על אי אלו שאלות כאלה, לא יפלא הדבר בעיני. הזוכרים אתם את התחלת
        הספור של טולסטוי "מות איון אליץ"? הגבור בשבתו על כסא מדון בתור שופט,
        חושב על יפוי חלונות ביתו בוילונים חדשים ואתם את וילוניכם עוד לא תליתם,
        היום או מחר יהיה האות הזה, אם התג- -  כרוניזים - -  -.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מבקשים אתם, שאבוא היום. יקירי! צר לי מאד שלא אוכל לעשות לי נחת רוח זה.
        אינני חרוצה עד כדי להספיק בשבוע אחד שני הדברים – נסיעה ירושלימה והכנות
        לקראת מאורע חשוב כנשף חברים שאני רוצה לערוך לכם בשבת. עם הזִקְנה  הסתלקה
        הזריזות, ותקופת החש&#39; והמהירות אינה הולמת כבר לאיטיות תנועותי. מה
        שנוגע לבקור אצלכם נפיל אח"כ גורל ונראה מי המאֻשר שיזכה קודם אני או
        אבא?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הראיתם את נעמי גיסולד וברגיר? מה משפחת בֵּהַם היודעים כבר את המאורע?
        אקוה שיחסכם לידידינו ישאר חם וכך גם כשתהיו עסוקים בעניני בית ומשק.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">שלום מאבא, סבתא, עשהאל, עדה והילדים. יונה מתפתתחת </span
      ><span class="c4 c6">[?]</span
      ><span class="c4"> יותר ויותר, אם תעקרנה במשך </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > הזמן החסרונות המועטים תהיה נערה מצינת. שאלתיה אם היא רוצה לנסע אתי
        ירושלימה ותען ברצינות גדולה: "איך אפשר? הגברת, הלמודים אינם מרשים..."
        אדם נושא בהתחיבֻיות!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לכם ונשיקות רבות </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מאת אמא.</span>
    </p>
    <br />

    <p class="c3" dir="rtl" id="5.2.1923">
      <span class="c9 c10">י"ט שבט תרפ"ג  מנחמה לאפרת וצבי  [5.2.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשל"ץ יום ב י"ט שבט תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כפי מנהגכם בשבוע העבר – לכתב לי יום יום, צפיתי אתמול למכתב ולא בא.
        עסוקים אתם כבר או חשבתם שאני באה ביום הראשון ולכן נמנע הענג ממני באותו
        היום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >פונה אני אליכם בבקשה להזמין לשבת אלינו את נעמי, גב&#39; סולד, גב&#39;
        ברגר ואת שרה טשציק. מקומות לשינה אני אזמין במלון וינעם לי מאד ומאוד
        השתתפותן אתנו בשמחת השבת. טוב היה שתקחו אוטו עד ראשון ולהזמין אותן באוטו
        זה. עונת פריחת השקדים עתה ורוצה אני מאד שג"ב סולד תראה את זה הרוח הבוטני
        שלה ישבע רצון. צבי לא ישכח בודי לכתוב לסובול לבא לראשון כפי שבקשתי עוד
        ביום החופה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >עם ההזמנות לשבת, אני שוב מרגישה את עצמי במצב של "חושם". בים זה איני
        מוצאת את עצמי. אתמול בלתה אתי מרים</span
      ><sup class="c2"><a href="#ftnt9" id="ftnt_ref9">[9]</a></sup
      ><span class="c1"
        > את הערב והרבה צחקנו בנדון זה על חשבוני. היא מסרה לכם שלום רב. היא היתה
        יפה מאד ונעימה מאד ואני נהייתי צעירה בחברתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >בא אתמול גדעון להפרד לפני נסיעתו ללונדון. שולח לך ברכה ואחולים לחיי
        אושר. אינו יפה עכשיו יותר </span
      ><span class="c4 c6">[?]</span
      ><span class="c1">, היה נבוך ונכשל בדבורו, קורה כזאת עם בני אדם!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">יזמין נא צבי במוצאי שבת את חבריו מיפו. לניומן</span
      ><sup class="c2"><a href="#ftnt10" id="ftnt_ref10">[10]</a></sup
      ><span class="c4"> כבר כתבתי, שיצרף לאוטו שלו גם את לאה ובן-ציון</span
      ><sup class="c2"><a href="#ftnt11" id="ftnt_ref11">[11]</a></sup
      ><span class="c4"> מרחל שלנו</span
      ><sup class="c2"><a href="#ftnt12" id="ftnt_ref12">[12]</a></sup
      ><span class="c1"
        > קבלתי אתמול מכתב מלא התרגשות, מתהוה אצלה כנראה איזה דבר והיא רוצה
        להתיעץ אתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">רצוף כאן מכתב ברכה אורגנלי מאת אדון אפשטין.</span
      ><sup class="c2"><a href="#ftnt13" id="ftnt_ref13">[13]</a></sup
      ><span class="c1"> </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תואילי נא, אפרת, לקנות אצל מארש מטר אחד שעונית לבנה עבור עדה. שרה
        דרובין קנתה שם מטר בשלושים גרוש וזה מין הכי משובח. תבקשי מאותו המין
        שקנתה שרה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">                     שלום לכם יקירי,</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >                                           כחפץ אמכם אוהבתכם בכל
        לב</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >                                                                       
             נפ"ש</span
      >
    </p>
    <br />

    <p class="c3" dir="rtl" id="14.2.1923">
      <span class="c15">כ"ח שבט תרפ"ג </span><span class="c15 c32"> </span
      ><span class="c9 c10">מנחמה לאפרת וצבי   [14.2.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יום ד&#39; כ"ח שבט תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >נשתנו התפקידים: התחלתי אנכי לכתב לכם יום, יום. צער גדול הצטערתי אתמול
        כשהדאר בגד בי ולא הביא לי שום מכתב מכם. האמנם אומרת ילדתי הטובה
        לגלות</span
      ><span class="c4 c6"> </span
      ><span class="c1"
        >אותי מאותו התענוג, שהשתדלה להוציא לי שבועת הזהב שלה? אינני רוצה עוד
        להאמין בזה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">על חתונת אדליהדה</span
      ><sup class="c2"><a href="#ftnt14" id="ftnt_ref14">[14]</a></sup
      ><span class="c4"
        > וגם חתונת יהודית התקבלו ממך טלגרמות ברכה, זו היתה בשבילי בת קול, שאת
        בריאה ושמחתי. בחתונת אדלהידה התערבבו יופי ועצב עמוק – הטרגדיה של העם עם
        כל העצב הנוגה שלה;</span
      ><sup class="c2"><a href="#ftnt15" id="ftnt_ref15">[15]</a></sup
      ><span class="c4"
        > העצב הנוכחי של האב, וכל עצב המשפחה נגעו עד הנפש, וכמעט בכל עין היתה
        נראת דמעה. לאה ובן-ציון</span
      ><sup class="c2"><a href="#ftnt16" id="ftnt_ref16">[16]</a></sup
      ><span class="c1"
        > באו. ללאה לא חסר רק מראה טרגי זה, ועיניה היו אדומות יותר משל אחרות. בן
        ציון היה נחמד. הוא יהיה בירושלים בשבוע הבא ויבקרך וימסר לך שלום ממני.
        יכותיאלי ואדלהידה הבטיחו לי להיות ידידים טובים אתך, זה זוג קטן ונעים,
        שאני מחבבת והייתי רוצה שתשרה ידידות ביניכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עדה נסעה אחה"ץ לחתונת יהודית ואמה מסרה לה מתנה נהדרה בשבילכם – כפות,
        מזלגות וסכינים, 18 במספר מהמין המשובח, עדינים ויפים עד מאד. היא קנתה עוד
        בשבוע העבר, אבל צפורה שכחה לקחת אותם ועכשו אני חוששת שמא כבר קניתם בימים
        אלה – וחבל אם הוצאתם כסף חנם. אני אביא אותם בבואי, וכתבו לי אם עוד לא
        נכנסו לבית חוץ מאלה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם אתם חושבים על זמן בואי, אז אני צריכה להגיד לכם את דבר החלטתנו לבא
        אליכם לפורים, ושני הזקנים בבת אחת! גם אחד הנכדים נקח אתנו. אלישיב אינו
        נותן מנוחה – "מתי יסע אתנו לירושלים?" ברגע שלוה אתכם אצל האוטו, הרי
        שמעתם את בקשתו לקחת אותו לירושלים, מאד אינו חודל לחלם על נסיעה זו. בקר
        וערב: "מתי סבא? ומתי סבתא?" מובן, ששנים האחרים ג"כ חולמים על נסיעה, אבל
        בפחות התלהבות ממנו ולכן חושבת אני שהגורל יפל עליו. אנחנו נבא ביום ד&#39;
        י"ד אדר וביום ה&#39;, אסתר כנענית, נכין יחד את כל הדרוש. אל תדאגו קודם
        לכלום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה שנוגע לחג השני היותר גדול – פסח, נמנה וגמור שאתם תבלו אצלנו. וככה
        אני רוצה, שכל חג וחג קטן או גדול נבלה בחברותא. אקוה שהזמן לא יהיה
        בעוכרינו ונוציא מחשבה יפה זו אל הפועל.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הבריאה את כבר, ילדתי? העבר השעול? שתי הרבה חלב והזהרי מרוח פרצים, את
        יודעת הלא שבבית היתי תדיר מזהירה אותך מזה בימי חורף.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >הקוראת את יום יום עתון, כמו שבקשתיך? מובן שאני רוצה, שתקראי את "הארץ".
        מענינים מאד הגליונות האחרונים מועידת הפועלים, זהו עורך חי בתוך החיים
        הקפואים. המאמרים של ד"ר גליקסון – מ.ג. – תמיד מענינים מאד: תראי בגיליון
        של יום שני זה המאמר: "נאומו של הנציב העליון". בכמה חכמה וזהירות וכאב לב
        נאמרו דברי הבקרת! השתדלי יקרה לקרא יום יום עתון – אשריך! </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > ועוד דבר נחוץ אחד, אף על פי שאתם עסוקים מאד: מחיב צבי להתחיל בשעורי
        עברית, אין לדחות את הדבר הזה בשום אופן. אח"כ יהיה יותר קשה, יבאו ענינים
        אחרים ויפריעו. התחילו כעת, אני משביעה אתכם. צבי בעל כשרונות וישתלם בשפה,
        אם את ילדתי תרצי בכך תקראו יחד ותני לו לחצי שעה ביום עבודה בכתב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">רצוף כאן מכתב מד"ר שפירא</span
      ><sup class="c2"><a href="#ftnt17" id="ftnt_ref17">[17]</a></sup
      ><span class="c1"
        > ומשפחתו. אם יהיה לך זמן ורצון כתבי להם. אני חייבת להם תשובה כבר על שני
        מכתבים ואפשר שהיום אכתב להם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לכם ונשיקות רבות, רבות</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מאת אמא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מה שלום נעמי וגברות סולד – ברגר?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כתבו לי איזה יינות אתם מחבבים יותר – ואביא אתנו יחד עם קוניק וליקר. מה
        שם בעל הבית שלכם? יש ששואלים למקום מגורכם ואיני יודעת לענות בדיוק.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מי הם אלה מהמכירים שלנו, אשר בקרו כבר את ביתכם? או לזה דרושה בעיר הזמנה
        מיוחדת?
      </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="15.2.1923">
      <span class="c15">כ"ט שבט </span><span class="c15 c32"> </span
      ><span class="c9 c10">מנחמה מנחמה לאפרת וצבי  [15.2.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשלץ יום ה&#39; כ"ט שבט</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים,</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >גם לי צר מאד, שלא נסעתי אתכם. אבל איך אפשר היה? התכנית לבא אליכם לפורים
        נוצרה משני טעמים: ראשית רוצה אני לגשת להתחלה – לבלוי החגים יחד, ושנית
        קשה לעזב את אבא בלי השגחה. ואף על פי כן מוכנה אני ומזומנה לותר על הכל,
        אם רק נחוצה לכם עצתי באמת. התחשבו וביום א&#39; תטלגרפו לי מלה אחת:
        -בואי-, ואבא תיכף.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">שרגית </span><span class="c4 c6">[?]</span
      ><span class="c1"
        > אצלנו הסתדר מהר וקל ולא עיפתי ביותר. כבר כתבתי לכם במכתבי הראשון של
        השבוע. הדעות שהובעו ע"ד הנשף הן מצוינות,- זה בפָנַי, מה לאחורי הקלעים
        מדברים איני יודעת. הקרובים אלי בלב שלם אמרו שהאטמוספרה היתה מצוינת, חמה
        וידידודית שלא פגשו מכבר כמוה וכלם הרגישו את עצמם טוב מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">ההזמנה שבאה לך להכן </span><span class="c4 c6">[?]</span
      ><span class="c1"
        > לועד התרבותי משמחת אותי ומיעצת אני לך לקבלה. צריך אדם למלאות חוץ
        מחובות ביתו עוד חובות צבור, ואם נותנים תפקיד יפה – מה טוב! השתדלי לעזר
        לג"ב סולד בכל מה שרק אפשר ותמלאי בזה חוב קדוש.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שעונך אפרתי נמצא באחת הקופסאות ואביא אתי. חומר לסמרטוטים ג"כ אביא. אם
        תקני קדרות, תפחיתי 2 ממספרם, כי מוכנות אצלי 2 קדרות הנחשת להוביל לך והן
        תשמשנה לך לצלי ולירקות. יש ברעיוני לפדות בכסף מהבנק, ע"י ועדת
        קרן-הגאולה, את פמוטי הכסף שלנו ולהובילן שי לילדתי. אם יצליח הדבר בידי
        אשמח מאד. ע"ד כפות, מזלגות וסכינים שיש לך כה יפים כתבתי לך אתמול. אקוה
        שתסדרי לך משק בית יפה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה שיסוד חייכם מתחיל להבנות על הלואות, זה לא משמח אותי. מפירות ההלואות
        טעמתי רק בשנים האחרונות, השקטות, ב30 שנות חיי המשפחה לא לקחתי אף פרוטה
        בהלואה ואומרת אני לכם: הנה אני לעשות כל מיני עבודות ___ עכשו שלא להזקק
        להלואות יותר!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם קשה לכם לרהט את החדר השלישי מי זה דורש מכם לעשות התאמצות זו? לנו אל
        תדאגו, לנו נעים יהיה לבא אליכם אם גם לא יהיה חדר שלישי. אנחנו אנשים
        שמסתפקים במועט כידוע לכם, ובכלל יש לי "עקרון" – פרינציפ – קבוע בחיים:
        לחיות כפי היכלת ולא למעלה מהאפשרות הכספית הברק החצוני שנוא עלי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ובכן אני מחכה לתשובה מכם, אם עלי לבא בתחילת השבוע הבא לבדי, או באמצע
        השבוע השני יחד עם אבא ולחוג בזה אתכם את הפורים. אם אסע קודם לא אוכל
        לשהות עד פורים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום ונשיקות לכם מכלנו</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה רבה     אמא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אם תהיו אצל ד"ר בהם, תדרשו בשלומם.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אסתר מרגלין שואלת אם יש לה הרשות לכתב לג&#39;יקוב. שיעבר</span
      ><span class="c4 c6"> </span
      ><span class="c4">את כלי הנגינה של לזר ב"תרבות"</span
      ><sup class="c2"><a href="#ftnt18" id="ftnt_ref18">[18]</a></sup
      ><span class="c1"
        >? הוא מקוה שבבית המסחר ימצא פעם קונה עליו. אבל אם עד זמן ידוע לא ימכר,
        אז תקחהו הנה להגרילהו ע"י מישהו. אקוה שצבי יענה לנו ע"ז אם מסכימים
        בתרבות או לא?</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="6.3.1923">
      <span class="c9 c10">י"ח אדר  מנחמה לאפרת וצבי  [6.3.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יום ג&#39; י"ח אדר.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרת וצבי היקרים.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עוד לפני חצות היום באתי אתמול הביתה. הירידה היתה מהירה מאד, וכשרק עמד
        האוטו, מצאתי בזו הרגע אוטו מראשון ונסעתי הביתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שמחתי מאד לראות שוב את הירק את הסביבה, את הילדים, את אמא. הבית היה רחוץ
        ונקי וכל פנה רננה לקראתי. רק ברפת מצאתי אי סדר וכשנכנסתי אח"כ מצאתי
        עבודה מרובה ומעיפת. ועוד יותר מהעבודה מפריעים אותי הביקורים. נגשתי לכתב
        לך ואינני יכולה בשום אופן להמשיך. הנה כבר קרוב לשעת סגירת הדאר ועוד לא
        כתבתי כלום מכל מה שהצטבר בלב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >רבים שואלים עליך, אפרתי, רוצים לדעת פרטי פרטים מחייך ואני משתדלת לתאר
        להם הכל, לפי הרושם שקבלתי. את צפורה עוד לא ראיתי, היא ערכה נשף בפורים,
        ובהצלחה ועסוקה בשיירי עבודת הנשף הזה. עסקי אגדתנו אפפוני מרגע בואי.
        דרושה עזרה מכל צד ואני כה רוצה לעשות איזה דבר בשבילי, בשביל עטי, ואיני
        יכולה כלום כלום. מונחות על השלחן הרשימות, גם בשביל אבא יש לו דבר לשלח
        ל"שדה". מצאתי כאן מכתב מהמערכת, שמבקשים אותו מאד לתת מאמר בשביל חוברת
        ניסן. החומר נמסר לידי מאבא ואין פנאי לשבת רגע להכינו – כה מרובים
        הענינים. אצל עדינה</span
      ><sup class="c2"><a href="#ftnt19" id="ftnt_ref19">[19]</a></sup
      ><span class="c1"
        > הצטברו הרבה עניני אגודה ואני צריכה לגמר אותם. חשבתי באתי להנה בודדה
        ואיש לא יפריעני – וטעות היתה! יש תקופה שאדם לא יכל לחיות בשבילו ותקופה
        זו הגיעתני.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את עדה לא מצאתי בבית, היא נסעה אתמול בבקר ליפו ותשהה איזה ימים. יתר
        הילדים שמחו בבואי מאד, ביחוד יונה, יש אותה חבה שצריך להיות בין סבתא
        ונכדה. שבלי עוד מן החוץ צעק: מה הבאת לי? וכשראה אוטומוביל נהדר (הראשון
        כבר אינו בעולם בקשני להביא אחר) שמח מאד ומאד. ספה"לי רצה בפטיש תכף לשבר
        את הקוף הגדול שנמסר לידו אבל הסכים אח"כ להעמידו לשמירה על הארון.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כל זה אולי לא מענין ביותר, אך אני משתדלת למסר לכם כל פרטי שיבתי
        הביתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה אצלכם, מה שלום עינו של צבי. הוא מבקש לא להביט עליו, ואני נושאת את
        המחשבה בלבי שהוא צריך לרפאות את העין. בשביל שתהיה לו אשה יפה, צריך הוא
        להשתדל שתהינה לו עינים יפות, מאירות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >בבקשה צבי יקירי, למסר לפסח גרינצבורג שהמכתב אשר אודותו דברתי אתו, התקבל
        מירושלים מיבזרוב </span
      ><span class="c4 c6">[?]</span
      ><span class="c4"
        >. אין שום יסוד לחשד את סגל במעל. כל המשפחה עבור "הארץ" אבל </span
      ><span class="c16 c4">ההמון</span><span class="c4"> דורש מתאמים </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"> ברוח "דואר היום". מה לעשות?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לכם יקירי. כתבו לי על הכל.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">רבה </span></p>
    <p class="c3" dir="rtl"><span class="c1">אמא</span></p>
    <p class="c3" dir="rtl">
      <span class="c1">לא עברתי על המכתב ולא תקנתי השארתי פליטת הקולמוס.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="8.3.1923">
      <span class="c15">כ&#39; אדר תרפ"ג  מנחמה לאפרת  </span
      ><span class="c9 c6">[8.3.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשון לציון כ&#39; אדר תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדתי הטובה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >יחד עם מכתבך של שלשום הגיעני הטלגרמה של ד"ר שפירא והמכתב של אבא – את
        שניהם אני שולחת לך. תודה לאל שהכל עבר בשלום!</span
      ><sup class="c2"><a href="#ftnt20" id="ftnt_ref20">[20]</a></sup
      ><span class="c1"> –</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את הצעת העזרה מצדך לא אוכל לקבל. את עובדת די וג"כ זקוקה למרגוע קצת. חוץ
        מזה איני יכולה להסכים שצבי ישאר בדד. די שהוא יצטרך בחוה"מ לשוב לבית
        המסחר ולהיות לבדו, להתחיל נדודים מעכשו לא מן היושר הוא. אסתדר, אין דבר.
        דוד ודוד מסורים לעבודה, הקטנות בבית שומרות תפקידיהן והכל טוב היה אלמלי
        ... בחצר, אבל לזה אין תקון, וזה בסך הכל הבל ורעות רוח, לא צריך
        להתחשב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >טוב מאד שהצעת לפני נעמי את העזרה בצערה, - אח לצרה יולד!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בדבר ג"ב ברגר, אינני חושבת שאת צריכה לחכות להזמנות. הלא היית בת בית שם.
        וכשנסעת אחרי סכות סרת ישר שמה כמו אל ביתך ממש, ומה פתאם הצרמוניות? הכנסי
        באופן פשוט וחסל. חבל להפסיד ע"י חשבונות תפלים ידידות כזו. את יודעת מה
        שיכולה לתת ג"ב סולד בהבנתה העמוקה ובמוסריותה הנעלה, מדוע תדחי בקש דברים
        כאלה, שהם יקר המציאות? שום דבר לא צריך להפריע בעד היחס הטוב, שהיה שורר
        ביניכם. את יכולה וצריכה להיות ידידת ביתם. למה זה למסר פרחים ע"י המשרתת
        ולהסתלק? "ישר במבוא הראשי!" אמר אדם גדול.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כתבתי אתמול לסוביל, שהיא מחיבת לבלות אצלנו את חג הפסח. בטח גם צבי כתב
        לה כפי בקשתי. אתם חוששים, שמא נסענו ברשם לא טוב. מדוע זה, יקרה שלי? הלא
        אי אפשר לדרש מן החיים יותר מכפי שהם יכולים לתת. רק יחידי סגולה, אנשי
        הרוח גוברים את הפעטות של החיים, ואנחנו אין חלקנו בין אלה. "פנאי חסר" –
        גם אני מתאוננת ככה כל ימי ומזניחה עשר רוחני מפני הבלי יום יום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >למה תבקשי ממני, ילדתי, סליחה, כאלו חסרה לי המנוחה בביתך? אני להיפך
        הרגשתי מוסר כליות, שאינני עוזרת לך די. באותו בוקר שהלכתי לבית הנכאת
        ועזבתי עליך את העבודה, אכלני החרטה. התאמיני לי, שאני מאמצע הדרך רציתי
        לשוב לעזר לך בעבודה, רק בשביל אבא המשכתי דרכי. איך קבלו את פני בבית כבר
        כתבתי לך. מה ספרתי להם? ספרתי שאת יושבת בהיכל שן, כרובים מעופפים למעל
        ואת בנעלי פז מעופפת ישר לזרועות בן-המלך. הקטנים מזכירים לעתים קרובות את
        אפרת, אבל יותר יפה מבטאים את המלה – צבי. מאירקה נעשה רזה וקל אחרי
        שגמלוהו. היתה לי שיחה עם ספה"לי היום ברפת. אמרתי לו כשיהיה נער גדול יעזר
        לי בחליבת הפרות והוא הוסיף: "גם אבא אהיה!"</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היו אצלי צפורה, גרטלה, מרים לשאל עליך ולמסר לך שלום. גרטלה מבקשת מאד
        שתבקרי את אדלידה השוכבת חולה כמעט כל הזמן. אני מצטערת, שלא הייתי
        אצלה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >איך סדרת עם הזיתים? איזה כלי מצאת בשבילם? בבקשה לראות את המזכירה של ג"ב
        ברג&#39;ר ולתת לה הוראות לכבוש פח הזיתים שאצלה. צר לי מאד אם לא יפיקו
        מהזיתים את התועלת הדרושה. אני מוכנה אז להשיב לה את הכסף. לזיתים שלך
        צריכים להוסיף עוד כ8 פלפלים. בודי שתצאו לקנות בשוק. קני יותר ותחריזי על
        חוט. יהיה לקיץ למלפפונים.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נשיקות רבות לך ולצבי. מה שלום עינו? העוד אסור להתענין בה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה אמא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את ג"ב בולקין ראית? בבית היה רק מעט חמאה וגבינה ושלחתי אתה. בטח מסרה
        תכף.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="13.3.1923">
      <span class="c15">כ"ה אדר תרפ"ג  מנחמה לאפרת וצבי  </span
      ><span class="c9 c6">[13.3.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשלץ כ"ה אדר תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרת וצבי יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את שני המכתבים, מיום א ויום ב קבלתי. מדוע את חושבת, ילדתי, שאני רוצה
        ללעג לכם? נחה עלי רוח הפנטסיה ואני תארתיכם רגע בתור גבורי אגדה. אסור
        לעוף על כנפי השירה פן זה יכעיס את ילדתי ויעיר בה דברי קנטור? טוב חביבה –
        אהיה רצינית, רק רצינית, אבל אל תדברי על עליה או נפילה – אין מדה
        לזה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >מצב הג"ב קונין נגעה עד לבי – מה היא תעשה כעת? ואיך תכלכל את הילדה
        שלקחה? שועד היתומים יפתר</span
      ><span class="c4 c6"> [יפטר?]</span
      ><span class="c1"
        > אדם שעבד במסירות שנים – צריך היה לצאת איזה דבר יוצא מן הכלל. ג"ב ברגר
        לא על נקלה יכולה לעשות צעד כזה. שהג"ב הופין תכה את הילדים ועוד תיכף
        בהכנסה למוסד, אינני מאמינה. אבל אם היא האדם המתאים, ג"כ לא ברור לי ובכלל
        מה אנחנו יכולות לדון בזה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >שלשום היתה אצלי אננה לוין. כנראה שהיא תקבל משרה בחיפה – אנטרנט</span
      ><sup class="c2"><a href="#ftnt21" id="ftnt_ref21">[21]</a></sup
      ><span class="c1"
        > של ד"ר בירם. רוצה יותר טוב ללכת לנהלל בתור פועלת פשוטה, אבל אני לא
        יעצתי לה. אם היא יכולה לנהל מוסד שלם, מדוע לא תקבל את ההצעה? בכל אפן זה
        יהיה אחרי פסח, ואת החג בטח תבלה אתנו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אסתר מרגולין</span
      ><sup class="c2"><a href="#ftnt22" id="ftnt_ref22">[22]</a></sup
      ><span class="c4"
        > קבלה משרת מילדת ברחובות. זה לא יכניס כעת הרבה, אבל בכל אופן יותר מאשר
        בראשון, מקום שעובדות 2. לי קשה יהיה בלעדיה, אני מאבדת הרבה בנסיעתה, אבל
        מה לעשות? עוד יותר קשה בלעדיך וצריכים גם להסתגל! אסתר מצאה דירה טובה מאד
        ברחבות, אצל צעירה עקרונית, שהתחתנה מרחבות והיא מכירה ומוקירה את אסתר ואת
        דוד</span
      ><sup class="c2"><a href="#ftnt23" id="ftnt_ref23">[23]</a></sup
      ><span class="c1"
        >, ולקחה עליה את הטפול בו בכל זמן שאסתר תהיה עסוקה – זה דבר מצוין!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >רחל ליפסון היתה אצלי במוצאי שבת היא מתארחת אצל משפחה אחת בנס-ציונה.
        חשבה לשוב השבוע ליפו, אבל כנראה שלא יתנו לה. מסכין ניומי – יתגעגע!
        ונעימה רחל, נעימה ורכה וכמה חיים יש בה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >היום ישנן אורחות במושבה, - באו 2 בתי ספר מירושלים, בית ספר לבנות
        ג&#39;, ובי"ס – א. אצלי רשמו 2, אבל מתאכסנות שש. ארבע הן חברות של
        אסתר</span
      ><sup class="c2"><a href="#ftnt24" id="ftnt_ref24">[24]</a></sup
      ><span class="c1"
        > מבית ספרה וממחלקתה. תוכלו לתאר איזה ששון ושמחה כאן!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אלכסנדר רטנר קבל היום מכתב מג"ב ד"ר שפירא ובו היא כותבת, שבקרה בבית
        החולים את אבא. הוא חלש, אבל הכל הולך כשורה. לכתב לא מרשה לו הרופא. היא
        כותבת שהחיתה את רוחו בבואה, כי עצוב לו בבית החולים, והיא רוצה לבקש
        משפירא רשיון מיחד, לבקר את אבא תכופות, מה שלא הותר בכלל.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ולך, צבי, יש בקשה מיחדת מרטנר: - הוא הגיש בקשת רשיון כניסה לגיסו הצעיר,
        ג"כ רופא. גר עם אשתו בוילנה ובתור נתיני רוסיה מגרשים אותם כעת משם והם
        נמצאים בכל רע. מחיבים להשתדל שהרשיון הנשלח מיפו היום יחתם בירושלים בהקדם
        האפשרי. רצוף כאן הנומר על פסת ניר זו שרכסתי למכתב – מינדלין הוא הוא האיש
        שבו תלוי הדבר לחיוב או לשלילה וממך מבקשים לפנות מיד למקום הדרוש ולהשתדל
        שלא ישיבו את בקשת הרשיון ריקם. רטנר תולה בך את תקותו, שאתה תעשה את כל מה
        שאפשר מצדך לטובת הענין הזה, שהוא פקוח נפש ממש. אני יודעת, שאתה עסוק מאד,
        אבל אין שום תירוץ בדבר. מחיבים לכל אדם לעזר במקרה כזה ובפרט שאנחנו כה
        קשורים עם המשפחה הזאת. אקוה שלא תחוס על עמלך ותצליח במשלחת.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו את נשיקותי החמות   מאת   אמא.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="16.3.1923">
      <span class="c15">כ"ח אדר תרפ"ג  מנחמה לאפרת וצבי  </span
      ><span class="c9 c6">[16.3.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ע"ש כ"ח אדר תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדים יקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אין פנאי לכתב כעת ואף על פי כן אתאמץ לערך איזה מלים בשביל שלא תצטער
        הילדה על העדר מכתבים. מכתבך הקצר, אפרתי, בצרוף מכתב של אבא קבלתי ביום
        ד&#39; ובאותה שעה הגיעוני עוד 2 מכתבים מאבא אחד ע"י ג"ב קורציק, ששבה עם
        בנה מבית החולים, והשני – ע"י הדאר. באחד מהם השתתפו מרים ורחל שפירא והד"ר
        עצמו, דבריו היו חמים וידידותיים עד אין שעור. אבא סבל הרבה, אבל בעת כתבו
        את המכתב כל הסבל עבר חלף, והוא שמח בחלקו. את ד"ר שפירא הוא מתאר בצבעים
        נפלאים בתור בעל מקצע יוצא מן הכלל ובתור אדם נעלה. וכל סדר ביה"ח והרכבתו </span
      ><span class="c4 c6"> </span
      ><span class="c1"
        >הכל מצא חן בעיני אבא. אקוה באלה הימים לקבל ידיעה שהוא כבר יושב ולא שוכב
        פרקדן ואולי כבר ישע – אימתי יצא לחופשי. בטח גם לכם ימשיך לכתב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שמחתי לשמע שאת תבואי שבוע לפני החג. לא חוששת לעזב את הילד – טוב מאד;
        חפש, זוהי שטת חנוך הכי חדישה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היודעת את שברכה הרשפלד בירושלים? חסן הארז הזה התערער – חלתה בזמן האחרון
        מרוב עבודה וכנראה שזקוקה תהיה לנתוח. אצל אסתר קריצ&#39;בסק-ברילנט תדעי
        איפוא היא נמצאת כעת ותבקרי אותה. אבא שלח לה במכתבו האחרון ד"ש ידידותית –
        תמסרי לה ותודיעי לי מה שלומה, לאיזה בי"ח נכנסה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >לפני נסיעתך תסורי למשרד של הג"ב ברג&#39;ר ותבקשי בשביל אסתר שמלה
        חדשה</span
      ><sup class="c2"><a href="#ftnt25" id="ftnt_ref25">[25]</a></sup
      ><span class="c1"
        > לחג ואיזה זוגות גרבים ואולי יש במוכסן שק למטיילים. הנה ביה"ס עורך טיול
        ביום ראשון ואסתר אינה יכולה לקחת את חפציה על כתפה כנהוג. אם תביאי לה שק
        – יהיה לטיולים הבאים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >התאכסן אצלי ניימן השבוע, בא לראות את המכירים, וביקש למסר לך שלום. הוא
        יבא יחד עם יענקה"לי בחוהמ"פ.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >גברת לוין בקשה שתביאי אתך הנה בגד ששלחו לה מגרמניה ע"י הגברת פרלמן
        שנשואה לאיזה אדון, שהוא מנהל כעת את הגמנסיה העברית בירושלים. אננה אמרה
        את שמו ושכחתי, אבל היא גם בעצמה תכתב לך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הראית עוד פעם את אדלהידה – מה הראו קרני x? יקוטיאלי היה פה עם בית ספרו,
        ונסעו גלילה, תודיעי לי את מהלך מחלתה, גרטלה בקשה מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ואתה, צבי יקירי, בטח מלאת את בקשתי בדבר הרשיון לגיסו ואחותו של רטנר.
        הבקשה הוגשה בשם המושבה ראשון לציון, שמזמנה אותו בתור רופא (באופן פיקטיבי
        כמובן) בשכר של 20 ל"יש לחדש. לדבר אין טעמים כנגד ובכל זאת צריכים
        השתדלות. בטח אתה יודע לאיזה מחלקה של הממשלה ולאיזה פקיד לפנות ותעשה את
        כל האפשר מצדך. כאן יש פקוח נפש ממש.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ושלום לכם וקבלו נשיקות חמות מאת אמא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אפרתי! שכחתי לכתב לך, שהייתי השבוע בגן הילדים. במצבו של ספה"לי בא שנוי
        גדול לטובה. הכרתי זה גם בבית, אבל רציתי להוכח ע"י הגננת.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="20.3.1923">
      <span class="c15">ג&#39; ניסן תרפ"ג  מנחמה לאפרת וצבי  </span
      ><span class="c9 c6">[20.3.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשל"ץ ג&#39; ניסן תרפ"ג     </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">לבני היקרים אפרת וצבי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את מכתבך, אפרתי, ממוצ"ש קבלתי אתמול ושמחתי מאד, שאתם מטילים הרבה
        ומקבלים אורחים הגונים; מתפלאה אני מאד שאין במכתב הזה נזכר שום דבר על
        אודות הבקשה, שפניתי לצבי בדבר גיסו של רטנר. עוד מהר אלכסנדר ביום ד&#39;
        בבקר השכם לבא ולשלח את המכתב דרך יפו, בשביל שימהר לבא אליכם – האמנם קרה
        מקרה והמכתב לא הגיע עדיכם? והענין כל כך נחוץ. גם שלשום התקבל מכתב שהם
        נמצאים בכל רע ומתחננים על נפשם לשלח את הרשיון. ראשון לציון עשתה יפה מאד,
        שהמציאה מצדה הזמנת רופא, אבל כל העיקר תלוי מירושלים וכל המשפחה תולה
        תקוות בצבי שיש בכחו לעשות איזה דבר. במכתבי הראשון היה רצוף מספר הניר
        שנשלח לירושלים, והלא אי-אפשר שהמכתב ההוא יאבד; בא בודי באחור זמן. אבל את
        מה שאפשר לו צבי בטח יעשה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שני המכתבים של אבא אני שולחת לך כאן – אקוה שיענינו אתכם. איך אבא ישוב –
        דרך חיפה או ירושלים ואימתי ישוב, - איני יודעת עוד. אבל אם ישוב דרך
        ירושלים הייתי מבקשת אותך לבא אתו יחד, זה ינעם לו מאד וכמובן שישמח גם
        אותי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >סוביל נסעה באחד הימים אלה באוטו יחד עם אבשלום פין ובקשה אותו, שימסר לי
        שקבלה את הזמנתי ותהיה אצלנו לחג הפסח. בודי גם לכם כתבה כזאת. איך הסתדר
        עם הדירה של נעמי? הנשארה בה ואיפוא תהיה בחג – בירושלים או ברחבות? בבקשה
        למסר לה וללדיז&#39;נסקי שלום וברכת חג. למנוחה"לי נשיקות, היא ילדה
        שמפליאה בהתפתחותה ולא יכולים להתיחס אליה בלתי אם כאל גדולה. גם יונה שלנו
        כבר רצינית ותענוג לבלות אתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את ילדי ד"ר מגנס עם המחנכת אני מקבלת. מוטב שיביאו כרים ושמיכות אתם – זה
        בכל אופן יותר טוב בשבילם. בטח תגיד לי המחנכת איזה מאכלים מותרים להם
        ואיזה אסורים. הילדים בודאי ירצו פה לרכב על החמור ועל זה אני רוצה רשיון
        מההורים, אם הילדים עוד לא מנוסים ברכיבה. יש עוד שאלה, - הצריכים הילדים
        לישון בחדר אחד עם המחנכת או לא?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ההתחלתם בלמודים? בנסעך מן הבית, את צריכה לתת לצבי עבודה רבה בכתב. יביא
        את זה הנה ותתקני. הוא יכל לכתב בקרת על ספרים, תאורי טבע ותאורי חיים או
        זכרונות. להשתלם בכתיבה זהו הדבר הראשון, - אח"כ ההשתלמות בהסתוריה
        ובספרות. מה שלום עיניו? – הוא צריך להזהר מאד מאבק ובעיקר לפנות לעצת רופא
        ולשאל מה לעשות כדי שלא תסבלנה העינים לעתים כה תכופות. ומה עם כאב הצד
        שלך, ילדתי? את ג"כ מזניחה את בריאותך, בקשתיך כמה וכמה פעמים לשאל בעצת
        רופא ואת מתרשלת בזה. אבל כעת יש משגיח עליך ואני מוסרת את בריאותך
        לפקודתו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">בדבר בנין ביתנו או חרבנו נדבר לעת פנאי.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כתבתי לג"ב ברגר שימסרו עבור אסתר לידך בגדים: שמלה, גרבים, שק לטיול, אם
        יש. גם סנדלים וכובע נחוצים לה. סנדלים מ&#39; 35, מדה לשמלה, שלוחה כאן
        וכובע, גם הוא חשוב תכף ... אקח מדה ע"פ ראשה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">בתקוה לראות את ילדתי בעוד ימים מספר _ _</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שולחת לשניכם נשיקות רבות</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא.</span></p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="25.3.1923">
      <span class="c15">ח&#39; ניסן תרפ"ג  מנחמה לצבי </span
      ><span class="c9 c6"> [25.3.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשל"ץ ח&#39; ניסן תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">צבי יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כותבת אני רק לך, מפני שאפרת בטח תהיה פה כשיבא מכתב זה אליך. אבא קבל את
        מכתבכם, אבל לא כתב אליכם, מפני שחשב שאפרת כבר בבית אמה. זהו מה שהוא כותב
        במכתבו האחרון ופונה בו גם ישר לאפרת בבטחון, שהיא כאן. את מכתביו משבוע
        העבר לא שלחתי לכם מפני זה ששם כתוב כי הוציאו לאבא 2 פעמים מים מן הרגל
        ולא רציתי שאפרת תסבל צער.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם אפרת לא לקחה אתה 2 כרים תיקח אתה. גם שמיכה אחת. רב תודות לך בעד
        השתדלותך אדות הרשיון, שם גיסו של רטנר הוא: ד"ר ל. כצנלנבויגען. אולי תוכל
        להודע אם מר גינצבורג (בנו של אחד העם) מקורב עם חיימסון? זה חשוב לענין.
        בטח היית עוד פעם בועד ההגירה כמו שהבטחת במכתבך ותודיע אם התקבלו מיפו
        הנירות או לא? רטנר חושב לנסע ליפו לחקר – אולי נשכח מלבם הדבר לגמרי כנהוג
        בממשלה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >על איזו הזמנה מגבוה אתה מדבר איני יודעת. בעתון לא פגשתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כתבתי שאפרת תסע עם אבא יחד, מפני שקויתי שאבא יבא לשבת ואולי דרך
        ירושלים, אז הייתי רואה אותה יומים קודם. לחכות כעת לאבא איני מסכימה, - לא
        ידוע לי עוד מתי אבא ישוב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום רב לך – אל געגועים! הלא תראה את אפרת בעוד ימים מספר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">באהבה אמא.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="9.4.1923">
      <span class="c15">כ"ג ניסן תרפ"ג  מנחמה לאפרת וצבי</span
      ><span class="c9 c6">  [9.4.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשלץ כ"ג ניסן תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">הבקר הביאו חבילה שנשלחה לך, אפרתי, מאת ד"ר סלימן</span
      ><sup class="c2"><a href="#ftnt26" id="ftnt_ref26">[26]</a></sup
      ><span class="c4"
        > ואשתו ע"י מר בנטביץ מלונדון. שם נמצא מן משי עבה כעין סטיקרוז </span
      ><span class="c4 c6">[?]</span
      ><span class="c4"> לחליפה, משי כחול לשמלה ובד דק (בטיסט</span
      ><sup class="c2"><a href="#ftnt27" id="ftnt_ref27">[27]</a></sup
      ><span class="c1"
        >) לחולצות. שלחתי תיכף לשאל את פנינה הלל אולי היא נוסעת ואשלח אתה, אבל
        היא ביפו צריכה לשוב היום או מחר ואינם יודעים אימתי היא נוסעת. אסור אליה
        בערב ואם אינה נוסעת השבוע אז אשלח את החבילה בדאר, היא הסתובבה דרך רחבות
        עד שבאה לראשון ממש יום אחרי נסיעתך. חליפת קיץ נהדרה תעשי לך, ידע ד"ר
        סלימן בנבואתו מה שנחוץ לך כעת. גם המשי ישמש לך טוב מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בעזבכם אתמול את הבית נכנסה העצבות, מעט דמעות עינים ושממון. לפנות ערב
        השתמשתי בצרך לחפש את הפרות ושאפתי אויר נדמה לי ששנים לא ראיתי את הכרם
        ואת השדה, הכל היה חדש בעיני, לא ידעתי כלל שהאזדרכת על יד חלוננו – פורחת
        כבר, לא ידעתי שהגפנים התלבשו סות ירוקה והכל כה נהדר מסביב – איך הייתי
        כלואה שבועים?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אבא קם בהחלטה לעבד</span
      ><sup class="c2"><a href="#ftnt28" id="ftnt_ref28">[28]</a></sup
      ><span class="c1"
        >. מתקנים את העגלה ולא יכלתי לעכבו מהשתתף בכך – אין לי די נשק בשביל ללחם
        נגד העול שהוא עושה מזה לעצמו. הלא כל המחלה באה מעבודה יתרה וזה ימשך הלאה
        הלאה כל הימים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה עם כאב הצד שלך? אם זה נמשך אל תתרשלי ותשאלי בעצת רופא – הגם אתך אני
        צריכה במלחמה בנוגע לבריאות?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >איך באתם הביתה עם רוב חבילותיכם? בודי שמחת שזה לילה ולא רואים את המשא
        שאת טעונה. איך עברה הדרך לאסתר? אל תתני לה לעזב מהר את ירושלים. עד אחרי
        השבת היא צריכה להיות שם. איך מצאת את הבית, השמר צבי על הסדר בלעדיך?
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מברנשטין קבלנו אתמול במתנה את ספרו, ששלח ע"י הדאר. עוד לא קראתי כמובן,
        עבודה קודמת לקריאה ועבודה ישנה אף על פי שאין אורחים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תשובת ד"ר בהם על אדות הבדיקה בטח תביא אסתר. דוד היה הבקר. אדם מוזר!
        אסתר כנראה חטאה, שהיא נסעה – הלא היא צריכה לדאוג רק לו!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אקוה שתכתבו לנו על כל מעשיכם ודרכי חייכם מבלי הסתר דבר</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">וקבלו נשיקותי החמות</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא.</span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >העברה נעמי לביתה? ההתרגש ד&#39;? בודי תשתדלי לבקר אותה, אף על פי שרחוק
        המקום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עשהאל שב עוד אתמול מיפו. אחרי כל הנסיונות נשאר בלי עבודה, וזה כה מכאיב,
        כה קשה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">היש לך אפשרות לראות את שטרן?</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="11.4.1923">
      <span class="c15">כ"ה ניסן תרפג מנחמה ומיכל לאפרת וצבי  </span
      ><span class="c9 c6">[11.4.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ראשון לציון יום רביעי כ"ה ניסן תרפג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">חביבי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אתמול קבלנו את מכתבכם היקר. נעים היה לשמוע כי מצאתם את קנכם בלי הפרעת
        סדרים, אשר דבר כזה יקר המציאות בזמנינו אנו בכל הארצות אשר אחינו חונים.
        בבית באמת נעשה רגוע </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > אחרי רבוי האוכלסין של החג אך זה קצת גם נעים. החיים דורשים שנוים
        וחליפין. אחרי רעש נעימה המנוחה. ואחרי מנוחה דרוש רעש. הכול בעתו מה
        טוב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >מצבי הולך וטוב. המים מתמעטים כמעט עד לאפס. זה יומים שעשיתי כבר טיול עד
        לפנים המושבה, אף כי הרגל עוד חלשה אך אפשר כבר להשתמש בה על הדרך. מה דעתו
        של דר בהם? איזה רפוי לא פרשת בודאי בקרוב תודעי לנו – את כתבת שהיה לך חשק
        לקחת את יונה עמדך והנה צרכה לתת שבח והודיה שלא עשית כדבר הזה – מיום שובה
        קבלה אינפלענזיה</span
      ><sup class="c2"><a href="#ftnt29" id="ftnt_ref29">[29]</a></sup
      ><span class="c1"
        > ועד עכשו החום לא יורד, וקשורה אל המטה. לו באה ירושלימה ושם שכבה כי אז
        שלמת בעד העונג הזה רבית דרבית.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ממרדכי בורנשטין קבלנו מתנה – ספר שחבר "אל שן הסלע" – דרמה בחמש מערכות.
        בודאי שלח גם לך ספר לחוד: כי על השער כתוב למשפחת פוחצ&#39;בסקי ואת הלא
        בין -  המיוחסים ממשפחת הכנהים נמנת!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אפרת יקרתי, אגלה לך סוד מהחדר לאמא שלך קשה מאד הפרידה ממך. בלכתך מהבית
        אספת את כל חפציך ורק באיזה פנה עזבת דבר נקרא ואמא מצאה את החפץ ומשתמשת
        בו לצרכה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום שלום לכולכם ואף לאסתר ולבהמים</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם מיכל.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרתי היקרה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שמחתי לקראת מכתבך, שכתוב בישוב דעת על הכל בסדר, רק את הבדיקה הפכת וכתבת
        "בקידה" טוב שאת יודעת עברית וזוקפים זה ע"ח פליטת קולמוס. אסתר בודי עקר
        אכסניתה אצלך ואני שמחה מאד. הייתי אתמול אצל ברכה הרשפלד היא מרגישה את
        עצמה יותר טוב. היא מרוצה מזה שאסתר מרשה לעצמה את העונג של טיול. אבא
        התגנב לקראת ערב מן הבית, וג"כ עשה בקור לברכה. ע"ד רגלו וע"ד הגעגועים שלי
        הוא כתב לך, מובן שהפרידה היתה קשה לי אחר שהותך 2 שבעות תמימים בבית –
        השקט שהשתרר בבית אינו נעים לי, אבל מה לעשות?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >חולה יונ"לי – גם זה אבא כתב לך – וחסר זה למצבם הנפלא. אתמהה למנוחת נפשה
        של עדה שזה לא רגיל אצלה וחוששת אני שזהו שקט שלפני סער, כי הלא אי אפשר
        להתיחס במנוחת נפש למצב קשה זה. מאירקה פניו רעים, רעים. רק עיניו מאירות
        מתוך פניו החורים. כשהוא פורש את כפיו הקטנות הנפש מתמוגגת, כאלו הוא רוצה
        שיעזרו בצר לו. הוא עושה שנים ואחרי המחלה שעברה עליו זה קשה כפלים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את שואלת אם חושבת אני לכתב לג"ב מגנס או מסרתי חשבון לולפסון: לא הא ולא
        דא. כל ענין הכסף אי נעים לי מאד ולדרש בכל אופן לא ארשה לעצמי. מג"ב ברגר
        אמנם לא קבלתי תשובה על הטלגרמה, אבל אני בטוחה שאת הכסף היא מסרה. שאלי
        אותה אם יש ידיעה מג"ב סולד, איך עברה עליה הדרך?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אם הענין של מרים עם ל. באמת מצא חן בעיניכם אז אתם יכולים להוציאו אל
        הפועל. אני חושבת שמצד הלובמנים עיכוב לא יהיה, כפי שאני ספרתי לך</span
      ><sup class="c2"><a href="#ftnt30" id="ftnt_ref30">[30]</a></sup
      ><span class="c1"
        >. אם יודע לכם איזה דבר מצדו הודיעי לי ואדע להכין יפה את הקרקע. אבל מה
        זה? האם באמת היינו לשדכנים? מקצע מצוין!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את הנעלים של צבי יחד עם החבילה של ד"ר סלימן אשלח עם מר גרוכוב. רציתי
        לסדר את החבילה לדאר והנה בא מכתב והודיעני ע"ד בואו של מר גרוכוב. מובן
        שזה יותר טוב. פנינה השבוע אינה נוסעת והיתה כבר החבילה מוכנה לדאר אלמלי
        ההזדמנות. העוד יש לך כהל למכונה? אם אין – תודיעי לי ואשתדל לקחת בכרמל
        ולשלח לך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">את הרפואה בשביל אבא מד"ר בהם בודאי תביא אסתר אתה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אם אסתר לא נסעה תמסרי לה שלום</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ושלום ונשיקות לך ולצבי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מאת אמא </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="15.4.1923">
      <span class="c15">כ"ט ניסן תרפג  מנחמה ומיכל לאפרת וצבי  </span
      ><span class="c15 c6">[15.4.1923]</span
      ><span class="c1"> ראשון לציון יום א&#39; כ"ט ניסן תרפג      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">חביבי היקרים</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הסיבותי לכם מעט צער בספרי לכם את מצבה של יונה. כעת אבוא לבשר לכם, כי
        יונה כבר מתהלכת בחצר ומלקטת פרחים אחרי איזה ימים ששכבה בחום של ארבעים.
        אמא חפצה להשתמש בהזדמנות זו שאני לא אצא עוד אל הכרמים ומכניסה אותי בעול
        של כתיבת מכתבים – דבר שהיתי תמיד חפשי מזה. מה לעשות עם אמא? האם את חושבת
        כי רק זה: יש עוד דברים הרבה יותר גרועים, למשל: כל שעתים הן ביום והן
        בלילה לשתות חלב. בלילה לא נותנת לי לקרוא ובבקר אוסרת עלי לקום בזמני,
        אוחזת את רסן הממשלה בידה ואני מסכין, מחויב להכנע. אני מתעתד לעשות איזה
        התקוממות נגדה כבר כשל כח הסבל. מה עצתך אפרת?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מצב בריאותי הולך וטוב מהפצע כמעט לא יוצא כלום, יש לקוות כי הרגל תרפא
        לגמרי. אני מרגיש בה רק חולשה וזה אין פלא – אחרי סבל כזה! הגעגועים לעבודה
        מתגברים בי מיום ליום קשה לי להתאפק אני נכנסתי ראשי ורובי בתנ"ך לדלות
        פנינים מים שפתינו העתיקה ובזה מוצא נוחם קצת. את החזיון של ברנשיין קראנו
        בשים לב חפצנו למצוא את הטיפוסים מהחיים אבל לשוא – קשה למצוא אותם בסביבה,
        אך בכלל עושה רושם טוב כתוב ב__ כשרון אף כי קצת מוזר.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">את </span><span class="c16 c4">החולמים ולוחמים</span
      ><span class="c1"
        > גמרתי מדף אל דף מה תאמרי על סבלנותי? אך איני מתחרט כלל על הקבלנות
        הזאת. אל תבישי את "הרשימות" מפרק לפרק תעיני ותמצאי שם דברים טובים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בירושלים בודאי שב החורף לקדמתו אם אצלינו כה קריר וגשם לפרקים, כמו בתחלת
        אדר ומה על ההרים? מזלה של אסתר גרם שלא תוכל לסיר את ירושלים כדבאי.</span
      >
    </p>
    <p class="c3" dir="rtl"><span class="c1">...</span></p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום שלום לכלכם שלכם אביכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מיכל</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרתי היקרה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >תסלחי שרק היום אני באה לענות על 2 מכתבים – מכ"ז וכו ניסן. אני באמת
        הייתי שרויה בצער גדול עם יונה ולא יכלתי לנסות בכתיבה. גם מצבו של עשהאל
        מדכא אותי מאד. לוא הכרמים נתנו איזו אפשרות קיום ל2 משפחות הייתי אולי
        משפיעה על אבא למסר לעשהאל את כל המשק ל2-3 שנים. לנו, הזקנים, הלא הרב
        אינו דרוש. מעט מצער מספיק לנו. אבל עשהאל הלא משפחה גדולה היא, דרוש לה
        הרבה ובעיקר שלחיות חיי אכר עוד לא למדו. והשאלה היא קשה וחמורה. אמרתי לך
        באחד המכתבים של שבוע העבר שמנוחת עדה מנבאה לי סער. כן, שלשום הם גלו לי </span
      ><span class="c4 c16">שחושבים על אדות נסיעה</span
      ><span class="c4">.  חפצים </span><span class="c16 c4">לאוסטרליה</span
      ><span class="c4"
        >. מחשבת הנסיעה היא עוגן הצלה אצלם ואני? נו אני – הלא את יודעת את
        השקפותי בנידון זה – חורקת שנים ושותקת. בשביל לעצר בהם הלא צריכים ליצור
        להם בסיס חיים, וממ___</span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > הכחות הדרושים לכך? אתמול נולדה איזו מחשבה ע"ד מצרים. נעמן הורויץ עזב
        את הויסמנים ושב ממצרים הביתה. היום נסע עשהאל לגדרה להודע עד כמה נכון
        הדבר שהויסמנים היו מקבלים אדם כעשהאל. אם המשקים שלהם במצב טוב ואם נחוץ
        להם אדם יודע חקלאות, אז אולי מקום לא רע אצלם ...
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היום בודי תשוב אסתר מירושלים ואקבל פ"ש מכם. את החבילה של ד"ר סלימון,
        יחד עם נעלי צבי שלחתי ע"י הג"ב גרוכוב. הוא לא בא לשבת והיא מהרה לעזב את
        ראשון, כי קשה מאד היה לה לבדה עם 3 פעוטים. בטח היום היא תגיע הביתה ומחר,
        או עוד הערב תקבלו ממנה את החבילה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שמחה אני שהכאב בצד עבר אצלך. בראשון אולי מזיק לך, שאת לא יוצאת כלל מן
        הבית, או אולי החלב המרובה יוצר גזים. מי יתן ולא תדעי מכאוב ילדתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">הדרמה של בנשטין</span
      ><sup class="c2"><a href="#ftnt31" id="ftnt_ref31">[31]</a></sup
      ><span class="c4"
        > כתובה בכשרון, באי אלו מקומות אמנם חקה את פשבישבסוני. מהטפוסים מוכרים
        משפחת לובמן, ה&#39; מרדכי עצמו יתרם – ערב רב. למה הביא פרוצה מפריז אינני
        יודעת. אבל צרף אליה הרבה תכונות של רחל</span
      ><sup class="c2"><a href="#ftnt32" id="ftnt_ref32">[32]</a></sup
      ><span class="c1"
        >  שלו וחבל אם חולל זכרה בזה, הפרובלימה שמצאתי – אינני יודעת אם זוהי גם
        של ברנשטין – הוא, שגבר הפכפך מביא עד לידי שגעון כל אשה שבאה במגע אתו.
        כתוב כמדמני מחיי הארץ, אבל אין שם כלום מחיי הארץ, חוץ ממחזה הראשון –
        הכביסה עם התימניות ושוב אוריה עם המעדר מהעבודה. אבל שם מימי מאד, רק
        הילדה הפנימן צפורה מפתיעה, לא יכולה להיות ילדה בת 13 בשום אופן!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בדבר מתנה לד"ר סלימן – איזו עצה אני יכולה לתת לכם? כשתכנסו לבצלאל תמצאו
        דבר יפה. בודי תדעי להצטדק למה לא הודעת בדבר החתונה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לך ולצבי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו נשיקות רבות</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מאת אמא.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="20.4.1923">
      <span class="c15">מנחמה ומיכל לאפרת וצבי  </span
      ><span class="c15 c6 italic">[על פי תוכן המכתב: ערב שבת, </span
      ><span class="c4 c6 c34 italic">יתכן 20.4.1923]</span
      ><span class="c1"> </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">לבני החביבים. </span><span class="c4 c6 c20"> </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אמא מכינה מטעמים לשבת ופתאום נזכרה בכם שודאי אתם תתגעגעו ביום השבת
        והטילה עלי לעשות מטעמים בשבילכם לשבת אף כי התימנית רחל מנבאה לנו כי
        תבואו היום לשבות אתנו. אך מי יאמין לה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אסתר באמת באה בהתפעלות רבה מכם היא מסרה לאמא בלי מלים: רק בנשיקות.
        בספרך על דבר בקורך בביה"ח מאדלאידא שעד עכשיו עוד סובלת מהנתוח נעשה ברי
        לי, למה לא סבלתי אנוכי – אסור להיות שמן! הטיפול ברגל כבר פסקו אין כלל
        סמן למים אני מחכה שתשלחי את התרבית מהד"ר בהם אולי אחרי הזריקות יפסק הכאב
        ברגל. לפי שעה מורגש הכאב בהליכה מרובה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >קבלנו מכתב מהד"ר שפירא בטון שמח מאד והוא יותר טוב ממך. אני שאלתי בעצתך
        להתפרץ מסנור אמא? ומה התשובה? כאמה כן בתה. אך הד"ר הצלני הוא נתן לי
        רשיון לחופש גמור הן באכילה והן בעבודה סוף סוף הגברים גברים. גם בענין
        עשהאל שהתרגשתו לא פעל עלי כעת מאומה, דבר כבר ישן נושן מיום שהתקשר...
        אמרתי נואש וחכיתי בכל יום לפצצה. ... לא נכון הדבר שאי אפשר לחיות, צריך
        רק החפץ והמרץ אך אפשר ואפשר, הנה לך משל טוב: בשנים האחרונות שלמנו עבור
        תבן לבד 25 לירא לשנה השנה פטמתי את הפרות בקש של אפונה ונתנו יותר חלב
        מבכל שנה. הודות לדוד העגלון שהוא בעל מרץ יש לי כעת עשב בקיה שיספיק לכל
        השנה. אפונה </span
      ><span class="c4 c6">[?]</span
      ><span class="c4"
        > גורן שיש בו ברכה. ונוסף לזה שדה של חטי תירס שזה יספיק אוכל לכל השנה
        לפרתינו וגם אפשר לפרד. זה מראה לך, שכן יכולים לחיות. אין מקום ליאוש בארץ
        ישראל. אלא קודם כל צריך להיות איש. והאיש נאבד אז... ואת חפצה שיסחוב גם
        אותנו לטמיון? אל תתי! </span
      ><span class="c4 c6">[?]</span><span class="c1"> לכן אנחנו גברים.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום ילדי ערב שבת ועלי לגמר שאספיק לפוסטה</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אביכם מיכל</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ערב שבת ואפיתי לחם ואבא מלא הפעם את תפקידי. אבל מה שהוא מהלל את הגברים
        מובן שלא מצא חן בעיני. אם גברים בכלל כל כך טובים, מדוע אינו מאמין בכשרון
        עשהאל לנהל את המשק?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את שואלת ע"ד החג הבא והנה תשובתי: מובן שהצפרים יעופו אל קן אמם. אמנם
        קשה הקש שהיא פורשת להם למצע, גם רוחות ביתה אינם ממתיקים להם את שבתם
        אצלה, אבל קל משק כנפיהם ובין רגע הם מרימים אותן וממעוף אחד שבים אל פינתם
        השקטה שמול הר הצופים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ככה, יקירי, תעשו לנשרים הזקנים שכנפיהם קוצצו – בכל חג ומועד ואתם במעוף
        אצלם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תודה עבור רצונך הטוב לעזור לבהמים בצר להם – וגם בעד בקור החולים – בדרכי
        את הולכת ואני מאושרה בזה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">בבקשה למסר מכתב הרצוף כאן – לסלימן.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">נשיקותי לכם</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא</span></p>
    <p class="c3" dir="rtl">
      <span class="c1">יש בלבי הרגש שאתם תבאו היום – האמנם?</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="29.4.1923">
      <span class="c4 c6 c34"
        >י"ג אייר תרפ"ג   מנחמה לאפרת וצבי  [29.4.1923]</span
      ><span class="c1"> ראשלץ יום א&#39; י"ג אייר תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!   </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >הקריאה הטלגרפית לבא ירושלימה והמכתב המבאר את הסבה – לא היו בשבילי אלא
        פתרון לאותה אי מנוחת הנפש שהרגשתי בשבוע העבר. לוא הכל היה כשורה בבית,
        הייתי אצלכם בטח כבר ביום השני העבר, אבל הזקנה</span
      ><sup class="c2"><a href="#ftnt33" id="ftnt_ref33">[33]</a></sup
      ><span class="c4"
        > דורשת עדיין טפולי וגם על אבא צריך להשגיח בשבע עינים לבל יחטא לבריאותו.
        ד"ר שפירא</span
      ><sup class="c2"><a href="#ftnt34" id="ftnt_ref34">[34]</a></sup
      ><span class="c1"
        >  התיר עבודה, אם כן הותרה הרצועה וכל עבודות פרך הוא נוטל עליו, לבסוף
        סובל מכאבים נוראים ברגל, מתיאש ומתרגש. – איך לעזוב אותם? רציתי את הזקנה
        כבר לקחת אתי ירושלימה לאיזה ימים, אבל הרופא לא הרשה. אסורה אני ילדתי,
        ואין חובש מתיר את עצמו מבית האסורים! –</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אקוה שהאנגינה כבר עברה, ואם נשארה חולשה וקשה לך לטפל בעבודת הבית, אנא
        בואי אלינו לחזוק בריאותך! מצדי עוד אראה: אם יוטב המצב בבית במשך יומים,
        אז עוד אבֹא ל3-2 ימים ולשבת, אם תרצי, נסע יחד לראשון. נראה איך יפל
        הדבר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">בדבר אספת </span
      ><span class="c16 c4">המועצה האנטרנציונלית</span
      ><sup class="c2"><a href="#ftnt35" id="ftnt_ref35">[35]</a></sup
      ><span class="c4"
        > אני אכתב לג"ב ברגר. לשתי קריאות – שלפני אשתקד ואשתקד ענינו בשלילה, -
        איננו רואות אפשרות לעבוד עבודה משֻתפת עם ג"ב </span
      ><span class="c16 c4">ניוטון</span
      ><span class="c1"
        > הידועה באנטישמיותה, או אחרות שג"כ אולי יש להן משמץ שנאה לעמנו. אבל אם
        הג"ב ברג&#39;ר מוצאת שזה נחוץ מאד, - אז אשתדל להשפיע על חברות אגדתנו
        ולכבש אותן.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">לבית בן-זאב מסרתי ע"ד הכנן </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > לאדלידה. בטח ישתדלו למלאות את הבקשה. עשהאל נסע היום ליפו להתחיל בעבודה
        עם שכר פעוט מאד – מה לעשות, כשאין ברירה? כמדמני כתבתי לך שבהרב הבטיח לו
        דבר מה אצל רוטנברג. אם רק יתחילו לעבד בחיפה, הדבר עוד רחוק, אבל התקוה
        מקילה על קושי המצב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">הילדים הבריאו ופני עדה קצת הוטבו.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לשניכם ונשיקות קבלו מאת</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אמכם אוהבתכם.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="14.5.1923">
      <span class="c15 italic"
        >כ"ח אייר תרפג  מנחמה ומיכל לאפרת וצבי  [14.5.1923]</span
      ><span class="c1"> יום ג&#39; כ"ח אייר תרפג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >שלוחה לכם כאן פתקה ליין. זהו באמת רעיון יפה לקבל ישר בירושלים, לא עלה
        בדעתי אף פעם שאפשר לסדר את זה, אכן ישנם חכמים שיודעים למצא דרך נוחה
        וטובה להשגת צרכיהם והנה במקרה זה הועילו גם לנו. היין שדרשת עולה 36
        ג"מ</span
      ><sup class="c2"><a href="#ftnt36" id="ftnt_ref36">[36]</a></sup
      ><span class="c1"
        >. נשארו אצלי 44, שתקבלו אצלי או במזמנים או במשלחת יין השניה – שתו ורוו
        דודים!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כסף אינם שולחים לסניף ירושלים ואם תקרה איזו הזדמנות אשתדל לשלח לך. חבל
        שלא לקחת אתך – הלא רק גרוש אחד עולה לנו הליטר מפני שזהו המעשר של התעשיה
        שנדון לזרם החוצה – ובחנות 3 ג&#39; כל אוקיה – ויוצא הליטר פי שמונה
        ממש.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תוצאת הנסיעה של עשהאל לגדרה היא זו, שנודע לו מפי הורויץ, שגם במצרים
        המצב רע מאד. הוא עצמו בא הנה לחפש לו משרה האחוזות שחכרו הויסמנים הביאו
        הפסד נורא והם יצאו משם. עוד צעיר גדרתי היה אצלם וגם הוא הולך כעת בטל.
        נתחזקה אצל עשהאל המחשבה לנסע עם כל המשפחה לאוסטרליה. הוא כבר פנה שמה
        בבקשה לשלח לו כשתן כניסה. ועד שיבא הרשיון הוא ישתדל למכר את הכרם ואולי
        גם הרהיטים, אינני יודעת ולי קשה לדבר מזה, הלא את מבינה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אני כתבתי לך כמדמני שנסיתי לדבר על לב אבא למסר לו את הנהלת הכרמים, ולא
        הצלחתי. אבא עוד מאמין שיש בכחו לא לתת למשק לההרס, וחושב שאחרים מסגלים
        יותר להרוס מאשר לבנות. אבל לוא ידעתי שעשהאל יוכל להתקיים על המשק לפי
        המצב הנוכחי, הייתי עורכת חזית למלחמה, אף על פי שזה לא בצדק לשלל מאבא את
        יסוד שעשועיו, הוא עוד אוהב את יצירי כפיו ורוצה לראותם בהשלמתם. נותנים
        אפשרות קיום או לא מזה עצם האופי לא משתנה. אך אין מה לדבר – גם משפחות
        פחות מפונקות אינן מתקיימות כעת על הכנסת כרמים בלבד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אצל הזקנה השעול עוד לא עבר, עוד נוסף לזה הרבה צ&#39;ד </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > ושכבה 3 ימים. הרופא היה נתן משחה מתאימה והוקל לה מעט. בראשון מתהלכת
        אנפלואנציה. בכל בית כמעט תפגש חולה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אנה הלכה היום בפעם הראשונה לבית הס&#39;. אבל ג"כ עוד משתעלת. עשהאל רצה
        לנסע ליפו בדבר בנין החנות. היה אתמול אחד הפועלים משם והוא לשֵם כך נסע.
        אשת אבי </span
      ><span class="c4 c6">[?]</span><span class="c1"> ילדה אתמול בן.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אסתר</span
      ><sup class="c2"><a href="#ftnt37" id="ftnt_ref37">[37]</a></sup
      ><span class="c4"> נסעה היום. דוד</span
      ><sup class="c2"><a href="#ftnt38" id="ftnt_ref38">[38]</a></sup
      ><span class="c1"
        > בהפרדו אתמול הביע דברי יאוש וקנטור כלפי מצבו. מסכנה אסתר! מי יודע איך
        הוא ימרר לה כעת את חייה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לך צבי יקירי תודה רבה עבור המכתב הקצר אודות ד"ר קצנילנבויגען. אני תיכף
        שלחתי לבית רטנר ושם היתה השמחה רבה. אוכל גם אני לבשר לך – שבמכתב אף
        שגיאה קלה לא היתה. אני מברכת צעד זה בשמחה גלויה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >וחצרנו, יקירי, מלאה גרנות. בצד אחד הבקיה ובצד השני האפונה. הפעם אנו
        אכרים באמת. חסרה רק חטה ושעורה. אבל אבא, אבא עובד בגרנות יותר מדי, וכל
        מלחמה וקול צעקות והתמרמרות לא מועיל, הוא מעמיד ומסדר ומטפל בלי הרף
        בגרנות היפים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לקבל את הצעתכם לנוח אצלכם הוא לא רוצה – דגל העבודה לא יתנופף כדבעי. בכל
        אופן אנו מודים לכם עבור לבכם הטוב וכונתכם הרצויה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אסתר הללה את חייכם; היא מצאה בהם פנת נחם בכל הצער שמסביב. מי יתן ונתנחם
        בכם תמיד!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו דרישות שלום מכל הבית</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ונשיקות רבות מאת אמא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >נ.ב. העיקר באמת שכחתי: - תואילי אפרתי לבקש את ד"ר בהם, שיכין בשביל אבא
        את הזרק</span
      ><span class="c4 c6">[ה]</span
      ><span class="c4"> ותמצאי דרך איך לשלח הנה. הג"ב </span
      ><span class="c16 c4">רבינוביץ</span
      ><span class="c1"> לעשות את הזריקות.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">תשלמי לד"ר בהם עבור הזרק ותכתבי לי כמה. </span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הפוסטה לא מרשה לי לכתוב לכם אני מסתפק הפעם במה שהלשינה עלי אמא. שלום
        שלום אביכם מיכל.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="may1923">
      <span class="c15"
        >ללא תאריך  מנחמה לאפרת וצבי [על פי תוכן המכתב: מאי 1923]</span
      ><span class="c1"
        > ילדי היקרים אפרת וצבי!   [אין תאריך, לפי התוכן – מאי 1923]</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >האם תסלחו לאמא, שהיתה כה רעה הפעם? מיום עזבכם אותנו לא כתבתי אף מלה
        ואיני יודעת כיצד היה הדבר הזה – טרחה, עמל, דאגה או עצלות? באמת לא קרה לי
        עד היום דבר כזה – סלחו סלחו!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הדודה כנראה לא נסעה אליכם, כי באותו יום ששבה מראשון באה מבירות האשה
        האומללה, שעל אדות בעלה באה הדודה ליפו להשתדל בדבר הצלתו והדודה כפי
        ששמעתי צריכה בלי כל שהות לשוב הביתה. אימתי היא נוסעת עוד לא ידוע לי,
        אשתדל עוד לראותה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הרגל של אבא הוטבה, אבל העצבים נרגזים מאד, בודי ממקור אחד גם סבל זה
        נובע. לנסע בירותה אינו רוצה בשום אופן, לא מענין אותו שם ועל כסף הוא חס.
        היה טוב מאד שינוח, אבל אי אפשר הלא לארזו בארגז ולשלחו; אם הוחלט אצלו לא
        לנסע אז אין עצה ואין תבונה נגד זה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >יש לי מחשבה שיסע לאיזה שבעות אליך, אבל איני יודעת עוד איך יתקבל הדבר
        הזה על לבו. יכל גם לזה להתנגד, כי רוצה הוא לעבד ואצלך מה יעשה? יש לו
        יתרון מרץ לעבודה וגם באפס כחות הוא עובד מבקר השכם עד שעה מאחרת
        בערב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">ההיית אצל נעמי</span
      ><sup class="c2"><a href="#ftnt39" id="ftnt_ref39">[39]</a></sup
      ><span class="c4"
        >, ההסתדרה כבר ומה שלום הדודה חנקה? תבקרי נא לעתים תכופות את נעמי. ראשית
        הקרובה היחידה שיש לך בירושלים ושנית – הלא היא חכמה ונעים לבלות אתה!
        הנסיעה לאנה בית </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"> בודי אינה עולה ביוקר.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מבן דודנו הרב אברהם יצחק פרידמן קבלתי שני מכתבים. הוא צריך לבא הנה, יש
        לו ענין חשוב מאד. אני מבקשת את צבי להודע באיזה אופן אפשר לעשות לו רשיון?
        הנותנים רשיונות לסוחרי אתרוגים? צבי על נקלה ידע זאת ממכירו שיש לו במשרד
        העליה. בבקשה למהר ולהודיע לי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ומה שלומך ילדתי הטובה? ההוטבה בריאותך? כמה אני משתוקקת לראותך שוב בריאה
        ויפה כמו שהית פעם. התקדמות, התקדמות ילדה! לעלות בחמר וברוח! עליה תמיד
        ולא ההפך, כי אם לא עכשו – אימתי?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ההיית על ההרצאה של ד"ר מגנס? היתה אדותה בעתון רק ידיעה קצרה ותו
        לא</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כשראיתי בארון את הדברים ששכחת הצטערתי מאד – מאין פזורה הנפש הזו? הלא
        תמיד הית דיקנית. לא שלחתי עוד את החבילה משום שחשבתי אולי צפרה תסע לשבת.
        אבל החבילה כבר מוכנה לדאר, ומחר אשלח אותה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אקוה שבאספה האנטרנציונלית היית ותכתבי לי פרטים ממנה. מרים וחברתה, העלמה
        הלונדונית היו אתמול אצלי ובקשוני למסר לך שלום רב מהן. מרים מתכוננת לתפר
        לה שמלות-קיץ ולנסע ירושלימה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום רב ונשיקות לכם מאבא ואמא.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >החום נורא אצלנו. ממש תחת קו-המשוה. הגם אצלכם מורגש החום הלוהט?</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="31.5.1923">
      <span class="c15">ט"ז סיון תרפ"ג.  מנחמה לאפרת וצבי  [31.5.1923]</span
      ><span class="c1"> ראשון לציון ט"ז סיון תרפ"ג.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי הטובים,</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הלכתי ליפו ביום ג&#39; לישיבת האקזקוטיבה של "הסתדרות עולמית לנשים
        ציוניות" ובשובי אתמול בערב, מצאתי את מכתבכם היפה. ככה בישוב דעת כשאת
        כותבת נעים לי מאד ילדתי. ביפו בין שאר הענינים, חוץ מאספה, נתתי את לבי
        הפעם גם לבנינכם. טרם שראיתי את ניומן וטרם ששמעתי מפיו ע"ד זה, שדפוס
        קורפורטיגי רוצה לשכר את כל החנויות יחד, נכנסתי לפיגין והוא הבטיח לי
        להשתדל ולחפש חוכר לחנויות למרות זה שהרבה חנויות גם ברחוב הרצל עומדות
        ריקות באין דורש. נכנסתי גם למשרד של לויצקי, רשמו אבל אמרו אותו הדבר: -
        הרבה ריקות והרבה עוד עומדות להתרוקן ומה שמחתי בבואי אל "תרבות" ובהודע לי
        מפי ניומן ע"ד הדפוס שרוצה לחכר כלם יחד. מובן שבמצב כזה אין לעמד על
        המחיר. המקום של בנינכם עשה עלי רושם טוב מאד. הפנה גבוהה ונחמדה. בית דפוס
        ישחית אולי משהו מנקיון הבנין, אבל אין להתחשב גם עם זה, ובהיותך, צבי,
        ביפו הפעם אתה צריך לגמר את הענין בשעה טובה. את המשקפים שלך, לקחה הדודה
        על מנת לקחת אתה ירושלימה וכיון שנסעה מסרה אותם לתרבות. הם עוד מונחים
        שמה, לא היתה להם כנראה הזדמנות לשלח והם מחכים למסר לידיך ממש.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בדבר הרשיון גם אני פניתי לועד העיר וענו את אותו הדבר – שיפנה לקונסול
        הבריטי אבל דא עקא, שאין בעיר מגורו, חרקוב, קונסוליה בריטית, אלא במוסקוה
        וזה כבר קשור עם קושי הוצאות. בכל אופן פה אינם נותנים רשיונות
        לסוחרים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את מבקשת שאשלח את אבא, וזה לא תלוי בי כידוע לך, אם תהי ביום ראשון פה
        תנסי לדבר אתו. האמנם גם את תסעי בשבת ליפו? כמה קשה לנו, הזקנים, להסכים
        לחפצו זה! את אדיקותי את יודעת ובכל זאת לפרץ גדר אני מתנגדת. אנחנו צריכים
        לסדר את עניננו ככה ששבת תהיה יום של מנוחה אמיתית. זה צריך לשלימות גוף
        ונפש האומה. אל נא נעשה את שבתנו חול, שבת – אחד הדברים היפים שמפריד אותנו
        לטוב מיתר האומות. מדוע אינני מפריעה לעשהאל, לצבי? הם לא ישמעו לי, הם לא
        יבינו לרוחי כמו שאת תרגישי ותביני את זה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תודה לך, ילדתי ע"ד הפרטים אודות האספה האנטרנ". מה שנוגע לכניסה
        ל"הסתדרות נשים העולמית" – אוכל למסור לך, ש"התאחדות נשים עבריות לשווי
        זכיות בא"י" כבר נכנסה לתוך ההסתדרות העולמית, ובקונגרס האחרון דרשו מאתנו
        לשלח ציר, אלא שכסף אין לה. __ נחוץ היה הדבר מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נלי מושנזון שבה השבוע מחו"ל. השתתפה באספתנו שלשום ונתנו דין וחשבון
        מהקונגרס של נשים בוינה. לפי הפרגרמה לא חכינו שהציונות תתפס שם מקום, והנה
        לשמחתנו נמצאו נשים, בנות טובות לעמן, שהטו כמעט את כל מהלך הקונגרס על
        צדנו. מעט מזה בודי קראת ב"הארץ" ועוד יותר מסרה לנו נלי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את יחסיה למושנזון עוד לא הספקתי לחקר – זה מענין מאד. פניה טובים והיא
        מחיכת בלי הרף, זה קשה קצת.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >חסר לי להשתתף עוד בועד אחד, ששפה אין לי שם ורוח שולטת שאיני יכולה לקלט!
        תודה למקרה שלא הכניסוני!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">להדודה חנקה צוו לנסע לקרלסבד</span
      ><sup class="c2"><a href="#ftnt40" id="ftnt_ref40">[40]</a></sup
      ><span class="c1"
        > והם מבקשים חברה לנסיעה. רחביה כתב, שאם דרוש לדודה כסף לנסיעה זו – הוא
        יכל לעזר לה, הוא כבר חסך איזה סכום הגון מעבודתו. חרוץ הבחור!  
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >את החבילה בודי קבלתם מבלי שהתקלקל שם איזה דבר. שמחה אני שאת מרגישה את
        עצמך יותר טוב. שמרי ילדתי על בריאותך – זה דבר כה חשוב, כה חשוב! ערך
        מיוחד אנו נותנים לבריאות כשרואים אדם שנפגעה בריאותו. אדם כזה ראיתי אתמול
        ולא אשכח לא אשכח. בקרתי את אחד-</span
      ><span class="c4">העם</span
      >
      <sup class="c2"><a href="#ftnt41" id="ftnt_ref41">[41]</a></sup
      ><span class="c4"
        > ומה שראיתי הרעיש את לבבי. אינו יכול הגוף הרצוץ לשאת את הראש הגדול! ישב
        לפני כוס קפה, כל לגימה עלתה לו בהתאמצות הנשימה ולפעמים ברעדת הגוף. דברנו
        על המצב וכאב לו כנראה מאד וזו היתה סיבת הרעדה. "עמנו לא רוצה!" כלומר, לא
        רוצה בתחיתו, לא רוצה לאמץ כחות לכך. אשתו, זו שראיתי כה יפה לפני 33 שנה,
        זקנה כעת וחלשה ושומעת בקושי. לא! נשי ארץ ישראל בעדותן  </span
      ><span class="c4 c6">[עבודתן?]</span
      ><span class="c1"
        > הקשה השתמרו הרבה, הרבה יותר טוב ואני חושבת שלוא חי אחד העם כל שנותיו
        בא"י, לא היה מגיע לידי חולשתו הנוראה. ואף על פי כן גם אם הוא נוסע עם
        אשתו לחפש לו תרופה בחו"ל – הלואי וישוב לחיות אתנו! נוסעים אתם גרודובסקי
        ואשתו. ג"כ אינם בריאים לגמרי. זהו מה שאמרתי: שרידי דור המדבר!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אשמח מאד לראותך ביום ראשון אצלנו. מובן שזה יהיה חג לזקנים!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">הייתי אתמול אצל לאה</span
      ><sup class="c2"><a href="#ftnt42" id="ftnt_ref42">[42]</a></sup
      ><span class="c1"
        >. החתונה תהיה בקרוב, אבל בכמה דאגה זה יעלה ללאה! לאה מתאוננת למה לא
        נתנו לה תמונה. אני הבטחתי שאתם תזמינו בשבילה תמונה וסוסקין ימציא לה. אנא
        ילדתי לא לשכח למלאות הבטחתי זו. ללאה אנו חיבים לעשות לפחות את זה אם לא
        דברים יותר גדולים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום לנעמי ולבית ד"ר בהם. תקבלי פה פתקה על 2 בקבוקים מלגה כפי שבקש צבי
        עבורך. קבלו נשיקות רבות מאת אמא.</span
      >
    </p>
    <br />

    <p class="c3" dir="rtl" id="june1923">
      <span class="c15">יום ד    מנחמה לאפרת וצבי    </span
      ><span class="c4 c6 c34">[לפי תוכן המכתב: יוני 1923]</span
      ><span class="c4"> יום ד&#39;  </span
      ><span class="c20 c4 c6">[אין תאריך. לפי תוכן המכתב: יוני 1923]</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדתי היקרה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תסלחי אם דברי יהיו הפעם מעטים. קרובה שעת הדאר ואין זמן לכתב. אלא אני
        רוצה שלא תדאגי לנו לכן החלטתי לערך רק איזה שורות בלבד שלא יבא הצער בלבך
        פן אצלנו איזה דבר שלא כשורה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נסעה אתמול עדה עם מאירקה ליפו. וילסון עבר לביתו השלישיה נשארה אצלי
        ותוכלי לך לתאר את הצעקות שבבית. אוכלים כעת צהרים – המנה הראשונה צעקה
        סתם, השניה בכיה והשלישית התגלגלות על גבי הרצפה, מבלי שיודעים את הסבה
        לכך. טרם שהם אוכלים דבר אחד מונים אלף דברים אחרים וקשה להחליט.  - -
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מכתבך מיום ב&#39; קבלתי. צר לי על נסיעת משפחת רפפורט – היא היתה אצלי
        אתמול - הלא את יודעת כמה אני סובלת עם נסיעת מישהו מן הארץ. היא התרגשה
        אתמול הרבה. לשתינו היה קשה להתאפק מדמעות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היה לי יום של אורחים אתמול. התחיל משבע בבקר, ונגמר באחת עשרה בערב.
        מתחילה תנופת הקיץ וצריכים לאסף כחות וזמן.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את נזכרת בשזיפים שלנו. לא נהנינו מהם כלום: גנבו אותם, המותר אבא מכר
        באיזה שלנגים על הכרם שהקונה יקטף בעצמו, כבר לא היתה סבלנות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מרים לובמן היתה אתמול, בודי תסע בעוד שבוע.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לצבי ונשיקות ממני ומאבא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ממני אמך.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">אסתר שרוף מבקשת מאד לדרש בשלומכם.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="4.6.1923">
      <span class="c15">כ" סיון תרפג.  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6">[4.6.1923]</span
      ><span class="c1"> ראשון לציון כ" סיון תרפג.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מכתבכם מיום ו&#39; קבלתי. צר לי מאד שהפרעתי לכם את התענוג של טיול לשבת.
        מה לא עושה בת נאמנה בשביל אם? ויהיה נא זה הקרבן! הרבה ותורים עבורי איני
        דורשת זה את יודעת ילדתי ומלאה תודה אני לך ששמעת בקולי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >קבלתי ממך אתמול פ"ש ע"י בן חי. הוא אכל אצלנו צהרים ופשוט התענגתי לראות
        את בריאותו. כעת העבודה פוסקת בבונה-בית מחסר מים, - מה יעשו כל 300
        הפועלים שמצאו שם עבודה? היתוספו על כל אלה האלפים מחוסרי העבודה, שתקותם
        למצא את לחמם ע"י עבודת גוף קשה – עוד מהם והלאה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >שמחה אני שצבי יצא כעת מן המיצר בדבר חובותיו. בימי מצוקה אלה זה אושר
        גדול. ההצעה בנוגע לתשלום חובו של עשהאל הפליאה אותי במקצת. בבקשה לא
        להזכיר דבר כזה. החפץ הזה היחידי רב הערך שיש להם בסדור ביתם, והוא יקר
        מאד</span
      ><sup class="c2"><a href="#ftnt43" id="ftnt_ref43">[43]</a></sup
      ><span class="c4"
        >. כספכם לא צריך לה___. לפי דעתי צבי צריך לדרש ממנו חשבון מפורט, שיכיל
        גם שכר טרחה בשבילו כמה שהוא </span
      ><span class="c16 c4">בעצמו יקציב לו</span
      ><span class="c1"
        >. את הסכום שיגיע לכם ממנו אחרי זה אשתדל לסדר ואז הכל יבא על מקומו
        בשלום. ואת החשבון בבקשה לדרש ממנו בתקפה. צריך לחנכו שיהיה אחראי בשביל
        פעולותיו ולתת דין וחשבון עליהם. נא ונא תיכף לערך לו מכתב בדרישה זו. אין
        פחד שזה יצער אותו. את עדה אני לא רוצה לצער, לבי נשבר לראותה איך בריאותה
        דועכת מיום ליום יותר. היא כה עצובה, חורת, רצוצה! חוץ מצער המצב, כנראה
        מציקה לה איזו טינא בלב שיש לה על מישהו. את המפתח ללבה לא מצאתי, אבל אני
        כואבת את מכאוביה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שמחה אני למצב בריאותך, כי הוטב. מי יתן ותמיד תבשרי לי אך טוב, אך טוב.
        גם לאבא כנראה הוטב מעט וכל רואהו אומר שמראהו יפה. רציתי מאד שיצלם את
        הרגל. בודי אסדר לו נסיעה אליך עוד לפני הבציר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >צבי יקירי! כבר יש לי ידיעה, שהית שבת בתל-אביב. באשמתי סבלת ונסעת בלי
        אפרת – תסלח לי! התחדש בחליפה הבהירה, שאפרת מצאה בך יופי וחן הודות לה. גם
        לגברים יש ערך בהלבשה! בין בעלי חיים יש משפחות שהזכר מתיפה ומתגנדר יותר
        מהנקבה. בשביל לקנות אהבה מחיבים לחפש דרכים שהם לפעמים נסתרים
        מאנוש.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם נחוץ בדבר החנויות לדבר עם "כרמל", לא קשה לי להכנס אליהם. תכתב נא לי
        מה שכר כל חנות, כמה הן ומה גדלן של כל אחת? פה המשרד הראשי ואני יכולה
        להכנס, כשאקבל ממך את האנפורמציה הדרושה. אל תכתב להם, בע"פ לדבר זה יותר
        טוב. אגש ישר לסמיליצנסקי ואשמע דברים ברורים מפיו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו נשיקות חמות ממני ומאבא</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אמכם.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">הראית, צבי, את הדודה מרים</span
      ><sup class="c2"><a href="#ftnt44" id="ftnt_ref44">[44]</a></sup
      ><span class="c1"> – אימתי היא נוסעת הביתה?</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="13.6.1923">
      <span class="c15">כ"ט סיון תרפ"ג  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[13.6.1923]</span
      ><span class="c1"> יום ד&#39; כ"ט סיון תרפ"ג</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרתי היקרה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עד רגע זה הייתי שרויה בצער על חסר מכתב ממך. היתה כבר מוכנה נוסחה
        לטלגרמה בשאלה – מדוע אין מכתבים? והנה מביאים לי את מכתבך הנעים מיום
        אתמול. לא פללתי שעד יום שלישי לא תכתבי אף מלה. אני חכיתי לשמוע ע"ד
        נסיעתך ליפו ובינתים ג"כ לא כתבתי כלום עד היום ואת תדאגי לנו חנם. אין לך
        מושג יקרתי, מה שאני סובלת אם מתעכב מכתב, אמרתי היום לאבא כמה קשים עלי
        יסורי הצפיה ועתה כשהתקבל המכתב הוא לועג לי – "נשים, נשים!"
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה שהתאכסנת אצל הדודה ועשית את הבקורים אצל מכירינו שמחתי מאד. אבל מה
        שלא סבת ביום ראשון לרגע לראות את הזקנים גרם לי צער. הנה המלים המעטות
        שנרשמו על ענין זה ביומני: "... וילדתי היתה בקרבת מקום ולא באה לבקר את
        הזקנה. האכעס? האוכל לכעס עליה?... היא צריכה לבנות את עולמה החדש ואני
        בצד, בצד." כן, אני לא כועסת, את לא רצית לגרום לי כאב ואני שמחה שטיולך
        עלה יפה לשניכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >הרחיצות בים לוא סדרת היה טוב מאד בשבילך, מדוע לא תקחי פנסיון אצל הדודה
        ותתרחצי חדש ימים מעכשו עד הבציר? אחרי חדש תבאו לאכל ענבים. השתמשי בחפש
        של זוג צעיר שעדיין הם לא יותר משנים... צבי יבא אליך לכל שבת ובינתים ג"כ
        יתפס רחיצות אחדות. מה שהוא אוהב לפי דבריך. חשבי על זה וסדרי לך את התענוג
        הקטן הזה. תזקפי אותו על חשבון טיול שרצית לעשות בל"ג בעומר או בחשבון טיול
        חתונה. הים הוא נחמד באמת. כשהייתי לפני שבועים ביפו ונגשתי לראות את עשהאל
        לא יכלתי באותו בקר להפרד מן הים. יכולים להעשות משורר כשמתבודדים על החוף.
        לדאבון לא היה לי זמן ולא יכלתי להרשות לעצמי להשאר שם שעה קלה. אקוה שאצל
        לאה הית ודאגת לתמונה בשבילה. תסלחי שאני מזכירה לך שנית על אדות זה. טוב
        שסרת לגלוסקין ג"ב גלוסקין קשורה אלינו ואליך ביחוד. תכתבי </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > לך שאחות ג"ב בצלאל יפה גרה בתל אביב? אולי פגשת בה? אמנם אי אפשר
        להכירה, מצבם החומרי רע מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כתבי לי יקרה – כמה כסף לפי החשבון מגיע לכם מעשהאל? אני רוצה שלא ישאר
        לכם חוב. תודיעו לי את הסכום ואשתדל משך הזמן לסדר שיסולק החוב.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מדוע צבי לא כתב לנו כלום זה זמן רב? האם כה טרוד הוא, או ישנה סבה אחרת?
        איך חושבת סוביל להסתדר לבדה? האם יש לה אפשרות לנהל את המשק בלי כל עזר?
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >על הוילסונים גם אני מצטערת ומצבם נוגע גם ישר אלי, כי ברע להם, רע לעדה.
        הוא מאנשים האלה שפורשים כנפים רחבות, רחבות ומה גדולה הטרגדיה כשאין
        הכנפים מציחות </span
      ><span class="c4 c6">[מצליחות?]</span
      ><span class="c1"
        > להתרומם... ולא רק המצב בעוכריו אלא גם הילדים אכלו אותו ויש שאני חושבת:
        מה יהיה אם חו"ש תדפק שעתו בלא עת והוא יעזב את המשפחה הזאת שרק מותרות היא
        יודעת ואינה מוכנה לחיים קשים? - -</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >במכתבי ביום ה&#39; העבר התאוננתי על צעקות הילדים וד&#39; העניש אותי –
        ביום ראשון חלו שנים, יונה ואלישיב. יונה באפן קל מאד, אבל לאלישיב היו 2
        ימים חום חזק שהגיע ליותר מארבעים והרופא חשב שתבא אדמת. כשביום ג&#39;
        לפנות בקר פתאם אחרי ליל קשה נפל החום, לא היה גבול לאשרי. היום עדה שבה
        מיפו ומצאה אותם כבר בריאים ושמחים. הפגישה עם הילדים נגעה עד הלב. ספי
        יחכם מדי יום ביום יותר. אין לתאר את רוב חכמותיו, זה ראש של ענק על גבי
        גוף זעיר, מאירקה הביט הביט עלי בשובו, הכירני ונדבק בי בחזקה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >הג"ב ברג&#39;ר השאירה במקומה במשרד את הג"ב הופין. איזה תפקיד תמלא אצלה
        נלי איני יודעת. אפשר רק בבית לשמר על שלומות </span
      ><span class="c4 c6">[?]</span><span class="c1"> המשק ועל אברהם.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >השלישיה שבביתכם באמת מענינת כעת קבוץ גלויות. מדוע את אומרת שאין לכם
        שפה? אם זה רק בנוגע אליך, אז כמדמני שאת שולטת גם בגרמנית, גם בצרפתית, את
        רק לא מאמינה הרבה בעצמך, זה חסרון גדול בחיים. מה שנוגע לנקודה הלאומית
        ודי עצוב מאד שהננו עם מפוזר ומפורד בין הגוים. אין קרקע ואין שפה מאחדת את
        כל חלקי העם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בשבת היו אצלי 2 בנות בן-אריה, נעמי וחוה. נעמי קבלה משרה כבודה מאד בדרום
        אפריקה. תפקידה יהיה לפתח רשת של גני ילדים עברים בטרנסול ושעורי עברית
        לסטודנטים. להוצאות הדרך שלחו לה שבעים פונט ורשיון לקבל בלונדון כמה שהיא
        תצטרך עוד. חוה נוסעת אתה עד אוסטריה לקרובים שהזמינו אותה. הן נפרדו אתנו
        ובקשו למסר לך שלום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הלילה לנה אצלנו גולדה יאפו. היתה ברחבות ובאה להתראות. "אלפי שלומות"
        (בשפת י. אפשטין) שולחת לך גולדה. שאלה פרטי פרטים על חייך כדרכה. גם ג"ב
        יעקובזון, אחות רוזה באה אתמול מח.-ה ודרשה בשלומך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אבא עוסק בהרכבות בכרמכם ומרגיש את עצמו יותר טוב. עשה היום את הזריקה
        הששית.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לך ולצבי. בטח תודיעו את סבת שתיקתו הארוכה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה   אמא.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="17.6.1923">
      <span class="c15">ג&#39; תמוז תרפ"ג  מאפרת לנחמה ומיכל   </span
      ><span class="c15 c6 italic">[17.6.1923]</span
      ><span class="c14 c10"> ירושלים, ג&#39; תמוז תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">אמא חביבה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >נסיעתנו נדחתה עד יום רביעי, הודות להפצרת צבי. מסרו לי אתמול דרישת שלום
        ממך, פגשו בך ביפו – ז"א שלא חכית לי וטוב עשית.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c24"
        >אמש באו אלינו הד"ר והג"ב טהון, בבקשה ושאלה כאחת. והיא: הם נוסעים לחול
        לששה או שמֹנה שבועות ואת הילדים עוזבים כאן. הילד ישאר אצל ידידים שלהם פה
        או ביפו, ואת הילדה רוצים הם למסֹר לך. ילדה גדולה כבר, דומני בת 15-14.
        ילדה טובה מאד ואוהבת עבודה. המסכימה את לקבלה? צבי וצפורה מתנגדים לזה. אך
        איני מעיזה לענות לאו שלא בהסכמתך. כל תנאים לא הציבו ולא שאלתי אני. זהו
        כמובן לא העיקר. השאלה היא אם לא יכבד עליך הדבר. התחשבי יפה עם כֹחותיך את
        ולא עם מה שהנימוס דורש. הבטחתי להם להמציא תשובה במהירות הכי אפשרית, וזו
        יכולה להיות רק בעזרת הטלפון. תעני לד"ר טהון למספר </span
      ><span class="c16 c24">138</span
      ><span class="c14 c10"
        > "הכשרת-הישוב". או ע"י טלגרמה, אם תצטרכי לחכות בדאר. אני אמרתי להם
        שיספיק אם תעני "הן" או "לאו" והמותר יהיה כבר מובן מאליו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלום ונשיקות לכלכם. </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">להתראות</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">אוהביכם צבי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">צפרה ואפרת.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="18.6.1923">
      <span class="c15">ד&#39; תמוז תרפ"ג  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[18.6.1923]</span
      ><span class="c1"> ראשל"ץ ד&#39; תמוז תרפ"ג</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >על מכתבכם הנעים, אוכל רק מלים מעטות לערך לכם, כי אצלי כביסה היום, יום
        הדין. רק לבל תדאגו אני ממהרת לכתב, אף על פי שפנאי אין. התפילות שלך צבי
        לאמנות הבשול של אפרת הזכירוני את דברי הקולונל מרגולין, שהיה מעיר בי
        ובאפרת צחוק, כשהוא אמר ולא פעם, שהבעל אוהב את האשה, מתי שהיא מכינה לו
        ארוחה טובה. כנראה שזה לא פרדוקס, ולכן מרץ האשה נתן רובו במטבח; על
        הפילוסופיה של הענין נדבר בפעם אחרת.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >צפורה היתה אצלי אתמול ואמרה לי שבטח תסע לשבת זו ירושלימה וכי אני יכולה
        להודיע לך על זה. היא סובלת שוב ברגל והוריה רוצים שתסע אליך ותחליף אויר
        פן יוטב לה. הייתי אצל לובמן אתמול ומרים מתפלאה מאד, שלא קבלה ממך שום
        תשובה על מכתבה של שבוע העבר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ועוד נס קרה לי אתמול: היה אצלנו מר מבשן, מנהל המו___ החקלאי, והביתה
        הביאו עוד ביום ששי מעט שזיפים, שהגנבים התעצלו לקטוף מראשי העצים. הסתרתי
        אותם בשבילך – חשבתי מרים תסע, והנה מר מבשן לקח עליו בטובו למסר לך. אינם
        מצוינים השזיפים ובכל זאת ינעמו לך – זכרון מן הבית!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מתפלאה אני, שאת לא זוכרת את אחות ג"ב ב. יפה – זו העלמה מרגולין שבילדותך
        התאכסנה אצל גלוסקין והיתה קוראת לך "אפרתיוניק". כמה נשתנתה זו העליזה:
        שני ילדים, בעלה ד"ר כמיה, שיושב בלי עבודה, מצבה קשה והיא כה רזה וצנומה.
        עוסקת בכל זאת הרבה בעסקי צבור בתל-אביב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אסתר מרגולין מבקשת לגבות את שארית הכסף מק"פ יפה, כי היא דחוקה מאד.
        ברחבות אינה מרויחה ומתחרטת על עזבה את ראשון. ואמנם היתה שגיאה, כי הנה
        המושבה כעת בלי מילדת – חלתה שוב ג"ב דורט בעצבון שלה ונסעה ליפו, ג"ב
        רבינוביץ חולה באותה המחלה של אשתקד ובמקרה של לידה נבוכות הנשים מאד באין
        עזר. איזו עול עשו לאסתר!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בבקשה להודיע לי אם יש בירושלים רופא מומחה למחלות עור. האקזמה של אסתר
        שרוף אינה מתרפאה למרות כל מיני התרופות, שנותן הרופא שלנו. אצטרך לנסע אתה
        או ליפו או לירושלים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">סליחה על קצור דברי!  אמכם אוהבתכם נחמה.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="20.6.1923">
      <span class="c15">ו&#39; תמוז  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[20.6.1923]</span
      ><span class="c1"> ראשל"ץ יום ב&#39; ו&#39; תמוז</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים אפרת וצבי!   </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >חשבתי היום לקבל מכתב מכם ונכזבה תוחלתי. מד"ר מבשן קבלנו אתמול גלויה
        כתובה באדיבות רבה, הוא ראה את צבי ומסר לו פ"ש כמו שבקשנוהו. הבריאים אתם,
        ההולך הכל כשורה – הצד, הרגל, העינים? הלא כל אחד יש לו משלו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אתמול שלחתי לד"ר בהם את החוב – 2 לי"מ, ואח"כ, כשאבא עשה את הזריקה
        השמינית התעוררה אצל ג"ב רבינוביץ שאלה כזו: הרשימה איך להתנהג רשומה על 10
        אמפולים ובקופסה נמצאו 12 אמ&#39;. שואלת גב&#39; רבינוביץ – אם להסתפק
        בעשרה או להשתמש גם ב2 הנותרים? תואילי אפרתי לסור לד"ר ולשאל אותו. אקוה
        שאת בכלל מבקרת שם לעתים קרובות ולא עוזבת אותם.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">הייתם בודי אצל נעמי – מה שלום הדודה חנקה?</span
      ><sup class="c2"><a href="#ftnt45" id="ftnt_ref45">[45]</a></sup
      ><span class="c4">  האיננה חושבת לנסע לקרלסבד?</span
      ><sup class="c2"><a href="#ftnt46" id="ftnt_ref46">[46]</a></sup>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היתה אצלי אתמול ג"ב מושנדוו. תארי בנפשך שמצב אוגוסטינה נשתנה לגמרי, כל
        המכאובים והסבל והכֹבד שהיה לה בראשה עבר. הכאב עבר למקום אחר ששם אין כלל
        אותה הסכנה שהיתה קודם. רוזה תסע אתה ירושלימה לצלם אותה בקרני X. ובודי
        תראי אותן.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >כעת, צבי יקירי, יש לי ענין ביחוד אליך. אחד מחברי הגדוד</span
      ><sup class="c2"><a href="#ftnt47" id="ftnt_ref47">[47]</a></sup
      ><span class="c1"
        > אברהם גרשברג, רוצה לבא בקשר עם צעירה מראשון. אתם שניכם מכירים אותה,
        צעירה טובה מאד, שסבלה כבר הרבה בחיים; את שמה בקשו לע"ע לא לגלות. ואני
        הבטחתי לשאל את פיך על מהותו של אדם הזה; שאתה מכיר בלי ספק. הוא עובד
        בעבודות צבוריות, נמצא בירושלים או במקום אחר איני יודעת, אבל אתה בודי
        יודע אותו ויודע את מהותו,  אם אדם הגון הוא וראוי לקחת צעירה טובה. את
        עברו היא יודעת – אשתו עזבה אותו והלכה בדרך רעה. ע"פ עצת הרב קוק הוא שלח
        לה גט ורשאי להתחתן. גם שני ילדים יש לו ברוסיה. כל זה היא יודעת, אבל משום
        הטרגדיה שבחייה היא לא שמה לב לזה. רק רוצה לדעת אם הוא איש ישר והגון. הלא
        תעשה לי זאת יקירי לחקר היטב, היטב ולהודיע לי אודותו בפרוטרוט עד כמה
        שאפשר. אהיה לך אסירת תודה בשביל זה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >רצוף בשבילך, אפרתי, מכתב מעדינה. אם לא קשה לך תסורי לג"ב טהון ותודעי
        איך הסתדרה המערכת בשביל "מדור האשה". ע"פ בקשתי כתבה עדינה מאמר וכנראה
        שלא יגיע למטרתו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מרים באה אתמול לשאול עליך. כתבתי לך כבר, שהיא מתפלאה, שאת לא ענית לה
        כלום. היא חכתה לתשובה ממך ושתיקתך מלאת עצבון בשבילה. את צפורה יותר לא
        ראיתי ואינני יודעת אם נוסעת מחר או לא.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הזקנה דורשת בשלומך היא מתענינת תמיד בכל מה שאת כותבת לנו. תפרשי גם לה
        ___ איזה מלים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום מאבא. הוא עובד, עובד בלי הרף. אני מתפלאה מאין הכח מאין המרץ בשנים
        האלה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >החיים הצמחים שלך? לוא אפשר היה להעביר אליך את הבגוניה שבחדר האוכל – כמה
        היה יפה בשביל חדריך!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">כתבו לי על בריאותכם ועל הכל</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אמכם אוהבתכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">נחמה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ב"שדה" האחרון ישנו מאמר של אבא "על הגפן ועל פרי הגפן" מאמר מוצלח –
        תקראי אפרתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה עם הלמוד שלכם? המוצאים אתם פנאי לעבודה רוחנית? חבל אם לא. לצבי יש
        כשרונות ואם לא עכשו – אימתי?</span
      >
    </p>
    <br />
    <br />

    <p class="c11" dir="rtl" id="30.6.1923">
      <span class="c15">ט"ז תמוז  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6">[30.6.1923]</span>
    </p>
    <p class="c11" dir="rtl">
      <span class="c4">יום ו&#39; ט"ז תמוז תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לא התקבל יותר שום מכתב והדאגה ע"ד רגלה של אפרת אוכלת אותי. קמתי בהחלטה
        היום לנסע ירושלימה ברכבת השניה, הכינותי כבר את מעט הבגדים הדרושים לכך
        אבל כשיצאתי אל החצר וראיתי את אבא עובד קשה אמרתי: אי אפשר לעזבו בלי
        עזרתי ועוד בערב שבת – ועם צער בלב חזרתי מהחלטתי לנסע, אבל יחד עם זה
        נולדה הצעה: - אם הרגל דורשת טפול גדול, אז מוטב ילדתי שתבאי עם צפרה
        בתחילת השבוע הבא הביתה ופה נסדר הכל על צד היותר טוב. שקלתי הרבה איפה
        יותר נוח לך בביתך פחות התרגזות זה נכון. אבל יתר המעלות שבבית לעת כזו,
        שאת מחיבת לשכב, כמובן מכריעות את הכף ואני מלאה תקוה שתקבלי את
        הצעתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היתה סברה אתמול שאבא יסע לראותך, אבל הוא מצא שיהיה שם רק למעמסה בעת שאת
        שוכבת. כן, אתם לא כתבתם לי זאת, אבל נודע לי הדבר מן הצד וטוב שאת שומעת
        בקול הרופא. אקוה שהכל יהיה טוב, אם יֵעֳשֶה כל הדרוש, זה יותר קל בבית ואת
        הלא תשמעי בקולי ובא תבאי, לא כן ילדתי?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הייתי, צפרתנו, אצל הוריך שלשום. זוג יונים. רק התור חסרה בקנם. אתמול
        היתה בביתנו חופה של ג"ב רבינוביץ. סדרנו יפה רק הכלה היתה נרגשת יותר מדי
        וזה עשה רושם עגום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >צבי לא יכעס על קריאתי לאפרת לבא לראשון? אני מזמינה גם אותו אם קשה לו
        הפרידה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום לשלשתכם מאבא ומהזקנה. עדה קודחת ומאירקה היה חולה ועדיין חלש ובחצר
        לא שמח, אם לא נקח בחשבון את "עזיזה" העליזה שהיא יחד עם אמה, עם "שמחה"
        מביאה ענג רב לשלשת הבחורים שלנו. חולבים את "שמחה" בחצר, מאירקה רוכב עליה
        ושני האחרים שומרים משני הצדדים ונוגעים בקצה אצבעותיהם בדדים, יוצאת טפת
        חלב והשמחה אז רבה, עד שלא מתרעם הסבא על שהם מקלקלים את העז והשנים
        מתפזרים בפנים עצובות ומאירקה צועק, צועק על ששללו את התענוג ממנו.
        "אדג&#39;ה, אדג&#39;ה!" כלומר ____</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ברגר מסר לי פ"ש. אח"כ ראיתי את רוגובסקי ושמחתי שהוא היה בביתכם. חנה
        מתכוננת לבא עם הבחורים. צפרה – זו עבודה בשבילך, בכל מקום יש לך עבודה!
        "רחלה" העושה אטריֹת היתה חולה מסכנת והיה לי השבוע ממנה צרות כאלה, שאין
        לתאר. הבקר הייתי אצלה והנה יצאה מן הסכנה – באמת הוקל לי; צריך מי שהוא
        לשאת את האחריות של אדם אומלל ובודד, וכל כובד המשא נפל עלי. נו, כעת טוב!
        הבקר כבר צחקתי אצלה ואמרתי לה שהיא מחיבת לקום לעשות בעוד ימים אחדים
        אטריות לבתי, כי לשם כך אני קוראת לה לבא לראשון.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו רב שלום ורב נשיקות מאת</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אוהבתכם.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >רבינדרנט תגורי לא בא ואני כבר השתעשתי במחשבה לראותו בראשון. היה לנו
        רצון להזמינו – והנה הודי אחר יושב לו על הכרמל והעתונות רמתה אותנו והיינו
        לצחוק – חבל, חבל!</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="juneOrJuly1923">
      <span class="c4 c34">יום א&#39; בערב</span
      ><span class="c15">  מנחמה לאפרת וצבי </span
      ><span class="c9 c6">[על פי התוכן: לקראת אמצע יולי 1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">יום א&#39; בערב   </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">בתי היקרה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עכשו הביאו לי את מכתבך מיום אתמול. צר לי מאד שעדיין לא מצאתם לכם דירה
        ואתם מתלבטים בחפושים, - כל כך מדכא אותי הדבר, כל כך אני סובלת מזה! רציתי
        להיות לך לעזר ולהקל במשהו, אבל אני קשורה לבית ולעזבו לא אוכל.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אבא אינו נח. עוד ביום החמישי רצה לגשת לגפור השקדים ועלה בידי לשדלו
        לדחות את זה לאיזה ימים, והנה קפץ רגזו של גורן התירס והוא כבר עובד מבקר
        השכם עד שעה מאחרת בלילה. נוסף על העבודה יש עסק עם עגלים וחמורים של כל
        הסביבה אלה הריחו ריח גורן טוב ובאים לטעם ממנו מכל צד זהו מה שנאמר: עושר
        שמור לבעליו לרעתו... עתה יש מעט אוליקנט שנשאר למתום </span
      ><span class="c4 c6">[?]</span><span class="c4"> במטבה </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > וישנו הגפור של שקדים, אח"כ אולי ימצא אבא מעט מנוחה. עם דוד עוד לא
        דברתי, אני חוששת שמא התערבות תזיק. מה יהיה למשל אם הוא לא יסכים לתנאי
        ויתפטר, ובמקומו יבא אדם, שאבא יצטרך ללמדו הכל מחדש והוא יסבל כפלים? זה
        הלא כבר מנוסה בעבודתנו ויודע להפיק במקצת רצון אבא. בכל הפגימות שלו שורר
        בכל זאת יחס ידידותי ביניהם, וזה כבר מעלה גדולה. ולכן אני מפקפקת אם
        להעמיד על המשקל את הדבר או לא.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הייתי היום עם אסתר ביפו אצל שני הרופאים. תוצאת ההקרנה עוד לא ידועה.
        ביום ג&#39; הבא אצטרך לנסע עוד פעם. את בית ה"ס שנפתח היום לכל משך חדש
        אלול אסרו הרופאים על אסתר לבקר, כי תקופת הנשיר היא הכי מזיקה. ובשביל שלא
        תפסיד בלמודים הוחלט אצלי שאדון אמיתי יכין אותה באופן פרטי. מה שזה יעלה
        בטח תשלם הג"ב ברגר. ג"ב סולד הציעה לפני עוד בחרף, שאקח לה מורה לעזר, אבל
        אז לא היה צרך גדול בזה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בדבר שתי הלירות לְפָני רצוי היה שישולחו השבוע, ואני מחר כותבת לועד
        היתומים ואבקש למסר ע"ח הרפוי 2 לירות עבורך ב"תרבות". ד"ר שפירא כותב לנו
        שהוא פנה לצבי בבקשה לסיע לו ברשיון כניסה עבור בנו; בטח קבלתם את המכתב
        ותעשו את האפשר לטובת הענין.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >במרכז המסחרי נגשתי בכונה לראות את החנות הפתוחה שלכם. נכנסתי להסתכל.
        חשבו שאני רוצה לשכר אחת החנויות, דברתי אליהם עברית והם לא הבינו אך שמחתי
        שיהודים עומדים אצל מכונות ועובדים. החנות שבפנה היתה הדלת פתוחה, על
        החנויות מן הצד תלויות מודעות להשכיר, ושגיאות נפלו בהן – מי תלה אותן?
        נחוץ לשנותן.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">נכנסתי היום אל אדון אפשטין</span
      ><sup class="c2"><a href="#ftnt48" id="ftnt_ref48">[48]</a></sup
      ><span class="c4"
        >. הם כבר ארוזים לנסיעה, לאדון אפשטין יהיה תפקיד חשוב בסמינר
        למורים</span
      ><sup class="c2"><a href="#ftnt49" id="ftnt_ref49">[49]</a></sup
      ><span class="c1"
        > ואפשר שאם לא תספיק לו העבודה, ימשיך לנהל את הסמנר ביפו. החתנים השנים
        ישבו אל השלחן אתם ומורגש היה אושר משפחתי. בליתי שם רגעים נעימים
        מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >גם את ג"ב גלוסקין בקרתי, דברנו בלבביות על החיים הובעה מעט פילוסופיה
        וג"ב גלוסקין אמרה: מה טוב שחיי אדם הוקצבו לשבעים שנה – יודעים שיש
        קצב</span
      ><sup class="c2"><a href="#ftnt50" id="ftnt_ref50">[50]</a></sup
      ><span class="c1"> לסבל!...</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עדה עוד מתרחצת ביפו. מחזה נהדר היה עם מאירקה. הוא משך אותי אל השלחן,
        הכריח לשבת והזמין לסעודה באופן ההבעה שלו. הקריב לו כסא וישב אצלי. גרש את
        כלם מסביב – הוא והסבתא זהו העולם כלו. וכשצריכה הייתי ללכת טפס על ידי
        נדבק בי, משכני אל הדלת, אל המדרגות והחוצה הרחק הרחק. לא לעדה ולא לג"ב
        וילסון ולא לאחת הילדות לא רצה ללכת, נדבק בי באופן משונה ורק אתי סוחב
        לעוף למרחקים. מה היה בנפש הפעוט, אם לא געגועים אל הכפר, אל המולדת? לולא
        ראו עיני את המחזה לא הייתי מאמינה שרגש חזק כזה קונן בלב פעוט
        שכמותו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >ילדתי! אם את באמת כבר בריאה, אני שמחה מאד. אקוה שברגע זה את כבר מסדרת
        את העברה של דירה חדשה ואני מאחלת לך הצלחה. אני מוזמנה לאפשטין ירושלימה
        ואני קודם כל מזמינה את עצמי אליך. הם לקחו דירה במאה וחמיש  פונט ברחוב
        מוסררי (ככה כמדמני נקרא הרחוב של מכון פסטור?)</span
      ><sup class="c2"><a href="#ftnt51" id="ftnt_ref51">[51]</a></sup
      ><span class="c1">. </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אפרתי הטובה! יש לי בקשה אליך: אם מצאת דירה בשכונה יהודית – אל תציתי את
        הפרימוס בשבת (יכולים לחמם את האוכל או לעשות כוס טה במכונה הפשוטה)</span
      ><sup class="c2"><a href="#ftnt52" id="ftnt_ref52">[52]</a></sup
      ><span class="c1"
        > ואל תנגנו בשבת. הלא סולחים אתם לי על הדרישה? אני נותנת תשובות לאנשים
        שיודעים להתחשב עם רגשותיהם של אחרים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">איפוא נעמי</span
      ><sup class="c2"><a href="#ftnt53" id="ftnt_ref53">[53]</a></sup
      ><span class="c1"> כעת – הבירשלים או ברחבות הנה?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה מאד כלתה נפשי לדעת את תוצאות הדירה! שלום רב לצבי – מתארת אני כמה
        שניכם עיפים, האפשר לשבת כאן במנוחה מבלי לבא לעזרתכם?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה אמא.</span>
    </p>
    <br />

    <p class="c3" dir="rtl" id="10.7.1923">
      <span class="c15">כ"ו תמוז תרפ"ג  מנחמה לאפרת  </span
      ><span class="c15 c6 italic">[10.7.1923]</span
      ><span class="c1"> ראשל"ץ כ"ו תמוז תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדתי הטובה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >קבלתי אמש את מכתבך, הכתוב מתוך צחוק של מראות הסינמה והוא </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > את צפורה בזמן אכלת גלידה. זה יפה, ככה צרכים לבלות בני הנעורים. אין
        חיים נתנים אלא מתוך צחוק.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הייתי אתמול ביפו עם אסתר אצל ד"ר שטם זו הפעם השניה. רפואותיו מועילות
        באופן פלא. האכזמה כמעט שלא נכרת יותר, רק הראש עוד לא הבריא ויש חשש
        שתצטרך לרפואי ע"י קרני X. זה יהיה עסק מצוין, אבל הרופא עוד לא קבע בהחלט.
        ביום הראשון הבא אסע אתה עוד פעם ויש לי בנוגע לנסיעתך אלינו הצעה כזו: אם
        את חושבת לבא בתחילת השבוע הבא – תבאי ביום הראשון בבקר ליפו. אחכה לך אצל
        הדודה חנה ונסע יחד לעיר גנים לבקר את סוביל. רציתי עוד אתמול לעשות את זה,
        אבל היתה לי עבודה רבה ולא יכלתי. ליום א&#39; הבא ישאר לי רק בקור אצל
        הרופא ואוכל לנסע לעיר גנים. סוביל תשמח מאד אם נבא אליה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >פגשתי את איזנשטין, הוא בנה לו בית בשכונת בורוכוב, אמרתי לנסע  אחה"ץ
        באותה הדרך אבל כאמור העבודה לא נתנה לי. אקוה שתשתדלי שנוכל לעשות את
        הבקור הזה יחד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את שלומית ראיתי ביפו. הילדה שלה חכמה מאד ומאד, בריאותה ותאבונה לאכילה
        הוטב בהרבה, אבל שלומית עצמה חולה, הרחיצות בים גלו בה מחלה, שהיא לא חשדה
        בה כלל- חול במרה. זה דבר רציני שדורש שימת לב רבה. הילדה כמדמני שלא תתן
        לה להתרפאות כדבעי, - יותר מדי אגואיסטים הם הקטנים; הם צרכים את האם כלה,
        את עצמה ואת בשרה ואת כחה ולפעמים את כל חייה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >ראיתי את לובמן-משה ודברתי ע"א החניות שלכם. עוד לא על דעתם כלל להעריך </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > את מסחרם מן העיר. לשנה הבאה כבר התקשרו כעת עם בעל החנות בלי העלת
        המחיר. מה שיהיה אח"כ אם רק תהיה להם אפשרות וחשבון לעבר למרכז המסחר כי יש
        להם 2 מגרשים שם וכשיצטרכו יבנו להם את הבנין הדרוש.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בדבר נסיעתו של עשהאל, או יותר נכון ע"ד רצוננו שישאר כאן, יצרתי תכנית
        חדשה, אם יצליח חפצי אינני יודעת – הלא מחשבותי והגיוני אינם קשורים
        בהצלחה. –
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >עם הרשברג נזדמנתי אתמול באוטו אחד. מקושט כחתן ממש. אוכל כעת לגלות לכם
        את שם הפלאות: אלישבע פל </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > מיודעתנו. הם מצאו מאד חן איש בעיני רעהו והקשר כנראה יצא לפועל למזל.
        אותה אנחנו אוהבים ומכבדים הוא ג"כ עושה רושם טוב – ואשמח אם היא תהיה
        מאושרה. ע"ד חתונת ג"ב רבינוביץ עם בן-ברוך כתבתי לך כמדמני, הרי זוגות
        זוגות צעירים. מה נעים לראות אנשים באשרם!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אתמול קבלתי מורשו </span><span class="c4 c6">[ורשה]</span
      ><span class="c4"> את התקופה השמונה עשרה. שם מודפס ציור קטן שלו </span
      ><span class="c4 c6">[שלי?]</span
      ><sup class="c2"><a href="#ftnt54" id="ftnt_ref54">[54]</a></sup
      ><span class="c1">, הגדול יבא בודי בכרך הבא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הַכניסו לתוך הספריה שלכם את "ישו" של קלוזנר ומבוא לספרי הקודש של
        ברנפלד, שני ספרים חשובים מאד. גם רשומות ג&#39; שיצא כעת כדאי שישאר
        לזכרון בשל ___, זכרון לתקופה אולי הכי נוראה שעברה על עמנו בכל שנות גלותו
        הארוכה מה הם יסורי האנקויזיציה מול נוראות הפרעות? _ _</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היום ראיתי את ארון שורץ, הוא אמר לי שהם מחכים לצפורה מחר, יום השלישי.
        ואני חשבתי שתבא אתך יחד.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לכם מאבא ומהזקנה, מעדה ומהילדים</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו כלכם את נשיקותינו        </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="13.7.1923">
      <span class="c15">כ"ט תמוז תרפ"ג  מנחמה לאפרת </span
      ><span class="c15 c6 italic">[13.7.1923]</span
      ><span class="c1"> ראשון כ"ט תמוז תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדתי הטובה.   </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >קבלתי אמש את מכתבך מיום ג&#39; כ"ד תמוז. מה זה – האמנם הלוחות שלנו אינם
        מ____? את כותבת שאני טעיתי בסמני את יום ב&#39;, יום כתיבת מכתבי העבר,
        בכ"ו לחדש. עיינתי היום שוב בלוח והנה נכון – יום ב&#39; היה כ"ו לחדש ושום
        טעות מצדי לא נפלה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שמחה אני שאתן מבלות כעת את הזמן יפה, יהיה זכרון נעים לצפרה. נכנסתי
        אתמול לביתם למסר שלום ממנה, שמחו שהיא תבא בעוד ימים אחדים. הם מחכים גם
        לאליהו שיבא לעזר להם לבציר. כלם מצפים לילדיהם ------</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ברשימת המקומות שהחלטתן לבקר, לא הכנסתם את בית הספרים הלאומי הלא צפורה
        ספרנית והיא ביחוד צריכה להתענין במוסד נהדר כזה. גם את אפרתי צריכה לרכוש
        הרוחני הזה שיש לנו בירושלים ולהשתמש בהזדמנות זו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אסתר מרגולין לנה אצלנו הלילה, היא דורשת בשלומך ושואלת אם ראית בזמן
        האחרון את משפחת ד"ר בהם? שפרה הבטיחה לה לבא אליה לאיזה זמן. גם מבקשת
        אסתר להודיע לה מה עם קפטן יפה, המסלק הוא את החוב בשביל הגרמפון.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם תהיי אצל ד"ר בהם לפני נסיעתך שאלי אם קבלו את 2 הלירות האחרונים בשביל
        הזרק?  </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >את כותבת לי, יקרתי, על אבוד חפצים ואינך כותבת - מה החלטתם בדבר הדירה –
        אם נשארים או מצאתם אחרת?</span
      ><sup class="c2"><a href="#ftnt55" id="ftnt_ref55">[55]</a></sup
      ><span class="c1"
        > צער גדול אני מצטערת על הטלטול הזה, ותמיד לבי נוקפני פן לא תמצאו דירה
        מרֻוָחה כרצונך, ושמא יהיו בה חסרונות יותר גדולים – מה טוב היה לוא רכשתם
        לכם קן כלבבך, אך צריך סבלנות – זה פעם יהיה. אימתי זמן המוחרם – העוד יארך
        ימים? באמת דואב לבי על העמל הנוסף הזה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לאדון בולינין הוטב. יותר משלשה שבעות היה חולה בטפוס הבהרות. היום עושים
        את החטאוי בביתם והוא יקום.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נפלו אי אלו שגיעות במכתבך גם בכתב, גם בסגנון ואני מתפלאה ומצטערת. את
        נסיעתי עם אסתר לרופא אני אדחה ליום שני כפי הצעתך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">צבי יקירי! קראתי את דבריך. אה </span
      ><span class="c4 c6">[איה?]</span
      ><span class="c1"
        > תועלת בהתענינותי אם להועיל לא אוכל? צריכים ביפו למצא את האדם המתאים
        שידע לנהל דבר כזה ויהיה חרוץ וזריז לחפש את ה"בעלנים".
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אנו לא הזכרנו ע"ד הענבים, - היש למצא קונה על כל הענבים שלכם? בשנה העברה
        היו כמדומני 17 קונטר השנה יהיה פחות. תחקרו אם אפשר יהיה למכר בכסף מזומן.
        את המינים אפרת יודעת.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אני ממהרת לדאר ומקצרת</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו כלכם את נשיקותי החמות</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא.</span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >צפורה! לך נשיקה מיחדת, גם מאבא&#39;לי ומאמא&#39;לי – כמה הם מתגעגעים
        לך!</span
      >
    </p>
    <p class="c3" dir="rtl"><span class="c1">נ.פ.</span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לעבר על המכתב לא אוכל, אפשר גם בו נפלו שגיאות וגם אני חוטאת
        ללשוננו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ביום שני, אם לא תמצאוני אצל חנה, תחפשוני ב"תרבות" שלכם.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="14.7.1923">
      <span class="c15">א&#39; אב תרפ"ג  מאפרת לנחמה</span
      ><span class="c15 c6 italic"> [14.7.1923]</span
      ><span class="c14 c10"> ירושלים א&#39; אב תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">אמא יקרה</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c24"
        >סו"ס הלוח שלך באמת אינו נכון. והרי חויה שניה. מכתבך האחרון מסֻמן בתאריך
        של כ"ט תמוז. בודאי שכתבת אותו ביום ה&#39; מפני שהוא נתקבל ביום "ו" ועפ"י
        הלוח שלנו כ"ט תמוז הוא יום "ו"</span
      ><sup class="c23"><a href="#ftnt56" id="ftnt_ref56">[56]</a></sup
      ><span class="c14 c10"
        >. היוצא מזה שהלוח שלך מקדים ביום אחד. זאת תוכלי להוכח בהשוותך אותו עם
        העתון.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >את בית-הספרים הלאומי הוצאנו מהרשימה מכיון שצפרה כבר בקרה אותו קודם לכן
        בהיותה בירושלים בפעמים הקודמות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >את הכסף בשביל הגרמפון כבר פדה צבי. אני אביא אותו עמדי השבוע.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >אנחנו כמעט החלטנו כבר להשאר בבית. כל מה שהרבינו לראות דירות נֹכחנו
        שדירתנו הנֹכחית טובה מכלן. חוץ מזה הטלטול, העמל והנזק הבאים בעקבותיו
        הכריעו את הכף. בעל הבית הסכים להפחית 30 לי"מ על שכר הדירה של השנה החולפת
        ז"א שהבית יעלה עתה 160 לי"מ במקום 190 לי"מ. שכנים יש כבר כמעט לכל הבית.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >איני מבטיחה לבא ביום השני. דברים קטנים עדיין מעכבים בעדי. אולי נבא ביום
        שלישי או רביעי. אם לא נספיק ביום שני.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >ע"ד הענבים דברנו עם אחדים מהסוחרים. אין לנו חשבון למכר להם, מפני שהם
        כלם מסכימים רק לקבל קומיסיון, ולא את הענבים על חשבונם הם. נשלח את הענבים
        ליקב וירד העול מעלינו יותר בנקל.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלום ונשיקות לכלכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">להתראות השבוע</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלכם צבי, צפרה ואפרת.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="19.7.1923">
      <span class="c15">ו&#39; אב התרפג  מצבי לנחמה </span
      ><span class="c15 c6 italic">[19.7.1923]</span
      ><span class="c14 c6"
        > [נעיר שצבי הגיע ארצה שנים ספורות קודם לכן עם הגדוד העברי]</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">ירושלים יום ה&#39; ו&#39; אב התרפג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלום לך אמא הנכבדה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >נגש אני לכתב אליך על דבר חשוב מאוד. ומפחד אני, מסבת שהשפה שלנו אינה
        שגורה בפי, שמא לא אוכל לבטא הכל כנחוץ. בכל זאת אני מאמין שתביני אותי,
        הלא דברים היוצאים מהלב נכנסים ללב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >טרם שאני נגש לעִקר הענין עלי לעשות הקדמה. אע"פ שלא כ"כ נאה להתחיל
        בהקדמות. אבל לזה יש לי היתר. אני סומך על אחד מהסופרים האנגלים "ברנרד
        שאו", לכל ספר שהוא כותב תמיד הוא כותב הקדמה ולפעמים שתי הקדמות Preface &
        Introduction. בספר האחרון שהוא חבר "חזרה לימי משתלח" ההקדמה היא שני שליש
        והספר בעצמו רק שליש אחר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >אני חושב שאנני צריך אפילו להזכיר שכל בן-אדם רוצה שהחבירים שלו יהיו להם
        דעה טובה עליו. וגם אני אחד מאלה. ביחוד הייתי רוצה שאתם יקירי יחשבו עלי
        רק טובות. בכל זאת בשום אופן לא הייתי פונה עליכם בשום הערה, מכיון שאנני
        טפש כ"כ גדול, או מפני שאני אולי די בעל גאוה. שלא אדבר אפילו על זה. אלא
        פה יש צד שלישי שבעדו אני מוכן לותר על הכל, ולעשות הכל מה שנחוץ.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >עכשיו לעִקר: פעמים אחדות העירות שפני אפרת נפלו (אע"פ שלצפרה אמרת שאפרת
        כ"כ נעימה עכשיו.) וזה מצער את אפרתי החביבה מאוד מאוד, ביחוד כשאת צוחקת
        ממני  מאיזה דבר. היא מתרגזת נורא. לו ראיתם כמה התרגזה חמדתי היקרה
        כשצחקתם לי באינצידנט הקטן של השעון.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >אין אני כותב את זה מפחד שמא תשפיעו על אפרתי לרעה עלי, חמדתי בעצמה וגם
        צפורה שהיתה אצלנו כחדש ימים תעידו. היחס שלי ליקרתי הוא כזה, שההתקשרות
        הולכת ונחזקת מדי יום ביום. רק יודע אני כמה זה מצער את החביבתי. אע"פ
        בידים רועדות בכל זאת כותב אני את המכתב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >תסלחי, אם נגעתי בכבוד לי היקר לי מאד, מקוה אני שאת מבינה את מצבי
        בזה,</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלך בכל הכבוד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">צבי.</span>
    </p>
    <br />

    <p class="c3" dir="rtl" id="16.8.1923">
      <span class="c15">ד&#39; אלול תרפ"ג מנחמה ומיכל  לאפרת וצבי </span
      ><span class="c15 c6 italic"> [16.8.1923]</span
      ><span class="c1"> ראשל"ץ יום ה&#39; ד&#39; אלול תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרתי היקרה! </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מכבר לא הרגשתי כאותה השמחה שהתעוררה בלב ברגע שנודע לי, ע"פ מכתבך הקצר,
        שכבר מצאת לך דירה מתאימה ובמקום קרוב לביה"מ, ועצים לפני הבית וגם אדמת
        גן, - אני מאושרה, מאושרה! כל השבוע לא ידעתי מנוח וכשקבלתי אתמול את מכתבך
        ואני מתכוננת למלאות גז בפרימוס וידי כבר בגז, לא היתה לי הסבלנות לרחץ
        ידים ומהרתי לפתח את המכתב. ואח"כ כשנשתתק הלב הרהרתי: כמה משונה הדבר: -
        פה יש מקום רחב ידים עם כל הנוחיות, ושם בכמה עמל עלה למצא פנה מתאימה!
        אמנם צדק השופט מך, שכל התאמצותם צריכה לפנות לבנין הישוב היהודי בערים; לא
        פללתי שכל כך קשה יהיה בעד _ כסף למצא דירה בירושלים. כעת באמת התכוננו
        לבנין המצב הקשה יעבר, יוטב, אין דבר, - רק לא לדאג ולא להצטער הרבה! אם
        נהיה בריאים, חזקים אז נתגבר על קושי המצב.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ברחוב שלכם יש שכנים טובים ואת לא תהיי בודדה ילדתי, זה כה משמח אותי!
        משפחת אפשטין, בהם, גם ג"ב מלמד, אחות אדון אפשטין, שכנה טובה. ג"ב ד"ר
        קריגר כמדמני כ"כ גרה ברחוב הזה. מובן שעם כלם קשה לבא במגע קרוב, אבל לי
        נעים מאד הרעיון שתשכני בסביבה יהודית טובה ולצבי כה קרוב יהיה הבית.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אתמול גמרנו את האליקנט</span
      ><sup class="c2"><a href="#ftnt57" id="ftnt_ref57">[57]</a></sup
      ><span class="c1"
        > המתוק, בשבוע הבא נגפר שקדים ואח"כ חפש לאבא. לוא רצה לנסע אליך מעט, אז
        הייתי שמחה מאד. הסביבה, שאת גרה כעת מתאימה לו, יכנס לאן שהוא רוצה בלי
        לעיף את הרגל הכואבת. לוא רק התרצה לנסע!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אצל צפרה הייתי שלשום, סובלת הילדה בלי הרף. בלי הקלה כל שהיא. היה דודה
        מחדרה, לו היא מאמינה הרבה, הבטיח שהרפואה, שהוא נותן בטח, בטח תואיל, והנה
        נסע והכאב כמו שהיה בלי כל שנוי. לא אדע מה יהיה סוף הדבר. שני הרופאים
        מוצאים שם רבמטיזם אצלה, אבל התרופה, התרופה איה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה שלום צבי? התעיפתם בטח כדבעי, אבל תנוחו כעת, כשירדה דאגת הדירה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">נשיקות רבות וחמות שולחת לכם</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא</span></p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >יקירי – אני כבר מביט עליכם מגבוה דאגותיכם דאגות השחרית באותו יום אשר
        אתם עסוקים כל כך בסידור קן חדש לשנה הבאה באותו יום גופא עברתי הר גבוה
        גבוה. זאת אֹמרת השארתי מאחרי בגרות, שחרות, סוף סוף הגעתי לפסגה</span
      ><sup class="c2"><a href="#ftnt58" id="ftnt_ref58">[58]</a></sup
      ><span class="c1"
        >. וכעת אני עומד ומביט לשני העברים ובעמדי כאן אני עושה חשבון הנפש שלי
        וחיוך תקפתני וגם שביעת רצון מן המקצת. כמה וכמה מהמעפלים שיצאו עמדי בדרך
        נכשלו ונפלו לאחור ולא זכו לראות בעינהם את הפסגה. הטלגרמות המרובות ששולחו
        לבן גילי מר אוסישקין מזכירים לי את הספורט שבחיים. ספורט ותו לא. אני מאחל
        לכם חיי שקט בפנה החדשה שסדרתם לכם אתמול באנרגיה ספורטיבית</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c16 c4">שלום שלום מאביכם </span><span class="c4 c6">[?]</span
      ><span class="c16 c4 c10 c29"> הזקן</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="19.8.1923fromEfrat">
      <span class="c15">ז&#39; אלול תרפ"ג  מאפרת וצבי לנחמה ומיכל </span
      ><span class="c15 c6 italic">[19.8.1923]</span
      ><span class="c14 c10"> יום א&#39; ז&#39; אלול תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">חביבי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >קבלנו את מכתבכם מיום ה&#39; ד&#39; אלול, ואת פתקתך ע"י מר מזאה
        האגרונום. הוא נכנס אלינו בשבת לפנות ערב. דוקא לא בשעה מוצלחת ביותר כי
        היינו עסוקים בהכנת מים ליום א&#39; בבֹקר. שאלת המים תופסת אצלינו את
        המקום הראשון, בפרט בימים האחרונים כשדרושה היתה כמות גדולה לנקיון הבית
        ולכביסה גדולה. הוא חכה מעט ואח"כ ישבנו ובלינו עמדו שעה ארֻכה. מובן
        ששוחחנו באנגלית, כפי בקשתו הוא.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >צר לנו מאד שלא ידענו על יום מלאת שנת הששים לאבא. כפי הנראה שיותר מדי
        טרודים היינו בהבלי העולם הזה, כגון דירה, מציאותה וסדורה, בכדי להפנות את
        הלב לשעה קלה לדברים העומדים במקצת יותר גבוה, ז"א מחוץ לחיי יום יום
        גשמיים ומגושמים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >קבל אבא יקר את ברכתנו הנאמנה ליום הגדול, יום עברך את ראש פסגת ימי חייך.
        מביט אתה עלינו עתה מגבוה. משקיף על צעירי הדור בחיוך קל על השפתים וממילא
        בודאי גם במקצת בטול כביכול. כן, אמנם הרשות הגמורה בידך לעשות זאת. עברת
        הר גבוה, טפסת בדרך עקלקלות ומכשולים רבים מוטלים בה, ולבסוף מרגיש אתה
        בנצחונך. אם חושב אתה שהגעת לראש הפסגה אזי מי יתן ולא תמהר לרדת ממנה. אם
        אין אפשרות לטפס יותר גבוה אזי השאר נא על עמדתך הנֹכחית והמשך לפעֹל
        וליצור, אך בריא, כי זהו התנאי הראשון לבלי לעזב את המערכה. אחל נא גם לנו,
        לא כמות אך איכות של חיים.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >סדרנו כבר כמעט את קננו לשנה הבאה אך מי יתן ויהי זה אך קן ארעי. פרוזדור
        לקן אמתי, ז"א שלנו. הקן הנֹכחי למרות כל מעלותיו מרובים בו גם החסרונות
        ואלה האחרונים מכרחים להיות, ומכרחים יחד עם זאת לותר עליהם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >צר לנו מאד על צפרה. מה יהא סוף הסבל הזה. אם חושבים שיש לה רבמטיזם, אזי
        מדוע אינה נוסעת לטבריה? אמנם כעת אינה העונה לרחיצה. כתבתי אליה עוד בשבוע
        העבר והיא לא ענתה לי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >משפחת אפשטיין כבר נמצאת בירושלים עוד מהשבוע העבר, אך עדיין לא נכנסו
        לביתם, מכיון שהדירים הקודמים (יונים שונאי ישראל) עדיין לא פנוה. והם
        בינתים מתגוללים מעט פה מעט שם. לא פגשתי בהורים רק את הבנות ראיתי.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >תשובה נחמדה מאד. היא לפי דעתי הכי עדינה בכל המשפחה. מזיגה אחת של נֹעם,
        פשטות, ואנטלגנטיות.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >מכל הסביבה כמובן הננו מבקרים לע"ע רק את הבהמים. מכיון שאני מאותם
        הטפוסים שאינם מתקרבים כ"כ מהר לאנשים חדשים, מובטחני שאגור שנים עם שכנים
        מבלי להפגש עמהם. אך למרות זאת רציתי בשכנות טובה. מוטב שישנה ולבלי להשתמש
        בה, משאהיה זקוקה לה ולא תהיה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">השבה עדה מיפו? מה מצב רוחה? ושלום הילדים.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">רצוף כאן המפתח מהמזודה של אסתר. מה עם ראשה?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >נדרה הכובסת שלי בקשה למסֹר לכם שלום רב בשמה. היא תמיד מתענינת בשלומכם
        ושואלת מה כותבים לי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלום ונשיקות</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">משלכם צבי ואפרת.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c14 c10"></span></p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">אבא יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >עלי להוסיף איזה מלים בתור ברכה לשנת הששים שלך. אני מאחל לך שתזכה לראות
        את בתך "הכי צעירה במשפחה" בהיותה בת ששים ושתרגישו את עצמכם לא יותר גרוע
        מעכשיו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלך</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="19.8.1923fromNehama">
      <span class="c15">ז&#39; אלול תרפ"ג  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[19.8.1923]</span
      ><span class="c1"> ראשון לציון ז&#39; אלול תרפ"ג </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בשמחה קראתי את מכתבך הכתוב בגן דירתך ושכוח גן היה מושרה עליו. שאלת המים
        בודי תפתר גם היא על צד היותר טוב ואז תוכלי לנוח מעמלך. כשאני תארתי לי
        בשבת זו, שאת יושבת כבר במנוחה, מבלי שתעיק עליך שאלת דירה, היה לי רגש של
        נועם מיחד, הלא אני נשאתי פה בסבלך, מרחוק הרגשתי את כל הקושי של העברה, של
        נדידה ממקום למקום והמצב העיק עלי מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >מועד היתומים בקשתי למסר בתרבות 2 ל"מ עבורך, קויתי שככה יעשו, והנה קבלתי
        ידיעה מהג"ב הופמן  </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        >, שהם לא יכולים בלי ג"ב ברגר לתת ע"ח הרפוי כלום, מפני שהכסף הזה הולך
        מקופה מיֻחדת הנמצאת ברשותה של הג"ב ברגר לבד. היא תשוב ב22 לח&#39; אגוסט,
        כלומר השבוע, ואז יסדרו לי תשלום הרפוי. לא הייתי רוצה לחכות עד אז עם
        שליחות הכסף לרוסטוב, ואם רק יש לך אפשרות לשלח את הסכום הזה קודם שנקבל
        מועד היתומים – אז אודה לך מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בודי צבי יודע ע"י איזה מוסד ובאיזה אופן לסדר את משלוח הכסף, ויסלח על
        הטריחנו אותו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >רגל אבא הוטבה והנה תפסהו כאב ראש. הוא זקוק לטפול כמו ילד קטן ממש.
        הקוראת את _ הקונגרס? מענין מאד. כעת ישנם שעורי גננות בירושלים, אני כל
        הזמן חושבת שלוא היית פנויה לבקר את השעורים האלה, אז מצאת בהם ענין רב
        בשובך לימי נעורים. אדון אפשטין ג"כ קורא שם הרצאות – בפסיכולוגיה
        כמדמני.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הייתי אתמול אצל צפורה. הרגל הוטבה מעט, נסתה לרדת ולעבר לחדר השני. יש
        תקוה שתבריא.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עדה שבה מיפו, שמחת מאירקה לא תתואר בחרט. שט הוא כל היום בחצר בצעקות:
        סבא, סבתא! יליד הטבע – כאן מצא מקומו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הילדים בריאים, אך ראשה של אסתר אינו טוב. הפצעים מתרבים וכל המשחות ללא
        הועיל. נשור השערות התחיל לאטו ולא ידועות עוד התוצאות. ילדה מסכנת, מסכנת!
        הלב מתבקע לראות את סבלה! שלום לצבי מאת    אמא.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="25.8.1923">
      <span class="c15">י"ד אלול  מנחמה לאפרת  </span
      ><span class="c15 c6 italic">[אין שנה, לפי התוכן כנראה 25.8.1923]</span
      ><span class="c15"> </span
      ><span class="c1">מוצש"ק אור לי"ד אלול. בבית הועד (לפני ישיבה).</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדתי היקרה!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >קבלתי את 2 מכתביך בשבוע העבר, אבל אני לא כתבתי לך כמעט כלום (חוץ ממלים
        אחדות בתחילת השבוע העבר). את צריכה לסלח לי, ילדה, על עול זה שעשיתי
        לכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >היה שבוע קשה. גפרנו ואבא סבל ברגל, התעיף ולנוח אף רקע לא הרשה לו עד
        הסוף. באחד הימים נפל דוד מן הפרד, יכל לההרג ממש, אבל נפגע רק ברגלו ושכב
        ימים מספר – זה ג"כ הוסיף לאבא עבודה. ביום הרביעי קבלה הזקנה נשיכה מאיזה
        זבוב צבתה היד באופן נורא והרופא חשב שאפשר שסכנה צפויה לה. היא עצמה מלאה
        חששות היתה ובקשה להובילה לצפת. כעבור יום עברה חלפה הסכנה, אבל היא נחלשה
        מאד והטפול בה אינו קטן. הרואה את, ילדה, שאסור לחלום על חפש ומנוחה? חשבתי
        אחרי הבציר אהיה צפור דרור, אעוף לאיזה קן שקט לעבד קצת עבודת רוח בשקט
        ורגזם של מאורעות קופצים ומהרסים את החלומות היפים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עם אסתר הייתי ביום ג&#39; העבר ביפו. לפי חוק ההקרנה, אם היא מצליחה
        צריכות היו השערות לנשר עוד בתחילת השבוע העבר. והנה עד היום רק חלק קטן
        נשר, זעיר שם זעיר שם ואני חוששת שמא ההקרנה לא הצליחה. מחר אני צריכה לנסע
        אתה שוב ואינני יודעת איך לסדר את זה – הזקנה עוד חלשה ועדה מרגישה את עצמה
        לא טוב, בטח תצטרך לשכב מחר ועם הילדים כעת קשה – אינני רוצה שיבאו במגע עם
        אסתר ובכלל לא יבאו אל הבית כשהשערות של אסתר נושרות ואפשר להדבק במחלתה.
        כשהם יכלו להיות אצלי, היה יותר קל. הזהירות עם פעוטים קשה היא עד
        למאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אבל די בהשתפכות הנפש המרה. בסך הכל לא נורא שום דבר! אני רציתי לסדר לאבא
        השבוע טיול ירושלימה. אבל גם כאן לא הצלחתי כי דוד קבל מכתב מאחיו, שחולה
        מסוכן הנהו וברגל צולעת נוסע דוד מחר לבקר את אחיו. בטח הוא יהיה אצלך למסר
        לך שלום מאתנו. גם שרה וצפורה נוסעות מחר והן תמסרנה לך פרטים על כל הנעשה
        בגבולנו הצר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הילדים חמודים וזוהי נחמה פורתא בכל הקושי שמסביב. הם חרוצים ורוצים לעזר
        לסבא בכל. כשמאירקה אוחז פטיש הוא מרגיש את עצמו מנצח וגבור ואיזו גאוה
        מנצצת בעיניו. _ ספר לי כיצד יודע בטח, שהוא גבור מוחלט. הוא מנסה לקום
        באמצע הלילה, מתלבש ואומר שהוא הולך לסבתא. את מבינה? להראות שבלילה הוא
        לובש בעצמו והולך בעצמו. כעת הוא פסק את ה___ הזאת.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את ה2 לי"מ בטח שלחת לרוסטוב. אם ג"ב ברגר באה אז אני מקוה השבוע לקבל את
        המגיע עבור הרפוי ואשיב לך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >כתבי לי רשימה של איזה יינות דרושים לכם. כשאקבל את הכסף אשלח לך פתקה
        ליין, רק עליכם להודיעני איזה מכולם </span
      ><span class="c4 c6">[?]</span><span class="c1"> ?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >נתן כרמי מתחנת האוטומבילים אמר לי שלשום, שבעד הנסיעה </span
      ><span class="c16 c4">האחרונה</span
      ><span class="c1"
        > של צבי, כשהאוטו בא הביתה, הוא כסף לא קבל, בבקשה להודיעני אם זה נכון,
        אזי אשלח לו את המגיע.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום רב לצבי. תארו לי את דירתכם בשביל שיהיה לי מושג ממנה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >צפורה כבר בריאה. מתהלכת ועוזרת בכל לאמה וזו מאושרה. החלטנו עם צפורה
        שלסכות אתם צריכים לבא הביתה. לא תעזבו את הזקנים לאנחות.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >באה ג"ב לובמן מבירות והביאה לי את כל השלום _ ויפים מאד,</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">קבלו נשיקות   מאמא  </span><span class="c4 c6">[?]</span
      ><span class="c4"> </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="29.8.1923">
      <span class="c15">ט"ז אלול תרפ"ג  מנחמה לאפרת </span
      ><span class="c15 c6 italic">[29.8.1923]</span
      ><span class="c1"> ראשון לציון ט"ז אלול תרפ"ג</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >ע"י דוד התקבל מכתבך, ילדתי ואני מתוך צער עמוק קוראת את קריאתו של צזר:-
        "הגם אתה ברוט?</span
      ><sup class="c2"><a href="#ftnt59" id="ftnt_ref59">[59]</a></sup
      ><span class="c1"
        >" גם מן הפנה השקטה, החמה, היחידה שלי קוראים עלי תגר ומטפלים עלי עוונות
        וחטאם, - העוד לא נתמלאה כוס יגוני?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אנסה לדבר בשקט אף כי עיני היו מקור דמעה. </span
      ><span class="c16 c4">לא נכון, שאני העלבתי את צבי</span
      ><span class="c1"
        >! אני לא העלבתי בביתי אף אדם אחד בכל שנות חיי הארוכים. אם היה לעג
        לז&#39;רגון, זה לא היה בנוגע אליו. תקון העברית זה כבר טבעי הרע: בכל
        אספה, בכל ישיבה אני מכניסה לכלם, לכלם את התקונים. לא רוצה, אבל זה נעשה
        מאליו. אני הייתי פורצת בצחוק ומעליבה אותו? ילדה, מה את סחה, מה את סחה?
        האם כל כך גסה, אי מנומסה ואי מחונכה אנכי? איזו מין פסיחולוגיה חולה מצאה
        קן בלבך! מכל חרדתך אני זוכרת שנים: בעת שאני דברתי על הרזון שלך ועל צערי
        בנוגע לזה, את רמזת לי שאחדל ואח"כ התחננת שלא בפניו: - "אמא, אל תדברי
        יותר מזה באזניו." השתוממתי. והיה מקרה בשבת האחרונה כשצבי סבב את השעון
        ושכח לסובב את הגלגל מימין. מצא שזה לא חשוב ואני שאלתי – מדוע זה לא חשוב?
        אז את ג"כ רמזת לי שאשתק ואני שוב לא הבנתי והרהרתי בלבי: האם צבי בובה הוא
        שעלולה להשבר? האם אינו גבר שמותר לדבר אתו דברים ישרים ונכונים?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >יש עוד עלילה: בראש מכתבי אני כותבת לילדתי. אם לחטט בדבר, אז אני חושבת
        שהסבה היא כתיבת רשימה חדשה בצורת "מכתבים לילדה". וזה נעשה אצלי הרגל. אני
        חולמת על פנה שקטה לעבודה לעבד את הדבר הזה בצורה אחרת ואז אחדל בקריאה
        בלתי פוסקת לילדה היחידה, הסגולה, הקטנה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c16 c4">את סובלת בבית מיחס לא טוב לכם</span
      ><span class="c1"
        >! אם ככה – לא צריך לבא, את לא צריכה לצאת ידי חובה כלפי מי שהוא. מצדי
        אותר על געגועים אתגבר, רק שיהיה לכם יותר נוח, יותר שקט, יותר נעים –
        "אשרי יושבי ביתך", שר המשורר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אני מפקפקת אם אבא ירצה לנסע. הוא מצא לו עבודה ולא יעזבה – כעת עוסק
        בברור הפרנצס</span
      ><sup class="c2"><a href="#ftnt60" id="ftnt_ref60">[60]</a></sup
      ><span class="c1"
        > אח"כ הוא הזמין מכונה לקצץ את קש האפונה המוכן לחֹרף. עובד, עובד וזה
        שומר עליו מסבל נפשי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >דוד לקח אצלכם חצי פונט, בהזדמנות אשלח. תואילי בטובך, ילדתי, לשלח לי
        תומי</span
      ><sup class="c2"><a href="#ftnt61" id="ftnt_ref61">[61]</a></sup
      ><span class="c1"
        > את המכתב של אסתר שבו נמצאת הכתֹבת של פני. כיון שלא נסתדר המשלח
        מירושלים אני רוצה לסדר את זה ע"י הבנק ביפו. חבל שלא עזבתי לי העתק של
        הכתבת. תואילי תכף תכף לשלח. מאסתר לבקש את הכתבת אי נעים לי – מפני שאני
        מבטיחה לה, שהכסף בודי כבר נשלח. שלום לצבי מאת אמא.</span
      >
    </p>
    <br />
    <br />

    <p class="c18" dir="rtl" id="sept1923">
      <span class="c15">ללא תאריך  ממיכל לאפרת וצבי </span
      ><span class="c15 c6 italic"
        >[לפי התוכן, לקראת סוף אלול תרפ"ג, תחילת ספטמבר 1923]</span
      ><span class="c1 italic"> [השליש הראשון של המכתב קרוע באלכסון]</span>
    </p>
    <p class="c18 c22" dir="rtl"><span class="c1"></span></p>
    <p class="c18" dir="rtl">
      <span class="c1">התקבלו</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1">לא הצליח הדבר</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1">אבל עם הסניפים</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1">פה על מנת לקבל את</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1">לקבל את היינות פה ולבקש</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1">אחת מה טוב ואם לא – אשלח</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c4"
        >אפרת וצבי חביבי! אמה הת  _ _ _ אתמול בלילה עם אסתר שרוף ליפו. עסק ביש
        עם ההקרנה כנראה לא הצליח כדבאי והרבה שערות נשארו ולא זזים ממקומם אפשר
        שיצטרכו שוב פעם להקרין. ובחפזה מסרה לי אמא בכוננה </span
      ><span class="c6 c24">[?]</span
      ><span class="c1"
        > להודיע לך חדשות מכַפְרך. שלומית בן הריאל שבה אתמול מאמריקא. אמה בקרה
        מצאה בה עלמה נחמדת היא קבלה משרה בירושלים והיא מסרה לאמה שהג" סולד אמרה
        לה __ אהובה השארתי בא"י ואפרת שמה. ולכן היא חפצה להתאהב באהובתה של הג"
        סולד. התכונני. גם עוד הפתעה רחל ובר-כוכבה. האח הגדול גם כן, ואמא עוד לא
        הספיקה לראותם. צפורה כבר הבריאה. ועל כולם! נעמי זייגר-לובמן ילדה אתמול
        בן. כנראה החשמל של רוטינברג פועל גם על לדות...</span
      >
    </p>
    <p class="c18" dir="rtl">
      <span class="c1"
        >העבודות של הבציר והקטיף עם ב_  הכל גמרתי אתמול. כעת אתחיל לנוח מעט
        מעמלי. הנני מודה לכם בכל לבי על הזמנתכם לבוא לכם אך אני חושב כי כעת לפני
        ראש השנה כבר אין זמן לנסוע. וגם קשה לי להטעין הכל על אמא בעת שהיא מרגישה
        את עצמה די חלשה.</span
      >
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">והטיל</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">שליט</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">של המנוחה</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">אך ע"ז</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">מאבא למלאת הששים</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">יד ___ בחזקת היד</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">בא הכאב שברגל</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">כמו שכבר בקרתי אתכם</span>
    </p>
    <p class="c25" dir="rtl">
      <span class="c1">המטבח נדמה רחוק מידי מהבית</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1"
        >והחסרון יורגש _ _ _ בירושלים, וחוששני שלא תסבלי בצואר.</span
      >
    </p>
    <p class="c18" dir="rtl">
      <span class="c4"
        >אם בעצתי תשמעי לסדר לך מן פרגוד ולהעמיד במבוא הבית פנימה ולפרקים לבשל
        שמה, מותב שהאלם </span
      ><span class="c6 c24">[עלם?]</span><span class="c4"> יסבול קצת ולא </span
      ><span class="c16 c4">הצואר</span><span class="c1">.</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c4"
        >בביתינו לפי שעה הכל שקט __ כל ההרפתקאות בשלום. מאיר התחיל להתפתח במהרות
        יום יום גודל </span
      ><span class="c16 c4">המלון</span
      ><span class="c1"
        > שלו וההתקשרות להסבתא והסבא. רק פקח עיניו רץ אלינו ועד חושך מבקר אותנו.
      </span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1">שלום שלום חביבי, אביכם ובשם אמכם. מיכל</span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c1"
        >אפרת חוברת י"א של חודש אב של "השדה" תמצאי ותקראי שמה מאמרי ע"ד
        הקרצית.</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="17.9.1923">
      <span class="c15">ז תשרי תרפ"ד  מנחמה לאפרת וצבי </span
      ><span class="c15 c6 italic"> [17.9.1923]</span
      ><span class="c1"> יום ב&#39; ז תשרי תרפ"ד </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אפרת וצבי יקירי! </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מצטערת אני מאד על נסיעתך ילדתי, הלא יכלת להשאר פה מבלי לסבל נדודים ועמל
        של ערב חג וכל האחריות של אורחת קטנה, אשר תקשר אותך מבלי שתוכלי לצאת
        בערב. אי אפשר הלא ילדה להשאיר בבית לבדה, אפילו אם יש שני שכנים ואם יש בן
        – בעל בית. כל זה לא כלום ואת מחיבת תהיי לשמר את הבית בערבים. זה לא אסון
        גדול באמת, אך כל דבר קטן שמכביד עליך גורם לי צער מרחוק.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הייתי ביפו אתמול, מצד אחד היתה לי שמחה גדולה כשהודיע לי ד"ר שטם, שאסתר
        כעת בריאה בראשה לגמרי לגמרי, אם רק לא ישוב לחוש משהו אחרי כך, גם במכון
        רנטגן שמחו מאד לראות את ראשה. _ _ _ תמסרי לג"ב ברגר את בשורה הטובה. גם
        תעודה נתנה לה מאת ד"ר שטם, שהיא חפשיה משמירה ותוכל לבא במגע עם ילדים
        ולבקר את ביה"ס. הילדה מאושרה, מאושרה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">מצד אחר סבלתי בבית הדודה</span
      ><sup class="c2"><a href="#ftnt62" id="ftnt_ref62">[62]</a></sup
      ><span class="c4"> כשנודע לי את כפל הטרגדיה של שפרה.</span
      ><sup class="c2"><a href="#ftnt63" id="ftnt_ref63">[63]</a></sup
      ><span class="c4"> הבחור </span><span class="c16 c4">נשואי</span
      ><span class="c4">. אומר שהתגרש, אבל זה עוד דורש </span
      ><span class="c16 c4">חקירה</span
      ><span class="c1">. תוכלי לשער את ההפתעה. זה נורא, נורא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נתתי לחנה מעט עזרה לחג. אבל זה כלום, כלום מול הסכומים הדרושים שם.
        אסתרקה ג"כ גורמת לאם המסכנה די צער. כמה אם זו אומללה! הקריבה את נפשה ואת
        כל חייה עבורן וכמה נותנות לה כבוד!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אני גומרת, אדון שורננר נוסע. יוביל המכתב והבגד של יונהלי. קבל  שלום
        מהסבתא.          
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבא  אמא.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="19.9.1923">
      <span class="c15">ערב יום כפורים  ממיכל  לאפרת וצבי  </span
      ><span class="c15 c6 italic">[19.9.1923]</span
      ><span class="c1"> ראשון לציון ערב יום כפורים</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">בני חביבי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בשמי ובשם אמא מכל משפחתינו הנני מברך אתכם בגמר חתימה טובה  _  בבית הכל
        בסדר אין חדשות נתהוו בימים האחרונים. אך אמא שבה מיפו ביום הראשון ופתקא
        בידה מהרופאים של אסתר שהנה כבר בריאה לגמרי ומותר לה כבר לבוא בקהל. אי
        לזאת יש סברא שאני ואמא ואסתר נופיע אליכם לכבוד החג בתור עולי רגל האם
        תקבלו אותנו? ההחלטה הגמורה תהיה ביום הראשון מפני עסקי שפרה עם שדוכיה יש
        פה ענינים שאמנם סובלים דיחוי הלא ידוע לך שהיה לה איזה צעיר שהיא דחתה
        מפני הצעיר יצחק זה שהיה בשבת אצלינו והנה טרגי קומעדי שלו יש אישה וילד
        ברוסיה וכעת אחרי שקלי וטריה הסכמה להראשון. ולכן אפשר מאד שעל ידי זה יהיה
        עכוב בנסיעתנו ואתך תהיה הסליחה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מפני טרדות הערב חג מסרה לי אמא את הכתיבה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כנראה שלא לשכוח זה דבר בטבע שלכם את העציץ וגם התבה שקנה צבי שכחתם אם
        נסע אז נשיב לכם את השכחות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">לשנה מבורכה בכל</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">בברכת הוריכם       נחמה ומיכל </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="beforeSukotSep1923">
      <span class="c15">ללא תאריך  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic"
        >[לפי התוכן – לקראת סוכות תרפ"ד, ספטמבר 1923]</span
      ><span class="c1"> ילדי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הפעם הלא תודו גם אתם שהתנהגתי מנהג חושם להפוך את היוצרות במקום לקבל את
        פניכם בביתי ביום חג עלתה המחשבה לעלות אליכם. את הסבה אבא בעצמו מחיב לבאר
        ואולי יתביש אם כן אני אומרת לכם כי בשבילו כל הדבר הזה. הוא סובל ברגל מאד
        גם פצעים כבר הופיעו באחרונה מסבת עבודה רבה והתרגזות על כל שעל ונחוצה לו
        המנוחה. אם לא יסע מן הבית אז _ דוד וכל עול האורח יפל על אבא בחג אני
        מאשרה שהוא נוסע וינוח למה לא באתי יחד אתו את זה יבאר אבא בפרוטרוט
        בעצמו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אני הסתרתי את מחשבתי לנסע אליכם לבל תשלט בה עין רעה והנה לא הועיל גם זה
        וביש גדא מצאני על דרך הסתר. "אין דבר אין דבר" היתה אומרת העובדת הגדולה
        הג"ב סולד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את מכתבכם אתמול קבלתי ואני עונה: יותר משהעגל רוצה לינוק, רוצה הפרה
        להניק, אבל מזל אין. או איני די חזקה בשביל צעד כזה – להנות פעם בחיים
        לפחות מחג ומחופש. שמחה אני שלאבא היה המרץ לעשות את זה ואני מצוה אותו:
        ב"שמחת בחגך"! הלא בבית גם לו אין שמחה של חג.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">את </span><span class="c24 c40">התקופה</span
      ><span class="c4"
        > בשבוע העבר קבלתי. תודה רבה! בבקשה להודיע לי – כמה עלתה הכריחה </span
      ><span class="c4 c6">[הכריכה?]</span
      ><span class="c1"> ואצרף את זה לעודף שיש עבורכם אצלי.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >השתדלו שאבא לא יתהלך הרבה. עד בית הספרים הלאומי יש לו היתר. לנעמי יסע
        ויתר המקומות המענינים ידחה לכשתבריא הרגל.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שמחו ועלזו דודים ותסלחו לי על ישיבתי בבית.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה אמא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום ונשיקות לאנהלי, בטח טובים הבגדים ששלחנו ע"י שפינר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">עדה נוסעת היום עם יתר החבריא ליפו.  </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="26.9.1923">
      <span class="c15">א&#39; דחוהמ"ס תרפ"ד  מנחמה לאפרת, צבי ומיכל </span
      ><span class="c15 c6 italic">[26.9.1923]</span
      ><span class="c1"> ראשון לציון א&#39; דחוהמ"ס תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">מיכל וילדים יקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מז"ט, מז"ט! אתמול התארסה שפרה. מר סטור בא אלינו בערב החג ואתמול בבקר הם
        יצאו שניהם אלי בקשו יין והודיעו לי את החלטתם. הוא אדם הגון מאד, בריא
        ברוחו וגופו. אפשר שיעמידו את החופה בירושלים באסרו חג בבקר. היום הוא נסע
        ליפו, ישוב בערב ואז נחליט הדבר. אפשר שהם יסעו עוד מחר בבקר ירושלימה
        ויבאו אליכם טרם שיגיע מכתב זה לידכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >יש אומרים שאני גבורה, לא האמנתי עד היום. כן, צריכה הייתי להיות גבורה
        בשביל להוציא ענין כזה אל הפועל! רק שתוצא העקמומיות מן הלב!...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >דוד ברק נוסע מחרתים להתארס כנראה. אמר לי חצאי מלים מתוך בושה ואינני
        יודעת אם להתארס או להתחתן. כלתו בקולנדיה. מחר בערב תבא הנה אחותה עם 2
        ילדים והוא נוסע אתם </span
      ><span class="c16 c4">אליה</span
      ><span class="c1">. קנה בגדים יפים והוא עצמו פניו זוהרין משמחה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שאני אוכל לנסע אינני רואה שום אפשרות. אחרים יותר חזקים ממני – כשהם
        רוצים דבר הם מוציאים זה אל הפועל. דוד מבקש מאד את סליחתי על שהפריע לי את
        שמחת החג, אבל מי יעמד בפני ענין חשוב של אהבה ואשרה? כל הנחלים הולכים אל
        הים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >את לא כועסת עלי, ילדתי שאני יושבת בבית? בודי את חושבת: אמא בשלה, בזרות
        שלה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >איך אתה מיכל? איך הרגל, איך הבריאות ומצב הרוח בירושלים? השמחה
        יונה&#39;לי?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום לכלכם ונשיקות אין סוף</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">נחמה</span>
    </p>
    <br />

    <p class="c3" dir="rtl" id="chohamSukotSep1923">
      <span class="c15">יום ה&#39; בערב    מנחמה לאפרת וצבי</span
      ><span class="c15 c6 italic"
        > [לפי התוכן – חוה"מ סוכות תרפ"ד, סוף ספטמבר 1923]</span
      ><span class="c1"> ראשל"ץ יום ה&#39; בערב.   </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עכשו הביאו לי את מכתבכם של היום עם הקריאה לבא ירושלימה. התחשבוני
        לעקשנית אם לא אשמע לקול קריאה זו, המלבבת והקוראת אותי כל כך? אבל הגידו
        איך לעשות את זה? אם אפקיר את המשק – בעלי החי שברפת אינם יכולים לקבול
        ולהביע התנגדות – אבל אמא אומרת בפירוש שלא תתן לי לנסע, פן יקרה לה מקרה
        רע וחס ושלום תלך מעולמה... תארו לכם, הנה אנחנו רק שתינו כעת בבית ובחצר,
        נחוץ לי מאד לגשת למושבה ולא מעיזה לעזבה לבדה לשעה קלה, ואיך אעזבה ואסע?
        סלחו לי כלכם ואל תדאגו לי, אני אסע ירושלימה אחרי החג ואשמח בצל קורתם של
        זוג היונים שלנו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >יפה הצעתך, צבי, בדבר אפרוחים. במחשבתי היה לעשות את זה בעת שנסע אבא, אבל
        הדבר לא יצא אז אל הפועל. יש לנו אפרחות יפות ומן המבחר אביא לכם בבואי
        אחרי מסיק הזיתים. תגדלנה לע"ע בבית, אולי תתחילנה בינתים איזה מהן להטיל
        ביצים ואביאן יחד עם תרנגל צעיר. יש לנו כעת גם יונים, אם תרצו לסדר שובך
        אביא גם זוג יונים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אתה, מיכל יקירי, אל תחשב לך לעוון שהנך נח כעת מעט. מכבר צריך היית
        להרשות לך זאת והיית יותר בריא וחזק. נחוצה מנוחה לאדם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לשפרה ואהרן נספחה אסתר בנסיעה. מובן דרצון גדול לא היה לי אבל בכל זאת
        הסכמתי. הילדה כה שאפה לנסיעה, סבלה די, תתענג כעת מעט. היא רוצה להיות אצל
        חברתה סבירה אלבום, ילדה טובה. אל צריך להפריע לה, שם תמצא עוד חברות וזהו
        תענוגה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >רחל הוסיפה לי על המעטפה שנפגשה בתחנה (אינני יודעת באיזה מקום) עם שפרה
        וחתנה. רחל ג"כ הביעה את השתתפותה בצערי, שלא יכלתי להנות מברכת בית
        אפרתנו. אבל עוד יבא תורי ובזה אני מתנחמת.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום ונשיקות לכלכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם נחמה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שפרה ואהרון! השמחים אתם על הטיול? המצאה ירושלים חן בעיניכם? כתבו לי על
        הכל.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלכם דודתכם נחמה. ליונציק נשיקות ולאסתר שלום ואל התרגזות!</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="1.10.1923">
      <span class="c15">הושע"ר תרפ"ד  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6">[1.10.1923]</span
      ><span class="c1"> ראשל"ץ הושע"ר תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם לא תלכו מחר אל הדאר, אז מכתבי זה יבא בידכם אחרי כל הרעש שהיה בביתכם,
        במשך החג. לא מעט אני הצטערתי על עמלך הרב, יקרתי, אף זה שלא יכלתי לעזר לך
        במאומה, כשהיתה לך עבודה רבה כזו. ---</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אתמל הייתי ברחבות עם הזקנה. ברכה הרשפלד הבטיחה ג"כ לנסע ולא יכלה. הניע
        אותי לנסיעה, זה שאסתר</span
      ><sup class="c2"><a href="#ftnt64" id="ftnt_ref64">[64]</a></sup
      ><span class="c1"
        > לא כתבה אף מלה יותר משלשה שבעות ואני דאגתי לה מאד. סוף סוף הכל בשלום
        אצלה, אלא שהיא עסוקה – יש לה שתי יולדות, מסַז&#39; אחד, וגמרה. איזה
        זריזות. חרוצה אסתר שלנו. לוא ראיתם איך היא מסדרת עבודות הבית ועבודות
        חוץ. ממש מפליא. והטפול באמלל הזה, בדוד, ומלוא ספוקיו והכל בזמן
        ובסדר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >בקרתי עם הזקנה את שלומית ואת חנניה. הוא יושב בדירה נפלאה – זו שגר  ד"ר
        ענטון – 9 חדשים  </span
      ><span class="c4 c6">[חדרים?] </span
      ><span class="c4"
        >בבית, אויר מכל עבריו, חצר גדולה ומרתף ועולה לו רק 4 1/2 לירות בחדש.
        איזה מזל! הוא עומד להת___ עם דורה ארנוביץ. ואפשר עוד בימי החג הזה. את
        אמה ראיתי אצל שלומית, אשה חכמה וערומה לא פחות מבתה. דורה היתה השבת
        ברחבות, אתמול לרגל מסחרה נסעה ליפו ובערב צריכה היתה לשוב, כנראה לגמר את </span
      ><span class="c16 c4">ענינה</span
      ><span class="c1"
        >. היא אוהבת את חנניה אהבה עזה. היא תדע לסדר את חייהם באופן מצוין. בביתו
        חסרה כעת אשה, ריח הרוקות נודף בכל פנה, יד אשה ואשה חכמה כדורה תמלא את כל
        החסר ותעמידו על רגל איתנה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >עמינדב ונתנאל ג"כ עומדים על הפרק ונבחרו ג"כ מאושרות, מפני הכבוד יתנו
        כמובן זכות קדימה לחנניה. חסרה נעמי ברחבות. יפה כל המשפחה בבית עשיר,
        כשיושבים בסוכה נהדרה ודשולחן מלא אורחים, - היו הרבה אורחים מחוץ לארץ –
        והזקנה היתה מאושרה מאד ומאד להנות מכל היופי וההדר. רק נפש אחת אומללה שם,
        זהו לוטמן, אינו מוצא פה מה לעשות ומתעתד לנסע, אבל את הילדות לעזב קשה לו,
        הוא קשור אליהן יותר מאם ובחייו נמשכת הטרגדיה, שאין ממנה מוצא. חבל שהוא
        רחוק מאתנו, חבל שאף מלה של נחמה איני יכולה להביע לו. איך הוא מרגיש את
        עצמו בין האחים? כלם בריאים חזקים,מלאי כח ואֹמץ וחיים והוא רצוץ ושבור
        ונדכה. כמו כל חלש אינו אהוב </span
      ><span class="c16 c4">על הבריאים, על השולטים</span
      ><span class="c1"
        > ועול ילדיו קשה על אחרים שבעתים, כל זה מעיק, כל זה מדכא את רוחו. וסוף
        סוף לאן אדם כזה ילך לחפש את לחמו?
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בשובי בערב מרחובות מצאתי את אננה לוין בגן. היא חכתה לי מן הבקר. כמה
        הצטערתי על העדרי. ביפו אמרו לה שאנכי בירושלים והיא באה רק לקחת את בגדי
        החרף שלה. אני ואמא בקשנו ממנה מאד להשאר לחג, אבל היא לא לקחה שום שמלה
        חגית וגם יש לה לדאג לשתילים לקחת לנהלל ולא הסכימה להשאר, לקחה בבקר השכם
        את חפציה ונסעה עם קושניר בעגלה פשוטה. זוהי בת חיל! יש שאני מסתכלת בה
        ומצרפת אותה לסריה של הקדושות, יש לי סריה כזו. אדם שלם ברוחו ובגופו, אדם
        שידע להקריב את עצמו לאדיאל במדה כזו – האין שם קדוש הולם לו? לא אדם שהלך
        המדברה להזות הוא קדוש, אלא אדם שהלך המדברה לעבוד ובלב טהור כזה ובמחשבה
        טהורה! אני כורעת ברך בפני אלה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אני משתעממת בחג הזה. הוא הראשון בכל ימי חיי, שאבלהו בבדידות כזו, רק
        זמרת הצפרים שהתגברה עם רוחות הסתו משעשעת אותי לרגעים. השרים הם באמת או
        מתכוננים וקובלים לקראת הימים, שהשמש מתחילה להסתתר בין עבי שחקים?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >____ שפרה? באיזה מצב רוח נמצאה בירושלים? לאימתי החליטו לחוג את חתונתם?
        אדם הזה יוצר לו טרגדיה בעצמו בעצם ידו. יש לה כֹכב  אבל אינה יודעת
        להורידו אליה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כתבו לי איך שררת השקט בביתכם משפיע עליכם? צפורה, אננה לוין ואסתר
        מרגולין בקשוני לדרש בשלומכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הקבלת צבי את השקדים ששלחה אסתר לדוגמה? טוב יהיה אם ימצא קונה בירושלים,
        השקדים שלה יפים מאד. ההסתדרה החנות של תוצרת הארץ? איך הספריה? בעתון לא
        פגשתי עדין שום מודעה ע"ד החנויות – מדוע? כדאי לבא בדברים עם סטור, הוא
        חרוץ, אולי ימצא מי שיחכר את החנויות.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >לא קבלתי שום ידיעה מאבא ואינני יודעת באיזו שעה ביום הרביעי לשלח את
        עגלתנו ללוד. עשיתי חשבון בעצמי, שהוא יצא ברכבת של 11 ואשלח את העגלה לפני </span
      ><span class="c16 c4">אחת</span
      ><span class="c1">. איך אבא, ההרגיש את עצמו בריא?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תמסרו לשפרה בהם שאסתר מצטערת מאד על שאין שום מכתב ממנה. השב ד"ר בהם
        מחו"ל? תמסרי לשפרה ולכל משפחתם שלום ממני.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ההיית בימי החג אצל נעמי? מה שלום יצחק ומנוחהלי?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם באהבה רבה אמא.</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="4.10.1923">
      <span class="c15">כ"ד תשרי תרפ"ד  מנחמה לאפרת וצבי </span
      ><span class="c15 c6 italic">[4.10.1923]</span
      ><span class="c1"> ראשל"ץ כ"ד תשרי תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יקירי! </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >נבהלתי מאד אתמול, כששבה העגלה מלוד ואתה, מיכל, לא באת. דוד עצמו היה
        מבוהל ולא יכל לבאר לי היטב את הדבר. הבחורה שלו כנראה מסובבת לו את מחו
        ואני לא יכלתי להאמין לדבריו האי ברורים ואמרתי: אך חולה אתה ולא יכלת לבא!
        לא ידעתי כלל שאסתר ויונה עוד קודם עזבו את ירושלים, שום מכתב לא היה ואחרי
        שהייתי כבר מלאת דאגה על חוסר מכתב עוד נוספה דאגה לבריאותך. - -</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לקראת ערב באה כל החבריא מיפו, עדה עשהאל כל הילדים ואסתר ונמסר לי המכתב
        משם. טוב שאבא ינוח ואולי בינתים יורכש המגרש ויהיה לכלנו זכרון נעים
        לבקורו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אני שקלתי היטב את עמלך, אפרתי, בחג הזה והחלטתי שכל זמן שאני עוד לא
        זקנתי לגמרי, נקבל את קרובינו ואת אורחינו יחד בביתנו כאן. זה באמת היה ראש
        כשרונותי, האם הוא צריך כבר לרדת יחד עם יתר סגולותי מעל הבמה?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >לעשהאל ביפו אין כבר יותר כלום מה לעשות. פה גברת כהנסקי, שהיא האפוטרופס
        על רכושו של מרקוס המנוח, רוצה למסר לו את הנהלת העבודה הזאת, אבל ישנם
        הרבה קופצים, ביניהם בקר, מנחם כהן ועוד יחסנים כאלה, האפשר להאמין שעדינה
        תנצח את הלויתנים האלה? בכל זאת היא בקשה את עשהאל לסיר היום את הכרמים
        ולחבר תכנית עבודה. הוצע לפניו איזה ענין במושבה אמריקאית שאצל
        עין-חי</span
      ><sup class="c2"><a href="#ftnt65" id="ftnt_ref65">[65]</a></sup
      ><span class="c4">, אבל לא עם משפחתו הוא מסוגל להושיב נשמות </span
      ><span class="c4 c6">[?] </span><span class="c4"> ולשבת שאהלים </span
      ><span class="c4 c6">[?] </span><span class="c4">או בברקים  </span
      ><span class="c4 c6">[?] </span
      ><span class="c4"
        >וכל הנדוניא שם – 30 דונם אדמה, מאה לירות הלואה ו6 לירות בחדש שכר </span
      ><span class="c16 c4">שוטר</span
      ><span class="c1"
        >. להתישב צריך תכף עם המשפחה. שאלני, ואני אמרתי לו שהרבה עדיפה ראשון,
        במקום שיכולה להיות לו הרבה יותר אדמה, הלואה של מאה ל ג"כ יוכל באיזה אופן
        שהוא להשיג, ואם יעבד היטב את אדמתו במקום שיש לו בית וכל נוחיות החיים –
        רופא ובית ספר וכ"ו, ואם יזרע טבק, המכניס יפה עכשיו כשענף זה התחיל להתפתח
        בארץ, אז ימצא את קיומו. בא מדוכה מאד, אח"כ שב קצת רוחו אליו. כן, זה לא
        קל כשדאגת משפחה גדולה מוטלה על השכם. ואין פרוטה לפורטה! מעט הכסף שהיה
        בכיסו הלך לרופאים ביפו. ספהלי היה חולה, עדה גם כן. פני כלם כמעט רעים.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >יום הששי הבא, הוא יום הולדך ילדתי. בכל פעם שיש לי צרך לגשת אל הלוח,
        עומד ב&#39; חשון זה נגד עיני. במה נכבד את היום הזה? אנכי אינני בעלת
        השראה כלל, חשבו אתם והחליטו על איזה דבר נעים לכלנו. אולי יבא זוג היונים
        אל קן אמם?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">רב שלום ורב נשיקות לשלשתכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">באהבה</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">נ. פוחצ&#39;בסקי</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="10.10.1923">
      <span class="c15">ל תשרי תרפ"ד  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6">[10.10.1923]</span
      ><span class="c1"> ראשל"ץ ל תשרי תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ילדים!   מכתבך הנחמד, אפרתי, הגיעני אתמול. מובן שאבא שמח מאד ואיננו
        חלילה מן המרגלים שיוציאו דבה. הוא מאשר שהביא לך איזו תועלת בסדור נוחיות
        הבית, אבא מביא תמיד תועלת, אני מקנאה בו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >הזמנתכם החביבה לבא אליכם נגעה עד נפשי, אבל עליכם הלא לדעת, שכעת עונת
        הזיתים היא, ועד כמה שהזיתים מועטים, בכל זאת הם דורשים סדור וטפול, הרי גם
        המעט אין להשליך החוצה. ובכן לבא לא אוכל ליום הולדת ילדתי, אבל אינני
        משוללת תקוה, שגם תורי יגיע לנוח קצת בצל קורתך. אבא מתפלא מהצורה שלך
        אפרתי ומאהבתך לסדר ולעבודה. כן, לוא היית בן, אז יכלו ההורים להיות בטוחים
        שעמלם לא ילך לריק ויהיה מי שידאג ל_ שהכניסו בו את כל שנותיהם וכשרון
        עבודתם ומרץ ומסירות. עתה מי יודע? מרץ גדול אינו מתעורר בעשהאל. הוא אומר
        שלוא נמצא בידו מוכן די כסף מספיק עשיה תמימה לקיום, אז היה יכול </span
      ><span class="c4 c6">[?] </span
      ><span class="c1"
        >לעבוד בלב שקט ובמרץ, אבל פלאות כאלה איך למצא בימינו?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אולי יעלה בידי להשפיע עליו משהו, אבל אני יודעת שזה יהיה יותר רע, שיוצר
        סבל חדש, אי נעימות, _ _ _ אבל לנסות צריך. הפרפר איננו יודע שהוא יכוה
        באש, אבל אדם המביט על החיים בעין בהירה הוא יודע אימתי הוא משחק באש ונרתע
        אחור.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >על המאורע האחרון אצלכם, אבא ספר לי ואני מתפלאה מאד, צר יקירי שאתה לא
        מתיעץ עם אפרת בענינים כאלה, הלא היא כל כך חכמה, כל כך יודעת את האנשים!
        (תסלח לי על שבחים אלה בפניך, ילדה!) חוששת אני שנלכדת צבי הפעם בפח; אפשר
        שכונתם לא היתה רעה, אבל הענין ענין ביש הוא אם אתה מוסר לידם את תפקידך
        ב"תרבות". בתוצרת הארץ אין לתלות תקוות לפי שעה, יחס הקונים והחנונים הוא
        רע מאד לשאלה זו; כשרציתי סבון, שמן לא מצאתי _</span
      ><span class="c4 c6"> </span
      ><span class="c4"> בשום מקום וכך אתי מיפו </span
      ><span class="c4 c6">[?]</span><span class="c4">. קופסת _</span
      ><span class="c4 c6"> </span
      ><span class="c1"
        >חפשתי בשבוע העבר מקצה המושבה עד קצה ולא היה. וככה בודי בכל אתר ואתר.
        התעצב תפקיד יפה והגון ותלך לתקן עולם שלא התפתח? בשום אופן אל תתן את
        מקומך באחר. אם עסקי תרבות אינם טובים, הצריך אתה להיות השעיר לעזאזאל?
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >תרגיעי את שפרה בהם שלא תכעס, אבא מצער ביום ההוא על זה </span
      ><span class="c4 c6">[?]</span><span class="c1">.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >משפרה קבלתי בשבוע העבר מכתב רע מאד, נזפתי בה ואינני יודעת מה
        עכשו?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">צפורה בריאה ויפה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ע"ד הפמוטים פניתי כבר לועדת קרן הגאולה, ובודי יבא יום שאוכל להוציא אותם
        מהבנק ולהובילם לך.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >טובה כהנסקי היתה אתמול בערב ובקשה מאד שתואילי בטובך לפנות למר לנדסברג
        ולשאל על אדותה, היא היתה אצלו והוא הבטיח לצלמה  </span
      ><span class="c4 c6">[?]</span
      ><span class="c1"
        > כשרק תבא שעת כשר לזה. לא קל לך לבא עדיו, אבל נחוץ מאד, מה לעשות?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אסתר השאירה אצלכם את התיק שלה. פתח בית הספר ואין לה תיק. הכל שוכחת
        הילדה הזאת.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">אם תראי את הג"ב ברגר, תואילי להגיד לה </span
      ><span class="c16 c4">שפנינה סגל</span
      ><span class="c4"
        >, זו שהבאתי אליה בקיץ, נשאה לאיש הגון בתל-אביב. הוא מורה לאנגלית. ידע
        את </span
      ><span class="c16 c4">מצבה</span
      ><span class="c4"> ולקח את האחריות הליו.</span
      ><span class="c4 c6"> [?] </span><span class="c4"> </span
      ><span class="c16 c4">הברנש</span
      ><span class="c1"> ההוא כנראה עזב את הארץ. </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לכבוד יום הולדתך, ילדה, אני עוסקת בהכנת זיתים בשבילך, אבל זיתים נפלאים
        ואם יצליחו אז יהיה לך דבר טוב בבית. כל השנה הזאת אין לך זיתים טובים –
        מדוע לא לקחת מן הבית אחרים?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >שלום רב וברכות מאליפות ליום חצי יובל של שנות יחידתי.___</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >כמה כואב לבי שלא אוכל לבא לראותך ביום זה, הלא זה גם יום הארוסין שלכם!
        שנה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">גם נשיקתי אך שלוחה באויר!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">סליחה, סליחה,</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">להרעה לאמא.</span>
    </p>
    <br />
    <br />

    <p class="c13" id="13.10.1923">
      <span class="c15">ג&#39; חשון תרפ"ד  מאפרת לנחמה  </span
      ><span class="c15 c6 italic">[13.10.1923]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">ירושלים, ג&#39; חשון תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">חביבי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >עד אחרי הצהרים של יום אתמול עוד לא אבדה תקותי לבואך. מכיון שלא היה מכתב
        כל השבוע חשבתי שמכינים אתם הפתעה לנו. אך תקותי נכזבה עם קבלת מכתבך מיום
        ל&#39; תשרי. אי אפשר לך לבא ואתך הסליחה. יודעת ומבינה אני מה קשה לעזֹב
        את המשק.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >צבי השתדל שיֹרגש החג במקצת, הזמין אורחים לערב ובלינו שעות נעימות. הפעם
        בקרונו סוג אחר במקצת. חברה יותר רצינית ונעימה מאד. א&#39; אגדתי היה מזמן
        לארוחת הערב, היה כה נעים לבלות בחברתו. השיחה התנהלה כל הזמן כמובן בנושא
        הקרוב לרוחו, ז"א על האמנות, הציור הפסול וכו&#39;. היתה יחד עם זה בקרת על
        הנשף שלו, שנערך בערב לפני זה, בליל חמישי. הוא אמן גדול. אילו ראית את
        רקודיו ואת התלבושות לכך שהוא מצירן בעצמו. הצטער מאד כשנודע לו בהמשך
        הארוחה שיום הולדתי היום והביע את רצונו לתת לי מתנה מאחת מעבודותיו. זו
        תהיה לי מזכרת מלבבת מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >היום היתה אספת חברי תרבות (שריברג בא מיפו לשם כך). כאן עלי לחזֹר על
        דברים שנאמרו דוקא במכתבך האחרון והמתאימים אף לכאן: "אדם המביט על החיים
        בעין בהירה, יודע אימתי הוא משחק באש ולא נרתע אחור!" אחרי השיחה שהיתה לי
        עם צבי בנוגע לתוצרת הארץ (את טועה בחשבך שהוא לא התיעץ עמדי קודם לכך, אבל
        הצורה של הדבר לא היתה ידועה לו קודם וזו היתה אי ההבנה) הוחלט ביננו שהוא
        בשום אֹפן לא יסכים לקבל עליו את העבודה הזו, אם גם מאון זה יביא לידי
        עזיבת "תרבות" לגמרי. וכפי הנראה שכך יהיה הדבר, אם רק תהיה בידם האפשרות
        לסלק לו את חלקו עפ"י תנאים ידועים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c10 c14"
        >אחרי ברור דברים ביניהם היום, הברר שאין מקום לארבעה חברים להתקים
        מ"תרבות" ואחד צריך לעזֹב וצבי רוצה להיות האחד הזה. לכך מסכימה גם אני.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >אין אני אוהבת את המסחר, יכולתי להסכים למסחר כ"תרבות", אך בשום אֹפן לא
        לדבר אחר. ומצד שני מתעוררים בי מידי פעם יותר ויותר הגעגועים לאכרות וכפר,
        ולא פעם שוחחנו בינינו והחלטנו שיותר מחמש או שש שנים לא נשאר בעיר. הודות
        לכך שמחים אנו להזדמנות הזו לעזֹב את המסחר. מסכימה אני להסתפק בהרבה פחות
        מעתה (זאת מקוה אני מאמינה את לי בתור בתך) ובלבד לחיות חיים יפים ושלוים
        יותר. אפשר שהפעם הולך ומתגשם, הודות לגורמים חצוניים שונים, אחד מחלומותיך
        שהבעת לא פעם על חיים ועבודה משתפת שלי ושל צבי על ידכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c24"
        >דבריך במכתב האחרון שבאהבתי לעבודה וסדר הייתי יכולה בתור </span
      ><span class="c16 c24">בן</span
      ><span class="c24"
        > להביא לכם תועלת עוררה בי ביתר עֹז  את הרצון להשתמש בכשרון הזה (אם ישנו
        בי באמת, תסלחי לי על הטילי ספק בדבריכם) אף בתור </span
      ><span class="c16 c24">בת</span
      ><span class="c14 c10">. וצבי בתור בנכם השני מקוה אני יצליח אף הוא.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >כל היום עבר עלינו בחלמות ותכניות ובדמיון. יושבים אנו כבר שם באותה
        "עליה" שלך.</span
      >
    </p>
    <p class="c3 c38" dir="rtl">
      <span class="c14 c10">- - - - - - - - - - - -</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >היינו היום אצל בהם. השתדלתי לפיס את שפרה. אם התפיסה באמת איני יודעת.
        היא מרגישה רע מאד, וחושבת אולי להנתח בסוף השבוע.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >אצל לנדברג אהיה השבוע ואדבר בדבר טובה כהנסקי. אני שלחתיה אליו כשהיתה
        פה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >את התיק של אסתר לא פגשתי עד היום. היא דומני לקחה אותו מפה, אני כמעט
        בטוחה בזה, והוא נשאר בודאי ביפו כשהיתה שם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10"
        >רב תודות על הכנת הזיתים בשבילנו ועל הברכות ליום הולדתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">שלום ונשיקות לכלכם</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c14 c10">מאוהביכם צבי ואפרת</span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="16.10.1923">
      <span class="c15">ו&#39; חשון תרפ"ד מנחמה ומיכל לאפרת וצבי  </span
      ><span class="c15 c6 italic">[16.10.1923]</span
      ><span class="c1"> ראשון לציון ו&#39; חשון תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מכתבך, אפרתי, מג&#39; דנא, העיר בי רגש נעים וחם. וכששאלתי את עצמי אח"כ
        – רגש זה מהו? הלב ענה: סר הפחד מפני חרבן המשק ברפות כחנו, סר הפחד מפני
        הזקנה והבדידות, אם רק יבא הזוג לעזרתנו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >כן, יפה יהיה אם תוציאו את מחשבתכם אל הפועל ולא תרתעו אחור מפני חיי
        הכפר. והקושי שיש בו שבעתים עתה בגבור המשבר בארץ. הזהרו רק שכספכם
        ב"תרבות" יהיה בטוח. יש לי יסוד לחשב שהחברים אינם ידידים לכם, כמו בכלל
        קשה למצא ידידים, וצריכים להזהר שלא ינצלו אתכם </span
      ><span class="c6 c24">[?]</span
      ><span class="c1"
        >. מה שנוגע להתפרדות החבילה, הלב אמר לי את זה ברור בהכנסי אשתקד בפעם
        הראשונה אחרי ארוסיכם לתרבות ביפו, וניומן לחש משהו על אזני
        צ&#39;צ&#39;יק. לא שמעתי את תוכן דבריהם בדיוק אבל הרגשתי משהו שישמש
        לפרוד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אני מאמינה ילדתי, שאת יכולה להסתפק במועט, ולפי טבעך ואהבתך לעבודה וסכום
        התועלת שיש בכחך להביא, חבל מאד, שאת צריכה לשבת בעיר </span
      ><span class="c6 c24">[?]</span
      ><span class="c1"
        > ושכל עמלך אין פרי. אם תבנו  עליה על גבי האכסדרה והמטבח שלנו, שזהו שטח
        הגון, אז תוכלו לסדר לכם פנת גן עדן. אם יקום חלומי זה, אז אחשב את עצמי
        למאשרה מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >יש אצלי הרבה תכניות בנוגע לעבודה, עשיתי רשימה שלמה אבל בראותי עד כמה
        אבא חלש, אני צריכה להתיאש מכל תכניותי. בעשהאל מרץ לעבודה לא נמצא אף טפה.
        נחוץ מאד לתקן את הגדר אצל ביתו בשביל לאפשר את התחלת העבודה בגן הירקות.
        דרשתי ממנו זאת אי אלו פעמים, והוא לא זז ולא נע; אין לו מרץ, - הוא אומר –
        כשאין לו פרוטה בכיס. לפי דעתי טעם זה אינו מספיק בשביל להזניח הכל. הוא
        חשב ע"ד איזו שותפות גדולה בשביל גדול טבק, לזאת עדה מתנגדת</span
      ><sup class="c2"><a href="#ftnt66" id="ftnt_ref66">[66]</a></sup
      ><span class="c4"
        >. ואני בכל זאת חושבת ע"ד גדול טבק על שטח קטן, רק כחותינו רפים ועזרה
        בעבודה אין משום צד. כמה גדול הרצון לחזק את המשק לבל יפול, ומה קטן הכח
        לפעולה. זוהי טרגדית הזקנה. צעירים </span
      ><span class="c16 c4">כשרק רוצים</span
      ><span class="c1"
        > ישנה היכלת להוציא לפועל, אבל אצל זקנים אסון הוא רצון גדול ושאיפה כבירה
        שעדיין מהבהבת בתוך האפר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >תודה לצבי שהוא דאג ליפות ולהנעים את נשף יום הולדתך. בבית אצלנו לא
        נהוגים לחוג חגיגות. חיי עבודה הם חיי חולין, ואנחנו כל ימינו שקועים בה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בראשון מבלה השבוע משפחת ד"ר שפירה. הוא בעצמו היה ביום א&#39; ונסע אתמול
        בבקר השכם. מטרת בואם היא ללות את הבנות. הן נוסעות פריזה, ושתיהן החליטו
        ללמד רפואה. לצערנו הגדול נפלה מרים למשכב שלשום. החום אינו גדול, אבל
        לנסיעה זה יפריע ומחר _ מפליגה אניתן למרסל. תוכלי לך לתאר את מצב שתיהן
        ומצב האם. ד"ר שפירא התענין מאד בכם, - מה מצבכם, איך אתם חיים, המרגישים
        אתם את עצמכם טוב והעיקר – אולי עומדה כבר נפש שלישית להופיע במעונכם?
        והתפלא כשעניתי – לאו! הם כלם דורשים בשלומכם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הקראתם את "בתוככי ירושלים"? ראיתי את  הרשימות על עדן חלון חדר המטות
        שבדירתכם הקודמת. אתם התרעמתם על בדידותי בפינה רחוקה – הזוכרים אתם?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אני אודה לך, ילדתי, אם יעלה בידך למצא מקום עבור טובה כהנסקי אצל לנדברג.
        לעדינה יש מעט עבודה בעתון, - איקסא </span
      ><span class="c6 c24">[?]</span
      ><span class="c1"
        > והבטיחו תשלום חודשי. אני שמחה מאד לעבודתה, זה נותן לה קצת ספוק. הכסת
        של סוביל שלחתי היום ל"תרבות" עבורה. בבקשה, צבי, להודיע לה.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">קבלו ברכות ונשיקות מאת שלכם באהבה –</span>
    </p>
    <p class="c3" dir="rtl"><span class="c1">אמא</span></p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מדוע היתה רק פעם אחת המודעה בעתון? צריכים לפחות במשך חדש ימים להמשיך
        ולהודיע על דבר החנויות.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">יקרי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >אני מודה שעוד לא תפסתי את הענין הזה עם תרבות. אמא בעלת טמפרמענט חם ומכל
        דבר היא מתפעלת. ואני מביט על הענין בכובד ראש. יעקב אבינו כעס על שמעון
        ולוי בניו מפני פחזותם. צעדים כאלה בחיים לא נעשים כעיף </span
      ><span class="c6 c24">[?]</span
      ><span class="c1"
        > עין ותשועה ברוב יועץ. את בתי העמדת אותי במצב לא נעים אמרת אם אמא לא
        תסע ירושלימה סימן שאנוכי לא היתי שמח בהתארחי אצליכם ולא זה נכון סבות
        מסבות שונות הפריעו ולא הועילו כל הפצרותי. ולשפרה בהם מסרי שמחשבתי היתה
        ללכת להפרד ואיך אפשר אחרת אך בזה שבלינו אצל מר עפשטיין עד מאוחר בלילה אי
        אפשר היה למלאות את חובי ואתה הסליחה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלכם אביכם מיכל </span>
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="18.10.1923">
      <span class="c15">ח&#39; חשון תרפ"ד  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[18.10.1923]</span
      ><span class="c1"> ראשל"ץ ח&#39; חשון תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >את מכתבך אפרת יקרה, הערוך כמדמני ביום ב&#39; קבלתי אתמול, יש הרבה מה
        לדבר בכל הנוגע לענינים, העומדים על הפרק, אבל כעת קרובה שעת הדאר ואני
        מחיבת למהר עם המכתב הזה, כי רוצה אני להודיע לך ע"א  </span
      ><span class="c6 c24">[?]</span
      ><span class="c1"
        > החדשה, שהולך להתהוות במשק: בכל הזמן שעשהאל שב מיפו אחרי החג אני
        השתדלתי שנגש לעבודה. מרקוס רוצה למסר לו את הכרם אמר שאין לו חשבון אמרתי
        טוב תקח גם את הכרמים שלנו ותהיה לנו האפשרות לנוח שנה מעמלנו שהוא כבר _
         הכחות.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >לא שמע כל זה ולא נקף אצבע. סוף נסע השבוע ליפו, מצא איזה אדם שרוצה לעזר
        לו בכסף והוא בא בהחלטה שכן יקח את הכרמים. חשבתי שעכוב יהיה מצד אבא, ועלה
        בידי גם את זה להסיר ואנו </span
      ><span class="c16 c4">מחכירים</span
      ><span class="c4"
        > לו לשנה אחת את הכרמים (חוץ מהחלקים שלכם שזה תלוי בדעתכם) מבלי לדרוש
        ממנו יותר </span
      ><span class="c16 c4">מחמישים פונט</span
      ><span class="c1"
        > לשנה למחייתנו. בשבילנו אנו משאירים רק פרה אחת ושתי עזים. את מספר
        התרנגלות בודי ג"כ אקטין. המשק מוכן כעת לחרף יפה, מתבן מלא על כל גדותיו
        עם תבן ובקיה. קנינו בכסף מלא זריעת בקיה, כשני קונטר אפונה הכינונו לזריעה
        וכשמונים רוטל חטי תירס, גם שעורה טחונה איזה קונטרים; הוא יכל להיות בעל
        בית עשיר אם ירצה לאמץ את כחותיו הרעננים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם את חושבת שבאופן כזה אין מקום כבר בשביל צבי במשק זוהי טעות. יש לי
        תכניות גם עבורכם אבל לא אוכל כעת למסור מפני קצור זמן. בכל אופן אם יגרם
        הזמן שתצאו מתרבות ותתישבו על אדמה מוטב לכם פה, במקום שיש 2 כרמים וחלק
        בבית מאשר להתחיל במקום חדש לגמרי. כאן הלא נהיה לצבי למורי דרך בכל ותוקל
        עליכם העבודה ע"י כך.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">שלום מאבא</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">וקבלו נשיקות מאמא</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >ג"ב ברגר היתה אתמול אצלנו ארחת הצהרים והתענגנו על בקורה</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="22.10.1923">
      <span class="c15">י"ב חשון תרפ"ד ממיכל ונחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[22.10.1923]</span
      ><span class="c1"> ראשון לציון יום ב&#39; י"ב חשון תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יקירַי</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c4"
        >את המכתב של צבי מיום הרבעי אך אתמול קבלנו. אף כי דבר מוחלט אין שם במכתב
        הנ"ל, מפני שנכתב לפני האספה המוחלטת של יום שבת זו, אך בכל אופן לא אוכל
        להתאפק מלאמור את דעתי בענין זה. כבר אספתי די חומר בימי חלדי במה שנוגע
        לחיה שנקראת בן-אדם. ולכן הזדעזעתי בשמעי כי מצדך אתה מוכן ומזומן לצאת
        מהעסק באופן רשמי ולהשאר רק "נושה" של סכום כסף מהעסק. אחת משתי אלה: אם
        העסק טוב הנהו, הלא מיותר להוציא אחד מהשותפים, ואם באתם לידי מסקנה להוציא
        – סימן שהעסק מתנהל בקושי ואיך אפשר להוציא ממנו סכומים גדולים כאלה בכל
        חודש? זאת אומרת שבעצמך תודה אחרי כן, שדבר אי אפשרי הוא ומחויבים להחריב
        את הכל בכדי להוציא את הכסף הנזכר. ופה עומדת השאלה: מי נדחה מפני מי? שלשה
        נגד אחד בודאי הם יהיו יותר חזקים. ועוד: הם הלא יקבלו כסף חדשי מהעסק ואתה
        תסתובב עם מקל ביד מעורך __ אחד אל השני עד אשר ימאס עליך הענין. אני מביט
        בעינים פקוחות באספקלריה המאירה לי את סוף הפסוק: השמר והזהר לעשות צעד
        מסוכן כזה. אל תוציא מפיך אף מילה אחת מעין זה שכתבת לנו. על כגון דא נאמר:
        "על תצדק הרבה". אני עוד לא ירדתי לעומק הענין איך יסדתם ועל איזה בסיס
        יורידו את השותפות שלכם – אם עשת__ </span
      ><span class="c4 c6">[עשיתם?]</span
      ><span class="c1"
        > ביניכם חוזה רשמי שיש לו תוקף, או סתם באופן אידיאלי ולא להתחשב עם העתיד
        האכזרי? ואף גם עם החיה הגרה בלב בני אדם? ולכן קשה לי לחות את דעתי בענין
        הזה אך לכאורה נדמה לי שהצעד שלך כפי שתארת במכתבך זה מסוכן מאד מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >לפי הבנתי יותר משלש מאות הפונטים שתקבל דמי פצוי בעד היציאה מהדלת ומסירת
        המפתחות מידך לא תקבל כלום יותר וחסל.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אם באמת אין דרך אחרת ל_ העסק אך על ידי זה, שאתה תצא ואתה מסכים לזה,
        עליהם, השותפים הנותרים, מוטל למצא הלואה לזמנים ארוכים ולשלם לך את כספך
        עד הפרוטה האחרונה בפעם אחת ואז תצא בכבוד וזה מה שרמזתי במכתבי הקודם
        "בכובד ראש".</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אפרת – איך מרגישה כעת את עצמך האם עוד לא החלפת את הסדר? כבר אויר סתוי
        מורגש ובודאי כבר קר מאד בחצר שלך. הזהרי אפרת מאויר הסתוני הוא יותר קשה
        מהחרפי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">באהבה רבה אביכם מיכל.</span>
    </p>
    <p class="c0" dir="rtl"><span class="c1"></span></p>
    <p class="c3" dir="rtl">
      <span class="c1">ילדי היקרים!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >עברתי על מכתב אבא. אני חושבת, שהוא לא הגזים במאומה ותאר את כל האמת
        שבדבר. מצדי אני יכולה להוסיף דעה אחת: לא לשלימות "התרבות" אתה צריך, צבי,
        לדאג כעת, אלא לשלימות כספך ובשביל זה צריכה לבא ליקוידציה גמורה של המסחר
        הזה. יסדרו הנותרים אח"כ את העסק מחדש כפי רצונם, אבל לפטר אותך כעת בשטרות
        – חלילה וחלילה לך מהסכים לזאת.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >חבל לי מאד שאנחנו מכבידים על מהלך הענין, אבל זו הקלה לעתיד. "כבדהו
        וחשדהו" אני מכבדת במדה ידועה את חבריך, אבל חושדת אני בהם, שעינם צרה בך
        על כספך ועל אֹשר חיי המשפחה שלך וישמחו לגרם לך רעה בהעמידם אותך במצב
        קשה, שלא יהיה ממנו מוצא. להשאר אתם קשה לוא אפילו קרה הפלא שהמצב יוטב
        ואין דרך אלא ליקוידציה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">על כל יתר הענינים בפעם הבאה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה עם ליטמן? חנניה היה אצלנו בשבוע העבר, דברנו על הילדות, אולי יקבלו
        אותם בתשלום חדשי לבית היתומות? הוא בקשני לחקר הדבר. אולי תגשי לג"ב ברגר,
        תספרי לה כל הענין – אולי יש אפשרות לקבל בשכר. רק ספק אם הדודה תסכים.
        אולי תתיעצי עם נעמי?</span
      >
    </p>
    <br />
    <br />

    <p class="c3" dir="rtl" id="6.11.1923">
      <span class="c15">כ"ז חשון תרפ"ד  מנחמה לאפרת וצבי  </span
      ><span class="c15 c6 italic">[6.11.1923]</span
      ><span class="c1"> יום ג&#39; כ"ז חשון תרפ"ד</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">יקירי!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >הוכחה על החלטתי שראשי יכל רק לשמש בשביל בית מטבחים, הרי לכם זה, שאני
        שכחתי לתת לכם את הצידה לדרך, שהכינותי בשבילכם. שר של שכחה לגמרי נצחני
        בזמן הזה של רוב טרדות ודאגות. תוכלו לתאר כמה הצטערתי שנסעתם בלי אוכל
        מפה. הלא אותי יכולה לצער עצם קטנה שהטבח נותן לי ומקמץ מאחרים (עובדה
        שהיתה בערב שבת זו ואני סבלתי מזה כל היום). - - -  </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >אתמול בשעה מאחרת הביאה עגלתנו חפצים מעיר גנים. עד הרגע האחרון לא ידעתי
        שום עצות בנפשי לאן לשים את התרנגלות והנה אבא מצא: במוכסן שכבר שמש לנו
        פעם לול לתרנגלות שמנו אותן והן מרוצות מאד.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >בבקר השכם (הלא תמיד בבקר אתה חכם) נולדה מחשבה במחי שעם שבכת הברזל
        שהובאה מעיר גנים יעשו גדר על גבי גדר אבנים על כל ארך הצפון. מהסמטא (ששם
        עומדת העגלה) יקחו את כל החלק עד המדרגה של הזקנה, יעשו שבכה גבוהה עם דלת;
        בפנה שאצל המדרגה יבנו לול טוב לשינה, והנה התחלת משק קטן בשבילכם. מובן
        שזה יוציאו לפועל בבואכם הנה. לע"ע תשארנה התרנגלות במוכסן כשהדלת תהיה
        פתוחה ושבכה  _ שלא תצאנה ולא תאבדנה. לערב אותן עם שלנו לא כדי, אם המשק
        יעבר לידי עשהאל כלו, אני לא בטוחה בקיום תרנגלות, את יודעת כמה הם קשורים
        לזה. ואני הייתי רוצה להקל מעלי את הגדול הזה, לא לפי שנותי הן כל העבודות
        יחד. בימים שאבא לא יוצא מן הבית קשה לי שבעתים. עשהאל משתדל לעבד, אבל
        שיהיה לו הכשרון להקל ברגע הדרוש אי אפשר להגיד. קוראים – הוא עונה, לא
        קוראים מוטב. אבל אין דבר. אני מקוה בכל זאת שהוא ימצא את דרכו, אוהב עבודה
        רחבה, כעת זה נתן לו ומקוה שמשפחתו לא תרעב ללחם וזה הכל מה שאני
        רוצה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c4">סובים </span><span class="c4 c6">[סוביל ?]</span
      ><span class="c1"
        > נשארה בעיר גנים לע"ע, עוד הרבה דברים נשארו שם ואיני יודעת איך היא תסדר
        את שלוחותם. טוב היה עד יפו להוביל ולשים בחנות שלכם. וכשתהיה עגלתנו ביפו
        היא תקח בשתי פעמים או בשלוש את כל הנמצא.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1"
        >מה נשמע אצלכם, איך באתם הביתה? ההופיעו כבר הבעלנים של הדירה? אבקשך
        ילדתי שלא תתעיפי יותר מדי, הכל במדה ובמשקל ובמנוחה! למדי סוף סוף לשמר על
        הכחות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1">אני ממהרת ושולחת לכם שלום ונשיקות</span>
    </p>
    <p class="c18" dir="rtl"><span class="c1">אמא</span></p>
    <p class="c18" dir="rtl">
      <span class="c4"
        >שולחת את המכתב ל"תרבות" כמקדם, בודי יהיו די דג&#39;נטלמנים וימציאו לכם
        תיכף.</span
      >
    </p>
    <p class="c11 c22" dir="rtl"><span class="c19 c10 c26"></span></p>
    <hr class="c27" />
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref1" id="ftnt1">[1]</a
        ><span class="c17 c10"
          > חתונת אפרת וצבי היתה צנועה, ורבים שלא הוזמנו כעסו.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref2" id="ftnt2">[2]</a
        ><span class="c17 c10"
          >  אפרת נסעה לי-ם בסביבות 1920עם יונה ואלישיב בני אחיה עשהאל לאחר
          שנושכו ע"י כלב שוטה כדי שיקבלו זריקות.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref3" id="ftnt3">[3]</a
        ><span class="c17 c10"
          >  כל שבת התאספו בבית הנרייטה סולד נכבדים מירושלים, הרברט סמואל ואשתו,
          דידס ואחרים והתנהלה תפילת שבת נאה.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref4" id="ftnt4">[4]</a
        ><span class="c17 c10"
          >  הזוג פוחצ&#39;בסקי חתמו ערבות תדירות ואח"כ שלמו בעצמם.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref5" id="ftnt5">[5]</a
        ><span class="c17 c10">  הסוס</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref6" id="ftnt6">[6]</a
        ><span class="c17 c10">  העגל</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref7" id="ftnt7">[7]</a
        ><span class="c17 c10">  אולי מרים קיפניס לבית לובמן.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref8" id="ftnt8">[8]</a
        ><span class="c17 c10">  מורה בראשון</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref9" id="ftnt9">[9]</a
        ><span class="c17 c10"> מן הסתם היפהפיה מרים קיפניס לבית לובמן.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref10" id="ftnt10">[10]</a
        ><span class="c10 c17"> מהוצאת ספרים "תרבות"</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref11" id="ftnt11">[11]</a
        ><span class="c17 c10"
          > לאה לבית מאירוביץ ובן-ציון אידלנט, חברים של אפרת</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref12" id="ftnt12">[12]</a
        ><span class="c17 c10"> רחל פוחצ&#39;בסקי לימים שוקלנדר</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref13" id="ftnt13">[13]</a
        ><span class="c17 c10"
          > כנראה יצחק אפשטיין שהיה ידיד של הפוחצ&#39;בסקים</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref14" id="ftnt14">[14]</a
        ><span class="c17 c10"> אדליהידה בן-זאב יקותיאל</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref15" id="ftnt15">[15]</a
        ><span class="c17 c10"
          > ארוסה הראשון של גב&#39; בן-זאב התאבד לפני הנשואים. מר בן-זאב בסוף
          ימיו היה מאד חולה.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref16" id="ftnt16">[16]</a
        ><span class="c17 c10"> לאה ובן-ציון אידלנט.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref17" id="ftnt17">[17]</a
        ><span class="c17 c10"> מנהל בית החולים בצפת.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref18" id="ftnt18">[18]</a
        ><span class="c17 c10"
          > הוצאת הספרים שצבי היה שותף בה לפני נישואיו.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref19" id="ftnt19">[19]</a
        ><span class="c17 c10"> כנראה עדינה כהנמן</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref20" id="ftnt20">[20]</a
        ><span class="c17 c10"
          >  מיכל פוחצ&#39;בסקי נזקק לניתוח בבית החולים בצפת. בגלל עבודתו בקרית
          ענבים דחה את הניתוח, והיה מקום לדאגתה של נחמה...</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref21" id="ftnt21">[21]</a
        ><span class="c17 c10"> יתכן שהכונה לאינטרנית – מתמחה ברפואה</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref22" id="ftnt22">[22]</a
        ><span class="c17 c10"
          > אסתר מרגולין, אחותו של קולונל מרגולין, מפקד הגדוד העברי, היתה
          מיילדת.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref23" id="ftnt23">[23]</a
        ><span class="c17 c10"> אחיה העיור של אסתר מרגולין.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref24" id="ftnt24">[24]</a
        ><span class="c17 c10"
          > אסתר היתה נערה יתומה שהופנתה אל נחמה פוחצ&#39;בסקי ע"י הג&#39;וינט
          בירושלים וזו שמשה לה אם אומנת. האומנה היתה ללא שכר אך פוחצ&#39;בסקי
          קיבלה החזר עבור הוצאות כספיות – לבגדים, לרפואה וכד&#39; – כפי שמסתבר
          מהמכתבים.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref25" id="ftnt25">[25]</a
        ><span class="c17 c10"
          > גב&#39; ברג&#39;ר בקשה מסבתא נחמה לטפל באסתר. כנראה לא שלמה עבור
          הוצאות החזקה, אך דאגה להוצאות מיוחדות – ביגוד, בריאות וכו&#39;. אסתר
          סיפרה שנים מאוחר יותר, שהשמלה שאפרת תפרה לה היתה השמלה החדשה הראשונה
          שהיתה לה מימיה.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref26" id="ftnt26">[26]</a
        ><span class="c17 c10"
          > רופא הגדוד העברי, ידיד של מרגולין, שביקר אתו בבית פוחצ&#39;בסקי.
          כשביקר בארץ בשנות ה-30 התארח בבית אפרת וצבי.
        </span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref27" id="ftnt27">[27]</a
        ><span class="c17 c10"> בד כותנה ארוג, דליל יחסית.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref28" id="ftnt28">[28]</a
        ><span class="c17 c10"> אחרי שובו מניתוח בצפת.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref29" id="ftnt29">[29]</a
        ><span class="c17 c10"> שפעת</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref30" id="ftnt30">[30]</a
        ><span class="c17 c10"
          > מרים לובמן ולוין קיפניס, שאכן נישאו אחר-כך.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref31" id="ftnt31">[31]</a
        ><span class="c17 c10"> ברנשטיין.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref32" id="ftnt32">[32]</a
        ><span class="c17 c10"
          > רחל פפר בת המורה ביפו חברה של ברנשטיין אבדה עצמה לדעת.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref33" id="ftnt33">[33]</a
        ><span class="c17 c10"> בלומה פיינשטיין, אם נחמה.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref34" id="ftnt34">[34]</a
        ><span class="c17 c10"> מבית החולים בצפת.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref35" id="ftnt35">[35]</a
        ><span class="c17 c10"
          > נראה שהכוונה לאירגון בין לאומי לזכויות נשים</span
        >
      </p>
      <p class="c7 c22" dir="rtl"><span class="c17 c10"></span></p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref36" id="ftnt36">[36]</a
        ><span class="c17 c10"> גרוש מצרי</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref37" id="ftnt37">[37]</a
        ><span class="c17 c10"> אסתר מרגולין</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref38" id="ftnt38">[38]</a
        ><span class="c17 c10"> דוד, אחיה העיור של אסתר.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref39" id="ftnt39">[39]</a
        ><span class="c17 c10"> נעמי לדיז&#39;נסקי</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref40" id="ftnt40">[40]</a
        ><span class="c17 c10"> משפחתה של בלומה באה מקרלסבד</span>
      </p>
    </div>
    <div>
      <p class="c39" dir="rtl">
        <a href="#ftnt_ref41" id="ftnt41">[41]</a
        ><span class="c19 c10 c41"
          > בני הזוג פוחצ&#39;בסקי שזה עתה נישאו פגשו את אחד העם באודסה בדרכם
          לארץ.</span
        >
      </p>
      <p class="c7 c22" dir="rtl"><span class="c17 c10"></span></p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref42" id="ftnt42">[42]</a
        ><span class="c17 c10"
          > לאה מאירוביץ, חברה של אפרת שהתחתנה עם בן-ציון אידלנט (אח"כ עיברתו את
          שמם לארבל).
        </span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref43" id="ftnt43">[43]</a
        ><span class="c17 c10"
          > הערת שולים במקור: "אם הוא יתקלקל כמו שהרבה דברים אחרים מתקלקלים אצלם
          – ד&#39; עמם! מידם יבא ההפסד.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref44" id="ftnt44">[44]</a
        ><span class="c17 c10"
          > אשתו של חיים פוחצ&#39;בסקי, אחי יחיאל מיכל. הם גרו בביירות והיו בעלי
          אכסנייה.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref45" id="ftnt45">[45]</a
        ><span class="c17 c10"> חנקה פיינשטיין</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref46" id="ftnt46">[46]</a
        ><span class="c17 c10"
          > בלומה פיינשטיין, אם נחמה, באה מקאלסבאד, ומשפחתה גרה שם.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref47" id="ftnt47">[47]</a
        ><span class="c17 c10"> צבי הגיע ארצה כחייל בגדוד העברי.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref48" id="ftnt48">[48]</a
        ><span class="c17 c10"
          > יצחק אפשטין, מורה עברית ובלשן, ידיד לפוחצ&#39;בסקים מאז עליתם
          ארצה.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref49" id="ftnt49">[49]</a
        ><span class="c17 c10"
          > היום בית המדרש למורים ע"ש דוד ילין בירושלים.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref50" id="ftnt50">[50]</a
        ><span class="c17 c10"> קיצוב, הקצבה.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref51" id="ftnt51">[51]</a
        ><span class="c17 c10"
          > מכון פסטר הוקם בירושלים ב-1913, והיה המקום היחידי בארץ שבו ניתן היה
          לקבל זריקות למניעת כלבת. ד"ר בהם ניהל את המכון.</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref52" id="ftnt52">[52]</a
        ><span class="c17 c10"> כנראה פתיליה.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref53" id="ftnt53">[53]</a
        ><span class="c17 c10"> נעמי פיינשטין, אח"כ לדיז&#39;נסקי.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref54" id="ftnt54">[54]</a
        ><span class="c17 c10"
          > נראה שהכוונה לכתבה "בימים ההם" שפורסמה בעיתון "התקןפה" בינואר
          1923</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref55" id="ftnt55">[55]</a
        ><span class="c17 c10"
          > אפרת סיפרה לנכדתה, שבתחילה גרו באזור יהודי חרדי. צבי, שלא היה דתי
          כלל, הפעיל את הגרמופון בשבת, ופתאום נשמעו בחוץ צעקות: "יודן, אס ברנט"
          (יהודים, שרפה!) צבי, שהיה נכון לעזרה תמיד, תפס דלי מים והלך לכבות את
          השרפה. כשהבין שה"שרפה" היא העובדה שניגן תקליט בשבת, כעס מאד, והעדיף
          לעבור לגור בשכונה ערבית ולא ליד יהודים דתיים.
        </span>
      </p>
      <p class="c7 c22" dir="rtl"><span class="c17 c10"></span></p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref56" id="ftnt56">[56]</a
        ><span class="c17 c10"> אפרת צדקה – כ"ט תמוז תרפ"ג היה יום שישי.</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref57" id="ftnt57">[57]</a
        ><span class="c17 c10"> זן של ענבים</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref58" id="ftnt58">[58]</a
        ><span class="c17 c10"
          > בג&#39; אלול תרפ"ג מלאו ליחיאל מיכל 60 שנה</span
        >
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref59" id="ftnt59">[59]</a
        ><span class="c17 c10">  צזר = יוליוס קיסר, ברוט = ברוטוס</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref60" id="ftnt60">[60]</a
        ><span class="c17 c10"> שקדים עם קליפה רכה</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref61" id="ftnt61">[61]</a
        ><span class="c17 c10"> תיכף ומיד</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref62" id="ftnt62">[62]</a
        ><span class="c17 c10"> חנה, אחות מיכל</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref63" id="ftnt63">[63]</a
        ><span class="c17 c10"> בת הדודה </span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref64" id="ftnt64">[64]</a
        ><span class="c17 c10"> אסתר מרגולין</span>
      </p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref65" id="ftnt65">[65]</a
        ><span class="c17 c10"> עין חי היא כפר מל"ל שהוקם ב 1912</span>
      </p>
      <p class="c7 c22" dir="rtl"><span class="c17 c10"></span></p>
    </div>
    <div>
      <p class="c7" dir="rtl">
        <a href="#ftnt_ref66" id="ftnt66">[66]</a
        ><span class="c17 c10"
          > כי יש כאן אחריות של כספי רבים, ועדה אולי לא בלי יסוד פוחדת מזה (הערת
          השולים במכתב עצמו).</span
        >
      </p>
    </div>
  </body>
</html>`;

export default page;
