const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c0" dir="rtl">
      <span class="c3 bold">דאר היום  21.12.1920</span><span>  –  </span
      ><span class="c1">תחת הכותרת</span><span> למנצח על השביתות </span
      ><span class="c1 c4"
        >מופיע פיליטון מאת  י. קר-ל בענין שביתה ביקב, הספר ביהודה החדשה מוזכר שם
        בחטף.</span
      >
    </p>
  </body>
</html>`;

export default modal;
