const page = `<html>
  <body class="c6 doc-content">
  <p class="c0 text-center" dir="rtl">
      <span class="c2">לאוהב אדם, לברזלי.</span>
    </p>
    <p class="c0 c8" dir="rtl">
      <span class="c2"
        >שמשוני פסע בצעדים אטים על פני החצץ בהפרדו מקציעה, ויבא עד משכנו, חדר
        נמוך וצר, הדומה במקצת לארוה, חלון אין לחדר ורק דרך האשנב שמעל לדלת חודר
        אליו אור היום, וכשנחנקים השוכנים בו מחסר אויר בלילה הם פותחים את האשנב
        מכוסה האבק ורוח דרומי קר וחודר מתפרץ אליהם בעזות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        שמשוני ירד שלש מדרגות ונכנס פנימה. אחד משני חבריו התהפך בגניחה
        מצד אל צד והשני ישן שנה כבדה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"> - מה אתך, משה, - מדוע אינך ישן עוד? </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"> - מעיפות יתרה, חברי! – עונה משה בתלונה – בתלחה </span
      ><span class="c1">[?]</span
      ><span class="c2"
        > חרשתי היום, רחוק זה, חביבי, ואויר רע מהחוליה שאפתי. כעת חש אני בראשי
        וכל עצמותי שבורות – האם לא סמן לקדחת זה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        > - אל תחשש משה, - אויר המקום הזה אינו נותן ליתושים הרעים לשלט בפועלינו
        לרעה, כאן גם אנו מובטחים באחריות מפני מציק זה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        משה הרים את ראשו; תמה הוא שנעימה העליזה שבקול חברו היא לא
        התאימה לפניו הרזים והחורים עם עינים גדולות המפיקות תוגה. אחוז שנה הוא
        סקר קצת את חברו, התנועע, צנח על כרו ונרדם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        מאֻשר הנער, דמו נקי מחידקי היאוש שתקף את רוב חברינו! – מחליט
        שמשוני בחלצו את מגפיו הכבדים. כבה את המנורה ושכב על לוחות עץ המונחים על
        תבות גז אצל קיר לח וצונן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        בתוך אפלולית החדר מבקש שמשוני את דמות קציעה. – לכאורה הינה יפה
        הילדה, גם מפותחה איננה! – ענין זה, לילידת מושבה עם בית ספר דל ונמוך? אין
        להאשימה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        רשמי הערב הזה עוברים לפניו. קולה הנעים של קציעה צלצל באזניו וכל
        תבניתה הגמישה, עם תנועותיה הזריזות בצבצה כאן עטופה אפלולית. זרם חם עובר
        בכל גופו והוא מתמלא געגועים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        מה זה? – שואל הוא את נפשו בתמיהה – היתאהב שנית ואת מקום אותה
        המכללית השויצית, שחִלוק הדעות הרחיקהו ממנה, תקח עתה בת אכר גלילי, ילדה
        פשוטה, תמה, נחמדה בטבעה כטבע הגליל עצמו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        אבל הלאה, הלאה שטותים, הלאה חלומות – מהרהר הוא אילך -, אדם שכל
        עולמו הפנימי נחרב ונשבר לרסיסים, ושתוחלתו להשכיח את יאושו בעמל העבודה גם
        היא נכזבה; אדם כזה אסור לו לקשר את חייו עם נפש תמה של ילדה כזאת!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >        וכאן הוא מוכיח את עצמו למדי, הראה לילדה פנים צוחקות במשך כל
        הערב; למה הרבה במהתלות? איזו הונאה – להראות עליז, ובלב עלוקות מוצצות
        דמך, העולם חשך בעדך!!.. הוי שקר, שקר עולמי, תולדתי </span
      ><span class="c1">[תולדותַי?]</span
      ><span class="c2"
        > – הבריות משנות את עורן וטבען למען הדמות לסביבה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        אבל האם תדיר היה מיואש? הרי לפני שני חדשים כשהדפיס את מאמרו ע"ד
        גאולת הארץ האמין באמת ובלב תמים שאפשרית היא הגאולה, אפשרית!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        קטעים אחדים ממאמרו עוברים במוחו: - רכישת קרקע, זו העבודה העקרית
        –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        מי יעשנה? . . . . לא העם, לא שום חברה ישובית לע"ע, זולת יק"א,
        יעשו אותה! לפי שעה רק היא מוכשרת לכך, היא, היא! ולמרות רצונה תעשה את
        עבודת העם; היא שמחויבת לעשותה אחרי הנסיונות האי מוצלחים בקנדה, ארגנטינה
        ועוד; מחויבת להכיר שרק הקרקע שבכאן יכשר למטרותיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        ופקידיה? . . . . הם ברצונם או שלא ברצונם יסייעו לזה, - כי אם לא
        יוסיפו לרכש קרקעות, הרי אפסה עבודתם, אפסה מחיתם, ורק ע"י יסוד מושבות
        חדשות הם נשארים איתן על עמדתם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        פקידי יק"א – בוני ציון! . . . . וצעירינו אכולי היאוש, המבקרים,
        המנקרים, המחטטים, פושטים ידם לעצר בגלגל התנועה! – בתי כפר ממדרגה עליונה
        לא הגיעה שעתם! קרקעות ביוקר, מוסדות – מעצורים על דרכם! – לחכות צריכים,
        לחכות!. . . ומחלה זו, אי האמונה בעבודתנו בצרוף היאוש, פשטה ביסודה עד
        הגליל, פשטה והכתה שרשים עמוקים- מה לעשות כדי לרפא את הנגע, במה
        לעצרהו!</span
      >
    </p>
    <p class="c5 text-center" dir="rtl"><span class="c2">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        וכשנרדם שמשוני נִשא לעולם אחר – הריהו חורש בהמרג&#39;. הכפה
        התכולה, הזכה, זרועה עבי שיש צחים; שם במזרח תלוים העננים וכנפיהם – אש
        בוערת, לוהטת. השמש נטתה מערבה וקרני פז האחרונים שלה מטפסות לראש החרמון,
        - שלחתן החמה לטול ברכת הפרידה מאת הסבא בהרים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        פרדות שמשוני עיפות ואינן רוצות יותר למשוך את המחרשה הכבדה.
        צליפה קלה הוא מצליף על גבן וקורא בקול מפייס:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"> - יאללה, אפורותי, יאלללה – רק תלם זה נגמר וחסל!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >        האפורות אינן מצייתות והוא עומד נבוך, כאן הוא רואה פתאם את קציעה
        יורדת אל המרג&#39;, נגשת אל התלם ואֹחזת אִתו ביד המחרשה. שניהם דחפוה
        והפרדות זזו בצ</span
      ><span class="c1">[ע]</span><span class="c2">ד ישר קדימה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        > - הביטה נא וראה איזה תלם ישר, עמֹק יצא לך בעזרתי! – אומרת קציעה
        בהראותה באצבעה הדקה על קצה המענה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        הוא תפס אצבע זו בידו החמה וכסה אותה נשיקות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >        הקיץ. . . את דממת הליל הפריעה קריאת התרנגול השוכן מעבר לגדר </span
      ><span class="c1">[?]</span
      ><span class="c2"
        >. אחריו ענו בקול צלול וחד גוני מכל הלולים שלארך המושבה: קוקו-ריקו. כמה
        יפה נראתה לו שפת תרנגולים זו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c4">                                </span
      ><span class="c3">נחמה פוחצבסקי.</span>
    </p>
    <p class="c0 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c0 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c0 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c0 c7" dir="rtl"><span class="c2"></span></p>
  </body>
</html>`;

export default page;
