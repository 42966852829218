const modal = `<body class='c2 doc-content'>
  <p class='c4' dir='rtl'>
    <span class='c6'>
      המלים הבאות מופיעות על גלויה ממוענת ל"אחיעבר" בניו יורק. המילים כתובות
      ברצף וכשנגמר המקום על הגלויה הן עוברות כנחש בהיקף הגלויה. [הגלויה נמצאת
      בארכיון אגודת הסופרים בבית אריאלה]
    </span>
  </p>
  <p class='c3' dir='rtl'>
    <span class='c0'></span>
  </p>
  <p class='c4' dir='rtl'>
    <span class='c0'>רשל"צ חוהמ"ס התרע"ב</span>
  </p>
  <p class='c1 c5' dir='rtl'>
    <span class='c0'>אגודה כבודה</span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c0'>
              אם יש באפשרותכם וברצונכם לטפל בהפצת ספרי, שדוגמא ממנו שלחתי לכם
      היום – אהיה מכרת תודה רבה.
    </span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c0'>
              את הקבץ הזה – "ביהודה החדשה" – הוצאתי על חשבוני וכל רצוני הוא רק
      לא להפסיד בו – קשה לאכרה הפסד ממון.
    </span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c0'>
              רוצה אני לקוות כי אחי העברים יבאו לי לעזרה רוחנית למען שאוכל
      להמשיך את עבודתי הספרותית, המבצבצת מתוך עבודה חקלאית.
    </span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c0'>                בברכת ציון  נחמה פוחצבסקי.</span>
  </p>
</body>`;

export default modal;
