const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c3" dir="rtl">
      <span class="c0">מתוך </span><span class="c1 bold">ביהודה החדשה</span>
      <span class="c4">.</span><span class="c0"> כתיבת הסיפור הושפעה </span>
        <span class="c0 c7 tooltip">
          <a href="/herArticles/onIsraelLand">ממותו הטרגי של בנימין פיין</a>
          <span class="c1 tooltiptext">ראו גם הספד לבנימין פיין שנחמה פוחצ&#39;בסקי פרסמה ב&#39;העולם&#39;</span></span>        
      <span class="c0">.</span>                
    </p>
  </body>
</html>`;

export default desc;
