const page = `<body class="c7 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c0"
      >נחמה פוחצ&#39;בסקי נהגה לעזוב את ביתה לתקופות קצרות ולגור במקום בו תוכל
      להתרכז בכתיבה ללא הסחות הדעת והאילוצים של החיים בסביבתה הרגילה. נראה
      שהמכתבים שלהלן נכתבו בתקופות כאלה. </span
    ><span class="c8 bold">גדעון</span
    ><span class="c1 c0"
      > היה בנם הבכור של אפרת וצבי. בזמן כתיבת שני המכתבים מתרפ"ז אפרת היתה
      בהריון מתקדם עם בתם רות.</span
    >
  </p>
  <br />

  <a href="#yomVavKeslav">
    <p class="c2" dir="rtl">
      <span class="c4 c3">יום ו&#39;  כסלו תרפ"ז</span>
    </p>
  </a>
  <a href="#tetKeslav">
    <p class="c2" dir="rtl">
      <span class="c4 c3">ט&#39; כסלו תרפ"ז</span>
    </p>
  </a>
  <a href="#kafZainElul">
    <p class="c2" dir="rtl">
      <span class="c4 c3">כ"ז אלול   [1933]</span>
    </p>
  </a>
  <a href="#tetElul">
    <p class="c2" dir="rtl">
      <span class="c6">ט&#39; אלול</span
      ><span class="c0 c6 italic">[השנה לא קריאה]</span>
    </p>
  </a>
  <br />
  <br />

  <p class="c2" dir="rtl" id="yomVavKeslav">
    <span class="c1 c3"
      >יום ו&#39;</span
    >
  </p>
  <p class="c2 text-end">
    <span class="c1 c3" style="margin-left: 60px"
      >לזכרון</span
    >
  </p>
  <p class="c2 text-end" dir="rtl">
    <span
      >ראשל"צ כסלו תרפ"ז
      [</span
    ><span class="c0 italic">דצמבר 1926</span><span class="c1 c3">]</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">אמא חביבה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אני מתפלאה מאד שאין מכתב ממך כל השבוע, הסבתה כבר התחילה כרגיל לדאג לך.
      אני אמנם הרגעתי אותה לעת-עתה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אין לי הפעם הרבה מה להוסיף על כל מה שכותבים האחרים, אך פטור בלא כלום אי
      אפשר. איך את מרגישה את עצמך שם? המצאת אמנם את המנוחה הדרושה והרצויה?
      השתדלי לנוח כמה שאפשר יותר, הן לא בנקל עלה לך להשתחרר מבית-כלאך.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >בבית הכל מסודר, לרוזה אין עבודה יתרה כי הכל מתנהל למישרים. את אבא אני
      מבקשת לבא לעשות אמבטיאות בכל יומים ובקשי זה עולה בידי. הלא יודעת את בכמה
      אי רצון הוא מסכים לזה, מטעם אחד – שזה לא מועיל. בכל-זאת אחרי הפצרות רבות
      הוא בא ומבלה פה את שארית היום לאשרו ושמחתו של גדעון. גדעון בימים האחרונים
      התקשר מאד גם לצבי אולי מפני שבלילה הוא נמצא ברשותו המחלטת. אני איני יורדת
      אליו, למרות זה שהוא אינו ישן כ"כ במנוחה מה-היא הסבה איני יודעת, כי חוץ מזה
      הוא בריא ואינו סובל מכלום.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אני ממהרת לגמר כי עוד יש לי מעט עבודה במטבח, אנו מחכים לשבת זו לשני אחים,
      צעירים שזה רק באו מאמריקה, חברים של צבי. האחד מהם בא בשם "אגודת הפועלים
      הלאומית" בשביל סדור אחריות-חיים לפועלים.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">שלום רב למשפחת יאפו</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">ונשיקות לך מכלנו</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">שלך אפרת</span>
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="tetKeslav">
    <span>רשל&#39;צ ט&#39; כסלו תרפ"ז [</span
    ><span class="c0 italic">דצמבר 1926</span
    ><span class="c1 c3">]</span>
  </p>
  <p class="c2 text-end">
    <span class="c1 c3" style="margin-left: 60px">אפרת לנחלה     </span>
  </p>
  <p class="c2 text-end" dir="rtl">
    <span class="c1 c3"
      >ראשל"צ לזכרון-יעקב</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">אמא חביבה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >תסלחי לי על כתבי בעפרון, זה אמנם לא כ"כ נמוסי אך היות וצבי עסוק בכתיבה
      והעט אצלו אנוסה הייתי להשתמש בעפרון ואתך והסליחה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >את מכתבך קבלנו, אך לא הספקתי לענות אליו קודם, הלא את יודעת איך נסחפים
      בזרם העבודה הביתית המפריעה לפעמים בעד מלוי חובות הכרחיים אף הם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >הצעירים שחכינו להם אז לשבת לא באו מפני איזו אספה שהיתה להם באותה שבת
      בירושלים, ועד היום עוד לא באו הנה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אתמול הפתיעה אותי רחל קומרוב בבקור, באה לשעות אחדות יחד עם עוד צעירה
      אמריקאית אחות רחמנית. הן עבדו שם בבית חולים אחד ובאו יחד באניה. זוהי בת
      הלפנד מרמת-גן חתנו של הורביץ נלסון, בחורה נעימה ועושה רושם של רצינית
      ושקטה. היא רצתה לעבוד ב"הדסה" בהביאה מכתב המלצה טוב מאת הגב&#39; סולד אך
      היות ואין לה ידיעות מספיקות בשפה העברית אינה יכולה להתקבל עתה, והיא אנוסה
      לשבת ללמד את השפה ורק אז תראה אם תוכל להשיג עבודה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >פני רחל די טובים, היא לא הזדקנה לגמרי ומלאה חיים ומרץ. שמחתי כמובן מאד
      ומאד לראותה ובלינו יחד את חצי היום. היא מצאה שאני לא השתניתי לגמרי. את צבי
      הכירה לפי התמונה שאצלה. סדור ביתנו אף הוא מצא מאד חן בעיניה ואמרה שעתה אני
      צריכה לסדר לה את חדרה. היא קבלה משרה בירושלים במכללה העברית תחת ידו של ד"ר
      קלוגלר. המשכרת אמנם אינה משביעה רצון 10 לי"מ לחדש אך היות ואין כל משרה
      אחרת הסכימה לזו והיא כבר הבקר היתה צריכה לנסע ירושלימה להכנס לעבודה. עוד
      קשי יש במשרה זו, ד"ר קלוגלר זה הוא אדם לא אהוד ורע מאד. העבודה אצלו - - -
       ואי נעימה. רב אלו שעבדו אצלו עד היום הלכו ממנו באי שביעת רצון גמורה. רחל
      כמובן לא תרצה להכנע ואיני יודעת אם תוכל זמן רב לעבד אצלו.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אני מרגישה את עצמי לא רע - - - איני מרגישה כבד והכל בסדר. - - - עוד לא
      הוטב בהרבה ומבלבל את שנתי בלילות.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >נו סו"ס התחתנה שושנה פדובה מזל טוב. האם שבו כבר מרים ואמה הביתה? דרישת
      שלום וברכה לכלם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >נשיקות חמות לך מכלנו. גדעון כפי הנראה מתגעגע אליך כי הוא מזכיר תמיד
      שהסבתה נסעה לזכרון יעקב.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">שלך באהבה אפרת</span>
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="kafZainElul">
    <span>כ"ז אלול     [</span><span class="c0 italic">1933</span
    ><span class="c1 c3">]</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">אמא יקרה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >הייתי מאד רוצה לגרום לך אתו הענג שתקבלי מכתבים מאתנו פעמים בשבוע, אך קשה
      להוציא זאת לפועל מחסר פנאי ומחסר חמר. וכי מה אפשר לכתב? חדשות אין ולפטפט
      סתם אין זה מדרכנו.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אבא היה אצלנו שבוע ימים והבריא יפה. מאתמול החל שוב ללכת הביתה ללון, כי שם
      אומר הוא שנתו יותר עמוקה. אם הוא ישמר גם להבא על סדר חייו הנכחי, אז יש
      תקוה שלא יסבל. אכילתו דורשת השגחה מעולה והזלזול או הטעות הכי קלים משתלמים
      ביוקר. אין לך לנזף בו על הטילו את עלו על אחרים, כי אין אני יכולה להקרא
      "אחרים" וכעת אני כמעט היחידה המטפלת בו. נכחתי שאם הטפול נעשה ע"י אדם אחד,
      הוא יותר מצלח. לא בנקל עלה לי לפתות אותו לעבר לרשותי ואל לך לקלקל לי
      זאת.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אי אפשר לתת לו עתה להכין בעצמו את ארוחותיו, כי צריך לגונן יותר מקודם
      ולדקדק יותר בטיבן ובהכנתן. מאכלי החלב צריכים לעמד תמיד על הקרח (וזאת אין
      לו בבית) כי אם לא כך הם מחמיצים יותר על המדה וחמצה יש לו בלאו-הכי יותר
      מדי. זאת הוכיחה הבדיקה של הרופא. אין אנו מפנקות אותו ואין הוא מתפנק חלילה
      אלא שהמעים בני ה70 הם המפנקים ורק אתם יש להתחשב הפעם. אני חושבת שגם
      בריאותך את דורשת קצת פנוק וכדאי לתת לה זאת מבלי לחשש חו"ש שהפנוק יקלקל את
      הילדים ויזיק לעתידם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >ברכות לשנה החדשה שלוחות לך מכלנו, צבי מוסר יפוי כח לדרש בשלומך בשמו, אין
      לו פנאי לכתב.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">שלך אפרת</span>
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="tetElul">
    <span>ט&#39; אלול         </span
    ><span class="c0 italic">[השנה לא קריאה]</span
    ><span class="c1 c3">                                        </span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">אמא יקרה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אבא מבטיח לך שאנו נלשין עליו ובאמת זה מיותר, את כבר מכירה אותו כ"כ טוב
      שאין צרך להוסיף.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >תסלחי על שלא כתבנו לך עד היום, סתם מתוך טרדות יום יום, אנו בריאים כלנו.
      הילדים מתרחצים יום יום בים ומשחירים יותר ויותר ואני חוששת שלא תכירי אותם
      כשתשובי. נסיעתם מועילה גם לי ואני מספיקה לנוח ע"י כך. בביתנו מהפכה הגונה,
      אבל לא קשה לסבל אותה בדעתנו שהיא מובילה לנוחיות. אני מקוה שאחרי התקון יהיה
      יותר טוב אצלנו.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      > היינו מאד רוצים לדעת איך את מרגישה את עצמך. והאם לא נקפאתן עדיין? מזג
      האויר כאן ג"כ השתנה בהרבה ורוחות הסתיו מנשבות כבר. בכ"ז אני חושבת שהילדים
      ימשיכו להתרחץ עד ראש-השנה, כי לפני הצהרים די חמים והם מתעמלים שם הרבה, כך
      אין הרוח שולטת בהם הרבה. את עדה שחררנו מעלו של אבא, יש לה גם בלאו הכי
      אורחים תמיד והיא עיפה מאד. אנחנו מטפלות באבא בשותפות אסתר ואני.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אצל הדודה בתל אביב עוד לא הייתי אך צבי היה אצל רחל ואנחנו יודעים מזה שהן
      מתהלכות עדיין ומתעגלות.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">שלום רב לברכה בן-ברוך.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3"
      >אנו מאחלים לשתיכן הבראה שלמה. עליכן ליצור שם טוב לניצה
      הארצי-ישראלית.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1 c3">שלך אפרת</span>
  </p>
</body>`;

export default page;
