const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c15 doc-content">
    <!-- <p class="c0" dir="rtl">
      <span class="c4">על דרכי הבית</span>
    </p> -->
    <p class="c0" dir="rtl">
      <a href="#theHome">
        <span class="c3">הבית</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#theFamily">
        <span class="c3">המשפחה</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#theGuests">
        <span class="c3">דרי הבית</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#theComings">
        <span class="c3">באי הבית</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#theAdopteds">
        <span class="c3">הנעזרים והמאומצים</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <span class="c11 c8 c13 small-text">אפרת בן כהן</span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >בית פוחצ&#39;בסקי שימש מלון, בית הבראה, פנסיון, בית משפט, בית חנוך
        ליתומות ומשפחה אומנת. הבית היה תמיד פתוח, ואנשים באו לבקש עזרה ולהתייעץ,
        הן במצוקות כספיות והן מצוקות נפשיות.
      </span>
    </p>
    <h3 class="c0" dir="rtl" id="theHome">
      <span class="c1">הבית</span>
    </h3>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ביתינו היה בית אבן שאבי בנה במו ידיו. היו בכל הבית בעצם שני חדרים. אחר
        כך מרפסת שסגרנו הפכה להיות חדר אוכל, מטבח, ועוד חדר עבודה של אבא – זה
        הכל מה שהיה שם, במספר חדרים, אבל הם היו גדולים.</span
      >
    </p>
    <h3 class="c0" dir="rtl" id="theFamily">
      <span class="c1">המשפחה</span>
    </h3>
    <p class="c0" dir="rtl">
      <span class="c2"
        >בית הורי היה בית מיוחד במינו. קודם כל הוא היה אחד הבתים הראשונים,
        ובשעתו היחידים בארץ ששפת הבית היתה עברית. לא היו בתים רבים כאלה. זה היה
        ייחוד הראשון. הדבר השני, שהורי היו שניהם אנשי תרבות, אנשי ספרות. אמא
        היתה גם עסקנית ציבורית בחסד עליון. אבי לא אהב עסקנות. אבי היה איש
        העבודה. אבל בו השתלבו שתי התכונות של ספרא וסייפא, המעדר והעט. הוא היה
        חקלאי מופלג. והוא היה אדם שידע לעשות הכל, אם זה בשטח הבניה, אם זה בשטח
        המכניקה, טכניקה, נגרות, סנדלרות – הכל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >היינו רק שני ילדים בבית, אחי ז"ל ואני. אחי היה גדול ממני ב 5 שנים. הוא
        התחתן בגיל צעיר. בן 21  כבר יצא מהבית. זאת אומרת שבגיל 16 נשארתי כבר בת
        יחידה לגמרי. ומי מכם שהוא בן יחיד אולי יכול להבין מה זה נקרא להיות יחידה
        בבית. לא פינקו אותי, לא. אבל היה קצת יותר מדי שקט בבית בשבילי. הורי היו
        תמיד עסוקים, אבא בעבודה מחוץ לבית או בחדר המלאכה שלו, אמא במשק בית
        ובכתיבה, היתה סופרת. אני גם כן תמיד עבדתי, אבל יש לי הרגשה שבבית כמעט
        שלא דברו, אינני זוכרת שבבית דיברו סתם, דיבורי סרק, רכילות אין מה לדבר!
        זה היה טָבו, אבל גם שיחות אחרות, לא היה פנאי לדבר, תמיד היו עסוקים בבית.
        לא חושבת שלא היה על מה לדבר, אבל פשוט לא היתה הזדמנות לדבר, היו תמיד
        עסוקים.</span
      >
    </p>
    <p class="c0 c7" dir="rtl"><span class="c1"></span></p>
    <h3 class="c0" dir="rtl" id="theGuests">
      <span class="c1">דרי הבית</span>
    </h3>
    <p class="c0" dir="rtl">
      <span class="c2"
        >בבית כמובן היתה עזרה. לא מפני שהיינו עשירים, אלא כיוון שהיה צריך לטפל
        בהרבה אנשים. קודם עבדו ערביות, אחר כך תימניות. אמא דגלה אך ורק בעבודה
        עברית. פועלים שעבדו בכרם היו כבני בית.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >המצב הכלכלי תמיד היה קשה. אבא עבד בהתנדבות יותר מאשר לפרנסה. בחוץ-לארץ
        אמא למדה צילום, והביאה לארץ את כל המכשירים אבל אז זו לא היתה פרנסה. אז
        נשים לא יצאו לעבוד. אז לאיזון התקציב היא הפכה את הבית לפרנסה. אמא החזיקה
        בבית פנסיונרים.  הפנסיונרים אז היו על פי רוב נוער ציוני גרמני שלא הסתדר
        עם ההורים הדתיים הלא-ציונים שלו או צעירים ממשפחות ציוניות בגרמניה. תנועת
        הנוער החליטה להביא נוער זה ארצה, ולהשכין אותו עם משפחות של חקלאים דוברות
        עברית. בדרך זו יכלו ללמוד גם עברית וגם חקלאות. המוסדות בארץ קיוו שעל ידי
        כך ישפיעו הבנים על ההורים, וגם הם יעזרו לישוב כאן או יקנו אדמות. הם
        הופנו לבית פוחצ&#39;בסקי ע"י רופין, שקרא לבית "האוניברסיטה העברית
        הראשונה". הם עבדו עם אבי ולמדו ממנו חקלאות, ואילו עברית למדו מפי אמא או
        מפי (ביניהם היתה אסתר, לימים עגנון, ואשת שר המשפטים לשעבר רוזן). היו עוד
        בתים כמו שלנו שלקחו נערים לבתיהם. בדרך כלל היו שנים-שלושה נערים. היה לנו
        בית גדול, אבל מעט חדרים, אז באותו חדר התחלק אחי עם הסטודנטים. עם פרוץ
        מלחמת העולם הראשונה, הרגישו בחורים אלה רגש פטריוטי לגרמניה והחליטו לחזור
        לשם ולהצטרף לצבא. הקונסול הגרמני בארץ סרב להחזיר אותם כדי שלא יסתכנו, אז
        הם לקחו אופניים ודרך טורקיה רכבו לגרמניה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >האחיין יונדב וכן אליעזר בן ידידתה גולדה יאפו גרו בבית כמה שנים ולמדו
        בבית הספר במושבה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c9">הסבתא בלומה  </span
      ><span class="c9 c6 italic">[אמה של נחמה]</span
      ><span class="c2"
        > אחרי שהתאלמנה עברה לגור בביתנו וחיה שם כמה עשרות שנים עד מותה בגיל 93.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c9">אסתר מרגולין </span
      ><span class="c9 c6 italic"
        >[אחותו של הקולונל מרגולין מפקד הגדוד העברי]</span
      ><span class="c2"
        >, מיילדת ברחובות וחברה מאד קרובה של אמא, נסעה לאירופה לניתוח ב-1914.
        היא שכרה חדר בראשון לאחיה העיוור דוד שהיה חי אתה. בביתנו היו רק שני
        חדרים ולא היתה אפשרות לתת פרטיות לאורח, אך את כל היום בילה דוד בביתנו.
        המלחמה פרצה, אסתר לא יכולה לחזור ארצה, ודוד נשאר בטפולנו כל שנות
        המלחמה.</span
      >
    </p>
    <p class="c0 c7" dir="rtl" id="h.gjdgxs">
      <span class="c11 c13 c17"></span>
    </p>
    <h3 class="c0" dir="rtl" id="theComings">
      <span class="c1">באי הבית</span>
    </h3>
    <p class="c0 italic" dir="rtl">
      <span class="c9 c6">מתוך ספרה של שרה עזריהו, </span
      ><span class="c14 c9 c6 bold">עליתי ארצה, פרקי חיים</span
      ><span class="c9 c6 c11">, עמ&#39; 66-65 , הוצאת ניומן ת"א תשי"ז:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >באחד מביקורי בראשון לציון נפגשתי עם האיכרה נחמה פוחצ&#39;בסקי. זו היתה
        דמות טיפוסית של איכרה עברית חלוצית, ששימשה בזמנה דוגמה ומופת בעניני
        הנהלת משק בית כפרי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        האוירה המלבבת ששררה בביתה המרווח, הפשוט והצנוע, אבל שופע אור,
        צמחים ופרחים, היתה אופפת כל אדם שהיה עובר את סף ביתה. מאור פניה וחיוכה
        החם, שבהם היא היתה מקדמת פני כל איש, העידו על תרבות ונפש יפה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c8 small-text">אפרת בן כהן</span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >בית אמי היה פתוח לאורחים, ותמיד המה מאדם. אמא לא הזמינה במיוחד, אך כולם
        הגיעו: האנשים שטיפלה בהם, הפועלים שעבדו בכרם, פועלים שעבדו במושבה, מורים
        וסופרים מהמושבה ומחוצה לה, סופרים אנשי רוח ואנשי במה שהגיעו מרוסיה.
        ביאליק, שניאור, תאומי ורבים רבים אחרים. באו גם קבוצת פועלים אינטלקטואלים
        – לוי אשכול, מרדכי ברנשטיין, טרוצקי, אהוליאב, הפט, כולם הגיעו לבית. בין
        ידידי הבית היו יעקב רבינוביץ, יוסף ויתקין, משה סמילנסקי, הנרייטה סאלד.
        כל תייר שהגיע ארצה ביקורו לא היה שלם עד שהגיע גם לבית
        פוחצ&#39;בסקי.</span
      >
    </p>
    <p class="c5 small-text" dir="rtl">
      <span>אליעזר יאפו </span><span class="c6">[אמו, גולדה יאפו היתה </span>

      <a href="/letters/toGoldaYafo">
        <span class="c6 c12 tooltip"
          >חברה קרובה
          <span class="c14 tooltiptext">
            ראו מכתבים של נחמה לגולדה</span
          >
        </span></a><span class="c6"> של נחמה פוחצ&#39;בסקי]</span>
    </p>
    <p class="c5" dir="rtl">
      <span> </span
      ><span class="c2"
        >כל העובר בראשון היה נכנס לבית. גם מנהיגי מפלגות הפועלים שפרינצק, יוסף
        אהרונוביץ, סופרים שונים: יעקב רבינוביץ, ברש, אזר, אלישבע. רבים נשארו
        ללון במרפסת... הכירה את כל המורים. כולם באו לבית וישבו שעות ושוחחו. דברו
        עברית צחה. מילים לועזיות היו טאבו.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c10 small-text">אפרת בן כהן</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">הבית היה בית ועד. אני זוכרת אסיפות בבית, ופגישות.</span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >אנשי רוח וסתם עמך התקבלו באותה לבביות, אנשים מכובדים והעוזרת הקטנה ישבו
        יחד ליד השולחן. לא הזמינו לארוחות, אך מי שבא היתה אמא מקבלת ב: "ש-לו-ם"
        ארוך ומתנגן, "מ-ה שלומ-ך?" ואחר כך הוזמנו לשבת לאכול ולשתות, ואם באו
        מחוץ לעיר – גם לישון. כולם התקבלו בסבר פנים יפות ובחום.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >מלון לא היה בראשון. כל מי שבא למושבה וביקש ללון הלך או לבית לובמן או
        לבית פוחצ&#39;בסקי. מי שהופיע נשאר לאכול ולישון. איפה ישנו? בכל מקום: על
        מיטה, על ספה, על שולחן, על ספסל ועל הרצפה שפרסו עליה שמיכה או מחצלת.
        תמיד הייתי עסוקה בהצעת מיטות.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >בבית דיברו רק עברית. זה היה אחד הבתים הראשונים במושבות שהתנהלו על טהרת
        העברית. יעקב רבינוביץ פעם אמר לי: את מדברת עברית כמו בתנ"ך.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >לשונה של אמא היתה תמיד נקיה, אף פעם לא הרימה קול, אף פעם לא הוציאה מלות
        כעס. הקפידו על דבור נאה ומנומס. אף פעם לא היו מריבות, רכילות או השמצות.
        קללות מעולם לא השמיעו. פעם שמעה אמא חברה שלי קוראת למישהו "חמור", ואסרה
        עליה להכנס הביתה.
      </span>
    </p>
    <p class="c5 c7" dir="rtl"><span class="c2"></span></p>
    <h3 class="c0" dir="rtl" id="theAdopteds">
      <span class="c1">הנעזרים והמאומצים</span>
    </h3>
    <p class="c5" dir="rtl">
      <span class="c9"
        >למרות כל העסקנות הרבה, כל הועדות שהשתתפה בהם ואגודות שהקימה הרגישה אמא
        תמיד צורך וחובה לעזור גם לפרטים. לכל אחד שנקרה בדרכה והיה זקוק
        לעזרה.</span
      ><span class="c16"> </span><span class="c10"> </span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >בבית היה ארון מלא מכשירים רפואיים – מודדי חום, חוקן, מוצצי חלב,
        שעווניות, כוסות רוח ועוד. כל מי שנזקק בא, הביא משכון כל שהוא – מזלג כסף
        חסר שן, צלחת סדוקה – וקיבל את מבוקשו. כשאמא לא היתה בבית חיכו בגינה. פעם
        סופר לי שאמא דרשה שיבקשו את מה שצריכים בעברית. אי לכך, מי שלא ידע עברית
        ישב בגינה ולמד את המילים הנדרשות לבקשתו, ואז נכנס.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >הבית שימש גם בית הבראה. מקובל היה שהחלפת אויר תרופה בדוקה היא לכל מיני
        חוליים. מישהו חלה בקצה האחד של המושבה, שלחוהו לבית פוחצ&#39;בסקי בקצה
        השני של המושבה להבריא, וכמובן אנשים מחוץ לעיר באו להבריא בבית, במיוחד
        חולי מלריה.</span
      >
    </p>
    <p class="c5" dir="rtl">
      <span class="c2"
        >הבית שימש גם כ"משפחה אומנת". ילדות יתומות נשלחו לבית ע"י גב&#39;
        ברגר-מול נציגת הג&#39;וינט בארץ. הן שהו בבית תקופות קצרות עד לקליטתן
        במוסד במאיר שפיה, ואחר כך התארחו בבית בחופשות. היו שנשארו תקופות ארוכות
        יותר, ואפילו כמה שנים. אמא קבלה מהג&#39;וינט רק לכסוי ההוצאות המיוחדות
        כגון הטפול הרפואי.  </span
      >
    </p>
  </body>
</html>`;

export default page;
