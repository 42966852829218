const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c1 doc-content">
    <p class="c3" dir="rtl">
      <span class="c7 bold">האור</span
      ><span class="c2"> ט&#39; שבט תרעב  [1912 01 28]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c6">הספד ליוסף ויתקין</span>
    </p>
    <p class="c0" dir="rtl"><span class="c4"></span></p>
  </body>
</html>
`;

export default desc;
