const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c10 doc-content">
  <p class="c6 text-center" dir="rtl">
      <span class="c2">(קטעי סיפור)</span>
    </p>  
  <p class="c6" dir="rtl">
      <span class="c2">והיא שבה.</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >שוב רואה ידידה את אותם שמי הזהר שכה אהבה, את החולות, ההרים והגבעות, את
        הכרמים עם שדות הבר – הכל הכל כמקדם! ועיניה הגדולות עם מבטיהן החמימות
        מתיזות מיצוצי אושר.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >טל נער בלחיה הורדה וחם בחרות בגופה הזקוף והרענן; והיא במטבח, - כל היום
        מטפלת בעבודת המטבח של הפועלים. שבעים איש! למזון זול ומבריא דואגת ידידה
        והעבודה מרובה כל הימים.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >לילה! – לידידה אין פנאי לקחת חלק באספות הפועלים ובוכוחיהם. מתלהבים הם
        ורועשים – כבוש עבודה, יחס הסביבה, השפעה כלפי פנים וכלפי חוץ... ידידה
        מסתלקת – לה מחכה עבודה: זרח או בריל קודח! בידים חרוצות היא מהפכת את משכב
        החולה, מגישה לו את הכסתות, החלב; מחליפה את התחבשות.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >וסדור הכביסה של החברים אימתי, אם לא בלילה? וטליאת בגדיהם? ורשימת צרכי
        המזונות שדרוש מחר להביא מן העיר?</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >וחורו פני ידידה, לחייה צמקו, ברק העינים היפות הועם ועליזות רוחה חמקה
        עברה לה.</span
      >
    </p>
    <ul class="c5 lst-kix_list_1-0 start">
      <li class="c0" dir="rtl">
        <span class="c2"
          >גלילה, גלילה! – והיא על שפת ים יפו יושבת שקועה... גדול ואודם כדור
          האש, שנוגע בשפתו הלוהטת בגלי האפק הקרים. לאט לאט הוא צולל תהומות,
          ונחלי ארגמן מתפשטים ברקיע, ושלהבת אוחזת את כנפי העבים.
        </span>
      </li>
      <li class="c12 li-bullet-0" dir="rtl">
        <span class="c2"
          >מה כה שקועה בהרהורים ידידה? – שואלים שני החברים היושבים על צדה.</span
        >
      </li>
    </ul>
    <p class="c3 c7" dir="rtl">
      <span class="c2"
        >וידידה מתלהבת – כרמל... תבור... הרי הגליל ומושבותיו!... שם היא תמצא
        בודאי ספוק נפשי. תיסד אגדה של פועלות; גנים </span
      ><span class="c11">[?]</span
      ><span class="c2"
        > ; מושבות תיסדנה; ענף חדש וגדול יברא!... גלילה גלילה, - שם רק שם שדה
        לעבודה רצינית.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        מקשיבים דבריה הגלים הנוזלים אט אל החוף. בקצף לשונם מלחכים הם את
        החול הטחוב וחוזרים בפחד למעמקיהם. העיז גל אחד, פרץ, התגנב ולחך את קצה
        סנדלה ונשק את שולי שמלתה. –</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2">        פרצה ידידה בצחוק וברחה מחבריה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        הר כנען... בין אבניו הלבנות והצחות משתטחות שורות גפנים זמורות.
        ידידה מקוששת ביניהן את העצים, מחברת גל אל גל ומוציאם מן הכרם.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        שם בשפוע ההר חורש גלעדי; שירתו הנוגה משתפכת בעצב על פני הכנען.
        נוטה ידידה אזנה ותמהה... למה היא כה נוגה שירתו? אמש, כשהוא הרציא בביה"ס
        היה כה מלא אמונה!... מהתפתחות הלאומיות בין עמי הבלקן, עבר להתפתחות הרגש
        הזה בעמנו...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        באמונה ובטחון הוכיח, כי הגרעין הקטן והצנום שהורד לחום אדמתנו –
        ינבט, ישגשג, יפרח!...
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        כמה הוא היה מענין אמש!... דומה שאף פעם לא ראתה אותו כה יפה
        בשערותיו הבהירות ובעיניו הגדולות, האפורות. דבורו שטף כמעין נובע, וזרם
        חם, חם בא ממנו אל תוך לבה, אל תוך נפשה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        מדוע כה כונו מבטיו אליה, רק אליה?... לא! שטיה היא, - טועה
        היא!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        מתוך כפיפה על קשוש העצים היא מעיפה עין השדה, גלעדי עולה בהר עם
        המחרשה ובעלו הולך ונגרר אחריו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        היא הזדקפה ותסתכל בו מרחוק. חלצתו הדקה הבליטה את רוחב כתפיו
        החזקות, ראשו זקוף היה ביהירות כשהוא לחץ את המחרשה באדמת האבות ופתח עמוק
        את תלמיו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        היא הרגישה בנצחונו על האדמה הקשה ובתוך עיפותה התערב איזה רגש של
        נעימות אי מובנה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        הסתכלה היא, הסתכלה בחרישתו ותפן הצדה. מראה המושבה הנהדר בצבץ
        נגד עיניה – גנים, עצים, שדרות, כרמי שקדים פורחים, יערות, יערות
        לבנים!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        הרי גלעד חובקים את העמק הירֹק ושם, שם החרמון! – ראשו השב – כמה
        קסם שופך הוא על כל הסביבה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >        ורואה ידידה מימין את מי מרום השקטים, ואת ים הכנרת בקסם יפיו
        משמאל, - ומתחילות עיניה מתיזות שוב ניצוצי אושר, והלב דופק, דופק
        בשמחה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2">        ...גן עדן עליון – וזה שערי השמים!</span>
    </p>
    <ul class="c5 lst-kix_list_1-0">
      <li class="c13 li-bullet-0" dir="rtl">
        <span class="c2">מי הכניסה בשערים הללו?</span>
      </li>
    </ul>
    <p class="c4 text-left" dir="rtl">
      <span class="c2">נחמה פוחצבצקי</span>
    </p>    
  </body>
</html>`;

export default page;
