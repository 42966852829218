const page = `
  <div class="c5 doc-content home">
    <h2 class="title text-center" dir="rtl">
      <span>ראשית ההתיישבות החדשה בארץ</span>
    </h2>
    <h2 class="title text-center" dir="rtl">
      <span>סופרת, איכרה, פעילה חברתית</span>
    </h2>
    <br/>
    <p class="c2" dir="rtl">
      <span class="c1 c3">בריסק ד'ליטא 1869 – ראשון לציון 1934.</span>
    </p>

    <p class="c2" dir="rtl">
      <span class="c3 c1"
        >עלתה ארצה בשנת 1889 בעקבות נישואיה ליחיאל מיכל שלמה זלמן פוחצ'בסקי,
        שבאותו הזמן היה מדריך חקלאי מטעם הברון רוטשילד, וחיה כל ימיה בראשון
        לציון.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c1">אשת </span><span class="c0 bold">העליה הראשונה</span
      ><span class="c1">, </span><span class="c0 bold">סופרת</span
      ><span class="c1"> ו</span><span class="c0 bold">פובליציסטית</span
      ><span class="c1"> בעברית! </span><span class="c0 bold">איכרה</span
      ><span class="c1"
        > שטיפלה במשק הביתי - פרות ותרנגולות, ירקות ופרחים, וייצרה לביתה מוצרי
        מזון מפרי גנה (גבינות, ירקות כבושים, פירות מיובשים וכד'); ניהלה </span
      ><span class="c0 bold">בית פתוח </span
      ><span class="c1"
        >ומכניס אורחים שהאכיל - מתוצרת עצמית של המשק, והלין - לעיתים על מחצלאות,
        שולחנות, וספסלים - מסופרים ואנשי ציבור ועד ליתומות חסרות כל; הקימה
        והפעילה </span
      ><span class="c0 bold">אירגוני סעד</span><span class="c1"> שונים; נאבקה ל</span
      ><span class="c0 bold">שוויון זכויות לנשים</span
      ><span class="c1">, היתה האשה הראשונה ש</span
      ><span class="c0 bold">נבחרה,</span
      ><span class="c1"
        > בעיקבות הצלחת המאבק שהובילה למתן זכות בחירה לנשים, ל</span
      ><span class="c0 bold">ועד המושבה</span><span class="c1"> ומאוחר יותר ל</span
      ><span class="c0 bold">ועד הלאומי</span><span class="c1">, דגלה </span
      ><span class="c0 bold">בעבודה עברית</span
      ><span class="c1"> ויצרה קשרים קרובים עם </span
      ><span class="c0 bold">פועלים</span
      ><span class="c1"> אנשי העליה השניה, אירגנה מקומות </span
      ><span class="c0 bold">מקלט לנפגעות אלימות בבית</span
      ><span class="c1">, נאבקה על </span
      ><span class="c0 bold">זכות הבנות להשכלה</span
      ><span class="c1"
        >, לימדה בעצמה ודאגה למימון לימודים לבנות, אירגנה כיתות לימוד לנשים;
        היתה </span
      ><span class="c0 bold">מגשרת</span
      ><span class="c1"> בשיתוף פעולה עם רב העדה התימנית, ו</span
      ><span class="c0 bold">שופטת</span
      ><span class="c1"
        > במסגרת בית משפט שלום של המושבה; שכלה בן בכור (בן 9 חודשים) מסיבוכי
        קדחת ובן שני בגיל שנתיים וחצי, גידלה עוד שניים משלה עד בגרות ושימשה
        כ</span
      ><span class="c0 bold">משפחה אומנת</span><span class="c3 c1"> ליתומים.</span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c0 bold"
        >באתר זה אנו מביאים חומרים שלה וחומרים של בני זמנה המתייחסים אליה, במטרה
        לתת תמונה שלימה ככל הניתן של אישה יוצאת דופן זו.</span
      ><span class="c3 c1"> </span>
    </p>
    <p class="hidden" hidden>
      <span class="">נחמה פוחצ'בסקי, NEHAMAH PUKHACHEWSKY,Nehama Pukhachewsky,Nechama Pohatchevsky,Pohatchevsky,Pukhachewsky,Puhachewskyעליה ראשונה,סופרות עבריות,זכויות נשים,תחית השפה העברית,פוחצ'בסקי</span>
    </p>
  </div>`;

export default page;
