const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c2 italic" dir="rtl">
      <span class="c6 bold">דבר</span><span class="c3">  27.5.1934  </span>
    </p>
    <p class="c2 c5" dir="rtl"><span class="c1"></span></p>
    <h3 class="c7" dir="rtl" id="h.jbt6nrg29bhm">
      <span class="c4">על קברה של "אכרה" אחת</span>
    </h3>
    <p class="c2 c5" dir="rtl"><span class="c1"></span></p>
    <p class="c2" dir="rtl">
      <span class="c1"
        >הכרתיה מיום הראשון שדרכו רגלי במושבה העברית. הכרתיה בתור אכרה, עוד לפני
        שרשמה את שמה בספרות. כל המשפחה, הבעל עם האשה, הבן עם הבת, דברו עברית, רק
        עברית, אז, לפני 27 שנה, היה זה, בראשון לציון ביחוד, יקר המציאות. למעשה –
        רק בית פוחצ&#39;בסקי ובית המנוח הירשפלד.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c1"
        >המנוחה היתה כמה שנים איש עצתם וסודם של הפועלים: איך מסדרים מטבח וכיצד
        מקימים מאפיה וכו&#39;. בעצמה עזרה לבישול ולאפיה. לא פעם סחבה מחדרה למטבח
        הפועלים שהיה מול ביתה, ערמה של זמורות גפן להסקה. היא היתה, לפני 25 שנה,
        בועדה לחיפוש עבודה, שבה השתתפו שלושה מהפועלים ושנים מנותני עבודה והיא
        בתוכם.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c1"
        >אך לא זה בלבד. כל הפועלים בראשון ידעו, כי בבית פוחצ&#39;בסקי יש נפש
        טובה ומסורה. זכורים לנו היטב מלחמתה בעד זכות בחירה לכל תושב ולכל עובד.
        בבחירות הראשונות לועד המושבה נבחרה היא וע. כ. בקולותיהם של
        העובדים.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c1">שלום לעפרך, אם ואחות!</span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c1">דוד בדר</span>
    </p>
  </body>
</html>`;

export default page;
