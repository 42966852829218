const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c1 doc-content">
    <p class="c3" dir="rtl">
      <span class="c2 bold">העולם</span
      ><span class="c4">  יט סיון תרס"ט  [1909 06 08]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0">הספד לבנימין פיין</span>
    </p>
    <p class="c3 c5" dir="rtl"><span class="c6 c2"></span></p>
  </body>
</html>
`;

export default desc;
