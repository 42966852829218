const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c9 doc-content">
    <p class="c1 c4 italic" dir="rtl">
      <span class="c8 bold">דאר היום</span><span class="c3">  19.6.1934</span>
    </p>
    <h3 class="c6 text-center" dir="rtl" id="h.hnkkobo552tc">
      <span class="c5">זר פרחים על קבר רענן</span>
    </h3>
    <p class="c2 text-center" dir="rtl">
      <span class="c0">לדמותה של המנוחה נחמה פוחצ&#39;בסקי ז"ל</span>
    </p>
    <p class="c2 text-center" dir="rtl">
      <span class="c10">ליום השלשים</span>
    </p>
    <br/>
    
    <p class="c1 c4" dir="rtl">
      <span class="c0"
        >השם "גב&#39; פוחצ&#39;בסקי" היה ידוע היטב בין עסקני הצבור בישוב, לא
        מתמול שלשום כי אם מזה עשרות בשנים. ואולם רק אלה שעמדו קרוב למחיצתה יכלו
        להכיר היטב ולהעריך את הטפוס המרומם הזה בין נשי הישוב ונשים בכלל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        המנוחה היתה "אשת חיל" מודרנית במובן היפה והמלא של מלה זו. היא
        היתה עקרת בית מופלאה, רעיה נאמנה, אם מסורה, אכרה למופת, ויחד עם זה סופרת
        ועסקנית צבורית פעילה עד מאד. ואחרון אחרון: לוחמת שאינה יודעת ליאות
        לזכויות שוות לאשה. בהיסטוריה של המלחמה לזכויות האשה העברית בארץ, יתנוסס
        בלי ספק שמה של המנוחה בין הראשונים בזמן ובמעלה במלחמה זו. ובבחירות
        הראשונות שבהן קבלו נשי המושבה ראל"צ בפעם הראשונה את הזכות לבחור ולהבחר,
        נבחרה המנוחה ברוב דעות מכריע של כל הבוחרים והבוחרות יחד. היתה זאת
        דמונסטרציה לזכויותיה המרובות של המנוחה בתור אישיות ולאו דוקא בתור
        אשה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        ואולם מעולם לא הסתפקה האשה המופלאה הזאת בתפקיד אחד, כי אם הטילה
        על הצמה כמה וכמה תפקידים ועבודות צבוריות ואחראיות בכלל. כשנראתה גב&#39;
        פוחצ&#39;בסקי ברחוב ידעו כבר מכיריה, שהנה בודאי כמה וכמה עבודות
        ושליחויות צבוריות עומדות לצאת לפועל על ידה בשעה זו. לפלוני תגש בענין
        אוסף תרומות לקרן זו או אחרת; לחברה זו בענין עזרה תכופה למשפחה דחוקה או
        פועל אינווליד. או שהיתה ממהרת ללכת לבקור חולה או לנחם איש אומלל ומסכן.
        ויחד עם זה לא הזניחה את הישיבות המרובות שהיו לה בועדים השונים שהיתה חברה
        בהם. ולו היו אוספים את כל מכתבי ההמלצה והבקשות שהמנוחה כתבה במקרים שונים
        לטובת אלה הזקוקים לכך כי אז היו מכתבים אלה מהוים כרך גדול ולא היה בשביל
        המנוחה סיפוק יותר גדול מאשר זה, שמכתבה או המלצתה נשאו פרי, והזקוק נעזר
        בזמנו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        זכורה אני שפעם אחת מצאתי אותה בוכה, מקרה שמצאתיה, מה יש! – אלו
        היו דמעות גיל, כי היא קבלה ידיעה שהיתום שהיא דאגה לו נתקבל סוף סוף לבית
        הספר בשפיה והוא  מסודר כבר ואולם לא בכתיבת מכתבים לזרז אחרים יצאה ידי
        חובתה. כי גם ביתה היא היה טוב לילדים או בוגרים חלשים שידם לא השיגה
        להוציא הוצאות מרובות להחליף את האויר. והיא לא הססה להצטמצם בחדריה המעטים
        ובשעת הדחק נמצא תמיד פרגוד מוכן שיצר בין רגע "חדר נוסף" בכדי לתת מקום
        לעוד מבריא או מחליף-כח לנוח בביתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        גם מהמעט המובא בזה אפשר כבר להבחין שהמנוחה לא הבדילה בעבודות
        הצבוריות בין כלליות לפרטיות. כי שתי העבודות כאחת, הסיוע לכלל והסיוע לפרט
        היו חשובות בעיניה, באשר הפרט גם הוא אלא חוליה אחת של הכלל כלו. ולכן כשם
        שדאגה לפלוני החולה או מחוסר העבודה, כן לקחה חלק פעיל בהפצת השפה העברית
        למשל. ויפה העיר אחד המספידים עליה, שהמנוחה השיגה זכויות שוות לפני התקיים
        ההסתדרות לשווי זכויות, כי אם רק בכח השפעתה בלבד. כן היה גם בנוגע לעבודות
        צבוריות אחרות, כי היא לא חכתה עד שמי-שהוא יבוא ויארגן "גדוד למגיני
        השפה", כי אם הרבה שנים לפני הוסד מוסד זה, עמדה המנוחה ואספה קבוצות
        קבוצות של בוגרים ונתנה להם שעורים בשפה העברית. זוכרת אני כי בשעה שזכיתי
        להיות תלמידה באחת מקבוצות התלמידים שלה, לא רצתה המנוחה בשום אופן לדבר
        אתנו אפילו מלים אחדות! – בשפה אחרת מלבד עברית. וההסברה הטובה שלה וידיעתה
        הרבה את השפה העברית עמדו לה, שנבין אותה סוף סוף מבלי שתהיה זקוקה לותר על
        העקרון שלה. ומה שהיא ידעה לדרוש מאחרים, דרשה עוד יותר מעצמה. כי ביתה היה
        אחד הבתים הראשונים והיחידים שהשפה העברית שלטה בהם בכל ענפי החיים. וכשם
        שהיא היתה קנאית לעבודה עברית ולא מעט עמל השקיעה המנוחה בתעמולה לטובת
        רעיון זה. וכן בהדרכת התימנים ובעיקר התימניות במשק בית או במקצוע אחר בכדי
        שתוכלנה מהר למצא עבודה. ולא לחנם הביטו התימנים על  "הגברת נחמה" כעל
        הפטרונית שלהם, כי היא עמדה תמיד לימינם בכל אשר יכלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        המנוחה היתה גם נואמת מצליחה. ואל יהיה הדבר הזה קל בעינינו,
        נואמים טובים ומצליחים אין בכלל הרבה בארצנו ונואמות על אחת כמה וכמה. היא
        היתה אחת החלוצות שהעיזו להופיע על בימות פומביות. העיזה והצליחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כידוע היתה המנוחה גם סופרת, ואולם זה הוא פרק מיוחד שלא אעמוד
        עליו כאן. אך גם בלי לנגוע בפרטי יצירותיה הספרותיות, יש לשים אל לב
        שהמנוחה היתה בין הנשים הראשונות הספורות בזמנה שהתחילו למשוך בעט סופרים
        בעברית בצורה מודרנית, כשהיא משתמשת לשם כך בשפה ברורה ועשירה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        עלי להוסיף עוד פרט אחד באופיה של האשה המופלאה הזו, שהלכה מאתנו
        לבלי שוב. היא הרסה כליל את הדעה המוטעית הסוברת שהעסקנית, הסופרת, או בכלל
        האשה הנוטה לענינים רוחניים, מן ההכרח שתזניח את עבודות הבית שלה ואת
        חובותיה כלפי בעלה וילדיה. המנוחה הכחישה את הדעה הזאת בעבודתה, היא היתה
        צופיה בעין פקוחה על הליכות ביתה והיתה מטפלת בחריצות יתרה בבעלה ובילדיה,
        היא היתה עקרת-בית למופת, תמיד שרר הנקיון והסדר לא רק בביתה ובמטבחה, כי
        אם גם – מה שסימן טוב הוא לגבי בעלת בית הגונה – גם בחצרה. עם המטאטא ביד
        ראוה השכנים בוקר בוקר מטאטאת סביב ביתה ולא הניחה מטאטא זה מידה עד שלא
        ראתה את חצרה מנוקה היטב. וכשם שלא שכחה את חצרה, כן פקדה יום יום את
        פרחיה, ירקותיה, העופות שלה והפרה. בהשתמשה בכל עבודות האלו ב"עבודה
        עצמית". ורק בימים שרבתה בהם העבודה ביותר היא הרשתה לעצמה לקחת עוזרת.
        וכמובן שזאת היתה תמיד מבנות עמה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כן, היא הספיקה הכל. לכל היה הזמן הקצוב מראש, רק לדבר אחד לא
        הספיק לה הזמן: לגשת אל הרופא ולשאל בעצתו בענין בריאותה הרופפת. את זאת
        שכחה לגמרי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        והנה יומן קצר מימיה האחרונים: ביום רביעי וחמשי בתערוכה. בחמשי
        בערב בישיבה משותפת עם המועצה המקומית ביום ששי עבודות-בית רגילות כאילו
        אינה חולה כלל ולמרות זה שפניה מעידים על מחלתה המתגברת בשבת מבהילים את
        רופאים. ביום ראשון מצוה היא לביתה ובתוך כך מבקשת היא שימכרו את ספריה
        ובכסף יתמכו בתלמידים עניים. ואינה שוכחת כל חוב פעוט שהיא חייבת שהיא
        מבקשת לשלם. וביום שני איננה עוד בין החיים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        כך חיתה בתוכנו וכך מתה האשה העבריה רבת הפעלים שתשמש לנו לסמל
        בקרבנותיה הרבים לטובת הכלל והפרט. יהי זכרה ברוך.</span
      >
    </p>
    <p class="c7 text-end" dir="rtl">
      <span class="c5">ע. כהנסקי</span>
    </p>
  </body>
</html>`;

export default page;
