const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c3" dir="rtl">
      <span class="c2 bold">המליץ</span
      ><span class="c5"> יד אייר תר"ן  [1890 05 04]</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0">על ביקור בירושלים</span>
    </p>
  </body>
</html>`;

export default desc;
