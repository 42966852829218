const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c22 doc-content">
    <p class="c4" dir="rtl">
      <a href="#ruthArbel">
        <span class="c8">רות ארבל</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <span class="c0">רוב העדויות שלהלן נאספו ונרשמו על ידי רות ארבל</span>
    </p>
    <p class="c4 c5" dir="rtl"><span class="c0"></span></p>
    <p class="c4" dir="rtl">
      <a href="#batSheva">
        <span class="c11">בת שבע רזניק</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <a href="#esterAndRachel">
        <span class="c8">אסתר ורחל</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <a href="#behoraChemdi">
        <span class="c8">בכורה חמדי</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <a href="#yonaYehud">
        <span class="c8">יונה יהוד</span>
      </a>
    </p>
    <p class="c4" dir="rtl">
      <a href="#dvora">
        <span class="c8">דבורה</span>
      </a>
    </p>
    <p class="c4 c5" dir="rtl"><span class="c12 c27"></span></p>
    <h3 class="c1" dir="rtl" id="ruthArbel">
      <span class="c7 c16">רות ארבל</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c0">סבתא נחמה נפטרה ב-1934. הייתי אז בת שבע.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >משך שנתיים, בדרכי חזרה מבית הספר נהגתי להכנס לביתה. תמיד מצאתיה עסוקה
        בבית או בחצר. והיא כיבדה אותי בקווקר מתובל – כיום היינו קוראים לזה
        גרנולה - וישבה אתי כמה רגעים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ביום הולדתי השביעי בחדש שבט תרצ"ד הלכתי עם סבתא לידידתה עדינה כהנסקי,
        שבביתה הקטן ברחוב ירושלים היתה לה חנות ספרים, וסבתא קנתה לי שני ספרים –
        "הילד המרגל" ו"זה לא עסק". הספרים לוו אותי שנים רבות בכל נדודי.</span
      >
    </p>
    <br />

    <h3 class="c1" dir="rtl" id="batSheva">
      <span class="c7 c16">בת שבע רזניק</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c0"
        >את בת-שבע פגשתי באקראי. בסוף שנות השבעים באתי לאביחיל לביתו של נלסון,
        שהיה חיל בגדוד העברי במלחמת העולם הראשונה, ומבקר קבוע באותה תקופה בבית
        סבא וסבתא. ביקשתי לשמוע מפיו ספורים עליהם, על הבית ועל אותם הימים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשנכנסתי לבית נלסון פגשתני בכניסה אשה מבוגרת, צנומה, שחרחורת ששאלה אותי
        בקול תקיף "מי את?". כשהשבתי "נכדה של נחמה פוחצ&#39;בסקי" אמרה "אני הכרתי
        את סבתא שלך". שאלתיה אם תהיה מוכנה לספר לי עליה. "לא" – השיבה נמרצות.
        "לא אספר, אני אכתוב". היא לקחה מיד את הפנקס והעט שהיו מוכנים ליד הטלפון
        וכתבה בזריזות ובשטף על צדו האחד של הדף, תלשה, כתבה על הצד השני, חתמה את
        שמה – והגישה לי:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 hand"
        >נחמה פוחצבסקי האשה אשר אהבה לטפל ביתומות, לתת להן בית חם במשפחתה. בנות
        שפיה היו בביתה והיא הייתה בכל פעם מבקרת בכפר הילדים מאיר שפיה, היא
        התענינה בגורל הבנות ועזרה גם בסידור מקומות עבודה. אחרי אשר עזבו את הכפר
        היו תמיד מתארחים בביתה. אני הייתי בת בית אצלה והייתי חולבת הפרות של המשק
        הקטן שלהם. האב הזקן היה דוד לנו. היתה ילדה שגדלה בביתה בשם אסתר גלילי,
        אשר היא הייתה
      </span>
      <span class="c3 c13 hand tooltip">אחראית לחינוכה<span class="c12 c20 tooltiptext regular">במכתבים של נחמה פוחצ&#39;בסקי מהשנים 1924-1923 מוזכרת אסתר פעמים רבות</span>.</span>      
      <span class="c7 c3 hand"
        > במסירות שמרה עליה עד אשר גדלה ועזבה את ביתה.  הגב&#39; מוהל – סופיה
        ברגר הייתה מביאה את היתומות אל נחמה, ועוזרת בסידור היתומות, הם היו הועד
        של היתומים בארץ ישראל, ועזרו בסידור היתומים הנמצאים בערי הארץ וסידרו
        אותם בכפר הילדים שפיה. כל היתומים ריכזו בכפר חקלאי, וחונכו לתורה ולעבודה
        בנאמנות עד סוף שנותיהם.</span
      >
    </p>
    <p class="c24 text-end hand" dir="rtl">
      <span class="c7 c3">בת שבע רזניק, אביחיל</span>
    </p>
    <br/>

    <p class="c1" dir="rtl">
      <span class="c12"
        >בת-שבע הוסיפה אחר-כך בע"פ ואני הזדרזתי לכתוב, בשולי הדף השני אחרי
        החתימה: </span
      ><span class="c3 hand"
        >"היא כסתה אותי בלילה שלא יהיה לי קר. רצתה שאלמד ולא זכיתי"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c12"
        >בקיץ 2003, יותר מ20- שנה אחרי פגישתנו הראשונה, בקרתי את בת-שבע. היא
        היתה אז באמצע שנות התשעים לחייה – נמוכה יותר, צנומה יותר, כפופה, כולה
        עור ועצמות, אך עדין מלאת מרץ ותנועה. היא סיפרה שקראו לסבתא "דודה" ולבעלה
        "דוד". </span
      ><span class="c3 hand"
        >"באנו לדודה נחמה להבראה, היינו בנות בית, לא היה לנו משפחה, היא היתה לנו
        אמא ודודה, ומשפחתה היתה לנו כמשפחה. לא היה לנו בית והיא נתנה לנו
        בית."</span
      ><span class="c0"> </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c12"
        >כשעמדתי ללכת בקשתי להפרד, ונגשתי אליה לחבקה ואז תפשה את פני בשתי ידיה
        הגרמיות ומשכה אותי למטה לגובה פניה ואמרה: </span
      ><span class="c3 hand"
        >"תני לי לתת לך נשיקה, כי סבתא שלך תמיד נשקה אותנו.  היא אהבה אותנו –
        היא לא רצתה שנרגיש יתומות"</span
      >
    </p>

    <br />

    <h3 class="c15" dir="rtl" id="esterAndRachel">
      <span class="c7 c16">אסתר ורחל</span>
    </h3>
    <p class="c21" dir="rtl">
      <span class="c7 c14 c12"
        >חפשתי את ידידי עובדיה (כנראה בסוף שנות ה-50). את הרחוב ידעתי, מספרים
        לבתים אין. הרחוב רעוע, ללא מדרכות. בתים קטנים צנועים בנויים טלאי על גבי
        טלאי – לבנים, עץ ופח. ולפני הבית – פיסת גינה: שיבה, נענע, פחים חלודים עם
        גרניום.</span
      >
    </p>
    <p class="c18" dir="rtl">
      <span class="c0"
        >דפקתי על אחת הדלתות. את הדלת פתחה תימניה מבוגרת. שאלתיה לביתו של
        עובדיה, והשיבה: "עובדיה גר בבית השלישי ממול אך בשעה זו הוא בבית הכנסת
        בתפילת מנחה" והמשיכה, "את לא מכירה אותי אבל אני מכירה אותך. הכנסי בינתים
        אלי ותשתי אצלי כוס תה". נכנסתי ברצון, ולעובדיה כבר לא הגעתי.</span
      >
    </p>
    <p class="c18" dir="rtl">
      <span class="c0"
        >אסתר – שמה של בעלת הבית – הציגה לפני את אחותה רחל שבאה לבקור. שתיהן
        עבדו בצעירותן אצל סבתא. האחות ספרה לי כי אמי, אפרת, לימדה אותה לתפור
        כפתור על בגד ולתקן גרבים. לסבתא היה גן ירק ליד הבית שטיפחה באהבה, ואת
        אדמת הגן הפכו במחרשת יד קטנה שדחפו מאחור. אם עבדו בשנים יכול היה אחד
        למשוך מלפנים ובכך להקל על עבודת הדוחף. רחל שמחה מאד שסבתא אפשרה לה לעזור
        לה בכך. חלקת הירקות גבלה עם הרחוב. לאורך הגבול היו עצי תות. בעונת התותים
        טיפסו תלמידי הת"ת – שהיה במורד הרחוב – על העצים לקטוף תותים. אם רחל חרשה
        עם סבתא, היו הילדים צועקים "פרה, פרה".
      </span>
    </p>
    <p class="c18" dir="rtl">
      <span class="c12">בקול רוי כאב אומרת לי רחל: </span
      ><span class="c7 c3 hand"
        >הם לא ידעו כמה שמחתי לעבוד עם סבתא שלך, כמה גאה הייתי ששתפה אותי בעבודת
        הגן האהוב עליה.</span
      >
    </p>
    <p class="c18" dir="rtl">
      <span class="c0"
        >אסתר עבדה אצל סבתא כשהיתה ילדה כבת שבע או שמונה. סבתא הבחינה שאסתר
        מוכשרת מאד, ורצתה שתלמד. כדי שלא תצטרך להתחיל מכתה אלף, לימדו אותה סבתא
        ואמא בכל רגע פנוי. סבתא השפיעה על אביה (אברהם טביב – לימים חבר כנסת)
        שירשו לה ללמוד, וסבתא שילמה את שכר הלמוד. אסתר נכנסה לבית הספר ישר לכתה
        ב&#39;. בקיץ המשיכה לעבוד בבית סבתא וללמוד, כך שעם התחדש שנת הלמודים
        דילגה כתה והמשיכה בד&#39;. כבר מגיל 3 ארסו אותה הוריה לבן של משפחת פנחס,
        משפחת תלמידי חכמים. כשלהורי החתן המיועד נודע כי אסתר לומדת, דרשו מאביה
        להוציאה מבית הספר, כי אם היא תדע קרוא וכתוב היא לא תהיה אשה טובה ו"כל
        המלמד בתו תורה כאילו מלמדה תפלות", והאב נכנע והוציאה מבית הספר. אסתר לא
        סלחה על כך למשפחת פנחס, וכשבגרה סרבה להנשא לארוס המיועד. בתה ספרה לי כי
        אמה השקיעה הרבה מאד בחנוך ילדיה, כל שלש בנותיה ובנה גמרו למודים
        על-תיכוניים בתחומים שונים. הבת הבכורה היתה מורה לספרות בתיכון.</span
      >
    </p>
    <p class="c18" dir="rtl">
      <span class="c12"
        >ישבתי שעה ארוכה בבית אסתר ושוחחנו. כשעמדתי ללכת ספרה לי אסתר כי בזמן
        שעבדה אצל סבתה, קמה ביום חורף אחד כשהיא מצוננת מאד, והרגישה נורא. היא
        בקשה להשאר במטה, אך אביה אמר לה, "מה תשארי בבית אסתר, בבית אין מה לאכול,
        לכי לגברת נחמה שם לפחות תאכלי". היא גררה רגליה עד בית סבתא, מרחק לא קטן.
        כשרק נכנסה בפתח הבחינה סבתא במצבה, ופנתה אליה בדאגה: "אסתר, למה באת? הרי
        את חולה!" </span
      ><span class="c3 hand">"התביישתי לספר לה מה אבא אמר",<span class="c12 regular"> אמרה לי אסתר. </span>"היא הציעה מיד מטה, השכיבה
        אותי, כסתה אותי בשמיכה, הביאה כוס חלב חם עם דבש וצנימים מרוחים בחמאה –
        מכירה את רותי את הצנימים הדקים שסבא שלך הכין? האכילה והשקתה אותי."</span
      ><span class="c12"> ואז הוסיפה: </span
      ><span class="c3 hand"
        >"תאמיני לי רותי, עד היום טעם החלב החם בדבש עומד בפי!"</span>
    </p>
    <br />

    <h3 class="c17" dir="rtl" id="behoraChemdi">
      <span class="c7 c14 c19">בכורה חמדי</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זה היה בשנת 1980 לערך. באתי לבקר את אמי בביתה בראשון לציון. על מדף נצב
        מעין חדר מניאטורי על רהיטיו ואביזריו עשוי מחצלות קש, עבודת יד מלאכת
        מחשבת. שאלתי את אמי מנין, והיא השיבה שבכורה ביקרה אותה ואמרה שהיא משיבה
        חוב ישן.</span
      >
    </p>
    <p class="c17" dir="rtl">
      <span class="c7 c14 c12">מהו החוב? ומי היא בכורה? </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c12"
        >הלכתי לבקר את בכורה. ובכורה ספרה לי: בילדותה התיתמה מאביה והיה לה אב
        חורג. בבית היה מחסור – גם במזון וכנראה גם ביחס.</span
      ><span class="c12 c26"> </span
      ><span class="c12"
        >בראיון לרחל גיסין ומיכל כספי שנערך בשנת 1983 אמרה בכורה</span
      ><span class="c3 hand"
        >:"חיינו חיים מאד קשים, לא היה מה לאכול, מצבנו היה גרוע, הייתי הולכת
        לשדה, מלקטת עלי חסה ועשבים אחרים כמו צנון ועוד... מהם עשינו סלט כמו
        לפרות. בזמן הבציר היה לנו קצת לקט ענבים. חוץ מזה קיששנו עצים, לבשנו
        סחבות, הלכנו יחף. נחמה פוחצ&#39;בסקי, נכנסה לתמונה ועזרה לי מאד."</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא באה לעבוד אצל סבתא. אמי נסעה לירושלים, לגור ולעבוד עם הנרייטה סאלד.
        באחד מבקוריה בבית הביאה פיסת בד שקנתה, ותפרה לבכורה שמלה. זו היתה פעם
        ראשונה שהיה לה בגד חדש. היא תארה לי את הבד – לבן ופרחים קטנים כתומים.
        היא גם סיפרה לי כי סבתא דאגה שתלך ללמוד וכמובן שילמה את שכר הלימוד. והיא
        תארה לי לפרטי פרטים את מערכת הבגדים שסבתא אספה והכינה לה לקראת יום
        הלמודים הראשון שלה כדי שתופיע בבית הספר בלבוש נאות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הדברים לעיל רשמתי עכשיו מזכרוני. ההמשך אלה דברים שרשמתי מפיה במקום.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c7 c3 hand"
        >"סבתא כזו אין. הרחוב על שמה קטן, אבל גדוש כנסיות. שבע בתי-כנסת ומקוה.
        כמה היתה צדיקה. אחריה נשאר השם הטוב.  היא טיפלה בי, דאגה לי. לימדה אותי.
        לא היה לה זמן. כל מי שרצה איזה דבר היה בא. הבית היה פתוח. עזרה לכל אחד
        בעשר ידים בכל מיני דברים. היא היתה משהו גדול."</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 hand">"היתה ככה קטנה"</span
      ><span class="c12"
        >  אמרה בכורה כשהיא מושיטה יד קדימה. ואז קמה מהכסא, נמתחת, מושיטה יד
        לתקרה </span
      ><span class="c7 c3 hand">"אבל אישיות ככה גדולה. אין כאלה".</span>
    </p>
    <br />

    <h3 class="c17" dir="rtl" id="yonaYehud">
      <span class="c7 c14 c19">יונה יהוד</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c12"
        >היתה כבר שעת ערב, הפנסים דלקו וברחוב אין נפש חיה. לפתע הגיחה מכונית
        לרחוב השומם ונעצרה כמה פסיעות לפנינו, ואשה לבושה נאה ירדה מהמכונית.
        כשעברנו על פניה ברכה אותה אמי לשלום. האשה נעצרה, תפסה את אמי בכתפיה,
        סובבה אותה כך שפניה של אמי יהיו מופנים לפנס, ואז קראה "אפרת!" ונפלה על
        צוארה. כך פגשתי לראשונה את יונה יהוד. שעה ארוכה עמדנו תחת הפנס ושוחחנו,
        או יותר נכון יונה דברה בקולה העמוק, בסגנונה הציורי, מלוה מימיקה, ואמי
        ואני הקשבנו. יונה דברה בהתרגשות על דאגתה של סבתא לילדות התימניות, ואז
        אמרה, </span
      ><span class="c3 hand">"היו עוד נשים שעזרו, אך הן אמרו"</span
      ><span class="c12"
        > – והיא מחקה אותן בקולה ודוחפת את שני אגודליה תחת בתי השחי תוך הבלטת
        ונענוע החזה – </span
      ><span class="c7 c3 hand"
        >"אנחנו הגבירות! אנחנו עוזרות לכן!" סבתא שלך רצתה לעזור לנו! הי בקשה
        שנאפשר לה לעזור לנו."
      </span>
    </p>
    <p class="c28" dir="rtl">
      <span class="c7 c14 c12 small-text"
        >קטע נוסף מפי יונה מצאתי במחברתי, אינני יודעת מתי נרשם: </span
      ><span class="c7 c3 hand"
        >"זו היתה אשה, זו היתה זהב. החצר שלה מלאה אנשים חשובים, אנשים פשוטים,
        אותו דבר. היתה מראה "כך תעשי, כך מחזיקים, כך אוכלים". אין אנשים כאלה
        כיום, אין. איך התנהגה עם כל בן-אדם!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ופעם אחרת ספרה לי יונה יהוד איך ישבה ליד השולחן, היו אורחים חשובים,
        התנהלה שיחה והיא לא הבינה מאומה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c10 italic"
        >הציטוטים הבאים הם שכתוב מתוך קלטת שהקליטה רחל גיסין בארכיון בראשון
        לציון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c12"
        >הוריה של יונה עלו לארץ במאה ה-19 והתיישבו בירושלים, בסילואן. יונה נולדה
        שם. הם עברו לראשון כשיונה עדין ילדה צעירה. תקופת מה למדה בבית הספר. </span
      ><span class="c3 hand"
        >"עברה עוד שנה ועוד שנה. ואז אמרו שילדה אסור לה להמשיך בבית הספר. רק
        ילדים צריכים ללמוד בבית הספר. בא אדון רוזין הביתה וביקש את ההורים מאד
        מאד, תשאירו אותה, יש לה ראש. יש לה ראש פנטסטי. היא יכולה ללמוד, היא
        יכולה להיות משהו. אבא אמר לא, היא ילדה, וילדה לא צריכה ללמוד. טוב,
        הוציאו אותי מבית הספר, המצב לא פשוט, אז התחלתי פה לקלף שקדים שם לקלף
        שקדים עד שהגעתי לחצר של נחמה פוחצ&#39;בסקי. הגעתי לחצר של פוחצ&#39;בסקי,
        הייתי מקלפת שקדים. היא היתה על ידי ככה. מסתכלת עלי כל הזמן. משהו, בטח
        אהבה אותי, אני לא יודעת. אני הייתי עוד ילדה! אז היא אמרה לי, "את יודעת,
        את לא תקלפי שקדים, את תהיי אצלי בבית". הלכתי לאמא שלי, ואמרתי: "הזו" -
        אפילו את שמה לא ידעתי, טוב - "זו רוצה שאני אהיה אצלה בבית". אז אמא שלי
        בשביל לפחות לתת לאכול אמרה "לכי". אז הייתי אצלה. אני לא יודעת אם ניקיתי
        כמו שצריך או עשיתי כמו שצריך לא יודעת, הייתי עוד ילדה. נתנה לי לאכול,
        נתנה לי לשתות, אם ישבתי לא אמרה לי למה ישבת, למה הלכת, למה באת, כלום!
        היה לי ארוחת בוקר טובה, ארוחת צהרים טובה, היה נהדר. בערב הייתי הולכת
        הביתה. היא היתה מבשלת! היה לה תנור גדול גדול גדול. היא היתה מבשלת את
        האוכל, שמה אותו בתוך התנור וסוגרת, והאוכל היה יוצא חום, טעים נורא. ואני
        מאד אהבתי כרוב חמוץ-מתוק. אז מתי שהיא עשתה כרוב חמוץ-מתוק והיא הוציאה
        אותו לפני הארוחה, אז תמיד גנבתי, גנבתי קצת. במקרה היתה לה ילדה, ילדה
        נהדרת, יפה. לבשה ככה חצאיות שחורות על הגוף, חולצה לבנה עם צוארון עד
        למעלה, יפה היתה, נהדרת. היה לה קסם.</span
      ><span class="c12"> רחל מראה על אמי הנוכחת בזמן השיחה.  </span
      ><span class="c3 hand"
        >"אני לא יודעת אם זאת היא. העיקר, הבחורה הזו, קוראים לה –"</span
      ><span class="c12"> רחל אומרת: קוראים לה אפרת לילדה הזו. </span
      ><span class="c7 c3 hand"
        >"כן, אפרת. יום אחד במקרה אפרת הלכה אחרי ואני לא ראיתי. ואני נגשתי לסיר
        ולקחתי קצת כרוב חמוץ ואכלתי. אז היא אומרת לי: "לא יונלה, יונלה מותק, אם
        את אוהבת את הכרוב החמוץ, קחי צלחת, וקחי כף, וקחי לך קצת כרוב לפני הארוחה
        ואכלי. אם את כל-כך אוהבת את זה אני מרשה לך, אני מרשה לך". וככה זה היה,
        ואני חייתי שם, לא זוכרת בדיוק כמה, אבל היה לי כל-כך טוב שאין לך מושג.
        היתה להם שם עוד ילדה אחת, היה שמה ציפורה, לא מראשון. היתה ילדה יתומה.
        היא טיפלה בה כל-כך יפה... שאין מילים בפי.
      </span>
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >היא תמיד הסתכלה ותמיד שאלה "איך אצלכם בבית, איך זה איך זה." סיפרתי לה
        מה שסיפרתי, סיפרתי.
      </span>
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c3 c7"
        >ואחר-כך הייתי תמיד הולכת אליה. אחרי שכבר גדלתי קצת הייתי הולכת
        אליה.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >... העקר אני לקחתי את זה לתוך הלב כמה שנחמה פוחצ&#39;בסקי היתה נהדרת.
        ואני התחלתי גם כן לחשוב שצריך לעזור לאדם.
      </span>
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >... אני מוכרחה להיות כמו שראיתי שנחמה פוחצ&#39;בסקי עושה. אצלנו המצב
        היה כבר טוב. היה לנו פרות, היה לנו יונים, היה לנו תרנגולות, היה לנו
        עזים, היה לנו יוצא מן הכלל. באמת היה לנו יוצא מהכלל. והיה לנו חֶברֶה
        בחצר שבאו עולים חדשים, רחמנות היה. שלשה בחורים גרו בבית, בדיוק שהחלונות
        היו לחצר שלנו, והם עבדו פחות או יותר ביקב, ככה יום כן, יום לא. ואם היה
        להם מה לאכול אני לא יודעת. הייתי אומרת להם, "חברה, כשתראו את האמא שלי
        הולכת, תבואו לחלון." אז הייתי נותנת להם גבינה וחלב ולחם, מה שהייתי יכולה
        לתת להם, "אבל אל תגידו, אמא שלי תרביץ לי." היא לא תרביץ לי, כמו שהיא לא
        תרשה לי להיות חפשיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">רחל: וזה בעצם למדת בבית נחמה פוחצ&#39;בסקי.</span>
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c3"
        >זה, הרגשתי את זה, כמה שהיא טיפלה בי ולי לא היה מה לאכול, וכשאני רואה
        אדם אין לו מה לאכול ואני יש לי כל-כך הרבה, היה לנו כבר, ככה עד כאן היה
        לנו. היה לנו כבר די טוב, מכל הבחינות. אז אני גם-כן התחלתי באותה מעשיה.
        ואני מקוה, ואני מבקשת מאלהים שאהיה בריאה, ומה שאוכל לעשות בסדר שאעשה.
        אין לאדם שום דבר בחיים. כסף – לא לוקח אתו אפילו גרוש. בית לא לוקח אתו –
        שום דבר. אם אתה טוב ואתה מרגיש את הצד השני זה החשוב ביותר. לא רק לאכול
        ולשתות ולכייף ולבלות. והיא, זכרונה לברכה, היא היתה אשה – אין מלים בפי,
        אין, אין, אין, אין. לא היה לה הבדל בין אדם לאדם, לא אחזה זה ככה, וזה ככה
        וזה ככה, כולם אותו הדבר. ואפרת שתהיה בריאה שתשכח את הכרוב החמוץ!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">אפרת: את הזכרת לי, אני לא זכרתי.</span>
    </p>
    <p class="c1" dir="rtl" id="h.gjdgxs">
      <span class="c7 c9 small-text"
        >רות ארבל:  פעם סיפרתי את הסיפור לאחד מילדי בנוכחות אמי. אמי לא קלטה מה
        כה מיוחד בתגובתה, ואמרה, "נו כן, מי זה אוכל בידים"...</span
      >
    </p>
    <br />

    <h3 class="c17" dir="rtl" id="dvora">
      <span class="c7 c14 c19">דבורה</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c10 italic"
        >שכתוב של ראיון שהוקלט ע"י רחל גיסין בבית נאדרה. ההקלטה נמצאת בארכיון
        ראשון לציון.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >"הורי עלו ב-1909. לפני עלית יבניאלי. עלו עם קבוצה קטנה שעלתה על אנית
        מפרשים. בכל רגע חשבו שהאניה נשברת בים. ורק התפללו, ורק התחננו לאלהים
        שיציל אותם. היא פשוט כמעט התפרקה ובאמת בנס הגיעו לחוף מבטחים בעדן.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3">אני נולדתי כאן.</span>
    </p>
    <p class="c1" dir="rtl"><span class="c0">- מתי?</span></p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3">שאלתי את אמא שלי. אמרה לא זוכרת.</span>
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >קראו לי הדסה. הורי נסעו לטבריה לעלות על קברי צדיקים. הייתי חולה מאד.
        בקבר רבי מאיר בעל הנס אמרו שיש לשנות את שמי וזה יגן עלי. קראו לי דבורה.
        היינו עניים. מאד עניים. לא היתה לשכת סעד. לא מקבלים את העולים ועושים להם
        כמו היום. אז חיו מתוך עבודת כפים. אם היו עובדים קנו קצת קמח ואפו לחם.
        אמי היתה מספרת שאבי בקש "תגדילי קצת את הככר שלי שיש לי מה לאכול". לא
        תמיד היתה עבודה. אם היו לאבי יומים עבודה בשבוע זה היה דבר גדול. היינו
        מאד עניים.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >הרבה הורים הוציאו את הילדות מבית-הספר שילכו לעבוד בבתים. ראשית לא היה
        לשלם שכר למוד ושנית שיכניסו עוד חצי לירה, או 25 גרוש. למשפחה זה היה חשוב
        מאד.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >היו גם הורים שאמרו שמי שמלמד את בתו תורה מוציא אותה לתרבות רעה, וביניהם
        היו אנשים חשובים, אברהם טביב (לימים חבר כנסת), אביו היה ראש העדה, שלמה
        כהן שלום עירקי.
      </span>
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >אני התחלתי ללמוד. אמי היתה אשה חרוצה, מאד חזקה ונחמדה, יפה וכל כך
        זריזה. היא עבדה בכביסה, כל המושבה הכירה אותה. "עוידה! עוידה! עוידה!"
        קראו לה. אבא לא עבד, ולשכר למוד לא הספיק. חשבו בטח שגם אני לא
        אלמד.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >היתה גברת נחמה עליה השלום, אשה מאד אצילה, באמת אצילה מאד מאד. היא עזרה
        לכולם, במיוחד לילדות התימניות. אנחנו כילדות ישבנו בחצר שלה וקלפנו שקדים.
        היא היתה מסתובבת בינינו והיתה מציעה לנו – אולי אתם רוצים צלחת מרק. היתה
        אשה מאד חרוצה ומאד חסכונית. אמרו שהיא בשלה את העצמות פעמים. אפילו קליפות
        אבטיח לא זרקה, היתה עושה מזה חמוצים. מאד הערכנו אותה ואהבנו אותה. אבל
        ילדות, מה ידענו. היינו מתאספות, מקלפות שקדים, גומרות יום והולכות הביתה
        בשביל להרויח כמה גרושים.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >פעם אני זוכרת היא פנתה לאמא שלי ואמרה "עוידה, לדבורה אל תדאגי, תני לה
        ללמוד, אל תוציאי אותה מבית הספר, אני אשלם בשבילה". וכך היה. היא שלמה
        לבית הספר כל מה שמגיע ממני כל השנים. היא פשוט הצילה אותי. ואני לא אשכח
        לה לעולם".</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        ><br />"כך גדלנו, על עוני ועל מחסור. אך יש להגיד הדור של הורינו פשוט
        גדלו על תרבות עמוקה ויקרה. כל הנוער שלנו, אף אחד לא יצא לתרבות רעה. כלם
        גדלו, עבדו, היה קשה, לא כמו היום. היום באמת הלב כואב כששומעים איזה
        חגיגות, מעשים לא הוגנים מהעדה וזה כואב לכל אחד ואחד מאתנו.</span
      >
    </p>
    <p class="c1 hand" dir="rtl">
      <span class="c7 c3"
        >"ההורים הכניסו בנו מה שהם ספגו במשך דורות בגלות והם חיו, גדלו על התורה,
        גדלו על מעשים טובים, גדלו על מוסר. גם היו מספרים שבתימן ביום ששי מתי
        שהנשים עשו לחי – זה המאכל לשבת – כל אשה שיש לה אפשרות היתה מובילה לבית
        עני. גם קצת בשר גם לחם כזה. ואנחנו גדלנו על המוסר של ההורים שלנו. לנו לא
        היה בעיות אפילו עניות לא יצאו לתרבות רעה. האנשים אצלנו השלימו עם מה שיש
        כי ההורים היו כאלה, ככה גדלנו."</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אחרי נשואיה, ואחרי שנולדו ילדים, המשיכה דבורה ללמוד, והיתה למורה
        לבישול.</span
      >
    </p>
  </body>
</html>`;

export default page;
