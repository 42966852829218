const page = `<body class="c7 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c1"
      >&#39;בן כהן&#39; הוא צבי בן-כהן בעלה של אפרת בת נחמה פוחצ&#39;בסקי. עשהאל
      הוא אחיה של אפרת. חיים הוא חיים פוחצ&#39;בסקי אחיו של יחיאל מיכל. מרים
      אשתו.</span
    >
  </p>
  <br />
  <p class="c2" dir="rtl">
    <span class="c0"
      >א&#39; חנוכה תרפ"ד 1923                                                  
           לגולדה יאפו</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >                                                                         
                 ידידה</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">ידידתי היקרה גולדה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">מזמן לא נתקבלו ידיעות ממך, וגם מזמן לא כתבתי    </span>
  </p>
  <p class="c6" dir="rtl">
    <span class="c0">אבל זו לא אשמתך.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אתם בקיץ הייתם אצלי וראיתם שאני תמיד עסוקה בעבודה אם כי עבודה לא כל כך
      חשובה, אבל לצערי יש ביממה רק 24 שעות וזה מעט ולא מספיק לאנשים שלא יודעים
      להתארגן.</span
    >
  </p>
  <p class="c2 c4" dir="rtl">
    <span class="c0"
      >אצלנו בראשון ואצלי בפרט יש שינויים. אתם ודאי שמעתם שבן כהן יצא מ"תרבות"
      מסיבות שונות, ורוצה להתישב (להתנחל) בראשון. בקיץ הם שוקלים לבנות בית
      ואנחנו הסתדרנו טוב מאד. פתחנו דלת מיוחדת בטרסה לזקנים והזוג הצעיר יש לו
      החדר הקטן לחדר שינה. אפרת מאד מרוצה. בירושלים היא כבר הרגישה זרה ולא
      במקומה. אני מקוה שיהיו במשך הזמן מתישבים טובים.</span
    >
  </p>
  <p class="c2 c4" dir="rtl">
    <span class="c0"
      >עם עשהאל יש גם כן שינויים גדולים הוא חזר לעבודה בחריצות גדולה ואנו נתנו
      לו את כל הגנים לעיבוד. וכן אנו נותנים לו האדמה בסרפנד שמאד נחוצה לבהמות
      וגם חלקה טובה לשתול טבק. הוא גם עִבֶד את הגן של מרקוס ואני מקוה שיעביר גם
      שנה זו. יש לו כל האפשרויות להסתדר. הוא רק צריך לרצות. וזאת אני מקוה
      שיהיה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">כמו בשנות הנעורים. </span>
  </p>
  <p class="c2 c4" dir="rtl">
    <span class="c0"
      >העול עלי כעת עוד גדול יותר וקשה יותר ובכל זאת אני מרוצה שילדי חזרו לקרקע
      ועמל שעמלנו במשך שנים לא ילך לאיבוד בזקנתנו וכאשר נהיה יותר חלשים.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >דבר נוסף שקרה במשפחה שהאחות של בן-כהן שבקיץ כבר היתה מסודרת בעיר גנים לא
      מזמן נשרפה <span class="italic">[נכותה]</span> וגרה גם כן אצלנו.
    </span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">לנחום דאסטין יש מקום לכלם. אתם תראו איך זה אצלנו.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >ועוד עניני משפחה: שפרה אידלמן לוקחת חתן טוב מאד, איש עשיר שתומך כעת בכל
      המשפחה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >לשלוש הבנות של חיים: רחל, ידידה ותרצה יש חתנים טובים ולמרים, האם הנבוכה,
      יהיה כבר קצת נחת.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c3"
      >מיונדב אין לה נחת. הוא זרק את המשרה בכרמל, רצה ללמוד וכעת גם זה נמאס לו.
      והוא מחפש הלאה ------------- </span
    ><span class="c1 italic">[עמוד 4 למעלה חתוך]</span
    ><span class="c0"> ויגרום שמחה להוריו הזקנים.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >נו די, כעת מה נשמע אצלכם? מה שלום מרים ואיך אליעזר? עליזינקה גם כן כבר
      ילדה גדולה ולכם יותר קל. איך הרומטיזם שלך? אני מקוה שלאחר הפעם ה-2 בטבריה
      המחלה חולפת. אני מבקשת לכתוב על הכל ולא לשכח אותנו.</span
    >
  </p>
  <p class="c2 c5" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">ידידתכם הטובה</span>
  </p>
  <p class="c2 c5" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">נ. פוחצבסקי  </span>
  </p>
  <p class="c2 c5" dir="rtl"><span class="c0"></span></p>
</body>`;

export default page;
