const page = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c9 doc-content">
    <p class="c3 italic" dir="rtl">
      <span class="c6 c0 c8 bold">העולם </span
      ><span class="c0 c1"
        > 18.2.1930 (כרך י"ח גל&#39; ח  עמ&#39; 160). בשנה זו השבועון (עיתונה
        הרשמי של ההסתדרות הציונית העולמית) יצא לאור בלונדון.</span
      >
    </p>
    <h3 class="c10" dir="rtl" id="h.m2x6u6jtbkvx">
      <span class="c4"
        >נחמה פוחצבסקי, בכפר ובעבודה, ספורים. הוצאת "הדים" תל-אביב תר"ץ.</span
      >
    </h3>
    <p class="c3" dir="rtl">
      <span class="c0"
        >        המספרת מכניסה אותנו לפני ולפנים של החיים בכפר העברי שבא"י, הישן
        והחדש; זה שהמוטיב העקרי בחייו הוא הביסוס החמרי של כל פרט לעצמו, וזה
        שבאוירו נשאת תמיד המנגינה החרישית של חדוות היצירה הלאומית, של בנין העתיד
        לעם ולאדם. ואם כל עצם ההתישבות וההתבססות של עם שב מגלותו אל שממון-דורות
        הוא מפעל-גבורה אשר ימים יבואו ואבות לבנים יספרו עליו בתמהון והערצה כעל
        מעשי נסים, הרי בתוך אותו מפעל-הגבורה ישנם גם סבל ותלאות של יחידים,
        המתלבטים בתנאי חיים, אשר עלו להם בגורלם המיוחד. יש שהתלאות והיסורים הם </span
      ><span class="c0 c6 bold">בידי שמים</span
      ><span class="c0">, בגלל מקרים ואסונות, ויש שהם </span
      ><span class="c6 c0 bold">בידי אדם</span
      ><span class="c2 c0"
        > – בגלל קשורי גורל מעוקלים בין אנשים בלתי מתאימים זה לזה, בגלל רעת לב
        אדם, בגלל חנוך רע ובגלל דעות נפסדות המושרשות בלב ואין כוח להתעלות עליהן
        ולכבשן. הרשימות הכלולות בתוך הספר הזה מגוללות לפנינו פרשות חיים של בני
        אדם עלובים ומעונים בעטי אותם הגורמים השונים, שזולת הסבל הכללי התלוי
        בתנאי המפעל הלאומי הנעשה שם מתוך מאמצים לא-אנושיים, הוטל עליהם עוד לשתות
        כוס תמרורים מיוחד שלהם ונפשם הולכת וכלה מן הארס שנמסך לתוך חייהם
        המיוחדים. בכשרון רב יודעת המספרת לחרוז את המחרוזת של פגעים קטנים
        ומאורעות פעוטים ואפורים הצומחים יום יום ורגע רגע בחיי בני אדם, והם
        מהווים את הטרגדיה הגדולה של חייהם. היא יודעת לאסוף יחד קווים בודדים מחיי
        איש ומשפחה, מיחסים בין איש ואשתו, בין אח ואחות ובין חברים, שכל אחד מאותם
        הקווים לבדו, כאלו אין לו שום ערך, כאלו אינו אלא שרטוט מקרי, אך בהאספם
        יחד הנה הם מהווים לפנינו תמונה בהירה וחיה ומרעידה כל מיתרי לבנו.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2"
        >        ויש גם רעיון מרכזי אחד העובר על פני כל הרשימות האלה: - גורל
        האשה. יחס הגבר אליה במשפחה ובצבור, סבלותיה ומצוקותיה, שפלותה ודל כבודה
        והעלבונות המנויים לה תמיד ובכל מצב. "רק סבל ושתיקה מנת חלקה". היא נעלבת
        יום-יום רגע-רגע – ועליה נטל לסבול את עלבונה בדומיה. כי קשה אפיו של הגבר
        בכלל, ויש ביניהם כאלה, שגם כאשר אוהבים את האשה, הם מוצאים להם תענוג
        מיוחד לענותה ולהציק לה. הם חושבים שהכל רק בשבילם ולהנאתם ויש מהם שמהפכים
        את נשותיהם לשפחות חרופות להם ממש. הם מעליבים אותה בתנועות, במלים,
        בבטויים דוקרים על כל דבר קטן שלא נעשה כחפצם, או גם כשאין היא אשמה בזה
        כלל. מעליבים "עד לידי הרתחת הדם". והיא – "יש רצון למחות, לענות, אבל המלה
        נעצרת על השפתים, ורק הלב מתחיל דופק בחזקה עד להתפלץ"... וגם כשהנשים באות
        לבית הועד להשתתף בישיבה ושומעות את פטפוטי המלים של הגברים, שאין להן שחר,
        הרי הן רק "מבקרות בחריפות" ביניהן לבין עצמן, "אבל להשמיע את קולן אינן
        מעיזות". מפני שהאשה "משוללת זכות אדם בכלל, ובאיזו רשות היא נדחקת לתוך
        הצבור לחוות דעה? הרי זוהי רק חוצפא מצדה! מקומה של האשה במטבח מאחורי
        הכירים ולא בין נבחרי העם"... ולא רק בצבור האכרים נחשלי התרבות, המשועבדים
        למשפטים קדומים, אלא גם בקבוצה ובתוך החיים המתקדמים והשואפים לתקנת העולם,
        אין האשה שווה לגמרי בזכיותיה עם הגבר. ואין עבודתה ועמלה נערכים בשווה עם
        עבודת ועמל הגבר. "אין שוויון גם בחיי הקומונה. כל אחריות המשק, ההנהלה,
        עבודת הפלחה והנטיעות – כל זה הוא בידי החברים בלבד. ובמה עסוקות החברות?
        ממש באותן העבודות שבנות בעלי-בתים עסוקות בהן. מהו, איפוא, שנוי הערכין
        שהכניסה הקבוצה לחיי הצעירה?..." (בצל הקבוצה).</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0"
        >        המספרת מעבירה לפנינו גלריה של טפוסי-נשים וסבלותיהן. נשים
        משכילות, שואפות אל על, נושאות בנפשותיהן משא וחזון לעמם ולכלל האנושות, -
        והן אובדות בעֹֹוניין ובמצוקותיהן, ונרמסות בגסותו של הגבר העריץ. ואחת היא
        אם הגבר הוא בעל או אח – תמיד ידו על העליונה...</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0"
        >        ומאין נובעת אותה ההכנעה של האשה ונכונותה לשאת את עולה ולסבול? –
        מן האהבה. רק האשה יודעת לאהוב באמת ולהביא קרבן לאהבתה את כל עצמה. רק היא
        אוהבת ומוותרת על כל עצמה בשביל הנפש האהובה לה, בעוד שהגבר הוא תמיד אוהב
        רק את עצמו ודורש קרבנות מאחרים... ספוריה של פוחצבסקי הם מסות פסיכולוגיות
        רבות-ענין במקצוע זה, וכאן גם ערכן האמתי.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2 c0"
        >        אכן מרהיבים הם גם תאוריה מחיי הכפר העובד, הדמויות של האדם
        והבהמה והנוף. רק חסרון אחד יש לה – האריכות. יש שהיא נותנת לנו דמות
        ורעיון הצודים את כל לבבנו, אבל היא הולכת וחוזרת על אותה הדמות ועל אותו
        הרעיון עצמם דף אחרי דף, ולפעמים אפילו באותן תמונות-הדבור עצמן – ואז כבר
        הקורא מתעיף ומרגיש איזו הכבדה ורצון להסתלק מזה לגמרי. אם תשמע הסופרת
        לעצה טובה ותמנע עצמה בעתיד מן החסרון הזה, יהיה הדבר רק רווח גדול בשביל
        יצירותיה.</span
      >
    </p>
    <p class="c7 text-end" dir="rtl">
      <span class="c2 c0">קורא ותיק [כנראה משה קליינמן]</span>
    </p>
  </body>
</html>`;

export default page;
