const page = `<body class="c0 doc-content">
  <p class="c1 italic" dir="rtl">
    <span class="c6 bold">דבר</span><span class="c3"> 26.6.1934</span>
  </p>
  <p class="c1 italic" dir="rtl">
    <span class="c3"
      >כתבה זו הופיעה במרכז העמוד בתוך כתבה של רחל כצנלסון שזר של הספד וביקורת
      על כתיבתה של נחמה פוחצ&#39;בסקי ואין ספק שהיא מתייחסת אליה</span
    >
  </p>

  <br />
  <h3 class="c9 text-center" dir="rtl" id="h.9306vkj7isyl">
    <span class="c8">לזכר אדם יקר</span>
  </h3>

  <p class="c1 c4" dir="rtl">
    <span class="c2"
      >נגזל מקרבנו אדם יקר ומסור לצבור הפועלים, אשר תמיד התענין בנו ותמיד היה
      מוכן לעזור לנו בשעת הצורך.</span
    >
  </p>
  <p class="c1 c4" dir="rtl">
    <span class="c2"
      >על ידה מוכרח היה האדם להיות טוב. היא משכה אחריה בכוח הנוער שתסס בה למרות
      הזקנה. היא היתה מארגנת רבת-כשרון, פשוטה ולבבית עם כל אחד. תמיד היה לה פנאי
      להקשיב לאדם שבא לשפוך לפניה את מרי לבו. בשאלת העבודה העברית לא ידעה ויתור
      ולא היתה סולחת לאלה אשר חטאו נגדה.</span
    >
  </p>
  <p class="c1 c4" dir="rtl">
    <span class="c2"
      >לא אשכח את שמחתה, כאשר ראתה את השלט על המסעדה הקואופרטיבית שלנו. היא שמחה
      למפעל חדש ולאפשרות של עבודה חפשית לחברה.
    </span>
  </p>
  <p class="c1 c4" dir="rtl">
    <span class="c2">אין תמורה לאדם הזה.</span>
  </p>
  <p class="c1 c4 c5" dir="rtl"><span class="c2"></span></p>
  <p class="c1" dir="rtl">
    <span class="c2">ראשון-לציון</span>
  </p>
  <p class="c7 text-end" dir="rtl">
    <span class="c2">בת שבע</span>
  </p>
</body>`;

export default page;
