import { configureStore } from "@reduxjs/toolkit";
import fromReducer from "./reducers/fromReducer";

const store = configureStore({
  reducer: {
    from: fromReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
