const desc = `<html>
  <head>
    <meta content='text/html; charset=UTF-8' http-equiv='content-type' />
  </head>
  <body class='c1 doc-content'>
    <p class='c2' dir='rtl'>
      <span class='c0'> פורסם בעיתון </span>
      <span class='c4 bold'>האור</span>
      <span class='c0'>
         ביום ג&#39; י&#39; תמוז אתתמ"ג לחרבן [תרע"ב - 25.06.1912].
      </span>
    </p>
  </body>
</html>`;

export default desc;
