const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c13 doc-content">
    <p class="c1 italic" dir="rtl">
      <span class="c6 c5"
        >זהו מכתב מנחמה פוחצ&#39;בסקי בראשון לציון לאחיה יעקב פיינשטיין, שכעבור
        זמן קצר עלה לארץ והיה מראשוני אכרי רחובות.
      </span>
    </p>
    <p class="c1 italic" dir="rtl">
      <span class="c5">המכתב פורסם בעתון </span
      ><span class="c2 bold">המליץ</span
      ><span class="c5 c6"> יום ה&#39; כ&#39; בניסן תר"נ, 10 באפריל  1890</span>
    </p>
    <p class="c1 c16 italic space-bottom" dir="rtl">
      <span class="c5"
        >הארוע המתואר במכתב, חגיגת ייסוד רחובות, התרחש במהלך השנה הראשונה לחייה
        של הכותבת בארץ ישראל (הנתונה תחת שלטון עותומני) שהיא גם השנה הראשונה
        לנישואיה. אותו ארוע מתואר (כמובן, באופן שונה ובדגשים אחרים) גם
      </span>
      <a href="/testimonies/onTheFirstYearInIsrael#rehovotSelebration">
        <span class="c5 c9 tooltip"
          >בזכרונותיו של בעלה של הכותבת<span class="c3 tooltiptext"
            >ראו קטע מתאור זה במדור העדויות.
          </span></span></a>      
      <span class="c5"> וכמו כן </span>
      <span class="c5 c10 modal-button" id="modal-button-1">בסיום הספד שכתב שמואל כהן במלאת 30 למותה</span><span class="c2">.</span>
    </p>
    
    <p class="c1 bold" dir="rtl">
      <span class="c7">העתקה ממכתב אחת מבנות ציון לאחיה, בשפת עבר </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c4">ראשון לציון י"ח אדר תר"ן  </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c4">אחי היקר!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c4"
        >הנה זה כבר לא ערכתי אליך דברים ארוכים כחפצי, יען מה? יען כי זה שבועות
        אחדים עסוקה אנכי בדברים של מה בכך. בתחילה עזבתי אנכי את ביתי ואחרי כן
        התארחו אחרים בביתי, וכן עברו לי יותר מירח ימים באפס מעשה. אבל בשבוע זה
        אקוה כי אמלא את כל מאויי לבבי. היום הזה הוא היום השני ועד הפאסט נשארו לי
        שלשה ימים תמימים. ואקוה כי תעלה בידי לערוך מכתבים אחדים לשעשע את נפש
        אוהבי. אליך באתי בתחלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4"
        >הנה חדשות נקרו בארצנו, חדשות אשר תשמחנה לב כל אוהבי שערי ציון. יוכל
        היות כי את החדשות האלה תדע טרם תקבל את מכתבי. אבל יודעת אנכי את חובתי
        להשמיעך אותו גם מפי עטי. ואקוה כי דברי יעשו רושם טוב עליך ועל כל אשר
        יקראם. אף כי לא אשתדל לתאר את המחזה בצבעים יותר מאירים מאשר התיצב לפני
        עיני.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4"
        >ה&#39; יהושע חנקין אישה של אלגא בעלקינד קנה זה לא כבר כברת ארץ גדולה בת
        עשרת אלפים דונאם כדרך חצי שעה ממושב "ועד-אל-חנין" מעבר האחד, וכשלשה רבעי
        שעה מ"עקרון" היא "מזכרת בתיה" מעבר השני. שם האחוזה הזאת בפי הערבים
        "דוראן", ועסקי בעליה, אחד עשירי הערבים, אלצוהו למכרה. אדמתה נודעת לשמנה
        ופוריה הטובה לנטיעת גפנים וגם למזרע תבואה. ה&#39; חנקין ימכור את האדמה
        לחלקים, ואחרי אשר יש כבר רשיון לבנות עליה בתים כמה שיחפצו, נקוה כי בעזר
        ה&#39; יוסד שם במהרה מושב חדש.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4"
        >ביום הפורים נסענו כלנו מהמושבות הקרובות למקומנו החדש הזה, ששמה באו
        ויתאספו כמאה וחמשים איש. מראשון באנו בחמש עגלות אשר רק הנשים והטף ישבו
        בהן, והגברים הולכים ברגל מזה ומזה, ורוכבים בסוסים לנו מפנים ואחור. על כל
        הדרך מועד- אל-חנין עד דוראן שרו הגברים משירי ציון, ובקרבנו אל מחוז חפצנו
        ירו הצעירים מקני-רובה, וקול הידד נשמע עד למרחוק. כשעה אחרי בואנו שמה באו
        גם הגברים, וטרם עוד עברנו הנה והנה לראות את פני האחוזה והצו נתן להתאסף
        כלנו אל מקום אחד. אנכי ומיכל אישי היינו אז הרחק מהמון העם על ראש גבעה
        הנשקפת על פני ככר נחמד המשתרע על הדרך העולה "גדרה", ובראותנו כי העם
        יתאסף אל מקום אחד מהרנו גם אנחנו שמה, ובבואנו מצאנו את כל הגברים יושבים
        על הארץ במעגלה סביב. לפני איש אחד – אייזענבערג שמו – הקריבו כוס יין,
        והוא פתח את פיו בדרשה נאה ונעימה, ויעביר לפנינו בקצרה את גבורת המכבים,
        את ימי גלותנו הארוך ואת תולדות התחיה החדשה הנראה עתה בקרבנו ותקותיה
        הנעימות. הוא דבר ברגש ובטוב טעם עד כי עורר כל לב ועינים רבות נזלו דמעות.
        ככלותו לדבר מחאו כלם כף ויחד ההרים ענו הידד לקראתו. אחריו נשאו מדברותיהם
        עוד אנשים אחדים, ואז החל המשא והמתן איך לקרא בשם את המושב החדש. כל איש
        חוה את דעתו בדבר הזה ויכנה שם, אך בבוא התור לישראל בעלקינד הביע הוא את
        חפצו לקרוא את המושב החדש בשם "רחובות" לאמר: עתה הרחיב ד&#39; לנו ונאחזנו
        על אדמת ציון בלי כל מכשול ומפריע. השם הזה מצא חן בעיני כלם ובפה אחד ענו
        כל העם ויקראו: "תחי רחובות! תחי רחובות"!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4"
        >עוד כשעה התמהמהנו במקום הנחמד ההוא, עוד ברכות למאות נשמעו מכל פה, ואחרי
        כן שבנו על עקבינו ונלך איש איש למקומו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4"
        >האם לא מחזה נעים הוא, יעקב יקירי? האם לא שוה לתת את כל מחמדי ארץ המערב
        בעד עונג אחד כזה? - - -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4">היה שלום, אחי יקירי, כחפץ אחותך אוהבתך בכל</span>
    </p>
    <p class="text-left" dir="rtl">
      <span class="c4">נפ"ש</span>
    </p>    
  </body>
</html>`;

export default page;
