const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <p class="c3 italic" dir="rtl">
      <span class="c0 c2 bold">הצבי</span
      ><span class="c6 c1 c2"
        >   ה באייר תר"ן  25.4.1890  (גליון י&#39;) -  בעמוד השלישי של העיתון
        מופיע הקטע הבא:</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0">ירושלם</span
      ><span class="c1"
        >. בימי חג הפסח לא זכינו כמעט כלל לעולי-רגל מבני ישראל. ממתי המעט שבאו
        נזכיר: הרב החכם הר"ו יעבץ, רב במושבת זכרון יעקב, האדון קרלינסקי מרוסיה,
        שבא לא"י לקנות לו אחוזת נחלה,
      </span>
      <a href="/herArticles/zionPilgrims">
        <span class="c1 c5 tooltip"
          >ושָיָרה קטנה מראשון לציון<span class="c0 tooltiptext"
            >ראו כתבתה של פוחצ&#39;בסקי על ביקור זה</span
          ></span
        ></a><span class="c1 c4">:</span>
      <span class="c1"
        >האדון דוד יודילביץ וארוסתו, ה&#39; פוחאצ&#39;בסקי ורעיתו, ה&#39; ליובמן
        ורעיתו. כל אלה מדברים עברית איש ואשתו, ובפרט הגברת&#39; פוחאצ&#39;בסקי
        היודעת היטב לשון עברית</span
      >
    </p>
  </body>
</html>`;

export default modal;
