const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c7" dir="rtl">
      <span class="c0">חתום: נ. בת צבי  </span><span class="c4 bold">המבשר</span
      ><span class="c0"> </span><span class="c0 c6">(קושטא)</span
      ><span class="c0">  יב תמוז תר"ע  [1910 07 19] </span
      ><span class="c1">[</span
      ><span class="c0">שנה א גל&#39; כח  עמ&#39;  438-440 ]  </span
      ><span class="c1 italic">לא הצלחנו להשיג</span>
    </p>
    <p class="c2" dir="rtl"><span class="c3"></span></p>
  </body>
</html>`;

export default desc;
