const page = `<body class='c10 doc-content'>
  <h3 class='c2' dir='rtl'>
    <span class='c7'>מרדכי פאדובה</span>
  </h3>
  <p class='c2 italic' dir='rtl'>
    <span class='c4'>
      מרדכי פאדובה היה המורשה של חובבי ציון בבריסק ד&#39;ליטא והוא ששידך בין בני
      הזוג פוחצ&#39;בסקי.
    </span>
  </p>
  <p class='c2 italic' dir='rtl'>
    <span class='c4'>
      המכתב התחיל להכתב ב-14.10.1889, כחודש ימים אחרי עליתה של נחמה ארצה בעקבות
      נישואיה ליחיאל מיכל שלמה זלמן פוחצ&#39;בסקי.
    </span>
  </p>
  <p class='c2 italic' dir='rtl'>
    <span class='c8'>המכתב מופיע בספרו של אלתר דרויאנוב</span>
    <span class='c11'> כתבים לתולדות חיבת ציון וישוב ארץ ישראל</span>
    <span class='c4'> (אודסה תרע"ט), כתעודה 1401.</span>
  </p>
  <p class='c2 c5' dir='rtl'>
    <span class='c0'></span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c0'>                ג&#39; דחוהמ"ס תר"ן, ראשון לציון</span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c0'>ידיד היקר והנכבד ה&#39; פאדובה,</span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c9'>
              הפלא ופלא! מאז בואי ראשונה אשתדל להעריך מכתב אליך ולא עלתה בידי.
      כמה פעמים לקחתי גיליון, עט ודיו, ישבתי לפני השלחן, החילותי לכתב והנה
      ענינים רבים אחרים באים לפני ויפריעוני ממלאכתי זאת. לוא הגיד לי איזה איש
      בנסיעה מבריסק, כי בבואי לארץ-ישראל תעבור ירח ימים ולא אכתב לך, אז ירקתי
      בפניו! ועתה הנה הדבר כן הוא בלי כל אמצעים. אמנם כמעט לא אאמין כי עדנה לא
      כתבתי לך, כי איך יכולתי לעשות זאת? אבל האמינה לי אדוני, כי אין כל אשם בי.
      טרודה אנוכי מיום בואי כל כך עד כי כמעט זרים לי עוד דרכי ציון. מאום לא
      קראתי. גם את מזג [?] המכתבים להורי ולאחי לא מלאתי כחפצי. כמה פעמים בכל יום
      יעלו על לבי דברי המשורר הגדול להאשה העבריה: "... תאפי, תבשלי ובלא עת
      תיבולי". וזאת היא תשובתי להשואלים אותי שבע בכל יום מדוע לא אכתב מאומה מארץ
      ישראל. גם בן יהודה ישאל מדוע לא אכתב? האם ארץ ישראל לא עשתה עלי כל רושם?
      כאילו לא ידעו גם זה כי אשה עבריה לא נוצרה לכתב ולברא רעיונות,
    </span>
    <span class='c9 c12 tooltip'>
      היא בחושך באה ובחושך תלך
      <span class='tooltiptext'>
        
          <span class='c1'>אישה עבריה מי ידע חייך</span>
        
        
          <span class='c1'>בחושך באת ובחושך תלכי...</span>
        
        
          <span class='c1'>תהרי תלדי תיניקי תגמולי</span>
        
        
          <span class='c1'>תאפי ותבשלי ובלא עת </span>
        
        
          <span class='c1'>תבולי.</span>
          <br/>
        
          <span class='c1'>י"ל גורדון</span>
        
      </span>
    </span>
    <span class='c0'>. </span>
  </p>

  <p class='c2' dir='rtl'>
    <span class='c0'>לכן ידידי בל תדרשו ממני הרבה, כי לא אוכל לעשות.</span>
  </p>
  <p class='c2 c13' dir='rtl'>
    <span class='c0'>כ"ד תשרי</span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c9'>
              ראה ידידי, שבע ימים עברו מעת אשר החילותי את מכתבי זה אליך; שבעה
      ימים לא יכולתי להוסיף אף הגה אחת. אמנם ימי חגים היו הימים, ימי אורה ושמחה.
      טרודה הייתי כל ימי החג
    </span>
    <a href='/testimonies/onTheFirstYearInIsrael'>
      <span class='c3 tooltip'>
        בקבלת האורחים בביתי.
        <span class='c1 tooltiptext'>
          ראו גם תאורו של בעלה, מיכל פוחצ&#39;בסקי על תקופה זו
        </span>
      </span></a>
    <span class='c0'>
      ביחוד היו אצלי אנשים לרוב ביום האחרון של החג (ואצלכם הוא לפני האחרון)
      וביניהם היו ה&#39; יודלביטש, המורה מעקרון והוא הכותב מאמרים ב"המגיד"
      מארץ-ישראל. משנה עברה כתב &#39;מסתרי יפו&#39; ועתה כותב הוא &#39;מסתרי ארץ
      ישראל&#39;. גם המורה מזכרון יעקב וציפר שמו היה עם כלתו אשר ארש לו זה לא
      כבר מירושלים. ועוד אנשים לרוב עד כי נלאתי ביום ההוא מרב טרדה ועבודה. יין
      דארץ-ישראל, כמובן, נשפך אז כמים. שתינו לחיי אחינו ברוסיא ולחיי אחינו פה
      והיה לנו שמחה, ששון ויקר.
    </span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c9'>
              שָיָרה גדולה כשלושים איש הלכו בחוהמ"ס זה ירושלימה - - - מי אשר לא
      ראה את השירה בהלכה מראשון, לא ידע מה יקר זכרון ירושלים לבן ישראל. בערב יום
      הראשון של החג בשעה העשירית עליתי לרחוב הגדולה של הקלניא
    </span>
    <span class='c8'>[המושבה]</span>
    <span class='c9'>
      . שם בראש הרחוב עמדו שלושה עגלות עמוסות אנשים נשים וטף, רבים מהנוסעים הלכו
      עוד בחוץ הנה והנה. שפת קודש היה על לשונם והכרת פניהם ודבריהם ענו בהם איך
      תיקד בקרבם האהבה לציון ולירושלים. - - - העגלות העתיקו ממקומם והנה כולם פה
      אחד החלו לשיר את השיר: "ראשון-לציון עיר כוננה ידינו..." ומכל העברים נשמע:
      לכו בשלום, דרשו את שלום ירושלים! ביצאם את העיר ירו מכלי קלע, וקול מנגינתם
      נשמע עד למרחוק. כל המקרה הזה עשתה עלי רושם חזק כל כך, עד כי לא יכולתי למוש
      ממקומי וכבמסמרות נטועה עמדתי על מקומי זמן רב, ולא ידעתי את נפשי מרוב שמחה,
      אשר מלאה את לבי על כל גדותיו. עוד זיקי אהבה עזה לירושלים לא כבו בלבבנו,
      הרגש ההסתריי
    </span>
    <span class='c8'>[ההיסטורי]</span>
    <span class='c0'>
       עוד חיה יחיה בקרבנו. ומי יודע? אולי יוליד הרגש הזה גדולות ונפלאות אשר לא
      נוכל לשער! אך לוא חי הרגש הזה בלב כל בני ישראל, לולא הגו בני עמנו ריק, אז
      רק אז יכולנו לעשות איזה דבר גדול וטוב, אבל עתה... עתה מי יודע!
    </span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c0'>
              השירה הזאת עשתה רושם עז בירושלים. הם כולם יחדיו הלכו תמיד, דברו
      עברית בחוצות, הלכו לבקר את גאוני ואדירי ירושלים, וכולם קבלו אותם בסבר פנים
      יפות. אך יושבי ירושלים כאשר ראו אותם כן תמהו: &#39;גוים מדברים לשון
      הקדש&#39;. אמנם זה דבר בלתי נשמע עד היום!!! גם אתה ידידי בטח לא שמעת כי
      גוים ידברו בלשון עם קדוש. האמת! גם חזיון ראו צעירנו על הבמה בירושלים.
      תלמידי בתי הספר תחת פקודת המורים והמשגיחים הציגו את זרובבל אשר אותו העתיק
      לעברית ה&#39; יעללין חתן ה&#39; פינס. כל התלמידים מדברים שם היטיב בשפת עבר
      רק בהברה ספרדית, והם עשו על כל השומעים רושם חזק ונורא. אחד הצעירים אשר מסר
      לי את דברי החזיון בכה בכי תמרורים בדברו. כל כך פעל עליו החזיון. מורי בית
      הספר חפצים גם פה להציג את החזיון, אבל לא תעלה בידינו למלאות את החפץ הזה כי
      קשה הדבר ממנו לעשותו, אף כי מדברים אנחנו מעט מעט בלשון הקודש, אבל להציג
      חזיון שלם כבד ממנו הדבר מאוד.
    </span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c9'>
              אם חפץ אתה לדעת אם מדברים אנחנו בשפת עבר, אז דע לך כי כן וכן. אחד
      מהבאים לביתי הוא יודעלאוויטש
    </span>
    <span class='c8 italic'>[דוד יודילביץ&#39;]</span>
    <span class='c9'>
       מדבר כמעט תמיד בלשון-הקדש. שחוק יעשה לנו האיש הזה כי הוא בשכבו ובקומו
      יקרא תמיד: עברית, עברית! אם שומע הוא מדברים זשארגאן
    </span>
    <span class='c8 italic'>[ז&#39;רגון, יידיש]</span>
    <span class='c0'>
      . זה לא כבר חלתה אחת העלמות היודעת שפת עבר. אנחנו כולנו עמדנו אצל מיטתה,
      היא בחומה דברה דברים אשר לא ידעה. היא פנתה אלי וקראה ברוססית: נחמתי,
      יקירתי, אהובתי וכו&#39;... והוא לא יכול שאת דברה רוסית וקרא אליה בקול:
      עברית, עברית! כדרכו תמיד. אז התחממה העלמה עוד יותר והחלה לזעוק ולבלבל
      בלשונה. גם הד"ר מזיא עמד אתנו, והוא התקצף מאוד על יודעלאוויטש וקרא אותו:
      משוגע איש הרוח ועוד בכנוים כאלה. ואנחנו כולנו שחקנו אז מאוד. – אך איך
      שיהי, יודעלאוויטש הביא תועלת גדולה בראשון. כל נערי בית הספר מוכרחים לדבר
      בפניו בשפת קודש. גם הנערות הקטנות יודעות מעט שפת עבר ואנחנו הגדולים
      מוכרחים בבואו לדבר בלשון הקודש. וכן הוא מוצא שכרו בצדו. - - -
    </span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c0'>        הננ ידידתך המוקרת ומכבדת אותך כערכך,</span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c0'>
                                                                      נפ"ש
    </span>
  </p>
</body>`;

export default page;
