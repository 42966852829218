import styled from "styled-components";
//import headImg from "../images/head.png";
import logo from "../images/logoWithText.png";
import appTexts from "../texts/appTexts";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../services/useWinDimentions";

const Header = () => {
  const { height, width } = useWindowDimensions();
  const [menuContainerWidth, setMenuContainerWidth] = useState(true);

  useEffect(() => {
    if (width < 720) {
      setMenuContainerWidth(false);
    } else {
      setMenuContainerWidth(true);
    }
  });

  return (
    <Container>
      <MenuContainer fixedwidth={menuContainerWidth.toString()} />

      <HeaderTitle className='semi-bold'>
        {/* {appTexts.nehamap} */}
      </HeaderTitle>
      <ImageContainer>
        {/* <a href={"/"}> */}
        <HeadImg src={logo} className='App-logo' alt='logo' />
        {/* </a> */}
      </ImageContainer>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  // background-color: #80808078;
  @media (max-width: 720px) {
    margin-bottom: 12px;
  }
`;

const HeaderTitle = styled.h1`
  margin: 0 20px;
  font-size: 3.5em;
`;
const MenuContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-width: ${(props) => (props.fixedwidth === "true" ? "260px" : "initial")};
`;
const Link = styled.a`
  margin: 0 8px;
`;
const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const HeadImg = styled.img`
  height: 256px;
  position: relative;
  top: -28px;
`;
