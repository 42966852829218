const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c5" dir="rtl">
      <span class="c1 bold">המגיד</span><span class="c2">  24.10.1889 </span>
    </p>
    <p class="c5" dir="rtl">
      <span class="c3"
        >   העלמה המשכלת הכבודה נחמה לבית פיינשטיין נִשאה לכבוד משנה הגנן ברל"צ
        האדון מיכל פוחצאסקי בבריסק, ויבאו כעת הנה להשתקע ברל"צ. הגברת נחמה
        פוחצאסקי היודעת ומבינה היטב את שפתינו הק&#39; ואשר כבר נסתה את ידה פעמים
        רבות למשוך בעט סופרים, ידע תדע את חובתה לאמנו הזקנה, העלובה מבנותיה, לעת
        כזאת אשר תחית שפת עבר עומדת בראשית ימי התפתחותה, ובין הידים העוסקות
        ועושות בשקידה את עבודתן במקדש השפה תקח חבל גם היא.</span
      >
    </p>
  </body>
</html>`;

export default modal;
