import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Item from "./Item";

function ItemContainer(props) {
  // console.log("ItemContainer props", props);

  const ref = useRef(null);
  const [preTitle, setPreTitle] = useState("");

  const setItemPreTitle = (itemPreTitle) => {
    if (!preTitle) {
      // console.log("setItemPreTitle !!! ", itemPreTitle);
      setPreTitle(itemPreTitle);

      return true;
    }
  };

  useEffect(() => {
    let nodeDiv = document.createElement("div");
    nodeDiv.innerHTML = preTitle;
    if (ref.current) {
      while (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
      ref.current.appendChild(nodeDiv);
    }
  }, [preTitle]);

  return (
    <Container>
      {props.item.itemPreTitle && <PreTitle ref={ref} />}
      {props.item.itemPreTitle && setItemPreTitle(props.item.itemPreTitle)}
      <Item {...props.item} from={props.from} />
    </Container>
  );
}

export default ItemContainer;

const Container = styled.div``;

const PreTitle = styled.div`
  text-align: right;
  // font-style: italic;
  padding: 0 8px;
`;
