const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c2" dir="rtl">
      <span class="c0">הצבי </span><span class="c1"> 17.5.1889 </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >... סופרת "המליץ" העלמה המשכלת נחמה פיינשטיין מעיר צריצין ברוסיה נתארשה
        להאברך המשכיל מיכל זלמן פוחצוסקי העתיד להיות גנן בקסטינה, כעת עוזר להגנן
        כוילן בראשון לציון. בעוד חודש ימים וחצי יצא החתן לרוסיה לחוג את חג
        הנשואין, והננו מברכים אותו לשוב בשלום עם בת זוגו נחמה פיינשטיין הידועה
        לחובבת ציון בכלל ואוהבת שפת עבר בפרט. ולקחה חבל בעבודתנו קדושה להרחבת
        השפה בפי ישראל בארצנו.
      </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3">חובב ציון שלעמלק"פ.</span>
    </p>
  </body>
</html>`;

export default modal;
