import {
  letters_mainDesc,
  yalag18851889,
  padoba1889,
  yosefVitkin19051904,
  levinEpshtein1912,
  toGoldaAndPesach_modal,
  fromYizhakEpshtein_modal,
  toGoldaYafo,
  fromBialik_modal,
  kidsMagazineEditor_modal,
  toDanielFreski1911_modal,
  toAhiavarInNewYork_modal,
  toDanielFreski1912_modal,
  toDanielFreski1914,
  toGoldBerg,
  fromYechiel1896,
  efratPuhachevski1912,
  efratAndAsael1916,
  fromEfrat1916_7,
  efratAndTzviBenCohen1923,
  yechielMichel1923,
  efratAsaelAndAda1924,
  yechielMichel1926,
  efratBenCohen1927_1933,
  fromYizhakEpshtein,
} from "../pages/letters";

import postcard1 from "../images/letters/postcardA.jpeg";
import postcard2 from "../images/letters/postcardB.jpeg";

const texts = {
  pageUrl: "/letters",
  mainDesc: letters_mainDesc,
  mainDescModalsContent: [
    {
      doc: toGoldaAndPesach_modal,
      isImage: false,
    },
    {
      doc: fromYizhakEpshtein_modal,
      isImage: false,
    },
    {
      doc: fromBialik_modal,
      isImage: false,
    },
    {
      doc: kidsMagazineEditor_modal,
      isImage: false,
    },
    {
      doc: toDanielFreski1911_modal,
      isImage: false,
    },
    {
      doc: toAhiavarInNewYork_modal,
      isImage: false,
    },
    {
      doc: toDanielFreski1912_modal,
      isImage: false,
    },
    // {
    //   doc: toDanielFreski1914_modal,
    //   isImage: false,
    // },
  ],

  items: [
    {
      name: "yalag18851889",
      title: "יל״ג 1889-1885",
      doc: yalag18851889,
      hidden: true,
    },
    {
      name: "padoba1889",
      title: "פדואה 13.10.1889",
      doc: padoba1889,
      hidden: true,
    },
    {
      name: "yosefVitkin19051904",
      title: "יוסף ויתקין 1905-1904",
      doc: yosefVitkin19051904,
      hidden: true,
    },
    {
      name: "levinEpshtein1912",
      title: "לוין אפשטיין  25.6.1912",
      doc: levinEpshtein1912,
      hidden: true,
      modalsContent: [
        {
          isImage: true,
          images: [postcard1, postcard2],
        },
      ],
    },
    {
      name: "fromYizhakEpshtein",
      title: "יצחק אפשטיין 26.8.1921",
      doc: fromYizhakEpshtein,
      hidden: true,
    },
    {
      name: "toGoldaYafo",
      title: "גולדה יאפו  3.12.1923",
      doc: toGoldaYafo,
      hidden: true,
    },
    {
      name: "toDanielFreski1914",
      title: "לדניאל פרסקי  8.2.1914",
      doc: toDanielFreski1914,
      hidden: true,
    },
    {
      name: "toGoldBerg",
      title: "לי״ל גולדברג  14.9.1922",
      doc: toGoldBerg,
      hidden: true,
    },
    {
      name: "fromYechiel1896",
      title: "1896  ראשון לציון – ליטא, מיחיאל מיכל שלמה זלמן פוחצ'בסקי",
      description: "לנחמה ובנם עשהאל ששהו אז ברוסיה",
      doc: fromYechiel1896,
    },
    {
      name: "efratPuhachevski1912",
      title: "1912  ראשון לציון – יפו, אפרת פוחצ'בסקי",
      description: "שלמדה אז בתיכון ביפו",
      doc: efratPuhachevski1912,
    },
    {
      name: "efratAndAsael1916",
      title: "1916 ראשון לציון – יפו, מאפרת ועשהאל פוחצ'בסקי",
      description: "בעקבות לידת הנכדה הבכורה",
      doc: efratAndAsael1916,
    },
    {
      name: "fromEfrat1916_7",
      title: "1916-7 ראשון לציון – יפו, מאפרת פוחצ'בסקי",
      description: "שלמדה אז בסמינר למורים ביפו",
      doc: fromEfrat1916_7,
    },
    {
      name: "efratAndTzviBenCohen1923",
      title:
        "1923 , תרפ״ד ראשון לציון – ירושלים,  אפרת (לבית פוחצ'בסקי) וצבי בן-כהן",
      //   description: "",
      doc: efratAndTzviBenCohen1923,
    },
    {
      name: "yechielMichel1923",
      title: "1923 ראשון לציון – צפת, יחיאל מיכל שלמה זלמן פוחצ'בסקי",
      //   description: "",
      doc: yechielMichel1923,
    },
    {
      name: "efratAsaelAndAda1924",
      title:
        "1924 ראשון לציון – קרית ענבים, אפרת (לבית פוחצ'בסקי), צבי (בעלה), עשהאל פוחצ'בסקי ועדה (אשתו)",
      //   description: "",
      doc: efratAsaelAndAda1924,
    },
    {
      name: "yechielMichel1926",
      title: "1926  ראשון לציון  - בית אלפא, יחיאל מיכל שלמה זלמן פוחצ'בסקי",
      //   description: "",
      doc: yechielMichel1926,
    },
    {
      name: "efratBenCohen1927_1933",
      title:
        "1927, 1933  ראשון לציון – זכרון יעקב (שפיה?), אפרת בן-כהן (לבית פוחצ'בסקי)",
      //   description: "",
      doc: efratBenCohen1927_1933,
    },
  ],
};

export default texts;
