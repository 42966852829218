const mainDesc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c12 doc-content">
    <div style="margin-right: 32px;">
      <a href="#messagesAndCongrats">
        <p class="c3" dir="rtl">
          <span class="c6">הודעות וברכות</span>
        </p>
      </a>
      <a href="#zutot">
        <p class="c3" dir="rtl">
          <span class="c6">זוטות</span>
        </p>
      </a>
      <a href="#reviewsOnHerBooks">
        <p class="c3" dir="rtl">
          <span class="c6">בקורת על ספריה עם צאתם לאור</span>
        </p>
      </a>
      <a href="#reviewsOnHerWritingsInObituaries">
        <p class="c3" dir="rtl">
          <span class="c6">בקורת על כתיבתה משולבת בהספדים</span>
        </p>
      </a>
      <a href="#obituaries">
        <p class="c3" dir="rtl" id="h.gjdgxs">
          <span class="c6">הספדים</span>
        </p>
      </a>
      <br/>
      
      <h3 class="c9" dir="rtl" id="messagesAndCongrats">
        <span class="c2">הודעות וברכות </span>
      </h3>
      <p class="c3" dir="rtl">
        <span class="c0 c5 modal-button" id="page-modal-button-1">על ארוסיה</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c0 c5 modal-button" id="page-modal-button-2">על נישואיה</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c0 c5 modal-button" id="page-modal-button-3">על עליתה ארצה</span>
      </p>
      <br/>

      <h3 class="c9" dir="rtl" id="zutot">
        <span class="c2">זוטות </span>
      </h3>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-4">הצבי</span><span class="c0 c8">  25.4.1890</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-5">השקפה</span><span class="c0 c8">   4.3.1908   </span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-6">הצבי</span><span class="c0 c8">  4.3.1909</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-7">הפועל הצעיר</span
        ><span class="c0 c8 c13">  23.5.1913</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-8">דאר היום</span><span class="c0 c8">  21.12.1920</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-9">דבר </span><span class="c0 c8"> 18.3.1926</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-10">דבר</span><span class="c0 c8">  27.7.1930</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-11">דבר</span><span class="c0 c8">  11.8.1932</span>
      </p>
      <p class="c3" dir="rtl">
        <span class="c1 modal-button" id="page-modal-button-12">דאר היום </span><span class="c0 c8">27.3.1935</span>
      </p>
      <p class="c3" dir="rtl">
      <span class="c1 modal-button" id="page-modal-button-13">דבר  </span><span class="c0 c8">8.1.1937</span>
    </p>
  </div>
  </body>
</html>`;

export default mainDesc;
