import homeDesc from "../pages/home/home.js";

const homeTexts = {
  title: "דף הבית",
  subTitle: "בריסק ד'ליטא 1869 – ראשון לציון 1934",
  mainDesc: homeDesc,
  // paragraphs: [
  //   {
  //     inlineTexts: [
  //       {
  //         text: "עלתה ארצה בשנת 1889 בעקבות נישואיה ליחיאל מיכל שלמה זלמן פוחצ'בסקי, שבאותו הזמן היה מדריך חקלאי מטעם הברון רוטשילד, וחיה כל ימיה בראשון לציון.",
  //       },
  //     ],
  //   },
  //   {
  //     inlineTexts: [
  //       {
  //         text: "אשת ",
  //       },
  //       {
  //         text: "העליה הראשונה, סופרת ופובליציסטית ",
  //         bold: "true",
  //       },
  //       {
  //         text: " בעברית! ",
  //       },
  //       {
  //         text: "איכרה ",
  //         bold: "true",
  //       },
  //       {
  //         text: " שטיפלה במשק הביתי - פרות ותרנגולות, ירקות ופרחים, וייצרה לביתה מוצרי מזון מפרי גנה (גבינות, ירקות כבושים, פירות מיובשים וכד'); ניהלה ",
  //       },
  //       {
  //         text: "בית פתוח ",
  //         bold: "true",
  //       },
  //       {
  //         text: "ומכניס אורחים שהאכיל - מתוצרת עצמית של המשק, והלין - לעיתים על מחצלאות, שולחנות, וספסלים - מסופרים ואנשי ציבור ועד ליתומות חסרות כל; הקימה והפעילה ",
  //       },
  //       {
  //         text: "אירגוני סעד ",
  //         bold: "true",
  //       },
  //       {
  //         text: "שונים; נאבקה ל",
  //       },
  //       {
  //         text: "שוויון זכויות לנשים",
  //         bold: "true",
  //       },
  //       {
  //         text: ", היתה האשה הראשונה שנבחרה, בעיקבות הצלחת המאבק שהובילה למתן זכות בחירה לנשים, ל",
  //       },
  //       {
  //         text: "ועד המושבה ",
  //         bold: "true",
  //       },
  //       {
  //         text: "ומאוחר יותר ל",
  //       },
  //       {
  //         text: "ועד הלאומי",
  //         bold: "true",
  //       },
  //       {
  //         text: ", דגלה ב",
  //       },
  //       {
  //         text: "עבודה עברית ",
  //         bold: "true",
  //       },
  //       {
  //         text: "ויצרה קשרים קרובים עם ",
  //       },
  //       {
  //         text: "פועלים ",
  //         bold: "true",
  //       },
  //       {
  //         text: "אנשי העליה השניה, אירגנה מקומות ",
  //       },
  //       {
  //         text: "מקלט לנפגעות אלימות בבית",
  //         bold: "true",
  //       },
  //       {
  //         text: ", נאבקה על ",
  //       },
  //       {
  //         text: "זכות הבנות להשכלה",
  //         bold: "true",
  //       },
  //       {
  //         text: ", לימדה בעצמה ודאגה למימון לימודים לבנות, אירגנה כיתות לימוד לנשים; היתה ",
  //       },
  //       {
  //         text: "מגשרת ",
  //         bold: "true",
  //       },
  //       {
  //         text: "בשיתוף פעולה עם רב העדה התימנית, ו",
  //       },
  //       {
  //         text: "ושופטת ",
  //         bold: "true",
  //       },
  //       {
  //         text: "במסגרת בית משפט שלום של המושבה; שכלה בן בכור (בן 9 חודשים) מסיבוכי קדחת ובן שני בגיל שנתיים וחצי, גידלה עוד שניים משלה עד בגרות ושימשה כ",
  //       },
  //       {
  //         text: "משפחה אומנת ",
  //         bold: "true",
  //       },
  //       {
  //         text: "ליתומים.",
  //       },
  //     ],
  //   },
  //   {
  //     inlineTexts: [
  //       {
  //         text: "באתר זה אנו מביאים חומרים שלה וחומרים של בני זמנה המתייחסים אליה, במטרה לתת תמונה שלימה ככל הניתן של אישה יוצאת דופן זו. ",
  //         bold: "true",
  //       },
  //     ],
  //   },
  // ],
};

export default homeTexts;
