import styled from "styled-components";
import { useEffect, useRef } from "react";
import closeImg from "../images/x.png";
import "react-image-gallery/styles/css/image-gallery.css";
import "lightbox.js-react/dist/index.css";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";

function Modal(props) {
  //  console.log("modal props", props);

  const ref = useRef([]);
  const navigate = useNavigate();

  let openTime;
  let closeTime;

  useEffect(() => {
    if (props.content.doc) {
      let nodeDiv = document.createElement("div");
      nodeDiv.innerHTML = props.content.doc;
      while (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
      ref.current.appendChild(nodeDiv);
    }
    initLightboxJS("1F31-EB7A-95C7-0D0B", "individual");

    openTime = Date.now();
  }, []);

  let imagesArr = [];
  const getImagesArr = () => {
    props.content.images.map((image) => {
      imagesArr.push({
        original: image,
        thumbnail: image,
        src: image,
      });
    });
    // console.log("imagesArr = ", imagesArr);
    return imagesArr;
  };

  const closeSlide = (e) => {
    closeTime = Date.now();
    const timeDiff = closeTime - openTime;
    //console.log("modal closeSlide timeDiff = ", timeDiff);
    if (timeDiff > 350) {
      props.close(e);
      // window.location.reload();
      // navigate(0);
    }
  };

  if (!props.content.isImage) {
    return (
      <OutLair onClick={(e) => props.close(e)}>
        <Container>
          <CloseBtn onClick={props.close} />
          <div ref={ref} />
          {props.content.isAudio && (
            <audio controls src={props.content.audioSrc} />
          )}
        </Container>
      </OutLair>
    );
  } else {
    return (
      <SlideshowLightbox
        images={getImagesArr()}
        showThumbnails={props.content.images.length > 1}
        open={true}
        lightboxIdentifier='lbox1'
        onClose={(e) => {
          closeSlide(e);
        }}
        showThumbnailIcon={false}
        showSlideshowIcon={false}
        fullScreen={true}
        showFullScreenIcon={false}
      />
    );
  }
}

export default Modal;

const Img = styled.img`
  max-width: 420px;
`;

const OutLair = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #8e8573ba;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  width: 421px;
  max-width: calc(80vw - 32px);
  background: #fff;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  padding: 20px 36px 12px;
  position: relative;
  text-align: right;
  justify-content: center;
`;

const CloseBtn = styled.button`
  background-image: url(${closeImg});
  background-color: #fff0;
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;
const StyledImage = styled.img`
  width: 320px;
`;
