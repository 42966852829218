const page = `<body class="c26 doc-content">
    <p class="c12 italic" dir="rtl">
      <span class="c10 c0 c15"
        >בשנת 1896 לקחה נחמה פוחצ&#39;בסקי את בנה עשהאל, כבן שלש, חזְרה למשפחתה
        ברוסיה ושהתה שם מעל שנה. נראה שנסיעתה של נחמה נבעה משילוב של נסיבות, לא
         רק ממצב בריאותה כפי שכותב מיכל פוחצ&#39;בסקי בזיכרונותיו. מתוכן
        המכתבים, וגם מסיפור שקיים במשפחה, עולה שגם למשבר בזוגיות שלהם היתה יד
        בנסיעתה. הורי נחמה עלו ארצה כשנתיים קודם לכן בעקבות גרוש היהודים מצריצין
        ונראה שגם להם היתה יד בסכסוך בין בתם וחתנם. בכל אופן יש לזכור שבשבע
        השנים שחלפו מאז נישואיהם שכל הזוג שני ילדים וייתכן שהנסיעה היתה ניסיון
        של האם לשמור על הילד השלישי, להגן עליו מפני הקדחת שעשתה שמות במשפחות
        רבות בארץ. אנו מביאים כאן מכתבים ששלח בתקופה זו מיכְל פוחצ&#39;בסקי,
        בראשון לציון, לרעייתו נחמה  ברוסיה. המכתבים נמצאים בידי המשפחה. איננו
        יודעים מדוע קיימים מכתבים רק מהחודשים הראשונים לאחר נסיעתה. לצערנו
        המכתבים ששלחה היא אליו (לפי התלונות במכתביו היו אלא בעיקר גלויות –
        "מכתבים גלויים")  לא נשמרו, אך ממספר ציטוטים שהוא מביא ממכתבים שלה וכן
        מתגובות שלו למה שכתבה אפשר אולי ללמוד משהו על מצבה הקיומי, כמו גם על
        אורח חייהם בראשון לציון.</span
      >
    </p>
    <p class="c12" dir="rtl">
      <span class="c15 c10 c0"
        >להלן מספר פרטים על אחדים מהאנשים המופיעים במכתבים:</span
      >
    </p>
    <p class="c12 italic" dir="rtl">
      <span class="c11 bold">משה ורהפטיג</span
      ><span class="c15 c10 c0"
        > (לימים – אמיתי) היה חבר ילדות של יחיאל מיכל ועלה ארצה יחד איתו אך בתום
        תקופת הכשרתם כמדריכים חקלאיים התיישב קבע ביסוד המעלה וחי שם כל
        ימיו.</span
      >
    </p>
    <p class="c12 italic" dir="rtl">
      <span class="c11 bold">חיים</span
      ><span class="c15 c10 c0"> היה אחיו של יחיאל מיכל ו</span
      ><span class="c15 c0 c19 bold">מרים</span
      ><span class="c15 c10 c0"
        > היתה אשתו של חיים. באותה תקופה חיו בראשון לציון אך בעקבות מצב בריאותו
        של חיים עברו לבירות ופתחו שם אכסניה שרבים מתושבי הארץ התאכסנו בה.</span
      >
    </p>
    <p class="c12 italic" dir="rtl">
      <span class="c11">אסתר</span><span class="c15 c19 c0"> </span
      ><span class="c15 c10 c0"
        >מרגולין, מיילדת, אחותו של הגנרל מרגולין שלימים היה מפקד הגדוד העברי.
        בתקופה זו היא חיה ברחובות,
      </span>
      <a href="/aboutHerInThePressOfHerTime/smilansky">
        <span class="c10 c0 c23 tooltip"
          >והיא ונחמה פוחצ&#39;בסקי היו חברות קרובות<span
            class="c11 tooltiptext"
            >ראו הספד של משה סמילנסקי לשתיהן</span
          ></span
        ></a
      ><span class="c10">.</span>
    </p>
    <p class="c12 italic" dir="rtl">
      <span class="c11 bold">בן יהודה</span
      ><span class="c15 c10 c0"
        > אליעזר, משפחות פוחצ&#39;בסקי ובן יהודה היו בידידות ושיתפו פעולה במלחמה
        על העברית. אליעזר וחמדה בן יהודה אף פירסמו מאמרים של נחמה בעיתוניהם הצבי
        והאור.</span
      >
    </p>
    <p class="c12 italic" dir="rtl">
      <span class="c11 bold">מחַמד</span><span class="c13 c10"> </span
      ><span class="c15 c10 c0"
        > עבד שנים רבות עם פקידות הברון בראשון לציון והיה</span
      >
      <span class="c10 c0 c16 tooltip"
        > ידידו של יחיאל מיכל<span class="c11 tooltiptext"
          >ראו גם מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה זלמן
          פוחצ&#39;בסקי, יד בן צבי, 2013</span
        ></span
      >
      <span class="c13 c10">.</span>
    </p>
    <p class="c12 italic" dir="rtl">
      <span class="c11 bold">חמדה</span
      ><span class="c15 c10 c0"
        > בן יהודה, אשתו (השניה) של אליעזר בן יהודה ושותפה עמו בעבודתו.</span
      >
    </p>
    <br />

    <a href="#21,05,1896">
      <p class="c4" dir="rtl"><span class="c19 c20">21,05,1896</span></p>
    </a>
    <a href="#28.05.1896">
      <p class="c4" dir="rtl"><span class="c3">28.05.1896</span></p>
    </a>
    <a href="#04,06,1896">
      <p class="c14" dir="rtl"><span class="c3">04,06,1896</span></p>
    </a>
    <a href="#25,06,1896">
      <p class="c6" dir="rtl"><span class="c3">25,06,1896</span></p>
    </a>
    <a href="#02,07,1896">
      <p class="c6" dir="rtl"><span class="c3">02,07,1896</span></p>
    </a>
    <a href="#09,07,1896">
      <p class="c6" dir="rtl"><span class="c3">09,07,1896</span></p>
    </a>
    <a href="#10-20,07,1896">
      <p class="c6" dir="rtl"><span class="c3">10-20,07,1896</span></p>
    </a>
    <a href="#16,07,1896">
      <p class="c6" dir="rtl"><span class="c3">16,07,1896</span></p>
    </a>
    <a href="#23,07,1896">
      <p class="c22" dir="rtl"><span class="c3">23,07,1896</span></p>
    </a>
    <a href="#06,08,1896">
      <p class="c6" dir="rtl"><span class="c3">06,08,1896</span></p>
    </a>
    <br />

    <p class="c4 c9 italic" dir="rtl" id="21,05,1896">
      <span class="c15 c10 c0">[21,05,1896]</span>
    </p>
    <p class="c4 c9" dir="rtl">
      <span class="c13 c10">ב"ה ראשון לציון יום ה&#39; ט&#39; סיון תרנ"ו</span>
    </p>
    <br />

    <p class="c4" dir="rtl">
      <span class="c2"
        >נחמתי, נחמתי !.. השלום לך? השלום לעשהאל יקירי ומחמדי אחרית אשרי בתבל.
        איך פעל עליכם מחמדי נפשי הטלטול הארוך הזה. והגעגועים האם ינכרו אותותם
        עליכם. ורוחך! האם נתחדש בקרבך. הבא בך אותות לחיים חדשים. היש תקוה?</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >נורא נורא היה מעת בואי מהאניה עד קבלי ערב חג השבועות לפנות ערב את מכתבך
        הראשונה מפארט סעיד, אשר היה בכוחו להפיח רוח חיים בהעצמות היבשות. אף גם
        המקרה הביאה לי מרפא לפי שעה כי הופיע אצלי ידידי ומכירי משה וורהאפטיג
        וישאר עמדי עד  אתמול אחרי הצהריים, גם ביקרתי עמדו בבית הוריך. הלכתי עמדו
        עקרונה וכן עבר עלי הזמן  בלי התרגשות יתר מדי.</span
      >
    </p>
    <p class="c4 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >אתמול בלילה קבלתי מכתבך השני מאלכסאנדריה אך האמת אגיד ולא אכחד כי לא
        מצאתי בו נוחם. כי מבין שורותיו מבצבץ רוח כהה, אך אקוה כי בקרוב תעבור,
        ורוח נכון יקח את מקומו, ותקותי תתחזק מרגע לרגע כי שנוי  המקום ושנוי
        האויר ושנוי האנשים יפעל עליך הרבה לטוב. מי יתן ותקותי זאת לא תשאר מעל.
        ידיד ביתנו הנאמן עומד על המצפה הכן להביא תרופה ומרפא לכל נכה רוח הוא לא
        יתן לבכות את אמך, והתפעלות אביך יגרש. ועמדי יתגר מלחמה תמיד וגם את בית
        חיים לא ישכח. במוצאי החג הינו הוריך עם אליעזר נאמן ואני עם ווארהאפטיג
        בבית מרים. והיא עשתה לנו מטעמי חלב ושם בילינו העת עד חצי הלילה. את מכתבך
        לאסתר מסרתי בעצמי לידה בהלכי עקרונה אך לא דיברתי עמדה אף מלה כי
        וורהאפטיג אץ לשוב לביתו.</span
      >
    </p>
    <p class="c4 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >התולעת עשתה היזק הרבה מאוד בכרמים אחדים. הצד המערבי מראשון נתנזק יתר
        מכל הצדדים ובכל זאת כרם אביך יצא בכי טוב. כרמינו גם כן לא חסר הרבה אך
        במקומות הקרובים לדרובין נכר מעט ההיזק. עוד עתה לא נדע עוד תוצאות התולעת
        כי הפאפיון עומד להתפתח בימים אלה מן הקראזאלידים הישנים תחת הגפנים ותחת
        כל רגבי העפר הנמצאים בכל הכרמים. עתה מחפשים את הקראזאלידים ומי יודע כמה
        מליָנים טמונים במקום אין רואה?</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >לו ראית פני אסתר עתה כי אז לא הכרת אותה כה שונו פניה בימים אלה. בשובי
        מהאניה מסרו על ידי מכתב אליך מאסתר מכתב מלא רגש, בצרוף ברכות הפרדה
        מהוריה, גם התחרטות אמה על הראיון  האחרון הקר. כמה עגמה עלי נפשי כי לא
        יכולתי למסור עוד המכתב על ידך.</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >מצבי לעת עתה איננו ברע כי עבודה רבה עלי, ואין רגעי פנאי לשבת דומם
        ולחשוב חשבונות של הבל. אף קבלתי עלי להחזיק בבית נקיון תמידי וכל דבר
        במשטר וסדר יהיה כמו את בבית. ולא תתעורר בקרבי  בכל רגע רעיון ההתבודות
        והשממון, וזה באמת יפעל עלי הרבה ויקל מעלי הגעגועים כמעט. בבקר ובערב אסעד
        בביתנו וסעודת הצהרים אצל מרים ואני שבע רצון לפי שעה. (אך לא בכל שעה) .
        נא נחמה יחידתי רוחי ונשמתי. אל תוסיף עוד עתה להכיל רוחך תמיד בקרבך מבלי
        לספר על לב שני את פגעי הזמן הנוגעים בך. נא ונא בשם הפרי הנחמד האוגד
        ואוסר אותנו יחד כי לא תעלימי ממני דבר ממצבך ומצב פרינו זה אשר נתתיו על
        ידך לשמרו מכל משמר כי זה כל נחמתי (דמעות) . לא אוכל עוד יותר לכתוב כי
        אתרגש יתר מדי. חיו בטוב חביבי נפשי ותמצאו מנוחה לנפשכם היגיעה מעמל הדרך.
        ותרופה ומרפא לנפשך  ולגופך את נשמתי ונחמתי!</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span>אלופך  </span><span class="c0 italic">[?]</span
      ><span class="c2"> המכבדך ומוקירך כנפשו </span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c2">מיכל זלמן פאחאטשאווסקי</span>
    </p>
    <p class="c18 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >ברכה ושלום לראש דודינו ודודתינו ובניהם היקרים והנחמדים. אין די מלים בפי
        עטי לתאר לפניכם רחשי לבבי עד כמה תביע לכם תודה בעד ישרת נפשכם וטוב לבבכם
        באספכם לביתכם את כל טובי אשר רכשתי לי בחלדי על תבל. האל הטוב ישלח לכם
        ברכה עם האורחים היקרים בודאי גם לכם</span
      >
    </p>
    <p class="c4" dir="rtl">
      <span class="c2">חיו בטוב ובנעימים כברכת מכבדכם הנ"ל</span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >(לקחתי עט עופרת בידי לראות היעלה בידי איזה רעיון, והנה יצא מכתב זה הלא
        תסלחי? מוקירך)</span
      >
    </p>
    <br />

    <p class="c4 italic" dir="rtl" id="28.05.1896">
      <span class="c8 c0">[28.05.1896]</span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c2">ב"ה פה ראשון לציון יום ה" טז" סיון תרנ"ו</span>
    </p>
    <p class="c4 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c4" dir="rtl">
      <span class="c2"
        >שלום שלום לרעיתי נחמתי ולבני מחמלי ומחמדי עשהאל היקר. מה ואיך שלומכם
        יקירי נפשי לו יכולתי בזו הרגע לדעת כי אז כל אשר לי נתתי בעד העונג הזה.
        אמרתי עונג אבל זה ללא אמת, יותר טוב לו אמרתי בעד חיתי, כי זה שבוע ימים
        חיי אינם חיים וכמו תלויים על שערה אחת. מיום ליום תתמרמר עלי הטבע נדכאתי
        ונחלתי מרוב יגוני וצרת נפשי עיני לא ידעו הפוגות מיום הששי העבר. לא אוכל
        דבר עם איש כי טרם פתחי את פי והנה הדמעות יזולו יזולו. הה! כמה קשה עלי
        פרידתכם ***** בלילה העברה אמרתי הנה קץ חייתי בא באה. כי אחזו התכוצות
        נוראה בבטני ועם זה בא התכוצויות **** גופי רעדה כערך שתי שעות בלי הרף
        וכפשע היה לבוא הקאפנולסית הארורה. אך ידיד ביתינו נעמן עשה לי קאמפריס
        והודיע לחיים ולמרים את המאורע. והם בחצי הלילה היו אצל הדאקטר עם הרוקחים
        והביאו לי סממנים והצילו את חיי מרדת דומה. אומרים כי המחלה הזאת מתהלכת
        עתה בארץ. אך אני יותר מוכשר עתה לקבל פגעי הזמן מאיזה עת אחרת, אך זאת אדע
        ברור, לו כבר קבלתי ממך מכתב כי באת אל המנוחה ואת נמצאת בין שארי בשרינו
        כי אז נח רוחי עלי לגמרא. אך בכל העת הזאת בשערי בנפשי מי יודע גורלכם, מה
        באכם, איפו אתם. עד כמה תסבלו. את חולה, והוא ילד רך. השאלות האי-נפתרים
        האלו ידכאו לעפר נפשי מיום ליום ומי יודע עד כמה עוד.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >סלחי לי נחמתי על כי דברתי אליך דברים כאלה המעוררים אך נוגה, וזאת לא
        יאות לגבר. אך הלא תדעי אותי כי כזה לא אוכל. נפשי ערומה נגדך.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >שבוע עברה נתראתי עם אסתר אצל טרכטינבירג והיא בקשה ממני את אדרסך בודאי
        כתבה לך בעצמה גם בן-יהודה בקש ב"ה *** את אדרסך, לא אדע למה עד כה לא הביא
        דבריך בהצבי.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >התולעת כבר אינה והשמיטירלינג היוצא ממנה כבר נראה בהכרמים. הוא כנראה לא
        יעשה רעה וגם אננו בהמון כי הרבה הרבה משחיתים על הקריזאלידים שלהם.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >על כרמינו ההזק איננו נחשב למאומה וכנראה יבוא פרי הרבה בשנה זו אם לא
        יבואו עליו עוד תחלואים רעים.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >תולעת המשי הנה שתי ימים החלו לעשות הקאקאנים שלהם ועתה הנני חפשי מעט
        מהם. חדשות המושבה למאראויץ נולד בן זה שמונה ימים אתמול היתה חתונת בן
        נפתלי הלל והחופה היתה כידוע לפני בית הכנסת והארקיסטרא עמד על המעקה וכמעט
        כל המושבה היתה שמה ואחר החופה הביא הפאטאגראף את כל הכבודה בסדר נכון ויעש
        תמונה. אם תעלה בידו התמונה הזאת אז הרבה הרבה מחובבי ציון מחוץ לארץ
        ישתגעו עליה. אני נהיתי היום לחבר בארקיסטור. אחרי כי ה&#39; מאירוויץ בקש
        בעצמו ממני לעשות זאת כי מצאו עתה את הארק&#39; עומד על בסיס אי-נכון טרם
        יהיו כל חבריו קאלאניסוים אמיתים כי כעת כבר נמצאים אחדים מהם העוזבים את
        המושבה. בת שליט הצעירה תסע בקרוב בהשוייץ לגמור שמה דקטאר על חשבון פרי
        עשרים דו_אם מכרמיו. הפרי הזה יהיה לה עד גמרה חוק למודה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >בבית הוריך שקט ושלוה על אביך איננה נראה סמני געגעים אך עליה נראה כמעט.
        היא תבוא בכל יום אלי לקחת את החלב כשעבר, ומותר החלב יקח נעמן על חשבון
        האדמיניסט&#39; ואני איך אנ_ע  אחת בכל בוקר אקח לשתות עם הטייה כנראה אהיה
        לעושר מופלג.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >האדון שייד נסע מזה שבוע זו והנחת אבן הפנה על בית הספרים לא נהיתה סבת
        הדבר לא אדע.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >בקשה יש לי אליך נחמתי הואילי למלאותה בהקדם האפשר. עשי לך שמה תמונה ממך
        ומעשהאל יקירי מהפארתאראט קאבינט, אדמה כי בזאת אמצא מעט נוחם.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >בשבוע זו בעת התגברות עלי צרת נפשי וכמעט מאסתי בחיי חיי בודד במועד פתחתי
        ספר המונח נגדי בלי תבונה והנה הופיע שיר אחד לנגד עיני ובו מצאתי כמעט חיי
        אני. לזאת אעתיקיהו פה במכתבי וקראי מעליו מה בלבי פנימה. שם השיר בעל כרחך
        אתה חי! ב"הכנסת".</span
      >
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl"><span class="c2">1.</span></p>
    <p class="c6" dir="rtl">
      <span class="c2">איש זקן יושב בודד במערה</span>
    </p>
    <p class="c14" dir="rtl">
      <span class="c2">מי מעין ישתה, מאכלו פקועות</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">בגד עזים לו הכסות בקרה</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">לבו בו חלל – כי נואש מתשועות</span>
    </p>
    <p class="c6" dir="rtl"><span class="c2">2.</span></p>
    <p class="c6" dir="rtl">
      <span class="c2">הוא גבר לא יצלח עלי החלד</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ובנפשו לא יאמין, באל אין לו שחר</span>
    </p>
    <p class="c6" dir="rtl">
      <span>אין אח לו ורע. </span><span class="c1">אשת וילד</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c10 c13">לגיל אל גילו או לבכות לשבר!</span>
    </p>
    <p class="c6" dir="rtl"><span class="c2">3.</span></p>
    <p class="c6" dir="rtl">
      <span class="c2">לפני פתח רבצו ראתו עיני</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">דומם כאבן, מט גם דל-כח</span>
    </p>
    <p class="c6" dir="rtl">
      <span>מה חי זה יחי עולם </span><span class="c0">[? אלי?]</span
      ><span class="c2"> הודיעני</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ובנפשו רצח לא ישלח שלח?!</span>
    </p>
    <p class="c6" dir="rtl"><span class="c2">4.</span></p>
    <p class="c6" dir="rtl">
      <span class="c2">קראתי, ודבר אזני שמעה</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">גם השיבני – ותשובתו נמרצה</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">מכל עץ היער אח לא אדעה</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">שהניף על גזעו ובדיו _ערצה!</span>
    </p>
    <p class="c6" dir="rtl"><span class="c2">5.</span></p>
    <p class="c6" dir="rtl">
      <span class="c2">כעץ כן אחיה – כי על כן צֻותי</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">עלי כי נועל גם אשא אסבולה</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">וקשי יומי זה אם כי לא בינותי</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">הן פה נטעתי – ובכן איחלה!</span>
    </p>
    <p class="c6" dir="rtl"><span class="c2">6.</span></p>
    <p class="c6" dir="rtl">
      <span class="c2">לא ידי עצבוני תחת שמים</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ולמושב הארץ לי לא אביתי</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">לא נפשי צררתי בצרור החיים</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">כן לבי לא ידאג גם אל אחריתי!</span>
    </p>
    <br/>
    
    <p class="c6" dir="rtl">
      <span class="c2"
        >לזאת נחמתי יקרתי אל נא נדאג הרבה כתבי לי מכתבים המשיבים רוח ונפש וניחל
        כי בא יבוא יום והשלום והחיים ישוב למעונו מאז כחפץ אוהבך נצח מיכל זלמן
        פאחאטשאוסקי</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >נשקי נחמתי את בני עשהאל הנחמד ואמרי את הנשיקה הזאת שלח לך אביך בהמכתב
        הזה גם תני לו איזה דבר טוב ויאמר בנפשו כי זאת שלחתיו במתנה. ולא ישכח כי
        יש לו אוהב עד… אומר ברכה ושלום לבית דודך טייטעלבוים לא אוכל לכתוב להם
        הרבה טרם תרגיע רוחי במכתב משם. הנ"ל.</span
      >
    </p>
    <br />

    <p class="c14 italic" dir="rtl" id="04,06,1896">
      <span class="c8 c0">[04,06,1896]</span>
    </p>
    <p class="c14" dir="rtl">
      <span class="c2">כ"ג סיון תרנ"ו</span>
    </p>
    <p class="c14 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c14" dir="rtl">
      <span class="c2">לאשתי נחמה</span>
    </p>
    <p class="c14 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c14" dir="rtl">
      <span class="c2"
        >... אך אחרית שמחה תוגה. בקראי דבריך האחרונים איך ימר חייך עשהאל הילד
        וכמה תלאות ומצוקות מצאוך בדרך הזה, ואת חולה וקשת רוח אז כמעט נפלתי
        אחורנית מעל הכסא בבית טריוויש, ואחרון אחרון היותר נעים לי אשר כחץ שנון
        פלח כליותי ומרגע לרגע תתגבר המחלה הלוך וגבור, הלוך וגבור הם המילים
        האחדים "כי אז לא נצרכתי עתה לנוס"! כמה טיפות דמעות נשפכו חלף הטיפה האחת
        דיו אשר שפכת את. יוכל היות גם בלי דעת הוצאת חיצי רעל האלה ולא את קלעת אל
        לבי, אך לבי העומדת הכן נגדך תמיד לא נתנה אל החיצים האלה להימין או להשמיל
        אך ישר ישר ירדו בלבבי פנימה ולא החטיאו, והרקב כבר החל! חי בננו התמים כי
        לו ראית אותי עתה פנים כי אז עוררתי בך חמלה לנחמני ולעודדני בצר לי. אך מי
        לי פה ומה לי פה ערער בערבה הנני וערער בערבה אותר עוד זמן רב! רב! עד אשר
        דמעי ייבשו ואפס כוחי, או אז תבוא מנוחת נפשי עם גוי גם יחד. או אז גם אז
        יושעו רבים תשועת עולמים...</span
      >
    </p>
    <p class="c14 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c14" dir="rtl">
      <span class="c2"
        >גם טרם הלכתם חביבי נפשי ממני ידעתי כי נפשי ולבי מלאים ומרגישים אהבה
        אליכם, אך לא ידעתי האיכות והכמות. אך עתה הנה נודעתי כי גם לבי לב בשר
        הינהו, וגם בו נמצאים חדרים מרווחים הממולאים אך אהבה ורגש, וכנראה גם רבים
        המה. באשר ארגיש את לבי הפצוע מכל עבריו, אהבה וחובה! אהבה וחובה! הם הם
        רעי הטובים אשר נותרו עמדי באמונה והם ינחמוני ביגוני באמרם: קוה מיכל קוה.
        הנה נחמתך כבר הגיעה אל המנוחה, מנוחת הגוף, או אז תבוא גם מנוחת הנפש
        עמדו. הנה עוד ימים אחדים, תעבורנה ומכתב מלא תקוה בא יבוא, ויפיח רוח חיים
        בעצמותיך היבשות, ויעורר בך תשוקה לחיים, תשוקה לעבודה, ותשוקה ללחם עוד את
        מלחמת החיים בעד אנשי ביתך ויוצאי חלציך. מי יתן והתנחומים האלה יתאמתו
        בקרוב וחלף הדמעות האלה יבואו דמעי גיל להשיב רוחי ונפשי. אין כל ספק כי
        בקבלך מכתבי זה כבר שכחת הדמעות, התלאות והמצוקות, אשר קרה אותך בדרך,
        ושגעונו של בננו היקר מכל יקר, והנך שולות ושוקטת. ועשהאל בודאי שבע רצון,
        שמח וטוב לב, ולא יזכר עוד את אביו הנדכא והאומלל, והגעגועים לא ינכרו עוד
        את אותותם. בזכרי כל אלה תבוא טיפת סם חיים בקרבי, ובכח הטיפה הזאת הנני חי
        ומצפה.</span
      >
    </p>
    <p class="c14 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span
        >ביום השלישי, אחר הצהרים, חגגו חג הנחת אבן הפנה לבית הספרים. הוא גם בית
        העם, גם בית להתזמורת. והנה אספר לפניך כמו כל תהלוכות החג. באמצע הבולוארד
        על הדרך, עשו את החריץ העמוק, סביב סביב להנחת האבנים אבני היסוד. ובתוך
        החריץ עמד האורקעסטר במלואו, ולצד דרומה מהאורקעסטר עמדה בימה אחת, ועל
        הבימה עמד ה&#39; רוזין ומגילת קלף בידו. וכל אחד ואחד חתם שמו עליה. וכלי
        נחושת העשוי לדבר, עמד על השלחן, ובהכלי הזאת יטמינו את המגילה עד עולם
        ועד. לצד דרום עמדו ספסלים הרבה בעד הקרואים, וכל בני המושבה. היתה שמה גם
        מהמושבות ומיפו באו קרואים הרבה. ויזמר התזמורת איזה נגונים. ויבוא אדון
        פריימאן המיסד הראשון של המושבה ובידיו איזה גליונות, ויעמוד על הבימה
        וידבר באזני הנאספים מתוך הגליונות דברים טובים על דבר הסטוריתה של ראשון
        לציון בפרט ותהלוכות הישוב בכלל, בכל החמש עשרה שנה העברו וכשעה שלמה נאם
        נאומו ויסיים בכי טוב. וכל העם הריע בחרדת קודש. אחריו ניגן האורקעסטר
        תפילת הנדיב בעזרת ילדים קטנים בני המחלקה השניה</span
      ><sup class="c17"><a href="#ftnt1" id="ftnt_ref1">[1]</a></sup
      ><span class="c2"
        >. אחר עלה חזן המושבה ויעש מישברך להסולטאן להנדיב, לכל הפקידים לכלל
        חובבי ציון ולכל המושבות וכי"ב, ובכל פעם ענה האורקעסטר אחריו אמן. נפלא
        היתה המראה הזאת. עתה קם האדון חזן פקיד המושבה ויניח האבן הראשה. וכל זקני
        המושבה ישבו כסאות בצד החריץ, ולרגליהם הניח את האבן עם המגילה, ויריעו
        נגדו כל זקני המושבה: מזל טוב מזל טוב! והאורקעסטר ענה אחריהם. אז קם האדון
        שארקין ויעמוד על הבמה וידבר מעיניני דיומי, בהתלהבות אשר מעולם לא ראיתי
        כזאת. וקולו היה קול עז אשר בכל הפינות היה נשמע קולו. ודבריו היו יוצאים
        מהלב, לב יהודי, לב מלא רגש קודש, וידבר על התקדמות הישוב נגד רצון אויבי
        הישוב. דבריו מצאו לבבות, והעם עמד משתאה משתומם, וחרדת קודש חופף על המקום
        הזה. דבריו ארכו כחצי שעה. אחריו ענה העם תרועה תרועה והאורקעסטר אחריהם.
        אחר עלה הדוקטור מאזיה, ויחל לדבר מענין הבית ותפקידו, ועל דבר שלושת
        הדברים אשר בעדם נוסד הבית הזה. מתחילה פחד מעט בדברו לפני קהל גדול כזה,
        אבל אחרי רגעים התאמץ, וידבר בטוב טעם ודעת. ונאומו התארך יותר משעה תמימה,
        ודבריו היו נמלצים ונמרצים. והוא פאר את נאומו בפסוקי תנ"ך ויביא חיים
        חדשים בלב העם והעדה. ודבריו פעלו הרבה על כל הנועדים פה. אחריו הריע העם
        בלי הרף הרבה הרבה פעמים, ואחריהם גם האורקעסטר ענה בגבורות. וינועו הלבבות
        מרוב הרגש, רגש קודש, רגש חיים רוחני. ובהרגע הזה עמד בפינה אחת במקום גבוה
        הפאטאגראף, ויעש תמונה מכל היפה והנעים הלז. אך אני אני האומלל שוטטתי
        מפינה לפינה, אולי אמצא בין כל הנאספים האלה את חביבי נפשי, את רעיתי ובני.
        למה גרוע חלקי מגורל כל בני המושבה. ערירי, ערירי, ערירי! שמעתי באזני הד
        קול האורקעסטר, מכל הדברים היפים אשר נגן, אך את הנאומים שמעתי ברגש. שכחתי
        כי גם אייזענשטאדט בתור שלוח חובבי ציון נאם נאומו, אך אחרי הדאקטר לא מצא
        כמעט מה לדבר. אך איזה דברים של רגש בלבד. אחרי החג הזה באתי הביתה עשיתי
        חשבון בנפשי. התקדמות הישוב, התקדמות המושבה, התקדמות!, התקדמות! וגורלי
        אני? האם לא צעדתי אחורנית? אז אמרתי: "אל תבכו למת, בכו בכה להולך" כי
        שערי דמעות לא ננעלו, ודמעי החמים יחמו את לבך לרחם עלי, ועל פרי בטננו
        התם, ולא תורידי שאולה בדמי ימיו את אביו אוהבו בלב ונפש. וחפץ ללחם בעדכם
        עד טפת דמו האחרונה. "אהבה וחובה" חרות על לוח לבי וזה דגלי! על יום השלישי
        הבא אחכה בכליון עינים, אולי אמצא פתח תקוה לגרש ממני את המעלאנחוליע בטרם
        תשתרש בקרבי עמוק עמוק, אם תאמרי מה היה עמדך מיכל. למה זה הרבית דברי הבל
        כמו אלה?</span
      >
    </p>
    <br />

    <p class="c6 italic" dir="rtl" id="25,06,1896">
      <span class="c8 c0">[25,06,1896]</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ב"ה פה ראשון לציון יום ה&#39; יד" תמוז תרנ"ו</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >שלום לך נחמתי יקרתי ושלום לבני יקירי מחמד נפשי עשהאל!</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >נחמתי, אחרי קבלי מכתבך הגלוי מ"כח" סיון ביום ג&#39; שבוע זו נצטערנו
        הרבה על צערך כי עד היות הלז לא קבלת עוד מכתב מהוריך. ובאמת הם כתבו בו
        ביום אשר כתבתי אני. אך הם נתנו מכתבם בארגז המכתבים. ואני מסרתיו על ידי
        מחַמד ישר ליפו. צר לי עליך יקרתי כי הדאבתי רוחך הרבה במכתבי החמשי אשר
        כתבתי לך ברגש נורא. אך תסלחי לי כי לא מרוע לב עשיתי זאת אך מדאבון נפש.
        עתה רואה הנני</span
      ><span class="c0"> </span
      ><span
        >עד כמה היתי בחזקת סכנה. מיום צאתך עד יום קבלי מכתבך הסגור אשר אף כי
        עניתיך עליו ברגש נעלה ובכל זאת הוא הרגיע את רוחי מקצה אל הקצה. את בעצמך
        מצאת כי איזה דברי רגש אענה אותך על המכתב הנ"ל. מזאת נראה יפה כי מכתב
        נקרא לא רק שחור על גבי לבן. היינו דברים שכתבת </span
      ><span class="c0 italic">[?]</span
      ><span
        > ממש. אך את הרוח אשר שרר אז על הכותב. והרגש הנכנס בהמעטפה ומרחף על פני
        המכתב עדי יבוא לידי בעליו. ואך יעתיק מושבו בקרב לבו פנימה, והוא הוא יפקח
        עיני בעליו לראות להבין ולשמוע מה בלב הכותב. ולא את אשר אך בגוף הכתב
        בלבד. מכתב, __ מכונה עלעקטערית, אשר תאחד שתי הלבבות ובקחת המכתב ביד אך
        תעבוד הספיריטיזים את שלה, להבין מחשבות איש לרעיהו, אם אך היה מתרגש באותו
        שעה. זה שבוע ימים יקרתי, אשר אינני חי חיים נורמאלי מכל וכל. וסיבות
        מסיבות שונות והרפתקאות אי-נעימות מאוד. הנה אספר לפניך סדר ימי השבוע. אף
        כי לא נעימים יהיו לך לשמוע. אך זאת אדע ברור כי בספרי לפניך את כל קורותי
        אז גם את לא תעלימי ממני, וזאת כל חפצי. שבוע עברה ביום ב" החל חיים לאמור
        כי יש לו קדחת, וכאן עברו עליו שלשה ימים ותארו שחר משחור ונהפך כולו לאיש
        אחר. ובסוף קמה סערה בלב מרים ותאמר כי נגמר בדעתה ללכת יפו עמדו, ושם תשאל
        ברופאים, וכל זאת גלתה לי ביום הששי אחר הצהרים. נתתי להם את העגלה, ואני
        לא חפצתי ללכת עמהם על יום השבת ליפו, ונשארתי אצל הוריך כאורח הגוף. עתה
        אודיע לך חדשות דעי לך כי נוסדה חברה ספרותית בראשון. והעומדים בראשה הם
        האדונים האחים פאפירמייסטיר, מאיראוויץ ראובן ידעליוויץ רוזין בריס לובמאהן
        גורוויץ ועוד. ועליהם נטו רבים מכל צעירי המושבה. ובכל מוצאי שבת מתאספים
        כל החברים (וכל החפץ בא עם אשתו) לבית אחד מהחברים. ושם לא יוגש דבר אך
        בקבוק מים קרים ולא יותר. ואחד יובחר להיות יושב ראש בהערב הזה. ועל ידו
        דוד יודעלאויץ סופר ומזכיר החברה. והם יסדרו את הערב. היינו מי יקרא, מי
        יספר ומי ידבר. ואחדים מראשי החברה לקחו עליהם הטורח, כל אחד בסעיף אחד
        המדעים, לקבץ חומר במשך ימי השבוע ולעשות ממנו דבר שלם בשפתינו המדוברת,
        ולמסרו לאוזן השומעים. למשל אחד לקח עליו את הפאליטין </span
      ><span class="c0">[פוליטיקה]</span
      ><span
        > בכלל, ואחד עניני היהודים בכל מקום גלותם. והשלישי סניף בחכמה כמו </span
      ><span class="c1">חימי</span
      ><span class="c2"
        >  ועוד אחד מהבאטאניק. ואחד יקבץ ידיעות הנמצאות "בהפראגרעס אגריקאל"
        השיכים להגפן ויינו ועוד ועוד. ואין רשות לאיש זר אשר לא מהחברה לבוא על
        הערב. אך אם ישנו אצל אחד החברים אורח ממושבה אחרת יש לו הזכות לקחתו עמדו.
        שעה בחודש מתאספים החברים (בלא חבֵרות) ביום הרביעי לדבר על דבר איזה
        תקונים וחוקות החברה. ואז יתנו כל החברים פתקאות מקפולים לידי המזכיר ובהם
        ישנם פתקאות חלקות, ויש פתקאות אשר נכתב עליהם שם חבר חדש אשר אחד החברים
        חפץ לספחו אל החברה. המזכיר כותב שמות האנשים החדשים על ספר ויודיע בגלוי
        שמותיהם. וארכה תנתן לכל החברים עד האסיפה השניה להתחקות על דרכי האיש
        הנועד להיות חבר, אם ראוי הוא או לא. ואם יהיה לו להחבר החדש הודיה מכל
        החברים - הן. ואם שלשה לאו, אז יצא חבר, ואם ארבעה קולות לאו, אז מרחיקים
        אותו עד שני חדשים. ואז עוד פעם נותנים שמו בהקלפא. וכן עד שלוש פעמים.
        הרבה הרבה יצאו וידיהם על ראשיהם. אך החברים החדשים לא ידעו מזה דבר. כי כל
        הנעשה בסוד החברים אסור להוציא דבר החוצה, וגורלי אני יצא זכאי, ואז גלה לי
        מזכיר החברה כי נספחתי בתור חבר, ובמוצאי שבת היתי פעם ראשונה על הערב
        הספרותי בבית סעמולאנסקי. ומה פחד ורחב לבי בראותי איך מכל העלימנטים אשר
        בהמושבה נתאגדו כשלשים חברים והם כגוף אחד, כולם עיניהם ולבם אך ורק על
        השלחן הקטן אשר משם תצא תורה וחכמה, דעת החיים ומקריה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >בפעם הזה אשר היתי ראשונה היו פנים חדשות. חמדה אשת בן-יהודא עם אחיה אשר
        בא על איזה ימים להתראות, איש נאור אשר גמר חוקי יוריסט בשנה זו, והוא פאר
        את הערב הנזכר במנאמו הארוך על דבר תהלוכות החכמה והמדע בשנים האחרונות בכל
        איירופא, ועתה ברוסיה. איך מצאו הנאורים כי הגימענאזיות והאיניווערזיטות
        יפעלו לרוע נגד הישוב הכללי. כי לא אנשים המסוגלים באמת ישארו על ספסל
        החכמה באוניווערסיטה או גימענאזיום, אך מי אשר אין לו סניף אחר להחיות
        נפשו, או אשר יש ביכלתו להוציא הדבר אל הפועל בכספו הרב, ולא בכשרונותיו
        הטובים. הם הם האנשים אשר יתלוננו בצל החכמה רבות בשנים, ואחרי קבלם את
        הדפלום ילכו ויעבדו בתור שומרי הסף בבתי אלי הכסף. לכן נגמר עתה וכבר יצא
        לפועל במסקבה ענין חדש. כי הפראפעסאריסין מכל סניף וסניף, יכתבו דבריהם
        בספר כמו דברו בפיהם לפני קהל התלמידים באי הבית. וזה יודפס על הרבה
        עקזמפלארים. וכל איש צעיר לימים בכל תפוצות הארץ הרוצה להיות תלמיד, יכתוב
        מכתב אל הדירקטאר בצירוף עשרה רובל לשנה. ומעתה הוא תלמיד האוניוערסיטע וכל
        אשר יכול לשמוע תלמיד מפי הפרופעסאר, זאת ימצא מוכן לפניו בביתו. הוא יכל
        לעבוד עבודתו בשדה אצל אביו בכפרו, או בעירו, ואין לו כל צורך להיות תלמיד
        חכם. יהיה בעל מלאכה וירויח מחיתו ברוח. ואיזה שעות ביום ילמוד. ודי לו
        להיות אחר איזה שנים לאיש המלא בכל חכמות ואז יראה באמת אם בעל כשרונות הוא
        אז יכול להשליך אחר גיוו מלאכתו או שדיהו ולעבוד בשדה האחר. ואם לא, אז
        ישאר בעל מלאכה נאור, אוֹ איכר נאור, ולא שומדבור [?] נאור, או פאראזיט
        נאור! והביא למשל איך עומדים עתה על הפרק במסקבה טרם לכתו על דבר ***** כמה
        שאלות באים בכל יום מהתלמידים מכל תפוצות רוסיה, אף גם מארחאנגילסק,
        והפרופיסערים יעבדו בכל יום להשיב שאלות לתלמידיהם באהבה ובשקידה יתירה.
        ומקוים כי הדבר החדש הזה יביא פרי הרבה למין האנושי בכלל, ולהחכמה בפרט.
        ובכן הוא מיעץ את החברה הנוסדה אצלינו לשום עינם אל הדבר הטוב והמועיל הזה,
        ולעשות כמתכונתה, היינו אם ישנו באיזה מקום שיהיה איש אבטוריטוטו באיזה
        סניף שיהיה, אז יתאחדו כל האבטורטיטים והיו לאגודה אחת. והם ירביצו תורה
        בין תלמידיהם הנמצאים בכל תפוצות המושבות. ומזה תבוא טובה כפולה להישוב. כי
        בניה הטובים לא ילכו לשבור צמאונם מבורות זרים, ובשובם גם אז לא יביאו טובה
        רבה להישוב, הלא תשיבי לי דעתך על דבר מנאמו זה?</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >אחריו ספר ידעלאוויץ את אשר מצא חדשות בהעיתונים. ומאיראוויץ ספר מהחדשות
        הנוגעים לנו ישראל, הרעים והטובים. הציע פראיעקט חדש כי יחפוץ לדבר בכל
        שבוע על דבר החימי-הביתית אשר תפקח עיני ההמון להבין מעט את הטבע סביבו.
        ועל זה קבל במתנה מה"בראצינער" טרם לכתו איזה כלים הנצרכים להראות בהם
        ניפלאות הטבע. גם רוז&#39;ין העתיק מספר אשכנזי אחד "יודען שטאדט" איזה
        פרקים אשר הם בעיניני דיומא, וכך נגמר הערב בנעימים. את פי חמדה שאלתי על
        דבר מאמרך בהצבי למה לא נדפס. והנה נתנה לי תשובה ברורה. בעת קבלה הפאסט
        מיפו ומצפת, ומעוד מקומות, מצאה מכתבך ותקרא אותו ואחרי כן קרעה את החצי זה </span
      ><span class="c0 italic">[?]</span
      ><span
        > מכתבם והחצי השני לקחה וטמנה בידה ממש במקום בטוח כי לא יאבד באשר יקר
        הוא בעיניה יותר מכל הנותרים. וכאשר באה הרגע לקחת את מאמרך לסדרו והנה
        נאבד ואיננו, וכל אשר חפשה וחפשה ולא עלתה בידה למצוא אותו. ועתה טרם לכתה
        הנה מצאה אותו באחת הארגזים הקטנים אשר בהשלחן, ועתה הנה כבר עבר זמנו. הם
        מצאו כי יש לך כשרון נעלה. אחרי פרדנו איש מרעיהו הלכתי עם בוריס לובמאן </span
      ><span class="c0 italic">[?]</span
      ><span> ורוזין לראות את שלום בית האלמנה לובמאן </span
      ><span class="c0 italic">[?]</span
      ><span
        >. היא גרה עתה בבית שמחה-הנגר ושנוי המקום פעל עליהם לרעה והילד הקטן נחלה
        מאוד במחלת אביו הידועה. וכאשר באתי שמה בחצות הלילה ראיתי כי הילד כבר אין
        לו כל תקוה. והם גיטל  </span
      ><span class="c0">[לובמן] </span
      ><span
        >ומַאני זה כמה לילות לא סגרו את עיניהם, לכן קבלתי עלי את הטורח לשבת כל
        הלילה והם הלכו להשיב רוח מעט, וכצאת השמש בבוקר יצאה נשמתו למנוחת עולמים.
        והיא גיטל נפלה במחלה ועד היום היא חולה, אני היתי שמה בכל הימים עשיתי כל
        אשר ביכלתי, ועתה הלכה לה לעקרון עם בניה. עתה נשוב לחיים ומרים, הלא הלכו
        ליפו, ושם הלכו לרופא, והרופא מצא כי חיים חולה מאוד, ולא יוכל לשוב
        ראשונה. קבלתי את עגלתי עם פתקא כזאת. מה נפל עלי לבי בקראי אותה. אחרי כן
        קבלתי ידיעה כי אשלח את העגלה לקחתם. הלכתי אני עם אביך ליפו ביום השלישי,
        ואביך חפץ לראות את לעווין אפשטיין ברדתו מהאניה. וגם לראות את שלום בית
        גורואץ אחרי הגט פטורין אשר קבלה מארענסבערג גיסתו היפיפיה הענוגה. ועתה
        הנה (כפסלה הושענה) שבנו עם חיים הביתה ואיננו עוזב מטתו כי קדחת-תמדי לו
        ומרים עומדת על המשמר יום ולילה. ואני עומד לעזרה בכל מה האפשר, מי יתן
        והשלום והחיים ישוב על מכונו. צר לי על חיים וגם על מרים במצבה הנוכחי,
        הנני יושב בביתם וכותב המכתב הזה כי לא אוכל לעזבם. הדאקטר בוא יבוא שתי
        פעמים בכל יום וכנראה עובד עבודתו באמונה. התראתי פנים לפני שעה עם לעוין
        עפשטיין ובקצרה אמר לי השקפתו על חיי היהודים ברוסיה ונפשי עלי תשתחמר </span
      ><span class="c0 italic">[?]</span
      ><span class="c2"
        > בשמעי מר גורלם עתה, ואת עתידם הנורא. אדמה כי בהיותך שמה לא תמצא רב
        נחת. גם פחד לבי בקרבי על דבר בריאותכם יקירי. אחשוב נחמה לו תוכלי למצוא
        מקום לשבת בקאזלאוויץ הלא זה מקום היותר קרוב לבריסק. ושם הנהר קרוב ולא
        מקום בצה. אדמת חול סביב סביב, כנראה לי אין מקום יותר טוב ממנו בעדך לשבת.
        לי לעצמי שלום בגופי הנני בריא באמת ומאחרי המכתב הסגור שלך נהיתי לאיש אחר
        לגמרא. שב בי רוחי כמעט, וחפץ החיים שב בחזקת היד לקחת מקום בלבבי. לו אך
        לא תגרשיהו אַת במרוצת המכתבים העומדים עוד לקראתינו. בעוד כמה ימים נמצא
        ענבים לאכול. בכלל האויר טוב מאוד, היו ימים אחדים עם חום נורא. אך יתר
        הימים נעימים מאוד. מאיר עם דוד הרוקחים שאלו היום עם קימת את דבריך לקנות
        את הספר אשר רשמת בפינקסך. נשקי את בני הנחמד. השארי בטוב וראי לחיות חיים
        שלוים ושקטים ושובי לבראותך.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >כחפץ אוהבך נצח ומצפה לשמוע ממך אך טוב    מיכל זלמן פוחאטשווסקי.</span
      >
    </p>
    <br />

    <p class="c6 italic" dir="rtl" id="02,07,1896">
      <span class="c8 c0">[02,07,1896]</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2">ב"ה פה ראשון לציון יום ה" כ"א תמוז תרנ"ו</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2">לנחמתי יקרתי, ולבני מחמד נפשי, שלום עדי נצח!</span>
    </p>
    <p class="c6" dir="rtl">
      <span
        >את מכתבך הגלוי מק"ב קבלתי עוד ביום הראשון שבוע זו, ועדי עתה לא מצאתי
        עוז בי להשיב לך אמרים. כי הויה בי ד" עד מאוד טרם מצאתי און בנפשי להבליג
        על צערי כי מחמדי נפשי רצחוני </span
      ><span class="c0 italic">[?]</span
      ><span
        > ואני גלמוד נותרתי, והנה בא השטן ויבצעני. שערי בנפשך, אחי היחיד לי פה
        נפל למשכב והרופא הכיר בו כי נמצאים בו שתי מחלות כאחת וכל אחת מהנה תוכל
        להוריד שאולה. Fievr Perni גם (אלינגינאונצינדיג) וכבר ראינו כי כמעט עמלנו
        עולה בתוהו כי אין כל שנוי לטוב בכל הימים וכמעט אמרנו נואש. אך תודה
        לאחותינו היקרה והנאמנה מרים, היא היא לא נתנה טרף לשני בכור שטן את אישה
        אשר אהבה. שנים עשר יום לא סרה בגדיה מעליה וישנה רגעים אחדים על הכסא אצל
        מטתו, ולילה כימים לא פסקה בכל חצי שעה להמציא לו איזה דבר מאכל. נפלא היה
        איך שכחה את עצמה לגמרא במצבה הנוכחי והקריבה את עצמה על מזבח אהבה-וחובה,
        ואני בכל העת הזאת עמדתי על המשמר לעזור על ידה בכל דבר בכל הימים והלילות.
        גם הרופא פקד אותו פעמים בכל יום ויום ובכל פעם חשב מעשיהו בכובד ראש ואף
        אני עזרתי הרבה על ידו בעשותי ליסטות מכל שעה וכתבתי את הטמפעראטור עם כל
        שנוי ועם כל סמני המחלה, ובכל עת בואו לקח את הליסטע וממנה ידע איך להתנהג,
        ובאמת נוכל עתה לאמור כי בדרך זה נוכל להציל אנשים רבים ממחלות היותר
        מסכנות. ביום השני נתודעתי ממרים כי בזו הימים תצא מירח השמני ומאוד יוכל
        היות כי אחרי ענוים כאלה אשר עבר על נפשה תשכב גם היא במטה, ואז אבדתי
        אבדתי. לכן דברתי עם הרופא אם אוכל לשלחם בירוטה </span
      ><span class="c0">[לבירות]</span
      ><span class="c2"
        > ביום השלשי. והרופא חשב ומצא כי מאוד יוכל היות כי בזאת נביא טובה הרבה
        למחלתו וחיים כאשר שמע כי התיר לו הרופא ללכת בירוטה הוטב מרגש לרגע והנה
        ביום השלשי הוטב מצבו הרבה באמת וכמעט יצא עתה מסכנה והלכו בשלום ביירוטה
        אקוה לקבל היום תלג" מהם ואז אדע מה שלומם עתה. האמיני לי כי הרבה יתר
        נכמרו רחומי על מרים מאשר על חיים. הוא אומלל זה כבר הימים עוד מילדותו יש
        מחלה בריאתו אשר בכל רגע עלול הוא לחליים רעים. אך היא מרים האומללה איך
        נפלה בבלי דעת בצרה הזאת. אודה לך כי בושתי מפניה כגנב למה זה על ידינו באה
        לה כל זאת, אמת הוא כי יכול יכלה לפול בצרה יותר מסוכנת. אך עתה ארגיש מוסר
        כליותי (********). אתמול בבוקר ראיתי את עצמי אחרי העמל ותלאה אשר עבר עלי
        במשך הזמן, כאיש היוצא מבית האסורים אחרי ישבו שמה ימים רבים. שמתי פני
        לרחובות אולי משם אשיב רוח גם שמעתי כי אסתר קבלה ממך מכתב ומאד חפצתי לדעת
        מה כתוב בו. באתי שמה בשעה העשירית ובליתי שמה עד החמשית ובאמת יפעל עלי
        רחובות לטוב וכמעט נגמר בדעתי ללכת שמה בכל פעם אשר אהיה עצוב רוח מעט.
        במכתבך לאסתר לא מצאתי כל חדש אך זאת מצאתי את נדרך אשר נדרת בלכתך ליפו
        בפעם האחרונה בפגשך את העלמה יָפֶה לולא לא הלכתי לרחובות אך להתודע מזאת
        די לי, כי הרבה הרבה פעלה עלי הידיעה הזאת לטוב. להרגיע שאון לבי אשר האורב
        יחדש בקרבי בכל פעם (ואנוכי יודע לטובת מי יעשה זאת) כמעט גמרתי בדעתי כי
        לא אדבר עמדו אך ורק מהדברים שאינם נוגעים לנו כלל. נדרך נחמה מצאתי לדבר
        טוב ומועיל לך בכל ימי חייך ומאוד מאוד שבעתי רצון מזה. אך אחת נשאר לי
        לחשוב, מאין נקח לנו תוצאות המפעל היקר הזה? לפי חשבונך צריכה את אלף
        פראנקים חוץ הוצאותיך את, והלא תדעי כי אך עד ששִם פראנקים אוכל לתת לך
        בחודש ואיה תקחי את המותר? אם באמת יש לך חשבון אז אנוכי מצדי אעזור לך בכל
        יכלתי, דעי נחמה כי מרגלית קאלוירייסקי קבל משרה על עשרה נאפליען לחודש
        במקוה-ישראל ורעיתו תבוא בקרוב והיא רופא שנים דיפלאמיטט והיא תעשה לה מכון
        ביפו לקבל חולים.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span
        >היום הרגשתי את עצמי גלמוד יותר מכל הימים אשר הלכת מפה. זה שני ימים אשר
        מרים הלכה לה ומקום אחר לאכול עוד לא מצאתי. לחפש אחרי מקום לא חפצתי טרם
        אודע כי הורתך איננה חפצה לתת לי ארוחה על שלחנה כי לחרפה תחשב להם אם אלך
        לאכול על שלחן אחרים בעת אשר הכול רואים כי חיים אנחנו בשלום. אך אתמול
        בלילה שמעתי ממנה דרך אגב כי היא איננה חפצה בזאת, לכן חפשי הנני עתה. אך
        לפי שעה לא אדע למי אפנה. ועל יום השבת חפצי ללכת לפ"ת, לזאת טבחתי זוג
        יונים ובכבודי ובעצמי השתדלתי ועשיתי ארוחה מליאה דשן ואחרי הארוחה ישנתי
        מעט. קמתי "מאבסוט". והנני כותב מכתבך זה. בעוד שבועות שלשה תחל הואנדאנז
        בראשון. הענבים החלו להתבשל מעט מעט. צר לי בזכרי כי אינכם פה מחמדי לעת
        כזאת היותר מועיל , ומה האם עשהאל נעשה ילד טוב האם לא ישתגע עוד, האם יתן
        לך מרגוע. אדמה כי עתה נפל עולו ממך בודאי הוא יתארח לחברה עם בני יעקב
        הנחמדים ולהם יספר ניפלאותיו אשר הביא עמדו מארץ הפלאות, איך מצא חן בעיני
        הורי? אמי בודאי תבכה תמיד מרוב שמחה, בשמעה דבריו וספורי מעשיו. איך תבלה
        **** בבריסק ואיך פעלה עליך העיר אחרי שנותה את טעמה, מדבריך לאסתר נראה כי
        תשפוט עתה את האמראפיים  </span
      ><span class="c0 italic">[?]</span
      ><span
        > בבקורת נמרצה וכל מעשיהם בעיניך כמעשי ילדים. אבל כנראה גם עליך פעל
        האויר העב והמעונן. כי לא תמצא לך די חומר לכתוב. ובכל העת אך גלויים
        תכתבי, אך אקוה כי בצאתך לנוה-שאנן אז תשוב לך הרוח, ואז תעניקי לנו מטוב
        פירותיך אשר אליהם תשתוקק נפשינו. טייבל  </span
      ><span class="c0 italic">[?]</span
      ><span class="c2"
        >בקשה ממני הרבה פעמים לפקדך בשלום בשמה והיא בכל פעם תשאל אם אין לה פריסת
        שלום בכל העת היותי אצל חיים ראיתי איך מצבה נורא עתה כמעט אין לה בית
        בראשון *** תלך שמה יומם ולילה תשב גלמודה בביתה הוא הולך לו על איזה ימים
        ואם אליהו כנראה לא תדבר. ובעת היותי שמה התקרבה הרבה לבית מרים. ועתה כאשר
        נסגר גם הבית הזה נשארה המסכנה כאבן דומם! כן כן נשתנה העתים ונשתנו
        הצפרים, וכאשר נשתנו הצפרים כך נשתנו מגיפותיהם.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >בזה הרגע הביאו לי את התל"ג מביירוט "גוט געקאמען" ועל החתום "מרים". תודה
        לאל עתה יש תקוה כי בקרוב ישוב לחויתנו וכמעט נוכל לאמור הצלנוהו
        הצלנוהו.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >הלא ידוע לך כי כבר אספו מכל מושבה ומושבה איזה אנשים לעשותם קאלוניסטים
        באמטולא </span
      ><span class="c0">[מטולה]</span
      ><span class="c2"
        > ועתה בא עוד פעם ידיעה מפאריז להוסיף עוד לשלוח מכל מקום ונותנים תקוה
        לכל העובדים הנמצאים בארץ כי כמעט כולם ימצאו מנוחה בקרוב אדמה כי מעתה לא
        יסעו מפה אף אחד. כלת מידאליע הידועה לך באה עתה ולקחה לה את המורה השעות,
        אני לא אמרתי לה דבר, אך לא אזכור אדמה כי את נתת לה כסף שויו משכבר הימים
        ועתה חפצה הניה עוד פעם. פרסי נחמה בשלום אחיך יעקב וחינה ולכל מכירנו
        ובשלום הורי ואחיותי היקרים לי. לא אוכל לכתוב להם טרם קבלי מכתב ממך
        מבריסק. נשקי את עשהאל הרבה פעמים בשמי ואמרי לו אם יהיה ילד טוב אז אשלח
        לו ענבים מכרמו והוא יתן לכל הילדים חלק. צמא אנוכי למכתב גדול לדעת מכל
        אשר עמדך שמה מכתב מלא חומר כאשר חננך הטבע. בזאת אמצא כל אשרי לעת כזאת.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >חיי בטוב ובנעימים כברכת אוהבך מיכל זלמן פאחאטשווסקי</span
      >
    </p>
    <br />

    <p class="c6 italic" dir="rtl" id="09,07,1896">
      <span class="c8 c0">[09,07,1896]</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ב"ה פה ראשון לציון יום ה" כ"ח לירח תמוז תרנ"ו</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2">נחמתי יקירתי, ובני מחמל נפשי עשהאל הנחמד שלום!</span>
    </p>
    <p class="c6" dir="rtl">
      <span
        >את הגלוי מ" 22 יוני **** קבלתי ביום השלשי בערב בצרוף עם הכרטיס מקישורי
        ב"ד ושאירת בשרינו גרונה למזל טוב. והאמת אגיד כי חכיתי הפעם על מכתב
        גדל-דפים ורב האיכות. וכמעט לא מצאתי די לרוה צמאוני בדבריך המעטים האלו
        אשר תוכלי לכתוב על גלוי קטן הכמות הזה, אך בהפכי את הגלוי הנה והנה אולי
        אמצא עוד איזה מלים המרחפים באויר ואינם בגוף הכתב, מצאתי זאת כי כמעט ערכת
        מכתב גדול ובו העירות מעת את רגשותיך אַת, כי רגש יעורר רגש בלי כל ספק. אך
        אחרי כן התבוננת ואמרת לא. אתאפק עוד מעט אתברר ואתבונן </span
      ><span class="c0 italic">[?]</span
      ><span class="c2"
        > בדברי טרם אשלחם, ובגוף הכתב מצאתי כי הנך מרגשת מאוד את הרגש אשר אמלא
        במכתבי אני והנך מצטערת עלי כי אסבול, וכל חפצך ומאויך את כי תשמעי ממני כי
        כבר הרגעתי את רוחי אני, ובאמת לוא היה כדבר הזה כי אז יותר טוב היה
        לשנינו. אך כנראה הדבר אינו תלוי בי לגמרא. לוא קבלתי ממך מכתבים כאשר
        קותי, כי אז בודאי כבר היה עבר עלי הגעגועים, וכבר נהיתי ונעשיתי לאיש אחר.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >אבל שתי אלה הם בעוכרי. חיים האייראפים המלאים תמיד בהבל וריק ולא יתנו
        לאיש לשבת במנוחה איזה רגעים ולהתעסק בעסקי המוח. וגם המלחמה הכבדה אשר
        תתחולל בקרבך על דבר העבר והעתיד שלך, הם הם אשר יעכבו את היום אשר אוכל
        לאמור עליו עד פה היתי אומלל ומעתה הנני מאושר, או? יותר אומלל… עד כה
        החילותי מעט מעט להתרגל לחיי צער והתבודדת, כי איזה שעות בכל יום היתי בבית
        חיים ומרים ובחברתם שכחתי מעט יגוני. ועתה הנם בבירוט קבלתי מהם מכתב, כי
        מצבו הולך וטוב ובכל זאת לא אדע מתי יבוא הוא. והיא בודאי תשאר שם עד אחר
        החג, ועתה מוטל עלי להתעסק בעצמי במזונות, כי להאטיל [?] לא יתנני לבי ללכת
        ובבית מכירי שאלתי באיזה מקומות וכלם אומרים באין להם משרתות לא יוכלו לקבל
        עליהם טורח איש זר. ואמך גם היא אינה חפצה מפני טעמה הכמוס… ועתה תוכלי
        לשער את מצבי הנוכחי. יוכל היות כי גם במצבי זה אתרגל גם כן כי במה לא
        יתרגל איש? אך לגרש כליל את הרגש מלבי, זאת נחמתי לא אוכל בכל אופן. כי
        הרגש מעורב בדמי. ועד טפת דמי האחרון ישאר בי נטף רגש גם כן.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >מה אומלל אני ומה אמולה לבתי בזכרי את העבר הנעים וההוה המר והנמהר הזה.
        מצבי אני ומצבך את, ומצב הילד האהוב לנו מכל, מה תוחלתו כי איחל? באמת איני
        רואה לפני דרך אשר בו נצא מן המבוכה. כנראה הולכים ומתרחקים אנחנו מן המסלה
        בעמקי היער עתה, וכל צעד וצעד אשר נעשה לפנים זה לריחוק ח"ו. מגמתינו היתה
        אך ורק להשיב לך בריאות וכאשר תהי בריאה אז תדעי לחשוב מחשבותיך כאיש בריא,
        והנה החלפת את מטרתך באחרת לגמרא וכל הגיוני לבך עתה לא על דבר בריאתך אשר
        זה הטפל אצלך והילד אשר הוא חיי רוחינו תמסריהו בידי אנשים זרים. ילד אשר
        נולד בחיק אזיה </span
      ><span class="c0 italic">[?]</span
      ><span
        >, באויר החם והיבש, אשר בשבע עינים להביט עליו שמה לשמריהו מכל פגי ***
        ומחלה כי יותר רוך וענוג הוא מכל הילדים בני גילו, איך תעמידיהו על המדרגה
        התחתונה, לא אבין. וגם מוצא לכסף בסכומים כאלה לא מאד תמצאי. אם למשל אתן
        לך שלשה רבעי הכסף אשר אקבל לשנה הלא צריכים הם לך מלבד הלימוד ומלבד
        האינסרומענטים </span
      ><span class="c0 italic">[?]</span
      ><span
        >. ואם תלמדי מילדת אשר לא יעלה בדמים מרובים אומר לך כי השם הזה כבר לזרא
        באפינו. למרות כל זאת הלכתי אתמול בפקודתך ליפו ונתודעתי בהסעראיע  </span
      ><span class="c0 italic">[בית הממשל התורקי]</span
      ><span class="c2">. </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c0 italic">[בראש עמוד חדש, דחוק למעלה נכתב:]</span
      ><span class="c2"
        > בערב שבת לפנות ערב קבלתי מכתבך הסגור וכבר כמעט קראתיו, היום היה לי רע
        מוחלט הגעגועים מתגברים עוד פעם. הנני אומלל לא אדע עוד סופי. מר לי מר,
        ואין לי כל נוחם. עתה אדע טעם הנוחם במובן אחר. הורג נפשות רבות הנני.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >על דבר הנפוס שלך והנך כתובה נולדה ביפו. ושאלתי את חיים בעקער אם אוכל
        למשל לעשות בהס&#39; את מכתב-ישרך כאשר בקשת. אמר אי-אפשר אין פה. בעד
        נאפאלינים אפשר לעשות כל דבר. ולעשות על ידי האדמיניסט" לא אוכל כי חזן הלך
        לסאמארין. וגם אחשוב מכתב האדמיניסט כמכתב פרטי, ועוד כאשר בעצמך אמרת כי
        מצב בריאתך לא הוטב עוד ודבר הפרופסר חזקים עליך כי תלכי ולא תשבי בקונגס,
        ואיך תאמרי לשוב מהרה ולשבת על ספסל הלימודים בעת אשר הנך חלושה עד כה,
        היתנו לך לעשות כדבר הזה? הלא יבקשו תעודה על דבר בריאתך קודם כל. עצתי אני
        כי תותירי את האידעע הזאת לעת אחרת, באמת יפה וטובה הניה מאוד מאוד אבל
        כדבר לפני זמנו הוא עתה. אני משתדל להתראות כמעט בכל יום עם הוריך, הלכתי
        לחזן ודברתי עמדו על דבר קבלת הענבים מכרמם. ויען, כי בשנה זו יקבל, אך
        בשנה הבאה לא ידע, כי עליו לשאול את פי הבאראן. כי נודע לו עתה אשר האדמה
        של אסטיושינסקי נקנתה למורת רוח האדמיניסט" והוא לא ידע מזה דבר. ויתר
        החדשות מהמושבה לא אוכל עתה לכתוב, כי רוחי עתה חובלה. חיי בטוב כחפץ אוהבך
        ומכבדך נצח, מכל זלמן פאחאטשאווסקי</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">נא נחמה לתן מכתב הורי על ידם –</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">פרסי בשלום בית אחיך יעקב נ"י ולכל מכירינו שלום </span>
    </p>
    <br />
    <p class="c4" dir="rtl" id="10-20,07,1896">
      <span class="c2">10-20,07,1896</span>
    </p>
    <p class="c6 italic" dir="rtl">
      <span class="c8 c0"
        >[הקטע הבא היה על דף נפרד, ולא ברור לאן הוא שייך. לפי תוכנו נראה שהוא
        חלק ממכתב שבא אחרי המכתב מתאריך 9.7.1896, ואולי גם אחרי המכתב מה
        16.7.1896]</span
      >
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >...יופל דבר. אתמול בבוקר לקחתי מכתבך והלכתי לרחובות למארגלין להתארות עם
        אסתר. מצאתיה ברחובות אבל בבית יולדת אחת אשר תקש בלדתה זה כעשרים שעות ומי
        יודע עוד כמה תשאר במצב כזה, צר היה לי מאוד כי לא אוכל לראותה אך קודם
        סעודת הצהרים באה להשב רוח מעט אז מצאתי את הרגעים לדבר עמדה מעט, היא ספרה
        לי כי קבלה בשבוע עברה גלוי ממך אשר נכתב יום אחרי הגלוי שלי והו קדם לבוא
        שבוע ימים. העתקתי לפניה מכתבך והיא שבעה רצון השמעה דברי *******אשר
        הרגיעו את רוחינו לגמרא גם היא מצאה לנכון כי הדבר היותר טוב כי תסעי
        לבריסק ושם תמצאי לך כפר קרוב לשבת שמה כל ימי הקיץ להשיב רוח, אך אחת לא
        מצא חן בעיניה כי איזה פעמים אמרתי "הלא על כסף אחוס" זה באמת יתר על המדה,
        ועל דבר הלימוד אשר בדעתך אמרה כי זה דבר אי אפשר עתה ברוסיה כי אין לך
        דפלום גימנאזיות שלם ועצתה היא כי לא תחשוב עתה דבר אך בימי החורף תלך
        לווארשא על שלושה ירחים ושם תשמעי לקח על דבר המאסאז וזה דבר נחוץ מאוד
        מאוד ובפרט למחלות נשים וזה יביא פה הרבה וטובה רבה כי אין עוד מאסאשיסטים
        פה, אך ורק עליך להיות בריאה</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >טרם תבואי לשמוע בלמודים כי חכמת המאסאז לומדים טעאריע ופראקטיק כאחת.
        והיא החלה בהיותה במסקוה ללמוד. ופטרה הרופא באשר לא היתה בריאה כל צרכה,
        גם בקשה ממני לבקש ממך סלחה כי לא יכלה עד כה לכתוב ומי יודע אם תוכל עוד
        לכתוב שבוע זה. היא שמעה כי יש סברה אשר פוטרים כסף הפאספארטים לכן יש
        בדעתה ללכת רוסיה אחר הוואנדאעז, אך אני החילותי לדבר עמדה על דבר נכבדות
        והדבר מצא מהלכים בלבבה. לכן נראה אני אשתדל להתראות עם שני הצדדים כאשר יש
        בידי עתה ללכת ולנסות בכל אות לפשר בלי מפרים </span
      ><span class="c0 italic">[?]</span
      ><span class="c2"
        >. הוריה מקבלים אותי עתה בסבר פנים כמו בנם ומבקשים ממני לעשות עמהם החסד
        ולבוא בכל שבוע פעם. הזקנים אמרו לי בעת אסתר לא היתה, כי פרידתך מהם לא
        יכלו לשכוח ימים רבים והם יאבלו עד עתה את בשרם עליהם למה זה עשו כדבר הרע
        הזה ומבקשים סליחה, כתבי להם איזה דברים כי באמת אנשים ישרים הם, בדאבון לב
        ובמפח נפש קרתי דבריך במכתביך אלו. "עתה אגלה לך כי נגמר בדעתי ללמוד". האם
        דבר חדש הוא לפני? האם לא דברתי עמדך אודות זאת ואת אמרת כי יש את נפשך
        ללמוד דאנטומטריסט והאם יש לי דבר נגד זה למה לא אם אך יש ביכולתך ובריאותך
        תתן לך ללמוד ואיך אתנגד לדבר טוב? ועוד גמרת "אילו לא תמצא את הדבר ישר
        בעיניך ולא תתן לי לנסוע" חרפה תכסה פני בשמעי מפיך כדברים האלה איך אחרי
        היותך שבע שנים בצלי ועוד לא הכרת את ישרת נפשי בקרבי האם את בקשת ממני כי
        אתן לך לנסוע או אני בקשתי ממך כי תלכי להטיב מצב בריאתך ואיך יכולת לשכוח
        כדבר הזה? ועוד תשנה דבריך "יוכל היות כי תקצוף מדוע לא גיליתי קודם אבל מה
        אוכל עשות אם תמיד יראתי מהגיד לך את אשר חושב ומרגיש לבי, זה אולי באמת
        עוני אשר לא יכופר. אך מה אוכל עשות אם מלאה עוונות הנני נגדך בהסתירי את
        אשר עם לבבי" אבל מדוע אתה לא יכולת לראות את לבבי ולדעת אשר בו? אך אולי
        ידעת ושמת גם אתה מסוה על פניך". מאה פעמים ואחת שנותי דבריך אלה אך לא
        אמצא מענה עליהם, באמת זה קשה להביע למה זה תמיד ראית לכסות מצפניך ממני
        ולא נתת לי מבוא לבוא ולדעת אף אחת מכל מחשבותיך ואני באמת ידעתי כמעט כולם
        וזה ללא שקר כי עשיתי מסוה על פני כאלו אנוכי יודע ומבין דבר י&#39; ידעתי
        גם אז בעת קבלתי מכתב ממורך דב"ש על דבר הילדים ברזלי ורעהו, ומשלו עם רוח
        מצווה, ולא אזכור עוד את דבריו על מה ***, אך זאת אזכור כי מאז מצאתי אותך
        חתום וסתום נגדי. וכל יגיעי אשר יגעתי להשיבו אלי עלה בתוהו.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >רבות רבות חשבתי דרכי מה אעשה ובמה אקניהו, אבל אוי ואוי לי לא מצאתי הדרך
        הישרה סלולה לפני כי אנשי בליעל סתמו הדרך באבני נגף וטרם עלה בידי לגול
        אחד, הניחו שנים על מקומו וכן היה הפרץ רחב *** מיום ליום. את אמרת "כי איש
        מלא פחדים אנוכי" זאת באמת ראתי בך לכן אמרתי תמיד בנפשי אין לי לקצוף עליה
        ביד *************** פחדי ממך זה היה דבר אי-אפשרי, כי לא ידעתי על ברור
        מפני מה תפחדי. היות גלית פעם לפני את לבבך האם לא אמרתי לך תמיד כי העש
        הזה יאכל עצמות, כי לא אוכל לדעת ממך ולהציל מפיך דבר, את אשר נפשי קשורה
        בנפשך בחוט המשולש, ומעת בואנו בברית לא נתתי לשום רעיון זר לחדור בלבבי
        וקדוש קדוש אמרתי לכל רעיוניך מעשיך דבריך ומאויך ואחר כל אלה אין לי כל
        זכות לדעת מטוב עד רע. אם תבקשי חשבון בצדק אז תמצאי בעצמך הסיבה למה לא
        יכולתי לקורות לבבך, כי לא נתת לי פתח אף כפומא דמחטא, וגם בזאת לא אאשימך
        ח"ו ואין אף אחד אשר יאמר כי האשם הזה בך. זה פרי מעללי הצעירים בראשון. לו
        הינו חיים במקום אחר כי אז מה מאושרים הינו. הנה עתה עוד בתים אחדים עומדים
        לפול למשואת ובעלי הבתים האלה גם בית הקדוש אסט" (עתה לא נדבר מזה) לו ידעת
        עד כמה הגיעו המחלקות בין צעירי ראשון כי אז מצאת...
      </span>
    </p>
    <br />

    <p class="c6 italic" dir="rtl" id="16,07,1896">
      <span class="c8 c0">[16,07,1896]</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >ב"ה פה ראשון לציון יום ה&#39; ו" לירח מנחם-אב תרנ"ו</span
      >
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2">נחמתי יקרתי ובני עשהאל הנחמד שלום!</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >את מכתבך הסגור מ30 יוני כבר קבלתי ביום הששי לפנות ערב כאשר הודעתיך
        במכתבי הקודם בשולי המכתב. מרפא לרוחי הדהה לא מצאתי גם במכתבך הסגור הזה.
        אמור אמרתי כי אך הגלוים לא יכילו בקרבם הרוח באשר גלוים הם. והנה אחרת
        אחשוב עתה כי לבך סגור עתה על מסגר ותקפוץ את ידך ועצבעותיך כי לא יפרצו
        רעיונותיך ח"ו חוצה. אך דעי לך יקרת נפשי, כי מלאכתך נעשה בלי ידיעתך על
        ידי אחרים. אף גם נגד רצונך. לבי קרוע לגזרים ברגע הזה ודמי יזרמו ממני.
        וכמעט לא אראה את הניר, אך היד הרועדת תתנהג לאטה ותבקש לה דרך בעצמה, תגשש
        תגשש אולי יעלה בידה איזה דבר טוב ומועיל. אוי ואוי לי כי באתי עד הלום.
        בשנת השבע לעת היותי מאושר בתבל בימים האלה, הלא אז באתי בריסקה. הלא אז
        עולם חדש ראו עיני הלא אז תקוה מלאה כל חדרי לבבי. הלא אז אז אז! אך עתה!
        היה! רעל בעצמי, ומוחי ייבש מיום ליום. רוחי כהה ונפשי נכאה. בנפשי אשביעך
        כי לא אדע איך עוד נשמתי בי כי העקת גדולה כל כך עד אשר אי נשוא, לעת כזאת
        אשר אמרתי כי אלך לאטי על שביל הזהב באין מכלים ומפריע, יצאתי מעבדות
        לחרות. ועתותי בידי נתונים עתה לעשות בהם הטוב והמועיל לנפשי לרוחי לבני
        ביתי לבני עירי למין האנושי בכלל, כי בהשנים האלו יצא האיש משחרותו, ודמו
        יתקרר, ורעיונו יתברר, הלא אז יבואו ימי הבינה. הימים היותר נעימים, היותר
        מועילים, היותר נחוצים בעד הכלל. ומה בינתי אני כי אדמה. אבין לשברי כי
        גדול ולצרת נפשי כי אנושה, כבת יפתח הגלעתי הנני סגור ומסוגר בביתי כל
        הימים זה שמונה ימים כמעט לא יצאתי חוצה (אך ביום השלישי הלכתי יפו על איזה
        שעות לקנות לי בד לעשות כתנות אשר כמעט אין לי, ו**** בעד שנים עשר
        פראנזיזל ולבן מעט) כי גמרתי בנפשי עתה כי אין לי שום תועלת בחברת אנשים,
        כי לא אוכל בכל אופן לרדת לעמקי לבם, יש אומרים ויש אומרים, אך לפצעי לבבי
        אין כל מרפא בלשונם. בבית הוריך הלכתי כמעט בכל יום, אך הם בהעת האחרונה
        הכאיבו הפצע את מוחי עוד יותר ויותר. ולא אוכל עוד לעמוד על דמי ולראות
        בקרת רוח איך הוא תוסס, גם את הארקיסטר מאסתי, אין לי עתה לשיר בשירים, אך
        ראי נא איך "מלחמת החיים", היינו מלחמת החיים על האדם כי בעל כרחך אתה חי,
        ולא בהעת אשר יש לך חפץ בחיים. ובהמלחמה הזאת לקח החיים, את נזר הנצחון עלי
        וינצחני, בתתו לפני מטרה אחרת בחיים, והמטרה הזאת אולי באמת תביא מרפא
        בכנפיה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >והנה אספר לפניך את מקרה ימי, בהחמשי העבר החלו צרות נפשי לדכאני לעפר,
        לבי דפק בכבדות וכאב נורא הרגשתי לפנמים בלבת </span
      ><span class="c0 italic">[?]</span
      ><span
        >, אמרתי אולי מחלה מתקרבת לבוא, כה עבר עלי היום, והלילה כידוע בלי שנה.
        ביום הששי הלכתי קודר כל היום ועוד היה עלי להכין מזונות ליום השבת. אז כבר
        קצתי בחיים מרים. חיי נצר נתאב חיי ערער בערבה חיי נעזב ומשולח, אך לפנות
        ערב בא מכתבך הסגור, פתחתיו בלי אונים כי ידי רעדו ממגור. קראתיו אך לא
        מצאתי בו כל נוחם, עוד נתרבה עלי צרת נפשך ומצבך אשר אין פתרונים גם לו.
        הילד ומעלליו, גורלו ומצבו ועתיבתו  </span
      ><span class="c0 italic">[?]</span
      ><span class="c2"
        >. מסכין מסכין מסכין! (נפשי תתפוצץ בקרבי בכתבי הטורים האלו) הנה הפעמון
        עת צהרים הגיע אוכל את אשר הכינותי שם על האח איזה דבר מאכל. אולי אבליגה
        מעט ואחרי הסהודה אכלה את דברי וירוח לי מעט…
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >עתה כבר השעה שלוש ומחצה. בעת האכילה בא אביך לדבר עמדי על דבר הענביו אשר
        קרוב היום. ביום השלישי הבא הוא יום הראשון להמאנדאנז. אחריו בא מארגוולין
        הזקן ישבו פה איזה שעות, וניימאן ישב עד עתה. ולא יכולתי לכתוב. --- הנה
        במכתבך מא מצאתי? מצאתי כי מצבך רע אין לך דרך לצאת מהמבוכה, ובכל זאת גמרת
        בנפשך חרף כל המעצורים הכסף, העת, הילד, האויר, הבריאות, ועוד… כי תלמדי
        ותשגשגי בלמודים, ובקראי כדברים האלה אמרתי הנה מצאתי מטרה בחיים! הלא אני
        ואת הננו עתה טובעים בים של צרה אחת, ומה! אם את תוכלי נגד כל המעצורים
        למצוא תרופה בהלימוד, להשקיע את עצמך ולשכוח העולם עם תורה, הלא אנוכי אין
        לי כל מפריע, ולמה לזה אבקש מרגוע לרוחי בדברי הבל אשר לא יועילו אך על רגע
        קטן, ואחרי כן יוסיפו לדאבה עוד יותר. גמרתי לבקש תשועתי ותרופה למחלתי אך
        ורק בהלימוד. ומיום השבת עד היום כבר למדתי על בוריו 62 עמודים מהספר
        הצרפתי ********* ומהיום והלאה אדמה כי עבודתי זאת תתן אומץ לחיות ואולי גם
        איזה תקוה בחיים, אמרתי "תקוה" אך מפני ההרגל כי באמת אין לי עוד תשוקה
        לתקוה, כי כבר מאסתיה – מצאתי כי הדרך היותר מרוצה לפני הוא לדון את כל
        התקוות בשרפה. ואין מה לדבר עוד על דבר העתיד, אך לספר לפניך. כאחת הנפשות
        ההיו יותר קרובות אלי. לכן אקח לי הזכות אם תרשיני לדבר באזניך ולספר תמיד
        את מרת נפשי או את אשרי על הדרך החדש אשר שמתי לי, בההוה, את השיר "בעל
        כרחך" מצאתי בעת אחת קרוב מאוד לגורלי. עזוב, נטוש. באין אישה וילד. ובכל
        זאת לא יעיז את נפשו לקחת. זה הוא תוכן השיר. תשאלני מה עם מארהאפ הנה
        נודעתי ממנו בסוד כי אשתו חולה במחלת מעלאנקאלית ביותר בעת ההריון ויש לה
        חפץ נמרץ לקחת את נפשה, הרבה הרבה פעמים הביא המקרה כי הצילוה, אך לא תמיד
        תתרחש **** ועתה כבר יש לה שלשה ילדים. אומלל תמיד יעמוד על המשמר. והורבץ,
        גם גורלו כגורל היותר האומללים אשתו חולה מאוד מאוד הכאב הראש לא ירף ממנה
        אף יום. וכפעם בפעם יבואו הקרעמפין היא כמעט לא תצא מפתח ביתה. זה גורל אחי
        אשר עבדו עמדו יחד עשר שנים. וזה משכורתי. יום ו&#39; אחר הצהרים. אתמול
        בכתבי את הטורים האחרונים נפלתי למשכב והנני חולה מאוד כל היום וכל הלילה
        לא סר ממני קרעמפין תחת הלב אמרתי כמעט נואש ואין איש עמדי בבית. כחצות
        הלילה באו איזה ************ לבקרני ורק בירגיר נשאר אצלי כל הלילה. בבוקר
        *** אביך *** ובאו ותודה להם כי השיבו בי רוח חיים מאט הביאו את הרופא גם
        סמי מרפא אך עתה כמעט סרו הקרמפין ממקומם ותחת זה לקחו מקום בהלב. הפאסט
        ילך לכן עלי לגמור המכתב אף כה. קשה לי מאוד מאוד הכתיבה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >מחיים וממרים קבלתי מכתב. מצבו הוטב הרבה בהימים האחרונים והיא עוד שלמה
        ****. נשקי את בני עשהאל אשר בדמעות ודם כתבתי את שמו.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >הקרעמפין אומרים די. השארי בטוב כחפץ אוהבך מכבדך ומוקירך בלב ונפש.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">מיכל זלמן פוחאטשאוסקי.</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">שלום לכל החפצים לשמוע ממני דברי שלום.</span>
    </p>
    <br />

    <p class="c22 italic" dir="rtl" id="23,07,1896">
      <span class="c8 c0">[23,07,1896]</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ב"ה פה ראשון לציון יום ה" י"ג מנחם-אב תרנ"ו</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >שבע שנים ושעות אחדות מיום נתקשרנו בקשר אמיץ. עברו חלפו עלינו עד הרגע
        אשר הננו בזה! כמה טיפות דם מדמי לבבי מהולים היום בהדיו אשר כתבתי הטורים
        האלו. מה נוראים לי הימים האלה בהציגי אותם. מחזה מול מחזה. נגד הימים
        הראשונים. מכתבי משבוע העברה כליתי במטתי כידוע לך. אך כנראה עודל לא בא קץ
        התלאות, וכמעט נגד רצוני סרה מחלתי ממני. וביום השני קמתי ממטתי. ביום
        הראשון קבלתי מכתבך הגלוי. תשובה על מכתבי עם "הרעיונות נכבדים" חרפה שברה
        לבי וכרקב היה בעצמותי בזכרי כי על מכתבי הלז אין לי ממך כל תשובה, אך מפני
        הכבוד מלים אחדים. זאת כדי חובתך לפי שעה. אמרתי אני בלבי גם לי לב בשר,
        וגם בלבי נמצא מעט אהבת עצמי. לא אוכל שאת עוד לתן את נפשי למדרך, די די לי
        עד כה וכבר נתמלאה סאתי, ומהיום גם אני אעשה כמוך, ומפני הכבוד אשיב לך
        אמרים אחדים על מכתב גלוי. אך המקרה הביא לידי איזה דברים אשר לא אוכל
        לעבור עליהם בשתיקה, ובעל כרחי עלי לערוך מכתב סגורם. ביום השני לפנות ערב
        באו אלי אורחים נכבדים. בתיה אשת מיכל גורויץ ושתי אחיותיה והם מתאכסנים
        אצלי עד עתה. אני נהיתי לקאוואלער איום ונורא, אינם הולכים בלעדי אף פסיעה
        אחת. לא ניקר כי אין בעלת הבית בבית, גם לא יחסר להם לאכול ולשתות. אך ורק
        הסהודות אשר הם קרואים. היינו  ביום השלישי אצל הוריך. ביום הרבעי אצל
        טייביל. ביום החמישי אצל הוריך עוד פעם. והיום אחשוב להובילם על עגלתי
        ליפו. הם כבר מכרו כל כלי הבית ובעוד שלשה שבועות ישימו לדרך פעמיהם.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span
        >חדשה נעשהו והוריך יצאו על זמן מה ליפו לגור שמה. לפני איזה שבועות הלא
        כתבתי לך כי האדון חזן ביקש את אביך כי יצא מבית סגל ויתן לו הרשות לגור
        בכל בתי ראשון. וכנראה לא רע עשו הוריך לוא מלאו בקשתו בו ביום. ואני אמרתי
        להם אז הלא ביתי לפניכם אך שמעו בקול הפקיד לטוב לכם, והם כאשר תדעם לא
        חפצו לשמוע בקולי ולא בקולו, ואביך הלך בעצמו להאדון חזן ויאמר לו איך זה
        יעשה כדבר הזה לצאת מדירתו בעת אשר כבר שלם בעד שלשה חדשים. וזה היה כמעת
        ללא אמת. אך ה" חזן ראה כי לא יעשה עמדו דבר בעת אשר לא יבין כי עליו לעשות
        זאת כרגע, אף אם אמת הדבר כי שלם, כי מה נחשב זאת ו**** ענה לו דבר, וילך
        לו אביך שמח כי עלה בידו לרמא את ה***. ובשבוע העברה באה אשת "החיט" לבקש
        את כספה מה" סגל ערך עשרים נאפאליון, והם ענו לה עזות, ותעש שמה פראות
        בבית. והם שחדו לה מהלומות מאלופות. ו*** נעשה מרקחה בראשון ויבוא לעת ערב
        בבית הכנסת בעל האישה ויבך ויתחנן לפני העדה כי ירחמו עליו ויעזרו לו
        להוציא את העושק מיד עושקו, ויקם אביך ויגדף את האיש הזה ואישתו, וידבר
        מחומו איזה דברים של אי-טעם, וימסרו הדברים להאדון חזן ויקרא אותו ויאמר לו
        כי בזמן שלשת ימים יצא מראשון. וזה היה ביום השני משבוע עברה וביום הששי
        קראו אותי לבואלבית הפקידות ומפני מחלתי לא הלכתי, והלכתי ביום השני, ויען
        ויאמר לי האדון חזן. הנה כי כן כאשר בא חותנך הנה הלא זכור נזכור כלנו. כי
        האדון שביד לא נתן רשיונו בכל אופן. וכל הבקשות, גם הדמעות, לא הועילו
        מאומה. וגם איזה פעמים התרה בי, ולא שמעתי בקולו הלא אז היה בידו לעשות
        עמדי רעה. אך כאשר חס עלי ועל כבודי, והוא היה תמיד שבע רצון ממני. לכן אמר
        אעשה את עצמי כאינני יודע. ומה! אם יהיה עוד איזה איש ישר בהמושבה, ועתה
        כאשר נודע לי על ברור כי איש נרגן הוא לכן אבקשך לקים את הפסק דין הישן. כי
        לא ישב עוד פה בלי רשיון אף יום אחד. כי האשמה עליו נגד הדון שביד באשר לא
        מלא את תפקידו. אך כאשר עתה נשתנה הדבר והוא עתה אב לקאלניסט. לכן כאשר יצא
        מהמושבה אז עליו לכתוב בקשה. כי יתנו לו רשיון לשבת בהמושבה אצל בניו, ואני
        אשלח את דבריו ישר לפריז ואקוה כי לא יחטיא את המטרה. ואחרי כן יתן לי
        חתימתו כי יהיה איש מתון ומתנהג לפי חוקי האדמיניסטראציע. (כל זאת דבריו
        ממש). והיום הלך אביך ליפו, לבקש אחרי דירה קרובה להים. הם חפצו ללכת
        לרחובות אך אני אמרתי הלא יותר טוב כי ילכו לשבת ביפו ושם תוכך אמך לעשות
        לה מרחצאות בהים. וכן נגמר הדבר. ואין לך לשים הדבר אל לבך. אדמה כי לא
        לרעה תהי הדבר להם. אף כי לפי שעה מצטערים הם הרבה. כי קשה להם "הטלטול".
        אך זה לימוד יפה לפני אביך. אני, ניימאן בקשנו ממנו הרבה פעמים כי לא יכניס
        את עצמו בגוף העינינ בין האחים סגל, כי זה נגד כל המושבה, ונגד הפקידות,
        ונגד דרכי המוסר. אך הוא התרועע עם </span
      ><span class="c1">שליט</span
      ><span class="c2"
        > וגינזבורג אשר הם בעצמם מכל המושבה כולה עומדים מצד דוד סגל.
      </span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >יום ו" אחר הצהרים עתה הנני בא מיפו הלכתי אתמול ליפו עם בית עדעלמאהן
        ובתיה גורוויץ. לנתי אצל חנה אחותי. ושם נתודעתי משלום חיים ומרים אחותינו.
        מצב חיים הוטב. והוא בעוד שבוע ימים יבוא הנה. ומרים עודנה בתומתה, חושים
        כי בשבוע זו יבוא יומה… ישבתי עם אביך אשר כבר גמר עם דירתו. הוא שכר דירה
        אצל איש ישר אחד אשר אדעיהו. בית השני מבית גורוויץ ואחר אשר יסעו המה יוכל
        היות כי יגורו בבית גורוויץ. אין רע בלי טוב. לפי שעה טוב מאוד הדבר כי בעל
        כרחה תתרחץ בים את אשר לא עשתה בכל אופן אם לא הסיבה הזאת. נסענו דרך מקוה
        ישראל ובקשנו מה" גייגו וורשה וקחת את ליטמווך עמנו. ובשבת הזה נהיה כלנו
        יחד בבית הוריך. איך את ועשהאל? הסי חושי הסי! לא עת התפעלות היום די לי
        די. הוואנדאנז כבר זה שלשה ימים פה בראשון ואך מלבך מקבלים. וקאבערנות יהיה
        כנראה ביום השלשי. יוכל היות כי אסתר תבוא על יום השבת הזה הנה אז יהיה לי
        באמת שבת נחמו. הלא אז באמת תתקנאי בי האם לא כן? אני משביעך כי תקחי כל
        מכתבי ותשימי מהם צרור כי לא יאבדו כי יקרים הם למאוד. אני בעצמי אהיה לך
        אסיר תודה אם בזמן מן הזמנים אוכל לראותם עוד פעם, שמעי נחמה, כחי לך מועד
        וקראי כל מכתבי. וכתבי לי מכתב גדול ובו תכתבי כל אשר בלבבך בלי מפריע כי
        אדע מה עמדך, אשר גמרתי אני כבר ידוע לך "מהמשל" אם תשימי לבך אז תביניהו
        כי בו צפנתי כל נחמדתי, חיי בטוב ובנעימים כחפץ מכבדך לנצח מיכל זלמן
        פאחאטשאווסקי</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >פרסי בשלום יעקוב אחינו בה"ב ובשלום הורי אם תראי אותם? שלום לכל
        חברינו.</span
      >
    </p>
    <p class="c6 italic" dir="rtl">
      <span class="c8 c0">[בהמשך בא מכתב ביידיש, כנראה מאמא של נחמה אליה]</span>
    </p>
    <br />

    <p class="c6 italic" dir="rtl" id="06,08,1896">
      <span class="c0 c8">[06,08,1896]</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">ב"ה פה ראשון לציון, יום ה" כ"ז לירח מנחם אב תרנ"ו</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2">לנחמתי יקרתי, ולבני מחמלי שלום עדי נצח!</span>
    </p>
    <p class="c6" dir="rtl">
      <span
        >את מכתבך ביום 13 אב אשר כמעט אחר יאוש קבלתיו לנכון. גם מכתבך הנכתב בידי
        אחיך יעקב אדע תכנו. אף כי כבר הוריך יושבים ביפו כידוע לך, אך אביך בא
        לבצור ענביו הקאבערנע לכן ישב אצלי כל ימי השבוע. ואך היום בבוקר הלך יפו.
        וכן היה לאל ידי לדבר עמדו ארוכות וקצרות, והוא בהעת האחרונה נשתנה מהקצה
        אל הקצה בדברים הנוגעים לנו, לזאת מצא לנכון להראות לי את דברי יעקב
        הנאמרים בדעת צלולה ובדעה מיושבת, אך גם כי דברי יעקב יטפחו על פני אביך את
        רשעתו נגדי. אך הוא יצטדק בזה כי בהיותך תמיד כלואת הרוח ולא יוכל לדעת מה
        בחובך לכן גמר אומר כי זאת הוא חפצך היותר נעלה בחיים לעשות </span
      ><span class="c1">הצעד הארור</span
      ><span
        >. אך עתה בראותו דברי יעקב האחרונים אשר הם דבריך, יתחרט מאוד על דבריו
        ומעשיו הראשונים, ומעתה הוא מבקש קרבתי וידבר על לבי הרבה מאוד להרגיע את
        רוחי הסוערה. ולהבליגני נגד היאוש הארור אשר התגנב בקרב לבי עמוק. ***
        אכלני מנפש עד בשר תתפלאי נחמתי עלי למה זה שנותי טעמי בהמכתבים האחרונים,
        ואך יאוש ויאוש מורגש בהם, ותבקשי תואנה לזה במכתבך מק"ב. לא לא! נחמתי לא
        דבריך המעטים אשר מצאתי יכלו להצעידני למֹלך בלהות. יותר מזה מנעך ממני
        הכתיבה לגמרא. אך גם זאת איננה הנסיבה העיקרית כי חשבתי דבריך מאז הלכת
        וראיתי כי מלחמה עצומה בקרבך ולא תוכלי להכריע את הכף, וחפצך העז היה לדבר
        עם יעקב ולהתודות לפניו. ואז אך אז תוכלי להשיבני דבר על מכתבי המלאים רגש,
        ואשר יכאיבו את רוחך המלא רחמים. ואך הוריך וניימאן עמהם הם הם היו בעוכרי
        הם העציבו את רוחי וימררו את חיי עדי כמעט אמרתי נואש. ומה המריצם לעשות
        כדבר הזה נראה בעליל מדברי יעקב האחרונים, קשה לי גם עתה נחמתי להפרד
        מהיאוש שלי, אף גם אחרי דבריך האחרונים אלי, אשר כמעט מלאים תקוה לעתיד
        כחפצי. אך מה ימריצני זאת בידעי כי תעשה זאת אך מפני ההכרח הידוע. וחייך
        יהיו מרים כלענה תמיד. האם תוכלי להנעים לי עוד רגעי חיי בחלדי? והאם יש לי
        עוד תקוה בחיים, האוכל לקות לימים טובים מאלה בעת אשר אדע ברור כי אפול
        עליך למשא ונפשך גם רוחך רחוק רחוק ממני, אף כי הודית כי ידי זרים היו במעט
        הזה, אך המעט כבר עשה פרי על תלמי לבבך וקשה מאוד לשרש אחריו, לאסון
        שלשתנו, לזאת נחמתי מצאתי כי הדרך היותר ממוצע הוא דרך היאוש בעדי. אם אומר
        כי איש צעיר לימים אנוכי החפץ חיים, וצריך לחיות, כאחד האדם אז בודאי לא
        אמצא זאת לעולם ותמיד אהיה איש נודד לאשרו ואננו. אך בקחתי לי דרך זר כזה
        אז לא אמצא עוד אבני נגף על דרכי לעולם. ותחת זאת אהיה תמיד שמח בחלקי. אם
        יוטב לי מצבי על איזה רגעים בחיי. ואם יצר ח"ו אז גם אז לא יצר לי כי לכך
        נוצרתי. ודי לי אם אוכל לעשות טוב עמדך ועם בני היקר לי מכל יקר בתבל זה כל
        יעודי בחיים. ואין לי עוד לבקש תענוגים בחיים. אחיה יחידי וערירי עד בוא
        פקודתי ותרחמני. אך דבריך על דבר בריאֻתך העציבו במאוד את רוחי כי שמעתי
        אשר כמעט אין לך עוד תקוה להיות בריא לגמרא. היתכן כי גם שם אין מזור
        למכתך? צר לי מאוד! על דבר הלימוד הלא כבר גלותי לך את דעתי כי אנני מתנגד
        לזה מעיקרא, אך מצאתי כי זה דבר לפני זמנו כי טרם תהי בריאה לפחות עד כי
        תוכלי להתעסק בלמודים, והילד יהי מורגל בהאויר הקר והמוזר לו עליך להמתין
        עוד. אך אם את תמצאי כי אין לדברי שחר ותביני עליך לעשות הצעד הזה דוקא
        בהחורף הזה אז היכולת בידך לעשות וגם אתן הסכמתי לדעתך. גם על דבר הכסף כל
        אשר ביכלתי אעשה. אך שמעי נא בקול יעקב אחיך ואת אשר ישפוט הוא כן תהי. כי
        מצאתיו לאיש ישר ונאמן ובית השופט שלו יותר טוב משלך. ואת אשר תאמרי להטיב
        מצבנו החומרי במלאכתך זאת אין לך כל תקוה. כי ביפו היו הרבה פעמים רופאי
        שינים והלכו להם בנקיון שינים אף עתה תבוא הנה אשת מרגלית קראמארינסקי
        בדיפלאם מאושר עם פראקטיק, ותפתח לה מכון ביפו לקבל חולים הלא אין כל תקוה
        לך. אף גם לא אוכל בשום אופן להאמין כי את תוכלי לעקור שינים מאיזה איש או
        אישה או ילד. ולוא לא היה הדבר הזה מורכב עם הוצאות רבות אז אין כל רע. אך
        כידוע תוציא כסף הרבה ואם לא תוכלי להרויח מזה מאומה אז תביא רעה עלינו ולא
        טובה. אך מי יודע את רחשי לבבך יוכל היות כי יש לך פטאן אחר לגמרא איך
        להרויח ממלאכתך זה. אך אחת גמרת לירוא מפני ולהוליכיני שולל תמיד בהצטדקך
        תמיד הלא יראתי לאמור לך מתחילה פן ואולי? ואת אשר תאמרי לי כי אקרא את </span
      ><span class="c1">מיל</span
      ><span
        > והשקפותיו על הנשים טוב הדבר האם לא אמרתי תמיד כדבריו? אך האם דרך אחד
        מצאת ואין שני לו אשר יתאים עם הדעה הזאת? ואת אשר קשה לך לשבת כל ימי
        החורף בבריסק זה בודאי ידוע לי. והלא תוכלי לשבת בקייב ולשאל מפי הפרופסר
        את תרופתך ובין כך תוכלי להתענג הרבה שמה בימי החורף וגם יוכל היות כי
        תמצאי איזה דבר המתאים לדברי </span
      ><span class="c1">מיל</span
      ><span class="c2"
        >. ואם תדעי כי אין כל מורך לעזוב בנינו היחיד והמיוחד בחיינו, על ידי
        אנשים זרים השומעים למשמעת אחיך, טוב! גם אני אתן את הסכמתי מרחוק. ואת אשר
        אמרת כי שכרינו מעט ותחפצי לקחת משרתת על זה אל ידאג לבך זה דאגתי אני וגם
        עתה יש לי משרת בבית וכל מי אשר יבוא בביתי יתפלא על הנקיון והסדר. תבקשני
        להתודות על דבר הדיפלום עד עתה לא היו עתותי בידי כי טרוד היתי עם הענבים
        זה תמול בלילה גמרתי ענבי. וענבי אביך כנראה יהיה כמו בשנה העברה לא יותר
        ענבים כי הסיבות היו רבות כידוע לך. טוב עשתה אחותי חנה כי באה אלי על ימי
        הואנדיונג. עתה היתי בעל הבית וחיים עם אביך אכלו על שלחני ואני גם כן
        עבדתי הרבה מאוד ולוא לא היתה חנה כי אז נפלתי חלל. היום כמעט ישנתי כל
        היום כי זמן קבלת הענבים כל הלילה. לכן לא ראיתי איש אך עוד יש זמן לדבר
        מזה. האדרסה של ילין מונחת תחת ידי ואשלחנה לך אולי תמצא בה איזה
        חפץ.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >חיים בא והנהו בריא ועובד עבודתו בהמרתף וממרים עוד אין לנו שום ידיעה
        והיא כבר עברה את הזמן לפי חשבונה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >חנה אחותי בכתה ולא יכולתי להרגיע את רוחה בראותה כי אין כל דבר וחצי דבר
        מהורינו היתכן כי לא ראית את הורי? והם הלא זמן רב יתחננו על בואך. והילד
        חיי רוחם האם לא באו לראותכם. ואת מפני הכבוד לא הית אצלם אם באמת ובתמים
        את כתבת את המכתב האחרון הזה? פלא הדבר הזה בעיני ועוד יותר עליך נחמתי.
        תחפצי לדעת משלומי היינו מבריאותי אומר לך כי מהמחלות השוררות לא אסבול מכל
        וכל אך אסבול מאוד מהנערבין שלי והם יפילוני לערש דוי מדי פעם. ומקום לאכול
        לא מצאתי אלא אחפש עדי תבוא מרים כי לא כבד לי הדבר כאשר תחשבי, והעת לא
        תלך לטמיון, כאשר אם אהיה מוכרח לשבת על שלחן אחרים. אני עובד הרבה על השפה
        הצרפתית גם אעשה חיל בה יותר מאשר חשבתי, ואם אקבל ממך מכתבים טובים אשר לא
        יפעלו לרעה על העצבים אז אהיה בריא ושלם ואעשה צעדים לפנים בהשפה
        וספרותה.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >אשמח מאוד על הבשורה כי עשהאל נעשה לילד אחר, ושמח עם הילדים ומוצא את
        עצמו מאושר בחברתם, מתי תשלחי לי את תמונתו? או עם תמונתך? הלא תקני לו
        איזה דבר חפץ בשמי אינני חפץ כי ישכח אשר יש לו אב כואב.</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">חיו בטוב והיו שלום כחפץ החי בעדכם</span>
    </p>
    <p class="c6" dir="rtl">
      <span class="c2">                                         בעל הנפ"ש</span>
    </p>
    <p class="c6 c7" dir="rtl"><span class="c2"></span></p>
    <p class="c6" dir="rtl">
      <span class="c2">שלום לך יעקב אחי!</span>
    </p>
    <p class="c6" dir="rtl">
      <span
        >צר לי מאוד עליך אחי כי באת בין המצרים. אראה לבבך מרחוק כי מלחמה בקרבו.
        תקח חלק בגורל כולנו ותשזר בצרות שלשתינו. בצרת האב ובתו וצרותי אני. אבל
        אזור חלציך ואל תספה במלחמה גבור! בידך עתותינו עתה נתונים. דע כי הרבה
        פעלתי על רוח אביך. גם על נחמה לא מעט. ואני אומר את האמת ערום כאשר נתתי
        בידך אז טרם נתקשרתי הכח, כן גם עתה אשים בידך עתותי והנני מוסר דני בידך.
        אף כי טרם תדע ממני דבר ודי את *** ספרה לפניך נחמתי, כי תדע עם מי הצדק.
        ועליך אחי מוטל הדבר לישר ההדורים. אך זאת אבקשך אחי אל תלך בדרך אחותך
        לאמור מורא מורא! אין לך לחשוב כל מורא בלבבך עוד, לא עשיתי דבר קטון אשר
        ייראו ממני אנשים, ואת אשר </span
      ><span class="c1">עשיתי</span
      ><span class="c2"
        > בפעם האחרונה גם כן עשיתי בישוב ודעת, ולוא הייתי איש אשר יש לרוא מפניו
        כי אז עשיתי דבר יותר רע ומר מזה הרבה……</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >ואל תאמר "קנאתו הנבערה" טרם תדע מה זאת, וכמה קשה לסבול, וכמה זמנים עבר
        עלי טרם גמרתי לעשות קץ לסבלי. הנה נחמתי לפניך היא תודה במו פיה עד כמה
        סבלתי מהוריך טרם יצאתי נגדם. וכמה סבלתי ממנה טרם עשיתי את הסקאנדאל
        הידוע, אז תדון כי אין לירא ממני. כי כל אשר בשם אדם יקרא לא יוכל עמוד
        בנסיונות כאלה ולשב בחיבוק ידים, אחי אחי! לבי קרוע לגזרים בזכרי עד היכן
        הביאני המקרה עתה, ולצרת נפשי אין קץ, אזכור את ימי שלותי, מה נעים היה לנו
        חיינו כמה פעמים אמרנו הבאמת כבר עבר עלינו ארבע או חמש שנים אחרי
        חתונתינו, הלא הננו אוהבים איש את רעיהו כביום הראשון, ועתה שער בנפשך את
        הקרע בשנתים האחרונים. אשאר ידידך</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c2"
        >                                                  אחיך נצח מיכל</span
      >
    </p>
    <p class="c5" dir="rtl"><span class="c2"></span></p>
    <p class="c7 c18" dir="rtl"><span class="c2"></span></p>
    <p class="c5" dir="rtl"><span class="c2"></span></p>
    <p class="c12 c7" dir="rtl"><span class="c15 c10 c0"></span></p>
    <p class="c5" dir="rtl"><span class="c2"></span></p>
    <hr class="c25" />
    <div>
      <p class="c24" dir="rtl">
        <a href="#ftnt_ref1" id="ftnt1">[1]</a
        ><span class="c10 c21"
          >  סביר להניח שהכוונה למחלקה השניה בגימנסיה, כלומר לכתה ו&#39;</span
        >
      </p>
    </div>
  </body>`;

export default page;
