const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c1" dir="rtl">
      <span class="c5">דבר</span
      ><span class="c6">   28.5.1934 (ללא שם הכותב) </span>
    </p>
    <br />

    <h3 class="c1" dir="rtl">
      <span class="c3">לויתה של נחמה פוחצ&#39;בסקי</span>
    </h3>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מודעות אבל מטעם המועצה המקומית, הסתדרות נשים ציוניות ומשפחת המנוחה
        הודיעו ביום ב&#39; בבוקר על מותה הפתאומי של נחמה פוחצ&#39;בסקי ואבל כבד
        הושרה על המושבה לכל חוגיה ושדרותיה. ב-10 בבוקר התאספו על יד ביתה אכרים,
        תושבים, פועלים, כי כאם וכאחות רחמניה היתה המנוחה בעיקר לעדת התימנים,
        וכולם באו לחלק לה את הכבוד האחרון. מהבית זזה הלויה לבית הכנסת. בין
        המלוים שאול טשרניחובסקי, ד. שמעונוביץ. הראשון למספידים היה מר פריימן בשם
        המועצה המקומית, וצימרמן בשם התאחדות נשים ציוניות, טרכטנברג בשם הועד
        החקלאי, מ. מאירוביץ, ד. יודילביץ וד. לובמן בשם זקני הישוב, ד. שמעונוביץ
        בשם אגודת הסופרים, מרת כהנסקי בשם סניף נשים ציוניות בראשל"צ, הח&#39;
        מינקוב בשם מועצת הפועלים, קרון וזרובבל לובמן בשם בית הספר, בשם התימנים
        אמר דברי הספד נער תימני, חניכה של המנוחה אשר הודות לה נכנס למדרש המורים
        בירושלים, בשם המשפחה – בן כהן. כל המספידים דיברו על אישיותה של המנוחה
        בתור עסקנית, סופרת ובעלת נפש רגישה. על יד הקבר הספידוה סמילנסקי וכץ.
        רושם כביר עשו בעיקר דברי מ. פוחצ&#39;בסקי, בעלה של המנוחה. הוא אמר:
        לכולם סלחת, נחמה, בטוחני שסלחת, אולם רק לי לא סלחת ולא תסלחי, על החטא
        עבודה ערבית שחשדת בי, נחמה, את הצדיקה ואני הרשע".</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">חפויי ראש ואבלים התפזרו המלוים.</span>
    </p>
    <p class="c1 c2" dir="rtl"><span class="c7"></span></p>
  </body>
</html>`;

export default page;
