const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c11 doc-content">
    <p class="c6" dir="rtl">
      <span class="c1"
        >הירח, שעלה בשעה מאֻחרת מעבר להרי יהודה, האיר את השכונה התימנית ואת
        החרשה הסמוכה אליה, ויערך שם משחק קסם בין העצים, – מתחבא הוא מאחריהם,
        מציץ מבין הדליות ומציר תמונות שונות: רשת מזהירה בין ענפי אכליפטוס אחד;
        ספיר ויהלום נוצצים בין עלי השני וצמרתו של השלישי הוארה פתאם באור פלאות,
        כי טפס שם הקוסם וצף למעלה, טבל את זהר עגולו בסביבה – וחיך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והשמים הזרועים ככבים חורורים, עמוקים הם ותכולים-כהים; רחוק מעבר לחרשה
        הצטברו עננים קלושים מכסיפים עם עטרות של ארגמן, שהו שם רגע קל ויתחילו
        להתפזר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קרקור צפרדעים וצרצור צרצרים נשאים באויר החרשה ומפריעים את דממת השכונה
        השקטה, שנרדמת בשנה מתוקה אחרי עמל היום. רק רֻמה אינה ישנה עוד. תוקף אותה
        השעול, תוקף ואפילו לשכב במנוחה אינו נותן לה. היא נסתה, לשבת אצל חלונה
        הקטן ולכאורה הוקל לה. הנשימה אינה כבדה ביותר, הדקירות בחזה כאלו מסתלקות
        ממנה והיא רוחשת ברכה לאל: "ברוך השם, ברוך השם!" – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רמה תולה את עיניה השחורות והטובות בשמים, וכעין רכות ילדותית זולפת מהן
        כשהיא מהרהרת בנחמה: – האמנם רחם השם, והיא תחיה, תחיה?... וכלום בתמים קלל
        אותה סעיד, כלום הוא רצה במותה? לא! אי אפשר זה, אי אפשר! אלא שמרֹב אהבה
        הוא בטא את דבריו הקשים, מאהבה ותו לא!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רוח מערבי חזק מלטף את פניה. חודר דרך חתך החלוק-שמלה ומחלק על לבה הרזה.
        גם מתחת המטליות השחורות, המהדקות את ראשה, הוא מתגנב וכאלו מעביר שם בתער
        על תלתליה השחורות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נעוצות עיני רמה בצללים ענקיים, אלכסונים שהתפשטו על קרקע החרשה ומשם
        מבצבצות לפניה זו אחרי זו תמונות העבר הקרוב. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרי ביתם שעל גבי החנות בחדן-שַם! מלא הוא כל טוב: קמח בשק גדול, כותח
        במחלבות, גבינה, חמאה ומלא סיר בשר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לשבתות היה סעיד מביא מן השוק כל מיני פֵרות וגם בשמים וצמוקים לקדוש.
        הבשר בסיר רותח והשבת מתֻקנה כיד המלך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרבה אורחים היו מבקרים אותם ביום השבת! סעיד, נ"ע, אהב מאד לטפל בקבלת
        האורחים! זוכרת היא שעל ברית מילה של יחיא, ולדה האמלל, שחט סעיד שור
        גדול  ושני ימים ושני לילות בלו כל תימני חדן בהלולא וחנגא, אף על פי
        שהזמנים הרעים כבר הגיעו להם אז בתימן ושנאתם של הגוים לבני ישראל מורגשה
        היתה בכל, וכבר דחפה אותם לצאת משם לכל אשר ישאם הרוח...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יציאה... מחנה גדול נעתק! – מאתים ושלשה עשר איש, שעזבו את בתיהם ואת
        אדמתם בידי הגוים שונאיהם! כלם עולים לארץ ישראל. לאדמת הקדש, שהשכינה
        שרויה בה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי דרך קשה! – קרני השמש שורפים ויוקדים אותם עד שהוטלו כל הימים כמתים
        על פני המדבר; רק לפעמים רחוקות אירע להם דקל להשיב נפשם בצלו הארך. כל
        הליכתם היא בלילות. ויחיא שלה מוטל על השכם רעב, מפרפר ובוכה בלי הרף, –
        הרי הצטמקו שדיה מיסורי הדרך, והתיבש החלב בתוכן! ולדה המסכן מתנפל על
        הפטמה השחורה, מוצץ ונושך ומתפרץ בבכי קורע לב. והיא? את המות היא מבקשת
        עליה ועל ולדה, מקללת את עצמה ואת העולם כלו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי! – שכחה היא, החוטאת, את השם, והוא התכעס ויענישה קשה!... כן, עונה
        גרם לכל האסונות שלה, רק עונה!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רמה משתעלת אט, גוחנת וקונחת את דמעותיה בקצה חלוקה-שמלתה הכחולה-כהה.
        –</span
      >
    </p>
    <p class="c10" dir="rtl">
      <span class="c1">*   *   *</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזוכרת רֻמה: – עיפים ויגעים הם באו לשֶח-עתמן. עירה קטנה על שפת הים,
        ומשם ירדו בספינת מפרשים לעדן. קטנה היתה הספינה והם הוטלו בתחתיתה צפופים
        ודחוקים. מקום לשכיבה לא היה בה, וכשתקפה אותם השנה, היו צונחים את ראשיהם
        איש על צד ועל כתף רעהו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רעבים וצמאים הם... חבית יחידה בקצה הספינה... ממנה חלקו לנוסעים כוס מים
        אחד בבקר וכוס בערב. ומהר התעפשו המים ורוב הנוסעים חלו מהם... ולדה המסכן
        היה מהדק את שפתיו אל הכוס מוצץ וגומא בכעס את סם המות... צבתה בטנו...
        צעקותיו לא פסקו יומם ולילה ובבקר אחד נשתתקו, נשתתקו לעולם! – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתכוץ לבה של רמה, ומתוך חרדת נפשה היא נזכרת בשעות המרות שאחרי הרגע
        הנורא ההוא. – הגוי הרשע, הספן, רוצה להטיל את גוית ולדה המימה והיא לא
        נותנת; מאהילה עליו בכל גופה וצועקת, שאת שניהם ביחד יטילו הימה, רק את
        שניהם. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סעדיה מתחיל גוער בה ואחרי כן חוזר ומשדלה בדברים רכים:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ולדה, הכי לא מהשם יצא הדבר, הוא נתן והוא לקח, – ברוך דין אמת! – דמעה
        נוצצה בעינו ויסיר את מבטו ממנה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא לשתוק לא יכלה בראותה איך ששמו את מתה בשק, קשרו אליו פח מים
        וישקיעוהו לתוך המצולה... רצתה היא לקפץ אחריו הימה, לטבוע אתו בתוך קצף
        הגלים. אלא שהרבה ידים אחזו בה ועצרוה. התלבטה היא התלבטה והתעלפה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזוכרת היא כיצד התחיל הים זועף באותו ערב וסוער. הספינה התנדדה כשכורה
        ואמרה להוריד את נוסעיה תהומה... התחילה יללה, צעקה ובכיה... תלמידי החכמים
        וסעדיה בראשם התעטפו בטליתיהם והתפללו כל אותו  הלילה... והיא, היא, תפלתם
        הבוקעת שמים, הצילה את חייהם! אותה שמע יושב הכרובים וישקיט עד הבקר את הים
        מזעפו…</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ורמה נזכרת אילך את החוף השמם בקרבת עדן. על החוף ההוא הורידו אותם לארבעה
        עשר יום. "קרנטינה" – קראו הגוים למקום ההוא, שגם שם אירעו להם מקרי מות, –
        הרי אבדו שם לחבורה, יעקב אבן דוד ואותה הילדה היפיפיה של סעידה מרת כהן!
        שני קברים נחפרו בשדה תחת דקל בודד וכל העדה ישבה והתאבלה. ולא על מתיהם
        גרידא התאבלה העדה, אלא גם על החיים, שעתידים למות ברעב ובצמא…</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל רחם השם, ושדר עזרתו ע"י שליחו הנאמן, זהו התימני העשיר מעדן, שהתחיל
        שולח יום, יום שני שקים לחם ושק תמרים להחיות את נפשם; גם את מחיר המים
        הורידו ע"פ פקודתו… "רחמנים יהודים, גוֹמלי חסדים!" – דובבת חרש רמה
        וזכרונה הוליך אותה הלאה. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הריה עדן! – בעיר יפה וגדולה זו נטפלה אליהם שירה אחרת, שגם היא עולה לארץ
        ישראל… איש חסדם השתדל שיפחיתו ממחיר כרטיסיהם ונתן צו לאנשיו שיחלקו ביד
        נדיבה צדה לדרך לכל העולים ארצה ישראל. – "בחייו! יאריך בימיו!" – כלום לא
        מלאך טוב זה, ששלח השם לפניהם?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזוכרת רמה את תמהונם הגדול כשהם התכנסו לספינה שהפליגה מעדן, – מה יפה
        היתה וגדולה, – מדינה שלמה!... נפשותיהם הנלאות נחו בה ממצוקותיהם ויסוריהם
        הקשים. שני המשוררים, דוד בן פנחס ושלמה כורסן הרי התחילו שם לחבר את
        שיריהם הארכים ע"ד הארץ הקדושה והנפלאה, ששתי עדות מישראל הולכות ומתקרבות
        אליה! שם שרו המשוררים! –</span
      >
    </p>
    <p class="c10" dir="rtl">
      <span class="c1">*   *   *</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי שעול חזק, שתקף את רמה גם אצל חלונה, היא צנחה עיפה על משכבה, התכסתה
        במעיל ישן מעל לראשה וסגרה את עיניה. – היא מתאוה לשנה, למנוחה, אבל
        הזכרונות הנגררים מאליהם אינם נותנים מנוחה, אינם נותנים להרדם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >...מושבה זו, שהם באו אליה כשבוע ימים אחרי רדתם על חוף יפו, – נראה להם
        כגן עדן פורח עם פלטרין יפים, שמלכים ובני מלכים יושבים בתוכם ומתענגים על
        כל טוב… מחלונות אחדים מתפרצות ערב ערב מנגינות נעימות וערבות לנפש. ויש
        שמעל מדרגות הפלטין הכי יפה, הוא בית התפלה שלהם, עולה ובוקעת שירת המקהלה
        של הבחורים והבחורות המתאספים בחבורה וצוהלים ושמחים שם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זוכרת היא כמה תמוה היה בעיניה, כשראתה בפעם הראשונה כיצד ילדה יפה אחת
        ישבה וקשקשה באצבעותיה הדקות על פני תבה גדולה, והלה הוציאה זמר יפה וקל
        ומחיה נפשות! היא כל הלילה דברה אדות זה עם סעדיה; והוא נ"ע, הרי היה ג"כ
        אוהב מאד את מנגינותיהם ואת שיריהם, ויש שעד שעה מאחרת אינה יכולה להטותו
        ללכת הביתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל אחרי ישיבה מאחרת, יש שמתעצל סעדיה לקום לעבודה והיא צריכה לפתותו
        ולשדלו שלא ירגיז את האדון ולא יבטל יום של עבודה. מצית לה סעדיה וקם
        להתפלל שחרית, והיא בינתים אורזת לו במטלית לבנה את ארחתו, מזרזת אותו והם
        יוצאים יחד לעבודה – הוא אל הכרם והיא אל בית גברתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זוכרת היא שמתחילה לא הבינה כלום בכל עבודות הבית ההוא. – לכאורה הכל נוצץ
        בו ומבריק ואין שום צרך לרחץ ולשפשף את אותה הרצפה היפה גם בלאו הכי. למותר
        נדמה לה גם נגוב הארונות והכסאות והשלחנות ושאר הרהיטים, שהם נראים כה
        חדשים ונקיים; ובכלל תוהה היתה מאד על כל הדברים המיותרים ההם, שעל פי דעתה
        הם תופשים אך לחנם מקום בחדרים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >השאלות: למה-זה? ולמה-זה? נקרו במחה בלי הרף. יש שבאמצע העבודה היא עומדת,
        מסתכלת ומהרהרת. ואם אירע שנתקלה בה הגברת ורואה אותה בכך, היתה תופפת על
        שכמה ומזרזתה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– בבקשה, רֻמה, אל תתרשלי, הרי היום קצר והמלאכה מרובה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"מרובה, מרובה!" היתה חוזרת בלבה. ולמה להם כל השטותים הללו?... מה איכפת
        לאדם, כשהוא ישן לו על מחצלת יפה ונקיה, וחדרו ריק ומרוח? – הרי תענוג
        הוא!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ולא עברו ימים מועטים וקרה הפלא! – היא בעצמה התחילה להרהר כיצד לרכש אי
        אלו דברים להכניסם לחדרה הריק. כיצד להפטר משכיבה על מחצלות ולרכוש לוחות
        בשביל ספה לשנה, לרכש ארון קטן, כסא, שלחן...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומצבם אז הלך וטוב אחרי שנזדמנה עבודה לסעדיה אצל חוג&#39;ה ברנס, אשר נתן
        לו דירה בחצרו וירקות מגינתו. זוכרת היא את כל הטוב שנהנו ממנו, מן הגן: -
        בצלים, שום, צנון, פול וגזר! מה עוד צריכים היו? – קמה לפתות לכל ימי השבוע
        ונוסף לזה צואר או רגל של פרה לשבת ותו לא!... בגדים יפים, יפים הרי נתנו
        החוגַ&#39;ת, מכף רגל עד הראש לבשו אותם. והשכר שלהם נצטבר ונצטרף
        לנפוליונים שלמים, - מה חסר להם?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל היא לא ידעה להודות להשם כהוגן! יש שעלתה התמרמרות קשה על לבה והיא
        בכתה בחשאי על אבדן ילדה היחיד שאין תמורתו. וחטאה היא להשם, חטאה!...
        ויודעת היא שאלמלי קבלה את יסוריה באהבה, לא היה השם מתרעם ומעניש אותה
        בענשו הקשה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי סעדיה, כבודה והדרה! הרי הלב היה מתמוגג מתענוג, כשהתלמיד חכם שלה,
        בעלה, עטרת ראשה, היה משנן על למודו בנגונו היפה והצלול! סבורה היתה ברגעים
        המתוקים ההם שכל מלאכי השרת קופצים ואומרים אחריו שירה... הוי למה לקחו השם
        ממנה? למה נטלו מן העולם היפה הזה בלא עת?</span
      >
    </p>
    <p class="c10" dir="rtl">
      <span class="c1">*   *   *</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ויום חליו של סעדיה מבצבץ בזכרונה: - זה היה בערב שבת. היא מהרה לגמר את
        עבודות הגברת, בשביל שתקדים ללכת ולסדר גם במעונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם באותו יום, כמו כל השבוע ההוא, התאוננה באזני הגברת על הצנה שחודרת
        מתחת למלט בשכבה על הרצפה, ותתכון לאותן לוחות העץ שהתגלגלו בחצר ושהשתוקקה
        להן בשביל להכין משכב לסעדיה, רק לסעדיה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הגברת הבינה לרעה ונתנה לה את הלוחות בלכתה אז הביתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ברוכה, ברוכה תהיי! – הביעו שפתיה לגברת בהכרת תודה, והגברת לטפה על
        לחיה ותאמר לה בחיוך, שאם רק חרוצה תהא, אז תקבל ממנה מתנות עוד טובות מאלה
        הלוחות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– בחייך, יאריך השם בימיך! – ענתה על חבת הגברת ותסתלק ממנה בזריזות.
        ובנשאה אז את הלוחות בצבצו כבר ההרהורים ע"ד ארון, שלחן, וכסא...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובחדרה היא עבדה ביום ההוא בענג מיוחד, – סידה תחילה את הקיר ששם קבעה
        מקום לספה. העבירה בחלק הקיר התחתון קו שחור של פיח מערב בסיד, כמו שעושים
        בבתי החוג&#39;ת. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכשהתקינה את הספה ופרשה עליה את שמיכת המוכין החדשה, אשר סעיד קנה אותה
        בשקל אחד בעיר, היא התחילה לתקן לו כר מנוצות של תרנגלות שצברה אותן במשך
        כל הזמן. עטפה היא את הכר בחלקת ארג ורד שחתכה משמלתה. חבל היה להפרד משמלה
        זו, אבל כלום קשה קרבן קל זה בשביל סעיד שלה, החביב שלה? –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כחוג&#39;ה ממש ישן החביב!" – הרהרה אז מתוך מלאכתה, כשנפשה צללה בים של
        תענוגים... מאין התגלגלו אז על שפתיה חרוזי השירים – על גאולתם ופדות נפשם
        מארץ טמאה, והודיה לשם על הביאו אותם לארץ טהורה, יפה, נעימה? הרי כמו אצל
        הזקנה חממה, ככה נבע גם אצלה מעין השירה! נבע ותאר גם את שמחת סעיד במצאו
        ספה יפה זו להסב עליה, תאר מה יפה יהא הקדוש שלו בליל שבת זו ומה יפות
        תהינה זמירותיו... –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי, מי מלל, שסעיד יובא חולה מסֻכן מן השדה ולא יתענג יותר על החיים? –
        הוי לנפשה, הוי למזלה הרע!...</span
      >
    </p>
    <p class="c10" dir="rtl">
      <span class="c1">*   *   *</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי בכיה ממושכה תקף שוב את רמה שעול חזק. קשה לה השכיבה ותחזר לשבת אצל
        חלונה, כשהיא נושמת בכבדות ופניה עֻוו מיסורים. פתאם בצבץ לפניה סעיד
        ברגעיו האחרונים. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">עם עמוד השחר הוא העיר אותה:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ולדה, יא ולדה! – קרא אז בקול רפה, שנדמה לה כי מהארץ הוא יוצא. היא
        נרתעה אליו בפחד ותזדעזע. – פניו חורים, חורים, ומעיניו האיומות כאלו נשקף
        כבר מלאך המות, אלא ששפתיו עוד נעות בלחש... היא גחנה ותט אזנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– יא ולדה! – רטטה לחישתו, הגיעה שעתי ואני הולך למות!... לתקופת השנה את
        תבאי אלי, ולדה... הלא תבאי?... לא תנשאי לאחר?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שעול עוד יותר חזק תוקף כאן את רֻמה ואתו חוזרות הדקירות שבצדה השמאלי
        והיא מתחילה רוטטת בחששיה. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >. . . מה זה? – המות?... כלום באמת סעיד קורא אותה והשמים חתכו את גזר
        דינה למות? –  –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">היא צונחת על משכבה באין אונים, בוכה ומתחננת:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– אנא קחני השם! נטלני מהר ואל תאריך ביסורי!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">כובשת היא פניה בכרה הקשה ובוכה, בוכה מרה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחרי שעה קלה כשהשתתקו מעט עצביה, סר גם פחדה הפתאומי, ודעתה התחילה
        מתישבת עליה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– – – וכלום אכזר חס ושלום היה סעדיה שלה?... או השמים רחמים אין בהם,
        שיטרידו גם אותה מעולם יפה זה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ורמה מתארת לה בדממת הלילה את כל אותו היֹפי שעיניה לא תשבענה ממנו ביום:
        – כאן יער העצים הגבוהים, הזקופים; אחריו משתטחים הכרמים והשדות הירקים;
        ושם, הלאה, – עדרי צאן ובקר הגולשים מן ההר, ושוב חוזרים ועולים
        בחולות...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והתיצבה פתאום מול עיני רמה, – פדיה, שחורת התלתלים! – יתומה קטנה זו,
        שברצונה לאספה אליה, – הרי כה נחמדה, כה זקוקה לרחמי אם!... ורמה מחליטה:
        "לא! לא יתאכזרו השמים ולא יוציאו עליה את גזר דינם הקשה!"...</span
      >
    </p>
    <p class="c10" dir="rtl">
      <span class="c1">*   *   *</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובין תקוה ופחד היתה רֻמה מוטלת חדשים שלמים עם שעולה, וכאב בצדה ועם
        יריקת דם בסוף ימיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">ביום ה"יארציט"</span
      ><sup class="c4"><a href="#ftnt1" id="ftnt_ref1">[1]</a></sup
      ><span class="c1"
        > של סעדיה הורע לה מאד, התעלפה היא פעמים אחדות וכבר אמרו באותו יום נואש
        לחייה. אבל הם נמשכו עוד יומים ביסורים קשים.</span
      >
    </p>
    <p class="c0 c2" dir="rtl"><span class="c1"></span></p>
    <p class="c12" dir="rtl">
      <span class="c1"
        >ובמותה התאבלה עליה כל השכונה, והנושאים אותה משם לבית העלמין הרהרו: "חבל
        על האי שופרא, שקללת בעלה הוציאה אותה מן העולם!" – ועיפים ונוגים הורידו
        התימנים את האלנקה על אדמה טחוחה אצל קבר חפור בשביל רמה סמוך לארז גבוה
        וחסון.</span
      >
    </p>
    <p class="c2 c3" dir="rtl"><span class="c5"></span></p>
    <hr class="c8" />
    <div>
      <p class="c9" dir="rtl">
        <a href="#ftnt_ref1" id="ftnt1">[1]</a
        ><span class="c7"
          > התימנים כותבים מלה "יארציט" ממש בתבנית זו ומפרשים אותה ככה: יום אסוף
          רגלי צדיק, ינוח טוב!           המחברת.</span
        >
      </p>
    </div>
  </body>
</html>`;

export default page;
