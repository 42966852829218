const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c6 text-center italic" dir="rtl">
      <span class="c9">דבר</span
      ><span class="c0"> 23.5.1934  (עמוד ראשון, ללא שם הכותב)</span>
    </p>
    <p class="c6 text-center" dir="rtl">
      <span class="c5">נחמה פוחצ&#39;יבסקי</span>
    </p>
    <p class="c7" dir="rtl">
      <span class="c3"
        >ביום ב&#39; מתה בראשון לציון נחמה פוחציבסקי, אחת הסופרות הראשונות
        בספרות העברית החדשה של ארץ-ישראל.</span
      >
    </p>
    <p class="c7 c8" dir="rtl">
      <span class="c3"
        >בת 64, הניחה אחריה בנים ובנות, נכדים ונכדות. אל הלויה באו כל קרוביה,
        רבים מידידיה ומהפועלים.</span
      >
    </p>
    <p class="c7 c8" dir="rtl">
      <span class="c3"
        >זה שנים נדם קולה, אך פעם היה ביתה בית ועד לסופרים צעירים. היא עצמה כתבה
        כמה ספורים ונובילות על ההוי במושבה העברית, על התלבטויותיה של האשה
        במציאות החדשה ועל שאלות חברתיות בישוב. מנסיונותיה הספרותיים האלה נישא הד
        של מחאה על ההתנכרות לאח, על טמטום ההשגות הבעל-בתיות ועל האיפינדיות העושה
        שמות בישוב. עד רגעה האחרון לא הפסיקה במלחמתה על עבודה עברית ועל
        עקרונותיה של הציונות. צחקו לה על עקשנותה, המרו לה, והיא לא נרתעה. כאי
        בודד בים העבודה הזרה היה ביתה פתוח לכל משאלות הישוב ונפשה כואבת את כאב
        האומה.</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c3"></span></p>
    <p class="c1" dir="rtl"><span class="c3"></span></p>
    <p class="c1" dir="rtl"><span class="c3"></span></p>
    <p class="c1" dir="rtl"><span class="c3"></span></p>
    <p class="c2" dir="rtl"><span class="c3"></span></p>
  </body>
</html>`;

export default page;
