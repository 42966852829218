const page = `<body class="c3 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c5"
      >אפרת, בתה של נחמה פוחצ&#39;בסקי, בתקופה זו כבת 15, למדה בבי"ס אליאנס
      לבנות ביפו. בידינו מכתבים רבים מאפרת לנחמה אך רק מכתב אחד מנחמה לאפרת,
      האחרים אבדו. אנו מביאים את המכתב הראשון מתקופה זו, מאפרת לנחמה,  את מכתבה
      של נחמה לאפרת ומכתב נוסף מאפרת לנחמה.</span
    >
  </p>
  <br />

  <a href="#EfratToNehama">
    <p class="c2" dir="rtl">
      <span class="c4">חשון תרע"ג מאפרת לנחמה</span>
    </p>
  </a>
  <a href="#nehamaToEfrat">
    <p class="c2" dir="rtl">
      <span class="c4"
        >יום ד&#39; י"ט חשון התרע"ג (30.10.1912) מנחמה לאפרת</span
      >
    </p>
  </a>
  <a href="#yafoYomBet">
    <p class="c2" dir="rtl">
      <span class="c4">יום ב&#39; יפו</span>
    </p>
  </a>
  <br />
  <br />

  <p class="c2" dir="rtl" id="EfratToNehama">
    <span class="c0">חשון תרע"ג</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">אמא יקרה!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >הרי גמרתי לאכל את ארוחת הצהרים, וישבתי לכתב לך את המלים המעטות
      הללו.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >הדרך עברה בשלום גם גשם לא היה השמש הופיעה. ורק פאת מערב היתה מכוסה עננים
      כבדים שהבהילוני ונדמה היה לי שמבול קרב לבא, אבל זה היה רק פחד שוא.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >כשבאתי ליפו מאד השתוממתי בראותי כי ממעל לי שמים תכולים ולולא הבוץ
      שברחובות כלל לא היה נכר שהיה גשם. אני ברדתי מהדיליזנס נגשתי אל הדוד והוא
      שכר לי אַטָל ועמו הלכתי הביתה.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >כעת שוב כסו השמים עבים כבדים אבל מכיון שהנני כבר פה הן אין לי כבר מה
      להצטער אם אפילו יהיה גשם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >חושבת אני עוד מעט ללכת לבית הספר. כבר ראיתי את רחל והיא הבטיחה לי לקרא
      אותי בלכתה לבית הספר. את הקורות אותי אחרי כך בטח אכתב מחר. מתפלאה אני למה
      לא שלחת את המטה. ומאד הנני מצטערת כי המזרן כלו מלוכלך כנראה מהעגלה. יהיה
      צריך לקנות איזה בד בכדי לתפר על הקודם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >לעת עתה ישאר כמו שהוא, ומקוה אני כי תשיגי לי איזה בד. כך הכל בא שלם
      וטוב.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום שלום ונשיקות חמות</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">מבתך אפרת.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום ונשיקות לך עשהאל מאחותך, אוהבתכם</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">בכל לב</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">אפרת</span>
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="nehamaToEfrat">
    <span class="c0">יום ד&#39; י"ט חשון התרע"ג.   </span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">אפרת יקרתי!</span>
  </p>
  <br />
  <p class="c2" dir="rtl">
    <span class="c0"
      >מובן שכל היום לויתיך בשעוני וכשבא מכתבך בערב אני שמחתי עליו מאד ומאד.
      ברוכה תהי בדרכך החדשה!</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >את המטה לא מסרתי מפני שגליקברג מהר ולא רצה לחכות בשום אופן עד שיוציאו את
      המטה. אקוה שיש ספה בחדרך ואחכה כעת עם השליחות עד שיעבר הגשם ואוכל למסר גם
      את הכוננית, זו תפרק מן הגשם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >שכחת בבית את פנקסך ואת הנעלים, גם תנ"ך (להבדיל) שכחת. כל זה עם מטפחת (אם
      תמצא חן בעיניך) ובד למזרן וספרים (אם תשלחי לי רשימת ספרי למוד הנחוצים לך)
      – אשלח בחבילה ביום הראשון. אנה יותר טוב לך – ל"כרמל" או להדוד?</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >על כל מכתב ומכתב תרשמי, חביבתי, מספר, N בלע"ז; הם יקרים לי ויהיו שמורים
      בסדר.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום ונשיקות חמות, חמות.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">זכרי: - בריאות ושקידה בלמודים!</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">אמך אוהבתך נחמה</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום להחביבים אידלננט</span>
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="yafoYomBet">
    <span class="c0">יום ב&#39; יפו</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">אמא יקרה!</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >הזקן מסר לי את הספר ואת מכתבך. זה היה בשעת ההפסקה בבית הספר, כי שפרה
      הביאה את זאת לי ועל כן לא הספקתי לענות לך מיד. בערב בשעה הששית בא אלי הזקן
      לראותי, כי בערב הלכתי אל הדודה. והוא לא היה בבית, ברגע שבא הזקן לא היה
      מוכן המכתב. ורק אחרי לכתו ישבתי לכתב לך. אמא יקרה כותבת את לי במכתבך כי אל
      אתיחס רע אל המורים, וכי צריכה את להגיד לי את זאת ואני האם לא מבינה בעצמי,
      אני מעולם לא התיחסתי רע אל מורים וכי אל הגברת הצרפתית שלנו אני התיחסתי רע
      ועל כן לא הספקתי כלום בצרפתית בשנה העברה? לא אמא חביבה שוגה את אם כה תחשבי
      אני אליה לא התיחסתי רע אלא אחרי אשר ראיתי כי אינה רוצה כלל לחשבנו לתלמידות
      את אלו האחדות שלא ידעו כל כך טוב. וכי בזה אפשר להגיד כי אני לא התיחסתי
      אליה טוב, להפך היא אלי לא התיחסה בטוב. ואם כתבתי לך במכתב על האדון טורוב
      פריץ זה היה בשעת כעס אבל חושבת אני כי לא כתבתי את זאת בישוב הדעת.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">יחסי אל המורים הוא טוב וכמו כן יחסם אלי.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אמא זה שהמורים אמרו כי אקח מורה זה לא כל כך נורא אחרי אשר כבר הייתי שבוע
      במחלקה ראיתי מה היא ומי הן תלמידותיה. ראיה לך כי עד כמה שאני חלשה בצרפתית
      בכל זאת אחרי אשר היא נתנה שיר בצרפתית וכל המחלקה חזרה אותו אמנם, אבל בשעה
      שספרנו אותו בעל פה אמרה הגברת לתלמידות למה אינכם יודעות לספר כמוהן, כמו
      אלו החדשות, זאת אומרת אני ורחל, ובכן אמא אם בשעה הראשונה שהיא שאלה אותי את
      השעור כבר נתנה אותי למשל למרות חולשתי בצרפתית, על כן אין לי מה לפחד עוד
      אשיג את המחלקה לא בפעם אחת אלא לאט לאט. מה שאנו לומדים כעת אני משתוה אתן
      אלא מה? את מה שאני לא עברתי בלמדי בראשון את זאת עלי להשיג.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אמא הקוננית (=כוננית?) נשברה מאד אבל אני חושבת כי אפשר יהיה לתקנה כמה שזה
      יעלה איני יודעת, חבל ששלחת אותה עם חביות היין אבל מה שאבד אבד, אלי-פת-מת
      אומר הערבי.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">אני אבקש את הדוד כי יקנה לי שלחן ומנורה.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c5"
      >תודה לך אמא ששלחת לי את הספר, הילדה שמחה מאד וגם אני שמחתי שיכלתי לעשות
      לה את הטובה הזאת.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אמא: אם יש לך תשלחי לי איזה בשליקים כי עלי לקנות ספר דקדוק. אבל אם אין לך
      אני אשתדל להשאיל כפעם בפעם מאיזו חברה אד אשר תהיי יותר חפשיה בכסף. אני לא
      מפזרת כסף, עדיין אף פרוטה לא הוצאתי לא לממתקים ולא לשטותים כדומה לאלו. אני
      קניתי את הדברים הכי נחוצים לי ותו&#39; לו (=ותו לא) ועוד נשארו לי איזה
      קרוב ל-3 בשליק.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >מטפחת עדין לא קניתי לי מפני שאין לי זמן ללכת העירה רק ביום הששי.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">אמא תועילי לשלח את מכתבי לרחל.</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אל תצטערי אמא חביבה על תוצאות הבחינות כי אני את כל האפשרי והתלוי בי
      אמלא.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >מסרי שלום ונשיקות לאסתר והגידי לה כי מאד הנני מתגעגעת לה ומה רציתי
      לראותה, מצב בריאותה משמחני מאד. ודוד מה עושה? שמח באורחתו?</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >מה נשמע אמא בגנה הזרעתם ירקות חוץ המשתלות. ומה עם המים. הצנורות עדיין
      ריקות, ובגינת הפרחים אין כל חדש? פרחי לא מתו? ומה עם הפרות, הנותנות עדיין
      חלב, הכל הכל כתבי לי אמא על כל שאלותי עניני.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >וכעת נגש אל העקר שבעקרים ומה היא בריאותך את אמא? אל תכחידי ממני הכל, הכל
      כתבי. ומה עם יונדב הלומד הוא, וכי אינו למשא עליך? ועשהאל עובד, עובד הרבה?
      ומדוע אינו רוצה לזכר את אחותו? ולהשקיעה נחת בכתבו לה. שלום לזקנה החביבה,
      שלום להדיה וליעקב החביבים. שלום מסרי לכל מכירי.</span
    >
  </p>
  <br />
  <p class="c2" dir="rtl">
    <span class="c0">שלום ונשיקות לכם חביבי     מאוהבתכם אפרת.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אמא למזרן חסר בד מפני שזה צר. תשלחי לי אַמה אפשר יספיק.</span
    >
  </p>
</body>`;

export default page;
