const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c3" dir="rtl">
      <span class="c0">מתוך </span><span class="c1 bold">ביהודה החדשה</span
      ><span class="c2">.</span><span class="c0">  (הסיפור נכלל גם בספר </span
      ><span class="c1 bold">בוסתן ראשונים – אנטולוגיה של סופרי ראשון לציון</span
      ><span class="c0 c6"
        > בעריכת יוסף אורן, הוצאת עירית ראשון לציון תשמ"ט 1989)</span
      >
    </p>
    <p class="c3 c4" dir="rtl"><span class="c0 c6"></span></p>
  </body>
</html>`;

export default desc;
