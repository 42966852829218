const desc = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c2" dir="rtl">
      <span class="c1">נחמה פוחצ&#39;בסקי  </span
      ><span class="c0 bold">הדאר</span
      ><span class="c1"> יג אלול תרצ"ד  [1934 08 24]</span>
    </p>
  </body>
</html>
`;
export default desc;
