const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <p class="c0 italic" dir="rtl">
      <span class="c6 bold">בוסתנאי </span
      ><span class="c4">י"ד תמוז תרצ"ד  27.6.34 (שנה 6,  גליון י"א)</span>
    </p>
    <br />
    <h3 class="c1 text-center" dir="rtl">
      <span class="c5">זכרונות</span>
    </h3>
    <p class="c1 text-center" dir="rtl">
      <span class="c3"
        >(במלאת  שלשים ימי-אבל אחרי מות נחמה פוחצ&#39;בסקי ז"ל)</span
      >
    </p>
    <p class="c0 c2" dir="rtl"><span class="c3"></span></p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בראשית ימי הישוב היינו פה מוקפים מדבר שממה (מדבר עין הקורא). על כל כברת
        אדמה שבאנו במגע אתה נתקלנו בעקבות חורבן ושממת דורות. בראותנו עכשיו את
        סביבות ראשון-לציון היפות ונטיעותיהן המלבלבות יקשה להאמין, שבכל השטח לא
        נראה כל צומח חוץ מעשבי בר. רק שלש שקמות הוריקו, עתיקות-ימים, ואיש לא ידע
        למה הוצבו כאן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        היה עגום מאד. נמצאנו במדבר הזה בתוך ים של בני-המדבר, "ילידי
        הארץ", שדמו כמעט לפראי בראשית. קשה קשה מאד היה לאיש אירופי לחיות בסביבה
        כזו. ביחוד קשו התנאים על הקומץ הקטן של האינטליגנציה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        בזמן ההוא היו אחינו תמימי-דעים שבגולה (יש לשער שהם עכשיו חברי
        "ברית ראשונים"), חולמים את חלום התחיה בארץ-ישראל, מטפחים את הרעיון
        הקדוש, ובעצמם מוצאים לנכון (מובן, לטובת הרעיון) להשאר בגולה כמפקדים על
        התנועה, להרבות שורות הנספחים לרעיון, אך לא להשתקע בעצמם בא"י.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        אין פלא אם בו בזמן שבחוג משכילי הנוער בא"י עוד אי-אפשר היה
        לספור אף שני מנינים משני המינים, עשתה הופעת נחמה פוחצבסקי רושם חזק מאד.
        אשה צעירה, עליזה, בעלת כשרונות ומלאת חיים, עם נטיה לספרות לירית. היא
        נכנסה מיד בחוג החלוצים הפעילים, ועמה באו ערות ורעננות בעבודה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        אמנם, נתיב-חייה היה מלא קוצים וחרולים, מלבד מה שסבלה יחד עם
        חבריה מפגעי הארץ בימים ההם, אבל נחמה פוחצ&#39;בסקי נשאה הכל בשלוה סטואית
        ובבת-צחוק על פניה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        זכורני: בעלה היה עסוק במשרתו בפקידות הברון, ועלי הוטל לטפל בה
        בקדחה במלריה: לשים תחבושת, להגיש רפואות וכדומה. שרות חולים היתה בימים
        ההם חובה הכרחית. יש שהיה צורך להכנס לבתים שכל דיריהם שכבו חולים על מטתם,
        ויש שהיית נתקל בתמונה כזו: כל בני המושבה במלואה (מובן, שמספר תושביה עוד
        לא הגיע אז לששת אלפים נפש) יוצאים מבית-המרקחת (מקום האמבולטוריה) –
        משפחות שלמות, האיש ואשתו בעינים עצומות מכאב, ותינוק מתיפח במכאוביו על
        זרועות האב או האם, ובכנפי בגדיהם נאחזים יתר הילדים ופניהם טלואים בסמים
        חריפים, נגררים אל עבר בתיהם, תמונות כאלה וכאלה חרטו רשמים בל-ימחו בלב כל
        אחד מאתנו. סבלות פיזיים, תנאים חמריים מרבי-יאוש, ועל כל אלה היסורים
        המוסריים שעברו עלינו. ונחמה פוחצ&#39;בסקי היתה מיד לחברה נאמנה, חלקה
        אתנו חיי צער ועצב – אבל גם שעות של שמחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        בזכרוני נשאר רושם משמחת ההוָי של הימים הראשונים, מלפני תקופת
        טומקין. בתנועה שרר קפאון. הכל סבורים היו שמחוץ לפקידות הנדיב אי אפשרית
        כל קנית אדמה, ושום פעולה התישבותית לא תעלה על הדעת בלעדיה. והנה נמצאו
        מתי-מספר שהעזו להוציא לפועל (כמובן, בקשיים מרובים) קנית אדמת דורן
        (רחובות), לכבוד המאורע הזה נעשה משתה על האדמה הזאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        יום בהיר של אביב. מצד צפונית מערבית של אדמה זו, בסביבה שוממה של
        רוכסי חולות, התנהלה בכבדות חבורה של מלובשי בגדי-חג בילקוטי צידה ומיני
        תרגימא. בהגיעה לגבעת חול נשאה, במקום שנסלל עתה כביש אספלט על יד תחנת
        הנסיונות, נשקפה לה אדמת דורַן הקנויה. כל החבריה פרצה בריצה מעל הגבעה
        לקראת האדמה שעכשיו היא קרקע עברית: הידד! הידד!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >        יחד אתי בשורה – בשמחה ובצחוק ובפנים מאירים גמאה ארץ נחמה
        פוחצ&#39;בסקי. והד צלצולה נשמע במרחבי השטח של אדמת דורן...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">שמואל כהן</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">ראשון לציון</span>
    </p>
  </body>
</html>`;

export default page;
