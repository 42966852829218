const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content italic">
    <p class="c0" dir="rtl">
      <span class="c3">העדויות נמסרו בעל פה או בכתב. להלן מקורן: </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5">- זכרונותיו של </span
      ><span class="c4 bold">יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי</span
      ><span class="c5"
        > (1862 בריסק ד&#39;ליטא - 1947 ראשון לציון), בעלה של נחמה
        פוחצ&#39;בסקי.  הזכרונות נכתבו בין השנים 1926 – 1931 וכתבי היד המקוריים
        נמצאים בידי המשפחה. הזכרונות פורסמו בספר </span
      ><span class="c4 bold">מהתם להכא סיפור חיים של עובד אחד</span
      ><span class="c3"> (הוצאת יד בן צבי, ירושלים, תרע"ג 2013). </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >מיכְל פוחצ&#39;בסקי מכנה את נחמה אשתו "הצעירה שלי" "חברתי"
        וכד&#39;.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5"
        >- שיחות שניהלו אנשים שונים (בעיקר רות ארבל ויוסי ריבלין) בשנות השמונים
        של המאה העשרים עם </span
      ><span class="c4 bold">אפרת בן כהן</span
      ><span class="c3"
        >, בתה של נחמה פוחצ&#39;בסקי (1898 ראשון לציון – 1991 ירושלים).  השיחות
        נרשמו במקום או <span class="modal-button" id="page-modal-button-1">הוקלטו</span> ומאוחר יותר שוכתבו.</span
      >
    </p>
    
    <p class="c0" dir="rtl">
      <span class="c3">אפרת בן כהן מכנה את נחמה פוחצ&#39;בסקי "אמא".  </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5">- זכרונות ילדות של </span
      ><span class="c4 bold">רות ארבל</span
      ><span class="c3"
        > נכדתה של נחמה פוחצ&#39;בסקי (1927 ראשון לציון – 2018 כרמי יוסף)
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">רות ארבל מכנה את נחמה פוחצ&#39;בסקי "סבתא".</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >- עדויות שאספה ורשמה רות ארבל, נכדתה של נחמה פוחצ&#39;בסקי (1927 ראשון
        לציון – 2018 כרמי יוסף), מאנשים שפגשה במהלך השנים והכירו את נחמה
        פוחצ&#39;בסקי בהקשרים שונים.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >- עדויות שהקליטה רחל גיסין בשנת 1983, הקלטת נמצאת בארכיון של מוזאון
        ראשון לציון.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c5"
        >- כמו כן אנו מביאים כאן מספר עדויות המופיעות בספריהם של בני זמנה שהכירו
        אותה.</span
      >
    </p>
  </body>
</html>
`;
export default desc;
