const page = `<body class="c6 doc-content">
  <a href="#fromEfrat">
    <p class="c1" dir="rtl">
      <span class="c4">לקראת סוף סיון תרע"ו</span
      ><span class="c2 italic">  [קיץ 1916 ] מאפרת בת נחמה פוחצ&#39;בסקי</span>
    </p>
  </a>
  <a href="#fromAsael">
    <p class="c1" dir="rtl">
      <span class="c4">כב סיון תרע"ו</span
      ><span class="c2 italic"> [23.6.1916] מעשהאל בן נחמה פוחצ&#39;בסקי</span>
    </p>
  </a>
  <br />

  <p class="c1 italic" dir="rtl" id="fromEfrat">
    <span class="c5"
      >[כפי הנראה המכתב הזה נשלח מתל אביב שם גרו הוריה של עדה כלתה של נחמה
      פוחצ&#39;בסקי. לפי תוכנו הוא נשלח בסוף סיון תרע"ו ,  שכן אז נולדה יונה בת
      עשהאל ועדה פוחצ&#39;בסקי, נכדה ראשונה לנחמה]</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">אמא חביבה!</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >את סבתא בפעם הראשונה ואני "דודה". כן! גם זה קצת נעים. ומה תגידי עלי? אני
      זו שלא אהבתי ילדים כ"כ קטנים הלא יודעת אני. אני זו כֻלי  נצֹדתי ע"י
      הקטנטונת הזו. את אשר שלחת קבלנו לנכון. – תודה לך בשביל זה. את השאר כתבת
      שתביאי בעצמך, בטח תביאי ביום הראשון. בואי נא באי אל כרובנו החדש.
    </span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >כן! זה היה די מגוחך בעיני קודם. כל הענין הזה. נדמה לי שזה שפל יותר מדי
      בשביל לשמוח או להתענין בזה. אבל לא כן כעת. אני רואה ומרגישה שיש לזה אמנם
      ערך בחיים. זה מאשיר את ההורים הצעירים אושר שאין ערוך לו.
    </span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >פנים אל פנים אוכל יותר לפרט לך את רגשותי המחֻדשות. שבת טובה לכם, הסבא
      והסבתא החדשים.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">שלום לכל בני הבית</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">ולחברות,</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">בתך אוהבתך</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">אפרת</span>
  </p>
  <br />
  <br />

  <p class="c1" dir="rtl" id="fromAsael">
    <span class="c0"
      >תל אביב יום ה&#39; כב סיון תרע"ו </span
    >
    <span class="italic">[23.6.1916 , מעשהאל אחיה הגדול של אפרת]</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">לאמא היקרה שלום!</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >זה עתה קבלנו את מכתבך וברכותיך, עפ"י הכתב עצמו אפשר להכיר את רב התרגשותך
      בהרגישך כי סבתא הנך.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >ולפי זה מעיז אני להגיד כי מכרחת את לסלוח לי על אשר לא הודעתיך בעצמי כלום,
      כי עד עתה, הייתי כמו חולם...</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >ומכתבך בא להראות לי, כי אינני חולם גרידא אלא בהקיץ באני האשר אשר אך בחלום
      לשוותו אפשר היה.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >ע"ד השם "יונה" חשבתי גם אני לפני העירך את אזני, אבל פה לא אמרתי כלום, כי
      נתתי את רשות הבחירה לאם המאשרה שכל כך הרבה סבלה בעבור אשרה... ואם היא לא
      תדרוש בתקפה שבתנו תקרא בשם מחדש ומצלצל כאחת, אזי...</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >לעת עתה קבלי את ברכות ההורים המאשרים וברכת הנכדה המתגעגעת כבר
      לראותך.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">ושלום לכלם לכלם.</span>
  </p>
  <br />
  <p class="c1" dir="rtl">
    <span class="c0">שלכם עשהאל ועידה ו...</span>
  </p>
</body>`;

export default page;
