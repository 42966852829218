const appTexts = {
  nehamap: "נחמה פוחצ׳בסקי",
  pages: {
    homePage: "דף הבית",
    AboutHerInWritings: "אודותיה ברשומות",
    herStories: "סיפורים שלה",
    herArticles: "מאמרים וכתבות שלה",
    testimonies: "עדויות",
    aboutHerInThePressOfHerTime: "עליה בעיתונות זמנה",
    letters: "מכתבים",
    fromHereAndThere: "מפה ומשם",
    aboutUs: "אודותינו",
  },
  main: "ראשי",
  back: "חזרה",
  // herStoriesInners: {

  // }
};

export default appTexts;
