const mainDesc = `<body class="c3 doc-content">
  <a href="#withFriendsAndKnowns">
    <p class="c4" dir="rtl">
      <span class="c2 c10">ידידים, מכרים וכד&#39;</span>
    </p>
  </a>
  <a href="#publishingAndDistribution">
    <p class="c4" dir="rtl">
      <span class="c2 c10">בענייני פרסום והפצה של כתביה</span>
    </p>
  </a>
  <a href="#inTheFamily">
    <p class="c4" dir="rtl">
      <span class="c10">בתוך המשפחה</span>
    </p>
  </a>
  <br />

  <h3 class="c9" dir="rtl" id="withFriendsAndKnowns">
    <span class="c2 c12">התכתבות עם ידידים ומכרים </span>
  </h3>

  <a href="/letters/yalag18851889">
    <p class="c4" dir="rtl">
      <span class="c2 c13">יל"ג  1889-1885</span>
    </p>
  </a>
  <a href="/letters/padoba1889">
    <p class="c4" dir="rtl">
      <span class="c13">פדואה  13.10.1889</span>
    </p>
  </a>
  <a href="letters/yosefVitkin19051904">
    <p class="c4" dir="rtl">
      <span class="c7 c13">יוסף ויתקין  1905-1904</span>
    </p>
  </a>
  <a href="/letters/levinEpshtein1912">  
    <p class="c4" dir="rtl">
      <span class="c0">
        לוין אפשטיין 25.6.1912</span>
    </p>
  </a>
  <p class="c14" dir="rtl">
    <span class="c7 c0 modal-button" id="page-modal-button-1"
      >גולדה ופסח יאפו כנראה בזמן הגרוש מתל אביב במלחמת העולם הראשונה
      (1917)</span>
  </p>
  <a href="/letters/fromYizhakEpshtein"> 
    <p class="c14" dir="rtl">
      <span class="c7 c0">יצחק אפשטיין  26.8.1921</span>
    </p>
  </a>  
  <a href="/letters/toGoldaYafo">  
    <p class="c4" dir="rtl">
      <span class="c0 c7">גולדה יאפו  3.12.1923</span>
    </p>
  </a>  
  <p class="c4 modal-button" id="page-modal-button-3" dir="rtl">
    <span class="c2 c0">ביאליק 5.3.1925</span>
  </p>
  <br />

  <h3 class="c9" dir="rtl" id="publishingAndDistribution">
    <span class="c16">בענייני פרסום והפצה של כתביה</span
    ><span class="c1"> </span>
  </h3>
  <p class="c4" dir="rtl">
    <span class="c2 c0 modal-button" id="page-modal-button-4"
      >מעורך כתב עת לילדים הפרחים – 2.1909</span
    >
  </p>
  <p class="c4" dir="rtl">
    <span class="c0 modal-button" id="page-modal-button-5"
      >לדניאל פרסקי 10.1911</span
    >
  </p>
  <p class="c4" dir="rtl">
    <span class="c0 modal-button" id="page-modal-button-6"
      >לאגודת אחיעבר בניו יורק 10.1911</span
    >
  </p>
  <p class="c4" dir="rtl">
    <span class="c0 modal-button" id="page-modal-button-7"
      >לדניאל פרסקי 24.7.1912</span
    >
  </p>
  <a href="/letters/toDanielFreski1914">
    <p class="c4" dir="rtl">
      <span class="c11 c0"
        >לדניאל פרסקי 8.2.1914</span
      >
    </p>
  </a>
  <a href="/letters/toGoldBerg">
    <p class="c4" dir="rtl">
      <span class="c2 c0"
        >לי"ל גולדברג  14.9.1922</span
      >
    </p>
  </a>
  <br />

  <h3 class="c9" dir="rtl" id="inTheFamily">
    <span class="c2 c12">בתוך המשפחה</span>
  </h3>
</body>`;

export default mainDesc;
