const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c0" dir="rtl">
      <span class="c4 bold">המליץ</span
      ><span class="c5">  ט אב תרנ"א  [1891 08 13] </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בסיום המאמר יש ציטוט ממכתב שלה על היחס לאופנה ולמותרות</span
      >
    </p>
  </body>
</html>`;

export default desc;
