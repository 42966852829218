import reviewOfTheNewJudea_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/reviewOfTheNewJudea_desc";
import rahelKentzelson_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/rahelKentzelson_desc";
import unknownWriterA_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/unknownWriterA_desc";
import yudAvizohar_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/yudAvizohar_desc";
import betHaim_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/betHaim_desc";
import mosheKlainman_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/mosheKlainman_desc";
import yaakovRabinovich_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/yaakovRabinovich_desc";
import akivaBenEzra_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/akivaBenEzra_desc";
import elKushi_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/elKushi_desc";
import elshech_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/elshech_desc";
import davidBadar_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/davidBadar_desc";
import unknownWriterB_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/unknownWriterB_desc";
import shinYudTzvieli_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/shinYudTzvieli_desc";
import cahansky_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/cahansky_desc";
import rahelFigenberg_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/rahelFigenberg_desc";
import batSheva_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/batSheva_desc";
import shmuelCohenBustanai_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/shmuelCohenBustanai_desc";
import unknownWriterC_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/unknownWriterC_desc";
import smilansky_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/smilansky_desc";
import onlyANote_desc from "../pages/aboutHerInThePressOfHerTime/descriptions/onlyANote_desc";

import {
  aboutHerInThePressOfHerTime_MainDesc,
  aboutHerEngagement_modal,
  aboutHerMarriage_modal,
  onHerAlia_modal,
  hatzvi2541890_modal,
  hatzvi431909_modal,
  hashkafa431908_modal,
  hapoelHatzair2351913_modal,
  doarHaiom21121920_modal,
  davar1831926_modal,
  davar2771930_modal,
  davar1181932_modal,
  doarHaiom2731935_modal,
  davar811937_modal,
  reviewOfTheNewJudea,
  reviewOfTheNewJudea_preTitle,
  yudAvizohar,
  betHaim,
  mosheKlainman,
  rahelKentzelson,
  rahelKentzelson_preTitle,
  yaakovRabinovich,
  akivaBenEzra,
  elKushi,
  unknownWriterA,
  unknownWriterA_preTitle,
  davidBadar,
  unknownWriterB,
  shinYudTzvieli,
  cahansky,
  rahelFigenberg,
  batSheva,
  shmuelCohenBustanai,
  unknownWriterC,
  smilansky,
  onlyANote,
} from "../pages/aboutHerInThePressOfHerTime";

const texts = {
  pageUrl: "/aboutHerInThePressOfHerTime",
  mainDesc: aboutHerInThePressOfHerTime_MainDesc,
  mainDescModalsContent: [
    {
      doc: aboutHerEngagement_modal,
      isImage: false,
    },
    {
      doc: aboutHerMarriage_modal,
      isImage: false,
    },
    {
      doc: onHerAlia_modal,
      isImage: false,
    },
    {
      doc: hatzvi2541890_modal,
      isImage: false,
    },
    {
      doc: hashkafa431908_modal,
      isImage: false,
    },
    {
      doc: hatzvi431909_modal,
      isImage: false,
    },
    {
      doc: hapoelHatzair2351913_modal,
      isImage: false,
    },
    {
      doc: doarHaiom21121920_modal,
      isImage: false,
    },
    {
      doc: davar1831926_modal,
      isImage: false,
    },
    {
      doc: davar2771930_modal,
      isImage: false,
    },
    {
      doc: davar1181932_modal,
      isImage: false,
    },
    {
      doc: doarHaiom2731935_modal,
      isImage: false,
    },
    {
      doc: davar811937_modal,
      isImage: false,
    },
  ],

  items: [
    {
      name: "reviewOfTheNewJudea",
      title: "יעקב זרובבל (ל)",
      preTitle: reviewOfTheNewJudea_preTitle,
      description: reviewOfTheNewJudea_desc,
      doc: reviewOfTheNewJudea,
    },
    {
      name: "yudAvizohar",
      title: "י. אביזהר",
      description: yudAvizohar_desc,
      doc: yudAvizohar,
    },
    {
      name: "betHaim",
      title: "ב' חיים",
      description: betHaim_desc,
      doc: betHaim,
    },
    {
      name: "mosheKlainman",
      title: "משה קליינמן",
      description: mosheKlainman_desc,
      doc: mosheKlainman,
    },
    ///
    {
      name: "rahelKentzelson",
      title: "ר.כ. רחל קצנלסון שזר",
      preTitle: rahelKentzelson_preTitle,
      description: rahelKentzelson_desc,
      doc: rahelKentzelson,
    },
    {
      name: "yaakovRabinovich",
      title: "יעקב רבינוביץ",
      description: yaakovRabinovich_desc,
      doc: yaakovRabinovich,
    },
    {
      name: "akivaBenEzra",
      title: "עקיבא בן-עזרא",
      description: akivaBenEzra_desc,
      doc: akivaBenEzra,
    },
    {
      name: "elKushi",
      title: "אלקושי",
      description: elKushi_desc,
      doc: elKushi,
    },
    ///
    {
      name: "unknownWriterA",
      title: "כותב לא ידוע",
      preTitle: unknownWriterA_preTitle,
      description: unknownWriterA_desc,
      doc: unknownWriterA,
    },
    {
      name: "elshechIsraelCohen",
      title: "אלשיך ישראל כהן",
      description: elshech_desc,
      doc: null,
    },
    {
      name: "davidBadar",
      title: "דוד בדר",
      description: davidBadar_desc,
      doc: davidBadar,
    },
    {
      name: "unknownWriterB",
      title: "כותב לא ידוע",
      description: unknownWriterB_desc,
      doc: unknownWriterB,
    },
    {
      name: "onlyANote",
      title: "כותב לא ידוע",
      description: onlyANote_desc,
      doc: onlyANote,
    },
    {
      name: "shinYudTzvieli",
      title: "ש. י. צביאלי",
      description: shinYudTzvieli_desc,
      doc: shinYudTzvieli,
    },
    {
      name: "cahansky",
      title: "ע. כהנסקי",
      description: cahansky_desc,
      doc: cahansky,
    },
    {
      name: "rahelFigenberg",
      title: "רחל פיגנברג",
      description: rahelFigenberg_desc,
      doc: rahelFigenberg,
    },
    {
      name: "batSheva",
      title: "בת שבע",
      description: batSheva_desc,
      doc: batSheva,
    },
    {
      name: "shmuelCohenBustanai",
      title: "שמואל כהן",
      description: shmuelCohenBustanai_desc,
      doc: shmuelCohenBustanai,
    },
    {
      name: "unknownWriterC",
      title: "כותב לא ידוע",
      description: unknownWriterC_desc,
      doc: unknownWriterC,
    },
    {
      name: "smilansky",
      title: "משה סמילנסקי",
      description: smilansky_desc,
      doc: smilansky,
    },
  ],
};

export default texts;
