const page = `<body class="c7 doc-content">
  <p class="c4 italic" dir="rtl">
    <span class="c6"
      >המלים הבאות נכתבו על גבי נייר משבצות שנתלש ממחברת. הספר עליו מדובר הוא מן
      הסתם </span
    ><span class="c3 bold">ביהודה החדשה – קבץ ציורים</span
    ><span class="c5"
      > שיצא לאור כשנתיים לפני כתיבת מכתב זה, ביפו. [המכתב עצמו נמצא בארכיון
      אגודת הסופרים בבית אריאלה]</span
    >
  </p>
  <p class="c2" dir="rtl"><span class="c0"></span></p>
  <p class="c4" dir="rtl">
    <span class="c0">ראשון לציון י"ב שבט התרע"ד</span>
  </p>
  <p class="c4" dir="rtl">
    <span class="c0">        לכבוד מר דניאל פרסקי</span>
  </p>
  <p class="c4" dir="rtl">
    <span class="c0">                                ניויורק.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0">א.נ.</span></p>
  <p class="c1" dir="rtl">
    <span>אתמול קבלתי ע"י הפועל הצעיר את המכתב ואת המחאה</span
    ><span class="c6 italic">[?]</span
    ><span class="c0"
      > כלום לא חשדתי בו ולחנם כל ההתנצלות הגדולה הזאת. חששתי פשוט שהספרים לא
      נמכרו וחסל.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span>לאדון בניסן</span><span class="c6 italic">[?]</span
    ><span
      > אני אמסר הערב את דבריו. הוא יושב כעת בדד מסכן, - המשפחה כלה במצרים. ישמח
      הזקן</span
    ><span class="c6 italic">[?]</span
    ><span class="c0"
      >-הצעיר שלנו לדבריו החמים. מה שנוגע לבנותיו הן מאֻשרות מאד – אלילות של
      בעליהן! לבולה כבר יש בן ובת, שני כרובים נפלאים. רֻחמה היא זולת דודה – אם
      ממש לילדי בולה. – משפחה נפלאה!</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >אדוני שואל-מדוע חדלתי לכתב? אמנם בשנה זו, הייתי אכרה מסודרת, בכל זאת
      פרסמתי אי-אלו דברים בעתוני ארצנו בהסתר שמי.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >מה שנוגע לספרי הנשארים בכרמל הניורקי, יש לי אליו, אדוני, הצעה שכזו: -
      רוצה אני לנדבם לקופת חזוק החנוך העברי. אם אדוני יתרצה להשתדל למכרם, אז הלא
      אפשר יהא להכניס איזה סכום לטובת תנועתנו. יוכל למכר אותם יותר בזול, אם
      נחוץ, בלבד שיפדו אותם והכסף ילך לתעֻדתו, לקופת החנוך, הזקוקה כל כך
      להתאמצות כל כחותינו. אקוה שאדוני יתיחס להצעתי ברצינות הדרושה.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0"
      >ע"ד קבלת ההמחאה של אתמול אכתב לאדון לוין-אפשטין. גם אבקשהו, שאם אדוני
      ידרש את ספרַי, ימסרם נא בלי כל עכוב.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">        בשלום רב וברכת ציון</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c0">                                נ. פוחצבסקי.</span>
  </p>
</body>`;

export default page;
