const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c1 italic" dir="rtl">
      <span>ההספד פורסם בעיתון <span class="bold">בוסתנאי </span>י"ד תמוז תרצ"ד  27.6.34.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        יום בהיר של אביב. מצד צפונית מערבית של אדמה זו, בסביבה שוממה של
        רוכסי חולות, התנהלה בכבדות חבורה של מלובשי בגדי-חג בילקוטי צידה ומיני
        תרגימא. בהגיעה לגבעת חול נשאה, במקום שנסלל עתה כביש אספלט על יד תחנת
        הנסיונות, נשקפה לה אדמת דורַן הקנויה. כל החבריה פרצה בריצה מעל הגבעה
        לקראת האדמה שעכשיו היא קרקע עברית: הידד! הידד!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        >        יחד אתי בשורה – בשמחה ובצחוק ובפנים מאירים גמאה ארץ נחמה
        פוחצ&#39;בסקי. והד צלצולה נשמע במרחבי השטח של אדמת דורן...</span
      >
    </p>
  </body>
</html>
`;
export default modal;
