const modal = `<body class='c5 doc-content'>
  <p class='c1 italic' dir='rtl'>
    <span class='c6'>
      המלים הבאות נכתבו על גבי גלויה שנמענה לדניאל פרסקי בניו יורק. הספר עליו
      מדובר הוא מן הסתם
    </span>
    <span class='c3 bold'>ביהודה החדשה – קבץ ציורים</span>
    <span class='c0'>
       שיצא לאור באותו זמן ביפו. [הגלויה עצמה נמצאת בארכיון אגודת הסופרים בבית
      אריאלה]
    </span>
  </p>
  <p class='c1 c2' dir='rtl'>
    <span class='c0'></span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>ראשון לציון חוהמ"ס תרע"ב.</span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>        אדוני!</span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>
              שלחתי לו היום את ספרי – קבץ ציורים שיצא עתה מן הדפוס.
    </span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>
              אם אדוני רוצה לקחת עליו את מכירת הספר במדינתו, יועיל נא להודיעני
      את סכום החוברות שהוא מזמין.
    </span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>                                        בברכת ציון</span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>
                                                      נ. פוחצבסקי.
    </span>
  </p>
  <p class='c1 c2' dir='rtl'>
    <span class='c4'></span>
  </p>
</body>`;

export default modal;
