const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c1 italic" dir="rtl">
      <span class="c0 bold">הצבי</span
      ><span class="c2"
        >   ה באייר תר"ן  25.4.1890  (גליון י&#39;) -  בעמוד השלישי של העיתון
        מופיע הקטע הבא:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c4 bold">ירושלם</span
      ><span class="c3"
        >. בימי חג הפסח לא זכינו כמעט כלל לעולי-רגל מבני ישראל. ממתי המעט שבאו
        נזכיר: הרב החכם הר"ו יעבץ, רב במושבת זכרון יעקב, האדון קרלינסקי מרוסיה,
        שבא לא"י לקנות לו אחוזת נחלה, </span
      ><span class="c3">ושָיָרה קטנה מראשון לציון</span
      ><span class="c3"
        >: האדון דוד יודילביץ וארוסתו, ה&#39; פוחאצ&#39;בסקי ורעיתו, ה&#39;
        ליובמן ורעיתו. כל אלה מדברים עברית איש ואשתו, ובפרט הגברת&#39;
        פוחאצ&#39;בסקי היודעת היטב לשון עברית.
      </span>
    </p>
  </body>
</html>`;

export default modal;
