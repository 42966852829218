import hereAndThere_mainDesc from "../pages/fromHereAndThere/fromHereAndThere_mainDesc";
import womanChooseProtocole from "../pages/fromHereAndThere/womanChooseProtocole";

import chooseWoman01 from "../images/fromHereAndThere/chooseWoman01.jpg";
import chooseWoman02 from "../images/fromHereAndThere/chooseWoman02.jpg";
import chooseWoman03 from "../images/fromHereAndThere/chooseWoman03.jpg";
import chooseWoman04 from "../images/fromHereAndThere/chooseWoman04.jpg";
import chooseWoman05 from "../images/fromHereAndThere/chooseWoman05.jpg";
import hashalomCourtNote from "../images/fromHereAndThere/hashalomCourtNote.jpeg";
import electionNote from "../images/fromHereAndThere/electionNote.jpg";
import nehamaAndMichelWill from "../images/fromHereAndThere/nehamaAndMichelWill.jpg";
import pickingAlmondsReceipt from "../images/fromHereAndThere/pickingAlmondsReceipt.jpg";

const texts = {
  pageUrl: "/hereAndThere",
  mainDesc: hereAndThere_mainDesc,
  mainDescModalsContent: [
    {
      isImage: true,
      images: [
        chooseWoman01,
        chooseWoman02,
        chooseWoman03,
        chooseWoman04,
        chooseWoman05,
      ],
    },
    {
      isImage: true,
      images: [hashalomCourtNote],
    },
    {
      isImage: true,
      images: [electionNote],
    },
    {
      isImage: true,
      images: [nehamaAndMichelWill],
    },
    {
      isImage: true,
      images: [pickingAlmondsReceipt],
    },
  ],
  items: [
    {
      name: "womanChooseProtocole",
      title:
        "[קטע זה משוכתב ממסמך הנמצא בארכיון ראשון לציון. אנו מביאים גם צילום של המסמך המקורי]",
      doc: womanChooseProtocole,
      hidden: true,
    },
  ],
};

export default texts;
