const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c5 italic" dir="rtl">
      <span class="c3 c4 bold">הצבי</span
      ><span class="c2 c3">  4.3.1909 (וגם 19.3.1909) - תחת הכותרת </span
      ><span class="c4 bold">לקוראינו</span><span class="c2"> </span
      ><span class="c2 c3"
        >פורסם מאמר לכבוד חצי שנה לעיתון. ברשימת הסופרים הכותבים לעיתון מופיעה
        גם נחמה פוחצ&#39;בסקי.</span
      >
    </p>
  </body>
</html>`;

export default modal;
