const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c1" dir="rtl">
      <span class="c0 bold">דבר  11.8.1932</span><span class="c3"> </span
      ><span class="c2"> –  תחת הכותרת </span
      ><span class="c3">חמישים שנות ראשון לציון (ממסיבת העתונאים)</span
      ><span class="c2"
        > מתואר מפגש עם "מיסדי וּוַתיקי" המושבה שסיפרו במשך ארבע שעות על הישגי
        המושבה ראשון לציון. בין הנואמים מוזכרת גם</span
      ><span class="c7"> </span>
      <a href="/herArticles/theWomanOnBuildingRishon"
        ><span class="c4">נ. פוחצ&#39;בסקי</span></a
      >
      <span class="c2">.</span>
    </p>
  </body>
</html>`;

export default modal;
