const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c4" dir="rtl">
      <span class="c1">פורסם בעיתון </span><span class="c2 c3 bold">התקופה</span
      ><span class="c1"> בשנת תרפ"ג (ינואר 1923, עמ 99-96)</span
      ><span class="c2">. </span
      ><span class="c1"
        >הסיפור ממוקם בראשון לציון ערב כיבושה ע"י הצבא האנגלי במלחמת העולם
        הראשונה. אותה תקופה ואותו מקום מתוארים </span>
      <span class="c1 c5 tooltip"> בזכרונותיו של בעלה של הכותבת <span> </span>
        <span class="c7 tooltiptext">
        <span class="bold">מהתם להכא</span>, יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי, יד בן-צבי 2013  עמ&#39;
        280 - 288
        </span>
      </span>
    </p>
  </body>
</html>`;

export default desc;
