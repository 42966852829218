const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c1 italic" dir="rtl">
      <span class="c5 bold">הדאר</span
      ><span class="c6"> יג אלול תרצד 24.8.1934  (</span
      ><span class="c7">שנה 13 גל&#39; ל"ו, עמ 673-4</span
      ><span class="c6 c10">)</span>
    </p>
    <h3 class="c13 text-center" dir="rtl" id="h.gii5wlvss8x0">
      <span class="c3">סופרים וספרים</span>
    </h3>
    
    <p class="c9 text-center" dir="rtl">
      <span class="c10 c12">נחמה פוחצ&#39;בסקי</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        לפני עשרים וחמש שנים התפללה סופרת צעירה אחת בשם "אם כל חי" (ד"ר
        חוה שפירא)  לאמר: "תקותי היותר עזה היא שתתעוררנה רבות מבנות מיני ללכת
        בעקבותי" (אם כל חי, קובץ ציורים, עמוד 4, ווארשה, תרס"ט). תפילת הסופרת
        הצעירה נתקבלה. עם הדפסת ספר צנום זה, בן ס"ג דפים, מתחילה תקופה חדשה
        בספרותנו היפה, שהיתה רק גברית, וחסר היה בה יסוד ההסתכלות הנשיית, האמהית.
        אמת, פה ושם בעתונות היומית והתקופתית קראנו שיר, ספור או תרגום בודד משל
        סופרת עברית, אבל לידי הבעה שלמה בספר, הכונס את דברי רוחה – לזה כמעט שלא
        הגיעה ספרותנו החדשה (סמי מכאן שירי רחל מורפורגו וספורי שרה פונר).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        והנה כשנתים אחרי הופעת הקובץ הנזכר הופיע קובץ ציורים משל סופרת
        אחרת, היא נחמה פוחצ&#39;בסקי בארץ-ישראל (ביהודה החדשה, קובץ ציורים,
        תרע"א, יפו).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        פני המספרת הזאת לא היו פנים חדשות לגמרי. בעודנה בת ט"ז כבר
        התעניינה בספרותנו והחליפה מכתבים עם יל"ג (אגרות יל"ג, חלק ב&#39;, אגרת
        ש"י). אחר כך היתה מפרסמת את דבריה ב"המליץ" בחתימת "נפש", כלומר נחמה
        פיינשטיין (שם הלוואי של אביה).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        בקובץ הזה נשמעה לנו נימה חדשה שלא נוגנה עד כה. אנו מאזינים
        צלילי קול יהודה החדשה המתעוררת לתחיה, לבטי האשה החלוצה הבונה את ארצנו
        יחד עם הגברים ונוטלת את חלקה, חלק היסורים, שווה בשווה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        נחמה פוחצ&#39;בסקי חודרת לתוך הנשמה של האשה הסובלת. יודעת היא
        את נפשה ורחשי אהבתה לילדיה וגם את רגשותיה החמים לארצה, ארץ-ישראל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        בכמה תוגה חודרת היא מתארת את האשה שנתאלמנה מבעלה ועוד מעט תהיה
        שכולה מבנה (האבדות, ביהודה החדשה). רק אשה היודעת את מכאובי בנות מינה
        יכולה למסור חוויות כאלה. והנה קול המתאכר אשר עקר את עצמו מארץ מולדתו
        הגשמית ובא למולדתו הרוחנית, אשר אידיאלו הוא לנטוע פרדס אתרוגים, "פרי זה,
        תשמיש לקדושה, כשיבוא מארץ-ישראל עבוד בידי בני ישראל רווי בזיעתם" (תחת
        האתרוג, ביהודה החדשה).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        העבודה העברית אינה נותנת לה מנוחה, ומה שהיא חושבת על-דבר
        הפרובלימה הזאת היא מביעה ב"בדידות" (בכפר ובעבודה, ספורים, תר"ץ, הוצאת
        הדים, תל-אביב). היומן הזה הוא דוקומנט אנושי של נפש הנמוגה בצערה החרישי,
        שאינה יכולה להבין את ההבדל בין "אנחנו", "בעלי קרקעות", ו"הם", הפועלים
        העברים. מתוך ספורי פוחצ&#39;בסקי אנו שומעים קול אשה אחרת, האשה אשר בנין
        הארץ היא תכליתה בחיים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        אבל יש והאשה שבה מתגברת על חובותיה הלאומיים (החרטה, ביהודה
        החדשה), והמלחמה הזאת, מלחמת הרגש וההגיון, מתבטאת ביומן "בדידות" (בכפר
        ובעבודה). ביומן הזה מפרפרת נפש אשה שמסרה חייה לעבודה, לבנין הארץ, ולה
        לעצמה, לרחשי נפשה היא, לא שמה לב. והנה לעת בלותה מתעורר בקרבה הרגש הטבעי
        של האהבה וכשאינה מוצאת את תיקונה היא מחליטה להתמסר כולה לעבודת
        העזרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        על כל ספוריה מרחפת תוגה חרישית, תוגה של אם מסכנה המקבלת את
        יסוריה באהבה. ומכל ספור וספור מתנוסס הנוף הארץ-ישראלי המתעורר משממתו
        בידי עברים, וריח הפרדס והשדה עולה מכל ציור.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        פוחצ&#39;בסקי היתה, כמדומני, הראשונה שהאירה בספרותנו פנה חשכה
        שעד כה היתה מכוסה בערפל, זוהי פנת חיי היהודים התימנים. בציורי התימנים
        שלה אנו רואים את השבט הזה שסבל בארץ גלותו שפלות ועבדות ועכשיו כשהוא
        משתחרר מכבליו הוא נמצא עדיין בבחינת חציו עבד וחציו בן-חורין (עיין פעמים,
        גוי, גלות ועוד, ביהודה החדשה).</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >        נחמה פוחצ&#39;בסקי היתה לא רק נאה דורשת, אלא גם נאה מקיימת
        ונעשתה מופת לאחרים. היא הגשימה את האידיאלים של גבוריה וגבורותיה על-ידי
        עבודה בבית, בגן, ברפת, בלול ובעזרה סוציאלית. והיא משתתפת גם השתתפות
        אקטיבית בכל ענפי החיים הארץ-ישראליים. שחרור עם ישראל מגלותו, שחרור הפועל
        העברי ושחרור האשה העבריה – בהם כל מעיניה. ביחד עם שרה עזריהו, שרה טהון
        ועדה פישמאן היא נלחמת לשויון זכויות האשה בארץ-ישראל. והמלחמה היא כבדה
        מאד. מצד אחד המשכילים בארץ, ומן הצד השני הדתיים האדוקים. פוחצ&#39;בסקי
        וחברותיה לא נרתעו לאחור והן המשיכו את מלחמתן לטובת האשה – והנצחון בא.
        עכשיו נהנות הנשים העבריות בארץ-ישראל מפרי נצחונן, ושויון זכויותיהן נעשה
        להן כדבר רגיל ולא מקרוב בא אליהן. אבל לפני ארבע-עשרה שנה עמדה השאלה הזאת
        ברומו של עולם האשה המודרנית בארץ ולא בנקל עלה לה הנצחון הזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">        יהי זכרה ברוך!</span>
    </p>
  </body>
</html>`;

export default page;
