const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c4" dir="rtl">
      <span class="c5 bold">המליץ</span
      ><span class="c1"
        > כ&#39; בניסן
        תר"ן  [1890 04 10] </span
      >
      </p>
      <p class="c4" dir="rtl">
        <span class="c1 c3"
          >על חגיגת
          ייסוד
          רחובות</span
        >
      </p>
  </body>
</html>`;

export default desc;
