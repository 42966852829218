const modal = `<body class='c5 doc-content'>
  <p class='c1 italic' dir='rtl'>
    <span class='c0 c6'>
      המכתב נמצא בספר אגרות חיים נחמן ביאליק בעריכת פ. לחובר, הוצאת דביר ת"א
      תרצ"ח כרך 3, עמ&#39; כ"ד, כ"ה
    </span>
  </p>
  <br />

  <p class='c1' dir='rtl'>
    <span class='c4'>תכט</span>
  </p>
  <p class='c7 text-end' dir='rtl'>
    <span>תל אביב, י"ט אדר תרפ"ה </span>
    <span class='c0 italic'>[15.3.1925]</span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>לכב&#39; הגב&#39; נחמה פוחצבסקי, ראשון לציון.</span>
  </p>
  <p class='c1' dir='rtl'>
    <span class='c4'>גב&#39;. נ.</span>
  </p>
  <p class='c1' dir='rtl'>
    <span>
      גם לי צר מאד שלא נזדמן לי לראותך גם הפעם, אחרי ראותי אותך לפני 15 שנה
      בארץ. זכרתי את מקל השקדים אשר עקרת מגנך בשבילי, כצפורה בשעתה, ואף אם לא של
      סנפיריון היה
    </span>
    <span class='c0 italic'>[כמטה משה לפי המדרש]</span>
    <span>
      , בכל זאת בקעתי בו את הים ועשיתי בו נסים ונפלאות על גביהם של כמה "מצרים",
      ומקוה אני כי בקרוב אזדמן עוד הפעם לפונדקכם בקרית ענבים ושמחנו יחד על כוס
      יין.
    </span>
    <span class='c0 italic'>[כנראה הכוונה לראשון לציון עם כרמי הענבים והיקב שבה]</span>
  </p>
  <p class='c2' dir='rtl'>
    <span class='c4'>בברכת שלום </span>
  </p>
  <p class='c1 c3' dir='rtl'>
    <span class='c4'></span>
  </p>
</body>`;

export default modal;
