const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c3" dir="rtl">
      <span class="c0 c2">מתוך</span><span class="c1"> </span
      ><span class="c4 bold">ביהודה החדשה</span
      ><span class="c0 c2">. (הסיפור נכלל גם בספר </span
      ><span class="c0">&#39;סיפורי נשים בנות העליה הראשונה&#39;</span
      ><span class="c0 c2"
        >, בחרה והתקינה יפה ברלוביץ, הוצאת תרמיל, משרד הבטחון, 1984)</span
      >
    </p>
  </body>
</html>`;

export default desc;
