const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c2 italic" dir="rtl">
      <span class="c6 c8 bold">המגיד</span><span class="c1">  24.10.1889 </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >   העלמה המשכלת הכבודה נחמה לבית פיינשטיין נִשאה לכבוד משנה הגנן ברל"צ
        האדון מיכל פוחצאסקי בבריסק, ויבאו כעת הנה להשתקע ברל"צ. הגברת נחמה
        פוחצאסקי היודעת ומבינה היטב את שפתינו הק&#39; ואשר כבר נסתה את ידה פעמים
        רבות למשוך בעט סופרים, ידע תדע את חובתה לאמנו הזקנה, העלובה מבנותיה, לעת
        כזאת אשר תחית שפת עבר עומדת בראשית ימי התפתחותה, ובין הידים העוסקות
        ועושות בשקידה את עבודתן במקדש השפה תקח חבל גם היא.</span
      >
    </p>
    <p class="c2 small-text" dir="rtl">
      <span class="c4">[</span
      ><span class="c5 c6">בהמשך ישיר לאותה הודעה נכתב: </span
      ><span class="c5"
        > - מבחר בני המושבות גמרו אמר להקים גם שם ושארית לשמות אבות אבותינו אשר
        נשכחו בימי הגלות הארוך וזכרם לא יבוא בקהל ולשרש את השמות הלועזים הנשחתים
        והנפתלים. וכבר החלו לקרא לבניהם יוצאי חלציהם בשמות עתיקים. אזנינו שומעות
        עתה: אמנון, אביחיל, אלישיב, יואב, נעמן וכאלה לזכרים; ימימה, עטרה, פנינה
        שושנה, שולמית וכאלה לבנות. בשבוע העבר שמחנו לקראת השם ברורה אשר קרא
        האדון אברהם זאב מיל&#39;צ לבתו. צלצול השמות העתיקים האלה נעים לאזן השומע
        מצלצול שמות טוני, מוני, בוני, שוני, גיטול,אייטול,וויטול טרייטול.</span
      ><span class="c4">]</span>
    </p>
  </body>
</html>`;

export default modal;
