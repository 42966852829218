const page = `<html>  
<div class="doc-content">    
      <p class="c7 p-bullet-0" dir="rtl">
        <a href="#curriculumVitae">
          <span class="c13">
            קורות חייה כפי שכתבם בן זוגה יחיאל מיכל שלמה זלמן פוחצ׳בסקי
          </span>
        </a>
      </p>
      <p class="c7 p-bullet-0" dir="rtl">
        <a href="#describedBy">
          <span class="c13">
            דמותה מתוארת על ידי נכדה אלישיב פוחצ׳בסקי
          </span>
        </a>
      </p>
      <p class="c7 p-bullet-0" dir="rtl">
        <a href='#aboutHerOnTheWeb'>
          <span class="c13"> 
            דמותה וקורות חייה במרשתת
          </span>
        </a>
      </p>
    
    <br/>
    
    
      <h3 class="c7 li-bullet-0" dir="rtl" id="curriculumVitae">        
        <span class="c15"
          >קורות חייה
          כפי שכתבם
          בן זוגה
          יחיאל מיכל
          שלמה זלמן
          פוחצ&#39;בסקי</span
        >
      </h3>
    
    <p class="c0 c12" dir="rtl" style="font-style: italic;">
      <span class="c6"
        >בארכיון
        הציוני
        נמצאים
        עמודים
        אחדים בכתב
        ידו של יחיאל
        מיכל שלמה
        זלמן
        פוחצ&#39;בסקי
        המספרים על
        אשתו המנוחה
        נחמה
        פוחצ&#39;בסקי.
        יתכן
        והדברים
        נכתבו בשביל
        ספר</span
      ><span class="c21 c25"
      style="font-weight: bold;"> ראשון
        לציון
        התרמ"ב-התש"א</span
      ><span class="c6"
        > (בעריכת דוד
        יודלביץ ,
        ראשון לציון,
        1941-1938) כיוון
        שבעמוד 523
        בספר הנ"ל
        מופיע מאמר
        שנראה
        כמבוסס על
        חיבור זה.
      </span>
    </p>
    <p class="c0" dir="rtl" style="font-style: italic;">
      <span class="c6"
        >זוהי הדפסה
        מכתב היד:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c23"
        >רשומות חייה
        של הסופרת
        נחמה
        פוחצ&#39;בסקי
        (פיינשטין).  </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >נולדה ביום
        ראשון של פסח
        שנת תר"ל
        למשפחת
        פיינשטין
        המסונפת
        בבריסק
        דליטא. גדלה
        על ברכי
        הורים
        אדוקים
        וחסידים,
        בתור בת
        יחידה
        מפונקה.
      </span>
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >בהמלא לה
        תשע שנים
        לחייה, הגרו
        אז הוריה
        לרגלי עסקם
        למזרח רוסיה
        על נהר
        הוולגה לעיר
        צריצין
        המאוכלסת אך
        ורק רוסים
        טהורים, שם
        נכנסה
        לגימנסיון
        לבנות בלי
        שתבין אף מלה
        רוסית. אחרי
        עבור חצי שנה
        ללמודיה
        היתה כבר
        התלמידה
        הראשונה
        במחלקתה.
        המפקחת
        הראשית
        הציגה אותה,
        היהודיה
        הקטנה,
        לדוגמא
        בחריצותה
        ובהבנתה
        ושקידתה
        לסמל לכל
        בנות הגוים.
        עד כדי כך
        הגיע, שבנות
        מרום הארץ
        חשבו להן
        לכבוד
        ולאושר
        להיות נמנות
        בין
        ידידותיה
        ולסדר את
        שעוריהן יחד
        עם היהודיה
        הצנומה
        הקטנה
        והצעירה
        הרבה מהן.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >בגמרה ארבע
        מחלקות
        גימנסיון
        בהצטינות
        יוצאת מהכלל,
        וקבלה פרס
        ראשון
        ותעודות
        שנמצאות תחת
        ידי לזכרון,
        נהיתה לשיחה
        בעיר הגוית.
        ומזה דוקא
        נרתע אביה
        היהודי
        הלאומי ופחד
        תקפתו לגורל
        בתו היחידה
        שלא תפול שלל
        ח"ו לעם זר,
        ופסק דינה
        לעזוב את
        למודיה וחסל
        חינוך גויי,
        ולא הועילו
        דברי הבת
        שנפשה חשקה
        בתורה.
      </span>
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >מאז מסר את
        חינוכה ביד
        מורה עברי
        מנוסה
        שהקומץ הקטן
        של יהודי
        צריצין
        הביאוהו
        מעיר קוסבֶה
        שבליטא
        להיות המזין
        הרוחני של
        ילדיהם. ומאז
        התמסרה בלב
        ונפש חפצה
        לשפה העברית
        וללמודי
        היהודים
        המסורתיים
        והקשר בינה
        ובין עמה הלך
        והתחזק
        והתחילה
        להיות גאה
        בזה שהיא בת
        ישראל. ולא
        עבר זמן רב
        התחילה
        שולטת בשפה
        העברית
        ולכתוב בה,
        ולהביע את
        מחשבותיה
        בנקל. ראשית
        הַגֵז היה
        מאמריה
        בעתון
        "המליץ"
        בשנות
        השמונים, גם
        החלפת
        מכתבים עם
        המשורר
        הלאומי
        באותו זמן
        יהודה ליב
        גורדון אשר
        כוכבו הזהיר
        בברק מסמא
        עינים בימים
        ההם.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >במלא לה
        כשבע עשרי
        שנה בקרה את
        אחיה היחיד
        יעקב
        פיינשטין
        בבריסק ושם
        נלכדה לחיבת
        ציון וכל
        מעיינה שמה
        במאמרים
        שהופצו אז
        אודות שיבה
        לציון. ומאז
        התחילה
        לחלום איך
        להוציא אל
        הפועל להיות
        אחת מבוני
        ציון ממש.
        וכמה צָרו אז
        צעדיה בדרכה
        על
        "אדמת-קודש"
        של הרוסים
        הטהורים
        בזמן
        שלהיהודים
        אחֶיה אסור
        אפילו לנשום
        אוירה.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >בתחילת שנת
        תרמ"ט פניתי
        כותב הטורים
        האלה
        להמורשה של
        חובבי ציון
        בעירי בריסק,
        מר מרדכי
        פדואה אשר
        הוא הוא שבחר
        בי להיות
        שלוח ארצה
        בין הששה
        שנבחרו מכל
        ארץ רוסיה
        הגדולה,
        שישלים את
        תעודתו
        בבחרו עבורי
        עזר כנגדי
        שתשא עמדי
        יחד בעול
        בנין הארץ.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >בשתים עשרי
        למנחם אב
        תרמ"ט חגגנו
        ברוב פאר
        והדר את
        כללותנו
        בעיר בריסק
        תחת סמל
        ציון, ומי לא
        קנא בזוג
        הארץ ישראלי,
        זו היתה באמת
        חתונה
        היסטורית
        וכמה היתה
        גדולה ההגבה
        על אחינו
        הגלותיים שם
        וכמה נפשות
        עשתה לציון,
        כעבור זמן קט
        נוסדה החברה
        "מנוחה
        ונחלה"
        שאנשי בריסק
        היו בין
        המיסדים.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >בשבעה עשר
        לאלול תרמ"ט
        כבר דרכו
        בראשונה
        רגלי הסופרת
        על אדמת אבות
        הקדוש לה
        ראשון לציון,
        הנקיה מרגלי
        רוסים
        טהורים, בטרם
        עוד מלאו לה
        עשרים שנה
        לחייה. פה
        שאפה רוח
        לרוחה
        ושפותיה
        דובבו משירי
        ר&#39; יהודה
        הלוי, "חיי
        רוח אויר
        ארצך".</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >אך הספיקה
        לסדר לה קן
        בפנת גג ממש,
        נעשה פנתה
        למקום ועד של
        הנוער התוסס
        והמבקש חופש,
        שהיה כל כך
        דרוש באותם
        הימים ימי
        האפטרופסות
        בתקפתה.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >האצבעות
        העדינות
        אחרי גמרה
        שפשוף כלי
        הבישול
        וסיוד הכירה
        מדי יום
        נצמדו ביזמה
        שוב אל עט
        הסופרים אשר
        בכל רגעי
        הכושר לא
        פסקו מלפתֵח
        את כשרונה
        לספרות
        העברית שלה
        כָמהה נפשה.
        ומחיקה לא
        נשל אף פעם
        הספר האהוב
        עליה שירי
        יל"ג.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >כאשר קצת
        גדלו הילדים
        לקחה לה חופש
        מעט, והתמסרה
        לעבודת
        הציבור בכלל.
        בפרט למלחמה
        להשיג שיווי
        זכויות
        לנשים. ופרי
        נצחונה
        הראשון טעמה
        בהבחרה
        הראשונה
        בארץ לחבֵרה
        במועצת
        רש"לצ. חברה
        בחבר
        השופטים.
        חברה בועדת
        תרבות. חברה
        בועד בית
        הספר ברש"לצ.
        בתרפ"ז
        נבחרה כצירה
        לאסיפת
        הנבחרים
        בירושלים.
        היתה יושבת
        ראש באגודת
        נשים עד קרוב
        לסוף ימיה.
        מנהלת חברת
        "לינה"
        ונושאת
        בעולה. אם
        ופטרונית
        ונותנת אוזן
        קשבת לכל סבל
        התמנים
        המתישבים
        ברש"לצ.
        מיסדת חברת
        מִלוה בלי
        רבית
        לנצרכים.
        חבֵרה
        פעילית
        בויצו. חבֵרה
        בחבר אגודת
        הסופרים
        בארץ. הדפיסה
        לפני איזה
        שנים את
        סִפרה מחיי
        הארץ
        "ביהודה
        החדשה"
        ובאחרית
        ימיה טרם כבה
        נרה הספיקה
        להדפיס את
        ספרה השני
        "בכפר
        ובעבודה"
        והשאירה כמה
        כתבי יד
        מוכנים
        לדפוס, אם
        יבוא פעם
        גואלם
        המקווה.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >בשביעי
        לחודש סיון
        שנת תרצ"ד
        נכבה נר
        התמיד, ושבקה
        אותנו
        לאנחות,
        ובעצמה מצאה
        את מנוחתה
        מנוחת
        עולמים בתוך
        אדמת ראשון
        לציון אשר
        בזיעה ודם
        עזרה
        התפתחותה,
        ליד קבר
        הוריה אשר
        כלכלה את
        שיבתם למופת.
        ומאוַיה לבה
        נתמלאו
        לשכון
        במנוחה בבית
        העלמין תחת
        צל השקמה
        כבדת הימים
        שבודאי עוד
        זוכרת את
        עברֵנו בארץ
        זו. ועל יד
        קברה היקר
        לי, עוד נשאר
        מקום פנוי
        היחידי,
        המחכה לבואי
        בבוא יומי,
        ואז תהיינה
        נשמותינו
        צרורות
        בצרור החיים
        אחרֵינו
        בארץ
        חמדתינו.</span
      >
    </p>
    <br/>
    
      <h3 class="c0 c12 c16 li-bullet-0" dir="rtl" id="describedBy">
        <span class="c15"
          >דמותה
          מתוארת על
          ידי נכדה
          אלישיב
          פוחצ&#39;בסקי </span>
      </h3>
    
    <span class="c6" style="font-style: italic;"
          >אלישיב
          פוחצ&#39;בסקי
          (ראשון
          לציון: 1918– 2014), בן
          עשהאל
          ועידה, היה
          נכדם של
          נחמה ומיכל
          פוחצ&#39;בסקי
          ובצעירותו
          עבד עם סבו
          בחקלאות.
          איננו
          יודעים מתי
          הדברים
          האלה נכתבו,
          הם נמצאים
          על דף מודפס
          במכונת
          כתיבה
          כשחתימת
          הכותב וכן
          תיקונים
          אחדים בכתב
          ידו.</span>
    <p class="c0 highlight" dir="rtl">
      <span class="c15 "
        >לזכרה של
        נחמה
        פוחצ&#39;בסקי</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ברצוני
        להתמקד כאן
        לא על הסופרת
        נחמה
        פוחצ&#39;בסקי
        אלא על סבתי
        כאדם, כנשמה
        טובה אשר
        דאגה לכל
        והכל לקחה
        ללבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סבתא נחמה
        הקדישה את כל
        חייה לעזרה
        לזולת. היא
        דאגה לכלם.
        לעניים,
        למסכנים,
        לעולים
        החדשים, כלם
        ידעו שאצלה
        תמיד יזכו
        לאזן קשבת.
        היא כאבה את
        כאבם השתתפה
        בדאגותיהם,
        בקרה בביתם
        והשתדלה לתת
        להם סעד נפשי
        ומעשי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא עזרה
        לכלם, ללא
        הבדל בין עדה
        ומפלגה. עיקר
        דאגתה הייתה
        לנשים, נלחמה
        במרץ למען
        זכויות
        הנשים וליחס
        הוגן אליהן
        מצד בעליהן.
        היא בעצם
        הייתה
        הפמיניסטית
        הראשונה
        בארץ.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרבה מאד
        מזמנה
        הקדישה לעדה
        התימנית שלא
        מכבר עלתה אז
        לארץ. מצבם
        היה קשה
        ביותר ומצב
        הנשים היה
        קשה מנשוא.
        היא בקרה
        בביתם, לימדה
        אותן הלכות
        משק בית,
        טפול בילדים
        והתנהגות
        כלפי
        הבעלים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סבתא אספה
        אליה ילדות
        יתומות
        ועניות,
        שיכנה אותן
        בביתה לפרקי
        זמן שונים
        נתנה להן
        אוכל, מיטה
        חמה והרבה
        אהבה עד אשר
        מצאה עבורן
        מוסד או
        בית-יתומים.
        אני עדיין
        זוכר כמה
        מהנערות
        האלה וגם
        פגשתי אחדות
        מהן אחרי
        שנים. הן
        תמיד דברו
        עליה באהבה
        רבה
        ובהערצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא בקרה
        בביתן של
        נערות
        עובדות
        ושכנעה את
        הוריהן
        לשלוח אותן
        לבית-ספר,
        דבר שלא היה
        אז מקֻבל אצל
        כלם. כמו כן
        כאבה מאד את
        המצב החברתי
        בארץ, את
        היחס בין אדם
        לאדם, את
        היחס בין
        העליות
        והעדות
        השונות ואת
        הריב וחוסר
        ההתחשבות
        בזולת. לכל
        אלה נתנה
        בטוי בכתביה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ולבסוף לא
        אשכח להוסיף
        שבביתנו
        דברו אך ורק
        עברית. וסבתא
        עמדה על כך
        שסבא יעסיק
        במשק רק
        פועלים
        עבריים.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זוהי בעצם
        הייתה
        גדולתה של
        סבתי נחמה.</span
      >
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c17"
        >אלישיב
        פוחצ&#39;בסקי</span
      >
    </p>
    <br/>
    
    <h3 class="c7 li-bullet-0" dir="rtl" id="aboutHerOnTheWeb">
      <span class="c15"
        >דמותה
        וקורות
        חייה
        במרשתת</span
      >
    </h3>
    
    <p class="c0" dir="rtl">
      <span class="c18 c24">
        הספרנים – מגזין הספריה הלאומית, שם המאמר: "בזכותה: האישה שדרשה קול שווה לנשות היישוב העברי"
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c20"
        ><a
          class="c10"
          target="_blank"
          href="https://blog.nli.org.il/giborot-nechama-pohatchevsky/">
          https://blog.nli.org.il/giborot-nechama-pohatchevsky/
          </a>
        </span
      >
    </p>
    <br/>
    <p class="c0" dir="rtl">
      <span class="c18 c24"
        >דוד תדהר -
        אנצקלופדיה
        לחלוצי
        הישוב
        ובוניו, ערך
        נחמה
        פוחצ&#39;בסקי
        (נפ"ש)</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c20"
        ><a
          class="c10"
          target="_blank"
          href="http://www.tidhar.tourolib.org/tidhar/view/3/1246&sa=D&source=editors&ust=1693150677903119&usg=AOvVaw1bPrdt5tHAjcatSCdn0v4O"
          >http://www.tidhar.tourolib.org/tidhar/view/3/1246</a
        ></span
      >
    </p>
    <br/>
    <p class="c0" dir="rtl">
      <span class="c18">Wendy Zierler - NEHAMAH PUKHACHEWSKY</span
      ><span class="c8"> (Jewish Women&#39;s Archive - Encyclopedia)</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c20 c21"
        ><a
          class="c10"
          target="_blank"
          href="http://jwa.org/encyclopedia/article/pukhachewsky-nehamah-feinstein"
          >http://jwa.org/encyclopedia/article/pukhachewsky-nehamah-feinstein</a
        ></span
      >
    </p>  
  </div>
</html>`;

export default page;
