const page = `<body class="c8 doc-content">
  
  <p class="c13 italic" dir="rtl">
    <span class="c9"
      >יוסף ויתקין פגש את בני הזוג פוחצ&#39;בסקי עם הגיעו לראשון</span
    ><span class="c0"> </span><span class="c9">לציון</span
    ><span class="c0"> </span
    ><span class="c9">(כנראה הגעתו השניה לשם ב-1902)</span
    ><span class="c0"> </span><span class="c9">ונראה ש</span><span class="c0 tooltip">הידידות נבטה מיד<span class="c5 tooltiptext"
        >על פגישתם הראשונה וראשית ידידותם, ועל ויתקין האיש, חייו ומותו ראו בספר
        מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי, יד
        בן-צבי, 2013, נספח ויתקין בראשון לציון עמ&#39; 382</span
      ></span><span class="c9"
      >. הוא לימד זמן מסויים בבית הספר בראשון לציון ואח"כ עבר ללמד במסחה ומשם
      כתב מכתבים אלה.</span
    >
  </p>
  <p class="c13 italic" dir="rtl">
    <span class="c9">המכתב הראשון  נמצא בארכיון הציוני.</span
    ><span class="c7"> </span
    ><span class="c9"
      >שלשת המכתבים הבאים (ליתר דיוק, קטעים מתוך מכתבים) מופיעים בספר כתבי יוסף
      ויתקין, עורך אליעזר שוחט, ספרית "עיינות", עם עובד, תל אביב, תשכ"א 1961. לא
      הצלחנו למצוא את המכתבים המקוריים ואיננו יודעים אם הם עדין קיימים בארכיון
      כל שהוא.</span
    >
  </p>
  <br />

  <h3 class="c10" dir="rtl" id="h.w6al7zbvuxua">
    <span class="c18 c17">מכתב ראשון</span>
  </h3>
  <p class="c13 italic" dir="rtl">
    <span class="c9"
      > לפי תוכן מכתב זה נראה שהוא נשלח ממסחה, אחרי "קונגרס אוגנדה" (אוגוסט
      1903) ואף אחרי פרוץ מלחמת רוסיה-יפן (פברואר 1904) אך לפני מותו של הרצל
      (יולי 1904).
    </span>
  </p>
  <br />

  <p class="c12" dir="rtl">
    <span class="c1">למשפחת פחשוסקי שלום!</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c15"
      >        מכתבכם האחרון מב&#39; שבט קבלתי. חן, חן לכם, ידידי, על תנחומיכם
      ועודדותכם. איזו אנחה התפרצה אז מלבי כמו למרות חפצי. כתבתי אז את מכתבי בליל
      אפל וסוער. יללת הרוח, הִדַפְּקות  הגשם על התריסין מסביב והבדידות הנוראה
      בפנים החדר עכרו את רוחי וכתבתי מה שכתבתי. אולם לא תמיד ישלט בי הפסמיזם.
      הייתי באמת חולה מסכן וגם עשו לי נתוח קשה, אך סוף, סוף יצאתי בשלום ומקוה
      אני שאהיה יותר בריא מאשר לפני המחלה. בית ספרי הולך ומסתדר ומקוה אני שבמשך
      הימים יהיה לבית ספר הגון. אבותי באים אל נכון. לעת עתה אין עוד משרה לאבי
      וממשכרתי אצטרך לשלח כ 25 פר&#39; מדי חדש בחדשו לאחותי, אך בכל זאת בטוח
      הנני כי לי ולהורי יוטב מעתה. אני אשתדל שהורי ירדו דרך חיפה, אולם אם אי
      אפשר יהיה הדבר ירדו ביפו ואז אהיה גם אני אצלכם ופה אל פה נדבר הרבה. בנוגע
      לעשרת הנפליונים אשתדל לבלי להוציאם עתה ואם אי אפשר יהיה אבקש את הבנק שתשלח
      לי מחאה על בירות ובמחאה הזאת אקבל פה את הכסף מסוחרי טבריה. מה שלומכם אתם?
      כקבורים חיים הננו ואין לנו עתון (לשלח כסף מפה לחוץ לארץ קשה כקריעת ים סוף)
      גם במכתבינו שאנחנו שולחים והנשלחים לנו אין אנו בטוחים. וגלגל התבל הגדול
      חדל מסובב למעננו. תלחם רוססיה את יפן והדר&#39; הרצל את אסישקין. תבטל
      אוגנדה ותבא </span
    ><span class="c4 highlighted">קונגה</span
    ><span class="c15"
      >  במקומה ואחריה מדגסקר או הרי חשך אחרים, אנו שקועים עד צואר בבצתנו ואין
      אנו יודעים כלום. עננים שחורים ממעל, בצה שחורה מתחת, וביניהם </span
    ><span class="c4 highlighted">יֵרָאה</span
    ><span class="c15"
      > לפעמים ילוד-אשה אמלל עומל ביתר כחותיו לעבֹר את בצת הרחוב. הנה הוא מאמץ
      את יתר כחותיו הרוחניים למצא מקום קצת יותר בטוח לרגלו השניה ואת יתר כחותיו
      המופנים להוציא את רגלו השקועה. אך לשוא! האמלל </span
    ><span class="c4 highlighted">פורש</span
    ><span class="c15"> ידיו ואין עוזר, עד כי ישטח גם </span
    ><span class="c4 highlighted">הזה</span
    ><span class="c15"
      > על פני הבצה המזהירה ובעד החלונות מביטים ילודי-אשה כמוהו ושחוק של רצון
      ועונג, כראוי ליסורי אחרים, עובר על שפתותיהם אך עתה אִבדנו גם את הענג הזה.
      כי חדלו רגלים בישראל חדלו! והיה בהצטרך אשה לשכנתה או איש לרעהו והוציאו את
      הסוס או את החמור ועברו ברכיבה מבית לבית. וגם תינוקות של בית רבן נהיו
      לפרשים וחצר בית הספר לחַן. וממעמקי בצתי עיני נשואות שמה אל עבר ההרים הרמים
      והעננים השחורים, אל מקום האנשים עוברים את רחוב מושבם בלי סכנה להטבע בטיט
      היון, אל מקום בני האדם קוראים עתונים, כותבים מחאות, מתערבים בפליטיקה, בכלל
      חיים חיי קולטורה. הוי מאֻשרים התדעו את אשרכם? הנה השתגע אחד ועבר במסירות
      נפש מבצתו ו__</span
    ><span class="c15"
      >לבצתנו מה חדש, אחי? אשאלהו. יש חדש, יענני: בראשון לציון קרה זה וזה ביפו
      זה וכדומה. מאין תדע זה אחי. קראתי בעתון "הצופה" (היוצא כידוע בוַרשה)
      והצופה חדש הוא כן, עודנו חם הוא קבלתיו לפני חֹדש... התבינו כמה נהנים אנו
      מהעולם הקולטורי ומהתלגרף בלי חוטי ברזל? ועתה תארו בנפשכם את החסד הגדול
      שתעשו לאדם שכמוני במכתבכם ובפרט בהודיעכם בו מכל הנעשה אתכם בפרט ומעולמכם
      בכלל. מה בדבר מחיר הענבים? מה מצב הרוח</span
    ><span class="c9 italic">[?החום?]</span><span class="c15"> בכלל הירד </span
    ><span class="c4 highlighted">בו</span
    ><span class="c1"> בערך של ירידת המחיר?...  </span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c1"
      >מה שלום בית ספרכם. העודנו אהוב למושבה, היוסיפו להתגאות בו, או הצטננו
      הלבבות?</span
    >
  </p>
  <p class="c13" dir="rtl">
    <span class="c1">מה בריאותו של מר פחוטשוסקי לבדו?</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c1"
      >הודעו לי, לשה הנדח, מכל והיה שכרכם רב מאד אם לא בזה הנה בבא בלי
      ספק.</span
    >
  </p>
  <p class="c13" dir="rtl">
    <span class="c1">                                ידידכם הנאמן</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c1">                                        יוסף ותקין</span>
  </p>
  <br />

  <h3 class="c10" dir="rtl" id="h.fwcpbffzl288">
    <span class="c18 c17">מכתב שני</span>
  </h3>
  <p class="c13" dir="rtl">
    <span class="c14"
      > כפי הנראה הכתוב כאן מתייחס למותו של הרצל בתמוז תרס"ד  (יולי 1904)</span
    >
  </p>
  <p class="c3 text-end" dir="rtl">
    <span class="c6">מסחה [תרס"ד – 1904]</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c6">אל משפחת פוחצ&#39;בסקי, ראשון –לציון</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c6"
      >...לא ידעתי גם אני מנוחה וחיים, ומיום ליום דחיתי את מכתבי ליום בו אנוח גם
      אני. והנה עד שלא שקט רוגזי בבית, באה השמועה הנוראה על אסוננו  הלאומי,
       וזמן רב הלכתי כהלום רעם. איזה רגש מעיק, רגש של יתמות אחזני. נדמה לי,
      שהלכתי בדרך באישון ליל-סער ובפנסי הכהה חיפשתי לי נתיב צר, המלא קוצים
      וחתחתים. ופתאום והנה נבקעו העננים, וברק מאיר הופיע וזרמי אורה השתפכו
      וישטפו את העננים – שאורו גם המה הפעם – את המרחב הגדול השטוח לפני ואת ההרים
      הגבוהים שם בקצה האופק. בו ברגע נדמה לי שרואה אני את ביתי, את אחרית נדודי,
      כל-כך קרוב, כל-כך מזהיר ומושך את הלב, והדרך אליו כל-כך קרובה, ישרה וסלולה.
      ופתאום... אהה, שבר! כבה הברק, כבה גם פנסי הכהה, וחושך, חשכת קבר סביבי.
      ואין שמים, ואין ארץ ואין ביתי ואין לי דרך אליו... רק חושך,חושך,
      חושך...</span
    >
  </p>
  <p class="c3 text-end" dir="rtl">
    <span class="c6">יוסף ויתקין</span>
  </p>
  <br />

  <h3 class="c10" dir="rtl" id="h.rjmpu6ak0i2e">
    <span class="c17 c18">מכתב שלישי</span>
  </h3>
  <p class="c13 italic" dir="rtl">
    <span class="c14">ע"פ אליעזר שוחט עורך </span
    ><span class="c14 c17 bold">כתבי יוסף ויתקין</span
    ><span class="c11"
      >, פויל דויטש היה הרפתקן רמאי שכביכול אסף כספים לקק"ל</span
    >
  </p>
  <p class="c3 text-end" dir="rtl">
    <span class="c6">מסחה, כ"ט באדר-א&#39; א&#39;תתל"ו [תרס"ה – 1905]</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c6">למשפחת פוחצ&#39;בסקי, ראשון-לציון</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c6"
      >...אלוהים בירך אתכם בכל: כי יבוא ההלך הנפלא מר פויל דויטש והייתם אתם
      הראשונים שזכיתם לביקורו הנעלה, וחזות את פניו בבית-העם, לשמוע את דרשתו
      המחוכמת, ומר מ., נשיאכם הרוחני, זכה גם להודות לו בשם כל ישראל. אשרהו!
      ה"השקפה" גם היא מינתה אצלכם כתב תמידי, ההולך ומונה בדייקנות נפלאה מספר
      הנולדים למען להודיע לכל באי עולם. בבית-ספרכם יש מנהל גם לעברית העלובה,
      לבביות ראויה לראשון המהוללה, ובצדו תלמוד-תורה, לקיים מה שנאמר וגר...
       בבית-העם ערבי קריאה ושיעורים לחכמת ישראל, וצעיריכם המגוהצים חדלו בודאי
      לבקר את הכרמים גם בעת הזמיר וחוזרים המה על שיעוריהם העבריים. במחנכם נפוצה
      המלחמה בין הזקנים והצעירים, הלו מושכים לבית-הכנסת והללו לבית-העם, הללו
      רוצים לאמור קינות על המחירים שירדו, והללו לנגן בנגינות כדי "להרוג את הזמן"
      שיש כל-כך הרבה.</span
    >
  </p>
  <p class="c13" dir="rtl">
    <span class="c6"
      >        הכל הסתדר אצלכם, איש על מחנהו ואיש על דגלו! הזקנים עמדו בודאי תחת
      פקודתו של "ועד חיפוש חטאים"... הצעירים גם המה התאחדו תחת פקודתו של המנצח
      בנגינות ובצל מקלו. תלמידי בית-הספר התאחדו והסתדרו גם המה. וחברתם בודאי
      סוציאלית-קוסמופוליטית-עברית-ציונית-פרקציונרית כראוי לשוייץ המחכימה. ונוסף
      על כל זה יבשת ברחובותיכם ואין לכם לחכות עשרה ימים עד אשר תוכלו לצאת מהבית.
      הוי מאושרים, מאושרים! ואצלי בוץ, בוץ ובוץ! עמוק הוא, שחור ומידבק, ארץ
      כָּבוּל ממש. וסביב לבוץ הזה הרים גבוהים עטופי ערפל, הסוככים בעדי ובעד
      העולם הגדול שם במערב, החי חיים כל-כך נפלאים. ויש אשר יעברו שבועות, גם
      ירחים יחלפו ובית-ספרי ריק מילדים (כי אין לעבור את הרחוב, פשוטו כמשמעו).
      הרחוב ריק מאדם, המוח ריק ממחשבות והלב גם הוא חלל! בכליון-עינים אחכה למכתב,
      לעתון. עשר שנות חיי הייתי נותן בעדם, ואינם! ורק הרוח הומה ומבכה אותי,
      והעננים זולגים דמעות, זולגים בלי הפוגות... ויש אשר תופיע השמש מבעד למפלשי
      העבים, והזהירו השלוליות של ביצתנו ונהרו, ואשֹנָאֶהָ אז פי עשרה. ההרים
      מופיעים לפנַי כולם ירוקים, רעננים, מושכים הם אותי אליהם כבחבלי קסם,
      מבטיחים הם לי עשב רך ופרחים נחמדים, והביצה הארורה שמה עלי מצור, אין יוצא
      ואין בא!</span
    >
  </p>
  <p class="c3" dir="rtl">
    <span class="c6">יוסף ויתקין</span>
  </p>
  <br />

  <h3 class="c10" dir="rtl" id="h.rne5d25cyoia">
    <span class="c18 c17">מכתב רביעי</span>
  </h3>
  <p class="c3 text-end" dir="rtl">
    <span class="c6"
      >מסחה, יום ו&#39;, א&#39; בחול-המועד פסח [תרס"ה – 1905]</span
    >
  </p>
  <p class="c13" dir="rtl">
    <span class="c6">למשפחת פוחצ&#39;בסקי, ראשון-לציון</span>
  </p>
  <p class="c13" dir="rtl">
    <span class="c6"
      >...צר לי מאוד ששלחתי את לשוני בקדשיכם, ושכחתי מקרא מפורש "וכל זר לא יאכל
      קודש". מעתה הנני ירא כבר לשאול אודות בית-הספר, מר ק.ר.; אודות המושבה
      ומ.מ.פ., ק.ו... ויתר גדוליה, פן אגע עוד פעם בקדוש, וקדוש פגיעתו רעה עוד
      יותר מאשה. לה יש באופן היותר רע שנים-שלושה מקנאים, ולקדוש – שבעים ושבעה...
      לשמחתי לא היה קץ בשמעי שהמנצח-בנגינות שלכם הואיל לדבר קצת עברית. מרוב
      התפעלות חטפתי את "בית-ספר עברי", חלק א&#39; של גרזובסקי ויצאתי במחול אתו.
      הצעתי, שהכתב המיוחד של "ההשקפה", הנמצא במחנכם ימהר להרימו על נס, פן יתחרט
      אחרי כן ויתחיל מחדש לגמגם צרפתית.</span
    >
  </p>
  <p class="c13" dir="rtl">
    <span class="c6"
      >משתדל אני לעבור הפעם מליצנות לרצינות, כי ירא אנוכי פן תחשבוני למאושר
      באמת, והמאושרים באמת, כידוע, אין להם אף כותונת לעורם, וביחד עם הכותונת
      אובד האדם את הכל, ובפרט את רעיו וידידיו. ובכן דעו לכם, יקירי, שאינני עוד
      מאושר לגמרי. יש לי עוד הרבה יסורים פנימיים, רוחניים וחמריים, וגם כותונת יש
      לי עוד. וצחוקי זה אנחה היא, ומהול הוא בדמעות...</span
    >
  </p>
  <p class="c3 text-end" dir="rtl">
    <span class="c6">יוסף ויתקין</span>
  </p>
</body>`;

export default page;
