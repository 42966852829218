const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c9 doc-content">
    <p class="c11 italic space-bottom" dir="rtl">
      <span class="c5 c10">זהו מאמר שכתב אברהם יעקב סלוצקי והתפרסם בעתון </span
      ><span class="c5 bold">המליץ</span
      ><span class="c5 c10"> ביום ה&#39; ט&#39; מנחם אב תרנ"א, </span
      ><span class="c5 bold">13 באוגוסט 1891</span
      ><span class="c5 c8"
        >. בפסקה האחרונה מובא קטע ממכתב שכתבה נחמה פוחצ&#39;בסקי (המכונה כאן
        בכנוייה הספרותי נפ"ש).</span
      >
    </p>    
    <p class="c0 bold" dir="rtl">
      <span class="c1">תמונה מחיי אחינו בראשון לציון. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >ריש ועושר נפגשו בספרות הישוב: עשירה היא בחדשות מתנועת הישוב והתפתתותו,
        ועניה עד מאד בידיעות ברורות ונאמנות ע"ד מצב החומרי והרוחני של אחינו
        הקולוניסטים, דרכי חייהם וסדר עבודתם בבית ובשדה. הסופרים באה"ק אינם
        מקמצים בדברים וגליוני כל מה"ע העברים הם ממלאים ב"מכתבים מאה"ק". אך רוב
        הסופרים האלה הם רק "מודיעי חדשות" ולא יותר. בשבע עינים האלה יביטו על כל
        ‬הנעשה בעולם הישוב, ועל כל דבר קטן וגדול ירעישו עיר ומתים, וכל כך זריזים
        הם כמקצוע זה, עד כי לפעמים יודיעו לנו סופרים אחדים ידיעות קלוטות מן
        האויר והמון הדשות עוד בטרם באו ונהיו. אם אחד מאחינו יתן עיניו באחוזה
        פלונית לקנותה ובאו מיד חסופרים ויצרפו מחשבה טובח למעשה ויכריזו בקולי
        קולות כי "שדה פלוני לפלוני‭."‬ האדמה עוד טרם נקנתה, עוד ערבי יהל עליח
        וקוצים וחרולים יכסו פניה וסופרינו—ה&#39; עליהם יחיו—כבר עומדים ומברכים
        "ברוך מציב גבול אלמנה" ותוקעים בשופר גדול ע"ד התיסדות מושב חדש שלא היה
        ולא נברא אלא בהבל פיהם של  המודיעים האלה. במקצוע זה זריזים הם  סופרינו
        וכמעט יוכלו להתחרות עם סופרי אמיריקא הבוראים חדשות רבות יש מאין ממש,
        ואיידי דטרידי בפרסום חדשות, ישן מפני חדש יוציאו ויסיחו את דעתם לגמרי
        מחיי אחינו בהמושבות, ארחם ורבעם, ממצב הקולוניות בפרט ומטבע הארץ ויושביה
        בכלל. זאת היא לדעתי הסבה שעד היום הזה אנו מגששים כעורים קיר בכל דבר
        הניגע לישוב הארץ ואין אתנו יודע בידיעה ברורה גם האלפא־ביתא של שאלת
        הישוב. רוב בעלי הישוב בקרבנו אינם יודעים בברור גם מספר המושבות שנוסדו עד
        כה באה"ק; האחד מונה עשר, השני—ארבע עשרה והשלישי - שמנה עשרה מושבות,
        ועאכ"ו שאין אתנו יודע בברור מצב המושבות בפרט, שטח האדמה שבאה
        הת&#39;&#39;י אחינו ומספר המשפחות שנאחזו בהמושבות. יוכל היות שבעיני
        הסופרים באה"ק אין דברים כאלה חשובים כלל לשום אליהם לב ולא יאבו לטרוח
        ב"קטנות" כאלה, אבל באמת כל האומר כך אינו אלא טועה וחבל על הטעות הזאת
        המאבדת טובה הרבה, כי ידיעות פשוטות ממצב המושבות והתפתחותן ותמונות נאמנות
        מחיי הקולוניסטים והנהגתם במילי דשמיא ובמילי דעלמא, הן הן עיקר הישוב
        ויסודו. וחביבות ויקרות הן לנו יותר מאלפי מאמרים ע"ד הצעות שונות הפורחות
        באויר וקורספנדנציות ע"ד חדשות קלוטות מן האויר המביאות מהומה ואנדרלמוסיא
        בעולם הישוב.
      </span>
    </p>
    <p class="c0 c2" dir="rtl">
      <span class="c4"
        >אם יכנסו דברי אלה ללב אחינו הסופרים באה"ק וישתדלו למלאות את החסרון
        המורגש הזה אשמח מאד שזכות כזאת תתגלגל על ידי. וכדי לקיים מאי דאמרי אנשי,
        "קריינא דאגרתא איהו להוי פרונקא" אביא בזה דברים אחדים ממכתב אחד פרטי
        שקבלתי בימים האלה מאת הסופרת הנכבדה מרת נפ"ש ע"ד חיי אחינו בראשון לציון
        וסדר הנהגתם בבית ובשדה, ואקוה כי קוראי "המליץ" יקראו כאהבה וברצון את
        הדברים האלה הכתובים בלי כל כחל וסרק ומליצות יהירות, ורק בשרד נאמן תארה
        פרטים אחדים מחיי אחינו אשר לכל בהם חיי רוחנו ונפשנו, וז"ל:
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        > "...‬טרם אחל את תשובתי על עשר שאלותיך, הנני להעירך לבל תאמין בדברי
        הטופלים עלינו שקר האומרים כי בראשון־לציון הגיע הלוקסוס - זו החיה הטורפת
        - עד הדיוטא העליונה, כי שקר הם דוברים. אמנם ישנן עוד בראשון לציון שתים
        שלש נשים (וגם גברים כמספר הזה) הרודפות עוד אחרי המֹאדה, אבל בכלל אין שם
        ואין זכר ללוקסוס בקרבנו. נשים אחדות אשר הביאו עמהן שמלות יקרות מאירופא
        תחשבנה להן לבושה ולכלמה ללבוש אותן על בשרן, ואם לפי שעה עוד לא כלם הכירו
        את התועלת הרבה אשר תצא לנו אם נכחיד את הלוקסוס מקרבנו, אבל תקוה רבה
        נשקפת לנו כי לא יארכו הימים ויבואו כלם לידי דעה אחת: ל ה נ ז ר  מ מ ו ת
        ר ו ת   ו ל ה ס ת פ ק   ב מ ו ע ט.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4">(סוף יבוא)</span>
    </p>
    <p class="c7" dir="rtl">
      <span class="c4">אברהם יעקב סלוצקי.</span>
    </p>
    <p class="c6" dir="rtl"><span class="c4"></span></p>
    <p class="c6" dir="rtl"><span class="c4"></span></p>
  </body>
</html>`;

export default page;
