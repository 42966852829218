const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c1" dir="rtl">
      <span class="c4">מיל"ג  3.1.1886</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2 c3">שלום לך בתי תמתי נחמה, באשר את שמה!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 c2"
        >לבבתני במכתבך הנחמד אשר נוראות נפלאתי עליו. האמת אגיד, כי כמעט לא אאמין
        לדברים כי נערה בת שש עשרה שנה את וכי בשנתיים ימים למדת את שפתנו הקשה עד
        כדי לכתוב בה מכתב ארוך ערוך בטוב טעם ודעת כזה. אשרי הוריך כי נתן להם
        ה&#39; בת משכלת כזאת ובכל שמה, ואשרי האיש אשר תפולי לו חבל בנעימים; כי
        נכון לבי בטוח כי ככשרונותיך כן מדותיך וכשם שהיית תלמידה ותיקה כן הנך בת
        טובה וכך תהיי, בהגיע תורך, גם אשה טובה ואם טובה, ואז תהיינה חכמתך ודעתך
        כענקים לגרגרותיך והיית תפארת הבנות ובך יתפאר ישראל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 c2"
        > באמרך כי קראת את שירי ויטבו בעיניך נתת שמחה רבה בלבי, כי הנך רואה את
        ניד שפתי על גורל נשי עמי העלובות בשירי "קוצו של יו"ד" ושומעת את קול
        קריאתי אל אחותי בנות ציון בשירי "למי אני עמל" ואיכה לא אשמח בראותי אחת
        מבנות ציון יודעת שפתי ומבינה הגיגי? עלי והצליחי בתי תמתי, כי יתן ה&#39;
        לך כלבבך תשגשגי ותפרחי ובבוא עתך עת דודים אל נא תשכחי לקרוא אותי לראש
        שמחתך אז מרחוק אתערב בשמחתך ומשירי יהודך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c3 c2">המוקירך מבלי להכירך,</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3 c2">יל"ג</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >פ.ס. ביאור מלת "חומה" תמצאי במקומה בבאור (שה"ש ח&#39;) ולהרחיב באורה לא
        יתכן באזני עלמה צעירה לימים אשר גם היא עודנה חומה.</span
      >
    </p>
  </body>
</html>`;

export default modal;
