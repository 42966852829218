const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c4" dir="rtl">
      <span class="c2 c0">פורסם לראשונה ב</span
      ><span class="c0 c5 bold">עִתּוֹנֵנוּ: עִתּוֹן לַיֶּלֶד וְלַנָּעַר</span
      ><span class="c0 c2"
        > בכ"ה סיון תרצ"ד [8.06.1934], (שנה ג&#39;, גליון טז). לפני הסיפור מופיע
        הספד קצר לכותבת שנפטרה שבועות בודדים קודם לכן.</span
      ><span class="c0"> </span>
    </p>
  </body>
</html>`;

export default desc;
