const page = `<html>

  <body class="c13 doc-content">
    <p class="c8 text-left" dir="ltr"><span class="c2"> .על קבר ב.  פ</span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >שמעונוביץ פוסע לאטו לארך פרדסו, מסתכל בעינים נוגות בעציו הנמוכים עם
        טרפיהם הנושרים, גונח ומהרהר: – מות, רק מות שורה בפרדס מכל העברים! –
        העצים, טפוחיו, הולכים ומתנונים כמו שמתנון רוח נוטעם העלוב...  אחריו הם
        נגררים, אחרי התועה האמלל שלא ידע לסלל לו דרך בארץ!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >הוא תולה ביאוש את עיניו בסביבה המקיפה את פרדסו, והן כאילו תוהות
        ושואלות: – מדוע סמוך לכאן הטבע כה פורח?...  השקדים והאפרסקים של השכן,
        כמה רעננים הם! ושם, יער הזיתים הלז, מה יהיר הוא ואיזה עשר רב צפון בו
        לבעלו... רק בפרדס שלו שורה הקללה, רק הוא כה אמלל, רק הוא! הוי, מדוע בא
        לו ככה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >שבור ורצוץ צונח שמעונוביץ ארצה, נסמך בגבו לעץ אחד ותולה מבט עמום
        במרחק.  – לפניו מתנשאה גבעה יפה, בנויה בתים לבנים, הטבולים בים של ירק,
        אחריהם משתרעת חרשה נפלאה, שבה מתערבים העצים מין בשאינו מינו, גבוהים
        בנמוכים, עבים בדקים. – הרי אכּליפטוסים ענקים, ארזים זקופים, תמרים
        צעירים, והרי גם זינזילַה ותות ותאנה! –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >על פני החרשה שטים עננים קלושים, מכֻספים וביניהם נחפז ורץ הירח החור
        שפניו כֻסו צל.  דומה כי הוא רוצה להשיג את החמה, היורדת מערבה מתוך ענני
        ארגמן המלוים אותה, רוצה למסר לה סוד, הכמוס בצלליו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >הוצתה מדורת אש בחרשה והתחילו העצים מתיזים ניצצות מסביב.  פתאם נחבאה
        המדורה ושוב התלקחה והציתה את כל היער.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >שמעונוביץ האהיל על עיניו בקצה כובעו.  שפע האורות הגדילו את לחץ לבבו,
        הוא הצטמק בכל גופו, כאלו רצה ללחוץ משם את יסוריו הקשים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >...כבה הניצוץ האחרון! – מהרהר שמעונוביץ.  – עתה לא נשאר כלום, כלום בלתי
        אם לבחר בדרך ההיא!...  אמנם עוד לפני ימים מועטים הבהבה התקוה להנצל, סבור
        היה שישיג את ההלואה החדשה וישיב לארורה את כספה ויפטר ממנה...  ועתה אין
        כבר תקוה להשיג כסף ולקנות לו חפשה, אין! נסגרו כל הדרכים לפניו רק זו
        נשארה, זו היחידה, – לעזב את אפרחיו, לעזב הכל, הכל ולהפרד מן
        החיים...  אבל קשה זה, הוי כמה קשה, וחושש הוא שלא יספיק המרץ...</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c2">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >עיני שמעונוביץ נתלו בגבעה עם שקמה עבותה, ענקית בראשה. שם פורשים צללי
        ערב סות כהה על פני קברים לבנים, שמזכירים לשמעונוביץ את ברתה, את חברתו
        המשכילה שאבדה לו מכבר.–</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >...  כן, משכילה היתה ברתה, – הרי ידעה את נדסון בעל פה, קראה תדיר בספרים
        רוסים ודברה בסגנון כה יפה את הרוסית שלה!...  זוכר הוא כיצד אהבה ברתה
        להתפלסף ולהתוכח ע"ד הספרות.  הוא אמנם לא ידע להתערב בשיחותיה, אבל תדיר
        היה זוקף אזנו לשמע את התפלספותה המחכמה ונדמה היה לו שבקסם לשונה היא
        מכניסה אותו לאיזה עולם נאצל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >וזוכר הוא את חייו הפשוטים שלפני הנשֻאין.  – אם לפת קיבר נוספו זיתים או
        עגבניות עם כוס טה, היתה הארחה נחשבת בעיניו לסעדת שלמה בשעתו.  הבית הקטן
        שלו עם העצים הסבוכים מול החלונות הנמוכים, דומה היה לפלטרין ממש; ואם
        בעונת החרף היו חודרים רוחות וגשמים אל היכלו, לא בא הוא במבוכה ולא התעצב,
        – הרי מקבתו בידו והוא מתקן הכל! להלבשה ולהנעלה לא דאג מעולם, כלום הרבה
        צריך אכר בארץ? – חליפה פשוטה וסנדלי עץ – וחסל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ועם ברתה באה התסיסה! תחת השפעתה שונו לאט לאט כל ערכי חייו הקודמים.  היא
        העמידה מלכתחילה דרישות גדולות והוא לא נבהל מפניהן.  סובר היה אז
        שהכנסותיו תספקנה לכל, כי הרי עולה מחיר הענבים ועולה, והמין ההודי הגיע
        כבר לשבעים פרנק הקונטר, – כלום אינם צפונים אוצרות קרח לכורמים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ומתוך אמונה זו הוא הרחיב את ביתו, הרים את גגו, הנהיג חיים מרוחים והפקיד
        את בחירי הערביים שלו לשרת את הגברת, ולצית לה בכל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >מאשר הוא היה, וסאת אשרו הוגדשה בעת שברתה ילדה לו את שושנה&#39;לי, כרוב
        קטן שחור עינים ושחום שערות, שצפצופו ערב לאזנו כמנגינה נעימה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >זוכר הוא כיצד נהרו אז פני ברתה מתוך הקינוף עם המסך הלבן. והיא אעפ"כ
        התלוננה, שהחדר לא די גדול וחסר לה אויר שם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">הוא התחטא לפניה כילד שסרח ושדלה בדברי פיוס:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >– עוד תראי, חביבה, איזה פלטין אבנה לך ולכרוב זה הקטן, שהבאת לי!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >והוא הרי בלב תמים האמין בהבטחות אלו! ורק אחרי כך, אחרי שנולד לו צבי,
        התחור המצב והוברר, שאין עוד לקות לעשר מכרמי הגפנים…</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >משונה היה המצב.  – מהאי גיסא כבר העיקו עליו החובות, ומאידך – היה תוקפו
        יצרו להתעשר, להתעשר בשביל ברתוצ&#39;קה שלו, שאינה רוצה בחיי עוני, ושלא
        לכך נשאה אליו… התחילה התסיסה… בכל מאמצי כחותיו הוא חתר וחפש את מקור
        העושר, עד שהלה נגלה לפניו בתמונת פרדס אתרוגים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >פרי זה, תשמיש לקדושה, כשיבא מא"י עבוד בידי בני ישראל ורווי בזעתם – בודי
        ובודי שיתפס את כל השוק העברי! – הוא היה בטוח בזה, ומלא אמונה ותקוה נגש
        לנטיעת פרדסו.  בידים ריקות אבל אמיצות התחיל בעבודה הקשה, שרק הסכום הגדול
        של מרץ שהיה נכלל בגופו האמיץ, עזר לו להחזיק מעמד בה.  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >וזוכר הוא כמה מאשר היה, כשנקלטו השתילים והתחילו משגשגים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >- אלו ידעת, חביבה, איזה עשר טמון לנו בפרדסנו! אלפים הוא עתיד לתת,
        רבבות! – סח הוא לברתה בהנאה מרובה.</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span class="c2"
        >ושוב האמונה בעֹשר, בחיים מרֻוחים האירו את פניה ופתאם תקפה המחלה! קלה
        היא לכאורה, ואף צל של סכנה לא נראה בה, אלא שנמשכה ונמשכה! לבסוף קפץ השטן
        והסית לנתוח, - והיא תחת האזמל של המנתח מתה, מפחד מתה!...</span
      >
    </p>
    <p class="c12" dir="rtl">
      <span class="c2"
        >הוי הנתוח הארור! – אלמלי הוא אפשר שבחיים היתה כיום חברתו החכמה...</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c2">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >צללים עגומים פשטו בכל הסביבה. מאיזה פנה נסתרה התחילו צפרדעים מקרקרות;
        מרחוק מיבבים השועלים, והקולות הללו שורטים בלב שמעונוביץ שריטה זעומה, ואף
        על פי כן הוא נשאר כמו צמוד למקומו – אין כח לזוז!.. כפוף הוא יושב וראשו
        מלא הרהורים קשים:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >...עשר שנים רצופות הוא טפל בילדיו במסירות ואת כל לבו נתן להם!.. יש
        שידידיו באים וטורדים: תשא לך אשה! – די לשבת גלמוד!" – והוא כועס: - "אם
        חורגת לילדַי? – היה לא תהא כזאת!..</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ועכשו?... מה היה לו לעולל ככה ליתומיו?... למה הביא האשה הזרה הזאת לקצר
        את חייו וחיי ילדיו?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >הה, - נחש בדמות ארבעים אלף פרנק השיאו! להבנות ממנה אמר, - לשלם את
        חובותיו המחניקים אותו, להציל את נחלותיו ולהתחיל את החיים מחדש!... הוי,
        הזיה שטותית שבשבילה הוא הולך בערמה אתה: - פרדסן גדול, אכר עשיר, ביתו מלא
        כל טוב, ואורוותיו – סוסים ופרדות; בחצר מרכבות מוכנות לקראתה לטיולים!..
        הוי למה רמה אותה ככה? – שוטה שבעולם הוא! כלום אינו שוה עתה כל הגהינם הזה
        וכל הגדופים והזלזולים ושפיכת הדמים? כן, שוה הוא, שוה! – ושמעונוביץ נושך
        את ידיו מכעס. -  -  -  -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ולְמַה קוה קודם? – מהרהר הוא אילך – הרי זה כמה וכמה שנים ברי לו הדבר,
        שכל עמלו לשוא, - אין דרישה על אתרוגיו!.. אלפים, אלפים מפרי הדר זה הוא
        מקבר כל שנה בבור סמוך לפרדס, ואף על פי כן לא חדל מהרבות בהוצאות על
        תעמולה, על נסיעות ועל העבודה עצמה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >אל אלהים, - איזו שגיאה, איזה שגעון זה היה, - לראות באותות ובמופתים שנוח
        לו לעם הקדוש, להעשיר את שונאיו, רודפיו במליוני פרנקים שנה שנה, - ולהאמין
        שעוד ישתנה הדבר, שעוד תפתחנה האזנים הסתומות משמע אנקת אחים!..</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >אתרוג קורפו! – לועג שמעונוביץ במרירות – כמה מן הקדושה יש בו! – מהפך
        אותו היהודי הגלותי בחרדה לפני המקום ברוך הוא; מתענג ומקוה לשכר מצוה...
        האי-ארץ-ישראל? דם אחים הצועק מן האדמה? – זה לא כלום! לא כלום!  -  -  -
         </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >לילה פשט על הארץ ושמעונוביץ טרם עזב את מקומו תחת האתרוג.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >למה? – חושב הוא – למה ילך לשם, לארורה?... הה, אלמלי אפשר היה לו למות
        כאן!.. סביב העצים, טפוחיו. לוקח הוא למשל רובה ונכנס בצלם... הם מרשרשים,
        מניעים בראשיהם – "שלום, שלום!" – והוא: "אחת, שתים!" מותח את המקור, -
        והרי נגמרו כל החשבונות הארורים עם העולם! -  -  </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >לגמור חשבונות? – מהרהר שמעונוביץ – כלום יש לו רשות כלפי ילדיו, יתומיו?
        – עלובים, מה יהא אתם? מה יהא?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בשעה מאחרת הוא קם ללכת... נדמה לו שילדיו הם אתו והוא מפיסם: - "כשיגמור
        עם חייו העלובים, ירחמו עליהם, על חביביו, אנשים טובים ויסללו להם דרך שהוא
        אבד בשבילם"! -  -  -  </span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c2">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c2">ובבית פגשה אותו אשתו בקיתון של רותחין:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >- היכן היית, נבל? כיצד הרשית לעצמך לשבת בבתים זרים עד שעה מאֻחרת כזו? –
        צועקת היא, וכל גופה הזקוף והבריא רוטט מכעס.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >לקול צעקותיה התכנסו השכנים ויזקפו אזניהם בסקרנות לשמע את טענותיה
        וקללותיה הגסות שחלו על ראש שמעונוביץ העלוב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ויתהו המתכנסים: שמעונוביץ כבש פניו בקרקע ולא ענה אף מלה אחת. התחמץ לבם
        ויאמרו להתערב ולעצר בעד שטף קללותיה, אבל שמעונוביץ התחנן:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >- אין צרך, רבותי, הרי מחר יבא הקץ!... במטותא מכם אל תפריעוה!...</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c2">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >עם עמוד השחר יצא שמעונוביץ מביתו, מזין ברובה החדש שלו; בפסיעות זהירות
        הוא נגש לחדר בתו, הנמצא בבנין נמוך סמוך לארוה. כאן הוא פרק את הרובה
        משכמו, העמידו אצל הציר ונגש בלאט אל הדלת.</span
      >
    </p>
    <ul class="c7 lst-kix_list_1-0 start">
      <li class="c5 li-bullet-0" dir="rtl">
        <span class="c2">היא ישנה! – לחש, כשהטה אזנו למנעול.</span>
      </li>
    </ul>
    <p class="c1" dir="rtl">
      <span class="c2"
        >הוא הרהר רגע קל ויסר אל החלון. עששית קטנה האירה את החדר, שצער עגום היה
        שורה בכל פנותיו. פני שושנה היו חורים, חורים! – לבו דופק ומתכוץ
        ביסורים.</span
      >
    </p>
    <ul class="c7 lst-kix_list_1-0">
      <li class="c1 c14" dir="rtl">
        <span class="c2"
          >שלום, שלום לך לנצח! – רוחשות שפתיו כשהוא רוצה לטול את הרובה וללכת
          מכאן, ואחרי רגע נמלך וחזר אל הדלת.</span
        >
      </li>
    </ul>
    <p class="c1" dir="rtl">
      <span class="c2"
        >הוא נכנס בלאט. נגש על בהונות רגליו למטתה, העיף עליה מבט צורב, גחן קצת
        ולחש בחשאי הברות מקטעות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >שושנה הזדעזעה והקיצה. תולה באביה מבט תוהה ושואלת בפחד:</span
      >
    </p>
    <ul class="c7 lst-kix_list_1-0">
      <li class="c5 li-bullet-0" dir="rtl">
        <span class="c2">כלום לא פסקה עוד המריבה שעדיין אינך ישן, אבא?</span>
      </li>
      <li class="c5 li-bullet-0" dir="rtl">
        <span class="c2"
          >פסקה המריבה, בתי, פסקה, - הכל יפסק! – בשביל כך אני נוסע בשעה מוקדמת
          כזו למושבה אחת ובאתי להגיד לך שלום!</span
        >
      </li>
      <li class="c5 li-bullet-0" dir="rtl">
        <span class="c2">תהיה גם במקוה ישראל אבא, - תראה את צבי?</span>
      </li>
      <li class="c5 li-bullet-0" dir="rtl">
        <span class="c2">לא, לא אהיה שם! – שלום, שלום!</span>
      </li>
    </ul>
    <p class="c1" dir="rtl">
      <span class="c2"
        >הוא מגפפה, נושקה נשיקה חטופה ומסתלק בזריזות. נוטל בחוץ את הרובה ויורד
        עד פשפש הגן. כאן הוא שוהה קצת וזוקף אזנו, - שומעים רק נחרת הסוס בארוה
        ונביחת הכלב מאחרי הגדר של השכן... מעיף הוא מבט לאחור ורואה את קירות ביתו
        הלבנים, שבולטים שם מתוך האפלה הקלושה. באחד החלונות הציץ אור וכמו צל אדם
        חלף מהר. – שמעונוביץ הזדעזע וישמט בפשפש.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >פוסע הוא בשביל הצר בין העצים, בא עד האתרוג, שישב תחתיו אמש, והתחיל פורק
        את רובהו… בעמידה כפופה חלץ את נעלו, כשלבו הגיש בחזקה וידיו רעדו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >– אלי! – רוחשות שפתיו, – רגע אחד של אמץ לב תן לי, ואני חפשי חפשי!
        –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >הוא נוטל את הרובה, תוחב את אצבעות רגלו הימנית בתוך הרצועה, מגיש את פי
        הצנור לתוך פיו ובאגדלו מותח את המקור התחתון של הרובה…</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >–לעזזאל! – מה זה?! – צועק בבהלה שמעונוביץ, כשהוא מרגיש את דחיפת הברזל
        הקר בלשונו ותו לא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >בודק הוא את הרובה ומוצא שאבוב אחד ריק, אבל משנהו מלא… שוב תוחב הוא את
        רגלו ברצועה ואצבעו מחפשת את המקור השני…</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ראשו סובב… נשמע לו רעש – והוא נוטה אזנו בפחד – מי זה? … – קול שושנה?
        לא! – זה צבי! – הוא בא?...  הרוח מיבב!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >פי הרובה בין שניו, – ואגדלו מותחת בחזקה את המקור למטה…</span
      >
    </p>
    <p class="c6" dir="rtl">
      <span class="c4"
        >חניקה… פצצה… פרכוס, – והאדמה התחוחה שותה בצמאון את הדם הקולח בעודנו
        חם…</span
      >
    </p>
    <p class="c6 c10" dir="rtl"><span class="c4"></span></p>
  </body>
</html>`;

export default page;
