const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c16 doc-content">
    <p class="c5" dir="rtl"><span class="c6"></span></p>
    <p class="c4 italic" dir="rtl">
      <span class="c8 bold">הפועל הצעיר</span
      ><span class="c14"> תמוז אב תרס"ח   30.7.1908  עמ&#39; 15-14</span>
    </p>
    <p class="c4 c12" dir="rtl"><span class="c18"></span></p>
    <br/>
    <p class="c9 text-center" dir="rtl">
      <span class="c0"> (מעין מכתב)</span>
    </p>
    <br/>
    <p class="c4" dir="rtl">
      <span class="c0"
        >ירדו הנוטרים הצעירים מהכרמים לפנות ערב, שבו הפועלים מעבודתם, התרחצו,
        הסתרקו ולבשו את חליקותיהם הנקיות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >לבשו גם בני האכרים את בגדיהם הפשוטים ; והפועלות הצעירות גם הן עזבו את
        מלאכתן, גהצו מהר את שמלותיהן הלבנות, וכלם ממהרים לתתונת אחד הפועלים מפתח
        תקוה עס בת אכר רחבותי.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >
        ושמחה פה החברה העליזה הזאת! כשבאתי אני אל החתונה, מצאתיה, כשהיא כבר פתחה בשיר פיה והתחילה לרקד בעגול, בתחילה משך האחד את השני, השני את השלישי וכו' עד שנהיתה שרשרת אחת גדולה, עגולה, חיה ומתנועעת, שהעירה כל נשמה חיה להדבק אליה, והרקוד הלך והתלהב, והשלשלת גדלה והתחזקה והתכפלה עד אפס־מקום בחדר.
        </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        > וכשנתיגעה החבורה מהרקוד, התאספו קבוצות קבוצות והתחילו לשיר ברצינות.
        ועל כל הקולות עלה בצלצולו הנעים קול אחת הצעירות, שגדלה במושבה, ילדה מלאה
        חיים ותנועה. בשצף קל השתפך קולה ורעד קצת בתוך הגלים הכבדים של אויר החדר
        הצר.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >והשירים שירים לאומים, שירי תחיה, מלאי גבורה ועז, נוקבים ויורדים הם לתוך
        הנפש בזרמם החם, זרם של תקוה, בגבורה ובאמיצות הלב של העם הנדח.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >עוד לא הספיקה להשתתק מחיאת הכפים הרועשת, שנתלכדה יחד עם בת-קולה האחרונה
        של המזמרת הצעירה, ואחד החברים התעורר מחדש, התחיל סופק כף, רוקע ברגל ושר
        בקול צרוד:
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0">עורו,אחי,אל תנומו, </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0">לעבודתכם, אנא, קומו! </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >כרטט עבר על כל החבורה, כלם נזדעזעו, קפצו ממקומותיהם, נתקלו האחד בשני,
        ושוב התחיל הרקוד האין־סופי‭...‬ </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >
        רוקד הסופר הרציני, אכר כבן שלשים ושלש, שזה עתה שמע מפי אחת ממיסדות המושבה, זקנה למעלה משבעים, כי כשהיא מביטה על פניה ועל פניו, היא רואה בחוש, איך שניהם הולכים ובלים ומזדקנים…
        </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >רוקד הפועל הסופר הצעיר, שעיניו השחורות בוערות שבעתים בתוך רקודו,
        ובלוריתו התלתלית מתנודדת על מצחו וטובלת בתוך שפעת הזעה שעל פניו ; כתנת
        הפסים, שמוסיפה כה חן לפניו השחרחרים, פתוחה כבר מהצואר עד עצם החזה, והוא
        שר ורוקד כחסיד אמתי, עד בטול היש ממש.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >רוקד האי "זעירא דמן חבריא" -‭ ‬ פועל אחד, אשר משכמו ומעלה גבוה הוא
        מכלם, רחב הכתפים, בריא וחזק, ובגחוך הרגיל על שפתיו צועק הוא בקול רם
        בעברית: "ימינה", שמאלה, סבוב, זוגות, גברים לשמאל! נשים לימין!" והולך הוא
        בראש, מושך את המרקדים לכל החדרים, מסובב אתם את כל השלחנות וכל הכסאות ושב
        לחדר הרקוד‭... ‬
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >לחיי הרקוד העברי  בארץ ישראל ‭ — !‬ נשמע פתאם קול חבר אjד מבין בקבוקי
        היין שנערמו על חשלחן.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        > קול הידד הרעיש לרגע את האויר, והרקידה נמשכת ביתר שאת.
      </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0"
        >
        ומהצד עומדים חברים חדשים, שזה מקרוב באו לא"י, עומדים ומסתכלים בשמחה התמימה והנלהבה; רואים הם בפעם הראשונה בחייהם, איך צעירי ישראל רוקדים על אדמתם, ורגש של התפעלות עמוקה נוגע בחזקה במיתרי הלב, יורד עד עמקי הנפש ומעלה משם פנינה אחת, שיורדת בתור דמעה על הלחי; ונוזלת הדמעה לאטה ונופלת לרגלי הרוקדים.
        </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >- "לוא ידעתי לדבר בשפתם, לשיר את שיריהם, לעבוד את ארצנו כמהם, אז גם אני
        מאשר הייתי, והייתי גם אני שר ורוקד!"  ‭  - ‬ אמר חדש אחד בעצבת עמוקה שעל
        פניו.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >"ולוא חפצה משפחתי לבא הנה - חשב חבר אחר בלבו - ולטעום מתענוגי חיי
        ארצנו, אז הרימותי את כל החברה העליזה הזאת על כתפי, הייתי מריע בקול אדיר
        וחזק, והייתי בוקע שמים מרוב שירה ועליצות".</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >למחרת ההלולא והחנגא, כשהלכתי לבקר בבתי מכירי, פגשתי את הזקן המחשב קצין
        והכותב ספר בעל כרכים רבים ע"ד שיבת ציון.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יומם ולילה הוא יושב בעליתו הקטנה וכותב. ומאמין הוא, כי ספרו יביא לידי
        "שנוי ערכין" ביהדות הישנה ויועיל לשרש מתוך לבות האדוקים את הקנאות, שנגדה
        הוא נלחם גם בע"פ עם "אבות" המושבה מראשית הוסדה. אך מצטער הוא על זה,
        ששפתו איננה שפת סופרים, ומתחנן הוא לפני הצעירים, שיקחו את ספרו ויעתיקו
        אותו, בכדי שיהיה *דבר השוה לכל נפש"... אבל שומע אין לו, לזקן המסכן, מאת
        הצעירים, והוא שחוח תחת סבל הדאגה, שמא תלך לאבוד עבודה של עשרות שנים,
        שבלעה את כל חייה של נשמה ערטילאית זו...</span
      >
    </p>
    <ul class="c11 lst-kix_list_3-0 start">
      <li class="c7 li-bullet-0" dir="rtl">
        <span class="c0"
          >אולי, זקני, אוכל אנכי להעתיק אח ספרך ‭ — ?‬ שאלתיו, למען בא אתו
          בדברים.
        </span>
      </li>
    </ul>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והוא הסביר לי פנים, ששמחה פנימית נגלתה פתאום עליהם, ואנכי השגתי את
        מטרתי. הוא ספר לי הכל, מה שענין אותי בזקן החביב ואוריגנלי הזה.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >‭ ‬- ... רוצה את לדעת, איך באתי לידי הכרת שיבת ציון, ואיך נוכחתי לדעת,
        שרבנינו, אלה הכלואים בד&#39; אמות של הלכה והרוצים להחניק את כל העולם ואת
        כל האומה בשלשלת האין סופית של אותיות יבשות ומתות, שהם מעכבים את הגאולה?
        ‭ —‬הנני ואספר לך !
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובעיני הזקן, הכחולות ממש כצבע השמים שעל ראשנו, ועל פניו המלאי קמטים
        והמלאי  רֹך ועדינות נראה חיוך של נוער.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >… גם אנכי, בתי, תעיתי בדרכי החיים. גם אני התפללתי ליום, אשר בו יבא עני רוכב על חמור, יתקע בשופר גדול ויקבץ את נדחי ישראל. הרביתי תפלות, סגופים וצומות עד… עד אשר נפקחו עיני וראיתי והכרתי, כי לא זו הדרך!…
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והזקן מתנשא על במתי עב של פ&#39;ר&#39;ד&#39;ס, חדושי הלכות וקטעים מספר
        יצירה משמשים אצלו בערבוביא‭... כבני מרון עוברים על אזני ואינם חודרים
        לתוך הכרתי מאמרי זהר המתחלפים חיש עם חקירות מ"מורה נבוכים"... ומבלי יכלת
        להדביק אחריהם, הנני נמשכת מבלי משים אחרי נגינת הפסנתר הבוקעת ועולה מאחד
        הבתים הסמוכים.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >‭ ..."‬ששים רבוא, לכל הפחות‭, ששים רבוא מבני עשרים ומעלה הננו צריכים
        להיות פה, לרכש אדמה ולעבד אותה, - ‬ורק אז יופיע משיח צדקנו, יגאל את עמו
        האמלל, את ירושלים יבנה ואת המקדש יקים‭."‬ </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אני פקחתי את עיני והסתכלתי לתוך עיני-הנוער של הזקן היושב לפני, הוא חייך
        וחזר על דבריו ליתר תקף:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"> "...ששים רבוא, לכל הפחות, ששים רבוא‭..."!‬ </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אני נעניתי לו בראשי לאות הסכמה, אעפ"י שמעולם לא עלה על דעתי, כי לידי
        מסקנא ריאלית כזו אפשר לפעמים לבא גם מתוך "עיון" מסבך כזה‭...‬ </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לפנות ערב נלויתי לחבורה של צעירים וצעירות, שהלכה לראות את שקיעת החמה
        מאחת הגבעות הקרובות למושבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        > אחרנו מעט לבא אל ראש הגבעה, אף כי רצנו בכל כחותינו במעלה ההר. ובטפסנו
        על סכת הדוד ג&#39;, הנוטר פה, ראינו רק חצי עגול אדם על אפק השמים בקצה
        מערבי צפוני, שהולך ושוקע מטה מטה, עד שנעלם כלו מהעינים.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ונגדך אתה רואה את המושבה, המשתרעת בעמק, באותו החן המיוחד, שחננו המושבות
        העבריות בא"י, וסביב מחבקים אותה גבעותיה הנטועות כרמים - כרמי גפן, וזית
        ושקד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עוד לא הסתבכו דליות הגפן על כל הצדדים, ונכרות השורות הישרות של הנטיעות,
        וביניהן בולטת פה ושם האדמה החרושה, הישרה, בצבעיה השונים צבע אדום, אדמת
        החמרה, צבע חום אחריה, והנה אדמה לבנה כצבע החולות הנראים שם במערב.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >על כל גבעה, מתנוססת סכת נוטר, ואת כל הגבעות סוגרים מצפון שני היקבים של
        רחבות, הנראים כשתי מצודות, גדולה וקטנה. ואחרי המצודות רחוק, רחוק לצפון
        מכירים את בית הפקידות של ראשון לציון, והמושבה ההיא עצמה כבר עטופה בטלית
        של בין השמשות.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובמזרח סוגרים את הפונורמה רכסי הרי יהודה מכוסי ערפל, הרי יהודה שכל
        תעופת עין עליהם, מביאה זרם חם לתוך הנפש.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ופה למטה על הכרם, יושבת חברתנו העליזה עם הדוד ג&#39;, זה הפועל
        הזקן-הצעיר, החביב כל כך על צעירי המושבה. הוא יושב ומבשל לו תה לארוחת
        הערב בקומקום שחור מכוסה בספל עתיק. שובר הדוד זמורות, ושם מקל אחרי מקל על
        האח הבוער.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">והחברה מתלוצצת אתו, מתפלספת ומבדחת את דעתו. </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אנכי עם הפועל ע, עודנו יושבים בסכת הדוד ומרשים לנו להתבונן בספריו,
        המפזרים פה. מצאנו אצלו ספרי מדע וחקירה בחמש לשונות: עברית, ערבית, רוסית,
        צרפתית וגרמנית, גם מלונים אחדים בשפות האלה. ונירות ומחברות כתובות מכל
        עברים מונחים פה על הרצפה.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">ולומד הדוד, ונוטר, וכותב כל הימים וכל הלילות. </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה קטנה ובורגנית הייתי בעיני, בראותי את הדוד הגדול יושב יחף וקרוע בגדים
        על הארץ ומבשל לו את המים !
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >
        הדוד שותק ע"פ רוב, וכשהוא מדבר, מדבר הוא בנחת ובעומק הבנתו את החיים. רק עיניו אז מתנוצצות. – מה משתקף בעינים השחורות, העמוקות האלה?‬
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >
        בערב נכנסתי לבית הפועלת ל. – נשמת הפועלים והצעירות ברחבות. שם מצאתי פועלים אחדים מהיותר אנטלגנטים, את האורח הצעיר ט. ואת חברתי ד.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >פה התוכחו על ענינים שונים: על תחית עם ישראל והשפה הלאומית, על מצב הפועל
        העברי ותעודתו, על הספרות העברית ועל סופריה ומשורריה.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כלם דברו. זה בנחת, זה בצעקות משונות, רק ל. לא לקחה חלק בוכוחים: לה יש
        עבודה תמיד. הנה עליה לגמר, אחרי עבודת היום, את חשבנות אגודתה, שהיא עומדת
        בראשה. אחרי כן היא נותנת תה לחבר אחד, מכינה סלט לשני, מציעה מטה בחדר
        הסמוך לשלישי. לכלם היא דואגת, לכלם חפצה להביא עזרה ותועלת.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכשנפרדתי ממנה ושאלתיה, מדוע איננה מבקרת בבית קרובי, ומדוע לא חפצה לטיל
        אתנו לפנות ערב, ענתה לי קצת בהתול וקצת ברצינות: ‭
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >-‬ חיי האדם עוברים מהר, וכשיבא יום ונסכם את פעולותינו, ייצר לנו, כשנמצא
        את הסך־הכל כה קטן, בלתי חשוב. ולפיכך צריכים אנו לעבוד הרבה ולדאוג הרבה
         לסך-הכל הזה‭...</span
      >
    </p>
    <p class="c2 text-left" dir="rtl">
      <span class="c0">נ.פוחצבסקי.</span>
    </p>    
  </body>
</html>`;

export default page;
