const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c5" dir="rtl">
      <span class="c0"
        >זהבה שטינברג מתכוננת זה כמה לדבר עם גיסה בגלוי ובמנוחה על המצב ועל
        הצעתה.  אבל היא נוכחה לדעת,  שהדבר הזה הוא למעלה מכחותיה, – לבה מתחיל
        דופק בחזקה וכעין רעד פנימי מורגש באבריה בשעה שהיא אומרת לגשת אל
        הענין.  אי-אפשר לדבר אתו, אבל גם קשה מאד להיות עד-רְאִיָה אִלם לכל
        העריצות והחטא הנעשה כאן. </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אם יעלה בידה לכונן במשק החקלאי את חלקה לחוד ולהסתדר באיזה צריף בחצר, אז
        תסבול אחותה מחוסר עזרה בבית, לעומת זאת יוקל לה, שעין בוחנת לא תחדור אל
        כל סבוכי החיים העוברים עליה במשפחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולעין יש כאן מה לראות.  הנה אחד המחזות, המערער את עצבי זהבה עד היסוד:
        שבת.  אחרי שגיסה ואחותה בלו כל היום בחבוקים ולטיפות, פרץ ביניהם פתאום
        סכסוך בשל זוג נעליו שלכלכן בזבל האורוה, ואשתו בקשה ממנו להחליפן באחרות
        בצאתו אל הטיול.  דרישה זו הרגיזה אותו וסרב למלאותה.  אז לא רצתה ללכת
        אתו, והוא התרגז, בעט ברגליו וצעק, שאם איננו מוצא חן בעיניה, יכולה היא
        לצאת תיכף מן הבית.  אין לו צורך במושלים על ראשו...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הילדים נפחדו מן הצעקות והתחילו לבכות.  האם הנרגשה לחצה את שני הפעוטות
        אל לבה; מלים לא מצאה, רק פרצה בצחוק היסטרי, הרי זו תשובתה על כל עלבונות
        חייה, – או צחוק, או בכי.  אין בטוי, אין פעולה, אין נקמה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >יודעת זהבה, שאחותה תרטיב בלילה את כרה בדמעות חמות, ובבוקר שוב תטפל בו
        בהכנעה רבה, תכין לו את ארוחתו המיוחדת ותגיש לו מכל טוב.  הרי בשבילו היא
        בוחרת את הביצים הטריות והגדולות ביותר, בשבילו היא משיגה לחם לבן וגבינה
        טובה, ואם מעט שמנת בבית, איש לא יטעם ממנה זולתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האינו לועג לה, הוא הבעל? האינו חושב, שהיא רוצה לפיסו כדי שלא יגרשנה מן
        הבית? היא התמימה חושבת, שבהתנהגות זו תעיר חרטה בלבו.  לא חדרה לתוך
        תכונתו להכירו, שהוא שַיָך לסוג אותם האנשים, שאינם יודעים חרטה לעולם.  הם
        צודקים בעיניהם תמיד, ואם אלף פעם יצערו את זולתם וישפכו את דמו, לא יתעורר
        בהם המצפון ליַסְרָם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אחרי כל מחזה כזה, רועדת זהבה מכעס וחורקת שנים.  למי משתיהן דרוש
        בְּרוֹם, לה או לאחותה? מי מהן סובלת יותר? אפשר שהיא בנוכחותה מוסיפה צער
        לאחותה, ובלעדיה היה לה נוח וקל יותר לסבול הכל – עין זרה לא תשורנה, לא
        תסתכל בחיי משפחתה, בכל נבכי אהבתה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והיא חוזרת בכאב לב על המלה "זרה".  בעיני גיסה היא בודאי נחשבת זרה,
        ואולי הוא גם מדבר על לב אשתו, כי האחות בבית אך מכשול היא לשלות חייהם,
        בהיותה אדם רע, גורמת להם צער, מנצלת ומקלקלת.  לעתים נדמה לה שאזנה קולטת
        מעין זה, ואולי משהו מזה מתקבל על לב אחותה.  הצועד בתוך הבוץ אי-אפשר שלא
        ידבק ברגליו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא מפריעה כאן, מיותרת, בזה אין שום ספק.  אבל האם עליה ללכת מפה, והן זה
        ביתה, אשר ידיה בנוהו מן המסד עד הטפחות, ורק אחרי-כן הכניסה מרצונה את
        הגיס? והוא הוא שגלה את אזנה, כי דין ישראל  מדיר מנכסים את האשה, שנשארה
        אחרי מות בעלה בלא ולדות וכל הירושה עוברת לאחיו.  אין שכלה משיג כדבר הזה,
        אבל יכול להיות – הכל אפשר בחיים.  הנה מי פלל, כי בעלה, שהכניס מרץ בלי
        גבול לבנות את הבית הזה ולבסס את המשק, ילך לעולמו בלא עת, ואחיו, אשר
        ממרחקים בא ולא יגע ולא עמל – יבוא, יראה ויקח הכל? גם את מרים אחותה, את
        האדם הרך הנפלא הזה רכש לו, וגם על הבית, שהוכנס לתוכו כלאחר-יד, נעשה
        שליט.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מעשיה בכלל אינם מוצאים חן בעיני הגיס ולועג הוא לצדקנות שבה.  יש אולי על
        מה ללעוג – כי מה נותנת היא לבני-אדם? מה יש בידה לתת?  רק מעט כח וזמן ותו
        לא – יותר אין לה כלום.  וגיסה אינו מרוצה כשהיא עושה דבר מה בשביל אחרים,
        שהרי כולה שייכת להם, כל זמנה להם הוא, וכשהיא הולכת מן הבית, הוא מביט על
        זאת בעיני זעם.  עמידתה ברשות עצמה מרגיזה אותו.  אבל היא הולכת מן הבית
        בהכרה גמורה, שקודם יצאה ידי חובה בבית.  הרי עבדה עבורם יום תמים, כעת
        מותר לה לעשות לנפשה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לפעמים היא עוסקת בערב אחד בדברים שונים: לפלוני צריך להשיג מלוה, לשני
        עבודה.  לילד אחד צריך להשיג כניסה לבית-הספר חנם אין כסף, ולילדה – שרות
        באיזה בית.  והנה אשה צעירה אחת, שנשארה אלמנה, ירדה מנכסיה והלכה לשרת,
        מנוצלת על ידי בעלת-הבית ובקשה את התערבותה והגנתה – יש צורך לחקור גם ענין
        זה ולעזור במה שאפשר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ממקום למקום מתהלכת זהבה.  פעם היא מצליחה ופעם איננה מצליחה, אבל דמעות
        ואנחות היא סופגת למדי.  לעתים היא שבה מסבוביה שבורה ורצוצה ונפשה ריקה, –
        כל פעולתה לטובת יחידים נראית לה דלה ופעוטה, והיא שואפת לעבודה יותר רחבה,
        שתגע בגבולי הכלל.  הלא ישנם ענינים ישוביים, ששם דרוש כחה המאורגן של האשה
        – האם אי אפשר לעשות זאת?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >במחה של זהבה שטינברג נוצרות תכניות שונות, איך לפנות אל הנשים לשם ארגון
        ופעולה.  מתעורר בה רצון כביר לצאת מתוך גבולותיה הצרים, לחפש ולמצוא את
        הכחות הדרושים לפעולה צבורית.  אבל כאן היא נזכרת באחותה, הזקוקה לעזרתה,
        בחובות הבית והמשק ובתפקידה לשמור על הקַיָם, על זה שביסודו מונחת נפש אדם
        אהוב, שהלך בלא עתו מכל מה שיצר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכשהיא שבה לעבודת המשק במסירת נפש, נדמה לה, שהיא ממלאה תפקיד חשוב
        בחובות הישוב, וגם סבורה היא, שאם כל מתישב יעשה את כל המוטל עליו באמונה
        ויצליח בעבודתו הפרטית, הרי הדבר הזה יביא תועלת לכלל ולישוב כולו.  בעלה
        המנוח הניח יסוד למשק היפה הזה, היא עזרה על ידו לבססו ועתה היא ממשיכה את
        פעלו, האם אינה יוצאת ידי חובתה בבנין הארץ?</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– עצלנית! – אומרת זהבה שטינברג לנפשה בשעה שהיא מובילה את הפרות אל
        העדר.  הן בבית מחכות הרבה עבודות והיא לאטה הולכת לה ומסתכלת מסביב.  הנה
        שורת עצים בקצה השדרה, עצים מכל המינים והם משרים צללים ארוכים על
        הככר.  לב הפרות גם הוא נמשך אחרי הצללים וזהבה צריכה לזרזן בלכתן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בדרך חזרה נזכרה, שעליה להכנס לגבאי בית הכנסת-אורחים ולברר מקרה שקרה
        אותה אמש עם איש זר, שלא מצא מקום ללון במושבה.  הדבר היה כל-כך
        מוזר.  בשובה בשעה מאוחרת הביתה ובראותה איזה איש הולך לקראתה, לא עלה כלל
        על דעתה, שהוא יפנה אליה בדברים, אך הוא עמד פתאום נגדה ושאל:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אולי הגברת יודעת מקום, שאוכל ללון שם מבלי שאצטרך לשלם?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא ענתה לו מיד שבמושבה ישנו בית הכנסת-אורחים, והראתה לו את הדרך שמה,
        אבל האיש ענה לה שכבר היה שם ולא מצא מקום פנוי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אם כן יפנה מר לועד הפועלים – שם בקצה הרחוב.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל ההלך המוזר ענה, שגם שם הוא היה ומקום לא מצא לו.  אז חשבה רגע
        ותשלחהו ליושבת-הראש של "אחדות הנשים", כי זו תשיג לו מקום לינה ותלך לה
        הלאה לצד ביתה, אבל מיד חזרה ובקשה לקרוא לנודד ולהזמינהו לביתם, אך
        התאפקה, – מה תגיד אחותה, מה יגיד גיסה, אם בשעה מאוחרת זו תכניס הביתה
        אדם, שאינם מכירים אותו? אם תצא איזו אי-נעימות מזה, הלא אך לצחוק תהיה
        בעיני הבריות! ובכל-זאת הלכה אחריו בצעדים אטיים ותטכס עצה בלבה, איך לסדר
        את הענין ובינתים נעלם האיש מעיניה כליל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בשכבה הרגישה מוסר-כליות רב, – מי יודע אם הנודד לא יוכרח ללון בחוץ?
        והיא, המכונה "צדקנית", מנעה מאיש מסכן עזרה פעוטה לתת לו פנה באחד מחדרי
        ביתה הרבים.  והיא עצמה היתה פעם גֵרָה בארץ נכריה.  כשלמדה בחוץ-לארץ וגרה
        בבית קרוביה, אירע לה פעם איזו אי-נעימות עמהם, ועזבה בערב אחד את הבית
        לחפש לה מקום-לינה במלון.  היא היתה תמימה במדה כזו, שלא הבינה מה צפוי לה
        מצעד כזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">בעלותה במדרגות הביט בה בעל המלון בחשד ואמר:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– לא, גברת, אצלי אין מקום כזה...</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מקום כזה! מה יכלה להיות כונתו? ודאי יצאנית היא, ולא רצה להטיל צל על
        טהרת מלונו.  ואולי לא היתה מלובשת באֶלֶגנטיות ועל-כן לא התאימה
        לו...  היא לא יכלה לסלוח לעצמה את הנסיון הטפשי הזה, להופיע פתאום במלון
        בלילה, כשהוא כולו מואר באור החשמל והאורחים מסביב לשלחן צוחקים ועולזים
        ומקשקשים בכלי-אוכל יקרים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לוא היה האיש שפגשה הערב לבוש יפה, לא היתה שולחת אותו ממקום למקום עד
        שיוכרח ללון בחוץ.  איך לא רחמה על איש בודד – הלא זה חטא אשר לא
        יכופר...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ככה אכל אותה בלילה מוסר-כליותיה, אבל עתה בצאתה מן השדרה אל הרחוב הרועש
        מאנשים ועגלות היוצאים לעבודה, התעוררה בה לפתע פתאום המחשבה: אולי לא לינה
        בקש האיש, אלא חפש עוד דבר מה בראותו אשה צעירה מתבודדת ברחוב?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובכל-זאת, בהתקרבה לבית הגבאי, נכנסה והגישה תלונה על זה, שהֵלֶך זר הוכרח
        ללון במושבה ברחוב.  והגבאי הבטיח לחקור את הדבר ולהזהיר את המפקח, שלא
        יקרה עוד כזאת.  מלאה שליחות והדבר כבר היה לצחוק בעיניה, אחרי החשד שעלה
        קודם בלבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מחשבתה על היחס שבין אשה וגבר מעירה אותה להתבונן בהם תמיד בפגישתם.  הנה
        אשה צעירה, עניה מרודה, עומדת על דלת בית-המרפא עם ילדה חולה ומתחננת שיתנו
        לה רפואה חנם.  בינתים הופיע גם צעיר בדלת – הוא האב – ועיני האשה הוצתו
        באור של אושר.  מחבקת היא את ילדתה ונפשה משתוקקת להלחץ אליו. את מי משניהם
        היא אוהבת יותר?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא עוברת על יד תחנת האוֹטוֹ. מחכים כאן נוסעים מכל המינים.  הנה אשה
        ירושלמית, ראשה מכוסה היטב לבל תראה אף שערה אחת.  כן, עוד ישנן שכונות
        בירושלים, אשר הנשים נזהרות בהן בשער ובכל אלה החוקים והדינים, שמסרו להן
        אמותיהן.  אבל כמה התרחק מזה הדור כולו.  הנה באותה התחנה מחכה אשה צעירה
        אחרת, בת עיר, שזהבה מכירה אותה וארחות-חייה מזכירים לה תמיד את אננה
        קרנינה.  גברת יפה זו מקסימה אותה תמיד והרבה פעמים היא מהרהרת בה ובגבורה
        של טולסטוי.  היתפתחו גם אצלנו כל אותם כַּוָנֵי האהבה וסכסוכיה ויחדרו
        לתוך שדרות שונות?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה נזכרה ביחסה לסוקולסקי, יחסים ידידותיים, ישרים, צנועים –  אבל איך
        יקראו לכל זה בני-אדם זרים? אגב, היא נזכרת בקרע הקטן שעמד להתהוות ביניהם,
        – הוא לא בקר במשך שבועים את ביתם מסבה של שטות: הוא רצה להגיש לה מתנה
        ליום הולדתה והיא התנגדה לכך בכל תוקף. הוא כעס מאד, אבל סוף-סוף התפייס
        ובא, ובשובם לדבר בנושא זה בקש ממנה באור לדבר.  היא הסבירה לו
        בפשטות:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– עלי מכבידות מתנות, כי בעד כל מתנה, שהוגשה לי פעם בחיים, אני מרגישה את
        עצמי בעלת-חוב לכל יָמי.  ואני צריכה תמיד לחפש דרך לסלקו, וברבית, מפני
        רוב הזמן, – למה לי העול הזה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סוקולסקי התוכח אתה הרבה על שאלה זו.  בעיקר הדגיש, שבין ידידים לא צריך
        שיהיה יחס כזה.  הנה היא עושה בשבילי כל כך הרבה, האם אין לו רשות
        לזַכּוֹתה במשהו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אני עושה בשבילו? – ענתה בתמהון – מה אני עושה?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– השעות הספורות, שאני מבלה אתה בשיחה, נותנות לי אושר, שאינני מוצא כמוהו
        בשום פנה אחרת בחיים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הדברים נגעו עד לבה, אבל היא השתדלה להסתיר את מבוכתה ואמרה בקלות:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– הנה גם מר סוקולסקי יודע לפזֵר מחמאות.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– האם מחמאות הן דברים היוצאים מעומק לבו של אדם בודד כמוני?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנימה הנפשית שבדבריו גרמה לה, שגם היא תדבר אליו דברים נפשיים.  הנה
        אחותה מתהלכת חורת ועצובה בימים האחרונים, ומכאיב לה הדבר מאד, שמא היא
        גרמה לכך; הלא על הרוב יקרה ככה, שהיא גורמת צער לאחרים, זהו מזלה, שבכל
        מקום שהיא אומרת להתערב, רק תקלה היא מביאה.  הפעם אמנם לא קרה כלום, הכל
        התנהג כדרכו לפני החגים, רק העבודה היתה למעלה מן הכחות, – כשֵדים
        עבדו.  אולי העיפות היתרה היא היא שמַשְרָה עצבות על מרים.  איך שהוא והדבר
        כה מכאיב, כה מדכא...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– איזה עבודות ישנן לפני החגים? – שאל סוקולסקי בתמימות.  והיא בארה
        לו:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– היה סיוד וכביסה, שפשוף ורחיצה וגהיצה, ושוב  נקיון ושוב צחצוח.  ועצם
        החג מה הוא נותן לאשה? כל הימים היא מבלה במטבח – בשול וטגון, צליה
        ואפיה. אין אשה יודעת משמחת החג ואינה נהנית ממנו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל מצדה חטא הוא להתאונן.  בביתם עובדים עבודה קשה, אבל לחמם ניתן
        ומימיהם נאמנים.  מה רבים הם עתה האנשים בארץ המחפשים עבודה, – תהיה קשה
        מאד, ובלבד שתתן להם לחם צר ומים לחץ, ואינם מוצאים.  בביתם יש מכל טוב,
        ואם היא מעיזה להתאונן, הלא רק חטא הוא.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואחרי-כן התביישה על הפטפוטים האלה באזניו.  האם זה יכול לענין אדם כמותו?
        הלא רק דברים קטני-ערך הם אלה – למה היה לה להשמיע כל זה באזניו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מבטו הטוב ולחיצת-ידו החמה לא הועילו להרגיע את רוחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ישנן שתי נפשות, שבטפלה בהן זולפים קוי-אור לתוך נשמתה העיפה, – אלה הם
        זוג הילדים הנחמד של אחותה. בחברתם נחה נפשה וכל כך טוב לה.  היא רוקדת
        ושרה אתם, משתובבת כילדה פותה ובודה להם ספורים.  הילדה דורשת את זאת ושמה
        אזנה כאפרכסת כשהדודה מספרת, והקטן גם הוא כאילו מבין דבר, פוקח עיניו
        ומביט ישר לתוך פיה, אשר משם מתגלגלות מלים רבות וזרות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– את מי משני הפעוטות אני אוהבת יותר? – שואלת זהבה לפעמים את עצמה, אבל
        תשובה אין, כי קשה לנתח אהבה כזו, שאין לה גבול.  הכל מעיר בה שובבות של
        שמחה: שערותיהם הסרוקות, פניהם הצחים, העינים המאירות וגם אלו השִנים
        הלבנות הזעירות – כמה הן מלבבות אותה. לקטן רק שני זוגות שנים, לא יותר –
        ואיזה זרם של חום היא חשה בראותה אותן. והרגלים העגולות והגוף הערום שלהם!
        היא כמשתגעת בכסותה בנשיקות את גופם הערום.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובמצב נפש כזה, בעברה על יד שלחנה, היא מתעכבת על יד תמונת בעלה המנוח,
        מסתכלת בעיניו הנפלאות, ונדמה לה, שהנה חי הוא עומד לפניה ומלטף אותה
        בצחוקו הרווי טל נשמתו הטהורה; באויר תלוי צליל הד קולו החנוק במקצת והיא
        עומדת ורועדת מכליון-נפש.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אל החלון הפתוח מתפרצים ריחות בשמים של הגשם הראשון.  בגן הושקו כל הצמחים
        ודמעות גיל מטפטפות מעליהם הרוויים, רק הפרחים התכוצו – קר להם. בגן הירקות
        נוהרות המשתלות, כל עלה בצבעו הבריא.  זהבה חושבת, שאחרי הגשם הזה יוכלו
        לשתול את הבצלים ואת הסלק, אחרי-כן יזרעו תפוחי-אדמה.  התרד יגדל בערוגה
        ואפשר יהיה להשתמש בו לבשול.  בבית מדברים כבר על חרישת הכרמים – רק יגמרו
        לקשר את הגפנים ו"אפור" יצא לעבודת החורף שלו.  הוא חרוץ בחרישה, הסוס
        הנאמן שלהם, נוהר ישר והתלמים עוברים זה ליד זה כקוים מסומנים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >השקיפה השמש בחלון, הראתה צחוק רך ונעים ומיד הסתתרה בענן.  רגע עמדו
        העצים בלי-נוע, כאילו הם כפותים, והנה עברה רוח פזיזה ויתנועעו וירעדו בגיל
        לקראת נטפי גשם חדשים.  רק בענק שבחצר לא נכרת שום תנועה.  רם וחסון גדל זה
        אצל בור הזבל ושרשיו מוצאים מזון למכביר וכן כבש את כל החצר בצל
        ענפיו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בהתגבר הגשם סוגרת זהבה את החלונות ונגשת לטליאת לבני הילדים.  היא חושבת
        על הטפול בהם, ונדמה לה, שהיא אינה מסוגלה לכך ביותר.  הנה אורה&#39;לי לא
        חפצה לטעום היום מאומה, והיא נסתה לשדלה בספור אחד, באיזו בדותא
        משונה. הילדה אמנם אכלה, אבל היא הרגישה מוסר-כליות על השקר שיצא
        מפיה.  בעיניה הנבונות של הילדה ניכר היה אי האמון בדבריה, היא תפשה את
        השקר, אף-על-פי שלא הביעה כלום.  התחיל מוסר כליות אוכל את זהבה: למה מרמים
        את הקטנים במעשיות-הבאי ולפעמים בהבטחות, שאין מקימים אותן ועל-ידי כך
        מרגילים את הילדים ללכת בדרך נלוזה ונוטעים את שורש הקלקול בנפשותיהם
        הרכות?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >חוץ מחוסר הידיעה בחנוך תינוקות טופלת זהבה על עצמה עוד עוונות וחטאים: כל
        מחלה, כל פגע רע בבית, היא זוקפת על חשבון עצמה.  אם נחלשה האחות, אם קרה
        מה לאחד הילדים, מיד היא חושבת שהיא אשמה בכך, כי לא שמרה עליהם כל צרכה או
        לא טפלה בהם כראוי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רק בדבר אחד אינה יכולה להאשים את עצמה: באסונה, באבדתה! כאן עשתה הכל
        ולהציל לא יכלה.  איך קרה הדבר? גבור, חזק, בריא – ודלקת-הריאות גדעה בשבעה
        ימים את החיים העשירים האלה. היא נושכת את ידיה, הלב דופק והיא רועדת
        כולה.</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה חלתה במחלת הצואר וכשהרופא אמר לה, שאין זו מחלה מדבקת, חדלה לרפאות
        את המחלה ותקוה נשקפה לה, כי מכאב זה יתהוה דבר, אשר אולי יביא בכנפיו את
        הפדות.  הלא היא בעצמה על-פי השקפותיה לא תפתור שאלה זו.  דעתה היא, שאסור
        לנו כאן ללכת בדרך של הפקרות.  אם אבוד עצמו לדעת הוא חטא שאין לו כפרה בכל
        העולם, בישובנו הרי זה חטא כפול, – כלפי האדם וכלפי העם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >במחלתה זו יש לה איזה פתח-תקוה – אולי יתהוה סרטן בצוארה! היא יודעת, שזהו
        מות אטי וקשה מאד, אבל הוא בא סוף-סוף, המות בטוח.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מפני מחלתה אינה משתתפת בעבודת המשק והיא מתפלאה איך מתיחסים שניהם, אחותה
        וגיסה, בשויון-נפש לכל העזובה הרבה הנכרת בחצרם.  גיסה היה לפנים בעל-בית
        טוב, היה משגיח על הכל, ועתה גדֵלה יום-יום העזובה ואינו שם לב.  רק היא
        סובלת מן העזובה שבמשק, מתפקעת ממש, אך להעיר על משהו אסור, הוא תיכף
        מתלהב, כועס וצועק:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אין זה עסקך, – אלף פעמים אמרתי לך לא להתערב בעניני.  – והיא באותה שעה
        מביטה וחושבת: לא, לא זה! רוצה הוא לקרוא: "צאי מכאן, מנוולת, ואם לאו את
        ראשך אשבור!" אבל הוא רק זורק ושובר איזה כלי ודי לו בזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >משוגע! הנה שולח הוא את אשתו אל השכנים לשאול מהם איזה כלי-עבודה החסר
        לו.  בינתים לא הספיקה לו הסבלנות לחכות, או אפשר שמצא את הנחוץ לו והתחיל
        לקרוא לה חזרה ולצעוק למה הלכה, למה היא מפטפטת שם? וככה לכל אורך הרחוב
        הוא הולך וגוער בה ומעליבה. וכך הדבר תמיד: בשעה שיש לו עבודה דחוקה, צריכה
        קודם כל לסבול אחותה. ראשית מעשה – קיתון של שופכין על מרים, בלי זה
        אי-אפשר לגשת לשום עבודה פחות או יותר קשה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האם הוצקה אחותה מברזל או מחומר מוצק אחר? ואיך אפשר לבלי החנק בתוך האדים
        הרעים, שחייהם נתונים בהם? הנה גיסה אמר איזה דבר, ומיד מחויבים להוציאו
        לפועל, אם גם לא נכון הדבר ויכול להביא הפסד וסבל, אבל הוא אמר – ומוכרחים
        לקיֵם – ולא, יסדר להכעיס את הענינים ככה, שלא ישכחוהו ימים רבים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כמה קשה לה להמצא אתו בכפיפה אחת! יש שבבוקר היא שומעת את קול הילדים
        בצחקם בחדר-המטות, לבה מתאוה להכנס שמה, לראות את פניהם, לנשקם בעיניהם
        הטהורות; הנה כבר עומדת היא לפתוח את הדלת, אבל בזכרה שהוא נמצא שם היא
        נרתעת לאחור.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אחותה יוצאת בינתים מן החדר חורת מאד, וזהבה מחפשת את הסבה: האם הילדים
        הפריעו את מנוחת-לילהּ, או בעלה הציק לה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >באה תופרת לעבוד בבית.  אחותה גזרה והכינה את הנחוץ.  בשעת מעשה שרה,
        ופתאום פנתה לתופרת ושאלה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– החושבת את, שפרה, כי אדם שר רק כטוב לבו עליו?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הגברת לפי מצבה בודאי שרה מתוך ספוק, האם יתכן אחרת?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">השאלה היתה לא לפי רוחה.  אבל אחותה ענתה בגלוי לב:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– יש אשר לכאורה לא יחסר לאדם מאומה, ובלבו בכל-זאת מקנן העצב, ואותו הוא
        מבקש להפיג בשירה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– ועל לבי אף לא עלתה מימי המחשבה, כי מרוב טובה יֵצַר לאדם – אמרה הנערה
        כשעיניה כבושות בתפירה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה מתפלאה, שהנה אחותה יכולה להיות גלוית-לב אפילו בפני נערה זרה, ומדוע
        בפניה היא כה סגורה ומכונסה? אבל הנה היא פונה גם אליה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– זהבה, את סובלת בצואר ואינך הולכת לרופא.  לכי היום ויבדקך היטב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">אבל היא סרבה:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אין פנאי, אין פנאי! זה יעבור מאליו.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התרגזה אחותה ולזהבה הכאיב הדבר. למה היא כל-כך רעה וגורמת צער לאחרים?
        היא כמעט נכנעה ורצתה כבר ללכת לרופא, אבל באותה שעה באו לקרוא לה לחֲבֵרָה
        חולה והיא מהרה ללכת שמה.  מצאה את חברתה חלשה מאד, ובקושי הוציאה מלים
        ספורות:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– למה לא באת קודם? – ועיני החולה הביטו בה מתוך יגון עמוק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לא יכולתי, לאה חביבה.  מחויבת הייתי לגמור את עניני הבית: הלא יודעת
        את, שאצלנו ילדים קטנים, והם דורשים טפול רב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  ואני כה חכיתי לך – אמרה החולה בסגרה את עיניה, ודמעה התגלגלה מבין
        עפעפיה המהודקות.  והנה נדמה לה לזהבה, ששפתי חברתה דובבות חרש.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >–  מה את אומרת, לאה&#39;לי? – והיא התכופפה להאזין למלים שנאמרו
        בלחש:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– קודם עוד יכולתי לדבר...</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אל תתני ליאוש שישלוט עליך, בבקשה.  אנחנו עוד נשוחח יחד על כל ענינינו
        ונסדרם על הצד היותר טוב.  הנה הרופא אמר לי, שאין לך שום מחלה רצינית,
        והוא מקוה כי בעוד ימים אחדים תבריאי לגמרי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– חיוך מר נראה על פני החולה. זהבה לטפה את שערותיה הרכות, נשקה לה על
        מצחה, החליקה על לחיה והתפלאה על הרגש המוזר, שקנן בלבה: אין לנוד ללאה
        ההולכת למות, כי הן המות יפדה אותה מכל צרותיה, מכל חייה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– וכשבאה זהבה ביום המחרת, מצאה אותה יושבת במטה, אוכלת את מנתה בתאבון
        ומצטחקת אליה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– חשבת, זהבה, שאני הולכת כבר למות.  – מענין, מה היה יחסך לצעד
        הזה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– יחס פילוסופי.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– כיצד? – שאלה לאה בהתענינות.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– החיים והמות קשורים זה בזה בהכרח, ואין להמנע בדפוק השעה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– נכון מאד.  אבל אני עוד רוצה לחיות – הצרות המרובות חִשלו את הרצון הזה
        להמשיך את החיים ולדעת מה עוד צפון בעתידם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה שתקה וחשבה בלבה: מאין נובע בגוף החלש הזה רצון כה כביר? היא הקשיבה
        להמית הרוח העולה מן הים ומתערב בשיחת העצים הסמוכים לחלון – איזו נגינת
        הֲוָיָה נפלאה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >נשבה הרוח על פני החולה והיא נרדמה.  נדמה לזהבה, שחורון מות
        כִּסָּהּ. היא נבהלה, התכופפה לשמוע את נשימתה, וכשנוכחה, שהכל כשורה, כסתה
        בזהירות את פני החולה בצעיף ויצאה בלאט.</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אחותה נכנסה למטבח בעינים אדומות מבכי.  זהבה חותרת למצוא את הסבה, מבלי
        לשאול אצל אחותה דבר.  סברה זו, או סברה אחרת – מה ההבדל? המסכנה סובלת,
        ואשמה היא, היא האחות הגדולה. כשבא גיסה מחוץ לארץ הוכו שתיהן בסנורים:
        דברן יפה, בר-אורין ומשכיל, יודע את החיים ואת הספרות, – מי יכול לדעת איזו
        נפש שפלה מקננת בו? היא ובעלה המנוח החליטו יחד, שיצחק הוא בן-זוג מתאים
        למרים, ושמחו מאד שהוא מצא חן בעיני הנערה.  איזו שגיאה נוראה! אבל עתה אין
        עוד לתקן כלום בחייה העלובים.  היא נדונה למאסר עולם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה רבה אכזריותו על כל צעד ושעל – הפרוטות, שהוא נותן להוצאות הבית ספורות
        הן, ותמיד הוא דורש ממנה דין וחשבון, שמא תוציא פרוטה למותרות.  אם היא
        מבקשת לפעמים שיעזור לה בטפול הילדים, אין לו אף פעם זמן לכך.  הנה לפני
        רגע היה לו פנאי להשתטח על הספה ולקרוא עתון, וכשהיא מבקשת ממנו איזה דבר,
        שהיא בעצמה אינה יכולה לעשותו בשעה זו מחוסר פנאי או מחוסר כח, אין לו זמן,
        והוא צריך ללכת, כי מחכים לו.  לפני בקשתה לא חכה לו שום דבר, וכעת עליו
        להזדרז וללכת.  ותמיד כך, – רק מלה אחת של בקשה, וכבר אין לו פנאי, עסוק
        הוא. גם בשעה שאחד הילדים הוא חולה וצריך לקרוא לרופא, פונה הוא לעניניו
        ולא איכפת לו...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מכל הליכותיו אלה של גיסה, נמתחים עצביה במדה כזאת, שהיא מוכרחה להשתמש
        בבְּרום, אם רוצה היא להמנע מאיזה מעשה-שטות, אשר יוכל לזעזע את כל הבנין
        הרעוע של חייהם.  הנה בלבה מחלחל הרצון לעשות את השערוריה, לקום ולברוח מן
        הבית הזה, שאין יותר כח להמשיך בו את הסבל המוסרי הנורא של אחותה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והיא, האם כדאי לה להמשיך את התפקיד של משרתת, שפחה, – כי מה שתיהן לו, גם
        היא גם אחותה, אֵם ילדיו, האם יותר משפחות?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >טוב לאמן שמתה בעתה, מבלי לראות באחרית בנותיה.  – גאה היתה האם, שמרה על
        כבודה ולא נתנה לרמוס עליה ברגל גאוה, והנה הבנות – איזו שפלות-רוח שוכנת
        בתוכן! הרי היא עצמה, בכל בחילתה לגיסה, בכל כעסה עליו – משמשת לו עזר על
        כל צעד ושעל – עוזרת ברפת, בחצר, בכרם.  ובבית היא מגישה לו כל דבר, כאילו
        זוהי חובתה.  רק לפעמים רחוקות, כשלבה רע עליה עד מאד, היא מתאפקת ואינה
        ממהרת לשרתו.  בזה היא מוצאת איזה ספוק, אבל גיסה נקם ינקם בעד כל אי
        שימת-לב קלה אליו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנה באו אליהם אורחים, לנו ובבוקר מהרו לנסוע, וצריך היה להכין להם את
        ארוחת הבוקר במהירות לבל יאחרו את הרכבת.  מסבה זו נתנה לגיסה פחות שימת-לב
        מן הרגיל, והוא התנפח מיד וידקור אותה בדברים.  היא צחקה ושמחה בלבה, כי
        סבלה גאותו מעט.  ואף-על-פי-כן, כשנסעו האורחים ואחותה היתה מטפלת בילדים,
        מהרה היא להגיש לו את מאכליו האהובים ותהי בעיניה כפתַיה. </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הבית הזה השפיל את כבודה, – האם היתה נשארת בו אלמלא אהבתה את הילדים
        והרחמים על אחותה המסכנה? הילדים גם הם קשורים בה, ובשעה שהאם עסוקה ואין
        לה פנאי לטפל בהם – כרוכים הם אחרי הדודה.  "דודה!" – תנעם לאזנה קריאה זו
        כשמן המור.  כשהיא רוחצת אותם באמבטי – כל אבר שלהם מזיל מתיקות לתוך
        נפשה.  ועיניהם, ברק עיניהם הבהירות, הרי כל פצע שבלבה הן מרפאות.  כשהיא
        מנשקת אותם היא צריכה לסגור את העינים מרוב מתיקות וכל מלות החבה שיש
        באוצרה אינן מספיקות בשביל הפעוטות בשעה זו.  איך עולה על דעתה לעזוב את
        הבית הזה עם הילדים האלה? לא, היא לא תעשה כזאת, תעבוד הלאה, תסבול הלאה,
        היא מלומדה בנסיונות, יודעת להרכין ראש ולכפוף גב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מאשימים אותה תכופות בפעולות בלתי הגיוניות.  מכאיב לה הדבר, אבל את הצדק
        היא נותנת למאשימיה.  כי באמת יקרנה לפעמים שהיא רוצה לעשות טובה ויוצאת
        רעה.  סבת אי-הצלחה זו אינה יודעת, כשרון מיוחד הוא, – לקלקל.  והרי כונתה
        תמיד רצויה, אלא שבמוחה ודאי ישנו איזה פגם, המטה אותה מן הדרך הישר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גם זה אינו מן ההגיון, שאינה מביעה שום מחאה ורק לבה דופק בחזקה בשעה שהיא
        רואה את שגעונותיו של גיסה ביחס למשפחה.  איך היא יכולה לשתוק בשעה שהוא
        מעליב ומשפיל את הרגש האנושי; רע, הכל רע בעיניו, שום דבר אינו נעשה
        כראוי.  מה עוד צריכה אחותה לעשות? – האם נשאר בה זיק אחד של אהבת עצמה?
        האם היא חיה לעצמה? הלא חוץ מן הילדים אין לה כלום בעולם בלעדיו, ולו היא
        נותנת את זמנה, כחה – וגם את מחה.  כי מה נשאר לה מכל התפתחותה
        הרוחנית?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומאין נובע יחסו השפל, התנועות והמלים המעליבות עד לידי הרתחת הדם? תמיד
        יש רצון למחות, לענות, אבל המלה נעצרת על השפתים, ורק הלב מתחיל דופק בחזקה
        עד להתפלץ.  אין מפלט מכאן אלא בקבר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומה כאן? האם חייה קשים? רק חרפת החיים היא היא המדכאה, המשפילה.  והיא
        הגורמת.  בזויה היא בעיניה מאד.  ביחס כזה לעצמה, אין שום אדם חושד בה.  גם
        סוקולסקי רחוק מלדעת זאת, אף על פי שהיא גלוית-לב בפניו ומספרת לו לפעמים
        על הרבה מחסרונותיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אמנם אדם זה רואה את היחסים בבית ואולי גם סובל מזה, אבל כלום חושד הוא,
        שיותר משמבזים אותה אחרים, היא מבזה ומשפילה את עצמה? בני אדם עלולים
        לטעות, גם הוא טועה בחשבו אותה לחכמה, מיושבת ומתיחסת לעצמה בכבוד.  גם תמה
        היא וצנועה לפי דעתו – אוי לה לצניעות שכזו! המסוה שעל פניה מרמה את
        הזר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל האמנם זר הוא לה? הלא הוא האדם היחידי, שהיא מרגישה קרובה נפשית
        אליו.  הוא לא רצה לרמותה, ומזמן גלה את אזנה, שלהתחתן אסור לו – מפני
        מחלתו. בדברו כך גלויות, היתה היא נבוכה, התאדמה, כבשה פניה בקרקע, כי קשה
        לה לדבר על נשואין.  נדמה לה, שלא היתה נשואה כלל, רק אהוב היה לה, שישב
        בלבה ימים רבים ופתאום פרח לו משם, ונשאר בלב חלל ריק ומכאיב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה היה לה אמש? האם היתה זו היסטריה או קפריסה, מחלה או עקשנות? – גיסה
        העליב לא אותה, אלא את ידידה, את סוקולסקי ולבה דפק בהתרגשות, כשנפרדה
        ממנו.  היא נכנסה לחדרה ונפלה על המשכב, כי אמרה, בשכיבה ישתתק הלב, אבל
        שכבה שעה, שעתים והמועקה לא עברה.  הנה שם בבית מתכוננים לארוחת-הערב,
        הילדים בוכים שניהם, דרושה שם עזרתה והיא כבול-עץ לא זזה, נדמה לה, שרותקה
        אל המטה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לבה נוקפה והיא רוצה לקום ולעזור בבית, אבל אינה יכולה – הגוף כבד
        כעופרת.  בחדרים שואלים עליה: "היכן זהבה? לאן הלכה זהבה?" והיא שוכבת מבלי
        להניד אבר.  פתאום שומעת היא את קול אורה&#39;לי, המבטאה: "דודה זהבה",
        ודמעות התפרצו מעיניה.  טוב כך, עתה יוקל לה.  הדמעות מקילות עליה
        תמיד... אבל זרם אחרי זרם יורד מעיניה ולא הוקל לה, רק שב האומץ לקום,
        לרחוץ את הפנים ולהכנס לחדרים.  שם הכירו בה מיד את עקבות הדמעות.  הגיס
        ודאי צחק בלבו על "דמעות נשים", אחותה הצטערה, אבל איש לא שאלהּ
        כלום.  בבית כבר שרר השקט. הילדים ישנו, והיא נגשה לעבודותיה, כרגיל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היוכל עוד סוקולסקי לשוב לביתם ולבקרה, אחרי שהעליבו אותו? – שאלה את
        נפשה.  והיא מאין ימָצא לה העוז לשאת בכל הסבל הזה?</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c7 text-center" dir="rtl"><span class="c6">ב</span></p>
    <p class="c1" dir="rtl" id="h.gjdgxs">
      <span class="c0"
        >היא חולה ומה טוב לה בחליה! שוכבת היא ומתפנקת, קוראת וחושבת.  כמה פנאי
        יש לה לחשוב! כל עֲבָרָה חולף לפניה.  יותר מדי אהבה, אהבה עד
        לשגעון.  הצמאון להט וגבר תמיד.  כל העולם כלל בשבילה אך אהבה, והיא היתה
        צמאה תמיד.  האם היתה זו תשוקה לילדים, שהטבע מנע ממנה, או פשוט מטבעה היתה
        חולת אהבה, תאונית</span
      ><span class="c10"
        ><a
          class="c8"
          href="https://www.google.com/url?q=http://benyehuda.org/poxa/004.html#_ftn1&sa=D&source=editors&ust=1696092785358528&usg=AOvVaw1TAgK8_3tOIhnYHtfhAUix"
          >[1]</a
        ></span
      ><span class="c0"
        >? עתה בזכרה כל זאת, היא מתביישת מפני זכרון בעלה המת.  לו נשמתו הטהורה
        היתה משוטטת עתה בעולם ורואה אותה אחרת, שאינה דורשת עוד כלום בשביל עצמה,
        היה עומד ומשתומם על התמורה שחלה בה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אולי המחלה שתקפתה והנתוח הקשה היו לה לתועלת, – אחריהם כבתה שלהבת האהבה,
        שלהטה בקרבה בגיל מוקדם מאד. מה טוב לה עכשיו שהיא יכולה לאהוב בשקט, בלי
        זעזועים, כמו שהיא אוהבת את סוקולסקי! לו היתה חולת אהבה חושנית כמו בימים
        ההם – מה גדול היה סבלה! בעיני סוקולסקי הטובות היא יכולה להביט בשקט
        ובמנוחה; ובשעה שהוא אוחז את ידה הקרה בשתי ידיו החמות, השעירות, אין שום
        רטט בגופה, רק חם לה ונעים, כאילו היתה נתונה בזרועות אב רחום, המלטף את
        בתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סוקולסקי המסכן! הוא חולה וצריך לנסוע לחוץ-לארץ לעשות לו נתוח, אבל
        בית-החרושת עוצר בו.  אם יסע יסָגר בית-החרושת ועשרות פועלים ישארו בלי פת
        לחם.  זהו הקושי שבדבר הנסיעה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מהלך מחשבות זה נפסק בהופיע אחותה בחדר.  טוב לה בחליה.  הנה מרים נכנסת
        אליה, מציעה לה את המטה ומסדרת את החדר, מכניסה לה טס מלא מאכלים. כמה
        צעירים וטובים פני מרים עכשיו, תענוג להביט.  הן משוחחות ביניהן בידידות
        וכל כך טוב לה כשמרים יושבת על ידה והיא יכולה ליהנות מזיו עיניה הטובות
        ומבת-צחוקה הנעימה.  והנה הגיס מופיע בדלת.  חשבה זהבה, שגם הוא רוצה להכנס
        לבקרה, אבל טעות היתה זו, – הוא בא לקרוא למרים, כי קשה לו לסבול, כשאשתו
        שוהה יותר מדי בחדר אחותה ומשוחחת אתה.  איזו קנאה עורת מבצבצת ממנו בשעה
        זו; "תפטפטי אחרי-כן!" – אומר הוא לה, או "הרי אין לך פנאי עכשיו, ולמה את
        עומדת כאן?"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >נאנחת זהבה אנחה עמוקה ומבקשת להסיח את דעתה ממחשבות התוגה.  היא פותחת את
        חבילת העתונים שאחותה הביאה לה, מניחה אותם זה על גבי זה לפי הסדר ומתחילה
        בקריאה.  בדף הראשון הודעה על חתונה, והיא מהרהרת מתוך חיוך: בשעה ששני
        אנשים הולכים לקשר את חייהם, האם בנין עתידם מגמתם? הלא רק ספוק האהבה הוא
        משאת נפשם והלאה מזה אינם רואים כלום.  רק אצל מעטים תופס בנין הקן את
        המקום הראשון, אבל אצל הרוב אין זה מובא בחשבון מתחילה, ובא רק אחרי-כן,
        כשפג שכרון האהבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הטלגרמות שבעתון אינן מענינות אותה, מענינים המאמרים, וביותר אלה שמפיצים
        אור על העבודה ועל הנעשה בין הפועלים, – בהם היא תולה את תקוותיה בבנין
        הארץ, הם הם בסיס התחיה: חַיִל ורוח! וכשגמרה את הקריאה בעתונים היא נוטלת
        את ספרה – כרך גדול של איבסן, אשר היה מונח אצלה חדשים שלמים ולא יכלה
        לגמרו עד שתקפה אותה מחלה קלה זו.  היא עוברת על ההקדמה זו הפעם השניה
        ומתפלאה על המתרגמת, שהצליחה להבליט בהערכתה כל-כך הרבה אהבה ביחס
        לפיטן. היא הופכת את הדפים בתוך הספר ועומדת על "הבנאי סולנס".  בתכונתו של
        זה היא מוצאת קוים דומים לאלה, שהיא עצמה סובלת מהם.  – הנה סולנס סובר
        שהוא חולה-רוח, אבל המחבר אינו מודה בזה ומוצא בגבורו רק מצפון חולה, הגורם
        לחרטה ומוסר-כליות, שאינם נותנים לו מנוחה.  איבסן הצליח לגמור את חיי
        גבורו, טרם הורד משלבי גדולתו, אלמלא כך היה מוסר-הכליות אוכלו ומביא עליו
        מות אטי. מצפון חולה ומוסר-כליות תדירי – אוי לו לאדם שהטבע חלק לו מנות
        אלה! היא מדפדפת ומוצאת את "ברנד" – את זה יש לה חשק לקרוא עוד פעם.  גם על
        "נורה" היא צריכה לעבור שנית.  על זו רוצה היא לדבר עם אחותה, – לאשה
        מחוסרת מרץ, יש מה ללמוד מנורה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה מחזיקה את ספרה סגור בין אצבעותיה, מסתכלת בלובן חדרה ונהנית
        ממנו.  בשני החלונות רואים עצים מתנועעים.  נדמה לה, שיער גדול של עצים גדל
        שם, ואחרי היער אין כלום.  במחשבתה נשאה ליער אחר, שם היה בנוי בית
        אביה. שם פרחו העצים באביב ופרחו גם נעוריה. דאגה לא ידעה אז, רק אהבה ידעה
        – כל-כך הקדימה לאהוב וכה מהר נגמרו חשבונותיה אתה.  עכשו אין עוד משא
        האהבה עליה והיא חפשיה לעצמה, חפשיה לכל פעולה הנדרשת מצדה, עתה לא יכבד
        ממנה כל קרבן אשר ידרש לצבור.  האם הבית הזה צריך לאחזה בכבליו? הרי רק
        חולשה היא מצדה, שהיא יושבת כאן.  היא צריכה היתה לתבוע את שלה, למסור את
        חלקה לקרן-הקימת, ובעצמה להכנס לאיזה מוסד ולהביא תועלת לרבים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גיסה מעז לפעמים לרמוז שאין לה כאן כלום – שטות! האם אותו הרכוש, שהיא
        ובעלה קנו אותו בזיעה ובדם עלומיהם וצרפו אליו מרצונם הטוב את הגיס, לאחר
        שהכל היה כבר מבוסס כאן, – אפשר לשלול ממנה לגמרי את הזכות עליו? הוא טוען
        שהכל שלו ומתפאר שהוא יודע דינים, דיני ירושה, שהכל עובר אליו על-פי
        החוק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא אינה נותנת אמון בדבריו.  עוד אינה חושבת להשפט, אבל בבוא הזמן, –
        המשפט יהיה על צדה.  – האם מישהו יכול להכחיש את מה שהיא הכניסה למשק הזה?
        ואי החוק אשר יוכל לשלול מאדם את זכותו על יצירת כפיו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אמנם היא אינה צריכה הרבה, אבל להדיר אותה מנכסיה – היה לא תהיה! האם אין
        דין ואין דַין בארץ?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >...אבל להשפט אתו, זאת אומרת להוסיף צער ורעל לחיי מרים,  מרוסינקה! לא,
        זה אי-אפשר, אי-אפשר! והשם "מרוסינקה" משתרבב בין שפתיה ויורד עמוק אל
        לבה. במלת-חבה זו היה משתמש אותו האדם, שהתאהב באחותה עד לשגעון, אלא שהאם,
        עליה השלום, הפריעה בעד האהבה הזאת. הטעמים היו נכונים, אבל אחותה סבלה אחר
        כך ימים רבים.  ומה עתה – האם הוקל הסבל? שתקנית היא וסבלנית מרים שלה, אבל
        הסבלנות מספיקה רק לו ולא לאחרים, קרובים ואהובים...  אך חימה אין לה על
        אחותה המסכנה, המבקשת להקל את סבלה בדרך של אורך-אפים בלי גבול.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אחותה רק לעתים רחוקות מרשה לעצמה להביע איזו מלה של התמרמרות כלפי בעלה,
        ואם מלה זו נאמרת בחריפות קצת, אין נפשו העדינה סובלת אותה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אם כך, אני מיד נוסע מפה! – אומר הוא בכעס וקם ללכת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >פני מרים נעשים חורים והיא מתחילה לחפש תחבולות לפייסו.  עוד לא למדה מדת
        זהירות שלמה ושוכחת לעתים, שרק סבל ושתיקה הם מנת חלקה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רצון כביר יש לה לזהבה לזרוק לו בפניו את המלה: "נבזה!" – ומתאפקת
        בקושי.  הנה התימניה הקטנה שלהם רצתה לנקות גם את נעלי "הגברת זהבה", בין
        הנעלים האחרות, שנקתה בערב שבת.  הוא ראה זה וימלמל דברי כעס, והיא לקחה את
        נעליה מיד הקטנה וצחצחתן בעצמה. בוקר בוקר הוא מתנפל על כל השכנים, שבאים
        אל החצר לבקש מעט מים מן החבית שתחת הברז.  לו יכלה לזרוק בפניו מלה יחידה
        זו: "נבזה!" כמה היה זה מקל ללבבה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עבודת החורף בשדות התחילה לא בהצלחה מרובה, וזהבה יודעת שאין להאשים כאן
        את גיסה, רק את סדרי הטבע שנשתנו השנה.  עוד לפני חג הסוכות הוכנו זריעות
        למספוא וחשבו, שתיכף אחרי הגשם הראשון יזרעו את חלקות הבור, אבל הגשמים
        אחרו לבוא, וכשבאו ירדו בשפע, עד כי שבועים לא יכלו לגשת אל השדה מרוב
        רטיבות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לבסוף כשכבר אפשר היה לגשת לעבודה, הוזמנו עוד שני סוסים ל"אפור" שלהם,
        כדי להספיק ביום אחד לחרוש ולזרוע.  אור לאותו יום הכינו הכל בעגלה, את
        המחרשות ואת הכלים, גם חבית מים וכל הדרוש עוד, חוץ מן הזריעות שעמדו
        מוכנות בשקים במחסן, וצוו על העגלון לשכב בשעה מוקדמת כדי שיקום עם עמוד
        השחר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל בחצות הלילה הקיצו לקול דפיקות על תריסי החלונות, כי התחוללה סערה,
        ובבוקר כבר היה כל הצפון מכוסה עננים שחורים.  התחילו להסתכל בשמים
        ולהתיעץ, אם אפשר לצאת לעבודת השדה או לא? העגלון התנגד, הראה על סימנים של
        גשם, אבל לא האמינו לו, והסוס נרתם, לוקחו הזריעות וגיסה נסע עם העגלון
        לעבודה.  אבל לא עברה שעה קלה ומטר סוחף  נתך והם שבו הביתה ספוגי
        מים. היתה גדולה הדאגה לזריעות, ליבשן שלא תתקלקלנה.  אם יפרשו אותן במחסן
        – העכברים יאכלו אותן, במטבח – צר המקום,  בחדר האכילה – הילדים
        יפזרון. בלב גיסה עלה בינתים הקצף וכל העולם אשם לו באי הצלחתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        > הוא רעב, כי ארוחת-הבוקר שלו עודנה מונחת בסל כי לא הספיק לאכלה.  יש לו
        אפוא הרשות לכעוס.  זהבה ממהרת לערוך את השלחן ולחמם את הביצים ואת החלב
        ואת הקהוה, רק שכחה להגיש מלח לשלחן.  וגם זה גרם להתאוננות.  הוא נכנס
        לחדר-המטות, במקום שמרים היתה עסוקה בילדים, והתאונן שם על אחותה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה שמעה רק קטעי דברים, אבל הבינה שחטא גדול חטאה.  היא שתקה, כששב
        לשלחן  ומצא את המלח.  היא התרגלה בשתיקה.  וכי מה יש לענות? היא צריכה או
        לצעוק או לברוח הרחק מכאן, וכיון שאינה יכולה לא זה ולא זה, הריהי כופפת
        ראשה תחת המהלומות.  לעתים עולה בדעתה מחשבה מוזרה, כי תבוא פעם חרטה בלב
        האדם הזה על כל העול שהוא עושה, ותאכל אותו עד כלות הנפש, אבל עד אז – איך
        לסבול את הרשעות ואת הנצול?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובנצול – הרי אשמות הן שתיהן, היא ואחותה.  בזה אין שום ספק.  הן יכלו
        להגן על כבודן ולא לתת לרמוס אותו ברגל גאוה.  עליהן רק להיות יותר חזקות
        ברוחן. אמנם היא, כאחות גדולה, אשמה יותר בכל השפלות הזאת, היא צריכה היתה
        לאחוז בהגה ולהתוות את דרך-הכבוד לאשה. אבל היא הרכינה ראשה תחילה, לא
        התנגדה לרע, ואחותה לא כל שכן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מרים סובלת כל כך – ומי אשם?  שוב היא, האחות הגדולה, שהילדה היתה תמיד
        מתחשבת עם דעתה.  היו רבים שבקשו לשאתה, והיא, האחראית לגורל הנערה, היתה
        מתיחסת לכולם בזהירות ודוחה את ההצעה, מפני איזה פגם – היא חפשה בשביל
        אחותה אדם מושלם בכל המעלות וטרם מצאה אותו.  בא הגיס מחוץ-לארץ, השכלתו
        עִוְרָה את עיניה, ולא ארכו הימים – וההסכם נתן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כל האשם הוא בה, ועל-כן עליה לכפר עתה על עוונה כלפי האחות ולעזור לה להקל
        מצבה.  למטרה זו היא לוקחת עליה את העבודה במטבח, ברפת ובלול.  אחותה
        מתפלאה על הסבלנות שיש לה לכל אלה. והגיס מביט בלעג על כל ההתמסרות הזאת,
        אבל היא אינה נסוגה אחור, עמלה וממציאה לבית מתוצרת הלול והרפת ככל האפשר
        יותר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא אוהבת את יצורי רפתה.  "מִלְכָּה" המבכירה, שעומדת להמליט, נצלה ממות
        הודות לטפולה.  בעצם ידיה גִדלה אותה, ובראותה עתה ב"מלכה" את הסימנים של
        לידה קרובה, היא מצטערת קצת: הלא כה צעירה הנה, טרם מלאו לה שלש שנים ובעוד
        שבועות מספר עליה להיות לאם.  הן לכשתלד תהיה עמוסה דאגת בר-בטנה, – בודאי
        תמלט אז מן השדה באמצע היום, כדי להעניק לו מטוב חלבה, – ככה נוהגת אמה,
        ככה תעשה גם היא.  כאמה כבתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לבה פתוח להתמסרות ולחבה ומרגישה היא כמיהה ורחמים להרבה יצורים אשר
        סביבה.  אבל סובלת היא בחשבה, שהיא איננה אהובה (אם להוציא את
        סוקולסקי).  גם בילדים היא חושדת שאינם קשורים בה במדה  שהיתה רוצה
        בכך.  הלא ילדיהם מוכשרים לאהוב, אך ביחסם אליה הם חוסכים את אהבתם ומלטפים
        ומחבקים אותה רק בשעה שאמם הלכה מן הבית והם זקוקים לדודה ביותר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >  אולי באמת איננה טובה כל צרכה, כמו שאומר עליה גיסה? דעתו היא, שהיא
        אינה מתמסרת להם במדה מספיקה והיא אחות רעה.  בשמעה דעה כזו מחשבתה מתערפלת
        ואינה יכולה להשיג, מה עוד עליה לעשות עבורם ואינה עושה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה צחוק אחותה והילדים משיב לה את רוחה, והיא הוגה תודה לאלהים, שאחותה
        מאושרה בפעוטותיה והם שמחים בה.</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה עסוקה בסדור איזה ענין בשביל אנשים, שאינה צריכה להכיר להם תודה כלל,
        אנשים שהתנהגו עמה שלא כשורה, אבל היא עושה לפנים משורת הדין, והולכת למשרד
        אחד להשתדל שם לטובתם.  בדרך היא בכל-זאת מחטטת בלבה, שמא הנקמה דוחפת אותה
        לעשות מעשה זה כדי לשלם טובה תחת הרעה שעשו לה: – פגעו בכבודה, דברו בה
        סרה.  מאין בא רגש נעים זה, שהיא הולכת להשתדל לשם הקלת מצבם? האם לא ממקור
        מכוער הוא בא ולא נקמה היא זו, נקמה בצורה נאה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל זהו דרכה תמיד – לחטט ולמצוא בעצמה פשעים וחטאים.  – סוקולסקי טוען
        כלפי קו זה שבאופיה, והדבר מרגיז אותו מאד בשמעו מפיה, איך כל פגע, כל תקלה
        או חסרון – היא תולה בעצמה ובאשמתה. בשמעה את מוסרו היא עונה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מובן, שאנשים האוהבים את עצמם אינם רואים את חסרונותיהם,  אבל אני, שחיי
        אינם תלויים </span
      ><span class="c3">ברצון</span><span class="c0"> החיים, אלא </span
      ><span class="c3">בחובת</span
      ><span class="c0"
        > החיים, – אני איני יכולה לעצום את עיני מלראות את מגרעותי.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והוא מתרעם  עוד יותר ומוכיח לה, שאם אדם אוהב את האחרים עליו לאהוב גם את
        עצמו מעט.  מובן שאינו מדבר על החכמים, שרק לטובתם הם עמלים וכל חשבונותיהם
        ערוכים לפי הרֶוַח שלהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האם איש רע הוא סוקולסקי, האומר להחזירה למוטב? הלא הוא עצמו עושה כל-כך
        הרבה בשביל אחרים ואינו מרגיש בזה ותובע ממנה מעט אהבה לעצמה, שתעשה מעט
        מזעיר גם בשבילה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשהם משלימים ביניהם בטיולם ויושבים על קורה עבה בשדרת עצי האיקליפטוס,
        מתענגים שניהם על מראה הים המכסיף לעיניהם ומצטחק לאור הירח. סמוכים הם
        בגבם לאיקליפטוס עבה אחד; הוא אוחז את ידה הקרה בשתי ידיו החמות, מתכופף
        הצדה ומביט ישר אל תוך עיניה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מה מאושר אני, שידידה טובה כמוך הזמין לי המקום, אבל תמיד חרד אני שמא
        אאבד אותך.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– למה הוא חושב ככה?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– על זה יש לדבר הרבה, אבל קודם כל עליך להגיד לי, מדוע תדברי אלי
        בנסתר?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– איני יכולה אחרת, ככה הורגלתי.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אם כן, את אינך אוהבת אותי.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הכל תלוי באיזה מובן.  אם הוא מדבר על אהבת אשה לגבר, אז אין מקום לענות
        על זה.  אבל אם מדבר הוא  על אהבת איש לרעהו, לעמיתו, – אז בלי פקפוק אוכל
        לענות בחיוב.  – אבל מיד באמרה זאת התחרטה, כי נדמה לה שמן הספר הוציאה
        דברים, – הלא "נורה" דברה מעין זה באזני בעלה.  ואיך היא חוזרת באופן
        מלאכותי על מחשבות ומבטאים של אחרים? הן רק קוֹפיוּת היא זו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מהרחוב שלמטה, ששם השכונה התימנית, עלו קולות הילולא וחינגא.  נזכרה זהבה
        שחתונת יעקב בן זכריה היום.  זכריה הזקן הוא ידידה, והיה עוד יומים קודם
        אצלה להזמין אותה לחתונה, – איזה עול עשתה שלא הלכה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– זה לא יפה מצדי, כלל לא יפה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אולי תכי על לבך "על חטא", כמו שרגילה את לעשות על כל מעשיך הרעים?
        –  אמר סוקולסקי ויתכופף אל ידה ונשק לה נשיקה ארוכה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בשבתם ככה הוא משוחח על אמו ואחותו, על למודיו בפריז, על בית החרושת
        שלו.  הוא רוצה להביא את משפחתו הנה, יש לו עתה היכולת לכך, אבל את הרשיון
        קשה להשיג, – הנה חמשה חדשים הוא מתלבט בהשגת הרשיון ואינו יודע מתי
        ישיגו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הם קמים ללכת והוא מלוה אותה עד ביתה.  חביבה עליה חברתו, אבל ראה פלא –
        פרידתו אינה קשה עליה, כשהיא עוזבת אותו ונכנסת בשער.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל בחדרה תרגיש מצוקה בלב, כאילו עומדת היא על סף הכליון.  אין לה שום
        מחלה, רק משהו כבד מעיק על הויתה. הרוצה היא בחיים או במות? הרבה פעמים היא
        מעמידה לפניה שאלה זו, אף כי ברור לה, שהיא תמשיך קיומה ותמשוך בעול
        חובותיה.</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >העבודה נעשית לה לעתים קשה מאד. בשעות הבוקר המוקדמות היא כבר מסתבכת
        בעבודה ואין לה פנאי להכנס הביתה לשתות כוס תה, גם כשהיא מרגישה את היובש
        בגרונה.  לפעמים מתוך חולשה היא שואלת את עצמה: מה יהיה כשלא תוכל לגמרי
        לעבוד? איך תסדר אז את חייה? ברי לה שכחותיה הולכים ופוחתים.  אין לה עוד
        אותה החריצות בעבודה, אין הזהירות הרבה ולא הכשרון.  הרבה מלאכות, שידעה
        בימי נעוריה, עתה כאילו נשכחו ממנה לגמרי. להרבה פנות עבודה חודרת עזובה
        ורשלנות.  זכרונה נחלש באופן מבהיל, – הנה צריכה היא לקרוא בשם איזה אדם
        ואינה יכולה לזכרו בשום אופן, ושעה ארוכה עליה לשבת עד שעולה בידה לדלות את
        השם מתוך הנשיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כן, לא תמיד נמצא בה עוד הכח לסחוב בעול.  אבל למה לה העול – האם מישהו
        גזר אותו עליה? הלא בידיה רתמה את עצמה בתוך העגלה הכבדה.  במעט תבונה יכלה
        לסדר את חייה אחרת, אבל גם במעט הזה לא חנן אותה אל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומה קשה העבודה הנעשית מתוך עבדות.  שתיהן שפחות לאדון אחד – ומר כפלים
        בעיניה גורלה של מרים, גורל אם ואשה מלא עלבונות.  הוא נח היטב ביום,
        בלילות אינו קם לעזור לה לטפל בילדים, והיא מתלבטת כל הלילות פעם עם ילד
        זה, פעם עם השני.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בשעת האכילה אין מרים יכולה לשבת במנוחה, – מצד אחד הילדים, מצד שני הבעל:
        פה חסרה לו כוס, פה דרוש עוד סכין או מזלג, החרדל לא הושם על השלחן. בעצמו
        לא יקום לקחת לו כלום, היא צריכה להגיש לו הכל.  הנה היא כבר מתישבת
        באין-אונים על הכסא, אך מיד עליה לקום שוב ולשרתו.  הוא מבין היטב את הצורך
        במנוחה, אבל המנוחה היא רק בשבילו.  אם ייעף רק במקצת, כל העולם צריך
        לדאוג, שמנוחתו לא תופרע, אבל האשה – הלא מברזל הוצקה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואף-על-פי-כן ישנם זמנים, אשר הן יושבות שקטות אצל השלחן.  זה יקרה בשעה
        שהגיס נוסע ופתאום משתרר בבית השקט.  הן עובדות יחד במנוחה ומשוחחות על
        עניניהן.  זהבה יושבת אצל אחותה, מסתכלת בשרטוטי פניה הדקים, באַפה החד,
        בריסים השחורים, המכסים על זוג עינים שחורות וטובות, ובעיקר נחות עיניה
        ומתעכבות הרבה על תלתליה השחורים כזפת.  מלמטה, מצד ערפה, השערות גזוזות,
        כאן הן ישרות, אבל למעלה ולצדדין מסתלסלים התלתלים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מרוסיה – פונה היא לאחותה בחבה – מי משח את שערותיך בזפת? – ומרים
        מתפרצת בצחוק ועונה לאחות:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– זפת, איזה יופי של שערות!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >צחוקה מתגלגל בכל החדר והוא כה נעים לזהבה.  היא היתה רוצה לראות את אחותה
        צוחקת תמיד ככה מטוב-לב. בכל-זאת היא אומרת לה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– הסי, תעירי את הילדים.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >תופרות הן שתיהן ושיחתן התגלגלה על חברה אחת, שעלתה מקרוב אל הארץ. ומרים
        שואלת את אחותה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הגידי לי, אם את יודעת – מה לה ל-Grande Dame זו ולארץ ישראל?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– זוהי אופנה חדשה, מרים.  הרי האופנה עמדה בחיי קלרה תמיד בשורה הראשונה,
        עוד אז כשלמדנו יחד בגמנסיה. אחרי-כן היה ביתה מסודר על פי האופנה האחרונה,
        – הרהיטים, הכלים, התמונות. אין לדבר על שמלות וכובעים; זה היה פולחן מיוחד
        במינו, אליל שאליו השתחוו כל מבקרי הסלוֹן  שלה.  עתה האופנה היא
        ארץ-ישראל, ומדברים עליה בסַלונים גדולים כעל נסיעה אל הריוְיֶרה,
        למשל. אבל אופנה זו ודאי תעבור, כמו שחולפת כל אופנה אחרת, ובעֲלִיָה יקחו
        חלק רק אלה, אשר ניצוץ התחיה מהבהב בלבם.  יוכל, כמובן, להיות גם אחרת:
        איזה נחשול חדש של שנאה בגולה יכול לגרוף הנה שוב אלמנטים שונים, אבל בזו
        אין ישועה לעם – צריך שהרוח הציוני יחדור לכל שדרותיו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– זוהי עבודה לדורות, חביבתי, ביובל אחד אי-אפשר לרפאות את כל נגעי העם,
        שהשתרשו בו במשך דורות רבים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולאט לאט, בזהירות ובתבונה, נגעה מרים בשאלת היחסים שבין אחותה
        וסוקולסקי.  זהבה התאדמה, אבל במנוחה גמורה גלתה את כל האמת לאחותה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אם כן מה יצא מזה? הלא הדבר רק יפריע לך לבנות לך שוב יסוד לחיי
        משפחה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– למה לי משפחה? – שואלת זהבה ומשתדלת להסתיר את התרגשותה, בפקחה זוג
        עינים גדולות – הלא יש לי מרוסיה, יש לי אורה&#39;לי ויש לי חובב, – האם
        אין לי בית?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מרים חבקה אותה מתוך דמעות: "מה טובה אַתְּ, זהבה, את טובה לי!" וזהבה
        נכנסה לחדרה ושם מחתה את דמעותיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התפרצו ענפי העץ אל החלון הפתוח. על אחד הענפים התעלס זוג סנוניות
        אהוב. בת-הזוג היתה קטנה וצנומה ונוגה, הוא – חי וזריז וקפץ כהרף-עין על
        גבה ונזקק לה, קפץ ונזקק שוב פעמים אין ספורות, וכששבעה נפשו, פרח ועזבה
        בעצבותה, כשהיא מתכוצת ומעוררת רחמים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה יצאה לחפש עשבים בשביל תרנגולותיה ותשמח כמוצא שלל רב בראותה באיזו
        פנת גדר סרפד גדול ורווי-מיץ.  מלאה את סלה ולא הסתפקה בזה, כי רצתה שימצא
        גם לפרות מה ללקק בלשונן.  ותוך כדי הרהורים על העשב, היא נושאת את לבה
        לעתיד הגשמים: הירבו בחורף זה? היביאו ברכה לשדות, לכרמים? אשתקד לא הספיקה
        האדמה לשתות לרויה, ולא היתה הברכה שורה במעשי ידיהם של האכרים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשחלקה את העשבים והתחילה לנקות את הלולים, מצאה פתאום תחת אחד מהם –
        תרנגולת שנטרפה.  מתוך צער התחילה לבדוק ולחפש את סבת המאורע, ותמצא ביצים
        אחדות מכוסות בעפר, שהתרנגולת רצתה לדגור עליהן,  על כן נשארה מחוץ ללול
        ותטָרף.  עוד אתמול ראתה אותה נכנסת מתחת, חפצה לתפסה ותתחמק מידה, וזהבה
        עזבתה לנפשה באמרה, כי בעצמה תצא מלמטה ותקפוץ לתוך הלול או שתתפוס אותה עם
        חשכה – והנה נשכח הדבר מלבה.  איזה הפסד! היא עומדת לפני ערמת הנוצות
        ושארית הכרעים ומוכיחה את עצמה על השכחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >את מאורע התרנגולת לא גלתה לאחותה, כי לא רצתה לצערה והשתדלה למחות את
        הסימנים, אבל הדבר לא עלה בידה, מפני שגיסה, שנזדמן באותה שעה בחצר, הכיר
        בשארית הנוצות, שאלה והיא לא הכחישה.  שמע זאת ורץ מיד הביתה והבשורה הרעה
        בפיו.  הוא ממהר לבשר את הרע, כי יש לו עונג מזה, לגרום צער לאחרים, ובמקרה
        זה הלא יש גם מקום לתלונות: בעלות-בית מצוינות! עוזבות הן תרנגולות הפקר
        לשנֵי הטורפים.  בחריצות כזו ילך כל המשק לאבוד!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולאחר יומים קבר הגיס בעצמו עמוק בתוך זבל הבור את קרבן הקנאה ששררה בלול
        שלהם – את התרנגול הזקן האדום-ירקרק, שהתהלך פעם בחצר כמלך בתוך הגדוד,
        ועתה הוא נרדף עד חרמה. כל הימים הכו אותו אחיו הצעירים ממנו לימים,
        עזי-הפנים, רדפוהו ופצעוהו, ויתהלך אָבֵל וכפוף-ראש לקראת אסון חייו, שהעיק
        עליו בלי-הרף.  זהבה רחמה עליו ובחפצה לפדותו מענוייו, הציעה לשחטו
        לשַבָּת,  זו היתה דרך של כבוד לתרנגול הזקן למצוא את הפדות, אבל גיסה לא
        הסכים, והמסכן סבל רעב וענויים, עד שנפח נשמתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לפעמים מתקנאה זהבה בשכנים, שבחצרם כבר מתהלכים אפרוחים, מהדוגרות שהושיבו
        בסוף אלול – כמה נחמדים אלה.  והיא חששה להושיב את דוגרותיה בעונה
        ההיא.  פחדנית! ביצים מוקדמות לא תהיינה לה השנה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בכלל גורמות לה התרנגולות עכשיו דאגות – חדלו להטיל.  מתנמנמות בפנות
        אפלות כל הימים בלי תנועה.  כשהיא מביאה להן את העשבים ומגרשתן ממחבואן, הן
        יוצאות לנקר ושבות אחרי-כן למקומן.  מצדה היא משתדלת להגיש להן כל מיני
        מזון טוב להטלה וללא הועיל. לפעמים היא מתיאשת מהן, לפעמים בראותה את כל
        עצי הגן בשלכת היא מתנחמת, שהטבע יעשה את שלו והתרנגולות תתעוררנה לחיים עם
        הנצנים הראשונים.  יעבור רק הקור הגדול ותראינה הביצים הראשונות בארגזי
        ההטלה.  הן תשובנה אז גם לחיי עבודה, – תעמודנה לחפור באדמה, לחפש רמשים
        ותפוררנה את הזבל ואת הרגבים כעודרים מצוינים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היונים מביאות יותר לידי יאוש: השובך בנוי היטב והן מעמידות ולדות גם
        בחורף.  אבל להן צפויה תמיד סכנה של אויבים טורפים ואינה יודעת תחבולה איך
        להלחם בהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שמירה מעולה דורשים גם הילדים בחורף הקר.  חובב הקטן הוא על ידיה כל היום
        וזה אינו מכביד עליה כלל; כל תנועה ומבט של הפעוט מוסך מתיקות לנפשה. כשהיא
        אוספת בחפנה את שתי כפות הידים הזעירות ולוחצת אותן אל לחיה נוטף מהן עסיס
        ריחני.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הקטן התחיל לבטא איזה מלים.  כבר מתגלגלה מפיו גם המלה "דודה", והדודה
        הטפשה קול צהלה מתפרץ מגרונה והיא רוקעת ברגליה משמחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האם כדאי לעמוד על עלבונות החיים? הרי זו שטות לבוא ולהוכיח לבן-אדם: הנה
        יחס זה, מלה זו – מרגיזים ומעליבים.  ברור שזה יביא לידי וכוח, התרגזות,
        אבוד דמים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אינה יודעת איזה רוח שטות נכנס בגיסה, לסדר דוקא בחדרה אוסף של כלי עבודה;
        לא נמצאה לו פנה מתאימה בכל הבית מפנתה היא היחידה, הנתנת לה למנוחה
        ולשינה.  היתה החלטה בלבה להגיד לו, שיוציא בבקשה את כל הדברים האלה מחדרה,
        רק חפשה במוחה את הצורה הנאותה, שלא תרגיז את רוח אחותה.  כל ההרצאה היתה
        מוכנה, אבל כשיצאה וראתה את פני אחותה החורים והעצובים, – החליטה לותר על
        דרישתה.  כל דין ודברים עם בעלה מרגיז אותה, וזהבה אומרת להתאפק ולא לתבוע
        את נוחוּתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היה ענין עם יָדית לעט.  משלש שהיו על שלחן-הכתיבה, לקחה היא אחת אל חדרה
        לכתוב מכתב.  והוא בא בתלונות מדוע לקחו דוקא את זו שהוא רגיל בה? איך
        מעיזים להכעיסו כך? מרים התוכחה אתו ואמרה, שאין כל הבדל באיזה עט משתמשים,
        והוא בשלו, כי בכונה עשו לו עוֶל זה, ובינתים הוא מזכיר חטאים אחרים שאין
        להם כפרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנעלבה זהבה? לא, היא רק הרגישה ביתר שאת את המכאוב, שגורמת לה תמיד
        רשעותם של בני-אדם, ביחוד של אלה, שהיא נמצאת במחיצתם.  הכאיב לה מאד, אבל
        יחד עם זה יכלה ללמד זכות: הלא אי-אפשר להאשים תמיד את האנשים מפני קשי-לבם
        שמורשה היא להם מאבותיהם. בלב בעלה המנוח היתה טינה על תכונות אביו הקשות
        ועל סבל האם.  גם לחובב ולאורה יהיה מה לספר לאהובי לבם בבוא שעתם.  יצחק
        שטינברג קבל השכלה, אבל לא חנוך מספיק עד כדי לעקור מן הלב מה שהשתרש בו
        מדם האבות.  הוא אינו אשם שאינו מסוגל לעשות טובה לאדם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">בקשה ממנו לתקן את מנעול דלתה ומיד התפרץ בכעס:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אין לי מה לעשות רק זה?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לבה התכוץ לשמע תשובה זו, אבל מרוצה היתה שאחותה לא שמעה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אם עושים בבית טובה למישהו, צריך הדבר להעשות בהסתר.  הנה אחותה נותנת
        לאשה חולה שתי כוסות חלב ביום.  באה ילדה עניה לקחת את החלב והוא מתחיל
        לחקור את הענין וצריך לשקר ולהבטיח, שאת החלב מוכרים בכסף.  גם כל נדבה
        מסתירים ממנו, כי יש לו מה לטעון: "הוא עמל בשביל כל הבית, הוא העובד
        היחידי המכניס הכל – וכאן מבזבזים את רכושו על ימין ועל שמאל".</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לשמוע טענות כאלה לא קל לה לזהבה.  נפשה סוערת: את הגוף נותנים ואת הנשמה
        – ואין ערך לדבר! כל העמל והדאגה להקל עליו, לחזק את יסודות המשק, כל
        ההתאמצות שלה ושל אחותה לשמור על הקיים ולהרחיבו,  – אין זה כלום.  רק הוא
        עובד ואין זולתו נוקף אצבע.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומי היא פה? קרובה עניה, האוכלת לחם חסד.  מחטים דוקרות בראשה, כשהיא
        משערת בנפשה, כי רעיון כזה יוכל לעלות במוחו של גיסה.  והיא, הגאה, שלא
        קבלה מעודה מאדם אף שוה פרוטה, היא שאינה יודעת לקבל, רק לתת, – עליה יאמרו
        שהיא אוכלת לחם חסד? היא תשתגע!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובכל-זאת יש אצלה תקופות, שאינה מרגישה שום טינא בלב, והיא מרוצה מאד שהיא
        יכולה להתרומם ולהתגבר על רגש רע אליו.  ולא עוד אלא שהיא רוצה למצוא בו
        איזה קוי-אור, באמרה אל נפשה, שאי-אפשר כי נפש האדם תהיה כולה שלילה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בחורשת עצי האיקליפטוס שמול הים, בשבתה תחת העץ העבה, משתתקת לגמרי סערת
        רוחה.  ידידה החכם ונדיב-הלב יודע תמיד להשליט שלוה בנפש.  הוא אינו יודע
        כלום מהקַטנות הכעורה שבביתם.  מבין הוא רק שהחיים בכלל הם  קשים עם אדם
        כמו יצחק שטינברג, ובפגשו אותה ופניה עצובים הוא שואל:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מה חדשות, מה קרה? למה העלו השמים עננים ורוצים להוריד גשם?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אמת, היא רוצה לבכות, אבל מתאפקת, פוקחת עיניה לרוחה ושולחת אותן למרחבי
        הים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– יראה נא – מה יפה הים עתה.  אף עננה אחת אין באופק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– "יראה נא!"...  עד מתי תדבר ככה עם ידיד נאמן?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא שוכחת  כל תלאה בשוחחה עמו, בשבתם סמוכים אל גזע העץ העבה, שענפיו
        הכבדים מתכופפים מסביב לשני המתבודדים בחורשה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה טוב לה בשבתה אתו.  אבל אחרי-כן, בשובה לחדרה, שוב נאחז הלב כמו
        בצבת.  המלים "לחם חסד", שנקבעו פעם במוחה, שוב אינן עוזבות אותה.  אולי
        צדק גיסה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שוב חלתה חברתה לאה.  גברת לזינסקי, אמה המסכנה, נופלת מרוב עבודה
        וצער.  זהבה החליטה להתמסר לטפול בחולה ולעזור במה שתוכל.  הנה ידידה
        סוקולסקי מציל כל-כך הרבה אנשים מחרפת רעב, תציל היא לפחות אדם אחד מן
        הכליון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל לא הצליח כל עמל להשיב חיים לנפש החולה והרצוצה, ולאה לזינסקי הלכה
        לעולמה בשקט ובדעה צלולה לגמרי.  טוב – אמרה לזהבה שעה אחת לפני מותה –
        המות שולח לה את הפדות, אבל צר לה על אמה ועל אחיה.  גרשון עודנו נער
        רך.  אמנם מלאו לו  שש עשרה שנה, אבל עודנו זקוק להדרכתה.  זהבה נשארה כל
        הזמן על יד מטתה ותראה בדעוך נר חייה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה לא עזבה את המשפחה כל ימי "השבעה".  מה עגום היה המראה, כשראשו של
        הנער נתמך בכתף האם, נדחק אליה, כמבקש אצלה מפלט ממחזות איומים.  כל צער
        העולם נשקף בתוך תוגת עיניהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אנשים יוצאים מן הבית ואנשים באים.  אלה יושבים בשתיקה, שקועים בהרהורי
        תוגה ועיניהם אדומות; ואלה – העוז בלבם לנגוע בעצם המאורע ולהעלות זכרונות
        מחיי הנפש שנקטפה בעודה באבה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >נלחץ הנער יותר אל האם, וכאילו נדבק בה, בהסתירו את ראשו בתוך
        זרועותיה.  באותה שעה דומה היה לילד קטן השוכב בחיק האם.  ואֵם זו – כמה
        אמיצות יש בה! היא יושבת דומם, נושכת שפתיה, ודמעה בעין לא תראה.  נוספו רק
        חריצים עמוקים במצחה וברק העינים עומם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מסתכלת זהבה במחזה וסופגת את כל עומק יגונם של אנשים השכולים מבת ומאחות
        אהובה.  חולשה תוקפת אותה והיא רוצה לבכות.  מפנה ראשה לכאן ולכאן ומוחה
        בהסתר את דמעותיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >את עומק פצעה באבדן חברתה, תרגיש ביחוד בלילות מתוך נדודי שנתה. רק נרדמה
        לשעה קלה, והנה היא מתעוררת מתוך עקת לב נוראה ואינה יכולה עוד
        להרדם.  מתהפכת מצד אל צד, ואינה יודעת איך תמלט מעצבונה.  יותר משדואב לבה
        על מות החברה, דואב הוא לאלה הנפשות השתים, שנשארו גלמודות בלי תומך
        ומפרנס, – ומה יעשו עתה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וחיי חברתה, לאה לזינסקי הקטנה, החלשה, – מה עשירים היו.  כל צעד שלה לא
        היה בלי תועלת, בלי פעולה.  עוד שם בנֵכר התמסרה לעבודת העם והמשיכה את
        עבודתה כאן באהבה ובמסירות – למה נידון אדם כזה לכליה בעודו באבו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התמסרותה למשפחת לזינסקי בימי אבלה היתה מורגשת מאד במשק.  גיסה מתנפח,
        מראה פנים זעומות, מדבר בעקיצות.  מרים שותקת,  היא מרגישה בעומק לבה את
        כאב אחותה באבדן ידידה כמו לאה.  אבל הבעל רוטן, שהנה בורחים מן הבית, אין
        ממלאים שום תפקיד...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אזניה שומעות את טענותיו והיא נושכה את שפתיה ושותקת, – עד כדי כך כבר
        אבדה את חופשתה? יש לה רצון להגיד, שהיא אדם חפשי כמוהו, שיש לה הרשות
        לעסוק בדברים שהשעה דורשת, שמותר לה להתפרק בעת הצורך מן השֵרות, שהיא
        משרתת אותו תמיד.  אבל היא בוחרת בשתיקה.</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0"> </span></p>
    <p class="c7" dir="rtl"><span class="c6">ג.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >למי משתיהן דרוש ברוֹם להשקיט את העצבים – לה או לאחותה? שואלת זהבה
        שטינברג את עצמה – ומה הוא האדם, אם מלה אחת, אשר בטאו לא בצדק ובנימה
        מעליבה – חודרת לתוך האברים, מרתיחה את הדם, הידים והרגלים נעשות כבדות,
        בגרון חונקות הדמעות ועל הלב מכביד נטל כבד? יש שכל העולם בין-רגע יחשיך
        בעיניך, אתה שומע אנשים הולכים, מדברים, צוחקים או שרים ואתה רוצה לאטום את
        אזניך, לברוח, לא לראות ולא לשמוע כלום.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >העדות הטובה ביותר עד כמה אי-נורמליים הם היחסים שבינה ובין גיסה, היא זו,
        שבהמצאם בבית אחד, תחת גג אחד, היא צריכה להשתמש בכתב לשם תביעת כבודה
        וכבוד אחותה.  במקום הדבור הפשוט, החי, שבו יבררו יחד את היחסים שביניהם,
        היא צריכה לכתוב אליו.  כמה מוזר ומעציב, כשמסתלקת המלה החיה מבני אדם,
        שהגורל הביאם לגור יחד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכשהיא רוצה לערוך לגיסה את הדברים שבכתב, היא קורעת גליון אחרי גליון
        בטרם תמצא את חפצה, אחרי-כן כל עצביה מתערערים ואינה מוצאת לה מרגוע בשום
        עבודה.  האם קשה לה להיות משרתת? קשה רק היחס המעליב, הבטול הגמור כלפי אדם
        ורגשותיו, הדריכה ברגל גסה על כל הקדוש לו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולמה היא צריכה לסבול כל זה, אך כה קשה לה להפרד מן הבית, שאמנם ידיה בנו
        אותו, אבל אינו נותן לה כלום חוץ מצער ושפיכות דם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והיא, זהבה שטינברג, הגאה כלפי אחרים, הרי סוחבת היא את משא הבית כסוס
        נאמן. ומפני שהסוס הזה בועט לפעמים באכזר, הרי גאותו הנפגעת אוכלת
        אותו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה משונים החיים, כשאנשים בעלי רגשות כאלה איש לרעהו צריכים לחיות תחת גג
        אחד, לשאוף אויר אחד, לעבוד עבודה משותפת ולהתכון למטרה אחת בהנהלת
        עניניהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התדפקה צפור על חלונה וזהבה פתחה לה אשנב.  נכנסה, הסתובבה במַשַק כנפים
        וחפצה לצאת, אבל נתקלה בחלון סגור ואת האשנב הפתוח לא מצאה. התדפקה על
        השמשה הסגורה והתלבטה ביאוש.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שטיה! אך תתרוממי מעט והדרך לחופש פתוחה לפניך.  היא פתוחה גם לך, זהבה,
        רק מעט מרץ, מעט עוז רוח!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מבית הפועלים עולים לחדרה קולות שירה.  אזניה נטויות לשמוע, נוער חי! האם
        גם היא היתה פעם צעירה, הגם לה היו עלומים רועשים? בזכרונה הכל מתערב
        יחד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >תמונת בעלה בתוך המסגרת היפה מביטה אליה כמו תמיד בעיניו הגדולות
        והנפלאות.  היא סומכת ידה על השלחן ומסתכלת בה.  היתה ילדה כמעט, כשהתודעה
        אליו, עוד את המחלקה החמישית לא גמרה והוא כבר עבר את בחינות-הגמר ועסק
        במסחר של אביו. בשבתות היה מופיע בביתם; אבא ואמא ישנו, האחות הלכה
        לחברותיה והוא היה מושיב אותה על ברכיו, מלטף את צמתה הארוכה, מטה אותה
        הצדה, ומנשק את צוארה החשוף.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מעשה ילדות.  רגש רציני לא היה עוד בלב.  רק לאחר שנים, כשהגיעה השמועה
        לאזניה, שמסובבת אותו איזו עלמה עשירה, התחילה אהבתה להתבשל ועלתה פתאום
        בכל זהרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומה תובע עתה הלב, מה הוא רוצה ממנה – האם לא יצאה ידי חובה כלפי כל אלה
        שאהבו אותה?</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גיסה ייף וישמן מיום ליום, ואחותה – כל יפיה הסתלק כבר ממנה.  היא כולה
        הקשבה, כל הויתה נתונה למלוי ספוקיו. רצונו ללכת אתה מחויב להמלאות
        בין-רגע, מבלי להתחשב, שהיא צריכה לשם זה למהר בעבודתה ולהוציא את
        נשמתה.  אבל אם היא קוראת אותו ללכת אתה, – הוא אינו ממהר כלל, יושב לו
        במנוחה, קורא את עתונו עד הגמר ונותן לה לעמוד ולחכות עד להתפקע.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשהוא צריך לנסוע לאיזה מקום – אין גבול לקריאות ולפקודות שלו: מרים, תני
        את המכנסים החדשים ואת העניבה האפורה! היכן הנעלים הצהובות? תני מהר מגבת
        עם סבון! היכן הפנקס שלי? לאן שמו את הפנקס? הכל מסתירים אצלנו.  הכל אבד
        מפני בעל הביתיות שלכן.  הביטו! כלום אין לי, אין לי כלום – איזה בית
        זה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">נמצא הכל, אך פתאום חסרה לו החזיה הכחולה.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מרים, מרים בואי הנה! אינך יכולה לעזוב  שם את העסקים הנפלאים שלך ולבוא
        אלי? נו, מהרי, שימי לי את זה במלתחה, רק אל תהפכי את הסדר! אוי ואוי, הנה
        את כבר מקלקלת לי הכל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">–  מה אני מקלקלת לך? – מעיזה מרים לשאול.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הנה היא עוד שואלת! מהרי וקני לי סגריות מן החנות, הרי כבר השעה עשר.
        אאחר את הרכבת בגללך.  – וסדר הריצות שלה טרם נגמר.  עוד מוצא הוא טענות
        ותלונות.  גם בשבתו בעגלה לנסוע אל התחנה, עדיין הוא רוטן, שלא זהו הכר
        והמושב אינו טוב.  וככה בלי-הרף, עד שהעגלון מצליף ב"אפור" והעגלה יוצאת
        במרוצה מן החצר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בנסעו הוקל ללב.  זהבה, שלקחה עליה את ההשגחה על העבודה, עוזבת את הבית
        ללכת אל הכרם.  הדרך רחוקה, היא עוברת ליד גדר של שיטה, קוטפת פרחים
        עגולים, צהבהבים ומוללת אותם בין אצבעותיה.  מה נפלא ריחם! השיטה עוד מכוסה
        בתרמילים מלאי זרעים, עוד לא השילה את הפרי הבשל – ושוב מתכוננת לקראת הדור
        הבא.  רק שיטה אחת גדולה, שלא נגעו בה במזמרה ולא גזמוה מימיה – אינה דואגת
        לקיום הדור הבא, רק לעצמה וליפיה, ולכן אין עליה תרמילים ולפרחים חדשים אין
        זכר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא ממשיכה את דרכה בשביל שבין השיטות, והנה קול עצוב של צפור מזמרת עולה
        מביניהן.  רוצה היא לראות את המזמרת, אך מחטי השיטה דקרו את ידה עד זוב
        דם.  כיצד זו, שקולה כל-כך מתוק, אינה מפחדת מפני הקוצים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בכרם אין זהבה שבעה נחת: מקמצים ומתחשבים עם כל פרוטה בהוצאות הבית, אבל
        על עבוד הכרמים מוציאים כסף לרוב, ובכל-זאת מה רבה העזובה בהם.  הם סומכים
        בכל על הערביים שלהם – הם הבעלים והם המכלכלים.  בנשיהם ובטפם הם יושבים
        בחצר והכל תחת ידיהם.  אסור אפילו לבקר את הדבר הזה, אסור להעיר כנגד המשטר
        הישן, ואם מעיזים לעשות זאת – גיסה עונה בזעם:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– צאי אַתְּ אל הכרמים, השגיחי אַתְּ! – ואולי הוא צודק בזה, כי למה היא
        צריכה להיות קשורה לכתלי הבית כל הימים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לעתים, מתוך בולמוס עבודה וריצה, היא אומרת בלבה: תינוקות ממש! כשם
        שהתינוק דורש שרות, ככה דורשים אנשים האוהבים את עצמם.  ועוד דמיון יש לה:
        הילד סובר שהכל שלו, גם הם ככה.  האם באמת תמיד הוא רע, גיסה? הלא בהרבה
        עניני-צבור הוא מביא תועלת, גם לקרוביו מתיחס הוא יחס אנושי, שולח ועוזר
        להם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אפשר באמת, שביחסיהם היא האשמה, היא הרעה, אינה נותנת את כל הנשמה, אינה
        יודעת להפקיר את עצמה. אינה יודעת איך לחיות עם אנשים, איך לדבר אתם, איך
        להכנע, להשלים, אם לא כונו את דבורם כהוגן.  לא תמיד היא יוצאת ידי חובתה
        כלפי הבית.  פונה לעניניה ושוכחת אותם שעות תמימות.  אם גיסה מתרעם יש לו
        יסוד, לא תמיד היא עוברת על מדותיה...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">מה צודק סוקולסקי באמרו לה:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– בגיל שלנו צריכים להביט בעינים גלויות ובלב שקט על כל הנעשה מסביב. מובן
        שאי-אפשר לגרש מן הלב את הרצון לראות את אהובי נפשנו על מרום פסגת האושר,
        אבל לא בידנו הדבר הזה, ולכן איננו צריכים להוציא להבל את מעט החמימות,
        שעוד השתמרה בפנים.  צריך לשחות עם הזרם ולא נגדו – אז אפשר עוד להנשא
        על-פני הגלים ולא לשקוע בתוכם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >סוקולסקי המסכן! מחלתו הולכת ומתגברת והוא צריך לנסוע לחוץ-לארץ לעשות לו
        את הנתוח, אבל בית-החרושת עוצר בעדו, – אם יסע יצטרך לסגרו ועשרות פועלים
        ישארו בלי פת לחם.  זהו הקושי המעכב אותו מנסיעה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בזמן האחרון התחילה היא לטפל בו בשעת ההתקפות, המעוררות בה רחמים
        ויאוש.  כי בראותה את הסבל הגדול הזה אי-אפשר לה להאמין בהצלה.  ביסוריו
        ניכרים עקבות המות, ובשבתה על-יד מטתו חולפות בה מחשבות נוגות: אפשר לא
        יארכו הימים ובני-אדם יספידוהו בקהל בנוסח הידוע.  "שירת חייו באמצע
        נפסקה".  וזה יהיה שקר, כי לא שירה הם חייו, אלא סבל ארוך...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואחרי-כן, כשהכל עובר, ופניו נחים על הכר חִורים, אבל שקטים, היא כופפת
        אליו את ראשה ושואלת, מה הוא רוצה עתה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– כלום איני רוצה, אחותי! – ומוסיף: הלא אחותי את! האחיות הרחמניות כולן
        כמלאכים טובים, שלוחים להקל על יסורי החולה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוא אינו מעיז לשלוח את ידיו הקרות לחבק אותה בשעה שהיא כפופה ומחייכת
        לדבריו.  וברגעים אלה אינה אומרת, שקשים החיים בלי מטרה, בלי לדעת שהיא
        נחוצה למישהו, שהיא יקרה למישהו. עולמה מלא וגדוש, כשהיא עוזבת אותו ויורדת
        אל ביתה ורואה משני הצדדים את ההרים עם נטיעותיהם, למטה – את חלקות השדה
        הקטנות, שהתבואה בהן נבטה יפה, ואחריהן את הים הצח והכחול.  באזניה עומד
        צלצול מליו החביבות: "אחותי! כמלאכים טובים שלוחות האחיות להקל את יסורי
        החולה!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">סוקולסקי הטוב, המסכן שלה!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מה היה הצחוק ההיסטרי הזה, שהיא צחקה בפנת חדרה האפל? נערך מחזה בבית –
        האם חדשה היא? חטאה מרוסיה והזמינה חברה אחת ללכת אתם להצגה, שאיזו חובבים
        ערכו במושבה.  כשרצה ללכת לקנות כרטיסים, בקשה לקחת כרטיס גם עבור החברה,
        והוא שנה מיד את דעתו ויאמר, שלא ילך הפעם להצגה, תלך לה לבדה עם מי שהיא
        רוצה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לא הלך עוד אף אחד מהם והתיאטרון היה בבית.  היו וכוחים, צער, רוגז ודמעות
        – הטרגיקומדיה התבלטה בכל חריפותה. בו, כמובן, לא פגע המאורע, שיצא ממנו
        בנצחון, והלך לבלות את הערב למקום שרצה, רק מרים העלובה נשארה עם צערה,
        עצבה ולחץ לבבה.  היא כלום לא דברה אחרי לכתו, שתקה והסתתרה בחדרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גם זהבה התכנסה לפנתה בהציק לה כעסה ורק שם התפרץ הצחוק ההיסטרי, שהשתדלה
        להחניקו בתוך הכר לבל יגיע לאזני מרים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אחרי שעה קלה והיא מנסה לפשוט את בגדיה ולשכב במטה.  העיפות מדכאה אותה
        ואינה יכולה למתוח את אבריה.  מקפלת היא את גופה הרצוץ בתוך השמיכה ומהדקת
        אותה אל ראשה.  אלי, הוי אלי, היכן נקודת-אור אחת? והיא מחפשת באפלה מלאך
        טוב, ומתחננת למנוחת רגע.  לבה לוחץ, ואינה יודעת מה לעשות כנגד לחץ זה,
        שאין לה מנוס ואין מפלט ממנו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לאזניה הגיעו אנקות חולה.  היא קפצה ממטתה ונכנסה אל מרים, שכאב ראש נורא
        תקף אותה.  זהבה נתנה לה טפות והרתיחה מים לתחבשות חמות.  הופיע הגיס, אבל
        הוא לא שאל כלום ושום עזרה לא הגיש, רק כששכב במטה, התרעם על המנורה הדולקת
        בחדר, שלא תתן לו לישון, הפך פניו אל הקיר ורטן על ההפרעה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בבוקר השתתקה כל הסערה וזהבה שומעת איך אחותה מפטפטת עם ילדיה ומצטחקת
        אתם.  אז עולה בזכרונה ילדותו של י&#39;ן כריסטוף, איך אמו הצטחקה אליו
        בבוקר והאב רגז.  – האם גם גיסה מראה שם בחדר את פניו הזועפים כאביו של
        י&#39;ן?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל ברור לה שסדרת העבדות מתחילה אצל אחותה עם קומו משנתו.  הנה לא מצא את
        בגדי העבודה שלו, והיא צריכה לעזוב את הילדים באמצע ההלבשה וללכת למצוא לו
        את הבגדים, הנעלים וגם את המגבת שלו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה צריכים להגיש לו כוס תה, ואם אשתו מבקשת ממנו למלאות בעצמו את הכוס
        הוא רוטן:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אפילו כוס תה קשה לה להגיש לי – או:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– לכל יש לה פנאי, רק למלאות לבעל כוס תה – חסר הזמן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מי מכר את אחותה לשפחה לצמיתות – האם לא היא ובעלה המנוח? איך תוכל לשאת
        עתה חטא זה כלפי נפש תמה וכשרה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >נוכחותה כאן עוד מכבידה על צערה בדעתה מה כבדה הנשימה באויר זה.  אבל לאן
        תברח? איזו פנת מנוחה תמצא בעולם, אם תתרחק מיחידתה, מנפש זו, שדרוש לה סעד
        למען לא תפול תחת משאה? אבל איך לחיות כאן עם הכעס והתמרורים שבלב? הרי לא
        רק האהבה, אלא גם השנאה מקלקלת את השורה, ובשביל לאפשר חיים הדדיים, דרוש
        לעקור את השנאה מן הלב – דרך אחרת אין.  צריך לסגור את העינים ולא לשים לב
        ולא לדקדק במעשים ובדבורים של אחרים.  צריך לשאת הכל בסבלנות – אז ממילא
        תתמעט הטינא שבלב.  כי למה לצעוק מרה, אם אין אפשרות לשנות כלום.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולמרות ההחלטה הזאת להתאזר בסבלנות, אין זהבה חדלה להעמיד לעצמה את השאלה:
        מה התועלת בכל ההשכלה של גיסה, בכל התפתחותו, אם אין לב ואין מסירות? הנה
        אחותה התלבטה כל הערב עם הילדים והוא לא נסה לעזור לה.  היה שקוע בספר ולא
        שמע או עשה את עצמו כאינו שומע את הנעשה מסביב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מרים שכבה בשעה מאוחרת, ורק נרדמה – התעורר ילד אחד, אחרי כן השני. נרגעו
        לרגע, ושוב התעוררו בבכי.  היא הולכת מילד לילד, מחממת להם חלב, משקה אותם,
        משכיבתם, שרה ומנענעת את העריסה,  זה משתתק וזה שב לבכות.  האב ישן שינה
        עמוקה, וכשהתעורר רטן על ההפרעה ושוב נרדם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה מונה בזכרונה את אלה המשפחות הצעירות ממכיריהם, ששָם הבעל עוזר לטפל
        בילדים, נותן להם את רגעי-הפנאי שלו ושומר על שנתה ומנוחתה של אם
        התינוקות.  היא מקנאה ושואלת: גורל אחותה עגום ואפל ככה – למה? היא נזהרת
        מאד מהתערב בדברים שבינו ובינה, חוץ מבמקרים יוצאים מן הכלל.  בכל אופן
        איזו חשיבות יש לה בעיניו? הלא תמיד נמצא איזה מום בה ובעבודתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכואב לבה לא רק על זה, שאין מעריכים את עבודתה, אלא על שהיא מחוסרת אונים
        לעשות את הצעד היחידי העומד לפניה – להתחלק במשק ולגור לחוד.  אבל את העוז
        הדרוש לנהל משפט עם הגיס שוללת ממנה האחות,  כי איך תרגיז את מרים, את
        מרוסיה שלה, היחידה שנשארה לה מכל משפחתה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והעבודה בינתים דוחפת אותה, תובעת מלויהּ.  אף רגע אין אפשרות לשאוף אויר
        ולחַשֵב את הדרך.  אינה עבד נכנע לחיים, – החיים אינם חשובים בעיניה, אך
        עבד היא לעבודה, שבעצם אינה נחוצה לה בצורה כזו, רק איזה גורל עור מרַתק את
        השרשרת הכופתת אותה והיא נוטה ערפה כבהמת-משא בפני העול.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האם יותר מבהמת-משא הנה? האם יש לה זכות לחוות דעה במשהו? אם היא רק מעיזה
        להתערב באיזו שאלה משאלות המשק מיד הוא כועס, לפעמים דופק על השלחן, צועק
        וטופל עליה אשמות,  שהיא מציקה להם, ממררת להם את החיים וקשה להם לסבול
        ממנה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אי-צדק ואי-יושר זה כלפי מסירותה, מעיר בה גועל-נפש ויש לה הרצון לירוק
        בפניו, אבל היא רק מגחכת גיחוך מר ולא יותר.  יודעת היא, שהוא משתמש
        בחולשתה, בהיותו בטוח, שלא תלך מכאן. פעם נסתה לנסוע ושבה כלעומת
        שהלכה. אצל זרים עוד יותר אוכל אותה היגון ומכרסם את רוחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והלא אחותה מאמינה בו, שהוא גם אדם טוב וישר, למרות זה שהוא מרגיז אותן,
        אין לה כל מושג מערמומיותו  ורוע לבבו.  האם טפשה היא אחותה? לא, אלא זוהי
        דרך האהבה – לכסות על כל הפשעים. ידיעותיה הרבות, שקנתה לה מהחיים ומהספר,
        נחלשו ונטשטשו בהפגשה עם אדם, שכבש את לבה והוא מושל בה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכדי לדבר אתו, צריכה גם אחותה להזהר.  לפעמים שואלת היא דבר, והוא לועג
        לה – "איך אמרת?" אז היא חושבת, שלא מחכמה שאלה בצורה כזו, ומשתדלת לבאר את
        דבריה.  אבל במדה שהיא נזהרת יותר, היא נכשלת יותר ומשמשת מטרה לחצי
        לעגו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אם היא מבטאה איזו שגיאה בשפה, מיד זה משמש לו בדיחה.  הוא חוזר על השגיאה
        ולועס אותה לראיה, שנשים אינן יודעות את השפה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רגש מוזר תוקף את זהבה בגלל המאורעות הקטנים האלה.  אם עלבון זה, או כעס,
        אינה יודעת, רק משהו מחניק בגרונה, אין יכולת לשכוח מה שהיא עשתה לאחותה
        בעבר, כשעזרה לה להתקשר באדם הזה. </span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשהתעוררה זהבה שטינברג משנתה בחצות הלילה, הרגישה איזו ריקנות בלב, כאילו
        נגול משם ואבד לה דבר יקר מאד. עברו איזה רגעים עד שנתברר לה הדבר:
        אתמול  היתה בעיר ולותה אל האניה את סוקולסקי.  הוא דחה ודחה את ענין
        הנתוח, וכשהחליט לעשותו אצל רופאי ירושלים, לא הסכימו הללו לקחת עליהם את
        האחריות ויעצו לו לנסוע לחוץ-לארץ.  הוא פקפק הרבה, אבל סוף-סוף החליט,
        כשלא היתה לו עוד ברירה אחרת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לבה מתכוץ ודופק: הישוב בריא? האם לא צפויה לו שום סכנה? הוא עצמו לא היה
        לו די אומץ לנסיעה, אך היא חִזקה את רוחו, עודדה אותו, עזרה לו בכל ההכנות
        ולא עזבתהו עד הרגע האחרון, כשהאניה כבר עמדה להפליג.  כל הזמן הראתה אומץ
        לב נפלא, אבל כשהתרחקה הסירה והוא עוד עמד על מכסה האניה, הניע לה בכובעו,
        כשתלתליו הדקים מתפזרים לרוח, כסה פתאום  דוק של דמעות את עיניה ולא ראתה
        כלום.  הנוסעים אתה בסירה הביטו בה והיא התבישה מפניהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הבעלך זה? – שאלה אותה ערביה אחת, מכוסה בצעיף שחור וקולה דק
        ונעים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– לא, אחי הוא.</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >טוב שאלה היו אנשים זרים שלא הכירו אותה.  המכירים מרחיקים ללכת
        בלשונם.  בודאי יש כבר בפיהם איזה שמות-גנאי, שעוד לא הגיעו לאזניה.  חשבו
        תחילה, שהנה הולכת היא להתחתן עם סוקולסקי. וכיון שצעד זה לא נעשה והיא
        עדיין מסורה לו, הרי יש מקום לחשוד בה, שאינה אשה הגונה.  והיה מקרה שדרושה
        היתה השתתפותה באוסף כספים לטובת ענין חשוב מאד.  פנו אליה בנידון זה וקבעו
        לה שותף לעבודה, אבל אדם זה, בעל בעמיו, נרתע אחור: "עם אשה </span
      ><span class="c3">כזו</span
      ><span class="c0"
        > הוא ישתף את עצמו לעבודה?" נמצאו הולכי-רכיל, שמסרו לה את הדברים האלה,
        והיא צחקה.  בעצמה מצאה לה בן-זוג, והסכום הדרוש נאסף במשך שלשה
        ימים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– בת-חיל שכמותך! – אמרו לה אחרי-כן אלה שדאגו לסדור הענין, ובלבה חשבה:
        אותה בת-חיל תעפרו גם אתם בעפר, בראותכם כי אחרים ישליכו עליה
        שקוצים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל כלום אשמים בני-אדם, האוהבים להתלוצץ, אם היא עצמה נתנה פתחון-פה לכך?
        איך יאמינו הם בטהרתה, אם עוד לפני איזה שנים גם היא לא היתה מאמינה ביחס
        טהור וצנוע כזה בין אשה וגבר.  לעתים היתה מלאה תשוקה לראותו, הציק לה הלב
        מכל מה שעובר עליה בבית, והנה פגשה בו, וְדַי שידה מונחת בתוך ידו ובלבה
        משתרר השקט.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עוד חושך בחלונות, זהבה משתדלת להרדם, אבל אין מלאך השינה חוזר אליה. מעל
        העצים שבגנתם צורח עוף טורף בקול איום.  לפני עיניה העצומות היא רואה ספינה
        שטה על פני הים, ובין נוסעיה הרבים היא מכירה אותו.  הנהו עומד ומלוה את
        סירתה ופניו העדינים כה עצובים.  היבריא? הישוב?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >החלל הריק, שהיא מרגישה סביבה עתה, אחרי נסיעתו, משרה עליה רוח נכאה.  אבל
        היא מחליטה שאסור להניח לעצבות שתשתרר עליה, כי הלא זקוק לעזרתה עוד מישהו
        קרוב, מרוסיה שלה חלשה בזמן האחרון, הילדים מיַגעים אותה מאד, צריך להקל
        עליה.  אין פנאי עתה להרהר בנשמה הנשרפת בתוך לבטיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ההחלטה אינה מועילה הרבה, ומצב-הרוח הולך ונמשך ועלטה עוטפת את לבה.  האם
        לא כך מסתלקים אנשים מן החיים? והיא הלא רוצה עוד להמשיך את הקיום.  יש לה
        תכניות חדשות איך לסדר אדם המצפה לעזרתה.  היא מצאה דרך כיצד להוציא לפועל
        תקון חברתי אחד, ועוד חושבת היא על תקון מצבה היא, – שתעַבֵד בפני עצמה משק
        קטן, חלק מן הגדול, תגור בצריף בחצר ותוכל תמיד להיות לעזר לאחותה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לפי הפסיכולוגיה של גיסה הכל שייך לו – כל העולם לא נברא אלא בשבילו.  היא
        מבינה שקשה לו להפרד מכֶּרֶם זה או מכרם אחר, אבל היא אינה יראה, עוד לא
        פסקה אמונתה באמת ובצדק.  אם יבוא הדבר לידי משפט, הלא יכירו למי שייך
        המשק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולעבודה יש לה מרץ.  היא בטוחה, שתוכל להתקיים אם תהיה ברשותה חלקת נחלה –
        כי מה דרוש לאדם אחד, היודע את תורת החסכון? העבודה עצמה אינה מפחידה אותה,
        היא מבינה איך לגשת אליה, וכשהיא לוקחת עליה לפעמים את העבודה בכרם, היא
        מונה אחת, אחת את השגיאות שנעשו שם.  גם יודעת כיצד לתקן את
        המעוות. וכשיהיה הכרם שייך לה לבד, ידוע תדע להעמידו על מדרגה גבוהה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה לא נרדמה עוד.  האיר הבוקר בחלון והיא התחילה להתלבש.</span
      >
    </p>
    <p class="c1 c2" dir="rtl"><span class="c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה מטילת יחידה בערבים בדרך העוברת בצד ההר של המושבה.  משמאל מתרומם
        ההר עם גדרות אבנים ושיחי-פרא; עצים, ששורשיהם ערומים, כנוטים לנפול לתוך
        המחצבה, שפערה כאן את פיה ונראית לאור הירח כתהום מבהילה.  מחצבה זו מעירה
        בה מחשבה על גזילות ורציחות, שיכלו לקרות במקום הזה, אלו היו השכנים שלהם
        מאותו הסוג, שהרבה נקודות אחרות בארץ מקוללות בו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה הולכת לאטה בדרך זו ועיניה חודרות לתוך הסביבה.  מימין משתרע העמק עם
        החלקות הזרועות, ושם על ההרים, במרחק, מבצבצות אורות של שתי המושבות
        הקטנות.  בשתי נקודות אלה מתהוים חיים מיוחדים, חיי בדידות עניים
        ודלים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנה אור אוטו חולף במהירות בתוך העמק למטה,  הוא הולך צפונה, למקום שהים
        משתטח שם ונוצץ באור הלבנה.  הים סובב את המושבה משני עברים ורחש גליו עומד
        באויר.  בודדה זהבה בַשְבִיל הזה ומתפלאה, שאינה פוגשת באיזה זוגות אהובים
        – הלא זהו שביל נאה בשביל אהובים להתהלך בו.  היא מתחילה להתרומם בהר, משם
        יורד איזה צל ובעברו על ידה, הכירה בלבושו של פועל, וחלפה המחשבה במוחה:
        הנה זה עוד לבוש יפה בזמן חוסר העבודה.  זו דאגת האם הרחוקה להעניק לבנה
        בגדים כל צרכו.  האמר לה לבה מה צפוי לו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >במעלה ההר היא עוברת דרך חצר אחת.  משפחה ערבית גדולה יושבת כאן מסביב
        למדורה והם משוחחים על סוסיהם, בעליהם ועניני כפרם.  שתי ערביות צעירות
        מחבורה זו מתנועעות בכל גופן מתוך צחוק.  שפתן של שתים אלה היא ערבית צחה,
        וזהבה עמדה רגע והאזינה, – הן ערבבו את שם בן בעל-הבית עם אחד מבחורי הכפר,
        ולשניהם לעגו שתי הבחורות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עולה היא בצעדים מדודים אל הרחוב.  בחלק הראשון שורר שקט, אבל שם הלאה,
        בפנת בית-התפלה, מצטופפים אנשים, עומדים דיליז&#39;נסים ואוטומובילים ורבה
        התכונה.  מכאן מתחילה גם צפיפות החנויות מכל המינים, גדולות וקטנות, שבצרוף
        עם איזו בתים רעועים הן עושות עליה רושם עגום.  היא שומעת בחלק זה בלבול של
        שפות: יהודית, פולנית, רוסית, צרפתית ועוד, אבל עולה על כל אלה – הערבית
        והעברית.  ביחוד העברית, השולטת כבר שליטה גמורה בפי הנוער, גם בין נשים
        וגברים צעירים.  וזהבה מוחלת להם על כל פגימה בגלל הדבור היפה הזה, שהוא
        טבעי בפיהם.  על אכסדראות הבתים משוחחים האבות והאמהות בשפה יהודית במבטא
        מיוחד, והבנים רוכבים אמיצים על סוסיהם והם רחוקים מן הגלות ושפתה. אוהבת
        זהבה את הדור הצעיר למרות כמה תכונות בלתי נעימות, שרכשו להם מן הסביבה
        הערבית, אשר בה גדלו בחצרי אבותיהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עולה היא הלאה ורואה משני הצדדים תערובת של בתים – גדולים עם קטנים
        יחד.  הקטנים הם לא בתים רגילים, אלא בתי עירה נדחת, וכה משונים הם ולא
        במקומם בין הבתים היפים שברחוב זה, שצורתם נאה גם מן החוץ וגם מבפנים
        החצר.  מתעכבת היא  אצל שער-ברזל אחד, המוביל אל שדרת עצים יפה, – מה רב
        ההבדל בין חצר זו ובין אלו שבחלקת הרחוב הקודמת.  בלכתה הלאה היא מתבוננת
        בסגנון הבנינים ורוצה לדעת ולהכיר איזה מהם נבנו מתחילה על-ידי אותו האיש
        הפלאי, אשר לא חס על מליונות והכניס חיים במקום שמם זה.  הנה שם למטה
        מתנוססות הארובות של בית-החרושת לתעשית המקום.  איזו מכונות ענקיות הובאו
        ממערב אירופה דרך ימים ויבשות והועלו בקושי אל חוף הארץ בדרכים בלתי סלולות
        עד למקום הזה.  על כל שעל במקום הזה היא עומדת ומשתוממת על מפעל אדם קשור
        בחזון רוחו במעשים אשר לא ידע העם כדוגמתם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ההצליח הישוב במקום הזה? בחלקו הגדול בודאי הצליח.  עדים הם הנטיעות היפות
        בצלעי ההרים,  עדים הם האנשים הבריאים, שהעמידו דור עובד וחרוץ.  הנה היום
        ראתה אב  עם בן, שיצאו אל החריש, ושניהם הלכו אחרי המחרשה אמיצים וזקופים
        בפתחם את התלם, – מי השיב את אלה לתחיה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה שטינברג הגיעה עד גדר בית-הקברות ושבה.  כשהיא מזדמנת ביום במקום
        הזה, היא נכנסת תמיד פנימה.  אין היא צריכה להביט על הטבלא לאיזה שורה
        להכנס.  היא יודעת היטב את השורה ואת המקום.  הולכת היא ישר אל קברו, יושבת
        על אבן גדולה וסומכת ראשה בטבלא.  היא צריכה רק לעצום את עיניה כדי שתוכל
        להתרכז במחשבה שלה, מחשבת העבר. בעזבה את המקום הקדוש היא תמיד מסובבת את
        שני הקברים, המוקפים סבכת-ברזל ירוקה וששה ארזים צעירים בתוכה.  "האהובים
        והנעימים בחייהם, גם במותם לא נפרדו", חקוק על הקבר שלו. אמנם יפה היה
        מעונם בחייהם ויפה הוא במותם! היא, זהבה, לא תנוח על יד חברה בין הקברים
        הצפופים שם.  לא יעמידו לה מצבה נאה, לא כזו שבסבכה ולא כמו ההיא; השחורה
        שמצד ימין, אשר יפיה מושך אותה תמיד.  הלא היא, זהבה, פשוטה מאד בחייה, אבל
        רוצה שלאחר מותה יתנו צורה יפה למצבתה.  אך יודעת היא שגם רצונה זה לא
        יתמלא, – אנשים אשר בחייהם אין הִדוּר, לאחר מותם לא כל שכן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה חזרה והנה שוב במרכז המושבה.  כאן יש לה ענינים שונים, אבל אינה
        יודעת עוד לאן תכנס קודם – לזו הצעירה המטופלה בילדים, שבעלה נסע ועקבותיו
        נעלמו ועתה אגודת ה"עזרה" מטפלת בה, או שתקדים לבקר זקנה אחת, השוכבת על
        ערש-דוי וגם היא מקבלת תמיכה מהאגודה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לאחר שני הבקורים נפגשה ברחוב עם מכירה אחת, שהלכה לאספת המושבה ותשדל גם
        אותה להכנס אל בית הועד.  כאן כבר התאספו הרבה נשים וכולן ישבו אצל הפתח,
        כרגיל, שמעו ושתקו.  ישבה גם זהבה ביניהן, זורקת איזו הערה בין הנשים
        ומסתכלת בנאספים.  על יד שני הקירות מסובים המוזמנים.  את פני האנשים היא
        רואה רק מן הצד, ואפשר לומר, שלא את פניהם היא רואה, אלא את אפיהם, שרובם
        חדים הם, אבל יש גם שטוחים ויש חרומי אף.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הקהל שטוף וכוחים על השאלה – איזה שמות להכניס בתור מועמדים לועד החדש?
        ובאותה שעה כבר ברור לכל, שהתקיפים, שכולם טוענים נגדם, הם יבחרו שנית
        וישארו שליטים בעדה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנשים מתוכחות ביניהם לבין עצמן בשאלה זו בהגיון, אבל אף אחת מהן אינה
        מעיזה לחוות דעה לפני הקהל.  הנה שם מדברים כבר על עצים ועל אבנים, פטפוטי
        מלים סתם, שאינם נוגעים ישר לענין, והנשים מבינות את זה ומבקרות בחריפות את
        כל מה שטעון בקורת, אבל להשמיע את קולן אינן מעיזות; וזהבה אינה יכולה
        לסלוח לעצמה ולאחרות את השתיקה הזאת המעליבה כל כך.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כשיצאו מבית הועד נגעה היא בשאלה זו והנשים מדַיְנות ומתוכחות עכשיו
        ביניהן: – כלום הן אשמות, שלא למדו לדבר בפומבי? הרי האשמה היא בהרחקת האשה
        מחיי צבור, בתנאי חייה המיוחדים, הקושרים אותה לחובות הבית, לילדים, למטבח,
        האחריות הגדולה, שהיא נושאת בה כלפי בריאות המשפחה ומנוחתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >חברה אחרת טוענת, שבכל אשמה שלילת הזכויות והיחס של החברה.  כשאשה מתעוררת
        פעם לתבוע את זכותה, שחוקי החברה הרעים שללו ממנה – הרי היא ללעג
        ולקלס.  בעולם קמה תנועת השחרור של האשה, שכבר חוללה נפלאות והרימה את מצבה
        בהרבה מדינות.  גם כאן השפיעה הרבה, אבל עוד האכרה העברית צריכה ללחום קשה
        לחופשתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היה בוקר בהיר וחם, אף כי חודש טבת עם לילותיו הקרים התקרב כבר אל
        קצו.  שם למטה, אחרי העמק שבין שתי גבעות נטועות זיתים, משתטח הים שקט
        וכחול ובהיר.  אבל כל היופי הזה אינו משקיט את העלבון, שזהבה נושאת
        בלבה. אמנם היא מוכיחה את עצמה, שמקטנות כזו בושה להתרגש.  ובכל-זאת נמתחים
        העצבים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >קסד###]הגיע אליהם ספר חדש, שהיא בקשה אותו זה זמן רב לקריאה, אבל גיסה
        אמר, שהוא רוצה לקראו תחילה.  היא חכתה בסבלנות, ומה עשה גיסה? בגמרו את
        הספר מסרו לידיד אחד מבלי להגיד אף מלה, והוא הלא שמע היטב איך כמהה נפשה
        לספר הזה, שבו הובעה מלה חדשה, חיה, וכמה היתה מצפה לשעה שהוא יגמור אותו –
        איך עשה כזאת? היא בקשה בכל זאת להצדיקו, אבל לא יכלה, עד שיצאה לעבודה
        בגן ומתוך עדור השתתק הלב ותמצא בו את המרגוע ויחד עם זה את הסליחה על העול
        שעשו לה,  ונדמה היה לה ברגע זה, שלא קרה כלום, לא פגעו בנפשה, לא סטרו על
        לחיה, היא מוחלת ומותרת, – לא צריך להיות קפדנית.  יתעלל הוא בה, אך אהבתה
        את משפחתו תשטוף את כל הזרם העכור הזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל גל חדש עובר על ראשה ושוטף את הסליחה שנמצאה בלב.  בבוקר אחד הקדימה
        יותר מדי לתת אוכל למבכירה; זו אכלה כמעט את כל המנה לפני החליבה ולא רצתה
        לעמוד בשקט בשעה שחלב אותה והסתובבה לכאן ולכאן ותרגיזהו.  לא עלה על דעתו,
        כי אשם הוא בעונש ששם על מבכירה בגלל שגעונותיה, – לעמוד לילה
        בתענית.  זהבה רחמה על עלובה זו, ורק בבוקר השכם קמה ומלאה את האבוס, כדי
        שתספיק לאכול ולא תצא רעבה אל השדה. חטא גדול חטאה בזה ועליה לשמוע עתה
        דברי נאצה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ועוד פשע גדול אחד נזקף על חשבונה.  היא נקתה וסדרה מחדש את המקום להטלת
        הביצים.  והתרנגולות, לא שהדבר מצא חן בעיניהן, נסו לחפש להן מקומות
        חדשים.  ראה הגיס ושפך קיתון של בוז על הנשים ועל סדריהן, שהכל הן מקלקלות
        ומשחיתות.  לטענות אלו לא שמה לב לגמרי והניחה לו לרטון כאות נפשו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה יוצאת עם חובב על ידיה לטייל ברחוב.  הילד מאושר ועיניו נוצצות
        משמחה, כשהיא מתעטפת אתו יחד בסודרה הגדול.  ברחוב שחקו נערים ונערות והיא
        עמדה והתבוננה. – התיחסותם של אלה הזכירה לה מאורע אחד מימי ילדותה: היא
        היתה אז כבת עשר, אמה נסעה לרגל המסחר לעיר המחוזית, ואותה עם האחות השאירה
        בבית דוד, אחי אמה.  היא היתה מרוצה מזה ושמחה נסתרת היתה בלב, כי שעשעה
        אותה התקוה, שיחזקאל בן הדוד ישחק אתה בשעות  החפשיות מן הלמודים בחדר והם
        יבלו יחד את הערבים.  אבל כשהוכזבה תקותה, בראותה שיחזקאל אינו שם לב אליה
        כלל, תקף אותה השעמום והצער והתחילה לבכות בלי הפוגות, עד כי איש לא יכול
        לנחמה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– המסכנה, מתגעגעת הביתה – החליטה הדודה ובהטיפה לה מוסר, שהנה אחותה קטנה
        ממנה ואינה בוכה כלל, – העניקה לה בינתים מכל טוב מזנונה הגדול, אבל גם זה
        לא הועיל.  יחזקאל היה אשם בדמעותיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הנה בחבריא זו נמצאות ילדות חמודות, ומי יודע? – אולי יש ביניהן היודעות
        כבר לשפוך את לבן מתוך דמעות כמוה אז?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">אצל אחד הגדרים שחקו שני פעוטות:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– בוא, חביבי, ונראה מה הללו עושים – פונה היא לחובב, כאילו הוא מבין את
        דבורה ותעמוד אתו אצל הבחורים השנים, המסיחים זה לזה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– אתה חולם לפעמים בלילות, בניהו?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– לא, אף פעם – עונה רענן – ואתה?</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אני חלמתי הלילה, שפרתנו האדומה "וַשְתִּי" ילדה שני עגלים בבת-אחת,
        ותיכף אחרי הולדם קפצו כבר בכל החצר. </span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– שנים? זה אי-אפשר! הלא "ושתי" שלכם פרה קטנה היא, איך תכיל שנים?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– הוי, טפש! עונה בניהו – הנה "פוֹרדָה", כלבה לא גדולה וילדה
        ארבעה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה אורה רצה אל דודתה ומצאה את חברי הגן.  ושלשת הילדים בהפגשם נזכרו
        בספור  אשר שמעו היום מפי ה"גברת" וזהבה בקשה לספרו לה.  זו היתה מעשיה
        בתיש קטן ולבן, שהציל ילד אחד ואת אביו המסכן, החולה.  לא היה להם מה לאכול
        והתיש הלך לערוך משחקים לפני בני אדם יחד עם הילד.  הם אספו פרוטות פרוטות,
        שהביאו לאב החולה ויחד אכלו ושתו ושבעו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זהבה ראתה, שבעיני בניהו החלש ובעיני אורה נצנצו דמעות, –  כנראה שרק
        החלשים בוכים.  גם אחותה בוכה יותר ממנה – החלשה היא ממנה, או סובלת
        יותר?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בהכניסה את הילדים הביתה ובהכנסה אל המטבח להכין את ארוחת-הערב, עוד
        מוסיפה היא להרהר בשאלת סבלה של אחותה: הגיס מתרגל יותר ויותר להפוך את
        תפקיד אשתו בתור חברה, בעלת-בית ואם ילדיו, – לתפקיד של שפחה חרופה במלוא
        משמעותה של מלה זו.  נראה ברור, שיש לו תענוג להשפילה.  בארוחה אחת מונה
        זהבה כמה וכמה פקודות שהוא נותן ללא צורך ומפריע בעד אכילתה וישיבתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        > להשפילה יש לו הזדמנות בכל שעה. הנה עומד הוא להתגלח וקורא לה פתאום,
        שתביא לו כד מים מן החוץ; היא עוזבת את מלאכתה והולכת למלאות את
        פקודתו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ושוב מעשה: הוא שוכב במטה והיא מטופלה בילדים שהתעוררו, פתאום עולה בדעתו,
        שבקור הזה טוב לשתות כוס תה חם – תלך ותחמם את התה.  והיא עושה את רצונו
        בשארית כוחותיה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והנה ענין של כסף: נתקבל מכתב מאחד הקרובים, המבקש עזרה בצר לו, מרים
        הראתה את המכתב לבעלה ועיניה הביעו רחמים.  תשובתו היתה כמובן שלילית. –
        הוא בעל-הבית, בעל-הרכוש, למי יש הרשות להשתמש בכספו לנדבות? אם הוא ירצה
        לתת לקרוביו יש לו הרשות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גם כשצריכה אחותה לעשות לה בגדים חדשים, היא תלויה בדעתו.  הוא צריך לנסוע
        אתה העירה כדי לקנות מה שדרוש.  הם נכנסים שניהם אל החנות והיא בוחרת לה
        לשמלה ובד לילדים.  הוא נגש, שואל אותה למחיר, מתעקם, מניח הצדה את הסחורות
        וקורא לה ללכת.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– זה יקר מאד,  אינני רוצה, – אומר הוא בקול רם והיא יוצאת בושה
        ונכלמה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >קצרים הימים והלילות ארוכים.  היא מספיקה הרבה בקריאה וקוראת לא בלי עיון
        ולא בלי בקורת, – הספר מעמיק את התלם בתוך לבבה והיא נושאת אתה את הרשמים
        לתוך החיים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא מספיקה הרבה גם בעבודה גם בקריאה,  אבל הנה התחילה קדחת לבקרה
        תכופות.  יש שמתוך העבודה תוקף אותה קור גדול, כאב ראש ולחץ חזק בלב והיא
        אומרת להתחזק ולהתגבר על זה רק על ידי העבודה.  אבריה רצוצים ושבורים והיא
        בכל-זאת על משמרתה עומדת, – מבשלת, רוחצת ומנקה.  האם מכריח אותה מישהו
        לעשות דבר, שהוא למעלה מכחותיה? זאת היא, היא עצמה שנפשה מזכירתה תמיד איזה
        חוב של עבודת עזרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוי, טפשה, טפשה! – קוראת היא לעצמה.  וכשאין עוד כח להמשיך בעבודה והיא
        יושבת פתאום אל השלחן במטבח וסומכת את ראשה בשתי ידיה, אז יודעת אחותה שרע
        לה ומכריחה אותה לשכב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >השכיבה אינה נעימה לה בזמן שבמשק העבודה רבה מאד.  זהבה קוראת לעצמה
        "שלומיאל", "לא יצלח", אין בה תועלת לא לעצמה ולא לאחרים.  אותה ההתלבטות
        התמידית מן החדרים אל החצר ובחזרה, ואין שום חדוש ואין עבודתה נכרת; מחכה
        היא, אולי אחרי-כן ישתנה מה, אף-על-פי שהיא יודעת ברור, כי שום שנוי לא
        יבוא. הכל יהיה כמו שהיה: עוות החיים ועוות נפש האדם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הקדחת נמשכת יומים,  וזהבה מוכרחה לשכב ולטעום את טעם המנוחה.  בבוקר אינה
        ממהרת לקום, תריס אחד פתוח והיא רואה שמים מכוסים עננים אפורים.  הנה
        התחילה נקודה אחת להתבהר, ובעמקו של הכתם הבהיר נראה אגם כחול עם ארי ענקי,
        הרובץ על שפתו; ארי זה בולע אל תוך פיו הפעור נחש מתפתל, אבל פתאום התחיל
        גם הוא להמוג ולחפות על תכלת האגם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שם ברפת גועה "מִלְכָּה", רוצה היא להתפרק ממשא החלב, ואינה יודעת שהחולבת
        נתונה לפנוק כגברת חשובה בתוך המטה.  מוכרחה "מלכה" לחכות עד שיגיע תורה
        והחולב הזועם יגש אליה לא בידים רכות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובכל-זאת עזבה זהבה את מטתה טרם הבריאה לגמרי, כי בבית קמה מהומה מפני
        מחלת "אפור".  שלחוהו עם משא כבד העירה, בא עד אמצע הדרך ולא היה בכחו
        להמשיך דרכו הלאה ונפל באמצע הדרך. העגלונים שהיו בשַיָרָה התירוהו מן
        העגלה, הסירו את רתמתו ושלחוהו בצעדים אטיים הביתה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >למחרת מת הסוס, וכשגיסה בחדר השני שמע את תלונתה, שהיא הביעה מתוך צער
        עמוק על זה שמעבידים אצלם את הסוסים עבודת-פרך, מעמיסים עליהם משאות כבדים
        מנשוא ולא פלא ש"אפור" נפל, – התרגז והתגעש, נכנס אדום כולו עם עינים מזרות
        אימה וצעק בקול נורא:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– צאי מכאן, מכשפה! צאי מביתי תיכף ומיד, ולא – את גלגלתך ארוצץ!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        > היא החוירה, לבה התחיל דופק וקופץ בקרבה, רגליה כשלו ותפול מלוא קומתה
        ארצה ותתעלף.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מי הרימה, מתי התעוררה וכיצד באה אל הסלע, שהיא יושבת עליו, איננה
        יודעת. דומה שהרוח נשאה והביאה הלום, הרחק מן המושבה וקרוב אל הים.  רק
        הפרדס שלמטה והבִצָה שאחריו מפרידים ביניהם. מאחריה מתנוססת האחוזה היפה
        ולפניה העמק עם חלקות אדמה ישרות, חרושות יפה, וגלי הים מלחכים את
        שפותיהן.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הים רועש חסר צבע.  עננים כבדים נשאים על פניו.  סביבה סלעים וחצץ.  את
        המושבה אין רואים, בינה ובין המושבה מפרידה החורשה, השדרות והגן עם הבית
        המופלא שבתוך האחוזה, בית זה שבו בִלַע המות את מיסדיו השנים, שצר היה להם
        בעולם היפה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ירד עליה הלילה, ליל אפל בלי סהר ובלי כוכבים, רק מבין העננים הכבדים נצנץ
        זעיר שם כוכב בודד.  זהבה איננה יודעת להחליט, אם לקום או להשאר? אנה תלך,
        אנה תבוא? האם לא נסגרו שערי עולמה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התחיל יורד גשם, קודם בנחת אחרי-כן בזעף, והיא עודנה יושבת על הכֵּף הרחוק
        ונפשה עכורה וזועפת כגשם הזה, כים אשר יחמר שם למטה.</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span
        >היא ירדה באה עד שכונת התימנים, נכנסה אל סַעֲדָה, הכובסת אצלם תמיד, ראתה
        את זו עם שני ילדיה והתפרצה בבכי.</span
      >
    </p>
  </body>
</html>`;

export default page;
