const page = `
<html>
  
  <body class="c6 doc-content">
  <p class="c0 text-center" dir="rtl">
      <span class="c2 c7">(מחיי התימנים).</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">        </span
      ><span class="c2 c1"
        >אחרי ליל צער ונדודים קם סעדיה עם נץ החמה להתפלל שחרית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        בצאתו כעבור שעה קלה מן השכונה התימנית, ירד ישר אל חוף הים ללכת
        מצד זה העירה. המסכן התביש לעבור הפעם את נוה-שלום מפני אנשיה הרבים, ובחר
        בחוף – כאן רק הדייגים עוברים בשעה זו, או הטבחים מביאים את כבשיהם לשחיטה.
        מהם אין להתביש!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        הוא ממשמש באזנו הימנית – והלב דואב. לאן נסתלקה פאתו היפה,
        שהתפתלה כאחותה בדמות תלתל ארוך ודק? האם לא יסלסלה יותר?... ארור השד
        הקטן, סלים בן פנחס, שעשה לו את הצחוק הרע הזה! לאן יוביל את חרפתו –
        לאן?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        גל שובב נפרד מקצף חבריו והתחיל רודף אחרי סעדיה, רדפו גם רוח
        פזיזי ונפנף את פאתו היחידה. מקלל הנער השחרחר את עלובה זו שנשארה לו לחרפה
        ולדראון, מקלל את מזלו ואת יומו הרע! ואותו היתוש שטרד במחו כל הלילה נוקב,
        נוקב שוב: הוא סעדיה, בן שלום הכהן נ"ע בן תלמיד חכם גדול יצא לתרבות רעה
        ונגזרה פאתו. הוי, ג&#39;וי הוא, ג&#39;וי, ג&#39;וי!!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        הוא עבר את כל אֹרך החוף ונכנס דרך מזבלות לחוץ </span
      ><span class="c3 c1">[?]</span
      ><span class="c2 c1"
        > אחד. עוד מעט יגיע עד הרחוב ההומה הגדול ששם המשרד ופגש באשכנזים, בילדות
        עוברות לבית הספר – מה לעשות, מה לעשות בפאתו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        הוא מנסה לתחב אותה תחת אזנו. היא שם מיותרת וארוכה יותר מדי. הוא
        מרגיש את זה ואף על פי כן ינחם את עצמו שככה לא יכירו בה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        בהכנסו למשרד היתה השעה קרובה לשבע. סעדיה נגש בהתרשלות לסדור
        המשרד. טאטא אותו, צבר את העפר עם פתותי ניירות ושיורי סגרות אל פח אחד
        שהוריד אל החצר. במטלית לחה נקה את השלחנות, הארונות והקסתות. הניח זה על
        גבי זה את הפנקסים והספרים. כשסקר אחר כך על עבודתו לא מצאה זו חן בעיניו
        ודומה היה לו, שהמשרד קבל היום צורה אחרת וכל חנו הסתלק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        נכה רוח עומד סעדיה ומתאר לו כיצד הראשון יכנס המזכיר לכאן ויביט
        בו, יסתכל ויפרוץ בצחוק גדול... קשה לו, קשה! ולמען השתק את סערת רוחו
        הוציא מתוך כיס מעילו הקרוע קצה אכרון </span
      ><span class="c1 c3">[עפרון?]</span
      ><span class="c2 c1"
        > ומחברת דקה, דפדף ומצא את נטיות הפעלים וחזר עליהם. אחרי כן פתח דפי
        חשבונות – הרי כאן עד עשרת אלפים הגיעו! בשבוע העבר הגיד לו האדון כי בקרוב
        יתחיל אתו מין חשבון חדש. איך קרא אותו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        סעדיה עוצם עיניו מקמט את מצחו ומבקש לדלות מתוך זכרונו את שם
        אותה החכמה. האח! כן, שברי מעשר! מי יודע איזה סודות ורזים טמונים בחכמה זו
        וכמה יעמל האדון על פרושם ובאורם!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        וכאן מבצבצת במוחו של סעדיה אותה השאלה שהוא אנו מוצא לה פתרון
        בשום אופן, למה ולאיזו מטרה כל כך מטפל בו האדון ברגעי הפנאי שלו? במה זכה
        יתום תימני עזוב כמותו לשומת לבו של אדון מלומד כזה, היושב יומם ולילה על
        ספריו? הוי, כמה ספרים יש לו! שלשה ארונות מלאים בחדרו ועל הספות הם מוטלים
        ועל השלחנות. חבל, שאין ביניהם משניות או איזו מסכתות של תלמוד בבלי, האדון
        בוָדי היה משאיל לו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        בספרים של האדון הוא אינו מבין אף אות אחת. מסתכל הוא בהם לפעמים,
        מסתכל, וכל כך צר לו על שהם חתומים וסתומים בשבילו. הלא כל חכמתו בוַדי קנה
        האדון בהם רק בהם, אבל למה הוא כה ממית את עצמו עליהם? – בלי ילדה יושב
        האדון, בלא עזר כנגדו ותדיר הוא שרוי בצער. למה ומדוע באמת לא יכנס לחפה,
        האין לו כסף לקנות ילדה?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1 c2"
        >        והרי המזכיר! – צעיר הוא עוד. אין חתימת זקן, והוא כיהודי נאמן
        כבר דואג לילדה! רק זה לא שפיר שהוא ערב-ערב מטייל עם ילדתו על הים. אסור,
        אסור!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        ואדונו הוא בעיניו אדם כשר וצנוע, וצר לסעדיה על בדידות אדונו
        ומחליט כי הוא לא כן יעשה! בן שמונה עשרה הוא יכנס בעזרת השם לחופה. כמה
        נשאר? זה לא כלום. בת דודתו חממה היא היפה בבנות התימנים. אותה הוא ישא
        לאשה כשירצה השם יתברך!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        סעדיה חוזר למחברתו. מדפדף ועומד על דף אחד וקורא: סעדיה בן שלום
        הוא נער חרוץ אוהב עבודה ויודע כתב וחשבון. תעודה זו, אמר פעם האדון כשנחה
        דעתו עליו, תנתן לסעדיה כשידע לכתב בלי שגיאות ולפתר את שאלותיו אל
        נכון!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        מי יודע? אולי יום כזה באמת בוא יבא ואז... אז אפשר שהאדון יקחהו
        למזכיר שני, יושיבהו אל שלחן הכתיבה ויתן לפניו פנקסים לנהל בהם חשבונות;
        הוא ישתדל למלאות הכל בדיוק נמרץ והאדון יהיה מרוצה מעבודתו ויקבע לו שכר
        חדש הגון, עד כדי לפרנס בכבוד את אמו ואת אחיותיו הפעוטות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        הרי האב החורג שם במושבה אינו רוצה לדעת כלום מפדיה וכורסה </span
      ><span class="c3 c1">[?]</span
      ><span class="c2 c1"
        >, גם בעד התינוקת, בעד ימימה שנולדה לו, אינו רוצה האכזר לתת מזונות! האם
        המסכנה עובדת כל הימים והתינוקת האומללה בוכה, הלב מתפקע מצערה של האם!
        –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        לא! לא טוב ככה!... כשהוא יהא בעזרת השם העוזר השני לאדונו לא
        תשרת יותר האם, לא תסבל ככה!... הוא ישכור לה בית ורהיטים יקנה – שלחן, מטה
        ושני כסאות... תנוח האם וישוב לה חִנה הקודם – הלא יפה היתה, וכאן העניות
        כל כך מנולתה! –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        מחלון המשרד רואה סעדיה את הים המשתטח מעבר לגגי הבתים הגבוהים.
        תכול הים היפה, מושך את לבו. אניות התורן שעל האפק קטנות הן קטנטנות. דומה
        לסעדיה שיונים לבנות שלוחות שם בין ים ורקיע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        מתוך יופי המרחק מתיצבת תמונת חממה נגד עיניו. היא מסתכלת בו
        תחילה בתמהון ואחרי כן פורצת בצחוק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        פסיעות מהירות על המדרגות הבהילו את סעדיה, החרידוהו מעולם
        החלומות לתוך הויתו הפגומה, - פאה אחת! הוי, בושה. הוי, כלימה, לאן ימלט,
        לאן?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2 c1"
        >        וכשנכנסו האדון ומזכירו ונתנו לו את פקודותיהם הרגילות מבלי שים
        לב אל אבדתו הנוראה הוא עמד ותהה: - הם לא רואים?! כלום לא רואים כלום? - -
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">                                                </span
      ><span class="c2 c7">נחמה.</span>
    </p>
    <p class="c0 c4" dir="rtl"><span class="c2 c1"></span></p>
    <p class="c0 c4" dir="rtl"><span class="c2 c5"></span></p>
  </body>
</html>
`;

export default page;
