import styled from "styled-components";
import { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import appTexts from "../texts/appTexts";
import MenuComp from "./Menu";
import Modal from "./Modal";
import BackButton from "./BackButton.tsx";

function Item(props) {
  //console.log("Item props", props);

  const navigate = useNavigate();
  const location = useLocation();
  // const path = location.pathname;

  const ref = useRef([]);

  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const showItemDoc = () =>
    props.itemDoc &&
    (props.isOpen || props.isMain) &&
    props.itemName !== "home";

  useEffect(() => {
    if (showItemDoc() || (props.itemDescription && !props.isOpen)) {
      let nodeDiv = document.createElement("div");
      if (showItemDoc()) {
        nodeDiv.innerHTML = props.itemDoc;
      } else {
        nodeDiv.innerHTML = props.itemDescription;
      }
      while (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
      ref.current.appendChild(nodeDiv);
      setModalButton();
    }
    if (location.hash.length) {
      // console.log("location.hash", location.hash);
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  const isClickable =
    !props.isOpen && !props.isMain && props.itemName !== "home"; // && props.itemUrl

  const isDisabled = props.itemDoc === undefined || props.itemDoc === null;

  // console.log(props.itemName + "   isDisabled == " + isDisabled);
  // console.log("isClickable", isClickable);

  const scrollToPageHead = () => {
    // console.log("scrollToPageHead called");
    window.scrollTo(0, 260);
  };

  const onItemClick = (event) => {
    const isLink = event.target.nodeName === "A";
    // console.log("onItemClick isLink = ", isLink);
    const isModalButton = event.target.classList.contains("modal-button");

    if (isClickable && !isDisabled && !isLink && !isModalButton) {
      navigate(props.itemUrl, { state: { from: props.from } });
      scrollToPageHead();
    }
  };

  // const goBack = () => {
  //   if (false) {
  //   } else if (location.hash.length) {
  //     const pathArr = path.split("/");
  //     // console.log("goback has hash! pathArr[1] = ", pathArr[1]);
  //     navigate("/" + pathArr[1]);
  //   } else {
  //     console.log("goback else");
  //     navigate(-1);
  //   }
  // };

  const setModalButton = () => {
    // console.log("setModalButton called");
    let openModalBtn1 = document.getElementById("modal-button-1");
    let openModalBtn2 = document.getElementById("modal-button-2");
    let openModalBtn3 = document.getElementById("modal-button-3");

    if (openModalBtn1) {
      openModalBtn1.onclick = () => openModal(1);
    }
    if (openModalBtn2) {
      openModalBtn2.onclick = () => openModal(2);
    }
    if (openModalBtn3) {
      openModalBtn3.onclick = () => openModal(3);
    }
  };

  const openModal = (i) => {
    // console.log("openModal called");
    setModalIndex(i - 1);
    setShowModal(true);
  };

  const closeModal = (event) => {
    if (event.target === event.currentTarget) {
      // console.log("item closeModal called");
      setShowModal(false);
    }
  };

  const isHidden = isClickable && props.hidden === true;

  // console.log(props.itemName + " isHidden ");

  return (
    <OuterContainer>
      <Container
        onClick={(event) => onItemClick(event)}
        clickable={isClickable.toString()}
        isdisabled={isDisabled.toString()}
        hidden={isHidden}
      >
        {props.isOpen && (
          <MenuCon>
            <MenuComp inItem={true} />
          </MenuCon>
        )}
        {props.isOpen && <BackButton />}
        {props.itemTitle && (
          <ItemTitle id='item-title'>{props.itemTitle}</ItemTitle>
        )}
        {props.itemSubtitle && props.isOpen && (
          <ItemSubtitle>{props.itemSubtitle}</ItemSubtitle>
        )}
        {props.itemDescription && !props.isOpen && (
          <ItemDescription ref={ref} />
        )}
        {(props.isOpen || props.itemName === "home") &&
          props.itemText &&
          props.itemText.map(
            (p, i) => (
              <ItemP bold={p.bold} key={i} align={p.align}>
                {p.inlineTexts
                  ? p.inlineTexts.map((text, i) => (
                      <InlineText bold={text.bold} key={i}>
                        {text.text}
                      </InlineText>
                    ))
                  : p.text}
                {/* {p.text} */}
              </ItemP>
            )
            // console.log("props.itemText.map p", p)
          )}
        {showItemDoc() && <DocParent ref={ref}></DocParent>}
      </Container>
      {showModal && (
        <Modal content={props.modalsContent[modalIndex]} close={closeModal} />
      )}
    </OuterContainer>
  );
}

export default Item;

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  padding: 0.5rem 2.5rem;

  flex-direction: column;
  border: ${(props) => (props.clickable === "true" ? "1" : "0")}px solid #000;
  border-radius: 8px;
  margin-bottom: ${(props) => (props.clickable === "true" ? "24" : "60")}px;
  cursor: ${(props) =>
    props.clickable === "true" && props.isdisabled !== "true"
      ? "pointer"
      : "auto"};
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  max-width: 920px;
  // @media (max-width: 920px) {
  //   max-width: 100vw;
  // }
  align-self: center;
  direction: rtl;
  background-color: ${(props) =>
    props.clickable === "true" ? "#faefd5a1" : "transparent"};
`;

// const BackButton = styled.div`
//   position: absolute;
//   cursor: pointer;
//   top: 32px;
//   font-weight: bold;
//   border: 1px solid #000;
//   border-radius: 4px;
//   padding: 4px 8px;
// `;
const MenuCon = styled.div`
  position: absolute;
  //top: -12px;
  top: -295px;
  left: 0;
`;

const ItemTitle = styled.h2`
  // width: 100%;
  margin: 1.75em 0 1em;
  padding: 0 12px;
`;
const ItemSubtitle = styled.p`
  text-align: start;
  padding: 0px 1rem;
`;
const ItemDescription = styled.p`
  font-style: italic;
  text-align: start;
  padding: 0px 1rem;
  margin-top: 0px;
  line-height: 1.5;
`;

const ItemP = styled.p`
  margin: 0px 0px 8px 0px;
  // text-align: initial;
  text-indent: 1.5rem;
  font-weight: ${(p) => (p.bold ? "700" : "initial")};
  text-align: ${(p) => (p.align ? p.align : "start")};
`;

const InlineText = styled.span`
  font-weight: ${(p) => (p.bold ? "700" : "initial")};
`;
const DocParent = styled.div`
  text-align: right;
  // font-family: "AlefRegular";
`;
