const page = `<body class="c8 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c3"
      >יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי, בעלה של נחמה הדריך בהתנדבות אנשי
      העליה השניה והשלישית שהתיישבו בקרית ענבים. בארכיון של קרית ענבים יש
      איזכורים לא מעטים לעבודתו שם</span
    >
  </p>
  <br />

  <a href="#erevYomGimel">
    <p class="c2" dir="rtl">
      <span class="c4">יום ג&#39;, ערב</span>
    </p>
  </a>
  <a href="#YodHetShvat">
    <p class="c2" dir="rtl">
      <span class="c4">י"ח שבט תרפד</span>
    </p>
  </a>
  <a href="#kafBetShvat">
    <p class="c2" dir="rtl" id="h.gjdgxs">
      <span class="c4">כ"ב שבט תרפ"ד</span>
    </p>
  </a>
  <a href="#kafTetShvat">
    <p class="c2" dir="rtl">
      <span class="c4">כ"ט שבט תרפ"ד</span>
    </p>
  </a>
  <br />
  <br />

  <p class="c2" dir="rtl" id="erevYomGimel">
    <span class="c0">קרית ענבים, יום ג&#39;, ערב</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">ילדי היקרים!</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >כותבת בחדרו של אבא, מובן אוריגנלי מאד אבל נעים כשקולות צעירים מתבקעים מכל
      החדרים הסמוכים. ביפו חכה לי קריתי, אבל נוסעים לא היו שיום גשם ובשביל לסדר
      אוטו שלמנו ביוקר והנסיעה היתה רעה מאד, אבל השופר אדם טוב והוביל אותנו ישר
      לחוה, עד הדלת ממש. היתה שמחה, כמובן, הצעירות קבלוני יפה, הן נחמדות. יש גם
      7 פעוטים בריאים ויפים. רחל, חברתה של ג"ב בולקין, התור שלה כעת לטפל בילדים.
      האמהות הולכות לעבד, רק בצהרים נכנסים האבות ומסתכלים באהבה בפעוטיהם – מחזה
      נהדר, מעורר חמלה רבה. הם לא לוקחים את הפעוטים על ידיהם רק צוחקים להם מעט,
      שולחים מבטי חבה ויוצאים לעבודה. הרבה מהצהרים עד עכשו לא הספקתי לראות, אבל
      הנעם שבסביבה מורגש בכל. לא לחנם אבא התאהב במקום.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >את ביתנו עזבתי כמובן לא ברצון. בלילה שמחתי שהיה מטר, רעמים וברקים שיכלו
      להפריע לי את הנסיעה. אבל בבקר קמתי במרץ להוציא פעם לפועל איזה דבר גם למען
      נפשי ומנוחתי. והלא יש לפני עתה הזדמנות כל כך טובה, שאתם בבית ולא השארתיו
      עזוב ושומם. אני מאושרת במחשבה, שבני ממלאים את חובתנו במשק החקלאי, ששבו
      אליו הכחות הצעירים והידים החרוצות. הלא זה כל כך טבעי ויפה וטוב, שרק בחלום
      יכלתי להזות בזה. ואתם בודי ובודי שמחים שנתתם לנו הזדמנות לנוח מעט. אבא
      הבריא, פניו טובים והוא עליז ושמח. כעת יש לי שאיפה אחת, שתבריאי גם את,
      אפרתי, ואז אהיה באמת אם מאושרה בעולם. צבי בודי כעת ישגיח עליך היטב, היטב
      ולעת שובי אמצא אותך כמו שהייתי רוצה לראותך. את תטפלי מעט בהשגחה על ה,בבלה"
      ושלום מסרי לה.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >שלום לעדה, לעשהאל ולקטנים שלהם. מה שלום סובול? בשני הערבים האחרונים
      הרגישה את עצמה לא טוב ואיך עכשו. הקרובה של אבא נחמדת.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום לכם לכלכם מאת</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl"><span class="c0">אמא</span></p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אסתר יקרה! אל תתגעגעי לדודה. היי שמחה ועליזה. את החדר שלנו סדרי היטב כל
      בקר ותעזרי לאפרת במה שאפשר יהיה לך. אם תצטרכי כסף למחברות או דברים אחרים
      בקשי מאפרת היא תתן על חשבוני.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">תמסרי שלום לאדון אמיתי ממני ומהדוד. </span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">דודתך אוהבתך נ. פוחצבסקי</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >חפצנו מחר לנסע ירושלימה, אבל מתכוננים פה ל"כח" שיטע חרשה ולא נח לעזב את
      המקום.</span
    >
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="YodHetShvat">
    <span class="c0">קרית ענבים י"ח שבט תרפד</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">ילדי היקרים צבי עשהאל אפרת ועדה</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >בכתבי לכם ביום השני לא פללתי, שעד היום לא נהיה מוכנים לנסיעה. הזמירה
      בכרמים לא נגמרה מסבת הגשם וחוץ מזה באו גפנים מחו"ל לנטיעה ואבא יצטרך
      להראות להם את אופן הנטיעה שלו ויום נסיעתנו לא נוכל בשום אופן.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span
      >מכם לא היה לי כל ימי השבוע שום מכתב, מובן שהדבר הזה גורם לי הרבה דאגה:
      מהי הסבה? הקרה דבר מה? החולה מי שהוא? מחשבות נוגות עולות על לבי והייתי
      רוצה כבר להיות בבית, אבל לעזב פה  את אבא מבלי לסדר את נסיעתו לטבריה הלא אי
      אפשר. הוא גם ככה משתמט מהנסיעה. כאן שהשלפחיות עם מים חמים, שאנו שמים בשביל
      להקל עליו את הכאב. מעיז הוא לחשב למנוע את עצמו מנסיעה, אבל בשום אופן אני
      לא אסכים לזה. מחיבים להשתדל </span
    ><span class="c5">(להקימו) על רגליו</span
    ><span class="c0"> ויפטר מסבלו.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span> בלילה העבר היתה סערה נוראה. חשבנו שהרוח </span
    ><span class="c5">****</span
    ><span class="c0"
      > את הבנינים ויעקרם ממקומם. אני כל הלילה חשבתי (עליך) אפרתי, את לא סובלת
      את הרוחות ודי לא ישנה כל הלילה. ודי גם כן (את) חושבת אודותנו- מה אנו עושים
      כאן (בסערה) זו? בחדר של הצעירות לא היה כל כך נורא. בחדר של אבא היה הרבה
      יותר גרוע. שם אין תקרה והקירות אינם מטויחים. אבל עבר הכל בשלום.
    </span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >מצב אבא בין הצעירים מפליא אותם הוא כל כך עליז ושמח, כה טוב מרגיש את עצמו
      ביניהם, כאלו הזמין לו הקבה את הגן עדן בעולם הזה. ובשבילי זה נצחון גמור, כי
      היו שנים שהוא לא האמין בכחות אחינו הצעירים, חשב הישוב מחיב להבנות ע"י
      זרים, הקול קול יעקב והידים שתהינה ידי עשו. זה גרם לו הרבה ענויים בחיים,
      וקשה היתה מלחמתי. וכעת אין די מלים בפי לתאר את חדות נצחוני, כשאבא רואה את
      עבודת "ידי יעקב", מאמין בעתידם ובכל שארית כחו ומרצו רוצה לסיע להם
      בהתבצרותם בפנת שממה זו בין הסלעים הנצחיים. כשאני מתהלכת ביניהם רואה את
      החלקות הקטנות ככף איש הן, הן לבד המסגלות לחרישה לזריעה לעבוד, ורואה את
      החבה ואת המסירות בה מעבדים את אמות אדמה אלה ומאמינים להבנות עליהן, כשרואה
      את אימת ההרים גודל יופים, יש שנדמה לי כי רוח אלהים מתהלך במקום הזה ולוחש
      משהו עתיק יומין ע"ד (על-דבר) עברו וע"ד עתידו.
    </span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >הכלם בבית בריאים __? ההולך הכל כשורה? ומה עם אדמת הטבק? אם האדמה הקרובה
      אצל נחלת יהודה לא הושגה, אל תתרחק הרבה ותטע יותר טוב על החלקה שחרשת באדמת
      סרפנד. היא היתה ___ וחרישת החורף בזמנה בערך וגם סביב לה חלקות טבק. הלא זה
      יתרון גדול. מה שנוגע לברגעסלן (אין) ברירה, אבל לכם יש ברירה ולמה תסתכנו כל
      כך?</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >בריאים הילדים? אני כל כך מתגעגעת להם? מה עושה אמא? האם לא חסר לה כלום?
      איך אסתר. בבית מבשלים רק מאכלי חלב והיא הלא אינה אוהבת תאכל נא אצל עדה
      צהרים ואני אתחשבן אתה אח"כ. ההתחיל עשהאל – במשתלות? אשר עם הזמירה ההספיקו
      בה.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום לכלכם              מאת אמכם</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">לסוביל אסתר דוד וכל השואלים עלי תמסרו שלום.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">המבקרת אותך מרים אפרת?</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c0">הגברת רבינוביץ?</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span>אני איני נוקפת אצבע חוץ מכתיבה ביום וקריאה בערב. </span
    ><span class="c5">חברות</span
    ><span class="c0"
      > טובות מקריאות לי לעתים. אני שוכבת במטה ותיכף נרדמת כדרכי.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >על ההר לצד הרפת התישבה השבוע קבוצה חדשה מ"השומר הצעיר" 14 צעירים 7
      צעירות. קרן הקיימת נותנת להם עבודה ביעור כאן. אני ואבא עשינו להם לפנות ערב
      בקור. איזה נוער נחמד איזה עדינות, איזה יופי, רוח אצילות של ישראל סבא שורה
      עליהם.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >ישנס אלוהים את מתניהם בעבודתם הקשה בבנין הארץ ובכבוש ההרים</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">הנ"ל</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >ליעדע מוטטער ליידער נאך מיכל מית זכון ארבייט נט פערטיג אונד איך</span
    >
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="kafBetShvat">
    <span class="c0">קרית ענבים כ"ב שבט תרפ"ד</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">ילדי היקרים!</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >הכל מוכן אצלנו לנסיעה, רק מחכים אנו לאויר יפה. אבא החליט לנסע באוטו
      מירושלים ישר לטבריה והנה בגשם באוטו מסוכן בדרך ההיא ואינני יודעת אימתי
      אפשר יהיה לצאת לדרך. לנסע בעצמי ולעזב את אבא שיסדר לו את הנסיעה לבדו, הוא
      לא יעזב את דילב כל כך מהר הוא יטבע בעבודה. ההיה גם אצלכם כל השבוע גשם. פה
      חוץ מהגשם טיט יון בחצר. אי אפשר להתנקות מהבוץ.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >מה נשמע בבית? הבריאים כלם. איך אמא מרגישה את עצמה? יוסי לא ראה אותה ואני
      הצטערתי מאד. את הצהרים שאכל בביתנו הוא הלל מאד. – הלא יודעת אני גם בלעדו
      שילדתי יודעת להכין מטעמים נעימים לחך.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום ונשיקות לכלם לכלם.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span>אבא עוד בכרם</span><span class="c5">. </span
    ><span class="c0">תפסו איזה שעות בלי גשם. שלום מאת אמא</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">התמונות יצאו נהדרות. פה היתה השמחה גדולה.</span>
  </p>
  <br />
  <br />

  <p class="c2" dir="rtl" id="kafTetShvat">
    <span class="c0">דילב כ"ט שבט תרפ"ד</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">ילדי היקרים אפרת, עדה, צבי ועשהאל.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >תסלחו לי שאני לא כתבתי לכם מיום השלישי העבר עד היום הזה. כל הימים האלה
      אנו עומדים מוכנים לנסיעה. הוחלט היה אצלנו לנסע ביום הרבעי וכבר ערכו פה
      בנקט לאבא ביום השלישי בערב. היה נשף נהדר, שהוא באמת יכל לשלם לאבא עבור כל
      עמלו כאן הכינו הכל בחשאי בשביל לעשות לנו הפתעה וזה באמת יצא יפה
      מאוד.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span
      >חוץ מהמנורה התמידית התלויה בתקרה, היו על השלחנות מנורות ופמוטים עם נרות
      עבים, מפות לבנות על כל השלחנות והוכן יין בכדים וכל מיני פירות ולגימות.
      הארוחה היתה של חג – בשר לביבות ממולאות מרק יפה וקומפט (תארו בנפשכם שבקבוצה
      באמצע השבוע – ארוחת ערב כזו) אחרי הארוחה התחילה סדרה של נאומים, דברו הרבה
      בשבחו של אבא. זה היה העקר של תוכן כל הנאומים. ואבא ענה להם דברים חמים
      וטובים. הוא אמר שלא נדב פה כלום, אלא סלק חוב ישן נושן להברון ששלח אותו בין
      יתר חבריו ללמוד </span
    ><span class="c3 italic">[ב]</span
    ><span class="c0"
      >ארץ ישראל – וללמד אחרי כך אחרים. והוא שמח מאד שנזדמן לו לסלק את החוב הזה.
      הציע לרשם אותו בתור חבר הקבוצה ובכל זמן ועידן שיהיה להם איזה צרך – לפנות
      ישר אל החבר הזקן שלהם. אחרי זה התחילו רקודים, שנמשכו כל הלילה.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >למחרת בבקר צריכים היינו לנסע, אבל אני כבר הרגשתי את עצמי ביום השלישי לא
      טוב וביום הרביעי לא יכלתי לקום, כי תקפתני אנפלואנציה קלה.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >הטפול פה טוב מאד חוץ מהחובשת מגישה חברה אחת את האוכל וכל מה שנחוץ לחולה.
      ביום החמישי היה פה ד"ר פרוז&#39;ינין שהוא מטעם קופת חולים רופא בקבוצה. –
      (הוא היה אצלנו בבית לפני 15 שנה.) מסר לחובשת מה לעשות לי ואני ביום השישי
      כבר קמתי והכינותי הכל לנסיעה ביום הראשון – והנה הלילה בא הגשם בצורה כזו, -
      שבדילב אינם זוכרים. הרים חלק גג חדרו של אברהם בן נריה – (הצעיר הגבוה
      שהזמין אז את הביצים אצל פרינמן) והיתה מהפכה בברקות. הגשם לא חודל. בבקר כסה
      ערפל את כל ההרים ודרך החלונות לא הכירו את דילב, דילב ההררית. הכל היה צבע
      אפור אחד. כעת רואים את ההרים והם נראים אפילו קצת יותר קרובים אל החלונות.
      אבל גשם, גשם בלתי פוסק וקר קר. אני לבושה כל בגדי החמים וקר לי. אבא בחדר
      האוכל עם הצעירים, שם שרים ורוקדים אלה, שלא יכלו לצאת לשדה ועבודה אחרת במשק
      אין להם. אבא מתקן שם מכונת תפירה אחת שנתקלקלה. אח"כ בודי ימצא עבודות
      אחרות.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >בשבת היו בקבוצה הרבה אורחים – צעירים וצעירות – ואנחנו ערכנו טיול לאחד
      המנזרים בהכפר אבו-גוש. היה לי מענין מאד הטיול, אני שאפתי אליו עוד משום זה,
      שאני ביום ששי גמרתי ספר אחד של אנטול פרנס – מחיי נזירים – "תרס" ורציתי מאד
      לראות אותם שוב פנים אל פנים.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >"האבא" הקדוש בעצמו ובכבודו נהל את כל היחברים בחורים ובחורות אל תוך קדשי
      הקדשים שלהם, איפה שמרים הקדושה עמדה על פידסטל גבוה! עטופה פרחים וחיוך קל
      על שפתיה. הבנין הוא עתיק יומין – קומתו התחתונה נבנתה במאה הרביעית, -
      העליונה במאה השתים עשרה. הנזירים עצמם בנו להם משכן סמוך לקירות המקדש.
      ומתענגים על כל טוב. איזה גנים יש להם, איזה ירקות וכרמים נפלאים, מין
      משובח!</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >ענין אותנו מאד גם המוזאם הנפלא. איך הם יודעים לסדר הכל! מה עלובים היו
      בשובנו הברקות של הקבוצה והאהלים של קבוץ "השומר הצעיר", שבאו גם הם להושיב
      את המקום השומם הזה. עלובים היו וצער תקף: באיזה קושי בונים הצעירים את נשמות
      ארצנו!  - -</span
    >
  </p>
  <p class="c7" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span>כעת, מה נעשה בבית? השבה עדה מיפו, ההבריא </span
    ><span class="c6">סבלה</span
    ><span class="c0"
      >? ואם עזרו הזריקות לעדה? מה עם התרנגולות הנותנות הן ביצים בקור הזה?
      ומהאפרחים הקטנים אין לי שום ידיעה – כמה יצאו, הסודר להם מקום טוב במוכסן,
      הגודלים הם יפה?</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >פה אינסטרוקטור אחד קרא אתמול הרצאה על גדול עופות, היה מענין מאד בשבילי.
      יש לנו הרבה מה לעשות ולבנות בשבילם במשק שלנו. אבל אי היכולת? אנחנו הזקנים
      כבר אין לנו שום תקוה לחדש מה שהוא. אתם הצעירים, עליכם (תקותי) כי תשבחו את
      המשק. זוהי נחמתי בחיים.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >את הנסיעה לטבריה מתעצל אבא לערוך לבדו, רוצה לשדל אותי שלא יעלה הרבה יותר
      אם נסע שנינו יחד והוא לא יצטרך להיות במלון אלא נקח לנו חדר בבית פרטי ונסדר
      לנו בעצמנו את הכלכלה. אני חושבת שזה טעות ונסיעת שני אנשים צריכה לעלות הרבה
      יותר מנסיעת איש אחד. בימים הקשים האלה אסור להוציא כספים מיתרים. ברוזה
      הזמין אותנו ליום אחד למוצא ואנחנו מחויבים נהיה לעשות שם תחנה בעזבנו את
      דולב. אימתי אפשר יהיה לנסע קשה לדעת. צנורות השמים כנראה נפתחו לא לזמן
      קט.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אני שולחת לכם שלום, ונשיקות לילדים החביבים הזוכרים הם את הסבא ואת
      הסבתא?</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">שלכם אמא</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">שלום רב לסוביל הנרפאו שניה? איך היא מרגישה?</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2 italic" dir="rtl">
    <span>[</span><span class="c3">כעת בא קטע ביידיש – לא הצלחנו לפענח!</span
    ><span class="c0">]</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אסתר יקרה! למה את לא כותבת לי כלום כל הזמן? השכחת את הדודה שלך. איך הם
      הלימודים כעת במחלקה השוה את עתה כבר בכל? מי עושה אתך יחד כעת את השעורים
      בבית – ומה את תופרת בשעת הגברת שרה?</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >לאדון אמיתי תמסרי שלום והגידי לו שאני דורשת גם בשלום אשתו ומרילו
      שלו.</span
    >
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >אם נחוצים לך כספים בודי את לא מתבישת לדרש מאפרת. ההביאו לך את הבכורים? אם
      עוד לא אז כתבי לי תיכף ואביא לך מירושלים. בודי את חופפת את ראשך וגדלו
      השערות יפה ואת לובשת את הכבע החדש.
    </span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">אבל מדוע את לא כותבת כלום? זה מצער אותי מאד.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">שלך באהבה דודתך נחמה.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0"
      >דוד! מה שלומך אתה – השכחת כבר את החולשה ואת המחלה? ומה נשמע מהרצפלד האם
      היה בראשון ודברת אתו ע"ד ההתישבות? בחדרך בודי קר מאד בימי הגשמים האלה. יש
      כעת מכסות מיתרות בבית קח לך עוד אחת והתכסה.
    </span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">אקוה לראותך בריא ובמצב רוח טוב.</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">אדון פוחצבסקי דורש בשלומך בידידות,</span>
  </p>
  <p class="c1" dir="rtl"><span class="c0"></span></p>
  <p class="c2" dir="rtl">
    <span class="c0">נ. פוחצבסקי</span>
  </p>
</body>`;

export default page;
