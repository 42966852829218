const desc = `<html>
  <body class="c2 doc-content">
    <p class="c6" dir="rtl">
      <span class="c0 c1">נחמה פוחצ'בסקי ז"ל</span><span class="c0"> </span
      ><span class="c1 c3">-</span
      ><span class="c3 c1 c5 bold"
        > עִתּוֹנֵנוּ: עִתּוֹן לַיֶּלֶד וְלַנָּעַר</span
      ><span class="c3 c1"> [8.06.1934</span><span class="c0 c1">]</span>
    </p>
  </body>
</html>`;

export default desc;
