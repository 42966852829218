const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c2 text-center"><span class="c8">– א –</span></p>
    <p class="c1">
      <span class="c0"
        >היטב חרה לה, לאָפיָה, כשבבית האשכנזי, שהיא עובדת בו, עלבו את מוסה שלה.
        הוא נכנס לבקש ממנה את המפתח ופניו מכוסים פיח, ראו אנשי הבית ופרצו בצחוק.
        נתבלבלה אפיה, שהיתה מנגבת אז צלחות לאחר השטיפה, ליבה התחיל דופק בחוזקה
        וידיה רעדו עד כדי כך, שהִפילה צלחת, שנשברה לרסיסים. היא פרצה בבכי ולא
        שמה לב לשברי-הכלי, ליבה רתת לכבוד בעלה שניפגע כאן לעיניה באופן
        אכזרי…</span
      >
    </p>
    <p class="c1" id="h.gjdgxs">
      <span class="c0"
        >ולאחר ששבו ידיה לעבוד, עוד ליבה קובל: “מה בכך אם חזר אדם מעבודה קשה
        במכונות ופניו הם כשולי קדרה? יתרחץ ויהיה יפה כמוהם! וכי מן היושר הוא
        להתפרץ בצחוק בפני אדם כזה ולשימו ללעג ולקלס? הלא מוסה שלה בקי בתורה
        ובעבודה יותר מבעל הבית הזה. הלה אפילו עד קרסוליו של תלמיד חכם שלה אינו
        מגיע וצריך הוא לנהוג בכבוד באדם היושב על התורה ועל העבודה!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >התמרמרותה אינה פוסקת: עניים מרודים, בחסד ניתן להם לשבת באחד מן המחסנים
        שבחצר. מה בכך אם ירמסו לארץ כבודם?… לא! היא צריכה להחליט פעם אחת ולעזוב
        את המקום הזה. אינה יכולה לסבול עוד יסורים כאלה!</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >אבל כאן היא נזכרת בעליזה הקטנה וליבה מתכווץ מכאב. האותה תעזוב?…
        מחשבותיה מוליכות אותה לחדר המיטות היפה. שם בעריסתה הלבנה, ישנה החביבה…
        עוד מעט ותקיץ משנתה ותקרא בקולה המתוק: “לפיה, לפיה!” הָאֵם תיגש אליה
        להוציאה מן העריסה והילדה תמאן, אל אפיה תשוקתה!…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >יקראו לה. הילדה תתפרץ אליה בשמחה, תפרוש כפיה, תחבק ותלטף את צווארה. אל
        רחום! תשלום לצערה, ליסוריה, נתן לה בזה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >יסורים! לא אלה בלבד! הנה התחיל מוסה להזכיר לה לעיתים תכופות את האסון
        הצפוי לה בחיים. “אסור”, הוא אומר, “לשבת בלא בנים!” לא יוסיף ללכת ערירי
        ויקח לו, בעזרת השם, ילדה!… הוי, ישמור השם, צרה יקח. צרה?</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >ואפיה קונחת דמעה אחר דמעה בקצה שרוולה העבה ורוצה לנחם את עצמה. ודאי לא
        חושב לעשות כך, ואך כדי לצער אותה בשעת כעסו מדבר מוסה דברי-שטות אלה!… הוא
        כועס מאוד כשהיא מרבה לטייל עם הילדה “שלה”. פעם אחרה קצת לשוב והוא לחש
        ברוגז כל הערב, שבבית לא שוכנות רגליה, שהיא פורצת גדר, ועוד, ועוד. היא
        ניסתה להצטחק, והוא אומר לה בכעס: “הרי בית המקדש חרב וזו ממלאה צחוק פיה!”
        ופעמים שהוא נאנח וקובל: “מלך אין בישראל ואיש את הישר בעיניו יעשה!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >אבל רוח אחרת שורה על מוסה שלה בשעה שהיא מקדימה לבוא מן העבודה, אופה לו
        את הפיתות והוא מוצא מוכן לפניו קומקום של קהווה חם וספלו בצידו. מיד נעשה
        ליבו טוב עליו. הוא מתרחץ בהנאה, מחליף את בגדיו, מתקין לו את המקטרת
        הגבוהה עם השפופרת הארוכה, מוציא ספר ויושב בשביעות-רצון מרובה על כר-הקש,
        שסידרה לו בחוץ סמוך לעץ העבה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >גמר לימודו הוא עוזב מקטרתו ונוטל לו את ספל הקהוה. גומע ממנו ומעיף עין
        באפיה במטפלת בבישול. היא מתחלת לספר על ענייני הבית של גבירתה, על הא ועל
        דא, והוא נהנה מניצוצי האש של עיניה הבוערות בשעת התעוררותה. מסתכל הוא
        בזקיפות גופה ונהנה! היפה בבנות אפיה! אכן, מן השמיים סייעו לו לקנות אותה.
        עשר ריאליות שילם, אך בעשר ריאליות – ילדה כזו! אכן, שוטה היה בעלה הראשון
        שגרש אותה בעודה קטנה!</span
      >
    </p>
    <p class="c1">
      <span class="c0">עולה על דעתו לשאול אותה:</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“ילדה, כבר יודעת אַתְּ את האלף-בית, שמלמדת אותך הגברת שלך?”</span
      >
    </p>
    <p class="c1">
      <span class="c0">“לא מוסה! השדה הקטנה שלנו קרעה אותו לגזרים”.</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“טוב מאוד, ברוכה תהיה הקטנה!” אומר מוסה בשמחה… “לא צריך, לא
        צריך!”</span
      >
    </p>
    <p class="c1">
      <span class="c0">“למה לא צריך?”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“מפני שכתוב מפורש: ‘כל המלמד את בתו תורה כאילו מלמדה תיפלוּת’”.</span
      >
    </p>
    <p class="c1">
      <span class="c0">“תיפלות? מה זה?” שואלת אפיה בסקרנות.</span>
    </p>
    <p class="c1">
      <span class="c0"
        >הוא מדבר על זה ארוכות ורוצה להטעים שהאישה אינה ראויה ללמוד תורה, מפני
        שהחטא כרוך אחריה והיא מוכשרת לקלקל בשעה אחת מה שהגבר מתקן בתפילותיו
        ותורתו יום תמים.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >דעה כזו מוטעית היא לגמרי בעיני אפיה, אבל להתווכח על זה אין לה מילים.
        היא רק מבטאת קצרות:</span
      >
    </p>
    <p class="c1">
      <span class="c0">“יסורין!”</span>
    </p>
    <p class="c1">
      <span class="c0">אחר כך היא חושבת קצת ושואלת:</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“במה אם כן, ניתן דין וחשבון לפני שמו יתברך? אם תורה לא לימדונו ומעשים
        טובים להכין אין לנו פנאי מפני השעבוד. במה נתייצב לפניו בעולם
        האמת?”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >מוסה נהנה הנאה מרובה מחכמת “ילדתו”, מחליק על גבה ומחייך, כשהמקטרת שוב
        בפיו. יושבים הם זה אצל זה על שק אחד ומתחילים לטבול את פיתם בקערית אחת
        קטנה שמעט מרק של “חילבה” בתחתיתה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >בין עצי הקאזוארינות שאצל הגדר מפזזות קרני-פז וקורי-זהב עם אזמרגדים
        נוצצים בין ענפיהם. מעבר לכרמים מתחלת החמה שוקעת לתוך החולות. אך נגעה בהן
        בשפה לוהטת, וירדה ונעלמה בפזיזות.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >ומן המעבר השני, מאחורי השדות, לבשו הרי יהודה ארגמן, הבליטו את
        חזה-תפארתם הטובל באור וורוד וחיש מהר פשטו את האורות וכהו. רוח קלילה נשבה
        מן השער והזוג ישב זה בצד זה בחיבה ובדבקות.</span
      >
    </p>
    <a id="id.30j0zll"></a>
    <p class="c1"><span class="c0"> </span></p>
    <p class="c2 text-center"><span class="c8">– ב –</span></p>
    <p class="c1">
      <span class="c0"
        >באחד הימים נפל דבר, שעוד מעט והרס את קינם. ילדי השכנים “השדים הקטנים”
        הללו, שידם בכל, פתחו את הלול הקטן, מעשה-ידיו של מוסה, והוציאו לחופשי את
        התרנגול והתרנגולת, שהתעלסו שם באהבים זה כשבוע ימים. הסתתר הזוג ועקבותיו
        לא נודעו. כל הבוקר חיפשה אותם אפיה בין השיחים ובין ערמות הזרדים, בין
        הזמורות ובין ענפי השיטה, גם בכל החצרות והאורוות והמזבלות שבשכנותם –
        חיפשה ולא מצאה. בכליון עיניים ציפתה שיבוא מוסה בצהריים מן העבודה; אפשר,
        יושיע לה הוא. והנה כשנודע לו הדבר, לא עזר לחפש, אלא התנפל עליה
        באכזריות-חימה והיכה אותה במקלו…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >היא לא צעקה, לא השמיעה קולה. רק סגרה את הדלת ואת החלון הקטן והתחבאה
        מעין-רואים. אחר-כך, כשהלך מוסה, לא שבה לעבודה לבית-גבירתה, ישבה כששתי
        ידיה על ראשה והרהרה. לאחר שעה ארוכה קמה, ארזה את חפציה, נטלה את החבילה
        והתגנבה אט מן החצר, שלא ירגישו בלכתה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >מוסה חזר לפנות ערב, כרגיל, ניגש ישר אל הלול ותימהון של שמחה תקפהו: זוג
        העופות ישב לו על המוט, ורק אמר לקפוץ ולברוח שוב כשניגש מוסה לשם. מוסה
        מיהר לסגור את הדלת עליהם וגולל אבן גדולה על פיה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >אפיה אינה במחסן, בוודאי היא מטיילת עם הילדה שלה! עברה שעה, שעתיים –
        איננה. הוא עולה לבעלי הבית: הם אינם יודעים כלום, ומתפלאים שלא באה אפיה
        אחר הצהריים. סוברים הם, שהיה לה איזה עניין אצל התימנים. מוסה רץ לשם – לא
        מצא אותה בשכונה. התחיל רץ מבית לבית, עד שנודע לו שראו אותה בלכתה לבדה
        בדרך העירה וחבילה על שכמה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >בלב מלא כעס ותמרורים שב מוסה לחדרו האפל, נפל על משכבו והתלבט ביסורים כל
        הלילה; ביקש תחבולות כיצד להשיב אליו את הבורחת. לבסוף החליט מה לעשות. הוא
        קם עם עמוד-השחר, התפלל, הזדיין באקדוחו, שם בכיס של עור את כליו ושם לדרך
        פעמיו.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >יצא כשאפלולית קלה עוד כיסתה את הסביבה. עבר כרמים ושדות-בר, וכשהגיע עד
        פרשת-הדרכים כבר טבלו הרי יהודה בים של זהב. פרצופו של מוסה הביע עוז
        וגבורה כשהוא מהלך זקוף ומזויין באקדוחו, שהוא תקוע בחגורת-עור מלאה
        תרמילי-חיצים. הלך בצעדי-און והרהר: איזו טפשה, איזו טפשה! כלום להרוג אותה
        אמר, חס ושלום? הלא רק אילו חבטות במקלו העביר על גבה ולא עוד – כלום בשביל
        דבר של מה-בכך צריכים לברוח למרחקים?… על צד האמת רחמנות על הילדה! היא
        חוששת, שבוודאי אֲבֵדָתָם לא חזרה, ולא עלה על דעתה, ששב הזוג למקומו.
        עכשיו כבר סגר עליהם היטב היטב, וה“שדים הקטנים” לא יוכלו לפתוח את הלול
        בשום אופן!</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >ומתוך רחמים על הילדה המוכה הוא נודר נדר להתנהג עימה להבא ברחמנות ולא
        להכותה. אסור להכנע ליצר הרע שמסית לעבודה זרה! אסור באמת להכות ילדה כזו!…
        וכאן מוסה נותן אל ליבו לקנות בעיר מתנה יפה לאפיה. אישה כלי זינה עליה
        וצריך לפייסה!…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >מהלך-מחשבתו פסק כשמתוך יער-הזיתים יצאה ונהרה לקראתו חבורה גדולה של
        פועלים ערביים, שהולכת לעבודה במושבה העברית. בלי משים מישמש את
        תרמיל-חיציו שבחגורה מתחת למעילו.</span
      >
    </p>
    <p class="c1">
      <span class="c5">“מרך הבא, נהרך סעיד![</span><span class="c7">ftn1</span
      ><span class="c0"
        >]” מברכים אותו אילו מן הפועלים. והוא משיב ולא משיב מתוך פיזור-נפש. הוא
        סר קצת הצידה והתחיל מחיש צעדיו, כי נזכר כאן בבן-עירו שנהרג אשתקד כשעבר
        לבטח דרכו כמוהו היום!… הוא קובל בליבו על הרשות: כיצד זו עד היום לא מצאה
        את הרוצחים ועקבותיהם לא נודעו. אצלם בתימן, הממשלה זריזה הרבה יותר: מיד
        היא עושה חיפושים והרוצח לא ימלט מידה. אבל אם במקרה לא תצליח, הרי יש לבני
        אדם דרך בטוחה לדעת את הרוצח.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >רטט עבר בגופו כשנזכר במעשה אחד, בשעה שידו היתה בחיפוש כזה: הימים ימי
        קציר בעָדֶן – שם. עליו נפל הגורל לסייע בדבר ולהוודע מפי המת – מה שם
        רוצחו. ליל אופל. הוא וחברו חפרו, הוציאו את המת מקיברו ונשאו אותו אל הר
        תלול. הניחוהו, התרחקו ממנו כמטווחי-קשת, ישבו בעצירת-נשימה והמתינו… הוא
        נתבלבל ולא ראה כלום. אבל חברו העיד, שציפור ירדה במעוף ממרומים, פרשה
        כנפיה על המת וציפצפה על אוזנו… שלטה דומיה מסביב. פתאום נשמע כאוב מארץ
        קולו של המת, שגילה לציפור את כל סיפור-המעשה: כיצד הלך בדרך, לאן היו פניו
        מועדות ומי רצחו נפש. אחר הדברים האלה החזירו את המת לקיברו וסתמו עליו את
        הגולל לנצח. וכשנתגלה הסוד לגואלי-הדם, כבר ידעו ברור במי לעשות
        שפטים!</span
      >
    </p>
    <p class="c1">
      <span class="c5"
        >מן הזכרונות הרחוקים והנוראים חוזר מוסה אל המציאות ובפסיעות גסות הוא
        ממהר אל ילדתו. צועד הוא על הכביש בדרך-המלך, פוגש בשיירות של גמלים
        טעוני-משא; בחמורים מובילים ירקות בארגזים, ורוכביהם עליהם; בערביות נושאות
        על ראשיהן סלים מלאים בקבוקי כותח</span
      ><span class="c9"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://benyehuda.org/read/6658#fn:1&sa=D&source=editors&ust=1696061568856958&usg=AOvVaw0W1IS4pq3El9tUk31EnHuk"
          >1</a
        ></span
      ><span class="c0"> למכור בשוק.</span>
    </p>
    <p class="c1">
      <span class="c0"
        >התנועה רבה אל העיר ומן העיר. והנה הפרדסים. מבין משוכות הצבר והשיטה
        נראים עצי תפוחי-זהב ופריים, שאך התחיל מתפתח. עובר מוסה על-יד שער פתוח
        ומעיף עין פנימה: שדרת רימונים מוליכה אל פלטרין יפה, זוג של דקלים מתנשא
        בשיא גובהו מעבר לבניין-קסם שבעומק הפרדס. ריחות חריפים מתנדפים והשמש
        בוערת בין הגדרות הירוקות והגבוהות. מוסה כולו שטוף זיעה. אך הנה העיר והנה
        השוק. כאן הוא קונה בשביל אפיה אריג-צבעונים לשמלה, שני זוגי גרביים חומים
        ואָמָה של סאטין שחור לקישורים על מצחה, ובדחילו ורחימו הוא נושא כל זה
        לבית אחיה. אבל מה גדל תימהונו כשבכל מתנותיו לא נתפייסה אפיה! וגם אחיה
        עמד כצר לו: הוא דרש עשר לירות קנס על המכות שהיכה את אחותו!</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“כאן,” טען האח, “יש רב ספרדי, שמגן על כבוד האישה. אינו נותן רשות להלקות
        אישה כמו שנוהגים בתימן. כאן דינים ומנהגים אחרים בחיי האישות. ואפיה לא
        תשוב אם לא תשלם את ה קנס!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >כל טענותיו והצטדקותו לא הועילו. העניין נסתבך מאוד והוא לא ראה לפניו דרך
        להחזיר את המורדת. “פוטר מים ראשית מדון”, ממלמל מוסה וחורק שיניו כשהוא
        משוטט בין תימני-העיר, ומבקש עיצות מפיהם. חכם אחד, שעוסק בניסתרות, שלח
        אותו לבקש אחר אדם ארוך, שרוב פניו עגולים ובהם נקבים נקבים, אות “ת” בשמו,
        ולו יש קרובים ואוהבים רבים, והוא יושב בצד צפון ומזלו מזל-חמה. אצל אדם זה
        אפשר למצוא סגולה להשיב לב נשים על בעליהן…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >עברו שבועיים בהתלבטות מרובה וביסורים לשני הצדדים, עד שעלה בידי אנשים
        טובים להשלים בין הזוג. נתפייסה אפיה ונתרצתה לו. אבל הביתה לשוב לא
        הסכימה. כי על פי עצתן של חברותיה אמרה להישאר לחודש ימים בעיר כדי להתרחץ
        במי-הים. “הים בונה עקרות”, אומרות האשכנזיות; ודאי חכמות הן ויודעות את
        סוד הלידה!</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >מוסה שב לבדו אל המושבה ואפיה התחילה יורדת ערב ערב אל שפת-הים להתרחץ.
        בפינתה של סוכת-רוחצים היא פושטת את בגדיה, לובשת חלוק כחול שנשאר לה יחידי
        לפליטה מכל בגדי-תימן, יורדת מן המדרגות, רצה עד המים, נכנסת בזהירות
        וכורעת לפני הגל. הלה, עז-פנים, רוצה להפכה: היא נפחדת, מטילה את עצמה על
        החול המכוסה קצף ואוחזת ביד אישה זקנה. הצעירות לועגות לה ואומרות להחזירה
        בחזקת-היד אל המים. אבל היא נלחמת בהן ומגינה על עצמה כשהיא זורקת עליהן
        מלוא חופניים צמחי-ים, שהביא אל החוף גל זועף.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >לאחר הרחיצה, כשנתלבשה גם הידקה את מצחה בקישור-הסאטין החדש וסרט אדום
        באמצעיתו, נשארה יושבת שעה ארוכה בין כנופיית המשרתות, שבאו לטייל עם ילדי
        גבירותיהן. אפיה נזכרת בעליזה “שלה” וגעגועים עזים תוקפים אותה: מה טוב היה
        לשבת כאם עם היפהפיה הקטנה! וכדי להפיג את צערה היא קונה מנת “גרעינים” מן
        המוכר הערבי, מחלקת בין חברות וגם היא מפצחת מהן כשהיא שולחת את מבטה הלוהט
        על פני השטח הכספי של הים.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >סערה קלה. הגלים אפורים, כהים; רק כאן, אצל החוף, הם לבנים בשצף-קצפם.
        המערב מכוסה עננים אפורים, שביניהם משתקפת תכלת הרום. פתאום הופיע מבין
        העננים כדור-אש גדול והצית להבות על פני הים. קרניים ארוכות נוצצות יוצאות
        מן הכדור, עוברות מעל לעננים ומגיעות עד חלקת הרקיע הזך והטהור מכל
        ענן.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >הנה טבלה החמה בקצה הים, צללה אט ושקעה. רק קו בהיר-אדמדם אחד נשאר בין
        העננים. אבל בעוד רגע – גם הוא איננו. כל זהב העבים נעלם וענני האופק נשארו
        כהים ועצובים כיתומים.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >חודש ימים של רחיצה עבר מהר ואפיה הולכת לשוב אל המושבה מזויינת בכל טוב:
        קדרת-נחושת מלובנת יפה-יפה והמכסה המבריק שלה הודק אותה בחן; קערת פח מלוטש
        ללוש בה את לחם חוקה; שתי צלחות של קוניה מצויירות פרחים וורודים, ושתי
        כפות שהן מבריקות ככסף טהור ממש. עם כל “כבודה” זו אין אפיה הולכת ברגל,
        אלא נוסעת בדיליז’אנס, ובזה היא חשה מעין שמץ גאוה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >וכשבאה אל מחסנה תקף אותה צער גדול, כי מיד נודע לה שמשרתה אינה פנויה
        עוד: במקומה לוקחה ערביה שהגברת שמחה בה ולא תפטר אותה. וביחד עם המשרה
        אבדה גם דירתה. כבר קיבל מוסה הודעה לפנותה, אלא שביקש שהות עד שתחזור היא,
        אפיה. עלבון וצער תקפו אותה ובכתה כל הלילה. מוסה ביקש לנחמה ולא
        יכול.</span
      >
    </p>
    <a id="id.1fob9te"></a>
    <p class="c1"><span class="c0"> </span></p>
    <p class="c2 text-center"><span class="c8">– ג –</span></p>
    <p class="c1">
      <span class="c0"
        >סוף הקיץ. החום גדול ביום. רק בערבים נושבת רוח קרירה. והמרתף, שאפיה דרה
        בו זה חודשיים, פולט חום כזה שהיא אינה יכולה לישון בלילות ורע לה, רע לה…
        לעיתים היא קופצת בחצות-לילה ובורחת החוצה כדי להקיא. מוסה שומע, קם ויוצא
        לעזרתה. עטופה שמיכה קלה היא יושבת על-יד אחת מן הקאזוארינות לשאוף אויר.
        מוסה צונח על-ידה. היא סומכת את ראשה שחור התלתלים, שעין אינה רואה אותה
        ביום, על זרועו ועיניה ננעצות נוגות בנקודה אחת.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“למה יַא וּלְדָה, ליבך כל כך שרוי בצער תמיד? אם כמהה נפשך לילדה,
        לעליזה, זה אסור! עבודה זרה אסור!”</span
      >
    </p>
    <p class="c1">
      <span class="c0">ומוסה מלטף אותה בידיו, שקרום עורן קשה ושחור.</span>
    </p>
    <p class="c1">
      <span class="c0"
        >הפעם לא התאפקה עוד ומתוך דפיקות-לב חזקות גילתה לו סוד: – הם לא ישבו
        ערירים!</span
      >
    </p>
    <p class="c1">
      <span class="c0">הוא קם כהרף-עין והציץ בה בתימהון:</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“ילדה, מה את סחה, ילדה! האמנם ריחם השם? ואני לא ידעתי. שמו יתברך לא נתן
        לי בינה בדברים כאלה!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >הוא לא ידע מה לעשות בגופה הגמיש שהיה מוטל עטוף בחיקו. היה לו רצון
        להרימה על כפיו ולעוף עימה כצפור-דרור, אבל נשאר יושב עם משאו היקר וליבו
        ושפתיו רוחשים תפילה. הוא התכופף אליה. עדיין עצבוּת שורה על פניה.</span
      >
    </p>
    <p class="c1">
      <span class="c0">“על מה ליבך מתעצב, ילדה?”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“חלומות רעים מבהילים אותי והלב אינו פוסק מלנבא לי רעה שיום יבוא ואתה
        תישא צרה עלי!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“חס ושלום יא ולדה! מה עולה על דעתך עכשיו, כשהשם יתברך ראה בעוֹנְיֵנוּ
        ושלח לנו ברכה?”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >היא השליכה פתאום מעליה את השמיכה, נעצה במוסה זוג של עיניים בוערות
        ומלמלה בחטיפה:</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“לא עכשיו, אלא אחר-כך, כשיקרני איזה אסון: אני אחלה או הילד ימות!”</span
      >
    </p>
    <p class="c1">
      <span class="c0">“ישמור השם, ישמור השם!” לוחש מוסה בצער.</span>
    </p>
    <p class="c1">
      <span class="c0">והיא מוסיפה לדבר בקול חרד:</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“אבל דע לך, מוסה, שאם עשה תעשה כדבר הזה ותיקח צרה עלי, אז עדים העצים
        האלה ועד גל-האבנים הזה, שאחנוק אותה! עד מוות אחנוק את צרתי!…”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >ונמשכו עוד ימים ולילות קשים לאפיה: לעבודה אינה מסוגלת עוד, כי הכפיפה
        קשה עליה; לאכילה אין נפשה – הכל היא מקיאה ובוחלת היא בכל. תשוקה יש לה אך
        לרקיקי-דוּרה אפויים בשמן, כמו שהיו מכינים בעדן לשבתות; אלא שאין כאן
        התנורים המיוחדים לאפותם. מוסה חיפש חומרים שונים לבניין תנור כזה. לא נח
        ולא שקט עד שהתקין אותו תנור, ואפיה אפתה לאחת מן השבתות את הלחם האהוב.
        אבל לחם זה הזיק לה: אכלה ממנו לשובע וחלתה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >ועצבותה הולכת וגדלה עם כל חודש וחודש. וכשמוסה מוכיח אותה, כדרכו, על
        החטא שהיא חוטאת כלפי השם יתברך, היא משיבה:</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“כלום איני מקבלת, חס ושלום, באהבה את מתנתו?… אבל מה לעשות כשהשטן מקטרג
        ושולח לי חולי, יסורים ופחד?”</span
      >
    </p>
    <p class="c1">
      <span class="c0">“איזה פחד? משום מה את חוששת ילדה?”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“חוששני, שמא המיתה תבוא עם הלידה!… כל פעם שמתרוצץ הוָלָד בקרבי ומכה
        בבטן, נדמה לי שאיזה שד קטן כרוך שם עימו יחד, וכשיבוא היום תיגבר יד השד
        על ידו של בן-האדם!”</span
      >
    </p>
    <p class="c1">
      <span class="c5">מוסה רָק</span
      ><span class="c9"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://benyehuda.org/read/6658#fn:2&sa=D&source=editors&ust=1696061568862597&usg=AOvVaw1BfLxqIZA9ULOvrdZDXwrM"
          >2</a
        ></span
      ><span class="c0"> מתוך כעס, גער ונזף בה.</span>
    </p>
    <p class="c1">
      <span class="c0"
        >לילה. היא שוכבת ערה ומקשבת לכרכורים שבבטנה. תחת ליבה לוחץ ומעיק דבר-מה.
        היא חושבת שזהו השד. זהו האסון שלה, שחוזר ומתגלגל אל תחת ליבה. חולשה
        נוראה תוקפת אותה והיא רוצה לצעוק ולקרוא לעזרה; אבל מרחמת היא על מוסה
        ומתאפקת מצעקה. היא נוטלת כוס מים קרים ומשתדלת להרדם.</span
      >
    </p>
    <p class="c1">
      <span class="c0">אשמורה שניה. נרדמת לשעה קלה ומתעוררת מתוך ליטופים…</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“אי אפשי, אי אפשי!” לוחשת אפיה מתוך כאב עצור. אבל מוסה אינו מרגיש
        ביסוריה ואינו חש בענות נפשה, וברתת תאוותו הוא שובר את חוזק הצימאון
        שלו…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >לעיתים עוד ניסתה אפיה להרוויח פרוטה. היתה יוצאת לעבודה. אבל על פי רוב
        היתה שבה מוּרגזת מאוד ומרבה בטענות: למה עזבו את תימן, את בית אביה בעדן –
        שם? הלא אדונים היו, בעלי בתים וקרקעות. בין עשירי העדה נחשבו, וכאן נהפכו
        לעבדים נמכרים!… עמוסים הם עבודת-פרך ועוד מתקלסים בהם. לשימצה שמים
        אותם!…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >היא מצאה בית קטן וניסתה לעשות חוזה לחודש ימים, אבל עד מהרה ברחה משם. כי
        נכנס פעם צעיר אחד ושאל:</span
      >
    </p>
    <p class="c1">
      <span class="c0">“זוהי שפחתך, גבירתי?”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >ומיד עזבה את הבית ההוא. לא יכלה לסבול את החוצפה שבשאלה ושבה בוכה
        למרתפה. “שפחה!” “שפחה!” – מילה זו כחרב-פיפיות גזר את ליבה. היא בכתה מר
        וקבלה: “הזאת היא ארץ-ישראל, שהלכנו להיגאל בה?”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >ומוסה משדל אותה לקבל את היסורים באהבה: – משיח לא יבוא אלא אם כל הדור
        יהא כולו זכאי, והיסורים באים למרק חטאים…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >כשהונח לה, התחילה להעסיק את מוחה בשאלה: מה תלד – בן או בת? רוצה היא בבת
        דוקא, שאם לא תהיה יפה כעליזה, תהיה נא כתירצה בת אביגיל! בדמיונה כבר
        גידלה את הבת עד שהגיעה לפירקה… היא לא תמכור אותה בכסף כדרך התימנים! היא
        תיתן לילדה לבחור ארוס כלבבה. כמה יפה מנהג זה אצל האשכנזים! וכי לא שטות
        הוא מצד אלה מן התימנים, שלועגים לכך?</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >פעם בערב שבת עם חשכה, תקפוה חבלי-לידה חזקים עד שלא יכלה לזוז
        ממקומה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >כל הלילה ההוא, ליל שבת, התלבטו אצלה תימניות. היא התקשתה בלידתה והן אחזו
        בכל מיני תחבולות ולחישות לאיים על הולד שיצא לאויר העולם. אבל השטן עיכבהו
        והוא התעקש ומאן לצאת. כלה כוחה של אפיה וחדלה לצעוק, ורק נאנחה חשאית
        כשהיא זוקפת אצבעותיה כלפי שמיים, נוגעת בהן בקצה חוטמה ושפתיה
        לוחשות:</span
      >
    </p>
    <p class="c1">
      <span class="c0">“הוי אלי, יא אלי, יא אלי!”</span>
    </p>
    <p class="c1">
      <span class="c0">דודה אחת יושבת לרגלי המיטה ומתפללת:</span>
    </p>
    <p class="c1">
      <span class="c0">“פתח השם שערי רחמים, פתח!”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >בתחילת הלילה קראה אפיה למוסה. ביקשה אותו ללכת ולקרוא לגברת, לאם-עליזה.
        הוא סרב. שבת היום, והאשכנזיה לא תשגיח בחילול שבת. אבל כאור הבוקר הוא לא
        יכול עוד לעמוד בפני תחנוניה והלך להזמין אותה נגד רצונו.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >הגברת נזדרזה להתלבש ושלחה את מוסה להביא את המיילדת לביתו. הוא סרב אף
        כאן:</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“לא צריך, לא צריך! שבת היא מלזעוק, ורפואה קרובה לבוא – השם ירחם! בידו
        ניתנו שלושת המפתחות: של גשם, של תחיית-המתים ושל חיוּת!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >הגברת הביאה את המיילדת והן נכנסו שתיהן יחד. כשאפיה ראתה אותן התפרצה
        בבכי גדול, תפשה את ידיהן, נשקה את ידי כל אחת מהן והתחננה בקול קורע
        לב:</span
      >
    </p>
    <p class="c1">
      <span class="c5"
        >"תנו לי מעט סם, תנו לי מוות. הלא חיים אין לי, אין! "בחדר עמדה צווחה:
        “יא אבא, יא אלי! רחום וחנון – רחם עליה! אב שבשמיים, שלח רחמיך, היושב על
        כסא-רחמים – רחם עליה! אַללָה כַּרִים</span
      ><span class="c9"
        ><a
          class="c6"
          href="https://www.google.com/url?q=https://benyehuda.org/read/6658#fn:3&sa=D&source=editors&ust=1696061568865818&usg=AOvVaw2qZNhHTPPYCb9MA7xNS2Kq"
          >3</a
        ></span
      ><span class="c0"> פתח שערי רחמים!”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >המיילדת גרשה את המקוננות, דרשה כלים, הכינה מים חמים וניגשה
        לעבודתה.</span
      >
    </p>
    <p class="c1">
      <span class="c0">וּבינתיים אפיה מוסיפה להתחנן בלחש:</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“הוי מיתה, מיתה תני לי! מעט סם, רק מעט סם – השם יכפר לך!”</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >המיילדת מסרה פתקה למוסה וביקשה להביא את הרופא. מוסה שוב מסרב:</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >“אני לא רוצה במלאכי-חבלה! שבת היום! אין כאן פיקוח-נפש! וברופא אין
        ממש!”</span
      >
    </p>
    <p class="c1">
      <span class="c0">וכשהגברת הביאה את הרופא ברח מוסה מן הבית כמטורף.</span>
    </p>
    <p class="c1">
      <span class="c0"
        >הרופא חגר סינור לבן וניגש לאפיה. זו תפשה את ידו והתחננה גם לפניו:</span
      >
    </p>
    <p class="c1">
      <span class="c0">“מעט סם, אדוני! מעט סם – הלא חיים אין לי!”</span>
    </p>
    <p class="c1">
      <span class="c0"
        >“יתנו לך סם, אפיה, אבל סם-חיים יתנו, להקל על יסוריך!” והוא מחליק את ידה
        הרזה ומנחם אותה.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >הובאה גם חובשת לעזרה, ליבנו באש את המכשירים, השיגו שולחן וניגשו
        לעבודה…</span
      >
    </p>
    <p class="c1">
      <span class="c0">הילד שהוצא – היה מת.</span>
    </p>
    <a id="id.3znysh7"></a>
    <p class="c1"><span class="c0"> </span></p>
    <p class="c2 text-center"><span class="c8">– ד –</span></p>
    <p class="c1">
      <span class="c0"
        >כעבור שישה חודשים הבריאה אפיה ממחלה קשה, שתקפתה אחר הלידה והנה כוסו
        שמיה עננים וקדרו לנצח: מוסה לא התפשר עם מיתת הילד, שבא מתוך מה שנשמעה
        אפיה לאשכנזים, ולא סלח לעוון של חילול השם וחילול שבת ביום הלידה. הלך
        וארס לו ילדה, בת דוד, שבא זה עתה מתימן, והתכונן להיכנס עימה לחופה…</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >שמים וארץ הורידו דמעות על אפיה ואסונה. וכשהוכיחו בני-אדם את מוסה על
        העוול הגדול שהוא אומר לעשות לה, השיב באנחה:</span
      >
    </p>
    <p class="c1">
      <span class="c0">" ‘מהשם מצעדי גבר ואדם מה יבין דרכו!’ "</span>
    </p>
    <p class="c10" dir="rtl"><span class="c4"></span></p>
  </body>
</html>`;

export default page;
