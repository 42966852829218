const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c7" dir="rtl">
      <span class="c3"
        >פורסם בגליון תמוז-אלול תרפ"ג [יוני 1923] של הרבעון הספרותי העברי </span
      ><span class="c0 c6 bold">התקופה</span
      ><span class="c3"
        > שיצא לאור במוסקבה. אחר כך הכלילה אותו נחמה פוחצ&#39;בסקי בספרה </span
      ><span class="c0 bold">בכפר ובעבודה</span
      ><span class="c3"
        > שיצא לאור בשנת 1930 בהוצאת הדים. קטע מהסיפור פורסם אחרי מותה ב</span
      ><span class="c0 bold">דבר הפועלת</span><span class="c0 c4"> </span
      ><span class="c3">26.6.1934 תחת הכותרת </span
      ><span class="c0 bold">באספה</span><span class="c3">. (</span
      ><span class="c1">הסיפור נכלל גם בספר </span
      ><span class="c0 c6 bold">שאני אדמה ואדם</span
      ><span class="c0 c4 c6"> </span
      ><span class="c0 c6 bold">– סיפורי נשים עד קום המדינה</span
      ><span class="c1"> בעריכת יפה ברלוביץ, הוצאת הקיבוץ המאוחד, 2003</span
      ><span class="c3">)</span>
    </p>
  </body>
</html>`;

export default desc;
