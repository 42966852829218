const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c8 doc-content">
    <p class="c4" dir="rtl">
      <span class="c1 small-text">אפרת בן כהן</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בשעתו לא כל כך ידעתי מה נעשה שם בין אמא הסופרת וביני. במרחק של השנים –
        אני יכולה לשפוט שיפוט יותר מדוייק. אמא היתה לא רק סופרת. היא היתה
        עסקנית. היתה עסקנית בכל השטחים. בבית היה בית-משפט פרטי </span
      ><span class="c10 c3 italic">[הכוונה לגישור]</span
      ><span class="c2">, ובבית היתה עזרה סוציאלית. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >האמת לאמיתה היא, ובלי להסתיר, היחסים ביני לבין אבי היו יותר טובים, יותר
        בריאים. קודם כל, כנראה שתכונות העבודה שיש לי הן מאבא. אהבתי עבודה כמו
        אבא שהיה איש עבודה. וממנו ירשתי תכונה זו. כתיבה לא מושכת אותי. בעבודה –
        טוב לי. אני הלכתי אחרי אבא. היתה תחרות עלי בין אבא לבין אמא. הבן – עשהאל
        – עזב את הבית בצעירותו, ואני נשארתי בת יחידה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >אני זוכרת שכשאמא הרגישה לא טוב, וזה היה לעתים די קרובות, היא שכבה, והיא
        ביקשה ממני לשבת על ידה ולהקריא לה מתוך ספרים לועזים. אני ידעתי צרפתית.
        ישבתי לצידה ותרגמתי תרגום חופשי מהספרות הצרפתית שקראה אותו זמן: אמיל
        זולה, ויקטור הוגו, רוסו, רומן רולן ואחרים ... הייתי מחזיקה את הספר
        הצרפתי, קוראת בעינים ומקריאה בעברית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >אבא רצה שאני אעבוד איתו. ואמא רצתה שאני אכתוב כמוה. היא רצתה שאני
        אתרגם. לא היו אז ספרי לימוד. ידעתי צרפתית והיא רצתה שאתרגם ספרי לימוד.
        אני נמשכתי עם אבא לעבודה. הוא לקח אותי לכרמים, למדידות ולסימונים. הוא
        הצליח לקנות את ליבי בזה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >למה לא רציתי לכתוב? הכתיבה גזלה ממני את האמא. אמא וסופרת זה שני דברים.
        לא היה לי מספיק ממנה כאמא. ואני האשמתי את הכתיבה בזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">כשאמא כתבה, אסור היה להפריע. וזה חסר לי מאוד.   </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >אמא היתה לגבי קפדנית יותר מאבא. אם הייתי באה אחרי עשר, היתה עושה סקנדל.
        אם הפריעו לה לכתוב בבית, אז היתה קמה ונוסעת לקרובים. שם היתה יושבת
        וכותבת. כשאמא היתה נוסעת, היה חופש גמור והשמחה היתה רבה. אבא אף פעם לא
        שאל אותי מתי את חוזרת הביתה, ושנינו נהננו מאוד. כשהיא היתה נוסעת אני
        הייתי צריכה לנהל את משק הבית. מגיל 13, הייתי כבר חולבת, מכבסת ומבשלת.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >... הייתי כבר בת עשרים. קיבלה אמא מכתב מהנרייטה סולד. ובמכתב הזה היא
        שואלת ממנה אם היא מוכנה לארח אותה ואת ידידתה, גברת מול, בשעתו ברגר, לפסח
        לביתה. מקום לארח אותם, אורחות כאלה במיוחד, לא היה לנו בבית, מפני שכפי
        שהזכרתי קודם היו חדרים גדולים אבל מעטים. אמא הצליחה לשכור להם חדר במין
        בית מלון שהיה בקרבת מקום, אכלו בביתנו ולנו שם. הימים האלה, זוכרת אני לא
        רק בחיבה אלא אני חושבת בהערצה רבה. זו היתה חוויה גדולה מאד. גברת סולד
        כידוע לך היתה גם שומרת מסורת, ובאחד מימי החג הן אכלו ארוחת בוקר והתכוונו
        ללכת לבית הכנסת. אז אני אמרתי לה: גברת סולד, לי אין פנאי ללכת לבית
        הכנסת, תתפללי גם בשבילי. אז היא ענתה לי: בחורה שיודעת לאפות עוגות כל כך
        טובות – בשבילה כבר לא צריך להתפלל. בסוף הביקור, כמעט בסוף הביקור, אני
        הייתי במטבח, והן ישבו ואכלו בחדר האוכל, ומהמטבח שמעתי שיחה בין גברת סולד
        ואמא. גברת סולד שואלת את אמא אם היא מכירה איזו בחורה שיודעת תפירה, כדי
        לשמש כמדריכה, לא אגיד מורה, מדריכה בבית ספר למלאכה של הג&#39;וינט שניהלה
        גברת ברגר. אני מהמטבח שמעתי את השיחה הזאת, ופתאם ככה נצנצה בדעתי מחשבה
        להציע את עצמי למשרה הזו. הייתי בת יחידה. אף פעם לא יצאתי מהבית. בכלל לא
        היה מקובל אז שבחורות עובדות, לא היו לנו מקצועות ולא עבדנו בכלל - עבדנו
        בבית. עבדנו הרבה, עבדנו קשה, אבל בבית. כשאמא חזרה מחדר האוכל למטבח אני
        אמרתי לה: אמא, אני שמעתי את השיחה, אני רוצה שתציעי אותי למשרה הזו. את
        תדהמתה של אמא אני עוד רואה לפני. מה? – היא אומרת – את? את, זאת אומרת הבת
        היחידה, משען בבית, אמרתי: כן. אני. טוב, חזרה לחדר האוכל ומספרת לגברת
        סולד שאני שמעתי את הסיפור ואני מציעה את עצמי. גברת סולד נדהמה. היא כבר
        ידעה שאני יודעת לאפות עוגות, אבל מה לי ולתפירה? אז היא אומרת מה? הבת שלך
        יודעת לתפור? גם לתפור? והיא אומרת, כן. היא אומרת: מה את יכולה להראות לי
        שאני אווכח שזה נכון? הימים היו מיד אחרי מלחמת העולם הראשונה. גברת סולד
        אז באה עם &#39;הדסה&#39;, כידוע לך, תיכף אחרי מלחמת העולם הראשונה
        הסתדרות &#39;הדסה&#39; שלחה את המשלחת הראשונה של רופאים ואחיות לישראל,
        ואליהם הצטרפה גברת סולד. אמא אומרת: כן, דברים חדשים אין לנו בבית להראות
        לך, אנחנו אחרי מלחמה כזו, אני יכולה להראות לך את כל התיקונים וההמצאות
        בתפירה של בתי. טוב, התחילה אמא להוציא מהמגרות את כל הבגדים כביכול שיצרתי
        מלא-כלום בזמן המלחמה. זה, לא צריך היה הרבה זמן לשכנע את הגברת סולד, שיש
        לה פה בדיוק את המציאה שהיא מחפשת. והיא לקחה אותי.
      </span>
    </p>
    <p class="c0 italic" dir="rtl" id="h.gjdgxs">
      <span class="c3 c10">ניתן ללמוד עוד על היחסים בין האם והבת</span
      >
      <a href="/letters#inTheFamily">
        <span class="c10 c3 c12">מחליפת המכתבים ביניהן</span></a><span class="c3 c7">. </span
      >
    </p>    
  </body>
</html>`;

export default page;
