const page = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <!-- <h3 class="c3" dir="rtl" id="h.csnof9dn2wef">
      <span class="c2">על העבודה במשק והקשר לטבע</span>
    </h3> -->
    <p class="c0" dir="rtl">
      <span class="c1 small-text">אפרת בן כהן ועמינדב בן דודה  </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >אבא עבד במטעים. החצר והמשק ליד הבית – הרפת וגן הירק – היו ממלכתה של
        אמא. המשק היה לשמוש הבית בלבד. מרבית המזון לבית היה מתוצרת עצמית – חלב
        ומוצרי חלב, ביצים, ירקות מהגנה ופרי מהמטעים – שקדים, תאנים, זיתים,
        ענבים, ופירות שייבשו: צמוקים, דבלים, משמש, קמרדין, סלק לסוכר, וכמובן שמן
        זית, ריבת ענבים, וחציר לבהמות. ובזמן המלחמה, כשחסר סובין ושעורה, שפשפו
        את הקוצים מעלי הצבר, קצצו אותם והאכילו את הפרות, ובמקום סוכר שלא היה
        בשלו את הענבים הרבה מאד זמן עד שהריבה הסמיכה ושימשה למתיקות. כך שמזון
        בסיסי אף פעם לא חסר למשפחה ולכל האורחים הרבים. אך אם מישהו נזקק לעזרה
        כספית ולא היה באפשרות אמא לעזור - כי למרות חריצותם של אבא ואמא הפרוטה לא
        היתה מצוייה - במקרה כזה פנתה אמא למשפחות אמידות במושבה, ואלו אף פעם לא
        השיבו את פניה ריקם: אם אמא באה, מישהו זקוק ויש לתת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1 small-text">אפרת בן כהן</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >איך היא עשתה את זה – באמת קשה להבין, אבל היא עשתה. עובדה שהיא עשתה. קמה
        מוקדם בבקר, אני זוכרת שלפני שהם יצאו לחלוב את הפרות אבא ואמא ישבו במטבח
        ושתו מים עם חלב – לא תה ולא קפה. מים עם חלב. ואז יצאו לרפת, אמא לחליבת
        פרות, אבא לטיפול בסוסים, והתחיל היום מוקדם. אינני זוכרת שאמא תנוח אחרי
        הצהריים. איך היא החזיקה מעמד קשה לי להבין עכשיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1 small-text">רות ארבל</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >יום יום, בשעת ערביים, חבשה סבתא את החמור ויצאה לטייל בין הכרמים והשדות.
        עם חשכה חזרה. היא אהבה במיוחד לטייל בגשם.
      </span>
    </p>
  </body>
</html>
`;
export default page;
