const page = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c1 doc-content">

    <p class="c0" dir="rtl">
      <span class="c2"
        >על ספת עץ רעועה, העומדת אצל חלון קטנטן בחדר צר ונמוך, שוכבת סצליה
        אלברשטס בפנים רזים וחורים עם עקבות אצילות שעליהם.  עיניה סגורות ונשימתה
        לא כסדר.  בידה הימנית אחוז ספר סגור ואצבע דקה תחובה בין הדפים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >מן החלון הפתוח נשב עליה רוח מערבי; היא הזדעזעה ותפקח את עיניה; הרימה
        מעט את ראשה, העיפה עין בחדר ותמהה: – הוא איננו עוד!..  מדוע?..  מדוע לא
        בא חברה הטוב, החבר היחידי שעוד דואג לה בחיים? –  –
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >באבריה מורגשת חלשה, אותה החלשה שהיא כה לא נעימה לה בעת שמחכה למן
        דהו.  ביחוד אינה רוצה, </span
      ><span class="c6">שהוא</span
      ><span class="c2"
        > יראה אותה בכך.  אינה רוצה – ומוטב שתעשה לה עוד הזיה!..  אולם בין רגע
        היא חוזרת מדעתה: לא, – אסור! הרי שש כבר עשתה היום וצריך להתאפק!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ראשה כבד עליה והיא מניחה אותו על אדן החלון הקשה והקר ותולה את עיניה
        העמומות במרחק. – על גבעה רמה משתטחים כרמי גפן בשורותיהם הישרות, ושורת
        גפנים ירקות שורת קטיפה שחומה, הפרושה כסרט ארך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >את האפק מכסה יער זיתים, שבין ענפיהם שוקע כדור גדול ואדם, ומעל צמרותיהם
        תלוים גושי אפר עם טלאים תכולים.  ושם בצד היער משתרבבות לשונות אש וגלי דם
        מתערבים בתמרות עשן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >לבה של סצליה דופק בגעגועים כמוסים, סובב ראשה – וההזדעזעות חזרה
        באבריה.  שוב תקפה החלשה, והדופק מפגר כאלו נעצרה זרמת הדם בערקים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >קר לה והידים רועדות.  היא מרתיעה אל השלחן, נוטלת את כלי הזיה, שואבת מלא
        מזרק מורפיום, מתקנת את המחט, ובין רגע וההזיה השביעית נעשתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >...  עוד מוקדם למות! – מהרהרת סציליה – צריכים ללחם במות, להתגבר עליו
        ולנצחו – הרי החיים עוד מבטיחים תענוגים, עוד לא נאבד הכל...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >משפשפת היא את עור ידה הדק סביב למקום הזיה, כשגופה רותת לפעמים.  פתאום
        צפה חיוך מר על פניה החורים.  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >...  איזו שטות– תענוגים!..  כלום לא שבעה אותם די בחיק מעריציה
        הרבים?..  רצוצת גוף ונפש שכמותה עכשו, – למה לחפש עוד אהבה, ידידים,
        תענוגים?..  הוא לא בא? – מוטב!...  די הבלים! –  –</span
      >
    </p>
    <p class="c4" dir="rtl" style="text-align: center;"><span class="c2">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >צנח ראשה על הכר, בעיניה העמומות התחילו מהבהבים ניצוצי די נור, ובלבה
        בצבצו המון זכרונות רחוקים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        > – – – את יום מות אמה כמעט שאינה זוכרת, אבל זוכרת היא היטב שלא עברו
        ימים מועטים ואשה זרה, חצופה ושנואה הֻכנסה במקום המתה וצוו עליה, לקרא
        אותה בשם אם... היא קובלת ומתרעמת: – לא רוצה באם שכזו, לא רוצה!..  וגיהנם
        שורה בבית כל הימים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >מקלטה היה אחרי כן בית הספר.  שם היא נוחלת תהלות ותשבחות מכל
        אפסים...  כמורים כמורות מציגים אותה לדוגמא בלמודים.  – היא הראשונה
        בכל...  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >בשתים עשרה שנה כבר אהבה!...  מִטְיָה, בן השכנים הנוצרים, נער גבוה
        וזקוף, כשהיא היתה רואה אותו בחלצתו האדמה מרחוק, התחיל לבה דופק,
        דופק... בכל ערב הם מזדמנים על הספסל שאצל הפשפש.  מטיה מלטף את צמתה
        הארוכה, נושק את קצה השערות של זו ולוחש מלים, שאזנה אינה קולטת אותן
        היטב.  – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >כעבור שנה והריה אוהבת נער אחר, עבה וצהוב...  על חג בית הספר, שנערך
        לכבוד הולדת ישו היא נפגשה אתו.  כל הערב הם רקדו יחד, והוא סח לה מקצת
        מתולדותיו.  – אביו יהודי ואמו נוצריה, ויש שרצון חזק מאד תוקף אותו להכיר
        את היהודים ולהתקרב אליהם, אלא שעד היום לא נזדמן לו זה. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >כשהתגלה בה הכשרון המוזיקלי, היתה כבר כבת שש עשרה...  התחילו מזמינים
        אותה לנשפים לנגן...  זוכרת היא נשף אחד, שאותו ערכו מטרוניתות כבודות
        לטובת ילדים עניים.  דומה שזה היה הערב הכי יפה שהיא ידעה בחייה...  ההצלחה
        היתה מרובה; ממחיאות הכפים רעש האולם; היא היתה שכורת נצחון...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >בערב ההוא נפגשה עם סמיון גברילוב, שבא מהאוניברסיטה הפטרבורגית לבקר את
        אביו, סוחר גפתן בעל מליונים רבים...  הוא לא סר ממנה כל הערב, ואחרי כן
        בקר את ביתם יום יום...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >הוא התיחס אליה כמורה לתלמידה.  היה מביא לה ספרים מענינים, ביחוד
        סוצִיַל-אקונומיים; מקריא, מבאר ואף פעם לא העיז להעיף עליה מבט אי
        צנוע... ואף-על-פי-כן הרי יצאו שמועות בעיר, שגברילוב מתעתד לגנב אותה מבית
        אביה, לנצרה ולקדשה לאשה!... </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >היא לעגה להבלים אלה, הואיל שבטוחה היתה בישרת לבו של גברילוב, כי לא ישים
        לה מוקשים... כמה חבבה אותו! זה היה עלם בריא ויפה, מלא רעננות שלבב אותה
        מאד, ואלמלי נצוצי הלאומיות שבצבצו בין כתלי בית אביה באופן פלא, אלמלי
        ההרגשה המטשטשת שהיא יהודיה, ושיש לה אי-אילו חובות כלפי עמה, אפשר שהקשר
        עם גברילוב הלך והתחזק במדה כזו, שאולי לא היה בכחה אחרי כן לנתק...</span
      >
    </p>
    <p class="c4" dir="rtl" style="text-align: center;"><span class="c2">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ונזכרת סצליה בנשואין עם ולדימיר, עורך דין, שכבר יצאו לו מוניטין
        בעולם.  לכאורה היא אוהבת אותו ומסורה לו ואף על פי כך לא ארך הקשר ימים
        רבים!...  נתקלה היא בלוין, זה הגבר העז, השליט, שכל מפלגתו תלויה
        בו...  כמו בקסם נמשכה ותלך אחריו לערי התחום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >העבודה רותחת...  מזדינת ההגנה העצמית..  היא נוסעת ממקום למקום לארגן
        קבוצות.  – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >באויר מתנשא ריח פרעות – מיום ליום מחכים להם.  – הנה הם באו,
        באו!...  היא נשקעת בחרב ודם, הורגת ומכלה בפורעים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >כאן מתכוצים אברי החולה, זכרונותיה מתטשטשים ונפסקים לאט לאט ואת פניה
        מכסה חורת מות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >עבר רוח חזק, לטף את פניה, העיר אותה ומשך בפתיל זכרונותיה. –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >– –  התקופה שאחרי הפרעות...  רצוצה ונדכאה היא תועה על פרשת דרכים ואינה
        יודעת לאן לפנות...  לולדימיר לשוב אינה יכולה, אף על פי שהוא סלח לה הכל
        והתחנן לפניה לחזר לביתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >נפשה הסוערת אינה יכולה להשלים עם חיי משפחה שקטים! לה דרוש שדה רחב,
        עבודה פוריה שתתפש את כל הויתה, והיא נספחת למפלגת פועלים, שעל דגלם מתנופף
        השם "ציון".  הרי לפניה אפק חדש, בהיר ויפה!– –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >זוכרת היא באיזו שמחה קבלה את המשלחת לאמריקה לשם תעמולה ציונית...  בשובה
        בהצלחה גדולה לאירופה, נתקלה על גדות הסינה באותו האדם, שמצץ במשך זמן קט
        את לשד חייה עד תומו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ברוקשטין, הריהו הגבר היותר יפה, שהיא פגשה בעולמה! ומלבד יפיו, הוא נראה
        לה לאדם בעל מדות תרומיות, שכדי להקריב בשבילו את תמצית נשמתה...  והלה
        אמנם בערה, בערה מהר תחת אשו האכזר. וכשנפרדה ממנו כבר מלאו החידקים
        הארורים את כל ריאתה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >ונזכרת סצליה ברתת את ליל פרידתם הארור.  – ברוקשטין עומד לפניה בעינים
        מתיזות זיקי שנאה, הוא קופץ את אגרופו וצועק כמטורף:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >– הגידי, לילית, הגידי – כמה עלי לשלם בעד כל התענוגים שהשבעתני? –  –  –
         –  –  –  –  –  –  –  –  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >רעש גדול חשה סצליה באזניה.  בבות העינים מעצמן ירדו וכסו על
        האשונים.  ראשה סובב וצונח על הכר ותמונות שונות באות בו
        בערבוביה.  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >פריז ההומה...  היא בורחת משם בליל חשך ונתקלת בפרעות... הרי שני האחים,
        שצוארם נטוי לשחיטה! פתאם מופיע צלם על צואר אחד מהם, וכשהתלין מרים גרזן,
        צוח בעל הצלם:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">– הבט, הריני נוצרי!..  זה, זה יהודי!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >היא מוציאה מהר את אקדוחה ויורה בבעל הצלם ובתלין...  עוזבת אותם כששניהם
        מתבוססים בדמם וברוצה הלאה נתקלת ברוצח, המתנפל על נערה רכה...  בין רגע
        וחרבה בידה והיא תוקעת אותה בחזהו של אותו האדם, החיה הטורפת...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >מתחילות התמונות מתטשטשות במחה העיף.  – חופי
        מצרים... א"י...  נחשול...  פתאם היא מרגישה כי רע, רע לה, הלב נלחץ וכל
        אבריה כאלו נאחזו בצבת...</span
      >
    </p>
    <p class="c4" dir="rtl" style="text-align: center;"><span class="c2">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >צללים כבדים פשטו בחדר וזחלו על פני סצליה שאחזה צירנות קלה.  בעלת הבית
        נכנסה להדליק מנורה בחדר, נבהלה מפני שכנתה ותרים קול קורא לעזרה.  נחפזו
        ורצו אנשי החצר ובדֻמית הערב נשמעה הברה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2">– גוססת, גוססת השכנה החדשה, – מהרו, הביאו רופא!  </span>
    </p>
    <p class="c3" dir="rtl"><span class="c5"></span></p>
  </body>
</html>
`;
export default page;
