const page = `<html>  
  <body class="c13 doc-content">
    
    <p class="c3 italic" dir="rtl">
      <span class="c18 c15 c22">המאמר פורסם בעתון </span
      ><span class="c15 c18 bold">המליץ</span
      ><span class="c5"> ביום א&#39; י"ד אייר תר"נ, 4 במאי 1890</span>
    </p>
    
    <p class="c10  italic" dir="rtl">
      <span class="c5"
        >הארוע המדובר התרחש כחצי שנה לאחר נישואי הכותבת, עלייתה לארץ ישראל (תחת
        שלטון עותומני) והתיישבותה בראשון לציון.
      </span>
    </p>
    <p class="c10 italic space-bottom" dir="rtl">
      <span class="c15"
        >אותו ביקור בירושלים מתואר (כמובן, באופן שונה למדי) גם
      </span>
      <a href="/testimonies/onTheFirstYearInIsrael#visitInJerusalem">
        <span class="c6 tooltip">
          בזכרונותיו של בעלה של הכותבת
          <span class="c16 tooltiptext">ראו קטע מתאור זה במדור עדויות</span>
        </span></a><span class="c15">, ומוזכר גם</span><span class="c15 c20"> </span
      ><span class="c15 c19 modal-button" id="modal-button-1">בעיתון הצבי</span><span class="c16">.</span>
    </p>


    <p class="c3 bold" dir="rtl">
      <span class="c7">עולי ציון. </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >יום הראשון של חג הפסח היה היום אשר אליו יחלה וגם כלתה נפשי מיום עלותי
        על אדמת קדשנו, כי בו בערב נשאתי את רגלי ירושלימה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >שתי עגלות יצאו ממושבנו לאחז דרכן ציונה לשאת את העולים שמה. בין הנוסעים
        היו חמשת אנשים ממכירי היותר קרובים אלינו, הם ישבו אתנו בענלה אחת וחשבנו
        לבלות יחדיו את הדרך בנעימים.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בני המושב התקבצו סביב העגלות לקרא לשלום בצאתנו לדרך, אבל אנחנו לא
        יכולנו לענות על ברכותיהם, כי כחולמים היינו בזכרנו את ציון, וישבנו
        מחרישים על מקומנו, אין מוציא הגה מפיו ואין דובר דבר.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >לאור הירח אשר יקר הלך על שפריר השמים עברנו את השרון המשתרע עד רגלי הרי
        יהודה, ושם עמדנו להנפש טרם יצא השחר. בבוקר השכם שמנו פעמינו הלאה, לא
        הרחקנו ללכת מן המקום אשר עמדנו שם, והנה מראה נפלא התיצב לנגד עינינו:
        הדרך סלולה בעמק נחמד כגן אלהים בין רוכסי הרי יהודה הנושאים עליהם זכרון
        גבורתנו לפנים. עליהם יפרחו יערי זיתים וכל עץ עושה פרי נחמד למראה אשר לא
        אדע אכנה את שמם. במקומות רבים עומדים ההרים חשופים וערומים כסלעי מגור
        לפנינו. סלעים אחדים עשוים בידי הטבע כסולמים הנצבים ארצה לעלות בהם על
        ראשי ההרים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >גם בין עצי היערים, גם לרגלי ההרים, גם על אם הדרך בכל אשר תראינה עינינו
        - יציצו אלפי פרחים מרהיבי עין. אנכי לא עצרתי עוד כח לשבת על מקומי, ירדתי
        מעל העגלה, אני ובני לויתי אתי, וכלנו הלכנו ברגל במקום הנחמד הזה אשר הטבע
        שפך עליו את כל הדרו. האח, הארץ כגן עדן לפנינו‭! - קרא אחד מאתנו.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >אך הנה עבר הדרך המלא קסם נפלא, בא לו הקץ , יצאנו למרחב והחילונו לעלות
        הרים ולרדת בקעים וללכת הלוך וסבב הרים וסלעים, וכשעה  אחת לפני חצות היום
        התיצבה ירושלים משאת נפשנו לננד עינינו. מה המו מיתרי לבבי למראה עיר הקדש,
        היא העיר אשר אליה נשואות עיני רבבות אחינו מכל קצוי ארץ. כמה זכרונות וכמה
        מחשבות התעוררו פתאום בלבבי! האמנם עיני רואות את ירושלים עיר קדשנו? שאלתי
        את נפשי ולבי הלם כהולם פעם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >המית העיר השביתה מעט את סערת לבבי, אבל תחתיה התעורר חשק עז ונמרץ לראות
        בפעם אחת את כל הזכרונות העתיקים והיקרים, לכן טרם הספקתי לנוח מעמל הדרך,
        טרם הבאנו את חפצנו בסדר בבית המלון, נשאנו את רגלינו אל הכותל המערבי. דרך
        מבואות אפלות ומטונפות עברנו אל הקדש, אל הכותל הזה הנשאר לנו לפליטה מכל
        מחמדינו.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >
        אזכרה את הרגעים אשר ראשונה נשאתי את עיני עליו, אזכרה כי ברגע אחד נמס לבי בקרבי ועיני היו מקור דמע, אזכרה את שיחי שם ואהמיה… “עורה ד', למה תישן?” קראתי בקול מר, ונהרי נחלי דמעות שטפו בלי הפוגות מארובות עיני עד אשר לא נשאר בי עוד רוח. בעינים חמרמרות מבכי ורפי כח עזבנו את המקום הזה ללכת אל מקום המקדש. אך הה! שם שמרו את צעדינו ויסגרו את הדרך בפנינו, כי מקום קדוש הוא עתה לעם הארץ, למחמדנים! והנה אנחנו, אשר אבותינו ירשו את הארץ הזאת והפכו אותה לגן עדן ולמשכן אלוה ובנו בית לד' במקום הזה, בית אשר אליו נהרו כל העמים, ולאורו הלכו גוים רבים, אנחנו, אשר מלכינו ישבו לנו פה על כסאות, אנחנו זרים עתה פה, אין לנו רשות להציג פה כף רגל ולנשק את העפר אשר כסר דרור היה אבקו להמשורר ברוך ה'.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >אחרי מחשבות רבות החלטנו לבוא אל חחורבה מקום מושב הסנהדרין, כי משם נוכל
        לראות את המקום הנבחר. בלב קרוע ומורתח הבטנו על הככר הנחמד אשר בתי תפלה
        למחמדים בנוים עליו, ולא יכולנו למוש ממקומנו. אנכי עמדתי כבמסמרות נטועה
        ומבטי עיני היו נטוים מבלי דעת לנקודה אחת ולא ידעתי נפשי‭ ...‬אך באה עת
        ללכת.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2">דרך המבואות המעוררות גועל נפש שבנו לבית מלוננו, ובדרך סרנו לבית התפלה של נסים באק, ולבית התפלה הבנוי על חורבת ר' יהודה חסיד, ובכל מקום בואנו התפלאו עלינו ילידי ירושלים הגדולים עם הקטנים. “ראו נא, מה זאת? הנה אשכנזים ואשכנזיות ומדברים בלשון הקודש!” – דברו הירושלמים בקול רם, בחשבם כי איננו מבינים את שפתם – שפת־עלגים.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בערב יום ההוא בקרנו בבתי אחדים ממשכילי ירושלים, וביום השני בבוקר השכם
        שמנו פעמינו אל הר הזיתים. אנחנו, הנשים, רכבנו על חמורים והגברים הלכו
        ברגל ער בואנו לרגלי ההר - שם בית מועד לכל חי. שם ירדנו מעל החמורים
        ועלינו ברגל לראות את יד אבשלום ואת קבר הנביא זכריהו. אחרי כן עלינו על
        החמורים ונרכב עד ראש הכפר. נכנסנו לבית מקלט הצרפתים הנקרא בפי היהודים
        בשם "טור מלכא". התענגנו על כל יקר תפארתו מפנים. משם עלינו על המגדל הגבוה
        הבנוי לתלפיות בחצר אחת מבתי התפלה לרוסים, משם ראינו במרחק רב מאד את כל
        ארץ ישראל מארבע רוחותיה. מעבר אחד התיצבה ירושלים עם כל רחובותיה ומגרשיה
        לנגד עינינו, מעבר השני ככר רחב ידים ועליו נראה קבר רחל אמנו והעיר בית
        לחם, מצד מזרח יראו מימי ים המלח כיריעת תכלת פרושה בין החופים הרמים
        והנשאים, והנה גם חוף הירדן יתנוסס שם בירקרק חרוץ. והלאה מצד צפון על חוג
        השמים יתנשאו ראשי הר חרמון המכוסים שלג עולמים, ושם לצד צפונית מערבית
        הרחק הרחק נראה הר המצפה, מקום שם קבר שמואל הנביא.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2">
      מלאי עונג מהמראות הנעימים האלה אך עיפי כח שבנו הביתה. אמרנו להנפש מעט, אך לשוא! אנחנו לא יכולנו לשקוט על מכוננו, וכאשר הגיע הערב התאספנו בבית איש אחד צעיר לימים, אשר היה מן החלוצים הראשונים על אדמת ציון והוא גר עתה בירושלים, ושם בלינו שעות אחדות בנעימות; שרנו ברגש קודש את שירי ציון, שתינו לחיי ירושלים, לחיי “ראשון לציון”, לתחית עמנו וארצנו, וקול הידד נשמע עד למרחוק.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >מה נעים לי זכרון הערב ההוא! בכל ימי חיי לא ראיתי עוד שעות כאלה המלאות
        אורה, תקוה ואמונה ... רק אנחנו, אנחנו בני ציון, פה בארץ הפלאות נוכל
        לראות רגעי נועם כאלה!
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2">
      ביום השלישי בקרנו את קברי מלכי ישראל, או מערת כלבא שבוע, – בנין נפלא עשוי בסלע ובו מערות אין מספר. בנרות נכנסנו לכל מערה ומערה, אחת מהנה לא נעדרה; ואנחנו בני אירופא אשר הסכינה עינינו לראות בנינים נפלאים, עמדנו מחרישים ומשתוממים למראה מלאכה נוראה ונפלאה כזאת.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >ביום הרביעי לעת הצהרים יצאנו את ירושלים לשוב "ראשונה"‭... ‬עוד הפעם
        עברנו את ההרים ואת הבקעים הנחמדים המעוררים אלפי זכרונות בקרבנו, עוד הפעם
        עברנו את העמק הארוך והנחמד בין רוכסי ההרים, עוד הפעם ראינו את השרון בכל
        הדרו והנה "ראשון לציון" הופיע לנגד עינינו.
      </span>
    </p>
    <p class="c1 c12" dir="rtl">
      <span class="c2">"ראשון לציון עיר כוננה ידינו, </span>
    </p>
    <p class="c1 c12" dir="rtl">
      <span class="c2">בעדך סבלנו, שפכנו דמינו" </span>
    </p>
    <p class="c1 c12" dir="rtl">
      <span class="c2"
        >שרו היושבים על העגלה, עד אשר באנו אל המושב מלאי ענג ושמחה על כל אשר ראו
        עינינו.
      </span>
    </p>
    <p class="c1 c12 c23" dir="rtl"><span class="c2"></span></p>
    <p class="c11 text-left" dir="rtl">
      <span class="c2">.ראשון לציון, כ"ז ניסן תר"ן</span>
    </p>
    <p class="c11 text-left" dir="rtl">
      <span class="c2">.נפ"ש</span>
    </p>    
  </body>
</html>`;

export default page;
