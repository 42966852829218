const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c0" dir="rtl">
      <span class="c2 bold">העולם</span><span class="c4">  1908 09 11 </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5">יומן מסע בארץ ובלבנון</span>
    </p>
    <p class="c0 c3" dir="rtl"><span class="c1"></span></p>
  </body>
</html>`;

export default desc;
