const page = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c22 doc-content">
    <p class="c0" dir="rtl">
      <a href="#the-match">
        <span class="c6">השידוך והארוסין</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#the-wedding">
        <span class="c6">החתונה</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#the-latency">
        <span class="c6">העיקוב באודסה</span>
      </a>
    </p>
    <p class="c0" dir="rtl">
      <a href="#the-immigration">
        <span class="c6">העליה</span>
      </a>
    </p>
    <p class="c0 c2" dir="rtl"><span class="c8"></span></p>
    <p class="c0 italic" dir="rtl">
      <span class="c1"
        >כל הקטעים המובאים כאן נכתבו על ידי יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי
        וקיימים עד היום בכתב ידו. כולם מלבד הראשון נכנסו לספר זכרונותיו – מהתם
        להכא (יד בן-צבי ירושלים 2013)</span
      >
    </p>
    <br/>
    <h3 class="c0" dir="rtl" id="the-match">
      <span class="c8">השידוך והארוסין</span>
    </h3>
    <p class="c5 italic" dir="rtl">
      <span class="c10">הקטע הבא כתוב על שני עמודים בכתב ידו של </span
      ><span class="c10 c18">יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי</span
      ><span class="c10 c11"
        >. העמודים נמצאים בארכיון הציוני, תיק A192-663</span
      >
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7">מאורע חשוב בחיים! </span>
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >מאורע זה, מתאר בצבעים בהירים ובולטים את חיי הישוב והרפתקאותיו בימים
        הראשונים טרם בא לידי יציבות וגיבוש מחד גיסא, ומאידך, חיבת ארצנו והתמסרות
        עיוורת של בני הגולה לרעיון ישוב ארצנו מבלי לבקש חשבונות רבים.</span
      >
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >בימים ההם שנת תרמ"ח היה מחסור גדול בין בני המושבות לבנות מבוגרות. רוב
        הבאים ארצה באותם הימים היה רווקים ובנות האיכרים חטפו מידיהם כבכורה בטרם
        קיץ ולא נתנו להן להתבגר כראוי. ולדוגמה כי בראשון מה שאני זוכר נמצאה אך
        עלמה אחת שעמדה על סף הבגרות וגם היא היתה כבר ארוסה. ואני שעבר עלי השנה
        העשרים וחמש לחיי פניתי על ימין ועל שמאל ולא מצאתי את החומר הזה הדרוש
        עבור בנין בית בארץ, ולמדתי ממעשי אנשי ביל"ו שהשתמשו באימפורט מחוצה לארץ.
        אי לזאת גיליתי את מרת נפשי במכתב לפני המורשה מ&#39; פדואה שהוא היה הגורם
        האקטיבי שנבחרתי ושולחתי ארצה. ובקראו את מכתבי נצנץ רעיון נועז במוחו,
        היינו להציע לפני הצעירה הצריצנית – בריסקאית שיצא לה מוניטין בלאומיות
        ובחיבה לארץ ישראל, בזמן שהוא ידע היטב כמה צעירים משכילים ומדופלמים מבני
        ברית ושלא מבני ברית בקשו את ידה והיא דחתה אותם באמת הבנין ולא הטתה אוזן
        קשבת לממשיכי הגלות כי לבה ונפשה לציון. והיה גם מקרה שצעיר משכיל אחד פנה
        ישר לאביה ודרש ממנו בתקפה את ידי בתו. והגיע הדבר לרצינות כזו שהיו
        מחויבים להבריח בגנבה את אוצרם לבריסק שלא יקרה מעשה דינה בת יעקב בשכם
        הגוית. ובקבלה את ההצעה של ידידה הנכבד מר פדואה, קפצה כנחשון בשעתו מבלי
        לחשוב חשבונות רבים ואמרה מסכימה!</span
      >
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >כעבור זמן קצר קבלתי אף אני את ההצעה הנכבדה הזאת וזה פעל עלי כמהלומה
        כבידה על הראש ונתבלבלתי ומחשבות שונות ומשונות התרוצצו בי ושאלתי את נפשי
        האם יש לי רשות במצבי החומרי ומצב הישוב בימים ההם לקחת עלי את האחריות
        להגות נפש עדינה כזאת מן המסילה הרגילה שכל בנות גילה ומעמדה דורכות בה בלי
        מכשולים ולהוביל אותה בדרך לא דרוכה לפי שעה? אבל כמוקסם צייתתי לדברי מר
        פדואה, ומילאתי את ציוויו לשלוח תיכף תמונתי ומכתב על המען פיינשטיין
         צריצין על נהר הוולגה. במכתב זה תארתי את מצבי לפניה דברים כהויתם בלי שום
        כחל, ומצב החומרי בכלל, באותיות מאירות עיניים ושכל הישוב תלוי במזל של
        קפריזות של איש אחד, אמנם מורם הוא שאין דוגמתו, אבל עירין ושידין רוקדים
        סביבו ומטילין ארס בכוס הישועות שהוא מגיש לישוב ביד נדיבה.</span
      >
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >כאשר אך יצא המכתב מרשותי ונפל בידי הדואר תקפתני חרטה איומה והכיתי על
        לבי על חטא שביד ממש הרסתי את קיני המקווה.</span
      >
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >בפוסטה החוזרת קבלתי מכתב מצריצין וידי התחילו רועדות ובמששי הרגשתי כי גם
        תמונה בתוכה. אז באה הרגעה שהתקוה משחקת לפני ורק פתחתי את המעטפה וברק
        עיני נפלו על התמונה הרגשתי בכל רמ"ח איברי כי רצוף פה גם פצצה מגניטית
        שפוצצה את לבי ומהרגע הזה נלכדתי בסבך יסורי געגועים אי-אנושיים.
      </span>
    </p>
    <p class="c5 c9" dir="rtl">
      <span
        >תוכן מכתבה וסגנונה הקסימני ואלה הם דבריה: תשובה על מכתבך שבו השתדלת
        להניח אבני נגף וצורי מכשול על דרכי החדשה אשר בחֶדוה רבה התאויתי לי,
        והודיתי בכל לבי ונפשי על המקרה שנזדמן לי באופן כל כך פלאי להיות אחת
        הבונות בדמי ובזיעי פשוטו כמשמעה, ובמקום לדרוך על מרבדים רכים ויקרים
        בגולה
      </span>

      <a href="/letters/yalag18851889#lilg1889">
        <span class="c16 tooltip"
          >ינעם לדרוך על קוצי ארצנו<span class="c17 tooltiptext"
            >ראו גם מכתבה של נחמה פיינשטיין למשורר יל"ג</span
          ></span
        ></a>
      <span>. </span>
    </p>
    <p class="c5 c9 c2" dir="rtl"><span class="c7"></span></p>
    <p class="c0 italic" dir="rtl">
      <span class="c1"
        >הקטעים הבאים לקוחים מתוך מחברות זכרונותיו של יחיאל מיכְל פוחצ&#39;בסקי
        והם מופיעים גם בספר מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה
        זלמן פוחצ&#39;בסקי, יד בן-צבי 2013</span
      >
    </p>
    <p class="c0 c2" dir="rtl"><span class="c12"></span></p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >יחד עם העבודה המעניֵנת שקמה לפני בראשון לציון, עלתה על לבי המחשבה
        להתקשר בעבותות עוד יותר חזקים ולבנות כאן את ביתי. סודי זה גִליתי באחד
        ממכתבי לפני מורשה עירנו מר מרדכי פדובה, שהשתדל עבורי לשַׁלחני ארצה,
        והתחקה תמיד לארחי ורבעי בארץ אבות. הלז התחיל לחשוב אודות זה בכובד ראש.
        באותה שעה היה מעונין בחיפוש דרך עבור בת עירי שנשבתה בין הגויים – בת ציון
        אחת מיני אלף, שכבר יצא לה מוניטין להיות סופרת בישראל, והשתוקקה בכל נפשה
        לארץ האבות.
      </span>
    </p>
    <p class="c5 c9" dir="rtl">
      <span
        >המורשה התלבש באצטלא של שדכן והציע לפני להיות גואל נפש אחת מהגולה,
        ולקשור את חיי עם הנפ"ש </span
      ><span class="c10 italic">[נחמה פיינשטיין]</span
      ><span class="c7"
        >. הדבר כמובן מצא נתיב ללבבי, וכפי שמשפחתה לא היתה מוזרה לי, והכרתיה
        מימי הנוער טרם לכתי ארצה, לא חשבתי הרבה ושלחתי למורשה שלי כוח והרשאה
        לקרב את הרחוקים ולסייע לבנות בית בארץ ישראל.
      </span>
    </p>
    <p class="c5 c9" dir="rtl">
      <span class="c7"
        >והדבר המופלא הזה יצא אל הפועל. לא ארכו הימים והתחלפנו בתמונות ובמכתבים.
        הלבבות התקרבו, המרחק ביננו הלך ונאבד, וַתִתן בכל לב את הסכמתה ללכת אחרי
        ולבנות קן משותף בארץ מולדת האבות. מחוף הוולגה עד לבריסק דליטא עפו
        הטלגרמות ומשם לראשון לציון, ובאחד הימים באביב תרמ"ט קִבלתי טלגרמה: "מזל
        טוב, כתבנו תנאים. פלונית התארסה לפלוני".</span
      >
    </p>
    <p class="c5 c9" dir="rtl">
      <span
        >השמועה עשתה לה כנפים בראשון, והנוער, שהיה צמא מאד למאורעות כעין אלה,
        התנפל על המציאה לסדר איזה הלולא וחנגא. </span
      ><a id="id.30j0zll"></a><a id="id.1fob9te"></a
      ><span
        >גיטל לובמן הטובה קִבלה עליה להיות המחותנת ולסדר חגיגה פומבית ו"תנאים"
        רשמיים בביתה. תמונות החתן והכלה הועמדו בראש השולחן, מעולפות בפרחים
        מרהיבי עין מתוצרתו של החתן דנן, ולחיי הזוג הזה שתו כלם לחיים! והוָתיקים
        הראו כיצד מרקדין לפני כלה צנועה ושקטה כזו. עד אור הבקר לא פסק הנוער
        משירים ורִקודים, וכל הלילה </span
      ><span class="c23 modal-button" id="modal-button-1"
        >הרעישו את אויר המושבה</span
      ><span class="c7"
        >...</span
      >
    </p>
    <br/>
    <p class="c0" dir="rtl" id="the-wedding">
      <H3>
        <span class="c19 modal-button" id="modal-button-2">החתונה</span>
      </H3>
      <span class="c1 italic">[החתונה נערכה בבריסק ד&#39;ליטא]</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >חג כלולתנו הוחג כיוצא מגדר הרגיל ברוב פאר והדר, לא לכבודנו אלא לכבוד
        ארצינו. בעונג לקחו כֻּלם חלק בשמחתנו, הקשורה בזכר ארצינו האהובה. הנִים
        המקשר את היהודי לארצו התעורר לשמע כי צעיר שב מארץ העברים על מנת להתקשר
        פה עם עלמה צעירה בקשר אמיץ, על ידי חופה וקדושין, ולשוב תיכף לארצנו
        הקדושה, לעבדה ולשמרה ולבנות שם את ביתם העברי. כמה מן היופי והפיוט יש בזה
        לנשמת היהודי, הקשור במולדתו מקטנותו על ידי ספר הספרים, וגעגועים בלתי
        פוסקים טורדים אותו כל ימי חייו עד זקנה ושֵֹבה, ואשרי מי שזוכה בסוף ימיו
        לנסוע ארצה ישראל ולמצֹא מנוחתו על הר הזיתים. האם יש בזה פלא
        ששמחתנו</span
      ><span class="c20 c21"> נעשתה לשמחת העם? </span>
    </p>
    <br/>
    <h3 class="c0" dir="rtl" id="the-latency">
      <span class="c8">עיקוב באודסה</span>
    </h3>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בימים האחרונים לירח מנחם אב תרמ"ט עזבנו את עירנו, הורינו, קרובינו,
        ידידינו ומכרינו, ונסענו לאודיסה על מנת לסדר שם את הפספורטים ולנסוע באניה
        הראשונה שתפליג ליפו. אך לדאבוננו נתקלנו באיזה קשיים טכניים בעניני
        התעודות, והיינו נאלצים לשבת כעשרה ימים באודיסה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אז נודע לר&#39; זלמן שלמה האלקיש אפשטיין כי חבר אחיו, עם אשתו הצעירה,
        הידועה בעולם הספרות העברית בתור מתחילה, נמצאים באודיסה בדרכם לארץ ישראל.
        וַיפרסם את זה ברבים, ובאחד הימים סִדרו לנו קבלת פנים על כוס תה במעון
        הקיץ של אשר גינסבורג – "אחד העם". שם התאספו משמנה וסֹלתה האודסאית, כמו
        הלוברסקים, ז&#39; אפשטיין, יהושע ברזילי, פרדפלד,
      </span>
      <span class="c13 tooltip"
        >ועוד הרבה מ"בני משה"<span class="c14 tooltiptext"
          >תאור מלא של אותה פגישה נמצא בספר מהתם להכא סיפור חיים של עובד אחד,
          יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי, יד בן-צבי 2013, עמ&#39;
          202-201</span
        ></span
      >
      <span class="c3">... </span>
    </p>
    <br/>

    <h3 class="c0" dir="rtl" id="the-immigration">
      <span class="c8">העליה</span>
    </h3>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אניתנו התרחקה מנמל אודיסה, ומהרגע הלז התחיל אצלנו ירח הדבש, והנסיעה
        שלנו קִבלה צורה של "נסִעת נשואין" </span
      ><span class="c24 c21 c10 italic">[מסע כלולות]</span
      ><span class="c3"
        >. הדרך עברה באופן חגיגי ונדמה שגם הים לוקח חלק בשמחתנו.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בכל נמל ונמל ירדנו לסַיֵר את ערי המזרח. בנסיעתי הראשונה באנו לאלכסנדריה
        של מצרים בימים שעוד עִשְׁנו האודים מהשרפה שחוללו התותחים האנגלים, בכבשם
        את מצרים בשנת תרמ"ב. וכעת, אך ארבע שנים מנו, וכבר מצאנו עיר בנויה על
        תִלה בסגנון אירופה, וחיים סואנים ורועשים. קשה היה לתאר שבאנו לעיר
        אפריקאית.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3">פה בילינו יפה איזה מעת לעת </span
      ><span class="c21 c10 c24 italic">[יממה]</span
      ><span class="c3"
        >, עד שבאה אניה רוסית אחרת לקחת אותנו ליפו. בדרך סִיַרנו גם את פורט
        סעיד. העֲירה הקטנה החדשה עשתה קִדמה רבה. אוכלוסִן היה בה מעט, אבל היה
        כבר נִכר סִמנים של עיר יפה בעתיד. זה עורר שמחה בלבנו, ותקוה כי גם ארצנו
        אשר שממה בשעריה תתנער מאפרה, ועוד נזכה לראותה בהִבָּנותה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >עוד יום, ומחוז חפצינו מתקרב. בבקר השכם, בטרם אור, קמנו לסַדר את חפצינו,
        שהיו פזורים, ובכל רגע תופשים מבט אל החוף הארץ ישראלי. וכמו בערפל נדמה
        שנראית כבר יפו, והלב מתחיל לדפוק בחזקה.</span
      >
    </p>
    <p class="c4" dir="rtl"><span class="c7">...</span></p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >אחרי איזה שעות, שכבר נחנו היטב, אכלנו ושתינו והרגשנו את עצמנו כאזרחים
        גמורים, שבנו אל החוף לקבל בחזרה את הפספורטים ולהוציא את הבגאז&#39;. בתוך
        הארגזים שלנו נמצאו הרבה דברי חפץ, כיאות לזוג צעיר, והיו דברים שיכֹלים
        בעצם על פי החוק לדרוש מכס. בימים ההם היה נהוג שבעת החפושים בבית המכס
        פקידי המכס, אפילו הגבוהים, משלשלים לתוך כיסיהם כל דבר חפץ שרק מצא חן
        בעיניהם, ו</span
      ><a id="id.2et92p0"></a><a id="id.3znysh7"></a
      ><span class="c3"
        >לית דין ולית דיין. לכן בעת החִפושים בארגזים או בסלים דרוש שמירה מעולה –
        עיני הבעלים צריכות להיות פקוחות על אצבעות המחפשים.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c3"
        >בכדי להכפיל את השמירה הבאתי את הצעירה שלי להיות לי לעזר בהשגחה. והנה את
        אשר לא פִללתי מצאתני: הארגז היותר גדול והיותר כבד מצאו לנחוץ לפתוח
        בראשונה. שם דוקא היו חפצים עתיקים מכל המינים וסִפרִיה הגונה. כאשר התנפלו
        בבת אחת כל הפקידים המחפשים וכפפו את ראשיהם לתוכו, מכסה הארגז נפתח לרוָחה
        ומתחת למכסה התהווה חלל ריק. וכאשר הפקידים היו עסוקים בחיפוש מה שנמצא
        בתוך תוכו של הארגז, אז רַעְיתי הצעירה הרימה אי אלה חפצים בכוָנה באופן
        כזה שפגעה בחזקה בפני הפקידים. הענין המוזר מצא חן בעיני הטורקים ועניֵן
        אותם, והתבלבלו, ובינתים העברתי מן הצד, מתחת ל"דלת", את הסלים המלאים כל
        טוב, אשר הספקתי קודם לפתוח אותם שלא יפילו חשד שעברו בלי בקורת. ככה נִצל
        כל רכושינו מבלי שיגע בזה יד אכזרי, והעברתי הכל בלי פרוטה מכס, ואפילו בלי
        בקשיש. </span
      ><span class="c24 c21 c10 italic"
        >[ולמי שהתבלבל בין החפצים: נראה שהחתן העביר את הכבודה מתחת למכסה הפתוח
        של הארגז, בשעה שתשומת לבם של הפקידים הייתה לכודה בהצגה של הכלה היפהפיה,
        נחמה פוחצ&#39;בסקי, שהִכתה בפניהם בשלומיאליות מתחנחנת.]</span
      >
    </p>
  </body>
</html>`;

export default page;
