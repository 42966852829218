const modal = `<body class="c7 doc-content">
  <p class="c1 italic" dir="rtl">
    <span class="c5">הועתק מ</span
    ><span class="c3 tooltip"
      >דבש מסלע<span class="c1 tooltiptext" dir="rtl">
        <span class="c4"
          >נורית גוברין, נפ"ש מראשון לציון הומיה : נחמה פוחצ&#39;בסקי - מספרת
          ארץ-ישראלית, בתוך
        </span>
        <span class="c0 bold">דבש מסלע – מחקרים בספרות ארץ-ישראל</span
        ><span class="c4">, עמ&#39; </span><span class="c0">114-171</span
        ><span class="c4">, </span
        ><span class="c0 bold">משרד הבטחון – ההוצאה לאור, תל-אביב</span
        ><span class="c4"> תשמ"ט </span><span class="c0">1989</span>
      </span></span
    >
    <span class="c5"
      > עמ&#39; 134-133 (עם תודה לגרשון גרא שהעביר צילום של גלויה זו לנורית
      גוברין)
    </span>
  </p>
  
  <p class="c1 italic" dir="rtl" id="h.gjdgxs">
    <span class="c5"
      >על פי חותמת הדאר המכתב נשלח בפברואר 1909. הכותב הוא עורך שבועון הילדים </span
    ><span class="c4 c5 bold">הפרחים </span
    ><span class="c5 c9"
      >(שיצא בלוגאנסק, אוקראינה בשנים 1914-1908). איננו יודעים באלו סיפורים
      מדובר.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c2">סופרת מכבדה!</span>
  </p>
  <p class="c1" dir="rtl">
    <span class="c2"
      >שלש ציוריך לא יסכנו לי, מפני שכבר נמצא בידי ציור גדול מאת אחד הסופרים
      הארצישראלים שנגע גם הוא בענינים ההם ואי אפשר לִשנות את הדבר בסגנונים
      שונים.</span
    >
  </p>
  <p class="c1" dir="rtl">
    <span class="c2"
      >        שלוחים לך בזה נומרין אחדים של &#39;הפרחים&#39; ש"ע בהם תמצאי
      "מכתבים לחברי בגלות". אם כמתכנתם הואלת גם את לכתב (על דבר חיי הערבים,
      הטורקים, מנהגיהם, בתי-ספריהם וכו&#39; וכו&#39;) בסגנון ובצורה זו, כי אז
      הייתי לך אסיר-תודה רבה והייתי מקבל את מכתביך במוקדם. מה טוב היה לו תארת
      בצורת מכתבים את מצב אחינו התימניים, הספרדים, את חזון-הרוחות של כל השנה
      בהשתוותם לאותם שברוסיה, הצמחים, הפרחים וכו&#39; וכו&#39;, כי עתה הייתי
      ממלא בזה חסרון גדול בעתון &#39;הפרחים&#39; הנדפס ברוסיה והמכון כלו כלפי
      ארצנו החביבה!</span
    >
  </p>
  <p class="c1 c6" dir="rtl">
    <span class="c2">              מכבדך ודו"ש י"ב לבנר</span>
  </p>
  <p class="c1" dir="rtl"><span class="c2">P.S. </span></p>
  <p class="c1" dir="rtl">
    <span class="c2"
      >בכל א"י אין יותר משלשה חותמים על &#39;הפרחים&#39; . אבל בארגנטינה מספרם
      יותר ממאתים!</span
    >
  </p>
</body>`;

export default modal;
