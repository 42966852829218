const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c10 doc-content">
    <p class="c9" dir="rtl">
      <span class="c5"
        >בשבועות מתה בראשון לציון הסופרת נחמה פוחצ&#39;ינסקי, שאת ספורה ששלחה
        לנו בחייה אנו נותנים כאן.</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span class="c5"
        >נחמה פוחצ&#39;ינסקי, שהיתה הסופרת הראשונה בספרות הא"י החדשה, היתה אכרה
        ועסקנית חשובה. ביתה היה בית ועד למשכילים ולפועלים העברים הראשונים שעלו
        לארץ, היא דאגה לעובד העברי והשתדלה להקל עליו את סדורו בעבודה. בעשרות
        שנות שבתה בארץ נלחמה בכתב ובעל-פה לעבודה העברית במושבות, ולא פסקה
        ממלחמתה זו עד יומה האחרון.</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span class="c12">נחמה פוחצ&#39;ינסקי היתה מפרסמת מפעם לפעם ציורים </span
      ><span class="c12 c16">[סיפורים]</span
      ><span class="c5"
        > מחיי הארץ והישוב העברי ובעיקר של המושבות. היא היתה חביבה על כל יודעיה
        ובלויתה השתתפו רבים – אכרים, סופרים, פועלים.</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span class="c5">בת 64 היתה במותה.</span>
    </p>
    <p class="c9" dir="rtl">
      <span class="c14 c15">מעזבונה</span>
    </p>
    <p class="c11 text-center bold" dir="rtl">
      <span class="c7">רַק פִּתְקָה...</span>
    </p>
    <p class="c17 text-center" dir="rtl">
      <span class="c15 c14">(מחיי ילדה תימניה)</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0">זה היה בשנה השניה לעליתם מתימן. </span
      ><span class="c14 c0 c12"> </span><span class="c1 c8">שַמְעָה</span
      ><span class="c0 c12 c14"> </span
      ><span class="c1 c0"
        >ודודתה יצאו לגור מן המרתף הכללי אל המחסן של הגברת ז., אשר הכניסה אותה
        לשרות ולא הכבידה עליה את העבודה. </span
      ><span class="c14 c0 c12"> </span
      ><span class="c1 c0">עושה בעצמה הכל, רק היא, שמעה עוזרת על ידה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0"
        >לעתים, כשחסרו מים בבית, היתה באה הגברת במבוכה, אבל שמעה, באה מיד
        לעזרתה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0">– אני ארד אל הבאר ואביא מים! </span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0">פקפקה הגברת ואחר כך הסכימה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2">– אבל רק חצי פח תמלאי ולא יותר. </span
      ><span class="c0"> </span><span class="c1 c0">השומעת את?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0">– כן, כן! – והיא מסתלקת עם הפח בזריזות.</span>
    </p>
    <p class="c3" dir="rtl"><span class="c0 c1"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c1 c0"
        >הדרך היתה נטויה על יד בית הספר ולעתים היה תוקף אותה יצרה להתעכב כאן
        רגעי מספר ולהקשיב לקולות הערבים שעולים מן החלונות הפתוחים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2"
        >על יד גנת הבית שמול בית הספר עומד ספסל מהודק לשני ברושים מרובי
        צל. </span
      ><span class="c0"> </span
      ><span class="c1 c0"
        >עליו היא מעמידה את הפח, מזדקפת ונועצת עינים סקרניות לחלל החלונות, שבעדם
        היא רואה ראשים, ראשים עד אין מספר, וגבוה מכלם נראה ראשו של אותו המורה
        הרזה והיפה, שמבקר לעתים בבית גברתה. </span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0"
        >הוא מדבר לפני ים הראשים ומבאר להם דבר מה בקולו הצלול הרך והנעים.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2"
        >פתאום השתתק קולו ולבה התחיל דופק בקרבה. – מה זה? </span
      ><span class="c0"> </span
      ><span class="c1 c0">למה כה הורמו המון ידים באויר?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2">– שולמית! </span><span class="c0"> </span
      ><span class="c1 c0">מצלצל קול המורה.</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2">האח! </span><span class="c0"> </span
      ><span class="c0 c2"
        >את שולמית היא מכירה, זו הילדה בעלת הצמות הארוכות והסרט היפה הקלוע
        בתוכן... </span
      ><span class="c0"> </span
      ><span class="c0 c2"
        >מה מדברת שם שולמית וקול המורה צוהל לאות הסכמה לדבריה? </span
      ><span class="c0"> </span><span class="c1 c0">כנראה, חכמה שולמית!</span>
    </p>
    <p class="c13" dir="rtl"><span class="c1 c0"> </span></p>
    <p class="c3" dir="rtl">
      <span class="c0 c2">לבה הקטן של שמעה</span><span class="c0"> </span
      ><span class="c8 c2">מִתְכַּוֵץ</span><span class="c0"> </span
      ><span class="c0 c2">בהרכיבה את חצי פח המים על ראשה. </span
      ><span class="c0"> </span
      ><span class="c0 c2"
        >ולא על המשא תדאב נפשה, אלא על חוסר היכלת להמצא שם בפנים על הספסל בין
        יתר הילדות בנות גילה.  </span
      ><span class="c0"> </span
      ><span class="c0 c2">האם אינה בת ישראל כשרה ונאמנה כמותן – ומדוע</span
      ><span class="c2 c8">נִטְלָה</span><span class="c0"> </span
      ><span class="c1 c0">ממנה הזכות ללמד חכמה ככל הילדות?</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2"
        >הולכת היא שזופת הפנים ושחורת התלתלים, כשהפח מתנועע על ראשה ומאחריה כבר
        נשמע קול צחוקם העליז של התלמידים והתלמידות שהתפרצו מן המחלקה ומרעישים את
        האויר. </span
      ><span class="c0"> </span
      ><span class="c1 c0"
        >היא ממהרת את דרכה בצל התותים ולבה נוקפה על האִחור לשוב הביתה, כשגברתה
        מצפה למים.</span
      >
    </p>
    <p class="c11 text-center" dir="rtl"><span class="c1 c0">*</span></p>
    <p class="c3" dir="rtl">
      <span class="c1 c0"
        >הרבה ימים ולילות של הרהורים קשים עברו על שמעה לאחר אותו הבֹּקר, עד
        שהחליטה בנפשה, כי נחוץ לה לגלות את לבה לפני הגברת ולבקשה שתשיג בשבילה
        פתקה לבית הספר, היא כבר שמעה לא פעם שנחוצה פתקת-כניסה, בלי פתקה אי
        אפשר.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2"
        >הגברת מרחמת עליה תמיד ובודאי גם את רצונה זה תעשה בחפץ לב. </span
      ><span class="c0"> </span
      ><span class="c0 c2"
        >האח – מה טוב ומה נעים יהיה אז! – היא תחף את ראשה יום יום, תרחץ יפה
        בסבון את פניה וגם את רגליה, שתהיינה נקיות; </span
      ><span class="c0"> </span
      ><span class="c0 c2"
        >שמלת השבת תלבש ואת הסנור שתפרה לה הגברת לחג הפסח. </span
      ><span class="c0"> </span
      ><span class="c0 c2"
        >ילקוט תחפש לה וספרים ותרוץ בבקר השכם לבית הספר, רק</span
      ><span class="c0"> </span><span class="c8 c2">פתקה</span
      ><span class="c1 c0">, פתקה להשיג – וחסל!</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0"
        >בערב אחד גלתה ברטט את סוד לבה לדודתה, וזו הלמה אותה בגערותיה כרעם על
        השטות שגמלה בלבה להכנס לבית הספר, – ולחמה מאין ימצא?</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0">ואחרי הגערה קול תחנונים:</span>
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2">היא, הדודה, חלשה ואינה עובדת חָדְשִית, רק יוצאת</span
      ><span class="c0"> </span><span class="c8 c2">פעמַים</span
      ><span class="c0"> </span
      ><span class="c1 c0"
        >ביום אל המושבה, ממלאה אי אלו פחי מים לבתים וחוזרת אל מעונם בנשימתה
        הכבדה.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c1 c0"
        >בגרושים אחדים, שהיא מרויחה לא תוכלנה להתפרנס שתי נפשות וביחוד כשעוד חלה
        עליה שארית החוב של הוצאות הספינה!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c0 c2"
        >הדודה מדברת עוד ועוד, אבל שמעה הקטנה אינה מקשיבה אלא לקול לבה, להיכלה
        שנהרס שם. </span
      ><span class="c0"> </span
      ><span class="c1 c0">גופה הפעוט רוטט וכר הקש מתרטב מדמעות חמות...</span>
    </p>
    <p class="c4" dir="rtl"><span class="c6"></span></p>
  </body>
</html>`;

export default page;
