const page = `<html>
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c7 doc-content">
    <p class="c11 italic" dir="rtl">
      <span class="c10">דבר</span><span class="c0"> -  26.6.1934</span>
    </p>

    <p class="c8 text-center" dir="rtl">
      <span class="c5 c6">נחמה פוחצ&#39;בסקי</span>
    </p>
    <p class="c4" dir="rtl"><span class="c3"></span></p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >חשבון ישן וחדש לנו עם המושבה העברית. ולאור הימים האלה יש משהו מעודד
        בדמותה של נחמה פוחצ&#39;בסקי – אם, עקרת-בית, מנהלת משק, שקועה בדאגות
        יום-יום ועומדת עשרות בשנים על המשמר של העבודה העברית. בודדת או כמעט
        בודדת בסביבתה וכותבת על ספר את רשמי חייה. וכתביה, אם אין בהם משום אמנות,
        יש בהם משום עדות נאמנה על האדם.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >הכוונה ביחוד לא לקובץ "ביהודה החדשה" שנכתב בשנות נעוריה, כי אם לספרה
        "בכפר ובעבודה" שיצא לאור בזמן האחרון ונכתב בשנות העמידה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >גם את סיפורי "בכפר ובעבודה" אין להעריך כאמנות, אבל טוב שהם ראו אור. בהם
        מספר אדם בשפה עברית טובה ופשוטה על דברים שהוא חי בהם.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >יש בקובץ הזה מהוי המושבה העברית, מחיי בניה הצעירים, ובו משתקפת נפש אשה
        וגורל אשה. והתיאור מעורר אימון וכנראה נאמן הוא למקור, למרות הסנטימנטליות
        המפריעה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >כדי להכיר את האשה הזאת, נחוץ לקרוא את הפרקים: "המשק" ו"בבדידות". הגבורה
        היא אחת בשניהם: אשה, אשר הנוער שלה כבר חלף, אשר ידעה בחייה רק רמז של
        אושר קצר ועכשיו היא בודדת, אשה בעלת משק, אשר במסיבות החיים נידונה רק
        לעבודה קשה ומסורה למען המשק, ולאחר כך יכול לקום מישהו,  פעם זה גיס, ופעם
        זה אח – ולגרש אותה מביתה ומנחלתה. שני הסיפורים נגמרים בנישול האשה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >האשה הזאת היא עובדת נפלאה היודעת את כל צרכי הכרם והלול והרפת, בעלת בית,
        המקדישה לסדר ולנקיון ולטעם את מיטב כוחותיה, נותנת-עבודה חסכונית החרדה על
        בזבוז זמנם של הפועלים, ויחד עם כל זאת יש באופיה משהו של הלקאה עצמית, של
        חיטוט פנימי – משהו משל ברנר, ומשל כל איש אשר דקותו המוסרית עוברת את גבול
        הרגיל והמקובל.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >ההרגשה, שלא יצאה ידי חובתה, שקלקלה, שלא סיפקה, מותר להגיד – הרגשת החטא
        ממלאה את חיי האשה, אשר לגורלה מוקדשים שני הספורים, ולמרות זה – או אולי
        מפני זה – מספרות השורות הבודדות הצנועות על יחס בלתי רגיל לסבל ולכל מקרה
        שתבע עזרת קרוב.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >בולטת מאד השפעת רוסיה של שנות השמונים – בעדינות נפשה, בהערצתה את הטבע
        ואת הספרות. יש גם פרובלימה של אהבה כאשר עמדה לפני ה"קורסיסטקה" הרוסית של
        התקופה ההיא: החלוקה לאהבה "נצחית" ולאהבה "בת-רגע", פחד מפני הטומאה
        שבאחרונה וכתוצאה – חניקת מאוויי חיים. מוטיב ידוע בחיי הנערה העבריה
        ברוסיה בסוף המאה שעברה.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >שלשה הם ב"משק": היא, אחותה הצעירה והגיס, הרודה בשתי הנשים. החיים הם
        קטנים, פרוזאיים, מלחמת קיום אפורה ודאגות יום-יום. על הרקע הזה מתלבטת נפש
        מרכבת, ובעלת-תרבות, ואין היא יודעת להגן על אחותה ועל עצמה מפני עריצותו
        של איש קטן. היא עוד יודעת למרות לעגו של הגיס לשמור על זכותה לעזור
        לסובלים, אבל על כל שאר העלבונות היא יודעת רק תשובה אחת – "חובה", מילוי
        "חובה" לאחות ולילדיה. ההגזמה הזאת בהעלאת ערך ה"חובה". ה"מוכרחה" הזו – בה
        הנכס וגם האסון של היחיד בן הדור ההוא וביחוד של האשה בת הדור.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        > רושם מדכא עושה הסיפור "בבדידות". שנים זכרתי אותו. יש משהו מפחיד באור
        אשר נזרק ממנו על הדור הצעיר במושבה. אחות בת-ארבעים המחנכת את אחיה ואוהבת
        אותו אהבה עזה, אהבת אם. שני גזעים הם: היא בת הצפון, הטווה המשך לספריה
        האהובים גם בחיי המציאות, והוא בן המושבה העברית, חניך הרחוב המלא ערבים.
        לו היה נושא זה בידי אמן!</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3">הנה מה שהיא מספרת על האח והאחות האלה:</span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >"יום גשם, גשם ברכה, גשם זהב! ויום זה, אחרי שכל הכרמים שלנו נחרשו
        יפה-יפה, יכול להיות לי לחג, לולא המקרה הרע, שנכשלתי בלשוני ודיברתי באזני
        אחי סרה על מוסטפה, שגם הוא במחילת כבודו, עצלן גדול. אמנם זו היתה עזות
        מצדי ועל כן קיבלתי עונש קשה! – עמרם לא אכל את ארוחת הבוקר שלו... אמנם
        אין להאשים את עמרם על שגעונותיו – וגם לא על יחס הביטול שלו לגבי האשה. את
        זה רכש לו מהסביבה... כשגנתי עולה כפורחת, הוא מבקר אותה חריפות, ובכל זאת
        כשבאים אלינו אורחים הוא מוליך אותם אל הגן,למען הראות להם, איזה ירקות
        יפים יש לו! כשהוא מסיח לפי תומו: "הכרם החדש שלי... הפרות שלי..." אז
        מתעורר בי העלבון הגדול של אדם הנרמס ברגל, ונפשי תובעת ושואלת: היכן
        נעורי, מיטב שנותי ותמצית חיי, שהכנסתי במשק הזה?"</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >הנה קם דור שגדל על הקרקע, דור פורח, שהשפה העברית חיה בפיו והוא רודה
        בעבדים ערבים וחולם על אוסטרליה או אמריקה. לספר על הדור הזה כאשר היא
        מספרת על עמרם, במרירות, בכאב – זאת זכות.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >גורל האדם המתענה הבודד בין קרובים – הבדידות הנוראה ביותר – ניתן על ידה
        גם בדמות גבר ב"מוצא". גם הוא "לא יוצלח" כגבורת הספורים האחרים. גם הוא
        עוד במלוא כוחותיו, מותר על חיים פרטיים, גם הוא מקדיש את חייו לחינוך
        בנו-היתום. (אגב, יש קו ברנרי בדמות האדם המבוגר, העושה או הרוצה לעשות
        מטיפול בילד עזוב מרכז לחייו). וכשהבן גדל, ולו ולאשתו לא היתה הסבלנות
        לשאת עוד את הקושי ואת האכזבות שבחקלאות העברית ושניהם נוסעים לאמריקה,
        עובר על האב זעזוע עצבים קשה. שני גזעים. עד שנמצא המוצא: האב מוסר את
        מחצית חלקתו לקבוצת פועלים "והוא חי בקבוצה כחבר נכבד והיה לה למורה דרך
        בעבודה".</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >את ברכת הארץ ומעשיותה נכיר ביכלתה של הסופרת להתגבר על הרומנטיקה הרוסית
        ועל החולשות, מתוך התמזגותה עם המשק ועם צרכי הצבור ובכוחה לעבור את החיים
        קוממיות, כלוחמת.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >גם מלחמתה של נחמה פוחצ&#39;בסקי על זכויות האשה נבעה מהתקוממות טבעית של
        אשה, בעלת יכולת ובעלת נפש, נגד היחס של ביטול, ומרצונה העז, לפעול ולהשמיע
        דעה גם בעניני הכלל, הנוגעים ללבה ודאי יותר מאשר הם נוגעים ללב גברים רבים
        "בעלי דעה".</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >והמפתיע באופיה היתה הרדיפה אחרי מעשים טובים, - "אסון החמלה", ונדמה, כי
        מאור אחד חוצבו גם מעשי הצדקה שלה וגם ההגנה על העבודה העברית – מאור
        המצפון ה"חולה", הפרטי והלאומי.</span
      >
    </p>
    <p class="c2" dir="rtl">
      <span class="c3"
        >לא לחנם התאבלה מושבת ראשון-לציון על כל שדרות הישוב שבה על מותה. כה
        התאבלה לפנים עירה יהודית על מות איש צדיק בקרבה.
      </span>
    </p>
    <br />
    <p class="c2" dir="rtl"><span class="c3">ר.כ.</span></p>
    <p class="c2" dir="rtl">
      <span class="c5">רחל כצנלסון שזר</span>
    </p>
  </body>
</html>`;

export default page;
