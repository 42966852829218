const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c0 doc-content">
    <p class="c2" dir="rtl">
      <span class="c3">פורסם לראשונה בעתון </span
      ><span class="c1 bold">השִלֹח</span
      ><span class="c3"
        > בגליון שבט תרע"ג [ינואר 1913] (כרך כ"ח חוברות קס"ג-קס"ח עמ&#39;
        50-43). (הוא נכלל גם בספר סיפורי נשים בנות העליה הראשונה, בחרה והתקינה
        יפה ברלוביץ, הוצאת תרמיל, משרד הבטחון, 1984)</span
      >
    </p>
  </body>
</html>`;

export default desc;
