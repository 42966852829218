const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c2 doc-content">
    <p class="c1" dir="rtl">
      <span class="c3 bold">המליץ</span
      ><span class="c0"> כט תשרי תרנ"ד  [1893 10 09]</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c5">הרהורים על גדות הדונאו ורסוס חוף יפו</span>
    </p>
  </body>
</html>`;

export default desc;
