const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c17 doc-content">
    <p class="c0 italic" dir="rtl">
      <span class="c7 c9">המאמר פורסם בשנת 1908 בעתון </span
      ><span class="c5 bold">העולם</span
      ><span class="c11 c7 c9"
        >, העתון הרשמי של ההסתדרות הציונית העולמית אותו ייסד וערך נחום סוקולוב.
        המאמר פורסם בארבעה המשכים (גליונות ל"ו, ל"ז, ל"ח, מ&#39; של העתון
         11.9.1908 – 9.10.1908).
      </span>
    </p>
    <p class="c0 italic" dir="rtl">
      <span class="c7 c9 c11"
        >בהדפסה זו הוספנו לעיתים כתיב מלא ואף הערות הבהרה [בסוגריים מרובעים]
        לנוחיות הקריאה</span
      >
    </p>
    <p class="c0 italic small-text" dir="rtl">
      <span class="c8">המאמר נמצא גם בספר </span
      ><span class="c8 c13 bold">אעברה-נא בארץ  מסעות אנשי העלייה הראשונה</span
      ><span class="c6"
        > - אספה וערכה יפה ברלוביץ בהוצאת משרד הבטחון 1992, עם הערות הרחבה ועם
        צילומים הסטורים.  </span
      >
    </p>
    <p class="c0 c3" dir="rtl"><span class="c11 c15"></span></p>
    <h3 class="c4 text-center" dir="rtl">
      <span class="c11 c14">מראשון-לציון עד מרג&#39; עיון</span>
    </h3>
    <p class="c4 text-center" dir="rtl">
      <span class="c11 c19">נחמה פוחצ&#39;בסקי</span>
    </p>
    <h2 class="c4 text-center bold" dir="rtl"><span class="c11 c14">א</span></h2>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחג הפסח עזבתי את מושבתנו היפה לעלות ירושלימה ומשם – דרך שכם, עין-גנים
        והר תבור – לגליל התחתון ודרך ים כנרת לגליל העליון.         </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        מפני סבות שונות לא נסעה חבורתנו דרך שכם גלילה, כמו שהתנינו
        מראש, אך שבה ליפו ללכת משם באניה לחיפה ואחרי כן לגליל התחתון.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        נסענו ליפו בעגלה. אחרי שכונת "מחנה-יהודה", עברנו על יד
        בית-החולים האמסטרדמי  "שער צדק", בית-מושב זקנים, בית-המשוגעים. הם כמו
        בכונה סוגרים את ירושלים למען השאיר על הסָיָרים רושם טוב; כי יפים ונהדרים
        הבנינים האלה ומרוממים את ערך הצדקה הירושלמית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        הכפר היותר קרוב אל העיר על דרך יפו, הוא ליפתה. אחריו רואים על
        גבע מורם את רמה, הנקרא בפי הערבים "נביא שמואל". ביחוד נבלט מסגד הכפר
        ההוא, הבנוי – לפי מה שמספרים הערבים – על קבר הנביא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        למטה, מימיננו – עמק נהדר, ובתוך העמק בנוי בית חרושת (של יהודי)
        לבורית. והולך ונמשך העמק עד מוצא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >         ועל ההר בצד מוצא, בנוי יפה הכפר קולוניה, ושונים הכפרים פה מאלה
        שבסביבות מושבותינו ביהודה. הבתים בנוים פה מאבן, וגדולים הם ומרוחים; גם
        חלונות קרועים בהם, רק הגגות אינם מכוסים רעפים, כי אינם משופעים; מזרחיים
        המה, ערבים, וביהודה, הערבי שהתעשר במושבה יהודית, בונה לו בית עם גג של
        רעפים דוקא; ואלה שעוד לא זכו לבתי-אבן, יושבים בבית-חמר דל וצר בלי חלון
        ובלי אור.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        ועשירים הכפרים שלפניך. הנך רואה כרמים נחמדים, זיתים יפים,
        מעובדים על פי האופן היותר טוב. גם רוח אחרת שולטת בכפרים האלה. פה גדולה
        השפעת המשלחת הנוצרית. כמעט סמוך לכל כפר הנך רואה בית-תפלה ובית-ספר של
        נוצרים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        ועל אם הדרך משתרעת מוצא הקטנה, בת שמונה מאות דונם. שמחה מיוחדת
        הנך מרגיש, כאשר פתאום, על דרך המלך, הנך פוגש בחברה של ילדים עברים והם
        קוראים בקול רם: "שלום, אדוני!" או "שלום, גבירתי!". וגרות פה המשפחות
        רחוקות זו מזו, ורחוקים חלקי אדמתם אחד מן השני. כגבורים אמתים הם יושבים
        פה, בלי שומרים ובלי נוטרים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        ורחוק, כמעט על קצה גבול אדמתם, השאיר למו הרצל זכרון יקר. הוא
        נטע שם ארז, והעץ גדל, פורח ומשתגשג. על ידו עומד ארז קטן שנטע מר ולפזון,
        ויכתרו האכרים את שני העצים בגדר של אבנים, ומתעתדים לנטוע שדרה של ארזים
        סביב על חלקה גדולה. נצמדתי אל המקום ההוא. איזה קדושה חופפת עליו! הנך
        עומד בלב דופק מול הארז הרם; נדמה לך רגע שפה שוכנת הנפש העדינה של המנהיג
        הגדול. רוצה הנך לכרוע ברך, לחבק את העץ הזה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        געגועים עזים מתעוררים בקרבך לאיש שנטע את הארז... למה כה מיהר
        ללכת מאתנו?! - -        </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c7"
        >        בעזבנו את מוצא הננו פוגשים ביער של זיתים, העולה מרגל ההר ועד
        ראשו, ושם, על אמיר ההר מתנשא </span
      ><span class="c7 c9 italic">[מראש ההר נראה]</span
      ><span class="c1"> כפר נוצרי, "עין כרם" עם בית-ספר מהולל.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        הנה הר הקסטל, עם כפר בנוי על סלע איתן, אשר שם היה לנו מבצר חזק
        בימי החשמונאים. משם הננו יורדים לבקעת דלב, ורואים את המעין המפואר הנושא
        את שם הבקעה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c7">        והנה גם קרית-יערים, הנקראת עתה בשם אבו-רוז </span
      ><span class="c7 c9 italic">[אבו-ע&#39;וש]</span
      ><span class="c1"
        >, על שם אחד העריצים הערביים והנך משתומם לראות את עשרה של הפנה
        הזאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c7"
        >        עוברים עוד הרים ובקעות וכפרים ובאים לבב-אל–ועד, לאמר: שער העמק,
        נחים שם וחודרים אחרי-כן לעולם חדש: שדות רחבים, ירוקים, מזהירים, כל גיא,
        כל גבע מושכים את העין ביופים והנה גם כרמים עבודים היטב ודומים לכרמי
        מושבתנו. שער יפה נפתח לאחד הכרמים ועליו צלב גדול, שחור, על אדמות נטרון </span
      ><span class="c7 c9 italic">[לטרון]</span
      ><span class="c1"
        > אנחנו עוברים, נטרון הידועה בגבינה המצוינה שלה, ושם מנזר ישועי, קן
        לקנאות שחורה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        הנה משתרע לפנינו השרון, הנה רמלה עם מגדליה, ושמה – עקרון, גדרה,
        רחובות...</span
      >
    </p>
    <p class="c16 text-center" dir="rtl">
      <span class="c1">*   *   *</span>
    </p>
    
    <p class="c0" dir="rtl">
      <span class="c1"
        >בצהרים עלתה חבורתנו על האניה ביפו. הים היה כה שקט באותו יום, עד שאפילו
        בין הסלעים הלכה סירתנו ישר מבלי התנודד, ומבלי שום עמל וקפיצות עלינו
        מהסירה על האניה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        בעזבי את חוף יפו, דפק לבי בשקט כאותם הגלים הטהורים של הים
        הכחול. חשתי את כל נועם האושר שמרגיש האדם בהשיגו את חפצו היותר קדוש, הלא
        אני הולכת ומתקרבת לשאיפתי הגדולה לראות את הגליל! מאושרה אנכי...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        אבל הנה סרה הרוח הטובה מעלי: באותה האניה נוסעת לרוסיה משפחה אחת
        שגרה במשך שנים רבות בארץ-ישראל, ושפת המשפחה הזאת – נשארה רוסית. הבן
        הבכור, נער שגדל כשש-עשרה שנה בארץ-ישראל אינו יודע לדבר עברית! אבל
        התנחמתי: משפחה זו – אמרתי בקרבי, יחידה הנה בארצנו, אבל הנה מגיעה לאזני
        שיחה בין שני צעירים שנוסעים לחיפה. אחד, בן המושבה וגר עתה בחיפה, מספר
        לרעהו, על אודות מצב האכרים בזכרון-יעקב ותקוות הדור הצעיר, על מצב
        בית-הספר והרוח הלאומי שמתחיל לתפוס מקום בו, על העברית, שהולכת בימים
        האחרונים ומתפשטת. והנה רעהו עונה: "הניחו לעברית שלכם. מה התועלת שבעברית
        שלכם? זוכר אני עוד את בית-הספר ב&#39;זכרון&#39; לפני שָנים עשר, אז היתה
        הצרפתית שולטת; צרפתית – זה אני מבין, שפה חיה, אירופית, ועתה – עברית,
        עברית בכל אשר אתה פונה, איזו שטות! לוא לימדו במקומה לפחות ערבית או
        טורקית!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        רוסית, צרפתית, או "לפחות" ערבית וטורקית, רק לא עברית! הוי, מתי
        ניגאל מעבדותנו הפנימית? - - -
      </span>
    </p>
    <p class="c0 c3" dir="rtl"><span class="c1"></span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        לחיפה באנו בערב. אחרי עברנו את העיר העתיקה נכנסנו למושבה
        הגרמנית. מעון מכירי, ששם סרתי ללון, נמצא בקצה המושבה, ועלי היה לעבור
        אותה לכל רחבה. סביב היה חושך, בכל זאת נכר היה הסדר והנקיון, היופי והעושר
        שבמקום הזה. ואז בפעם הראשונה בארץ-ישראל הרגשתי קנאה לאחרים. בלי רעש
        והמולה בוראים להם הנכרים גני עדן בארץ הזאת...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        בבקר עזבתי את חיפה. עגלה גרמנית נשאה אותנו מן העיר. בעברי את
        העיר לא פגשתי באחינו, בלתי אם בנער עברי אחד נחמד, שקרא לי: "שלום" –
        בעברית, בצלצול הרוסי...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >        הזה הוא המרכז הנאור של "תל האביב" העתיד? ואיפה אתחלתא
        דגאולה?... כן, הנה ה"עתיד". בית-החרושת העברי הראשון על חולות שפת-הים
        בחיפה. שמחה פנימית מעיר בית-חרושת במקום קנאה שמעורר בך הרובע הגרמני –
        גאוה: פה אתה רואה התחלה לתעשיה עברית! צעיר עודנו "עתיד" וכבר קנה לו שם
        גדול בארץ על-ידי בורית הכביסה שלו ובורית הרחיצה, המצוינה בטיבה
        ובריחה.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    
    <p class="c0" dir="rtl">
      <span class="c1"
        >כל מה שהתרחקנו מן הים ומן העמק אל הרי אזר, החילותי יותר להכיר את השינוי
        שבין הטבע פה ובין אותה, שחייתי בה זו השנה התשע-עשרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מה עבותים היערות על מורדי ועל ראשי ההרים! אינך פוגש יותר את אותו הסלע
        הערום שהתרגלת לראות בהרי יהודה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וגם הדרכים פה אינן שוממות. הנה מטיילת לה יחידה, אנגלית צעירה לימים,
        והיא חמושה בכלי זין. ביד אחת היא אוחזת ברסן סוסה ובשניה היא מלקטת פרחים
        בעמק ומאגדת אותם. "מג&#39;נונה (משוגעת)", מעיר עליה הרכב שלנו. ערבי צעיר
        ויפה, ויצחק בצחוק שטני.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">יצאנו את ההרים והיערות ובאנו לעמק יזרעאל...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אלי! איזה יופי, איזה מרחב! כמו שטיח אחד, בעל צבעים שונים הוא הולך ונמשך
        לימין ולשמאל. ושם, רחוק, בקצהו סובבים אותו מכל צד הרי הגליל. הנך משתומם
        ועומד נדהם למראה תמונת קסם זאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה כפר מונח על גבעה קטנה, קרוב לדרך. סמוך אליו בעמק נפוצו עזים לאלפים.
        הרועה מאסף אותן בחלילו וקוראן למים, והן הלכות, נדחקות זו לזו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רואים הלאה את ג&#39;נזה ואפולה, תחנת מסלת-הברזל החג&#39;ית, ההולכת
        מחיפה לדמשק ועוברת דרך עמק יזרעאל. הנה רצה שם רכבת ומתפתלת כנחש שחור על
        פני השטיח הנפלא...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התרחקנו מעמק יזרעאל, ונכנסנו לסביבת נצרת המהוללה. אחרי היופי המקסים של
        עמק יזרעאל אין היא לוקחת עוד את לבי: למול עיני עומדים עוד כל קויו
        הבהירים והנפלאים... והנה באנו לנצרת, הבנויה בחצי עגול יפה על גבעות.
        רואים צלבים וצלמים על כל פתח ועל כל גג. בערות וקנאות מורגשות  באויר.
        הנצרות התחזקה והשתרשה בארץ הולדת היהדות. העיר כלה, תושביה, כהניה, כמריה,
        בתי-התפילה והמנזרים שלה מעיקים באיזה כובד נורא על לבך...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשעה אחרי נצרת עברנו את כפר-קנה היפה, המונח בעמק והרים סביב לו, יפה
        ביחוד הר אחד העולה באלכסון, ומתרומם עד העבים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפנות ערב עברנו את עמק טורן. העננים פרשו אז צל מיוחד על הצבע הכהה של
        אדמת העמק החרושה, שהשתרעה עד לאין-סוף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סרנו מדרך המלך ללכת לסג&#39;רה. העגלה מתחילה להתנועע מצד אל צד, ודופקים
        האופנים על פני רגבי האדמה הקשים. בדרכנו ראינו מאחד הגבעות מראה-פלא: מצד
        צפונית מזרחית של אופק השמים נראה פתאום ראש החרמון עם פסי הכסף שלו.
        לשמאלו נמשך הר ברוחב, ואחרי ההר הזה רואים המון הרים גבנונים ועליהם תמונה
        מזהירה באויר, תמונת-פלא וקסם שלא ידעתי שחרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"מה זה? שלג המבריק לקרני השמש האחרונות, או ענני פז?" שאלתי את הנוסעים,
        ולא יכלו לתת לי תשובה על זה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לסג&#39;רה באנו לפנות ערב. לפני שער החוה פגשנו את המורה ו. ופועלים
        אחדים ששבו מהעבודה. נכנסנו לחצר. שם בית הפקיד, משמאל – חדרי הפועלים
        והפועלות, מימין – בית-הספר, בית המורה, בית-המרקחת ועוד. הפקיד קיבל אותנו
        בסבר פנים יפות. גם הפועלים שמחו לקראתנו, בשמעם מפינו שלום מחבריהם
        שביהודה. בחברתם בילינו עד שעה מאוחרת בלילה. חברה מענינת מאד של פועלים
        ישנה בסג&#39;רה. כשלושים במספר הם, יחד עם הפועלות העובדות בחוה ובשדה.
        הרוב הם משכילים ומדברים עברית צחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בקרנו בבתי אריסים אחדים, צעירים קשורים לארץ-ישראל, והעובדים אותה באהבה.
        בקרנו גם בבית אריס אחד מהגֵרים. אם המשפחה, אשה בריאה בעלת כתפים רחבות,
        פנתה אל שלשת חברי, ששמעה מפיהם שיש בדעתם להתישב בארץ-ישראל, ותאמר להם: -
        רבותי! קנו פה אדמה. הביטו נא, מה טוב פה, מה נעים! ראו איזה עשב רך ונעים,
        איזה פרחים משמחים לב, איזה אויר, איזה חופש!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לא האמנתי למשמע אזני, שמפי אשה פשוטה כזאת, אני שומעת מלים כאלו מלאות
        שירה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מסג&#39;רה שלחנו את עגלתנו למסחה ושכרנו לנו סוסים לעלות על התבור.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בדרך יפה, בנויה מדרגות ורצפות, העשויה לכבוד ביקורו של המלך וילהלם
        בארץ-ישראל, עלינו על התבור. באמצע ההר עצרנו מלכת, כי פתאם נגלה לעינינו
        עמק הפלא, עמק יזרעאל בכל יפיו והדרו. שטיחים, שטיחים נפלאים פרושים תחת
        כפת השמים. כלו הוא ישר, ירוק, נחמד, כלו זרוע, חרוש, צומח; העין לא תשבע
        פה מראות את כל היופי, את כל החמדה שבחלקה הזאת. אבל הלב דופק בחזקה מרוב
        התרגשות וצער, שלא לנו הוא עמק הפלא, ושזרים מושלים בו. לא באו אחינו
        לפדותו בעוד מועד, לא בקש עמנו לפתח פה את כחותיו המעונים, לא בקשנו מרחב
        לגאון העברי, להבריאהו ולהקימו לתחיה. הושבנו חולות, בצות, סלעים ואת עמק
        יזרעאל עזבנו בשביל אחרים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היו ימים שיכלו פה לקנות אדמה, ועתה קשה הדבר: האדמה התיָקרה ובעליה לא
        בנקל ימכרוה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על ראש התבור נכנסנו למנזר אטלקי. שם נהוגה הכנסת אורחים באופן מצוין.
        ועלה על לבי במקום הנחמד הזה, כי מאושרים הנוצרים, בעלי המנזר, שתפשו את
        המקומות היותר יפים בארץ-ישראל, את הפינות היותר נחמדות בעבר שלנו, אותן
        הפינות שמעוררות בלב האדם את הרגשות היותר קדושים, היותר נעלים, שחלקו לו
        מאת הטבע. ואת הרעיון הזה רשמתי בתוך ספר הזכרונות של המנזר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נזיר אחד העביר אותנו דרך בנינים עתיקים מענינים מאד עד קצה הר. עלינו על
        שרידי מעלות של קיר חרב וראינו פתאום, מגובה של 600 אמה, את מסחה המביטה
        בחן שם, בעמק, למטה. יפה מפה מושבה זו ובלבבת אותך. איזה נגוד חי אתה מרגיש
        בינה ובין החרבות האלה, עם חיי המנזר הדוממים שלהם!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הירידה מהתבור לא היתה מצליחה. יעצו לנו ללכת בדרך יותר קצרה, ונמשכה הדרך
        כפלים במוצאנו בה קוץ ודרדר, ושבילים צרים ומסוכנים אשר בכבדות עברנו
        בהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באנו סוף סוף למסחה. בתיה, הדומים זה לזה בנויים בשתי שורות. היא תופשת
        מקום של 6500 דונם אדמת זריעה, שנחלק בין 28 אכרים. סמוך למסחה יש לקנות
        אדמה, והאכרים דורשים מהפקידות של יק"א, היושבת בנצרת, לקנות את האדמה
        הזאת, להוסיף על חלקיהם, ולהושיב עוד אכרים עליה. כלם מקוים לשנה טובה.
        הגשמים היו מרובים ובעתם, והתבואות מרהיבות את העין ביפים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פגשתי במסחה צעיר רחובותי, העובד אצל אכרה אחת בתור חרת על אותם התנאים
        אשר עובדים מאות ערבים במושבות היהודים בגליל; לאמור: הוא חורש, זורע וקוצר
        את אדמת בעל-הבית שלו ומקבל חלק חמישי מכל התבואה. ובריא הצעיר הזה ושמח,
        ומקוה להשיג מהפקידות על-ידי חריצותו חלקת אדמה להתנחל עליה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >המורה ע. מכירי מיהודה, הוביל אותנו לבית-הספר הריק. היו אז רק ימים אחדים
        אחרי החג, והלמודים טרם החלו. ראינו ילדים משחקים בחוץ, או מטפסים על
        הגדרים ופניהם בריאים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לא השתהנו במסחה, ונסענו משם דרך שדותיה הזרועים פול, עדשים, חמוץ, חלבה,
        חטה, שעורה ושבולת שועל, ליבניאל, היא ימה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה עוברים אנחנו את כפר "קמה", העירה הצ&#39;רקסית העשירה. פה הנך פוגש
        הרבה נטיעות של עצי תות ושל גפנים, ובתוך העירה הנך רואה בתים גדולים
        ומרוחים. עברנו את שדותיה הרחבים, המשתרעים עד קצה העמק, ומתחילים אנו
        לעלות על הרי נפתלי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זכר כל ההרים והעמקים שעברת, בא פתאום לפניך, ואיזה רגש נעים ממלא את
        כולך. הרעיון שעכשיו הנך מכירה, הנך יודעת, מה היא ארצך ארץ ההרים, ארץ
        הפלאות, מעודד אותך. כשהנך חי שנים רבות ביהודה, על המישור הגדול הרחב ולא
        ראית את הגליל הנך חושב כי ככה הוא תכונת כל הארץ, ולא תוכל לשער בשום אופן
        את בקעות חמד עם הרי פלא אחוזים ודבוקים זה בזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פה, על הרי נפתלי, פגשתי, בפעם הראשונה בארץ-ישראל את השפע של מים רבים.
        על כל צעד ושעל שוטפים מי המעינות; ויורדים להם לאט לאט מההרים היפים אל
        תוך הבקעה הצרה. בין שני רכסי ההרים הלכנו רגלי והתענגנו על יופי הטבע
        במקום הזה, פתאום הופיעה לפנינו תמונה נהדרת: ימה, הבנויה בעמק, לרגלי הרי
        נפתלי, מושכת אותך ברוב חנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בימה נמצאים כעשרים ושנים אריסים ועשרים אכרים, לכל אחד מהם כשלש מאות
        דונם אדמת זריעה, הנעבדת רובה על-ידי חרתים ערביים, היושבים על הרוב עם
        משפחותיהם בתוך המושבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פועלים יהודים רק כחמשה במספר בכל המושבה. אחד מהם, עניין אותי ביחוד.
        בהתלהבות גדולה הוא מדבר ברכישת הקרקעות סביב לימה. הוא מעביר את ידו ימינה
        ושמאלה, קדמה ונגבה, ואומר:
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הכל, הכל אפשר לקנות, את כל ההרים, הגבעות, העמקים האלה אנחנו נרכוש
        בקרוב. צריכים אנו לאחד את ימה למסחה ועם מלחמיה, וכל הגליל צריך להיות
        בידינו!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועובד הצעיר באמונה את עבודתו, ואוהב אותה וחולם את חלומותיו הנעימים על
        דבר רכישת כל הגליל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבית-הספר היבניאלי מתלמדים כשמונים ילדים וילדות. המורים מעסיקים אותם
        הרבה בעבודת גן-הירקות, ומדברים מזה בחבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אל המושבה הביאו מים מהמעינות, ובכל החצרות וברוב הגינות הנך מוצא צנור
        מים. איזה עושר לאכר!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נכנסתי לבתים אחדים, ומצאתי בהם נקיון נפלא. בעלות-הבית מתעסקות הרבה
        בגידול עופות. מגדלות הן ביחוד אוזים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מראה הבתים מחוץ הוא מצוין. האבנים אם צבע אפור כהה, לא מכוסים בטיח, וגם
        לא מלובנים בסיד (כמו ביהודה), ונראו הקירות כמו עשויות תבות-תבות
        רבות,</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הלכנו בבקר, אחרי השינה בימה, לבית ג&#39;ן <span class="italic">[בית גן]</span>. שמה ראיתי בראשונה
        יהודים יושבים בבתי חמר, כערבים. בבית ג&#39;ן יושבות 8 משפחות של גרים,
        משפחה אחת של היהודים ההרריים, משפחה ספרדית, וגם אשכנזי אחד ומשפחתו.
        מדוכים אנשים, סובלים הם מגשם בחרף ומקדחת בקיץ, מתאוננים על מצבם הרע
        ושואפים שיושיבו אתם במקום אחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עזבנו את ימה אחר-הצהרים, מלאי רגשי תודה לאנשים אשר הראו לנו שם ידידות
        כה חמה ותמימה, ופנינו מועדות למלחמיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם מלחמיה בנויה כשתי אחיותיה, ימה ומסחה, רק הרחוב פה רחב יותר מזה של
        ימה, ויש מקום לגנות לפני הבית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לכל אכר (מספרם 21 במושבה) יש חלקה של 800 דונם אדמה זרועה. האכרים שמחים
        לשנת ברכה זו, ולולא חובותיהם של השנים הרעות הקודמות, אז היו שמחים
        בחלקם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבית האכר הצעיר ק., הבריא והרענן בלינו רגעים נעימים מאד. שם אכלתי
        עגבניות אדומות, אף כי זמן בכורי העגבניות עוד טרם הגיע, אך האכר הזה השכיל
        הודות לחריצותו, להמציא לביתו עגבניות וגם בי-גנים <span class="italic">[חצילים]</span> בזמן מוקדם
        כזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבית הספר – 37 תלמיד עם מורה ומורה אחת, ראינו ילדים אחדים העובדים בגן
        בחריצות גדולה. המורה הראה לנו ספריה (בית-ספרים) מפה, שאסף בעמל רב. ילדי
        המושבה, ספר המורה, מרבים להתענין בקריאה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נפרדנו ממלחמיה וממכירינו החדשים שמה. בעזבנו את המושבה ראינו בפינת
        הרחוב, על יד בית-המרקחת, שתי עלמות מענינות עובדות בגינה. המחזה הזה היה
        כה חדש בעיני עד שזמן רב לא סרתי את עיני  ממנו. מתי נזכה לראות עלמות
        עובדות בגן ביהודה? - -
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ממלחמיה הלכנו לטבריה. על אם-דרך זו פוגשים, מימין במכר קרוב וחביב:
        הירדן. ומשונה הירדן ב"מצבי-הרוח" שלו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנהו נוזל לאט לאט, שקטים, זכים מימיו ומנוחה נעימה חופפת עליהם ופתאום
        במרחק צעדים אחדים ממקום המנוחה הזה – יתקצפו יתגעשו מימיו ונופלים ברעש,
        ושוטפים בהמולה לדרכם ושבים שוב למנוחתם...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אלפי מחשבות וזכרונות מעיר הנחל הזה עם מימיו הזכים-כחולים-תכולים
        והשקטים-קוצפים. מתגבר בך הרצון לכרע ברך על שפתיו, לנשק אותו, לבכות
        ולשיר, ולשפך לפני השובב העתיק הזה את כל מצוקות לבך...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה גם ים טבריה, ים כנרת. חופו הגבוה מימין שומר עליו ומחייך, ומשתקף במי
        הים המזהירים. פלאות מסביב. רק שם בקצה הימיני של הים נכרות עקבות החרבות.
        שם נראה התחנה צמח על המסילה החג&#39;ית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התקרבנו אל חוף הים. רעש הגלים המכים באבני החוף מרנין את האזן, עצי
        ליאנדר עם פרחים ורדיים צומחים על החוף, כמה יפים הם בתוך הסביבה הפראית
        הזאת!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפני בואנו העירה, על יד חמי טבריה, עברנו את הבנין שעל קברו של ר&#39;
        מאיר בעל הנס. שם שתי ישיבות, אחת לאשכנזים והשניה לספרדים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רוב יהודי טבריה חיים על החלוקה. יושבים המה צפופים ודחוקים במעונותיהם
        הצרים שברחובות המעופשים. עברתי ברחוב צר אחד, מלא רפש וטיט. הנה עומדת
        באמצע הרחוב מול "ביתה" אשה זקנה, וכובסת. ברחוב זה נכנסתי לדירה אחת, מרתף
        לח וצר; בחדר – או בדומה לזה – מטה ושלחן ושני כסאות שבורים סמוכים זה לזה
        מאפס מקום. רק אצל הדלת יש מקום פנוי כאמה על אמה, ובמקום הפנוי הזה יושבת
        אשה צעירה וגם היא עסוקה בכביסת לבנים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וצפופים הרחובות, ודחוקות הסמטאות. ומתפתלות הן אחת בשניה – "לבירינט"
        ממש. ואנשים כפופי-גב, עם פנים חורים, עינים שקועות, בגדים קרועים
        ומלוכלכים, נעים-נדים, ממלמלים – בקצור: חיים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רפש וזוהמא, חוסר אויר ואור, פאות היורדות עד הצואר, בערות, טפשות,
        "חלוקה", עניות מנולת, שעמום – הנה תמונתה של טבריה שלנו בחומר
        וברוח.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלב מלא עצב עזבתי את רחובות היהודים ועליתי למלון "טבריה", הגרמני.
        מהמעקה ראיתי שם, מימין, את העיר הצפופה והדחוקה, ועל הככר לפני, מול
        המלון, בית החולים האנגלי, מנזר קתולי ובית-ספר של המשלחה השוטלנדית.
        בנינים מרווחים. ואיזה נקיון!</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבקר עזבנו, רוכבים על סוסים, את טבריה לנסע לראש-פנה. לימיננו הים התכול,
        לשמאלנו – הרי פלאים המכוסים ירק נחמד, והמפתיעים אותך לרגעים בסלעיהם
        הערומים, השחורים והאיומים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וגם הים מלא הפתעות. הנהו שוקט ומלטף בחבה וברוך את הצמחים שעל שפתו,
        ובמרחק צעדים נהיה לשובב קפדן; רועש הנו ומכה בזעם על אבני החוף הלבנים,
        הזכים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועל הרי החוף הימני, עפים-טסים עננים קלים באויר ומכסים את ההרים בצעיף קל
        כחול-אפורי. זעיר שם, זעיר שם חודרים קרני השמש מבין העבים ושופכים את אורם
        המזהיב ברצועה ארוכה מן ההר אל הים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשעה לפני הטפחה, ששם נמצא מנזר קתולי, על קצה ים כנרת מצפון, סרנו הצדה
        והחילונו להתרחק מן הים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עברנו את מג&#39;דל ונבא לעמק גניסר. בבצות העמק הזה הנך פוגש בצמחים
        פראים רבים, הנפלאים במינם, וזוכר הנך את פירות גניסר, שהיו כה מהוללות
        לפנים. גם עתה לוקחים מפה כל שנה ושנה שתילי עגבניות לכל קצוי ארץ פלשת
        וסוריה.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה הגענו לדרך מלאה סלעים ולמדבר שמם. במקומות האלה, כשבכל רגע נגפו רגלי
        הסוס בסלע החלק, נדמה לך שמלאך-המות מרחף לפניך. עוד דק – ונופל הנך אל
        התהום העמוק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >דרך זו מטרידה עד מאד. סר וזעף הנך גם כשבא אתה אל המישור ואפילו כשאומרים
        לך כי "ראש-פנה" קרובה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על המישור מוצאים הננו פה ושם אהלי תיירים עשירים, ההולכים לדמשק. לימין
        על ההר נראת חורבה עתיקה. על שאלתי מה היא, עונה לי הערבי המלוה אותנו
        "ג&#39;יב יוסף!" (בור יוסף): מאמינים הערבים שפה נמצא הבור שבו הושלך יוסף
        על ידי אחיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קרוב לראש-פנה הומה הדרך מאדם ובהמה רבה. הנה עוברת לפנינו שיירה של שלשים
        איש בערך, ההולכת מטבריה לזחלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"ראש-פנה!" קוראים בני-לויתי בשמחה. נדהם אתה מתבונן למושבה היפהיפיה
        המשתרעת על רגל ההר והטבועה כולה בים של ירק. בלב דופק מחדוה הנני עוברת
        בשדותיה-בר הנפלאים ונכנסת אל המושבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">יפה ראש-פנה – גן-עדן!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הטבע העניקה לה מיפיה ביד נדיבה: בדרום המושבה מתנשא הר כנען. הרי הבשן,
        המכוסים בבקר בטל מכסיף – ממזרח. ושם בצפון, הישיש בכבודו ובעצמו, המחייך
        בחן וברוך נעורים, משקיף על מי מרום השקטים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האויר קל, נעים ומשיב נפש. ביחוד, משתוממת אני, בת יהודה – החרבה, לשפעת
        המים פה. כמנגינה נעימה מצלצלת באזניך יומם ולילה המית המים מתוך הצנורות
        הרבים. מכל קיר, מכל חור, מתפרצים המים החוצה ונוזלים לצדי הרחובות לתוך
        החצרות והגנים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כל עץ שותה פה לרויה, כל צמח טובל במים! איזה עושר ואיזה אושר בשביל
        מושבה. עצי-פרי, ירקות, פרחים, אוזים ובני-אוזים; ומשך כל הקיץ...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הננו נכנסים לבית-הספר שבראש-פנה, זה המהולל שבבתי-הספר במושבות, ואחד
        המורים היותר מצוינים בין מורי ארץ-ישראל עומד בראשו. כמאתים ילד לומדים
        בבית-הספר הזה, כמעט כלם בריאים, יפים ונחמדים, ביחוד פעלה עלי המחלקה
        העליונה בידיעות תלמידיה ובנקיונם. ברוכה לנו ראש-פנה שמגדלת שתילים
        כאלה!...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*   *   *</span></p>    
    <p class="c0" dir="rtl">
      <span class="c1">הננו עולים לצפת...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יפה צפת מרחוק, יפים ההרים שסביב לה. הנך שואף רוח צח מלא רוך ועדנה, רוח
        ההרים. הרי צפת מצד שמאל עוד מכוסים טל הבקר. מימין הנך רואה את הג&#39;רמק
        בכל גבהו. ולמטה, הצדה, מירון, המקום המקודש על יד קברו של ר&#39; שמעון בר
        יוחאי. מצד זה במרחק – מי מרום <span class="italic">[החולה]</span>, ובדרום, מזרחה – כל ים
        הכנרת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנני בצפת. רחובות היהודים צרים ומלאי חלאה ובוץ שמפיצים ריח נורא... רפש
        וזוהמה שאין לראות בשום מקום אחר בעולם. זוהי עיר הקודש צפת!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בהתהלכך בים של רפש זה הנך שואל את עצמך: איך חיים פה אנשים, איך לא
        מתפשטות מחלות מתדבקות? ובזכרך את הסביבה הנחמדה אומר הנך לנפשך: בודאי
        רוח-ההרים עוצר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גדולה השפעת הצרפתית בצפת, וגדולה ממנה השפעת המשלחת <span class="italic">[המסיון]</span>. רוב
        המשפחות, ביחוד הספרדיות אינן שולחות את ילדיהן לגן הילדים העברי, ומבכרות
        הן עליו את בית-הספר של המשלחת, הנותן להן גם בגד לילדיהן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כמאתים ילד מתחנכים בבתי-הספר של המשלחת, מלבד הצעירים המבקרים את שעורי
        הערב שלהם בחשאי. אלה המה מחנכי הדור החדש שלנו בעיר בקדושה, צפת! ועוסקת
        המשלחת ברפואת הנפש והגוף, כי רופא יהודי אין בצפת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">וגם בית-ספר עברי אין בצפת. אין, אין...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלב מדוכא עזבתי את צפת ואשוב דרך ראש-פנה ללכת למשמר-הירדן
        וליסוד-המעלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בדרך מראש-פנה פגשתי בחורבה חדשה, קבר על שאיפות, תכניות והצעות שלא
        נתגשמו, הלא היא המושבה מחנים; שנעזבה כלה בידי הערביים, היושבים שם עם
        נשיהם ובניהם, כבעלים גמורים. הבנינים שלא התישבו בהם הערבים נהרסו עד
        היסוד. כל מה שאפשר היה לשאת משם, גנבו המתישבים החדשים: את הדלתות,
        המשקופים, החלונות, קרשי הגג והרעפים, את הכל גנבו ונשאו מפה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפני שנים אחדות, כמה רעשו אז ה"חובבים" <span class="italic">[חובבי ציון]</span> בגליציה וכמה
        התריעו: ישוב חדש... גליציה מתעוררת... החסידים מתישבים... הרבי
        מצ&#39;רטקוב סמך את ידו על "מחנים"...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועתה – את הכל נשא הרוח... התעשרנו בחורבה חדשה! אמנם, מורגלים אנחנו
        לראות חורבות בארץ-ישראל, אבל החורבות ההן, יקרות לנו; מזכירות הן לנו ימים
        גדולים, ימי אורה ואושר, והחורבה החדשה הזה מכריזה על פזיזותנו, אי
        יכולתנו, שפלותנו, אי-כחנו ואי-רצוננו לבנות בארץ בנינים תדיריים,
        בניני-עד, ולהקריב קרבנות – מְחיים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רושם מעציב ממין אחר עשתה עלי המושבה הקטנה, משמר-הירדן. כשנים-עשר אכרים
        נמצאים שם. הם עובדים, חורשים, זורעים וקוצרים בלי שום מרץ, הכל נעשה על פי
        כח-העצלות. אין שמחה, אין תקוה ואין חיים בחייהם, כי אינם מאמינים ביום
        המחרת; חרב מלאך המות, בצורת הקדחת הירוקה, רוחף תמיד לפני עיניהם. בחורף
        העבר מתו בקדחת הירוקה אכר ואכרה, ונער בן אחת-עשרה שנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבית-הספר של מושבה זו נמצאים עתה אחד-עשר תלמיד. בשנה העברה למדו בו
        עשרים. המספר נפחת מפני שמשפחות רבות ברחו מהמושבה מפחד הקדחת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כמאה צעדים מהמושבה נמצא הגשר "בנות-יעקב", שעל פני הירדן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פה שוכחים את החרבנות ואת הצרות ואת כל העולם כלו. מי הירדן זורמים לפנינו
        וממלאים את האויר באלפי קולות. מה עז קצף המים בעברם תחת הגשר הזה. ואת טעם
        הקצף, מי ידענו? - - -
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מ"משמר" הלכנו ליסוד המעלה. המראה החצוני של מושבה זו נחמד. באים דרך שדרה
        יפה מאקליפתים גבוהים, ועבים, ובקצה הרחוב מלחכים גלי מי-מרום <span class="italic">[אגם החולה]</span>
        את עפר המושבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אדמת "יסוד" טובה ופוריה, ונחלקת בין 32 אכרים. מצב המושבה היה טוב, לולא
        הקדחת ששולטת גם שם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבית-הספר מתלמדים כשלשים וחמשה ילד. פניהם חורים ורעים. גם בית-הספר חולה
        במחלת הקדחת...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בקרנו את אחד ממיסדי המושבה, אכר זקן המלא כח-עלומים. בשפה חיה ומהודרה
        סיפר לנו הזקן הזה על אודות העבר שלו.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבקר עם צאת השמש עזבנו את יסוד-המעלה, ופנינו מועדות למתולה. עברנו את
        שדות הכָּר של "יסוד", עברנו את האקליפתים שעל גבול המושבה מצד החולה. על
        הרי הבשן מימין פרוש עין-עופרת אפור, הדומה לגוש כהה, המכסה את כל המזרח...
        לאט לאט מתחילים השמים להטהר, ובהגיענו עד המלחה, נהר זך כבדולח היוצא מהרי
        נפתלי, שנמשכים לשמאלנו; ובעברנו את התחנה אשר פה על הנהר, התעטפו השמים
        בצבע התכול שלהם, ואז בצד צפוני מזרחי התחיל מתבלט החרמון. הכרתיו מיד על
        פי הכפה הלבנה שלו, וקוי השמש העולה נדמה לטוית זהב. ויפה הוא החרמון
        בפינתו זאת! קדושה נעלה חופפת עליו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ופה למטה, לימיננו ולפנינו, משתרעת ארץ החולה עם בצותיה העושות שמות בשבטי
        הבדוים הרבים, יושבי החבל הזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפנינו, רחוק-רחוק על ההרים מתחילים להכיר את מתולה. אך רחוקה הנה עוד
        כארבע שעות מאתנו, ועלינו עוד לעבור בצות רבות. ראשך כבר הולך סובב מהריח
        הרע של הבצות; הנך עצוב ומורגז שעוד רבה הדרך, ומכה בשוטך בכעס על גב הסוס.
        אבל בהרימך את עיניך, והחרמון המופז מצַחק לך בתמימות קדושה, הנך שוכח ברגע
        את עצמך ואת רגזך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בקצה החולה נפגשים בפינה שֶכלה ירוקה, נחמדה. שם גנים ויערים עבותים. "מה
        זה?" שאלתי את הערבי המלווני, ויענני ששם נמצאות אחוזות של ראש שבט בדווי
        אחד, שהיה לפנים השודד היותר מפוסם בחבל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >סמוך לכפר חלסה פגשנו בבדווית צעירה ויפה התולשת פולים. היא שרה בקול עצוב
        שירה חדשה, המקללת את ארץ הזהב, היא אמריקה, שגזלה ממנה את אהובה, את שלותה
        ואת מנוחת לבה וגרמה לה געגועים וענויים עזים כמות. השיר הזה מעיד על
        ההגירה הגדולה מקומות האלה לאמריקה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפני התלחה <span class="italic">[לימים תל חי]</span>, שהיא נחלת ששה אכרים ממתולה, מתחילות הגבעות
        להתרומם. מפה רואים את העמק לצד דרום, את כל ארץ החולה עם אהלי הבדווים
        הנטוים שם לאלפים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בקצה העמק מדרום רואים את מי מרום עם יער אקליפתים. זאת היא יסוד- המעלה.
        כשש שעות רחוקים אנחנו ממנה, והיא כל כך קרובה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הדרך למתולה על ההרים טובה הנה. מתחילים לפגוש במעינות שוטפים. גם העצים
        מרובים פה. ביחוד רבו עצי התות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה מי רווחינה השוטפים בהמולה נעימה למורד ההר. ורואה הנך את עיבל מצד זה
        ואת מתולה מצד השני על ההר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנך שוכח את כל עמל הדרך הרחוקה וממהר הנך אל המושבה, שאתה כה משתוקק
        לראותה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתולה! כמה זכרונות כורך השם הזה אתו. בזכרך את השם הזה תזכור את כל
        הטענות והמענות: למה יסדו את המושבה במקום רחוק כזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל צריך לחיות ימים אחדים במתולה בשביל להבין, שכדאי היה לסבול את כל מה
        שסבלו מיסדי מתולה. כשרואה הנך את פני האכרים הבריאים, פני הילדים האדומים
        עם עיניהם היפות, הבריאות שלהם; כששומע הנך את הצעירים מספרים לך את
        תקוותיהם המרובות לעתיד במקום הזה, במקום המצוין במימיו ובאוירו, שיהיה
        בקרב הימים מרכז עברי, - מוחל הנך לאכרים את עוונותיהם וחטאיהם ושמח יחד
        אתם. באויר שכזה, אפשר לגדל דור בריא וחזק ומלא רעננות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מצוינה מתולה וסביבותיה. ממערב המושבה – הרים פראים, מלאי יופי, מנגד
        משתרעים שדות זרועים חטה המתנועעת בגליה; ושוטפים להם גלי הנחל ומתפוצצים
        לזֹהר קרני השמש המשתברים בהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומצפון – החרמון בשני ראשי השלג שלו. והלאה נראה הלבנון כננס לפני הענק.
        ומקביל לרחוב המושבה משתרע מרג&#39; עיון עם שדות החמד שלו, ומאחרי העמק
        הנפלא הזה, ערים וכפרים אין מספר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קרוב למתולה אפשר לראות את אשד המים "תנור", כחצי שעה בערך הלכנו מהמושבה
        דרך שדות חטה, שעורה ועדשים עד בואנו קרוב לבית-הקברות. משם יורדים אל בקעה
        צרה שבפנתה נמצא התנור. עלמה צעירה שהלכה עמדי עמדה רגע ותסתכל במרחק,
        במקום שעמדו אנשים אחדים כפופים על עבודתם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כמדומה לי, פלשתים" (כך קוראים המתולים לדרוזים), אמרה הנערה בקול מלא
        רז...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באנו למטה עד הנחל, ומפינה נסתרה נשמע רעש של אשד-מים נופל בחזקה. התקרבנו
        אליו והחלנו לטפס על הסלעים החדים, וחלקלקים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנהו – אשד-המים! מגובה של ששים מתר, נופלים מים מן ההר הרחב ומשתפכים
        ברעש ובהמולה רבה לתוך ברכה צנועה, המתחבאת בפינה צרה ומשם משתפכים המים
        בקצף והולכים ובוקעים להם דרך בין ההרים אל הנחל...</span
      >
    </p>
    <p class="c0 c3" dir="rtl"><span class="c1"></span></p>
    <h2 class="c4 text-center" dir="rtl"><span class="c1">ב</span></h2>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היה בקר נפלא כשעזבתי את מתולה רוכבת על פרדה. בקר בהיר וקר כמו ביהודה
        בימי החרף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ירדנו מהמושבה אל המרג&#39; הירוק כלו, כשהשמש עלתה על הרי הגולן, וקרניה
        הראשונות ורוח קל חרישי לטפו את פנינו בנעימות עדינה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פרדתי צועדת בזהירות ומטפסת על ההרים, את המרג&#39; עזבנו מאחרינו. המושבה
        מתחילה להסתר לאט לאט, ומתחילות מתגלות לפנינו מחזות חדשים ושונים זה
        מזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה הכפר דר מומס עם גניו הפוריים על גבי הטרשים <span class="italic">[טרסות]</span>, יערי זיתים ותות
        נמשכים מרגל ההר עד ראשו; ושם למעלה בתי תפארה הטובעים בתוך ים הירק... הנה
        פוגשים אנו במעין רועש בהמולה רבה, ופורץ המעין בין שני הרים גבוהים,
        גבוהים מאד. על אחד ההרים מעל לסלע ישר וגבוה מאד, מתרומם המבצר העתיק
        גלה-תשקיף; אומרים שזה שריד מבצרו של הורדוס; שרידי החרבות האלה רואים אחרי
        כן מרחק איזה שעות, כמעט עד בואך לנבטיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נוסעים אנו על הכביש החדש, אשר בונים משדרה לנבטיה, ומשם ילך לצידון. הוא
        עובר פה בין הרים ובקעות. הנה מתחת להר מגיעה לאזניך המית מים נעימה, שם
        נוזל הליטני ומכה ברעש על פני אבני הנגף שעל דרכו. שוטפים המים  הזכים
        ויורדים אל תוך העמק, ומשקים את הסביבה הנהדרה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עומד אתה על הכביש הלבן ומתענג על מראה הככר הירוק המושקה כלו. חושב אתה
        שתלך הלאה בדרך היפה הזאת כי עוד רחוק לפניך מתנוצצות אבני הכביש הלבנות,
        והנה פתאום סר החמָר שלנו, המתואָלי, מדרך-המלך ומעלה אותך על הרים וסלעים
        מלאי חתחתים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"למה סרת מדרך טובה והבאתנו בין סלעי שדים?" פונים אליו הנוסעים בכעס
        ובתלונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והוא עונה בקרירות: "מנהון אקרב!" ומצחק בלבו עליך, ועל כעסך ועל
        תלונתך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומסוכנת הדרך מאד; והנך משתומם איך אפשר לעבור פה, מבלי שבור יד או רגל.
        הנך מטפס על סלע גבוה; חלק הוא כזכוכית מלוטשת. רוחב המשעול לא יותר מחצי
        אמה, ולימין השביל הצר הזה רובצת תהום עמוקה ונוראה. ומעמידה הפרדה בזהירות
        רבה את רגלה על הסלע החלק, ודורכת לאט לאט ופתאום היא נכשלת ברגלה וכורעת
        ברך...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הקיץ הקץ! עוד רגע ותהי מונח עם פרדתך רסוק אברים... אבל הפרדה קמה,
        מיישרת את גבה ומובילה אותך בזהירות הלאה, עד אשר הוציאה אותך
        למישור.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ונרכב בבטחה הלאה עד בואנו לנבטיה. עירה זו שוכבת בקצה העמק, ואחריה
        נכנסים בין רכסי ההרים. ההרים פה הם מאותו הטפוס של הרי יהודה. הם לא
        גבוהים, רואים עליהם טרשים, וגדרי הטרשים, ממש כבהרי יהודה. כפרים מעטים
        בין ההרים, ובכל זאת רוב הטרשים חרושים, זרועים ועבודים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על הרמה שעלינו אחרי צאתנו מההרים האלה מתחילים לראות מרחוק המון כפרים,
        וכל מה שמתקרבים יותר אל הים נהיה הישוב יותר ויותר תקוף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה הגענו  עד הכפר זיפתא, המונח לשמאל הדרך, ולימין רואים כפרים עד אין
        מספר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כעשרה דק מזיפתא עלינו על כביש חדש ולבן העובר בצד ההר. פה ראיתי פתאום
        לנגדי מרחוק את השטח הכחול הבהיר של הים. כמה התגעגעתי עליו במשך ימי
        נסיעתי, וכמה רגשות נעימים העיר בי פתאום! אבל בירדנו מן ההר נאבד הים מנגד
        עיני...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >צבע האדמה במקום הזה הוא לבן כלו. הדרך, ההר, הבקעה, הגבעות והכל לבן; רק
        נקודות וקוים ושטחים ירוקים נראים באמצע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה איזה הר פלאי לבן כקרטון לימיננו! בלובנו מצטירות הנטיעות בצבעים
        מקסימים. גנים, פרדסים, רמונים, תמרים והכל אוחד ומתלכד זה בזה בין קוי
        הזהר של הקרקע הלבנה, ובורא אחוד, הרמוניה, של צבעים נפלאים, המלבבים
        ומקסימים את עיניך ולבך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בגעגועים חזקים הנך מתבונן בתמונה ומקשיב לקול המית הנחל, המחבק והמנשק שם
        בגיא את כל המקום המקסם... רוכב הנך הלאה והנה במערב נראה שנית הים בכל
        הדרו. בהיר הוא, שקט, נחמד, מוצק ומלא יופי. כנשרים קלים, עפות עליו הסירות
        עם כנפיהן הלבנות, וחוצות את גלי התכלת ואניות-הקיטור נראות לך כברבורים
        שחורים, השטים באמון ובבטחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד מעט והננו עוזבים את ההרים ויוצאים אל המישור שעל שפת הים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משמאל, לדרום מונחת צור על גבעתה ורוחצת בגלי הים; לימין צדון, הטובעת
        בפרדסיה. עושר של נטיעות וזריעות לפניך. הכביש עובר בין שדות-בר נפלאים.
        הים רוחץ משמאל את קמת השדה, הגדולה היפה, המבריקה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">הנה עדרי צאן עולות מן הרחצה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פוגשים אנו במרכבה נהדרת, משפחה עשירה, שכל בניה שמחים ובריאים מטילת בה,
        וקול צחקם העליז נשמע מרחוק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכל – עושר וחיים! וקנאה גדולה אכלתני במקום הזה: "להם החיים, ההוה,
        לפניהם כל הארץ, ולנו העבר שחלף והשאיר שרידי זכרונות וחרבנות. ומה
        בעתיד?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">עם רעיונות עזב כאלה נכנסתי לצדון.</span>
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*   *   *</span></p>    
    <p class="c0" dir="rtl">
      <span class="c1"
        >...העיר נקיה והומה מאדם. רוב ימיהם חיים הצדונים ברחובות העיר: פה שותים
        קהוה, שוחקים בדומינו, "עצמות", פה המה עוסקים גם במסחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומרגישים בצדון כי במאה העשרים אנו חיים: על מעקה בית-הקהוה מנגן
        הגרמופון, והמון רב שומע מקשיב ומביע את התפעלותו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מפליא אותך שברחובות האלה אינך פוגש כמעט בנשים. הגברים רק הם נהנים מן
        החיים, רק להם ניתנו התענוגים, והאשה – כל כבודה בבית פנימה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלילה הרגשתי את עצמי בצדון כמו משלכה לאיזה אי רחוק – רחוק וזר לי, רק
        הטבע היפה הקריב אותי מעט אליה. עד שעה מאוחרת בלילה לא יכלתי להפרד ממעקת
        מלוני, שממנו יכלתי להתענג על מראה הים. והירח הבהיר שפך בלילה ההוא את חנו
        המזרחי, הארץ-ישראלי על פני העיר, שהיתה עטופה רזי ליל, וסודות-תאוה... רוח
        צח, חרישי נשב מכל אפסים, שקט ושלוה סביב, רק שירת אהבה של משוררת עגבנית
        בבית-הקהוה הקרוב, הפריעה את הדממה וצרדה את האוזן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבקר השכם עזבתי עם חברתי, אכרה ממתולה, את צדון היפה ללכת לבירות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הושיבו אותנו עם טורקיה צעירה כבת שש-עשרה (והיא נשואה זה שלש שנים!) על
        הספסל האחרון של הדילז&#39;נס, במקום שמורגשות ביחוד דפיקות האופנים; וכבדה
        הנסיעה על ידי זה לאשה חולה כמו חברתי, שנסעה לבירות להתרפאות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על הספסלים הקודמים ישבו הגברים, אלה עמודי העולם; לפנינו – שני נערים
        צעירים. וכמה שבקשנו להחליף אתנו את המקומות – לא הסכימו, אפילו ה"בקשיש"
        שהבטחנו לא הועיל. "אסור הדבר" לפי מנהגי המקום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">מנהגים יפים!...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשיצאנו את העיר ראינו משמאל את מצודת צדון העתיקה, והבנויה בתוך המים.
        בעיר נדמה לך שהיא נמצאת במרכז, ופה אתה רואה שהיא בתוך המים, וקיר יוצא
        ממנה אל הים בחצי עגול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הכביש שאנחנו נוסעים עליו, עובר אל חוף הים, המים כה קרובים אליך, עד שהנך
        רואה איך מכים גליהם את הכפים, ואיך מפזזים הגלים על פני הקרקע
        הירוק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה עוברים הננו את דמור, עיר של חרושת-המשי, עם יערי תות והמון
        בתי-חרושת. דמור בנויה על ההר. מתרוממת על פני פרדסיה היפים, ומביטה בחן אל
        הים, המשתרע לרגלה. והים כה שקט במקום הזה! ראי כחול מוצק לפניך. אינך רואה
        אף גל אחד מתנועע על פני כל השטח שבו משתקפים העננים הקלילים בכל זהרם
        הנחמד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה באנו לשויפן, עיר בנויה על מול-הלבנון, עם יערי זיתים עבותים אשר לא
        ראיתי כמוהם ליופי. העיר בנויה על הר גבוה ושלג הלבנון סוכך עליה למעלה.
        ומהעיר למטה בנוים טרשים על רגל ההר, והטרשים מכוסים ביערים וגנים
        נחמדים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ונמשכות הנטיעות עד תוך העמק המשתרע על חוף הים ובעמק, בין עצי יערי
        הזיתים, נפוצים בתים נהדרים בחצי עגול יפה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובמקום הזה מתעוררת המחשבה על דבר ערך הזית, ועתידו הגדול בארץ; מכיר אתה
        בכחם של הזרים, והקנאה מתערבת בהכבוד, הראוי להם בעד כשרונם להשתמש בכל
        סגולות ארצנו העשירה. זה יותר מחצי יובל שהננו מתעסקים פה בנטיעות ועוד טרם
        אחזנו בענף הבריא הזה! נוטעים אנחנו ועוקרים, עוקרים ונוטעים. לעצי תות אין
        כבר זכר אצלנו, עתידות הגפן עוד מכוסים ערפל, והזית חי רק במחשבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >למעננו "אין חשבון", והם – מצליחים בכל, גם הזית גם התות עולה ופורח,
        ומעשיר אותם...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי יערי שויפן מתחילים להכיר מרחוק את בירות. הנה עוברים אנו את הרי
        מסילת-הברזל. הקטר צופר מרחוק, עוד מעט והנה רכבת-משא אצל עגלתנו. ועוד
        רגעים אחדים וגם אותומוביל עובר על פנינו. בכל עבר – חיים תנועה התפתחות,
        תרבות...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והנה עברנו גם את שדרת העצים הארוכה הנמשכת כשעה תמימה לפני בירות, ונבא
        עד יער הבטנים <span class="italic">[יתכן שהכוונה לאלת הבוטנה - פיסטוק]</span> שבראש בירות...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    
    <p class="c0" dir="rtl">
      <span class="c1"
        >בערב שבת עם חשכה באתי מצדון לבירות. יפה היא בירת סוריה, הומה ורועשת.
        אבל הכל זר לי סביב, הנני מרגישה ריקנות נוראה בלב, אותה הריקנות, שהרגשתי
        בערי חוץ לארץ, כשבאתי שמה מארץ-ישראל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואתפלא על רגשותי אלה: בירות שכה קרובה לארצנו, שכה קרובה ללבנו, בירות
        היושבת לרגלי הלבנון זרה לרוחי לגמרי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כי לא לנו הנה בירות, ולא תהי לנו לעולמים. הרגש הזה התחזק בקרבי ביחוד
        כשטילתי בסמטאות היהודים. נפגשתי שם עם צעירים וצעירות, בני אבות יהודים
        פשוטים, שלא ידעו לענות לי על שאלותי בעברית; צרפתית ואך צרפתית
        ידעו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם הילדים, שנתחנכו במושבותינו, שהשפה העברית היתה שגורה בפיהם, מתחילים
        לשכוח את השפה. וכאשר הנך שואל תלמידת בית-הספר של "אליאנס", מה היא לומדת?
        היא עונה בגאוה: "צרפתית" – "ועברית?" – שואלת אנכי. "כן, המורה הספרדי
        למדני כתב רש"י, אבל מצאתי שגיאה בכתיבתו על הלוח והוא גרשני ממחלקתו,
        ויותר אינני לומדת עברית!" עונה לי הנערה, שבלמדה בבית-הספר שבמושבות
        ביהודה, הצטינה בידיעותיה בעברית... שואל אתה ילדים אחרים מה הם לומדים
        בבתי-הספר שלהם, והם עונים: גרמנית, צרפתית, אנגלית, איטלקית. מדעים אינם
        מזכירים לך, ועברית... עברית – אינם מלמדים אותם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רבות הן עתה משפחות משכילות עבריות בבירות. והנך מתפלא שהן מסתפקות
        ב"גן-ילדים" עברי לבד, ואינם משתדלים ביסוד בית-ספר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וזה לא קשה ביותר – לו רק חפצו. הלא יכלו, למשל, בחיפה, ארבע המשפחות לקחת
        מורה עברי טוב וליסד בית-ספר בזעיר אנפין, ומדוע אינם יכולים בבירות לדאוג
        שבנינו ובנותינו לא ילכו מאתנו?
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל גם פה מצאתי בתי-מכרים, שהשפה העברית שולטת בבתיהם ומתענינים בכל
        הנעשה אצלנו. ביניהם הרגשתי שאני נמצאת בין אנשים אחים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במוצאי-שבת היה נשף בבית-העם – נשף לאומי ציוני. שני צעירים, סטודנטים,
        מיהודי פרס, נאמו ברוח עברי לאומי בשפה הצרפתית (עברית לא הספיקו עוד
        ללמוד), אבל הרוח העברי בצבץ בכל דבריהם, המלאים שאיפת התחיה. אחד מחברי
        "הפועל הצעיר" שבבירות, העתיק <span class="italic">[תרגם]</span> את הנאומים לעברית, והקהל מחא
        כף...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ביום הראשון בבקר השכם עזבתי את בירות ללכת במסילת-הברזל לזחלה, אשר על
        הלבנון.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שלש מנהרות, נחצבות בהר עברנו, עד בואנו לתחנה הראשונה חדית. תחנה זו
        שטובעת כֻּלה בירק רך וריח תמרים, מזכיר לך שהנך בלבנון.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושואן הקטר, נושם בכבדות, מטפס ועולה. הננו עוברים את התחנה בעבדה. הכפר
        בנוי על הרים גבוהים ואל כל פינה שתשקיף – גנים. מורד ההר בנוי טרשים יפים
        עם יערי זיתים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ברגל ההר רועים עדרי צאן לרב, וממלאים הם את כל החורשות וכל הסביבה המלאה
        קסם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בהתחנה ארעא נוספו הרבה נוסעים מיושבי המקום, שדברו במבטא שהיה חדש לי
        לגמרי, זאת ההברה ההררית, הג&#39;בלית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מארעא רואים היטב את הים המשתרע לרגלי ההר. מימין עוברים יערי בטנים. עודך
        עומד אל החלון ומתענג אתה על יופי הים, ופתאום חושך: נכנסת אל תוך מנהרה,
        וכשיצאת משם, והנה אלע היפה נגדך, אלע עם בניניה הנהדרים וצביונה
        המיוחד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באלע נכנסת לתוך עגלתנו צעירה מגושמת, עם פנים לבנים מכוסים אבקה ורדית
        וערבי מגוהץ מלווה אותה. אוושת שמלת המשי שלה וריח של שמנים חריפים הפנו
        עליה את מבטיהם הצוחקים של כל היושבים במרכבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התבוננתי גם אני בצעירה האופיית הזאת. אמנם, פניה לא מכוערים ובריאים,
        כתפיה רחבות, גבוהת הקומה וגבוהת החזה היתה, אבל כמה מגושמת אשה זו, ויחוסה
        להערבי, הנדחק אליה והאוכל אותה בעיניו השחורות, הלוהטות. והיא מתגנדרת
        ומעוררת תאותו. מעורר גועל-נפש. "יהודיה", לוחשת באזני עלמה בירותית,
        שנוסעת לדמשק, ללכת משם לצפת ומירון לחגיגת רבי שמעון בל"ג בעומר, "יהודיה
        מבירות, בת ה"שמש" הספרדי משה".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משוררת היא בבתי-הקהוה. מבירות עד דמשק יודעים אותה. היא סוחרת בקולה
        ובגופה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אלי! בכל מקום אתה פוגש אותן "היהודיות", ובכל פעם פגישה זו מעוררת בקרבך
        שאלות וספקות ורגשות מרים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בסאדנל, התחנה שלפני זחלה, מתחילים להכיר שנית בעקבות תרבות עשירה. פה אתה
        פוגש במטעים ממינים שונים" גפן, תות, תפוח, אפרסק, וביחוד עצי אורן, שבו
        משתמשים בלבנון לרב חלקי הבנין.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עמק מול הלבנון, שהננו עוברים בו עתה, מלא יופי שמימי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יפה המקום הזה, יפה הלבנון כולו, אך לבי העברי אינו הולך שבי אחריו. אמנם,
        מתענגת אני על היופי הזה, כמו שהייתי מתענגת על כל תמונה יפה ומחזה נהדר,
        אבל לבי נשאר שקט. איננו הולם כמו בגליל: כל אלה המקומות הנחמדים לא לנו הם
        ולא יהיו עוד לנו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הננו בעיר-הפלאות זחלה; היא בנויה כלה על המים, משלשת עבריה מקיפים אותה
        הרי הלבנון, עם מעינות נפלאים ומפלי-מים מקסימים. באמצע העיר עובר אברדני
        השוטף עם יערי אורן על שפתו. מאברדני מסתעף הסֶקֶר לאלפי נחלים השוטפים צדי
        הרחובות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכל סמטא, בכל פנה, שוטפים המים ורועשים. אין בית, אין אכסדרה, אין מדרגה
        שאין המים סביבם. על כל פסיעה אתה רואה גשר, על כל שעל תוכל לשאוב מים
        כחפצך. הלבנון סוכך על פני העיר, ובכל מקום הנך פוגש או במעין או במפל של
        מים. עובר אתה ברחוב, והמית המים מחרישה את אזניך. חפץ אתה להתחבא ממנה ורץ
        אל החצר, אשר שם מעונך, אבל גם שם היא מוצאת אותך; גם לשם יגיע רעש הנחל
        והמזרקות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנך נכנס לחדרך וסוגר את הדלת אחריך, ורעש גלי הנחל חודר בעד הקירות, ויש
        שנדמה לך, שאתה יושב באניה בלב ים, והגלים דופקים בכתליה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והמחזה הנהדר הזה מרגיז אותך. נזכר הנך בחוסר המים שיש ביהודה שלך, שכל
        טפה עולה שמה בעמל ובכסף...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נרגז הנך כשעובר אתה ברחוב ופוגש בנשים יפות ובריאות. רואה ___ את הגברים
        החזקים עם עיניהם הלוהטות, ששואלות בתמהון: מה ליהודיה הזאת פה? או כששומע
        אתה את קול הילדים הקורא אחריך: "יודיקה", אז הנך מתמלא חמה ושואל: מה אני
        פה, ומי לי פה בעיר, המלאה בתי-תפלה, מנזרים וישועים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כמה אני זרה באותו הלבנון, שכל כך הרבה הושר לו בספרותנו העתיקה, שתופס
        מקום כה גדול בזכרונות קדומים שלנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מזחלה נסענו כחצי שעה להכפר קרק, ששם ראיתי קבר עתיק מצוין במינו, כשלושים
        מתר אורך הקבר, והוא סגור ומסוגר בבנין מיוחד, קדוש לטורקים, הבאים להתפלל
        על הקבר ומביאים אליו את תרומותיהם בכל חג, ובכל מקרה רע של מחלה ושל אסון.
        והקבר הזה נושא עליו שם: "קבר נח!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ועשירה העיר זחלה, או יותר נכון "מלקה-זחלה", כי שתי העירות
        מחוברות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מלקה הנהו כפר על-יד עמק מול הלבנון, וזחלה הנה האירופית משתי
        האחיות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומורגשה שניות משונה בעיר זו. כפולה גם הרשות: במלקה הנה טורקית ובזחלה –
        צרפתית. גם שתי עיריות יש לה; ושונים השאיפות, הדעות והמנהגים של שתי
        העיריות-האחיות האלו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זחלה, כמו כל ערי הלבנון התעשרה מההגירה האמריקנית. כל אשר כח במתניו הולך
        לאמריקה. ומי שרק אסף הון שב הביתה ומעשיר בכספו את עירו ואת ארצו. והזחלים
        שבו ובנו היכלים נהדרים וכל תענוגי בני-אדם בראו להם והפכו את המקום
        לגן-עדן, אשר מושך עתה אליו בכל קיץ אלפי עשירים מכל המזרח, וממדינת מצרים.
        ומתעשרת העיר גם על-ידי זה יותר ויותר ואין גבול לעתידה הגדול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ויפה ביחוד הסביבה של העיר הזאת: בקעת-קסם, עם שטיח יערים רבים מלאי-סוד,
        שאלפי מעינות רועשים וסואנים יום יום. ועשיר המקום בצבעי הפרחים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל על כל צעד ושעל מרגיש אנך דקירה עמוקה: לא לנו גן-עדן זה!...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*   *   *</span></p>
    
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשירדתי מהלבנון לבירות, תקפוני געגועים למושבתי היפה ביהודה, געגועים אל
        ביתי, אל גינתי, אל אפרוחי, יונותי, ואשב אל האניה לשוב לחיפה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפני מתנוצצת העיר ברבבות הנרות שלה, ובקצה האופק האפל – גוש שחור מפיל
        איפה, הוא הלבנון עטוף אפלת הליל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל הנה שט לו הירח בזהירות ומושך את עיניך מהלבנון ומהעיר. ואור הירח כל
        כך בהיר, עד שלא נכר אף כוכב אחד על פני הרקיע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומאיר הירח הכספי את הים האפל ורצועה בהירה נמשכת מהחוף – עד האניה,
        ומזהיר האור שבתוך הרצועה, ומתפוצץ האור לאלפי ניצוצים מפזזים ומכרכרים
        בשובבות פראית על פני המים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">רוח צח ונעים מלטף את פניך, וריח הים מיַשן אותך...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >...רק האיר הבקר, והאניה השליכה כבר את עגנה אצל חיפה בלשון הים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפנינו הרי הכרמל, מסובבים את העיר הקטנה השוכבת לאורך החוף. מצד ימין של
        העיר – המושבה הגרמנית, הטובעת בירק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ספינתנו, בלכתה מבירות, עמדה בחיפה יום תמים. במחשבתי היתה תחילה לרדת
        העירה, להתראות שנית עם מכירי הטובים שם. אבל מראה הים הנפלא, תמונת העיר
        נגדנו, הכרמל המחבק אותה, וכל הסביבה הנהדרה – כה נעמה לי, עד שצר היה לי
        לעזב את האניה, והחלטתי אחרי ישוב דעת ארוכה להשאר כל היום על הספינה.
        קויתי להתענג הרבה על אוירו הקרירי של הים ועל זיוו המלבב.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל תקוותי רמוני. בשעה עשר בערך בא פתאום רוח חם ממזרח וזרק רשפי אש על
        פני.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"בא חמסין, ז&#39;יל! המרגיש אתה?" אמרה אשה צעירה, יפה, שהשתטחה בכל גופה
        החסון על שתי נדנדות והתרפקה על דודה, העומד למראשותיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ז&#39;יל צרפתי מגוהץ, חסול כאלון, איננו חרד להביט ישר אל תוך עיניה
        הגדולות והחמות הפתוחות לרוחה ועונה לה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כן, יקירתי, כנראה מוכן לנו גיהנום היום ולא אדע איפה לסדר עבורך מקום
        להסתיר מן החֹם".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנוסעים של מחלקה א&#39; וב&#39;, שנשארו על האניה ולא ירדו העירה, הלכו
        וסבבו את המכסה האניה לבקש להם מקום שלא ינשב שם הרוח החם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והחם הלך וגדל. וכשירדתי בצהרים אל מחלקתי השלישית נאנקו שם אנשים מחם
        ומחסרון אויר. הגברים פשטו את מעיליהם, הפשילו את שרווליהם, מחו בכל רגע
        זעה מן הפנים וצעקו:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חרם, חרם הוא להתנהג עם אנשים כעם כלבים! איזו אניה זאת? איזו חברה זו?!
        הלא לקחו כסף להוביל אותנו למטרתנו, ולא לענות אותנו יום שלם חנם פה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היה שיך אחד, שכעס יותר מכלם. עוד אתמול בערב חפץ לעלות למעלה לשאוף אויר
        קר, וגרשוהו משם, ועתה, בחם הזה בערה חמתו שבעתים, ויעשה תעמולה בין
        הנוסעים, שילכו לבקש דין וחשבון מאת הקברניט על מנהגי האניה הרעים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נאנקות הערביות והטורקיות הסרוחות על משכבותיהן. מפינה אחת קרובה למקום
        מושבי שמעתי אנחות תכופות. הפינה מכוסה כלה בסדינים לבנים התלוים על חבלים
        דקים, ונמשכתי אל הפנה הנסתרת לראות מה שם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"מי יושב פה?" שאלתי ערבי אחד שישב מעבר החצוני לסדינים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"טורקיות!" ענני, "אחת מהן חושבת למות".</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרימותי קצה הסדין מן הצד ונכנסתי. שתי טורקיות זקנות, חוורות ישבו אצל
        טורקיה צעירה בעלת עינים גדולות ושחורות ושתי מחלפות שער ארוכות, שחורות
        ועבות. אחת אחזה על חזה את ראש הצעירה, השניה שפשפה את ידיה;  התעלפה
        המסכנה בכל רגע, ובהתעוררה נאנקה בעינים סגורות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מהרתי לאחד משכני והבאתי מעט קוניק, נתתי לנער אחד את צלוחיתי שיביא מים
        קרים, שפשפתי היטב את ידיה בקוניק, זרקתי פניה במים, השקיתיה את המים
        הקרים, עד אשר התחילה לאט לאט לפתוח את עיניה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"מה לך?" שאלתיה בערבית.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא הניעה בידיה ובראשה לאות שאיננה מבינה אותי. פניתי לשתי הזקנות, אך גם
        הן אינן מבינות אותי. עמדתי עוד רגעים אחדים, וכשראיתי שהחולה חדלה מהאנק,
        שצבע פניה הוטב מעט וכי שוכבת הנה בעינים פתוחות, עזבתי אותן והלכתי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >חפצתי לעלות למעלה ועמדתי באמצע המדרגות, כי הנוסעים אתנו שבו להתפלל,
        ועיני נמשכו אליהם. הם התאספו קבוצות, קבוצות במקומות שונים, פרשו שטיחים
        תחת רגליהם, כורעים ברך, משתטחים מלא קומתם על הארץ. אחד קורא בקול, נוגע
        בקצה אצבעותיו בזקנו, בשפתיו, ואחרים מתפללים בלחש ומחקים את תנועות החזן.
        זו הפעם השלישית במשך הבקר, שהם מתפללים בכונה גדולה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כגמור התפילה הם מתחילים להתעסק בהכנת טה. שותים, אוכלים, נחים ומתפללים
        שוב. הם היחידים מכל הנוסעים שאינם גונחים ואינם מתאוננים על החם. הכל ניחא
        להם מפני שהם נוסעים ירושלימה, למקום מקדש עומר... ומשם עוד הלאה, למכה
        להשתטח על קבר נביאם... הם מאושרים, הם יגיעו לקדושה! ומאושרים כאלה אינם
        שמים לב להבלי העולם הזה!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">עליתי שנית למעלה. גם שם עוד בוער האויר מסביב.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הננו היום כמו תחת קו המשוה!" אמר אחד מפקידי האניה לחברו בגרמנית, "מה
        טוב אצלנו בצפון בין הקרפטים. שם קריר. שם עדן! ופה מול הכרמל, גיהנום
        ממש!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"ושם, חביבי, אהובתך שלך. שם העינים הכחולות, השערות הבהירות שלה!...
        ואני, חברי, מחבב רק את העינים השחורות כעורב, אשר לבנות המזרח! לי נעימה
        חמימותן, האש הבוערת בחובן..."</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עשיתי את עצמי כלא שומעת את שפתם והלכתי לצד השני, אולי אשאף שם מעט אויר.
        אבל גם שם אש נזרקה עלי ושרפה את לחיי השחורות והשזופות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומי הים למטה שקטים בתוך הגולף <span class="italic">[מפרץ]</span>, ורק כמאה צעדים ממולך מתנועע קו
        אחד בהיר ומבריק. האניה שעמדה מולנו עזבה את מקומה והפליגה דרומה ליפו,
        בהשאירה אחריה הקו המבהיר הזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ונשר עיף חוצה שם בכנפיו את האויר הכבד, הלוהט, וכמו אומר הוא ללוות את
        הספינה על דרכה...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשעה שלש בערך אחר-הצהרים התחיל החום לרדת וינועו פתאום גלי הים ומימיו
        נעשו עכורים. ובשעה החמישית התחילו הנוסעים מחיפה לעלות על האניה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה הצעיר הזכרוני, שנסעתי אתו באניה בלכתי גלילה. הוא שהתרגזתי על
        התנגדותו לעברית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הצעיר שב שנית למצרים והוא עתה היותר מאושר בגברים. הוא מוביל ארוסה יפה
        תחת זרועו ; ארוסה מלובשה מגבעת בעלת נוצות גדולות, חולצת שמלת משי יקרה,
        ומפטפטת צרפתית. רא צרפתית!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עם הזוג הזה עלו עוד שני יהודים. אחד כבן ארבעים וחמש, בריא, גבוה וכרסני,
        מלובש בגדי אירופה נקיים ופונה להמלחים בשפת אטלקית, ומה שדבר אתם לא
        הבנתי. השני יהודי זקן בעל חטוטרת וזקן ארוך. שניהם ירדו אל המחלקה
        השלישית; ובבואי לשם לפנות ערב לשתות טה מצאתי את ה"דז&#39;נטלמן" שלנו
        מתהלך יחד בתוך מחלקתנו, בלי מעיל וחזהו פתוח, הוא הכיר בי יהודיה, ויגש
        אלי תכף ויתחיל לספר לפני את כל גדלו ותפארתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"עסקנו בסמירנה הוא היותר גדול בכל עסקי הברזל באורינט. פעמיים בשנה אסובב
        את כל ערי המזרח, עושה שם עסקים גדולים וחי בהוטלים היותר מפארים".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"אדוני הוא קומי(סוכן)-נוסע?"</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"לא, חלילה! אני הדירקטור השני, אני מנהל את העסק שלנו. בכל אפסי ארץ
        מכירים אותי ויודעים את שמי הטוב".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הבנתי תכף שזה "אפריסט" <span class="italic">[נוכל]</span> ושקרן. צחקתי עליו בלבי ושאלתיו ברצינות:
        "אם אדוני מורגל בחיים שעירים, בהוטלים מפארים, איך יענה את נפשו פה במחלקה
        הזאת?"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >די היתה השאלה הקטנה הזאת שיתעוררו עצביו ויתחיל לדבר בטון גבוה ומרגז,
        ולהשבע שבועות אמונים שהוא איש את ואיננו משקר מעולם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"חי ילדתי התמימה, היא אשתי הצעירה והיפה, שתמיד, תמיד אני נוסע אתה
        במחלקה הראשונה; תמיד מתאכסן בהוטלים היותר גדולים. וכשאשוב אם ירצה השם
        מירושלים אסע בלי ספק במחלקה הראשונה!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"ומדוע לא עתה, אדוני?"</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"עתה? עתה צריך אני לקמץ כמה שאפשר לי, בשביל שאוכל לקנות מתנות יפות
        לילדתי – יפתי. הלא מלאכי הטוב הזה יושב בהיכלי תפארה. לה צריכות המתנות
        היותר יקרות! ולכן אני מחסר הפעם את נפשי מטוב בשבילה. זה רק
        בשבילה!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד הרבה הרבה דִבר האדם המשונה הזה באזני, אבל כבר היה לי לזרא, וכשהוא
        ראה את הגחוך על שפתי נעלב ואמר בקול של תלונה: "הגברת, כנראה, איננה
        מאמינה לדברי. הגברת איננה מהנשים הרוסיות המשכילות, שיודעות להוקיר ערך
        אדם, שאיננו נוסע דוקא כבורגני במחלקות הגבוהות. אני אדם הגון מאד, גברתי.
        אחד העקרים שבחיים אצלי הוא: &#39;ואהבת לרעך כמוך&#39;. לכל אדם הקורא
        לעזרה – אח אני. כל העיר סמירנה, כל ערי המזרח מכירים אותי ואת צדקותי
        הרבות... אבל מה זה?" קרא פתאום בכעס – "הגברת צוחקת באמת עלי?" ויפן וילך
        לו בזעף אף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והזקן בעל החטוטרת, שפשט גם הוא את מעילו ונשאר בארבע-כנפות ארוך וכפה
        שחורה על ראשו נגש אלי, ובקרצו את עיניו על ה"דז&#39;נטלמן" שלנו ספר לי
        בלחש, שעוד טרם שירדתי הנה פנה אליו הפטפטן הזה בשאלה אם הרב סלנט בירושלים
        יוכל לעזר לאדם כמהו, שירד מנכסיו, בתמיכת כסף הגונה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"וזקני הוא ירושלמי?" שאלתי את בעל החטוטרת. </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"לא!" ענני, "רק לפני שנה באתי לארץ-ישראל, כי יש לי קולוניה <span class="italic">[קרקע, נחלה]</span>
        בחדרה, והתישבתי בירושלים. הבאתי אתי נערה יתומה, בת אחי, השאתיה לאיש,
        ללמדן מופלג, ונשארתי בלי פרוטה. עתה אני מבקש עסקים. הייתי ביפו, בירות
        וחיפה. את השפה אינני יודע ובכל מקום מנצלים אותי ופושטים את עורי מעל
        בשרי. אינני יודע עד אן יובילו אותי עסקי אלה,ומה יהיה סופי!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא דיבר והערביים מתחילים להתאסף סביבו ולהתלחש: "יהודי, יהודי!" אנכי
        גערתי בהם ויתפזרו לכל רוח; והזקן שב למקומו, הוציא מילקוטו ספר תהלים
        ויתחיל לקרא בקול נוגה ועצוב, אשר העיר בי זכרונות רחוקים, נעימים, זכרונות
        ילדותי... נשמע לי הד קולם של אבי ושל זקני, כשהיו קוראים תהלים בנגון
        הזה...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הגיע הערב. שנית ראיתי מן האניה את הכדור הגדול, האדום העולה ממזרח. יום
        קודם בבירות ובערב הזה בחיפה. גאה עלה הירח מעל צלעי הכרמל האפלים, ויתרומם
        על פני הרקיע המבריק בכוכביו המתנוצצים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עומדת אני סמוכה אל סורג מכסת האניה <span class="italic">[מעקה הסיפון]</span> אצל המחלקה השניה. עלמה
        גרמנית, בעלת לחיים ורדות, עגולות ועינים אפורות עומדת על צדי, היא מספרת
        לי על דבר בית-הספר, שגמרה בירושלים, על דבר מושבתם שם, על אודות יסוד
        שעורי-ערב המענינים שלהם. היא התארחה אצל דודתה בחיפה ועכשיו שבה
        הביתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"והגברת מאין?" שואלת אותי העלמה הנחמדה,</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"אנכי מהמושבה ראשון-לציון".</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"זאת המושבה היהודית שאצל יפו? אם כן הגברת..."</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"כן, יהודיה אנכי!" עניתי לה בהרגישי, כי כבד לה להביע את המלה הנוראה
        הזאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >העלמה נבוכה מאד וכרגע נעלמה ממני... ובעלותה שוב אחרי איזה זמן על מכסה
        האניה, לא פנתה יותר אלי ולא הביטה על צדי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קבוצות וזוגות פטפטו הנוסעים. פתאום משכה את עיני כלנו תמונה מוזרה בקצה
        האניה: עלמה עטופה אבית-משי (מן בגד עליון להתעטף בו) לבנה טפסה על הסורג,
        אחזה בעמוד ותבט מבלי נוע אל צד החוף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נקרבתי מן הצד והשתדלתי לראות את פניה. תויו דקים, מחדדים והם חוורים
        כסיד. אנחנו רעדנו מפחד, פן נפול תפל הימה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">רופא אניה נגש אליה ויאמר בקול רך:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"גברתי! הלא גדולה הסכנה לעמוד פה! רדי, אנא, רדי!"</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא זרקה מבט מלא בוז ואמרה: "אין שום אדם רשאי לצוות עלי דבר מה!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ותוסף לעמד ולהביט בגוף מתוח מחוץ לסורג. ראשה נטוי אל פני הגלים הרחוקים
        ובברק עיניה הזה נדמה שהיא חפצה לבלע את המרחק המבדיל אותה עם החוף; משם
        הפליגה סירה וחוצה במהירות את הגלים. הסירה שטה והתקרבה אל אניתנו... העלמה
        הזדקפה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשמחה גלויה הביטה לתוך הסירה למטה, שם ישב צעיר לבוש לבנים, וראשו פנוי
        למעלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הזה אתה, אנטון?" צעקה העלמה צרפתית בקול רועד משמחה, ותפנה לתוך האניה
        ותקפץ מהר מעל הסורג, ותנס אל מדרגות האניה לחבק את אנטון בזרועותיה
        הפתוחות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל זה לא היה אנטון, הפנטסיה רמה אותה! ובברכים כושלות היא שבה אל פינתה,
        אבל אין לה כח לעלות עוד פעם על הסורג. היא עומדת בראש סמוך אל העמוד,
        ועיניה תועות שוב על פני הגלים המבריקים לאור הירח. במרחק שטות סירות
        ומעוררות בה תקוה אולי יבוא אהובה אל האניה ויקחנה תחת  כנפיו, וישאנה אל
        קִנה שרותח בו מלֹא כוס אהבה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"אנטון, אנטון!" לוחשות שפתיה, ודמעות מתגלגלות מעיניה השחורות ויורדות על
        לחייה הרזות, החוורות. והנה הספינה הרימה את עוגנה ונפלגת מן החוף. ואנטון
        איננו שומע את אנחותיה, איננו רואה את דמעותיה!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הכל נאבד!" ובצעדים מתנועעים היא נכנסת אל האולם. שם כיבו את האור
        האלקטרי. היא בקשה מהמשרת מנורה, ותשב לכתוב. לבה זב דם, ובדמה זה כתבה
        לאנטון את מכתבה...</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלילה נדדה שנתי ממני. רשמי היום העבר לא חפצו לסור מנגד עיני. חום נורא
        היה במחלקה ובענוים רבים התהפכתי מצד אל צד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל סוף סוף התחיל הבקר להאיר. קמתי ממשכבי להתרחץ ולקשר את חבילותי. חלק
        גדול מהנוסעים התעתד לרדת אל חוף יפו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד לא עלה השמש, כשעמדה אניתנו מנגד החוף, עטופה היתה העיר בתוך ענן קל.
        והנה הבריק פתאום ראש אחד המגדלים, אות הוא שהחמה הולכת ומתקרבת... והנה
        הופיעה! היא יצאה אלינו מראש ההר ומצחקת לקראתנו ומפזרת קו אור רחב על פני
        הים ממולנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כל הסביבה הוארה. רק ההרים שם מרחוק עוד כהים, עודם מכוסים בעב הענן.
        והעיר בחצי עגולה היפה כבר נראה כלה נגדנו. הבתים שלה מתחילים משמאל,
        בנוה-שלום, נמוך אצל הים, ועולים עד טבור העיר המשתרע על ראש ההר. לצד ימין
        יורדים שנית הבתים אל העמק. הים היה שקט באותו בקר והגענו בסירתנו מהר אל
        החוף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הג&#39;ומרקי <span class="italic">[המכס הטורקי]</span> עובר מי שחי כבר בארץ-ישראל ויודע ערבית, בלי
        עכובים. הוא מרגיש את עצמו חפשי. כאשר דורשים ממנו בשליק מיותר בקשיש, הוא
        שואל את פקידי החוף בבטחה: "למה? הלא זה לא מגיע לכם כלל!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכשהם רואים שאין להם עסק עם &#39;רשים&#39; – לאמר: חדש, אי-בקיא –
        נותנים לו ללכת בטח דרכו.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכשעוברים רק את הסמטאות הצרות שבתוך העיר העתיקה ובאים להעיר החדשה,
        פוגשים תכף בעקבות חיים עברים ביפו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה חנויות רבות ששלטיהם כתובים עברית. הולכים הלאה ופוגשים בפועלים
        ופועלות צעירים המדברים עברית, פוגשים בתלמידים ותלמידות עם ספריהם
        ומחברותיהם תחת אזרועם ופניהם מועדים לבית-הספר לבנות, שמתחנכות בו כשלש
        מאות נערות מכל השדרות; או אל הגמנסיה העברית, שמשכה אליה את הילדים היותר
        טובים שבערי ארץ-ישראל ובמושבותיה, ובאו אליה ילדים גם ממדינות וארצות
        אחרות כמו רוסיה, ארגנטינה, אנגליה, אפריקה הדרומית ועוד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנך מרגיש פה שלמרכז עברי באת, למקום שהתחיה פורחת בכל רחבה ויפיה; ועברת
        אחרי כן לנוה-שלום, לחלק העיר היהודי, והנך שמח גם עצוב יחד. לפני שנים
        אחדות מעטים היו בתי היהודים פה ועתה הגיע מספרם לעשרות ולמאות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על סף הבתים מצחקים ילדים וילדות, תלמידי גן-ילדים, והשפה העברית בפיהם.
        מחלון אחד הבתים משתפכת שירה עברית, שירה עמוקה, מתוקה מפי אחת העלמות
        שגמרה את בית-הספר לבנות פה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הלב היה מתמלא שמחה לולא האי-נקיון שהנך פוגש ברחובות האלה. אצל כל שער,
        כל דלת וחלון מושלך זבל ואשפה, המעלים צחנה ומחניקים את העובר פה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הנה צפת בזעיר אנפין!" אמר לי אחד מידידי, שהלך ללוותני.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואמנם נזכרתי את צפת. פלא! כל מה שיגדל הישוב היהודי ביפו, כל מה שמתרבים
        בתי היהודים – מתרבה האשפה והחלאה בראש חוצות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"למה זה? למה היהודים מחבלים בידיהם את בריאותם, ומביאים על עצמם מחלות
        ופגעים הבאים מאי-נקיון הזה?" שאלתי את רעי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"עמנו דורש חנוך, חנוך בכל!... לימי הזקנה הוא נמצא שוב בבחינת ילד...
        דרושים לו אומנים, מורים ומחנכים..." ענה לי רעי בקול עצוב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ביפו נשארתי ימים אחדים לחכות על אחד מקרובי שצריך היה לבא מרוסיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הגיע יום כ&#39; תמוז. ובהכנסי בבקר במקרה ללשכת המודיעין נודעתי, שבשעה
        ארבע תהיה אזכרה לנשמת הרצל בבית-התפילה של הספרדים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבואי בשעה הקבועה למקום האזכרה מצאתי הרבה ממכירי ומכירותי הטובים
        מבעלי-הבתים וגם מהפועלים. אלה ישבו על הספסלים בצד זה, ואלה עמדו בצד
        השני.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהתאסף המון גדול באולם, עלה על הבמה מר פפר, מורה בבית-הספר לבנות, עטוף
        טלית והתפלל מנחה.
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">אחרי המנחה עלה מר שינקין על הבמה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"אל תבכו למת, בכו בכו להולך!" התחיל הנואם ויביע רעיון כזה: עם ישראל
        שעוד לא מת, הולך וגווע. ישנם בחייו רגעים שהוא מתעורר לתחיה, אבל
        ההתעוררות עוברת בין רגע, ודורות רבים ושלמים הוא מתהלך כמת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תקופות שונות עברו על עמנו. בין האחרונות היו ימים שחפץ עם ישראל למחות כל
        זכר של גאולה מקרב חייו, ובאה אחרי התקופה הזאת התעוררות של תחיה, היא
        תנועת שיבת ציון. ואחרי זה באה התקופה הבהירה של הציונות שברא אותה הד"ר
        הרצל. (בהזכירו את שם הרצל בכה הנואם).</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרצל קרא לבני עמו את הקריאה הגדולה: "חיו, כי חיים אתם!" – והעם עונה
        לעומתו: "כן, חפצי חיים אנו, חיים נורמלים על אדמת אבותינו! לך דרוש את
        זכותנו ואת צדקתנו!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והרצל הולך. הלך ומת פתאום באמצע הדרך... צואה אחת קטנה הוא עזב לנו. בה
        התחנן לשאת את עצמותיו לארץ-ישראל, ואנחנו עד היום עוד לא עשינו את מצותו.
        לא הבאנו הנה את עצמות מתנו הגדול, מפני שארץ עוד אין לנו!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהתחיל מר פפר את התפלה "אל מלא רחמים", קם כל הקהל. כמו דקירה הרגשתי
        פתאום במוחי, ובהקשיבי להנגון העצוב והחודר לב, עבר רעד בכל עצמותי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מר שינקין הוסיף אחרי זה דברים אחדים מלאי תמחומים, באמרו שהקמץ הקטן,
        היושב בארץ-ישראל, הוא ה"שאר ישוב", הוא יסלל דרך לעם ישראל לבא
        לארץ-ישראל. וכשתהיה הארץ מיושבת מבני אחינו, יעמידו פה זכרון נצח להרצל,
        זכרון שיהי נאה לו ונאה לעמנו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחריו עלה מר מוסינזון על הבמה. הוא באר בתחילה שמנהג הזכרת נשמות מן
        המקובלים מצאו. אחרי כן נתן תאור ממהותו של הרצל, ואמר שהרצל הוא אחד
        הגבורים, אשר תקופות שלמות תלויות בהם; ולא לספר אותו הוא חפץ, כי מעשי
        הרצל לא מעשי בכי המה, כי אם חפץ הנו, שהיום הזה יהיה יום של חשבון הנפש
        לעמנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אמנם עוד כבד להביא אכל בחשבון, אבל לאט לאט נוכל לעשות את זה
        כשנחפוץ...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וספר מר מוסינזון אגדה נפלאה על בן-מלך שתעה בדרך לארמון אביו, ופגש את
        אליהו הנביא ויבקשהו להורות לו את הדרך, והזקן ענה: "לך ישר, ואל תשים לב
        לשום מכשול שימצאך בדרך, אפילו אם באבנים ירגמו אותך אל תפנה לזה. רק תלך
        ישר, ואל תשים לב לשום דבר שתראה לפניך!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וילך בן-המלך מבלי הבט ימינה או שמאלה ועוד מעט קרב למטרתו. פתאום פגש
        בילד עזוב, בוכה ומתחנן לעזרה. וירחמהו בן-המלך, וירימהו ישאהו אתו...
        וברגע הזה סר כחו ואונו ויאבד דרכו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עוד דברים יפים מלאי מליצת סגנונו המגוהץ, דבר מר מוסינזון, ומשך את לב
        הקהל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחריו נאמו נואמים אחרים. ובגמר הנאומים התפזרו הנאספים לסמטאות יפו לכל
        עברים.</span
      >
    </p>
    <p class="c4 text-center" dir="rtl"><span class="c1">*</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי יומיים עזבתי את יפו ללכת הביתה. שם, בעין-הקורא, חכתה עלי עבודה
        רבה. זמן בציר הענבים ולקיטת השקדים הגיע, והבית דרש את תפקידי, תפקיד
        אכרה, בו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נסעתי ב"דיליז&#39;נס" הראשוני, המלא נוסעים על כל גדותיו. תחת רגלי
        מונחות חבילות מכל המינים ובקושי אני מוצאת מקום להניח עליהן את רגלי. מצד
        שמאלי על ספסלי יושב איש עב הכרס ומתנפח מחום, לימיני אשה לא צעירה עם ילדה
        קטנה על ברכיה, ואני באמצע כדג בקופסא. נוסף לזה, דחפה אותי ברגליה ילדת
        שכנתי; התפרפרה השובבה, התנועעה, צעקה ובכתה ולא ידענו מה היא רוצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בדרך שבין הפרדסים היה חם נורא. אבק העולה מן הכביש החניק את הצואר, האביק
        את העינים. גדרי הצבר, השטה, עצי אורנג&#39;ים, לימונים, אתרוגים ורמונים –
        הכל היה מכוסה באבק עב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכשיצאנו את הפרדסים והתקרבנו למקוה-ישראל נשב עלינו פתאום רוח קריר והשיב
        את רוחנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ירושלמי צעיר עם פאות מסלסלות וחלוק ארוך, שישב על הספסל השני מאחרינו
        התחיל לשיר, בתחילה בחשאי, ואחרי כן בקול רם, ולא תפילות לימים הנוראים הוא
        שר, כי אם שירי חול, שירים ז&#39;רגונים <span class="italic">[ביידיש]</span> מן היותר מודרנים בין
        המון הגלותי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"מאין יודע אדוני את השירים האלה?" שאלתיו בפנותי אליו את ראשי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"מאין?" ענני בצחוק קל, "הלא אני משורר גדול! אני בעצמי ובכבודי חברתי
        אותם. העתון הז&#39;רגוני האמריקני (שכחתי איזה שם קרא לו), מלא על כל
        גדותיו שירי הנפלאים!..."</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"גם את המנגינות מחבר אדוני בעצמו?"</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"בודאי! לפי טעם השיר אני נותן  לו נגון. בירושלים מכירים ויודעים את טוב
        טעמי בשיר!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >צחקתי בלבי ושתקתי. והנה אחרי רגעי מספר פונה אלי הצעיר ואומר: "הגברת
        חושבת אותי באמת לירושלמי צנוע? הקפוטה הארוכה, שאני מלובש עתה, אינה כי אם
        בשביל שלא לאבק את בגדי הטובים. יש לי, גברתי, בגדים קצרים ויפים, וכשאבוא
        אם ירצה השם אל המושבה, שאני מקוה להשיג שם משרת חזן, אלבש את בגדי היפים
        ואת כובע הקש שלי אשים על ראשי, במקום הכובע הירושלמי..."</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"ואדוני נשוי?" שאל אותו יפוני אחד, שמתעסק קצת בשדכנות. הצעיר התאנח
        וענה: "שלש נשים היו לי! עם השתים הראשונות חייתי חיי אמלל ונתתי להן גט
        פטורין; אבל השלישית, עליה השלום, אשה טובה היתה ובעלת נפש עדינה, ולא חפץ
        ד&#39; באשרי ולקחה מעל ראשי!..."</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"ומה אתה חושב לעשות עתה?" שאלהו השדכן שנית.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"עתה אני מקוה להושע מן המושבות! כי יחד עם החזנות אני מקוה לבנות לי אם
        ירצה השם בית, כראוי לאדם הגון מלא כשרונות כמוני!"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">בכל כחי התאפקתי מצחק לשמע דבריו.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ה"דילז&#39;נס" לא הלך ישר בכביש דרך בית-דגון, כי אם נטה לצד החולות,
        אחורי הכפר יעזור, והלך לו בין השדות, שהוקצרו, גם הגרנות הֻרמו מכבר ונשאר
        רק השֻמשמין בשדה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מרחוק נראה ראשון, עוד מעט ואנחנו נכנסים בין הכרמים מצד המערבי של
        המושבה. אני מכירה מרחוק לימין את הר-החול הלבן שעין-הקורא גוזל
        ממנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה פה קרוב פרדסו החדש של לונטין. ודרומה מעט משתרע יער עבות, זה הפרדס
        בן תשע שנים, פרדסו של לוין.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משני צדי הדרך נמשכים הכרמים בשורות הגפנים הישרות שלהם; ומסובלות כבר
        הגפנים אשכולי ענבים מלאי מיץ מתוק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה כרם גדול של עצי שקדים רעננים וחורשת אקליפתים אחריו; והנה רחוב עם
        גדר של שטה משני הצדים; עוד מעט והגענו עד רחוב הגדול, היפה ששם רחוק
        מלמעלה מתנוסס בית-התפילה הנהדר בראשו. הכל ירוק, צץ פורח. מבין עצי תות
        וזינזלה, הנטועים משני צדי הרחוב, נשקפים הבתים הלבנים, המבריקים מקרני שמש
        האחרונות. מימין מאחורי עצי מצל נשקף היקב בכל גדלו וענקיותו. הכל נראה לי
        כה חדש, יפה! כה הרנין את מיתרי לבי! ובאותו רגע חשבתי: לא רק הגליל שאני
        שבה עתה ממנו יפה הנהו! יפה גם ארץ יהודה, ויפה ונחמד הוא "ראשון"
        שלנו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלב דופק משמחה נכנסתי אל סמטאתי, אשר השטה הירוקה גודרת אותה, ועצי התות
        מתלחשים מעל לשטה ומניעים לי ראש...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בצעדים ממהרים נגשתי אל גנתי, שעל יד חצר ביתי. שם תחת תאנה גדולה, עבותה
        יושב חברנו העוור ועוזר לבני ביתי לקלף את השקדים שהתחילו ללקט באותו יום.
        הוא קולף ושר וקול צלצול שירתו הנעימה, העמוקה חודרת אל תוך נימי הלב היותר
        דקים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">"שלום, דוד!" קוראת אני לו מרחוק ונכנסת אל החצר.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פה רצים לקראתי ילדי שמחבקים ומנשקים לי, והנה גם התרנגלות עפות לקראתי;
        והיונים ירדו מארבותיהם ומקשקשים בכנפיהם הלבנות, החומות ואפורות על הארץ;
        גם האוזים התנערו מתרדמתם בצל העגלה שעומדת בחצר, ויקרבו אלי לבקש ממני
        אוכל...</span
      >
    </p>
  </body>
</html>
<span class="italic"></span>
`;

export default page;
