const page = `<body class="c9 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c1">לפי התאור נראה שהרשימה עליה מדובר היא הסיפור </span>
    <a href="/herStories/inSolitude">
      <span class="c1 c11 c12 tooltip"
        >בבדידות<span class="c1 c11 tooltiptext"
          >ראה ״בבדידות״, סיפורים שלה</span
        ></span
      ></a
    >
    <span class="c1">אשר פורסם בעיתון </span
    ><span class="c1 c11 bold">התקופה</span
    ><span class="c1"> ביוני 1923 (קיץ תרפ"ג) ואחר כך נכלל בקובץ </span
    ><span class="c1 c11 bold">בכפר ובעבודה</span
    ><span class="c8 c1"> שיצא לאור ב1930.</span>
  </p>
  <br />

  <p class="c2" dir="rtl">
    <span class="c3">ראשון לציון כ"א אלול תרפ"ב</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c3">        לכבוד מר י.ל. גולדברג  </span>
  </p>
  <p class="c2 c10 text-center" dir="rtl">
    <span class="c3">תל אביב</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c3"
      >        על יסוד הבטחתו להיות לי למליץ לפני מר שטיבל, אני עורכת את המכתב
      הנוכחי. הרשימה, שאני מבקשת להדפיס, תכיל כששים דף בערך וכתובה בתור יומן. –
      בת אכרים שהתיתמה, מנהלת בכשרון את נחלת ההורים; יחד עם עבודת המשק, כל חייה
      נתונים בעוצר לאח צעיר ממנה, שאליו היא מתמסרת באהבת אם רחמניה למרות כל
      הנגודים שבדעותיהם. – היא לאומית קצונית, חולמת על בנין הארץ רק ע"י בני
      עמנו, בעצם ידיהם, והוא לועג לדעותיה ואת כל קדשיה מחלל בהבל פיו ובמעשי
      ידיו.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c3"
      >הוא עצבני, מלא תהפוכות וממלא תמרורים את חיי האחות. סִבלה מגיע לידי נקודת
      הגובה שלו, בעת שהאח רוצה להתחתן והאחות נעשתה בשבילו מיֻתרת בבית ובִקש
      להתפטר ממנה. בנפשה התחוללה אז מלחמה קשה ובדם ליבה נתקה מהר מכל היקר לה,
      מכל מה שיצרה וידיה טפלו בהם עשרות בשנים – ותלך לה.
    </span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c3"
      >במטבח פועלים מצאה עבודה ותשקיע בה שארית הכחות הרצוצים.</span
    >
  </p>
  <p class="c6" dir="rtl"><span class="c3">__</span></p>
  <p class="c0" dir="rtl">
    <span class="c3"
      >אדוני! לכל הנ"ל אוכל להוסיף שמר פיכמן קרא את היומן ולפי דבריו הוא עשה
      עליו רושם חזק מאד ויחשבהו בתור חמר לאחד הדפים שבתולדות הישוב.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c3"
      >אקוה שבהשתדלות כבודו יסכים מר שטיבל לסיע לי להוציא ספרי זה לאור. כשהדפסתי
      את ספרי הראשון היו לי אמצעים לכך, ועתה בהתרושש הישוב, נתדלדלה קופתי לגמרי,
      לגמרי.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c3">אני שולחת לכבודו ברכת שנה טובה, שנת שפע בכל מפעליו.</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c3">                        בברכת בנין ועליה –</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c3"
      >                                        נחמה פוחצ&#39;בסקי.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c3"
      >ת.א. – להצעתי ליסד עתון על שם הגברת שטיבל המנוחה, עוד "הסתדרות הנשים
      העבריות לשווי זכויות בא"י" לא הספיקה להתוות דרך. בכל אופן מענינת דעת מר
      שטיבל,</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c3">                        הנ"ל.</span>
  </p>
</body>`;

export default page;
