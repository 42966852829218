const page = `<body class="c7 doc-content">
  <h3 class="c6 text-center space-bottom" dir="rtl" id="h.qi3wctbkjgp5">
    <span class="c1">נחמה פוחצ'בסקי ז"ל</span>
  </h3>
  
  <p class="c0" dir="rtl">
    <span class="c4"
      >בשבועות מתה בראשון לציון הסופרת נחמה פוחצ'ינסקי, שאת ספורה ששלחה לנו
      בחייה אנו נותנים כאן.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c4"
      >נחמה פוחצ'ינסקי, שהיתה הסופרת הראשונה בספרות הא"י החדשה, היתה אכרה
      ועסקנית חשובה. ביתה היה בית ועד למשכילים ולפועלים העברים הראשונים שעלו
      לארץ, היא דאגה לעובד העברי והשתדלה להקל עליו את סדורו בעבודה. בעשרות שנות
      שבתה בארץ נלחמה בכתב ובעל-פה לעבודה העברית במושבות, ולא פסקה ממלחמתה זו עד
      יומה האחרון.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c8">נחמה פוחצ'ינסקי היתה מפרסמת מפעם לפעם ציורים </span
    ><span class="c5 italic">[סיפורים]</span
    ><span class="c4"
      > מחיי הארץ והישוב העברי ובעיקר של המושבות. היא היתה חביבה על כל יודעיה
      ובלויתה השתתפו רבים – אכרים, סופרים, פועלים.</span
    >
  </p>
  <p class="c0" dir="rtl">
    <span class="c4">בת 64 היתה במותה.</span>
  </p>
  <p class="c0" dir="rtl">
    <span class="c5 c9 italic"
      >בהמשך הכתבה מופיע הסיפור "רק פתקה", הנמצא <a href="/herStories">כאן</a> במדור
      הסיפורים.</span
    >
  </p>
</body>`;

export default page;
