import appTexts from "../texts/appTexts";
import homeTexts from "../texts/home";
import aboutUsTexts from "../texts/aboutUs";

import herStories from "../texts/herStories";
import herArticles from "../texts/herArticles";
import testimonies from "../texts/testimonies";
import aboutHerInThePressOfHerTime from "../texts/aboutHerInThePressOfHerTime";
import letters from "../texts/letters";
import fromHereAndThere from "../texts/fromHereAndThere";

import aboutHerInWritings from "../pages/aboutHerInWritings";

const getItemsFromArr = (arr, parentPath) => {
  let itemsArr = [];
  arr.map((item) => {
    let newItem = {};
    newItem.itemName = item.name;
    newItem.itemDoc = item.doc;
    newItem.itemUrl = parentPath + "/" + item.name;
    if (item.description) {
      newItem.itemDescription = item.description;
    }
    if (item.preTitle) {
      newItem.itemPreTitle = item.preTitle;
    }
    newItem.itemTitle = item.title;
    if (item.subTitle) {
      newItem.itemSubtitle = item.subTitle;
    }
    if (item.modalsContent) {
      newItem.modalsContent = item.modalsContent;
    }
    if (item.hidden) {
      newItem.hidden = item.hidden;
    }

    itemsArr.push(newItem);
  });
  return itemsArr;
};

const pagesContent = [
  {
    pageName: "home",
    // pageTitle: texts.homePage.title,
    path: "/",
    isMain: true,
    mainDesc: homeTexts.mainDesc,
    items: [
      {
        itemName: "home",
        itemSubtitle: homeTexts.subTitle,
        itemText: homeTexts.paragraphs,
      },
    ],
    children: [],
  },
  {
    pageName: "AboutHerInWritings",
    pageTitle: appTexts.pages.AboutHerInWritings,
    path: "/AboutHerInWritings",
    items: [
      {
        itemName: "aboutHerInWritingsItem",
        itemDoc: aboutHerInWritings,
        isMain: true,
      },
    ],
    children: [],
  },
  {
    pageName: "herStories",
    pageTitle: appTexts.pages.herStories,
    mainDesc: herStories.mainDesc,
    path: "/herStories",
    items: getItemsFromArr(herStories.items, "/herStories"),
    modalsContent: herStories.mainDescModalsContent,
    children: [],
  },
  {
    pageName: "herArticles",
    pageTitle: "מאמרים וכתבות פרי עטה של נחמה פוחצ'בסקי",
    path: "/herArticles",
    items: getItemsFromArr(herArticles.items, "/herArticles"),
    children: [],
  },
  {
    pageName: "testimonies",
    pageTitle: "עדויות של אנשים שהכירו את נחמה פוחצ'בסקי בחייה",
    mainDesc: testimonies.mainDesc,
    path: "/testimonies",
    items: getItemsFromArr(testimonies.items, "/testimonies"),
    modalsContent: testimonies.mainDescModalsContent,
    children: [],
  },
  {
    pageName: "aboutHerInThePressOfHerTime",
    pageTitle: appTexts.pages.aboutHerInThePressOfHerTime,
    mainDesc: aboutHerInThePressOfHerTime.mainDesc,
    path: "/aboutHerInThePressOfHerTime",
    items: getItemsFromArr(
      aboutHerInThePressOfHerTime.items,
      "/aboutHerInThePressOfHerTime"
    ),
    modalsContent: aboutHerInThePressOfHerTime.mainDescModalsContent,
    children: [],
  },
  {
    pageName: "letters",
    pageTitle: appTexts.pages.letters,
    mainDesc: letters.mainDesc,
    path: "/letters",
    items: getItemsFromArr(letters.items, "/letters"),
    modalsContent: letters.mainDescModalsContent,
    children: [],
  },
  {
    pageName: "fromHereAndThere",
    pageTitle: appTexts.pages.fromHereAndThere,
    mainDesc: fromHereAndThere.mainDesc,
    path: "/fromHereAndThere",
    items: getItemsFromArr(fromHereAndThere.items, "/fromHereAndThere"),
    modalsContent: fromHereAndThere.mainDescModalsContent,
    children: [],
  },
  {
    pageName: "aboutUs",
    pageTitle: aboutUsTexts.title,
    mainDesc: aboutUsTexts.mainDesc,
    path: "/aboutUs",
    isMain: true,
    items: [],
    children: [],
  },
];

export default pagesContent;
