const page = `<html>
  <body class="c10 doc-content">
    <p class="c4 c12" dir="rtl">
      <span class="c3"
        ><a
          target="_blank"
          class="c1"
          href="http://benyehuda.org/poxa/bamidron.html&sa=D&source=editors&ust=1696094438872477&usg=AOvVaw10nJ949yHKFX_I6iLIYwE3"
          >http://benyehuda.org/poxa/bamidron.html</a
        ></span
      ><span class="c5"> </span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c5">          </span>
    </p>
    <p class="c2" dir="rtl"><span class="c11"></span></p>
    <!-- <p class="c4" dir="rtl"> 
      <span class="c11">מאמרים במרשתת על הרומן :  </span>
    </p>
    
    <p class="c4" dir="rtl">
      <span class="c6"
        ><a
          target="_blank"
          class="c1"
          href="https://www.achva.ac.il/sites/default/files/achvafiles/maof_book/10/2004_13.pdf"
          >יוסף אורן - הרומן "במדרון" וזיקתו לברנר</a
        ></span
      ><span class="c8"> </span
      ><span
        >(מעוף ומעשה כתב עת לעיון ולמחקר, 2004, אחוה המכללה האקדמית לחינוך) </span
      ><span class="c7"> </span>
    </p>
    <p class="c4" dir="rtl">
      <span class="c6"
        ><a
          target="_blank"
          class="c1"
          href="http://www.haaretz.co.il/literature/1.948265&sa=D&source=editors&ust=1696094438873547&usg=AOvVaw3jcSVxcc16JEG4VF6ycijq"
          >מור אלטשולר - סופר אינו כותב לפי הזמנה ולא כפי שמעירים מן החוץ</a
        ></span
      >
    </p>
    <p class="c4" dir="rtl" id="h.gjdgxs">
      <span class="c6"
        ><a
          target="_blank"
          class="c1"
          href="http://www.haaretz.co.il/literature/1.963590&sa=D&source=editors&ust=1696094438873798&usg=AOvVaw3cOMPIFAy1Yvdm7tyxDLZ7"
          >מימי חסקין – "החיים והספר היינו הך, שם סבל וכאן סבל" - במדרון, רומן
          ארצישראלי
        </a></span
      ><br/><span class="c14"> </span><span>(הארץ 4 במאי 2004 מדור ספרים)</span
      ><span class="c0"> </span>
    </p>
    -->
  </body>
</html>`;

export default page;
