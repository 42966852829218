const desc = `
  <body class="c10 doc-content">
    <p class="c3" dir="rtl" id="h.gjdgxs">
      <span class="c2 c8">פלורה ספורטו</span><span class="c2 c6">  </span
      ><span class="c2 c4">פורסם לראשונה בעיתון </span
      ><span class="c7 c12">החרות</span><span class="c2 c4"> </span
      ><span class="c4 c11">בחמישה חלקים</span
      ><span class="c2 c4"
        > במהלך שבט תרע"ד [פברואר 1914] תחת שם העט &#39;בת צבי&#39; (שם אביה של
        נחמה פוחצ&#39;בסקי היה צבי פיינשטיין).  הראשון שזיהה סיפור זה כפרי עטה
        של נחמה פוחצ&#39;בסקי הוא משה בהר במאמרו
      </span>
      <a
          target="_blank"
          class="c5"
          id="linkOut"
          href="https://in.bgu.ac.il/humsos/hercen/Annual/Shared%20Documents/ben%20gurion%20paper%20behar.pdf"
          >מאה לפלורה ספורטו סיפור לא ידוע של נחמה פוחצ&#39;בסקי ואפשרותה של
            ברית פמיניסטית־מזרחית</a>
    </p>
  </body>

`;

export default desc;
