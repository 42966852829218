const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c0" dir="rtl">
      <span class="c2 bold">דבר 8.1.1937</span><span> - </span
      ><span class="c1">תחת הכותרת</span><span class="bold"> הדים</span
      ><span class="c1"
        > יש פרוט של ספרות עברית ומאמרים על ספרות עברית, אשר פורסמו בעיתונים
        שונים בארופה או יצאו לאור בתרגומים לשפות ארופאיות. בין היתר יש איזכור
        למאמר שהופיע ב&#39;דאס ווארט&#39; הקובנאי גל. 275, מאמר של ח.נ. שפירא על
        הסיפור והמספרים בא"י, ונכללת רשימה של הסופרים והספרים ששפירא מתייחס
        אליהם במאמרו. ברשימה זו מופיעים בין היתר שני ספריה של נחמה פוחצ&#39;בסקי
        ביהודה החדשה ובכפר ובעבודה.</span
      >
    </p>
  </body>
</html>`;

export default modal;
