const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c8 doc-content">
    <p class="c4" dir="rtl">
      <span class="c1">פורסם </span><span class="c7">ב</span
      ><span class="c5 bold">השִלֹח</span><span class="c3"> </span
      ><span class="c5 bold">- ירחון לספרות, למדע ולעניני-החיים</span
      ><span class="c7"> </span
      ><span class="c1"
        >תחת הכותרת: אסונה של אפיה (ציור) מאת נחמה פוחיצבסקי, בכסלו תרפ"ה [דצמבר
        1924] (כרך מ"ג חוברת ג). (הסיפור נכלל גם בספר </span
      ><span class="c3 bold">סיפורי נשים בנות העלייה הראשונה</span
      ><span class="c1"
        >,  בחרה והתקינה יפה ברלוביץ, ספרית תרמיל, משרד הבטחון 1984. כמו כן הוא
        תורגם לאנגלית ע"י  Sonia F Grober &  Claudia Chernov ופורסם תחת הכותרת </span
      ><span class="c0 bold">Aphia’s</span><span class="c0 c6"> </span
      ><span class="c0 bold">plight</span><span class="c1"> בספר </span
      ><span class="c5 bold">Ribcage</span><span class="c3"> - </span
      ><span class="c5 bold">Israeli Women’s Ficton</span
      ><span class="c1"
        >  בעריכת Carol Diament & Lily Rattok , יצא לאור בשנת 1994 ע"י</span
      ><span class="c3"> </span
      ><span class="c1"
        >Hadassah, The Women’s Zionist Organization of America inc</span
      ><span class="c6 c9">)</span>
    </p>
  </body>
</html>`;
export default desc;
