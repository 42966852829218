const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c9 doc-content">
    <p class="c4 text-center" dir="rtl"><span class="c5">א</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בעברה את הדרך, המובילה מן הקבוצה אל הכביש, מצאה אוֹטוֹ לנסוע העירה, לא
        לזו הקרובה, המוקפת הרים, שבתוכה רועשים לעתים פעמוני כנסיות, אלא לעיר
        העברית החדשה  שעל שפת הים.  שם יש לה קרובים מקרוב באו, שהיא מטופלת
        בסדורם וגם עתה קרואה היא לבוא לעזרתם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה עוברת ברחוב רועש.  כמה מוזר לה, לכפרית, כל הרעש הגדול הזה, התנועה,
        הריצה, המהומה.  יש לה הרצון להסתתר לבל יראנה, לבל יכירנה איש. פוגשת היא
        בפרצופים מוּכרים ומורידה את הכפיה עד לגבות עיניה והוא כזרה ומתנכרת
        לכולם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ערב.  היא עוברת ברחוב הראשי ואור החשמל מעַוֵר את עיניה.  הנה זקן
        הסופרים, בן עיר מולדתה, צועד עם בנו והם שלובי-זרוע;  הבן מעיף עליה עין
        כאילו הכירהּ, אבל היא מהרה לעבור על פניהם. לא הרחק מכאן הוא ביתו של
        המשורר, ובקצה הרחוב ההוא גר </span
      ><span class="c2">המורה</span
      ><span class="c1"
        >, אותו היא רוצה לבקר.   נכנסה אליו ברטט חשאי ולא מצאה אותו בבית; ראתה
        את שולחן כתיבתו, מקלו, ספריו הרבים ועיניה נצמדו אליהם כאל דבר
        שבקדושה.  עמדה דומם והרהרה:  רחוקים הימים מאז נפרדו ותהום רובצת
        ביניהם.  אם כולם עקרה הסערה והגלים נשאו אותם אל חוף המולדת – היתבצר
        החוף?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לרגל עניני קרוביה, עשתה שני ימים בעיר, ובגמרה שמֵחה היתה למצוא אוטו
        ולברוח מן הרעש שנית אל נקודתה החבויה בין ההרים.  הפרדסים, שעברה  ביניהם,
        הגנים, השדות רחבי הידים, הזרועים תבואות שונות עוררו בה קנאה:  הנה כל
        השפלה זרועה, – החטה, השעורה והתורמוס תופסים שטחים רחבים מהכביש עד האופק,
        משתטחים כפרים וערים עד קרבת ההרים.  כל הישוב הרב הוא </span
      ><span class="c2">שלהם</span
      ><span class="c1"
        >. לנו שייכות רק נקודות בודדות הנראות במרחק, ועוד צועקים הם בלי-הרף, כי
        אנו מנשלים אותם מעל אדמתם – כל העושר הזה, כל הטוב, כל הברכה הזאת, ואנו
        נדחקים אל החולות, הסלעים, הבצות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשנכנס האוטו בין ההרים, נדמה לה פתאום, כי למקומות קרובים לה הגיעה, –
        כאן מוּכרים לה כל הר וכל גבעה.  הנה הגיעו לכפר הגדול שבשכנותם עד הכרמים
        על ההר והמנזר במבואו.  על גבול קבוצתה התחיל הלב הולם משמחה בראותה את
        הברושים הזקופים, המתנשאים בגֵאוּת, כאילו יש להם ההכרה, כי לתפארת הארץ
        והבראתה הם נטועים כאן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהגיע האוטו עד הדרך המובילה אל הקבוצה עמד כאומר להוריד אותה עם חפציה
        ולנסוע הלאה, אבל היא בקשה להובילה הביתה ותאמר לנהג כשזה סבב את
        האוטו:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– יש גם לנו אוטו, העובר כאן פעמים ביום העירה להוביל את החלב שלנו.  –
        ובמלים אלו הרגישה ספוק נפש שהנה יכולה היא לבשר, כי יוצרים הם דבר מה בתוך
        מקום שמם זה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עברה על יד הנטיעות החדשות, שנקלטו יפה, נמלא הלב גאוה על ההצלחה
        בנטיעות.  היא נזכרה בינתים בחורף דאשתקד, בזמן פתיחת התעלות לגפנים,
        כשאנשים הכו במכושיהם בתוך האבן, הזיעו מחום, נרטבו מגשם.  ובשעה שהמים
        מלאו את התעלות, פחדו פן אי-אפשר יהיה לנטוע, או אולי יגרפו המים את
        הנטיעות, או השרשים ירקבו בתוכם;  כל מיני פקפוקים באו אל הלב, ועכשיו כל
        החלקה התכסתה גפנים יפות, צוחקות והכלורופיל מבצבץ מכל עלה ומרהיב את
        העין.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האוטו עמד אצל הצריפים;  היתה שעה של אחרי הצהרים;  בחלונות נראו הפנים
        החביבות של החברים והחברות. הנה הם יוצאים אחד אחד לקראתה, ויש לה הרצון
        לאמצם אל לבה, כאילו לא ראתה אותם זה ימים רבים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחדר-האוכל עמדה והתבוננה – הכל נראה לה לבן ומבריק;  והיא שואלת את
        לבה:  מי שפך את החן הזה על קירות העץ, השלחנות הפשוטים ורצפת המלט, ההרוסה
        בכמה מקומות, ופנאי אין וכסף אין לקנות מלט למען תקנהּ?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נכנסו הפעוטות, – הנה עליז&#39;נקה ויריב&#39;לה וציון.  רק הקטנים ביותר
        ישנים עדיין את שנת-הצהרים בעריסותיהם. תמרה מחלקת לפעוטות את המתנות
        שהביאה להם והכרובים הקטנים עולזים ורוקדים סביבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >השעה מאוחרת בלילה ותמרה אינה יכולה להרדם.  – קול הקורא נשמע
        בהרים;  הדלת החיצונה חרקה על צירה ומעבר למחיצה נשמע לחש אהובים – ברקאי
        וברורה הם!  היא מתכסה מעל לראשה, לבל תשמע את לחשם.  מה קשה לה להיות
        לילה-לילה עֵד שמיעה לצחוקם והשתובבותם.  היא רוצה זה כבר לבקש לה חדר אחר,
        רק חוששת היא שמא יחשדו בה בקנאה, ולכן דוחה היא את הדבר ומוסיפה לסבול
        ולשתות כוס המרירות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >משתדלת היא להסיח דעתה משכֵנות זו שמעבר למחיצה ומקשיבה למה שנעשה הלאה,
        בחדרים אחרים.  – נחרת הבחורים עולה מקצה הצריף וממלאה את חלל האויר.  צעדי
        השומר נשמעו, הנה הולך הוא להעיר את גרשוני לקום אל הרפת.  בימי
        רִפְתָּנוּתָהּ היו בשעה זו, כבר דופקים גם על דלתה, אבל כעת רשאית היא
        להתפנק ולישון עד אור הבוקר.  היא מתעטפת בשמיכתה, שמה את הכר על
        ראשה  ואינה רוצה לשמוע עוד מאומה, רק לישון כל הלילה, לישון גם כל יום
        המחרת – היא כה עיפה, כה עיפה!  אבל מחר הן ערב שבת, מחר צריכה היא לאפות
        שתי פעמים לחם וגם עוגות לשבת, – אבל מדוע זה אינה יכולה לישון?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובשכבה ככה ערה היא מחליטה להשתמש בסגולה אחת, המקילה לה להרדם, – היא
        מאמצת את עיניה היטב ומשתדלת להעלות בזכרונה את טחנת המים שעל גדות הנהר
        העובר מחוץ לעיר מולדתה.  הנה מתנועעות כנפי הגלגל, היא משתדלת להסתכל היטב
        בסבובן ולא לאבד אותן אף רגע מנגד עיניה.  הנה מתחילות אצבעות ידיה ללוות
        את תנועת הגלגל ולהסתובב באויר ומתוך סבוב זה נופלת עליה תרדמה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יום המחרת היה יום מוצלח.  – הלחם עלה יפה, גם העוגות הצליחו וחדר-האכילה
        סודר באופן כזה, שעיניה נהנו ממנו הנאה מרובה.  הכל הבריק כאן, – החלונות,
        הספסלים, המנורות וגם הארון העתיק לבש צורת חג, יופי מיוחד נוסף לחדר הודות
        לפרחים, שלושה זֵרִים, שאספה ברורה וסדרה על השלחנות.  נכנס אחד החברים
        וחלק לברורה מחמאה: –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– היום בודאי ברורה היא שסדרה לנו את חדר-האכילה, מכירים תיכף את ידה של
        ברורה – וברורה היתה מאושרה ופניה הנעימים כוסו באודם של שמחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החברה לא מהרה להתפזר אחרי ארוחת-הערב ונשארו יושבים אצל השלחנות, תמרה
        ישבה ברחוק מקום מול ברקאי, ובסמכה את לחיה בידה השמאלית הסתכלה בו
        בהתענינות.  שערותיו שחורות, ריסי עיניו ארוכים ומכסים על העינים המורדות
        אל העתון;  מקום הגלוח שעל הלחי תופס מקום רחב מאד, ותמרה חושבת, שלוּ היה
        לו זקן, אז היו כל פניו טבועים בשערות ולא היתה אולי נכרת הגומה שבלחיו,
        גומה זו, שאינה מסתלקת כמעט, אינה מתישרת ותמיד מְשַוָה לפנים איזו
        בת-צחוק, שהיא כה אוהבת.  הנה הרים ברקאי את ראשו, אבל לעברה לא הביט, שלח
        מבט למרחוק ושוב נשקע בקריאה. כמה היתה רוצה לשבת קרוב אליו, סמוך
        אליו.  הוא יקרא לו ככה בעתונו.  אבל היא תרגיש את חום גופו קרוב
        אצלה.  לבה ידפוק באהבה והוא לא יֵדע, כמו שאינו יודע, כי מראה פניו מלוה
        אותה בכל מקום. והלא תמיד היא רואה לפניה את הראש היפה הזה עם השביל בצד
        שמאל והבלורית הסרוקה יפה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יושבת היא ומהרהרת את מחשבתה בשעה שהכל רועשים מסביב.  – מהי האהבה
        האפלטונית שלה, – אי מקורה? הנה החבר זהבי האהוב יותר וחכם יותר מברקאי, –
        אדם בעל יכולת כבירה;  כל מלה שלו, כל דעה שהוא מביע, כל פעולה, יש להן ערך
        וחשיבות.  היא מיחלת למוצא פיו, מחבבת אותו, אבל אין לה אותה כמיהה חולנית
        לקרבת גופו, למגע קל שלו ואין דפיקות-לב בשעה שהוא מתקרב.  איזה רגש
        בלתי-מובן הוא בשבילה זה שבני אדם קוראים לו – אהבה.  היכן חבוי אותו התא
        הראשון, שממנו מתחיל הסבל הזה?  מדוע ישנה כמיהה לאיש זה ולא לאחר, הטוב
        ממנו עשרת מונים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ביום השבת קמה תמיד תמרה שביב בשעה מאוחרת.  אכלה דבר קל ויצאה לסַיֵר את
        הירקות.  היא עוברת מחלקה לחלקה, משורה לשורה, מרימה כל עשב מיותר או שיח
        שהתגלגל לכאן, מסתכלת בכל השטח הזרוע ואת עיניה מרהיבות השורות הישרות
        והגאות.  הנה הבצל, הסלק, החסה, הכרובית – הלא בין חצץ זרועים כל אלה,
        באדמה שבקושי רב עלה לסקלה מן האבנים הכבדות ולהכשירה לעבוד. היא זוכרת מה
        היה המקום הזה, כל הסביבה הזאת בשעה שבאה לכאן אל הקבוצה – מדבר שממה. ועתה
        ההרים נטועים, המישורים זרועים, צלעות ההרים חרושות ומוכנות חלקות לזריעות
        קיץ.  הנה רועים שם בעשב שני זוגות הפרדים שלהם, החרוצים שלהם, – החברים
        הנאמנים הללו, כמה הם עוזרים למשק לשכללו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >עיניה פונות לצד הרפת, הבנויה לתפארה, שם כבר נוספה רפת קטנה בצד וחמשים
        גלגלות בקר מונים ברפתות – מה רב העושר!  התחילו מופיעים בקבוצה תַיָרים
        כמעט יום יום.  "רוצים אנו לראות את המושבה לדוגמא – Die
        Muster-Kolonie"  אומרים הם, ודבריהם מצלצלים באזני החברים כלעג לרש.  בשום
        אופן אינם משמשים עוד דוגמא, אבל שיש בעבודתם דבר-מה ממשי – אין
        להכחיש!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במחשבות כאלה מתהלכת תמרה שביב לבדה בין ההרים עם אורותיהם הרבים, ונדמה
        לה, כי בשום מקום אחר בעולם אין שפע-אורות כזה כמו בין סלעים גבוהים אלה,
        בוקר בוקר היא מסתכלת בהתפעלות בצאת השמש מאחורי ההר המזרחי ואינה יכולה
        לבלי להתעכב במקום הזה;  אמנם מוכיחה היא את עצמה בשעה זו:  ראי, העבודה
        מחכה לך ואת מתבטלת! אודם מכסה את פניה, אם מישהו מן החברים מזדמן לראות
        אותה בבטלנותה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        > לא רק האורות אלא גם הצבעים הרבים מלבבים אותה.  חלקה חלקה
        וצִבעה:  ירוקה-כהה, אדמומית, ירוקה-בהירה, צהבהבה, אפורית, וסביב לכולן
        גדרות, גדרות אבן, המשמשות להן גבולות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ההרים סוגרים את כל העולם בפניה; מעבר להם אין כלום;  כאן ערש העולם, כאן
        התהלכו הנביאים ונוצר המוסר האנושי, כאן נוצרה הנבואה הישראלית.  האם לא
        קול אלהים מתהלך בנקיקי הסלעים האלה, אשר שמרו על הויתם מאז ומעולם?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא מתעוררת פתאום לקול צליל פעמון הבא ממרחקים וכעין זעזוע עובר
        בקרבה:  הוי המנזר הזה – כמה אין זה מתאים עם חייהם ועם צורת
        הקבוצה. מציאותו כאן ממש שורטת בנפשה.  היא היתה רוצה שהם ימצאו רחוקים מן
        השקר, מן הצביעות, והנה קן-צביעות במקום טהור זה.  חברתה ירדנית אינה
        מסכימה לדעה זו, שהיא הביעה לפניה באחד הימים.  ירדנית מחבבת את רעיון
        המנזרים ואומרת, שאילו נוסד מנזר לבנות ישראל היתה היא נכנסת אליו
        מיד.  היא לא מצאה את ספוקה גם בקבוצה, ולפי דעתה, אשרי הנערה שתברח אל
        המנזר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושוב נשמע צלצול פעמונים ותמרה עומדת תמהה, – למה מצלצלים שם בכל עוז, את
        מי קוראים במדבר הזה ומה פשרו של הצלצול בכלל?  לא ברעש ה&#39; – עונה לה
        הד ההרים.  והיא כבר חוזרת הביתה, אלא שמתעכבת היא על יד הלולים.  כאן לצדי
        הגדרות, במקום גידול העשב, התכנסה דוגרת עם אפרוחיה הרכים, מלמדת אותם לנקר
        בעשבים, ובאכלם לשובע, סרה היא אתם הצדה, חופרת גומה באדמה רכה ומלמדת את
        אפרוחיה חכמת הרחיצה באמבטית חול.  בכל מלוא החצר רחשו אפרוחים יוצאי
        המדגרות, ונדמה לה שהיא רואה שדה  של פרחים, פרחים חיים, הזזים ממקום למקום
        בבקשם גידול וקיום בעולם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בערב הכריזו על אספה כללית. הקריאו את הדין-והחשבון ודנו על המצב. – ישנו
        גם השנה גרעון, השנה שכל כך גדולה היתה בה ההתאמצות לשכלל את המשק, להכניס
        תקונים בכל ענפי העבודה, שנה שהתחילו למכור ענבים וירקות והחלב התרבה בה
        במדה נכרת, כיצד בא גם השנה גרעון, איה מקורו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מכאיבה מאד השאלה ומתקשים בפתרונה.  שתי דעות שוות לא שמעה, כל אחד
        בשלו.  שימת-לב מרובה עוררה בה הרצאת החבר זהבי, שהקיף את המצב בעין בהירה
        וידע להדגיש את נקודת-המוצא.  רק בדבר אחד נפשה סולדת:  הוא יותר סוחר
        מחקלאי!  מדבר מתוך כאב מר על גידול ירקות, ענף שאינו מכסה, לפי דעתו, את
        הוצאות העבודה וצריך למעט בו.  כל אותן החלקות, שהירקניות מתפארות בהן,
        פריָם עולה להם כפלים מכפי שאפשר להשיג בשוק.  למה להקריב למולך כוחות,
        שאינם נמצאים אצלנו בעודף כלל?  רק במקצוע אחד, יחיד, מאמין זהבי, שכדאי
        לרכז בו את הכוחות, – זוהי הרפת!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא מתנגדת לזה שהם יתרכזו בשכלול מקצוע אחד, אלא נחוץ שכלול כל המשק
        בהיקפו.  משק החלב קשור יותר מאחרים במסחר, – קניה ומכירה, מכירה
        וקניה!  החקלאים האמתים צריכים להתקשר יותר באדמה, ישר בקרקע ולאו דוקא
        במקצועות כאלה, שהם אחוזים ודבוקים במסחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זהבי אינו מודה במסחר היתר שיש בהרחבת הרפת, זהו ענין של קיום, ואומר בכל
        תוקף:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אם הרפת תתן לנו את אפשרות הקיום, כמו שאני מאמין, הלא הצלנו בגללה את
        כל החקלאות שלנו, את כל קיומנו על הקרקע.  אנחנו באנו ליַשֵב כאן את ההרים
        השוממים הללו;  הכשרת כל אַמַת קרקע עלתה בזעה ובדם.  אנחנו משתדלים לנצל
        אותה להוציא ממנה את מה שאפשר, והנה רואים אתם, חברים שלא הכל מצליח
        בידינו, כי תלויים אנו בתנאים הקשים של הטבע:  פעם השרב אוכל את יגיענו,
        פעם חוסר-גשמים הוא בעוכרינו, והנה הופיעו מחלות בצמחים!  עודנו מגששים
        כעורים קיר, כי מורי-דרך אין לנו.  אלה שרוצים להיות מורינו למדו בארצות
        אחרות, שונות מארצנו, התמסרו לספר, והמעשה רחוק מהם, ואנחנו מוסיפים שגיאות
        על שגיאות. על-כן, חברים, דעו לכם, כי כדי לשמור על קיום קבוצתנו ועל
        שלמותה, עלינו להתרכז במקצועות משק כאלה, שיתנו הכנסה בטוחה ויאפשרו את
        קיומנו בפנה זו שבחרנו לנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">ותמרה עונה שוב על דבריו:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– קשה מאד כשאחד מן החברים מביט בעין של תַּגָר על תוצאת עבודתם.  לפי
        דעתי צריך להיות יחס אחר לגננות.  כל בצל או ירק אחר, שאנחנו מוציאים בעצם
        ידינו מן האדמה, מכינים אותו למאכל ואיננו זקוקים שהערביים מן הכפרים
        הסמוכים ימכרו לנו את תוצרתם בכסף או בהקפה, – עלינו לקבל באהבה ובלי
        מספרים מחכימים כמו אלה:  שלנו עולה רוטל סלק למשל כך וכך, ולקנות בשוק
        נוכל יותר בזול!  – חשבון כזה מכאיב מאד, כי בחשבונות ממין זה ישנה תמיד
        נטיה מדרך החקלאות הישרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשעת הכביסה כשתמרה תולה את הלבנים ורואה בהם טלאים על גבי טלאים, תוקף
        אותה צער גדול.  רגש זה יש לה גם בשעה שהחברים שבים מן העבודה ובגדיהם
        קרועים ובלויים.  מתגנדרים הם בעניותם – והלא העניות רק מדכאה ומשפילה את
        האדם, כשאין לו מוצא ממנה.  ומה שנוגע לקרעי בגדים, הרי זה תלוי הרבה באדם
        עצמו.  הנה שמשון!  בחור כיתר הבחורים ותמיד לבושו נקי ושלם, אף על פי
        שעובד הוא יותר מהאחרים.  קם הוא עם הנץ החמה ועובד עד חשכה.  בשעה שהכל
        כבר התרחצו והחליפו בגדיהם לאחר העבודה, עוד שמשון עודר סביב לעצים ולפרחים
        ואינו מסתלק עד שינקה את הדרכים ועד שיאסוף את שיָרֵי העלים והשרשים
        שהצטברו לפני הצריפים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שמשון מתגאה בחריצותו ובלבו מבטל הוא קצת את החלשים הזריזים פחות ממנו
        בעבודה, אבל "לבא לפומא לא גלי".  גם באספות אינו מדבר ואינו מתוכח, איזה
        גחוך ערפלי עובר על פניו בקלטו את שטף הדבורים.  כשהוא מרגיש בדברים
        היוצאים ממעמקי לבו של אדם, מתעורר בו הרצון להגיד גם הוא מה שבלבו, אבל
        מיד מתחרט הוא – למה כל הדבורים הללו?  נעבוד יותר, אל נבזבז זמן וכחות
        חנם, אז ממילא תהיינה פחות סבות להתאונן.  כשהוא מביע את זה בחשאי, בשבתו
        על יד תמרה, היא מתוכחת אתו אחרי-כן בחצר:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– עבודה, – אומרת היא – זהו כמובן הפולחן שלנו, הדת שלנו, אבל הכל לפי
        הכחות.  הלא לנוער שלנו אין עבר של עבודה גופנית והכל חדש בשבילו, הגב
        הכפוף עוד לא חָזַק, כדי לשאת בנקל את משא החקלאות.  מחויבים אנו לחפש
        דרכים, איך להקל את המשא ולהתרגל בו, ויחד עם זה – ליצור אותה מדת הקיום,
        שתאפשר את החיים כאן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הניחי, תמרה, את הפלפולים!  – אומר לה שמשון – מעולם לא עזרה לנו
        ההתפלספות בעבודה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אבל המדע והנסיון, הנמסרים לנו על-ידי הספר ובעל-פה, הלא מקילים על האדם
        בכל מקצועות החיים, ולכן צריכים תמיד לבקש בהם פתרונים לשאלותינו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא ברור לי עוד מהו ערך המדע בחיי יום-יום, אבל דבר אחד ברור:  שאם
        אשמור על העדור מסביב לעץ, ישגשג העץ ויתפתח; אם אַשְקָה את הצמחים בזמן
        ואמציא להם את הטפול הדרוש, יעשו פרחים.  והחלקה שזרעתיה בזמנה ועבדתיה
        היטב, מוכרחה לתת את פִּרְיָהּ, אבל אם התרשלתי – מאום לא יהיה.  ומה יתן
        ומה יוסיף המדע טרם הסתגלו ידינו לעבודה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אם כן, לפי דעתך, אין אדם צריך לצרף את מחשבתו למעשיו כלל?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא, תמרה, לחשוב צריך אבל לדבר ולהתוכח ולדחות את הפעולה ללא-צורך, זה
        מביא רק הפסד.  יעשה כל אחד כמה שהוא יכול ויתוכח פחות, אז תפתרנה שאלותינו
        מאליהן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אבל אי-אפשר, שמשון, שכל אחד יעשה על דעת עצמו מה שהוא מוצא
        לנחוץ;  באופן כזה תוָצר ערבוביה כזו שלא נצא ממנה.  אצלנו שורר השכל
        הקבוצי, ועל-פי ההסכם הכללי צריכים להיות מכונים כל המעשים, אין דרך
        אחרת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אבל שמעי, תמרה, אילו שאלתי אתכם באספה אם לעדור את העצים ליד הצריפים,
        שכה עזובים היו, בודאי הייתם מתוכחים על זה ולא הייתם מחליטים באספה אחת,
        אלא דוחים לשניה.  בינתים היתה האדמה מתקשה במדה כזו, שאי-אפשר היה עוד
        לגשת לעדורה, לכן עשיתי על דעת עצמי והצלתי את העצים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה העבירה יד רכה על תלתליו הסבוכים ואמרה לו בחבה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– בן עם קשה-עורף אתה, בחורי!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשעזבה אותו, עלה פתאום בדעתה ללכת את האורוה, לחבוש את "זריז" ולצאת
        ברכיבה לטייל.  אמרה ועשתה – והנה כבר רוכבת היא זקופה על הפרד, והמשעול
        מבריק לפניה לאור הירח, השָט מעל לכפר.  לצדה, בעמק, הצטייר הצל הענקי שלה
        ושל הפרד, והוא צועד בזהירות, נפחד לרגעים מפני הענק המשתרע לצדו, נרתע
        לאחור, עומד ותוהה:  מי הוא זה, הזוחל במורד על הגפנים, על חלקות המספוא
        ומגיע עד הנחל – מאין בא הלזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא עברה את המשעול עד הכביש וחזרה ושבה עוד פעם. מן הכפר הערבי לימין
        נשמעה נביחת כלבים, ומשמאל, מן הכרמים, מיללים שועלים רעבים וזריז כולו
        קֶשֶב.  לובן הבתים שבכפר הצטייר באופן מיוחד, והבתים הן תמיד מעוררים בה
        קנאה:  לו היה להם לפחות בית אחד, בנוי בשביל החולים, בשביל אלה שמפני הקור
        אשר בצריפים מחויבים הם לעזוב בימי החורף את הקבוצה ולבקש להם מפלט במקום
        יותר חם!  בכל חורף יש להם הפסד בבריאות האנשים וגרעון בעבודה.  אשתקד
        התוכחו הרבה על תכנית בית. הביאו מומחים, נסעו ונהלו משא-ומתן עם כל</span
      ><span class="c2"> הגדולים</span
      ><span class="c1"
        > ומאומה לא יצא מזה.  התכנית מונחה לה מקופלת באיזה מקום והאנשים מוסיפים
        לסבול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשובה מן הטיול ראו אותה החברים והתלוצצו על חשבונה ועל חשבון הנהגתה
        המוזרה – לטייל על "זריז" בשעה זו.  היא לא שמה לב, רכבה עד האורוה, ירדה
        שם, פרקה את האוכף והכניסה את "זריז" פנימה לקשרהו אל אבוסו ובדפקה על גבו
        בחבה ותודה נפרדה ממנו והלכה לה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבוקר הודיע לה סדרן העבודה, שהיא צריכה לצאת עם ירדנית אל השדה לעדור את
        סלק-הבהמות.  זו היתה בשורה טובה בשבילה, כי מאד חבבה את העדור.  היא לקחה
        בשבילה ובשביל ירדנית אוכל והן הלכו מן הבית ליום תמים, כי רחוקה הדרך לשוב
        לצהרים הביתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הן עברו על יד הנחל לכל ארכו והתבוננו לתוכו, – רצפה חלקה על שכבת-סלע,
        שרוחצה היטב, יורדת במדרגה אל שכבה אחרת, שגם היא רחוצה ומשופשפה על-ידי מי
        הגשמים, ששטפו כאן בחורף.  בתחתית הנחל משתטחת שכבת חלוקי אבנים, שנשטפו
        כאן בחורף. בתחתית הנחל משתטחת שכבת חלוקי אבנים קטנות מצוחצחות עד היות
        להן לובן צח.  על החוף צומח יער שלם של גדולי-פרא, והנחל מתפשט הלאה ושוב,
        מתכוץ בין קירות אבן.  למעלה, הרחק מן הנחל משתרע שדה הסלק לבהמות, כאן הוא
        מקום עבודתן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במרץ ובזריזות נגשו שתי החברות לעדור.  מתוך צחוק התחילו להתחרות – מי מהן
        תגמור יותר מהר את השורה?  פעם נצחה תמרה ופעם ירדנית.  בינתים נוכחו שזה
        מעיף אותן יותר מדי וההתחרות חדלה והעבודה התנהלה בקצב רגיל, ובכל-זאת עיפו
        כראוי בחום היום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הגיעה שעת הצהרים והן הניחו את כלי-עבודתן.  ירדנית טפלה בהוצאת האוכל מן
        הסל ותמרה הספיקה להתבונן מסביב ותאמר לחברתה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הביטי, ירדנית, כמה יפות כל החלקות המעובדות על ההרים – איזו עבודה יפה
        הוכנסה כאן.  האם ידים לא-חרוצות היו יכולות ליצור שפע כזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הן ישבו בתוך הסבך לאכול ולנוח. מתוך שכיבה שוחחו על ענינים שונים, עד
        שהתגלגלה השיחה על ספרים שקראו בזמן האחרון.  ירדנית קראה את "תֲּאִיס", –
        והספר נראה לה מתאים כל כך לסביבה זו עם מנזריו לנשים לחוד ולגברים
        לחוד. וכשהלכו אחרי-כן בשבת לבקר את המנזר, ראתה ב"אבא" פֶטֶר דמות מוּכרה
        ולא גרעה עין מתנועותיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ירדנית קראה בזמן האחרון, זה אחרי זה, – את ברנר ואת ברקוביץ, שהפליאו
        אותה ביחסיהם השונים לסוג נשים אחד.  – שניהם לקחו את טפוסיהם מעולם העניות
        והדחקות של עירה קטנה, נשים העָמֵלות ודואגות למחית משפחתם וחותרות בשארית
        כחותיהן בים של צרות למצוא טרף לפי הטף.  יש שהאשה מפרנסת גם את בעלה, כדי
        שתהיה לו השהות לעסוק בתורה היקרה מכל הון.  סביבה אחת – אבל איזה תהום
        מבדיל בין סוג נשים אחד למשנהו.  – אצל ברנר הנשים רעות הן ורעות במדה כזו,
        שאֵם צעירה מחניקה את תינוקה בצר לנפשה.  האם זה נכון?  האם תמָצא אכזריות
        כזו באם עבריה, אשר אין כמותה למסירות בגדול בניה?  ולעומת דעה מוזרה זו
        ביחס לאֵם ואשה אנו פוגשים אצל ברקוביץ נשים טובות ואמהות מצוינות, אפילו
        כשהן לפעמים רגזניות או פטפטניות. נפלאה האֵם הפשוטה בציורו "נכד" – לסליחה
        ולרחמים אין כאן גבול.  בציוריו "תלוש", "ממרחקים", "פרא אדם" – נותן לנו
        ברקוביץ נשים, הסובלות בחשאי את מצוקת חייהן, מבלי לקבול על גורלן
        המר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החברות שבו לעבודה.  שעות אחדות עברו עליהן שוב בעדור בלתי פוסק, שורה
        אחרי שורה, והנה רוב החלקה כבר עדורה והעלים האדמדמים-ירוקים עם גבעוליהם
        הורודים הרימו ראשיהם בגאוה ובשמחה, וכשהתחילה החמה לנטות אל מעבר להר
        המערבי, הרימו גם העודרות את ראשיהן. מנגד עוד חרשו החברים בכרם, והחלק
        החרוש היה כל כך נהדר במראהו, שהנערות לא יכלו להסיר מבטן ממנו.  תמר מדדה
        בעיניה את השטח הגדול שחרשו שני הסוסים למול החלקה הקטנה שעדרו הן וחיוך על
        שפתיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– מה את מחייכת, תמרה?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אם נדַמה את מה שהספיקו חמדן וזריז לזה שהספקנו אנחנו – עלינו לתת יתרון
        לבהמה על האדם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אתְ מרחיקה ללכת מן המלך החכם! – אמרה ירדנית, ועל פניה העצב
        התמידי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הן הולכות הביתה ומעדריהן על שכמן.  נושא שיחתן בדרך היתה ההרצאה שהרצה
        בקבוצה הסופר והעסקן הצעיר – מולכי.  תמרה היתה אז בעיר וירדנית מספרת
        בהתעוררות: –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– צעיר הוא ויפה!  ובשעה שהוא מדבר נדמה לך, כי מכה הוא בפטיש על גבי
        הסדן.  הכל הוא מעביר תחת שבט הבקורת, – את המושבות ואת  הקבוצות, את העליה
        והישיבה, את התנועה ואת הקפאון.  ואחרי ההרצאה חלמתי על-אודותיו:  הנה הוא
        עומד ומכה בפטיש ממש וניצוצות עפים מתחת פטישו ואש נפוצה מסביב, – קודם
        כחולה ואחרי-כן אדומה עם זֵר כחול מסביב!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– איזו בעלת הזיה אתְ!  – אומרת לה תמרה - את מתארת אותו כאילו התאהבת
        בו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– הה, באדם כזה אפשר להתאהב!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– גם את, ירדנית?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– כן גם אני, התמה, הצנועה, כמו שאתם קוראים לי.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הן עלו על הגבעה שעליה נטויה הדרך ועיניהן ראו את הילדים אשר שבו עם הגננת
        מטיול וענפי אורז משמשים להם דגלים. המראה היה נפלא, ושתי החברות הטו את
        שיחתן אל הטפול בתיקונות, שתמרה עסקה בו עד שבאה הגננת החדשה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כמה אני שמחה – אמרה תמרה, – שבאה אלינו סוף-סוף גננת ומסרנו את
        פעוטותינו בידה.  בששת החדשים שטפלתי בהם נוכחתי לדעת, כמה רציני הוא מקצוע
        זה, ולא נכון כלל היה למסור אותו לידים בלתי אמונות.  אני הרגשתי את עצמי
        בלתי-מוכשרה, ובכל-זאת טפלתי ששה חדשים בפרחים רכים אלה, שיד בלתי מנוסה לא
        צריכה לנגוע בהם.  דרושה הכנה כדי לדעת לגדל דור צעיר, והנה חנוך הבנות
        אינו נותן לנערה שום מושג מדבר זה החשוב בשביל עתידה.  בכל שבע החכמות
        מפטמים אותנו, רק דבר זה, החשוב כל-כך, חסר תמיד מן הספר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– האם רק מקצוע זה דורש הכנה?  – אומרת ירדנית – הלא כל החיים דורשים הכנה
        קודמת, ורע ומר לאדם שנגש לחיים בלי הכנה.  כל אי-הצלחה וכל צרה באה בעקב
        אי-הידיעה וביחוד סובלת מזה חקלאותנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה שנוגע לקבוצתנו – אומרת תמרה – הלא אנחנו "כֵּן" קבלנו איזו הכנה
        בחקלאות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הוי תמרה, איזה ערך יש כאן לידיעות הקלושות שהבאנו אתנו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שיחתן נפסקה לרגלי מראה הטבע אשר נגד עיניהן:  – מעל ההרים כבר הסתתרו
        עקבות הזהב, רק בפנה אחת, בין שני הרים, תלויה עננה בהירה, כולה עטורה עטרה
        של זהב, עטופה מסביב צעיף צחור, שקוף.  ובתוך כל צבעי הערב הרכים התערבו
        הצבע הירוק של כרי המספוא ואדמימותן של החלקות החרושות לזריעות הקיץ.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה שביב עמדה מוקסמה, לבה נמלא אהבה אין קץ, וצר היה בו המקום מהכיל את
        כל הרגשות הנפלאים, שתקפו אותה בשעת שקיעה זו.  עוד האורות מתהלכים בגאוה
        על ראשי ההרים, פושטים צורה ולובשים צורה, עומדים בסקרנותם ומחכים עד שתגיע
        השעה וההר החצוף יבלע בתוכו את המלכה כולה בכל מלוא עגולה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרועים השכנים מאספים את עדריהם להובילם הכפרה;  העזים עולות בהר עדר עדר
        לבדו, זה עוד מואר בקוי שמש האחרונים וזה כבר נוהר בצל.  עיפים הם אחרי
        יום-הנדודים, מטפסים על הסלעים, מתחככים ודוחפים זה את זה, כל לבם אל הדיר,
        אל המנוחה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם שתי החברות שואפות למקצת מנוחה ומתקרבות אל החצר;  הילדים רצים
        לקראתן;  הידים הקטנות לופתות את ברכיהן ואינן נותנות להן לזוז. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– עליזה, יריב, ציון – חביבים שלנו, הניחו לנו להכנס הביתה, הלא עיפות
        אנחנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל תחנוניהן הם ללא הועיל. הקטנים יותר חזקים, הגדולים מחויבים תמיד
        להכנע ולצאת אתם במחול, אם כה חפצם של השובבים הקטנים.  הם הם עמוד החיים,
        יסוד ההויה!</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c4 text-center" dir="rtl"><span class="c5">ב</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הרוצה אתה ללכת אל הזית? שואלת תמרה את קרובה, שבא אליה לשַׁבָּת,
        וכשהלה הסכים, ירדו שניהם בשביל צר המוביל אל הכרמים וישבו בצל הזית
        העבות.  קרובה הזכיר את חטאו, כי בלכתו לקבוצה לא חשב כלל, שיש להם עצים
        נפלאים כאלה לנוח בצלם.  הוא ידע, שהנטיעות אצלם צעירות הן, והעצים העתיקים
        שנמצאו ברשותם, הוא שמע, שבחורף גזמו אותם ולמען השיב להם את עלומיהם –
        קצצו בהם על ימין ועל שמאל. לא פלל אפוא שעוד נשארו עצים עבותים
        כאלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הם ישבו סמוכים לגזע העבה, דברו על ענינים משפחתיים שונים אשר עוררו בה
        תוגה.  האם חולה, חולה מיום עזבה אותה, ואינה יכולה להתעודד ולשוב
        לאיתנה.  כמה היא חטאה כלפי האם!  האם לא הביעה כל התנגדות לנסיעתה, אבל
        כמה סבל הביעו הפנים!  קרובה מספר על הבית ועל האנשים וכל תאור – פצע נוסף
        ללבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והנה חדל לספר והוא חוקר אותה על מצב הקבוצה, מביט בפניה הילדותיים במקצת
        ומתפלא על הרצינות בהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– קשה הוא המצב – עונה היא על שאלתו – ואף-על-פי שרכשנו לנו את שוק החלב
        בכל חלקי העיר, עוד מוכרחים אנו לבקש קמוצים, והיכן הקמוצים?  ברפת הלא
        אי-אפשר, – מפנוקן של פרותינו לא נחסיר כלום, להן נותנים את המזון הטוב
        ביותר ככל האפשר, והן משיבות לנו מרוב טובן וחלבן.  איננו ממציאים להן רק
        את "קערת השמים", חוץ מזה – הכל.  הקמוץ הוא רק בגבול ההולכים על שתים, –
        זוהי בריה, הזקוקה יותר לספוק המחשבה, ספוק הרוח, אבל הגוף – במה נחשב
        הוא?  ומה התוצאה?  חולים וחלשים.  אבל אין דבר, אין דבר!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רוח מן הים נשבה על שני היושבים בצל הזית ולחשה סודות.  תמרה הוסיפה
        להשׂיח את לבה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הנה ההרים הגבוהים סוגרים את העולם כולו בפני היושבים כאן.  זהו עולם
        קטן בפני עצמו.  – עמק ירוק, צריפים, איזה אהלים, ובתוכם הולמים וחולמים
        לבבות צעירים...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא הדקה ראשה אל הגזע, או יותר נכון אל שני גזעים יחד, שאחזו ודבקו זה
        בזה בדרך פלא.  לפני שני היושבים משתטח הצל, כעין אמפיתיאטרון בזעיר אנפין,
        שבמרכז בָמָתו עורכים הענפים משחק באורות וצללים דלולים.  התכופף ענף כבד,
        הקשיב לרוח והתחיל להלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מן הזית הובילה תמרה את אורחה אל הכרמים.  והיא מספרת לו איך היו הגפנים
        בחורף, איזו צורה היתה להן אחרי הזמירה, גם בארה לו את תורת הזמירה והתועלת
        אשר בה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– עתה – אומרת היא – הנה הגפנים לבושות סות זו הירוקה והיפה, והנה הוא
        רואה איך הן עומדות זקופות וגאות על פִרְיָן הרב, שהן נושאות בין
        דליותיהן?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא הובילה אותו לגפן אחת, שאורך אשכולותיה היה כחצי מֶטֶר ואמרה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הרואה הוא אשכול זה?  עליו נאמר "וישאוהו במוט בשנים".  הפרי עדיין
        בוסר, אבל התוצאה מחזקת בנו את התקוה, שהגפן תשמש לנו אחד הענפים, שעליו
        יתבסס המשק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">האורח התפלא ואמר:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– במושבה אחת שבקרתי באלה הימים, שמעתי דעה אחרת על הגפן, שם התיאשו ממנה
        לגמרי.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כן, – אמרה תמרה – שם נטוע פרי-חמד זה לשם תעשית יין,  לשַכֵּר בו את
        העולם – מובן שאין זאת תעודתנו, תעודת התחיה, ובמשך השנים בא אצלם
        המשבר. כאן, אצלנו, מטפלים בענבי אכילה בלבד ולגפן ערך אחר לגמרי.  אפשר
        לחשבה לאחד מענפי המשק החשובים ביותר.  מינים אלה צריכים היו לנטוע מתחילה,
        אבל זוהי, לדאבוננו, לא השגיאה הראשונה במשך ארבעים שנות הנסיונות השונים
        שאנו מתנסים בהם בלי הרף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא עלתה עם אורחה עד ראש סלע איתן, הנשקף בהדר גאונו על כל הסביבה וישבו
        על פלח סלע עתיק זה, אשר פרץ לוע הר-געש שבר אותו לפנים, זרק ממנו חלקים
        לכל עברי ההר, הפכם ותקעם בצד ובכתף. רק שלשה חלקים גדולים נשארו תלויים
        באורח פלא.  עֵד הוא סלע איתן זה למהפכה שהתהותה כאן – ערמות ערמות של
        אבנים שרופות הצטברו עליו, פלחי-סלע מוטלים נהפכים או תקועים בתוך ההר
        ועומדים על צדם.  ממש מהפכת סדום!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הם ישבו ונדהמים היו למראה הקדושה והטהרה שמסביב.  על ראשם צחקו השמים
        התכולים עם ענניהם הצחורים והזכים.  על היריעה אין-הסופית הצטירו ציורים
        נפלאים:  הנה שם כרוב צחור-תלתלים שט בפרשו את כנפיו, שם שתי תינוקות
        אוחזות זו בזו, דבקו אשה אל אחותה ושפתותיהן נמוגו בנשיקה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >על אחד ההרים טפסו אט צללים, עלו מעל רגלי ההר, הגיעו עד ראשו, שקעו
        ונעלמו.  וזחילתם בעלותם היתה בחשיבות כל-כך כאילו נשלחו לעולמות סתרים
        לדלות משם את סודות היקום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמהון רב העירו חלקות-אדמה קטנות ככף איש, שבעליהן עִבדו אותן בין הסלעים
        וכבר אוספים שם תבואה.  ערביה זקנה תלשה במהירות כמכונה, ואחת, צעירה אספה
        את העמרים לערמות, שצבע זהב להן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– איך התחכמו בני-האדם להוציא לחם מבין שממות צורים אלה?  – שואל
        האורח.  ותמרה עונה לו:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– עם-הארץ מוכשר לכל, – הוא חזק מצור.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה רואים שם?  – שואל האורח, שלא הכיר מגובה ההר את מקום הקבוצה. ותמרה
        ענתה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הלא שם נקודתנו.  – הצריפים משמאל, הרפת מימין, באמצע נראות הכַוָרות,
        ושם הלאה הכרמים, ואחריהם יער-הזיתים, שעוד לא כולו לנו הוא!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– ומה שם, רחוק?  הבנינים הגדולים ההם?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– שם מתנוססים שלשת המנזרים על שלש גבעות שונות – עונה לו תמרה, ובלבה כבר
        מתעוררת השאלה, אם הלכו שמה בני-אדם לטהר את חייהם או לקבר אותם?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחזרם מהטיול בקרו את בית-הקברות, – שני קברים בודדים, גדול וקטן, ותמרה
        ספרה לו על הקטן:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כאן קבור חבר קטן של קבוצתנו. ילד רך, רפה ויפה היה הפעוט.  על מותו
        התאבלנו כולנו במשך זמן רב וקשה היה לנו לראות את סבל הוריו.  יום יום עמדה
        האם על-יד העריסה, שנשארה מסודרה עם כל צעצועיו ותמונת הילד המת
        באמצע.  היא אינה נותנת עד היום להוציא את העריסה הזאת מן החדר ומעַנָה את
        נפשה על ידה. האב המסכן מתחזק בפניה ומשמיע גם הלצות, אבל כשהיא מסתלקת
        לעבודתה, הוא נכנס בלאט לחדר של חבר-אב, עומד שם על יד עריסת ילד ישן ונועץ
        בו עינים כמֵהוֹת ודומעות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יום של כביסה, ותמרה מתעכבת בדרך על-יד השער באין סבלנות, – היא צריכה
        ללוות את האורח והעגלה שוהה אצל המחסן לשם טעינת משא.  האורח עוד מטייל כאן
        ומסתכל, הכל חדש בשבילו:  – הנה שם מסתובבים הפרדים במעגל ומניעים את
        המַקְצֵצָה, הפרות מתהלכות בחצר הרפת, מקשקשות בזנבותיהן, כאילו מטריד אותן
        דבר-מה;  השמש המאירה את כל החצר מבהיקה על גביהן ודומה כאילו הפרות עלו זה
        עתה מן הרחצה.  עדת יונים התאספה על הגג האדום של הרפת לתפלת הבוקר, ואחרי
        שהות מועטה התחילה מתיעצת בחשיבות על סדר היום, – לאן תעוף ראשונה לתור לה
        מזון?  התרנגלות מקרקרות בלוליהן ומכריזות בקולי קולות על רצונן להביא
        לאויר העולם את פרי בטנן המציק להן.  גם התרנגולים משתתפים בכרוז, – גם להם
        חלק ונחלה בממשלה הזאת.  למטה מסתובבת השַפְשֶפֶת בראש סוכת הגן, את קולה
        החד-גוני היא משמיעה בלי הרף  יומם ולילה, הגלגל סובב לפי הרוח, וכשמתגבר
        הרוח – נדמה שהשפשפת אומרת להתפרץ ממעגל הקסמים שלה, לעוף למרחקים, אבל
        אינה יכולה להנתק ממקומה והיא מסתובבת בזעף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה מתפקעת מחוסר סבלנות, – כל החברים זה כבר אצו רצו לעבודה.  התפזרו
        בשדה ובכרמים, והיא עומדת כאן ומתבטלת.  אבל הנה נגשה סוף-סוף העגלה, קרובה
        ישב בה והעגלה זזה.  אז שאפה רוח כאילו ירד ממנה משא כבד ותמהר
        למכבסה.  כאן עוד לא הפרידו את הכביסה והיא מהרה לעשות זאת ולטבול את
        הלבנים במים קרים.  זוהי שיטתה, אף כי החברות מתנגדות לה.  בלי וכוחים
        יתרים שטפה וסחטה את הכבסים ושמה אותם בגיגית שניה בשביל חברתה שתסבנם
        עכשיו בחמים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >- רואה את, שולמית, כמה מלוכלכים המים הללו, ששטפתי בהם?  ירד הלכלוך
        הראשון והלבנים יהיו הרבה  יותר נקיים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא יצאה להביא חמים ולהוסיף עצים תחת הדוד.  ביער הברושים הסמוך פתחה
        צפור עזת-מצח בקול גדול את צוחתה, הביעה איזו התמרמרות צעקנית ונשתתקה, אבל
        אחריה התחילו קולות דקים מצפצפים ומהגים תלונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה הוסיפה דלי מים לחברתה, שעמדה כפופה וכבסה  בזריזות.  הביאה גם לה
        מים, שפכתם לתוך הגיגית שלה ותגש גם היא לכביסה.  שולמית נאנחה ותמרה העיפה
        עליה עין וחשבה:  – מה היה לה, כי כה נפלו פניה בזמן האחרון?  עצבות,
        בחילה-בחיים בעינים – איזה סוד כמוס מתהוה בחייה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לעת הצהרים התחילה שמש לחדור לתוך חלונות המכבסה ולהכות על ראשי
        הכובסות.  החלונות אינם נפתחים במכבסה, תריסים אַיִן, והשמש הלוהטת ממערב
        מצליפה על הנערות בלי רחמים.  ביחוד גוברים הענויים אחרי הצהרים, שאז נהפכת
        המכבסה לגיהנום ממש.  החברות מתרגזות ורוטנות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מדוע אין להוציא אצלנו אל הפועל שום תקון?  – שואלת שולמית – הלא זה כבר
        הבטיחו למלאות את דרישתנו ולהוציא איזו שמשות למען יכנס האויר לפחות.  או
        לו עשו אשנבים, או אפילו סְכָכָה לפני החלונות, להגן מעט מפני
        השמש!  מבטיחים ואינם מוציאים אל הפועל.  לא איכפת לחברים, שהן סובלות
        ענויים אלה בשעת הכביסה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הבטיח לי שמשון, שהוא יקח את הדבר עליו – אומרת תמרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– עוד לא קיץ אחד יעבור עד שהמכבסה תתוקן!  – אומרת שולמית – ורק זה?  הרי
        על כל צעד ושעל שולטת אצלנו הרשלנות.  הנה הפרוזדור שלנו, - הריהו למטה מכל
        בקורת. הרצפה שבורה, בולטות מתוכה אבנים גדולות, בהן נכשלה פנינה וחלתה
        שבועים – היא לא ספרה לקבוצה כיצד ניזוקה. נכשלים גם אחרים באבנים הללו
        ושותקים. לנקות רצפה זו אי-אפשר;  בחורף היה הפרוזדור מלא בוץ, גם בקיץ
        אי-אפשר לנקותו;  מתהוה רטיבות, ריח קשה עולה מן הרטיבות, פשוט מחניק
        לפעמים.  בתוך החדרים החברות רוחצות, מנקות, מצחצחות, – ובפרוזדור החברים
        תולים בגדים ישנים,  זורקים נעלים  ישנות, והאויר הרע נשא לחדרים פנימה, –
        מהי התועלת שבעמל החברות לשמור על הנקיון?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ישנו גורם אחד, המפריע לנו בעד תקון הרצפה שבפרוזדור – לומדת תמרה זכות
        על התקלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כלום איני יודעת?  אבל זה לא צריך בשום אופן לשמש עכוב בעד התקון, שיכול
        היה להעשות בשתי עגלות חצץ אשר יפזרו על כל הפרוזדור, יזרקו עליו מים
        ויהדקוהו היטב.  ואם עוד נוציא משם לאיזה מחסן את הבגדים  והנעלים הישנים,
        אפשר היה לשאוף אויר צח גם בפרוזדור.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אין לנו מחסנים כאלה מן המוכן, והחברים כשמחליפים את בגדי-העבודה, אינם
        יכולים לרוץ ולהסתירם באיזה מקום, – הלא מחר יצטרכו ללבוש אותם שוב.  גם את
        אלה שלא ילבשו מחר, אי-אפשר לזרוק החוצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא, תמרה, ככה אי-אפשר.  החיים צריכים להיות נקיים בפנים ונקיים גם
        מחוץ, ודברים שהם נגד הבריאות, בודאי שצריך להרחיקם עד כמה שאפשר.  האם
        תוכלי להכחיש, שיש אצלנו רשלנות?  על התקון הכי קל, כמו ארון-הכלים, למשל,
        צריך לדבר השכם ודבר, וכל זה ללא תוצאה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– האם החברים אשמים בזה?  – לומדת שוב תמרה זכות עליהם – האם הם צריכים
        לדאוג לדברים קלי-ערך כאלה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– בודאי הם, – כי עבודת התקון וכל ההנהלה בידיהם היא.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– והרי בזה האשמה בנו, כי אין אנחנו נכנסות לתוך עניני המשק ואין לנו יחס
        חיובי להנהלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אין יחס, מפני שאין אנו נבחרות אל ההנהלה, אבל לותר על שגיאות לא נוכל,
        ביחוד שגיאות מתוך רשלנות, ובפרט במקום שישנן ידים צעירות וחרוצות, שיכלו
        להמנע מהן.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אין משק שאין בו שגיאות, שולמית.  רק במקום שאין פעולה אין
        שגיאות.  מלבד זה, הלא בקבוצה קשה יותר להכניס תקונים מאשר במשק פרטי.  שם,
        אם בעל המשק חרוץ, הוא נגש ועושה על דעת עצמו מה שנחוץ לו.  כאן בקבוצה, כל
        דבר קל ערך דורש אשור מכל החברים – בזה הקושי, כי לא הכל מעונינים במדה שוה
        בתקון פלוני או אלמוני, ולא כל אחד יכול לתפוס את הדבר במדה שתופס אותו
        החבר בעל ההצעה, שחדר לתוכה ומתענין בה בכל נפשו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– זוהי כל הצרה, תמרה, שאנחנו מתוכחים על-כך הרבה, עד שהענין הנדון מסתבך,
        עוטה ערפל, מעמידים את ההצעה למנין והיא נופלת, אף על פי שהדבר נחוץ כאויר
        לנשימה.  מה יוצא?  המשק סובל, הרבה דברים עומדים בחורבנם ואין להם
        תקנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        > תמרה לא ענתה יותר, כי תקף אותה כאב ראש חזק ובקושי גמרה את העבודה
        במכבסה.  הסתלקה לחדרה, נפלה על מטתה, הדקה את ראשה במטלית ורעדה
        מכאב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">היה חושך בחדרה, והנה דפק על הדלת נחמיה ונכנס.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– את כאן, תמרה?  לא אכלת הערב, – מה אתך?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא בקשה אותו להדליק מנורה ולהגיש לה מים קרים בקערה.  הוא עשה זאת בלי
        חריצות יתרה.  בכל-זאת היה לה לעזרה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– הרוצה את שאקריא לך, תמרה.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– לא, נחמיה, אינני יכולה, עליך לעזוב אותי תיכף.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפקודה זו לא נשמע נחמיה.  הוא ישב על מטתה, גפף ראשה והתחיל לטפל
        בשערותיה ולהוציא מתוכן את הסכות, עד שהשערות התפזרו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה אתה עושה?  עזוב אותי, נער רע!  – היא כסתה את ראשה בבגדה, שהיה מוטל
        על השכם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נחמיה ישב אל השלחן, פתח אחד הספרים המונחים שם ושקע בקריאה. ותמרה ראשה
        מתפקע ודבר ישיבתו העקשנית של נחמיה מרגיז את עצביה, – מדוע נער זה דבק בה
        וגוזל את מנוחתה? יום יום היא חושבת לשים קץ ללמודיהם, להפסיק את הקריאה
        המשותפת שהיא רק מגרה את עצביהם ולהרחיקו ממנה לגמרי. וכאן רחמים מתעוררים
        בלבה:  הוא נער חלש, רגשני;  אינו ישן היטב, כי רעבונו לאהבה מציק לו, והוא
        נעשה יותר ויותר רזה וחִוֵר.  מה לעשות לנער?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הלב דואב לראותו בסבלו ודרך לעזרה אין.  הוא שואף לבלות איזה זמן בעיר,
        אבל לזה דרוש כסף ותלבושת הגונה, ובקבוצה אין לא כסף ולא בגדים יפים.  מה
        לעשות? כיצד לעזור לו?  וכאן היא נזכרת בסכת-הזהב, החבויה בין חפציה,
        ומחשבה נצנצה בה: – כשתסע העירה לרופאת-השנים, תקח אתה את הסִכה הזאת,
        תמכור אותה לצורף ותתן את הכסף לנחמיה, – אולי יוקל לו כשיסע מעט.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מפני נסיעתה של הגננת בקשו מאת תמרה לקחת עליה את הטפול בתינוקות למשך
        יומים.  הקטנטוים הם בסוכת הגן ותמרה נהנית בראותה כיצד הפעוטות מטפלים זה
        בזה.  בֶּבֶּלה הגדול משגיח על רעו הקטן מלאכי;  מתהלך בבלה בגאוה, נגש לכל
        הפנות, מסתכל בעצים, בפרחים, בגדר הגן; מקשיב להלמות הפטיש בבית-המלאכה,
        וכשחברו מתחיל לבכות, הוא נגש אליו ומשתיק אותו בלטופים.  מלאכי זוחל,
        מתרחק קצת, מפנה ראשו לאחור, לידידו, מצטחק אליו ומבטו כמו אומר:  הרי גם
        אני יכול להגיע כמוך למקום שאני רוצה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פתאום מתהפך מלאכי, מתגלגל על המחצלת ופורץ בבכי.  כשנגשה אליו תמרה, פרש
        הקטן את כפיו אליה מתוך שכיבה וכאילו בקש רחמים.  תמרה הרימה אותו, חבקתהו
        ונשקה לו בצוארו כשהוא צוחק צחוק עליז.  התקנא כאן בבלה, נגש אליה ופשט גם
        הוא את צוארו אליה לנשיקה.  ומלאכי אזר גבורה, זחל עד סבכת הסוכה, אחז בה
        ועמד.  כאן הוא רואה פרחים, קוטף אחד מהם, נוטלו אל פיו וזורקו, – הטעם לא
        נעם לו.  הוא זז הלאה ומצא חבל קשור אל עמוד, תפסו בידו והניעו, כמו
        שמניעים מושכות הסוס, והוציא גם איזה הברות מתוך פיו לזרז את בהמתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והנה בא יריב, זהו כבר רוכב אמתי. בידו השמאלית הוא אוחז מוט הנתון בין
        רגליו.  הקצה האחד בידו, והשני נגרר על הארץ.  בימינו מקל רך – הוא השוט –
        ומצליף הוא על סוסו:  – דִיוֹ, דִיוֹ!  שני הקטנים מביטים בתמיהה וקנאה אל
        הגבור הנכנס למחיצתם ובידו כל טוב...  בבלה נהר מיד אחרי השוט ואמר להוציאו
        מידי הרוכב.  החלה מלחמה, שנגמרה במנוסת הגבור מתוך המערכה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הופיעה הגדולה שבחבורה, עליזה, והביאה צעצועים לפעוטות.  היא לקחה את העז
        האדומה, הקריבתה אל אפו של בבלה והפחידה אותו:  "הנה דוב, הנה דוב!"  אבל
        תמרה גערה בה על עשותה כדבר הזה והילדה הסתלקה נעלבת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה ממשיכה את תקון הלבנים של החברים ומעיפה עין גם על הקטנים שלה. יש
        להתפלא על יחסם של שני הננסים האלה זה לזה, כשהם טווים את חייהם
        המשותפים.  מלאכי משתדל לחקות בכֹּל את חברו.  הנה מצאו קשוא על
        הארץ;  בבלה מגלגל מאותו לצד אחד ומלאכי לצד אחר. זוחלת חפושית על גבי
        המחצלת, – בבלה רוצה לתפסה, וגם מלאכי פורש כפיו הקטנות כאומר לצוד את
        הזחלן השחור. הביאה להם ספל חלב.  "הַם, הַם!"  משמיע אחד הפעוטות, והשני
        גם הוא מרים פניו ופושט שפתים לשתיה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה היתה מטפלת בילדים עד שעה מאוחרת בערב, וכשנכנסה לחדר-האכילה היה
        כמעט ריק מאדם, רק האחראים לעבודה עוד שוחחו ביניהם על סדור הירקות, שצריך
        להכין בארגזים כדי לשלחם העירה, על התירס העומד בָּשֵל בשדה ועל הענבים
        הקרובים להתבשל, שצריכים לדאוג לחוזה עם "המשביר", כי יקח עליו את
        המכירה.  דברו גם על סדור מחסן למספוא, שקשה להם להחליט בדבר קביעת
        מקומו.  זה זמן רב, שהם מכניסים בסדר היום של האספות את השאלה הזאת ועדיין
        לא נמצא לה הפתרון הרצוי.  היתה הצעה להעמידו בתוך חצר הרפת, והיו חברים
        שבאו בטענות על הצעה כזו, – הלא אי-אפשר לעשות את חצר הרפת עוד יותר צרה
        ולנצל את הפרות.  יהיה גזל ממש, אם יקחו חלק מהשטח היחידי, שנִתן להן
        לתנועה מעטה.  הלא ארבעה עֲבָרים פנויים הם לכל צדי החצר – ולמה להסיג דוקא
        את גבולן של הנפשות האלמות, אשר למחות באספות אינן יכולות, אף-על-פי שהן
        פרודוקטיביות מאד.  החברים צחקו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והנה נגעו בשאלה עוד יותר חשובה, העומדת אצלם על הפרק – זהו בנין בית, אשר
        כה מרבים להתלבט בתכניתו ואינם יכולים לבוא לידי החלטה מסוימה.  גם
        ה"מִשאָל" שהוכרז לא הביא לשום פתרון, גם חבר המהנדסים והרופאים, שבאו לדון
        בדבר, לא מצאו עצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אולי כל הצרה היא ברבוי המומחים – מתערבת סוף-סוף גם תמרה בשיחה – הרי
        זה מעציב באמת, שהקבוצה אנוסה לצַיֵת בכל למומחה, אף כי הצעתו מתנגדת לגמרי
        לכל האינטרסים שלה.  באמצעינו המעטים יש להתחשב עם פשטות הצורה, עם היכולת
        המועטה, ובעיקר יש להביא בחשבון את התועלת לבריאות – כל יתר הדברים הם כאין
        וכאפס.  והנה בא לחץ מגבוה – ולמטה טרגיקומדיה של בית באויר, ובינתים נתנו
        בידי הגורל חיי אנשים, בריאותם, עתידם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשנתפזרו החברים ותמרה יצאה החוצה היה האויר כה נפלא, שהיא התפלאה איך
        אנשים שוכבים עתה סגורים בחדריהם.  יצאה אל הדרך ונדמה לה, שאם כל הלילה
        תתהלך כאן בין ההרים, לא יכבד ממנה הדבר.  גדרי אבנים מבהיקות בתוך העמק,
        ונדמה לה, שלא אבנים מפוזרות כאן, אלא פרחים זרועים – עמק הפרחים.  וההר
        הגובל את העמק אינו גבוה כלל, הוא כאילו הצטמצם ונבלע בתוך צלליו
        המרובים.  אור בהיר שכן על כל הסביבה, רק בפנה צפונית-מזרחית נעלם הר בודד
        בתוך הערפל.  כיתום עמד נבדל מחבריו, עמד ורעד מרטיבות האֵדִים, שנסכו עליו
        תוגה. היא הלכה הלוך ושוב, עד שתקפתה עיפות גדולה ורצתה להכנס החדרה, אבל
        כמו יד נעלמה הדפה אותה אחור, – למה לה להכנס, לשכב ולהתענות במטתה?  – שם
        הם שניהם, ברורה וברקאי, מעבר למחיצתה הם מרוים את נפשותיהם מדבש-הנעורים,
        והיא תשמע, תתעטף בשמיכתה, תרעד בכל גופה.  לא!  מוטב לה להשאר
        בחוץ...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא צנחה עיפה על הספסל, סמכה ראשה על גדר האבנים, נשכה את שפתיה והתעותה
        מכאב.  לפניה מהבהבים אורות הרפת, האהלים מלבינים, השומר הרחוק שר בעצב
        וגעגועים רבים, שחדרו לתוך לבה.  ראשה צנח על הספסל מלא הרהורים על אפסותה
        ורשעותה:  – הן ישנם בני-אדם טובים, היודעים לכבוש את יצרם הרע, היודעים
        לעקור את הקנאה מן הלב, לבער את הרע, והיא – כמה רעה היא ביחסה
        לחברים.  אשרם גוזל את מנוחתה – כמה מן השפלות יש בה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אמנם מימי ילדותה היתה קשורה בברקאי ונדמה היה לה תמיד, כי בו קשורים גם
        חייה.  בנסעם לארץ-ישראל ובבחרם יחד עם החברים במקום להתישבות הקבוצה, לא
        חדלה רגע להאמין גם בבנין פנה חמה לה ולאהבתה.  ההבטיח לה מה?  לא, אף פעם
        אחת!  אף כי היו ידידים טובים ואחרים חשבו אותם לזוג, אשר יבוא יום והוא
        יבנה לו קן אצלם.  והנה נספחה אליהם חברה חדשה, חרוצה ופעילה, ומיד הרגישו
        הכל אנה פונה לבו של ברקאי, בחיק מי יניח ראשו ויבחר לו לחברת חייו...  האם
        יש לה להתאונן עליו?  ההבטיח ולא קִיֵם?  במה אשם הוא, אם היא קשורה בו
        באלפי נימים דקים מן הדקים, שאין היא בעצמה יודעת את מקורם?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באספה מצומצמת נמסרה הודעה, שהחברים ברקאי וברורה מתחתנים השבוע וכדאי
        לערוך לכבודם נשף ביום השבת. הדבר נמסר לידי שני מסדרים, ועצם ההודעה
        התפשטה בקבוצה במהירות הברק. ואף כי הידיעה לא הפליאה שום איש, הוחש
        בכל-זאת איזה לחץ באויר – מה יהיה על תמרה?   התעוררה השאלה בלב
        אחדים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ותמרה למן הרגע הראשון עזבה את החצר ותלך הלוך והתרחק מן הקבוצה עד בואה
        למגרש הירקות, שכף רגלה עוד לא דרכה שם בלילה.  רגש הקנאה אכל אותה, וגם
        הבושה מפניו עִנתה את נפשה.  כשהיה מזדמן לה לקרוא על-דבר קנאת גבר או קנאת
        אשה, המרעילה את החיים, היתה אומרת בלבה, שאצלה לא יהא רגש כזה
        לעולם!  ביחוד כאן, בקבוצה, בתוך החיים המתוקנים הללו, אין רגש מכוער זה
        יכול למצוא לו מקום.  כי יחס של חבה שורר בין החברים, סבלנות מצאה לה פה
        קן, והחופש הידידותי לא יתן לחיה רעה זו לנעוץ את צפרניה בלב.  ומה
        עתה?  הנה היא עצמה, תמרה שביב, הבת האידיאליסטית של הקבוצה, אחוזה אש
        הקנאה, האוכלת בה כליות ולב!  היכן העיקרים המוצקים, שעליהם בנתה את חייה
        בבואה לקבוצה? למה בגדה באחד העיקרים של הקבוצה  – וִתוּר?  בלי ותורים אין
        שלמות ביחסים, והיא לא יכלה להתרומם מעל לאהבת עצמה ואינה בת-קבוצה ראויה
        לשם זה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא ישבה על אחת האבנים ורעדה כולה.  עצמה עיניה ונדמה לה, שנהפכה גם היא
        לאבן ולא תוכל עוד לזוז מכאן לעולם. היא הרהרה הרבה במצבה, שקלה את כח סבלה
        ובאה לבסוף לידי החלטה – לעזוב את הקבוצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא נכנסה לחדרה בשעה מאוחרת מאד ובידים רועדות החלה להכין את עצמה
        לדרך.  העבודה רבה לפניה, – את זה יש עוד לכבס, את זה לתקן ואת זה לתפור,
        כדי לצאת לעולם הגדול כבן-אדם.  בלבה יראה מאד את "העולם הגדול", וכל-כך
        רצתה להשאר כאן, בתוך פנה חבויה זו בין ההרים, שהיא עושה בה יום-יום את
        המלאכות החביבות עליה.  חביבות הן כולן;  כשבתקופת הרפתנות עבדה כשלש-עשרה
        שעות ביום, היתה כה גאה, שהנה שותפה היא לענף העיקרי המפרנס אותם, ומה לה
        עיפות ועבודה יתרה, בשעה שהיא מרגישה את החשיבות הרבה שבעמלה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ותקופת העבודה בשדה – מה יפה הנה!  כאן היא לומדת את הטבע ואת הצמחים ובכל
        מה שהיא עושה במעדר, במזמרה או במכשיר אחר, יודעת היא, שהתמחותה במקצוע זה
        תביא בעתיד תועלת לקבוצתה, בהשתלמות שהיא קונה בעבודה, היא נעשית אבר חשוב
        בגוף הקבוצה.  תמיד רצתה לדעת הכל, להבין הכל ולהיות בטוחה שעבודתה תשא פרי
        – ומדוע זה נגזר עליה פתאום לעזוב את קבוצתה ולנוד?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל מנוי וגמור, - היא הולכת מפה! החברים אינם צריכים לדעת מזה דבר.  היא
        תצא לבדה בבוקר השכם, אחרי-כן ישלחו לה את חפציה המוכנים כולם.  היא תסתלק
        לאט, תסגור אחריה את חדרה, ורק אחרי שיחפשו אותה ימצאו את הפתקה, שבה היא
        נפרדת מהם, ויוכחו לדעת את אשר עשתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואריזת חפציה אינה עולה לה בנקל, – דבר-מה מעיק על הלב, על המוח, גם כל
        אבריה נתונים תחת לחץ כבד, וכאילו זרם דם כביר עומד להתפרץ מחזה.  בהמתח
        עצביה, היא מבקשת להשקיטם על-ידי שתית מים קרים כוס אחרי כוס, גם שוטפת את
        פניה ואחרי-כן את ראשה בצוננים ונדמה כי הוקל לה;  אבל אך זכרה שהיא הולכת,
        – מיד התחילו דמעות חונקות אותה והיא זקוקה שוב לשמוש במים קרים להשתיק את
        עצביה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יודעת היא את השטות שהיא עושה בלכתה מכאן, – היכן תמצא חברים כאלה ועבודה
        כזו?  ומן הצער, – האם אפשר להמלט?  האם הוא לא ירדוף אחריה בכל מקום
        בואה?  עיניה אמנם לא תראינה את הזוג הזה, אבל הלב הן ישא את אהבתה לכל אשר
        תפנה.  היא תמוג ביגונה בכל מקום בואה.  ומתי היא הולכת?  בתקופה, שקבוצתם
        התחילה לראות פרי בעמלה! הנה נתרבה החלב;  מתחילים לשלוח העירה גם ענבים,
        דבש ועגבניות.  כאן חג העבודה, חג הבכורים, והיא – אסור לה ליהנות מכל אלה
        ועליה לשבר את חייה וללכת לחפש עבודה, שאינה יודעת היכן תמצאנה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל מי מכריח אותה לצעד מסוכן זה?  הלא עליה רק למשול ברוחה, להבליג על
        כאב-לבה, על יגונה, ולהמשיך את דרכה.  – האם לא תמצא לעולם מרפא
        לרוחה?  האם באמת כל החיים אינם אלא ספוק עצמי בלבד, ובלי זה הם נטולים גם
        את היכולת לעבודה חברתית, שהתחילו רוקמים אותה לפני כמה וכמה שנים?  איך
        תפרד עתה מן הקבוצה, שהיא קשורה בה בכל נימי נפשה?  מה עליה לעשות, מה
        לעשות?...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואת הקבוצה לא עזבה.  רק ביום שברקאי וברורה עלו העירה לקידושין, קראה
        תמרה שביב צום לנפשה.  הרגישה צורך להתרחק מן הבית ובקשה שישלחוה לקצץ
        סירים להסקת התנור.  לשם זה עליה לעלול על אחד ההרים הגבוהים, הסוכך על
        העמק.  לעתים רחוקות מאד עולה מי מן הקבוצה על פסגת ההר הזה;  תמרה סבבה
        אותו, טפסה והגיעה למעלה ולעיניה נגלה עולם חדש:  כפרים, מנזרים, בתי
        אפנדים, וכולם מוקפים חורשות, גנים, ערוגות של ירקות.  שבילים לבנים
        מתפתלים ועולים מרגלי ההרים עד המשכנות הגבוהים האלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >צפונה מן ההר עוברת דרך סלולה מימי הרומאים, והיא מובילה אל כפרים שונים
        עשירי-נטיעות, אשר שמה הולכים בזמן הזה בני יהודה עשירים להתבודד בימי
        חופשתם וליהנות מהדר הטבע ואויר ההרים הצח, להעשיר את יושבי המקומות
        האלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >האם לא כדאי היה להשתדל, שכספנו לא יפול בידי זרים?  – שואלת תמרה את
        נפשה, – הנה אחד האורחים רמז פעם לקבוצה בדבר יסוד מעון-קיץ על אדמתם. "דבר
        זה יכול היה להוסיף לכם הכנסה הגונה – אמר – כי את תוצרת החלב, הדבש
        והפירות הייתם יכולים למכור במחירים טובים פה במקום, ואולי היה זה פתרון
        לשאלת קיומכם הקשה.  נוף הרים זה ראוי לשמש מקום-מרפא לחולי הקדחת, ומכל
        הארץ יבואו הנה להחליף אויר וכח". החברים התנגדו בזלזול לדברי האורח, – הם,
        אנשי עבודה, צריכים להתקיים על העבודה ולא מהכנסות אחרות המשפילות, לפי
        דעתם, את האדם העובד.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מדוע?  – תמה האורח – הלא כל ארצות אירופה הנאורות נוהגות ככה. הכפרים
        שאוירם טוב משמשים מקומות-הבראה לבני הכרכים.  בארצנו, שהקדחת מצויה בה,
        חשובים מאד מקומות בריאים כסביבה זו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה הגיעה למקום אשר משם היה כל המערב פתוח לפניה.  היא קשרה את חבילת
        הסירים העוקצים וטרם רדתה מן ההר העיפה עין על מרחבי המערב.  – נדמה לה,
        שעינה תופסת במרחב ההוא מקומות, שהיא מכירה אותם.  הנה מושבות שלנו, והנה
        כפרים רבים וערים שלהם.  המשקפת, שהזדיינה בה בלכתה להר הרחוק הזה, הראתה
        לה גם את המושבה העברית, שהיא בקרה שם אשתקד את אחת מחברותיה בעבר.  המושבה
        ההיא אחוזה ודבוקה בחולות הים, עזה ואמיצה עמדה בפני הטבע האיתן, שזז
        בלי-הרף, גחן ולפת אותה, כאומר להשתער עליה, אבל ידים חרוצות נטעו צמחים,
        העוצרים בעד אויב מסוכן זה – החולות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא יורדת עם חבילת הסירים על גבה לא בדרך שעלתה.  צועדת היא בזהירות
        בשביל, אשר חברים סללוהו, לא אלה הנמצאים פה עתה – אחרים, שקדמו להם. היא
        שולחת ברכה חשאית לידים ההן ומתפלאה איך למדו מלאכה זו במולדת?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ראתה את הצריפים ולבה התחיל דופק בחזקה, – אולי הזוג כבר שב, אולי הזוג
        כבר שם?  טבעת הקדושין כבר קשרה אותם לנצח; החברים יתכנסו לברך.  – התאמר
        גם היא להם ברכה עם האחרים? הניחה את חבילתה וישבה על אחת האבנים עיפה
        ונרגשה.  החליטה שתיכף תבקש לה אחד האהלים הפנויים ושם תסתדר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בהגיעה הביתה היתה כבר השמחה במלואה.  הזוג היה בחדרו וכל החברים הספיקו
        להתאסף שם.  ברכות, נשיקות, הלצות.  רק היא היחידה לא השתתפה בשמחה;  קבלה
        את האוהל, נכנסה, נקתה אותו בזריזות והתחילה להעביר שמה את חפציה.  עצבות
        חולנית בגוף ובנפש והיא עובדת באפס כח, עד שנופלת על משכבה כמתעלפת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והנה הגיע יום השבת שבו ערכו נשף לכבוד הזוג.  העגלה, בשובה מן העיר,
        הביאה אורחים, גם באוטו שאחר לבוא היו אורחים, והפגישה היתה חמה.  איש לאיש
        לחץ יד, איש עם איש התנשק;  היו גם הרבה זרים ואת כולם קבלו בסבר פנים
        יפות.  תמרה התבוננה במחזה והרהרה כמו תמיד בהופיע אצלם אורחים רבים:  –
        איזה בית-קבול הם הצריפים הקטנים, המכניסים לתוכם עולם מלא.  מאין באה מדה
        גדולה זו של הכנסת-אורחים במקום קטן זה, שבני-אדם עסוקים בו תמיד, עובדים
        בזעת-אפים וחיים בצמצום ובדוחק?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחדר-האכילה נדפה הפעם השבת בריחותיה הנעימים.  דלקו נרות בכל
        הפמוטים;  על הקירות הוסיפו מנורות ואור רב מלא את החדר המקושט בדקלים
        וענפים.  המאכלים היו מאכלי-חג, אלא שרוב החברים אכלו בעמידה מחוסר מקומות
        ליד השלחנות.  ביחוד צר היה המקום למחרת, כשנוספו עוד אורחים, שבאו ברגל מן
        המושבות הקרובות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מה השמחה הזאת?  מי אסף את ההמון הרב הזה?  השאלות האלה דקרו לבה של תמרה
        והיא לא זזה ממקומה, – מתבוננת לזוגות שלהם ושל אחרים וחושבת על האהבה
        המקשרת אותם.  הנה שנים, מתוך הרגל של יחס חברים טוב, לפפו זה את זו
        ויושבים כצמד ילדים תמימים.  ביחסיהם של הזוג החדש נכר משהו יותר מחבוקי
        חברים רגילים.  לבה דופק בהסתכלה בברורה, – כמה מקסימה היא היום!  גם ברקאי
        בבגדי-השבת הלבנים יפה הוא מאד...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה נושכת שפתיה, הדמעות נדחקות אל עיניה והיא מתרעמת על עצמה: – מדוע
        היא כה רעה, כה רעה, שקשה לה לראות באשרם של אחרים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במוצאי שבת נערך הנשף.  ההמון היה עליז ושמח, רק תמרה נשארה סגורה באהלה
        וכל בקשות החברים להכנס לא יכלו להזיזה ממקומה.  היא לא שכבה לישון, רק
        ישבה לערוך מכתב לאחותה, לה היא רשאית לגלות את כל מצפוני לבה, – הן גם
        עליה עברה הכוס ויאוש מר תקף פעם את כל הויתה.  אך בעלת-מרץ היא אחותה,
        התעודדה ומצאה סוף-סוף את דרכה. משונה אחותה בנידון זה.  דעתה היתה, שאם
        עלמה רוצה להנשא, צריכה היא רק מעט מרץ ובן-הזוג ימָצא לה.  כשעזב אותה
        חתנה הראשון, לא נפל לבה ותתעודד מהר. הלאה העבר!  – אמרה – צריך
        לשכוח. הבל הבלים!  נפש צעירה אינה צריכה לבַכות חרבנות, – יש להבליג על
        העצבון שבלב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מן האולם מגיעים אליה קולות של הלולה.  המרקדים הם בעצם התלהבותם. שמחה
        אמתית מורגשת בקבוצה, כי הנה חבר רוּתַק למקום ועוד חברה עמו – יש לזה
        ערך!  והיא סותמת את אזניה בפני קול השירה הבוקעת, בפני דפיקות רגלי
        המרקדים.  נשימתה נעצרת ואינה יכולה לשאוף אויר, והיא בורחת מן האהל החוצה
        ומתרחקת מן הצריפים.  רק פה שאפה רוח במלוא חזה והוקל לה קצת.  בהיר ורך
        הפיץ הירח את אורו בשפע על כל הסביבה. באותה שעה לא נכר עמק בפני הר וכאילו
        הכל התמזג לחטיבת-קסמים אחת.  רק ראש כפר אחד בלט בחצי עגולו ממרומי אחד
        ההרים.  – יתרם הומכו, הושפלו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >פתאום הבריק איזה לובן מזהיר, והיה כמראה פלאות בעיניה.  מה זה יכול
        להיות? נזכרה ששם המחצב, אשר נפתח מחדש, ולאור הירח היה דומה לשלגים רבים,
        שהצטברו למקום אחד.  נעלמו אותו לילה הכוכבים מעל הכפה הכחולה והמלכה טיילה
        ביחידות.  אבל מי העז וזנק מן הכפר ויתרומם כהרף-עין מעל גבי ההר?  מה שם
        עז-המצח, הזורק קרנים לכל העברים?  כאן במקום בודד זה בין ההרים, כשמכירים
        כל כוכב וכוכב אימתי יצא מצד ההר מזה, ונעלם בשעה ידועה ובנקודה ידועה מעבר
        להר מזה, מתפלאים להופעה חדשה והיא מעסיקה את המוח, מאין הופיע
        הפלאי?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה ישבה זמן רב על אבן גדולה, המונחת בצד הדרך, הניחה עליה את ראשה הכבד
        ולאט לאט נרדמה.  בהקיצה כבר הסתלקו הקולות מחדר-האכילה והיא פסעה בצעדים
        מאוששים הביתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במבוא, על הספסלים, עוד ישבו חברים אחדים ושוחחו עם אורחים.  גם היא ישבה
        בקצה ספסל.  אחד החברים ישב אצלה, הניח את ראשו על כתפה ולטף ברכות את
        שערותיה.  בלבה השתרר שקט, אותו השקט שבא בטבע אחרי הסערה, כשטפות גשם
        מתחילות לרדת בנדיבות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אתה שומע?  הבחור שלך בוכה – אמרה בהרימה בלאט את ראש חברה מעל כתפה –
        מהר אליו החדרה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובפתח אהלה עוד עמדה תמרה זמן רב טרם תלך לישון.  מה זה התחיל הירח
        להתלהלה?  נפחד הוא מן העננים, שעלו עליו פתאום בליל תמוז בהיר זה והוא שט
        בחפזון, מתחמק מענן זה ומסתתר באחר. הסביבה עוטפת לרגע שחורים, כמתאבלת, עד
        שמופיע שנית הירח בבת-צחוקו מתוך הענן ומבריח את הצללים.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c4 text-center" dir="rtl"><span class="c5">ג</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– זריזה ומלכה הכוּ זוּ את זו.  – מבשר חבר, שבא מהרפת ודלי חלב על
        כתפו.  וחברים אחרים, שישבו אל השלחן לארוחת-הבוקר, מתחילים לספר מעשיות
        בשמות-נשים שונים.  על מי מדובר פה? תמרה יודעת.  את שמות פרותיהן כולן היא
        יודעת, הן עסקה בטפול, בגדול ובחליבה ולכל אחת מקום-מה בלבה.  אחד
        ממקצועות-העבודה החשובים בקבוצה היא הרפת.  מישהו התבטא:  "עורק-החיים של
        המשק".  לפי שעה זה כך, כי ממנה יתד, ממנה פנה, ממנה הברכה לקיום.  אמנם
        היא בלעה הון והסתבכו בגללה בחובות, אבל התקוה ממנה נשקפת להטבת
        המצב. לפעמים נשמעת מעין תלונה על עבודת-פרך זו – הלא בשתים בלילה כבר
        מוארים חלונות הרפת ובני-אדם נעים ונדים כצללים בתוך החצר, בשלש עוסקים
        בחליבה, ובארבע החלב כבר מובל העירה.  איזו התאמצות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל ישנן פנות משק, אשר שם שולטים רשלנות ורפיון-ידים.  ובדברה על זה עם
        החברים, היא מזכירה להם את דברי מוֹלטשנוב ב"בני קריניצה", כי העיקר הוא לא
        כמה שעושים, אלא איך שעושים, האיכות שקולה כנגד הכל.  – "מתחשבים אתם עם
        כמות הזמן ולא עם איכות העבודה, – היתכן?  הנה רושמים אתם בדיוק נמרץ כמה
        שעות עבד איש, אבל איך עבד – לא איכפת לכם!"  ותמרה, כמו מולטשנוב, חוטטת
        ונוקרת, נוקרת וחוטטת ואיזה רגש קשה מעיק על הלב.  יקרה אותה, שכל עבודתה
        מאבדת פתאום את ערכה בעיניה, ונדמה לה, כי כל מה שהיא עושה אינו טוב, אינו
        מוצלח, ושגם רבים אחרים אינם עושים את עבודתם בכשרון, ולכן רבים הם
        הגרעונות ואין הברכה.  בפרט קשה לה, כשהיא שומעת על-דבר תקציבים והלואות
        וכל מיני חפושי כספים לשם פתוח המשק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אבל ישנם רגעים שהיא חושבת אחרת, ואז כמעין תנחומים נובע מן הלב:  – את
        עבודת העם אנו עובדים וסוללים את הדרך לפניו.  העם בגולה חולם את
        חלום-הגאולה, ואנחנו כאן יוצרים את הגאולה עצמה ומכשירים את הקרקע לפני אלה
        שהזרם יגרוף אותם סוף-סוף אל החוף, החוף היחידי המרמז להם עוד אחרי רוב
        הנדודים.  הם יבואו בהכרח – גם אלה הרוצים במולדת ישנה-חדשה, וגם אלה
        שעדיין לא באו לידי הכרה זו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אמנם נעשו שגיאות, – בארבעים שנות הישוב ויותר אפשר היה להעביר הנה כספים
        רבים ולרכוש הרבה אדמה, מזמן אפשר היה ליצור רוב עברי בארץ, לבנות יסוד
        איתן ולבסס עליו את הקיום הלאומי, אבל העם לא רצה, עמד מרחוק, פקפק
        והתרשל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נעשו שגיאות, ובכל-זאת נוצר דבר-מה.  – הנוער נכנס לעבודה, המחנה התרחב,
        ואם העם העומד בחוץ מסייע בכספו לפועל כפינו, אין זה צריך להעיק על
        המצפון.  הם נותנים את הכסף, ואנחנו – את תמצית דמנו ואת חיינו בהכנת הקרקע
        לדורות הבאים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרים שוממים אלה, שאנחנו משיבים לתחיה כאן, הלא במשך הזמן יהפכו לגן-עדן
        פורח, והוא יהיה רכוש העם, ולא רכוש פרטי שלנו, – ומה הצעקה על גרעונות, על
        כסף העם ההולך לאבוד?  האם העם יפסיד?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >את הרעיון הזה הביעה תמרה שביב באספה נלהבה אחת, שנגמרה בשאלת-חיים פשוטה
        ורגילה – שאלת הבגדים.  הכל קרועים ובלויים, ועתה, כיון שנכנס מעט כסף,
        צריך לנסוע העירה ולקנות בד לתפירה.  החליטו ובחרו בתמרה לתפקיד זה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבוקר השכם קמה לנסוע.  הדרך ידועה לה, כל הר עם צורתו ותכונתו
        המיוחדת.  יודעת היא את כל סִבוּבי ההרים הגבוהים והנמוכים;  יודעת היא
        איפה תפגוש העין צמחים, ואיפה תראה רק שממה ומות;  שממה זו נגרמה על-ידי
        בני-אדם פראים, שעקרו וקצצו כל מה שצמח בסלע לשם תועלת פעוטה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כמה יודע העם הזה לחבל ולהשחית!  – אומרת תמרה לחבר הנוהג בפרדים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ובכל-זאת הרי לעצמם יודעים הם ליצור חקלאות כל שהיא – אומר הלז.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כן, לעצמם הם מתחכמים, אבל מה שיצרו אחרים, מנצלים הם ומחריבים בלי
        חמלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא משוחחת עם חברה על זה, שלפנים אל-נכון היתה צפויה סכנה לבני-אדם
        בעברם במקום הזה.  גם היא היתה רוצה פעם להתקל בסכנה ולעמוד פנים אל פנים
        עם אויב, אשר יארוב לה בדרך...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הם הגיעו עד למושב קרוב.  כרמים שטוחים בצלע ההר, ולמעלה מהם נראה יער
        אלונים וברושים עם בנין נהדר באמצע.  אנשי המקום ספרו לה על השממה ששררה
        כאן;  במקום הזה גדל עץ אחד נהדר, שנטעו אותו לזכר אדם גדול בישראל, והנה
        העבירה יד אכזריה את העץ מן העולם, התעללה בו, גדעה אותו ותחשוף את קליפתו,
        שלא יוכל לתת נצנים חדשים. לא שודדים רצחוהו, אלא אחד המיוחסים, שהופיע
        במרכבה כבודה לעשות מעשהו רשע, לנקום ביהודים – איזו שלפות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אך הזמן עשה את שלו, תקיף הוא מכל שונאינו יחד.  הם החריבו את העץ האחד,
        ובמקומו צמחו ועלו שדרות עצי-צל נפלאים, שביניהם צץ פלטין נהדר, אשר יחד עם
        החורשה הוא יוצר מעין אגדה בין שממת הרים אלה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואשר לעצם מלאכת-הבנין בארץ, רואים הנוסעים גם-כן פלאות:  – מיום בואם
        לארץ, במשך שנות מספר, למד החלוץ את מלאכת-הבנין על בוריה. מקימים הם
        בנינים ענקיים, סוללים כבישים, חוצבים בהרים, – ולא יאמר עוד:  הקול קול
        יעקב והידים ידי עשו.  ומה גדול הפלא, כי הידים הרכות האלה, אשר לא חונכו
        בעבודה כלל, בונות ערים ומיבשות בצות והופכות אותן לאדמת ברכה.  – אי זה
        הדרך בא ונהיה הפלא הגדול הזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שתי פרות חלו, ונפשה של תמרה אינה יודעת מנוח.  אחת מהן טרם ידוע מה לה,
        והשנית מקַשה בלדתה וסכנה צפויה לה. אין תוֹרהּ של תמרה בין הרפתניות, אבל
        לבה אינו נותנהּ לעמוד מרחוק בעת צרה, ובבוקר השכם נמצאת כבר ברפת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא נגשת אל אחת החולות.  זו עומדת במנוחה, מעלה גרה ומלקקת את שיָרי
        המספוא מן האבוס.  גם השניה, – פניה אינם מביעים שום סבל והיא מַטה ראשה
        לצד ערמת החצר, ששמו לפניה.  מראה זה השקיט את רוחה ומשא כבר נגול מן
        הלב; כל מחשבת התוגה, שלא נתנה לה לישון בלילה, עברה חלפה.  בחצר ראתה את
        כל העדר רובץ בשלוה.  נגשה לפרות האהובות עליה, גחנה אליהן וטפחה על גביהן
        בחבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בינתים התלקטה ובאה עדת תרנגולות למלאות את התפקיד החשוב בנקיון הפרות. הן
        מקיפות את הפרות מכל עבר ומנקרות בשקידה את הקרציות מעל גֵוָן.  היונים גם
        הן מצטרפות לארוחת-הבוקר, אבל הן רק את טובת עצמן וטובת ילדיהן דורשות, –
        אוכלות לשובע ונושאות לאפרוחיהן מזון מכביר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלי כל חשש עזבה תמרה את חצר הרפת בבוקר, אבל המצב אחרי כן השתנה לרעה
        ובלילה היתה כל הקבוצה עֵרָה.  – זלפה, שקשתה בלדתה, המליטה סוף-סוף, אבל
        אחרי שהמליטה ויצאה השיליה היה מצבה קשה.  נשלח רוכב העירה להביא את
        רופא-הבהמות, לפנות בוקר בא הרופא, עשה את כל הדרוש אך ללא הועיל, עברו עוד
        שעות אחדות קשות והפרה שבקה חיים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרפתנים היו חורים, מדוכאים, הבהלה היתה גדולה והאבל רב.  מרוב צער
        והתרגשות התעלפה הרפתנית ובקושי השיבו את רוחה.  תמרה לקחה אותה אל אהלה,
        שם הגישה לה עזרה והשאירה אותה באוהל לטפול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפני הצהרים היתה הקבורה.  בלב דופק הסתכלה תמרה במחזה מבעד לסדק המסך,
        המורד על פתח האוהל.  חברתה נרדמה ותמרה נזהרה שלא יעירוה הקולות. הפרדים
        זזו בקושי, לא רצו להוביל לקבורה את היפהפהיה.  עצב השתרר בלבה – מה גדולה
        האבדה!  במה ימלאו אותה?  והנה נצב לפניה ברור כל המצב:  בזמן האחרון
        התחילה איזו תסיסה בקבוצה.  עזבהּ אחד, אחרי-כן יצא זוג, שהלך העירה לחפש
        עבודה.  רבו החולים בין החברים.  ברוכוב, הגבור שבחבורה, חלה והוא שוכב זה
        חודשים.  כשהיו רואים אותו ואת חברתו בלומה, היו לוחשים בהתפעלות:  "איזה
        זוג יפה!"  לא היו בקבוצה חברים מוצקים כאלה, ועכשו הוא חולה ומיואש, והיא
        רזתה, חורת, עצובה ומדוכאה, – אימה לראות מה נעשה מזוג נחמד זה!  גם החברה
        חנה, זו שהעלומים והבריאות בצבצו מלחייה, נכנסה עכשיו לטפול;  זקוקה היא
        כבר לחלב ולמזון מיוחד, – מה יהיה הסוף? מי יעשה את העבודות, ההולכות
        ומתרבות עם הרחבת המשק?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לפני זמן מה באה הצעה להעסיק פועלים שכירים;  אך ההצעה לא נתקבלה. חשבו
        תחילה לקבל עזרה רק במקצועות אחדים של המשק, אבל גם את זו לא קבלו –
        ובצדק!  היא עצמה התנגדה לכך באמרה, שאם רק יתחילו להעסיק פועלים, לא יהיה
        עוד גבול לדבר.  החלשים יחדלו לגמרי לעבוד והבריאים גם הם יתחילו להוציא
        לאט-לאט את הנשק מן היד, אם יראו שיש על מי לסמוך, – כמה קשה לעמוד במערכת
        ההתאמצות שאינה פוסקת!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ומה יש לעשות?  – שאלו אותה החברים, אחרי דברי ההתנגדות שהביעה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– יש להבריא את החלשים, למצוא אמצעי-חזוק לנחשלים, לתת אומץ לנושאים בעול,
        – ואז תבוא הברכה במעשי ידינו!  – אבל היה מי שמלמל:  אֶ, שטויות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמהה תמרה על עצמה, שנעשתה חשדנית ופחדנית.  בראותה יחס בלתי-ידידותי בין
        החברים, מיד חוששת היא לפרוד, לחורבן שעתיד לבוא.  יותר מדי פעל עליה הספר
        "בני קריניצה".  שם קראה, כי היחסים הקשים בין החברים החריבו את קריניצה,
        והיא החלה לראות סימנים רעים גם אצלם.  היא מוקירה מאד את הספר, לומדת ממנו
        הרבה וכמה קטעים מתוכו רשומים בפנקסה.  לה נדמה שהם מתאימים לדברי
        זקן-פועלינו – א.  ד. גורדון ז"ל.  ממש אותה שאיפה פילוסופית-דתית לאחד את
        האמת עם טהרת העבודה.  "חיים של מושבים קבוציים פרושם, – חיים של אהבה
        וצדק, קשר לב ונפש.  לא הצורה החיצונית היא עיקר, כי אם רוח השותפות
        בנפש".  ועוד זוכרת היא קטע אחר:  "השאיפה לעליה, ליושר, לאחדות, לשויון
        הזכויות וכו&#39;...  מתלקחת לעינינו ומתהוה ללהבה גדולה" – הלא ככה היה
        כותב גם הוא, אותו הפלאי, שידע לאחד עם העבודה את כל טהרת רגשתיו ועליות
        נשמתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החברה הקיצה ותמרה נתנה לה שוב את הטפות, החליפה את התחבושת על הלב, ונסתה
        לפתותה שתשתה מעט תה.  החברה התאוננה על חוסר-אויר ותמרה הזדרזה לסלק את
        המסך מעל הפתח, ולפני שתי הנערות השתחוה הארז הצעיר, הצומח על יד
        האוהל.  על ראשו הדק עם ענפיו הקטנים, הדקים, שאך זה התחילו לצמוח, עמדה
        צפור זעירה מאד ושוררה בציוץ דק, מתנועעת לכל צד ועבר, עם תנועות הענף
        הצעיר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– איזה יופי, איזה יופי!  – מתפרץ מלבה של תמרה, והיא מתארת לפני חברתה,
        איזו פעוטה נפלאה:  גוף בהיר, צהבהב;  ראש אדמדם – וכובע שחור על
        הראש.  הנה לאורך הכנפים עוברים שני פסים – אדום וירוק.  והזנב כולו
        ירוק!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ותמרה עומדת בפתח האוהל ומתבוננת, איך הזעירה מתלהלהת עם הענף לפי נטות
        הרוח ושרה בכמיהה וגעגועים. פתאום היא פורשת כנפיה, פורחת ואחרי רגע שבה
        נאלמת למקומה.  "מה חפשה זו ולא מצאה?"  ותוגה לוחצת על לב תמרה: גם היא
        שרה פעם את שירת הנוער.  עליזה היתה – לאן נסתלקה עתה שירת עלומיה? האם
        השירה נתנה בפי האדם רק מתוך האהבה ליצור שני, ולא מתוך אהבה ליוצר, לטבע,
        לעבודה ולחובה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה תקנה על-יד מטת החולה חבילה גדולה של לבנים בשביל החבורה, ולעת ערב
        התעודדה חברתה, קמה, התלבשה ותלכנה יחד לחדר-האכילה.  שם ישבו החברים נוגים
        ודברו על המאורע המעציב – על מות זלפה.  אחרי-כן דנו והתוכחו על מצב-הפרות
        בכלל.  היו הצעות מהצעות שונות להקדים תרופה למכה, שלא תסבולנה פרותיהן
        מלידה קשה, אבל לידי הסכם לא באו בענין זה, הדורש מומחיות.  ישבו החברים
        עצובים ומרושלים עד שעה מאוחרת.  היה רצון להתבטא, להזכיר נשכחות.  העלו את
        זֵכֶר עבודתם בצבא, את ימי הרעב והמצוקה בשנות המלחמה. בפרשת הפרעות לא
        נגעו הפעם;  זוהי פרשה מיוחדת שבה אפשר לשוחח לילות שלמים, אלא שענין זה
        עצוב מדי, ועל-כן משתדלים לעבור עליו בשתיקה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה מקשיבה כשהיא יושבת בקצה אחד השלחנות ותופרת לאור מנורה קטנה. כל
        ספור וספור של החברים עושה עליה רושם עמוק, היא מתפלאה איך הנוער הזה, שעבר
        כל שבעת מדורי הגיהנום, מצא עוד עוז בלבו להתאחד ולרקום יחד את חלום חייו.
        כאיש אחד קם הנוער הזה ועלה אל הארץ לבנות לו פנת עבודה וחיים.  לא נרתע
        מפני שממת ההרים הללו ותקע לו יתד במדבר צחיח זה.  לבה מתמלא גֵאוּת על
        קבוצתה האמיצה, שהיא הִנה חלק ממנה ומסייעת עמה בעול מלחמתה הקשה. כאן
        נזכרה תמרה במחשבת-פגול, שהיתה קרובה להוציאה לפועל:  עזיבת הקבוצה – והיא
        מתמלאה כעס ובחילה לעצמה על רשעות לבה.  לו ידעו החברים את מחשבת החטא
        שטִפחה בקרבה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שיחת החברים נתגלגלה בינתים ונגעה בנושא חדש.  חבר אחד הוכיח את הקבוצה
        על </span
      ><span class="c2">קמצנות</span
      ><span class="c1">, ובמושג זה קשר מושג קרוב אליו – </span
      ><span class="c2">קבצנות</span
      ><span class="c1">.  ותמרה התערבה כאן בשיחה והגינה על שתי אלה יחד.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אם לא נקמץ, חברים, לא נוכל להתקיים כלל.  ובנוגע לקבצנות – אל נתבייש
        גם בה.  עובדי ארצנו קבצנים הם כולם, והדבר הזה אינו צריך לשמש לגנאי בשום
        אופן.  כי לא העושר אנו מחפשים במולדת, כי אם עבודה בזעת אפים, תקון הגוף
        ותקון הנפש.  ומשונה הדבר, – אמרה בנימה של רוגז – שאצלנו נשמעת מלה זו
        לגנאי, כמו יש כונה לדקור בה את מישהו...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >החברים התנגחו בשאלה זו עד שעה מאוחרת בלילה, ורק כשאזל הנפט במנורה
        התפזרו לאהליהם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה עסוקה במשרד להכניס את רשימות הרפת אל הפנקסים.  אחד החברים עסוק גם
        הוא בכתיבה ליד אותו השלחן. פתאום העיפה בו תמרה עין ותלונה נשמעה
        מפיה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– גרעונות!  – בכל גרעון, אפשר להתפקע. אני הייתי רוצה לעבוד לא שתים-עשרה
        שעות ביום, אלא הרבה יותר, ובלבד שאדע, כי זה מכניס עוד דבר-מה
        לקופתנו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קובלת תמרה כלפי כל צל של רשלנות.  רוצה היא שהכל יהיו זריזים ומעונינים
        במדה שוה בקמוץ ובחסכון. החבר מוסיף לכתוב ועונה כלאחר יד:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– קמוץ, חסכון – הבל הבלים! לתקון המצב דרושים דברים יותר רדיקליים.  הנה
        כאן הוא מעבד תכנית, איך להרבות את התוצרת ולהגדיל את ההכנסה. אמנם מסופק
        הוא אם החברים יקבלו את תכניתו, אם ימצא אצלם בכלל אוזן קשבת. אפשר שיענו
        לו כדרכם:  קשים החיים יותר מדי, שנשחק בנסיונות חדשים!  הוא מבין זאת ולבו
        כואב, אבל הוא ישתדל להגן על תכניתו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >במשרד הופיע פתאום המורה הזקן, שבא אל הקבוצה לבקר את "ילדיו". גם הוא
        הביא להם כמה הצעות חדשות, אבל בקבוצה השיבו, שאין אמצעים לחדושים. המורה
        הטוב אינו מתרעם על התשובה, הוא מבין את מצבם ומנחמם:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מאות צעירים וצעירות סובלים כמוכם בארץ בלי כל התאוננות – הכל לשם
        העתיד.  כי העתיד הוא לעובד התרבותי, אשר ידים מיובלות לו ואוצר של
        מדע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לעתים הם מתוכחים עם המורה וזה יקרה בשעה, שעם כל אמונתו בעתיד, הוא פולט
        בכל-זאת גם דבור מר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הנה – אומר  הוא – הרבה שמש לנו כאן, אבל מעט הוא האור לפי שעה.  שמש
        מוזרה, מחממת ואינה מאירה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא נכון!  – מתרגז אחד השומעים – והזקן אינו מתבלבל, מטפח על גב מתנגדו
        ואומר בחבה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– טוב, טוב!  אוהב אני את המאמינים.  ירבו כמותכם בישראל!  – אחרי-כן הוא
        מדבר באזני "ילדיו" על ההבדל שבינינו ובין אומות העולם, שלכל אומה ואומה
        ישנה מדינה, שהיא דוגלת בשמה, ואצלנו יש רק דגל תכלת-ולבן, שגם אותו מצווים
        אנו להרים בזהירות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">כאן שוב מוצאים התלמידים מקום לקושיות:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה שייך?  הלא תקופת הדגל כבר עברה אצלנו.  בזעת אפינו אנו מרטיבים את
        האדמה, כלום בדגל בלבד, היינו מסקלים פה אבנים, חוצבים בהרים וסובלים
        דחקות?  לא הדגל יזקוף את קומתנו הכפופה – </span
      ><span class="c2">העבודה</span
      ><span class="c1"> רק היא תיַשר את הגו המעוקם מסבל הדורות.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– אבל האדמה, חוץ מחלקות קטנות, עדיין לא לנו היא...</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ברובה תהיה לנו, אם נאמין בדבר הזה ואם תושג ההתאמצות הדרושה בתוך העם
        בכל תפוצותיו.  בלגלוג על חולשה ובאי-אמון בכחות הטמונים בנפש העם ליצור את
        חייו ואת עתידו בארצו, – מוטב להשאר בגלות ולהִמֵק בעבדות עולם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >המורה מחבק את תלמידיו, ושלום גמור משתרר במחנה.  הם סובבים את המורה בחבה
        לשמוע מפיו תורת הצמח.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ככלות עבודת הערב נכנסה תמרה לחדרה, הדליקה את המנורה ותתכונן לכתוב
        מכתבים. אבל מיד הופרעה, כי נכנס שמשון ופניו נוגים וסודיים.  נכר מיד,
        שאיזה דבר מעיק בלבו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– שב וספר למה נפלו פניך היום?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוא ישב קרוב אליה ופניו עוררו רחמים בהתודותו לפניה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– תמרה, לך עלי לגלות את כל לבי! אַתְּ קרובה לי מאחרים, את קולטת כל סבל,
        את יודעת לנהל בעצה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה קרה, שמשון?  – שאלה בהורידו את עיניו.  הוא שתק רגע קל, אחר התעודד
        ואמר:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אוהב אני את רוזה.  סוד זה בודאי גלוי וידוע לכולכם.  אנחנו בקרנו יחד
        את בית-הספר החקלאי;  יחד פתחנו את הרעיון על-דבר הקבוצה ולא יכולתי לתאר
        לי, שיבוא יום ודרכינו יתפרדו.  היא האירה לי את דרכי, – והנה חשך עולמי
        בעדי משעה שהבחור מקבוצת החוצבים החל לבקר אותה תכופות. היחסים ביניהם
        נעשים יותר ויותר קרובים, ואין כנראה למנוע את הדבר.  אבל לו ידעתי שהוא
        ראוי לה, או שיוקיר אותה כמוני, – האמיני לי, תמרה, שהייתי משתדל להבליג על
        צערי, על עומק הכאב שבלבי והייתי מניח את ידי על ראשה ונותן לה את
        ברכתי.  אבל בר-נש זה, שהיא הולכת אחריו בעינים עצומות, בחור ריק הוא
        וקל-דעת.  הוא יוציא אותה מקבוצתנו ואושר לא יתן לה.  מה לעשות?  מה
        לעשות?  – שאל ביאוש בלחצו את צדעיו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא ישבה ראשה אחוז בין כפות ידיה והביטה בו בהשתתפות.  מה יש לענות ומה
        יש ליעץ?  אלא שיסורי שמשון גלויים לכל – ויסוריה היא נסתרים מעיני אחרים,
        נמשכים זה זמן רב, כמחלה כרונית שאין לה ארוכה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– תמרה!  את שותקת, לא קלטת את צעקת לבי ואין לך רצון לנהלני בעצה טובה,
        כיאה לידיד טוב?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מה העצה שאוכל לתת לך, שמשון חביבי?  הן התרופה היא רק בך, בנפשך
        פנימה.  עליך להרגע, להתאזר בסבלנות, ובמשך הזמן יבוא הכל על מקומו
        בשלום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">הוא קפץ ממקומו:</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– זוהי העצה?  זהו כל מה שאת יכולה להגיד לי?  זהו יחס של ידידה?  –
        ושמשון יצא מן החדר מלא זעם ותמרורים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה המשיכה לכתוב את המכתב שהחלה קודם לכן.  היא עברה על השורות הכתובות,
        אבל לא תפסה את הכתוב בהן. עברה עליהן שנית, וכשתפסה את התוכן התחילה
        להמשיך.  היא תארה לפני אמה את חיי הקבוצה, את העבודות שבהווה ואת התקוות
        לעתיד.  בקשיים וביסורים, כמובן, לא נגעה, – אלה הם מן הדברים המסורים רק
        ללב.  היא ספרה לאם על הטבע, על שירת הנוער, הנשמעת תמיד בקֵן זה.  כמה טוב
        לה פה!  היא מתמסרת לעבודת יום יום ומרגישה, שהיא מוסיפה דבר-מה על
        הבנין. יש שבקומהּ עם דמדומי בוקר לעבודה, היא נעצרת על סף הצריף ומסתכלת
        במזרח המתחכלל.  ההרים בשעה זו עטופים עוד סוּת כהה;  בפנה אחת חבויה
        מתחילים העננים הקלושים להתאדם, ולאחר שעה קלה אורות רבים מתפשטים
        בסביבה.  והיא משתדלת לתאר הכל הפרטי פרטים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אך ברוח אחרת ובשפה אחרת היא כותבת את מכתבה לאחותה יחידתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >...  העצבות נאחזת בלבה כעלוקה, מוצצת ומוצצת אותה.  מה רע עשתה?  מה
        חטאה?  שטות כל-שהיא וכבר מנוחת נפשה נגוזה.  אינה יודעת אנה תברח
        משֵד-הבלהות המציק לה, ואיפה תבקש תקנה לנפשה הרצוצה, שאין לה כלום בהווה
        ואף קו-אור אחד בעתיד...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשעה שהכל בקבוצה הולך כשורה, טוב לה והיא מקבלת הכל באהבה.  אבל דַיָה
        אי-הצלחה קלה, ומיד עצביה נרגזים ועָקַת הלב מכבידה ביום ובלילה.  עש היאוש
        אינו פוסק מלכרסם את הלב.  היא מחפשת אז לעשות איזה דבר למעלה מכחותיה,
        למען השתיק את העצב המדכא אותה.  איש אינו יודע מה שנעשה בלבה פנימה, רק
        חברתה בחדר, ירדנית, מכירה לעתים במצב-רוח זה ושואלת:  מה לך?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– לא כלום – היא משיבה – ובכל זאת מטפלת בה חברתה כמו בילדה קטנה. מעמידה
        לה ללילה ספל חלב סמוך למטתה, מוסיפה עליה עוד שמיכה ונזהרת מקריאה, שמא
        יפריע לה אור המנורה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"המרוצה את בעבודתך?"  על שאלה זו במכתב אחותה היא עונה בהתגלות לב: מובן
        שהיא אוהבת כל מיני עבודות, אבל ישנים ימים שבהם היא נכספת לשדה, לחרוש
        ולזרוע – והלב מתלונן:  מדוע רק לחברים נתנת עבודה זו, שיש בה כל-כך הרבה
        הרחבת-הדעת?  לחברות הֵצֵרו את הגבולים:  משק הבית, טפול בילדים ובעופות,
        בפירות ובירקות – ולא יותר. כשהיא רואה את החברים חורשים בהר או בעמק, היא
        מקנאה בהם, כי נפשה כְמֵהָה למרחב, לשדה.  היא שאפה תמיד לשויון גמור בכל,
        – והנה אין שויון גם בחיי הקומונה.  כל אחריות המשק, ההנהלה, עבודות הפלחה
        והנטיעות – כל זה הוא בידי החברים בלבד.  ובמה עסוקות החברות? ממש באותן
        העבודות שבנות בעלי-בתים עסוקות בהן;  מהו אפוא שנוי-הערכין, שהכניסה
        הקבוצה לחיי הצעירה? ואף-על-פי-כן מה חזק הקשר המרתק אותה למקומה
        ולעבודתה.  הנה הזמינה אותה חברה אחת מן העמק לבוא אליה לזמן מה. החברות
        משדלות אותה לנסוע, – והיא אינה יכולה להפרד מן העבודה.  נמה לה שחטא תחטא,
        אם תפרוק את עולה מעליה;  ואפילו לזמן קצר.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c4 text-center" dir="rtl"><span class="c5">ד</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שלשה ימים ושלשה לילות נתך גשם סוחף.  הוא בא בסערה, שהתחוללה באחד
        הלילות.  בצריף הקיצו לקול מפץ נורא, – ברק, רעם ואחריהם תעופת פחים באחד
        הגגות.  קמה בהלה, הדליקו מנורות ויצאו החוצה לדעת מה קרה?  נודע שנפל גגו
        של ברוכוב החולה, אבל מיד העבירו אותו לצריף אחר וסדרוהו על הצד היותר
        טוב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הגשם התחיל לאחר שנשתתקה מעט הסערה.  בא בשטף קצף ועקר עצים, הסיט אבנים,
        הרס גדרים וסלל דרך למימיו. והמים, בבואם עד קיר אבנים, פרצו להם נתיב
        בנתצם חלק מן הקיר;  נפלו מהפרצה ברעש ובהמולה, זרמו הלאה ושוב נפלו במפל
        אדירים, כשפניהם מועדות אל הואדי הזועף והמתגעש.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה, ששוב הגיע לה תור הרפתנות, נשאה כד חלב אל המטבח ובינתים עמדה
        להסתכל, כיצד ההרים מנערים מכתפיהם את השלוליות הרצות, שולחים אותן לנפשן
        במסלולם היורד למטה ברעש ובהמולה. מנגינת המים!  היכן שמעה מוסיקה
        כזו? הרהרה ונזכרה:  על שפת ים רחוק, שמימיו היו אפורים ועכורים ושמי-עופרת
        קמורים על היקום.  המולה כזו שמעה גם על שפת ים התכלת כאן בארץ זו בהיותו
        סואן ברעש וגליו עולים בזעם, משתפכים זה בזה ורצים אל החוף להריק עליו את
        לבנת קצפם. המולה כזו עולה באזניה זה שלשה ימים ושלשה לילות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נורא ואדיר הרעש בלילות.  יש שהסקרנות דוחפתה לחדור אל חשכתם, ובצאתה אחרי
        ארוחת-הערב לחצר היא עומדת להסתכל בתוך האפלה.  חשכה גדולה עוטפת את הכל,
        כלום לא נראה, גם את המים הנתכים בזעם אין רואים, רק המולת הגשם ורעש המים
        הנופלים אל הואדי נשאים באויר.  רוחות איתנים, רוחות סתרים, מפילים אימה
        והיא ממהרת לסגור אחריה את הדלת הכבדה של הפרוזדור ולהתכנס בחדרה.  גם פה
        נשמעים קולות המים, – הם דופקים על הגג, על זכוכיות החלון ונתכים בעוז ובלי
        חָשָׂך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– שומעת את, ירדנית? פונה היא לחברתה, שהתעטפה למעלה מסנטרה בשמיכה עבה
        וקראה לאור המנורה, העומדת על שלחן סמוך למטתה – את שומעת, כמה מים נשפכים
        בחוץ?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ירדנית הרימה את ראשה, סגרה את ספרה בתחבה אצבע בין דפיו, נערה שערותיה
        ותאמר בתלונה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– כן, מבול של מים, ולנו אין האפשרות להַקוותם למקום אחד ולשמרם לחדשי
        הקיץ, לבלי נצטרך לקנות מים בפח משכנינו, להוציא על זה הון, ולבסוף נשארות
        הבהמות צמאות ובני-האדם מקבלים טיפוס ודיזנטריה וכל מיני צרות, – כמה שלחנו
        אשתקד לבית-החולים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הן מונות את מספר החברים, שהיו אז בבית-החולים, וירדנית ממשיכה את
        תלונתה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ולמה הולכים המים ואובדים בתוהו?  מפני שאין לנו ממשלה לא כלפי פנים ולא
        כלפי חוץ, שתדאג למלאות את צרכי ישובנו.  הלא עבודות גדולות כאלה הן רק
        בידי העם או הממשלה, אבל זו אינה מעונינת כלל לעשת דבר לטובתנו;  ועל-כן
        צריך העם לעשות זאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– העם, העם, – היכן העם?  – נאנחת תמרה.   אחרי שהציעה לה את המטה, שכבה
        והתעטפה גם היא בשמיכתה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי שעה קלה נשמעה נחרת חברתה שנרדמה.  גם מהחדרים הסמוכים הגיע קול
        נשימה כבדה של אנשים עיפים מעבודה; רק היא לא יכלה להרדם והיא מתהפכת מצד
        אל צד.  היא מתגעגעת לאהלה, שמוכרחה היתה לעזבו מפני הקור.  שם לא היתה
        אזנה כפופה לנשימתם של אחרים, לחלומותיהם והזיותיהם.  כאן, בצריף, אין אדם
        יכול להתיחד עם עצמו, כל אחד מחויב לבוא בנגיעה עם השני, – פה את רואה, שם
        שומעת.  הקירות המסודקים אינם חוצצים בפני כל זעזוע קל.  הכל מגיע לאזני
        החברים, גם לאורחים, שמזדמן להם ללון אצלם.  לה קשה מאד להמצא בשכנות עם
        זוגות אהובים.  האם צניעותה גורמת, או סתם בחילה בלבה לגלויי יחסים בצורה
        זו? דירות טובות מזו לא יכלו הפועלים לבנות והיו מאושרים, שידם השיגה לטיח
        את הקירות מן החוץ ולסתום את הסדקים בפני רוחות וגשמים.  קודם לכן גם זה לא
        היה. </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לאזניה מגיעה מחדר רחוק לחישת זוג נאהבים באשמורה הראשונה.  היא מתכסה
        למעלה לראשה ומשתדלת להרדם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >העירו אותה צעדי השומר, שנכנס לפרוזדור, הנה דפק על שתי דלתות – השלישית
        היא שלה – וטרם הספיק לקרוא לה בשם, קפצה מעל מטתה והחלה להתלבש ולהכין את
        עצמה לרפת.  היה עוד חשוך כשהגיעה שמה, אבל ככלותה לחלוב ובצאת העגלה עם
        הכדים העירה כבר האיר הבוקר והחברים כולם התכוננו לעבודה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קר היה בחוץ.  פנות הגדרות וכל החגָוים שבין האבנים מכוסים היו חספוס של
        קרח.  ערמות השלג הלבנות התקלחו בגשם ונמסו;  זרמו למטה ורחצו ושפשפו את
        האבנים.  מן החצר הביטה מסביב והתפלאה, – לאן הסתתרו כל ההרים?  דבר לא
        נראה מן הדרך והלאה, רק אדים אפורים, עבים, הזוחלים גושים גושים בתוך האויר
        ומכסים את הסביבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רק לעת הצהרים התחילו השמים להתבהר.  קודם הואר קצה ענן אפור אחד, וטרם
        הספיקו השמים להתגלות, עלו עננים אחרים, עבים מן הראשונים, ושוב טפטף גשם
        קל;  והנה שבה הרוח ותגרשהו, פזרה את העננים הכהים וגלתה אופק כחול עם
        עננים צחים וזכים כשלג.  באותה שעה הוארו ראשי שני הרים גבוהים, וסלעיהם
        הרחוצים למשעי נראו כמצוחצחים ומסוידים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שיח-ורדים התנפנף בגנה ופרחו היחיד זע הנה והנה כחָרֵד מקור.  על-גבי
        הכרכוב מתחת לגג עמדו שלשה אנקורים מרוחקים זה מזה, הצפינו ראשיהם בכנפיהם
        והתכוצו מקור; לבה של תמרה נמלא רחמים עליהם, – הנה בימי הגשמים הסתתרו כל
        צפרי הסביבה וחדלו להשמיע קולן, רק השלשה לא מצאו להם מחסה מקור ועומדים פה
        קפואים ואומללים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בכה ילד בחדר-התינוקות, ותמרה נכנסה לראות מה לו לפעוט – הסובל גם הוא
        מקור, כמו הצפרים שעל הכרכוב? והרי הילד בתוך קנו הוא שוכן, מחממים את
        חתוליו על גבי התנור, מכסים ומעטפים אותו – למה לו לבכות?  היא עזרה לאם
        הצעירה להשתיק את ילדה ולישנהו בעריסתו, אחר נכנסה אל ברורה לבקרה בחליה, –
        היא סובלת מאד מן ההריון והרופא צוה עליה לשכב במשך איזה זמן.  בפי תמרה לא
        נמצאה שיחה בשביל החברה, היא רק האזינה לקטעי דבוריה  והסתכלה בה. נעימים
        נראו לה הפנים עם קויהם העדינים והעינים הנבונות והטובות.  "אין פלא
        שאוהבים אשה כזאת!"  – עברה מחשבה במוחה.  איזה נים חם זע בלב, ובצאתה לחצה
        את יד חברתה בחבה רבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה שביב אינה קוראת עתונים – היא מעונינת יותר בספר, ובכל-זאת אוספת היא
        לעתים אל חדרה איזה גליונות ומניחה אותם לקריאה לשעה פנויה בערב, לעבור
        עליהם בסקירה קצרה, ועל-פי-רוב הם משאירים בלבה רושם עגום.  – מתגלה לפניה
        מצב העם בארצות פזוריו בכל צורתו האיומה.  מן העתונות היא לומדת גם את המצב
        בארץ ומתרגזת, בראותה באיזה מיני תחבולות משתמשים שונאינו להשבית את
        עבודתנו – כל שעל-אדמה נקנה בדמים מרובים;  מעצורים אין סוף על דרכנו;  עד
        שתדרוך הרגל על קרקע מוצק יש לעבור שבעה מדורי גיהנום.  וגם כשכבר הגענו
        עדיו, אחרי ששלמנו בדמנו ובחלבנו, – באים שכנינו הטובים במשפטים או
        בהתנפלויות, – כל האמצעים כשרים להם. את כל זה היא מוצאת בעתונות:  מבית
        ומחוץ רק רדיפות וצרות ויסורים.  אנו נקלעים בכף הקלע, תלויים באויר
        ונחנקים – אי המוצא, אי ההצלה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחרי קריאה כזו אין תמרה יכולה להרדם בשום אופן, וצריכה היא להשתמש
        בתחבולה בשביל השינה.  משפשפת היא בכוהל את מצחה וקצת גם את ראשה ומעטפת
        אותו במגבת הדק היטב.  ריח הכוהל תמיד היה מישן אותה, אבל הפעם אינו
        מועיל.  היא מנסה גם תרופה אחרת: מונה היא מספרים קבועים מאחד והלאה, גם זה
        היה מועיל לה לשינה, אבל הפעם – הכל לשוא, אין מלאך השינה נוטה חסדו לה,
        והיא מחליטה לשכב במנוחה ולצפות עד שיבוא השומר להעירה לרפת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >צפיה זו אינה עוברת בלי מחשבות. הנה עולה על לבה השאלה:  מהי סבת הדבר
        המוזר הזה, שפתאום נודדת שנתו של אדם משך ליל תמים?  אולי עקבות פסיכוזה
        בדבר?  הלא ככה מתחילים אנשים חולי-רוח, קודם נפסקת השינה, אחרי-כן – עבודת
        המוח הרגילה.  ובאמת לא יפלא, אחרי כל מה שעבר עליה בשנות המלחמה ובפרעות,
        – אם תטרף פתאום דעתה עליה, ובקבוצה ישמע קול באחד הימים, כי תמרה שביב
        השתגעה.  בזכרונותיה של וירה פיגנר הן מסופר על פוחיטונוב, שאחרי ירידה
        אטית עם סימנים רפים מאד למחלת רוח, הוכר פתאום בשליסלבורג – למשוגע. ואצלה
        – האם המכתבים הארוכים שהיא כותבת והציורים המוזרים היוצאים מתחת מכחולה, –
        האינם מעידים על איזה קלקול במוחה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרי היא מוזרה גם בעיני החברים. במוצאי שבתות, שלעתים עורכים אז החברים את
        רקודיהם, אין היא משתתפת בהם אף פעם;  אף כי מוכיחה היא את עצמה על
        התבודדות זו, שאינה יודעת את מקורה. רוצה היתה להראות פנים צוחקות לחברים
        בשעת חדותם ואינה יכולה, איזה דבר בלתי ברור מעכב בעדה והיא מתחמקת לחדרה
        בשעה שמתחילה ההוללות.  הד ההתלהבות מגיע לאזניה והיא חוקרת לדעת, איך
        מתמזגים יחד עבודה קשה, דאגת הקיום, חוסר המוצא מן המצב המסובך, – עם
        הרקודים עד כדי שכרון, עד כדי טשטוש הדעת?  האם יתרון המרץ הוא המתפרץ
        החוצה, או חסר כאן מרחב לנפש, ולכן מחמרמר ומתגעש הדם ומבקש לו מוצא
        ברקודים נלהבים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והיא, למה אין לה צורך בכל זה? האם הזדקנה?  האם בת עשרים ושמונה – זקנה
        היא?  אולי.  ולמה יתקוף אותה הרצון להתבודד בפנתה, להסגר בתוך עצמה, להיות
        לבדה עם תוגתה ועם עצבה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מתוך שאלות אלו נרדמה וחלום טוב חלמה:  – רופא-בהמות צעיר התחיל לבקר
        בקבוצה והתיחס אליה בידידות.  הביא לה ספרי-מקצוע לקריאה, נתן לה באורים
        שונים; ועל יריעת חייה מתחיל להתרקם רומן;  אך משהו מעכב בעדה מלהושיט לו
        יד ואינה יודעת מהו הדבר.  בכל-זאת נראה, שהיא עומדת להרים את המכשול מן
        הדרך – והנה מעיר אותה השומר לעבודה, היא מזדרזת לקום והולכת באהבה
        לתפקידה.  עוד לא העיר הבוקר החרפי, אבל את קלוחי העננים, היורדים כאנך אל
        העמק, אפשר היה להכיר.  עננים אלה, כשראשי ההרים הגבוהים מעברים את מסעם,
        הם מתכוצים וזורמים בעל-כרחם למטה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשנכנסה תמרה אל הרפת, הכירה "זריזה" את צעדיה וקמה לקראתה מרבצה, הפכה
        לאטה את ראשה אליה, כאומרת לקדמה בברכת הבוקר.  "האם לא תמלאי את אבוסי
        הריק?"  שואלות עיניה, בשעה שתמרה נגשת לחלבה, והנערה מטפחת לה על גבה
        ואומרת:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– מנוחה, רחימתי!  תקבלי מנה יפה אחרי-כן, הבה ואחלבך תחילה!  – ומביטה
        תמרה על הזרם הלבן הקולח אל תוך הדלי וחושבת:  כמה טובה היא "זריזה"
        שלנו! הרי כבר עשרה חדשים, שהיא נותנת חלב בלי הפסק, בעוד חדשים היא עומדת
        להמליט, ומדת חלבה עדיין כה גדושה!  הנה תחלוב תיכף את "אילה", – גם זו פרה
        טובה, אבל אינה מגיעה אפילו לקרסוליה של זריזה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בצאת תמרה מהרפת, היא עומדת רגע קט ומחפשת את השמש על ההר המזרחי. בימים
        כתקונם רואה היא בשעה זו את השמש, המתחילה להתרומם מעבר ההר, היום לא תראה
        אותה.  קלוחי העננים הפכו לערפל וההרים הסתתרו לגמרי.  גם הכרמים ושדות
        המספוא והירקות אינם נראים, על הכל רובצים אדים לחים.  הנה הערפל עולה
        ומשתער גם על הצריפים ולא נשארה לפליטה כי-אם הגנה עם גזעי העצים, גזעים
        לבד עומדים וראשיהם חותלו בערפל.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא בדרך הביתה וחבורת אוזים מתפרצת לקראתה בצוחה:  גַע, גע – מתי יגישו
        לנו את ארוחתנו?  – והלא שם, מבית לסבכה, מטפלת כבר חברה אחת במזונם
        והטפשים אינם מרגישים ורצים אחרי אחרת:  איזה עם אוילי!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא הכניסה את כד החלב אל המטבח והתפארה באזני החברות על רבוי חלבה של
        "זריזה" שלה.  החברות אומרות, שהדבר תלוי בחולבת, אבל היא מתנגדת ומבקשת
        להוכיח להן, שלא מפני חריצותה באה הברכה, אלא בגלל מאיר, המשגיח על הכלכלה
        ועל הנקיון.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כאן מתגלגלת שיחת החברות על טיב הפרות, על יפין וחכמתן;  בפרט ראויות לשבח
        העגלות, אשר ביניהן יש למצוא יפהפיות כאלה, שצריך לשלחן לתערוכה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ובאמת נשלח – עונה המטפלת בתרנגולות, – הנה מתוך עדתי כבר נבחרו
        קנדידטים לתערוכה, וגם מתוך הרפת נבחר, ולא יפלא כלל אם ביום בהיר אחד
        ישָמע בקהל, כי הקבוצה ההררית קבלה את הפרס הראשון בעד גדוליה.  – כאן צחקו
        החברות מטוב לב ואף כי לא האמינו עוד באושר הפרס, בכל-זאת דברו בו כולן
        בהנאה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רש שני ההרים שמנגד שתקו.  ואחרי שהתפזר הערפל ותמרה ראתה אותם מצופים זהב
        וענני-אש משחקים לפניהם, התעורר בה הרצון לצייר את המחזה. לפנים צִיְרָה,
        אבל עתה אין לה שהות לכך – "זמני – זמנם!"  לוחשת היא כדרכה. מחליפה את
        שמלתה לשעת האכילה, אבל ללכת אינה יכולה מרוב עיפות, והיא צונחת על קצה
        מטתה לנוח, בחבקה בכפיה בֶרֶך אחת ובכבשה את ראשה בחלון.  הפעמון מצלצל
        שנית, עליה ללכת, אבל אינה זזה ממקומה.  יש לה הרצון להתפרץ בבכי גדול,
        כדרך שבוכות לעתים קרובות חברות אחרות.  אבל היא יודעת להבליג על
        בכיתה.  הלא היא "אמיצה", כפי שקוראים לה, ועליה לגרש מלבה את התשוקה
        לציור, לקריאה, להשתלמות.  אין פנאי, אין פנאי, כי צריך לעבוד ולהשתכר
        לקיום – את הלחם שהיא אוכלת ואת הבגד שהיא לובשת!  בלי עבודה מאומצת אין
        זכות-קיום כאן.  בקבוצה אין רשות להתפנק, לצייר, לשחק.  אין כאן שהות
        למשחקים,  בקבוצה החיים רציניים מדי.  כל אחד צריך לסייע בכל כחו ומרצו
        במלחמת הקיום.  הנה בליל חמישי היתה אספה, שבה התלבטו בשאלה, כיצד להשיג
        שעורה בשביל הפרדות, סובין ופולים בשביל הפרות, מכולת לבית.  בהקפה קשה
        להשיג, כסף מזומן אַיִן – ומה יעשו עתה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואחרי חליבת הערב, כשהתכנסה שוב לחדרה ונגשה לסגור את החלון, עוד העיפה
        עין החוצה.  – ההרים טרם נעלמו באפלת הלילה.  בין שני עננים כבדים שגחו אל
        המערב, נראה כעין אי בהיר.  הובלטו הזיתים על ראש ההר הממוצע, ושני אחיו
        הגדולים ממנו תומכים בו לבל ימוט מכובד עציו העתיקים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לא הדליקה את המנורה, שכבה על מטתה והביטה לפניה אל הקיר.  היא מבחינה שם
        את התמונות, אף כי את הפרצופים אינה רואה בחשכת החדר – הן יודעת היא מקומה
        של כל תמונה ותמונה. הנה כאן אמא החביבה שלה יחד עם שתי אחיותיה.  התמונה
        שניה – האח עם בני משפחתו, והנה שני ילדיו לחוד.  על השלחן עומדת תמונה
        חדשה – זוהי אחותה מַשָה עם חתנה.  מאושרת מַשָה בכתבה דברים נלהבים על
        חתנה:  סטודנט לרפואה העומד לגמור את חוק למודיו.  השתלם קודם במדעים
        עבריים וכותב עברית.  "איך הוא כותב, תמר&#39;נקה!"  את מאמריו תשלח לה
        בקרוב.  והנה חיצוניותו מתוארת:  גבוה, שערות-ערמון ועינים עמוקות, עמוקות
        וטובות!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– את אוהבת, ילדה!  – התמלטו המלים מפי תמרה וזרם עבר בגופה.  – הנה קרובה
        החתונה – למזל לך, קטנה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הירח השקיף לרגע בחלון ותמרה מקשרת את המחזה עם הרהוריה על אחותה:  אפשר
        שהירח מאיר בשעה זו גם את חדרה שם מעבר לימים ומלטף את גופה הרענן, כשהיא
        מתפנקת במטתה הרכה בתוך השמיכות החמות.  או אפשר שהירח מלוה אותה בטיולה עם
        בחיר-לבה, כשהיא מתרפקת עליו באהבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מַשה, משה&#39;נקה!  – מתחמקת הקריאה מבין שפתיה.  כמה היתה רוצה ללחוץ את
        אחותה אל לבה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בלילה קמה בהלה בקבוצה – החברה ברורה עומדת להפיל.  והתחילו רצים רוכבים
        והאוטו העירה וחזרה.  הביאו רופא אחד, אחרי-כן את השני בלוית אחות
        מ"הדסה".</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בבוקר השיבו אותם העירה והכל שב למנוחתו, רק האנשים הלכו להם רצוצים
        והקשיבו בכליון-נפש לצריף האמצעי, אשר משם נשמעה אנקת החולה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לא הועילה לברורה המנוחה והשכיבה.  כיון שהתחילה לעבוד, הפילה בחודש הששי
        להריונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אחרי שגמרו הרופאים את עבודתם, התיעצו מה לעשות?  להוביל אותה – צפויה
        סכנה;  להשאירה פה – דרוש טפול והתמסרות מצד מישהו מחברות הקבוצה. לא די
        בחובשת בלבד, שהיא מטופלה גם בעבודות אחרות.  מי יקח על עצמו עבודה אחראית
        זו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >התנדבה תמרה והתמסרה לחולה בכל נפשה, היא עברה לגמרי לחדרם ולא עזבה את
        החולה לא ביום ולא בלילה, איזה רגש עמוק רתק אותה אל ברורה והתאהבה בה כמו
        שמתאהבים בילד יפה ורפה.  רק את ברקאי לא חבבה, ובהתבוננה מקרוב לתנועותיו
        ולאופן דבורו, השתוממה כיצד אהבה פעם את האדם הזה וכיצד לא הרגישה כמה
        משונה הוא וזר לה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זו היתה תקופה יפה בחייה, אבל תקופה קצרה, כי אך הוטב לחולה, מיד נכנסה
        לטפול הכללי, ותמרה שבה לעבודת הרפת.  רק אז הרגישה בעיפות הגדולה ובאפיסות
        כחותיה.  מרוב חולשה התחיל העצב להתגנב שוב אל לבה.  אינה יודעת אי-זה דרך
        באה אליה השלוה, ואינה יודעת איך בא אתה העצב הזה, האוכל אותה מבשר ועד
        נפש.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יש שאינה יכולה לאכול וגם לדבר קשה לה, וכשפונים אליה בשאלה, שלא לפי
        רוחה, היא רוצה לגעור בשואל – הס, אל תדבר!  – אך היא מתאפקת שלא יחשדוה
        בשגעון.  בטוחה היא, שמתפתח בקרבה פסיכוז, כי אין שום בסיס אחר למצב-נפש
        חולני זה, העלול להתחלף בכל רגע.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >והלא גם זה מוזר:  – ברי לה, שמעיפות יתרה באה עליה הרוח הנכאה והיא זקוקה
        למנוחה, אבל מה היא עושה ברגעי הפנאי?  תחת  לנוח מעט, היא הולכת לאסוף את
        גללי-הבקר, הפזורים בכל קצות החצר, נושאת אותם אל המזבלה שמעבר לשער.  אין
        זה תפקידה, אבל הנה החבר עסוק, אין לו פנאי לטפל בכגון זה, ולה צר שהגללים
        יאבדו את חמריהם הטובים בגשם וברוח, צר לה על כל טפה טובה שיכולים להוסיף
        למזבלה ולכן גם אל הלולים היא חודרת, מטאטאה ומאספת את לַשְלֶשֶת העופות –
        הרי גם זה חשוב בשביל הזבל...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יש ונדמה לה, שזוהי עבודה חשובה מאד, ולעיתים הספק מתגנב אל לבה:  האם יש
        שמץ תועלת בכל אלה?  ואם גם תצרף את הטפול בפרות, האם כל זה משתלם בעד
        האוכל שלה, הלבשתה והנעלתה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הנה זה שלשה ימים רצופים מענה אותה המחשבה, שאינה יוצאת ידי חובה כלפי
        אלה, שהיא נמצאת בכפיפתם וצערה גדל במדה כזו, שאינה יכולה לחשוב עוד על שום
        דבר אחר.  ותוגה זו מתגברת ביחוד כשהעבודה אינה עולה בידה.  – לא שפשפה
        כראוי את כדי החלב, או שכחה להאכיל את אחד העגלים, כל משוגה קטנה בעבודה
        מעמיקה את צערה, ונדמה לה, שאינה מוכשרת להביא כל תועלת לקבוצה.  ונצבת
        לפניה השאלה:  למה הביאה את אמה לקרבן חפצה?  הלא אמה חולה מיום שנסעה מן
        הבית.  והלא היא היא שגרמה לכך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בחדרה של ברורה היא מחפשת הקלה כל-שהיא לנפשה הדואבת.  לעתות בין הערבים
        היא נכנסת שמה, מניחה את ראשה על מִכְסֶהָ של ברורה ועוצמת עיניה.  בעומק
        האפלה היא רואה הרבה צבעים בהירים – ירוקים, אדומים וצהובים.  ובהרימה את
        ראשה ובהחזיקה ביד החולה היא ממלמלת מתוך תוגה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הנה אמי שם גם היא חלשה וחולה, ואני עזבתיה על ידי אלה, שאינם מסוגלים
        לטפל בה כראוי. איך יכולתי לעשות את העול הזה, את החטא הזה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– את החטא?  איזה חטא, תמרה? – שואלת ברורה בקולה הרפה – להפך, והלא אנו
        מכפרים על חטאיהם של הורינו, אשר לא נענו לקריאה ולא הלכו בעוד מועד לגאול
        את הארץ.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אפשר שחטאו ואפשר שלא הוכשר עוד הדור – עונה לה תמרה מתוך עצבות – בכל
        אופן אמי הבינה יפה את רעיון בנין החיים החדשים בארצנו.  כשהלכתי לעבוד
        באחוזה הגרמנית, הקרובה לעירנו, נבא לה לבה אנה פני מועדות.  אפשר שמאז
        התחילה מחלתה להכות בה שרשים, אבל אני לא הייתי פנויה לחשוב בזה.  כולי
        שקועה הייתי בעבודת ההכנה.  כשבא היום ובשרתי לה את דבר הנסיעה, החוירה אמי
        מאד, אבל לא השמיעה מלה של התנגדות; היא התחזקה וקבלה את צערה גם בלי דמעות
        בעינים.  </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >תמרה השתתקה.  באותה שעה כבר מצצה העלוקה את לבה.  "אמא, אמא&#39;לי – האם
        סולחת היא לה במחלתה ובסבלה?"</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >זה כבר שצלצלו לארוחת-הערב וברורה מזרזת אותה ללכת לאכול.  תמרה קמה, רצתה
        ליַשֵר את גבה, – כה כואב הגב מעבודת היום הקשה, – וחזרה וישבה בקצה המטה,
        פהקה ואמרה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– מה לעשות לה לעצלות, שהתחילה תוקפת אותי?</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– תני לה מכות ותחדל – עונה ברורה בחיוכה הקל, הנעים – ותמרה מסתכלת בה
        ונהנית מעדינות פניה הנפלאים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בידה הגסה אחזה את אצבעות חברתה הדקות והארוכות. לחצה אותן בחזקה, גחנה
        אליהן ונשקה להן נשיקה ארוכה, לבבית.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אני אוהבת, אוהבת אותך, ברורה – מלמלו שפתיה החמות וזרם נפלא חשה עובר
        בגופה וכל העולם נמלא אהבה.  היא שכחה ברגעים אלה שיש לה עצבות, געגועים על
        בית, על אם, שיש לה כמיהה לדבר רחוק, בלתי-מושג, אשר לא תדע כַנותו
        בשם.  ברגע זה ידעה כי טוב לה בקבוצה ואין פנת-עולם חמה מזו.  רק מעט אהבה
        ורצון טוב, ואפשר להתגבר על כל הספקות והיאוש.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא עומדת בחצר ואינה נכנסת לחדר-האכילה.  בחוץ כה יפה.  סוף החודש, הירח
        איננו, אבל אוד נפלא שופע מן הכוכבים, ומה רבים הכוכבים בעמק הזה! רבוא
        רבבות עולמות עומדים על ראשך בנקודה קטנה זו.  למה הם נוצצים ורועדים ככה
        בהשקיפם על העמק הקטן הזה, בתוך ההרים העוטפים סוד ודממה?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >באותו ערב תקף את תמרה רצון כביר לכתוב מכתבים.  היתה כבר השעה מאוחרת
        ועוד דלקה המנורה בחדרה והעט לא הונח מידה.  החברים הזכירוה פעמים רבות
        מעבר למחיצות, שתלך לישון, כי השעה מאוחרת, אך היא ממשיכה את כתיבתה
        הקדחתנית ואינה שמה לב להערות.  אבל סוף-סוף הרגיזהּ הדבר – למה הם מאיצים
        בה? המביטים הם בעין רעה על המשחק הזה של השתפכות-נפש במכתבים
        ארוכים?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ואולי הצדק אתם?  – לוחש לה קול אחר – הן עליה להשכים קום וחובתה היא
        להקדים לשכב.  זמנה – זמנם!  ואין בזה משום רוע-עין, חלילה, כי הלא את
        טובתה הם דורשים, בהזכירם לה לכבות את המנורה ולשכב לישון.  איך אפשר
        אחרת? הצדק אתם – הן בשלש עליה לקום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שמה הכל במְגֵרָה, פשטה בגדיה, כבתה את המנורה ותשכב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הפעם נרדמה מהר, ובחלומה והנה בא לארץ ידיד-נעורים טוב ויבקר אותה
        בקבוצה.  קבלת הפנים היתה חמה ולבבית. היא דאגה שיהא לו נוח וטוב אצלם,
        האכילתהו מכל טוב הקבוצה ומִטָה הציעה לו כיד המלך – לבנה, רעננה, נקיה
        ורכה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >יחד ערכו טיולים בסביבה.  היא הראתה לו את כל עשרם.  בשיחתה היה מן
        ההתעוררות.  היה לשניהם מה לספר, לגלות, ופתאום עלה על דעתה דבר
        שטות:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >"הרוצה אתה, ברוך, להתחרות אתי בריצה?"  שאלה, וטרם הספיק לענות, התחילה
        לרוץ ופתאום הרגישה, שאינה רצה, אלא מאמצת את זרועותיה, מתרוממת מעל הארץ
        ועוברת בתעופה מרחק גדול, אחרי-כן היא יורדת, עומדת על רגליה ומחכה לברוך
        המתקרב מלא השתוממות: </span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– איך זה, תמרה?  והיא צוחקת, מתרוממת שוב ועפה הלאה, הלאה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כשהקיצה עוד הרגישה בתעופה, וכשהלכה בעלטת הבוקר עם הדליים אל הרפת, נדמה
        לה, כי כבר קרה אותה לעוף גם בהקיץ.  עוד אשתקד, בטַיְלָהּ בליל-אפל לבדה
        והשתוקקה לתנועה, אז אמצה את זרועותיה, התרוממה קצת מן הארץ, ובקפיצת-תעופה
        עברה את כל שורת הברושים שבגדר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >לבה של תמרה קל עליה, והיא שמחה מאד על ההתגלות החדשה, שהיא יכולה להתרומם
        מעל אדמתה ולעוף.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >אך מצב-רוח מרומם זה לא ארך הרבה אצל תמרה, – עוד באותו בוקר, בהכנסה אל
        הרפת, מצאה את "ברודה" רובצת מבלי יכולת לקום.  ו"ברודה" זו, הן מחכים
        ללידתה בכליון-עינים, הרבה תקוות תלויות בחלבה.  כי היא נותנת מספר ליטראות
        יותר מכל פרות הרפת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מדדו את החום, הגישו לה "עזרה ראשונה", ושליח מיוחד רכב העירה לקרוא לרופא
        הבהמות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ותמרה ידעה, כי גורלה שזור לעולם בגורל האנשים הטובים והטהורים האלה,
        ההולכים שחוח תחת נַטֶל הדאגה, המתחדשת עליהם בכל יום תמיד.</span
      >
    </p>
    <p class="c0" dir="rtl"><span class="c1"> </span></p>
    <p class="c10 text-left" dir="rtl">
      <span>כפר-ילדים – מאיר-שפיה</span>
    </p>
  </body>
</html>`;

export default page;
