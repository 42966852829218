import styled from "styled-components";

import Header from "./components/Header";
import UpButton from "./components/UpButton";
import homeBGimg from "./images/homeBGimg.jpg";
import { useState } from "react";

import "./App.css";

const App = (props) => {
  //console.log("App props", props);

  const [currentPath, setCurrentPath] = useState("");

  const isHome = () => window.location.pathname === "/";

  // useEffect(() => {
  //   console.log("App useEffect - window.location", window.location.pathname);
  // }, []);

  return (
    <Main className='App' ishome={isHome().toString()}>
      <Header />
      {props.children}
      <UpButton />
    </Main>
  );
};

export default App;

const Main = styled.div`
  background-image: url(${(p) => (p.ishome === "true" ? homeBGimg : "none")});
  background-color: #f9ede2;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
