import {
  moreOnTheGirlsQDesc,
  girlLetterCopyDesc,
  zionPilgrimsDesc,
  aPicFromBrotherDesc,
  aFarmerMemoryDesc,
  openQuestionsDesc,
  nightAndDayInRehovotDesc,
  fromRishonToMargeDesc,
  onForeignLandDesc,
  poorWriterDesc,
  theLightDesc,
  aThingDesc,
  onIsraelLandDesc,
} from "../pages/herArticles/descriptions";

import {
  moreOnTheGirlsQ,
  girlLetterCopy,
  girlLetterCopy_modal,
  zionPilgrims,
  zionPilgrims_modal,
  aPicFromBrother,
  aFarmerMemory,
  openQuestions,
  openQuestions_modal,
  hiddenQuestion,
  nightAndDayInRehovot,
  fromRishonToMarge,
  theLight,
  theWomanOnBuildingRishon,
  theWomanOnBuildingRishon_modal,
  aThing,
  onIsraelLand,
} from "../pages/herArticles";

const texts = {
  pageUrl: "/herArticles",
  // subTitle: "מאמרים וכתבות פרי עטה של נחמה פוחצ'בסקי",

  items: [
    {
      name: "moreOnTheGirlsQ",
      title: "עוד על שאלת הבנות",
      //subTitle: "",
      description: moreOnTheGirlsQDesc,
      doc: moreOnTheGirlsQ,
    },
    {
      name: "girlLetterCopy",
      title: "העתקה ממכתב אחת מבנות ציון לאחיה",
      description: girlLetterCopyDesc,
      doc: girlLetterCopy,
      modalsContent: [
        {
          doc: girlLetterCopy_modal,
          isImage: false,
        },
      ],
    },
    {
      name: "zionPilgrims",
      title: "עולי ציון",
      description: zionPilgrimsDesc,
      doc: zionPilgrims,
      modalsContent: [
        {
          doc: zionPilgrims_modal,
          isImage: false,
        },
      ],
    },
    {
      name: "aPicFromBrother",
      title: "תמונה מחיי אחינו בראשון לציון",
      description: aPicFromBrotherDesc,
      doc: aPicFromBrother,
    },
    {
      name: "aFarmerMemory",
      title: "מזכרונות אחת האכרות בא״י",
      description: aFarmerMemoryDesc,
      doc: aFarmerMemory,
    },
    {
      name: "openQuestions",
      title: "שאלות גלויות",
      description: openQuestionsDesc,
      doc: openQuestions,
      modalsContent: [
        {
          doc: openQuestions_modal,
          isImage: false,
        },
      ],
    },
    {
      name: "hiddenQuestion",
      title: "שאלה נעלמה",
      doc: hiddenQuestion,
      hidden: true,
    },
    {
      name: "nightAndDayInRehovot",
      title: "לילה ויום ברחבות",
      description: nightAndDayInRehovotDesc,
      doc: nightAndDayInRehovot,
    },
    {
      name: "fromRishonToMarge",
      title: "מראשון-לציון עד מרג' עיון",
      description: fromRishonToMargeDesc,
      doc: fromRishonToMarge,
    },
    {
      name: "onIsraelLand",
      title: "בארץ ישראל",
      description: onIsraelLandDesc,
      doc: onIsraelLand,
    },
    {
      name: "onForeignLand",
      title: "בנכר, רשימה מן החיים בארץ ישראל",
      description: onForeignLandDesc,
      doc: null,
    },
    {
      name: "poorWriter",
      title: "משורר עלוב",
      description: poorWriterDesc,
      doc: null,
    },
    {
      name: "onFreshGrave",
      title: "על קבר רענן",
      description: theLightDesc,
      doc: theLight,
    },
    {
      name: "theWomanOnBuildingRishon",
      title: "האשה בבנין ראשון לציון",
      description: "[1932]",
      doc: theWomanOnBuildingRishon,
      modalsContent: [
        {
          doc: theWomanOnBuildingRishon_modal,
          isImage: false,
        },
      ],
    },
    {
      name: "aThing",
      title: "לזכר אסתר מרגולין",
      description: aThingDesc,
      doc: aThing,
    },
  ],
};

export default texts;
