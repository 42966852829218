const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c6 doc-content">
    <p class="c0" dir="rtl">
      <span class="c1"
        >עם דמדומי בקר העיר את שרה לאה העקרונית משנתה צפצוף עקשני של זוג אנקורים
        מעל ענפי התות העבה שמול חלון קיטונה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא קמה, בעצלות נטלה את כליה מעל הכסא הסמוך ונשארה יושבת אתם במטה,
        –  הריה כה שבורה מכבוסה דאתמול, כה רוצה לפוש עוד רגע, רגע קט!...  אבל
        לא, אין פנאי! – הרי פרץ כבר מטפל שם בפרדות; הוא יוצא היום לחרש את אדמת
        נניה.  רחוק! צריך להכין לו מזון לכל היום...  מהר, מהר לחליבה! – בקבוק
        חלב יקח נא אתו!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושרה לאה תוקעת רגליה לתוך זוג סנדלי עץ.  נכנסת אל המטבח, מציתה אש בכירה
        ושופתת קֻמקום לקפה.  וכאן נוטלת היא מחלבה ומסתלקת בזריזות.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– צפרא טבא, פרץ! – קוראת היא לו בעברה את החצר ובפתחה את דלת הרפת, – מה
        זה – ערפל היום?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא אינה שומעת מה שהוא עונה לה ונכנסת פנימה.  צוברת מהר במגרפה את הזבל
        לפנה אחת, וזורקת על המקומות הטחובים את התבן היבש, שנצטבר אצל
        האבוסים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ונוטלת שרה לאה סל גדול מפנת הרפת, נושאת אל המתבן וממלאה אותו תבן רך
        וטוב, שהיא מרקדת ממנו נפה נפה לכל פרה, מערבת אותו עם סובין ונגשת
        לחליבה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אכלי, אכלי, יפתי! – דובבת שרה לאה, כשהיא מטפחת בחבה על צדה של פרה
        שחורה ושמנה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נתנה היא לעגלה לינק תחילה, וכשהדדים נמלאו חלב, הרחיקה מהן את העגלה
        בסטירה קלה והתחילה לחלב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >קולח, קולח הנוזל הלבן מתוך העטינים המלאים ופני החולבת נוהרין: – היום
        יהא לה כנראה הרבה חלב, תמלא המחלבה משתי הפרות!...  אבשלומ&#39;קי ישתה;
        גם הרצליה תרוה חלב.  ללקוחות אפשר יהא לתת כמה שירצו ועוד ישאר לה לערב
        להכין לפרץ ארז בחלב נקי בלי שום תערובת של מים, כמו שהוא אוהב.  –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וגם לה, לשחומה קטנה זו, תשאיר דד מלא! – שרה לאה מעיפה עין על העגלה
        הגחונה על האבוס ומתענגת על יפיה: – כמה נאה שובבה זו! הריה ממש כאחותה
        הבכירה שדומה כבר במקצת להאם. כמה היא רצינית עתה ושקועה באכילה! אבל אחרי
        לכת אמה אל השדה ויקרה לה, לשרה לאה, להכנס אל הרפת מה תעשה השובבה? הרי
        תקפץ,  תפזז סביבה, תלקק את ידיה, תטפס על גבה בשתי הרגלים הקדֻמות ופתאם,
        – קפיצה! – והיא כבר בקצה החצר ומתעלמת מן העין. – – –</span
      >
    </p>
    <p class="c5" dir="rtl text-center"><span class="c1">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >נסע פרץ לעבודתו, הילדים שתו, אכלו, ונסתלקו בקריאות שלום לבית הספר, ושרה
        לאה רק אז נזכרה שעוד לא שתתה כלום.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא ממלאה לה כוס קפה ושותה אותו בעמידה על יד החלון, סוקרת בין השִמְשות
        ותוהה שעוד לא פסק הערפל, עוד לא התגלו צבעי הבקר, וכל היקום מכֻסה בצבע
        וגון אפורי אחד.  ותוהה היא גם על עגולו הקטן, החורור של השמש, המתיז היום
        אור משונה כה קר!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >היא נועצת עיניה במרחק לחפש במראות החביבים, ואינם! –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הרי נעלמו השדות, הגנים והגבעות הירקים, – הכל נהפך לערפל אחד דק
        וזולף.  רק בחצר נראו שלשת הקיקיונים ופיתלקה אחת העומדים כאן כאבלים;
        אליהם התקרבה משוכת הצבר; ואותה שדרת השקדים שמעבר למשוכה דומה כאלו
        התרוממה, עלתה בצבר ונאחזה בברקניו.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מבין ענפי אחד הקיקיונים עולה צפצוף דק ונוגה של צפור מתלוננת על הטחב
        הקרירי של בקר זה.  מקצה החצר, ששם מסתעף זית בודד ענו לה בקול תלונה יותר
        צלול, – חברה זו התמרמרה והטיחה כלפי היוצר בעזות...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גם בקרקורה של התרנגלת הלבנה נשמעה נעימה זרה.  היא התהלכה בלי סבלנות
        לפני פתח הלול ותהרהר, הכדאי הוא להספח לחברתה המנמרת ובקופה אחת אתה להטיל
        את ביצתה, – או מוטב אולי לחכות שעה קלה?...  בינתים נגש אליה התרנגל
        היהיר, בעל הכרבלת האדמה, והתחיל מסתבב אצלה בפשוק כנפים ובגנדרנות בסברו
        להתעלס אתה באהבים, והיא מיד נמלטה ממנו ברגז, – כלום יש לה פנאי כעת? הרי
        עליה למלאות את התפקיד הכי רציני שבבריאה! – ותקפץ ישר אל קופת
        חברתה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ושרה לאה פותחת לרוחה את חלונה, גוחנת קצת ונועצת עיניה למטה, לשני שתילי
        זינזילה, שנטעה כאן לפני ימים מועטים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מבקשת היא במבט צמא עלה חדש, – שמא נקלטו.  הוי, כמה היא רוצה שיקלטו
        השתילים! – הרי יום, יום היא עודרת סביבם, משקה אותם, אך שיקלטו, ואז – שנה
        תעבר או שנתים ומול חלון מטבחה יתנוססו שני עצי זינזילה יפים, עגולים
        כשמשיות נחמדות! האח, כמה טוב יהיה, מה נעים!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכששרה לאה גמרה אחרי שעה קלה לכבד את הבית ותגיע עד המדרגות, נוכחה
        שהערפל מתפזר. – הריה מבחינה בתים, גנים, המגרש שלפני ביתה התגלה בפרחיו;
        התחיל גם השמש לצאת מחתוליו ופורש אורות וצללים מסביב; השמים נעשו עמוקים,
        בהירים וקרעי עננים מכסיפים נתלו בגבהם התכול.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >מגדר הגן פורצים סנבולים אדמים עם עלי הקטיפה שלהם. מתלהלהים הם על
        גבעליהם הארכים ונעים ומברכים על החום הרך והעדין הזולף סביבם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ובגן, – הפול, החמצה, תפוחי-האדמה, הבצלים והחסה, – כמה מוריקים ויפים
        העלים אחרי רבוי הטללים, שנפלו עליהם!...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >רק על הסלק תוהה שרה לאה, – בשלמה אין עליו מסמיקים?.. שמא הזריעה ממין
        גרוע היתה? או אולי משנה עברה היא?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומצטערת היא על הסלק, וחוששת שעברה העונה ולא תשיג שתילים אחרים, – מה
        תעשה בלי סלק טוב? – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אבל שטיה שכמותי! – זורקת פתאם שרה לאה תוכחה כלפי עצמה, – מחכים לי שני
        לקנים עם כביסה שלא נגמרה, – ואני עומדת כאן בבטלה כאלו שבת היום!</span
      >
    </p>
    <p class="c5" dir="rtl"><span class="c1">*  *  *</span></p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשעה מאחרת בלילה עוד טפלה שרה לאה בשיורי עבודה של היום הקשה הזה עם
        גמירת כביסה, גלגול הלבנים, גהוצם וסדורם.  רק ההטלאה נשארה למחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בקושי היא מתהלכת בחדריה. נפוחות הרגלים וכואבות, ובעקביה כמו שפודים
        דוקרים עם כל פסיעה ופסיעה.  נפשה שואפת למנוחה, אבל השעה עוד לא הגיעה
        לכך, עוד יש לה לתקן מחמצת לאפית לחם מחר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בשארית כחה היא מטפלת בעבודה זו וכבר חשה בהתרגזות עצביה, אלא שהיא משדלת
        את עצמה:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– הֵי סבלנות, גברת! הרי עוד רגע קט, רק ברדיד החם לכסות את המלוש, –
        וחסל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >גמרה, – ובתענוג היא פשטה בגדיה והתמתחה במטה.  אבל טרם שהספיקה להרדם
        והרי דפיקה בדלת.  "מי זה יכול היות בשעה זו?" – תוהה שרה לאה, וכשבצבצה
        המחשבה, שמא קוראים אותה, הזדעזעה: – "הילך הפתעה"! – –</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >בעצלות היא נוטלת כפש מן הקולב, מתעטפת בו ופוסעת לדלת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– ר&#39; ליזר?..  מה חדשות? – שואלת היא בצער את שמש החברה "פקוח חולים",
        שהכניס ראשו בדלת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אצל סלוצקין מסֻכן הילד, דרושה לינה והראש דגל שלחני להגברת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >שרה לאה עמדה כנדהמת ולא ידעה מה להשיב.  הריה מרגישה שאין לה כח, אין שום
        אפשרות ללכת ואף על פי כן גם להשיב את הדרישה ריקם אינה יכולה, היא מעודה
        עוד לא ענתה בשלילה על דרישת אגדתם.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >...  אם כן ללכת?..  אבל בבקר השכם הריה צריכה ללוש ולאפות לחם.  מי יעזר
        לה אם כחותיה לא יספיקו? היש לה רשות לותר על חובות המשפחה והבית?.. לא,
        אין רשות! אינה יכולה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– במחילה, ר&#39; ליזר, הפעם איני יכולה למלאות את תפקידי ומבקשת לקחת
        אחרת במקומי! – אומרת שרה לאה כמתחטאת.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– אי אפשר, גברתי, אין כעת למצא אחרת!</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >– אבל, במטותא מכם – הפעם, רק הפעם, תנו לי חפשה!..  תשכרו את אחת המפקחות
        בכסף, – ואני לא אוכל!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">ר&#39; ליזר מתכעס ומסתלק בתרעמות.</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >וכששכבה שוב שרה לאה במטתה, היא התחילה להתהפך מצד אל צד ולא יכלה להרדם
        בשום אופן. מוסר כליות תקפה ונקב, ושרט בלבה הדואב.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >...כיצד נהפכה לקשת לב שכזו? מה אתה? מדוע פתאם התחילה להתחשב עם כחותיה,
        עם אפשרותה? הרי קודם לא היה ככה, ותדיר נמצאו אצלה כחות בשביל כל חובותיה
        – ומה זה עכשו?</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >הוי, היא שונאת את עצמה, את רשעותה ואת חשבונותיה! אבל צריכים להתגבר,
        צריכים לחזר בתשובה!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >ומיד קמה שרה לאה בהחלטה גמורה ללכת כעת לבית סלוצקין, אלא שאבריה כבדים
        ואינה יכולה להזיזם ממקומם! – "מה לעשות? מה לעשות?"...  – והיא צונחה
        ביאוש על המטה.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1"
        >כובשת שרה לאה את פניה הלוהטים בכר, קונחת בו דמעות של צער וחרטה ודובבת
        חרש:</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c1">– הוי, רשעה, רשעה אנכי, רשעה! – – –</span>
    </p>
    <p class="c2" dir="rtl"><span class="c3"></span></p>
    <p class="c2" dir="rtl"><span class="c4"></span></p>
    <p class="c2" dir="rtl"><span class="c4"></span></p>
  </body>
</html>
`;

export default page;
