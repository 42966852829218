const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c4 doc-content">
    <p class="c1" dir="rtl">
      <span class="c0">על </span><span class="c2 bold">בכפר ובעבודה</span
      ><span class="c0"> – </span><span class="c2 bold">העולם</span
      ><span class="c0"> [1930 02 18]</span>
    </p>
  </body>
</html>`;

export default desc;
