// import { useEffect, useState } from "react";
import styled from "styled-components";
import ItemContainer from "./ItemContainer";

// import useWindowDimensions from "../services/useWinDimentions";

const ItemsList = (props) => {
  // console.log("itemsList props", props);

  // const { height, width } = useWindowDimensions();
  // const [menuSpacerWidth, setMenuSpacerWidth] = useState(true);

  // useEffect(() => {
  //   console.log("ItemsList useEffect calles");
  //   if (width < 720) {
  //     setMenuSpacerWidth(false);
  //   } else {
  //     setMenuSpacerWidth(true);
  //   }
  // });
  return (
    <OuterContainer>
      {/* <MenueSpacer fixedwidth={menuSpacerWidth.toString()} /> */}
      <Container>
        {props.items &&
          props.items.map((item) => {
            return (
              <div key={item.itemName}>
                <ItemContainer item={item} from={props.from} />
              </div>
            );
          })}
      </Container>
    </OuterContainer>
  );
};

export default ItemsList;

const OuterContainer = styled.div``;
const MenueSpacer = styled.div`
  // background-color: red;
  // width: ${(props) => (props.fixedwidth === "true" ? "260px" : "initial")};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // background: green;
  width: 100%;
`;
