const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c3 doc-content">
    <p class="c1" dir="rtl">
      <span class="c2 bold">המליץ</span
      ><span class="c5"> ז&#39; אדר א תרמ"ט</span><span class="c0"> </span
      ><span class="c5">[1889 02 08] </span
      >
      </p>
      <p class="c1" dir="rtl">
        <span class="c5 c6">על הצורך בחינוך לבנות</span>
      </p>
  </body>
</html>`;

export default desc;
