const modal = `<body class="c9 doc-content">
    <p class="c8 italic" dir="rtl">
      <span class="c7"
        >לפי תוכן המכתב נראה שהוא מתייחס לגרוש מיפו בשנת 1917, בתחילת אותה שנה
        התאכסנה אפרת בת נחמה פוחצ&#39;בסקי בבית יאפו ביפו
      </span>
      <a href="/letters/fromEfrat1916_7#11.1.1917" target="_blank">
        <span class="c2 tooltip"
          >כשלמדה בסמינר למורים<span class="c10 tooltiptext"
            >ראו מכתבים ששלחה אפרת לנחמה מבית יאפו ביפו.</span
          ></span
        ></a
      >
      <span class="c7"> שם. </span>
    </p>
    <br />    

    <p class="c1" dir="rtl">
      <span class="c3">יום ה&#39;</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3">ידידי פסח וגולדה</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3">יאפו יפו</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3"
        > בתשובה על שורותיך הקצרות, הנני להגיד כי בזרועות פתוחות אקבל אתכם ואת
        פעוטותיכם בין קירות ביתי. ירחם רק המקום שלא נצטרך גם מפה לצאת בגולה
        בידידות רבה
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3">נ. פוחצ&#39;בסקי</span>
    </p>
    <p class="c4" dir="rtl"><span class="c3"></span></p>
  </body>`;

export default modal;
