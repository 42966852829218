const modal = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c1 doc-content">
    <p class="c0 italic" dir="rtl">
      <span class="c2 bold">הצפירה</span><span class="c3 c4">  16.11.1889 </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c5"
        >בראשון לציון נוספה אשה אחת יודעת היטב שפה עברית, והיא הגברת נחמה שנשאה
        להגנן פוחאציוסקי. נקוה כי היא תהיה אחת מהעובדות בתחית הלשון
        בארצנו.</span
      >
    </p>
  </body>
</html>`;

export default modal;
