const page = `<body class="c0 doc-content">
  <p class="c2 italic" dir="rtl">
    <span class="c5"
      >יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי, בעלה של נחמה הדריך בהתנדבות אנשי
      העליה השניה והשלישית שהתיישבו בעמק יזרעאל וסביבותיו</span
    >
  </p>
  <br />

  <p class="c2" dir="rtl" id="h.gjdgxs">
    <span class="c1"><br />בית אלפא י"א אייר תרפ"ו</span>
  </p>
  <p class="c2 c3" dir="rtl"><span class="c1"></span></p>
  <p class="c2" dir="rtl">
    <span class="c1">נחמה יקרתי,</span>
  </p>
  <p class="c2 c3" dir="rtl"><span class="c1"></span></p>
  <p class="c2" dir="rtl">
    <span class="c1"
      >בערב שבת אחרי הצהרים עזבתי את דגניה א אחרי שגמרתי בהצלחה מרובה את כל
      העבודה הנחוצה, סיפוח הצעירות לעבודת הנטיעות עלתה בידי על צד היותר טוב ושמי
      מבורך. כעת בדגניה לא פחות מקרית ענבים נפרדתי מהם, זאת אומרת מכל הקבוצה כאח
      ממש. הסבה שעזבתי אותם היא המכתבים שקבלתי מהעמק שמחכים לי מיום ליום. בימים
      האחרונים סבבתי שמה בין הקבוצות השכנות כמו כנרת ודגניה ב. ומה נעים היה לי
      שבהרבה מקומות מצאתי כבר פרי תורתי שהתקבלה מסיורי הראשון, וכעת הבטחה גמורה
      שלא ינטו בדרך אשר אני מתוה להם ימין ושמאל. באתי לפנות ערב לבית אלפא ערב
      שבת. השמחה היתה גדולה שמחר שבת וכלם חפשים מעבודה ויכלו לעשות עמדי טיול
      בכרמים. בבקר יצאתי בכנופיה גדולה וכמובן מעורבת, חברים וחברות, כי החברות
      כבר שמעו מה שעשיתי בדגניה. והנה תפשתי חברה אחת בגנבה שרושמת בפנקסה כל מה
      שאני מורה להם, וזה מצא חן בעיני. לכן סדרתי כבר היום קבוצה מעורבת לקשירת
      הגפנים והן מצטינות בעבודתן מהגברים. אני פה לא אשאר זמן רב, מפה אעבור לתל
      יוסף, ומשם לגבעה ולעין חרוד. מכתבים תכתבי לעין חרוד, ומשם אקבל לכל נקודה
      שאמצא וגם מדגניה ישלחו לי את המכתבים שיתקבלו שם על שמי לעין חרוד.</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1"
      >המכתב שלך עם הנזיפות על זה שאני לא מתמסר לכרמי קבלתי בזמנו אך לא חפצתי
      לענות לך עד שתתקר רוחי. יש הרבה מה לענות אך שתיקה יותר יפה. ובכלל הענין
      לעמול עד יומי האחרון - ועוד בהיותי נגוע - אך ורק בשביל עצמי בעת שיכולים
      להנות מתורתי מאות משפחות - היכן ההגיון? מה חסר לי לחם, והאם אני במצב
      בריאותי מחויב עוד לעבוד בשביל בני ובני בני. אני צריך לפי ההגיון למצוא איזה
      ספוק בחיים בשביל להמשיך את החיים. יש אנשים במצבי שמתמסרים לשכרות, או למשחק
      בקוביא ודכותיה, האם לא יותר יפה שאני מתמסר לדברים מועילים אם לא לי לפחות
      לאחרים. ובזה נכללו גם התמסרותי לבניותם?
    </span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1"
      >אני מוצא בזה ספוק לשכוח על איזה זמן את כאבי, ומה שאת חושבת, כי מנוחה ואי
      מעשה מקיל עלי את כאבי זה לגמרי לא נכון. אדרבה בין כה וכה הכאב מציקני אך אם
      אני פנוי, מרגיש יותר באפסתי שאני אינולידי. אך אם אני עושה איזה דבר
      פרודוקטיבי יהיה בשביל מי שלא יהיה אני שוכח את רישי, וכפי עניות דעתי אין לך
      שום רשות לפזר מלח על פצעי, ולגלות אזני למוסר שאני מזניח כרמינו, את בעצמך
      יודעת כמה חביבים עלי הכרמים, אך אם אי אפשר לי להוציא אל הפועל כפי הבנתי
      בכוחי ועצם ידי, ולדרוש הכל משכירי יום, ושלבי תתפקעת בכל רגע מעבודתם
      הפסולה, זה קצור ימי בהחלט, והאם בזאת אביא תועלת רבה למשפחתי?</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1"
      >הנה המצאתי אופן חדש בסימון. כעת אני נותן שעורים למעשה ומפיץ בכל הארץ את
      תורת הסמון. ואם תאמר האם עד כה לא סימנו ונטעו כרמים בלעדי המצאתי, הנה כרם
      שנטעו בבית אלפא השנה ודוקא אנשים אינטלגנטים מאד, מוכיח עד כמה נחוץ התיקון
      הזה שהכנסתי בחיים והכל מודים לי בעד השעור הזה. למחר הוזמנתי לבית הספר חצי
      חקלאי שבבית אלפא, שנמצאים בו כארבעים ילד, לתת להם את השעור בסימון והיום
      סדרו את המכשיר והילדים מחכים בכליון לרגע שילמדו ממני איזה דבר מועיל. גם
      הבטחתי להם ללמדם שעור תכני (=טכני, מעשי) בהרכבות הלא זה עושר שמור לבעליו
      שאין אף שודד ערבי יכול שדוד ממני, ודגניה שרבץ עליה עול של ארבע מאות עצי
      זית גרועים שהביאו אך הפסד, וכעת במשך אי אלה ימים הורכבו בהם כארבעת אלפים
      הרכבות ממינים משובחים הלא זה מצבת אל מות בשבילי. והכרמים החדשים שנוטעו בכל
      העמק אשר חותמתי טבועה עליהם והכל מודים כעת שהשטה שלי הצילה את המצב, האם כל
      זה לא נותן סיפוק לנפש נכאה. כמה שיארך עוד נסיעתי לא אוכל לדעת. לפי שעה עלי
      לבקר בארבעה משקים. כמה ימים זה יקח אי אפשר לדעת. אחרי זה בדעתי לבקר את
      גינגר, נהלל, ואולי גם מושב החסידים, אחרי כן זכרון ובנותיה ואפשר עוד אי אלה
      מקומות שאינם כעת בפרוגרמה. מעשה יפה נודע לי בהיותי בדגניה *** שורר בהיותו
      לרגלי התעמולה בפולניה התאהב שמה באיזה עלמה והתחתן והביא אותה לארץ עִמַדוֹ
      ושלח אותה לדגניה בבקשה לקבלה על איזה זמן אך הם סרבו לקבלה מפני אי מוסריות
      שבדבר. ומה נשמע עם זמירה? אומרים שכבר קבלה גט פטורין ממנו. הספור הזה כל כך
      הרעישני שלא נתן לי מנוחה איזה ימים. עלובה כזו גורשה מקינה עם גוזלה ביחד
      מסכנה, בודאי לכם הדבר לא חדש אבל בשבלי זה היה הפתעה נוראה. מצב בריאותי טוב
      מאז אל תדאגי לי כלום. מה נשמע בבית איך מצב של אמא, ומה גדעון השכחן היש לו
      עוד צורך בסבא?</span
    >
  </p>
  <p class="c2" dir="rtl">
    <span class="c1">שלום לאפרת ולצבי ולכל הילדים.</span>
  </p>
  <br />
  
  <p class="c2" dir="rtl">
    <span class="c1">שלכם מיכל</span>
  </p>
  <br />
  
  <p class="c2" dir="rtl">
    <span class="c1">צעיר מדגניה שנסע לפולניה הבטחני להכנס. האם היה?</span>
  </p>
  <p class="c2" dir="rtl">
    <span class="c1"
      >בזו הרגע אני נמצא עם משה גלדנברג ליד שולחן אחד וכותבים לכן בקשני למסור
      לכם שלום בשמו</span
    >
  </p>
</body>`;

export default page;
