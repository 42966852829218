const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c13 doc-content">
    <p class="c1 italic" dir="rtl">
      <span class="c0 c6"
        >בתיק א20/5 בארכיון של מוזאון ראשון לציון מופיע הטקסט הבא בכתב ידה של
        נחמה פוחצ&#39;בסקי. יתכן שהטקסט נכתב כהכנה לנאומה במסיבת עתונאים שנערכה
        במלאת חמישים שנה לראשון. אותו כינוס, ונאומה של פוחצ&#39;בסקי הוזכרו
      </span>
      <span class="c0 c6 c16 modal-button" id="modal-button-1">בעתון דבר</span><span class="c0 c6 c7">.</span>
    </p>
    <br/>
    
    <p class="c1" dir="rtl"><span class="c0 c3">1932</span></p>
    <p class="c1" dir="rtl">
      <span class="c3 c8">נחמה פוחצ&#39;בסקי</span>
    </p>
    <h3 class="c10" dir="rtl">
      <span class="c9 c12">האשה בבנין ראשון לציון.</span>
    </h3>
    <p class="c1 c4" dir="rtl"><span class="c3 c0"></span></p>
    <p class="c1" dir="rtl">
      <span class="c3 c0"
        >המושבה היא בת חמשים, ועטורה נזר זהב של חולות-ים, המפרידים בינה ובין ענק
        זה, אבל מעבירים אליה לעתים את הד רעש גליו הממלא את אוירה בשעות לילה סוד
        שיח תהומות הים. חגגה המושבה הזאת את חג יובל בחמשה עשר באב זה ואם האשה
        בראשון לציון תבא לעשות את חשבון נפשה העבודה שלה במשך יובל שנים, היא תמצא
        ספוק רב בפעולתה.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >עוד בצעדי הגבורה הראשונים של סלילת דרך במדבר-זה, נלותה האשה לבעלה כחבר
        שוה בעבודה. הוא היה עסוק בשדה ובכרם והיא - בבית ובחצר, ושעות עבודתה הרי
        הן תמיד מרובות משל הגבר. הוא, בשובו מן השדה ובסדרו את סוסיו ואורותו, כבר
        פנוי למנוחה ולקריאה. ולא כן האשה: עם בוא הערב מתחדש עמלה, כי צריכה היא
        להכין את ארחת ערב לבעל, להאכיל את ילדיה, לרחצם ולהשכיבם. אחרי כן שוב
        רחיצת כלים, וסדור מטה, והחרוצות ביותר עוד עוסקות אחרי כל אלה בתפירה, שלא
        הספיקו לגמר ביום או לשות לחם לאפיה בבקר השכם.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >מאוחר בלילה באה השעה המאושרה של מנוחה במטה, אבל לא תמיד מסתיע מלתא
        להנות מן המנוחה הזאת, כי מתעורר פתאם ילד זה או אחר, פעם בוכה בלי סבה
        חשובה ופעם מסבת חום או כאב בטן ומנוחת האם נגזלת, נגזלת...</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >ככה היה בשנים הראשונות להתישבות. ואיך התיחסה האשה לתפקידה הקשה? אין
        תלונה, אין רטון על מה שהטיל עליה הגורל, כי כלום יש מקום להתאוננות בלכת
        אדם לבנות את מולדתו הישנה החדשה ולהחזיר לעם תועי מקלט בטוח? אם נגיד שלא
        כל קבוץ הנשים עמד על מדרגה גבוהה של הכרה לאומית, אבל כלן בלי יוצאת מן
        הכלל מלאו את חובתן במסירות ועזרו להתפתחות והתקדמות המושבה.
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >לא לחוג ביתה הצר לבד התמסרה האשה. – לאחר שהמושבה הרחיבה את גבוליה,
        הגדילה כרמיה, בססה את מצבה והנהיגה שלטון פנימי לנהל את הצבור, התעוררה
        האשה לצאת לשדה פעולה צבורית. האגודה הראשונה, שיסדו הנשים בראשון לציון,
        נשאה עליה את השם "דבורה", לזכר דבורה בן-יהודה, האם העבריה הראשונה, אשר
        סללה לאמהות את הדרך להחיאת השפה בפי ילדיהם. לאגודה זו היו מטרות
        תרבותיות, לאומיות גרידא. והנה באו החיים עם דרישות אחרות והפנו את לב האשה
        לצרכים חמריים של הצבור.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >במושבה התישבו עולים חדשים, שלא מצאו עוד במה להתפרנס, דרושה היתה להם
        עזרה ועצה, - ומי יטפל בכגון דא אם לא האשה, הערה יותר מן הגבר לסבל זולתה?
        קופת העזרה נוצרה ממקורות שונים: היו חברות, שנתנו שעורים פרטיים ואת שכר
        הלמוד הכניסו לקופה. אחרות התנדבו לאסף מס מבעלי היכלת, או לתבע כל מיני
        כספי עונשין להגדיל את קרן העזרה. ככה עבדו יחידות עד שנוסדה במושבה אגודה
        אחת גדולה, שנקראה בשם "לינת הצדק", משום שהחברות והחברים שנספחו לאגודה
        טפלו בכל מיני חולים גם בלילות.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >וזה היה כבר בזמן העליה השניה, שהביאה אתה צעירים חלוצי עבודה. נכנסו
        למושבה לשם עבודה, אבל הרגל אין בה, התנאים קשים, החום בקיץ מציק בשדה,
        ההתאקלמות מפילה אותם אחד, אחד למשכב. ידי האגודה מלאות עבודה, - נחוץ לעזר
        ברפואות, מכשירי רפואה, בהזנה, טפול והשגחה. לכל זה דאגה "לינת הצדק"
        בעבודתה המאומצת.
      </span>
    </p>
    <p class="c10 text-center" dir="rtl"><span class="c3 c0">----------</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בימי המלחמה העולמית עמדה האשה מוצקה בתוך אותו הסבל הרב, שמקיף אותה מכל
        הצדדים. בשנה הראשונה למלחמה, בעת אשר לחרב המתהפכת על העולם, נוסף השונא
        הפנימי – </span
      ><span class="c0 c15">הארבה</span><span class="c0">, </span
      ><span class="c0 c9 c6"
        >{אותה מכת ארבה והמאבק בה מתוארים בזכרונותיו של בעלה: </span
      ><span class="c5"
        >מהתם להכא סיפור חיים של עובד אחד, יחיאל מיכל שלמה זלמן פוחצ&#39;בסקי,
        יד בן-צבי 2013, עמ&#39;</span
      ><span class="c0 c9 c6"> 280-276} </span
      ><span class="c0"
        >לחמה האשה יחד עם הגבר להציל את יגיעת כפם משִני המחבל, שבא לעשות כליה
        בכל עץ וצמח, הנפגש לו בדרך. נורא היה האסון השני מן הראשון והרבה גברים
        נפלו ברוחם, נעשו עצבנים ומיואשים, אבל האשה עמדה על משמרתה לעודד אותם
        ולחזק רוח נכאה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובתקופה שאחרי הארבה, כשגברו הלחץ והמצוקה מידי הממשלה התורקית,</span
      ><span class="c0 c9 c6"> </span
      ><span class="c0"
        >אשר לקחה מן האוכלוסין מכל הבא לידה, התחיל הרעב להראות את עקבותיו בארץ.
        האשה עשתה אז לילות כימים לברר את החטה שהשיגה, לטחן אותה ולאפות לחם. את
        ילדיה הרכים הזינה בעדשים ו"בורגול" (גריסי-חטה) ונשארה עומדת על יד ההגה
        לנהל את ספינתה לבל תמוט. דאגה לבריות גופם של אנשיא וטפליא כמה שהספיק
        כחה, עד שהופיעה העזרה בצורת מכלת שלוחה מאת אחינו ואחיותינו באמריקה
        הברוכה, לכל אוכלוסי הארץ בלי הבדל דת ולאום. –  </span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >לא מתוך שמחה אלא מאונס, קבלה האשה את העזרה המושטה ביד נדיבה. בעיניה
        היתה זאת כעין התחלה ל"חלוקה" חדשה ויכאב לבה לישוב החדש שהוכרח ללכת בדרך
        הישנה, של קבלת "חלוקה"...
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >הממשלה התורקית הוסיפה להציק למושבות, את ראשון אמרה להחריב והנה בשעה
        מכריעה אחת שרתה האשה את מושבתה שרות גדול ונאמן: - ג&#39;מל פחה הוציא
        גזירת גרוש על ראשון לציון וראשי המושבה לא מצאו שום תחבולה להעביר את רוע
        הגזירה. כבר התחילו באי אלו בתים לארז את חפציהם ולהסתירם במרתפיהם או ביקב
        והנה באמצע הבהלה הזאת קמו נשי חיל בהחלטה, שהן ומשפחותיהן לא תזזנה מן
        המקום, לא תתננה לשלטונות התורקים להוציא את מזימתם אל הפועל ולהפקיר
        בידיהם את ראשון החמודה על בתיה וכרמיה וחרושתה. אם למות נדונו: - מוטב להם
        למות כאן, בקן שלהם, מלמות בנדודים!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 c0">קול האשה מצא הד בלבבות וראשון נצולה. </span>
    </p>
    <p class="c10 text-center" dir="rtl"><span class="c3 c0">----------</span></p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >עם הכרזת בלפור וכניסת האנגלים לארץ ישראל, הציף גל של תקוות מזהירות את
        כל הישוב, ובתוכו גם את האשה. קם הועד הזמני עם הנשים המשתתפות בו. התחילה
        אחרי כן יצירת קרן-הגאולה. והנשים פשטו את עדיהן מעליהן, תרמו לקרן והשתתפו
        בהתלהבות בעבודתה. והנה בתוך כל המערבלת של תקוות ופעולות שונות, צמחה בלב
        האשה השאיפה לשווי זכיותיה. נוסדו אגודות נשים בראשון לציון, תל-אביב,
        ירושלים אשר התחילו לטפל בשאלה זו ולנהל את התעמולה, אבל טרם שהתנועה הקיפה
        את כל נקודות הישוב – פתחו נשי ראשון במלחמה להשגת זכיותיהן.
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c0"
        >ארוכה היתה המלחמה וקשה, אבל האשה יצאה ממנה בנצחון. עוד בשנה השניה
        לכניסת האנגלים כבר ישבו </span
      ><span class="c0 c14">4 נשים נבחרות בועד המושבה</span
      ><span class="c0"> בראשון לציון.</span><span class="c0 c9 c6"> {</span
      ><span class="c0 c9 c5">ראו את פרוטוקול שלב הסיום של מאבק זה</span
      ><span class="c0 c9 c6"> [</span
      ><span class="c0 c9"
        >מפה ומשם, ההחלטה על בחירת נשים לועד המושבה ומימושה]</span
      ><span class="c0 c6 c9">}</span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >מאז עד היום לא נכרת התקדמות רבה בכוון זה, אבל החיים בכלל קבלו צורה יותר
        רחבה. בשנת יובלה מנתה ראשון למעלה מארבעים מוסדות עם ארגונים ותעשיות.
        כחמשים וחמש בארות כבר נחפרו בה והיא הלא הסתפקה במשך ארבעים שנה הקודמות –
        בבאר אחת למושבה ושלש לפרדסים! כעת משקות כל עשרות הבארות שטח של 6000
        דונמים פרדסים. לאנשים שידעו לסדר את המשק שלהם לפי רוח הזמן, נשקף עתיד
        בטוח. העבודה הקשה של האשה הוקלה פורתא והאפשרות נתנת בידה לתת מזמנה
        לעניני צבור שונים.  </span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >כמאתים חברות בראשון מאורגנות בשתי הסתדרויות. – הסתדרות נשים עבריות,
        העוסקת  בטפוח באם ובילד, והתאחדות נשים עבריות לשווי זכיות, המגינה על
        האשה במקרה של תסבכת בחיים משפחתיים וקפוח זכותה. בעיקר מבקשת ההתאחדות דרך
        לשנוי ערכין במהלך החוקים המעיקים על האשה מדור, דור.
      </span>
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >הנשים בראשון תומכות בשתי תחנות לטפול ביונקים, דואגות להזנת ילדים בשני
        גנים, מחלקות חלב לילדים זקוקים, לאנשים חולים ומבריאים. נותנות הלואות או
        עזרה כספית ורפואית, תומכות בזקנים וזקנות, מחלקות בגדים ומעות חטין
        לעניים.</span
      >
    </p>
    <p class="c1 c2" dir="rtl">
      <span class="c3 c0"
        >שתי חברות מנשי ראשון נבחרו למשפט השלום המקומי, יש להן ועד משפטי מתוך
        ההסתדרות עצמה לשרות הקהל, הפונה אליהן באמון. חברה אחת משלהן היא חברת
        המועצה המקומית, גזברית של המועצה, יש חברות בועדת התרבות של המועצה ועוד.
      </span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c3 c0"
        >האשה בראשון לציון תפסה את מקומה הראוי לה ורצונה הוא להביא תועלת בכל פנת
        חיים, שהיא חודרת לשם.
      </span>
    </p>
  </body>
</html>
`;

export default page;
