import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Outlet, useLocation } from "react-router-dom";
import ItemsList from "./ItemsList";
import MenuComp from "./Menu";
import useWindowDimensions from "../services/useWinDimentions";
import Modal from "./Modal";

let Page = (props) => {
  // console.log("page props", props);

  const location = useLocation();
  // const [location, setLocation] = useState(useLocation());

  const currentPath = location.pathname;
  // console.log("page location ", location);

  const ref = useRef(null);
  //const { height, width } = useWindowDimensions();

  const [menuContainerWidth, setMenuContainerWidth] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const pathHasExtantion = currentPath.includes("/" + props.pageName + "/");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // console.log("page useEffect props.pageName", props.pageName);
    if (props.mainDesc) {
      let nodeDiv = document.createElement("div");
      nodeDiv.innerHTML = props.mainDesc;
      // console.log("page useEffect  nodeDiv.innerHTML", nodeDiv.innerHTML);

      if (ref.current) {
        // console.log("page useEffect  ref.current", ref.current);
        while (ref.current.firstChild) {
          ref.current.removeChild(ref.current.firstChild);
        }
        ref.current.appendChild(nodeDiv);
      }
    }

    // if (width < 720) {
    //   setMenuContainerWidth(false);
    // } else {
    //   setMenuContainerWidth(true);
    // }
    setModalButton();

    if (!pathHasExtantion) {
      scrollToTop();
    }

    if (location.hash.length) {
      // console.log("location.hash", location.hash);
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const getPageChildByUrl = () =>
    props.children.find((child) => child.path === currentPath);

  const setModalButton = () => {
    let openPageModalBtn1 = document.getElementById("page-modal-button-1");
    let openPageModalBtn2 = document.getElementById("page-modal-button-2");
    let openPageModalBtn3 = document.getElementById("page-modal-button-3");
    let openPageModalBtn4 = document.getElementById("page-modal-button-4");
    let openPageModalBtn5 = document.getElementById("page-modal-button-5");
    let openPageModalBtn6 = document.getElementById("page-modal-button-6");
    let openPageModalBtn7 = document.getElementById("page-modal-button-7");
    let openPageModalBtn8 = document.getElementById("page-modal-button-8");
    let openPageModalBtn9 = document.getElementById("page-modal-button-9");
    let openPageModalBtn10 = document.getElementById("page-modal-button-10");
    let openPageModalBtn11 = document.getElementById("page-modal-button-11");
    let openPageModalBtn12 = document.getElementById("page-modal-button-12");
    let openPageModalBtn13 = document.getElementById("page-modal-button-13");

    if (openPageModalBtn1) {
      openPageModalBtn1.onclick = () => openModal(1);
    }
    if (openPageModalBtn2) {
      openPageModalBtn2.onclick = () => openModal(2);
    }
    if (openPageModalBtn3) {
      openPageModalBtn3.onclick = () => openModal(3);
    }
    if (openPageModalBtn4) {
      openPageModalBtn4.onclick = () => openModal(4);
    }
    if (openPageModalBtn5) {
      openPageModalBtn5.onclick = () => openModal(5);
    }
    if (openPageModalBtn6) {
      openPageModalBtn6.onclick = () => openModal(6);
    }
    if (openPageModalBtn7) {
      openPageModalBtn7.onclick = () => openModal(7);
    }
    if (openPageModalBtn8) {
      openPageModalBtn8.onclick = () => openModal(8);
    }
    if (openPageModalBtn9) {
      openPageModalBtn9.onclick = () => openModal(9);
    }
    if (openPageModalBtn10) {
      openPageModalBtn10.onclick = () => openModal(10);
    }
    if (openPageModalBtn11) {
      openPageModalBtn11.onclick = () => openModal(11);
    }
    if (openPageModalBtn12) {
      openPageModalBtn12.onclick = () => openModal(12);
    }
    if (openPageModalBtn13) {
      openPageModalBtn13.onclick = () => openModal(13);
    }
  };

  const openModal = (i) => {
    setModalIndex(i - 1);
    setShowModal(true);
  };

  const closeModal = (event) => {
    if (event.target === event.currentTarget) {
      // console.log("page closeModal called");
      setShowModal(false);
    }
  };

  if (getPageChildByUrl()) {
    return <Outlet />;
  } else {
    return (
      <OuterContainer>
        <MenuCon fixedwidth={menuContainerWidth.toString()}>
          <MenuComp inPage={true} />
        </MenuCon>
        <Container>
          {props.pageTitle && <PageTitle>{props.pageTitle}</PageTitle>}
          {props.mainDesc && <MainDesc ref={ref} />}
          <ItemsList items={props.items} from={props.pageName} />
        </Container>
        {showModal && (
          <Modal content={props.modalsContent[modalIndex]} close={closeModal} />
        )}
      </OuterContainer>
    );
  }
};

export default Page;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-image: url(${})
// background-size: cover;
// `;

const OuterContainer = styled.div`
  // background: red;
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
  @media (min-width: 1366px) {
    justify-content: center;
  }
`;
const MenuCon = styled.div`
  width: ${(props) => (props.fixedwidth === "true" ? "260px" : "initial")};
  @media (min-width: 1366px) {
    width: initial;
  }
  @media (min-width: 720px) {
    margin-right: 20px;
  }
  @media (max-width: 719px) {
    width: initial;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  max-width: 920px;
  flex: 1;
`;
const PageTitle = styled.h1`
  flex: 1;
  @media (max-width: 719px) {
    padding: 0 40px;
  }
`;

const MainDesc = styled.div`
  text-align: right;
  margin-bottom: 32px;
`;
