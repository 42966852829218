const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c8 doc-content">
    
    <p class="c0 italic" dir="rtl">
      <span class="c4 c7 bold">העולם</span
      ><span class="c3">  י"ט סיון תרס"ט - 8.6.1909 (עמ&#39; 15)</span>
    </p>
    <br />
    <!-- <br />
    <h3 class="c11 text-center" dir="rtl">
      <span class="c12">בארץ-ישראל.</span>
    </h3>
    -->
    <p class="c0" dir="rtl">
      <span class="c2">        מראשון-לציון כותבת אלינו הגברת נ.פ. :</span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c4"
        >        אבל גדול במושבותנו. היום בארבע שעות בבקר נפל חלל עצמו אחד בידי </span
      ><span class="c4 c10">[?]</span
      ><span class="c2"
        > החלוצים הראשונים, האכר בנימין פיין, בן 48 שנה בערך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        הוא ירה בראשו מתוך קנה-רובה, באמצע פרדס האתרוגים שלו, שהוא
        הביאו לידי כך.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >        איש ישר וחרוץ, בעל מרץ ואניציאטיבה היה פיין, אך גם בעל הזיה, -
        והנה זה כשש עשרה שנה שעלה בדעתו רעיון אחד, שנראה לו כה גדול
        וענקי!-</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2"
        >הרעיון – להוציא משוק העברים את אתרוגי קורפו ולהחליפם באתרוגי
        ארץ-ישראל!</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2"
        >בלי אמצעים נגש לפעלו, בזעה ובעמל נטע פרדס אתרוגים אחד, אחרי כן את השני,
        במרץ שאין כמהו נסה לסלול לאתרוגיו ולאתרוגי מושבות יהודה בכלל דרך בשוק
        העברי, אשר ממנו מיליונים פרנקים של יהודים עוברים לידי צורריהם לתשמישי
        קדושה... נסה, אבל לא הצליח.</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2"
        >עד הרגע האחרון נלחם המסכן עם פגעי הזמן. הוא השקיע באתרוגים את כל כחו
        ואונו והון רבים, עד כי בא בין המצרים!</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2"
        >בדעה צלולה ערך מכתב לפני שלשה ימים, הוא שלח בו שלום לאחיו האכרים, באר
        להם את סבת מותו, מנה שלשה אפוטרופסים לילדיו – בן, כחמש עשרה שנה, בת כשש
        עשרה. בו הוא מבקש לשלם את חובותיו בכסף הנחלות ולדאוג לבניו, שגם אם אין
        להם (אמם מתה זה כבר), וגם אב לא יהיה.</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2"
        >רגעים אחדים לפני מותו נכנס לחדר הילדים במנוחה מדומה. נשק לילדיו באהבה
        ובחיבה ונפרד בשלום!...</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2">נכנס אל הפרדס ויור ברובה בראשו.</span>
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2"
        >שלום לך חלוץ ישר וחרוץ! בגבורה נלחמת וכגבור נפלת חלל.</span
      >
    </p>
    <p class="c0 c1" dir="rtl">
      <span class="c2">שלום לעפרך!</span>
    </p>
    <p class="c0 c1 c9 text-left" dir="rtl">
      <span class="c5">.ראשון לציון יום א&#39;, ג&#39; סיון תרס"ט</span>
    </p>    
  </body>
</html>`;

export default page;
