const page = `
<html>
  <body class="c12 doc-content">
    <p class="c4" dir="rtl">
      <span class="c5"> </span><span class="c0">א.</span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c11"> </span
      ><span class="c8"
        >בעליתה הקטנה מול מצודת ציון כפופה דינה פינקלשטין מן הבקר על
        עבודתה.  היא מפהקת וגונחת מעיפות וכאב ראש, כשפניה הזעירים והחורים עם
        העינים השחורות והעמֻקות מביעים צער ורגז.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >...לפוש!...  אלו יכלתי רק שעה קלה לפוש, אפשר שהיה עובר כאב הראש, –
        מהרהרת דינה, – אבל תפירה זו מה תהא עליה?...הרי שתי השמלות עודן רחוקות
        מגמירה, – שתי שמלות של גברת קפדנית כברקוביץ, הכי מלתא זוטרתא
        היא?...  ונמאסה עלי, כמה נמאסה תפירה ארורה זו שמקצרת את חיי!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עצביה מתוחים, ראשה מתבקע מכאב והיא עוד יותר ממהרת בתפירתה, כאלו היא
        רוצה בזה להטריד את המון הזכרונות הקשים שתקף את מוחה העיף.  ––</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– – –  כה פתאם אירע אז האסון!...  בדמות יתוש ארסי קפץ מלאך המות וסמן
        נקודה קטנה על הלחי...  אוכל חיים נחמן, עובד ושר, לא חושד כלום ומלאך המות
        כבר לוטש עיניו, ובין לילה – ואדם גוסס!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >הוי ליל קשה של התאבקות עם מר המות! – הנשמה פרפרה, התלבטה ומאנה להסתלק
        מגופו המוצק... נושם הוא בכבדות ומעל העינים הסגורות הורם קצת עפעף אחד
        שמתחתו נשקף מבט עמום, – הוי, המבט הנורא שלא נשכח ממנה ימים רבים!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואחר כך? – עוד תוקף אותך היאוש המר, הרצון לשלח יד בנפשך לא פסק ואף על
        פי כן כבר ברי לך שהחיים ינצחו, – כי הרי חמדה גנוזה שהשאיר חיים נחמן,
        דורשת את הוַיתך וכובשת אותך!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עמרמ&#39;קי היה אז בן שמנה חדשים, והרי בשלהי הקיץ כבר תשע שנים תמלאנה
        לו – תינוק מסכן! הכי באמת אינו חש מה שחסר לו? זוכרת היא שרק פעם אחת לפני
        איזה שנים שאל אותה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אמא, מדוע לכל הילדים ישנם אב ואם ולי רק אם, רק את? – ויתלה אז בה מבט
        מלא צער.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא נטלה אותו על ידיה, בארה איך שיכלה וקנחה אחרי כן את דמעותיה בתוך
        שחור תלתליו לבל יראה אותן הפעוט.</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >תינוק נחמד! – מוסיפה דינה להרהר בעמרמ&#39;קי שלה, – פלא היאך שמתחיל
        קלסתר פניו להדמות לפני חיים נחמן! – ממש אותו המצח הגבוה, אותן העינים
        השחומות ואותן השערות המתלתלות; גם בקומה יהא כמוהו!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכאן עולה משום מה בזכרונה של דינה פגישתה הראשונה עם חיים
        נחמן.  --</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >זה היה באותו הסתו שקבלה את המשרה אצל &#39;לוינשטיין ובנו&#39;, בחנות
        הגדולה של סדקית וכלי נשים.  זוכרת היא שבהסתלקה יום יום עם החברות אחרי
        שקיעת החמה מהחנות ובעברה את רחוב יפו הארך משער יפו עד מחנה יהודה, היו
        מבטים סקרנים מסמיקים את לחייה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >החברות מצאו שתלבשתה מארג ירק-כהה, שהיתה לה אז, והמגבעת של לבד אדמה
        המופשלת כלפי הפנים, הולמים לה מאד ולפיכך כה ננעצות בה העינים
        הסקרניות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וזוכרת היא שפעם לפנות ערב, בשובה הביתה, נתקלה בעלם, שעמד על פרשת
        הרחובות וישאלנה, אם כאן היא הדרך למחנה יהודה.  הם הלכו יחד...  אבל מי
        האמין אז שזה נתקל בן זוג בבת זוגו?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וחיים נחמן התחיל לבקר את ביתם! על זה מתרעם אביה, ולבו מר עליו, – לא
        אוהב הוא את הזאטוטים הללו!...  הוא רצה להשיאה לנער ירושלמי שרק נמלאו לה
        חמש עשרה שנה! "בוגרת" כבר קרא לה אז ויתכעס ויתמרמר, כשבוגרת זו גלתה את
        דעתה, שהיא רוצה ללמד מלאכה ולעמד ברשות עצמה ולא להנשא לאיזה שוטה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מאי קא סלקא אדעתה של פתיה זו? – מחה האב.  כלום הוא, ר&#39; אשר, אחד
        ממופלגי התורה בקרתא קדישא, המקורב לר&#39; שמואלקי שליט"א – הוא יתן את
        בתו לצאת לתרבות רעה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >וכשהיא עמדה על דעתה ותלך ללמד תפירה, חשש פן דבוק הוא רחל"צ שתקף את
        הנערה, ויפנה לר&#39; שמואלקי לשאל עצה מפיו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואחרי כן, כשגמרה את למוד מלאכתה וקבלה את המשרה אצל הלוינשטינים, הוא ספק
        כפיו וצעק שאין לו בת, אין בת – וחסל!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ולשדוך עם חיים נחמן הסכים סוף סוף אבא רק מתוך יאוש.  – הריה בתולה זקנה
        בת שמנה עשרה, התשב עד שילבין ראשה?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואף על פי כן התרכך לבו בימים שלפני החתונה.  התרגש וגנח: – אמא נעדרת,
        אמא!...  לא זכתה הצדקת להוביל לחפה את דגתה! הרי ככה היתה אמא תדיר קוראת
        את פעוטתה, – דגה קטנה או דגת-ים והיתה רגילה לספר באזני השכנות, איך שילדה
        אותה בספינה סמוך לים חיפה; ואיך שרכבה אתה אחרי ימים מועטים על הפרדות
        לצפת. -</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ומתאנח העלוב על חייו הבודדים ושוב על אותה צרת הבנות דהאידנא, שהולכות
        אחרי שרירות לבן…</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דינה נטלה בפעם השניה אבקת פינצטין וכאב ראשה התחיל להשתתק.  היא שפתה
        קומקום לטהי על מכונת גז ובחזרה לתפירתה התחילו הזכרונות מטלטלים אותה לכרך
        רחוק, רחוק.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא במוסקוה בבית הדוד פרץ! אחרי מחלתה הארכה והקשה, שתקפתה במות חיים
        נחמן, צוו הרופאים חלוף אויר וטיול על פני הים.  – דוד פרץ הזמינה במכתב
        לבא אליו.  היא מאנה תחלה, אולם נכנעה אחרי שדולים רבים ותסע להתם עם המשלח
        ר&#39; גֶציל.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עמרמ&#39;קי שהיה אז כבן שנתים, חלה בדרך ודעתה כמעט שנטרפה עליה
        מצער.  ר&#39; גציל היה לה כאב ועזר לרפאות את תינוקה ולהבריאו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מוסקוה!...  כמה הבהילה אותה עיר זו בענקיותה ורבוי אכלוסיה שלא תארה לה
        מעולם.  נדמה היה לה, שהיא הוטלה לים רועש, גל אחרי גל עובר על ראשה, והיא
        הולכת ונבלעת בתוכם...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ובבית הדוד הסבירו לה כלם פנים, התיחסו אליה בחבה גלויה ובכבוד רב ואף על
        פי כן הרגישה את עצמה עלובה ובודדה שם, וכאלו צר לה המקום בבית המרֻוח
        ההוא! היא התחרטה מאד על נסיעתה ותתעצב ותסבל בחשאי כשהגעגועים לירושלים
        שלה תוקפים, תוקפים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ושיחה אחת, שהגיעה פעם לאזניה בחשכת הליל מן החדר הסמוך, הסבה לה יסורים
        קשים.  –</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– כמה אהודה, הירושלמית שלנו!...  אבל בעודנה כה צעירה, ילדה כמעט, וכבר
        אלמנה עניה שזקוקה לעזרתנו, – רחמנות! – דברה הדודה בקול בכיני.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– היא לא עניה כל כך, – הרי יש לה חמשת אלפים פרנק, שקבלה מחברת האחריות
        אחרי מות בעלה! תשב נא אתנו עד שיגדל התינוק קימעא ואז נשיאה לאיש! – ענה
        הדוד במנוחה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >שיחה זו משום מה העליבה אותה מאד ותבך כל הלילה.  –  היא, הגאה, היהירה,
        שבעודנה קטנה שאפה כבר לעמד ברשות עצמה, – היא ממלאת כעת תפקיד של קרובה
        עניה, הזקוקה לרחמים ולעזרה? קשה הדבר, קשה, וצריכה היא לשוב מהר על
        עקבה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל להוציא אל הפועל את החלטתה, לא היה לה די מרץ! בעיקר חששה מקושי הדרך
        לבדה עם הפעוט, והתלבטה היא, התלבטה בשאלה זו ולא מצאה לה פתרון.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ואז באו לה לילות הנדודים. יש שהיא שוכבת בעינים פקוחות, חבוק עמרמ&#39;קי
        בזרועותיה, והיא נשאת אתו יחד לירושלים עירה האהובה, אשר הרי יהודה ומואב
        חונים סביבה.  רואה היא את השמש, המנסר שם בחוג שמי תכלת זכים ובהירים ואת
        עבי הבדולח שצוחקים שם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >והרי ערב! רבבות כֹּכבים מתיזים אור קלוש על טליתה הכהה של ירושלים
        העתיקה, קורצים לה החביבים, רומזים ולוחשים: "שלום, שלום לבת ציון
        במרחקים!"</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לילות הנדודים תששו את כֹחה.  הדודה מסתכלת, מסתכלת בה ושואלת
        ברחמים:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דינהלי, – מה אתך? מדוע כה נפלו פניך, כלום חסר לך דבר כאן?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא במבוכה ולא מוצאת מה לענות.  פתאם לחץ לה דבר מה בגרונה, והיא חשה
        שדמעות חונקות אותה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >- בודי מתגעגעת חביבתנו ורוצה לחזר עם גוזלה אל קנה, – כן ירושלמיה שלנו?
        – שואל הדוד בחיוך, כשהוא מלטף את צמותיה העבות הסובבות את ראשה
        היפה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">מתפרצות דמעותיה והדוד ממלמל כמתחטא:</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אל לבכות, שטיא קטנה! – אני מבטיח אותך שתשובי עם הזדמנות הראשונה
        ירושלימה, אם את באמת כה מתגעגעת עליה.</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >קוי השמש האחרונים טפסו על קירות העליה, טפסו ועלו עד התקרה ונעלמו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >התחילה דינה מקפלת את עבודתה, צוברת את חתיכות הארג ומסדרת בחדר.  צר לה
        שעמרם עדין לא שב מבית הספר.  ישר משם הלך היום לחברו אבשלום לעזר
        בחבור. חביב, – לכלם הוא עוזר, ובעצמו מספיק הכל, מצליח בכל, – חרוץ שלה,
        נחמד שלה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ודינה נזכרת את שמחתו של אתמול.  – בשובו מבית הספר הוא הוציא בזריזות
        חבור נקי והראה בנצחון על אותיות גדולות רשומות בעפרון אדם: – מצוין,
        והתחיל סח בהתלהבות:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– שמעי נא, אמא, שמעי! אדון גולדשטין הביא אל המחלקה את החבורים המתֻקנים,
        הוציא אחד מהם ויאמר:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0">– הרי אקריא כאן לפניכם, ילדים,  דגמה יפה!</span>
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היודעת את, אמא חבור של מי הוא הקריא? שלי, שלי! – ועמרמ&#39;קי לטף את
        צוארה ויכסֶה בנשיקות ויסבבה ברקוד.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >מתחילה כאן דינה מהרהרת בעתידו של עמרם.  – כשיגמר את ביתה"ס "למיל" היא
        לא תכניסו אל הסמנר.  – אינה רוצה שיהא מורה, הואיל והמורות הרי מתשת את
        כחו של אדם! – היא תכניסו ישר אל הגמנסיה… אם תתפתח זו של ירושלים מוטב,
        ואם לאו – תסע אתו ליפו.  את לחמה הרי תמצא גם שם, ואמצעים ללמודים הלא יש
        לפעוט שלה.  את חמשת האלפים פרנק היא הכניסה בהבנק על שמו ליסֻד
        עתידו.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ועמרמ&#39;קי יגמר את הגמנסיה ויסע לשויץ להשתלם, כן להשתלם! כי הריהו בעל
        כשרונות, ככה אומרים לה כל המורים שלו, והיא אינה מפקפקת בזה שישיג
        גדולות…</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אבל מי יתן ותתפתח הגמנסיה הירולשמית!...  כי אם יצטרך הפעוט ללמוד ביפו,
        יהיו לה החיים שם אי נעימים מאד.  מפני שקשה לה להפגש עמהם, עם האכזרים,
        הורי חיים נחמן שמצצו את דמה במשך שתי השנים, שגרו ביחד!...  הריהם רודפים
        ושונאים אותה עד היום ואפילו את ילדה החביב הם שונאים!...  ואף על פי כך זה
        לא יעצרה מהתישב ביפו, אם ידרש לחנוכו של עמרמקי, שום דבר לא יעצרה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דינה נגשה אל החלון, הרימה את הוילון והציצה למטה, כשלבה כבר מפרכס בקרבה
        משום שעמרם בושש לבא.  נועצת היא את עיניה בהמון הנכנס בשער יפו.  מסתכלת
        בו, מסתכלת ופתאם מתחיל הלב רוטט בשמחה, – היא הכירה מרחוק אצל הפנס את
        תרבושו האדם של פעוטה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >רוח פזיזי מפזר את שערותיה ומקיש במקלות התריס הרעועה; אבני המצודה שמנגד
        התכסו בסות כהה, המסתירה את גדלן וענקיותן. רק הרוח המיבב בסמטא, כאלו מספר
        את סוד עברן הגדול שהוא נושא אתו מכאן על פני ימים רחוקים. </span
      >
    </p>
    <p class="c6" dir="rtl"><span class="c0"></span></p>
    <p class="c4" dir="rtl"><span class="c0">ב.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >על פני דינה זלף זרם קלוש של מים קרים ותתעורר מהתעלפותה. היא פוקחת את
        עיניה ותולה בפני האחות הרחמניה מבט מלא אימה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– שתי נא, גברתי פינקלשטין, רק כף אחת של רפואה זו, שתי! – משדלת אותה
        האחות הרחמניה בקול רך ועדין.</span
      >
    </p>
    <p class="c1" dir="rtl"><span class="c0">דינה ממאנת.</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– למה, למה גברתי תסרבי? כשיקיץ עמרם ויראה אותך במצב שכזה, הרי יצטער
        מאד!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >דינה שוהה רגעים אחדים בעינים עצומות, אחרי כן פקחתן, קמה ושואלת
        בהחלטה:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– מדוע התלחשו הרופאים, כשיצאו מבית החולים? היש סכנה בשעול שנוסף למחלתו
        של עמרמ&#39;קי?...  הגידי את האמת, אל תכחשי!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אל נא תחשש הגברת שום רעה! הכל הולך וטוב, ועמרמ&#39;קי שלנו יהא בעוד
        ימים מועטים בריא בכל גופו!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >לא האמינה האם המסכנה בדברי תנחומיהם של האחות ושל הרופאים, שהשתדלו
        להוכיח לה תדיר שאין לה מה לחשש, הואיל ובנין איתן כזה של בנה, הוא תריס
        בפני הפרעניות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אזניה לא קלטו את דבריהם כל עיקר.  יתוש טורד לא חדל מנקר במחה ומזמזם לה,
        שאסון גדול מתרחש לבא עליה; ומתוך תהום ההרהורים מבצבץ ועולה רעיון שחור
        ונורא...  היא מזדעזעת, רוטטת בכל אבריה והלב מקיש, מקיש בחזקה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ויש שנצוצי אורה מהבהבים באפלת נשמתה.  – הרי התינוק מסתכל בתבונה בכל
        הנעשה סביבו והוא בכל מקרה שהוא מנחמה ומשדלה לא לבכות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >גולחים את שערות ראשו.  הוא מכיר בצערה של אמו, תולה בה מבט של תחנונים
        ואומר:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אמא, יקרה, למה יצר לך ככה על תלתלי? כלום לא יצמחו במהרה שוב? וכעת,
        טרם שחלצתני החמה הרי נוח לי בלעדם!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >או, כשהוא מתעורר בלילה ומבטו נתקל בעיניה האדמות מבכי, הוא ממלמל
        בתרעמות:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– למה תדיר את בוכה ומקלקלת את עיניך, אמא? הרי שמעת ברור מפי אדון הרופא,
        שעוד ימים מספר והוא ירשני לרדת מן המטה!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ויש שהוא שוכב זמן רב בעינים פקוחות מהרהר, מהרהר ושואל:</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >– אמא, עד שיפתח שוב בית הספר אחרי חג הפסח, האספיק לחזר על למודי
        החרף?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בלי ספק תספיק, רחימאי! – עונה היא, וברגע כזה מסתלק מלבה כל חשש של סכנה
        והיא מתארת לה בדמיונה את עמרם שלה, כשהוא בריא ויפה עם לחיים סמקות, ויהיר
        ועליז הוא מסתלק מביתה וממהר לבית הספר.</span
      >
    </p>
    <p class="c3" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >אולם רֻבם של שלשים לילות האביב בבית החולים היו קשים ואיומים.  יש שחשה
        דינה כאלו עורבים שחורים מסתובבים על ראשה, מורטים משם שערה אחרי שערה
        קולעים חבלים ומצליפים על גופה הרצוץ.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ויש שהכחות אפסו ואינה יכולה לנקף אצבע צרדה.  הנשימה כבדה עליה, בצדעים
        נוקבות מרצעות ובלב הולם פטיש, ופתאם פוסקות הדפיקות לגמרי, כאלו חדלה
        מרוצת הדם בערקים והאברים הולכים ונקפאים, – התעלפות קלה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ויש התעלפות יותר קשה.  – לילה.  השעון בפרוזדור דופק אחת, שתים.  דינה
        מזדעזעת, – הגיעה השעה להחליף את הקרח בשלפוחית! היא נרתעת אל החלון ששם על
        אדנו מונח הקרח במטלית עבה.  בוחרת היא בחתיכות קטנות ומטילתן אל פי
        השלפוחית.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ננעצו עיניה בלי משים באפלה, התלויה כמסך שחור מעבר החלון, ונדמה לה, כי
        נחשים שחורים מתפתלים שם ומשרבבים לשונות אש כלפי חדרה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >היא חוזרת אל המיטה ובידים רוטטות מניחה את השלפוחית על ראש
        ילדה.  וכשרוצה להרים את ידו שצנחה, ונדמה לה שיד זו קרה והדופק פסק – היא
        רוצה לצעק ולא יכולה.  אז מתחיל חונק דבר מה את גרונה, ראשה סובב עליה,
        העינים חשכות והיא נשקעת לאיזה תהום עמוק, שחור.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >עוד אי אלה ימי חשך ולילות בלהה ונשמת התינוק הסתלקה!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >האם המסכנת נעצרה בבית החולים והיתה נתונה בסכנה גדולה ממחלת לב קשה
        וזעזוע העצבים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >כעבור ימים לא מועטים, והרופאים  התירו לתת למבקרים רשות להכנס אליה; אז
        בא אצלה שליח מחברה קדישא לפרע דמי הקבורה…</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >… היא שלחה ע"י אביה פתקא אל הבנק בבקשה לתת לה עשרה נפוליונים על חשבון
        כספה המונח שם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >ושב הזקן העלוב ריקם וסח לה בהתרגשות ובגמגום, כי מנהל הבנק הודיע לו בצער
        שחותנה העשיר בא מיפו והביא פסק דין מהרבנים, כי הכסף המונח בהבנק על שם
        נכדו, עובר ע"פ דין ישראל כלו בירושה אליו, ואין לכלתו שום חלק
        בו!...</span
      >
    </p>
    <p class="c9" dir="rtl" style="text-align: center"><span class="c0">*  *  *</span></p>
    <p class="c1" dir="rtl">
      <span class="c0"
        >בבית החולים קמה פתאם מהומה ומבוכה,  – האחיות והמשגיחים רצו ונחפזו להחיש
        עזרה לדינה פינקלשטין שהתעלפה ומגרונה קולח דם! –  –  –</span
      >
    </p>
    <p class="c6 c7" dir="rtl"><span class="c0"></span></p>
  </body>
</html>
`;
export default page;
