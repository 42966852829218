const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c1 doc-content">
    <p class="c2" dir="rtl">
      <span class="c0">
        הכתבתי? לא! פנאי לא היה – העבודה הקשה בלעה את הסופרת, ומה שנדפס – אינני
        יודעת כיצד ומתי יצא מתחת עטי.
      </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c0">
        אם לאחר שאני אשכון בדירת הנצח ימצא במישהו רצון לאסוף לקֹבץ את מה שימצאו
        בעלים הללו ובעוד אי אלו מקומות בדפוס או בכתב יד, אבקש למסור את העבודה
        לידים נאמנות, שתרצנה לטפל בעבודות שאינן גמורות.
      </span>
    </p>
    <p class="c2" dir="rtl">
      <span class="c0">נ.פ.</span>
    </p>
    <p class="c2 c3" dir="rtl"><span class="c0"></span></p>
  </body>
</html>`;

export default page;
