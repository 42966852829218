const desc = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c5 doc-content">
    <p class="c6" dir="rtl">
      <span class="c7"> </span><span class="c0 c2">מתוך</span
      ><span class="c0"> </span><span class="c1 bold">בכפר ובעבודה</span
      ><span class="c0">. </span
      ><span class="c2 c4">הסיפור נכתב בכפר הילדים מאיר-שפיה לשם היתה פוחצ&#39;בסקי קשורה דרך </span>
      
        <span class="c2 c4 c8 tooltip"><a href="/testimonies/onTakingCareOfOtherGirls">היתומות בהן טיפלה</a><span class="c1 bold tooltiptext">ראו במדור העדויות</span></span><span class="c2 c4">.</span>
      
      
    </p>
  </body>
</html>`;

export default desc;
