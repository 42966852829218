const page = `<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
  </head>
  <body class="c12 doc-content">
    <p class="c6 c11 text-center" dir="rtl">
      <span>– </span><span class="c2">רשימה –</span>
    </p>
    <p class="c7 c11" dir="rtl"><span class="c2"></span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >אחרי עבודת-יום קשה בכרם ובארוָה, עמד אברהם שחור נשען בראשו אל אחד
        העמודים של אכסדרת ביתו והרהר בתוגה: הנה המלחמה גזלת עוד אחד מן הידידים
        היחידים, הספורים!... ודגול היה האדם ואת זהב נשמתו פזר על ימין ועל
        שמֹאל... כל מטרת חייו של זה היתה רק להקל מן יסוריהם של אחרים... ועתה
        השתתק לב גדול וחיים צעירים ורעננים הפסיקו פעֻלתם!...   מי יהיה לעזר
        לחמודותיו – לשושנת-השרון ופעוטיה? התגיע ידו לעשות בשבילם דבר?...
      </span>
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >הוא צנח על מדרגות-האבן ברשלנות וראשו מלא דאגה, איך לסדר את משפחת המת.
        ריח חריף נודף כאן מן הגן, מן העציצים הסמוכים. מבלי-משים הוא שולח מבטיו
        למטה. וַשינגטוניות בחביות ירֻקות שומרות את שני צדי המדרגות; טרדיסקנסיה
        אחת תלויה על כרכוב המעקה ויורדת ישר אל שורת-העציצים, המסֻדרה יפה על ספסל
        ירֹק, שבקצהו, בפח צבוע פסי-תכלת, צומח אספרגוס מסֻלסל, שגבעוליו הדקים עם
        עלי-רקמתו הנפלאים מטפסים ועולים עד למעקה האכסדרה הגבוהה והם קשורים כאן
        זה אצל זה בחוטי-רַפיה דקים.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >בפנת הגן שתי טֵיות נטועות בקו אלכסוני; גרניום אָדֹם צומח בחצי-עִגול,
        ובאמצע משתרע "מגן-דוד" רב-גוַָנים עם קקטוסים ממינים שונים; אחד מהם פורח
        והפרח גדול, גדול לבן. "מלכת-הלילה" הוא נקרא, והוא מולך על הגן, - והיה
        רצון לשָחור לרדת, לקטוף את הפרח ולהשליכהו מעבר לגדר.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >כי למה כל זה, אם החיים הולכים ונהרסים וכל מה שנבנה במשך עשרות בשנים
        עומד להעקר משרשו? לאט לאט מתמוטט הבנין במשך שלש השנים של מלחמת-עולם קשה
        זו, מתמוטט ומתפרד לנגד עינינו!</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >קוי שמש שוקעת בחולות האירו את מרחקי הכרמים; עם האֹפק הרחוק מואר מִגדל
        בודד, ומחשבת שָחור נִשֵֹאת למקום ההוא. – שם נחרבה עיר שלמה בהתרוקנה
        מיושביה! מהפרוַר היהודי היפה, אולי הכי-יפה שבפרוַרי עם ישראל באיזו
        פנת-עולם שהיא, גֹרשו האוכלוסים ורכושם הֻפקר והיה לבז.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >נחרבה העיר ועומדות להחרב גם המושבות! לא נמָלט מן הפקֻדה לעזוב הכל –
        האדמה, הבתים, הכרמים – ולצאת מיהודה! לולא עֹז-רוחן של נשי המושבה, אשר
        התעקשו להשאר ויהי מה, לא היה כבר כאן אף בית אחד מיֻשב!... אבל מי יודע מה
        יהיה בימים הבאים, - הנעמוד בפני צר? הלא גרוש בזעיר-אנפין כבר התחיל
        במושבה... התחיל מדלת העם, מאלה, שאין ידם משגת לשלם דמי "זרעין", - דמי
        תעודה, שזרעו חלקת אדמה והם צריכים לשמור עליה...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >מתוך רעד הוא נזכר במחזה קורע-לב, כשנשלחו חמש עגלות טעונות מטלטלים בלים
        ונשים וטף עם פנים דלים ושחורים... בחריקת-שנים הוא שואל את-עצמו: למה
        צריכים היו הם לשמש קרבנות, למה לא סדרה העזרה לפדות גם אותם מידי הממשלה?
        באיזו רשות נשארנו אנחנו בבתינו היפים, עם שקי קמח וכל טוב באסמים, והם
        גרשו מקנם הדל ללכת באשר ילכו למות מרעב?</span
      >
    </p>
    <p class="c0 c8" dir="rtl"><span class="c2"></span></p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >יום-אלול יפה. אברהם שחור נגש לסדור גן-הירקות שלו, אשר נשאר עזוב כל ימי
        הבציר והקטיף. הוא העביר מן הערוגה הרטֻבה שתילי עגבניות-סתָו ושתלם בחלקה
        מעֻבּדה מחדש. כשגמר, הסתכל במעשה-ידיו וכעין תענוג של יצירה זע בלב; אבל
        אחר-כך, כשאסף את כלי-עבודתו ואמר לצאת מן הגן, באה רוח אחרת בקִרבו ושאל
        את-עצמו: שמחה זו מה טעמה? לא עצים נטע, לא כרם, שיחיה שנים רבות וממנו
        יהנו בנים ובני-בנים. הלא רק שיח קטן יהיה זה, שיח נמוך, שאינו בר-קימא,
        אלא שפריו נאה ונהנים על טעמו הטוב במשך חדשים אחדים עד כלות צמיחתו.
        תפוח-אהבה קראו הצרפתים לפרי האדֹם הזה, בעל העסיס הוָרֹד והריח הדק,
        המעורר תאבון. חבל, שהשיח מת אחרי סַלקו את פריו והוא נעשה שחרחר ומכֹער,
        מכער. רק הפרי החוַרור-ירֹק, שלא הספיק להתבשל עוד, מראה אותות-חיים, מתבקש
        לכבוש...</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >שחור נזכר, שחג לו היום – יום חתֻנתו. עשרים שנה עברו! הרי הטבע עורך באמת
        חג לכבודו, - העצים מסביב כה יפים ומלבבים, גם החצר והדרכים ושבילי הגן
        נראים לו היום נקיים יותר ומרֻוָחים יותר. גם הסוסים כאלו קלה עליהם עבודתם
        הפעם והנה הם שבים זקופי-ראש, בלי סִמן של עיפות, וצוהלים ועורם
        מבריק.</span
      >
    </p>
    <p class="c0" dir="rtl">
      <span class="c2"
        >הנה באו במעוף אליו התרנגולות והיונים. לא – הוא טעה! הן לשיָרי האֹכל של
        הסוסים מתכונות. יודעות הן, שהעגלון יוריד את השֹקים מן העגלה והן תמצאנה
        גרעינים לשבע, בטרם יספיק רשע זה להצליף עליהן בשוטו...</span
      >
    </p>
    <p class="c9" dir="rtl">
      <span class="c2"
        >        על רעפי גג-הארוָה פשט ענפיו העליונים אוקליפטוס צעיר, המתנשא
        בגאון על-פני אחיו; הוא לבוש כֻּלו פרחים לבנים-צהבהבים. את עיני שָחור
        מושך ביחוד זוג פרחים דבוקים ואחוזים זה בזה על-גבי ענף דק, דק ורך. בהנאה
        רבה מסתכל שחור בדמות הזוג, דמות חיה זעירה, בעלת לחיַיִם שקערוריות ועינים
        גדולות, נוצצות.</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >מרחוק משמיעה אתון קולה: זוהי "פרשה", המחזירה את שרה מן הטיול. תדיר
        "פרשה" משמיעה את קולה בפנותה אל הסימטה בדרך הביתה! שחור יוצא לקראת אשתו,
        מתבונן בה בחִבה ומחפש את הקוִים החביבים לו ביותר. הזדקנה שרה! – מהרהר
        הוא בלבו. – אבל עדין יפה היא, ועשרים שנות עבודה קשה לא טשטשו מחנה
        כלום!</span
      >
    </p>
    <p class="c3" dir="rtl">
      <span class="c2"
        >הוא הורידהּ בזהירות מן האתון, ובעודה חבוקה בידיו נשק לה על תלתלי ראשה
        הבהיר.</span
      >
    </p>
    <p class="c10" dir="rtl">
      <span class="c2">נשיקת-חתונה? – מגחכת אשתו, והוא עונה בפתוס:</span>
    </p>
    <p class="c10" dir="rtl">
      <span class="c2"
        >זכרתי לך חסד נעוריך, אהבת כלולותיך, לכתך אחרי במדבר, בארץ לא-זרועה! –
        וצחקו שניהם.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >היא מוסרת לו את האתון, תולה את קובע-הקש שלה על עץ וממהרת אל הרפת
        לחלוב.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >קול-חליל דק נשמע ברחוב. שחור הציץ בין העצים, - גדוד-צבא עובר עם התזמֹרת
        שלו, והצלילים רכים, רכים ולבביים. רטט פנימי עברו: הזוהי שירת המוֵֶת?
        הככה משתפך הלב בלכת האדם למות? הנה הד של יריות רחוקות רועד באויר – וזה
        הצבא, היחף והרעב, הלא ישר אל-תחת רעמי התותחים הוא הולך!...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >והלכו קולות העצב, התרחקו והשתתקו. גם הד היריות פסק וקולות רגילים עלו
        ובקעו מן המושבה: דפיקת מכונות הפרדסים; קול ילדים משחקים; נגינה על פסנתר;
        תרנגול צעיר מתלמד קריאה, וצפרדע מקרקרת על-יד הברֵכה.</span
      >
    </p>
    <p class="c1 c8" dir="rtl"><span class="c2"></span></p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >זה שלשה ימים ושלשה לילות נִשאת מדרום בלי-הרף שירת-המות הרחוקה. הד
        היריות פולח את האויר, ועצבי אברהם שחור נמתחו עד כדי נדידת-שֵנה לגמרי.
        על-פי-רב הוא קם באמצע הלילה, מתלבש לאט לאט ויוצא בזהירות את הבית. הוא
        מטפס על סֻלם ועולה על גג ארותו לראות, מאיזו נקֻדה יוצאים זִקי-האש. הוא
        עומד ורועד מקֹר ומרטיבות הטל, רועד ומתכוֵץ ושולח מבטיו דרומה
        בעקשנות.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >קול משק מים משתפכים מהברז עלה באזניו. שחור קפץ בשמחה: זה שלשה ימים שלא
        היו מים במושבה, מאין כסף לקנות פחמים להסקת המכונה – ועתה, באמצע הלילה,
        באה הישועה! שחור ממהר לרדת, למען סַדר את המים להשקאת הגן הצמא. הוא מביא
        פנס מן הבית, מעדר מן המחסן, ומתחיל לפתוח דרך אל השיחים הכי-צמאים.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בזרם חרישי ואִטי נוזלים המים לאור הקטן של הפנס ונופלים ישר לתוך הגומות
        החרבות. מנוחת הטבע ויפי הטבע מתערבים בכחות אדירים אכזריים. למטה מַשק-מים
        קל, למעלה הלמות תותחים כבדים, וחרדים הכוכבים, חרדים למראה.</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >בניָן וסתירה! בכל נשלחה יד של כלָיה, וכמה מגחך ה ו א ברצותו להציל שיחים
        אחדים ממות! מהי גִנה עלובה זו כנגד כל מה שנחרב עליו מימי הארבה ועד היום
        הזה? היָקים את ההרס ההולך ומתרחב, הולך ומתעמק?</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >כאן נזכר שחור בדברי פקיד גבוה, שאמר באזני הוַעד: - "אם יבוא השונא, לא
        נשאיר לכם אבן על אבן ולא יתד בקיר! את עציכם נעקור ואת כרמיכם נשרוף באש;
        הנה אתם מחכים לשונא בכליון-נפש, כחתן שמצפה לבוא הכלה, ואנחנו ביום-שמחתכם
        נפריד ביניכם, בכח-הזרוע נשמידכם!" ככה דִבר האיש, אשר כבוד-מלכים חלקנו לו
        – האם לא יקיֵם את הבטחתו?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >אברהם שחור חופר במעדרו, נותן כִּוון חדש לזרם המים, מפסיק ועומד נשען על
        ידות המעדר לחכות, עד שתתמלאנה הגומות החדשות, ומחשבה מרה קוססת לבו: תמיד,
        בהתנגש העמים זה בזה, דם היהודי שותת ראשון, שותת בלי-רחם, והוא תמיד
        הראשון לקרבן!</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >הוא מפהק, מתמתח וחֻלשה רבה תוקפת אותו; הוא עוזב את העבודה, הולך לסגור
        את המים ובינתים שואל את-עצמו: איזו רוח נעלמה מניעה את כח-היצירה שבאדם
        וקוראת לו לעבוד, לעמול, לחגור שארית המרץ, למען הציל משהו מהחֻרבן הגדול,
        -  בשביל השונא, שיהיה לו מה להחריב? ולמה ולשם-מה בא החרבן, באה הכליה
        הזאת על העולם כלו?...</span
      >
    </p>
    <p class="c1" dir="rtl">
      <span class="c2"
        >אור הפנס דועך, המזרח התחיל מתחַור, השתתקו יריות התותחים, רק הים רועש
        וזועף מרחוק. במזרח נגלו ראשי ההרים, עטופי טלית של שחרית. מן העצים שבגן
        נשמע צפצוף דק מן הדק, אחריו עוד קול ועוד קול, קורא ומשיב, עד שהתגברה
        השירה ובקעה את האויר בברכת "צפרא טבא" למשכימי קום.</span
      >
    </p>
    <p class="c1 c8" dir="rtl"><span class="c2"></span></p>
    <p class="c1" dir="rtl">
      <span class="c13 small-text">ראשון לציון.</span>
    </p>
  </body>
</html>`;

export default page;
