const desc = `<body class="c5 doc-content">
  <p class="c2" dir="rtl">
    <span class="c4"
      >זר פרחים על קבר רענן, לדמותה של המנוחה נחמה פוחצ&#39;בסקי ז"ל, ליום
      השלשים</span
    ><span class="c3 bold"> דואר היום </span
    ><span class="c4">  1934 06 19</span>
  </p>
  <p class="c2 c6" dir="rtl"><span class="c0"></span></p>
</body>`;

export default desc;
